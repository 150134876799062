import { USER_TEMPLATE_TYPE } from 'Common/constants/userTemplateType';
import manageWorkbenchModalTemplate from './modal/workbenchRequirementTemplatesMgmt.html';
import manageWorkbenchModalTemplateCtrl from './modal/workbenchRequirementTemplatesMgmtCtrl';

class UsersTemplatesCtrl {
  constructor($state, $uibModal, templateService) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.templateService = templateService;

    this.userTemplates = [
      {
        type: USER_TEMPLATE_TYPE.TASK,
        name: 'Task Templates',
        description: 'Create and manage templates used in creating tasks',
      },
      {
        type: USER_TEMPLATE_TYPE.SMS,
        name: 'SMS Templates',
        description:
          'Create and manage templates that you can use in sending SMS to your clients',
      },
      {
        type: USER_TEMPLATE_TYPE.EMAIL,
        name: 'Email Templates',
        description:
          'Create and manage email templates to send out in your follow ups and campaigns',
      },
      {
        type: USER_TEMPLATE_TYPE.WORKBENCH,
        name: 'Workbench Default Templates',
        description: 'Create and manage default requirements for Workbench',
      },
    ];
  }

  manageTemplates(type) {
    switch (type) {
      case USER_TEMPLATE_TYPE.TASK:
        this.manageTaskTemplates();
        break;
      case USER_TEMPLATE_TYPE.SMS:
        this.manageSMSTemplates();
        break;
      case USER_TEMPLATE_TYPE.EMAIL:
        this.manageEmailTemplates();
        break;
      case USER_TEMPLATE_TYPE.WORKBENCH:
        this.manageWorkbenchTemplates();
        break;
      default:
      // do nothing
    }
  }

  manageTaskTemplates() {
    this.$state.go('app.business');
  }

  manageEmailTemplates() {
    this.$uibModal.open({
      templateUrl:
        'assets/views/corporate/emailManagement/emailManagement.html',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      controller: 'EmailManagementCtrl',
    });
  }

  manageSMSTemplates() {
    this.templateService.openSMSTemplatesManagement(false);
  }

  manageWorkbenchTemplates() {
    this.$uibModal.open({
      template: manageWorkbenchModalTemplate,
      controller: manageWorkbenchModalTemplateCtrl,
      controllerAs: 'vm',
      size: 'lg',
    });
  }
}

export default UsersTemplatesCtrl;
