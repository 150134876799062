app.factory('loanAppHelperService', [
  '$timeout',
  'loanApplicationModel',
  function($timeout, loanApplicationModel) {
    var factory = this;
    factory.financial = {};

    // validation if there is data inside every financial position
    factory.financial.validation = function(financialInfoObj, familyId) {
      var filterFinancial = _.find(financialInfoObj, obj => {
        return obj.FamilyId === parseInt(familyId);
      }) || { overview: { FinancialInfo: [] } };
      return filterFinancial.overview.FinancialInfo.length > 0;
    };

    factory.financial.addCallback = function(scope, callback) {
      isRealEstate = typeof isRealEstate === 'undefined' ? false : isRealEstate;
      scope.assetLoading = true;
      $timeout(() => {
        callback();
      }, 2000);
    };

    return factory;
  },
]);
