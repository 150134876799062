export function optionListBuilder(list) {
  return {
    name: list.Name,
    value: list.Value,
  };
}

export function objectivesOptionBuilderForUI(option) {
  return {
    questionId: option.QuestionId,
    options: option.List.map(optionListBuilder),
  };
}
