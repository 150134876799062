import angular from 'angular';

angular.module('app').directive('onDragFiles', [
  '$window',
  function onDragFiles($window) {
    const onDragFilesEnter = (show = false, scope) => {
      scope.showDragBox = show;
    };
    return {
      link(scope) {
        $window.addEventListener('dragenter', () => {
          onDragFilesEnter(true, scope);
        });
      },
    };
  },
]);
