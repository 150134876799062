import angular from 'angular';

angular
  .module('app')
  .controller(
    'PipelineModalLeadsFilterCtrl',
    function PipelineModalLeadsFilterCtrl(
      $scope,
      $uibModalInstance,
      pipelineService,
      pipelineSharedData,
      taskAdviserList,
      labelsList,
      enquirySourceList,
      referralListCopy,
      DisplayMode
    ) {
      $scope.pipelineSharedData = {};
      angular.copy(pipelineSharedData, $scope.pipelineSharedData);

      $scope.labelsList = [];
      angular.copy(labelsList, $scope.labelsList);
      $scope.labelsList.splice(0, 0, {
        pipelineSettingsLabelID: 0,
        LabelName: 'All',
      });

      $scope.taskAdviserList = taskAdviserList;
      $scope.enquirySourceList = enquirySourceList;
      $scope.referralListCopy = referralListCopy;
      $scope.DisplayMode = DisplayMode;

      $scope.cancel = () => {
        $uibModalInstance.dismiss();
      };

      $scope.save = () => {
        const {
          SortColumn: sortColumn,
          SortType: sortType,
          PipelineStatusID: statusId,
          adviserId: advisorId,
          labelId,
          probability,
          enquiryId: enquirySource,
          referralId: referral,
          sortingMode,
          CustomerSort: customerSort,
          TableViewExcludeColumns,
        } = $scope.pipelineSharedData.leadFilterData;

        const data = {
          sortColumn,
          sortType,
          statusId,
          advisorId,
          labelId,
          probability,
          enquirySource,
          referral,
          sortingMode,
          customerSort,
          TableViewExcludeColumns,
          isLeads: true,
          isCompact: $scope.pipelineSharedData.leadFilterData.isCompact === '1',
          isTableView: false,
        };

        pipelineService.setPipelineCardFilters(data).then(() => {});
        $uibModalInstance.close($scope.pipelineSharedData);
      };
    }
  );
