import { isValidEmailAddress } from 'Common/utilities/string';

export default class EmailEditor {
  constructor(emailObj = {}) {
    'ngInject';

    this.emailObj = emailObj;
  }

  onSelectRecipient(recipient) {
    if (!isValidEmailAddress(recipient)) {
      this.isRecipientsInValid = true;
      return;
    }

    if (this.emailObj.recipientsEmailAddresses) {
      this.emailObj.recipientsEmailAddresses = [
        ...this.emailObj.recipientsEmailAddresses,
        recipient,
      ];
    } else {
      this.emailObj.recipientsEmailAddresses = [recipient];
    }
  }

  onSelectCc(cc) {
    if (!isValidEmailAddress(cc)) {
      this.isCcInValid = true;
      return;
    }

    if (this.emailObj.ccEmailAddresses) {
      this.emailObj.ccEmailAddresses = [...this.emailObj.ccEmailAddresses, cc];
    } else {
      this.emailObj.ccEmailAddresses = [cc];
    }
  }

  onRemoveRecipient(recipient) {
    const indexOfRecipient = this.emailObj.recipientsEmailAddresses.indexOf(
      recipient
    );
    this.emailObj.recipientsEmailAddresses = [
      ...this.emailObj.recipientsEmailAddresses.slice(0, indexOfRecipient),
      ...this.emailObj.recipientsEmailAddresses.slice(indexOfRecipient + 1),
    ];
  }

  onUpdateSubject(subject) {
    this.emailObj.subject = subject;
  }

  onUpdateBody(body) {
    this.emailObj.body = body;
  }
}
