import angular from 'angular';

angular
  .module('app')
  .directive('windowResize', function windowResize($log, $window) {
    return {
      restrict: 'E',
      scope: {
        onResize: '&onResize',
      },
      link(scope) {
        function onWindowResize() {
          scope.onResize();
        }
        angular
          .element($window)
          .on('resize orientationchange load', onWindowResize);
        scope.$on('$destroy', () => {
          angular
            .element($window)
            .off('resize orientationchange load', onWindowResize);
        });
      },
    };
  });
