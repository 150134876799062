import angular from 'angular';
import swal from 'sweetalert';
import { clientWindowWatcher } from 'Common/utilities/clientWindowWatcher';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('ClientModalCtrl', function ClientModalCtrl(
    $scope,
    $window,
    $uibModalInstance,
    contactService,
    loadcontactdata,
    contacts
  ) {
    $scope.roles = {};
    $scope.titles = {};
    $scope.contacts = contacts;

    if (
      typeof $scope.contacts.isCorporateUser !== 'undefined' &&
      !$scope.contacts.isCorporateUser
    ) {
      contactService.roleType().then(
        response => {
          $scope.roles = response.data;
        },
        () => {
          $scope.roles = {};
        }
      );

      contactService.titleType().then(
        response => {
          $scope.titles = response.data;
        },
        () => {
          $scope.titles = {};
        }
      );
    }

    $scope.contact = [
      {
        FamilyId: '0',
        LastName: '',
        FirstName: '',
        MiddleName: '',
        PreferredName: '',
        Title: '',
        Gender: '',
        DOB: '',
        Employment: [
          {
            Occupation: '',
            OccupationClass: 0,
            Employer: '',
          },
        ],
        SmokerStatus: '',
        Deceased: false,
        DOBReminder: false,
        BestTimeToCall: '',
        Phone: [
          {
            Type: 'Home',
            Number: '',
          },
          {
            Type: 'Work',
            Number: '',
          },
          {
            Type: 'Mobile',
            Number: '',
          },
        ],
        Email: [
          {
            Type: 'Email',
            EmailAddress: '',
          },
        ],
        Notes: '',
      },
    ];

    $scope.addClient = form => {
      let firstError = null;
      if (form.$invalid) {
        form &&
          Object.keys(form).forEach(field => {
            if (field[0] !== '$') {
              if (firstError === null && !form[field].$valid) {
                firstError = form[field].$name;
              }

              if (form[field].$pristine) {
                form[field].$dirty = true;
              }
            }
          });

        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        return;
      }
      if (
        typeof $scope.contacts.isCorporateUser !== 'undefined' &&
        !$scope.contacts.isCorporateUser
      ) {
        contactService
          .addContact($scope.contact)
          .then(() => {
            $uibModalInstance.dismiss('cancel');
            loadcontactdata();
            swal('Added', 'Contact has been Added Successfully.', 'success');
          })
          .catch(displayError);
      }
    };
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    if ($scope.screenSize < 992) {
      $scope.isNotesPanelDisplayed = false;
      $scope.isAdditionalPanelDisplayed = false;
    } else {
      $scope.isNotesPanelDisplayed = true;
      $scope.isAdditionalPanelDisplayed = true;
    }
    const viewport = () => {
      let e = $window;
      let a = 'inner';
      if (!('innerWidth' in $window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = () => {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        const clientDetailWindowWatcherObj = clientWindowWatcher(newValue);
        Object.keys(clientDetailWindowWatcherObj).forEach(key => {
          $scope[key] = clientDetailWindowWatcherObj[key];
        });
      },
      true
    );
  });
