import angular from 'angular';

class SetCreditChecksModalCtrl {
  constructor($uibModalInstance, selectedContact) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.selectedContact = selectedContact;
  }
}

angular
  .module('app')
  .controller('SetCreditChecksModalCtrl', SetCreditChecksModalCtrl);
