import { getBenefitTabName } from 'Common/utilities/insurance';

export function benefitTypeForUI(benefitType) {
  return {
    benefitType: benefitType.BenefitType,
    benefitTypeId: benefitType.BenefitTypeID,
    insuranceType: benefitType.InsuranceType,
    keyQuestion: benefitType.KeyQuestion,
    qprBenefitId: benefitType.QPRBenefitID,
    risk: benefitType.TheRisk,
    solution: benefitType.TheSolution,
    descriptionTitle: benefitType.Title,
    name: getBenefitTabName(benefitType.QPRBenefitID),
  };
}

export function healthCoverBenefitBuilderForUI(healthCover) {
  return (
    healthCover && {
      loading: parseInt(healthCover.Loading, 10) || 0,
      excess: parseInt(healthCover.Excess, 10) || 0,
      isSpecialistsTest: !!healthCover.SpecialistsTest,
      isGpPrescriptions: !!healthCover.GpPrescriptions,
      isDentalOptical: !!healthCover.DentalOptical,
    }
  );
}

export function healthCoverBenefitBuilderForAPI(healthCover) {
  return (
    healthCover && {
      Loading: parseInt(healthCover.loading, 10) || 0,
      Excess: parseInt(healthCover.excess, 10) || 0,
      SpecialistsTest: !!healthCover.isSpecialistsTest,
      GpPrescriptions: !!healthCover.isGpPrescriptions,
      DentalOptical: !!healthCover.isDentalOptical,
    }
  );
}

export function lifeCoverBenefitBuilderForUI(lifeCover) {
  return (
    lifeCover && {
      isIndexed: !!lifeCover.Indexed,
      isFutureInsurability: !!lifeCover.FutureInsurability,
      loading: parseInt(lifeCover.Loading, 10) || 0,
      coverAmount: parseInt(lifeCover.CoverAmount, 10) || 0,
      calcPeriod: parseInt(lifeCover.CalcPeriod, 10) || 0,
    }
  );
}

export function lifeCoverBenefitBuilderForAPI(lifeCover) {
  return (
    lifeCover && {
      Indexed: !!lifeCover.isIndexed,
      FutureInsurability: !!lifeCover.isFutureInsurability,
      Loading: parseInt(lifeCover.loading, 10) || 0,
      CoverAmount: parseInt(lifeCover.coverAmount, 10) || 0,
      CalcPeriod: parseInt(lifeCover.calcPeriod, 10) || 0,
    }
  );
}

export function familyProtectionBenefitBuilderForUI(familyProtection) {
  return (
    familyProtection && {
      isIndexed: !!familyProtection.Indexed,
      loading: parseInt(familyProtection.Loading, 10) || 0,
      coverAmount: parseInt(familyProtection.CoverAmount, 10) || 0,
      benefitPeriodTerm: parseInt(familyProtection.BenefitPeriodTerm, 10) || 0,
      benefitPeriodYear: parseInt(familyProtection.BenefitPeriodYear, 10) || 0,
    }
  );
}

export function familyProtectionBenefitBuilderForAPI(familyProtection) {
  return (
    familyProtection && {
      Indexed: !!familyProtection.isIndexed,
      Loading: parseInt(familyProtection.loading, 10) || 0,
      CoverAmount: parseInt(familyProtection.coverAmount, 10) || 0,
      BenefitPeriodTerm: parseInt(familyProtection.benefitPeriodTerm, 10) || 0,
      BenefitPeriodYear: parseInt(familyProtection.benefitPeriodYear, 10) || 0,
    }
  );
}

export function traumaCoverBenefitBuilderForUI(traumaCover) {
  return (
    traumaCover && {
      isIndexed: !!traumaCover.Indexed,
      isChildrenIncluded: !!traumaCover.IncludeChildren,
      isLifeBuyBack: !!traumaCover.LifeBuyBack,
      isTraumaBuyBack: !!traumaCover.TraumaBuyBack,
      isTpdAddOn: !!traumaCover.TpdAddOn,
      isChildTrauma: !!traumaCover.ChildTrauma,
      isStandalone: !!traumaCover.Standalone,
      isAccelerated: !!traumaCover.Accelerated,
      isTpdAnyOccupation: !!traumaCover.TpdAnyOccupation,
      isTpdOwnOccupation: !!traumaCover.TpdOwnOccupation,
      loading: parseInt(traumaCover.Loading, 10) || 0,
      coverAmount: parseInt(traumaCover.CoverAmount, 10) || 0,
      childCoverAmount: parseInt(traumaCover.ChildCoverAmount, 10) || 0,
      calcPeriod: parseInt(traumaCover.CalcPeriod, 10) || 0,
    }
  );
}

export function traumaCoverBenefitBuilderForAPI(traumaCover) {
  return (
    traumaCover && {
      Indexed: !!traumaCover.isIndexed,
      IncludeChildren: !!traumaCover.isChildrenIncluded,
      LifeBuyBack: !!traumaCover.isLifeBuyBack,
      TraumaBuyBack: !!traumaCover.isTraumaBuyBack,
      TpdAddOn: !!traumaCover.isTpdAddOn,
      ChildTrauma: !!traumaCover.isChildTrauma,
      Standalone: !!traumaCover.isStandalone,
      Accelerated: !!traumaCover.isAccelerated,
      TpdAnyOccupation: !!traumaCover.isTpdAnyOccupation,
      TpdOwnOccupation: !!traumaCover.isTpdOwnOccupation,
      Loading: parseInt(traumaCover.loading, 10) || 0,
      CoverAmount: parseInt(traumaCover.coverAmount, 10) || 0,
      ChildCoverAmount: parseInt(traumaCover.childCoverAmount, 10) || 0,
      CalcPeriod: parseInt(traumaCover.calcPeriod, 10) || 0,
    }
  );
}

export function tpdBenefitBuilderForUI(tpdBenefit) {
  return (
    tpdBenefit && {
      isIndexed: !!tpdBenefit.Indexed,
      isOwnOccupation: !!tpdBenefit.OwnOccupation,
      isAnyOccupation: !!tpdBenefit.AnyOccupation,
      isTpdOwnOccupation: !!tpdBenefit.TpdOwnOccupation,
      isTpdAnyOccupation: !!tpdBenefit.TpdAnyOccupation,
      isStandalone: !!tpdBenefit.Standalone,
      isAccelerated: !!tpdBenefit.Accelerated,
      loading: parseInt(tpdBenefit.Loading, 10) || 0,
      coverAmount: parseInt(tpdBenefit.CoverAmount, 10) || 0,
      calcPeriod: parseInt(tpdBenefit.CalcPeriod, 10) || 0,
    }
  );
}

export function tpdBenefitBuilderForAPI(tpdBenefit) {
  return (
    tpdBenefit && {
      Indexed: !!tpdBenefit.isIndexed,
      OwnOccupation: !!tpdBenefit.isOwnOccupation,
      AnyOccupation: !!tpdBenefit.isAnyOccupation,
      TpdOwnOccupation: !!tpdBenefit.isTpdOwnOccupation,
      TpdAnyOccupation: !!tpdBenefit.isTpdAnyOccupation,
      Standalone: !!tpdBenefit.isStandalone,
      Accelerated: !!tpdBenefit.isAccelerated,
      Loading: parseInt(tpdBenefit.loading, 10) || 0,
      CoverAmount: parseInt(tpdBenefit.coverAmount, 10) || 0,
      CalcPeriod: parseInt(tpdBenefit.calcPeriod, 10) || 0,
    }
  );
}

export function incomeProtectionBenefitBuilderForUI(incomeProtection) {
  return (
    incomeProtection && {
      isIndexed: !!incomeProtection.Indexed,
      isBooster: !!incomeProtection.Booster,
      coverAmount: parseInt(incomeProtection.CoverAmount, 10) || 0,
      loading: parseInt(incomeProtection.Loading, 10) || 0,
      weekWaitPeriod: parseInt(incomeProtection.WeekWaitPeriod, 10) || 0,
      benefitPeriod: parseInt(incomeProtection.BenefitPeriod, 10) || 0,
      isTaxable: parseInt(incomeProtection.IsTaxable, 10) || 0,
    }
  );
}

export function incomeProtectionBenefitBuilderForAPI(incomeProtection) {
  return (
    incomeProtection && {
      Indexed: !!incomeProtection.isIndexed,
      Booster: !!incomeProtection.isBooster,
      CoverAmount: parseInt(incomeProtection.coverAmount, 10) || 0,
      Loading: parseInt(incomeProtection.loading, 10) || 0,
      WeekWaitPeriod: parseInt(incomeProtection.weekWaitPeriod, 10) || 0,
      BenefitPeriod: parseInt(incomeProtection.benefitPeriod, 10) || 0,
      IsTaxable: parseInt(incomeProtection.isTaxable, 10) || 0,
    }
  );
}

export function mortgageRepaymentBenefitBuilderForUI(mortgageRepaymentBenefit) {
  return (
    mortgageRepaymentBenefit && {
      isIndexed: !!mortgageRepaymentBenefit.Indexed,
      loading: parseInt(mortgageRepaymentBenefit.Loading, 10) || 0,
      coverAmount: parseInt(mortgageRepaymentBenefit.CoverAmount, 10) || 0,
      weekWaitPeriod:
        parseInt(mortgageRepaymentBenefit.WeekWaitPeriod, 10) || 0,
      benefitPeriod: parseInt(mortgageRepaymentBenefit.BenefitPeriod, 10) || 0,
    }
  );
}

export function mortgageRepaymentBenefitBuilderForAPI(
  mortgageRepaymentBenefit
) {
  return (
    mortgageRepaymentBenefit && {
      Indexed: !!mortgageRepaymentBenefit.isIndexed,
      Loading: parseInt(mortgageRepaymentBenefit.loading, 10) || 0,
      CoverAmount: parseInt(mortgageRepaymentBenefit.coverAmount, 10) || 0,
      WeekWaitPeriod:
        parseInt(mortgageRepaymentBenefit.weekWaitPeriod, 10) || 0,
      BenefitPeriod: parseInt(mortgageRepaymentBenefit.benefitPeriod, 10) || 0,
    }
  );
}

export function redundancyCoverBenefitBuilderForUI(redundancyCover) {
  return (
    redundancyCover && {
      isIndexed: !!redundancyCover.Indexed,
      hasMpCover: !!redundancyCover.HasMpCover,
      loading: parseInt(redundancyCover.Loading, 10) || 0,
      coverAmount: parseInt(redundancyCover.CoverAmount, 10) || 0,
      mrpCoverAmount: parseInt(redundancyCover.MRPCoverAmount, 10) || 0,
    }
  );
}

export function redundancyCoverBenefitBuilderForAPI(redundancyCover) {
  return (
    redundancyCover && {
      Indexed: !!redundancyCover.isIndexed,
      HasMpCover: !!redundancyCover.hasMpCover,
      Loading: parseInt(redundancyCover.loading, 10) || 0,
      CoverAmount: parseInt(redundancyCover.coverAmount, 10) || 0,
      MRPCoverAmount: parseInt(redundancyCover.mrpCoverAmount, 10) || 0,
    }
  );
}

export function waiverOfPremiumBuilderForUI(waiverOfPremium) {
  return (
    waiverOfPremium && {
      loading: parseInt(waiverOfPremium.Loading, 10) || 0,
      weekWaitPeriod: parseInt(waiverOfPremium.WeekWaitPeriod, 10) || 0,
      benefitPeriod: parseInt(waiverOfPremium.BenefitPeriod, 10) || 0,
    }
  );
}

export function waiverOfPremiumBuilderForAPI(waiverOfPremium) {
  return (
    waiverOfPremium && {
      Loading: parseInt(waiverOfPremium.loading, 10) || 0,
      WeekWaitPeriod: parseInt(waiverOfPremium.weekWaitPeriod, 10) || 0,
      BenefitPeriod: parseInt(waiverOfPremium.benefitPeriod, 10) || 0,
    }
  );
}

export function benefitListItemBuilderForUI(benefitListItem) {
  return (
    benefitListItem && {
      benefitId: benefitListItem.BenefitId,
      isSelected: benefitListItem.IsSelected,
      healthCover: healthCoverBenefitBuilderForUI(benefitListItem.HealthCover),
      lifeCover: lifeCoverBenefitBuilderForUI(benefitListItem.LifeCover),
      familyProtection: familyProtectionBenefitBuilderForUI(
        benefitListItem.FamilyProtection
      ),
      traumaCover: traumaCoverBenefitBuilderForUI(benefitListItem.TraumaCover),
      totalAndPermanentDisability: tpdBenefitBuilderForUI(
        benefitListItem.TotalAndPermanentDisability
      ),
      incomeProtection: incomeProtectionBenefitBuilderForUI(
        benefitListItem.IncomeProtection
      ),
      mortgageRepaymentCover: mortgageRepaymentBenefitBuilderForUI(
        benefitListItem.MortgageRepaymentCover
      ),
      redundancyCover: redundancyCoverBenefitBuilderForUI(
        benefitListItem.RedundancyCover
      ),
      waiverOfPremium: waiverOfPremiumBuilderForUI(
        benefitListItem.WaiverOfPremium
      ),
    }
  );
}

export function benefitListItemBuilderForAPI(benefit) {
  return (
    benefit && {
      BenefitId: benefit.benefitId,
      IsSelected: benefit.isSelected,
      HealthCover: healthCoverBenefitBuilderForAPI(benefit.healthCover),
      LifeCover: lifeCoverBenefitBuilderForAPI(benefit.lifeCover),
      FamilyProtection: familyProtectionBenefitBuilderForAPI(
        benefit.familyProtection
      ),
      TraumaCover: traumaCoverBenefitBuilderForAPI(benefit.traumaCover),
      TotalAndPermanentDisability: tpdBenefitBuilderForAPI(
        benefit.totalAndPermanentDisability
      ),
      IncomeProtection: incomeProtectionBenefitBuilderForAPI(
        benefit.incomeProtection
      ),
      MortgageRepaymentCover: mortgageRepaymentBenefitBuilderForAPI(
        benefit.mortgageRepaymentCover
      ),
      RedundancyCover: redundancyCoverBenefitBuilderForAPI(
        benefit.redundancyCover
      ),
      WaiverOfPremium: waiverOfPremiumBuilderForAPI(benefit.waiverOfPremium),
    }
  );
}

export function personBenefitListBuilderForUI(benefitList) {
  return (
    benefitList &&
    benefitList.map(benefit => {
      return benefitListItemBuilderForUI(benefit);
    })
  );
}

export function personBenefitListBuilderForAPI(benefits) {
  return (
    benefits &&
    benefits.map(benefit => {
      return benefitListItemBuilderForAPI(benefit);
    })
  );
}

export function peopleEntityItemBuilderForUI(peopleEntityItem) {
  return (
    peopleEntityItem && {
      clientId: parseInt(peopleEntityItem.ClientId, 10) || 0,
      firstName: peopleEntityItem.FirstName,
      lastName: peopleEntityItem.LastName,
      gender: parseInt(peopleEntityItem.Gender, 10) || null,
      dob: peopleEntityItem.DateOfBirth,
      age: parseInt(peopleEntityItem.Age, 10) || 0,
      isSmoker: !!peopleEntityItem.Smoker,
      occupation: parseInt(peopleEntityItem.Occupation, 10) || 0,
      employmentStatus: parseInt(peopleEntityItem.EmployedStatus, 10) || 0,
      isChild: !!peopleEntityItem.IsChild,
      benefits: personBenefitListBuilderForUI(peopleEntityItem.BenefitList),
    }
  );
}

export function peopleEntityItemBuilderForAPI(peopleEntityItem) {
  return (
    peopleEntityItem && {
      ClientId: parseInt(peopleEntityItem.clientId, 10) || 0,
      FirstName: peopleEntityItem.firstName,
      LastName: peopleEntityItem.lastName,
      Gender: parseInt(peopleEntityItem.gender, 10) || null,
      DateOfBirth: peopleEntityItem.dob,
      Age: parseInt(peopleEntityItem.age, 10) || 0,
      Smoker: !!peopleEntityItem.isSmoker,
      Occupation: parseInt(peopleEntityItem.occupation, 10) || 0,
      EmployedStatus: parseInt(peopleEntityItem.employmentStatus, 10) || 0,
      IsChild: !!peopleEntityItem.isChild,
      BenefitList: personBenefitListBuilderForAPI(peopleEntityItem.benefits),
    }
  );
}

export function quoteDataPeopleEntityBuilderForUI(peopleEntity) {
  return (
    peopleEntity &&
    peopleEntity.map(peopleEntityItem => {
      return peopleEntityItemBuilderForUI(peopleEntityItem);
    })
  );
}

export function quoteDataPeopleEntityBuilderForAPI(peopleEntity) {
  return (
    peopleEntity &&
    peopleEntity.map(person => {
      return peopleEntityItemBuilderForAPI(person);
    })
  );
}

export function quoteDataBuilderForUI(quoteData) {
  return (
    quoteData && {
      quoteId: parseInt(quoteData.quoteId, 10) || 0,
      familyId: parseInt(quoteData.FamilyID, 10) || 0,
      familyFullName: quoteData.FamilyFullName,
      paymentFrequency: parseInt(quoteData.Frequency, 10) || 0,
      numberOfChildren: parseInt(quoteData.NumberOfChildren, 10) || 0,
      numberOfClients: parseInt(quoteData.NumberOfClients, 10) || 0,
      insuredPersons: quoteDataPeopleEntityBuilderForUI(quoteData.PeopleEntity),
    }
  );
}

export function quoteDataBuilderForAPI(quoteData) {
  return (
    quoteData && {
      quoteId: parseInt(quoteData.quoteId, 10) || 0,
      FamilyID: parseInt(quoteData.familyId, 10) || 0,
      FamilyFullName: quoteData.familyFullName,
      Frequency: parseInt(quoteData.paymentFrequency, 10) || 0,
      NumberOfChildren: parseInt(quoteData.numberOfChildren, 10) || 0,
      NumberOfClients: parseInt(quoteData.numberOfClients, 10) || 0,
      PeopleEntity: quoteDataPeopleEntityBuilderForAPI(
        quoteData.insuredPersons
      ),
    }
  );
}

export function profilerNoteTemplateBuilderForMyCRM(profilerNote) {
  return {
    NoteID: profilerNote.id,
    BenefitTypeID: profilerNote.benefitTypeId,
    BenefitTypeName: profilerNote.benefitTypeName,
    InsuranceType: profilerNote.insuranceType,
    Title: profilerNote.name,
    Note: profilerNote.content,
  };
}

export function benefitProfilerNoteTemplatesBuilderForUI(
  benefitProfilerNoteTemplates
) {
  return {
    benefitTypeId: benefitProfilerNoteTemplates.BenefitTypeID,
    benefitTypeName: benefitProfilerNoteTemplates.BenefitTypeName,
  };
}

export function profilerNoteTemplateBuilderForUI(profilerNote) {
  return {
    id: profilerNote.NoteID,
    benefitTypeId: profilerNote.BenefitTypeID,
    benefitTypeName: profilerNote.BenefitTypeName,
    insuranceType: profilerNote.InsuranceType,
    name: profilerNote.Title,
    content: profilerNote.Note,
    orderBy: profilerNote.OrderBy,
    readMore: profilerNote.ReadMore,
  };
}

export function profilerSendReportMailBuilderForMyCRM(email) {
  return {
    ProfilerID: email.profilerId,
    SendToCurrentUser: email.sendToCurrentUser,
    Subject: email.subject,
    Content: email.body,
    Recipients: email.recipients,
    FamilyID: email.familyId,
  };
}

export function recipientBuilderForMyCRM(recipient) {
  return {
    Name: recipient.name,
    EmailAddress: recipient.emailAddress,
  };
}

export function allocatedAdviserBuilderForUI(adviserData) {
  return {
    brokerId: adviserData.BrokerId,
    fullName: adviserData.FullName,
    reportingStateId: adviserData.ReportingStateId,
    roleBoolValue: adviserData.RoleBoolValue,
    sourceFamilyId: adviserData.SourceFamilyID,
  };
}

export function policyStatusListBuilderForUI(list) {
  return {
    policyStatusName: list.PolicyStatusName,
    policyNumber: list.PolicyStatusName,
  };
}

export function providerListBuilderForUI(list) {
  return {
    providerId: list.ProviderID,
    providerName: list.ProviderName,
  };
}

export function categoryListBuilderForUI(list) {
  return {
    catergoryTypeId: list.BenefitTypeID,
    catergoryTypeName: list.BenefitTypeName,
  };
}

export function summaryOfCoverBenefitsForUI(benefit) {
  return {
    id: benefit.BenefitID,
    name: benefit.BenefitName,
    details: benefit.BenefitDetails,
    premium: benefit.BenefitPremium,
  };
}

export function summaryOfCoverClientDataForUI(client) {
  return {
    id: client.ClientID,
    firstName: client.FirstName,
    lastName: client.LastName,
    ballParkPremium: client.BallParkPremium,
    benefits:
      client.Benefits &&
      client.Benefits.map(benefit => {
        return summaryOfCoverBenefitsForUI(benefit);
      }),
  };
}

export function summaryOfCoverBuilderForUI(summary) {
  return {
    id: summary.ProfilerID,
    totalPremium: summary.TotalPremium,
    clients:
      summary.Clients &&
      summary.Clients.map(client => {
        return summaryOfCoverClientDataForUI(client);
      }),
  };
}
