export function existingReferrerDetailsBuilderForUI(field) {
  return {
    familyId: field.FamilyId,
    firstName: field.FirstName,
    middleName: field.MiddleName,
    lastName: field.LastName,
    contact: field.Contact,
    email: field.Email,
    mobilePhone: field.MobilePhone,
  };
}
