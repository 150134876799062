import angular from 'angular';

angular
  .module('app')
  .controller('ServiceabilityModalCtrl', function ServiceabilityModalCtrl(
    $scope,
    $uibModalInstance,
    lendingScenarioService,
    saveSearchLendersResultToLendingScenario,
    backToLendingScenario,
    lendingScenarioId,
    title,
    $state,
    isEdit,
    toaster
  ) {
    $scope.saveSearchLendersResultToLendingScenario = saveSearchLendersResultToLendingScenario;
    $scope.backToLendingScenario = backToLendingScenario;
    $scope.lendingScenarioId = lendingScenarioId;
    $scope.Title = title;
    $scope.isEdit = isEdit;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    const saveService = () => {
      lendingScenarioService
        .serviceabilityLinkSet({
          lendingScenarioId: $scope.lendingScenarioId,
          brokerEventId: $state.params.brokerEventId,
        })
        .then(() => {
          toaster.pop(
            'success',
            'Success',
            'Products are added to your Lending Scenario. Redirecting lending scenario.'
          );
          $scope.saveSearchLendersResultToLendingScenario();
          $scope.backToLendingScenario();
        });
    };

    $scope.save = function() {
      lendingScenarioService
        .serviceabilityNameSet({
          brokerEventId: $state.params.brokerEventId,
          name: $scope.Title,
        })
        .then(() => {
          if ($scope.isEdit) {
            lendingScenarioService
              .serviceabilityNameSet({
                brokerEventId: $state.params.brokerEventId,
                name: $scope.Title,
              })
              .then(() => {
                saveService();
              });
          } else {
            saveService();
          }
        });
    };
  });
