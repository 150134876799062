import template from './pipelineKanban.html';
import controller from './pipelineKanbanCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    parentToChildNotificationRegistration: '&',
    isSmallDevice: '<',
    settings: '<',
    showLoader: '<',
    onToggleShowLoader: '&',
    isCompact: '<',
    onMenuClick: '&',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    onViewBorrower: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onClickPipelineItem: '&',
    onDeletePipelineItem: '&',
    isCorporateUser: '<',
    onChangeSettings: '&',
    onChangeProbability: '&',
    onChangeSubStatus: '&',
    onLeadAddNote: '&',
    onRefreshStats: '&',
    onOpenWorkbench: '&',
  },
};
