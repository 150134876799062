import template from './leadCard.html';

export default {
  template,
  bindings: {
    isSmallDevice: '<',
    card: '<',
    isSingle: '<',
    isShowTimeline: '<',
    timelineStatusList: '<',
    onChangeCardStatus: '&',
    isCorporateUser: '<',
    labelsSettings: '<',
    onLabelToggle: '&',
    onRefreshLabelsSettings: '&',
    isShrink: '<',
    isCompact: '<',
    onMenuClick: '&',
    onViewBorrower: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onCardClick: '&',
    onToggleIsShrink: '&',
    onDeletePipelineItem: '&',
    probabilityList: '<',
    onChangeProbability: '&',
    onChangeSubStatus: '&',
    onLeadAddNote: '&',
    conversionOptions: '<',
    onConvertCard: '&',
  },
};
