import { COMPANY_TYPES } from 'Common/constants/partyTypes';

class entityContactDetailsCtrl {
  $onInit() {
    this.COMPANY_TYPES = COMPANY_TYPES;
    this.partyType = parseInt(this.partyType, 10);
  }
}

export default entityContactDetailsCtrl;
