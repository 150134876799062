import angular from 'angular';
import swal from 'sweetalert';
import FastClick from 'fastclick';
import { MYCRM_GLOBAL } from 'Common/constants/mycrmGlobal';
import { getConfigData } from 'Common/utilities/config';

const addScriptOnDOM = (src, doc) => {
  const s = doc.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = src;
  const x = doc.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);
};

const addUserSnap = (apiKey, doc) => {
  const userSnapSrc = `//api.usersnap.com/load/${apiKey}.js`;
  addScriptOnDOM(userSnapSrc, doc);
};

const googleMapScript = (googleApiKey, doc) => {
  const baseGoogleMapSrc = `//maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=initMap`;
  window.initMap = () => true; // eslint-disable-line
  addScriptOnDOM(baseGoogleMapSrc, doc);
};

function main(
  $rootScope,
  $state,
  $stateParams,
  $timeout,
  $window,
  $document,
  Idle,
  Keepalive,
  configService,
  authService,
  sessionService,
  apiRefactorService,
  currentUserService
) {
  sessionService.sessionTime = MYCRM_GLOBAL.SESSION_TIME;
  sessionService.autoLogout = MYCRM_GLOBAL.LOGOUT_TIME;

  $rootScope.$on('IdleStart', () => {
    // the user appears to have gone idle
    sessionService.sessionUpdateTimer(true);
  });

  $rootScope.$on('IdleEnd', () => {
    // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
    sessionService.keepAlive();
  });

  $rootScope.$on('Keepalive', () => {
    // do something to keep the user's session alive
    sessionService.keepAlive();
  });
  // Starts user watcher
  if (configService.token) {
    Idle.watch();
    // Validate if the Session is still valid
    // -----------------------------------
    // Run token check API
    authService.checkJwt(configService.token, 'mycrm').then(
      () => {},
      err => {
        if (err.status === 401) {
          swal(
            'Session Expired',
            'Sorry but we got confused! You will be redirected automatically to the login page to verify your identity.',
            'error'
          );
          // sessionService.logoutUser(true);
          $timeout(() => {
            $window.location.assign(configService.login);
          }, 3000);
        }
      }
    );
  } else {
    sessionService.logoutUser();
  }

  // Attach Fastclick for eliminating the 300ms delay between a physical tap and the firing of a click event on mobile browsers
  try {
    FastClick.attach($document.body);
  } catch (e) {
    // Continue regardless of error
  }

  // Set some reference to access them from any scope
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;

  // GLOBAL APP SCOPE
  // set below basic information
  $rootScope.app = {
    name: 'myCRM', // name of your project
    author: 'NZFSG', // author's name or company name
    description: '', // brief description
    version: '2.0', // current version
    year: new Date().getFullYear(), // automatic current year (for copyright information)
    isMobile: (() => {
      // true if the browser is a mobile device
      let check = false;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          $window.navigator.userAgent
        )
      ) {
        check = true;
      }
      return check;
    })(),
    defaultLayout: {
      isNavbarFixed: true, // true if you want to initialize the template with fixed header
      isSidebarFixed: true, // true if you want to initialize the template with fixed sidebar
      isSidebarClosed: false, // true if you want to initialize the template with closed sidebar
      isFooterFixed: false, // true if you want to initialize the template with fixed footer
      isBoxedPage: false, // true if you want to initialize the template with boxed layout
      theme: 'main', // indicate the theme chosen for your project
      logo: 'assets/images/defaultBrandingLogo/branding.svg', // relative path of the project logo
      logoCollapsed: 'assets/images/square-logo.png', // relative path of the collapsed logo
    },
    layout: '',
  };

  // update author if loanmarket
  if (configService.isLoanMarket) {
    $rootScope.app.author = 'Loan Market';
  }
  $rootScope.app.layout = angular.copy($rootScope.app.defaultLayout);

  const config = getConfigData();
  // Usersnap Plugin
  if (currentUserService.isCorporate) {
    // If Access Type is Corporate
    addUserSnap(config.userSnapKeyCorporate, $document[0]);
  } else {
    addUserSnap(config.userSnapKey, $document[0]);
  }
  googleMapScript(config.googleApiKey, $document[0]);
}

main.$inject = [
  '$rootScope',
  '$state',
  '$stateParams',
  '$timeout',
  '$window',
  '$document',
  'Idle',
  'Keepalive',
  'configService',
  'authService',
  'sessionService',
  'apiRefactorService',
  'currentUserService',
];

export default main;
