import angular from 'angular';
import uiService from 'Assets/js/services/uiService';
import icons from '../icons';
import LayoutCtrl from './LayoutCtrl';
import sidebar from './sidebar';
import navigation from './navigation';
import navigationMobile from './navigationMobile';
import topNavbar from './topNavbar';
import './layout.scss';

export default angular
  .module('components.layout', [uiService, icons])
  .controller('LayoutCtrl', LayoutCtrl)
  .component('sidebar', sidebar)
  .component('navigation', navigation)
  .component('navigationMobile', navigationMobile)
  .component('topNavbar', topNavbar).name;
