import angular from 'angular';

angular
  .module('app')
  .controller(
    'OpenModalForDemoVideoModalCtrl',
    function OpenModalForDemoVideoModalCtrl(
      $scope,
      $uibModalInstance,
      $uibModal,
      DEMO_VIDEO
    ) {
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.headerName = DEMO_VIDEO.DASHBOARD.Header_Name;
      $scope.videoList = DEMO_VIDEO.DASHBOARD.Video_List;
      $scope.videoId = DEMO_VIDEO.DASHBOARD.Video_List[0].Url;
      $scope.isActive = id => {
        const active = id === $scope.videoId;
        return active;
      };
      // For Change Video URL
      $scope.changeVideoUrl = url => {
        $scope.videoId = url;
        const $url = `https://player.vimeo.com/video/${
          $scope.videoId
        }?player_id=video1`;
        angular.element('#video-player iframe').attr('src', $url);
      };
    }
  );
