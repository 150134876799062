import angular from 'angular';
import $ from 'jquery';

angular
  .module('app')
  .directive('stickyTableHeader', function stickyTableHeader($window) {
    return {
      link(scope, elem) {
        // Set table th width
        function setTableWidth() {
          $('.sticky-div table tbody tr:nth-child(1) td').each(function(i) {
            const thWidth = $(this).outerWidth();
            $('.table')
              .find(`th:eq(${i})`)
              .outerWidth(thWidth);
            $(this).width(thWidth);
          });
        }

        // For Task Table Header Freeze
        let first = 0;
        function taskTableHeaderFreeze() {
          first = 0;
          const windowTop = $(window).scrollTop();
          const taskTable = elem;
          if (typeof taskTable !== 'undefined' && taskTable.length > 0) {
            if (windowTop > 130) {
              $('.sticky-div').addClass('sticky-table-fixed');
              $('.sticky-div table').removeClass('tasks-table');
              taskTable.height($('.sticky-div thead').outerHeight());
              if (first === 0) {
                setTableWidth();
                first = 1;
              }
            } else {
              $('.sticky-div').removeClass('sticky-table-fixed');
              $('.sticky-div table').addClass('tasks-table');
              taskTable.height(0);
            }
          }
        }

        $(() => {
          $window.onscroll = function() {
            taskTableHeaderFreeze();
          };
          $(window).resize(() => {
            setTableWidth();
          });
        });
      },
    };
  });
