import angular from 'angular';

angular
  .module('app')
  .directive('bigWidgetSettings', function bigWidgetSettings(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        myParam: '=',
        indexInArray: '=',
      },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/bigWidgetSettings.html',

      link($scope) {
        $scope.DisplayAsDropDownList = [];

        $scope.displayAs = 'Table';

        function getDisplayAs(fromInit) {
          overviewDashboardService
            .WidgetTypeGet($scope.myParam.WidgetName)
            .then(
              response => {
                $scope.DisplayAsDropDownList = response.data.map(item => {
                  if (item.WidgetType === 'Table View') {
                    item.Category = 'Big Widget';
                  } else if (item.WidgetType === 'Graph View') {
                    item.Category = 'Graphic Widget';
                  } else if (item.WidgetType === 'Calendar View') {
                    item.Category = 'Big Widget';
                  } else {
                    item.Category = 'Big Widget';
                  }

                  return item;
                });

                if (fromInit) {
                  $scope.DisplayAsDropDowns = $scope.DisplayAsDropDownList.filter(
                    item =>
                      parseInt(item.Category, 10) ===
                      parseInt($scope.myParam.Category, 10)
                  )[0];
                } else {
                  $scope.DisplayAsDropDowns = $scope.DisplayAsDropDownList[0];
                }
              },
              () => {}
            );
        }

        function init() {
          getDisplayAs(true);
        }
        $scope.updateWidgetType = function(displayAObj) {
          $scope.myParam.Category = displayAObj.Category;
        };

        $scope.updateWidgetName = function(widgetId) {
          $scope.myParam.WidgetName = $scope.myParam.dropDownOption.filter(
            item => parseInt(item.WidgetId, 10) === parseInt(widgetId, 10)
          )[0].WidgetName;
          $scope.myParam.DisplayName =
            parseInt(widgetId, 10) === 0 ? '' : $scope.myParam.WidgetName;
          getDisplayAs(false);
          $scope.myParam.Widget_UserID = 0;
        };

        init();
      },
    };
  });
