import angular from 'angular';
import _ from 'lodash';
import { DEFAULT_VALUES } from 'Common/constants/securityValues';
import { assetFinanceIgnore } from 'Common/utilities/security';
import { parseIntValue } from 'Common/utilities/parse';

angular
  .module('app')
  .controller('SecurityStructureCtrl', function SecurityStructureCtrl(
    $scope,
    contactService,
    imageDataURI,
    SweetAlert,
    $timeout,
    optionsService,
    loanScenarioService,
    dashboardService,
    toaster,
    $rootScope,
    contactSharedData,
    generalService,
    uiService,
    loanAppSharedData,
    selectOptionsService,
    modelDataService
  ) {
    $scope.uiService = uiService;
    $scope.assetFinanceIgnore = assetFinanceIgnore;
    $scope.zoningChanged = zoningId => {
      optionsService.getPropertyType(zoningId).then(response => {
        $scope.PropertyTypeList = response.data;
        $scope.PropertyTypeList.unshift({
          AddressPropertyType: 'Please select',
          AddressPropertyTypeID: '',
        });
        if (
          zoningId === DEFAULT_VALUES.RESIDENTIAL_ZONINGID &&
          uiService.isCountry('New Zealand') &&
          !$scope.securityStructure.PropertyDetails.PropertyTypeId
        ) {
          $scope.securityStructure.PropertyDetails.PropertyTypeId =
            DEFAULT_VALUES.STDRESIDENTIAL_PROPERTYTYPE;
        } else {
          $scope.securityStructure.PropertyDetails.PropertyTypeId = '';
        }
      });
    };
    if (contactSharedData.isEditSecurity) {
      $scope.zoningChanged(contactSharedData.securityZoningId);
    }
    $scope.securityStructure = {
      LoanApplicationId: 0,
      LoanSecurityId: 0,
      SecurityInfoId: 0,
      IsPrimary: true,
      SecurityTypeId: null,
      SecurityType: 'string',
      Ownership: [
        {
          Percentage: 0,
          BorrowerID: 0,
          FirstName: 'string',
          LastName: 'string',
          IsInclude: true,
          IsEntity: true,
        },
      ],
      TransactionId: 0,
      Transaction: 'string',
      IsRefinance: true,
      PropertyDetails: {
        LoanSecurityId: 0,
        StatusId: 0,
        Status: 'string',
        PropertyPrimaryPurposeId: 0,
        PropertyPrimaryPurpose: 'string',
        HoldingId: 0,
        Holding: 'string',
        ZoningId: 0,
        Zoning: 'string',
        PropertyTypeId: 0,
        PropertyType: 'string',
        CurrentValue: 0,
        LandValue: 0,
        EstimatedPrice: 0,
        EstimatedBasesId: 0,
        EstimatedBases: 'string',
        ContratctPrice: 0,
        ContractDate: '',
        LicensedRealEstateAgentContract: true,
        IsPreApproved: true,
        AddressId: 0,
        TitleTypeId: 0,
        TitleType: 'string',
        TitleId: 0,
        Title: 'string',
        PropertyState: 'string',
        IdentifierList: [
          {
            Security_TitleIdentityId: 0,
            IdentityTypeId: 0,
            Value: '1',
          },
        ],
        ContactForAccess: {
          ContactForAccess: 0,
          CompanyName: 'string',
          ContactTitle: 'string',
          ContactFirstName: 'string',
          ContactLastName: 'string',
          PhoneAreaCode: 'string',
          PhoneNumber: 'string',
          FaxAreaCode: 'string',
          FaxNumber: 'string',
          ContactTypeId: 0,
          ContactType: 'string',
        },
      },
      FundingCalParametersId: 0,
      MortgageList: [
        {
          LoanApplicationId: 0,
          LoanSecurityInfoId: 0,
          SecurityMortgageId: 0,
          MortgagePriorityId: 0,
          MortgagePriority: 'string',
          Ownership: [
            {
              Percentage: 0,
              BorrowerID: 0,
              FirstName: 'string',
              LastName: 'string',
              IsInclude: true,
              IsEntity: true,
            },
          ],
          CreditorId: 0,
          CreditorName: 'string',
          Limit: 0,
          AccountName: 'string',
          CurrentBalance: 0,
          AccontNumber: 'string',
          BSB: 'string',
          InterestTaxDeductible: true,
        },
      ],
    };

    $scope.security = {};

    $scope.clientListArray = [];
    $scope.setDefaultValues = () => {
      $scope.securityStructure.PropertyDetails.StatusId =
        DEFAULT_VALUES.EXISTING_STATUSID;
      $scope.securityStructure.PropertyDetails.PropertyPrimaryPurposeId =
        DEFAULT_VALUES.OWNEROCCUPIED_PRIMARYPURPOSE;
      $scope.securityStructure.PropertyDetails.HoldingId =
        loanAppSharedData.borrowerList.length === 1
          ? DEFAULT_VALUES.SOLE_HOLDINGID
          : DEFAULT_VALUES.JOINT_HOLDINGID;
      $scope.securityStructure.PropertyDetails.ZoningId =
        DEFAULT_VALUES.RESIDENTIAL_ZONINGID;
      $scope.securityStructure.PropertyDetails.PropertyTypeId = $scope.zoningChanged(
        DEFAULT_VALUES.RESIDENTIAL_ZONINGID
      );
    };

    optionsService.getSecurityType().then(response => {
      _.forEach(response.data, value => {
        if (value.Value) {
          value.Value = parseInt(value.Value, 10);
        }
      });

      $scope.securityTypeOptions = response.data;
    });

    optionsService.getStatusType().then(response => {
      $scope.propertyDevelopmentStatusOptions = parseIntValue(response.data);
    });

    if ($scope.securityStructureAction === 'view') {
      $scope.isSecurityStructureEditable = false;
    } else {
      $scope.isSecurityStructureEditable = true;
    }
    $scope.ownedByOptions = [];
    $scope.currentOwnershipTag = [];

    $scope.addSecurityValue = function() {
      $scope.securityStructure.Valuation.push({
        PropertyValuationId: '0',
        Type: '',
        Value: 0,
        IsDefault: false,
      });
    };
    $scope.updateValuationCheck = function(valIndex) {
      angular.forEach($scope.securityStructure.Valuation, (value, index) => {
        value.IsDefault = valIndex === index;
      });
    };

    $scope.securityAutoAddress = function() {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService
          .placeSearch($scope.securityStructure.Address.formatted_address)
          .then(respond => {
            $scope.securityAutoAddressDetails = respond.data;
          });
      }, 500);
    };

    $scope.closeSecurityAutoAddress = function() {
      $scope.securityAutoAddressDetails = [];
    };

    $scope.selectSecurityAddress = function(address) {
      $scope.securityStructure.Address.formatted_address = address;
      $scope.securityAutoAddressDetails = [];
    };

    $scope.removeCurrentOwnershipTag = function(tag) {
      let fullName = '';
      let firstName = '';
      let lastName = '';
      let client = '';
      if (tag.text.indexOf(' (') !== -1) {
        fullName = tag.text.split(' (');
        firstName = fullName[0];
        lastName = fullName[1].replace(')', '');
        client = `${firstName}///${lastName}`;
      } else {
        firstName = tag.text;
      }

      const clientIndex = $scope.clientListArray.indexOf(client);

      if (clientIndex !== -1) {
        $scope.clientListArray.splice(clientIndex, 1);
      }
      angular.forEach($scope.securityStructure.Borrowers, (value, index) => {
        if (value.FirstName === firstName) {
          $scope.securityStructure.Borrowers.splice(index, 1);
        }
      });
    };

    $scope.saveLoanStructureData = function() {
      $scope.updateOwnedBy();

      contactService.securitySet($scope.securityStructure).then(() => {
        if ($scope.securityStructureAction === 'add') {
          toaster.pop(
            'success',
            'Added',
            'Security structure successfully added'
          );
        } else if ($scope.securityStructureAction === 'edit') {
          toaster.pop(
            'success',
            'Updated',
            'Security structure successfully updated'
          );
        }
        $scope.securityDataField();
      });
    };

    $scope.runDefaultBorrowers = function() {
      angular.forEach($scope.securityStructure.Borrowers, value => {
        const firstName = value.FirstName !== '' ? value.FirstName : '';
        const lastName = value.LastName !== '' ? `///${value.LastName}` : '';
        const client = firstName + lastName;
        if ($scope.clientListArray.indexOf(client) === -1 && value.IsInclude) {
          $scope.clientListArray.push(client);
        }
      });
    };

    // default use for adding new security structure

    /** ********************** team 4 ************************** */
    if (!$scope.PropertyTypeList) {
      $scope.PropertyTypeList = [
        {
          AddressPropertyTypeID: 0,
          AddressPropertyType: 'Please Select Zoning First',
        },
      ];
    }

    optionsService.getTransactionType().then(response => {
      angular.forEach(response.data, value => {
        value.Value = parseInt(value.Value, 10);
      });

      $scope.TransactionTypeList = response.data;
    });

    optionsService.getPrimaryPurposeType().then(response => {
      angular.forEach(response.data, value => {
        value.Value = parseInt(value.Value, 10);
      });

      $scope.PropertyPrimaryPurposeList = response.data;
    });

    optionsService.getHoldingType().then(response => {
      angular.forEach(response.data, value => {
        value.Value = parseInt(value.Value, 10);
      });

      $scope.HoldingList = response.data;
    });

    optionsService.getZoningType().then(response => {
      angular.forEach(response.data, value => {
        value.Value = parseInt(value.Value, 10);
      });

      $scope.ZoningList = response.data;
    });

    optionsService.AssetValueBasisGet().then(response => {
      angular.forEach(response.data, value => {
        value.Value = parseInt(value.Value, 10);
      });

      $scope.assetValueBasisList = response.data;
    });

    // Open Date Popup

    $scope.datePopUp = {
      ContractDate: false,
    };

    $scope.openDate = function(dateField) {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.format = 'dd MMM yyyy';
    $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    // address
    $scope.security.getFormattedAddress = function() {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        generalService.placeSearch($scope.security.formatted_address).then(
          securityStructureResp => {
            $scope.security.addressSuggestions = securityStructureResp.data;
            $scope.security.showSuggestionModal = true;
          },
          () => {
            toaster.pop('error', 'Warning', 'Something went wrong');
          }
        );
      }, 500);
    };

    $scope.security.closeAutoHomeAddress = function() {
      $scope.security.addressSuggestions = [];
    };

    $scope.security.pickAddressSuggestion = function(homeAddress) {
      if (!homeAddress) return;
      const { formatted_address: formatAddress } = homeAddress;
      $scope.security.Address = homeAddress;
      $scope.security.formatted_address = formatAddress || '';

      // Address to post
      $scope.security.ContactAddress = homeAddress;

      $scope.security.closeAutoHomeAddress();
      $scope.security.showSuggestionModal = false;
      $scope.security.saveAddress();
    };

    $scope.security.saveAddress = function() {
      if ($scope.security.Address && !$scope.security.Address.AddressID) {
        $scope.security.ContactAddress = {
          formatted_address: $scope.security.formatted_address,
          geoCoded: false,
        };
      }

      const securityStructureAddressModel = {
        ...modelDataService.addressMakeModelData(),
        ...$scope.security,
      };
      return dashboardService
        .addressDetailsSet(securityStructureAddressModel)
        .then(response => {
          $scope.securityStructure.PropertyDetails.AddressId = response.data;
        });
    };
    /*--------------*/

    $scope.ownershipList = {};
    const lastFilter = [];

    contactService
      .borrowersDetailsGet($scope.params.familyId, $scope.params.loanId)
      .then(response => {
        const temp = {
          BorrowerID: -1,
          FirstName: 'All Owner',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilter.indexOf('All') > -1) {
              value.ticked = value.BorrowerID === -1;
            } else if (lastFilter.indexOf(response.data[x].FirstName) > -1) {
              value.ticked = true;
            }
            tmp.push(value);
          });

        $scope.ownershipList = tmp;
      });

    // Filter Select all
    $scope.filterRefreshData = [
      {
        ownership: [],
      },
    ];

    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected:
        "<div class='buttonLabel padding-left-10'>All Owner</div>", // default-label is deprecated and replaced with this.
    };

    $scope.onSelectAll = function() {
      if (
        $scope.filterRefreshData[0] &&
        $scope.filterRefreshData[0].ownership
      ) {
        $scope.filterRefreshData[0].ownership = [];
      }
      for (let i = 0; i < $scope.ownershipList.length; i++) {
        if ($scope.ownershipList[i].BorrowerID > -1) {
          $scope.ownershipList[i].ticked = false;
        } else {
          $scope.ownershipList[i].ticked = true;
        }
      }
    };

    function isOtherTicked(data) {
      let bool = false;
      if (
        $scope.filterRefreshData[0] &&
        $scope.filterRefreshData[0].ownership
      ) {
        $scope.filterRefreshData[0].ownership = [];
        data.forEach(val => {
          if (val.BorrowerID > -1 && val.ticked) {
            bool = true;
            $scope.filterRefreshData[0].ownership.push(val.BorrowerID);
          }
        });
      }

      return bool;
    }

    $scope.ownerListSelected = [];
    $scope.onItemSelect = function(data) {
      if (data.BorrowerID === -1) {
        for (let i = 0; i < $scope.ownershipList.length; i++) {
          if ($scope.ownershipList[i].BorrowerID > -1) {
            $scope.ownershipList[i].ticked = false;
          } else {
            $scope.ownershipList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.ownershipList.length; i++) {
          if (
            $scope.ownershipList[i].BorrowerID === -1 &&
            !isOtherTicked($scope.ownershipList)
          ) {
            $scope.ownershipList[i].ticked = true;
            $scope.ownerListSelected.push($scope.ownershipList[i]);
          } else if ($scope.ownershipList[i].BorrowerID === -1) {
            $scope.ownershipList[i].ticked = false;
          }
        }
      }

      $scope.securityStructure.Ownership = $scope.ownerListSelected;
    };

    $rootScope.$on('initializeSecurityStructure', () => {
      /* this method is just to call the securityStructurectrl file */
    });

    $rootScope.temporaryAddress = {};

    $scope.$watch(
      () => {
        return contactSharedData.lendingSharedData.secCtrlEditSecurityStructure;
      },
      () => {
        $scope.securityStructure = angular.copy(
          contactSharedData.lendingSharedData.secCtrlEditSecurityStructure
        );

        if (!_.isUndefined($scope.securityStructure.TransactionId)) {
          $scope.securityStructure.TransactionId = String(
            $scope.securityStructure.TransactionId
          );
        }

        if (!_.isUndefined($scope.securityStructure.PropertyDetails)) {
          if (
            !_.isUndefined(
              $scope.securityStructure.PropertyDetails.PropertyPrimaryPurposeId
            )
          ) {
            $scope.securityStructure.PropertyDetails.PropertyPrimaryPurposeId = String(
              $scope.securityStructure.PropertyDetails.PropertyPrimaryPurposeId
            );
          }

          if (
            !_.isUndefined($scope.securityStructure.PropertyDetails.HoldingId)
          ) {
            $scope.securityStructure.PropertyDetails.HoldingId = String(
              $scope.securityStructure.PropertyDetails.HoldingId
            );
          }

          if (
            !_.isUndefined($scope.securityStructure.PropertyDetails.ZoningId)
          ) {
            $scope.securityStructure.PropertyDetails.ZoningId = String(
              $scope.securityStructure.PropertyDetails.ZoningId
            );
          }

          if (
            !_.isUndefined(
              $scope.securityStructure.PropertyDetails.EstimatedBasesId
            )
          ) {
            $scope.securityStructure.PropertyDetails.EstimatedBasesId = String(
              $scope.securityStructure.PropertyDetails.EstimatedBasesId
            );
          }

          if ($scope.securityStructure.PropertyDetails.AddressId) {
            /* this is to assign address when edit part in security structure */
            dashboardService
              .addressDetailsGet(
                $scope.securityStructure.PropertyDetails.AddressId
              )
              .then(response => {
                $rootScope.temporaryAddress = response.data;
                if (response.data) {
                  $scope.security.formatted_address =
                    response.data.formatted_address || '';
                }
              });
          }
        }

        if ($scope.securityStructure.SecurityInfoId) {
          loanScenarioService
            .contactLoanSecurityInfoGet(
              $scope.params.loanId,
              $scope.securityStructure.SecurityInfoId
            )
            .then(response => {
              const { data: securityResponse } = response;
              if (!securityResponse || !securityResponse.PropertyDetails)
                return;
              const {
                ContractDate: contractDate,
              } = securityResponse.PropertyDetails;
              const formatContractDate = contractDate
                ? new Date(contractDate)
                : '';
              securityResponse.PropertyDetails = {
                ...securityResponse.PropertyDetails,
                ContractDate: formatContractDate,
              };
              $rootScope.TempSecurityStructure = securityResponse;
              const { FundingCalParametersId } = securityResponse;
              $scope.securityStructure.FundingCalParametersId = FundingCalParametersId;

              if (!$scope.ownerListSelected.length) {
                $rootScope.TempSecurityStructure.Ownership =
                  $scope.ownershipList;
              }
            });
        }
      }
    );

    $rootScope.TempSecurityStructure = $scope.securityStructure;

    $scope.$watch(
      () => {
        return $rootScope.TempSecurityStructure;
      },
      () => {
        $scope.securityStructure = $rootScope.TempSecurityStructure;
        $scope.security.Address = $rootScope.temporaryAddress;
      },
      true
    );

    /** **********************original************************** */
    if (typeof $scope.securityId === 'undefined') {
      $scope.securityId = 0;
    }

    $scope.updateOwnedBy = function() {
      $scope.securityStructure.Borrowers = [];
      $scope.currentOwnershipTag = [];
      angular.forEach($scope.securityClientsListOptions, value => {
        const firstName = value.FirstName !== '' ? value.FirstName : '';
        const lastName = value.LastName !== '' ? `///${value.LastName}` : '';
        const client = firstName + lastName;
        if ($scope.clientListArray.indexOf(client) !== -1) {
          value.IsInclude = true;
          $scope.securityStructure.Borrowers.push(value);
          if (value.LastName !== '') {
            $scope.currentOwnershipTag.push({
              text: `${value.FirstName} (${value.LastName})`,
            });
          } else {
            $scope.currentOwnershipTag.push({ text: value.FirstName });
          }
        }
      });
    };

    $scope.deleteValuation = function(index) {
      $scope.securityStructure.Valuation.splice(index, 1);
    };

    $scope.updateSecurityDescription = function() {
      if ($scope.Description === '') {
        $scope.Description = `${$scope.securityStructure.SecurityType}at${
          $scope.securityStructure.Address.formatted_address
        }`;
      }
    };

    $scope.cancel = function() {
      $rootScope.$emit('cancelAddSecurity', false);
    };

    const saveSecurityInfo = () => {
      $scope.securityStructure.LoanApplicationId = $scope.params.loanId;
      if (!_.isUndefined($scope.securityStructure.Ownership[0])) {
        if (
          $scope.securityStructure.Ownership[0].BorrowerID === -1 ||
          $scope.securityStructure.Ownership[0].BorrowerID === 0
        ) {
          $scope.securityStructure.Ownership = $scope.ownershipList;
        }
      } else {
        $scope.securityStructure.Ownership = $scope.ownershipList;
      }

      const a = new Date($scope.securityStructure.PropertyDetails.ContractDate);
      const aYear = a.getFullYear();
      const aMonth = a.getMonth() + 1;
      const aDate = a.getDate();
      const { FundingCalParametersId } = $scope.securityStructure;
      $scope.securityStructure.PropertyDetails.ContractDate = `${aYear}-${aMonth}-${aDate}`;
      $scope.securityStructure.FundingCalParametersId =
        FundingCalParametersId || 0;

      loanScenarioService
        .contactLoanSecurityInfoSet($scope.securityStructure)
        .then(response => {
          $scope.addSecurity = !$scope.addSecurity;

          if (response.status === 200) {
            toaster.pop('success', 'Security structure successfully save');
            $rootScope.$emit('reloadSecutiyListGrid');
          }
        });
    };

    $scope.loanSecurityInfoSet = function() {
      if (
        $scope.security.formatted_address ===
        $scope.security.Address.formatted_address
      ) {
        saveSecurityInfo();
        return;
      }

      $scope.security.saveAddress().then(() => {
        saveSecurityInfo();
      });
    };
    if (uiService.isCountry('New Zealand')) {
      $scope.setDefaultValues();
    }
  });
