import { LOAN_STATUS } from './loanStatusCategory';

const { IN_PROGRESS } = LOAN_STATUS;

export const LOAN_AND_PIPELINE_STATUS_MAPPING = [
  {
    NAME: 'New Application',
    LOAN_STATUS: IN_PROGRESS.NEW_APPLICATION,
    PIPELINE_STATUS: 7,
  },
  {
    NAME: 'Lodged',
    LOAN_STATUS: IN_PROGRESS.LODGED,
    PIPELINE_STATUS: 6,
  },
  {
    NAME: 'Pre-Approved',
    LOAN_STATUS: IN_PROGRESS.PRE_APPROVED,
    PIPELINE_STATUS: 12,
  },
  {
    NAME: 'Conditionally Approved',
    LOAN_STATUS: IN_PROGRESS.CONDITIONAL_APPROVAL,
    PIPELINE_STATUS: 5,
  },
  {
    NAME: 'Unconditionally Approved',
    LOAN_STATUS: IN_PROGRESS.UNCONDITIONAL_APPROVAL,
    PIPELINE_STATUS: 9,
  },
  {
    NAME: 'Pending Settlement',
    LOAN_STATUS: IN_PROGRESS.PENDING_SETTLEMENT,
    PIPELINE_STATUS: 10,
  },
];
