import template from './subscriptionPreloader.html';
import controller from './subscriptionPreloaderCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    closeModal: '&',
    loadingMessage: '<',
  },
};
