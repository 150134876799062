import template from './widgetCurrencyInput.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    fieldId: '@',
    label: '@',
    required: '<',
    minValue: '<',
    maxValue: '<',
    modelValue: '<',
    onUpdate: '&',
  },
};
