import template from './lmPdfHeaderShort.html';
import './styles.scss';

export default {
  template,
  bindings: {
    pageTitle: '@',
    adviserName: '@',
  },
};
