export const IMPORTANT_DATE_TYPES = {
  IN_PROGRESS: {
    SETTLEMENT: 1,
    APPROVAL: 2,
    SUBMITTED: 3,
    FINANCE: 4,
    APPROVALEXPIRY: 5,
  },
  DELETE: {
    ESTIMATED_SETTLEMENT: 12,
    ACTUAL_SETTLEMENT: 1,
    APPROVAL: 8,
    SUBMITTED: 5,
    FINANCE: 2,
    APPROVALEXPIRY: 9,
  },
  API_STATUS: {
    SUCCESS: 1,
    INVALID_DATE: 3,
  },
};
