import _ from 'lodash';
import {
  LIST_OF_QUESTIONS,
  DISPLAY_OPTIONS,
  ANSWER_TYPE,
  STRING_OPTION,
  BOOL_OPTION,
  INT_OPTION,
  DECIMAL_OPTION,
  RATE_TYPE,
  RATE_TYPE_IMPORTANT,
  RATE_IMPORTANT,
  SPECIAL_RATE_IMPORTANT,
  REPAYMENT_PREFERENCE,
  REPAYMENT_OPTION,
  REPAYMENT_IMPORTANT,
} from 'Common/constants/customerObjectives';

export default class ObjectivesQuestionService {
  constructor(optionsService) {
    'ngInject';

    this.optionsService = optionsService;
    this.featurePreference = [];
  }

  $onInit() {
    this.objectivesData = [];
  }

  objectivesQuestionValid() {
    this.result = true;
    if (!this.featurePreference || !this.featurePreference.length) return false;
    this.featurePreference.forEach(item => {
      if (!item.status) this.result = false;
    });

    return this.result;
  }

  setFeaturePreferenceStatus(questionId, status) {
    const isQuestionAlreadyAdded =
      this.featurePreference &&
      this.featurePreference.find(i => i.questionId === questionId);
    if (isQuestionAlreadyAdded) {
      this.featurePreference.map(item => {
        if (item.questionId === questionId) {
          item.status = status;
        }
        item.hide = this.hideMainQuestion(item.questionId);
        return item;
      });
    } else {
      const newFeature = {
        questionId,
        status,
        hide: this.hideMainQuestion(questionId),
      };
      this.featurePreference.push(newFeature);
    }
  }

  getFeaturePreferenceStatus(questionId) {
    if (!this.featurePreference || !this.featurePreference.length) return false;
    const status = this.featurePreference.find(
      item => item.questionId === questionId
    );
    return (status && status.hide) || false;
  }

  setObjectives(data) {
    this.objectivesData = data;
  }

  getOptions() {
    this.objectivesOption = [];
    this.optionsService.getObjectivesOption().then(data => {
      this.objectivesOption = data;
    });
  }

  getQuestionOptionType(displayOption) {
    this.answerType = '';
    if (STRING_OPTION.includes(displayOption)) {
      this.answerType = ANSWER_TYPE.ANSWER_STRING;
    } else if (BOOL_OPTION.includes(displayOption)) {
      this.answerType = ANSWER_TYPE.ANSWER_BOOL;
    } else if (INT_OPTION.includes(displayOption)) {
      this.answerType = ANSWER_TYPE.ANSWER_INT;
    } else if (DECIMAL_OPTION.includes(displayOption)) {
      this.answerType = ANSWER_TYPE.ANSWER_DECIMAL;
    }
    return this.answerType;
  }

  getAnswerList(data) {
    if (!data) return [];
    this.result = [];
    if (data.DisplayOptionId === DISPLAY_OPTIONS.MULTI_DROPDOWN) {
      switch (data.QuestionId) {
        case LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE:
          this.getOptionByQuestion(
            LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE
          ).forEach(item => {
            if (data.answerInt.includes(parseInt(item.value, 10))) {
              this.result.push(item);
            }
          });
          break;
        case LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE:
          this.getOptionByQuestion(
            LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE
          ).forEach(item => {
            if (data.answerInt.includes(parseInt(item.value, 10))) {
              this.result.push(item);
            }
          });
          break;
        default:
      }
    }

    return this.result;
  }

  getAnswer(data) {
    this.answerValue = null;
    if (!data) return this.answerValue;
    const optionType = data.DisplayOptionId || data.QuestionDisplayTypeId;
    switch (optionType) {
      case DISPLAY_OPTIONS.YES_NO_ONLY:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerBool);
        break;
      case DISPLAY_OPTIONS.TEXT_ONLY:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerString);
        break;
      case DISPLAY_OPTIONS.TEXTBOX_ON_YES:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerString);
        break;
      case DISPLAY_OPTIONS.TEXTBOX_ON_NO:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerString);
        break;
      case DISPLAY_OPTIONS.SINGLE_DROPDOWN:
        this.answerValue = data.update
          ? data.answerValue
          : String(_.last(data.answerInt));
        break;
      case DISPLAY_OPTIONS.SINGLE_DROPDOWN_NO_FRAME:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerInt);
        break;
      case DISPLAY_OPTIONS.CHECK_BOX:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerBool);
        break;
      case DISPLAY_OPTIONS.TEXT_FLOAT:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerDecimal);
        break;
      case DISPLAY_OPTIONS.RADIO_BOX_ON_YES:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerBool);
        break;
      case DISPLAY_OPTIONS.RADIO_BOX_ON_NO:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerBool);
        break;
      case DISPLAY_OPTIONS.SINGLE_DROPDOWN_STRING:
        this.answerValue = data.update
          ? data.answerValue
          : _.last(data.answerString);
        break;
      default:
    }

    return this.answerValue;
  }

  hideMainQuestion(questionId, update = false) {
    this.result = false;
    this.questionId = questionId;
    const requiredFieldForConflictDeclaration =
      questionId === LIST_OF_QUESTIONS.RATE_PREFERENCES ||
      questionId === LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES ||
      questionId === LIST_OF_QUESTIONS.REPAYMENT_PREFERENCE;
    if (update) {
      this.questionId = requiredFieldForConflictDeclaration
        ? LIST_OF_QUESTIONS.CONFLICT_DECLARATION
        : questionId;
    }
    if (this.questionId === LIST_OF_QUESTIONS.CONFLICT_DECLARATION) {
      this.featurePreference.forEach(item => {
        const checkStatus =
          (item.questionId === LIST_OF_QUESTIONS.RATE_PREFERENCES ||
            item.questionId === LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES ||
            item.questionId === LIST_OF_QUESTIONS.REPAYMENT_PREFERENCE) &&
          !item.status;
        if (checkStatus) this.result = true;
      });
    }

    return this.result;
  }

  getOptionByQuestion(questionId) {
    this.optionByQuestion = this.objectivesOption.find(
      o => o.questionId === questionId
    );
    return (this.optionByQuestion && this.optionByQuestion.options) || [];
  }

  getOptionList(data) {
    this.option = [];
    const chooseRate = this.objectivesData.find(
      item => item.QuestionId === LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE
    );
    switch (data.QuestionId) {
      case LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT:
        this.option = this.getRateImportantOption(this.getAnswer(chooseRate));
        break;
      case LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE
        );
        break;
      case LIST_OF_QUESTIONS.RATE_DURATION:
        this.option = this.getOptionByQuestion(LIST_OF_QUESTIONS.RATE_DURATION);
        break;
      case LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE
        );
        break;
      case LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT
        );
        break;
      case LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE
        );
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY
        );
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY
        );
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.REPAYMENT_OPTION
        );
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT:
        this.option = this.getOptionByQuestion(
          LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT
        );
        break;
      default:
    }

    return this.option;
  }

  getOptionPlaceHolder(questionId) {
    this.placeHolder = null;
    switch (questionId) {
      case LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE:
        this.placeHolder = 'Select Preference';
        break;
      case LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT:
        this.placeHolder = 'Choose reason';
        break;
      case LIST_OF_QUESTIONS.RATE_DURATION:
        this.placeHolder = 'Select ducration';
        break;
      case LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS:
        this.placeHolder = 'Enter Details';
        break;
      case LIST_OF_QUESTIONS.SPECIAL_CHOOSE_RATE_PREFERENCE:
        this.placeHolder = 'Add Preference';
        break;
      case LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT:
        this.placeHolder = 'Choose reason';
        break;
      case LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE:
        this.placeHolder = 'Add Preference';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY:
        this.placeHolder = 'Select frequency';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY:
        this.placeHolder = 'Choose period';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.placeHolder = 'Choose option';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT:
        this.placeHolder = 'Choose reason';
        break;
      case LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS:
        this.placeHolder = 'Set priorities and reasons';
        break;
      case LIST_OF_QUESTIONS.OTHER_REQUIREMENTS_DETAILS:
        this.placeHolder = 'Enter details';
        break;
      default:
    }
    return this.placeHolder;
  }

  updateSubQuestion(data, subQuestions) {
    this.result = subQuestions;
    switch (data.QuestionId) {
      case LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE:
        this.result.map(item => {
          const obj = item;
          if (obj.QuestionId === LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT) {
            obj.optionList = this.getRateImportantOption(data.answerValue);
          }
          if (obj.QuestionId === LIST_OF_QUESTIONS.RATE_DURATION) {
            obj.hideQuestion = data.answerValue === RATE_TYPE.VARIABLE;
          }
          return obj;
        });
        break;
      case LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT:
        this.result.map(item => {
          const obj = item;
          if (obj.QuestionId === LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS) {
            obj.hideQuestion = RATE_IMPORTANT.OTHER !== data.answerValue;
          }
          return obj;
        });
        break;
      case LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT:
        this.result.map(item => {
          const obj = item;
          if (
            obj.QuestionId === LIST_OF_QUESTIONS.SPECIAL_RATE_IMPORTANT_DETAILS
          ) {
            obj.hideQuestion =
              SPECIAL_RATE_IMPORTANT.OTHER !== data.answerValue;
          }
          return obj;
        });
        break;
      case LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE:
        this.result.map(item => {
          const obj = item;
          const { answerList } =
            subQuestions.find(
              o =>
                o.QuestionId === LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE
            ) || [];
          if (obj.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY) {
            obj.hideQuestion = !(
              answerList.length &&
              answerList.find(
                o =>
                  o.value === REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST ||
                  o.value === REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS
              )
            );
          }
          if (obj.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY) {
            obj.hideQuestion = !(
              answerList.length &&
              answerList.find(
                o => o.value === REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS
              )
            );
          }
          if (obj.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_OPTION) {
            obj.hideQuestion = !(
              answerList.length &&
              answerList.find(
                o => o.value === REPAYMENT_PREFERENCE.LINE_OF_CREDIT
              )
            );
          }
          return obj;
        });
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT:
        this.result.map(item => {
          const obj = item;
          if (obj.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_DETAILS) {
            obj.hideQuestion = REPAYMENT_IMPORTANT.OTHER !== data.answerValue;
          }
          return obj;
        });
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.result.map(item => {
          const obj = item;
          if (obj.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_OPTION) {
            obj.showOtherField = REPAYMENT_OPTION.OTHER === data.answerValue;
          }
          return obj;
        });
        break;
      default:
    }

    return this.result;
  }

  checkOptionalQuestion(questionId) {
    this.result = false;
    switch (questionId) {
      case LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS:
        this.result = true;
        break;
      case LIST_OF_QUESTIONS.SPECIAL_RATE_IMPORTANT_DETAILS:
        this.result = true;
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_DETAILS:
        this.result = true;
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION_DETAILS:
        this.result = true;
        break;
      default:
    }

    return this.result;
  }

  withTextboxQuestion(questionId) {
    this.result = false;
    switch (questionId) {
      case LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS:
        this.result = true;
        break;
      case LIST_OF_QUESTIONS.OTHER_REQUIREMENTS_DETAILS:
        this.result = true;
        break;
      default:
    }

    return this.result;
  }

  textareaSize(questionId) {
    this.result = 1;
    switch (questionId) {
      case LIST_OF_QUESTIONS.CONFLICT_DECLARATION_DETAILS:
        this.result = 3;
        break;
      default:
    }

    return this.result;
  }

  hideSubQuestion(questionId) {
    const repaymentPreference =
      this.getAnswerList(
        this.objectivesData.find(
          item =>
            item.QuestionId === LIST_OF_QUESTIONS.CHOOSE_REPAYMENT_PREFERENCE
        )
      ) || [];
    this.result = false;
    switch (questionId) {
      case LIST_OF_QUESTIONS.RATE_IMPORTANT_DETAILS:
        this.result =
          RATE_IMPORTANT.OTHER !==
            this.getAnswer(
              this.objectivesData.find(
                item =>
                  item.QuestionId ===
                  LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT
              )
            ) || '';
        break;
      case LIST_OF_QUESTIONS.SPECIAL_RATE_IMPORTANT_DETAILS:
        this.result =
          SPECIAL_RATE_IMPORTANT.OTHER !==
            this.getAnswer(
              this.objectivesData.find(
                item =>
                  item.QuestionId ===
                  LIST_OF_QUESTIONS.SPECIAL_RATE_PREFERENCES_IMPORTANT
              )
            ) || '';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_FREQUENCY:
        this.result = true;
        repaymentPreference.forEach(item => {
          if (item.value === REPAYMENT_PREFERENCE.PRINCIPAL_AND_INTEREST)
            this.result = false;
          if (item.value === REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS)
            this.result = false;
        });
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_INTEREST_ONLY:
        this.result = true;
        repaymentPreference.forEach(item => {
          if (item.value === REPAYMENT_PREFERENCE.INTEREST_ONLY_REPAYMENTS)
            this.result = false;
        });
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.result = true;
        repaymentPreference.forEach(item => {
          if (item.value === REPAYMENT_PREFERENCE.LINE_OF_CREDIT)
            this.result = false;
        });
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_DETAILS:
        this.result =
          REPAYMENT_IMPORTANT.OTHER !==
            this.getAnswer(
              this.objectivesData.find(
                item =>
                  item.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_IMPORTANT
              )
            ) || '';
        break;
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION_DETAILS:
        this.result = true;
        break;
      case LIST_OF_QUESTIONS.RATE_DURATION:
        this.result =
          RATE_TYPE.VARIABLE ===
          (this.getAnswer(
            this.objectivesData.find(
              item =>
                item.QuestionId === LIST_OF_QUESTIONS.CHOOSE_RATE_PREFERENCE
            )
          ) || RATE_TYPE.VARIABLE);
        break;
      default:
    }

    return this.result;
  }

  showSubQuestionOtherFields(questionId) {
    this.result = false;
    switch (questionId) {
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.result =
          REPAYMENT_OPTION.OTHER ===
            this.getAnswer(
              this.objectivesData.find(item => item.QuestionId === questionId)
            ) || '';
        break;
      default:
    }

    return this.result;
  }

  getOtherFieldValue(questionId) {
    this.result = null;
    switch (questionId) {
      case LIST_OF_QUESTIONS.REPAYMENT_OPTION:
        this.result =
          this.getAnswer(
            this.objectivesData.find(
              item =>
                item.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_OPTION_DETAILS
            )
          ) || null;
        break;
      default:
    }

    return this.result;
  }

  getRateImportantOption(value) {
    this.option = [];
    const importantOption = this.getOptionByQuestion(
      LIST_OF_QUESTIONS.RATE_PREFERENCES_IMPORTANT
    );
    switch (value) {
      case RATE_TYPE.FIXED:
        importantOption.forEach(item => {
          if (RATE_TYPE_IMPORTANT.FIXED.includes(item.value)) {
            this.option.push(item);
          }
        });
        break;
      case RATE_TYPE.VARIABLE:
        importantOption.forEach(item => {
          if (RATE_TYPE_IMPORTANT.VARIABLE.includes(item.value)) {
            this.option.push(item);
          }
        });
        break;
      case RATE_TYPE.SPLIT:
        importantOption.forEach(item => {
          if (RATE_TYPE_IMPORTANT.SPLIT.includes(item.value)) {
            this.option.push(item);
          }
        });
        break;
      default:
    }

    return this.option;
  }

  checkQuestionIsDone(question) {
    this.result = true;
    const { SubQuestion } = question;
    if (
      question.answerValue === false &&
      question.answerType === ANSWER_TYPE.ANSWER_BOOL
    )
      return true;
    if (!question.answerValue) return false;
    if ((!SubQuestion || !SubQuestion.length) && question.answerValue !== null)
      return true;
    SubQuestion.forEach(item => {
      const isBoolAndIntNotAnswered =
        item.answerValue === null &&
        (item.answerType === ANSWER_TYPE.ANSWER_BOOL ||
          item.answerType === ANSWER_TYPE.ANSWER_INT) &&
        item.QuestionDisplayTypeId !== DISPLAY_OPTIONS.MULTI_DROPDOWN &&
        !item.hideQuestion;
      const isStringNotAnswered =
        (!item.answerValue || item.answerValue === null) &&
        item.answerType === ANSWER_TYPE.ANSWER_STRING &&
        !item.optionalNote &&
        !item.hideQuestion;
      const isOptionNotNotAnswered =
        !item.answerValue && item.optionalNote && !item.hideQuestion;
      const isMultiSelectNotAnswered =
        !item.answerList.length &&
        item.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN &&
        !item.hideQuestion;
      const isSubQuestionNotAnswered =
        isBoolAndIntNotAnswered ||
        isStringNotAnswered ||
        isOptionNotNotAnswered ||
        isMultiSelectNotAnswered;
      if (isSubQuestionNotAnswered) {
        this.result = false;
      }
    });

    return this.result;
  }

  setOtherFieldAnswer(obj, item) {
    this.result = obj;
    if (item.QuestionId === LIST_OF_QUESTIONS.REPAYMENT_OPTION) {
      this.result.QuestionId = LIST_OF_QUESTIONS.REPAYMENT_OPTION_DETAILS;
      this.result.DisplayOptionId = DISPLAY_OPTIONS.TEXT_ONLY;
      this.result.answerString = [item.otherFieldValue];
    }

    return this.result;
  }

  setObjectivesAnswer(obj, item) {
    this.setObj = obj;
    switch (item.answerType) {
      case ANSWER_TYPE.ANSWER_STRING:
        this.setObj.answerString = [item.answerValue];
        break;
      case ANSWER_TYPE.ANSWER_BOOL:
        this.setObj.answerBool = [item.answerValue];
        break;
      case ANSWER_TYPE.ANSWER_INT:
        if (item.QuestionDisplayTypeId === DISPLAY_OPTIONS.MULTI_DROPDOWN) {
          this.setObj.answerInt = [];
          item.answerList.forEach(o => {
            this.setObj.answerInt.push(parseInt(o.value, 10));
          });
        } else {
          this.setObj.answerInt = [item.answerValue];
        }
        break;
      case ANSWER_TYPE.ANSWER_DECIMAL:
        this.setObj.answerDecimal = [item.answerValue];
        break;
      default:
    }

    return this.setObj;
  }
}
