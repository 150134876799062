import { GENDER_OPTIONS } from 'Common/constants/options';

class AssetSupplierFieldsCtrl {
  constructor(optionsService) {
    'ngInject';

    this.optionsService = optionsService;
  }

  $onInit() {
    this.genderList = GENDER_OPTIONS;
    this.getTitleList();
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  getTitleList() {
    this.optionsService.getTitleList().then(response => {
      if (!response || !response.length) return;
      this.titleList = response;
    });
  }
}

export default AssetSupplierFieldsCtrl;
