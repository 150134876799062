import angular from 'angular';

angular
  .module('app')
  .controller(
    'ContactOpenModalForDemoVideoCtrl',
    function ContactOpenModalForDemoVideoCtrl(
      $scope,
      $uibModalInstance,
      DEMO_VIDEO
    ) {
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.videoId = DEMO_VIDEO.LEAD_PIPELINE.Url;
    }
  );
