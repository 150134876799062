import { toastSaveSuccess, toastSaveDelete } from 'Common/utilities/alert';
import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';

export default class PipelineLabelsCtrl {
  constructor(pipelineService, toaster) {
    'ngInject';

    this.pipelineService = pipelineService;
    this.toaster = toaster;
  }

  $onInit() {
    this.resetLabelObj();
  }

  resetLabelObj() {
    this.label = { id: 0, title: '', color: '' };
  }

  isLabelSelected(settingLabelId) {
    if (!this.cardLabels || !this.cardLabels.length || !settingLabelId)
      return true;
    const findIsLabelSelected =
      this.cardLabels.find(
        o => parseInt(o[this.cardLabelKey], 10) === parseInt(settingLabelId, 10)
      ) || false;

    return !findIsLabelSelected;
  }

  showAddLabel() {
    this.isAdd = true;
    this.isEdit = false;
    this.resetLabelObj();
  }

  showEditLabel(event, label) {
    event && event.stopPropagation();
    this.isAdd = false;
    this.isEdit = true;
    this.resetLabelObj();

    const {
      pipelineSettingsLabelID: id,
      labelName: title,
      colorName: color,
    } = label;
    this.label = { id, title, color };
  }

  labelBack() {
    this.isAdd = false;
    this.isEdit = false;
    this.resetLabelObj();
  }

  colorPicked(selectedAddEditColor) {
    this.label.color = selectedAddEditColor;
  }

  saveLabel() {
    const { id: LabelID, title: LabelName, color: ColorName } = this.label;
    const data = {
      LabelID,
      LabelName,
      ColorName,
    };

    const refreshParams = {
      updatedLabel: {
        labelId: LabelID,
        labelName: LabelName,
        labelColor: ColorName,
      },
      action: LabelID ? 'update' : 'add',
    };

    this.pipelineService.PipelineSettingsLabelSet([data]).then(() => {
      toastSaveSuccess(this.toaster);
      this.onRefreshLabelsSettings({ $e: { refreshParams } });
      this.labelBack();
      if (LabelID) {
        const cardLabels = this.cardLabels.map(card => {
          if (parseInt(card.labelId, 10) === parseInt(LabelID, 10)) {
            card.labelName = LabelName;
            card.labelColor = ColorName;
          }
          return card;
        });
        this.onLabelToggle({ $e: { newLabelsList: [...cardLabels] } });
      }
    });
  }

  removeLabel(label) {
    const { pipelineSettingsLabelID: LabelID } = label;
    const refreshParams = {
      updatedLabel: { labelId: LabelID },
      action: 'delete',
    };
    this.pipelineService.PipelineSettingLabelDelete({ LabelID }).then(() => {
      toastSaveDelete(this.toaster);
      this.onRefreshLabelsSettings({ $e: { refreshParams } });
    });
  }

  toggleLabel(settingLabelId) {
    const isAdd = this.isLabelSelected(settingLabelId);
    const isLeads = this.pipelineType === 'leads';
    const addMethod = isLeads
      ? 'PipelineCardsLabelSet'
      : 'postPipelineLoanLabel';
    const deleteMethod = isLeads
      ? 'PipelineCardsLabelsDelete'
      : 'deletePipelineLoanLabel';
    const { pipelineCardsID, pipelineCardsId, loanID, loanId } = this.card;
    const cardLoanId = loanID || loanId;
    const cardID = pipelineCardsID || pipelineCardsId;
    const opportunityApplicationMethod =
      this.pipelineType === 'opportunity' ? 'opportunity' : 'loan';

    if (isAdd) {
      const args = isLeads
        ? { labelsID: settingLabelId, cardID }
        : {
            labelId: settingLabelId,
            loanId: cardLoanId,
            method: opportunityApplicationMethod,
          };
      this.pipelineService[addMethod](args).then(({ data }) => {
        toastSaveSuccess(this.toaster);
        if (!data) return;
        const { labels } = isLeads
          ? collectionCamelizeBuilderForUI(data)
          : data;

        if (!labels) return;
        this.onLabelToggle({
          $e: { newLabelsList: pipelineLabelBuilder(labels) },
        });
      });
    } else {
      const args = isLeads
        ? { labelsID: settingLabelId, cardsId: cardID }
        : { labelId: settingLabelId, loanId: cardLoanId };

      this.pipelineService[deleteMethod](args).then(({ data }) => {
        toastSaveDelete(this.toaster);
        if (!data) return;
        const { labels } = isLeads
          ? collectionCamelizeBuilderForUI(data)
          : data;

        this.onLabelToggle({
          $e: { newLabelsList: pipelineLabelBuilder(labels) },
        });
      });
    }
  }
}
