import moment from 'moment';

export function getFutureDateRanges() {
  return [
    {
      text: 'Today',
      range: [moment(), moment()],
    },
    {
      text: 'Next 3 days',
      range: [moment(), moment().add(3, 'days')],
    },
    {
      text: 'Next 7 days',
      range: [moment(), moment().add(7, 'days')],
    },
    {
      text: 'Next 30 days',
      range: [moment(), moment().add(30, 'days')],
    },
    {
      text: 'This month',
      range: [moment().startOf('month'), moment().endOf('month')],
    },
    {
      text: 'Custom',
      range: [moment(), moment()],
    },
  ];
}
