import template from './threeDotModal.html';
import controller from './threeDotModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    parentToChildNotificationRegistration: '&',
    card: '<',
    menuType: '<',
    props: '<',
    labelsSettings: '<',
    modalInstance: '<',
  },
};
