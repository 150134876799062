import angular from 'angular';

angular.module('app').directive('touchspin', function touchspin() {
  return {
    link(scope, elem, attr) {
      const tsOptions = [
        'initval',
        'min',
        'max',
        'step',
        'forcestepdivisibility',
        'decimals',
        'stepinterval',
        'stepintervaldelay',
        'verticalbuttons',
        'verticalupclass',
        'verticaldownclass',
        'prefix',
        'postfix',
        'prefix_extraclass',
        'postfix_extraclass',
        'booster',
        'boostat',
        'maxboostedstep',
        'mousewheel',
        'buttondown_class',
        'buttonup_class',
      ];
      const options = {};
      for (let i = 0, l = tsOptions.length; i < l; i++) {
        const opt = tsOptions[i];
        if (typeof attr[opt] !== 'undefined') {
          options[opt] = attr[opt];
        }
      }
      elem.TouchSpin(options);
    },
  };
});
