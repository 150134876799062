import angular from 'angular';
import onlineFactFindModal from './onlineFactFindModal';
import requestFactFind from './requestFactFind/requestFactFind';
import reviewFactFind from './reviewFactFind/reviewFactFind';
import './style.scss';

export default angular
  .module('components.onlineFactFindModal', [])
  .component('requestFactFind', requestFactFind)
  .component('reviewFactFind', reviewFactFind)
  .component('onlineFactFindModal', onlineFactFindModal).name;
