export const ANSWER_TYPE = {
  BOOLEAN: 1,
  STRING: 2,
  INTEGER: 3,
  DECIMAL: 4,
};

export const QUESTION_DISPLAY = {
  YES_NO: {
    ID: 1,
    ANSWER_TYPE: ANSWER_TYPE.BOOLEAN,
  },
  TEXT_ONLY: {
    ID: 2,
    ANSWER_TYPE: ANSWER_TYPE.STRING,
  },
  TEXT_ON_YES: {
    ID: 3,
    ANSWER_TYPE: ANSWER_TYPE.STRING,
  },
  TEXT_ON_NO: {
    ID: 4,
    ANSWER_TYPE: ANSWER_TYPE.STRING,
  },
  SINGLE_DROPDOWN: {
    ID: 5,
    ANSWER_TYPE: ANSWER_TYPE.INTEGER,
  },
  MULTI_DROPDOWN: {
    ID: 6,
    ANSWER_TYPE: ANSWER_TYPE.INTEGER,
  },
  SINGLE_DROPDOWN_NOFRAME: {
    ID: 7,
    ANSWER_TYPE: ANSWER_TYPE.INTEGER,
  },
  CHECKBOX: {
    ID: 8,
    ANSWER_TYPE: ANSWER_TYPE.BOOLEAN,
  },
  TEXT_WITH_DROPDOWN: {
    ID: 9,
    ANSWER_TYPE: ANSWER_TYPE.STRING,
  },
  TEXT_FLOAT: {
    ID: 10,
    ANSWER_TYPE: ANSWER_TYPE.DECIMAL,
  },
};
