import template from './workbenchAddNote.html';
import controller from './workbenchAddNoteCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    quoteId: '<',
    familyId: '<',
    noteToEdit: '=',
    onBack: '&',
    modalInstance: '<',
  },
  controller,
  controllerAs: 'vm',
};
