import './style.scss';
import SaveProfilerToContactCtrl from './saveProfilerToContactCtrl';

export default {
  bindings: {
    profilerData: '<',
  },
  controller: SaveProfilerToContactCtrl,
  controllerAs: 'vm',
  template: `
  <span ng-click="vm.clickListener()"><i class="fa fa-plus fa-fw"></i> Save</span>
  `,
};
