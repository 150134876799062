import swal from 'sweetalert';
import { toastError } from 'Common/utilities/alert';

class MatchCommissionModalCtrl {
  constructor(contactService, commissionService) {
    'ngInject';

    this.contactService = contactService;
    this.commissionService = commissionService;
    this.clientLoanStructures = [];
    this.countryCode = 'NZ';
  }

  $onInit() {
    this.loadFamilyLoanStructures();
  }

  loadFamilyLoanStructures() {
    this.showLoading = true;
    this.contactService
      .unmatchedCommissionsLoanStructuresGet({
        familyId: this.familyIdToMatch,
        providerId: this.commission.ProviderId,
      })
      .then(response => {
        this.clientLoanStructures = response;
      })
      .finally(() => {
        this.showLoading = false;
      });
  }

  setSelectedMatch(loanStructure) {
    this.selectedLoanStructure = loanStructure;
  }

  isLoanStructureSelected(loanStructure) {
    if (loanStructure && this.selectedLoanStructure) {
      return (
        loanStructure.loanStructureID ===
          this.selectedLoanStructure.loanStructureID &&
        loanStructure.expectationID === this.selectedLoanStructure.expectationID
      );
    }
    return false;
  }

  matchToCommission() {
    if (!this.selectedLoanStructure) return;

    this.isMatching = true;
    if (this.selectedLoanStructure.expectationID) {
      this.proceedMatchingCommission();
    } else {
      this.createExpectedCommission();
    }
  }

  proceedMatchingCommission() {
    const {
      Commission_Spreadsheet_ID: commissionSpreadSheetID,
    } = this.commission;
    const {
      expectationID,
      loanID,
      loanStructureID,
    } = this.selectedLoanStructure;

    this.contactService
      .matchCommission({
        expectationID,
        loanID,
        loanStructureID,
        commissionSpreadSheetID,
      })
      .then(() => {
        this.matchSuccess = true;
        swal('Matched', 'Commission successfully matched.', 'success');
        if (this.onCommissionMatched)
          this.onCommissionMatched({ commission: this.commission });
      }, this.handleMatchFailure)
      .finally(() => {
        this.isMatching = false;
      });
  }

  createExpectedCommission() {
    const {
      loanID: LoanID,
      loanStructureID: LoanStructureID,
      loanAmount: LoanAmount,
    } = this.selectedLoanStructure;

    this.commissionService.commissionsTypeGet().then(response => {
      const { data } = response;
      const { TypeName: typeName, AmountPaid, DatePaid } = this.commission;
      const commissionType =
        data &&
        data.find(type => type.Name.toLowerCase() === typeName.toLowerCase());
      const typeId = commissionType && commissionType.Value;

      const serviceMethodName =
        typeName === 'Trail'
          ? 'loansExpectedCommissionsRecurringSet'
          : 'loansExpectedCommissionsSet';
      this.contactService[serviceMethodName]({
        LoanID,
        LoanStructureID,
        LoanAmount,
        DatePaid,
        AmountPaid,
        TypeId: typeId,
        IsPaid: true,
      })
        .then(expectedCommission => {
          this.selectedLoanStructure.expectationID = expectedCommission.id;
          this.proceedMatchingCommission();
        })
        .catch(this.handleMatchFailure)
        .finally(() => {
          this.isMatching = false;
        });
    });
  }

  handleMatchFailure() {
    this.matchSuccess = false;
    toastError();
  }

  matchEnabled() {
    return !!this.selectedLoanStructure;
  }

  cancel() {
    this.modalInstance.dismiss('cancelled');
  }
}

export default MatchCommissionModalCtrl;
