class EmploymentData {
  constructor() {
    this.EmploymentId = 0;
    this.EntityId = 0;
    this.ClientId = 0;
    this.Income = [];
    this.EmploymentStatusId = '';
    this.EmploymentBasisId = '';
    this.EmploymentBasisName = '';
    this.EmploymentTypeId = '';
    this.EmployerTypeId = '';
    this.DateStarted = '';
    this.DateEnded = '';
    this.showEndDate = false;
    this.EmployerName = '';
    this.EmployerNumber = '';
    this.EmployerAddress = '';
    this.EmployerContactFirstName = '';
    this.EmployerContactLastName = '';
    this.Address = {};
    this.AddressId = 0;
    this.Details = {};
    this.EmploymentRollId = '';
    this.EmploymentRole = '';
    this.isRoleResults = false;
    this.IsBenefits = false;
    this.IsCurrent = true;
    this.IsProbation = false;
    this.format = 'dd MMM yyyy';
    this.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
    this.dateOptions = { formatYear: 'yy', showWeeks: false };
    this.datePopUp = { StartDate: false, EndDate: false };
  }
}

export default EmploymentData;
