import {
  ALT_DATE_INPUT_FORMATS,
  DD_MMM_YYYY_FORMAT,
} from 'Common/constants/dateConfigs';
import { ASSET_INSURANCE_DETAILS } from 'Common/constants/questions';
import {
  getAnswerObjToSave,
  getAnswer,
} from 'Common/utilities/loanApplication';
import {
  singleAnswerBuilderForMyCRM,
  singleAnswerBuilderForUI,
} from 'Common/mappers/questionAnswer';

class AssetInsuranceDetailsCtrl {
  constructor(loanScenarioService) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.DD_MMM_YYYY_FORMAT = DD_MMM_YYYY_FORMAT;
    this.ALT_DATE_INPUT_FORMATS = ALT_DATE_INPUT_FORMATS;
    this.ASSET_INSURANCE_DETAILS = ASSET_INSURANCE_DETAILS;
  }

  $onInit() {
    this.expiryDate = undefined;
    this.expiryDatePopup = false;
    this.getAssetInsuranceDetails();
  }

  popupDate() {
    this.expiryDatePopup = !this.expiryDatePopup;
  }

  getAssetInsuranceDetails() {
    this.loanScenarioService.loanInsuranceGet(this.loanAppId).then(response => {
      if (!response) return;
      const insuranceDetailAnswers = response.data.map(
        singleAnswerBuilderForUI
      );

      this.insurer = getAnswer(
        insuranceDetailAnswers,
        this.ASSET_INSURANCE_DETAILS.INSURER
      );
      this.insuranceBroker = getAnswer(
        insuranceDetailAnswers,
        this.ASSET_INSURANCE_DETAILS.INSURANCE_BROKER
      );
      this.policyNumber = getAnswer(
        insuranceDetailAnswers,
        this.ASSET_INSURANCE_DETAILS.POLICY_NUMBER
      );
      const expiry = getAnswer(
        insuranceDetailAnswers,
        this.ASSET_INSURANCE_DETAILS.EXPIRY_DATE
      );
      this.expiryDate = expiry ? new Date(expiry) : null;
    });
  }

  saveAnswer(question, answer) {
    if (typeof answer === 'undefined' || answer === null) return;

    const answerObj = getAnswerObjToSave(question, answer);
    answerObj.loanScenarioId = this.loanAppId;
    answerObj.familyId = this.familyId;
    this.loanScenarioService.loanInsuranceSet(
      singleAnswerBuilderForMyCRM(answerObj)
    );
  }

  saveDate(dateForm, question, answer) {
    if (dateForm.$invalid) return;
    this.saveAnswer(question, answer);
  }
}

export default AssetInsuranceDetailsCtrl;
