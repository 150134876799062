import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('clientReviews', function clientReviews(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/clientReviews.html',

      link(scope) {
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };

        scope.clientReviewTableData = [];
        scope.clientReviewTableDropDownList = [];
        scope.clientReviewTableDropDowns = {};
        scope.clientReviewMultiAdviserList = [];
        scope.outp = [];

        scope.WidgetOptionGetData = [];
        scope.filteredClientReviewTableDropDownList = [];

        function ClientReviewTableGet() {
          scope.showSlick = false;
          overviewDashboardService
            .ClientReviewTableGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.clientReviewTableData = [];
                const totalData = response.data.length;
                const round = Math.floor(totalData / 4) * 4 - 1;
                const modStart = Math.floor(totalData / 4) * 4;
                const mod = Math.floor(totalData % 4);
                for (let i = 0; i < round; i += 4) {
                  const testData = [];
                  testData.push(response.data[i]);
                  testData.push(response.data[i + 1]);
                  testData.push(response.data[i + 2]);
                  testData.push(response.data[i + 3]);

                  scope.clientReviewTableData.push(testData);
                }

                if (parseInt(mod, 10) !== 0) {
                  const lastItem = [];
                  for (let i = modStart; i < totalData; i++) {
                    lastItem.push(response.data[i]);
                  }
                  scope.clientReviewTableData.push(lastItem);
                }

                scope.dataLoaded = true;
                scope.showSlick = true;

                if (scope.clientReviewTableData.length > 0) {
                  $timeout(() => {
                    try {
                      scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1000);
                }
              },
              () => {}
            );
        }

        function widgetOptionGet() {
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;

                scope.WidgetOptionGetAdviser = {};
                scope.Advisers = [];
                for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 7
                  ) {
                    const widgetOptionValue =
                      scope.WidgetOptionGetData[i].Widget_OptionValue;
                    scope.filteredClientReviewTableDropDownList = scope.clientReviewTableDropDownList.filter(
                      item =>
                        parseInt(item.OptionValueId, 10) ===
                        parseInt(widgetOptionValue, 10)
                    );

                    scope.clientReviewTableDropDowns =
                      scope.filteredClientReviewTableDropDownList[0];
                  }
                  let Flag;
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 3
                  ) {
                    Flag = 1;
                    scope.switchsetting2 = 'Yes';
                    scope.Advisers =
                      scope.WidgetOptionGetData[i].SelectedUerValues;
                    const AdvisersArray = scope.Advisers.split(',');

                    scope.clientReviewMultiAdviserList = scope.clientReviewMultiAdviserList.map(
                      obj => {
                        if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }

                        return obj;
                      }
                    );
                  } else if (
                    scope.WidgetOptionGetData[i].OptionName !== 'Adviser' &&
                    scope.switchsetting2 !== 'Yes' &&
                    Flag !== 1
                  ) {
                    scope.switchsetting2 = 'No';
                  }
                }
              },
              () => {}
            );
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(() => {
              ClientReviewTableGet();
              widgetOptionGet();
            })
            .catch(displayError);
        }

        scope.makeSelectNone = function() {
          for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
            scope.WidgetOptionAdviserList[i].ticked = false;
            scope.clientReviewMultiAdviserList.push(
              scope.WidgetOptionAdviserList[i]
            );
          }
          scope.setIsFiltered.Widget_OptionValue = '';
          scope.setIsFiltered.WidgetOptionId = 3;
          scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
          scope.setIsFiltered.SelectedUser = [];
          saveForTimePeriod(scope.setIsFiltered);
        };
        scope.isFiltered = function(filteredOption) {
          scope.val = filteredOption;
          scope.setIsFiltered = {};

          if (scope.val === 'No') {
            scope.makeSelectNone();
          } else {
            scope.switchsetting2 = 'Yes';
          }
        };
        function WidgetOptionTimePeriodListv4Get() {
          overviewDashboardService.WidgetOptionTimePeriodListv4Get().then(
            response => {
              scope.clientReviewTableDropDownList = response.data;
              widgetOptionGet();
            },
            () => {}
          );
        }
        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet().then(
            response => {
              scope.WidgetOptionAdviserList = response.data;
              for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                scope.WidgetOptionAdviserList[i].ticked = false;
                scope.clientReviewMultiAdviserList.push(
                  scope.WidgetOptionAdviserList[i]
                );
              }
              widgetOptionGet();
            },
            () => {}
          );
        }
        scope.updateMultiselectFilterData = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.clientReviewMultiAdviserList, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.clientReviewMultiAdviserList, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.clientReviewMultiAdviserList) !== 0) {
              scope.clientReviewMultiAdviserList[0].ticked = false;
            }

            dataToSend = scope.clientReviewMultiAdviserList
              .filter(item => item.FamilyName !== 'All' && item.ticked === true)
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue =
              scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.clientReviewMultiAdviserList = scope.clientReviewMultiAdviserList.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.clientReviewMultiAdviserList[0].FamilyId,
            });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue =
              scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };
        scope.updateTimePeriod = function(clientReviewTableDropDowns) {
          scope.clientReviewTableDropDownsObj = clientReviewTableDropDowns;
          scope.clientReviewTableDropDownsObjToSet = {};

          scope.clientReviewTableDropDownsObjToSet.Widget_UserID =
            scope.myParam.Widget_UserID;
          scope.clientReviewTableDropDownsObjToSet.Widget_OptionValue =
            scope.clientReviewTableDropDownsObj.OptionValueId;
          scope.clientReviewTableDropDownsObjToSet.WidgetOptionId =
            scope.clientReviewTableDropDownsObj.OptionId;
          scope.clientReviewTableDropDownsObjToSet.SelectedUser = null;
          saveForTimePeriod(scope.clientReviewTableDropDownsObjToSet);
        };
        function init() {
          ClientReviewTableGet();
          WidgetOptionTimePeriodListv4Get();
          WidgetOptionAdviserListGet();
        }

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'clientReviewsPopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') scope.customizeMode = true;
          else scope.customizeMode = false;
        };

        init();
      },
    };
  });
