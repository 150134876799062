import angular from 'angular';

class SetDefaultTemplateModalCtrl {
  constructor($uibModalInstance, template, methodType) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.template = template;
    this.methodType = methodType;
  }

  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }
}

angular
  .module('app')
  .controller('SetDefaultTemplateModalCtrl', SetDefaultTemplateModalCtrl);
