class ShowDocumentListOnHoverCtrl {
  constructor() {
    'ngInject';

    this.showDocumentList = false;
    this.isIconClicked = false;
  }

  iconClicked(event) {
    this.showDocumentList = !this.showDocumentList;
    this.isIconClicked = true;
    event.stopPropagation();
  }

  cursorLeftIcon() {
    if (this.isIconClicked) {
      this.showDocumentList = !this.showDocumentList;
    }
  }

  cursorLeftDocumentList() {
    this.isIconClicked = false;
    this.showDocumentList = false;
  }
}

export default ShowDocumentListOnHoverCtrl;
