export default class ThreeDotModalCtrl {
  constructor() {
    'ngInject';

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.hasWorkbenchOpen = typeof this.props.onCardClick !== 'function';
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  processParentNotification(action, data) {
    if (action === 'refresh-labels-settings') {
      this.props.labelsSettings = data;
    }
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }
}
