import { releaseNotesBuilderForUI } from 'Common/mappers/releaseNotes';

class ReleaseNotesService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
  }

  getReleaseNotes(params) {
    return this.httpClient
      .get('release-notes', params)
      .then(response => response.data.map(releaseNotesBuilderForUI));
  }
}

export default ReleaseNotesService;
