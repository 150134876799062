import angular from 'angular';

angular.module('app').directive('onAnchorClick', [
  '$document',
  '$timeout',
  function onAnchorClick($document, $timeout) {
    return {
      link(scope, element, attrs) {
        let eventClickTimeout;
        const elementConstant = element.on('click', () => {
          eventClickTimeout = $timeout(() => {
            const id = attrs.onAnchorClick;
            const el = $document.find(`#${id}`);
            angular.element(el).click();
          });
        });
        scope.$on('$destroy', () => {
          element.off('click', elementConstant);
          if (eventClickTimeout) $timeout.cancel(eventClickTimeout);
        });
      },
    };
  },
]);
