import requirementsFormModalTemplate from '../../components/workbenchRequirementsModal/workbenchRequirementsModal.html';
import requirementsFormModalCtrl from '../../components/workbenchRequirementsModal/workbenchRequirementsModalCtrl';

export default class OutstandingRequirementService {
  constructor(loanScenarioService, $uibModal, $q) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.$uibModal = $uibModal;
    this.$q = $q;
  }

  get(loanId) {
    const defer = this.$q.defer();

    this.loanScenarioService
      .workbenchRequirementGet(loanId)
      .then(response => {
        const { data } = response;
        if (!data) return defer.reject([]);

        const requirements = data.map(obj => {
          const requirement = Object.assign({}, obj, {
            ApplicantID: obj.ClientID || obj.EntityID,
          });
          return requirement;
        });
        defer.resolve(requirements);
      })
      .catch(() => {
        defer.reject([]);
      });

    return defer.promise;
  }

  complete(obj, loanId) {
    const postData = {
      loanId,
      requirementID: obj.RequirementID,
      isCompleted: obj.IsCompleted,
    };

    this.loanScenarioService.workbenchRequirementCompletion(postData);
  }

  modal(loanId, requirementObj, clientsList = [], requirementsList) {
    this.$uibModal
      .open({
        template: requirementsFormModalTemplate,
        controller: requirementsFormModalCtrl,
        controllerAs: 'vm',
        resolve: {
          loanId: () => loanId,
          requirement: () => requirementObj,
          clientsList: () => clientsList,
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(response => {
        const { success, requirement } = response;
        const isSuccessful = success && requirement;
        if (!isSuccessful) return;

        const requirementIndex = requirementsList.findIndex(
          obj => obj.RequirementID === requirement.RequirementID
        );
        if (requirementIndex !== -1) {
          requirementsList[requirementIndex] = requirement;
        } else {
          requirementsList.push(requirement);
        }
      });
  }
}
