import template from './editLoanSubmissionEmailModal.html';
import EditLoanSubmissionEmailModalCtrl from './editLoanSubmissionEmailModalCtrl';

export default {
  template,
  controller: EditLoanSubmissionEmailModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    emailType: '<',
    lenderSubmissionObj: '<',
  },
};
