import {
  appendSupplierFixedData,
  formatAssetSupplierForContact,
} from 'Common/utilities/assetSupplier';

class AddAssetSupplierModalCtrl {
  constructor(
    toaster,
    contactService,
    dashboardService,
    loanScenarioService,
    loanScenarioModelService
  ) {
    'ngInject';

    this.toaster = toaster;
    this.contactService = contactService;
    this.dashboardService = dashboardService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
  }

  $onInit() {
    this.assetSupplier = {};
    this.SAVE_TYPE = { TO_CONTACTS: 1, THIS_APP_ONLY: 2 };
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  saveAssetSupplier(saveType) {
    if (this.assetSupplierForm.$invalid) {
      this.toaster.pop('error', 'Please fill all required fields.');
      return;
    }

    this.saving = true;
    const supplierAddress = this.assetSupplier && this.assetSupplier.address;
    if (!supplierAddress) {
      this.proceedSave(saveType);
    } else {
      this.dashboardService
        .addressDetailsSet(supplierAddress)
        .then(response => {
          this.assetSupplier.addressId = response.data;
          this.proceedSave(saveType);
        });
    }
  }

  proceedSave(saveType) {
    if (saveType === this.SAVE_TYPE.TO_CONTACTS) {
      this.saveToContacts();
      return;
    }
    this.saveForThisApplication();
  }

  saveToContacts() {
    this.savingToContacts = true;
    const assetSupplierToContact = formatAssetSupplierForContact(
      this.assetSupplier
    );

    this.contactService
      .addContact(assetSupplierToContact)
      .then(response => {
        if (!response || !response.data) return;
        const familyId = response.data;
        return this.contactService
          .clientInformGet(familyId, 0, true)
          .then(res => {
            if (!res.data || !res.data.length) return;
            const clients = res.data;
            return clients.map(element => ({ ...element, FamilyId: familyId }));
          });
      })
      .then(response => {
        if (!response || !response.length) return;
        const addedContact = appendSupplierFixedData(
          this.loanAppId,
          response[0]
        );
        return this.loanScenarioService
          .scenarioApplicantSet(addedContact)
          .then(() => {
            this.modalInstance.close(addedContact);
            this.toaster.pop('success', 'Asset Supplier successfully saved.');
          });
      })
      .catch(() => {
        this.toaster.pop('error', 'Problem in saving asset supplier.');
        this.saving = false;
        this.savingToContacts = false;
      });
  }

  saveForThisApplication() {
    this.loanScenarioModelService
      .postAssetSuplier(this.loanAppId, this.assetSupplier)
      .then(response => {
        if (!response || !response.data) return;
        const assetSupplierId = response.data;
        const assetSupplierToSave = this.formatAssetSupplier(
          assetSupplierId,
          this.assetSupplier
        );
        this.modalInstance.close(assetSupplierToSave);
        this.toaster.pop(
          'success',
          'Asset Supplier - successfully saved for this application.'
        );
      })
      .catch(() => {
        this.toaster.pop('error', 'Problem in saving asset supplier.');
        this.saving = false;
        this.savingToApp = false;
      });
  }

  formatAssetSupplier(assetSupplierId, assetSupplierObj) {
    const supplierToSave = assetSupplierObj && {
      AssetSupplierId: assetSupplierId,
      FirstName: assetSupplierObj.firstname,
      LastName: assetSupplierObj.lastname,
      ClientEntityId: assetSupplierId,
    };

    return appendSupplierFixedData(this.loanAppId, supplierToSave);
  }
}

export default AddAssetSupplierModalCtrl;
