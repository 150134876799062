import template from './advanceDetails.html';
import AdvanceDetailsCtrl from './advanceDetailsCtrl';

export default {
  template,
  controller: AdvanceDetailsCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    entityData: '=',
    formReference: '<',
    isSubmitted: '=',
    formHasError: '=',
  },
};
