export function complianceReviewRatingBuilderForUI(rating) {
  return {
    complianceReviewId: rating.ComplianceReviewId,
    adviserFamilyId: rating.AdviserFamilyId,
    reviewerFamilyId: rating.ReviewerFamilyId,
    reviewerName: rating.ReviewerName,
    rating: rating.Rating,
    comments: rating.Comments,
    createdDate: rating.CreatedDate,
    lastModifiedDate: rating.LastModifiedDate,
  };
}
