import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('LoanappOpenGridModalCtrl', function LoanappOpenGridModalCtrl(
    $scope,
    $uibModalInstance,
    expenseTypes,
    frequencyTypes,
    loanAppId,
    familyId,
    ownersList,
    loanScenarioService,
    financialsSharedService,
    utilitiesService,
    contactService,
    SweetAlert,
    optionsService
  ) {
    let gridExpenseTypes = _.clone(expenseTypes);

    angular.extend($scope, {
      Title: 'Expenses',

      cancel() {
        $uibModalInstance.dismiss('cancel');
      },

      frequencyTypes: _.reverse(frequencyTypes),

      ownersList,

      Settings: {
        defaultFrequencyID: null,
        isSaving: false,
      },

      $checkIfTheSameFrequency() {
        let isTheSameFrequency = true;
        let firstFrequencyID = null;
        _.forEach($scope.ExpensesGrouped, objExpense => {
          _.forEach(objExpense, expenses => {
            _.forEach(expenses, expense => {
              if (isTheSameFrequency) {
                if (!firstFrequencyID) {
                  firstFrequencyID = angular.copy(expense.FrequencyID);
                } else if (firstFrequencyID !== expense.FrequencyID) {
                  isTheSameFrequency = false;
                }
              }
            });
          });
        });

        return { isTheSameFrequency, firstFrequencyID };
      },

      $setDefaultFrequencyID(id) {
        // update all entries
        _.forEach($scope.ExpensesGrouped, objExpense => {
          _.forEach(objExpense, expenses => {
            _.forEach(expenses, expense => {
              expense.FrequencyID = id;
            });
          });
        });
        // update the settings
        $scope.setDefaultFrequencyID(id, true);
      },

      setDefaultFrequencyID(id, isOverride) {
        if (isOverride) $scope.Settings.defaultFrequencyID = String(id);
        else {
          const checkIfTheSameFrequency = $scope.$checkIfTheSameFrequency();

          if (checkIfTheSameFrequency.isTheSameFrequency) {
            $scope.$setDefaultFrequencyID(id);
          } else {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text:
                  'This will update ALL expense frequencies. Do you really want to proceed?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, update it!',
                closeOnConfirm: true,
              },
              confirm => {
                if (confirm) {
                  $scope.$setDefaultFrequencyID(id);
                }
              }
            );
          }
        }
      },

      frequencyTypePerExpenseClicked() {
        // This will just check if all the frequencies are the same, will change the setting, if not, will null the setting
        const checkIfTheSameFrequency = $scope.$checkIfTheSameFrequency();

        if (checkIfTheSameFrequency.isTheSameFrequency) {
          $scope.Settings.defaultFrequencyID = String(
            checkIfTheSameFrequency.firstFrequencyID
          );
        } else {
          $scope.Settings.defaultFrequencyID = null;
        }
      },

      $ExpenseInfoGet() {
        // Get Existing Expenses

        if (loanAppId) {
          loanScenarioService.ExpenseInfoGet(loanAppId).then(response => {
            // get the financials for the selected family only
            const expenseData = _.find(response.data, o => {
              return (
                parseInt(o.FamilyId, 10) ===
                parseInt(financialsSharedService.selectedFamily.FamilyId, 10)
              );
            });

            _.forEach(expenseData.overview.FinancialInfo, financialInfo => {
              _.remove(financialInfo.Borrowers, borrower => {
                return borrower.BorrowerID === 0;
              });

              _.forEach(financialInfo.Borrowers, borrower => {
                borrower.OwnershipInitials = utilitiesService.filterInitial(
                  borrower.FirstName,
                  borrower.LastName ? borrower.LastName : ''
                );
                borrower.background = financialsSharedService.getBGcolor(
                  borrower.BorrowerID
                );
                return borrower;
              });
            });

            $scope.FamilyExpense = expenseData;

            if (_.size($scope.FamilyExpense.overview.FinancialInfo) === 0)
              $scope.setDefaultFrequencyID('4', true); // default settings to Monthly

            // blocks of code for the grouping
            $scope.ExpensesGrouped = {};
            $scope.ExpensesGroupedExtract = _.groupBy(gridExpenseTypes, o => {
              return o.GroupName;
            });

            _.forEach($scope.ExpensesGroupedExtract, (objectExpense, key) => {
              const convertToArrayTypes = [];

              _.forEach(objectExpense, o => {
                angular.extend(o, {
                  Id: 0,
                  FrequencyID: '4', // default to Monthly,
                  LoanScenarioId: loanAppId,
                  FamilyId: familyId,
                  Description: '',
                  Borrowers: [],
                  LiabilityId: 0,
                });

                // search for current expenses with the same TypeId
                if (
                  _.find(
                    $scope.FamilyExpense.overview.FinancialInfo,
                    expense => {
                      return String(expense.TypeId) === String(o.TypeId);
                    }
                  )
                ) {
                  const expensesArray = [];
                  _.forEach(
                    $scope.FamilyExpense.overview.FinancialInfo,
                    expense => {
                      if (String(expense.TypeId) === String(o.TypeId)) {
                        angular.extend(expense, {
                          GroupId: o.GroupId,
                          GroupName: o.GroupName,
                          TypeName: expense.Type,
                          LoanScenarioId: loanAppId,
                          FamilyId: familyId,
                        });

                        expensesArray.push(expense);
                      }
                    }
                  );
                  convertToArrayTypes.push(expensesArray);
                } else {
                  convertToArrayTypes.push([o]);
                }
              });

              $scope.ExpensesGrouped[key] = convertToArrayTypes;
            });

            delete $scope.FamilyExpense;
            delete $scope.ExpensesGroupedExtract;
          });
        } else {
          contactService.expenseInfoGet(familyId).then(response => {
            const expenseData = {
              FamilyId: familyId,
              overview: response.data,
            };

            _.forEach(expenseData.overview.FinancialInfo, financialInfo => {
              _.remove(financialInfo.Borrowers, borrower => {
                return borrower.BorrowerID === 0;
              });

              _.forEach(financialInfo.Borrowers, borrower => {
                borrower.background = financialsSharedService.getBGcolor(
                  borrower.BorrowerID
                );
                borrower.OwnershipInitials = utilitiesService.filterInitial(
                  borrower.FirstName,
                  borrower.LastName ? borrower.LastName : ''
                );
                return borrower;
              });
            });

            $scope.FamilyExpense = expenseData;

            if (_.size($scope.FamilyExpense.overview.FinancialInfo) === 0)
              $scope.setDefaultFrequencyID('4', true); // default settings to Monthly

            // blocks of code for the grouping
            $scope.ExpensesGrouped = {};
            $scope.ExpensesGroupedExtract = _.groupBy(gridExpenseTypes, o => {
              return o.GroupName;
            });

            _.forEach($scope.ExpensesGroupedExtract, (objectExpense, key) => {
              const convertToArrayTypes = [];

              _.forEach(objectExpense, o => {
                angular.extend(o, {
                  Id: 0,
                  FrequencyID: '4', // default to Monthly,
                  FamilyId: familyId,
                  LoanScenarioId: loanAppId,
                  Description: '',
                  Borrowers: [],
                  LiabilityId: 0,
                });

                // search for current expenses with the same TypeId
                if (
                  _.find(
                    $scope.FamilyExpense.overview.FinancialInfo,
                    expense => {
                      return String(expense.TypeId) === String(o.TypeId);
                    }
                  )
                ) {
                  const expensesArray = [];
                  _.forEach(
                    $scope.FamilyExpense.overview.FinancialInfo,
                    expense => {
                      if (String(expense.TypeId) === String(o.TypeId)) {
                        angular.extend(expense, {
                          GroupId: o.GroupId,
                          GroupName: o.GroupName,
                          TypeName: expense.Type,
                          LoanScenarioId: loanAppId,
                          FamilyId: familyId,
                        });

                        expensesArray.push(expense);
                      }
                    }
                  );
                  convertToArrayTypes.push(expensesArray);
                } else {
                  convertToArrayTypes.push([o]);
                }
              });

              $scope.ExpensesGrouped[key] = convertToArrayTypes;
            });

            delete $scope.FamilyExpense;
            delete $scope.ExpensesGroupedExtract;
          });
        }
      },

      _initializeGrid() {
        // if frequency is still empty from the parent controller, get the types here independently
        if (!$scope.frequencyTypes || _.size($scope.frequencyTypes) === 0) {
          optionsService.FinancialFrequency().then(response => {
            _.map(response.data, o => {
              o.Desc = o.Name;
              o.Value = parseInt(o.Value, 10);
              return o;
            });
            $scope.frequencyTypes = _.reverse(response.data);
          });
        }

        // if ownersList is still empty from the parent controller, get the types here independently
        if (!ownersList || _.size(ownersList) === 0) {
          loanScenarioService
            .ExpenseClientGet(familyId, loanAppId, null)
            .then(response => {
              const tmp = [];
              _.forEach(response.data, o => {
                if (o.BorrowerID) {
                  tmp.push({
                    BorrowerID: o.BorrowerID,
                    FirstName: o.FirstName,
                    LastName: o.LastName,
                    PreferedName: `${o.FirstName} ${o.LastName}`,
                    initials: utilitiesService.filterInitial(
                      o.FirstName ? o.FirstName : '',
                      o.LastName ? o.LastName : ''
                    ),
                    background: financialsSharedService.getBGcolor(
                      o.BorrowerID
                    ),
                    IsEntity: o.IsEntity,
                  });
                }
              });
              $scope.ownersList = tmp;
            });
        }

        // if expenseTypes is still empty from the parent controller, get the types here independently
        if (!gridExpenseTypes || _.size(gridExpenseTypes) === 0) {
          loanScenarioService.ExpenseTypeGet().then(response => {
            // Filter result - removing (Loans-Credit Cards and Mortgage Repayments)
            const filteredTypes = [];
            for (let i = 0; i < response.data.length; i++) {
              switch (response.data[i].Name) {
                case 'Credit Cards':
                  // if credit
                  break;
                case 'Mortgage Repayments':
                  break;
                default:
                  filteredTypes.push(response.data[i]);
                  break;
              }
            }
            const tmp = [];
            filteredTypes &&
              Object.keys(filteredTypes).forEach(x => {
                const value = filteredTypes[x];
                tmp.push({
                  GroupId: value.GroupId,
                  GroupName: value.GroupName,
                  TypeName: value.Name,
                  TypeId: value.Value,
                });
              });

            // put this on the main injected object
            gridExpenseTypes = tmp;
            $scope.$ExpenseInfoGet();
          });
        } else {
          $scope.$ExpenseInfoGet();
        }
      },

      ExpensesIcons: [
        { id: 3, icon: 'icon-gen-expenses' },
        { id: 2, icon: 'icon-children-schooling' },
        { id: 6, icon: 'icon-household' },
        { id: 9, icon: 'icon-investments' },
        { id: 11, icon: 'icon-misc' },
        { id: 10, icon: 'icon-entertainment' },
        { id: 5, icon: 'icon-recreation' },
        { id: 7, icon: 'icon-savings' },
        { id: 1, icon: 'icon-car' },
      ],

      getExpenseIcon(GroupId) {
        const findIcon = _.find($scope.ExpensesIcons, o => {
          return String(o.id) === String(GroupId);
        });
        return findIcon ? findIcon.icon : 'default-none';
      },

      checkIfOwnerIsSelected(expense, owner) {
        return _.find(expense.Borrowers, o => {
          return o.BorrowerID === owner.BorrowerID;
        });
      },

      selectOwner(expense, owner) {
        // check first if the owner is already selected or not
        const isSelected = !!$scope.checkIfOwnerIsSelected(expense, owner);

        if (isSelected) {
          // deselect
          _.remove(expense.Borrowers, o => {
            return o.BorrowerID === owner.BorrowerID;
          });
        } else {
          // select
          expense.Borrowers.push(owner);
        }
      },

      saveGrid() {
        $scope.Settings.isSaving = true;

        // Set outside model
        const reformExpenseModel = {
          rootBorrowers: ownersList,
          rootFamilyId: familyId,
          expenseListModel: [],
        };

        // set actual data to expenseListModel
        _.forEach($scope.ExpensesGrouped, objExpense => {
          _.forEach(objExpense, expenses => {
            _.forEach(expenses, expense => {
              if (!(expense.Id === 0 && !expense.Amount)) {
                _.omit(expense, 'isDeleted');
                reformExpenseModel.expenseListModel.push(expense);
              }
            });
          });
        });

        // save grid
        loanScenarioService
          .ExpenseDetailsSetMultiple(reformExpenseModel)
          .then(() => {
            $scope.cancel();
          });
      },

      addExpenseBreakdown(objExpense, expense) {
        objExpense.push({
          GroupId: expense.GroupId,
          GroupName: expense.GroupName,
          TypeId: expense.TypeId,
          TypeName: expense.TypeName,
          Id: 0,
          FrequencyID: '4', // default to Monthly
          LoanScenarioId: loanAppId,
          FamilyId: familyId,
          Description: '',
          Borrowers: [],
          LiabilityId: 0,
        });
      },

      removeExpenseBreakdown(objExpense, expense, index) {
        if (expense.Id === 0) {
          objExpense.splice(index, 1);
        } else {
          expense.isDeleted = true;
          expense.Amount = 0;
        }
      },
    });

    $scope._initializeGrid();
  });
