import template from './eSignUploadSignatureModal.html';
import controller from './eSignUploadSignatureModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
  },
};
