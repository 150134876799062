import template from './pipelineItemLabels.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    selectedLabels: '<',
    onAddLabelToCard: '&',
    onDeleteLabelFromCard: '&',
    onItemClick: '&',
  },
};
