import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { isFunction } from 'lodash';
import { isCancelled } from 'Common/utilities/subscription';
import swal from 'sweetalert';

export default angular
  .module('services.uiService', [uiBootstrap])
  .service('uiService', function uiService(
    $state,
    $uibModal,
    $rootScope,
    $window,
    authService,
    configService,
    contactService,
    corporateService,
    loanProfilerService,
    fundingCalculatorService,
    customThemeService,
    generalService,
    aliService,
    toaster,
    $localStorage,
    usersSubscriptionService,
    creditCheckSubscriptionService,
    $sce,
    $timeout,
    loanCalculatorLinking,
    opportunityNewModalService,
    userService,
    currentUserService
  ) {
    this.isShowCustomizeTheme = false;
    this.localStorage = $localStorage;
    this.$timeout = $timeout;
    this.showUserProfileManagement =
      (configService.feature && configService.feature.userProfileManagement) ||
      0;
    this.showLMICalculator =
      (configService.feature && configService.feature.lmiCalculator) || 0;
    this.showCreditCheck =
      (configService.feature && configService.feature.creditCheck) || 0;
    this.showHEMCalculator =
      (configService.feature && configService.feature.hemCalculator) || 0;
    this.showMergeContactsOption =
      (configService.feature && configService.feature.mergeContacts) || 0;
    this.showIncomeCalculator =
      (configService.feature && configService.feature.incomeCalculator) || 0;
    this.showOpportunity = configService.feature.opportunity || 0;
    const insurancePipelineEnabled =
      (configService.feature &&
        configService.feature.insuranceApplicationsPipeline) ||
      0;
    this.showInsurancePipeline =
      insurancePipelineEnabled && currentUserService.isNZ;
    this.showProviderInformation =
      (configService.feature && configService.feature.providerInformation) || 0;
    this.showMaxLVR =
      (configService.feature && configService.feature.maxlvr) || 0;
    this.navbarCollapsed = false;
    this.mobileMenuNavBarCollapsed = true;
    this.isCorporateUser = false;
    this.isAutraliaBased = false;
    this.sidebarMainObj = {};
    this.brokerPediaPostData = {};
    this.waitBrokerPediaReponse = false;
    this.allowedAliAccess = false;
    this.searchMainObject = {
      isGeneralSearchEnable: false,
    };
    this.generalSearchText = '';
    this.themeBranding = customThemeService.branding;
    this.loanCalculatorLinking = loanCalculatorLinking;
    this.opportunityNewModalService = opportunityNewModalService;

    this.$onDestroy = () => {
      this.timeoutRedirection && this.$timeout.cancel(this.timeoutRedirection);
    };

    this.checkHemAccess = () => {
      return this.showHEMCalculator && this.isAutraliaBased;
    };
    this.getUserInfoProcedure = () => {
      userService.GetUserInfo().then(userInfoResponse => {
        if (!userInfoResponse || !userInfoResponse.data) return;

        this.initCorporateUser(userInfoResponse);
        this.initBrandingCategory(userInfoResponse);
        this.isShowCustomizeThemeFunction(userInfoResponse);
        this.initProfilePicture(userInfoResponse);
      });
    };
    this.getUserInfoProcedure();

    this.initCorporateUser = response => {
      this.loggedUserData = response.data;
      this.allowedAliAccess =
        this.loggedUserData.ALIQuoteEnabled &&
        (this.loggedUserData.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
          this.loggedUserData.AccessType === ACCESS_TYPE.ADVISER);

      if (
        response.data.AccessType === ACCESS_TYPE.CORPORATE ||
        response.data.AccessType === ACCESS_TYPE.ASSISTANT
      ) {
        this.isCorporateUser = true;
      }
      if (response.data.CountryId === COUNTRY_TYPE.AUSTRALIA) {
        this.isAutraliaBased = true;
      }
      usersSubscriptionService.setBrokerPediaDataForPosting(
        this.loggedUserData.FamilyId
      );
      this.getCurrentUserSubscriptions();
      const { CorporateTeamId: corporateTeamId } = response.data;
      this.showFeatureManagement =
        corporateTeamId && this.checkForFeatureManagement(corporateTeamId);
    };

    this.checkForFeatureManagement = corpTeamId => {
      const { IT_SUPPORT, SUPER_ADMIN } = CORPORATE_TEAM;
      const allowedCorpTypes = [IT_SUPPORT, SUPER_ADMIN];
      return allowedCorpTypes.includes(corpTeamId);
    };

    this.checkUserProfileManagementAccess = () => {
      const { AccessType } = this.loggedUserData || 0;
      const { CountryId } = this.loggedUserData || 0;
      const checkIfUserHaveAccess =
        this.showUserProfileManagement &&
        CountryId === COUNTRY_TYPE.AUSTRALIA &&
        AccessType !== ACCESS_TYPE.CORPORATE;
      return checkIfUserHaveAccess;
    };

    this.userFamilyId = () => {
      let familyId = 0;
      if (this.loggedUserData && this.loggedUserData.FamilyId) {
        familyId = this.loggedUserData.FamilyId;
      }
      return familyId;
    };

    this.getCurrentUserSubscriptions = onSubsFetched => {
      if (this.loggedUserData && this.loggedUserData.FamilyId) {
        contactService
          .subscriptionInfo(this.loggedUserData.FamilyId)
          .then(response => {
            if (response && response.length) {
              const brokerPedia =
                response &&
                response.find(
                  obj =>
                    obj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA
                );
              usersSubscriptionService.setBrokerPediaData(brokerPedia);
              const priceFinder =
                response &&
                response.find(
                  obj =>
                    obj.subscriptionProductId === SUBSCRIPTIONTYPE.PRICE_FINDER
                );
              usersSubscriptionService.setPriceFinderData(priceFinder);
              this.creditCheckSub =
                response &&
                response.find(
                  sub =>
                    sub.subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK
                );
              if (isFunction(onSubsFetched)) onSubsFetched();
            }
          });
      } else {
        this.getUserInfoProcedure();
      }
    };

    this.showBrokerPedia = () => {
      return (
        (configService.feature &&
          configService.feature.brokerPedia &&
          this.isAutraliaBased) ||
        0
      );
    };

    this.brokerPediaFormSubmit = () => {
      if (this.externalLink) {
        $window.document.getElementById('brokerpedia-form').submit();
      } else {
        this.redirectExternalBrokerpedia();
      }
    };

    this.redirectExternalBrokerpedia = () => {
      if (!this.externalLink) {
        generalService.brokerpediaLoginURLGet().then(response => {
          if (response && response.data) {
            this.externalLink = $sce.trustAsResourceUrl(response.data);
            this.timeoutRedirection = this.$timeout(() => {
              this.brokerPediaFormSubmit();
            }, 0);
          }
        });
      } else {
        this.brokerPediaFormSubmit();
      }
    };

    this.buildBrokerPediaPostData = () => {
      if (this.loggedUserData && Object.keys(this.loggedUserData).length) {
        this.brokerPediaPostData.userid = this.loggedUserData.FamilyId;
        this.brokerPediaPostData.username = this.loggedUserData.Email;
        this.brokerPediaPostData.email = this.loggedUserData.Email;
        this.brokerPediaPostData.companyname = this.loggedUserData.BrandingCategory;
        this.brokerPediaPostData = {
          ...this.brokerPediaPostData,
          ...usersSubscriptionService.getBrokerPediaPostData(),
        };
      }
      this.redirectExternalBrokerpedia();
    };

    this.brokerPediaClickMobile = () => {
      this.menuToggleInMobile();
      this.brokerPediaClick();
    };

    this.brokerPediaInfoSet = () => {
      generalService.brokerpediaInfoSet().then(response => {
        this.waitBrokerPediaResponse = false;
        if (!response || !response.data) return;
        this.buildBrokerPediaPostData();
      });
    };

    this.brokerPediaClick = mobileClick => {
      if (mobileClick) this.menuToggleInMobile();
      const familyId = this.userFamilyId();
      if (this.waitBrokerPediaResponse || !familyId) return;
      this.waitBrokerPediaResponse = true;
      const brokerPediaData = usersSubscriptionService.getBrokerPediaData();
      const { AccessType } = this.loggedUserData;
      const checkBrokerPediaUserStatus =
        AccessType === ACCESS_TYPE.CORPORATE ||
        ((AccessType === ACCESS_TYPE.ADVISER ||
          AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER) &&
          brokerPediaData &&
          (brokerPediaData.isActive || isCancelled(brokerPediaData)));
      if (checkBrokerPediaUserStatus) {
        this.brokerPediaInfoSet();
      } else {
        const props = {
          familyId,
          subscriptionData: brokerPediaData,
          orderedById: familyId,
        };
        usersSubscriptionService
          .subscribeToService(props)
          .then(response => {
            this.waitBrokerPediaResponse = false;
            if (!response) return;
            this.getCurrentUserSubscriptions();
            this.brokerPediaInfoSet();
          })
          .finally(() => {
            this.waitBrokerPediaResponse = false;
          });
      }
    };

    this.hemCalculatorMobileClick = () => {
      this.menuToggleInMobile();
      this.stateGo('app.hemCalculator');
    };

    this.initBrandingCategory = response => {
      const {
        BrandingCategory,
        TradingName,
        BrandingCategoryID,
      } = response.data;
      this.userBrandingCategory = BrandingCategory;
      this.tradingName = TradingName;
      this.userBrandingCategoryID = BrandingCategoryID;
    };

    // TEMP
    this.isCountry = country => {
      const brokerCountryInfo =
        $rootScope.crmCountry ||
        $window.sessionStorage.getItem('brokerCountryInfo');
      return brokerCountryInfo === country;
    };
    this.returnCountry = () => {
      return (
        $rootScope.crmCountry ||
        $window.sessionStorage.getItem('brokerCountryInfo')
      );
    };
    this.isCommissionReportShown = () => {
      const hasValidAccessType =
        this.loggedUserData && this.loggedUserData.AccessType;
      const allowedTypeList = [
        ACCESS_TYPE.ADVISER,
        ACCESS_TYPE.PRINCIPAL_ADVISER,
      ];
      return (
        hasValidAccessType &&
        allowedTypeList.indexOf(this.loggedUserData.AccessType) >= 0 &&
        this.isCountry('Australia')
      );
    };

    this.isNotBranded = () => {
      return (
        this.isCountry('New Zealand') &&
        this.userBrandingCategoryID === BRANDING_CATEGORY_TYPE.OWN_BRAND
      );
    };

    this.brokerNotesLabel = () => {
      let notes = '';
      if (typeof $rootScope.crmCountry === 'undefined') {
        notes = 'Notes';
      } else {
        notes = this.isCountry('New Zealand')
          ? 'Adviser Notes'
          : 'Broker Notes';
      }
      return notes;
    };

    this.getCountryCode = () => {
      switch ($rootScope.crmCountry) {
        case 'New Zealand':
          return 'NZ';
        case 'Australia':
          return 'AU';
        case 'Indonesia':
          return 'ID';
        default:
          return '';
      }
    };

    // TEMP
    this.getCrmCountryID = () => {
      return $rootScope.crmCountryID;
    };

    this.getCrmCountry = () => {
      return $rootScope.crmCountry;
    };

    // TEMP
    this.getCrmCurrency = () => {
      return $rootScope.crmCurrency;
    };

    // TEMP
    this.getCrmCurrencyCode = () => {
      return $rootScope.crmCurrencyCode;
    };

    this.isBrandingCategory = branding => {
      return this.userBrandingCategory === branding;
    };

    this.menuToggleInMobile = () => {
      this.mobileMenuNavBarCollapsed = !this.mobileMenuNavBarCollapsed;
    };

    this.menuHideInMobile = () => {
      this.mobileMenuNavBarCollapsed = true;
    };

    this.showCorporateTeamPage = () => {
      const enabledCountryList = [COUNTRY_TYPE.AUSTRALIA];
      const isEnabled =
        enabledCountryList.indexOf(this.getCrmCountryID()) !== -1 &&
        configService.feature.meetCorporateTeamPage;
      return isEnabled;
    };
    this.showEmailCampaignReferrers = () => {
      const isEnabled =
        (configService.feature &&
          configService.feature.emailCampaignReferrers) ||
        0;
      return isEnabled;
    };
    this.meetCorporateTeamMobileState = () => {
      this.menuToggleInMobile();
      this.stateGo('app.meetTheCorporateTeam');
    };
    this.customizeTheme = () => {
      $uibModal.open({
        templateUrl: '/assets/views/customTheme.html',
        size: 'lg',
        backdrop: 'static',
      });
    };

    this.openAddNewLoanApplicationModal = (isMultiApp = false) => {
      if (
        (configService.feature && configService.feature.newLoanApplicationv2) ||
        isMultiApp
      ) {
        // use the new feature
        const modalInstance = $uibModal.open({
          template: `<create-new-loan-application
                      modal-instance="vm.modalInstance">
                    </create-new-loan-application>`,
          controller: 'CommonModalPlaceholderCtrl',
          controllerAs: 'vm',
          size: 'md',
          backdrop: 'static',
          windowClass: 'new-loan-modal',
          keyboard: false,
          backdropClass: 'newLoanApp-modal',
          resolve: {
            props: () => {},
          },
        });
      } else {
        $uibModal.open({
          templateUrl: '/assets/views/createNewLoanApplicationModal.html',
          controller: 'NewLoanApplicationCtrl',
          size: 'lg',
          backdrop: 'static',
          windowClass: 'new-loan-modal loan-app-modal-v1',
          keyboard: false,
          backdropClass: 'newLoanApp-modal',
          openedClass: 'modal-w700',
        });
      }
    };

    this.openAddNewInsuranceApplicationModal = () => {
      $uibModal.open({
        templateUrl: '/assets/views/createNewInsuranceApplicationModal.html',
        controller: 'NewInsuranceApplicationCtrl',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
      });
    };

    this.stateGo = (stateName, config) => {
      $state.go(stateName, config);
    };

    this.stateIncludes = stateName => {
      $state.includes(stateName);
    };

    this.redirectToLoanProfiler = tabName => {
      const onCurrentPage = $state.includes('app.loanProfiler', {
        tab: tabName,
      });
      if (onCurrentPage) return;
      // Get Broker Event Id
      loanProfilerService.getServiceabilityNewBrokerEvent().then(response => {
        this.loanCalculatorLinking.resetLinker();
        $localStorage.contactLoanCalculatorLinker = {};
        this.stateGo('app.loanProfiler', {
          brokerEventId: response.data,
          loanScenarioId: 0,
          tab: tabName,
        });
      });
    };

    this.redirectToFundingCalculator = () => {
      if ($state.current.name === 'app.fundingCalculator') return;
      // Get Broker Event Id
      fundingCalculatorService
        .getFundingCalculatorNewBrokerEvent()
        .then(response => {
          this.loanCalculatorLinking.resetLinker();
          this.stateGo('app.fundingCalculator', {
            brokerEventId: response.data,
          });
        });
    };

    this.redirectToQuickLMICalculator = () => {
      // Get Broker Event Id
      fundingCalculatorService
        .getFundingCalculatorNewBrokerEvent()
        .then(response => {
          fundingCalculatorService.setFundingCalculatorFundsIsQuickLMI(
            response.data,
            true
          );
          this.stateGo('app.quickLMI', { brokerEventId: response.data });
        });
    };

    // Genral Search For Mobile
    this.redirectOnGenralSearchInMobile = genralSearchText => {
      this.stateGo('app.genralSearchInMobile', {
        searchText: genralSearchText,
      });
    };

    // Genral Serach Result Update
    this.updateGeneralSearchResult = genralSearchText => {
      this.generalSearchText = genralSearchText;
    };

    this.logoutUser = () => {
      if (!window.localStorage.getItem('myCRM_jwt'))
        return window.location.assign(configService.login);
      authService
        .logout()
        .then(() => {
          this.localStorage.isEnvNotifDismissed = false;
        })
        .finally(() => {
          window.location.assign(configService.login);
          window.localStorage.removeItem('myCRM_jwt');
          window.sessionStorage.clear();
        });
    };

    this.gotoContact = result => {
      switch (result.ContactType.toLowerCase()) {
        case 'adviser organisation':
          this.stateGo('app.contactsAdviserOrg', {
            adviserOrgId: result.FamilyId,
            adviserComplianceId: 0,
          });
          break;
        case 'adviser':
        case 'principal adviser':
          this.stateGo('app.contactsAdviser', {
            clientId: 0,
            familyId: result.FamilyId,
          });
          break;
        case 'assistant':
          this.stateGo('app.contactsAssistant', {
            clientId: 0,
            familyId: result.FamilyId,
          });
          break;
        case 'referrer organisation':
        case 'referrer org':
          this.stateGo('app.contactsReferrerOrg', {
            referrerComplianceId: 0,
            referrerOrgId: result.FamilyId,
          });
          break;
        case 'referrer':
          this.stateGo('app.contactsReferrer', {
            clientId: 0,
            familyId: result.FamilyId,
          });
          break;
        case 'businessentity':
          this.stateGo('app.BusinessAccount', { familyId: result.FamilyId });
          break;
        default:
          this.stateGo('app.contactsSingle', {
            familyId: result.FamilyId,
            activeTab: 'summary',
          });
          break;
      }
    };

    this.checkAliAccess = () => {
      toaster.pop('info', 'Authenticating ALI...');

      aliService.authAliGroup().then(
        response => {
          if (!response.succeeded) return displayInfo(response);

          $window.open(response.data, '_blank');
          toaster.clear();
          toaster.pop('success', 'ALI Authentication successful.');
        },
        error => {
          toaster.clear();
          swal('Oops!', 'ALI authentication failed.', 'error');
        }
      );
    };

    this.goToCreditChecks = () => {
      this.menuHideInMobile();
      if (!this.creditCheckSub) return;

      if (this.creditCheckSub.isActive) {
        this.stateGo('app.creditCheck');
      } else {
        this.getCurrentUserSubscriptions(() => {
          if (this.creditCheckSub.isActive) {
            this.stateGo('app.creditCheck');
            return;
          }

          creditCheckSubscriptionService
            .openCreditCheckSubModal(this.userFamilyId(), this.creditCheckSub)
            .then(response => {
              if (!response || !response.getAllSubscribe) return;
              this.creditCheckSub.isActive = true;
              this.stateGo('app.creditCheck');
            });
        });
      }
    };

    this.isCreditChecksEnabled = () => {
      return this.isCountry('Australia') && !this.isCorporateUser;
    };

    this.noEventProgation = e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    this.selectTab = selectedTab => {
      $timeout(() => {
        const el = $window.document.querySelectorAll(
          `#${selectedTab} .nav-link`
        );
        angular.element(el).triggerHandler('click');
      }, 0);
    };

    this.spokenLanguage = [
      { code: 'ab', name: 'Abkhaz', nativeName: 'аҧсуа' },
      { code: 'aa', name: 'Afar', nativeName: 'Afaraf' },
      { code: 'af', name: 'Afrikaans', nativeName: 'Afrikaans' },
      { code: 'ak', name: 'Akan', nativeName: 'Akan' },
      { code: 'sq', name: 'Albanian', nativeName: 'Shqip' },
      { code: 'am', name: 'Amharic', nativeName: 'አማርኛ' },
      { code: 'ar', name: 'Arabic', nativeName: 'العربية' },
      { code: 'an', name: 'Aragonese', nativeName: 'Aragonés' },
      { code: 'hy', name: 'Armenian', nativeName: 'Հայերեն' },
      { code: 'as', name: 'Assamese', nativeName: 'অসমীয়া' },
      { code: 'av', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
      { code: 'ae', name: 'Avestan', nativeName: 'avesta' },
      { code: 'ay', name: 'Aymara', nativeName: 'aymar aru' },
      { code: 'az', name: 'Azerbaijani', nativeName: 'azərbaycan dili' },
      { code: 'bm', name: 'Bambara', nativeName: 'bamanankan' },
      { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле' },
      { code: 'eu', name: 'Basque', nativeName: 'euskara, euskera' },
      { code: 'be', name: 'Belarusian', nativeName: 'Беларуская' },
      { code: 'bn', name: 'Bengali', nativeName: 'বাংলা' },
      { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी' },
      { code: 'bi', name: 'Bislama', nativeName: 'Bislama' },
      { code: 'bs', name: 'Bosnian', nativeName: 'bosanski jezik' },
      { code: 'br', name: 'Breton', nativeName: 'brezhoneg' },
      { code: 'bg', name: 'Bulgarian', nativeName: 'български език' },
      { code: 'my', name: 'Burmese', nativeName: 'ဗမာစာ' },
      { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català' },
      { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru' },
      { code: 'ce', name: 'Chechen', nativeName: 'нохчийн мотт' },
      {
        code: 'ny',
        name: 'Chichewa; Chewa; Nyanja',
        nativeName: 'chiCheŵa, chinyanja',
      },
      {
        code: 'zh',
        name: 'Chinese',
        nativeName: '中文 (Zhōngwén), 汉语, 漢語',
      },
      { code: 'cv', name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
      { code: 'kw', name: 'Cornish', nativeName: 'Kernewek' },
      { code: 'co', name: 'Corsican', nativeName: 'corsu, lingua corsa' },
      { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
      { code: 'hr', name: 'Croatian', nativeName: 'hrvatski' },
      { code: 'cs', name: 'Czech', nativeName: 'česky, čeština' },
      { code: 'da', name: 'Danish', nativeName: 'dansk' },
      { code: 'dv', name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
      { code: 'nl', name: 'Dutch', nativeName: 'Nederlands, Vlaams' },
      { code: 'en', name: 'English', nativeName: 'English' },
      { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto' },
      { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel' },
      { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe' },
      { code: 'fo', name: 'Faroese', nativeName: 'føroyskt' },
      { code: 'fj', name: 'Fijian', nativeName: 'vosa Vakaviti' },
      { code: 'fi', name: 'Finnish', nativeName: 'suomi, suomen kieli' },
      { code: 'fr', name: 'French', nativeName: 'français, langue française' },
      {
        code: 'ff',
        name: 'Fula; Fulah; Pulaar; Pular',
        nativeName: 'Fulfulde, Pulaar, Pular',
      },
      { code: 'gl', name: 'Galician', nativeName: 'Galego' },
      { code: 'ka', name: 'Georgian', nativeName: 'ქართული' },
      { code: 'de', name: 'German', nativeName: 'Deutsch' },
      { code: 'el', name: 'Greek, Modern', nativeName: 'Ελληνικά' },
      { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ' },
      { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી' },
      {
        code: 'ht',
        name: 'Haitian; Haitian Creole',
        nativeName: 'Kreyòl ayisyen',
      },
      { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
      { code: 'he', name: 'Hebrew (modern)', nativeName: 'עברית' },
      { code: 'hz', name: 'Herero', nativeName: 'Otjiherero' },
      { code: 'hi', name: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
      { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
      { code: 'hu', name: 'Hungarian', nativeName: 'Magyar' },
      { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua' },
      { code: 'id', name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
      {
        code: 'ie',
        name: 'Interlingue',
        nativeName: 'Originally called Occidental; then Interlingue after WWII',
      },
      { code: 'ga', name: 'Irish', nativeName: 'Gaeilge' },
      { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
      { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
      { code: 'io', name: 'Ido', nativeName: 'Ido' },
      { code: 'is', name: 'Icelandic', nativeName: 'Íslenska' },
      { code: 'it', name: 'Italian', nativeName: 'Italiano' },
      { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
      {
        code: 'ja',
        name: 'Japanese',
        nativeName: '日本語 (にほんご／にっぽんご)',
      },
      { code: 'jv', name: 'Javanese', nativeName: 'basa Jawa' },
      {
        code: 'kl',
        name: 'Kalaallisut, Greenlandic',
        nativeName: 'kalaallisut, kalaallit oqaasii',
      },
      { code: 'kn', name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
      { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri' },
      { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
      { code: 'kk', name: 'Kazakh', nativeName: 'Қазақ тілі' },
      { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
      { code: 'ki', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
      { code: 'rw', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
      { code: 'ky', name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
      { code: 'kv', name: 'Komi', nativeName: 'коми кыв' },
      { code: 'kg', name: 'Kongo', nativeName: 'KiKongo' },
      {
        code: 'ko',
        name: 'Korean',
        nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
      },
      { code: 'ku', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
      { code: 'kj', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
      { code: 'la', name: 'Latin', nativeName: 'latine, lingua latina' },
      {
        code: 'lb',
        name: 'Luxembourgish, Letzeburgesch',
        nativeName: 'Lëtzebuergesch',
      },
      { code: 'lg', name: 'Luganda', nativeName: 'Luganda' },
      {
        code: 'li',
        name: 'Limburgish, Limburgan, Limburger',
        nativeName: 'Limburgs',
      },
      { code: 'ln', name: 'Lingala', nativeName: 'Lingála' },
      { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ' },
      { code: 'lt', name: 'Lithuanian', nativeName: 'lietuvių kalba' },
      { code: 'lu', name: 'Luba-Katanga', nativeName: '' },
      { code: 'lv', name: 'Latvian', nativeName: 'latviešu valoda' },
      { code: 'gv', name: 'Manx', nativeName: 'Gaelg, Gailck' },
      { code: 'mk', name: 'Macedonian', nativeName: 'македонски јазик' },
      { code: 'mg', name: 'Malagasy', nativeName: 'Malagasy fiteny' },
      { code: 'ms', name: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
      { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം' },
      { code: 'mt', name: 'Maltese', nativeName: 'Malti' },
      { code: 'mi', name: 'Māori', nativeName: 'te reo Māori' },
      { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
      { code: 'mh', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
      { code: 'mn', name: 'Mongolian', nativeName: 'монгол' },
      { code: 'na', name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
      {
        code: 'nv',
        name: 'Navajo, Navaho',
        nativeName: 'Diné bizaad, Dinékʼehǰí',
      },
      { code: 'nb', name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
      { code: 'nd', name: 'North Ndebele', nativeName: 'isiNdebele' },
      { code: 'ne', name: 'Nepali', nativeName: 'नेपाली' },
      { code: 'ng', name: 'Ndonga', nativeName: 'Owambo' },
      { code: 'nn', name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
      { code: 'no', name: 'Norwegian', nativeName: 'Norsk' },
      { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
      { code: 'nr', name: 'South Ndebele', nativeName: 'isiNdebele' },
      { code: 'oc', name: 'Occitan', nativeName: 'Occitan' },
      { code: 'oj', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
      {
        code: 'cu',
        name:
          'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        nativeName: 'ѩзыкъ словѣньскъ',
      },
      { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo' },
      { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
      { code: 'os', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
      { code: 'pa', name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
      { code: 'pi', name: 'Pāli', nativeName: 'पाऴि' },
      { code: 'fa', name: 'Persian', nativeName: 'فارسی' },
      { code: 'pl', name: 'Polish', nativeName: 'polski' },
      { code: 'ps', name: 'Pashto, Pushto', nativeName: 'پښتو' },
      { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
      { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
      { code: 'rm', name: 'Romansh', nativeName: 'rumantsch grischun' },
      { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi' },
      {
        code: 'ro',
        name: 'Romanian, Moldavian, Moldovan',
        nativeName: 'română',
      },
      { code: 'ru', name: 'Russian', nativeName: 'русский язык' },
      { code: 'sa', name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
      { code: 'sc', name: 'Sardinian', nativeName: 'sardu' },
      { code: 'sd', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
      { code: 'se', name: 'Northern Sami', nativeName: 'Davvisámegiella' },
      { code: 'sm', name: 'Samoan', nativeName: 'gagana faa Samoa' },
      { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö' },
      { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
      { code: 'gd', name: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
      { code: 'sn', name: 'Shona', nativeName: 'chiShona' },
      { code: 'si', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
      { code: 'sk', name: 'Slovak', nativeName: 'slovenčina' },
      { code: 'sl', name: 'Slovene', nativeName: 'slovenščina' },
      { code: 'so', name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
      { code: 'st', name: 'Southern Sotho', nativeName: 'Sesotho' },
      {
        code: 'es',
        name: 'Spanish; Castilian',
        nativeName: 'español, castellano',
      },
      { code: 'su', name: 'Sundanese', nativeName: 'Basa Sunda' },
      { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili' },
      { code: 'ss', name: 'Swati', nativeName: 'SiSwati' },
      { code: 'sv', name: 'Swedish', nativeName: 'svenska' },
      { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்' },
      { code: 'te', name: 'Telugu', nativeName: 'తెలుగు' },
      { code: 'tg', name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
      { code: 'th', name: 'Thai', nativeName: 'ไทย' },
      { code: 'ti', name: 'Tigrinya', nativeName: 'ትግርኛ' },
      {
        code: 'bo',
        name: 'Tibetan Standard, Tibetan, Central',
        nativeName: 'བོད་ཡིག',
      },
      { code: 'tk', name: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
      {
        code: 'tl',
        name: 'Tagalog',
        nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
      },
      { code: 'tn', name: 'Tswana', nativeName: 'Setswana' },
      { code: 'to', name: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
      { code: 'tr', name: 'Turkish', nativeName: 'Türkçe' },
      { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga' },
      { code: 'tt', name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
      { code: 'tw', name: 'Twi', nativeName: 'Twi' },
      { code: 'ty', name: 'Tahitian', nativeName: 'Reo Tahiti' },
      { code: 'ug', name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
      { code: 'uk', name: 'Ukrainian', nativeName: 'українська' },
      { code: 'ur', name: 'Urdu', nativeName: 'اردو' },
      { code: 'uz', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
      { code: 've', name: 'Venda', nativeName: 'Tshivenḓa' },
      { code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt' },
      { code: 'vo', name: 'Volapük', nativeName: 'Volapük' },
      { code: 'wa', name: 'Walloon', nativeName: 'Walon' },
      { code: 'cy', name: 'Welsh', nativeName: 'Cymraeg' },
      { code: 'wo', name: 'Wolof', nativeName: 'Wollof' },
      { code: 'fy', name: 'Western Frisian', nativeName: 'Frysk' },
      { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa' },
      { code: 'yi', name: 'Yiddish', nativeName: 'ייִדיש' },
      { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá' },
      {
        code: 'za',
        name: 'Zhuang, Chuang',
        nativeName: 'Saɯ cueŋƅ, Saw cuengh',
      },
    ];
    this.getInitials = name => {
      if (!name) {
        return;
      }
      return name
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/\W*(\w)\w*/g, '$1')
        .toUpperCase();
    };
    this.navigate = () => {
      this.localStorage.sendEmailClientLinker = null;
      contactService.getUserInfo().then(response => {
        const userInfo = response.data;
        const whiteListedAccess = [
          ACCESS_TYPE.PRINCIPAL_ADVISER,
          ACCESS_TYPE.ADVISER,
          ACCESS_TYPE.ADMIN_ASSISTANT,
          ACCESS_TYPE.ASSISTANT,
        ];
        if (userInfo && whiteListedAccess.indexOf(userInfo.AccessType) >= 0) {
          $state.go('app.users');
        }
      });
    };

    this.generateClientHeaderName = (clientHeaderName, client, data) => {
      let newName = clientHeaderName;
      if (newName !== '') {
        newName += ` & ${client.FirstName} ${client.LastName}`;
      } else if (data.length > 1) {
        newName += client.FirstName;
      } else {
        newName += `${client.FirstName} ${client.LastName}`;
      }

      return newName;
    };

    this.redirectToUsers = () => {
      this.localStorage.sendEmailClientLinker = null;
      this.stateGo('app.users');
    };

    this.isShowCustomizeThemeFunction = infoResponse => {
      const { data } = infoResponse;
      if (!data) return;
      const isCustomizeThemeFeatureEnabled =
        configService.feature && configService.feature.customizeTheme;
      this.isShowCustomizeTheme =
        ((data.IsAllowCustomizedTheme && isCustomizeThemeFeatureEnabled) ||
          COUNTRY_TYPE.NEW_ZEALAND === data.CountryId) &&
        !this.isCorporateUser;
    };

    this.initProfilePicture = infoResponse => {
      const { data } = infoResponse;
      if (!data) return;
      this.profilePicture = data.ProfilePicture;
    };
  }).name;
