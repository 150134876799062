import angular from 'angular';
import createNewLoanApplication from './createNewLoanApplication';
import borrowers from './borrowers';
import guarantors from './guarantors';
import familyContainer from './familyContainer';
import searchExistingClientPanel from './searchExistingClientPanel';

export default angular
  .module('components.newLoanApplicationModal', [])
  .component('createNewLoanApplication', createNewLoanApplication)
  .component('borrowers', borrowers)
  .component('guarantors', guarantors)
  .component('familyContainer', familyContainer)
  .component('searchExistingClientPanel', searchExistingClientPanel).name;
