import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ADVISER_TAB } from 'Common/constants/contactTabIndex';

angular
  .module('app')
  .controller('CorporateSendEmailCtrl', function CorporateSendEmailCtrl(
    $scope,
    $uibModal,
    contactService,
    SweetAlert,
    dashboardService,
    corporateService,
    $rootScope,
    $state,
    broadcastDataService,
    $window,
    commonFnService,
    templateService,
    customerCareTemplateService
  ) {
    $scope.recipients = {};
    $scope.selectEmail = [];
    $scope.loginUseInfo = {};
    $scope.templates = {};
    $scope.attachedFile = [];
    $scope.attachments = [];
    $scope.email = {};

    dashboardService.getBrokerBasicInfo().then(response => {
      $scope.loginUseInfo = response.data;
      $scope.email = {
        Subject: '',
        Content: '',
        ReplyTo: {
          EmailAddress: $scope.loginUseInfo.Email[0].EmailAddress,
        },
        SentDate: new Date(),
        Attachment: [],
        From: {
          EmailAddress: 'email@nzfsg.co.nz',
        },
        FamilyID: $scope.familyId,
      };
    });

    $scope.familyId = $state.params.familyId;
    $scope.clientId = $state.params.clientId;
    function getCorporateEmailTemplates(familyName) {
      corporateService.getEmailTemplates(0).then(response => {
        $scope.emailTemplateListing = _.filter(
          templateService.filterTemplatesByUserType(response.data),
          folder => !templateService.isBlankFolderOrTemplate(folder)
        );
        for (let i = 0; i < $scope.emailTemplateListing.length; i++) {
          if ($scope.emailTemplateListing[i].EmailTemplates.length > 0) {
            for (
              let j = 0;
              j < $scope.emailTemplateListing[i].EmailTemplates.length;
              j++
            ) {
              if (
                $scope.emailTemplateListing[i].EmailTemplates[j]
                  .UserTemplateContent !== ''
              ) {
                $scope.emailTemplateListing[i].EmailTemplates[
                  j
                ].UserTemplateContent = $scope.emailTemplateListing[
                  i
                ].EmailTemplates[j].UserTemplateContent.toString().replace(
                  '[Preferred Name]',
                  familyName
                );
              }
            }
          }
        }
      });
    }

    function getMergeFields() {
      $scope.preferredName = '';
      if ($state.params.familyId !== undefined) {
        $scope.familyId = $state.params.familyId;
      } else {
        $scope.familyId = 0;
      }
      if ($state.params.clientId !== undefined) {
        $scope.clientId = $state.params.clientId;
      } else {
        $scope.clientId = 0;
      }
      $scope.loanId = 0;

      corporateService
        .MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId)
        .then(response => {
          $scope.emailMergeFieldList = response.data;
          $scope.mergeFieldsObj = response.data[0];
          $scope.mergeFields = [];
          $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
          $scope.preferredName = $scope.mergeFields.reduce(
            (accum, currentVal) => {
              const val =
                currentVal.Name === 'Full Name' ? currentVal.Value : '';
              return `${accum}${val}`;
            },
            ''
          );

          getCorporateEmailTemplates($scope.preferredName);
        });
    }
    getMergeFields();
    $scope.emailSharedData = broadcastDataService;

    $scope.$watch('emailSharedData.emailSendingPage.isTriggered', () => {
      if ($scope.emailSharedData.emailSendingPage.isTriggered) {
        getCorporateEmailTemplates('');
        $scope.emailSharedData.emailSendingPage.isTriggered = false;
      }
    });

    $scope.templates.selectedTemplate = [
      {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
      },
    ];

    // Get adviser information
    $scope.adviserDetails = {};
    corporateService
      .corporateContactGet($scope.clientId, $scope.familyId)
      .then(response => {
        $scope.adviserDetails = response.data;
        $scope.recipients.name = [
          `${$scope.adviserDetails.FirstName} ${
            $scope.adviserDetails.LastName
          } [ ${$scope.noEmail() ? 'No Email' : $scope.adviserDetails.Email} ]`,
        ];
        $scope.recipients.email = [$scope.adviserDetails.Email];
      });

    $scope.noEmail = function() {
      return (
        typeof $scope.adviserDetails.Email === 'undefined' ||
        $scope.adviserDetails.Email === ''
      );
    };

    $scope.openEmailManagement = function() {
      $uibModal.open({
        templateUrl:
          'assets/views/corporate/emailManagement/emailManagement.html',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        controller: 'EmailManagementCtrl',
      });
    };

    $scope.tmpAttachedFile = [];
    const attachFile = arr => {
      $scope.attachedFile = arr;
      $scope.attachedFile &&
        Object.keys($scope.attachedFile).forEach(x => {
          $scope.tmpAttachedFile.push($scope.attachedFile[x]);
        });

      $scope.attachments = $scope.tmpAttachedFile;
    };

    // Attach files

    $scope.attachments = $scope.attachedFile[0];
    $scope.openAttachMent = function() {
      const modalInstance = commonFnService.attachFileMgmt($scope.familyId);
      modalInstance.result.then(attachFile);
    };

    $scope.setTemplateSelection = function(id, name, content) {
      $scope.templates.selectedTemplate = [
        {
          UserTemplateID: id,
          UserTemplateName: name,
        },
      ];
      // This will check if $scope.email exist. Previously no declared $scope.email
      // that fall to possible error. And if content is undefined it should initialized with empty string
      if ($scope.email) {
        $scope.email.Content = content || '';
      }
    };

    $scope.init = function() {
      // Ckeditor Tollbar Configuration
      $scope.options = {
        language: 'en',
        allowedContent: true,
        uiColor: '#DFD6E4',
        height: 300,
        resize_enabled: false,
        removePlugins: 'elementspath',
        toolbar: [
          { items: ['BGColor'] },
          { items: ['JustifyLeft'] },
          { items: ['BulletedList', '-'] },
          { items: ['Link'] },
          { items: ['Image'] },
          { items: ['Table'] },
          { items: ['Paste', '-'] },
          { items: ['Smiley'] },
          { items: ['-'] },
          '/',
          { items: ['Format'] },
          { items: ['Font'] },
          { items: ['FontSize', '-'] },
          { items: ['Bold'] },
          { items: ['Italic'] },
          { items: ['Underline'] },
          { items: ['Subscript'] },
          { items: ['Superscript'] },
        ],
      };

      // Get Email Template Category
      $scope.emailTemplateCategoryList = [];
      $scope.getemailTemplateCategory = function() {
        $scope.emailTemplateCategoryList = customerCareTemplateService.getEmailTemplateCategory(
          $scope.familyId
        );
      };
      $scope.getemailTemplateCategory();

      // List Of Recipient
      $scope.getRecipientAutoSuggestion = function(userInput) {
        contactService.getRecipientAutoSuggestion(userInput).then(response => {
          return response.data;
        });
      };
    };

    $scope.init();

    // Send Email
    $scope.sendEmail = function(formSendEmail) {
      let firstError = null;
      if (formSendEmail.$invalid) {
        formSendEmail &&
          Object.keys(formSendEmail).forEach(field => {
            if (field[0] !== '$') {
              if (firstError === null && !formSendEmail[field].$valid) {
                firstError = formSendEmail[field].$name;
              }
              if (formSendEmail[field].$pristine) {
                formSendEmail[field].$dirty = true;
              }
            }
          });
        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        return;
      }
      $scope.recipientsEmailArray = [];
      angular.forEach($scope.recipients.email, item => {
        $scope.recipientsEmailArray.push({ EmailAddress: item });
      });

      $scope.email.Recipients = $scope.recipientsEmailArray;
      $scope.email.Attachment = $scope.attachments;

      contactService.sendEmail($scope.email).then(() => {
        $scope.email.Subject = '';
        $scope.email.Content = '';
        formSendEmail.$setPristine(true);
        $window.sessionStorage.setItem(
          'currentTab',
          ADVISER_TAB.COMMIUNICATIONS
        );
        swal(
          {
            title: 'Success!',
            text: 'Email has been sent.',
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Go to Communication',
            closeOnConfirm: true,
          },
          () => {
            $state.go('app.contactsAdviser', {
              familyId: $scope.familyId,
              clientId: $scope.clientId,
            });
          }
        );
      });
    };

    // Get Email Template Content
    $scope.getEmailTemplateContent = function(template) {
      _.map($scope.emailTemplateCategoryList, item => {
        if (!item.HasSubCategory) {
          if (
            parseInt(item.Template.UserTemplateID, 10) ===
            parseInt(template.UserTemplateID, 10)
          ) {
            item.Template.isSelected = true;
          } else {
            item.Template.isSelected = false;
          }
        } else {
          angular.forEach(item.ListOfTemplates, innerItem => {
            if (
              parseInt(innerItem.UserTemplateID, 10) ===
              parseInt(template.UserTemplateID, 10)
            ) {
              innerItem.isSelected = true;
            } else {
              innerItem.isSelected = false;
            }
          });
        }
      });
      contactService
        .getEmailTemplateContent(template.UserTemplateID, $scope.familyId)
        .then(response => {
          $scope.email.Content = response.data.TemplateContent;
          $scope.email.Subject = response.data.UserTemplateEmailSubject;
        });
    };

    // Called when the editor is completely ready.
    $scope.onReady = function() {};

    // Opern Model For Quick Start Video
    $scope.openModalForQuickStartVideo = function(size) {
      $uibModal.open({
        templateUrl:
          '/assets/views/contacts/partials/quick_start_video_modal.html',
        scope: $scope,
        controller($uibModalInstance) {
          $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
          };
        },
        size,
      });
    };
  });
