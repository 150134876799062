import './style.scss';
import SaveToContactCtrl from './saveToContactCtrl';

export default {
  bindings: {
    formData: '<',
    buttonLabel: '@',
    iconClass: '@',
    forModule: '@',
    buttonClass: '@',
    listenFrom: '<',
  },
  controller: SaveToContactCtrl,
  controllerAs: 'vm',
  template: `
  <button class="{{vm.buttonClass}}" ng-if="vm.listenFrom" ng-click="vm.clickListener('app')"><i class="{{vm.iconClass}}"></i>{{vm.buttonLabel}}</button>
  <button class="{{vm.buttonClass}}" ng-if="!vm.listenFrom" ng-click="vm.clickListener('save')"><i class="{{vm.iconClass}}"></i>{{vm.buttonLabel}}</button>
  `,
};
