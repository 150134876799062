import angular from 'angular';

angular
  .module('app')
  .controller('SaveContactModalCtrl', function SaveContactModalCtrl(
    $scope,
    $uibModalInstance,
    createNewLoanScenario,
    saveToContact,
    resetSaveToContact,
    innerResetObject,
    loanProfilerSharedData,
    createNewContact,
    showCreateNewContact,
    searchClientList,
    checkIsShowNewContact,
    checkIsShowSaveContact,
    mainSearchObject
  ) {
    $scope.showCreateNewContact = showCreateNewContact;
    $scope.searchClientList = searchClientList;
    $scope.mainSearchObject = mainSearchObject;
    $scope.createNewLoanScenario = createNewLoanScenario;
    $scope.saveToContact = saveToContact;
    $scope.resetSaveToContact = resetSaveToContact;
    $scope.innerResetObject = innerResetObject;
    $scope.selectClient = loanProfilerSharedData.selectClient;
    $scope.getSearchClient = loanProfilerSharedData.getSearchClient;
    $scope.checkIsShowNewContact = checkIsShowNewContact;
    $scope.checkIsShowSaveContact = checkIsShowSaveContact;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.createNewLoanScenarioInMobile = function() {
      $scope.createNewLoanScenario();
      $scope.cancel();
    };

    $scope.saveToContactInMobile = function() {
      $scope.saveToContact();
      $scope.cancel();
    };

    $scope.createNewContact = () => {
      $scope.cancel();
      createNewContact();
    };
  });
