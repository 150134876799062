import angular from 'angular';

angular.module('app').directive('vbox', function vbox() {
  return {
    link(scope, element, attrs) {
      attrs.$observe('vbox', value => {
        element.attr('viewBox', value);
      });
    },
  };
});
