import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('upcomingBirthdaysSmall', function upcomingBirthdaysSmall(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/upcomingBirthdaysSmall.html',

      link(scope) {
        scope.upcomingBirthdaysMultiAdviser = [];
        scope.upcomingBirthdaysMultiAdviserObj = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];
        scope.upcomingBirthdayTableData = {};
        scope.upcomingBirthdayDropDownList = [];
        scope.objForWidgetOptionSet = {};

        function UpcomingBirthdaySmallGet() {
          scope.userWidgetId = {};
          scope.userWidgetId = scope.myParam;

          overviewDashboardService
            .UpcomingBirthdaySmallGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.upcomingBirthdaySmallData = response.data;
              },
              () => {}
            );
        }

        function WidgetOptionTimePeriodListv2Get() {
          overviewDashboardService.WidgetOptionTimePeriodListv2Get().then(
            response => {
              scope.upcomingBirthdayDropDownList = response.data;
              scope.upcomingBirthdayTableData =
                scope.upcomingBirthdayDropDownList[0];
              scope.filteredUpcomingBirthdayDropDownList = [];

              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  () => {
                    scope.WidgetOptionGetData = response.data;
                    for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                      if (
                        parseInt(
                          scope.WidgetOptionGetData[i].WidgetOptionId,
                          10
                        ) === 4
                      ) {
                        const widgetOptionValue =
                          scope.WidgetOptionGetData[i].Widget_OptionValue;
                        scope.filteredUpcomingBirthdayDropDownList = scope.upcomingBirthdayDropDownList.filter(
                          item =>
                            parseInt(item.OptionValueId, 10) ===
                            parseInt(widgetOptionValue, 10)
                        );

                        scope.upcomingBirthdayTableData =
                          scope.filteredUpcomingBirthdayDropDownList[0];
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(UpcomingBirthdaySmallGet)
            .catch(displayError);
        }

        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet().then(
            response => {
              scope.WidgetOptionAdviserList = response.data;
              for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                scope.upcomingBirthdaysMultiAdviserObj = {
                  FamilyName: scope.WidgetOptionAdviserList[i].FamilyName,
                  FamilyId: scope.WidgetOptionAdviserList[i].FamilyId,
                  OptionValueId: scope.WidgetOptionAdviserList[i].OptionValueId,
                  OptionDB_Value:
                    scope.WidgetOptionAdviserList[i].OptionDB_Value,
                  ticked: false,
                  WidgetOptionId: scope.WidgetOptionAdviserList[i].OptionId,
                };

                scope.upcomingBirthdaysMultiAdviser.push(
                  scope.upcomingBirthdaysMultiAdviserObj
                );
              }

              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  () => {
                    scope.WidgetOptionGetData = response.data;

                    scope.WidgetOptionGetAdviser = {};
                    scope.Advisers = [];
                    for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                      if (
                        parseInt(
                          scope.WidgetOptionGetData[j].WidgetOptionId,
                          10
                        ) === 3
                      ) {
                        scope.Advisers =
                          scope.WidgetOptionGetData[j].SelectedUerValues;
                        const AdvisersArray = scope.Advisers.split(',');

                        scope.upcomingBirthdaysMultiAdviser = scope.upcomingBirthdaysMultiAdviser.map(
                          obj => {
                            if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                              obj.ticked = true;
                              return obj;
                            }

                            return obj;
                          }
                        );
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }

        scope.showNextVal = function(displayObj) {
          scope.currentObj = displayObj;
          const displayPos = scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;
          if (arrayPos + 1 > scope.upcomingBirthdayDropDownList.length - 1) {
            scope.upcomingBirthdayTableData =
              scope.upcomingBirthdayDropDownList[0];
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                response => {
                  scope.WidgetOptionGetData = response.data;
                },
                () => {}
              );
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.upcomingBirthdayTableData.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.upcomingBirthdayTableData.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }
          scope.upcomingBirthdayTableData =
            scope.upcomingBirthdayDropDownList[arrayPos + 1];
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;
              },
              () => {}
            );
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.upcomingBirthdayTableData.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.upcomingBirthdayTableData.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };
        scope.showPrevVal = function(displayObj) {
          scope.currentObj = displayObj;
          const displayPos = scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;

          if (arrayPos - 1 === -1) {
            const lastPos = scope.upcomingBirthdayDropDownList.length - 1;
            scope.upcomingBirthdayTableData =
              scope.upcomingBirthdayDropDownList[lastPos];
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.upcomingBirthdayTableData.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue: scope.upcomingBirthdayTableData.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }
          scope.upcomingBirthdayTableData =
            scope.upcomingBirthdayDropDownList[arrayPos - 1];
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.upcomingBirthdayTableData.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue: scope.upcomingBirthdayTableData.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };
        scope.updateMultiselectFilterData = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.upcomingBirthdaysMultiAdviser, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.upcomingBirthdaysMultiAdviser, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.upcomingBirthdaysMultiAdviser) !== 0) {
              scope.upcomingBirthdaysMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.upcomingBirthdaysMultiAdviser
              .filter(item => item.FamilyName !== 'All' && item.ticked === true)
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue =
              scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.upcomingBirthdaysMultiAdviser = scope.upcomingBirthdaysMultiAdviser.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.upcomingBirthdaysMultiAdviser[0].FamilyId,
            });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue =
              scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };

        function init() {
          UpcomingBirthdaySmallGet();
          WidgetOptionTimePeriodListv2Get();
          WidgetOptionAdviserListGet();
        }
        scope.doAction = function(msg) {
          if (msg === 'edit') scope.customizeMode = true;
          else scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else scope.customizeMode = true;
        };

        init();
      },
    };
  });
