export default class FamilyContainerCtrl {
  constructor() {
    'ngInject';

    this.selectExistingClient = this.selectExistingClient.bind(this);
  }

  $onInit() {
    this.isShowAddExistingPanel = false;
    this.familyType = this.isPersons ? 'Person' : 'Company';
    this.enableOffClick = false;
    this.isAddDisabled = !this.loanAdviser || !this.loanAdviser.familyId;
  }

  $onChanges(changes) {
    const { loanAdviser } = changes;
    if (loanAdviser && loanAdviser.currentValue) {
      this.isAddDisabled = !this.loanAdviser || !this.loanAdviser.familyId;
    }
  }

  showExistingPanel() {
    this.enableOffClick = !this.enableOffClick;
    this.isShowAddExistingPanel = !this.isShowAddExistingPanel;
  }

  selectExistingClient(params) {
    this.showExistingPanel();
    const param = {
      client: params.client,
      clientList: this.clientList,
      requesterType: this.requesterType,
      partyType: this.partyType,
    };
    this.onSelectClient({ $e: { ...param } });
  }
}
