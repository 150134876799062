class ConnectService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'connect';
  }

  connectLogs(fromDate, ToDate, eventType, messageId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ConnectLogs`, {
      fromDate,
      ToDate,
      eventType,
      messageId,
    });
  }
  welcomeEmail(familyId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/welcome-email`,
      {},
      { familyId }
    );
  }
  resetPassword(familyId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/reset-password`,
      {},
      { familyId }
    );
  }
}

export default ConnectService;
