import { camelize } from 'Common/utilities/string';

export function collectionCamelize(collection) {
  let newObject = {};
  if (collection && collection instanceof Array) {
    newObject = collection.map(value => {
      return typeof value === 'object' ? collectionCamelize(value) : value;
    });
  } else {
    collection &&
      Object.keys(collection).forEach(origKey => {
        if (typeof collection[origKey] !== 'undefined') {
          const newKey = camelize(origKey);
          const value = collection[origKey];
          const isToCamelize =
            value instanceof Array ||
            (value !== null && value.constructor === Object);
          newObject[newKey] = isToCamelize ? collectionCamelize(value) : value;
        }
      });
  }
  return newObject;
}
