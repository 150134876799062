import template from './insuranceNotesTemplateSelector.html';
import controller from './insuranceNotesTemplateSelectorCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    benefitTypeId: '<',
    benefitTypeName: '<',
    contentForUpdate: '<',
    onTemplateSelected: '&',
  },
};
