import template from './workbenchTabs.html';
import WorkbenchTabsCtrl from './workbenchTabsCtrl';

export default {
  template,
  bindings: {
    loanId: '<',
    familyId: '<',
    clientsList: '<',
    familyList: '<',
    workbenchView: '<',
    onViewChange: '&',
    onEmailEdit: '&',
    onMailEdit: '&',
    onNoteEdit: '&',
    onRefreshLog: '&',
    logList: '=',
  },
  controller: WorkbenchTabsCtrl,
  controllerAs: 'vm',
};
