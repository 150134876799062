import { getUserInfoData } from 'Common/utilities/user';
import { userInfoBuilderForUI } from 'Common/mappers/contact';

class CurrentUserService {
  constructor() {
    const user = getUserInfoData();

    Object.assign(this, userInfoBuilderForUI(user));
  }

  isCountry(country) {
    return this.crmCountry === country;
  }
}

export default CurrentUserService;
