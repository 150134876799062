import template from './complianceReviewRatingModal.html';
import ComplianceReviewRatingModalCtrl from './complianceReviewRatingModalCtrl';

export default {
  template,
  controller: ComplianceReviewRatingModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    comment: '<',
  },
};
