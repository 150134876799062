import angular from 'angular';
import employmentModal from './employmentModal';
import employmentDetails from './employmentDetails';
import employmentInfo from './employmentInfo';
import personList from './employmentInfo/personList';
import income from './income';
import saveEmployment from './saveEmployment';

export default angular
  .module('components.employmentModal', [])
  .component('employmentModal', employmentModal)
  .component('employmentDetails', employmentDetails)
  .component('employmentInfo', employmentInfo)
  .component('personList', personList)
  .component('income', income)
  .component('saveEmployment', saveEmployment).name;
