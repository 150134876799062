import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('tasksTable', function tasksTable(
    $uibModal,
    $filter,
    $timeout,
    $window,
    overviewDashboardService,
    contactService,
    dashboardService,
    toaster,
    uiService,
    eventService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/tasksTable.html',

      link(scope) {
        scope.tableMode = true;
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };

        scope.tasksTableData = [];
        scope.WidgetOptionAdviserList = [];
        scope.widgetOptionAdviser = {};
        scope.webBrowsersGrouped = [];
        scope.webBrowsersGroupedObj = {};
        scope.tasksTableDropDownList = [];
        scope.tasksTableDropDowns = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];
        scope.filteredTasksTableDropDownList = [];
        scope.tasksMultiAdviserList = [];
        scope.isFiltered = function(filteredOption) {
          scope.val = filteredOption;
          scope.setIsFiltered = {};

          if (scope.val === 'No') {
            scope.makeSelectNone();
          } else {
            scope.switchsetting = 'Yes';
          }
        };

        function TasksTableGet() {
          overviewDashboardService
            .TasksTableGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.allTaskList = [];
                scope.dueToday = 0;
                scope.tasksTableData = [];
                const totalData = response.data.length;
                scope.allTaskList = response.data;

                let idForCbo = 1;
                for (let j = 0; j < response.data.length; j++) {
                  response.data[j].idForCbo = idForCbo++;
                  if (response.data[j].IsDueToday) {
                    scope.dueToday += 1;
                  }
                }
                const round = Math.floor(totalData / 3) * 3 - 1;
                const modStart = Math.floor(totalData / 3) * 3;
                const mod = Math.floor(totalData % 3);
                for (let i = 0; i < round; i += 3) {
                  const testData = [];
                  testData.push(response.data[i]);
                  testData.push(response.data[i + 1]);
                  testData.push(response.data[i + 2]);

                  scope.tasksTableData.push(testData);
                }

                if (parseInt(mod, 10) !== 0) {
                  const lastItem = [];
                  for (let i = modStart; i < totalData; i++) {
                    lastItem.push(response.data[i]);
                  }
                  scope.tasksTableData.push(lastItem);
                }

                scope.dataLoaded = true;
                if (scope.tasksTableData.length > 0) {
                  scope.refreshSlick();
                  $timeout(() => {
                    try {
                      scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1000);
                }
              },
              () => {}
            );
        }

        function widgetOptionGet() {
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;

                scope.WidgetOptionGetYesNo = {};
                scope.WidgetOptionGetAdviser = {};
                scope.Advisers = [];

                for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 2
                  ) {
                    scope.WidgetOptionGetYesNo.DisplayValue =
                      scope.WidgetOptionGetData[i].DisplayValue;
                  }
                  let FlagAdviser;
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 3
                  ) {
                    FlagAdviser = 1;
                    scope.switchsetting = 'Yes';
                    scope.Advisers =
                      scope.WidgetOptionGetData[i].SelectedUerValues;
                    const AdvisersArray = scope.Advisers.split(',');

                    scope.tasksMultiAdviserList = scope.tasksMultiAdviserList.map(
                      obj => {
                        if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }
                        return obj;
                      }
                    );
                  } else if (
                    scope.WidgetOptionGetData[i].OptionName !== 'Adviser' &&
                    scope.switchsetting !== 'Yes' &&
                    FlagAdviser !== 1
                  ) {
                    scope.switchsetting = 'No';
                  }
                  let FlagType;
                  let widgetOptionValue;
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 8
                  ) {
                    FlagType = 1;
                    scope.switchsetting3 = 'Yes';
                    widgetOptionValue =
                      scope.WidgetOptionGetData[i].Widget_OptionValue;
                    scope.filteredTasksTableType = scope.tasksTypes.filter(
                      item => {
                        if (item.OptionValueId === widgetOptionValue) {
                          return item;
                        }
                        return false;
                      }
                    );

                    scope.tasksType = scope.filteredTasksTableType[0];
                  } else if (
                    scope.WidgetOptionGetData[i].OptionName !== 'Adviser' &&
                    scope.switchsetting3 !== 'Yes' &&
                    FlagType !== 1
                  ) {
                    scope.switchsetting3 = 'No';
                  }
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 10
                  ) {
                    widgetOptionValue =
                      scope.WidgetOptionGetData[i].Widget_OptionValue;
                    scope.filteredTasksTableDropDownList = scope.tasksTableDropDownList.filter(
                      item => {
                        if (item.OptionValueId === widgetOptionValue) {
                          return item;
                        }
                        return false;
                      }
                    );

                    scope.tasksTableDropDowns =
                      scope.filteredTasksTableDropDownList[0];
                  }
                }
              },
              () => {}
            );
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(() => {
              TasksTableGet();
              widgetOptionGet();
            })
            .catch(displayError);
        }

        scope.makeSelectNone = function() {
          for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
            scope.WidgetOptionAdviserList[i].ticked = false;
            scope.tasksMultiAdviserList.push(scope.WidgetOptionAdviserList[i]);
          }
          scope.setIsFiltered.Widget_OptionValue = '';
          scope.setIsFiltered.WidgetOptionId = 3;
          scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
          scope.setIsFiltered.SelectedUser = [];
          saveForTimePeriod(scope.setIsFiltered);
        };

        scope.isOff = function(option) {
          scope.val = option;
          scope.setIsFiltered = {};

          if (scope.val === 'No') {
            scope.makeSelectNoneTaskType();
          } else {
            scope.tasksType = scope.tasksTypes[0];
            scope.setIsFiltered.Widget_OptionValue =
              scope.tasksTypes[0].OptionValueId;
            scope.setIsFiltered.WidgetOptionId = scope.tasksTypes[0].OptionId;
            scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
            scope.setIsFiltered.SelectedUser = [];
            saveForTimePeriod(scope.setIsFiltered);

            scope.switchsetting3 = 'Yes';
          }
        };

        scope.makeSelectNoneTaskType = function() {
          scope.setIsFiltered.Widget_OptionValue = '';
          scope.setIsFiltered.WidgetOptionId = 8;
          scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
          scope.setIsFiltered.SelectedUser = [];
          saveForTimePeriod(scope.setIsFiltered);
        };

        scope.getInitials = function(name) {
          return uiService.getInitials(name);
        };

        scope.openModal = function(size, Id) {
          $uibModal.open({
            templateUrl:
              '/assets/views/overviewDashboard/partials/update_task_details.html',
            resolve: {
              allTaskList() {
                return scope.allTaskList;
              },
            },
            /* eslint-disable no-shadow */
            controller(scope, $uibModalInstance, allTaskList) {
              scope.selectedTask = {};

              contactService.taskAdviserListGet().then(respond => {
                scope.taskAdviserList = respond.data;
              });
              dashboardService.getBrokerBasicInfo().then(response => {
                scope.brokerId = response.data.BrokerId;
                scope.selectedTask.BrokerID = parseInt(scope.brokerId, 10);
              });
              scope.addNotes = function() {
                scope.showNotes = true;
              };
              scope.allTaskList = allTaskList;

              const cbxIndex = Id;
              let defaultDate;
              for (let i = 0; i < scope.allTaskList.length; i++) {
                if (scope.allTaskList[i].idForCbo === cbxIndex) {
                  scope.selectedTask.taskTitle = scope.allTaskList[i].Title;

                  if (scope.allTaskList[i].Notes) {
                    scope.showNotes = true;
                    scope.selectedTask.Notes = scope.allTaskList[i].Notes;
                  }
                  scope.selectedTask.FamilyID = scope.allTaskList[i].FamilyId;
                  scope.selectedTask.TaskType =
                    scope.allTaskList[i].WorkflowType;
                  scope.selectedTask.TaskCategory =
                    scope.allTaskList[i].TaskCategory;
                  scope.selectedTask.TaskID = scope.allTaskList[i].WidgetItemId;
                  // scope.selectedTask.BrokerID=scope.brokerId;
                  const date = scope.allTaskList[i].DueDate;
                  defaultDate = scope.allTaskList[i].DueDate;
                  scope.selectedTask.DueDate = moment(date).format(
                    'D MMM YYYY h:mma'
                  );
                }
              }
              scope.taskSet = function(taskObj) {
                scope.taskObj = {};
                scope.taskObj.FamilyID = taskObj.FamilyID;
                scope.taskObj.Title = taskObj.taskTitle;
                scope.taskObj.assignedClientID = taskObj.BrokerID;
                scope.taskObj.DueDate = taskObj.DueDate;
                scope.taskObj.TaskType = taskObj.TaskType;
                scope.taskObj.TaskCategory = taskObj.TaskCategory;
                scope.taskObj.Notes = taskObj.Notes;
                scope.taskObj.TaskID = taskObj.TaskID;
                contactService
                  .taskSet(scope.taskObj)
                  .then(() => {
                    toaster.pop(
                      'success',
                      'Updated',
                      'Task successfully updated'
                    );
                    TasksTableGet();
                    $timeout(() => {
                      $uibModalInstance.dismiss('cancel');
                    }, 100);
                  })
                  .catch(displayError);
              };

              $timeout(() => {
                angular.element('#due-date').datetimepicker({
                  inline: true,
                  sideBySide: true,
                  debug: false,
                  defaultDate,
                });
                scope.duedateview = 'date';

                angular
                  .element($window.document)
                  .on('dp.change', '#due-date', e => {
                    scope.selectedTask.DueDate = moment(e.date._d).format(
                      'D MMM YYYY h:mma'
                    );
                  });
              });

              scope.$watch('duedateview', newVal => {
                if (newVal === 'date') {
                  angular.element('.datepicker').show();
                  angular.element('.timepicker').hide();
                  angular
                    .element('.modal-actions')
                    .clone()
                    .appendTo('.datepicker');
                } else {
                  angular.element('.timepicker').show();
                  angular.element('.datepicker').hide();
                  angular
                    .element('.modal-actions')
                    .clone()
                    .appendTo('.timepicker');
                }
              });
              scope.closeModal = function() {
                $uibModalInstance.dismiss('cancel');
              };
            },
            size,
          });
        };
        scope.taskCompletionSet = function(obj) {
          const taskObj = obj;
          scope.taskCompletionObj = {};
          scope.taskCompletionObj.familyID = taskObj.FamilyId;
          // scope.taskCompletionObj.taskID=284811;

          scope.taskCompletionObj.taskId = taskObj.WidgetItemId;
          scope.taskCompletionObj.isComplete = taskObj.IsCompleted;
          contactService
            .setTaskCompletion(scope.taskCompletionObj)
            .then(() => {
              toaster.pop(
                'success',
                'Completed',
                'Task has been updated successfully.'
              );
            })
            .catch(displayError);
        };

        scope.refreshSlick = function() {
          scope.showSlick = false;
          $timeout(() => {
            scope.showSlick = true;
          }, 300);
        };

        scope.updateTimePeriod = function(tasksTableDropDowns) {
          scope.tasksTableDropDownsObj = tasksTableDropDowns;
          scope.tasksTableDropDownsObjToSet = {};

          scope.tasksTableDropDownsObjToSet.Widget_UserID =
            scope.myParam.Widget_UserID;
          scope.tasksTableDropDownsObjToSet.Widget_OptionValue =
            scope.tasksTableDropDownsObj.OptionValueId;
          scope.tasksTableDropDownsObjToSet.WidgetOptionId =
            scope.tasksTableDropDownsObj.OptionId;
          scope.tasksTableDropDownsObjToSet.SelectedUser = null;
          saveForTimePeriod(scope.tasksTableDropDownsObjToSet);
        };
        scope.updateMultiselectFilterDataAdviser = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.tasksMultiAdviserList, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.tasksMultiAdviserList, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.tasksMultiAdviserList) !== 0) {
              scope.tasksMultiAdviserList[0].ticked = false;
            }

            dataToSend = scope.tasksMultiAdviserList
              .filter(item => {
                if (item.FamilyName !== 'All' && item.ticked) {
                  return item;
                }
                return false;
              })
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue =
              scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => {
              if (item.FamilyName === 'All') {
                return item;
              }
              return false;
            });
            if (all.length > 0 && scope.outp.length > 1) {
              scope.tasksMultiAdviserList = scope.tasksMultiAdviserList.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.tasksMultiAdviserList[0].FamilyId,
            });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue =
              scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };
        scope.callServerWithUpdatedvalue = function() {
          overviewDashboardService.WidgetYesNoGet().then(
            response => {
              scope.YesNoData = response.data;
              for (let i = 0; i < scope.YesNoData.length; i++) {
                if (
                  scope.WidgetOptionGetYesNo.DisplayValue ===
                  scope.YesNoData[i].OptionDisplayName
                ) {
                  scope.WidgetOptionGetYesNo.Widget_OptionValue =
                    scope.YesNoData[i].OptionValueId;
                  scope.WidgetOptionGetYesNo.WidgetOptionId =
                    scope.YesNoData[i].OptionId;
                  scope.WidgetOptionGetYesNo.Widget_UserID =
                    scope.myParam.Widget_UserID;
                  scope.WidgetOptionGetYesNo.SelectedUser = null;
                }
              }
              saveForTimePeriod(scope.WidgetOptionGetYesNo);
            },
            () => {}
          );
        };

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'tasksTablePopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') {
            scope.customizeMode = true;
            scope.calendarMode = false;
            scope.tableMode = false;
          } else if (msg === 'CalendarMode') {
            scope.customizeMode = false;
            scope.calendarMode = true;
            scope.tableMode = false;
          } else {
            scope.customizeMode = false;
            scope.calendarMode = false;
            scope.tableMode = true;
          }
        };

        scope.events = eventService.getEventsValue();

        scope.calendarView = 'month';
        scope.calendarDate = new Date();

        scope.startOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.endOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };
      },
    };
  });
