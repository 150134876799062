import angular from 'angular';
import SVGInjector from 'svg-injector';
import Raven from 'raven-js';
import ravenAngularPlugin from 'raven-js/dist/plugins/angular';
import axios from 'axios';
import { getToken } from 'Common/utilities/token';
import { getFeaturesData } from 'Common/utilities/features';
import liveChat from 'Common/config/liveChat';
import { constructConfigRequest } from 'Common/utilities/request';
import { setConfigData, getConfigData } from 'Common/utilities/config';
import { getCountryCode, getUserInfoData } from 'Common/utilities/user';
import { getApiResourceBasedOnLocation } from 'Common/utilities/windowLocation';
import ravenConfig from './config/config.raven';

/* eslint-disable angular/window-service, angular/document-service */
const redirectToBaseUrl = () => {
  window.location.assign('/');
};
export function bootstrapRun() {
  angular.bootstrap('#bootstrapApp', ['app']);
}

export function loanMarketValidation() {
  return window.location.href.search(/loanmarket.+(com.au|co.id)/) !== -1;
}

export function runDebug(data) {
  window.mydebug = {};
  // debug
  if (data.debug) {
    window.mydebug.log = (...args) => {
      window.console.log(Array.from(args));
    };
  } else {
    window.mydebug.log = () => {};
    window.console.log = () => {};
    window.onerror = () => {
      return true;
    };
  }
}

export function setUpRaven() {
  const data = getConfigData();
  // Sentry Plugin
  // Note: needs to run before bootstrapping application
  const sentryEnvs = ['prod'];
  const validSentryEnvironment =
    data.sentry_dsn &&
    data.sentry_env &&
    sentryEnvs.indexOf(data.sentry_env) > -1;
  if (validSentryEnvironment) {
    Raven.config(data.sentry_dsn, ravenConfig)
      .addPlugin(ravenAngularPlugin)
      .install();
  } else {
    // initialize so that ngRaven wont go error
    angular.module('ngRaven', []);
  }
}

export function setUpSVGInjector() {
  // SVG Injection
  const svgElement = window.document.querySelectorAll('img.mycrm-svg');
  SVGInjector(svgElement);
}

export function setUpFeatureFlag() {
  const config = getConfigData();
  const feature = getFeaturesData();
  Object.assign(config, { feature });
  setConfigData(config);
}

export function useApiResourceBasedOnLocation() {
  const config = getConfigData();
  const resource = getApiResourceBasedOnLocation() || config.resource;
  Object.assign(config, { resource });
  setConfigData(config);
}

export function getUserInfo() {
  const data = getConfigData();
  const token = getToken();
  const requestConfig = {
    method: 'GET',
    url: `${data.resource}/GetUserInfo`,
    headers: { Authorization: token },
  };
  return axios(constructConfigRequest(requestConfig));
}

export function getFeatures() {
  const data = getConfigData();
  const token = getToken();
  const requestConfig = {
    method: 'GET',
    url: `${data.resource}/features`,
    headers: { Authorization: token },
  };
  return axios(constructConfigRequest(requestConfig));
}

const appendStyleToRemoveLiveChat = () => {
  const head = window.document.getElementsByTagName('head')[0];
  const style = window.document.createElement('style');
  style.type = 'text/css';
  style.innerHTML =
    '.disable-live-chat div[data-test-id="ChatWidgetButton"]{display:none!important}';
  head.appendChild(style);
};

export const liveChatConfig = () => {
  const user = getUserInfoData();
  const countryCode = getCountryCode(user.CountryId);
  if (!countryCode) return;
  if (!liveChat[countryCode]) {
    window.document.body.classList.add('disable-live-chat');
    appendStyleToRemoveLiveChat();
  } else {
    window.document.body.classList.remove('disable-live-chat');
  }
};

const saveTokenFromJWT = query => {
  const jwt = query.replace(/(jwt=|\?|#)/gi, '');
  const auth = decodeURIComponent(jwt);
  window.localStorage.setItem('myCRM_jwt', auth);
};

const saveTokenFromUserId = query => {
  const config = getConfigData();
  const userId = query.replace(/(userId=|\?|#)/gi, '');
  // check if the token is still valid
  const requestConfig = {
    method: 'GET',
    url: `${config.resource}/${userId}/token`,
  };
  axios(constructConfigRequest(requestConfig))
    .then(response => {
      if (!response || !response.data || !response.data.Data) return;
      const token = `Bearer ${response.data.Data}`;
      window.localStorage.setItem('myCRM_jwt', token);
    })
    .catch(e => console.error(e))
    .then(redirectToBaseUrl);
};

export function tokenPassByQuery() {
  const queryStrings = window.location.search.split('&');
  const isUserIdPassed =
    queryStrings.filter(query => query.indexOf('userId') !== -1).length > 0;
  for (let i = 0; i < queryStrings.length; i++) {
    if (queryStrings[i].search('jwt=') !== -1) {
      saveTokenFromJWT(queryStrings[i]);
    }

    if (queryStrings[i].search('userId=') !== -1) {
      saveTokenFromUserId(queryStrings[i]);
    }
  }
  !isUserIdPassed && redirectToBaseUrl();
}

export function runTheme() {
  if (loanMarketValidation()) {
    angular.element('.app-body').addClass('app-lm');
  } else {
    angular.element('.app-body').addClass('app-default');
  }
}

export function gaBootstrap(gaAccount) {
  window.ga('create', gaAccount, 'auto');
}

export function setUpAppAndDependencies() {
  const config = getConfigData();
  angular.module('app').config([
    'configServiceProvider',
    function(configServiceProvider) {
      configServiceProvider.setConfig(config);
    },
  ]);
  gaBootstrap(config.gaAccount);
  liveChatConfig();
  setUpSVGInjector();
  setUpRaven();
  bootstrapRun();
}

export function validateToken() {
  const token = getToken();
  const config = getConfigData();
  const requestConfig = {
    method: 'GET',
    url: `${config.resource}/CheckJWT?jwttype=mycrm`,
    headers: { Authorization: token },
  };
  axios(constructConfigRequest(requestConfig))
    .then(() => {
      setUpFeatureFlag();
      setUpAppAndDependencies();
    })
    .catch(() => {
      window.localStorage.removeItem('myCRM_jwt');
      window.location.assign(config.login);
    });
}

export function handleAuthentication() {
  const token = getToken();
  const config = getConfigData();
  if (window.location.search !== '') {
    tokenPassByQuery();
  } else if (token) {
    // if token has been return from session
    validateToken();
  } else {
    window.localStorage.setItem('nextLocation', window.location.hash);
    window.location.assign(config.login);
  }
}
