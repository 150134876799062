import template from './insurerBrochures.html';
import controller from './insurerBrochuresCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onInsurerBrochuresInit: '&',
    insurerIds: '<',
  },
};
