import template from './leadWorkingDays.html';
import LeadWorkingDaysCtrl from './leadWorkingDaysCtrl';
import './style.scss';

export default {
  template,
  controller: LeadWorkingDaysCtrl,
  bindings: {
    familyId: '<',
    leadsProfile: '=',
    isCompact: '<',
    isEditable: '=',
  },
  controllerAs: 'vm',
};
