export const REFERRAL_STRINGS = ['Referral'];

export const REFERRAL_ITEM = {
  REFERRAL: -1,
  REFERRAL_EXISTING_CLIENT: -37,
  PERSONAL_CONTACT: -17,
  FAMILY_AND_FRIENDS: -16,
  OTHER_MARKETING_ACTIVITY: -15,
  OTHER: -18,
};

export const CLIENT_REFERRAL = [REFERRAL_ITEM.REFERRAL_EXISTING_CLIENT];
export const ADDITIONAL_REFERRAL = [
  REFERRAL_ITEM.PERSONAL_CONTACT,
  REFERRAL_ITEM.FAMILY_AND_FRIENDS,
  REFERRAL_ITEM.OTHER_MARKETING_ACTIVITY,
  REFERRAL_ITEM.OTHER,
];
