import { EMPLOYMENT_STATUS } from 'Common/constants/employmentStatus';
import { EMPLOYMENT_TYPE } from 'Common/constants/employmentType';

class EmploymentModalService {
  constructor(uiService, employmentService) {
    'ngInject';

    this.uiService = uiService;
    this.previousEmployment = false;
    this.validEmpoymentObject = false;
    this.EMPLOYMENT_TYPE = EMPLOYMENT_TYPE;
    this.employmentService = employmentService;
  }

  buildEmploymentAddrSetData(
    addr,
    AddressID,
    IsMailing,
    FamilyId,
    AddressValuationExtendedDetailsId
  ) {
    this.validEmpoymentObject = addr && Object.keys(addr).length;
    return this.validEmpoymentObject
      ? {
          ...addr,
          IsMailing,
          AddressID,
          FamilyId,
          AddressValuationExtendedDetailsId,
          Type: parseInt(addr.Type, 10),
          TypeId: parseInt(addr.AddressType, 10),
        }
      : null;
  }

  isPreviousEmployment(employment) {
    if (!employment) return false;

    const isPreviousByCurrentStatus =
      !employment.IsCurrent &&
      this.employmentService.isCurrentEmploymentStatusShown();
    const isPreviousByEmploymentStatus =
      parseInt(employment.EmploymentStatusId, 10) ===
      EMPLOYMENT_STATUS.PREVIOUS;
    return isPreviousByCurrentStatus || isPreviousByEmploymentStatus;
  }

  isEmployerTypeShown() {
    return !this.uiService.isCountry('New Zealand');
  }

  isRequiredEmploymentBasis(employmentTypeId) {
    const isRequired = [
      this.EMPLOYMENT_TYPE.UNEMPLOYED,
      this.EMPLOYMENT_TYPE.RETIRED,
      this.EMPLOYMENT_TYPE.HOME_DUTIES,
    ];
    return isRequired.indexOf(employmentTypeId) === -1;
  }
}

export default EmploymentModalService;
