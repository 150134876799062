export function creditCheckVMToPostBody(creditChecksVM) {
  if (!creditChecksVM) return;

  const {
    clientId: ClientID,
    firstName: Firstname,
    lastName: LastName,
    gender: Gender,
    dateOfBirth: DateOfBirth,
    unit: Unit,
    streetNumber: StreetNumber,
    streetName: StreetName,
    suburb: Suburb,
    state: State,
    postCode: PostCode,
    driversLicense: DriversLicense,
  } = creditChecksVM;

  return {
    ClientID,
    Firstname,
    LastName,
    Gender,
    DateOfBirth,
    Unit,
    StreetNumber,
    StreetName,
    Suburb,
    State,
    PostCode,
    DriversLicense,
  };
}

export function clientToCreditCheckVM(client) {
  if (!client) return;

  const {
    PersonId: clientId,
    FirstName: firstName,
    LastName: lastName,
    GenderIntValue: gender,
    DateOfBirth: dateOfBirth,
  } = client;

  return {
    clientId,
    firstName,
    lastName,
    gender,
    dateOfBirth,
  };
}
