import template from './workbenchRequirementTemplates.html';
import WorkbenchRequirementTemplatesCtrl from './workbenchRequirementTemplatesCtrl';

export default {
  template,
  controller: WorkbenchRequirementTemplatesCtrl,
  controllerAs: 'vm',
  bindings: {
    requirementTemplateType: '<',
  },
};
