import angular from 'angular';

angular.module('app').filter('term', function term() {
  return function(value) {
    let interestTermValue = String(value);
    if (!interestTermValue) {
      return '';
    }
    switch (interestTermValue) {
      case '0':
        return 'None';
      case '0.25':
        return '3 Months';
      case '0.5':
        return '6 Months';
      case '0.75':
        return '9 Months';
      case '1.5':
        return '18 Months';
      default:
        interestTermValue = parseInt(interestTermValue, 10);
        if (interestTermValue > 1 && interestTermValue !== 1.5) {
          return `${interestTermValue} Years`;
        }
        return `${interestTermValue} Year`;
    }
  };
});
