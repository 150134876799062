import template from './preloaderDotInline.html';
import '../style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    preloaderText: '<',
    preloaderSize: '@',
    isOpaque: '<',
  },
};
