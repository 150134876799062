import template from './breadCrumb.html';
import BreadCrumbCtrl from './breadCrumbCtrl';

export default {
  template,
  controller: BreadCrumbCtrl,
  controllerAs: 'vm',
  bindings: {
    moduleData: '<',
  },
};
