import template from './widgetDropdown.html';
import controller from './widgetDropdownCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fieldId: '@',
    label: '@',
    modelValue: '<',
    modelSubValue: '<',
    options: '<',
    optionValueProperty: '<',
    optionNameProperty: '<',
    defaultValueIndex: '<',
    required: '<',
    subDropdown: '<',
    onUpdate: '&',
    onSubDropdownUpdate: '&',
  },
};
