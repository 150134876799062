import template from './assistantAdviserSelect.html';
import controller from './assistantAdviserSelectCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    assistantName: '<',
    assistantId: '<',
    showChangeLink: '<',
    onAssistantChange: '&',
  },
};
