import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import { PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';
import HelperFunctions from 'Common/utilities/createNewLoanAppHelper';

export default class NewLoanApplicationCtrlV2 {
  constructor(
    $uibModal,
    userService,
    corporateService,
    generalService,
    loanApplicationServices,
    toaster,
    $scope,
    $state,
    $rootScope,
    broadcastDataService,
    dashboardService,
    $document,
    $timeout,
    contactService,
    utilitiesService,
    loanScenarioService,
    loanAppDetailsService,
    currentUserService,
    $q,
    optionsService,
    configService
  ) {
    'ngInject';

    this.loanApplicationServices = loanApplicationServices;
    this.loanAppDetailsService = loanAppDetailsService;
    this.broadcastDataService = broadcastDataService;
    this.loanScenarioService = loanScenarioService;
    this.currentUserService = currentUserService;
    this.corporateService = corporateService;
    this.dashboardService = dashboardService;
    this.utilitiesService = utilitiesService;
    this.contactService = contactService;
    this.generalService = generalService;
    this.userService = userService;
    this.optionsService = optionsService;
    this.configService = configService;
    this.toaster = toaster;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$document = $document;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.contactService = contactService;
    this.utilitiesService = utilitiesService;
    this.loanScenarioService = loanScenarioService;
    this.loanAppDetailsService = loanAppDetailsService;
    this.optionsService = optionsService;
    this.configService = configService;

    this.SHOW_EXISTING_PANEL = CONTACT_REQUESTER.SHOW_EXISTING_PANEL;
    this.REQUESTER_TYPE = CONTACT_REQUESTER.REQUESTER_TYPE;

    this.helperFunctions = new HelperFunctions({
      $uibModal,
      contactService,
      utilitiesService,
      $q,
    });

    this.allFamilies = [];
    this.formData = {
      borrowers: {
        families: [],
        entities: [],
      },
      guarantors: {
        families: [],
      },
    };
    this.borrowers = [];
  }

  $onInit() {
    this.loanAdviser = this.helperFunctions.getDefaultAdviser(
      this.currentUserService
    );
    this.currentUser = {
      ...this.currentUserService,
      isAdviser: this.helperFunctions.isAdviser(this.currentUserService),
    };
    this.existingFamilies = [];

    this.showAdvisers = false;
    this.showAdviserLabel = !!this.loanAdviser;
    this.selectedAdviser = this.loanAdviser
      ? this.helperFunctions.adviserModel(this.currentUser)
      : {};
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  showAdvisersField(showLabel = false, showDropdown = false) {
    this.showAdviserLabel = showLabel;
    this.showAdvisersDropdown = showDropdown;
  }

  setAdviser(adviser) {
    this.selectedAdviser = this.helperFunctions.adviserModel(adviser);
    this.showAdvisersField(true);
  }

  resetAdviser() {
    this.selectedAdviser = {};
    this.showAdvisersField(false, false);
  }

  openPersonModal(params) {
    const { person, assignedAdviserId, partyType, clientList } = params;
    const finalPerson = person && person.length ? person[0] : null;

    this.helperFunctions
      .openApplicantModal(finalPerson, partyType, clientList, assignedAdviserId)
      .result.then(response => {
        const { familyId, excludedIds, partyApplicants } = response;
        this.finalizePersonFamily(familyId, excludedIds, partyApplicants).then(
          finalFamily => {
            const personParams = {
              person: finalPerson,
              finalFamily,
              clientList,
              familyId,
              partyType,
            };
            this.onAddingNewPerson(personParams);
          }
        );
      });
  }

  addToExistingFamily(familyId) {
    this.existingFamilies.push(familyId);
  }

  onAddingNewPerson(params = {}) {
    const { person, partyType, finalFamily, clientList, familyId } = params;

    if (partyType !== PERSON_PARTY_TYPES.GUARANTOR && !person) {
      this.borrowers.push(finalFamily);
    }

    if (person) {
      this.editFamily(finalFamily, clientList, familyId);
    } else {
      this.addFamily(finalFamily, clientList, familyId);
    }
  }

  selectExistingClient(params) {
    const { client, clientList, partyType } = params;
    const { FamilyID: familyId } = client;
    this.finalizePersonFamily(familyId, []).then(finalFamily => {
      const personParams = {
        person: null,
        finalFamily,
        clientList,
        familyId,
        partyType,
      };
      this.onAddingNewPerson(personParams);
    });
  }

  selectExistingEntity(params) {
    const { client, clientList } = params;
    const { FamilyID: familyId } = client;
    this.finalizeEntity(familyId).then(finalEntity => {
      this.addEntity(finalEntity, clientList, familyId);
    });
  }

  finalizePersonFamily(familyId, excludedIds = [], clientList = []) {
    return this.contactService.clientInformGet(familyId).then(clients => {
      let idx = -1;
      const finalClients = clients.data
        .filter(o => o.Role === 'Adult')
        .map(o => {
          idx++;
          const member = {
            ...this.helperFunctions.personModel(o, familyId),
            IsFirstHomeBuyer: clientList.length
              ? clientList[idx].IsFirstHomeBuyers
              : false,
          };
          return {
            ...member,
            IsIncluded: true,
          };
        });
      return excludedIds.length
        ? this.helperFunctions.getIncludedFamily(
            excludedIds,
            finalClients,
            'PersonId'
          )
        : finalClients;
    });
  }

  addFamily(family, clientList, familyId) {
    clientList.push(family);
    this.addToExistingFamily(familyId);
  }

  editFamily(family, clientList, familyId) {
    const idx = clientList.findIndex(
      o => o && o.length && o[0] && +o[0].FamilyId === +familyId
    );
    const finalFamily = family.map(member =>
      this.helperFunctions.personModel(member, familyId)
    );
    if (idx !== -1) clientList.splice(idx, 1, finalFamily);
    this.editBorrowerList(familyId, finalFamily);
  }

  editBorrowerList(familyId, family) {
    const borrowersIdx = this.borrowers.findIndex(
      o => o && o.length && o[0] && +o[0].FamilyId === +familyId
    );
    if (borrowersIdx !== -1) this.borrowers.splice(borrowersIdx, 1, family);
  }

  removeFamily(clientList, family) {
    const familyId = family && family.length && family[0].familyId;
    const idx = clientList.find(
      o => o && o.length && o[0] && o.FamilyId === familyId
    );
    clientList.splice(idx, 1);
    const exisitingFamiliesIdx = this.existingFamilies.find(
      o => o.FamilyId === familyId
    );
    this.existingFamilies.splice(exisitingFamiliesIdx, 1);

    this.editBorrowerList(familyId, {});
  }

  openEntityModal(params) {
    const { person: entity, clientList } = params;
    this.helperFunctions.openCompanyModal(entity).result.then(response => {
      const { FamilyId: familyId } = response;
      this.finalizeEntity(familyId).then(finalFamily => {
        if (entity) {
          this.editEntity(finalFamily, clientList, familyId);
        } else {
          this.addEntity(finalFamily, clientList, familyId);
        }
      });
    });
  }

  finalizeEntity(familyId) {
    return this.contactService.organisationInfoGet(familyId).then(response => {
      return this.helperFunctions.entityModel(response.data, familyId);
    });
  }

  getLendingCategories() {
    this.optionsService.getLendingCategories().then(
      response => {
        if (!response || !response.length) return;
        this.lendingCategories = response;
        this.selectedLendingCategory = this.lendingCategories[0];
      },
      () => {
        this.lendingCategories = [];
      }
    );
  }

  addEntity(entity, clientList, familyId) {
    clientList.push([entity]);
    this.borrowers.push([entity]);
    this.addToExistingFamily(familyId);
  }

  editEntity(entity, clientList, familyId) {
    const idx = clientList.findIndex(
      o => o && o.length && o[0] && +o[0].FamilyId === +familyId
    );
    if (idx !== -1) clientList.splice(idx, 1, [entity]);
    this.editBorrowerList(familyId, [entity]);
  }

  createNewLoanApp() {
    this.helperFunctions
      .validateData(this.formData, this.selectedAdviser)
      .then(response => {
        if (!response) return;
        const borrowerList = this.helperFunctions.convertFormDataToAPIData(
          this.borrowers
        );
        const guarantorList = this.helperFunctions.convertFormDataToAPIData(
          this.formData.guarantors.families
        );

        const lendingCategoryId =
          (this.selectedLendingCategory &&
            this.selectedLendingCategory.categoryId) ||
          null;
        const sendParam = {
          AllocatedAdviserId: this.selectedAdviser.familyId,
          Title: 'Loan Application',
          Borrowers: borrowerList,
          Guarantors: guarantorList,
          LendingCategoryId: this.configService.feature.assetFinance
            ? lendingCategoryId
            : null,
        };
        this.saveDisabled = true;
        this.postLoanApplication(sendParam);
      });
  }

  postLoanApplication(params) {
    this.loanApplicationServices
      .postMultipleApplicants(params)
      .then(response => {
        const { data } = response;
        const { LoanScenarioID: loanAppId } = data;
        this.setFirstHomeBuyers(loanAppId, params);
        const familyId =
          (data.Borrowers.length &&
            data.Borrowers[0] &&
            data.Borrowers[0].FamilyID) ||
          0;
        this.saveDisabled = false;
        if (!familyId) {
          this.toaster.pop(
            'error',
            'Yikes',
            `There's a problem in creating this loan app.`
          );
          return;
        }
        this.toaster.pop(
          'success',
          'Created',
          'Loan application successfully created'
        );
        this.$state.go('app.loanApplicationDetailsV3', {
          familyId,
          loanAppId,
        });
        this.$rootScope.$on('$stateChangeSuccess', () => {
          this.modalInstance.dismiss('cancel');
          this.broadcastDataService.InlinePreloader = {
            preLoaderText: '',
            preLoaderSize: '',
            showPreLoader: false,
          };
        });
        if (!this.currentUser.isAdviser) {
          this.adviserFamilyId = params.AllocatedAdviserId || 0;
        }
        this.loanAppDetailsService.setInsuranceDefault(
          loanAppId,
          familyId,
          this.adviserFamilyId
        );
      });
  }

  setFirstHomeBuyers(loanAppId, finalApiData) {
    const fullList = [
      ...finalApiData.Borrowers.filter(obj => obj.IsClient),
      ...finalApiData.Guarantors.filter(obj => obj.IsClient),
    ];
    fullList &&
      fullList.forEach(member => {
        this.loanScenarioService.LoanAppIsFirstHomeBuyerSet(
          member.ClientEntityId,
          loanAppId,
          member.IsFirstHomeBuyer
        );
      });
  }
}
