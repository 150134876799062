import template from './factFindSidebar.html';
import controller from './factFindSidebarCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
  },
};
