export const REFERRAL_TYPE = {
  RAY_WHITE: 1,
  UK_PENSION: 2,
  F_AND_G: 3,
  WEB_LOANMARKET: -3,
  CONTACT_US: -2,
  OFX: 4,
  WEALTH_MARKET: 5,
  OTHER: 6,
  LOAN_MARKET: 7,
  XE: 8,
  ASSETS_FINANCE: 9,
};

export const LOAN_APP_REFERRAL_QUESTION = {
  IS_CUSTOMER_REFERRAL: 29,
};

export const LOAN_APP_ENQUIRY_SOURCE = {
  REFERRAL: -1,
};

export const SELECTION_VALUE = {
  AGREEMENT: 1,
  REFERRER: 2,
  BROKER: 3,
};

export const AGREEMENT_REFERRAL_TYPE = {
  COMPANY: 1,
  INDIVIDUAL: 2,
  PARTNERSHIP: 3,
  RAY_WHITE: 4,
};
