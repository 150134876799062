import template from './monthDayIcon.html';
import MonthDayIconCtrl from './monthDayIconCtrl';

export default {
  template,
  controller: MonthDayIconCtrl,
  controllerAs: 'vm',
  bindings: {
    inputDate: '<',
  },
};
