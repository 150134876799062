// Config idle watcher
function configIdleWatcher(IdleProvider, KeepaliveProvider, TitleProvider) {
  // configure Idle settings
  IdleProvider.idle(5); // in seconds
  IdleProvider.timeout(5); // in seconds
  KeepaliveProvider.interval(2); // in seconds
  TitleProvider.enabled(false); // Disable title override
}

configIdleWatcher.$inject = [
  'IdleProvider',
  'KeepaliveProvider',
  'TitleProvider',
];

export default configIdleWatcher;
