import { COMPANY_TYPES } from 'Common/constants/partyTypes';

class AdvanceDetailsCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUser = currentUserService;
  }

  toggleAdvancedDetails() {
    this.showAdvancedDetails = !this.showAdvancedDetails;
  }

  addNewBeneficiary() {
    const newBeneficiary = {
      Id: 0,
      BeneficiaryName: '',
      isSubmitted: false,
    };

    this.entityData.TrustBeneficiary.push(newBeneficiary);
  }

  removeBeneficiary(index) {
    this.entityData.TrustBeneficiary.splice(index, 1);
  }

  openDatePicker() {
    this.datePickerOpen = true;
  }

  $onInit() {
    this.showAdvancedDetails = false;
    this.COMPANY_TYPES = COMPANY_TYPES;

    this.datePickerSettings = {
      format: 'dd MMM yyyy',
      altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
      dateOptions: { formatYear: 'yy', showWeeks: false },
    };
  }
}

export default AdvanceDetailsCtrl;
