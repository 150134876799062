import template from './crmTablePagination.html';

export default {
  template,
  bindings: {
    totalRecords: '<',
    maxSize: '<',
    currentPage: '<',
    itemsPerPage: '<',
    onPageChange: '&',
  },
};
