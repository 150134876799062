import angular from 'angular';
import moment from 'moment';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import {
  ADDRESS_TYPE,
  ADDRESS_TYPE_NAME,
} from 'Common/constants/addressModules';

angular
  .module('app')
  .controller(
    'PipelineAppMoveToSettledModalCtrl',
    function PipelineAppMoveToSettledModalCtrl(
      $scope,
      $uibModalInstance,
      pipelineService,
      contactService,
      loanApplicationServices,
      toaster,
      familyID,
      LoanScenarioID,
      crmConfirmation
    ) {
      $scope.SettlementDate = {
        checkbox: false,
        date: new Date(),
        IsEstimated: true,
      };

      if (LoanScenarioID) {
        contactService.loanDetailsGet(LoanScenarioID).then(res => {
          const loan = res.data.loan;
          if (loan && loan.SettlementDate) {
            $scope.SettlementDate.date = new Date(loan.SettlementDate);
            $scope.SettlementDate.IsEstimated = false;
          }
        });
      }

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.getAllAddress = () => {
        if (!familyID) return;

        contactService.contactAddressGet(familyID).then(response => {
          $scope.propertyList = response.data;
          $scope.currentAddress = [];
          $scope.postSettlementAddress = [];

          const addressData = $scope.propertyList
            .filter(
              address =>
                address.Type === 'Current Address' ||
                address.Type === 'Post-Settlement Address'
            )
            .reduce((accum, obj) => {
              const addressType =
                obj.Type === 'Post-Settlement Address'
                  ? 'postSettlement'
                  : 'current';
              accum[addressType] = obj;
              return accum;
            }, {});
          $scope.hasPostSettlement = !!addressData.postSettlement;
          $scope.hasCurrentAddress = !!addressData.current;

          $scope.selectedPostSettlementAddress = addressData.postSettlement;
          $scope.selectedCurrentAddress = addressData.current;
        });
      };
      $scope.getAllAddress();
      $scope.SettlementDate.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
      $scope.SettlementDate.format = 'dd MMM yyyy';
      $scope.SettlementDate.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      $scope.SettlementDate.datePopUp = {
        settlement: false,
      };

      $scope.SettlementDate.openDate = dateField => {
        if (dateField) {
          $scope.SettlementDate.datePopUp[dateField] = !$scope.SettlementDate
            .datePopUp[dateField];
        }
      };

      $scope.updateLoanStatus = () => {
        let toBeUpdatedAddress = null;
        if ($scope.selectedCurrentAddress) {
          if ($scope.hasPostSettlement) {
            toBeUpdatedAddress = $scope.selectedPostSettlementAddress;
            toBeUpdatedAddress.Type = ADDRESS_TYPE_NAME.CURRENT_ADDRESS;
            toBeUpdatedAddress.TypeId = ADDRESS_TYPE.CURRENT_ADDRESS;
          } else if ($scope.hasCurrentAddress) {
            toBeUpdatedAddress = $scope.selectedCurrentAddress;
          }
          if (toBeUpdatedAddress) {
            toBeUpdatedAddress.familyId = familyID;
          }
        }
        const settlementDate = moment($scope.SettlementDate.date).format(
          `YYYY-MM-DD`
        );
        contactService
          .loanImportantDatesSet(
            LoanScenarioID,
            IMPORTANT_DATE_TYPES.IN_PROGRESS.SETTLEMENT,
            settlementDate,
            null,
            $scope.SettlementDate.IsEstimated
          )
          .then(
            res => {
              if (res.data === IMPORTANT_DATE_TYPES.API_STATUS.INVALID_DATE) {
                toaster.pop('warning', 'Date is invalid');
              }
              if (
                $scope.hasPostSettlement &&
                $scope.hasCurrentAddress &&
                $scope.SettlementDate.checkbox
              ) {
                const previousAddress = $scope.selectedCurrentAddress;
                previousAddress.Type = ADDRESS_TYPE_NAME.PREVIOUS_ADDRESS;
                previousAddress.TypeId = ADDRESS_TYPE.PREVIOUS_ADDRESS;
                contactService
                  .contactAddressSet([toBeUpdatedAddress, previousAddress])
                  .then(() => {
                    crmConfirmation.open({
                      type: 'success',
                      title: 'Updated',
                      description: `Your address has been updated successfully`,
                      buttonText: 'Okay, got it!',
                    });
                    $uibModalInstance.close(settlementDate);
                  });
              } else {
                crmConfirmation.open({
                  type: 'success',
                  title: 'Updated',
                  description: `Loan info has been updated successfully`,
                  buttonText: 'Okay, got it!',
                });
                $uibModalInstance.close(settlementDate);
              }
            },
            () => {
              toaster.pop('error', 'Error saving important date.');
            }
          );
      };
    }
  );
