import template from './insuranceNoteTemplatesModal.html';
import controller from './insuranceNoteTemplatesModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    benefitId: '<',
    actionType: '<',
  },
};
