import './style.scss';
import template from './employmentModal.html';
import EmploymentModalCtrl from './employmentModalCtrl';

export default {
  template,
  bindings: {
    newIncomeDetails: '<',
    modalInstance: '<',
    isFromIncome: '<',
    employmentId: '<',
    loanAppId: '<',
    clientId: '<',
    familyId: '<',
    action: '<',
  },
  controller: EmploymentModalCtrl,
  controllerAs: 'vm',
};
