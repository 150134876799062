export function getNumberView(masked, value) {
  return masked || value || 'None';
}

export function removeCountryCode(number) {
  let parsedNumber = number;
  if (number) {
    const num = number.replace(/^\+?(\s+)?6(\s+)?(4|1)/g, '');
    parsedNumber = num ? num.trim() : '';
  }
  return parsedNumber;
}
