const TOKEN_KEY = 'myCRM_jwt';

export function getToken() {
  return window.localStorage.getItem(TOKEN_KEY); // eslint-disable-line
}

export function removeToken() {
  window.localStorage.removeItem(TOKEN_KEY); // eslint-disable-line
}

export function setToken(token) {
  window.localStorage.setItem(TOKEN_KEY, token); // eslint-disable-line
}
