import template from './onboardingUsersQuestionniare.html';
import OnboardingUsersQuestionniare from './onboardingUsersQuestionniareCtrl';

export default {
  template,
  bindings: {
    activeTab: '<',
    tabs: '<',
    isShowingUserQuestion: '&',
    showQuestion: '&',
    userFilterData: '<',
  },
  controller: OnboardingUsersQuestionniare,
  controllerAs: 'vm',
};
