import angular from 'angular';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('adviserLeaderboard', function adviserLeaderboard(
    $timeout,
    overviewDashboardService,
    corporateService,
    queryService,
    npsService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/adviserLeaderboard.html',

      link($scope) {
        $scope.tableMode = true;
        $scope.customizeMode = false;
        $scope.slickConfig = { method: {} };
        $scope.adviserLeaderboard = [];
        $scope.widgetCustomizeArr = [];
        $scope.statusList = [];
        $scope.adviserStatusSelected = {};
        $scope.showBy = [
          { id: 1, name: '# of Transactions', value: 'number' },
          { id: 2, name: 'Value of Transactions', value: 'amount' },
          { id: 3, name: 'Weighted Average', value: 'average' },
        ];
        $scope.showBySelected = {};
        $scope.reportingStateList = [];
        $scope.reportingStateSelected = {};
        $scope.timePeriodArraysAdviser = [0];
        $scope.timePeriodArraysSelectedAdviser = {};

        $scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'upcomingEventsTablePopover.html',
          title: 'Title',
        };

        function getStatus() {
          corporateService.getRelatedLoanStatus().then(response => {
            $scope.statusList = response.data;
          });
        }

        function getTimePeriod() {
          overviewDashboardService.WidgetOptionTimePeriodListv5Get().then(
            response => {
              $scope.timePeriodArraysAdviser = response.data;
            },
            () => {}
          );
        }

        function reportingStatesGet() {
          corporateService.reportingStatesGet().then(response => {
            const data = {
              ReportingStateId: 0,
              ReportingStateName: 'All',
            };
            response.data.unshift(data);
            $scope.reportingStateList = response.data;
          });
        }

        function adviserLeaderboardGet(
          statusId,
          showBy,
          areaId,
          referralSourceId,
          period
        ) {
          $scope.showSlick = false;
          const COUNT_PER_PAGE = 3;
          npsService
            .adviserLeaderBoardGet(
              statusId,
              showBy,
              areaId,
              referralSourceId,
              period
            )
            .then(
              response => {
                const dataParent = response.data;
                $scope.adviserLeaderboard = queryService.queryPerPage(
                  COUNT_PER_PAGE,
                  dataParent
                );
                $scope.showSlick = true;
                if ($scope.adviserLeaderboard.length > 0) {
                  $timeout(() => {
                    try {
                      $scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1000);
                }
              },
              () => {}
            );
        }

        function widgetOptionGet() {
          overviewDashboardService
            .WidgetOptionGet($scope.myParam.Widget_UserID)
            .then(response => {
              const tmp = [];

              angular.forEach(response.data, value => {
                tmp.push(value);
                if (parseInt(value.WidgetOptionId, 10) === 12) {
                  $scope.adviserStatusSelected = $scope.statusList.filter(
                    item =>
                      parseInt(item.LoanStatusID, 10) ===
                      parseInt(value.SelectedUerValues, 10)
                  )[0];
                  if (typeof $scope.adviserStatusSelected === 'undefined') {
                    $scope.adviserStatusSelected = $scope.statusList[0];
                  }
                } else if (parseInt(value.WidgetOptionId, 10) === 15) {
                  $scope.timePeriodArraysSelectedAdviser = $scope.timePeriodArraysAdviser.filter(
                    item =>
                      parseInt(item.OptionValueId, 10) ===
                      parseInt(value.SelectedUerValues, 10)
                  )[0];
                } else if (parseInt(value.WidgetOptionId, 10) === 13) {
                  $scope.showBySelected = $scope.showBy.filter(
                    item =>
                      parseInt(item.value, 10) ===
                      parseInt(value.SelectedUerValues, 10)
                  )[0];
                } else if (parseInt(value.WidgetOptionId, 10) === 14) {
                  $scope.reportingStateSelected = $scope.reportingStateList.filter(
                    item =>
                      parseInt(item.ReportingStateId, 10) ===
                      parseInt(value.SelectedUerValues, 10)
                  )[0];
                }

                if (typeof $scope.showBySelected === 'undefined') {
                  $scope.showBySelected = $scope.showBy[0];
                }
              });
              $scope.widgetCustomizeArr = tmp;
            })
            .then(() => {
              let relatedLoanStatusId;
              let reportingStateSelected;
              $scope.$watchCollection(
                '[adviserStatusSelected, showBySelected, reportingStateSelected, timePeriodArraysSelectedAdviser]',
                newValues => {
                  $scope.adviserLeaderboard = [];

                  relatedLoanStatusId = newValues[0]
                    ? newValues[0].LoanStatusID
                    : 1;
                  reportingStateSelected = newValues[2]
                    ? newValues[2].ReportingStateId
                    : 0;
                  adviserLeaderboardGet(
                    typeof $scope.adviserStatusSelected === 'undefined'
                      ? 10
                      : $scope.adviserStatusSelected.LoanStatusID,
                    $scope.showBySelected.id,
                    reportingStateSelected,
                    relatedLoanStatusId,
                    1
                  );
                }
              );
            });
        }

        function init() {
          getStatus();
          getTimePeriod();
          reportingStatesGet();
          widgetOptionGet();
        }
        $scope.doAction = function(msg) {
          if (msg === 'CustomiseMode') {
            $scope.customizeMode = true;
            $scope.tableMode = false;
          } else {
            $scope.customizeMode = false;
            $scope.tableMode = true;
          }
        };

        function _setWidgets(optionToSet) {
          overviewDashboardService
            .WidgetOptionSet(optionToSet)
            .then(widgetOptionGet)
            .catch(displayError);
        }

        $scope.saveDropdownPeriodValue = function(widgetObj) {
          const setObj = $scope.widgetCustomizeArr.filter(item => {
            if (parseInt(item.WidgetOptionId, 10) === 15) {
              item.DisplayValue = widgetObj.OptionDisplayName;
              item.SelectedUerValues = `${widgetObj.OptionValueId}`;
              item.Widget_UserID = $scope.myParam.Widget_UserID;
              return item;
            }
            return null;
          })[0];
          $scope.OptionToSet = setObj;
          _setWidgets($scope.OptionToSet);
        };

        $scope.saveDropdownValue = function(widgetObj, selectedValueID) {
          widgetObj.SelectedUerValues = selectedValueID;
          widgetObj.Widget_UserID = $scope.myParam.Widget_UserID;
          $scope.OptionToSet = widgetObj;
          _setWidgets($scope.OptionToSet);
        };

        // Initialization
        init();
      },
    };
  });
