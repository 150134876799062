import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';

export function isEmploymentIncome(incomeId) {
  const defaultEmploymentIncome = [
    DEFAULT_INCOME_TYPES.SALARY,
    DEFAULT_INCOME_TYPES.BONUS,
    DEFAULT_INCOME_TYPES.COMMISSION,
  ];
  return defaultEmploymentIncome.indexOf(parseInt(incomeId, 10)) !== -1;
}
