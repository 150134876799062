class IsFeelingStuck {
  constructor(userService, generalService, $cacheFactory) {
    'ngInject';

    this.userService = userService;
    this.generalService = generalService;
    this.isShowFeelingStuckPopOver = false;

    this.cache =
      $cacheFactory.get('httpRequest') || this.$cacheFactory('httpRequest');
  }

  $onInit() {
    this.getUserInfoProcedure();
  }

  getUserInfoProcedure() {
    this.userService.GetUserInfo().then(userInfoResponse => {
      if (userInfoResponse && userInfoResponse.data) {
        this.isShowFeelingStuckPopOver = !userInfoResponse.data.GotItTicked;
      }
    });
  }

  openDemoVideo() {
    if (this.openModalForDemoVideo) {
      this.openModalForDemoVideo();
    }
  }

  isGotItClicked() {
    this.generalService.gotItTickedSet().then(isPostSuccessful => {
      if (isPostSuccessful) {
        this.isShowFeelingStuckPopOver = false;
        this.cache.remove('GetUserInfo');
        this.getUserInfoProcedure();
      }
    });
  }
}

export default IsFeelingStuck;
