import template from './workbenchRequirementTemplatesModal.html';
import WorkbenchRequirementTemplatesModalCtrl from './workbenchRequirementTemplatesModalCtrl';

export default {
  template,
  controller: WorkbenchRequirementTemplatesModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
  },
};
