import template from './usersLeadManagement.html';
import UsersLeadManagementCtrl from './usersLeadManagementCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
  },
  controller: UsersLeadManagementCtrl,
  controllerAs: 'vm',
};
