import template from './insurerComparisonModal.html';
import controller from './insurerComparisonModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    quoteData: '<',
    insurers: '<',
  },
};
