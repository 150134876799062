import angular from 'angular';
import { NUMBER_CHECKING_TYPE } from 'Common/constants/numberCheckingType';
import { setNumericValueValidator } from 'Common/utilities/ngModelValueValidation';

angular.module('app').directive('minNumberValue', function minNumberValueFn() {
  return {
    require: '?ngModel',
    link(scope, elem, attrs, ngModel) {
      setNumericValueValidator(attrs, ngModel, NUMBER_CHECKING_TYPE.MIN);
    },
  };
});
