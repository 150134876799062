import _ from 'lodash';
import toastr from 'toastr';
import { getFinanceCatIDsWithEmployer } from 'Common/utilities/loanStructure';

class NewLoanStructureCtrl {
  constructor(
    $state,
    optionsService,
    loanAppDetailsService,
    loanScenarioService,
    loanScenarioModelService
  ) {
    'ngInject';

    this.$state = $state;
    this.optionsService = optionsService;
    this.loanAppDetailsService = loanAppDetailsService;
    this.loanScenarioService = loanScenarioService;
    this.loanScenarioModelService = loanScenarioModelService;
    this.getFinanceCatIDsWithEmployer = getFinanceCatIDsWithEmployer;
  }

  $onInit() {
    this.loanAppId = this.$state.params.loanAppId;
    this.loanStructure = { InterestTaxDeductible: false };
    this.buttonAction = 'Save';

    this.getFinanceTypes();
    this.getLoanTerms();
    this.getLeasingParties();
    this.getOwners();

    this.showEmployerField = false;

    if (this.loanStructureId) {
      this.buttonAction = 'Update';
      this.getLoanStructureDetails();
    }
  }

  getFinanceTypes() {
    this.optionsService.getAssetFinanceCategory().then(
      response => {
        this.financeTypeList = response;
        this.financeCatIDsWithEmployer = this.getFinanceCatIDsWithEmployer(
          this.financeTypeList
        );
      },
      () => {
        this.financeTypeList = [];
      }
    );
  }

  getLoanTerms() {
    this.optionsService.getAssetFinanceLoanTerms().then(
      response => {
        this.loanTermList = response;
      },
      () => {
        this.loanTermList = [];
      }
    );
  }

  getLeasingParties() {
    this.loanScenarioModelService.getApplicantEmployment(this.loanAppId).then(
      response => {
        if (!response || !response.data) return;

        const applicantEmployment = response.data;
        const employments = _.flatMap(
          applicantEmployment,
          emp => emp.Employment
        );
        this.leasingPartyList = employments.map(emp => {
          return {
            employmentId: emp.EmploymentId,
            employerName: emp.EmployerName,
          };
        });
      },
      () => {
        this.leasingPartyList = [];
      }
    );
  }

  getOwners() {
    this.loanAppDetailsService.ownersGet(this.loanAppId).then(
      response => {
        this.ownersList = response.map(element => ({
          ...element,
          ticked: true,
        }));
      },
      () => {
        this.ownersList = [];
      }
    );
  }

  setEmployerFieldDisplay(financeTypeId) {
    this.loanStructure.LeasingPartyId = null;
    this.showEmployerField = this.financeCatIDsWithEmployer.includes(
      financeTypeId
    );
  }

  calculateTotalLoanAmount() {
    this.loanStructure.TotalLoanAmount = this.loanStructure.BaseLoanAmount;
  }

  residualValuesChange(percentage, amount) {
    this.loanStructure.ResidualPercent = percentage;
    this.loanStructure.ResidualAmount = amount;
  }

  commissionValuesChange(percentage, amount) {
    this.loanStructure.BrokerCommissionPercent = percentage;
    this.loanStructure.BrokerCommissionAmount = amount;
  }

  saveLoanStructure() {
    this.loanStructure.loanScenarioId = this.loanAppId;

    this.loanScenarioService.loanFacilitySet(this.loanStructure).then(() => {
      toastr.success('Loan Structure - successfully created!');
      this.modalInstance.close();
    });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  getLoanStructureDetails() {
    this.loanScenarioService
      .loanFacilityGet(this.loanAppId, this.loanStructureId)
      .then(response => {
        if (!response.data) return;
        this.loanStructure = response.data;
        this.formatLoanTerm(this.loanStructure.LoanTerm);
      });
  }

  formatLoanTerm(loanTerm) {
    if (!this.loanTermList) return;
    const selectedLoanTerm = this.loanTermList.find(
      term => loanTerm === Number(parseFloat(term.value).toFixed(2))
    );
    this.loanStructure.LoanTerm = selectedLoanTerm && selectedLoanTerm.value;
  }
}

export default NewLoanStructureCtrl;
