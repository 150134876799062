import angular from 'angular';

angular
  .module('app')
  .directive('disableZeroAsFirst', function disableZeroAsFirst() {
    return {
      require: 'ngModel',
      link(scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          if (text) {
            const transformedInput = text.replace(/^(0*)/g, '');
            if (transformedInput !== text) {
              ngModelCtrl.$setViewValue(transformedInput);
              ngModelCtrl.$render();
            }
            return transformedInput;
          }
          return undefined;
        }
        ngModelCtrl.$parsers.push(fromUser);
      },
    };
  });
