import template from './lmiLenderMortgage.html';
import './style.scss';

export default {
  template,
  bindings: {
    mortgageObj: '=',
    brokerEventId: '<',
  },
  controller: 'LmiLenderMortgageCtrl',
  controllerAs: 'vm',
};
