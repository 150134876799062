// TODO: move to tasksService
class BusinessService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'tasks';
  }

  TaskTemplateListFullDetailsGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/TaskTemplateListFullDetailsGet`
    );
  }

  TaskTemplateSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/TaskTemplateSet`, data);
  }

  TaskTemplateDelete(TaskTemplateID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/TaskTemplateDelete`, {
      TaskTemplateID,
    });
  }

  TaskSettingsSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/templates/${data.TaskTemplateID}/settings`,
      data
    );
  }

  TaskSettingsDelete(taskTemplateID, taskSettingsID) {
    return this.httpClient.delete(
      `${
        this.apiBaseUrl
      }/templates/${taskTemplateID}/settings/${taskSettingsID}`
    );
  }

  taskOrderSet(templateId, data) {
    return this.httpClient.post(`tasks/templates/${templateId}/order`, data);
  }
}

export default BusinessService;
