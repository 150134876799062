import './style.scss';
import template from './employmentInfo.html';
import EmploymentInfoCtrl from './employmentInfoCtrl';

export default {
  template,
  bindings: {
    onCurrentEmploymentChanged: '&',
    isEmploymentStatusModsShown: '<',
    formReference: '<',
    personList: '=',
    employment: '=',
    loanAppId: '<',
    isLoanApp: '<',
    clientId: '<',
    familyId: '<',
  },
  controller: EmploymentInfoCtrl,
  controllerAs: 'vm',
};
