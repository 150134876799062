import './style.scss';
import template from './template.html';
import CurrencyConvertionCtrl from './currencyConvertionCtrl';

export default {
  template,
  bindings: {
    value: '<',
    currencyCode: '<',
    familyId: '=',
    sourceFamilyId: '<',
    loanAppId: '<',
    ownersSelected: '<',
    xeConvertedAmount: '=',
  },
  controller: CurrencyConvertionCtrl,
  controllerAs: 'vm',
};
