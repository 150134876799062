export function taskPdfBuilderForUI(data) {
  return {
    name: data.Name,
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    isSvg: data.IsSvg,
  };
}

export function taskFilterBuilderForUI(parameter) {
  return (
    parameter && {
      dueDateType: parameter.DueDateType,
      assignedBrokerId: parameter.AssignedBrokerId,
      createdByBrokerId: parameter.CreatedByBrokerId,
    }
  );
}

export function taskAdviserListForUI(data) {
  return (
    data && {
      displayName: data.DisplayName,
      brokerId: data.BrokerID,
      firstName: data.FirstName,
      lastName: data.LastName,
      accessType: data.AccessType,
      profilePicture: data.ProfilePicture || '',
    }
  );
}

export function taskFilterBuilderForMyCRM(parameter) {
  return (
    parameter && {
      DueDateType: parameter.dueDateType,
      AssignedBrokerId: parameter.assignedBrokerId,
      CreatedByBrokerId: parameter.createdByBrokerId,
    }
  );
}

export const taskTemplateBuilderForUI = data => {
  return {
    name: data.TemplateName || data.TaskTemplateName || data.name,
    id: data.TemplateID || data.TaskTemplateID || data.id,
    list: data.TaskSettingsList || data.list || [],
  };
};
