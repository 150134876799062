import {
  PIPELINE_STATUS,
  CONVERSION_PIPELINE_OPTIONS,
} from 'Common/constants/pipelineStatus';

export default class StatusDropdownLeadsCtrl {
  $onInit() {
    this.PIPELINE_STATUS = PIPELINE_STATUS;
    this.LEADS_CONVERSION = CONVERSION_PIPELINE_OPTIONS.LEADS;
  }
}
