import { isFunction } from 'lodash';

class DashboardCategoryService {
  constructor(overviewDashboardService, $rootScope) {
    'ngInject';

    this.overviewDashboardService = overviewDashboardService;
    this.$rootScope = $rootScope;
  }

  CategorySet(categoryObj, callback) {
    this.overviewDashboardService.CategorySet(categoryObj).then(() => {
      this.$rootScope.$broadcast('categoryUpdated');

      if (isFunction(callback)) {
        callback();
      }
    });
  }
}

export default DashboardCategoryService;
