import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { videoNotFoundAlert } from 'Common/utilities/subscription';

class SubscriptionModalCtrl {
  constructor(
    $timeout,
    contactService,
    usersSubscriptionService,
    configService,
    contactModelService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.contactService = contactService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.videoNotFoundAlert = videoNotFoundAlert;
    this.configService = configService;
    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.isCheckedTerms = false;
    this.subscriptionLabels = this.usersSubscriptionService.getSubscriptionLabels(
      this.subscriptionData.subscriptionProductId
    );
    this.getDiscountsData();
    this.termsAndCondition = false;
    this.isLoading = true;
    this.isShowSuccess = false;
    this.loadingMessage = 'Loading Available Plans';
    this.showDiscountTable = false;
    this.isFactFind =
      this.subscriptionData.subscriptionProductId ===
      SUBSCRIPTIONTYPE.ONLINE_FACT_FIND;
    this.getProductPrice();
  }

  $onDestroy() {
    this.showPreloaderTimeout &&
      this.$timeout.cancel(this.showPreloaderTimeout);
  }

  getDiscountsData() {
    const { subscriptionProductId } = this.subscriptionData;
    if (subscriptionProductId !== SUBSCRIPTIONTYPE.ONLINE_FACT_FIND) return;
    this.contactModelService.getFactFindPricing().then(response => {
      if (!response || !response.length) return;
      this.discountData = response;
    });
  }

  toggleDiscountTable() {
    this.showDiscountTable = !this.showDiscountTable;
  }

  subscribe(skipCheck = false) {
    const { subscriptionProductId } = this.subscriptionData;
    this.isCheckedTerms = skipCheck ? true : this.isCheckedTerms;
    if (!this.isCheckedTerms) return;
    this.isLoading = true;
    const checkValidToSubscribe =
      this.subscriptionData &&
      this.subscriptionData.subscriptionProductId &&
      this.orderedById;
    this.loadingMessage = 'Activating Subscription';
    this.subscriptionData.monthlyRecurringFee =
      subscriptionProductId === SUBSCRIPTIONTYPE.CREDIT_CHECK
        ? 0
        : this.pricing.subscriptionPrice;
    this.subscriptionData.orderedById = this.orderedById;
    if (!checkValidToSubscribe) return;
    this.contactService
      .subscription(this.subscriptionData, this.familyId)
      .then(response => {
        if (!response) return;
        const { esignSignature } = this.configService.feature;
        const validToCheckSignature =
          esignSignature &&
          this.subscriptionData.subscriptionProductId ===
            SUBSCRIPTIONTYPE.E_SIGN;
        if (validToCheckSignature) {
          this.returnToParent();
        } else {
          this.checkIsShowPreLoader();
          this.isShowSuccess = true;
        }
      });
  }

  getProductPrice() {
    this.isLoading = true;
    const { subscriptionProductId } = this.subscriptionData;
    const checkValidToGetPricing =
      this.familyId && this.subscriptionData && subscriptionProductId;
    if (!checkValidToGetPricing) return;
    this.contactService
      .subscriptionPricing(this.familyId, subscriptionProductId)
      .then(subscriptionPricingResponse => {
        if (subscriptionPricingResponse) {
          this.pricing = subscriptionPricingResponse;
          this.checkIsShowPreLoader();
        }
      });
  }

  checkIsShowPreLoader() {
    this.showPreloaderTimeout = this.$timeout(() => {
      this.isLoading = false;
      this.showPreloaderTimeout &&
        this.$timeout.cancel(this.showPreloaderTimeout);
    }, 2000);
  }

  returnToParent() {
    this.modalInstance.close({ getAllSubscribe: true });
  }
  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}

export default SubscriptionModalCtrl;
