import template from './borrowers.html';
import BorrowersCtrl from './borrowersCtrl';

export default {
  template,
  bindings: {
    loanAdviser: '<',
    formData: '<',
    existingFamilies: '<',
    onSelectClient: '&',
    onSelectEntity: '&',
    onAddPersonModal: '&',
    onEditPersonModal: '&',
    onAddEntityModal: '&',
    onEditEntityModal: '&',
    onRemoveClient: '&',
  },
  controller: BorrowersCtrl,
  controllerAs: 'vm',
};
