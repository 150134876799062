import { INTEREST_RATE } from 'Common/constants/interestRateTermNumbers';
import _ from 'lodash';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

app.factory('contactSharedData', [
  'userService',
  'contactService',
  'corporateService',
  'enquirySourceService',
  '$uibModal',
  '$q',
  function(
    userService,
    contactService,
    corporateService,
    enquirySourceService,
    $uibModal,
    $q
  ) {
    var factory = this;

    factory.isEditSecurity = false;
    factory.securityZoningId = 0;
    factory.showLoanSplit = true;
    factory.summaryData = {};
    angular.extend(factory.summaryData, {
      destinationTabToLending: '',
    });

    factory.pipelineStatusData = {};
    angular.extend(factory.pipelineStatusData, {
      statusUpdate: {
        LoanScenarioID: 0,
        StatusID: 0,
      },
      referenceUpdate: {
        LoanScenarioID: 0,
        ReferenceNumber: 0,
        isUpdating: false,
      },
    });

    factory.lendingSharedData = {};
    angular.extend(factory.lendingSharedData, {
      loanDetail: {
        processAction: '',
        processResult: '',
      },
      inProgressToPipeline: {
        statusObj: {},
        inprogressObj: {},
        updateLinkToLoan: false,
      },
      referenceUpdate: {
        loanScenarioID: 0,
        referenceNumber: 0,
        isUpdating: false,
      },
      secCtrlEditSecurityStructure: {},
      getCurrentUserInfo() {
        userService.GetUserInfo().then(response => {
          if (response.data) {
            factory.lendingSharedData.userInfo = response.data;
          }
        });
      },
      userInfo: {},
    });

    factory.numberToMonthYearText = interestRateTerm => {
      const interestRateTermConst = parseInt(interestRateTerm, 10);
      let toReturnValue = '';

      if (
        interestRateTermConst < INTEREST_RATE.YEAR_1 ||
        interestRateTermConst === INTEREST_RATE.MONTHS_18
      ) {
        if (interestRateTermConst === INTEREST_RATE.MONTHS_1) {
          toReturnValue = `${interestRateTermConst} Mo`;
        } else {
          toReturnValue = `${interestRateTermConst} Mos`;
        }
      } else if (interestRateTermConst === INTEREST_RATE.YEAR_1) {
        toReturnValue = `${interestRateTermConst / INTEREST_RATE.YEAR_1} Yr`;
      } else {
        toReturnValue = `${interestRateTermConst / INTEREST_RATE.YEAR_1} Yrs`;
      }

      return toReturnValue;
    };

    factory.toggleChildrenDiv = (
      referralCategory,
      referralCategoryList,
      contactInfoSetValue
    ) => {
      const defer = $q.defer();
      let scope = {};
      if (referralCategory.IsEditMode) return false;

      scope.selectedReferralCategory = {};
      _.map(referralCategoryList, o => {
        o.isVisible = false;
        o.hasChildrenData = false;
        return o;
      });

      referralCategory.isVisible = !referralCategory.isVisible;
      scope.ReferralCategory = referralCategory.CategoryName;

      if (referralCategory.isVisible && !referralCategory.hasChildrenData) {
        contactService
          .getReferralChildList(referralCategory.ReferralCategoryID)
          .then(response => {
            if (response && response.data) {
              referralCategory.Children = response.data;
              referralCategory.hasChildrenData = true;

              scope.selectedReferralCategory = referralCategory;
            }
            return defer.resolve(scope);
          });
      }
      return defer.promise;
    };

    factory.accessByCorporate = familyId => {
      const defer = $q.defer();
      let scope = {};
      contactService.getUserInfo().then(userlevel => {
        if (
          _.size(userlevel.data) > 0 &&
          userlevel.data.AccessType === ACCESS_TYPE.CORPORATE
        ) {
          scope.isCorporateUser = true;
          let tmp = [];
          corporateService.clientUTMDetailsGet(familyId, 0).then(response => {
            angular.forEach(response.data, value => {
              tmp.push(value);
            });
          });
          scope.utmCampaigns = tmp;
        }
        return defer.resolve(scope);
      });
      return defer.promise;
    };

    factory.openReferralModal = (
      size,
      getReferralCategoryList,
      contactFamilyInfoGet,
      contactInfoSetValue,
      selectedReferralCategory,
      referralCategoryList
    ) => {
      return $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/referral.v2/modal.html',
        controller: 'ReferralModalCtrl',
        size,
        resolve: {
          getReferralCategoryList() {
            return getReferralCategoryList;
          },
          contactFamilyInfoGet() {
            return contactFamilyInfoGet;
          },
          contactInfoSetValue() {
            return contactInfoSetValue;
          },
          selectedReferralCategory() {
            return selectedReferralCategory;
          },
          referralCategoryList() {
            return referralCategoryList;
          },
        },
      });
    };

    factory.getUsersActiveIndexTab = (activeTab, usersTab) => {
      if (!usersTab) return;
      let active;
      usersTab &&
        Object.keys(usersTab).forEach((item, idx) => {
          if (item === activeTab) {
            active = idx;
          }
        });
      return active;
    };
    return factory;
  },
]);
