app.factory('utilitiesService', [
  '$q',
  '$rootScope',
  function($q, $rootScope) {
    var factory = this;

    const roundFigure = val => {
      return $rootScope.crmCurrency + Math.round(val).toLocaleString();
    };

    const loanOptionData = {
      responsive: true,
      scaleLabel(valuePayload) {
        return roundFigure(parseInt(valuePayload.value, 10));
      },
      tooltipTemplate(v) {
        return roundFigure(v.value);
      },
      multiTooltipTemplate(label) {
        return `${label.datasetLabel} : ${roundFigure(label.value)}`;
      },
      scaleShowGridLines: true,
      scaleGridLineColor: 'rgba(0,0,0,.05)',
      scaleGridLineWidth: 1,
      bezierCurve: true,
      bezierCurveTension: 0.4,
      pointDot: true,
      pointDotRadius: 3,
      pointDotStrokeWidth: 2,
      pointHitDetectionRadius: 5,
      datasetStroke: true,
      datasetStrokeWidth: 2,
      datasetFill: true,
    };

    factory.filterInitial = function() {
      var i;
      var initials = '';
      for (i = 0; i < arguments.length; i++) {
        if (!_.isNil(arguments[i]) && _.isString(arguments[i])) {
          initials = `${initials}${arguments[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };

    factory.getHeaderName = function(lastName, firstName, lastName2) {
      const headerName = `${lastName} & ${firstName} ${lastName2}`;
      return headerName;
    };

    factory.filterInitialOneString = function(str) {
      var initials = '';

      if (str) {
        var stringArray = str.split(/(\s+)/);

        var i = 0; // first word
        initials = stringArray[i].charAt(0).toUpperCase();

        i = stringArray.length - 1; // last word
        if (i > 0) initials += stringArray[i].charAt(0).toUpperCase();
      }

      return initials;
    };

    factory.formatDate = function(dateValue, IsyyyymmddToddmmyyyy) {
      if (!dateValue) return '';

      if (IsyyyymmddToddmmyyyy)
        return dateValue
          .split('/')
          .reverse()
          .join('-');
    };

    factory.getLoanOptions = () => {
      factory.data = {};
      factory.data = loanOptionData;
      return factory.data;
    };

    factory.groupList = (list, groupSize) => {
      var shallowList = _.clone(list);
      var newList = _.reduce(
        shallowList,
        (result, value, index) => {
          if (index % groupSize === 0) {
            result.push(shallowList.slice(index, index + groupSize));
          }
          return result;
        },
        []
      );

      return newList;
    };

    return factory;
  },
]);
