import { AMOUNT_ABBREVIATION } from 'Common/constants/amountAbbreviation';

export function shortenAmount(amount, decimalPlaces) {
  let shortenedAmount = parseInt(amount, 10);
  const afterDotDigits = 10 ** decimalPlaces;
  const amountAbbrev = [
    AMOUNT_ABBREVIATION.THOUSAND,
    AMOUNT_ABBREVIATION.MILLION,
    AMOUNT_ABBREVIATION.BILLION,
    AMOUNT_ABBREVIATION.TRILLION,
  ];
  let i = amountAbbrev.length;
  amountAbbrev.reverse().forEach(abbrev => {
    const amountAbbrevRange = 10 ** (i * 3);
    const isAmountWithinRange = shortenedAmount >= amountAbbrevRange;
    if (isAmountWithinRange) {
      shortenedAmount =
        Math.round((shortenedAmount * afterDotDigits) / amountAbbrevRange) /
        afterDotDigits;

      // Handle special case where we round up to the next abbreviation
      if (shortenedAmount === 1000 && i < amountAbbrev.length) {
        shortenedAmount = 1;
        i++;
      }

      // Add the letter for the abbreviation
      shortenedAmount = `${shortenedAmount}${abbrev}`;
      return true;
    }
    i--;
  });
  return shortenedAmount;
}

export function getMoneyForm(amount) {
  return parseFloat(parseFloat(amount).toFixed(2));
}
