import angular from 'angular';

angular.module('app').directive('onErrorSrc', function onErrorSrc() {
  return {
    link(scope, element, attrs) {
      element.bind('error', () => {
        attrs.$set('src', attrs.onErrorSrc);
      });
    },
  };
});
