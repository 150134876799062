import { mapContactPointForComponent } from 'Common/utilities/providerInfo';

export default class ProviderInfoCtrl {
  constructor($state, providerInformationService, providerUploadDocsService) {
    'ngInject';

    this.providerId = $state.params.providerId;
    this.providerInformationService = providerInformationService;
    this.providerUploadDocsService = providerUploadDocsService;
    this.favouritesTab = '';
  }

  $onInit() {
    this.loadProviderInfo();
  }

  loadProviderInfo() {
    this.isLoading = true;
    this.providerInformationService
      .getProvidersInformation({ providerId: this.providerId })
      .then(response => {
        if (!response || !response.length) return;
        this.providerInfo = response[0];
        const {
          countryCode,
          providerName,
          insurerId,
          lenderId,
        } = this.providerInfo;
        this.insurerId = insurerId;
        this.providerLogo = `assets/images/lenderLogoLg/${countryCode}/${providerName}@2x.png`;
        this.providerIds = this.insurerId === 0 ? lenderId : insurerId;
        this.loadProviderContactPoints();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  loadProviderContactPoints() {
    if (!this.providerInfo.lenderId) return;
    this.providerInformationService
      .getProviderContactPoints({ lenderId: this.providerInfo.lenderId })
      .then(response => {
        const providerContactPoints = response && response[0];
        if (!providerContactPoints) return;
        this.contactPoints = providerContactPoints.contactPoints.map(
          mapContactPointForComponent
        );
      });
  }

  hasContactInfo() {
    if (!this.providerInfo) return false;
    const { website, emailAddr, faxNumber, phoneNumber } = this.providerInfo;
    return website || emailAddr || faxNumber || phoneNumber;
  }

  toggleWidget(widgetType) {
    switch (widgetType) {
      case 'contactPoints':
        this.isContactPointsExpanded = !this.isContactPointsExpanded;
        break;
      case 'quickForms':
        this.isQuickDownloadsExpanded = !this.isQuickDownloadsExpanded;
        break;
      case 'keyContactPoints':
        this.keyContactPoints = !this.keyContactPoints;
        break;
      case 'toolsDownloads':
        this.isToolsDownloadsExpanded = !this.isToolsDownloadsExpanded;
        break;
      default:
    }
  }
}
