import moment from 'moment';

class MonthDayIconCtrl {
  $onInit() {
    const date = this.inputDate && moment(this.inputDate);
    this.isValidDate = date && date.isValid();

    if (this.isValidDate) this.setMonthDay(date);
  }

  setMonthDay(dateParam) {
    this.month = moment(dateParam).format('MMM');
    this.day = moment(dateParam).format('D');
  }
}

export default MonthDayIconCtrl;
