import template from './lendingOpportunity.html';
import controller from './lendingOpportunityCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityLoans: '<',
    onDeleteLoan: '&',
    familyId: '<',
    isBusiness: '<',
    isShowPrevious: '<',
    onSeeAllOpportunity: '&',
  },
};
