import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('scrollOnClick', function scrollOnClick() {
  return {
    restrict: 'A',
    link(scope, $elm) {
      $elm.on('click', () => {
        $('body').animate({ scrollTop: $elm.offset().top }, 'slow');
      });
    },
  };
});
