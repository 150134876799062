import angular from 'angular';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller('OpenCreateTaskModalSBCtrl', function OpenCreateTaskModalSBCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $timeout,
    $state,
    toaster,
    tasksSharedData,
    taskService,
    dashboardService,
    clientInfoService,
    taskDetailService,
    generalSearchService,
    currentUserService
  ) {
    contactService.clientInformGet($state.params.familyId).then(response => {
      $scope.taskInput.familyValue = clientInfoService.getFullName(
        response.data
      );
      $scope.taskSet.FamilyId = $state.params.familyId;
    });
    $scope.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
    };
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.mainObject.datePopUp = false;
    $scope.format = 'dd MMM yyyy';
    $scope.isSaveTaskDisable = false;
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.getClientName = tasksSharedData.getClientName;
    $scope.getClientName($state.params.familyId);

    $scope.updateFamilyOnAdviserChange =
      tasksSharedData.updateFamilyOnAdviserChange;

    $scope.openDate = function() {
      $scope.mainObject.datePopUp = !$scope.mainObject.datePopUp;
    };

    $scope.closeFamilyList = () => {
      $scope.familyDetailsList = taskDetailService.closeFamilyList(
        $scope.taskInput,
        $scope.mainObject
      );
    };

    $scope.selectFamily = (id, name) => {
      $scope.familyDetailsList = taskDetailService.selectFamily(
        id,
        name,
        $scope.taskInput,
        $scope.taskSet,
        $scope.mainObject
      );
    };

    $scope.$on('$destroy', () => {
      $timeout.cancel($scope.dismissTimeout);
      $scope.dismissTimeout = null;
    });

    if ($scope.taskId) {
      taskDetailService.taskDetailsGet($scope.taskId).then(respose => {
        $scope.taskSet = respose.data;
        if ($scope.taskSet) {
          $scope.selectFamily(
            $scope.taskSet.FamilyID,
            $scope.taskSet.ClientFamilyName
          );
          $scope.showNotes =
            $scope.taskSet.Notes && $scope.taskSet.Notes.trim() !== '';
        }
      });
    } else {
      $scope.taskSet.DueDate = new Date();
    }

    $scope.showNotes = true;
    if (
      typeof $scope.taskSet.Notes !== 'undefined' &&
      $scope.taskSet.Notes.trim() === ''
    ) {
      $scope.showNotes = false;
    }
    $scope.addNotes = function() {
      const notes = $scope.taskSet.Notes;
      if (typeof notes === 'undefined' || notes.trim() === '') {
        $scope.showNotes = true;
      }
    };
    $scope.isCorporateUser =
      currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased =
      COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    taskDetailService
      .taskAdviserListGet($scope.taskSet, $scope.taskId, isLoanWriter)
      .then(adviserResp => {
        if (!adviserResp || !adviserResp.data) return;
        $scope.taskAdviserList = adviserResp.data;
      });
    taskDetailService.getAllByFilter(
      $scope.taskInput,
      $scope.taskSet,
      $scope.taskId,
      $scope.familyId
    );

    $scope.taskInput = {};

    $scope.saveNewTask = function() {
      $scope.isSaveTaskDisable = true;
      if ($scope.taskInput.familyValue === '') {
        $scope.taskSet.FamilyId = 0;
      }
      const postData = {
        FamilyID: $scope.taskSet.FamilyId || $state.params.familyId,
        Title: $scope.taskSet.Title,
        assignedClientID: $scope.taskSet.assignedClientID,
        CreatedByUser: $scope.taskSet.CreatedByUser,
        DueDate: moment($scope.taskSet.DueDate).format('YYYY-MM-DD'),
        Notes: $scope.taskSet.Notes,
      };

      const dismissModalTimeOut = () => {
        $scope.getTasksList(0);
        $scope.dismissTimeout = $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      };
      let message;
      let serviceTaskMethod;
      if ($scope.taskId) {
        postData.TaskID = $scope.taskId;
        message = 'Updated';
        serviceTaskMethod = 'updateTask';
      } else {
        message = 'Added';
        serviceTaskMethod = 'storeTask';
      }
      taskService[serviceTaskMethod](postData).then(() => {
        toaster.pop(
          'success',
          message,
          `Task successfully ${message.toLowerCase()}`
        );
        dismissModalTimeOut();
      });
    };

    $scope.toggleOneComplete = task => {
      task.IsCompleted = !task.IsCompleted;

      if (!task.IsCompleted) {
        $scope.setTaskCompletion(task.TaskID, task.IsCompleted);
        return;
      }

      tasksSharedData.completionConfirmation(
        () => {
          $scope.setTaskCompletion(task.TaskID, task.IsCompleted);
        },
        () => {
          task.IsCompleted = false;
        }
      );
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.isSaveDisable = () => {
      return (
        $scope.isSaveTaskDisable ||
        ($scope.loanDataList && $scope.loanDataList.length <= 0) ||
        $scope.mainObject.type === 'view'
      );
    };

    $scope.searchFamilyInput = () => {
      generalSearchService.searchFamilyInput($scope);
    };
  });
