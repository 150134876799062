import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller(
    'OpenCreateTaskTemplateModalSBCtrl',
    function OpenCreateTaskTemplateModalSBCtrl(
      $scope,
      $uibModalInstance,
      contactService,
      $timeout,
      toaster,
      taskService,
      taskDetailService,
      tasksSharedData,
      dashboardService,
      $state,
      generalSearchService,
      contactModelService,
      currentUserService
    ) {
      $scope.taskSet = {};
      $scope.mainObject = {};
      $scope.mainObject.isSearchEnable = false;
      $scope.format = 'dd MMM yyyy';
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };
      $scope.getClientName = tasksSharedData.getClientName;
      $scope.getClientName($state.params.familyId);
      $scope.openDate = function(templateTask) {
        templateTask.datePopUp = !templateTask.datePopUp;
      };

      $scope.dateChanged = function(templateTask) {
        templateTask.DueDate = templateTask.DueDateFormat;
      };

      $scope.closeFamilyList = function() {
        $scope.familyDetailsList = [];
        $scope.mainObject.isSearchEnable = false;
        $scope.taskInput.familyValue = '';
      };

      $scope.selectFamily = function(fid, fname) {
        $scope.taskInput.familyValue = fname;
        $scope.taskSet.FamilyId = fid;
        $scope.mainObject.isSearchEnable = false;
        $scope.familyDetailsList = [];
      };
      $scope.$on('$destroy', () => {
        $timeout.cancel($scope.templatedTimeout);
        $scope.templatedTimeout = null;
      });
      $scope.taskSet.DueDate = new Date();

      $scope.editTemplateTask = function(task) {
        taskDetailService.editTemplateTask(task, $scope.mainObject);
      };

      $scope.cancelTemplateTaskEditMode = function(task) {
        _.map($scope.copiedTemplatedTaskList, item => {
          if (item.TaskSettingsID === task.TaskSettingsID) {
            task.TaskName = item.TaskName;
            task.AssignedAdvisorID = item.AssignedAdvisorID;
            task.DueDate = item.DueDate;
            const $adviserData = _.find($scope.taskAdviserList, adviser => {
              return (
                parseInt(adviser.BrokerID, 10) ===
                parseInt(task.AssignedAdvisorID, 10)
              );
            });
            if (typeof $adviserData !== 'undefined') {
              item.FirstName = $adviserData.LastName;
              item.LastName = $adviserData.LastName;
              task.FirstName = $adviserData.FirstName;
              task.LastName = $adviserData.LastName;
            }
          }
        });
        task.isEditMode = false;
      };

      $scope.saveTemplateTask = function(task) {
        _.map($scope.copiedTemplatedTaskList, item => {
          if (item.TaskSettingsID === task.TaskSettingsID) {
            item.TaskName = task.TaskName;
            item.AssignedAdvisorID = task.AssignedAdvisorID;
            item.DueDate = task.DueDate;
            const $adviserData = _.find($scope.taskAdviserList, adviser => {
              return (
                parseInt(adviser.BrokerID, 10) ===
                parseInt(task.AssignedAdvisorID, 10)
              );
            });
            if ($adviserData) {
              item.FirstName = $adviserData.FirstName;
              item.LastName = $adviserData.LastName;
              task.FirstName = $adviserData.FirstName;
              task.LastName = $adviserData.LastName;
            }
          }
        });
        task.isEditMode = false;
        $scope.formatTaskSettingsDueDate(
          task.TaskTemplateID,
          task.TaskSettingsID
        );
      };

      taskService.taskTemplateListGet().then(respond => {
        $scope.taskTemplateList = respond.data;
      });

      $scope.copiedTemplatedTaskList = [];
      $scope.selectedTemplateId = 0;
      $scope.getTemplateTask = function(templateId) {
        if (!templateId) return;
        $scope.selectedTemplateId = templateId;
        taskService.getTaskTemplateSettings(templateId).then(respond => {
          $scope.templatedTaskList = respond.data;
          $scope.formatTaskSettingsDueDate(templateId);
        });
      };

      $scope.formatTaskSettingsDueDate = (templateId, taskId = 0) => {
        const templateModel = {
          TaskTemplateID: templateId,
          TaskTemplateName: '',
          TaskSettingsList: $scope.templatedTaskList,
        };

        taskService
          .updateDueDateSubsequently(templateModel, taskId)
          .then(newTaskSettings => {
            $scope.templatedTaskList = newTaskSettings.data;

            dashboardService.getBrokerBasicInfo().then(response => {
              const { data } = response;
              if (!data) return;

              $scope.mainObject.CreatedByUser = data.BrokerId;

              $scope.templatedTaskList.forEach(item => {
                item.AssignedAdvisorID =
                  item.AssignedAdvisorID && item.AssignedAdvisorID.toString();
                if (!parseInt(item.AssignedAdvisorID, 10)) {
                  item.AssignedAdvisorID = data.BrokerId;
                  item.FirstName = data.FirstName;
                  item.AssignedAdvisorID = data.BrokerId;
                }
              });
              $scope.copiedTemplatedTaskList = [...$scope.templatedTaskList];
            });
          });
      };

      $scope.deleteTemplateTask = function(task) {
        const $index = $scope.templatedTaskList.indexOf(task);
        if ($index > -1) $scope.templatedTaskList.splice($index, 1);
      };

      $scope.taskInput = {};

      $scope.searchFamilyInput = () => {
        generalSearchService.searchFamilyInput($scope);
      };

      contactService.getAllByFilter('*').then(respond => {
        angular.forEach(respond.data.FamilyList, value => {
          if (value.FamilyID === $scope.familyId) {
            $scope.taskInput.familyValue = value.FamilyFullName;
            $scope.taskSet.FamilyId = value.FamilyID;
          }
        });
      });
      $scope.isCorporateUser =
        currentUserService.accessType === ACCESS_TYPE.CORPORATE;
      $scope.isNzBased =
        COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
      const isLoanWriter =
        $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
      contactModelService.taskAdviserListGet(isLoanWriter).then(respond => {
        $scope.taskAdviserList = respond.data;
        $scope.taskAdviserList = _.reject(respond.data, ['BrokerID', 0]);
        angular.forEach(respond.data, item => {
          item.BrokerID = item.BrokerID.toString();
        });
      });

      $scope.saveTemplatedTask = function() {
        const $postDataList = [];
        angular.forEach($scope.templatedTaskList, item => {
          const $taskData = {
            FamilyID: $scope.taskSet.FamilyId || $state.params.familyId,
            TaskID: 0,
            Title: item.TaskName,
            assignedClientID: item.AssignedAdvisorID,
            CreatedByUser: $scope.mainObject.CreatedByUser,
            DueDate: moment(item.DueDate).format('YYYY-MM-DD'),
            Notes: '',
            TaskSettingsID: item.TaskSettingsID,
          };
          $postDataList.push($taskData);
        });

        const $postData = {
          TaskList: $postDataList,
          TaskTemplateID: $scope.selectedTemplateId,
        };
        taskService.TaskSetv3Multiple($postData).then(() => {
          toaster.pop('success', 'Added', 'Templated task successfully added');
          $scope.getTasksList(0);
          $scope.templatedTimeout = $timeout(() => {
            $uibModalInstance.dismiss('cancel');
          }, 100);
        });
      };
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.filterInitial = function(...args) {
        let nameInitials = '';
        if (!args || !args[0]) {
          return;
        }
        for (let a = 0; a < args.length; a++) {
          if (!_.isNil(args[a]) && _.isString(args[a])) {
            nameInitials = `${nameInitials}${args[a].charAt(0).toUpperCase()}`;
          }
        }
        return nameInitials;
      };
    }
  );
