import template from './selectContactTypeTemplate.html';
import controller from './selectContactTypeCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    formData: '=',
    insurersList: '=',
    onClientTypeChange: '&',
    onNewClientSelected: '&',
  },
};
