import template from './borrowers.html';
import controller from './borrowersCtrl';

export default {
  template,
  bindings: {
    onShowAddExistingPanel: '&',
    onSelectClient: '&',
    onPersonModal: '&',
    formData: '<',
    onEditFamily: '&',
    onCompanyModal: '&',
    onSearchClient: '&',
    onEditCompany: '&',
    onRemoveFamily: '&',
    searchClientName: '=',
    showThisPanel: '<',
    onRemoveCompany: '&',
    showClientList: '<',
    displayFamily: '<',
    clientList: '<',
    selectedAdviser: '<',
    showSelectAdviser: '<',
    displayCompany: '<',
  },
  controller,
  controllerAs: 'vm',
};
