export const PIPELINE_STATUS = {
  APPLICATION: 1,
  NOT_PROCEEDED: 9,
  TO_OPPORTUNITY: 10,
  NEW_OPPORTUNITY: 13,
  SETTLED: 2,
  NEW_LEADS: 4,
};

export const CONVERSION_PIPELINE_STATUS = {
  TO_APPLICATION: 1,
  TO_SETTLED: 2,
  TO_OPPORTUNITY: 10,
  TO_NOT_PROCEEDED: 9,
};

export const CONVERSION_PIPELINE_OPTIONS = {
  LEADS: [
    {
      PIPELINE_STATUS: 'Application',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_APPLICATION,
    },
    {
      PIPELINE_STATUS: 'Opportunity',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY,
    },
    {
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
  OPPORTUNITY: [
    {
      PIPELINE_STATUS: 'Application',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_APPLICATION,
    },
    {
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
  APPLICATION: [
    {
      PIPELINE_STATUS: 'Settled',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_SETTLED,
    },
    {
      PIPELINE_STATUS: 'Not Proceeded',
      PIPELINE_STATUS_ID: CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED,
    },
  ],
};
