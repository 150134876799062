import { PIPELINE_TYPES_STR } from 'Common/constants/pipelineType';
import { objectLength } from 'Common/utilities/objectValidation';
import { goToOpportunity } from 'Common/utilities/loanOpportunity';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';

export default class CardRedirections {
  constructor(
    opportunityNewModalService,
    loanApplicationServices,
    loanOpportunityService,
    nextGenLoanAppService,
    contactService,
    pipelineService,
    $stateParams,
    stateService,
    $uibModal,
    $window,
    $state,
    $filter,
    $sce,
    uiService
  ) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.loanApplicationServices = loanApplicationServices;
    this.loanOpportunityService = loanOpportunityService;
    this.nextGenLoanAppService = nextGenLoanAppService;
    this.contactService = contactService;
    this.pipelineService = pipelineService;
    this.$stateParams = $stateParams;
    this.stateService = stateService;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$state = $state;
    this.$filter = $filter;
    this.$sce = $sce;
    this.uiService = uiService;
  }

  openWorkbenchModal(cardDetails) {
    if (!isMobileView(this.$window.innerWidth)) {
      return this.$uibModal.open({
        templateUrl: 'assets/views/loanApplication/components/workbench.html',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          cLoanId: () => cardDetails.loanID,
          CLoanStatusId: () => String(cardDetails.loanStatusID),
          CPipelineCardsID: () => cardDetails.pipelineCardsID,
          cLoanScenarioId: () => cardDetails.loanScenarioID,
          cFamilyId: () => cardDetails.familyInfo[0].familyId,
          cEnquirySource: () => cardDetails.enquirySource,
        },
        controller: 'WorkbenchModalCtrl',
        controllerAs: 'vm',
        windowTopClass: 'workbench-modal',
      }).result;
    }

    const params = {
      LoanID: cardDetails.loanID,
      LoanStatusID: String(cardDetails.loanStatusID),
      PipelineCardsID: cardDetails.pipelineCardsID,
      LoanScenarioID: cardDetails.loanScenarioID,
      familyId: cardDetails.familyInfo[0].familyId,
      enquirySource: cardDetails.enquirySource,
      clientNames: cardDetails.clients.trim(),
    };

    this.stateService.savePrevState(
      this.$state.$current.self,
      this.$state.$current.locals.globals.$stateParams
    );
    this.$state.go('app.mobileWorkbench', { ...params });
  }

  getFamiliesPerLoan(cardDetails, key = 'loanID') {
    const { familyInfo } = cardDetails;
    const loanId = cardDetails[key];
    if (familyInfo && objectLength(familyInfo)) return;
    return this.contactService
      .getFamiliesPerLoan(loanId)
      .then(({ succeeded, data }) => {
        if (!succeeded || !data) return;

        const { familyInfo: families } = data;
        const clients = families.filter(
          family => family.isClient && !family.isGuarantor
        );
        const entities = families.filter(family => !family.isClient);
        const guarantors = families.filter(
          family => family.isClient && family.isGuarantor
        );

        cardDetails.familyInfo = [...clients, ...entities, ...guarantors];
      });
  }

  clickPipelineItem(params) {
    const {
      event,
      card,
      pipelineType,
      opportunityParams,
      callback,
      callbackParams,
    } = params;
    event && event.stopPropagation();
    if (!event || !card || !pipelineType) return;
    switch (pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY: {
        const { loanScenarioId: opportunityId, loanId } = card;
        const redirectParams = {
          opportunityId,
          loanId,
          ...opportunityParams,
        } || {
          opportunityId,
          loanId,
          sourceFamilyId: 0,
          sourceFamilyName: '',
          isContactOpener: false,
          tabName: 'details',
        };
        goToOpportunity(this.$state, redirectParams);
        break;
      }
      case PIPELINE_TYPES_STR.APPLICATION: {
        if (!card.loanID) return;

        if (!card.familyInfo || !objectLength(card.familyInfo)) {
          this.getFamiliesPerLoan(card).then(() => {
            this.openWorkbenchModal(card).then(
              () => {
                callback && callback(callbackParams);
              },
              () => {
                callback && callback(callbackParams);
              }
            );
          });
        } else {
          this.openWorkbenchModal(card).then(
            () => {
              callback && callback(callbackParams);
            },
            () => {
              callback && callback(callbackParams);
            }
          );
        }
        break;
      }
      default:
        break;
    }
  }

  redirectLoanApp(cardDetails) {
    const { loanScenarioID: loanAppId } = cardDetails;
    const familyId = cardDetails.familyInfo[0].familyId;
    this.$state.go('app.loanApplicationDetailsV3', { familyId, loanAppId });
  }

  viewLoanApp(event, cardDetails) {
    event && event.stopPropagation();

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.redirectLoanApp(cardDetails);
      });
    } else {
      this.redirectLoanApp(cardDetails);
    }
  }

  viewBorrower(event, family) {
    event && event.stopPropagation();

    const { familyId, isClient } = family;
    if (isClient) {
      this.$state.go('app.contactsSingle', { familyId, activeTab: 'lending' });
    } else {
      this.$state.go('app.BusinessAccount', { familyId, activeTab: 'loans' });
    }
  }

  viewSubmission(event, cardDetails) {
    event && event.stopPropagation();

    const { loanScenarioID: LoanScenarioID, loanID: LoanID } = cardDetails;

    this.nextGenLoanAppService
      .nextGenAOLView({ LoanScenarioID, LoanID })
      .then(({ data }) => {
        const nextGenObj = data;
        nextGenObj.https = this.$sce.trustAsResourceUrl(nextGenObj.https);
        const guid = nextGenObj.GUID || '';
        const startPage = nextGenObj.StartPage || '';
        const applicationViewURL = `${
          nextGenObj.https
        }?GUID=${guid}&StartPage=${startPage}`;
        this.$window.open(applicationViewURL);
      });
  }

  duplicateLoan(cardDetails) {
    const familyId = cardDetails.familyInfo[0].familyId;
    const { loanScenarioID } = cardDetails;
    this.loanApplicationServices.cloneLoan(loanScenarioID, familyId).then(
      ({ data: loanAppId }) => {
        cardDetails.loansSectionLoadingStatus = false;
        this.$state.go('app.loanApplicationDetailsV3', { familyId, loanAppId });
      },
      () => {
        cardDetails.loansSectionLoadingStatus = false;
      }
    );
  }

  onDuplicateLoan(event, cardDetails) {
    event && event.stopPropagation();

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.duplicateLoan(cardDetails);
      });
    } else {
      this.duplicateLoan(cardDetails);
    }
  }
}
