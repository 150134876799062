import angular from 'angular';

angular.module('app').directive('triggerFocus', function triggerFocus() {
  return {
    restrict: 'A',
    link: (scope, elem, attrs) => {
      if (!attrs.triggerFocus) return;

      const clickListener = () => {
        const targetElem = angular.element(`#${attrs.triggerFocus}`);
        if (targetElem.length) {
          targetElem[0].focus();
        }
      };
      elem.on('click', clickListener);
      scope.$on('$destroy', () => {
        elem.off('click', clickListener);
      });
    },
  };
});
