import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import {
  ADVISER_STATUS,
  ADVISER_STATUS_NAME,
} from 'Common/constants/adviserStatus';
import {
  convertToLowerCase,
  removeSpacesOnString,
} from 'Common/utilities/string';
import { ADVISER_ORG_TAB } from 'Common/constants/contactTabIndex';
import { displayMap, displayCustomAddress } from 'Common/utilities/gMap';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { NO_BRANDING_CATEGORY_WARNING } from 'Common/constants/promptMessages';
import { setCurrrentDate, setDateFormat } from 'Common/utilities/date';
import { displayWarning } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('AdviserOrgCtrl', function AdviserOrgCtrl(
    corporatePermissionService,
    referralAgreementService,
    regionalizationService,
    broadcastDataService,
    addressModalService,
    corporateService,
    referralsService,
    utilitiesService,
    modelDataService,
    dashboardService,
    commonFnService,
    contactService,
    generalService,
    queryService,
    dataService,
    $rootScope,
    SweetAlert,
    mapService,
    $uibModal,
    $timeout,
    toaster,
    $filter,
    $window,
    $state,
    $scope,
    $http,
    $sce,
    $q
  ) {
    /* Filter */
    $filter('lowercase')();
    /* State Params */
    $scope.familyId = $state.params.familyId || 0;
    $scope.clientId = $state.params.clientId || 0;
    $scope.adviserOrgId = $state.params.adviserOrgId || 0;
    $scope.referrerOrgId = $state.params.referrerOrgId || 0;
    $scope.adviserComplianceId = $state.params.adviserComplianceId || 0;
    $scope.referrerComplianceId = $state.params.referrerComplianceId || 0;
    /*   VARIABLE(s)        */
    /* Data Type: {Object}  */
    $scope.contactsUnderOrganizationGet = {};
    $scope.organizationAgreementGet = {};
    $scope.adviserOrganizationGet = {};
    $scope.adviser_org_address = {};
    $scope.editMode = {};
    $scope.saveType = {};
    $scope.shipping = {};
    /* Data Type: {Array} */
    $scope.adviserOrganizationEntityTypesGet = [];
    $scope.advisersUnderOrganizations = [];
    $scope.brandingCategoryListGet = [];
    $scope.operatingCountriesGet = [];
    $scope.adviserOrgTypesGet = [];
    $scope.brokerStatusLists = [];
    $scope.cessationReasons = [];
    $scope.altInputFormats = [];
    $scope.review_months = [];
    $scope.setterType = [];

    $scope.adviserOrgPage = {};
    $scope.adviserOrgPage.page = 1;
    $scope.adviserOrgPage.size = 10;
    $scope.adviserOrgPage.total = 0;
    $scope.referralType = REFERRAL_TYPE;
    /* Data Type: {Array of Objects} */
    $scope.tabsAdvisersOrgs = [
      {
        name: 'Business Details',
        page: 'assets/views/corporate/adviser-org/adviser-org-details.html',
        value: 'business-details',
      },
      {
        name: 'Contact',
        page: 'assets/views/corporate/adviser-org/contact.html',
        value: 'contact',
      },
      {
        name: 'Agreements',
        page: 'assets/views/corporate/adviser-org/agreements.html',
        value: 'agreements',
      },
      {
        name: 'Marketing',
        page: 'assets/views/corporate/adviser-org/marketing.html',
        value: 'marketing',
      },
      {
        name: 'Members',
        page: 'assets/views/corporate/adviser-org/members.html',
        value: 'members',
      },
      {
        name: 'Notes',
        page: 'assets/views/contacts/client/notes.html',
        value: 'notes',
      },
      {
        name: 'Documents',
        page: 'assets/views/contacts/client/documents.html',
        value: 'documents',
      },
    ];
    /* Data Type: {String}{Boolean}{Interger} */
    $scope.businessDetailsOrgAddresss = 'businessDetailsAddresss';
    $scope.isSameinAdviserOrgAddress = false;
    $scope.userCurrentCountyId = 0;
    $scope.selectedAdviserOrgContacts = 0;
    $scope.showEditAdviser = false;
    $scope.dateStaticValue = '1900-01-01';
    $scope.editAdviserOrg = false;
    $scope.isProfileAdmin = false;
    $scope.currentDate = '';
    $scope.dateFormat = '';
    const currentState = {
      state: 'app.contactsAdviserOrg',
      params: {
        adviserOrgId: $scope.adviserOrgId,
        adviserComplianceId: $scope.adviserComplianceId,
      },
    };
    /*  FUNCTION DEFINITION(S)  */
    const getBrandingCategories = countryId => {
      dataService.getBrandingCategories(countryId).then(brandingLists => {
        $scope.brandingCategoryListGet = brandingLists;
      });
    };

    const loadAdviserOrgAddress = () => {
      corporateService
        .organizationAddressGet($scope.adviserOrgId)
        .then(response => {
          if (response && response.data) {
            $scope.organizationAddressGet = response.data;
            $scope.organizationAddressGet &&
              Object.keys($scope.organizationAddressGet).forEach(key => {
                if ($scope.organizationAddressGet[key].isMailing) {
                  $scope.shipping = angular.extend(
                    {},
                    $scope.organizationAddressGet[key]
                  );
                } else if (!$scope.organizationAddressGet[key].isMailing) {
                  $scope.adviser_org_address = angular.extend(
                    {},
                    $scope.organizationAddressGet[key]
                  );
                }
                if (
                  $scope.adviser_org_address.formatted_address ===
                  $scope.shipping.formatted_address
                ) {
                  $scope.isSameinAdviserOrgAddress = true;
                }
              });
            if (_.isUndefined($scope.shipping)) {
              $scope.shipping = modelDataService.addressModelData();
            }
            if (_.isUndefined($scope.adviser_org_address)) {
              $scope.adviser_org_address = modelDataService.addressModelData();
            }
          }
        });
    };

    const getNotesAdviserOrg = () => {
      angular.extend($scope, {
        pagination: {
          bigTotalItems: 0,
          bigCurrentPage: 1,
          maxSize: 5,
          itemsPerPage: 5,
        },
      });
      const tempFamilyId = 1;
      $scope.brokerBrandingId = $state.params.adviserOrgId || 0;
      contactService
        .notesListCount(1, $scope.brokerBrandingId)
        .then(response => {
          $scope.pagination.bigTotalItems = response.data;
          contactService
            .notesList(
              tempFamilyId,
              $scope.pagination.bigTotalItems,
              $scope.brokerBrandingId
            )
            .then(notesData => {
              $scope.noteListData = notesData.data;
              if ($scope.noteListData && $scope.noteListData.length > 0) {
                $scope.noteListData.forEach(value => {
                  value.initial = utilitiesService.filterInitialOneString(
                    value.ActivityOwner
                  );
                });
              }
              $scope.$watch($scope.noteListData, () => {
                $scope.notesContainer = $scope.noteListData;
              });
            });
        });
    };
    /*
     *   @name onRetrieveOrganizationAgreementGetSuccess
     *   @desc This will load data from Api: `organizationAgreementGet` on success.
     *   @params {Object} response - data from the api result.
     */
    function onRetrieveOrganizationAgreementGetSuccess(response) {
      if (response && response.data) {
        response.data = _.uniqBy(response.data, 'AgreementId');
        response.data = _.map(response.data, data => {
          data.referrerLists = [];
          data.showReferrers = true;
          if (data.AgreementType === 'Loan Market') {
            data.AgreementType = 'Loan Market / Wealth Market';
          }
          if (typeof data.Referrers_FullNames !== 'undefined') {
            const referrerNameArray = data.Referrers_FullNames.split(',');
            const referrerActiveArray = data.Referrers_IsActive.split(',');
            /* Display only active referrer */
            for (let index = 0; index < referrerActiveArray.length; index++) {
              if (Number(referrerActiveArray[index]) > 0) {
                data.referrerLists.push(referrerNameArray[index]);
              }
            }
          }
          if (_.size(data.referrerLists) > 1) {
            data.showReferrers = false;
          }
          return data;
        });
        $scope.organizationAgreementGet = response.data;
      }
    }
    /*
     *   @name onRetrieveContactsUnderOrganizationGetSuccess
     *   @desc This will load data from Api: `contactsUnderOrganizationGet` on success.
     *   @params {Object} response - data from the api result.
     */
    function onRetrieveContactsUnderOrganizationGetSuccess(response) {
      const data = response.data;
      if (data) {
        $scope.contactsUnderOrganizationGet = data.Result;
        $scope.adviserOrgPage.total = data.Count;
      }
    }

    const getAccessMatrixes = selectedContacts => {
      if (!selectedContacts) return;
      if (selectedContacts === 'Agreements') {
        referralAgreementService.getCreateAgreementAccess().then(response => {
          $scope.editMode.allReadOnly = !response;
        });
        return;
      }
      dataService
        .mapFieldAccessMatrixes('AdviserOrganizationContact', selectedContacts)
        .then(response => {
          $scope.editMode = response;
        });
    };

    $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
      return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
    };

    $scope.getDateFormatInView = date => {
      return dataService.setDateFormatInView(date);
    };

    function getAllAdviserOrgTabs(selectedContactTab) {
      switch (selectedContactTab) {
        case 'Business Details':
          $scope.adviserOrganizationGet.AdviserOrganizationId =
            $scope.adviserOrganizationGet.AdviserOrganizationId || 0;
          $scope.adviserOrganizationGet.ReviewMonth = $scope.adviserOrganizationGet.ReviewMonth.toString();
          corporateService.adviserOrganizationStatusesGet().then(response => {
            const brokerStatus = response.data;
            if (brokerStatus && brokerStatus.length) {
              $scope.brokerStatusLists = brokerStatus;
              $scope.adviserOrganizationGet.BrokerStatusName =
                $scope.adviserOrganizationGet.Status > -1
                  ? queryService.getArrValue(
                      $scope.adviserOrganizationGet.Status,
                      null,
                      'StatusCode',
                      'Status',
                      $scope.brokerStatusLists
                    )
                  : 'None';
              $scope.adviserOrganizationGet.StatusName = queryService.getArrValue(
                $scope.adviserOrganizationGet.Status,
                null,
                'StatusCode',
                'Status',
                $scope.brokerStatusLists
              );
            }
          });
          dataService.getAdviserOrgTypes().then(orgtypes => {
            $scope.adviserOrgTypesGet = orgtypes;
          });
          dataService.getAdviserOrganizationEntityTypes().then(entityTypes => {
            $scope.adviserOrganizationEntityTypesGet = entityTypes;
            $scope.adviserOrganizationGet.EntityTypeName = queryService.getArrValue(
              $scope.adviserOrganizationGet.EntityTypeId,
              null,
              'EntityTypeID',
              'EntityTypeName',
              $scope.adviserOrganizationEntityTypesGet
            );
          });
          dataService.getOperatingCountries().then(countries => {
            $scope.operatingCountriesGet = countries;
            $scope.adviserOrganizationGet.ReportingCountryName = queryService.getArrValue(
              $scope.adviserOrganizationGet.CountryId,
              null,
              'CountryId',
              'CountryName',
              $scope.operatingCountriesGet
            );
            $scope.adviserOrganizationGet.ReportingCountryCode = queryService.getArrValue(
              $scope.adviserOrganizationGet.CountryId,
              null,
              'CountryId',
              'CountryCode',
              $scope.operatingCountriesGet
            );
            $scope.mobileMask = broadcastDataService.getMobileFormat(
              $scope.adviserOrganizationGet.CountryId
            );
            $scope.phoneMask = commonFnService.getAdviserOrgMobileFormat(
              $scope.adviserOrganizationGet.CountryId
            );
          });
          dataService.getCessationReason().then(cessationReasons => {
            $scope.cessationReasons = cessationReasons;
            $scope.adviserOrganizationGet.CessationReasonDesc = queryService.getArrValue(
              $scope.adviserOrganizationGet.CessationReasonID,
              null,
              'CessationReasonID',
              'CessationReasonDesc',
              $scope.cessationReasons
            );
          });

          loadAdviserOrgAddress();
          break;
        case 'Contact':
          dataService
            .getAdvisersUnderOrganization(
              $scope.adviserOrganizationGet.AdviserOrganizationId
            )
            .then(adviserOrgUnderOrg => {
              $scope.advisersUnderOrganizations = adviserOrgUnderOrg;
              $scope.adviserOrganizationGet.LeadAviser_FullName = queryService.getArrValue(
                $scope.adviserOrganizationGet.LeadAdviser_FamilyId,
                null,
                'FamilyId',
                'FullName',
                $scope.advisersUnderOrganizations
              );
              $scope.adviserOrganizationGet.LeadAviser_FullNameTmp = queryService.getArrValue(
                $scope.adviserOrganizationGet.LeadAdviser_FamilyId,
                null,
                'FamilyId',
                'FullName',
                $scope.advisersUnderOrganizations
              );
              $scope.adviserOrganizationGet.FranchisePrincipalName = queryService.getArrValue(
                $scope.adviserOrganizationGet.FranchisePrincipalFamiyId,
                null,
                'FamilyId',
                'FullName',
                $scope.advisersUnderOrganizations
              );
              $scope.adviserOrganizationGet.FranchisePrincipalNameTmp = queryService.getArrValue(
                $scope.adviserOrganizationGet.FranchisePrincipalFamiyId,
                null,
                'FamilyId',
                'FullName',
                $scope.advisersUnderOrganizations
              );
            });

          $scope.userCurrentIsAustralia =
            $scope.adviserOrganizationGet.CountryId ===
              COUNTRY_TYPE.AUSTRALIA || 0;

          if (typeof $scope.adviserOrganizationGet.Phone === 'string') {
            const phoneDigit =
              $scope.adviserOrganizationGet.CountryId === 1 ? -6 : -9;
            $scope.adviserOrganizationGet.PhoneAlt = $scope.adviserOrganizationGet.Phone.replace(
              / /g,
              ''
            ).slice(phoneDigit);
          }
          break;
        case 'Agreements':
          corporateService
            .organizationAgreementGet(0, 0, $scope.adviserOrgId, 0, 0)
            .then(
              onRetrieveOrganizationAgreementGetSuccess,
              commonFnService.onRetrieveError
            );
          break;
        case 'Marketing':
          $scope.userCurrentCountyId = $scope.adviserOrganizationGet.CountryId
            ? $scope.adviserOrganizationGet.CountryId
            : $scope.userCurrentCountyId;
          getBrandingCategories($scope.userCurrentCountyId);
          if ($scope.brandingCategoryListGet.length > 0) {
            $scope.adviserOrganizationGet.BrandingCategory = queryService.getArrValue(
              $scope.adviserOrganizationGet.BrandingCategoryId,
              null,
              'BrandingCategoryId',
              'BrandingCategory',
              $scope.brandingCategoryListGet
            );
          }
          break;
        case 'Members':
          corporateService
            .contactsUnderOrganizationGet(
              $scope.adviserOrgId,
              $scope.adviserOrgPage.page,
              $scope.adviserOrgPage.size
            )
            .then(
              onRetrieveContactsUnderOrganizationGetSuccess,
              commonFnService.onRetrieveError
            );
          break;
        case 'Notes':
          getNotesAdviserOrg();
          break;
        default:
          break;
      }
      getAccessMatrixes(removeSpacesOnString(selectedContactTab));
    }

    /**
     *   @name initialize
     *   @desc This will load/initialize data needed on load.
     */
    function initialize() {
      mapService.loadAPI();
      dataService.getUserInfo(true).then(response => {
        if (response && response.data) {
          $scope.isProfileAdmin =
            response.data.CorpAccessTypeID === 1 ? 'true' : 'false';
          $scope.userCurrentCountyId = response.data.CountryId;
        }
      });
      $scope.saveType = dataService.getSaveTypes('Adviser Org');
      $scope.setterType = dataService.getSetterTypes('Adviser Org');
      $scope.review_months = dataService.getAllMonths();
      $scope.dateFormat = setDateFormat();
      $scope.altInputFormats = dataService.getDateValidityFormats();
      $scope.currentDate = setCurrrentDate();
      if ($scope.adviserOrgId && $scope.adviserComplianceId) {
        const currentTab = parseInt(
          $window.sessionStorage.getItem('currentTab'),
          10
        );
        if (currentTab === ADVISER_ORG_TAB.AGREEMENTS) {
          $scope.selectedAdviserOrgContacts = currentTab;
          $window.sessionStorage.removeItem('currentTab');
        } else {
          $window.sessionStorage.removeItem('currentTab');
          $scope.selectedAdviserOrgContacts = 0;
        }
        $scope.$watch('selectedAdviserOrgContacts', newValue => {
          corporateService
            .adviserOrganizationGet(
              $scope.adviserComplianceId,
              $scope.adviserOrgId
            )
            .then(response => {
              if (response && response.data) {
                dataService.getRecentViewed();
                let tmpAdviserOrgDetails = {};
                response.data &&
                  Object.keys(response.data).forEach(x => {
                    tmpAdviserOrgDetails = response.data[x];
                  });
                if ($scope.tabsAdvisersOrgs[newValue]) {
                  tmpAdviserOrgDetails.ABN = tmpAdviserOrgDetails.ABN
                    ? tmpAdviserOrgDetails.ABN.replace(/-/g, '')
                    : tmpAdviserOrgDetails.ABN;
                  tmpAdviserOrgDetails.ACN = tmpAdviserOrgDetails.ACN
                    ? tmpAdviserOrgDetails.ACN.replace(/-/g, '')
                    : tmpAdviserOrgDetails.ACN;
                  $scope.adviserOrganizationGet = tmpAdviserOrgDetails;
                  getAllAdviserOrgTabs($scope.tabsAdvisersOrgs[newValue].name);
                }
              }
            }, commonFnService.onRetrieveError);
        });
      }
    }
    /*
     *   @name querySetAdviserOrg
     *   @desc This will save the value from api.
     *   @params {String} setter
     *   @params {String} key
     *   @params {Array} arrays
     *   @params {Boolean} isCorporate
     */
    function querySetAdviserOrg(
      adviserOrgSetter,
      key,
      adviserOrgField,
      adviserOrgArrays
    ) {
      $scope.editMode[key] = false;
      commonFnService.setEditMode($scope.editMode, adviserOrgField);
      corporateService[adviserOrgSetter]
        .apply(null, adviserOrgArrays)
        .then(response => {
          if (response) {
            toaster.pop('success', 'Update', 'successfully updated!');
          }
        });
      toaster.clear();
    }

    function checkEmailValidity(email, familyId) {
      const query = $q.defer();
      generalService
        .getCheckIfEmailExists(email, familyId, 1)
        .then(response => {
          if (response) {
            query.resolve(response.data > 0);
          }
        });
      return query.promise;
    }

    $scope.onSameinAdviserOrgAddress = function(q) {
      $scope.isSameinAdviserOrgAddress = q;
      if ($scope.isSameinAdviserOrgAddress) {
        commonFnService
          .saveAddress(
            $scope.adviserOrgId,
            $scope.adviserComplianceId,
            $scope.adviser_org_address,
            true,
            false,
            true
          )
          .then(() => {
            loadAdviserOrgAddress();
            $scope.editAdviserOrg = false;
            $scope.showEditAdviser = false;
          });
      }
    };

    $scope.saveAdviserAddress = function(type, address) {
      const isBusiness = type === 'business';
      const isMailing = type === 'shipping';
      commonFnService
        .saveAddress(
          $scope.adviserOrgId,
          $scope.adviserComplianceId,
          address,
          isMailing,
          isBusiness,
          true
        )
        .then(() => {
          loadAdviserOrgAddress();
          $scope.editAdviserOrg = false;
          $scope.showEditAdviser = false;
        });
    };

    $scope.setClasses = function(status, statusId, isbtn) {
      return isbtn
        ? dataService.getBtnClasses()[statusId]
        : dataService.getFontClasses()[statusId];
    };

    $scope.viewReferrer = function(data) {
      data.showReferrers = !data.showReferrers;
    };

    $scope.hideShowAdviserOrg = function() {
      $scope.editAdviserOrg = !$scope.editAdviserOrg;
    };
    $scope.hideShowShippingAdviserOrg = function() {
      $scope.showEditAdviser = !$scope.showEditAdviser;
    };

    $scope.setStatus = function(name, setterType, obj, saveType) {
      let key;
      let field;
      obj.IsActive = null;

      const invalidBrandingCategory =
        name.Status === ADVISER_STATUS_NAME.ACTIVE && !obj.BrandingCategoryId;
      if (invalidBrandingCategory) {
        const warningMsg = NO_BRANDING_CATEGORY_WARNING.ADVISER_ORG;
        displayWarning(warningMsg, 'Warning', false);
        return;
      }

      if (setterType === 'adviserOrganizationSet') {
        $scope.adviserOrganizationGet.BrokerStatusName = name.Status;
        key = 'BrokerStatusName';
        field = key;
      }
      $scope.updateAdviserOrgDetails(setterType, obj, key, field, saveType);
    };
    $scope.updateEmail = function(setterType, obj, key, field, saveType) {
      if (key === 'CompanyEmail') {
        checkEmailValidity(obj.CompanyEmail, $scope.adviserOrgId).then(prom => {
          if (prom) {
            swal(
              'Email Validation',
              'Email is already used by other user.',
              'error'
            );
          } else {
            $scope.updateAdviserOrgDetails(
              setterType,
              obj,
              key,
              field,
              saveType
            );
          }
        });
      } else {
        $scope.updateAdviserOrgDetails(setterType, obj, key, field, saveType);
      }
    };

    $scope.dateEditMode = function(obj, key) {
      return commonFnService.dateEditMode(obj, key, $scope.editMode);
    };

    $scope.formatDateString = function(date) {
      return commonFnService.formatDateString(date);
    };

    $scope.formatDate = function(date) {
      return commonFnService.formatDate(date);
    };

    $scope.convertPipeToCSV = function(str) {
      return commonFnService.convertPipeToCSV(str);
    };

    $scope.updateAdviserOrgDetails = function(
      setterType,
      obj,
      key,
      field,
      saveType,
      formName,
      inputName
    ) {
      let dataArray = [];
      const invalidAcnAbnFormat =
        (key === 'ACN' && !obj.ACN) || (key === 'ABN' && !obj.ABN);
      if (invalidAcnAbnFormat) {
        toaster.pop('error', 'Error', 'Invalid Format.');
        return false;
      }

      if (setterType === 'adviserOrganizationSet') {
        if (formName && inputName) {
          if (
            formName[inputName].$error.pattern &&
            commonFnService.checkInputPattern(formName, inputName)
          )
            return false;
          if (
            formName[inputName].$error.date &&
            !commonFnService.checkFormDateValidation(
              formName,
              inputName,
              obj,
              key
            )
          )
            return false;
        } else {
          switch (key) {
            case 'StatusName':
              obj.Status = queryService.getArrValue(
                null,
                obj.StatusName,
                'StatusCode',
                'Status',
                $scope.adviserOrganizationStatusesGet
              );
              break;
            case 'EntityTypeName':
              obj.EntityTypeId = queryService.getArrValue(
                null,
                obj.EntityTypeName,
                'EntityTypeID',
                'EntityTypeName',
                $scope.adviserOrganizationEntityTypesGet
              );
              obj.EntityTypeName = queryService.getArrValue(
                obj.EntityTypeId,
                null,
                'EntityTypeID',
                'EntityTypeName',
                $scope.adviserOrganizationEntityTypesGet
              );
              break;
            case 'BrokerStatusName':
              obj.Status = queryService.getArrValue(
                null,
                obj.BrokerStatusName,
                'StatusCode',
                'Status',
                $scope.brokerStatusLists
              );
              break;
            case 'AdviserOrgTypeDesc':
              obj.AdviserOrgTypeID = queryService.getArrValue(
                null,
                obj.AdviserOrgTypeDesc,
                'AdviserOrgTypeID',
                'AdviserOrgTypeDesc',
                $scope.adviserOrgTypesGet
              );
              obj.AdviserOrgTypeDesc = queryService.getArrValue(
                obj.AdviserOrgTypeID,
                null,
                'AdviserOrgTypeID',
                'AdviserOrgTypeDesc',
                $scope.adviserOrgTypesGet
              );
              break;
            case 'ReviewMonth':
              obj.ReviewMonth = Number(obj.ReviewMonth);
              break;
            case 'ReportingCountryCode':
              obj.CountryId = queryService.getArrValue(
                null,
                obj.ReportingCountryCode,
                'CountryId',
                'CountryCode',
                $scope.operatingCountriesGet
              );
              obj.ReportingCountryCode = queryService.getArrValue(
                obj.CountryId,
                null,
                'CountryId',
                'CountryCode',
                $scope.operatingCountriesGet
              );
              obj.ReportingCountryName = queryService.getArrValue(
                obj.CountryId,
                null,
                'CountryId',
                'CountryName',
                $scope.operatingCountriesGet
              );
              break;
            case 'BrandingCategory':
              if (
                $scope.userCurrentCountyId &&
                $scope.userCurrentCountyId < 3
              ) {
                getBrandingCategories($scope.userCurrentCountyId);
              }
              obj.BrandingCategoryId = queryService.getArrValue(
                null,
                obj.BrandingCategory,
                'BrandingCategoryId',
                'BrandingCategory',
                $scope.brandingCategoryListGet
              );
              break;
            case 'CessationReasonDesc':
              obj.CessationReasonID = queryService.getArrValue(
                null,
                obj.CessationReasonDesc,
                'CessationReasonID',
                'CessationReasonDesc',
                $scope.cessationReasons
              );
              obj.CessationReasonDesc = queryService.getArrValue(
                obj.CessationReasonID,
                null,
                'CessationReasonID',
                'CessationReasonDesc',
                $scope.cessationReasons
              );
              break;
            case 'AccountOwner_FullName':
              if (obj.AccountOwner_FullName) {
                if (typeof obj.AccountOwner_FullName === 'object') {
                  obj.AccountOwner_FullName =
                    obj.AccountOwner_FullName.originalObject.FullName;
                  obj.AccountOwner_FamilyId = queryService.getArrValue(
                    null,
                    obj.AccountOwner_FullName,
                    'FamilyId',
                    'FullName',
                    $scope.adminUsers
                  );
                  obj.AccountOwner_FullNameTmp = queryService.getArrValue(
                    obj.AccountOwner_FamilyId,
                    null,
                    'FamilyId',
                    'FullName',
                    $scope.adminUsers
                  );
                }
              } else {
                obj.AccountOwner_FullName = '';
                obj.AccountOwner_FamilyId = 0;
                obj.AccountOwner_FullNameTmp = '';
              }
              break;
            case 'LeadAviser_FullName':
              if (obj.LeadAviser_FullName) {
                if (typeof obj.LeadAviser_FullName === 'object') {
                  obj.LeadAviser_FullName =
                    obj.LeadAviser_FullName.originalObject.FullName;
                  obj.LeadAdviser_FamilyId = queryService.getArrValue(
                    null,
                    obj.LeadAviser_FullName,
                    'FamilyId',
                    'FullName',
                    $scope.advisersUnderOrganizations
                  );
                  obj.LeadAviser_FullNameTmp = queryService.getArrValue(
                    obj.LeadAdviser_FamilyId,
                    null,
                    'FamilyId',
                    'FullName',
                    $scope.advisersUnderOrganizations
                  );
                }
              } else {
                obj.LeadAviser_FullName = '';
                obj.LeadAdviser_FamilyId = 0;
                obj.LeadAviser_FullNameTmp = '';
              }
              break;
            case 'FranchisePrincipalName':
              if (obj.FranchisePrincipalName) {
                if (typeof obj.FranchisePrincipalName === 'object') {
                  obj.FranchisePrincipalName =
                    obj.FranchisePrincipalName.originalObject.FullName;
                  obj.FranchisePrincipalFamiyId = queryService.getArrValue(
                    null,
                    obj.FranchisePrincipalName,
                    'FamilyId',
                    'FullName',
                    $scope.advisersUnderOrganizations
                  );
                  obj.FranchisePrincipalNameTmp = queryService.getArrValue(
                    obj.FranchisePrincipalFamiyId,
                    null,
                    'FamilyId',
                    'FullName',
                    $scope.advisersUnderOrganizations
                  );
                }
              } else {
                obj.FranchisePrincipalName = '';
                obj.FranchisePrincipalFamiyId = 0;
                obj.FranchisePrincipalNameTmp = '';
              }
              break;
            case 'PhoneAlt':
              obj.Phone = obj.PhoneAlt !== '' ? `0${obj.PhoneAlt}` : '';
              break;
            default:
              break;
          }
        }
        dataArray = [$scope.saveType[saveType], obj];
      }
      querySetAdviserOrg(setterType, key, field, dataArray);
    };

    $scope.openCategoryModal = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/category_modal.html',
        scope: $scope,
        controller($uibModalInstance) {
          $scope.categoryModalType = 'Select';
          $scope.isNewCategoryVisible = false;
          $scope.newCategoryList = [];
          $scope.existingCategoryNameList = [];
          $scope.contactInfoSetValue = [];

          function getCategory() {
            contactService.getCategory().then(
              response => {
                $scope.existingCategoryNameList = [];
                const listOfCategory = [];
                const data = response.data;
                data &&
                  Object.keys(data).forEach(x => {
                    const categoryName = data[x].CategoryName || '';
                    if (
                      listOfCategory.indexOf(categoryName) === -1 &&
                      categoryName !== ''
                    ) {
                      listOfCategory.push(categoryName);
                      $scope.existingCategoryNameList.push(data[x]);
                    }
                  });
                if ($scope.adviserOrganizationGet) {
                  $scope.contactInfoSetValue = $scope.adviserOrganizationGet.AdviserCategoryNames.split(
                    '|'
                  ).map(item => {
                    return item.trim();
                  });
                }
              },
              () => {}
            );
          }
          $scope.setCorporate = function(
            categoryId,
            isExclusiveForCorpUser,
            positionIndex
          ) {
            $scope.filteredItems[
              positionIndex
            ].isExclusiveForCorpUser = !(typeof isExclusiveForCorpUser ===
            'undefined'
              ? false
              : isExclusiveForCorpUser);
            contactService
              .setContactCategoryCorpUser(
                categoryId,
                !(typeof isExclusiveForCorpUser === 'undefined'
                  ? false
                  : isExclusiveForCorpUser)
              )
              .then(() => {});
          };
          $scope.isCategoryPresent = function(category) {
            return $scope.contactInfoSetValue.indexOf(category) !== -1;
          };
          $scope.categoryCheckedAdd = function(
            categoryAdviserOrgName,
            checked
          ) {
            if (checked) {
              $scope.contactInfoSetValue.push(categoryAdviserOrgName);
            } else {
              const categoryIdx = $scope.contactInfoSetValue.indexOf(
                categoryAdviserOrgName
              );
              $scope.contactInfoSetValue.splice(categoryIdx, 1);
            }
          };
          $scope.addCategory = function() {
            $scope.isNewCategoryVisible = true;
            const newCategory = {
              CategoryID: Math.random(),
              CategoryName: '',
              IsChecked: true,
            };
            $scope.newCategoryList.push(newCategory);
          };
          $scope.deleteCategory = function(categoryID) {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text: 'This record will be removed from your category list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: true,
              },
              confirm => {
                if (confirm) {
                  contactService.deleteCategory(categoryID).then(
                    () => {
                      getCategory();
                      // swal("Deleted", "Category has been deleted.", "success");
                      toaster.pop(
                        'success',
                        'Deleted',
                        'Category has been successfully deleted'
                      );
                    },
                    e => {
                      swal(
                        'Yikes! Something is wrong',
                        e.data.Message,
                        'error'
                      );
                    }
                  );
                }
              }
            );
          };
          $scope.saveCategory = function(categoryAdviserOrg) {
            if (categoryAdviserOrg.CategoryName === '') {
              swal('Empty Name', 'Category Name cannot be empty.', 'error');
              return;
            }
            contactService.addCategory(categoryAdviserOrg.CategoryName).then(
              () => {
                const index = $scope.newCategoryList.indexOf(
                  categoryAdviserOrg
                );
                if (index > -1) $scope.newCategoryList.splice(index, 1);
                getCategory();
                toaster.pop(
                  'success',
                  'Added',
                  'Category has been successfully added'
                );
              },
              e => {
                swal('Yikes! Something is wrong', e.data.Message, 'error');
              }
            );
          };
          $scope.hideEditButton = function(category) {
            category.IsEditMode = true;
          };
          $scope.updateCategory = function(currentlyEditedCategory) {
            if (currentlyEditedCategory.CategoryName === '') {
              swal('Empty Name', 'Category Name cannot be empty.', 'error');
              return;
            }
            currentlyEditedCategory.IsEditMode = false;
            contactService
              .updateCategory(
                currentlyEditedCategory.CategoryID,
                currentlyEditedCategory.CategoryName
              )
              .then(
                () => {
                  toaster.pop(
                    'success',
                    'Updated',
                    'Category has been successfully updated'
                  );
                },
                e => {
                  swal('Yikes! Something is wrong', e.data.Message, 'error');
                }
              );
          };
          function reorder(category, orderBy) {
            contactService.updateCategoryOrder(category, orderBy).then(() => {
              getCategory();
            });
          }
          $scope.reorderUp = function(category, index) {
            if (index > 0) {
              const itemAbove =
                $scope.filteredItems[
                  $scope.filteredItems.indexOf(category) - 1
                ];
              reorder(category.CategoryID, itemAbove.OrderBy);
            }
          };
          $scope.reorderDown = function(category, index) {
            if (index + 1 < $scope.existingCategoryNameList.length) {
              const itemBelow =
                $scope.filteredItems[
                  $scope.filteredItems.indexOf(category) + 1
                ];
              reorder(category.CategoryID, itemBelow.OrderBy);
            }
          };
          $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
          };
          $scope.done = function() {
            // todo: checkedCategories contains categories which are checked
            $scope.existingCategoryNameList.filter(item => {
              return item.IsChecked ? item : '';
            });
            const categoryIds = [];
            const categoryNames = [];
            $scope.contactInfoSetValue &&
              Object.keys($scope.contactInfoSetValue).forEach(x => {
                $scope.existingCategoryNameList &&
                  Object.keys($scope.existingCategoryNameList).forEach(
                    existingCategory => {
                      if (
                        $scope.existingCategoryNameList[existingCategory]
                          .CategoryName === $scope.contactInfoSetValue[x]
                      ) {
                        categoryIds.push(
                          $scope.existingCategoryNameList[existingCategory]
                            .CategoryID
                        );
                        categoryNames.push(
                          $scope.existingCategoryNameList[existingCategory]
                            .CategoryName
                        );
                      }
                    }
                  );
              });
            if ($scope.adviserOrganizationGet) {
              $scope.adviserOrganizationGet.AdviserCategoryNames =
                _.size(categoryNames) > 0 ? categoryNames.join('|') : '';
              $scope.adviserOrganizationGet.AdviserCategoryIDs =
                _.size(categoryIds) > 0 ? categoryIds.join(',') : '';
              if (_.size($scope.setterType) > 0) {
                $scope.updateAdviserOrgDetails(
                  $scope.setterType[0],
                  $scope.adviserOrganizationGet,
                  'AdviserCategoryIDs',
                  'AdviserCategoryIDs',
                  'all'
                );
              }
            }
            $uibModalInstance.dismiss('cancel');
          };

          function init() {
            getCategory();
          }
          init();
        },
        size,
      });
    };

    $scope.gotoContact = function(member) {
      if (
        member.ContactType === 'Adviser' ||
        member.ContactType === 'Principal Adviser'
      ) {
        $state.go('app.contactsAdviser', {
          clientId: 0,
          familyId: member.FamilyId,
        });
      } else if (member.ContactType === 'Assistant') {
        $state.go('app.contactsAssistant', {
          clientId: 0,
          familyId: member.FamilyId,
        });
      } else {
        $state.go('app.contactsSingle', { familyId: member.FamilyId });
      }
    };
    /* ------------------------Elmer-----------------------------------------------------*/
    // Code for Modals by Elmer
    $scope.addAgreementAdviserOrg = referralType => {
      $window.sessionStorage.setItem('currentTab', ADVISER_ORG_TAB.AGREEMENTS);
      referralAgreementService.launchNewReferralModal(
        referralType,
        currentState,
        $scope
      );
    };
    /* elmer To Do: please put this in services */
    $rootScope.loadAgreemnt = function() {
      corporateService
        .organizationAgreementGet(0, 0, $scope.adviserOrgId, 0, 0)
        .then(response => {
          $scope.organizationAgreementGet = response.data;
        });
    };
    // Temporarily Removed
    // $scope.addNewOrgMember = function () {
    //    $rootScope.referrerOrganizationId = $state.params.adviserOrgId;
    //    $rootScope.modeForModal = 'add';
    //    $uibModal.open({
    //        templateUrl: 'assets/views/contacts/referrer-org/referrer_member.html',
    //        size: 'md',
    //        backdrop: 'static',
    //        keyboard: false,
    //        controller: 'referrerAsMemberNewCtrl'
    //    });
    // };
    // $rootScope.loadMemberWhenModalClose = function () {
    //    queryService.queryGet($scope, corporateService, 'contactsUnderOrganizationGet', [$scope.adviserOrgId], function () {
    //    });
    // };
    $scope.deleteContact = function() {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This contact will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        isConfirm => {
          if (isConfirm) {
            contactService.deleteContact($scope.adviserOrgId).then(
              () => {
                swal('Deleted', 'Contact has been deleted.', 'success');
                $state.go('app.contacts');
              },
              e => {
                SweetAlert.swal(
                  'Yikes! Something is wrong',
                  e.data.Message,
                  'error'
                );
              }
            );
          }
        }
      );
    };

    $scope.editAgreement = function(agreementId) {
      referralsService.getReferrerOrgSummary(agreementId).then(response => {
        const typeId = response.data.AgreementTYpeID === 2 ? 4 : 0;
        const orgTypeId =
          response.data.AgreementTYpeID === 3
            ? response.data.OrganisationTypeId
            : typeId;
        const agreementTypeId = response.data.AgreementTYpeID;
        $window.sessionStorage.setItem(
          'currentTab',
          ADVISER_ORG_TAB.AGREEMENTS
        );
        $window.sessionStorage.setItem(
          'currentState',
          JSON.stringify(currentState)
        );
        $state.go('app.newReferral', {
          referralType: orgTypeId,
          agreementId,
          agreementTypeId,
          requestId: 1,
        });
      });
    };
    /**
     *   Function Call
     */
    initialize();
    /* -------------------------------existing code need refactor from directive------------------------------------------*/
    $scope.selectContacts = function(selectedAdviserOrgContacts) {
      $timeout(() => {
        const ele = $window.document.querySelectorAll(
          `#${selectedAdviserOrgContacts} .nav-link`
        );
        angular.element(ele).triggerHandler('click');
      }, 0);
      $scope.selectedAdviserOrgContacts = selectedAdviserOrgContacts;
    };
    //  Code that was reused from contactDetailCtrl.js
    $scope.hiddenTabs = false;
    $scope.narrowNav = true;
    $scope.openHiddenTabs = function() {
      $scope.hiddenTabs = $scope.hiddenTabs === false;
    };
    $scope.closeHiddenTabs = function() {
      $scope.hiddenTabs = false;
    };
    $scope.pageChange = () => {
      corporateService
        .contactsUnderOrganizationGet(
          $scope.adviserOrgId,
          $scope.adviserOrgPage.page,
          $scope.adviserOrgPage.size
        )
        .then(
          onRetrieveContactsUnderOrganizationGetSuccess,
          commonFnService.onRetrieveError
        );
    };
    $scope.getAdviserOrgStatus = statusCode => {
      let textColorClass = '';
      switch (parseInt(statusCode, 10)) {
        case ADVISER_STATUS.ONBOARDING:
          textColorClass = 'text-success';
          break;
        case ADVISER_STATUS.ACTIVE:
          textColorClass = 'light-blue';
          break;
        case ADVISER_STATUS.TERMINATED:
          textColorClass = 'text-muted';
          break;
        default:
          break;
      }
      return textColorClass;
    };
    $scope.convertToLowerCase = convertToLowerCase;

    $scope.launchAddressModal = () => {
      const size = 'md';
      const isOrgAddress = true;
      const modalInstance = addressModalService.launchAddressModal(
        size,
        $scope.adviserOrgId,
        isOrgAddress
      );
      modalInstance.result.then(() => {
        $scope.timeOutOrgInstance = $timeout(() => {
          loadAdviserOrgAddress();
          toaster.pop('success', 'Successfully Updated', 'Address');
        }, 1000);
      });
    };
    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeOutOrgInstance);
    });

    $scope.logoFinishedUploading = response => {
      if (response.length && response[0].DocumentId) {
        $scope.adviserOrganizationGet.BrandingLogoDocId =
          response[0].DocumentId;
        $scope.updateAdviserOrgDetails(
          $scope.setterType[0],
          $scope.adviserOrganizationGet,
          '',
          '',
          'marketing'
        );
      }
    };
    $scope.displayMap = displayMap;
    $scope.displayCustomAddress = displayCustomAddress;
    const getAdviserOrgAddressPermission = () => {
      $scope.isAdviserOrgAddressEditable = false;
      const pageName = 'AdviserOrganizationContact';
      corporatePermissionService
        .isPermissionReadAndWrite(pageName)
        .then(response => {
          $scope.isAdviserOrgAddressEditable = response;
        });
    };
    getAdviserOrgAddressPermission();
  });
