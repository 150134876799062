/* Added by Ellen */
import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .directive('mycrmMapView', function mycrmMapView(commonFnService) {
    const directive = {
      restrict: 'E' /* element  */,
      scope: {
        /*  (isolatedScope) - defining scope means that 'no inheritance from parent'. */
        /* NOTE: Normally I would set my attributes and bindings
            to be the same name but I wanted to delineate between
            parent and isolated scope. */
        mapId:
          '=' /* (two-way binding) which means any modification in
        childScope will also update the value in parentScope, and vice versa.
        This rule works for both primitives and objects.
        If you change the binding type of parentObj to be =, you will
         find that you can modify the value of parentObj.x. */,
        addressObj: '=addressObj',
      },
      link(scope) {
        scope.loadMap = () => {
          if (_.isUndefined(scope.addressObj)) {
            scope.addressObj = null;
          }
          if (scope.addressObj) {
            scope.$watch('addressObj', newValue => {
              if (newValue) {
                commonFnService.setMapInit(scope.addressObj, scope.mapId);
              }
            });
          }
        };
        scope.loadMap();
      },
      templateUrl: 'assets/views/mapView/mapView.tpl.html',
      replace: true,
    };

    return directive;
  });
