import template from './pipelineTable.html';
import controller from './pipelineTableCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    parentToChildNotificationRegistration: '&',
    settings: '<',
    showLoader: '<',
    onToggleShowLoader: '&',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    onDeletePipelineItem: '&',
    isCorporateUser: '<',
    onRefreshStats: '&',
    probabilityList: '<',
    onViewLoanApp: '&',
    onViewBorrower: '&',
    onViewLoanInformation: '&',
    onViewSubmission: '&',
    onDuplicateLoan: '&',
    onClickPipelineItem: '&',
    onChangeSubStatus: '&',
    onChangeProbability: '&',
    onMenuClick: '&',
    onLeadAddNote: '&',
  },
};
