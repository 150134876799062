import template from './documentSharing.html';
import DocumentSharingCtrl from './documentSharingCtrl';

export default {
  template,
  bindings: {
    documentObject: '<',
    userInfo: '<',
    familyId: '<',
    adviserOrgId: '<',
    output: '=',
    noSavingAction: '<',
  },
  controller: DocumentSharingCtrl,
  controllerAs: 'vm',
};
