import angular from 'angular';

angular
  .module('app')
  .controller('CustomerCommentModalCtrl', function CustomerCommentModalCtrl(
    $scope,
    $uibModalInstance
  ) {
    $scope.closeModal = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
