import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('BrochuresModalCtrl', function BrochuresModalCtrl(
    $scope,
    $rootScope,
    $http,
    $uibModalInstance,
    insuranceQuoteService,
    toaster,
    $window,
    $timeout
  ) {
    /** ************************************************ */

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /** ************************************************ */

    $scope.benefitList = [
      { Name: 'Health Cover', id: 1 },
      { Name: 'Life Cover', id: 2 },
      { Name: 'Family Protection', id: 3 },
      { Name: 'Trauma Cover', id: 4 },
      { Name: 'Total and Permanent Disability', id: 5 },
      { Name: 'Income Protection Taxable', id: 6 },
      { Name: 'Mortgage Repayment Cover', id: 7 },
      { Name: 'Redundancy Cover', id: 8 },
    ];

    /** ************************************************ */

    $scope.providerId = null;
    $scope.benefitId = 1;
    $scope.brochuresList = [];

    $scope.getbrochuresList = function() {
      insuranceQuoteService
        .insuranceQuoteBrochureGet($scope.benefitId, $scope.providerId)
        .then(response => {
          const data = response.data;

          $scope.brochuresList = data;
        });
    };
    $scope.getbrochuresList();

    /** ************************************************ */

    $scope.selectedBrochure = function(value, value2) {
      $scope.brochuresList[value].selected = value2;
    };

    /** ************************************************ */

    $scope.downLoadPDF = function() {
      $scope.downloadablePDFList = [];

      _.forEach($scope.brochuresList, obj => {
        if (obj.selected) {
          $scope.downloadablePDFList.push(obj);
        }
      });

      for (let n = 0; n < $scope.downloadablePDFList.length; n++) {
        const eventTimeout = $timeout(() => {
          const temporaryDownloadLink = $window.document.createElement('a');
          temporaryDownloadLink.style.display = 'none';
          $window.document.body.appendChild(temporaryDownloadLink);

          const download = $scope.downloadablePDFList[n];
          const urlSplit = download.URL.split('-');

          temporaryDownloadLink.setAttribute('href', download.URL);
          temporaryDownloadLink.setAttribute(
            'download',
            urlSplit[urlSplit.length - 1]
          );
          temporaryDownloadLink.setAttribute('rel', 'noopener noreferrer');
          temporaryDownloadLink.setAttribute('target', '_blank');

          temporaryDownloadLink.click();
          $window.document.body.removeChild(temporaryDownloadLink);
          $timeout.cancel(eventTimeout);
        });
      }
    };

    /** ************************************************ */
  });
