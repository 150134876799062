import { NUMBER_OF_DEPENDENTS } from 'Common/constants/hemCalculatorDependents';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

class HemCalculatorCtrl {
  constructor(
    loanToolsService,
    $state,
    contactService,
    toaster,
    configService
  ) {
    'ngInject';

    this.loanToolsService = loanToolsService;
    this.$state = $state;
    this.contactService = contactService;
    this.toaster = toaster;
    this.configService = configService;
  }

  $onInit() {
    this.showLoading = false;
    this.haveResult = false;
    this.clientType = 0;
    this.numberOfChildren = NUMBER_OF_DEPENDENTS.MIN;
    this.houseHoldIncome = null;
    this.hemMonthlyExpense = 0;
    this.showFilter = false;
    this.getBrokerInfoHem();
    this.numberOfDependents = NUMBER_OF_DEPENDENTS;
  }

  getBrokerInfoHem() {
    this.contactService.getUserInfo().then(userInformationResponse => {
      if (userInformationResponse && userInformationResponse.data) {
        this.adviserInfo = userInformationResponse.data;
        this.checkHemAccess();
      }
    });
  }
  checkHemAccess() {
    const { CountryId } = this.adviserInfo;
    const showHemCalculator =
      (this.configService.feature &&
        this.configService.feature.hemCalculator &&
        CountryId !== COUNTRY_TYPE.NEW_ZEALAND) ||
      0;
    if (!showHemCalculator) {
      this.toaster.pop(
        'warning',
        'This page is currently unavailable',
        'Come back soon!!!'
      );
      this.$state.go('app.dashboard');
      return;
    }
    this.hemCalculatorStatesGet();
    this.hemMaritalStatusGet();
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  hemMaritalStatusGet() {
    this.loanToolsService.hemMaritalStatusGet().then(response => {
      if (response && response.data && response.data.length) {
        this.getMaritalStatus = response.data;
        this.maritalStatusGet = this.getMaritalStatus[0].Name;
      }
    });
  }

  hemCalculatorStatesGet() {
    this.loanToolsService.hemCalculatorStatesGet().then(response => {
      if (response && response.data && response.data.length) {
        this.countries = response.data;
        if (this.countries[0] && this.countries[0].Value) {
          this.country = this.countries[0].Value;
        }
      }
    });
  }

  disableCalculate() {
    return this.showLoading || !this.houseHoldIncome;
  }

  addChildren() {
    if (this.numberOfChildren < this.numberOfDependents.MAX)
      this.numberOfChildren++;
  }

  minusChildren() {
    if (this.numberOfChildren > this.numberOfDependents.MIN)
      this.numberOfChildren--;
  }

  calculateHemMobile() {
    this.toggleFilter();
    this.calculateHem();
  }
  calculateHem() {
    this.showLoading = true;
    const params = {
      location: this.country,
      numberOfDependents: this.numberOfChildren,
      householdIncome: this.houseHoldIncome,
      civilStatus: this.maritalStatusGet,
    };
    this.loanToolsService.hemCalculatorInfoGet(params).then(response => {
      this.showLoading = false;
      this.haveResult = true;
      if (response && response.data) {
        const { MonthlyExpense } = response.data;
        this.hemMonthlyExpense = MonthlyExpense || 0;
      } else {
        this.hemMonthlyExpense = 0;
      }
    });
  }
}
export default HemCalculatorCtrl;
