import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('clientReviewsSmall', function clientReviewsSmall(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/clientReviewsSmall.html',

      link($scope) {
        $scope.clientReviewsMultiAdviser = [];
        $scope.clientReviewsMultiAdviserObj = {};
        $scope.WidgetOptionGetData = [];
        $scope.outp = [];
        $scope.clientReviewTableDropDowns = {};
        $scope.clientReviewTableDropDownList = [];
        $scope.filteredClientReviewDropDownList = [];
        function ClientReviewSmallGet() {
          overviewDashboardService
            .ClientReviewSmallGet($scope.myParam.Widget_UserID)
            .then(
              response => {
                $scope.clientReviewTableData = response.data;
              },
              () => {}
            );
        }

        function WidgetOptionTimePeriodListv4Get() {
          overviewDashboardService.WidgetOptionTimePeriodListv4Get().then(
            response => {
              $scope.clientReviewTableDropDownList = response.data;
              $scope.clientReviewTableDropDowns =
                $scope.clientReviewTableDropDownList[0];
              overviewDashboardService
                .WidgetOptionGet($scope.myParam.Widget_UserID)
                .then(
                  res => {
                    $scope.WidgetOptionGetData = res.data;
                    for (
                      let i = 0;
                      i < $scope.WidgetOptionGetData.length;
                      i++
                    ) {
                      if (
                        parseInt(
                          $scope.WidgetOptionGetData[i].WidgetOptionId,
                          10
                        ) === 7
                      ) {
                        const widgetOptionValue =
                          $scope.WidgetOptionGetData[i].Widget_OptionValue;
                        $scope.filteredClientReviewDropDownList = $scope.clientReviewTableDropDownList.filter(
                          item =>
                            parseInt(item.OptionValueId, 10) ===
                            parseInt(widgetOptionValue, 10)
                        );

                        $scope.clientReviewTableDropDowns =
                          $scope.filteredClientReviewDropDownList[0];
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }
        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet().then(
            response => {
              $scope.WidgetOptionAdviserList = response.data;
              for (let i = 0; i < $scope.WidgetOptionAdviserList.length; i++) {
                $scope.clientReviewsMultiAdviserObj = {
                  FamilyName: $scope.WidgetOptionAdviserList[i].FamilyName,
                  FamilyId: $scope.WidgetOptionAdviserList[i].FamilyId,
                  OptionValueId:
                    $scope.WidgetOptionAdviserList[i].OptionValueId,
                  OptionDB_Value:
                    $scope.WidgetOptionAdviserList[i].OptionDB_Value,
                  ticked: false,
                  WidgetOptionId: $scope.WidgetOptionAdviserList[i].OptionId,
                };
                $scope.clientReviewsMultiAdviser.push(
                  $scope.clientReviewsMultiAdviserObj
                );
              }

              overviewDashboardService
                .WidgetOptionGet($scope.myParam.Widget_UserID)
                .then(
                  res => {
                    $scope.WidgetOptionGetData = res.data;

                    $scope.WidgetOptionGetAdviser = {};
                    $scope.Advisers = [];
                    for (
                      let j = 0;
                      j < $scope.WidgetOptionGetData.length;
                      j++
                    ) {
                      if (
                        parseInt(
                          $scope.WidgetOptionGetData[j].WidgetOptionId,
                          10
                        ) === 3
                      ) {
                        $scope.Advisers =
                          $scope.WidgetOptionGetData[j].SelectedUerValues;
                        const AdvisersArray = $scope.Advisers.split(',');

                        $scope.clientReviewsMultiAdviser = $scope.clientReviewsMultiAdviser.map(
                          obj => {
                            if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                              obj.ticked = true;
                              return obj;
                            }

                            return obj;
                          }
                        );
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }

        function saveForTimePeriod(widgetSetObj) {
          $scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet($scope.OptionToSet)
            .then(ClientReviewSmallGet)
            .catch(displayError);
        }

        $scope.updateMultiselectFilterData = function(clickedItem) {
          let dataToSend = [];
          $scope.MultiOptionSet = {};
          $scope.MultiOptionSetForAll = {};
          const itemData = _.find($scope.clientReviewsMultiAdviser, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map($scope.clientReviewsMultiAdviser, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size($scope.clientReviewsMultiAdviser) !== 0) {
              $scope.clientReviewsMultiAdviser[0].ticked = false;
            }

            dataToSend = $scope.clientReviewsMultiAdviser
              .filter(item => item.FamilyName !== 'All' && item.ticked === true)
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            $scope.MultiOptionSet.SelectedUser = dataToSend;
            $scope.MultiOptionSet.WidgetOptionId =
              $scope.WidgetOptionAdviserList[0].OptionId;
            $scope.MultiOptionSet.Widget_UserID = $scope.myParam.Widget_UserID;
            $scope.MultiOptionSet.Widget_OptionValue =
              $scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod($scope.MultiOptionSet);
          } else {
            const all = $scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && $scope.outp.length > 1) {
              $scope.clientReviewsMultiAdviser = $scope.clientReviewsMultiAdviser.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: $scope.clientReviewsMultiAdviser[0].FamilyId,
            });

            $scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            $scope.MultiOptionSetForAll.WidgetOptionId =
              $scope.WidgetOptionAdviserList[0].OptionId;
            $scope.MultiOptionSetForAll.Widget_UserID =
              $scope.myParam.Widget_UserID;
            $scope.MultiOptionSetForAll.Widget_OptionValue =
              $scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod($scope.MultiOptionSetForAll);
          }
        };
        $scope.showNextVal = function(displayObj) {
          $scope.currentObj = displayObj;
          const displayPos = $scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;
          if (arrayPos + 1 > $scope.clientReviewTableDropDownList.length - 1) {
            $scope.clientReviewTableDropDowns =
              $scope.clientReviewTableDropDownList[0];
            overviewDashboardService
              .WidgetOptionGet($scope.myParam.Widget_UserID)
              .then(
                response => {
                  $scope.WidgetOptionGetData = response.data;
                },
                () => {}
              );
            $scope.objForWidgetOptionSet = {
              WidgetOptionId: $scope.clientReviewTableDropDowns.OptionId,
              Widget_UserID: $scope.myParam.Widget_UserID,
              Widget_OptionValue:
                $scope.clientReviewTableDropDowns.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod($scope.objForWidgetOptionSet);
            return;
          }
          $scope.clientReviewTableDropDowns =
            $scope.clientReviewTableDropDownList[arrayPos + 1];
          overviewDashboardService
            .WidgetOptionGet($scope.myParam.Widget_UserID)
            .then(
              response => {
                $scope.WidgetOptionGetData = response.data;
              },
              () => {}
            );
          $scope.objForWidgetOptionSet = {
            WidgetOptionId: $scope.clientReviewTableDropDowns.OptionId,
            Widget_UserID: $scope.myParam.Widget_UserID,
            Widget_OptionValue: $scope.clientReviewTableDropDowns.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod($scope.objForWidgetOptionSet);
        };
        $scope.showPrevVal = function(displayObj) {
          $scope.currentObj = displayObj;
          const displayPos = $scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;

          if (arrayPos - 1 === -1) {
            const lastPos = $scope.clientReviewTableDropDownList.length - 1;
            $scope.clientReviewTableDropDowns =
              $scope.clientReviewTableDropDownList[lastPos];
            overviewDashboardService
              .WidgetOptionGet($scope.myParam.Widget_UserID)
              .then(
                response => {
                  $scope.WidgetOptionGetData = response.data;
                },
                () => {}
              );
            $scope.objForWidgetOptionSet = {
              WidgetOptionId: $scope.clientReviewTableDropDowns.OptionId,
              Widget_UserID: $scope.myParam.Widget_UserID,
              Widget_OptionValue:
                $scope.clientReviewTableDropDowns.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod($scope.objForWidgetOptionSet);
            return;
          }
          $scope.clientReviewTableDropDowns =
            $scope.clientReviewTableDropDownList[arrayPos - 1];
          overviewDashboardService
            .WidgetOptionGet($scope.myParam.Widget_UserID)
            .then(
              response => {
                $scope.WidgetOptionGetData = response.data;
              },
              () => {}
            );
          $scope.objForWidgetOptionSet = {
            WidgetOptionId: $scope.clientReviewTableDropDowns.OptionId,
            Widget_UserID: $scope.myParam.Widget_UserID,
            Widget_OptionValue: $scope.clientReviewTableDropDowns.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod($scope.objForWidgetOptionSet);
        };
        $scope.doAction = function(msg) {
          if (msg === 'edit') $scope.customizeMode = true;
          else $scope.customizeMode = false;
          if (msg === 'notEdit') {
            $scope.customizeMode = false;
          } else $scope.customizeMode = true;
        };
        function init() {
          ClientReviewSmallGet();
          WidgetOptionTimePeriodListv4Get();
          WidgetOptionAdviserListGet();
        }

        init();
      },
    };
  });
