import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('OnboardingCtrl', function OnboardingCtrl(
    $scope,
    $timeout,
    $uibModal,
    $state,
    $element,
    $anchorScroll,
    $window,
    onboardingService,
    corporateService,
    contactService,
    dataService,
    onboardingDataService
  ) {
    const familyId = $state.params.familyId;
    const clientId = $state.params.clientId;

    const mainElem = '.tab-pane';
    $scope.activeTab = 0;
    $scope.reportingCountryId = 0;
    $scope.completedOnboardingScreens = false;
    $scope.downloads = [];
    $scope.percentages = [];
    $scope.helpfulLinks = [];
    $scope.teamLinkLists = [];
    $scope.onboardingTabs = [];
    $scope.tabs = {};
    $scope.allOnboardingDetailsPreview = {};
    $scope.options = { responsive: true };
    $scope.tabs.selected = $scope.onboardingTabs[0];

    const timeOutCall = () => {
      $timeout(() => {
        const el = $window.document.querySelectorAll(
          `#select${$scope.onboardingTabs[1].value}`
        );
        angular.element(el).triggerHandler('click');
      }, 0);
    };

    const setOnboardingCompletion = () => {
      if ($scope.activeTab === $scope.onboardingTabs.length) {
        $scope.completedOnboardingScreens = true;
        if ($scope.completedOnboardingScreens) {
          $scope.allOnboardingDetailsPreview = onboardingDataService.getAllOnboardingDataForPreview();
        }
      }
    };

    const getActiveTab = () => {
      onboardingDataService.getCurrentTab(familyId).then(currentTabValue => {
        $scope.activeTab = currentTabValue || 0;
      });
    };

    const checkTabsIsCompleted = tabs => {
      return (
        tabs &&
        tabs.map((tab, key) =>
          key < $scope.activeTab ? { ...tab, completed: true } : tab
        )
      );
    };

    const initialize = () => {
      /* set family id for all tabs without changing state params */
      onboardingDataService.setFamilyId(familyId);
      onboardingDataService.setClientId(clientId);
      /* get active Tab */
      getActiveTab();

      /* set Icons */
      $scope.onboardingIcons = onboardingDataService.getOnbardingTabsIcons();
      /* get helpfulLinks */
      $scope.helpfulLinks = onboardingDataService.getOnboardingHelpfulLinks();
      /* set tmpTabs */
      onboardingDataService
        .getOnbardingTabs($scope.onboardingIcons)
        .then(tabsTmp => {
          $scope.onboardingTabsTmp = tabsTmp;
          dataService.getUserInfo(true).then(userCountryResp => {
            if (userCountryResp && userCountryResp.data) {
              onboardingDataService.setUserCurrentCountry(
                userCountryResp.data.CountryId
              );
              $scope.reportingCountryId = userCountryResp.data.CountryId;
            }

            if ($scope.reportingCountryId) {
              /* set countryId referrence for all tabs */
              $scope.teamLinkLists = onboardingDataService.getOnboardingTeamList(
                $scope.reportingCountryId
              );
              angular.forEach($scope.onboardingTabsTmp, response => {
                if (response.value === 'asicAppointment') {
                  if ($scope.reportingCountryId === COUNTRY_TYPE.NEW_ZEALAND) {
                    response.name = 'FSPR Registration';
                  }
                }
              });
              /* call list api need to use for all tabs */
              onboardingDataService.getAdviserOrgDetails();
              onboardingDataService.getFamilyBrandingCategory();

              onboardingDataService
                .getOnboardingResponse(familyId, clientId)
                .then(response => {
                  if (response) {
                    if (response.RoleId) {
                      $scope.isFranchisee = onboardingDataService.isOnboardingUserRole(
                        response.RoleId,
                        ONBOARDING_ROLE_ID.FRANCHISEE
                      );
                      $scope.isContractor = onboardingDataService.isOnboardingUserRole(
                        response.RoleId,
                        ONBOARDING_ROLE_ID.CONTRACTOR
                      );
                      $scope.isEmployee = onboardingDataService.isOnboardingUserRole(
                        response.RoleId,
                        ONBOARDING_ROLE_ID.EMPLOYEE
                      );
                      $scope.isDirector = onboardingDataService.isOnboardingUserRole(
                        response.RoleId,
                        ONBOARDING_ROLE_ID.DIRECTOR
                      );
                      $scope.isPartner = onboardingDataService.isOnboardingUserRole(
                        response.RoleId,
                        ONBOARDING_ROLE_ID.PARTNER
                      );
                    } else {
                      $scope.onboardingTabs = $scope.onboardingTabsTmp;
                    }
                    /* set Tabs */
                    let removeArrays = [];
                    if (
                      onboardingDataService.isRoleType(
                        ONBOARDING_ROLE_ID.CONTRACTOR
                      )
                    ) {
                      removeArrays = [2, 5];
                    } else if (
                      onboardingDataService.isRoleType(
                        ONBOARDING_ROLE_ID.EMPLOYEE
                      )
                    ) {
                      removeArrays = [0, 2, 5];
                    }
                    if (
                      !onboardingDataService.isRoleType(
                        ONBOARDING_ROLE_ID.CONTRACTOR
                      ) &&
                      !onboardingDataService.isRoleType(
                        ONBOARDING_ROLE_ID.EMPLOYEE
                      )
                    ) {
                      if ($scope.isContractor) {
                        removeArrays = [2, 5];
                      } else if (
                        $scope.isEmployee ||
                        $scope.isDirector ||
                        $scope.isPartner
                      ) {
                        removeArrays = [0, 2, 5];
                      }
                    }
                    $scope.onboardingTabsTmp = $scope.onboardingTabsTmp.filter(
                      (val, key) => {
                        return (
                          removeArrays.find(idx => {
                            return idx === key;
                          }) === undefined
                        );
                      }
                    );
                    $scope.onboardingTabs = $scope.onboardingTabsTmp;
                    $scope.onboardingTabs = checkTabsIsCompleted(
                      $scope.onboardingTabs
                    );
                    setOnboardingCompletion();
                    $scope.downloads = onboardingDataService.getOnboardingDownloads(
                      $scope.isFranchisee
                    );
                  }
                });
            }
          });
        });
    };
    $scope.setTab = function(idx) {
      $scope.activeTab = idx;
      $scope.setActiveTab(idx);
    };

    const promptMessage = formLength => {
      if (
        formLength &&
        ($scope.onboardingTabs[$scope.activeTab] &&
          $scope.onboardingTabs[$scope.activeTab].name === 'Additional Staff')
      ) {
        displayError(
          'You have entered one or more Additional Staff without saving. Please click Save or Cancel on each Additional Staff record before proceeding.',
          true,
          'Confirm Additional Staff'
        );
        return true;
      }
      return false;
    };

    $scope.setNextTab = function() {
      $scope.percentages = onboardingDataService.getTabsRequiredField(
        angular.element(mainElem).eq($scope.activeTab),
        $scope.activeTab
      );
      if ($scope.percentages) {
        const tabFillValue = $scope.percentages[`${$scope.activeTab}`].filled;
        const tabValidValue = $scope.percentages[`${$scope.activeTab}`].valid;
        const totalTabValue = $scope.percentages[`${$scope.activeTab}`].total;

        angular.forEach($scope.onboardingTabs, (value, key) => {
          if (!value) return false;
          if (key === $scope.activeTab) {
            if (
              tabFillValue === totalTabValue ||
              tabValidValue === totalTabValue
            ) {
              value.completed = true;
            } else {
              value.completed = false;
            }
          }
        });

        const tabElm = angular.element(mainElem).eq($scope.activeTab);
        const formAddLength = angular
          .element(tabElm[0])
          .find('form[name="assistantForm"]').length;
        if (tabFillValue < totalTabValue || tabValidValue < totalTabValue) {
          if (!formAddLength) {
            displayError(
              'Please review the fields marked as required before proceeding.',
              true,
              'Required Fields'
            );
          } else {
            displayError(
              'Please fill in all the field(s) and check invalid input!',
              true,
              'Error'
            );
          }
          return false;
        }
        if (promptMessage(formAddLength)) return false;

        if ($scope.onboardingTabs) {
          if ($scope.activeTab === $scope.onboardingTabs.length - 1) {
            $scope.activeTab = $scope.onboardingTabs.length;
            setOnboardingCompletion();
          } else {
            $scope.activeTab++;
          }
        }
        $anchorScroll();
        corporateService.currentOnboardingTabSet(familyId, $scope.activeTab);
      }
      timeOutCall();
      return true;
    };

    $scope.setPrevTab = function(str) {
      if (str === 'end') {
        $scope.activeTab = $scope.onboardingTabs.length;
        $scope.completedOnboardingScreens = false;
      } else {
        timeOutCall();
      }
      $scope.activeTab--;
      $anchorScroll();
      corporateService.currentOnboardingTabSet(familyId, $scope.activeTab);
    };

    $scope.setActiveTab = function(idx) {
      let i = 0;
      $scope.activeTab = 0;
      while (i <= idx) {
        if (i) {
          if (!$scope.setNextTab()) {
            break;
          }
        }
        i++;
      }
      $scope.completedOnboardingScreens = false;
      corporateService.currentOnboardingTabSet(familyId, $scope.activeTab);
    };

    $scope.currentView = 'tabs';
    $scope.switchView = function() {
      $scope.currentView = $scope.currentView === 'tabs' ? 'progress' : 'tabs';
    };

    $scope.hiddenTabs = false;
    $scope.openHiddenTabs = function() {
      $scope.hiddenTabs = $scope.hiddenTabs === false;
    };

    $scope.closeHiddenTabs = function() {
      $scope.hiddenTabs = false;
    };

    $scope.sendApplication = function() {
      $uibModal.open({
        templateUrl: 'assets/views/onboarding/modal/thankyou.html',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        scope: $scope,
        controller($uibModalInstance) {
          contactService.getUserInfo().then(response => {
            $scope.familyId = response.data.FamilyId;
          });

          $scope.closeThakyouMsg = function() {
            $uibModalInstance.dismiss('cancel');
          };

          $scope.submitApplicationForm = function() {
            onboardingService.sendProcessCompletedEmailSet($scope.familyId);

            corporateService
              .currentOnboardingTabSet($scope.familyId, $scope.activeTab, 1)
              .then(currentTabResponse => {
                if (currentTabResponse) {
                  $uibModalInstance.dismiss('cancel');
                  $state.go('app.accreditation', {
                    familyId: $scope.familyId,
                    clientId: 0,
                  });
                }
              });
          };
        },
      });
    };

    $scope.downloadDocument = function(data) {
      const timeOut = $timeout(() => {
        const element = $window.document.createElement('a');
        element.setAttribute(
          'href',
          `data:${data.ContentType};base64,${data.DocumentContent}`
        );
        element.setAttribute('download', data.DocumentName);
        element.style.display = 'none';
        $window.document.body.appendChild(element);
        element.click();
        $window.document.body.removeChild(element);
      });
      $scope.$on('$destroy', () => {
        $timeout.cancel(timeOut);
      });
    };

    initialize();
  });
