class PipelineStatusService {
  getPipelineStatus() {
    this.pipelineStatus = [
      {
        statusId: -1,
        statusName: 'All Pipeline Status',
        ticked: true,
      },
      {
        statusId: 54,
        statusName: 'New Opportunity',
        ticked: false,
      },
      {
        statusId: 55,
        statusName: 'Requirements',
        ticked: false,
      },
      {
        statusId: 56,
        statusName: 'Researching',
        ticked: false,
      },
      {
        statusId: 57,
        statusName: 'Proposal Sent',
        ticked: false,
      },
    ];
    return this.pipelineStatus;
  }
}

export default PipelineStatusService;
