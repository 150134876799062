import template from './achievementField.html';
import AchievementFieldCtrl from './achievementFieldCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
  },
  controller: AchievementFieldCtrl,
  controllerAs: 'vm',
};
