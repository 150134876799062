import angular from 'angular';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('AddKeyModalCtrl', function AddKeyModalCtrl(
    $scope,
    $uibModalInstance,
    lenderList,
    selectedLender,
    selectedKeyContact,
    $rootScope,
    loanToolsService
  ) {
    $scope.lenderList = lenderList;
    $scope.selectedLender = selectedLender;
    $scope.selectedKeyContact = selectedKeyContact;
    $scope.selectedLenderProviderID = `${$scope.selectedLender.ProviderID}`;
    $scope.keyContact = {
      keyRelationshipID: 0,
    };
    if ($scope.selectedKeyContact) {
      $scope.keyContact = $scope.selectedKeyContact;
      $scope.keyContact.Name = $scope.selectedKeyContact.Name;
      $scope.keyContact.Position = $scope.selectedKeyContact.Position;
      $scope.keyContact.Mobile = $scope.selectedKeyContact.Mobile;
      $scope.keyContact.Phone = $scope.selectedKeyContact.Phone;
      $scope.keyContact.BUPhone = $scope.selectedKeyContact.BUPhone;
      $scope.keyContact.BUFax = $scope.selectedKeyContact.BUFax;
      $scope.keyContact.Email = $scope.selectedKeyContact.Email;
    }

    $scope.selectElementChanged = ProviderID => {
      $scope.selectedLender = $scope.lenderList.filter(item => {
        if (item.ProviderID === parseInt(ProviderID, 10)) {
          $scope.changeSelectedLender($scope.lenderList.indexOf(item), item);
        }
        return item.ProviderID === parseInt(ProviderID, 10);
      })[0];
    };

    $scope.changeSelectedLender = (index, lender) => {
      $scope.selectedLender = lender;
      $scope.selectedIndex = index;
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.saveKeyContact = keyContactval => {
      const res = angular.isUndefined(keyContactval);
      if (res !== true) {
        $scope.keyContact.providerID = $scope.selectedLenderProviderID;
        loanToolsService
          .updateKeyContact($scope.keyContact)
          .then(() => {
            $uibModalInstance.dismiss('cancel');

            swal('Updated', 'Contact has been Added Successfully.', 'success');

            $rootScope.$broadcast('callInit');
          })
          .catch(displayError);
      } else {
        $scope.keyContact.providerID = $scope.selectedLenderProviderID;
        $scope.keyContact.keyRelationshipID = 0;
        loanToolsService
          .saveKeyContact($scope.keyContact)
          .then(() => {
            $uibModalInstance.dismiss('cancel');
            swal('Added', 'Contact has been Added Successfully.', 'success');
            $rootScope.$broadcast('callInit');
          })
          .catch(displayError);
      }
    };
  });
