import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller('ViewTaskModalCtrl', function ViewTaskModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $timeout,
    toaster,
    tasksSharedData,
    taskService,
    $state,
    dashboardService,
    $stateParams,
    type,
    clientInfoService,
    taskDetailService,
    generalSearchService,
    currentUserService
  ) {
    contactService.clientInformGet($stateParams.familyId).then(response => {
      $scope.taskInput.familyValue = clientInfoService.getFullName(
        response.data
      );
      $scope.taskSet.FamilyId = $stateParams.familyId;
    });
    $scope.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
    };
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.mainObject.type = type;
    $scope.mainObject.datePopUp = false;
    $scope.format = 'dd MMM yyyy';
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    $scope.updateFamilyOnAdviserChange =
      tasksSharedData.updateFamilyOnAdviserChange;

    $scope.openDate = function() {
      $scope.mainObject.datePopUp = !$scope.mainObject.datePopUp;
    };

    $scope.closeFamilyList = () => {
      $scope.familyDetailsList = taskDetailService.closeFamilyList(
        $scope.taskInput,
        $scope.mainObject
      );
    };

    $scope.selectFamily = (id, fullName) => {
      $scope.familyDetailsList = taskDetailService.selectFamily(
        id,
        fullName,
        $scope.taskInput,
        $scope.taskSet,
        $scope.mainObject
      );
    };

    if ($scope.task_id) {
      taskDetailService.taskDetailsGet($scope.task_id).then(respond => {
        $scope.taskSet = respond.data;
        if ($scope.taskSet) {
          $scope.selectFamily(
            $scope.taskSet.FamilyID,
            $scope.taskSet.ClientFamilyName
          );
          $scope.showNotes =
            $scope.taskSet.Notes && $scope.taskSet.Notes.trim() !== '';
        }
      });
    } else {
      $scope.taskSet.DueDate = new Date();
    }
    $scope.showNotes = true;
    if (
      $scope.taskSet.Notes !== 'undefined' &&
      $scope.taskSet.Notes.trim() === ''
    ) {
      $scope.showNotes = false;
    }
    $scope.addNotes = function() {
      const note = $scope.taskSet.Notes;
      if (typeof note === 'undefined' || note.trim() === '') {
        $scope.showNotes = true;
      }
    };
    $scope.isCorporateUser =
      currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased =
      COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    taskDetailService
      .taskAdviserListGet($scope.taskSet, $scope.task_id, isLoanWriter)
      .then(res => {
        $scope.taskAdviserList = res.data;
      });
    $scope.taskInput = {};

    $scope.searchFamilyInput = () => {
      generalSearchService.searchFamilyInput($scope);
    };

    taskDetailService.taskAdviserListGet(
      $scope.taskInput,
      $scope.taskSet,
      $scope.task_id,
      $scope.familyId
    );

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
