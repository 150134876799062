const APP_MEDIAQUERY = [
  'APP_MEDIAQUERY',
  {
    desktopXL: 1200,
    desktop: 992,
    tablet: 768,
    mobile: 480,
  },
];

export default APP_MEDIAQUERY;
