import { commissionReportBuilderForUI } from 'Common/mappers/commission';

class CommissionService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;
    this.apiBaseUrl = 'commission';
  }

  totalCommissionGet(date, brokerIDs = 0, type) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/TotalCommissionsAsOfDateGet`,
      { date, brokerIDs: `${brokerIDs}`, type }
    );
  }

  totalCommissionOfLenderGet(date, type) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/TotalCommissionsOfLendersGet`,
      { date, type }
    );
  }
  assignBrokersSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/assignedbrokers`, data);
  }

  assignBrokersGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/assignedbrokers`);
  }

  totalCommissionOfBrokerGet(date, type) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/TotalCommissionsOfBrokersGet`,
      { date, type }
    );
  }

  availableBrokerOnlyGet(data) {
    return this.httpClient.get(`${this.apiBaseUrl}/available-brokers`, data);
  }

  SearchTransactionGet(params) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/SearchTransactionGet`,
      params
    );
  }

  AvailableDatesOfStatementsGet(date, type) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/AvailableDatesOfStatementsGet`,
      { date, type }
    );
  }

  PDFStatementGet(date, brokerId) {
    return this.httpClient.get(`${this.apiBaseUrl}/PDFStatementGet`, {
      date,
      brokerId,
    });
  }

  totalCommissionByTypeGet(date, type) {
    return this.httpClient.get(`${this.apiBaseUrl}/TotalCommissionsByTypeGet`, {
      date,
      type,
    });
  }

  getAllBrokers() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokersForDropdownGet`);
  }

  availableStatementGet(date, type) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/AvailableDatesOfStatementsGet`,
      { date, type }
    );
  }

  getMonthlyProduction(fromDate, toDate) {
    return this.httpClient.get(`${this.apiBaseUrl}/MonthlyProductionSplitGet`, {
      fromDate,
      toDate,
    });
  }

  commissionsTypeGet() {
    // TODO: move this to api
    const defer = this.$q.defer();
    defer.resolve({
      data: [
        { Value: 1, Name: 'Upfront' },
        { Value: 2, Name: 'Refix' },
        { Value: 3, Name: 'Bonus' },
        { Value: 4, Name: 'Trail' },
      ],
    });
    return defer.promise;
  }

  commissionsSummaryDaysGet() {
    // TODO: move this to api
    const defer = this.$q.defer();
    defer.resolve({
      data: [
        { Value: 30, Name: 'Next 30 days' },
        { Value: 60, Name: 'Next 60 days' },
        { Value: 90, Name: 'Next 90 days' },
      ],
    });
    return defer.promise;
  }

  commissionsSummaryGet(
    adviserId = 0,
    noOfDays,
    pageNumber,
    pageSize,
    providerId
  ) {
    return this.httpClient.get(`/contacts/LoanExpectedComissionSummary`, {
      adviserId,
      noOfDays,
      pageNumber,
      pageSize,
      providerId,
    });
  }
  reports() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/reports`)
      .then(response => response.data.map(commissionReportBuilderForUI));
  }
  report(key) {
    return this.httpClient.get(`${this.apiBaseUrl}/report`, { key });
  }
}

export default CommissionService;
