import angular from 'angular';

angular.module('app').directive('goScenario', function goScenario($timeout) {
  return {
    restrict: 'A',
    link(scope, element, attributes) {
      element.bind('click', () => {
        const id = attributes.goScenario;
        const eventTimeout = $timeout(() => {
          angular
            .element(id)
            .find('a')
            .click();
          $timeout.cancel(eventTimeout);
        });
      });
    },
  };
});
