import template from './guarantors.html';
import GuarantorsCtrl from './guarantorsCtrl';

export default {
  template,
  bindings: {
    loanAdviser: '<',
    formData: '<',
    existingFamilies: '<',
    onSelectClient: '&',
    onSelectEntity: '&',
    onAddPersonModal: '&',
    onEditPersonModal: '&',
    onAddEntityModal: '&',
    onEditEntityModal: '&',
    onRemoveClient: '&',
  },
  controller: GuarantorsCtrl,
  controllerAs: 'vm',
};
