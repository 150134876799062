import angular from 'angular';

class InsuranceProfilerSettingsCtrl {
  constructor(
    $timeout,
    $window,
    $uibModalInstance,
    $uibModal,
    insuranceProfilerService,
    toaster
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.toaster = toaster;

    this.insuranceProfilerModalTab = {
      tabStatus: 'general',
      tpdStatus: 'earner',
    };
    this.checkBoxArr = [
      'General_Stepbystep',
      'General_ShowPremium',
      'General_ShowMRTable',
      'General_ShowIPTable',
      'Health_AddOn1',
      'Health_AddOn2',
      'Health_AddOn3',
      'Trauma_Recovery',
      'Trauma_Mortgage',
      'Trauma_Debt',
      'TPD_NE_Recovery',
      'TPD_NE_Mortgage',
      'TPD_NE_Debt',
      'TPD_Recovery',
      'TPD_Mortgage',
      'TPD_Debt',
      'TPD_Expense',
      'TPD_Education',
      'Life_Mortgage',
      'Life_Debt',
      'Life_Expense',
      'Life_Education',
      'Life_Retire',
      'Life_Funeral',
    ];
    this.insuranceProfilerSettings = {};
    this.insuranceProfilerSettingsTemp = {};
  }

  funcCheboxValue(value) {
    if (this.insuranceProfilerSettings[value] === 1) {
      this.insuranceProfilerSettings[value] = true;
    } else {
      this.insuranceProfilerSettings[value] = false;
    }
  }

  funcCheboxValuetoNumber(value) {
    if (this.insuranceProfilerSettingsTemp[value] === true) {
      this.insuranceProfilerSettingsTemp[value] = 1;
    } else {
      this.insuranceProfilerSettingsTemp[value] = 2;
    }
  }

  // 3. Angular Functions
  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  funcInsuranceProfileSettingsGet() {
    this.insuranceProfilerService
      .insuranceProfileSettingsGet()
      .then(response => {
        if (response.data) {
          this.insuranceProfilerSettings = response.data;

          this.checkBoxArr.forEach(value => {
            this.funcCheboxValue(value);
          });
        }
      });
  }

  saveSettings() {
    this.insuranceProfilerSettingsTemp = angular.copy(
      this.insuranceProfilerSettings
    );

    this.checkBoxArr.forEach(value => {
      this.funcCheboxValuetoNumber(value);
    });

    if (!this.insuranceProfilerSettingsTemp.UserSettingsID) {
      this.insuranceProfilerService
        .insuranceProfileSettingsSet(this.insuranceProfilerSettingsTemp)
        .then(response => {
          if (response.status === 200) {
            this.toaster.pop(
              'success',
              'Success',
              'Settings was successfully saved'
            );
            this.cancel();
          }
        });
    } else {
      this.insuranceProfilerService
        .insuranceProfileSettingsUpdate(
          this.insuranceProfilerSettingsTemp,
          this.insuranceProfilerSettingsTemp.UserSettingsID
        )
        .then(response => {
          if (response.status === 200) {
            this.toaster.pop(
              'success',
              'Success',
              'Settings was successfully updated'
            );
            this.cancel();
          }
        });
    }
  }

  $onInit() {
    this.funcCheboxValue();
    this.funcCheboxValuetoNumber();
    this.funcInsuranceProfileSettingsGet();
  }
}

angular
  .module('app')
  .controller('InsuranceProfilerSettingsCtrl', InsuranceProfilerSettingsCtrl);
