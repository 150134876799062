import template from './insurancePipelineTable.html';
import controller from './insurancePipelineTableCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    labelsSelection: '<',
    clientFilter: '<',
    sortCol: '<',
    sortDir: '<',
    notProceedingReasons: '<',
    statuses: '<',
    onTableSort: '&?',
    onInit: '&?',
    onAddLabel: '&',
    onUpdateLabel: '&',
    onDeleteLabel: '&',
    onApplicationConverted: '&',
    onViewWorkbench: '&',
  },
};
