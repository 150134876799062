import angular from 'angular';
import _ from 'lodash';
import moment from 'angular-moment';
import { PERSON_CONSTANT } from 'Common/constants/personConstants';

angular
  .module('app')
  .controller('InsurancePersonAddCtrl', function InsurancePersonAddCtrl(
    $scope,
    $uibModalInstance,
    utilitiesService,
    toaster,
    contactService,
    selectedPartyType,
    person,
    $state,
    personService,
    accountService,
    genderService
  ) {
    angular.extend($scope, {
      partyApplicants: [],
      Settings: {
        MaritalStatusAdded: false,
      },
    });

    $scope.ContactSet = {};

    // Default
    $scope.saveText = 'Applicant';
    $scope.Settings.SelectedParty = 'Applicants';

    if (person) {
      $scope.Settings.isEdit = true;
      let loanAppFamily = [];

      _.forEach($scope.listApplicants, family => {
        if (_.isEqual(_.first(family).FamilyId, person.FamilyId)) {
          // take a copy of the family object
          loanAppFamily = angular.copy(family);
        }
      });

      contactService.clientInformGet(person.FamilyId).then(family => {
        let ApplicantNumber = 1;
        _.forEach(family.data, member => {
          if (member.Role === 'Adult') {
            contactService
              .clientInformGet(person.FamilyId, member.PersonId)
              .then(response => {
                // $scope.ContactSet = response.data[0];
                const ContactSet = $scope.getNewContactModel('Adult');
                if (
                  ContactSet.customerConsentOptions.ConsentMarketing !== true
                ) {
                  ContactSet.customerConsentOptions.ConsentMarketing = false;
                }

                angular.extend(ContactSet, response.data[0]);

                if (_.size(ContactSet.Email) > 0) {
                  ContactSet.EmailAddress = ContactSet.Email[0].EmailAddress;
                }

                if (_.isUndefined(ContactSet.IsResident)) {
                  ContactSet.IsResident = false;
                }
                // find country codes
                let CitizenshipObj = _.find($scope.countryList, obj => {
                  return (
                    parseInt(obj.CountryID, 10) ===
                    parseInt(ContactSet.CitizenshipId, 10)
                  );
                });
                let CountryOfResidencyObj = _.find($scope.countryList, o => {
                  return (
                    parseInt(o.CountryID, 10) ===
                    parseInt(ContactSet.CountryOfResidencyId, 10)
                  );
                });
                let CountryOfBirthObj = _.find($scope.countryList, o => {
                  return (
                    parseInt(o.CountryID, 10) ===
                    parseInt(ContactSet.CountryOfBirthId, 10)
                  );
                });
                if (!CitizenshipObj) {
                  CitizenshipObj = {
                    CountryCode: '',
                  };
                }
                if (!CountryOfResidencyObj) {
                  CountryOfResidencyObj = {
                    CountryCode: '',
                  };
                }
                if (!CountryOfBirthObj) {
                  CountryOfBirthObj = {
                    CountryCode: '',
                  };
                }

                // Task 2: If the member is included in the loanAppFamily put isIncluded property = true, else false
                const familyMember = _.find(loanAppFamily, mem => {
                  return (
                    parseInt(mem.PersonId, 10) ===
                    parseInt(ContactSet.PersonId, 10)
                  );
                });

                angular.extend(ContactSet, {
                  FamilyId: person.FamilyId,
                  CountryCode: CitizenshipObj.CountryCode,
                  ResidencyCountryCode: CountryOfResidencyObj.CountryCode,
                  CountryOfBirth: CountryOfBirthObj.CountryCode,
                  ResidencyId: ContactSet.ResidencyId.toString(),
                  MaritalStatusId: ContactSet.MaritalStatusId.toString(),
                  DOBGet: moment(ContactSet.DOB).format('DD/MM/YYYY'),
                  PartyType: selectedPartyType,
                  ApplicantNumber: ApplicantNumber++,
                  PreferredContactMethodId: ContactSet.PreferredContactMethodId.toString(),
                  isIncluded: !!familyMember, // to be used on tickbox
                  isExisting: !!familyMember, // to be used on knowing if it's currently existing
                });

                // Push it
                $scope.partyApplicants.push(ContactSet);

                // set contact set for selected person
                if (
                  parseInt(member.PersonId, 10) ===
                  parseInt(person.PersonId, 10)
                ) {
                  $scope.ContactSet = ContactSet;
                }
              });
          }
        });
      });
    } else {
      $scope.Settings.isEdit = false;
      $scope.ContactSet = $scope.getNewContactModel('Adult');
      $scope.ContactSet.PartyType = selectedPartyType;
      $scope.ContactSet.ApplicantNumber = 1;
      $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };
      $scope.partyApplicants.push($scope.ContactSet);
    }

    $scope.titles = [
      { Value: 'Mr', Name: 'Mr' },
      { Value: 'Mrs', Name: 'Mrs' },
      { Value: 'Ms', Name: 'Ms' },
      { Value: 'Miss', Name: 'Miss' },
      { Value: 'Master', Name: 'Master' },
      { Value: 'Doctor', Name: 'Doctor' },
    ];

    $scope.$watch('ContactSet.Title', newVal => {
      genderService.changeGender(newVal, $scope.ContactSet.Gender);
    });

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.datePopUp = {
      DOB: false,
    };
    // Open Date Popup
    $scope.openDate = function(dateField) {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.format = 'dd MMM yyyy';
    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    // methods
    angular.extend($scope, {
      setMaritalStatus(bool) {
        $scope.Settings.MaritalStatusAdded = bool;
      },
      addPhone() {
        $scope.ContactSet.Phone.push({
          Type: 'Mobile',
          Number: '',
        });
      },
      removeContact() {
        /* Remove Party Applicant if $scope.ContactSet.MaritalStatusId == 2 && partyApplicant > 1 */
        if (_.size($scope.partyApplicants) > 1) {
          $scope.partyApplicants.pop();
        }
      },
      addContact(type, isMarried) {
        if (
          type === PERSON_CONSTANT.TYPE_APPLICANT &&
          $scope.partyApplicants.length < 2
        ) {
          const contactModel = $scope.getNewContactModel('Adult');
          contactModel.isIncluded = true;
          if (!isMarried) {
            $scope.ContactSet = contactModel;
            personService.addContact(
              $scope.partyApplicants,
              isMarried,
              $scope.Settings,
              $scope.ContactSet,
              $scope.setMaritalStatus
            );
          } else {
            personService.addContact(
              $scope.partyApplicants,
              isMarried,
              $scope.Settings,
              contactModel,
              $scope.setMaritalStatus
            );
          }
        }
      },
      selectContact(party) {
        if (party.FirstName === '' || typeof party.FirstName === 'undefined') {
          party.errFirstName = true;
        } else {
          party.errFirstName = false;
        }
        if (party.LastName === '' || typeof party.LastName === 'undefined') {
          party.errLastName = true;
        } else {
          party.errLastName = false;
        }
        if (parseInt($scope.accessType, 10) === 5) {
          const validAllocatedAdviserDescription =
            $scope.allocatedAdviser &&
            $scope.allocatedAdviser.adviserSelected &&
            $scope.allocatedAdviser.adviserSelected.FullName &&
            _.isEmpty(
              $scope.allocatedAdviser.adviserSelected.FullName.description
            );

          if (validAllocatedAdviserDescription) {
            party.errAdviser = true;
          } else party.errAdviser = false;
        }
        if (parseInt(party.PartyType, 10) === 1) {
          $scope.ContactSet = party;
          $scope.setMaritalStatus(false);
          $scope.Settings.SelectedParty = 'Applicants';
        }
      },
      removeSecondApplicant() {
        $scope.partyApplicants.splice(1, 1);
        $scope.selectContact(_.first($scope.partyApplicants));
      },
      checkMaritalStatus() {
        // NOTE: Married status = 3; make sure the number of applicants is still = 1
        if (
          parseInt($scope.ContactSet.MaritalStatusId, 10) === 2 &&
          _.size($scope.partyApplicants) === 1
        ) {
          $scope.addContact('Applicant', true);
          $scope.isMarried = true;
        }
        if (!_.isUndefined($scope.isMarried)) {
          const validApplicantRemoval =
            parseInt($scope.ContactSet.MaritalStatusId, 10) !== 2 &&
            $scope.isMarried === true &&
            _.size($scope.partyApplicants) > 1;

          if (validApplicantRemoval) {
            $scope.removeContact('Applicant');
          }
        }
      },
      saveClientAccount() {
        // save applicants
        const partyApplicants = angular.copy($scope.partyApplicants);
        const excludedApplicants = [];
        const excludedIds = [];

        _.forEach(partyApplicants, party => {
          if (party.isIncluded === false) {
            excludedApplicants.push(party);
            excludedIds.push(party.PersonId);
          }
        });

        // trap ids, we cannot exclude 2 members (this means no adult is selected)
        if (_.size(excludedApplicants) > 1) {
          toaster.pop('error', 'Error Saving', 'Cannot save empty members');
          return false;
        }
        if (_.size(excludedApplicants) > 0) {
          // find if the person is currently existing
          if (excludedApplicants[0].isExisting) {
            const role = '1';
            $scope.deleteClient(
              {
                IsApplicant: true,
                Role: role,
                PersonId: excludedApplicants[0].PersonId,
              },
              true
            );
          }
        }

        // Map the included parties
        _.map(partyApplicants, party => {
          party.Email = [{ Type: 'Email', EmailAddress: party.EmailAddress }];
          party.DOB = utilitiesService.formatDate(party.DOBGet, true);

          if (parseInt(party.CountryCode, 10) === -1)
            party.CountryOfBirth = party.CitizenshipCountryManual;
          else party.CountryOfBirth = party.CountryCode;

          let CitizenshipObj = _.find($scope.countryList, o => {
            return (
              parseInt(o.CountryCode, 10) === parseInt(party.CountryCode, 10)
            );
          });
          let CountryOfResidencyObj = _.find($scope.countryList, o => {
            return (
              parseInt(o.CountryCode, 10) ===
              parseInt(party.ResidencyCountryCode, 10)
            );
          });
          let CountryOfBirthObj = _.find($scope.countryList, o => {
            return (
              parseInt(o.CountryCode, 10) === parseInt(party.CountryOfBirth, 10)
            );
          });
          if (!CitizenshipObj) {
            CitizenshipObj = {
              CountryID: 0,
            };
          }
          if (!CountryOfResidencyObj) {
            CountryOfResidencyObj = {
              CountryID: 0,
            };
          }
          if (!CountryOfBirthObj) {
            CountryOfBirthObj = {
              CountryID: 0,
            };
          }

          angular.extend(party, {
            CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
            CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
            CountryOfBirthId: parseInt(CountryOfBirthObj.CountryID, 10),
            ResidencyId: parseInt(party.ResidencyId, 10),
          });
        });

        contactService.addContact(partyApplicants).then(response => {
          const FamilyId = response.data;
          $uibModalInstance.close({ FamilyId, PartyType: '1', excludedIds });
          const FamilyID = response.data;

          if ($scope.accessType === 5) {
            $scope.AssignedAdviserId =
              $scope.allocatedAdviser.adviserSelected.FullName.description.FamilyId;
            contactService
              .putAssignAdviser(FamilyID, $scope.AssignedAdviserId)
              .then(responseVal => {
                if (responseVal.data) {
                  $state.go('app.insApp', { insAppId: 0, familyId: FamilyID });
                  toaster.pop(
                    'success',
                    'Successful!',
                    'Contact has been assigned to an adviser.'
                  );
                }
              });
          } else {
            contactService
              .putAssignAdviser(FamilyID, $scope.AssignedAdviserId)
              .then(responseObj => {
                if (responseObj.data) {
                  $state.go('app.insApp', { insAppId: 0, familyId: FamilyID });
                  toaster.pop(
                    'success',
                    'Successful!',
                    'Contact has been assigned to an adviser.'
                  );
                }
              });
          }
          toaster.pop(
            'success',
            'Added!',
            'Contact has been Added Successfully.'
          );
          $scope.cancel();
          $scope.dismissModal();
        });
      },
      saveAccount(form) {
        const errKey = accountService.saveCheck(
          $scope.partyApplicants,
          form,
          $scope.accessType,
          $scope.allocatedAdviser
        );

        if (form.$invalid) {
          $scope.selectContact($scope.partyApplicants[errKey]);
        } else {
          $scope.$watch('submitClicked', () => {
            $scope.submitClicked = true;
          });
          $scope.saveClientAccount();

          $scope.submitClicked = false;
        }
      },
    });
  });
