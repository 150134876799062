import angular from 'angular';

angular
  .module('app')
  .directive('pipelineNewLeads', function pipelineNewLeads(
    $timeout,
    overviewDashboardService,
    uiService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/pipelineNewLeads.html',
      link(scope) {
        scope.tableMode = true;
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };
        scope.fixedRateReviewsDropDownList = [];
        scope.fixedRateReviewsDropDowns = {};
        scope.WidgetOptionAdviserList = [];
        scope.widgetOptionAdviser = {};
        scope.fixedRateReviewTableData = [];
        scope.fixedRateReviewsMultiAdviserObj = {};
        scope.fixedRateReviewsMultiAdviser = [];
        scope.WidgetOptionGetData = [];
        scope.filteredFixedRateReviewsDropDownList = [];
        scope.outp = [];
        scope.switchsetting2 = {};

        function widgetPipelineNewLeadsGet() {
          overviewDashboardService
            .WidgetPipelineNewLeadsGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.showSlick = false;
                scope.newLeadsTableData = [];
                const totalData = response.data.length;
                const round = Math.floor(totalData / 4) * 4 - 1;
                const modStart = Math.floor(totalData / 4) * 4;
                const mod = Math.floor(totalData % 4);
                for (let i = 0; i < round; i += 4) {
                  const testData = [];
                  testData.push(response.data[i]);
                  testData.push(response.data[i + 1]);
                  testData.push(response.data[i + 2]);
                  testData.push(response.data[i + 3]);

                  scope.newLeadsTableData.push(testData);
                }
                if (mod) {
                  const lastItem = [];
                  for (let i = modStart; i < totalData; i++) {
                    lastItem.push(response.data[i]);
                  }
                  scope.newLeadsTableData.push(lastItem);
                }

                scope.dataLoaded = true;
                scope.showSlick = true;

                if (scope.newLeadsTableData.length > 0) {
                  $timeout(() => {
                    try {
                      scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1000);
                }
              },
              () => {}
            );
        }

        scope.getInitials = function(name) {
          return uiService.getInitials(name);
        };

        function init() {
          widgetPipelineNewLeadsGet();
        }

        scope.dynamicPopover = {
          content: '',
          templateUrl: 'pipelineNewLeadsPopover.html',
          title: '',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') {
            scope.customizeMode = true;
            scope.tableMode = false;
          } else {
            scope.customizeMode = false;
            scope.calendarMode = false;
            scope.tableMode = true;
          }
        };

        init();
      },
    };
  });
