import template from './opportunityCard.html';

export default {
  template,
  bindings: {
    isSmallDevice: '<',
    card: '<',
    isCompact: '<',
    onCardClick: '&',
    onMenuClick: '&',
    onViewBorrower: '&',
    onDeletePipelineItem: '&',
    isShowTimeline: '<',
    timelineStatusList: '<',
    isSingle: '<',
    onChangeCardStatus: '&',
    onViewOpportunity: '&',
    conversionOptions: '<',
    onConvertCard: '&',
    isCorporateUser: '<',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    isShrink: '<',
    onToggleIsShrink: '&',
  },
};
