import template from './createNewLoanApplicationV2.html';
import controller from './createNewLoanApplicationCtrlV2';
import './styles.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
  },
};
