import angular from 'angular';
import { KEY_CODE } from 'Common/constants/keyCode';

angular
  .module('app')
  .directive('overrideValidation', function overrideValidation(
    $window,
    loanAppSharedData
  ) {
    return {
      restrict: 'A',
      link(scope) {
        const handleKeyDownEvent = e => {
          loanAppSharedData.isOverrideValidation =
            e.keyCode === KEY_CODE.MAC_CTRL || e.keyCode === KEY_CODE.WIN_ALT;
        };

        $window.addEventListener('keydown', handleKeyDownEvent);
        scope.$on('$destroy', () => {
          $window.removeEventListener('keydown', handleKeyDownEvent);
        });

        const handleKeyUpEvent = () => {
          loanAppSharedData.isOverrideValidation = false;
        };

        $window.addEventListener('keyup', handleKeyUpEvent);
        scope.$on('$destroy', () => {
          $window.removeEventListener('keyup', handleKeyUpEvent);
        });
      },
    };
  });
