/**
 * Generate color name using http://chir.ag/projects/name-that-color
 */

export const COLOR = {
  SALMON: '#f98169',
  SEA_BUCKTHORN: '#fda829',
  TURBO: '#fde000',
  CANNON_PINK: '#9c4b85',
  MULLED_WINE: '#694573',
  LOCHMARA: '#017cc9',
  AFFAIR: '#6b499d',
  BRIGHT_TURQUOISE: '#05d0ee',
  APPLE: '#58cd40',
  WHITE: '#ffffff',
  BRIGHT_TURQUOISE_DARKER: '#3D91CC',
  DARKER_BLUE: '#579BCB',
  BLUE_VIOLET: '#A098D2',
  TERRACOTTA: '#DD6B55',
  CK_EDITOR_BUTTONS: '#DFD6E4',
  BLUE_VIKING: '#42B4B4',
  HAITI: '#1B1439',
  CERULEAN: '#00AAE5' /* Australia LM */,
  SUSHI: '#9BB53B' /* Insurance Market */,
  LUCKY_POINT: '#1E246E' /* Wealth Market */,
  APRICOT: '#EC7777',
  SCOOTER: '#30C1C4',
  GERALDINE: '#FA8E91',
  DARKER_BLUER: '#3f2e85',
};

export const PIPELINE_COLORS = {
  SALMON: '#f98169',
  SEA_BUCKTHORN: '#fda829',
  TURBO: '#fde000',
  CANNON_PINK: '#9c4b85',
  MULLED_WINE: '#694573',
  LOCHMARA: '#017cc9',
  AFFAIR: '#6b499d',
  BRIGHT_TURQUOISE: '#05d0ee',
  APPLE: '#58cd40',
};

export const GMAP_COLOR = {
  DARK_SLATE_GRAY: '#444444',
  LANDSCAPE: '#f2f2f2',
  WATER: '#b4d4e1',
};
