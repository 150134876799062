import template from './emailSubjectField.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    placeholder: '@',
    subject: '<',
    isInvalid: '<',
    validationMessage: '<',
    onUpdate: '&',
  },
};
