// Angular-breadcrumb configuration
function configBreadcrumb($breadcrumbProvider) {
  $breadcrumbProvider.setOptions({
    template:
      '<ul class="breadcrumb"><li><a ui-sref="app.dashboard"><i class="fa fa-home margin-right-5 text-large text-dark"></i>Home</a></li><li ng-repeat="step in steps">{{step.ncyBreadcrumbLabel}}</li></ul>',
  });
}

configBreadcrumb.$inject = ['$breadcrumbProvider'];

export default configBreadcrumb;
