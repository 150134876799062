import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('ContactFinancialsCtrl', function ContactFinancialsCtrl(
    $scope,
    uiService
  ) {
    $scope.uiService = uiService;
    angular.extend($scope, {
      financials: {
        liabilityTotal: 0,
        assetTotal: 0,
        incomeTotal: 0,
      },
    });

    $scope.processBorrowersData = (info, borrowers, ownersListSelected) => {
      const temp = {
        Percentage: 0,
        BorrowerID: 0,
        FirstName: 'All',
        LastName: 'Clients',
        PreferedName: 'Joint',
        ticked: true,
        disabled: true,
      };
      let ownersList = [];
      if (
        ownersListSelected.length < 1 ||
        ownersListSelected.length === info.data.length
      ) {
        const tmp = [];
        info.data &&
          Object.keys(info.data).forEach(x => {
            const value = info.data[x];
            value.PreferedName = `${value.FirstName} ${value.LastName}`;
            tmp.push(value);
          });
        if (tmp.length === 1) {
          tmp[0].ticked = true;
        } else {
          tmp.unshift(temp);
        }
        ownersList = angular.copy(tmp);
      } else {
        temp.ticked = false;
        const tmp = [];
        // Set default selected from edit
        if (_.size(borrowers) <= _.size(info.data)) {
          info.data &&
            Object.keys(info.data).forEach(x => {
              const value = info.data[x];
              value.ticked = !!_.find(borrowers, o => {
                return (
                  parseInt(o.BorrowerID, 10) === parseInt(value.BorrowerID, 10)
                );
              });
              value.PreferedName = `${value.FirstName} ${value.LastName}`;
              tmp.push(value);
            });
        } else {
          info.data &&
            Object.keys(info.data).forEach(x => {
              const value = info.data[x];
              value.ticked = value.BorrowerID === -1;
              value.PreferedName = `${value.FirstName} ${value.LastName}`;
              tmp.push(value);
            });
        }
        if (info.data.length > 1) {
          tmp.unshift(temp);
        }
        ownersList = angular.copy(tmp);
      }
      return ownersList;
    };
  });
