import template from './crmTableView.html';
import controller from './crmTableViewCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    tableData: '<',
    tableHeaders: '<',
    freezeColumns: '<',
  },
};
