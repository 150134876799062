import _ from 'lodash';
import swal from 'sweetalert';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';

class SMSTemplatesCtrl {
  constructor(
    toaster,
    uiService,
    corporateService,
    contactService,
    mailTemplateService,
    templateService
  ) {
    'ngInject';

    this.toaster = toaster;
    this.uiService = uiService;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.mailTemplateService = mailTemplateService;
    this.templateService = templateService;
    this.SEND_METHOD_TYPES = SEND_METHOD_TYPES;
  }

  $onInit() {
    this.isCorporateUser = this.uiService.isCorporateUser;
    this.templateListing = [];
    this.templateList = [];
    this.templateFolderList = [];
    this.template = {};
    this.isAddCategory = false;
    this.isTemplateEditor = false;

    this.draggableConfig = {
      accept: (srcItemHandleScope, dest) => {
        const destinationCategory = dest.sortableScope.modelValue[dest.index()];
        return (
          !srcItemHandleScope.itemScope.modelValue.IsTemplateFolder &&
          !srcItemHandleScope.itemScope.modelValue.IsSystemTemplate &&
          !destinationCategory.IsSystemTemplate
        );
      },
      itemMoved: event => {
        const template = event.dest.sortableScope.modelValue[event.dest.index];
        const destinationFolderId =
          parseInt(event.dest.sortableScope.$parent.$parent.heading, 10) || 0;
        this.moveTemplate(template, destinationFolderId);
      },
      orderChanged: event => {
        this.saveOrder(event.dest.sortableScope.modelValue);
      },
      dragStart: event => {
        event.source.itemScope.element.addClass('mail-template-dragging');
      },
      dragEnd: event => {
        event.source.itemScope.element.removeClass('mail-template-dragging');
      },
      containerPositioning: 'relative',
      allowDuplicate: true,
    };

    this.draggableFolderConfig = {
      accept: (srcItemHandleScope, destSortableScope) => {
        return (
          srcItemHandleScope.itemScope.modelValue.IsTemplateFolder &&
          _.isArray(destSortableScope.modelValue) &&
          !destSortableScope.modelValue.IsTemplateFolder
        );
      },
      dragStart: event => {
        event.source.itemScope.element.addClass(
          'mail-template-folder-dragging'
        );
      },
      dragEnd: event => {
        event.source.itemScope.element.removeClass(
          'mail-template-folder-dragging'
        );
      },
      orderChanged: event => {
        this.saveOrder(event.dest.sortableScope.modelValue);
      },
      containerPositioning: 'relative',
      allowDuplicate: false,
    };

    this.getTemplates();
  }

  getTemplates() {
    this.contactService.smsTemplatesGet().then(response => {
      if (response.data) {
        this.blankCategory = _.remove(response.data, category =>
          this.templateService.isBlankFolderOrTemplate(category)
        )[0];
        this.blankTemplate = this.templateService.getBlankTemplateIn(
          this.blankCategory
        );
        this.templateListing = this.templateService.filterTemplatesByUserType(
          response.data
        );
      }
    });
  }

  toggleAddCategory() {
    this.showAddCategory = !this.showAddCategory;
    this.templateFolderList.newFolder = null;
  }

  setTemplateEditor(folder, template) {
    if (!folder) return;

    this.selectedTemplate = {
      folderId: folder.UserTemplateID,
      folderName: folder.UserTemplateName,
      templateId: template ? template.UserTemplateID : 0,
      templateName: template ? template.UserTemplateName : 'New SMS Template',
    };

    this.template = template
      ? _.clone(template)
      : {
          UserTemplateName: 'New SMS Template',
          UserTemplateFolderId: folder.UserTemplateID,
          UserTemplateFolderName: folder.UserTemplateName,
          IsExclusiveForCorpUser: this.uiService.isCorporateUser,
          isSharedWithAdviser: false,
          IsTemplateFolder: false,
        };

    this.toggleTemplateEditor();
  }

  toggleTemplateEditor() {
    this.showEditTemplate = !this.showEditTemplate;
  }

  cancelEditTemplate() {
    this.toggleTemplateEditor();
    this.selectedTemplate = {};
  }

  editTemplateFolder(folder) {
    if (folder) {
      this.templateFolderList[folder.UserTemplateID] =
        this.templateFolderList[folder.UserTemplateID] || {};
      this.templateFolderList[folder.UserTemplateID].folderName =
        folder.UserTemplateName;
      this.templateFolderList[folder.UserTemplateID].isEdit = true;
      this.currentTemplateId = folder.UserTemplateID;
    }
  }

  updateTemplateFolder(folder) {
    this.contactService
      .smsTemplateFolderSet({
        UserTemplateID: folder.UserTemplateID,
        UserTemplateName: this.templateFolderList[folder.UserTemplateID]
          .folderName,
      })
      .then(
        () => {
          this.templateFolderList[folder.UserTemplateID].isEdit = false;
          folder.UserTemplateName = this.templateFolderList[
            folder.UserTemplateID
          ].folderName;
          this.toaster.pop('success', 'Template Folder successfully saved.');
          this.onChangesMade();
        },
        () => {
          this.toaster.pop('error', 'Template Folder was not saved.');
        }
      );
  }

  cancelEditFolder(folder) {
    if (folder) {
      this.templateFolderList[folder.UserTemplateID].isEdit = false;
    }
  }

  triggerDeleteTemplate(template) {
    if (template) {
      SMSTemplatesCtrl.confirmDelete(false, () =>
        this.deleteTemplate(template)
      );
    }
  }

  triggerDeleteFolder(folder) {
    if (folder) {
      SMSTemplatesCtrl.confirmDelete(true, () => this.deleteFolder(folder));
    }
  }

  static confirmDelete(isFolder, onConfirm) {
    const title = isFolder
      ? 'Are you sure you want to delete this Folder?'
      : 'Are you sure you want to delete this Template?';
    swal(
      {
        title,
        text: 'If you want to recover, just contact the administrator.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: true,
      },
      isConfirmed => {
        if (isConfirmed && onConfirm) {
          onConfirm();
        }
      }
    );
  }

  deleteFolder(folder) {
    this.contactService.smsTemplateFolderDelete(folder.UserTemplateID).then(
      () => {
        const deletedIndex = _.findIndex(
          this.templateListing,
          obj => obj.UserTemplateID === folder.UserTemplateID
        );
        this.templateListing.splice(deletedIndex, 1);
        this.toaster.pop('success', 'Template folder successfully deleted.');
        this.onChangesMade();
      },
      () => {
        this.toaster.pop('error', 'Unable to delete the template folder.');
      }
    );
  }

  deleteTemplate(template) {
    this.contactService.smsTemplatesDelete(template.UserTemplateID).then(
      () => {
        const folder = _.find(
          this.templateListing,
          obj => obj.UserTemplateID === template.UserTemplateFolderId
        );
        if (folder) {
          const deletedIndex = _.findIndex(
            folder.DocRtfSMSTemplateList,
            obj => obj.UserTemplateID === template.UserTemplateID
          );
          folder.DocRtfSMSTemplateList.splice(deletedIndex, 1);
        }
        this.toaster.pop('success', 'Template successfully removed.');
        this.onChangesMade();
      },
      () => {
        this.toaster.pop('error', 'Unable to delete the template.');
      }
    );
  }

  saveTemplateEnabled() {
    return this.mailTemplateService.isTemplateValid(this.template);
  }

  saveTemplate() {
    if (!this.saveTemplateEnabled()) return;

    this.savingTemplate = true;
    this.contactService.smsTemplatesSet(this.template).then(
      response => {
        if (response.data) {
          this.toaster.pop('success', 'Template successfully saved.');
          const folder = this.templateService.isBlankFolderOrTemplate(
            this.template
          )
            ? this.blankCategory
            : _.find(
                this.templateListing,
                obj => obj.UserTemplateID === response.data.UserTemplateFolderId
              );

          if (folder && folder.DocRtfSMSTemplateList) {
            SMSTemplatesCtrl.insertTemplateToFolder(folder, response.data);
          } else if (folder) {
            folder.DocRtfSMSTemplateList = [];
            folder.DocRtfSMSTemplateList.push(response.data);
          }

          this.toggleTemplateEditor();
          this.template.UserTemplateName = null;
          this.template.UserTemplateContent = null;
          this.savingTemplate = false;
          this.onChangesMade();
        }
      },
      () => {
        this.toaster.pop('error', 'Unable to save the template.');
        this.savingTemplate = false;
      }
    );
  }

  static insertTemplateToFolder(folder, template) {
    const templateIndex = _.findIndex(
      folder.DocRtfSMSTemplateList,
      obj => obj.UserTemplateID === template.UserTemplateID
    );
    if (templateIndex !== -1) {
      folder.DocRtfSMSTemplateList[templateIndex] = template;
    } else {
      folder.DocRtfSMSTemplateList.push(template);
    }
  }

  saveOrder(obj) {
    this.mailTemplateService.setTemplatesOrder(obj);
    this.contactService.smsTemplateSortingSet(obj);
    this.onChangesMade();
  }

  moveTemplate(template, folderId) {
    template.UserTemplateFolderId = folderId;
    this.contactService.smsTemplatesSet(template).then(
      () => {
        this.toaster.pop(
          'success',
          'Template successfully moved to another folder.'
        );
        this.onChangesMade();
      },
      () => {
        this.toaster.pop('error', 'Unable to move template to another folder.');
      }
    );
  }

  insertValue(value) {
    if (!this.template) {
      this.template = {};
    }
    this.template.UserTemplateContent = this.template.UserTemplateContent
      ? `${this.template.UserTemplateContent}{${value}}`
      : `{${value}}`;
  }

  saveTemplateFolder() {
    if (
      this.templateFolderList.newFolder &&
      this.templateFolderList.newFolder.trim()
    ) {
      const folder = this.mailTemplateService.constructNewTemplateFolder(
        this.templateFolderList.newFolder
      );
      this.contactService.smsTemplateFolderSet(folder).then(
        response => {
          this.toaster.pop('success', 'Template folder successfully saved.');
          folder.UserTemplateID = response.data;
          this.templateListing.push(folder);
          this.toggleAddCategory();
          this.onChangesMade();
        },
        () => {
          this.toaster.pop('error', 'Unable to save the template folder.');
        }
      );
    }
  }

  onChangesMade() {
    if (!this.onTemplatesUpdated) return;
    this.onTemplatesUpdated();
  }
}

export default SMSTemplatesCtrl;
