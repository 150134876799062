export const CORPORATE_TEAM = {
  A_AND_O: 1,
  COMPLIANCE: 2,
  MARKETING: 3,
  STATE_TEAM: 4,
  P_AND_P: 5,
  WEALTH_MARKET_CORPORATE: 6,
  BROKER_SUPPORT: 7,
  IT_SUPPORT: 8,
  ACCREDITATIONS: 9,
  SUPER_ADMIN: 12,
};
