class ReferencedReferralService {
  constructor(optionsService, $q) {
    'ngInject';

    this.optionsService = optionsService;
    this.$q = $q;
  }
  getReferencedReferralList(brokerId, scope = {}) {
    const defer = this.$q.defer();
    this.optionsService.getReferencedReferralList(brokerId).then(
      response => {
        const data = response.data;
        if (data && data.length) {
          this.referralList = data.filter(item => {
            return item.ReferralOrganizationName && item.ReferralMemberName;
          });
          this.getClientList = this.referralList;
          if (scope) {
            scope.ReferralOrgList = this.referralList;
            scope.referredByHelper.loopCount++;
          }
          defer.resolve(this.referralList);
        } else {
          defer.resolve([]);
        }
      },
      () => {
        defer.reject([]);
      }
    );
    return defer.promise;
  }
}

export default ReferencedReferralService;
