export function brokerStatusFilterProspect(data) {
  let prospectTmpObj = null;
  data.forEach((value, key) => {
    if (value.BrokerStatusName === 'Prospect') {
      prospectTmpObj = value;
      data.splice(key, 1);
    }
  });

  return prospectTmpObj ? [prospectTmpObj, ...data] : [];
}
