import { PERSON_CONSTANT } from 'Common/constants/personConstants';

class PersonService {
  addContact(list, isMarried, settings, contactSet, setMaritalStatus) {
    this.list = list;
    if (!isMarried) {
      contactSet.PartyType = PERSON_CONSTANT.PARTY_TYPE_APPLICANT;
      contactSet.ApplicantNumber = list.length + 1;
      contactSet.customerConsentOptions = { ConsentMarketing: true };
      if (settings.isEdit) {
        contactSet.isIncluded = true;
      }

      list.push(contactSet);
    } else {
      contactSet.PartyType = PERSON_CONSTANT.PARTY_TYPE_APPLICANT;
      contactSet.ApplicantNumber = list.length + 1;
      contactSet.MaritalStatusId = PERSON_CONSTANT.TYPE_MARRIED;
      contactSet.customerConsentOptions = { ConsentMarketing: true };

      if (settings.isEdit) {
        contactSet.isIncluded = true;
      }

      list.push(contactSet);
      setMaritalStatus(true);
    }
    settings.SelectedParty = PERSON_CONSTANT.SELECTED_PARTY_APPLICANT;
  }
}

export default PersonService;
