import template from './benefitTypeDescriptionModal.html';
import controller from './benefitTypeDescriptionModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    benefitType: '<',
    modalInstance: '<',
  },
};
