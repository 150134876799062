import EmailEditor from 'Common/services/emailEditor';

export default class EmailEditorCtrl {
  $onInit() {
    if (typeof this.isRecipient === 'undefined') this.isRecipient = true;
    if (typeof this.isCc === 'undefined') this.isCc = true;
    if (typeof this.isSubject === 'undefined') this.isSubject = true;
    if (typeof this.isSend === 'undefined') this.isSend = true;
    if (typeof this.btnSendText === 'undefined') this.btnSendText = 'Send';

    this.emailEditor = new EmailEditor(this.emailObj);
  }

  selectRecipient(recipient) {
    this.emailEditor.onSelectRecipient(recipient);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }

  removeRecipient(recipient) {
    this.emailEditor.onRemoveRecipient(recipient);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }

  selectCc(cc) {
    this.emailEditor.onSelectCc(cc);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }

  remove(cc) {
    this.emailEditor.onRemoveCc(cc);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }

  updateSubject(subject) {
    this.emailEditor.onUpdateSubject(subject);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }

  updateBody(body) {
    this.emailEditor.onUpdateBody(body);

    this.onEmailChange({ emailModel: this.emailEditor.emailObj });
  }
}
