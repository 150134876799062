app.provider('configService', function() {
  var config = {};
  this.setConfig = function(paramConfig) {
    angular.extend(config, paramConfig);
  };

  this.$get = [
    function() {
      if (!config) {
        throw new Error(
          'Unable to find config.json. Configuration file is needed for the application to run'
        );
      }

      return config;
    },
  ];
});
