import { totalLoanAmountsBuilderForUI } from 'Common/mappers/loanOpportunity';

export function opportunityCardsBuilderForUI(data) {
  return {
    cards: data.Cards.map(card => {
      return {
        contactNames: card.ContactNames,
        clients: card.Clients,
        entities: card.Entities,
        guarantors: card.Guarantors,
        adviserFirstName: card.AdviserFirstName,
        adviserLastName: card.AdviserLastName,
        adviserFullName: card.AdviserFullName,
        title: card.Title,
        loanScenarioId: card.LoanScenarioId,
        pipelineStatusId: card.PipelineStatusId,
        proposedLoanAmount: card.ProposedLoanAmount,
        securityAmount: card.SecurityAmount,
        lastModifiedDate: card.LastModifiedDate,
        enquiry: card.Enquiry,
        loanId: card.LoanId,
        adviserProfilePicture: card.AdviserProfilePicture,
        loanPurposeCategory: card.LoanPurposeCategory,
        totalRecords: card.TotalRecords || 0,
        labels: card.Labels,
      };
    }),
    totalLoanAmounts: data.TotalLoanAmounts.map(totalLoanAmountsBuilderForUI),
  };
}

export function opportunityTableBuilderForUI(rowData) {
  return {
    contactNames: rowData.ContactNames,
    clients: rowData.Clients,
    entities: rowData.Entities,
    guarantors: rowData.Guarantors,
    adviserFullName: rowData.AdviserFullName,
    title: rowData.Title,
    loanScenarioId: rowData.LoanScenarioId,
    pipelineStatus: rowData.PipelineStatus,
    pipelineStatusId: rowData.PipelineStatusId,
    pipelineCardsId: rowData.PipelineCardsId,
    proposedLoanAmount: rowData.ProposedLoanAmount,
    referrerName: rowData.ReferrerName,
    securityAmount: rowData.SecurityAmount,
    enquiry: rowData.Enquiry,
    loanId: rowData.LoanId,
    totalRecords: rowData.TotalRecords,
    statusUpdated: rowData.StatusUpdated,
    labels: rowData.Labels,
  };
}

export function pipelineSettingsBuilderForAPI(settings) {
  return {
    SortColumn: settings.sortColumn,
    SortType: settings.sortType,
    StatusId: settings.statusId,
    AdvisorId: settings.advisorId,
    LabelId: settings.labelId,
    Probability: settings.probability,
    ReferralItemID: settings.referralItemID,
    ReferralCategoryID: settings.referralCategoryID,
    Referral: settings.referral,
    IsCompact: settings.isCompact,
    SortingMode: settings.sortingMode,
    IsTableView: settings.isTableView,
    CustomSort: settings.customSort,
    CustomerSort: settings.customerSort,
    TableViewExcludedColumns: settings.tableViewExcludedColumns,
    SubStatusId: settings.subStatusId,
    StatusIds: settings.statusIds,
    ConversionStatusIDs: settings.conversionStatusIDs,
    searchClientName: settings.searchClientName,
    PipelineCardsId: settings.pipelineCardsId,
    IsOpportunity: settings.isOpportunity,
    IsLeads: settings.isLeads,
    DateCreatedStart: settings.dateCreatedStart,
    DateCreatedEnd: settings.dateCreatedEnd,
  };
}

export function pipelineSettingsBuilderForUI(settings) {
  return {
    sortColumn: settings.SortColumn,
    sortType: settings.SortType,
    statusId: settings.StatusId,
    advisorId: settings.AdvisorId,
    labelId: settings.LabelId,
    probability: settings.Probability,
    referralItemID: settings.ReferralItemID,
    referralCategoryID: settings.ReferralCategoryID,
    referral: settings.Referral,
    isCompact: settings.IsCompact,
    sortingMode: settings.SortingMode,
    isTableView: settings.IsTableView,
    customSort: settings.CustomSort,
    customerSort: settings.CustomerSort,
    tableViewExcludedColumns: settings.TableViewExcludedColumns,
    subStatusId: settings.SubStatusId,
    searchClientName: settings.searchClientName,
    statusIds: settings.StatusIds,
    conversionStatusIDs: settings.ConversionStatusIDs,
    pipelineCardsId: settings.PipelineCardsId,
    isOpportunity: settings.IsOpportunity,
    isLeads: settings.IsLeads,
    dateCreatedStart: settings.DateCreatedStart,
    dateCreatedEnd: settings.DateCreatedEnd,
  };
}

export function pipelineStatusBuilderForUI(status) {
  return {
    brokerBrandingId: status.BrokerBrandingID,
    categoryName: status.CategoryName,
    pipelineCategoryId: status.PipelineCategoryID,
    pipelineStatus: status.PipelineStatus,
    pipelineStatusId: status.PipelineStatusID,
    subStatus: status.SubStatus,
    typeName: status.TypeName,
    isConversionStatus: status.IsConversionStatus,
  };
}

export function pipelineTableViewBuilderForUI(tableData) {
  return {
    clientName: tableData.ClientName,
    dateCreated: tableData.CreateDate,
    postCode: tableData.PostCode,
    noAutoAllocationReason: tableData.NoAutoAllocationReason,
    probability: tableData.Probability,
    pipelineStatus: tableData.PipelineStatus,
    enquirySource: tableData.EnquirySource,
    adviserName: tableData.AdviserFullname,
    referrerName: tableData.ReferrerName,
    subStatusName: tableData.SubStatusName,
    lastUpdated: tableData.LastModifiedDate,
    labels: tableData.Labels,
    pipelineCardsId: tableData.PipelineCardsID,
    clientFamilyId: tableData.ClientFamilyID,
    adviserProfilePicture: tableData.AdviserProfilePicture,
    lastModifiedProfilePicture: tableData.LastModifiedProfilePicture,
    subStatusId: tableData.SubStatusID,
    totalRecords: tableData.TotalRecords,
    lastModifier: tableData.LastModifiedFullName,
    adviserId: tableData.AdviserId,
  };
}
export function pipelineCardsTimeLineViewBuilderForUI(data) {
  return {
    isAllowLeadReactivation: data.IsAllowLeadReactivation,
    timeLineList: data.TimeLineList,
  };
}
