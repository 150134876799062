import angular from 'angular';

angular
  .module('app')
  .directive('upcomingEventsTable', function upcomingEventsTable(
    $timeout,
    overviewDashboardService,
    overviewDashboardSharedService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/upcomingEventsTable.html',

      link(scope) {
        scope.tableMode = true;

        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };

        scope.upcomingEventTableData = [];

        function upComingEventTableGet() {
          scope.showSlick = false;
          overviewDashboardService.UpComingEventTableGet().then(
            response => {
              scope.upcomingEventTableData = [];
              const totalData = response.data.length;
              const round = Math.floor(totalData / 3) * 3 - 1;

              const modStart = Math.floor(totalData / 3) * 3;
              const mod = Math.floor(totalData % 3);
              for (let i = 0; i < round; i += 3) {
                const testData = [];
                testData.push(response.data[i]);
                testData.push(response.data[i + 1]);
                testData.push(response.data[i + 2]);

                scope.upcomingEventTableData.push(testData);
              }

              if (parseInt(mod, 10) !== 0) {
                const lastItem = [];
                for (let i = modStart; i < totalData; i++) {
                  lastItem.push(response.data[i]);
                }
                scope.upcomingEventTableData.push(lastItem);
              }
              scope.dataLoaded = true;
              scope.showSlick = true;
              if (scope.upcomingEventTableData.length > 0) {
                $timeout(() => {
                  try {
                    scope.slickConfig.method.slickGoTo(0);
                  } catch (error) {
                    // continue regardless of error
                  }
                }, 1000);
              }
            },
            () => {}
          );
        }
        function init() {
          upComingEventTableGet();
        }
        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'upcomingEventsTablePopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          overviewDashboardSharedService.doActionShared(msg, scope);
        };

        scope.viewChangeClicked = function() {
          return false;
        };

        const date = new Date();
        const d = date.getDate();
        const m = date.getMonth();
        const y = date.getFullYear();

        scope.events = [
          {
            title: 'My event title', // The title of the event
            startsAt: new Date(y, m, 5, 19, 0),
            endsAt: new Date(y, m, 5, 22, 30),
            color: {
              // can also be calendarConfig.colorTypes.warning for shortcuts to the deprecated event types
              primary: '#e3bc08', // the primary event color (should be darker than secondary)
              secondary: '#fdf1ba', // the secondary event color (should be lighter than primary)
            },
            actions: [
              {
                // an array of actions that will be displayed next to the event title
                label: "<i class='glyphicon glyphicon-pencil'></i>", // the label of the action
                cssClass: 'aTestClass', // a CSS class that will be added to the action element so you can implement custom styling
                onClick() {
                  // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                },
              },
            ],
            cssClass: '', // A CSS class (or more, just separate with spaces) that will be added to the event when it is displayed on each view. Useful for marking an event as selected / active etc
            allDay: true, // set to true to display the event as an all day event on the day view
          },
          {
            title: 'My Program', // The title of the event
            startsAt: new Date(y, m, 5, 19, 0),
            endsAt: new Date(y, m, 5, 22, 30),
            color: {
              // can also be calendarConfig.colorTypes.warning for shortcuts to the deprecated event types
              primary: '#e3bc08', // the primary event color (should be darker than secondary)
              secondary: '#fdf1ba', // the secondary event color (should be lighter than primary)
            },
            actions: [
              {
                // an array of actions that will be displayed next to the event title
                label: "<i class='glyphicon glyphicon-pencil'></i>", // the label of the action
                cssClass: 'aTestClass', // a CSS class that will be added to the action element so you can implement custom styling
                onClick() {
                  // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                },
              },
            ],
            cssClass: '', // A CSS class (or more, just separate with spaces) that will be added to the event when it is displayed on each view. Useful for marking an event as selected / active etc
            allDay: true, // set to true to display the event as an all day event on the day view
          },
        ];

        scope.events2 = [
          {
            title: 'Birthday Party',
            type: 'home',
            startsAt: new Date(y, m, 5, 19, 0),
            endsAt: new Date(y, m, 5, 22, 30),
          },
          {
            title: 'AngularJS Seminar',
            type: 'off-site-work',
            startsAt: new Date(y, m, 8, 10, 30),
            endsAt: new Date(y, m, 9, 18, 30),
          },
          {
            title: 'Event 1',
            type: 'job',
            startsAt: new Date(y, m, d - 5),
            endsAt: new Date(y, m, d - 2),
          },
          {
            title: 'Event 2',
            type: 'cancelled',
            startsAt: new Date(y, m, d - 3, 16, 0),
            endsAt: new Date(y, m, d - 3, 18, 0),
          },
          {
            title: 'This is a really long event title',
            type: 'to-do',
            startsAt: new Date(y, m, d + 1, 19, 0),
            endsAt: new Date(y, m, d + 1, 22, 30),
          },
        ];

        scope.calendarView = 'month';
        scope.calendarDate = new Date();

        function showModal() {}

        scope.eventClicked = function(event) {
          showModal('Clicked', event);
        };

        scope.timeSpanClicked = function(event) {
          showModal('Clicked', event);
        };

        scope.toggle = function($event, field, event) {
          $event.preventDefault();
          $event.stopPropagation();

          event[field] = !event[field];
        };

        scope.startOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.endOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.endOpen = function() {
          scope.endOptions.minDate = scope.event.startsAt;
          scope.startOpened = false;
          scope.endOpened = !scope.endOpened;
        };

        scope.startOpen = function() {
          scope.startOptions.maxDate = scope.event.endsAt;
          scope.endOpened = false;
          scope.startOpened = !scope.startOpened;
        };

        init();
      },
    };
  });
