import template from './crmConfirmation.html';
import controller from './crmConfirmationCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    cTitle: '<',
    cDescription: '<',
    cButtonText: '<',
    cType: '<', // only pass either success, warning, info
    cModalInstance: '<',
    cShowCancelButton: '<',
    cShowCloseButton: '<',
    cModalSize: '<',
    cRenderAsComponent: '<',
    cCommonObject: '<',
  },
};
