import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('PipelineLabelsModalCtrl', function PipelineLabelsModalCtrl(
    $scope,
    $uibModalInstance,
    toaster,
    pipelineService,
    card,
    labels,
    pipelineSharedData
  ) {
    $scope.card = card;
    $scope.labels = labels;

    $scope.checkLabelExistInList = label => {
      const findLabelModal = _.find($scope.labels, o => {
        return String(o.pipelineSettingsLabelID) === String(label.labelID);
      });

      if (_.size(findLabelModal) > 0) {
        label.labelColor = findLabelModal.ColorName;
        return true;
      }
      return false;
    };

    $scope.addlabel = () => {
      $scope.card.addLabelShown = true;
      $scope.defineColors();
    };
    $scope.defineColors = () => {
      $scope.labelColor1 = false;
      $scope.labelColor2 = false;
      $scope.labelColor4 = false;
      $scope.labelColor3 = false;
      $scope.labelColor5 = false;
      $scope.labelColor6 = false;
      $scope.labelColor7 = false;
      $scope.labelColor8 = false;
      $scope.labelColor9 = false;
      $scope.labelColor10 = false;
    };
    $scope.colorPicked = color => {
      $scope.pickedColorCode = color;
    };
    $scope.updateLabel = () => {
      $scope.lblsToUpdate = [];
      $scope.lblToUpdate = {};

      if ($scope.card.lblObjToEdit) {
        const labelObj = $scope.card.lblObjToEdit;

        $scope.lblToUpdate.LabelID = labelObj.pipelineSettingsLabelID;
        $scope.lblToUpdate.LabelName = $scope.card.labelTitle;
        $scope.lblToUpdate.ColorName = !_.isEmpty($scope.pickedColorCode)
          ? $scope.pickedColorCode
          : labelObj.ColorName;
        $scope.lblsToUpdate.push($scope.lblToUpdate);
      } else {
        $scope.lblToUpdate.LabelID = 0;
        $scope.lblToUpdate.LabelName = $scope.card.newLabelTitle;
        $scope.lblToUpdate.ColorName = $scope.pickedColorCode;
        $scope.lblsToUpdate.push($scope.lblToUpdate);
      }

      pipelineService.PipelineSettingsLabelSet($scope.lblsToUpdate).then(
        () => {
          toaster.pop(
            'success',
            $scope.card.lblObjToEdit ? 'Updated' : 'Added',
            $scope.card.lblObjToEdit
              ? 'Label has been updated successfully.'
              : 'Label has been added successfully.'
          );
          $scope.card.editLabelShown = false;
          $scope.card.addLabelShown = false;
          if (!$scope.card.lblObjToEdit) {
            $scope.labels.push($scope.lblToUpdate);
          } else {
            $scope.card.lblObjToEdit.LabelName = $scope.card.labelTitle;
            if ($scope.pickedColorCode)
              $scope.card.lblObjToEdit.ColorName = $scope.pickedColorCode;
            const labelInCard = _.find($scope.card.labels, cardLabel => {
              return (
                parseInt(cardLabel.labelID, 10) ===
                parseInt($scope.card.lblObjToEdit.pipelineSettingsLabelID, 10)
              );
            });
            if (labelInCard) {
              labelInCard.labelName = $scope.card.labelTitle;
              labelInCard.labelColor = $scope.card.lblObjToEdit.ColorName;
            }
          }
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.performAction = (lbl, index) => {
      $scope.result = $scope.isIncluded(lbl, index);

      if (!$scope.result) {
        $scope.setLabelForCard(lbl);
      } else {
        $scope.deleteLabelFromCard(lbl);
      }
    };
    $scope.isIncluded = lbl => {
      if ($scope.card && $scope.card.labels) {
        const isMatch = $scope.card.labels.filter(l => {
          return String(l.labelID) === String(lbl.pipelineSettingsLabelID);
        });
        return isMatch && isMatch.length;
      }
      return false;
    };
    $scope.deleteLabel = labelObject => {
      if (labelObject.pipelineSettingsLabelID) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObject.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
          () => {
            toaster.pop(
              'success',
              'Deleted',
              'Label has been deleted successfully.'
            );
            _.remove($scope.labels, label => {
              return (
                label.pipelineSettingsLabelID ===
                labelObject.pipelineSettingsLabelID
              );
            });
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
      }
    };
    $scope.setLabelForCard = labelObject => {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = $scope.card.PipelineItemId;
      $scope.pipelineCardsLabelSet.labelsID =
        labelObject.pipelineSettingsLabelID;
      $scope.pipelineCardsLabelSet.colorCode = labelObject.ColorName;

      if (_.isEmpty($scope.card.labels)) $scope.card.labels = [];
      $scope.card.labels.push({
        labelID: labelObject.pipelineSettingsLabelID,
        labelColor: labelObject.ColorName,
        labelName: labelObject.LabelName,
      });

      pipelineService.PipelineCardsLabelsSet($scope.pipelineCardsLabelSet).then(
        () => {
          toaster.pop(
            'success',
            'Added',
            'Label  has been added successfully.'
          );
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.editLabel = lblModal => {
      $scope.defineColors();
      const labelToTrue = pipelineSharedData.editLabel(lblModal, $scope.card);
      _.forEach(labelToTrue, labelKey => {
        $scope[labelKey] = true;
      });
    };
    $scope.deleteLabelFromCard = labelObject => {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = $scope.card.PipelineItemId;
      $scope.deleteLblFromCard.labelsID = labelObject.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(
        () => {
          _.remove($scope.card.labels, cardLabel => {
            return (
              parseInt(cardLabel.labelID, 10) ===
              parseInt(labelObject.pipelineSettingsLabelID, 10)
            );
          });
          toaster.pop(
            'success',
            'Deleted',
            'Label has been deleted from your list.'
          );
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };
  });
