import template from './quickProductFinderDetailModal.html';
import QuickProductFinderDetailModalCtrl from './quickProductFinderDetailModalCtrl';

export default {
  template,
  controller: QuickProductFinderDetailModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanStructure: '<',
    product: '<',
    productCount: '<',
    selectLoanProduct: '<',
  },
};
