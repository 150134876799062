import './style.scss';
import template from './surveyIframe.html';
import controller from './surveyIframeCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    props: '<',
  },
};
