class UsersReferralComponentCtrl {
  constructor(
    corporateService,
    userService,
    pepperService,
    toaster,
    loanAppDetailsService
  ) {
    'ngInject';

    this.loanAppDetailsService = loanAppDetailsService;
    this.corporateService = corporateService;
    this.userService = userService;
    this.pepperService = pepperService;
    this.toaster = toaster;
  }
  $onInit() {
    this.getUserInfo();
    this.displayHomeNow = this.loanAppDetailsService.isHomeNowEnabled();
  }
  getBrokerSupport(familyId, clientId) {
    this.corporateService
      .familyBrokerSupportGet(familyId, clientId)
      .then(response => {
        this.brokerSupport = response.data;
      });
  }
  getUserInfo() {
    this.userService.GetUserInfo().then(response => {
      if (response.data) {
        this.userInfo = response.data;
        this.familyId = this.userInfo.FamilyId;
        this.clientId = 0;
        this.getBrokerSupport(this.userInfo.FamilyId, this.clientId);
      }
    });
  }
  updateFieldValue(brokerSupportObj) {
    this.corporateService
      .familyBrokerSupportSet(this.familyId, this.clientId, brokerSupportObj)
      .then(() => {
        this.toaster.pop('success', 'Update', 'successfully updated!');
      });
  }
  updatePepperOptOut(optOut = false) {
    if (!this.familyId) return;
    this.pepperService.optOut(this.familyId, optOut).then(() => {
      this.toaster.pop('success', 'Update', 'successfully updated!');
    });
  }
}

export default UsersReferralComponentCtrl;
