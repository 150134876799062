import { parseIntValue } from 'Common/utilities/parse';
import { lengthOfTime } from 'Common/utilities/lengthOfTime';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import { getDatePickerConfig } from 'Common/utilities/date';
import {
  formatCustomAddress,
  getContactLivingIn,
} from 'Common/utilities/addressHistory';
import { toastError } from 'Common/utilities/alert';
import moment from 'moment';
import toastr from 'toastr';

const defaultLengthOfTime = '0 months';
const defaultOwnerId = -1;

export default class AddressHistoryModalCtrl {
  constructor(
    $q,
    optionsService,
    contactService,
    dashboardService,
    broadcastDataService,
    addressHistoryService,
    currentUserService
  ) {
    'ngInject';

    this.$q = $q;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.dashboardService = dashboardService;
    this.broadcastDataService = broadcastDataService;
    this.addressHistoryService = addressHistoryService;
  }

  $onInit() {
    this.isLoanApp = !!this.loanAppId;
    this.isNew = this.modalType === 'new';
    this.modalTitle = `${this.isNew ? 'Add' : 'Edit'} an Address`;
    this.ADDRESS_TYPE = ADDRESS_TYPE;
    this.isSaveDisabled = true;
    this.dateConfig = getDatePickerConfig();
    this.occupancyLengthOfTime = defaultLengthOfTime;
    this.datePopUp = {
      StartDate: false,
      EndDate: false,
    };

    if (!this.propertySet && this.isNew) {
      this.propertySet = {
        EstimatedValue: 0,
        Address: { AddressID: '0' },
        PropertyName: '',
        LocationDesc: '',
        Ownership: [],
        OwnershipTypeId: 0,
        ContactLivingIn: [],
        FloorNumber: '',
        DischargeDate: new Date(),
        PurchaseDate: new Date(),
        StartDate: null,
        EndDate: null,
        FullAddress: { formatted_address: '' },
      };
    } else if (this.propertySet && !this.isNew) {
      this.propertySet.Address = {
        formatted_address: this.propertySet.formatted_address,
        geoCoded: this.propertySet.geoCoded,
        latitude: this.propertySet.latitude,
        longitude: this.propertySet.longitude,
      };
      formatCustomAddress(this.propertySet);
      this.propertySet.FullAddress = { ...this.propertySet.Address };

      this.propertySet.StartDate = this.propertySet.StartDate
        ? new Date(this.propertySet.StartDate)
        : null;
      this.propertySet.EndDate = this.propertySet.EndDate
        ? new Date(this.propertySet.EndDate)
        : null;
      this.onAddressTypeChange();
      this.onOwnershipTypeChange();
    }

    this.$q
      .all([
        this.getAddressTypes(),
        this.getOwnershipType(),
        this.getOwners(),
        this.getFinancialFrequency(),
      ])
      .then(() => {
        this.isSaveDisabled = false;
      });
  }

  close() {
    const closeParam = this.isLoanApp ? { FamilyId: this.familyId } : null;
    this.modalInstance.close(closeParam);
  }

  dismiss() {
    if (this.isAddAnotherTriggered) {
      this.close();
    } else {
      this.modalInstance.dismiss();
    }
  }

  getAddressTypes() {
    return this.optionsService.addressTypeGet().then(({ data }) => {
      this.addressTypeList = data || [];
      if (!this.isNew && !this.propertySet.TypeId) {
        this.propertySet.TypeId = (data && data[0] && data[0].Value) || null;
      }
    });
  }

  getOwners() {
    this.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>No Owner Selected</div>",
    };
    this.ownersList = [];
    this.ownersListSelected = [];

    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      const dataContent =
        (data &&
          data.filter(o => o.Role === 'Adult' || o.Role === 'Guarantor')) ||
        [];
      const defaultOption = {
        ClientEntityId: defaultOwnerId,
        PersonId: defaultOwnerId,
        FirstName: 'All',
        LastName: 'Owners',
        Role: 'Adult',
        ticked: this.isNew,
        PreferredName: 'All Owners',
      };
      const ownersList = [{ ...defaultOption }, ...dataContent];

      if (!this.isNew) {
        this.propertySet.ContactLivingIn.forEach(value => {
          const selectedOwners = {
            ClientEntityId: value.BorrowerID,
            FirstName: value.FirstName,
            LastName: value.LastName,
            PersonId: value.BorrowerID,
            PreferredName:
              (`${value.FirstName} ` || '') + (value.LastName || ''),
            Role: 'Adult',
            ticked: true,
          };
          this.ownersListSelected.push(selectedOwners);
        });

        ownersList.map(value => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!this.ownersListSelected.find(
            obj => +obj.ClientEntityId === +value.ClientEntityId
          );
          return value;
        });
      } else {
        ownersList.map(value => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!value.ticked;
          return value;
        });
        this.ownersListSelected.push(defaultOption);
      }
      this.ownersList = ownersList;
    });
  }

  getOwnershipType() {
    return this.optionsService.ownershipTypeGet().then(({ data }) => {
      this.ownershipTypeList = parseIntValue(data) || [];
    });
  }

  getFinancialFrequency() {
    return this.optionsService.FinancialFrequency().then(({ data }) => {
      this.listRentalFrequency = parseIntValue(data) || [];
    });
  }

  onOwnerSelect(client) {
    if (!client.ticked) return;
    if (client.PersonId === defaultOwnerId) {
      this.ownersList.map(value => {
        value.ticked = value.PersonId === defaultOwnerId;
        return value;
      });
    } else {
      this.ownersList.map(value => {
        value.ticked = value.PersonId !== defaultOwnerId ? value.ticked : false;
        return value;
      });
    }
  }

  onSelectAllOwners() {
    this.ownersList.map(owner => {
      owner.ticked = owner.ClientEntityId === defaultOwnerId;
      return owner;
    });
  }

  onOwnershipTypeChange() {
    if (!this.propertySet.OwnershipTypeId) {
      this.isWithRental = false;
      return;
    }

    this.isWithRental =
      this.broadcastDataService.addressWithRentalIds.indexOf(
        this.propertySet.OwnershipTypeId.toString()
      ) !== -1;
  }

  onAddressTypeChange() {
    this.getLengthOfTime();
  }

  onOpenDate(dateField) {
    this.datePopUp[dateField] = !this.datePopUp[dateField];
  }

  getLengthOfTime() {
    if (!this.propertySet.EndDate && !this.propertySet.StartDate) {
      this.occupancyLengthOfTime = defaultLengthOfTime;
      return;
    }

    const isCurrentAddress =
      +this.propertySet.TypeId === +ADDRESS_TYPE.CURRENT_ADDRESS ||
      !this.propertySet.EndDate;
    const start = isCurrentAddress
      ? moment()
      : moment(this.propertySet.EndDate);
    const end = moment(this.propertySet.StartDate);

    this.occupancyLengthOfTime = lengthOfTime(start, end);
  }

  setSaveAndAddAnotherTitle() {
    this.modalTitle = `Add Another Address`;
  }

  saveSuccess(isSaveOnly) {
    if (isSaveOnly) {
      this.close();
    } else {
      this.propertySet.Address = {};
      this.isSaveDisabled = false;
      this.isAddAnotherTriggered = true;
      this.setSaveAndAddAnotherTitle();
    }
  }

  save(form, isSaveOnly) {
    if (this.isSaveDisabled) return;

    if (!form.$valid) {
      toastr.warning('Please fill in all fields', 'Required fields');
      return;
    }

    if (
      !this.ownersListSelected ||
      !this.ownersListSelected.length ||
      !this.ownersListSelected[0]
    ) {
      toastr.error('No owners found', 'Owners');
      return;
    }

    this.isSaveDisabled = true;
    if (this.ownersListSelected[0].ClientEntityId === defaultOwnerId) {
      this.ownersList.forEach(data => {
        if (data.ClientEntityId === defaultOwnerId) return;

        this.propertySet.Ownership && this.propertySet.Ownership.push(data);
        this.propertySet.ContactLivingIn.push(getContactLivingIn(data));
      });
    } else {
      const contactsLivingIn = [];
      this.ownersListSelected.forEach(data => {
        this.propertySet.Ownership && this.propertySet.Ownership.push(data);
        contactsLivingIn.push(getContactLivingIn(data));
      });
      this.propertySet.ContactLivingIn = contactsLivingIn;
    }

    this.propertySet.FullAddress = {};
    this.propertySet.FullAddress.OwnershipTypeId =
      this.propertySet.OwnershipTypeId || '';

    const setAddressMethod = this.isLoanApp
      ? 'setLoanAppAddress'
      : 'setContactAddress';
    if (this.isNew) {
      formatCustomAddress(this.propertySet);
      /**
       * the user might be adding a po box address
       * */
      if (
        !Object.prototype.hasOwnProperty.call(
          this.propertySet.FullAddress,
          'formatted_address'
        )
      ) {
        const fullAddressObject = {
          OwnershipTypeId: this.propertySet.OwnershipTypeId,
          formatted_address: this.propertySet.Address
            ? this.propertySet.Address.formatted_address
            : '',
          geoCoded: false,
        };
        this.propertySet.FullAddress = {
          ...this.propertySet.FullAddress,
          ...fullAddressObject,
        };
      }
      this.addressHistoryService[setAddressMethod](
        this.propertySet,
        this.familyId
      )
        .then(() => {
          toastr.success('New address successfully added', 'Added');

          this.saveSuccess(isSaveOnly);
        })
        .catch(e => toastError(e));
    } else {
      formatCustomAddress(this.propertySet);
      this.propertySet.FullAddress = { ...this.propertySet.Address };
      this.propertySet.FullAddress.AddressValuationExtendedDetailsId = this.propertySet.AddressValuationExtendedDetailsId;
      this.addressHistoryService[setAddressMethod](
        this.propertySet,
        this.familyId
      )
        .then(() => {
          toastr.success('Address successfully edited', 'Edited');
          this.close();
        })
        .catch(e => toastError(e));
    }
  }
}
