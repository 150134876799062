import template from './lmiGoalType.html';
import LmiGoalTypeCtrl from './lmiGoalTypeCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    mortgageObj: '=',
  },
  controller: LmiGoalTypeCtrl,
  controllerAs: 'vm',
};
