import angular from 'angular';

angular
  .module('app')
  .controller(
    'ForAddLoanStructureModalCtrl',
    function ForAddLoanStructureModalCtrl(
      $scope,
      $uibModalInstance,
      loanProfilerService,
      saveLoanStructure,
      mode,
      loanStructure,
      $state
    ) {
      $scope.brokerEventId = $state.params.brokerEventId;
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.mode = mode;
      loanProfilerService.getProductType().then(response => {
        $scope.productTypeList = response.data;
      });
      loanProfilerService.getRateTypes().then(response => {
        $scope.rateTypesList = response.data;
      });

      if (mode === 'Add') {
        $scope.loanStructure = {
          LoanStructureID: Math.random(),
          ProductType: '',
          RateType: '',
          LoanAmount: '',
          IsEditMode: false,
        };
      } else {
        $scope.loanStructure = loanStructure;
        loanStructure.IsEditMode = true;
      }
      $scope.saveLoanStructure = function(savedLoanStructure) {
        savedLoanStructure.BrokerEventID = $scope.brokerEventId;
        const $result = saveLoanStructure(savedLoanStructure);
        if (typeof $result === 'undefined') {
          $scope.cancel();
        }
      };
    }
  );
