export default class InsuranceSummaryOfCoverService {
  constructor($uibModal) {
    'ngInject';

    this.createInsuranceAppModal = $uibModal;
  }

  openSummaryOfCoverModal(profilerData) {
    const props = {
      profilerData,
    };
    return this.createInsuranceAppModal.open({
      animation: true,
      template: `
        <insurance-summary-of-cover-modal
          modal-instance="vm.modalInstance"
          profiler-data="vm.props.profilerData"
          family-id="vm.props.familyId">
        </insurance-summary-of-cover-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'insurance-summary-of-cover',
      size: 'sm',
      resolve: {
        props: () => props,
      },
    });
  }
}
