import toastr from 'toastr';

export function getCalculatedAmount(baseAmount, percentage) {
  return baseAmount ? (baseAmount * percentage) / 100 : 0;
}

export function getCalculatedPercentage(baseAmount, amount) {
  const allValuesValid = baseAmount && baseAmount !== '0' && amount;
  return allValuesValid ? (amount / baseAmount) * 100 : 0;
}

export function validateRange(value, min, max) {
  if (value < min) {
    toastr.error(`Value should not be less than ${min}`, 'Error');
    return min;
  }

  if (value > max) {
    toastr.error(`Value should not be greater than ${max}`, 'Error');
    return max;
  }

  return value;
}
