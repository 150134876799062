export const INCOME_GROUP = {
  ADDBACK: '1',
  INVESTMENT_INCOME: '2',
  GOVERMENT_PAYMENTS: '3',
  OTHER: '4',
  SUPPERANNUATION: '5',
  BONUS_COMMISSION: '6',
  ALLOWANCE: '7',
  REGULAR_OVERTIME: '8',
  SALARY_WAGES: '9',
  TAX: '10',
};
