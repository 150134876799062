export default class BouncingTooltipService {
  $onInit() {
    this.resetTooltipVisibility();
  }

  setTooltipVisible(isVisible) {
    if (this.wasSetToTrue) {
      this.isTooltipVisible = false;
      return;
    }
    this.isTooltipVisible = isVisible;
    this.showElement = isVisible;
    if (isVisible) this.wasSetToTrue = true;
  }

  resetTooltipVisibility() {
    this.isTooltipVisible = false;
    this.wasSetToTrue = false;
    this.showElement = false;
  }
}
