class imagePreviewThumbCtrl {
  constructor(generalService) {
    'ngInject';

    this.generalService = generalService;
  }

  getThumb() {
    if (!this.item.DocumentId || this.item.isGettingThumbnail) {
      return;
    }

    if (!this.item.PageNumber) {
      this.item.PageNumber = 1;
    }

    const data = {
      documentId: this.item.DocumentId,
      pageNumber: this.item.PageNumber,
    };
    this.item.isGettingThumbnail = true;
    this.generalService.documentThumbnail(data).then(
      response => {
        this.item.ThumbNail = response.data;
        this.item.isGettingThumbnail = false;
      },
      () => {
        this.item.isGettingThumbnail = false;
      }
    );
  }

  $onInit() {
    if (!this.item.ThumbNail) {
      this.item.ThumbNail = null;
    }
  }
}

export default imagePreviewThumbCtrl;
