import template from './reviewFactFind.html';
import controller from './reviewFactFindCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
  },
};
