/* Added by Ellen */
import angular from 'angular';

angular.module('app').directive('mycrmSubTab', function mycrmSubTab() {
  const directive = {
    restrict: 'E' /* element  */,
    link: () => {},
    scope: {
      subMenuVal: '@',
      isCorpTeamValue: '=',
      editModeObj: '=',
    },
    controller: '@' /* specify the controller is an attribute */,
    name: 'ctrlName',
    templateUrl(element, attrs) {
      return attrs.tplUrl;
    },
    replace: true,
  };

  return directive;
  /**
   * Access: Private
   * Function: Link
   *
   * Directive Function
   *
   * Parameter(s):
   *
   *     (Object) $scope - Angular $scope object.
   *     (Object) element - jqLite-wrapped element that this directive matches.
   *     (Object) attrs - hash object with key-value pairs of normalized attribute names
   *                      and their corresponding attribute values.
   */
});
