// This directive is customize to let the user input only
// one zero in the beginning. This is required in postal code
// By DatzMe101
import angular from 'angular';

angular.module('app').directive('numberPostal', function numberPostal() {
  return {
    require: 'ngModel',
    link(scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          const transformedInput = text.replace(/[^0-9]/g, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }
          element.bind('keypress', event => {
            if (event.keyCode === 48 && Number(ngModelCtrl.$modelValue) === 0) {
              event.preventDefault();
            }
          });
          return transformedInput;
        }
        return undefined;
      }
      ngModelCtrl.$parsers.push(fromUser);
    },
  };
});
