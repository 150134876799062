import angular from 'angular';

angular
  .module('app')
  .controller('BrokerViewBrokerSupporCtrl', function BrokerViewBrokerSupporCtrl(
    $scope,
    $state,
    corporateService,
    brokerSupportService,
    dashboardService,
    $stateParams,
    $filter,
    toaster
  ) {
    $filter('lowercase')();

    $scope.brokerInfo = [];
    const ticketId = $stateParams.ticketId;
    $scope.commentList = [];
    $scope.created = $stateParams.create;
    $scope.requester = [];

    const getUserFullName = () => {
      dashboardService.getBrokerBasicInfo().then(response => {
        $scope.brokerInfo = response.data;
      });
    };

    const getZendeskTicketComments = () => {
      brokerSupportService.getZendeskTicketComments(ticketId).then(response => {
        $scope.commentList = response.data;
      });
    };

    getUserFullName();
    getZendeskTicketComments();

    const initialization = () => {
      brokerSupportService.brokerSupportRequestGet(ticketId).then(response => {
        $scope.requester = response.data;
      });
    };

    initialization();

    const setZendeskTicketComment = () => {
      brokerSupportService
        .setZendeskTicketComment(ticketId, $scope.message)
        .then(response => {
          if (response.data > 0) {
            $scope.message = '';
            getZendeskTicketComments();
            toaster.pop(
              'success',
              'Replied',
              'Your quick reply already added to the conversation'
            );
          } else {
            toaster.pop(
              'error',
              'Reply Failed',
              'There is an issue regarding the sending of reply. Please try again.'
            );
          }
          $scope.isSending = false;
        });
    };

    $scope.isSending = false;
    $scope.quickReply = () => {
      $scope.isSending = true;
      setZendeskTicketComment();
    };
  });
