class MergeFieldsSelectionCtrl {
  constructor(corporateService, templateService) {
    'ngInject';

    this.corporateService = corporateService;
    this.templateService = templateService;
  }

  $onInit() {
    this.getMergeFields();
  }

  getMergeFields() {
    this.corporateService.getMergeFields(this.type === 'sms').then(response => {
      if (response.data && response.data.length) {
        this.mergeFieldList = response.data;
        this.mergeFields = response.data[0];
        this.populateMergeFieldsSelection();
      }
    });
  }

  populateMergeFieldsSelection() {
    if (this.mergeFields) {
      this.mergeValues = this.mergeFields.MergeFields;
    }
  }
}

export default MergeFieldsSelectionCtrl;
