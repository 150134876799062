import angular from 'angular';

angular.module('app').filter('propsFilter', function propsFilter() {
  return function(objs, props) {
    let out = [];

    if (angular.isArray(objs)) {
      const keys = Object.keys(props);

      objs.forEach(item => {
        let itemMatches = false;

        for (let i = 0; i < keys.length; i++) {
          const prop = keys[i];
          const text = props[prop].toLowerCase();
          if (item[prop]) {
            if (
              item[prop]
                .toString()
                .toLowerCase()
                .indexOf(text) !== -1
            ) {
              itemMatches = true;
              break;
            }
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = objs;
    }

    return out;
  };
});
