import { objectLength } from 'Common/utilities/objectValidation';
import { SURVEY_SKIP_TIME } from 'Common/constants/survey';

class SurveyModalCtrl {
  constructor(userService, crmConfirmation, $uibModal) {
    'ngInject';

    this.userService = userService;
    this.crmConfirmation = crmConfirmation;
    this.$uibModal = $uibModal;
  }
  $onInit() {
    this.isDisableAllButtons = true;
    this.isShowSetReminder = false;
    this.isShowCloseModal = false;
    this.isShowNotNow = true;
    this.selectedDismissalOption = '';
    this.surveyDetails = [];
    this.surveyOptionList = this.getSurveyDismissOptions();
    this.getUserSurvey();
  }
  closeModal() {
    this.modalInstance.close();
  }
  getUserSurvey() {
    this.userService.getUserSurvey().then(getSurveyReponse => {
      this.isDisableAllButtons = false;

      if (!getSurveyReponse || !getSurveyReponse.length) this.closeModal();

      this.surveyDetails = getSurveyReponse[0];
    });
  }

  putUserSurvey(data) {
    const { surveyId, startSurvey, dismissSurvey, hoursHidden } = data;
    this.userService.putUserSurvey(
      surveyId,
      startSurvey,
      dismissSurvey,
      hoursHidden
    );
  }
  getSurveyDismissOptions() {
    return this.userService.getSurveyDismissOptions();
  }

  toggleSection() {
    this.isShowCloseModal = this.isShowSetReminder
      ? false
      : this.isShowCloseModal;
    this.isShowSetReminder = !this.isShowSetReminder;
  }

  getPutUserSurveyModel() {
    if (!this.surveyDetails || !objectLength(this.surveyDetails)) return null;

    const { surveyId } = this.surveyDetails;

    return {
      surveyId,
      startSurvey: false,
      dismissSurvey: false,
      hoursHidden: 0,
    };
  }

  startSurvey() {
    this.isShowCloseModal = true;
    this.isDisableAllButtons = true;
    this.isShowNotNow = false;
    const postData = { ...this.getPutUserSurveyModel(), startSurvey: true };

    this.putUserSurvey(postData);
    this.closeModal();
    this.openSurvey(this.surveyDetails && this.surveyDetails.embeddedUrl);
  }
  openSurvey(surveyUrl = '') {
    const props = surveyUrl;

    this.$uibModal.open({
      template: `<survey-iframe props="vm.props" modal-instance="vm.modalInstance"></survey-iframe>`,
      controller: 'CommonModalPlaceholderCtrl',
      resolve: {
        props: () => props,
      },
      controllerAs: 'vm',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'survey-iframe',
    });
  }
  dissmissModal() {
    this.closeModal();

    const messageList = [
      {
        text: 'in 24 hours',
        dismissalOption: SURVEY_SKIP_TIME.TWENTYFOUR_HOURS,
      },
      {
        text: 'until next survey',
        dismissalOption: SURVEY_SKIP_TIME.UNTIL_NEXT_SURVEY,
      },
    ];
    const message = messageList.find(
      item => item.dismissalOption === this.selectedDismissalOption
    ).text;
    const postData = {
      ...this.getPutUserSurveyModel(),
      dismissSurvey: true,
      hoursHidden: this.selectedDismissalOption,
    };

    this.putUserSurvey(postData);
    this.crmConfirmation.open({
      type: 'success',
      title: 'Reminder Set',
      description: `We will remind you to take the survey again ${message}`,
      buttonText: 'Okay, got it!',
    });
  }
  isDisableDismissButton() {
    return (
      (!this.selectedDismissalOption && this.selectedDismissalOption === '') ||
      this.isDisableAllButtons
    );
  }
}

export default SurveyModalCtrl;
