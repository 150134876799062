import template from './assetFinanceVehicleModal.html';
import controller from './assetFinanceVehicleModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    asset: '<',
    brokerEventId: '<',
  },
};
