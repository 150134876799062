import angular from 'angular';

angular.module('app').directive('convertToNumber', function convertToNumber() {
  return {
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$parsers.push(val => {
        return val != null ? parseInt(val, 10) : null;
      });
      ngModel.$formatters.push(val => {
        const str = '';
        return val != null ? str + val : null;
      });
    },
  };
});
