import angular from 'angular';

class SendMailCtrl {
  constructor($stateParams, contactService, mailTemplateService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.familyId = this.$stateParams.familyId;

    this.contactService = contactService;
    this.mailTemplateService = mailTemplateService;
  }

  getClientInfo() {
    this.contactService
      .clientInformGet(this.familyId)
      .then(contactMailResponse => {
        const { data } = contactMailResponse;
        if (!data) return;

        if (data) {
          this.clientHeaderName = this.mailTemplateService.constructClientFullName(
            data
          );
          this.primaryClient = this.mailTemplateService.getPrimaryClientFrom(
            data
          );
        }
      });
  }

  $onInit() {
    this.getClientInfo();
  }
}

angular.module('app').controller('SendMailCtrl', SendMailCtrl);
