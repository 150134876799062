export function getAccountCheckBuilderForUI(data) {
  return {
    hasExistingAccount: data.HasExistingAccount,
    hasExistingSignature: data.HasExistingSignature,
    status: data.Status,
    displayName: data.DisplayName,
    redirectURL: data.RedirectURL,
  };
}
export function getSignDocumentBuilderForUI(data) {
  return {
    documents: data.Documents,
    signers: data.Signers,
  };
}

export function getDocumentsBuilderForUI(data) {
  return {
    status: data.Status,
    taggedDocumentType: data.TaggedDocumentType,
  };
}

export function getPackageDocumentsBuilderForUI(data) {
  return {
    dateCreated: data.DateCreated,
    documentName: data.DocumentName,
    eSignId: data.ESignId,
    eSignPackageId: data.ESignPackageId,
    loanApplicationId: data.LoanApplicationId,
    signedDocumentId: data.SignedDocumentId,
    status: data.Status,
    unsignedDocumentId: data.UnsignedDocumentId,
    userId: data.UserId,
  };
}

export function getPackagesBuilderForUI(data) {
  return {
    brokerId: data.BrokerId,
    dateSent: data.DateSent,
    loanApplicationId: data.LoanApplicationId,
    packageId: data.PackageId,
    name: data.PackageName,
    senderId: data.SenderId,
    status: data.Status,
    taggedDocumentType: data.TaggedDocumentType,
    documents:
      (data.Documents && data.Documents.map(getPackageDocumentsBuilderForUI)) ||
      [],
  };
}
