import { sum, flatten, head } from 'lodash';
import {
  processDates,
  getLoanCategory,
  processLoanPurpose,
  checkLender,
} from 'Common/utilities/loan';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';

class LoanService {
  constructor(
    $q,
    $state,
    contactSharedData,
    contactService,
    configService,
    utilitiesService,
    loanAppSharedData
  ) {
    'ngInject';

    this.configService = configService;
    this.contactSharedData = contactSharedData;
    this.contactService = contactService;
    this.utilitiesService = utilitiesService;
    this.loanAppSharedData = loanAppSharedData;
    this.$state = $state;
    this.$q = $q;
  }
  removeBorrowerApplicant(applicants, valueIndex, partyType) {
    const defer = this.$q.defer();

    if (partyType === 'guarantor') {
      applicants.guarantors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'solicitor') {
      applicants.solicitors.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'accountant') {
      applicants.accountants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else if (partyType === 'dependant') {
      applicants.dependants.splice(valueIndex, 1);
      defer.resolve({ data: applicants });
    } else {
      const borrowers = flatten(applicants.borrowers);
      const applicantCount = sum([
        applicants.entities.length,
        borrowers.length,
      ]);
      if (applicantCount > 1) {
        if (partyType === 'applicant') {
          applicants.borrowers.splice(valueIndex, 1);
        } else {
          applicants.entities.splice(valueIndex, 1);
        }
        defer.resolve({ data: applicants });
      } else {
        defer.reject();
      }
    }
    return defer.promise;
  }

  loanListGet(familyId) {
    return this.contactService.loanListGet(familyId, true).then(response => {
      const loanData = {};
      const { data } = response;
      const loans = {
        opportunityLoans: [],
        existingLoans: [],
        previousLoans: [],
        inprogressLoans: [],
      };

      data &&
        data.forEach(value => {
          processDates(value);
          value.hasLender = checkLender(value);
          const loanCategory = getLoanCategory(value, loans);
          const { LoanStatusID } = value.LoanStatus;

          if (loanCategory.isExisting) {
            loans.existingLoans.push(value);
          } else if (loanCategory.isInProgress) {
            loans.inprogressLoans.push(value);
          } else if (loanCategory.isOpportunity) {
            value.IsPrevious =
              LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH ===
              parseInt(LoanStatusID, 10);
            loans.opportunityLoans.push(value);
          } else if (loanCategory.isPrevious) {
            value.IsPrevious = true;
            loans.previousLoans.push(value);
          } else {
            loans.previousLoans.push(value);
          }

          if (value.LoanPurpose) processLoanPurpose(value);
          const family = head(value.FamilyList);
          if (family && family.AdviserFullName)
            family.Initials = this.utilitiesService.filterInitialOneString(
              family.AdviserFullName
            );
        });

      loanData.opportunityLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.opportunityLoans
      );
      loanData.existingLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.existingLoans
      );
      loanData.previousLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.previousLoans
      );
      loanData.inprogressLoans = this.loanAppSharedData.distinguishPartyForLendingTab(
        loans.inprogressLoans
      );
      return loanData;
    });
  }
}

export default LoanService;
