import angular from 'angular';

angular
  .module('app')
  .controller('OpenTaskMoveModalCtrl', function OpenTaskMoveModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    pipelineService,
    $sce,
    pipelineCardsID,
    pipelineNewStatusID,
    destStatus,
    fromStatus
  ) {
    pipelineService
      .PipelineCardsStatusActivityGet(pipelineCardsID, pipelineNewStatusID)
      .then(response => {
        $scope.activityList = response.data;

        $scope.taskNames = [];
        $scope.emailTemplateNames = [];
        $scope.fromStatus = fromStatus;
        $scope.destStatus = destStatus;

        for (let i = 0; i < $scope.activityList.length; i++) {
          if ($scope.activityList[i].ActivityName === 'Task Create') {
            $scope.task = {};
            $scope.activityNameTask = $scope.activityList[i].ActivityName;
            $scope.task.taskName = $scope.activityList[i].TaskName;
            $scope.task.ActivityID =
              $scope.activityList[i].PipelineSettingsActivityID;
            $scope.task.IsActivityStarted =
              $scope.activityList[i].IsActivityStarted;
            $scope.taskNames.push($scope.task);
          }
          if ($scope.activityList[i].ActivityName === 'Email') {
            $scope.emailTemp = {};
            $scope.activityNameEmail = $scope.activityList[i].ActivityName;
            $scope.emailTemp.emailTempName =
              $scope.activityList[i].EmailTemplateName;
            $scope.emailTemp.emailTempID =
              $scope.activityList[i].EmailTemplateID;
            $scope.emailTemp.ActivityID =
              $scope.activityList[i].PipelineSettingsActivityID;
            $scope.emailTemp.IsActivityStarted =
              $scope.activityList[i].IsActivityStarted;
            $scope.emailTemplateNames.push($scope.emailTemp);
          }
        }
      });
    $scope.showEmailTemplate = emailTempID => {
      $scope.emailTempContent = {};
      const emailTempId = emailTempID;

      const familyId = 0;
      contactService
        .getEmailTemplateContent(emailTempId, familyId)
        .then(response => {
          $scope.emailTempContent = response.data;
          $scope.test = $scope.emailTempContent.TemplateContent;
          $scope.content =
            '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
          $scope.getHtml = html => {
            return $sce.trustAsHtml(html);
          };
        });
    };

    $scope.proceed = () => {};
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
