import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import {
  revertMoveCardToOtherColumn,
  updateColumnTotalRecords,
  closeLabelPopover,
} from 'Common/utilities/kanbanHelper';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import { objectLength } from 'Common/utilities/objectValidation';

angular
  .module('app')
  .controller('PipelineCtrl', function PipelineCtrl(
    $scope,
    $rootScope,
    $http,
    $uibModal,
    SweetAlert,
    configService,
    $filter,
    dashboardService,
    imageDataURI,
    $timeout,
    $templateCache,
    overviewDashboardService,
    pipelineService,
    contactService,
    pipelineCardsService,
    $stateParams,
    $localStorage,
    toaster,
    moment,
    loanScenarioService,
    userService,
    communicateService,
    optionsService,
    DEMO_VIDEO,
    pipelineSharedData,
    utilitiesService,
    uiService,
    $window,
    $state,
    commonFnService,
    modalRenderService,
    enquirySourceService,
    opportunityNewModalService
  ) {
    $scope.getInitials = name => {
      return utilitiesService.filterInitialOneString(name);
    };
    let familyIDToPass = $state.params.familyId;
    if (!familyIDToPass) {
      familyIDToPass = 0;
    }

    $scope.hiddenClientForNz = false;
    commonFnService.hiddenClientForNz().then(response => {
      $scope.hiddenClientForNz = response;
    });

    const pageSize = 10;
    $scope.leadPipeLineMainObj = {};
    $scope.filterTouched = false;
    $scope.pipelineSharedData = pipelineSharedData;
    $scope.pipelineSharedData.isCategoriesLoading = true;
    $scope.pipelineSharedData.validateFilters();

    $scope.filterRange = PICKER_RANGE;
    $scope.displayFilterDate = '';
    $scope.activeRangeIndex = null;
    $scope.selectedRange = {
      isOpen: false,
    };

    $scope.formatDateFilter = () => {
      if (
        !$scope.pipelineSharedData ||
        !$scope.pipelineSharedData.leadFilterData
      )
        return;
      const {
        DateCreatedStart,
        DateCreatedEnd,
      } = $scope.pipelineSharedData.leadFilterData;
      if (!DateCreatedStart) return;

      if ($scope.activeRangeIndex === PICKER_RANGE.TODAY) {
        return `Today, ${moment(DateCreatedStart).format('DD MMM YYYY')}`;
      }
      return `From ${moment(DateCreatedStart).format('DD MMM')} to ${moment(
        DateCreatedEnd
      ).format('DD MMM YYYY')}`;
    };

    $scope.onRangeSelected = activeRangeIndex => {
      if (typeof activeRangeIndex === 'undefined') return; // filter only if undefined, can be null or zero
      $scope.activeRangeIndex = activeRangeIndex;
      if (activeRangeIndex !== PICKER_RANGE.CUSTOM)
        $scope.selectedRange.isOpen = false;
    };
    $scope.calendarUpdated = filterData => {
      if (!filterData) return;
      $scope.pipelineSharedData.leadFilterData.DateCreatedStart = filterData.DateCreatedStart
        ? filterData.DateCreatedStart
        : '';
      $scope.pipelineSharedData.leadFilterData.DateCreatedEnd = filterData.DateCreatedEnd
        ? filterData.DateCreatedEnd
        : '';
      $scope.getAllCards(true);
      $scope.displayFilterDate = $scope.formatDateFilter();
    };

    $scope.checkFilter = mode => {
      if (
        !$scope.pipelineSharedData ||
        !$scope.pipelineSharedData.leadFilterData ||
        !mode
      )
        return;
      const {
        PipelineStatusIDs: pipelineStatusIds,
        SubStatusID: subStatusId,
        adviserId,
        labelId,
        probability,
        enquiryId,
        referralId,
        DateCreatedStart: dateCreatedStart,
      } = $scope.pipelineSharedData.leadFilterData;

      const haveCardsFilter =
        subStatusId !== '0' ||
        adviserId ||
        labelId ||
        probability !== 'All' ||
        (enquiryId && enquiryId.ReferralItemId) ||
        referralId > 0 ||
        dateCreatedStart;
      const haveListViewFilter = pipelineStatusIds.length || haveCardsFilter;

      $scope.isShowListViewFilter = mode === 'list_view' && haveListViewFilter;
      $scope.showFilterForCards = mode !== 'list_view' && haveCardsFilter;
      $scope.displayFilterDate = $scope.formatDateFilter();
    };

    $scope.showColorPicker = () => {
      $scope.editLabel = !$scope.editLabel;
    };

    $scope.toggled = open => {
      $scope.hideParent = !!open;
    };
    $scope.back = () => {
      // Toggle dropdown if not already visible:
      if (
        $('.dropdown')
          .find('.parent')
          .is(':hidden')
      ) {
        $('.dropdown-toggle').dropdown('toggle');
      }
      $scope.hideParent = true;
    };

    $scope.colorPickerPopover = {
      content: '',
      templateUrl: 'colorPickerPopover.html',
      templateUrlForLabel: 'colorPickerPopoverForLabel.html',
      rightSide: 'right',
      bottomSide: 'bottom',
      title: '',
    };
    $('.pipelineContainer ').on('click', eve => {
      const popoverArea = $('.colorPickerPopoverWrapper');
      const popovers = angular.element('.popover.fade.in');
      const cardWrap = $window.document.querySelectorAll('.pipeline-wrap-card');
      const popoverParent = $(eve.target).closest('.popover');
      if (
        eve.target.classList.contains('add-card-label') ||
        eve.target.classList.contains('update-label-pipeline')
      ) {
        _.forEach(popoverArea, elm => {
          const eventTimeout = $timeout(() => {
            $(elm)
              .closest('.popover')
              .prev()
              .click();
            $timeout.cancel(eventTimeout);
          });
        });
        return false;
      }
      // will fix overlap issue and drap
      if (
        eve.target.classList.contains('add-pipeline-new-label') ||
        popoverParent.length > 0
      ) {
        _.forEach(cardWrap, obj => {
          obj.classList.add('position-static');
        });
        if (popovers.length > 1) {
          return false;
        }
      } else {
        _.forEach(cardWrap, obj => {
          obj.classList.remove('position-static');
        });
      }

      if (typeof $scope.kanbanBoard !== 'undefined') {
        if (!$scope.kanbanBoard && !$scope.kanbanBoard.columns) {
          return;
        }
        if (
          !popoverArea.is(eve.target) &&
          popoverArea.has(eve.target).length === 0
        ) {
          for (let i = 0; i < $scope.kanbanBoard.columns.length; i++) {
            for (
              let j = 0;
              j < $scope.kanbanBoard.columns[i].cards.length;
              j++
            ) {
              if ($scope.kanbanBoard.columns[i].cards[j].textOpen)
                $scope.kanbanBoard.columns[i].cards[j].textOpen = false;

              if (
                typeof $scope.kanbanBoard.columns[i].cards[j].labels !==
                'undefined'
              ) {
                for (
                  let k = 0;
                  k < $scope.kanbanBoard.columns[i].cards[j].labels.length;
                  k++
                ) {
                  if ($scope.kanbanBoard.columns[i].cards[j].labels[k].textOpen)
                    $scope.kanbanBoard.columns[i].cards[j].labels[
                      k
                    ].textOpen = false;
                }
              }
            }
          }
        }
      }
    });
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = size => {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'ContactOpenModalForDemoVideoCtrl',
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.pipelineTypes = [];
    $scope.selectedPipelineType = 1;
    $rootScope.dateRangeFilter = '12Months';
    $scope.toDate = moment().format('DD MMM YYYY');

    $scope.populateReasonList = () => {
      $scope.lastTwoSubstatusesArr = [];
      pipelineService.settingsReasonGet().then(response => {
        if (response.data) {
          $scope.reasonList = response.data;
          _.forEach($scope.reasonList, object => {
            $scope.lastTwoSubstatusesArr.push(object.PipelineSubStatusID);
          });
        }
      });
    };

    $scope.changeDateRange = () => {
      if ($rootScope.dateRangeFilter === '1Month') {
        $scope.fromDate = moment()
          .subtract(1, 'months')
          .format('DD MMM YYYY');
      } else if ($rootScope.dateRangeFilter === '3Months') {
        $scope.fromDate = moment()
          .subtract(3, 'months')
          .format('DD MMM YYYY');
      } else if ($rootScope.dateRangeFilter === '6Months') {
        $scope.fromDate = moment()
          .subtract(6, 'months')
          .format('DD MMM YYYY');
      } else if ($rootScope.dateRangeFilter === '12Months') {
        $scope.fromDate = moment()
          .subtract(1, 'year')
          .format('DD MMM YYYY');
      }
      pipelineService
        .getPipelineCardsSummary($scope.fromDate, $scope.toDate, 0)
        .then(response => {
          $scope.statePer = response.data;
        });
    };

    pipelineService.PipelineTypeGet().then(response => {
      if (response && response.data) {
        $scope.pipelineTypes = pipelineSharedData.pipelineTypeGet(
          response.data
        );
      }
    });

    $scope.selectType = PipelineId => {
      $timeout(() => {
        const el = $window.document.querySelectorAll(
          `#tab-${PipelineId} .nav-link`
        );
        angular.element(el).triggerHandler('click');
      }, 0);
      $scope.selectedPipelineType = PipelineId;
    };

    // Change Show State
    $scope.changeShowState = showState => {
      $scope.cardViewStates.isShowState = showState;
    };

    $scope.switchSetting = {
      isShowStateSwitch: false,
    };
    $scope.$watch(
      'switchSetting.isShowStateSwitch',
      nv => {
        $scope.cardViewStates.isShowState = nv;
      },
      true
    );

    // Change List View Filter
    $scope.showFilter = (displayMode, pipelineType) => {
      if (pipelineType === 'Leads') {
        if (displayMode === 'list_view') {
          $scope.isShowListViewFilter = !$scope.isShowListViewFilter;
        } else {
          $scope.showFilterForCards = !$scope.showFilterForCards;
        }
      } else if (displayMode === 'list_view') {
        $rootScope.isShowListViewFilterApp = !$rootScope.isShowListViewFilterApp;
      } else {
        $scope.showFilterForCardsApp = !$scope.showFilterForCardsApp;
      }
    };

    $scope.showMode = mode => {
      $localStorage.pipelineLeadView = mode;
      $scope.currentMode = mode;
      $scope.isShowListViewFilter = false;
      $rootScope.isShowListViewFilterApp = false;
      if (mode !== 'cards') {
        $scope.isLoadingCardCategories = false;
      }
      pipelineSharedData.isFirstLoad = true;

      const isTableView = mode === 'list_view';
      const filters = pipelineSharedData.getSortingForSet(true, isTableView);

      pipelineService.setPipelineCardFilters(filters).then(() => {
        if (mode === 'list_view') {
          $scope.initListView();
        } else {
          $scope._cardInit();
          pipelineSharedData.isCategoriesLoading = false;
        }

        $scope.DisplayMode = mode;
        if (!$scope.isListViewInitialized) {
          $scope.isListViewInitialized = $scope.DisplayMode === 'list_view';
        }
        if (!$scope.isCardViewInitialized && $scope.DisplayMode === 'cards') {
          $scope.isCardViewInitialized = true;
          $scope.populateReasonList();
        }
      });

      $scope.showFilterForCards = false;
      $scope.showFilterForCardsApp = false;

      if (mode === 'cards') {
        $timeout(() => {
          let el = $window.document.querySelectorAll('#board.cards');
          angular.element(el).removeClass('hidden');

          el = $window.document.querySelectorAll('#table.list_view');
          angular.element(el).addClass('hidden');
        }, 0);
      } else if (mode === 'list_view') {
        $timeout(() => {
          let el = $window.document.querySelectorAll('#board.cards');
          angular.element(el).addClass('hidden');

          el = $window.document.querySelectorAll('#table.list_view');
          angular.element(el).removeClass('hidden');
          $scope.cardViewStates.isShowState = false;
        }, 0);
      } else {
        $timeout(() => {
          let el = $window.document.querySelectorAll('#board.cards');
          angular.element(el).addClass('hidden');

          el = $window.document.querySelectorAll('#table.list_view');
          angular.element(el).addClass('hidden');
        }, 0);
      }

      const container = $window.document.querySelectorAll('.pipelineContainer');
      angular.element(container).removeClass('list_view');
      angular.element(container).removeClass('cards');
      angular.element(container).addClass(mode);
      $scope.checkFilter(mode);
    };

    // Make Abbreviation of Adviser
    $scope.filterInitial = (...args) => {
      let i;
      let initials = '';
      if (!args || args[0] === undefined) {
        return;
      }
      for (i = 0; i < args.length; i++) {
        if (!_.isNil(args[i]) && _.isString(args[i])) {
          initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };
    // Manage Settings
    // getLeadStatus
    $scope.getAllLeadStatus = callback => {
      pipelineService.SettingsStatusGet(false).then(
        response => {
          $scope.statusList = response.data;
          $scope.leadsStatusList = [];
          for (let i = 0; i < $scope.statusList.length; i++) {
            if ($scope.statusList[i].TypeName === 'Lead') {
              $scope.leadsStatusList.push($scope.statusList[i]);
            }
          }

          const allStatus = {
            SubStatus: [{ pipelineSubStatusID: 0, SubStatusName: 'All' }],
          };
          $scope.statusDataList = [allStatus, ...response.data];

          if (typeof callback !== 'undefined') {
            callback();
          }
        },
        () => {}
      );
    };

    function getEnquirySourceForFilter(enquirySources) {
      $scope.enquirySourceList = [...enquirySources];
      $scope.enquirySourceList.splice(0, 0, {
        ReferralItemId: 0,
        ReferralItemName: 'All',
        ReferralCategoryId: 0,
        ReferralCategoryName: '',
      });
      enquirySourceService.selectEnquiry(
        $scope.pipelineSharedData,
        $scope.enquirySourceList
      );
    }
    function getReferralListForFilter(referralList) {
      $scope.referralListCopy = angular.copy(referralList);
      $scope.referralListCopy.splice(0, 0, {
        ReferralMemberId: -1,
        ReferralOrganizationName: 'All',
      });
      if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
        if ($scope.referralListCopy.length > 0) {
          $scope.pipelineSharedData.leadFilterData.referralId =
            $scope.referralListCopy[0].ReferralMemberId;
        }
      }

      _.forEach($scope.referralListCopy, o => {
        if (o.ReferralOrganizationName !== 'All') {
          o.ReferralOrganizationName +=
            (o.ReferralOrganizationName !== '' ? ' - ' : '') +
            o.ReferralMemberName;
        }
      });
    }

    function getAdvisersForFilter(adviserList) {
      $scope.taskAdviserList = pipelineSharedData.getAdvisersForFilter(
        adviserList
      );
    }

    function getLabelsForFilter(labels, callback) {
      $scope.labelsList = pipelineSharedData.getLabels(labels);
      $scope.pipelineSharedData.leadFilterData = pipelineSharedData.getFilterWithLabel(
        $scope.labelsList
      );
      if (_.isFunction(callback)) callback();
    }

    function getProbabilitiesForFilter(probabilityList) {
      $scope.probabilityListforFilter = angular.copy(probabilityList);
      $scope.probabilityListforFilter.splice(0, 0, {
        probabilityId: 0,
        probability: 'All',
      });
      if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
        if ($scope.probabilityListforFilter.length > 0) {
          $scope.pipelineSharedData.leadFilterData.probability = angular.copy(
            $scope.probabilityListforFilter[0].probability
          );
        }
      }
    }
    $scope.getAllEnquirySource = () => {
      optionsService.getEnquirySourceList().then(response => {
        const isValidResponse =
          response && response.data && response.data.length;
        if (!isValidResponse) {
          $scope.enquirySourceList = [];
          $scope.enquiryList = [];
          return;
        }
        $scope.enquiryList = response.data;
        $scope.enquirySourceList = enquirySourceService.getEnquirySourceListData(
          response.data
        );
        getEnquirySourceForFilter($scope.enquirySourceList);
      });
    };

    $scope.getAllReferrals = () => {
      optionsService.getReferencedReferralList().then(response => {
        $scope.referralList = response.data;
        getReferralListForFilter($scope.referralList);
      });
    };
    // getAllAdvisers
    $scope.adviserList = [];
    $scope.getAllAdvisers = () => {
      pipelineSharedData.getAdvisersListData().then(adviserList => {
        $scope.adviserList = adviserList;
        getAdvisersForFilter($scope.adviserList);
      });
    };

    // getAllLabels
    const getLabels = $rootScope.$on('getAllLabels', () => {
      $scope.getAllLabels(() => {});
    });
    $scope.$on('$destroy', getLabels);
    $scope.checkLabelExistInList = label => {
      const findLabelPipeline = _.find($scope.labels, o => {
        return String(o.pipelineSettingsLabelID) === String(label.labelID);
      });

      if (_.size(findLabelPipeline) > 0) {
        label.labelColor = findLabelPipeline.ColorName;
        return true;
      }
      return false;
    };
    $scope.getAllLabels = callback => {
      pipelineService.PipelineSettingsLabel().then(
        response => {
          $scope.labels = response.data;
          getLabelsForFilter($scope.labels, callback);
        },
        () => {
          $scope.isLoadingCardCategories = false;
        }
      );
    };
    $scope.getLabelColors = () => {
      $scope.labelColors = [
        {
          colorId: 1,
          colorCode: '#f78171',
        },
        {
          colorId: 2,
          colorCode: '#ffa53c',
        },
        {
          colorId: 3,
          colorCode: '#fcdd1c',
        },
        {
          colorId: 4,
          colorCode: '#9b4d84',
        },
        {
          colorId: 5,
          colorCode: '#027ec5',
        },
        {
          colorId: 6,
          colorCode: '#6b4d9b',
        },
        {
          colorId: 7,
          colorCode: '#25d1ec',
        },
        {
          colorId: 8,
          colorCode: '#5ecb49',
        },
      ];
    };
    $scope.getLabelColors();
    // getAllProbabilities
    $scope.getAllProbabilities = () => {
      $scope.probabilityList = [
        {
          probability: 'Hot',
          probabilityId: 1,
          probabilityColor: '#ec7777',
        },
        {
          probability: 'Warm',
          probabilityId: 2,
          probabilityColor: '#fa9c5d',
        },
        {
          probability: 'Cold',
          probabilityId: 3,
          probabilityColor: '#66a3cf',
        },
      ];
      getProbabilitiesForFilter($scope.probabilityList);
    };

    $scope.openAddCardModal = (size, familyId, subtool) => {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/pipeline/leads/partials/add_new_card.html',
        resolve: {
          leadsStatusList() {
            return $scope.leadsStatusList;
          },
          familyId: () => familyId,
          subtool: () => subtool,
        },
        controller: 'OpenAddCardModalCtrl',
        size,
        scope: $scope,
      });
      if (modalInstance && modalInstance.result) {
        modalInstance.result.then(newCard => {
          $scope.handleCardInserted(newCard);
        });
      }
    };

    $scope.handleCardInserted = newCard => {
      if (
        !newCard ||
        !$scope.kanbanColumns ||
        !$scope.leadsStatus ||
        !$scope.leadsCards
      )
        return;

      const cardCategory = $scope.leadsStatus.find(
        category => category.PipelineStatus === newCard.PipelineStatus
      );
      if (!cardCategory) return;

      const cardColumn = $scope.kanbanColumns.find(
        column => column.PipelineStatusID === cardCategory.PipelineStatusID
      );
      if (!cardColumn) return;

      if (!$scope.leadsCards) $scope.leadsCards = {};
      if (!$scope.leadsCards[cardColumn.PipelineStatusID]) {
        $scope.leadsCards[cardColumn.PipelineStatusID] = [];
      }

      if ($scope.leadsCards[cardColumn.PipelineStatusID]) {
        $scope.reloadColumn(cardColumn, false);
      } else {
        $scope.leadsCards[cardColumn.PipelineStatusID] = [newCard];
        newCard.LabelArray = pipelineCardsService.convertStrLabelsToObj(
          newCard
        );
        const kanbanCard = $scope.buildCardForBoard(newCard, cardCategory);
        cardColumn.cards = [kanbanCard];
      }
    };

    $scope.insertCardBySubStatus = (card, column) => {
      if (!card || !column) return;
      if (!column.cards) column.cards = [];

      const lastIndexOfCardWithStatus = _.findLastIndex(
        column.cards,
        colCards => colCards.SubStatusID === card.SubStatusID
      );
      if (lastIndexOfCardWithStatus !== -1) {
        const insertAtIndex = lastIndexOfCardWithStatus + 1;
        column.cards.splice(insertAtIndex, 0, card);
      } else {
        column.cards.push(card);
      }
    };

    $scope.userFamilyId = 0;
    $scope.userFullName = '';

    $scope.setColumnSort = (columnArgs, sortType, index) => {
      $timeout(() => {
        const element = angular.element(
          $window.document.querySelector(`#columnBody-${index}`)
        );
        const height = element[0].offsetHeight;

        if (height > $scope.kanbanBoardHeight) {
          columnArgs.minHeight = angular.copy($scope.kanbanBoardHeight);
        } else {
          columnArgs.minHeight = height;
        }
        columnArgs.loading = true;
      }, 0);

      if (sortType !== 'Reset') {
        columnArgs.SelectedFilterStatus = sortType;
        columnArgs.SelectedFilterStatusObjectify = `${
          columnArgs.name
        }:${sortType}`;
      } else {
        columnArgs.SelectedFilterStatus = '';
        columnArgs.SelectedFilterStatusObjectify = '';
      }

      let sortingMode = '';
      _.forEach($scope.kanbanBoard.columns, columnObject => {
        if (columnObject.SelectedFilterStatusObjectify) {
          sortingMode += `${columnObject.SelectedFilterStatusObjectify},`;
        }
      });

      if (sortingMode) {
        sortingMode = sortingMode.slice(0, -1);
      }

      // overwrite the sort string to be sort obj
      $scope.pipelineSharedData.leadFilterData.sortingMode = sortingMode;
      const sortingModeArray = sortingMode ? sortingMode.split(',') : [];
      pipelineSharedData.convertSortStrToObj(sortingModeArray);

      // empty the custom sort
      let CustomerSort = '';
      const CustomerSortObj = {};
      _.forEach(
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
        (columns, columnKey) => {
          if (_.size(columns) > 0 && columnKey !== columnArgs.name) {
            _.forEach(columns, item => {
              CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                item.position
              },`;
            });
            CustomerSortObj[columnKey] = columns;
          }
        }
      );
      if (CustomerSort !== '') {
        CustomerSort = CustomerSort.slice(0, -1);
      }
      $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;
      $scope.pipelineSharedData.leadFilterData.CustomerSortObj = CustomerSortObj;

      const isTableView = $scope.DisplayMode === 'list_view';
      const filters = pipelineSharedData.getSortingForSet(true, isTableView);
      filters.SortingMode = sortingMode;
      pipelineService.setPipelineCardFilters(filters).then(
        () => {
          $scope.reloadColumn(columnArgs);
        },
        () => {
          columnArgs.loading = false;
        }
      );
    };

    $scope.defaultSortSet = getCards => {
      const filters = pipelineSharedData.getSortingForSet(true, false);
      pipelineService.setPipelineCardFilters(filters).then(() => {
        if (getCards) {
          $scope.getPipelineCards();
        }
      });
    };

    $scope.changeIsCompact = () => {
      $scope.defaultSortSet(true);
    };

    $scope.filterAdviser = () => {
      $scope.filterTouched = true;
      $scope.getAllCards(true);
    };
    $scope.filterLabel = () => {
      $scope.getAllCards(true);
    };

    $scope.filterProbability = showLoading => {
      $scope.getAllCards(_.isBoolean(showLoading) && showLoading);
    };

    $scope.filterEnquiry = () => {
      $scope.pipelineSharedData.leadFilterData.ReferralItemID =
        $scope.pipelineSharedData.leadFilterData.enquiryId.ReferralItemId;
      $scope.pipelineSharedData.leadFilterData.ReferralCategoryID =
        $scope.pipelineSharedData.leadFilterData.enquiryId.ReferralCategoryId;
      $scope.getAllCards(
        $scope.pipelineSharedData &&
          $scope.pipelineSharedData.leadFilterData &&
          !_.isNull($scope.pipelineSharedData.leadFilterData.enquiryId) &&
          !_.isUndefined($scope.pipelineSharedData.leadFilterData.enquiryId)
      );
    };

    $scope.filterReferral = () => {
      $scope.getAllCards(true);
    };
    $scope.filterSubStatus = () => {
      $scope.getAllCards(true);
    };
    function setHeight() {
      // init variables
      const winHeight = $window.document.documentElement.clientHeight;
      const headerHeight = $window.document.querySelector(
        'header.navbar.navbar-default.navbar-static-top'
      )
        ? $window.document.querySelector(
            'header.navbar.navbar-default.navbar-static-top'
          ).offsetHeight
        : 55;
      const pipelinePanel = $window.document.querySelector('#pipeline-page');
      const mobileAddCard = $window.document.querySelector('.mobileAddCard');
      const mobileAddCardHeight = mobileAddCard ? 45 : 0;
      const pHeight = `${winHeight - headerHeight - 50}px`;

      // declare height to panel
      if (!_.isNull(pipelinePanel) && !_.isUndefined(pipelinePanel)) {
        pipelinePanel.style.setProperty('height', pHeight);

        const pipelineFilterWrapperHeight = $window.document.querySelector(
          '#pipelineFilterWrapper'
        )
          ? $window.document.querySelector('#pipelineFilterWrapper')
              .offsetHeight
          : 55;
        $scope.kanbanBoardHeight =
          winHeight -
          headerHeight -
          pipelineFilterWrapperHeight -
          mobileAddCardHeight -
          150;
      }
    }

    setHeight();
    $window.addEventListener('resize', setHeight);

    $scope.myParam = {};
    $scope.allCards = [];
    $scope.getPipelineCards = searchFilter => {
      const adviserId = parseInt(
        $scope.pipelineSharedData.leadFilterData.adviserId,
        10
      );
      if (uiService.isCorporateUser && !adviserId) {
        $scope.leadsCards = [];
        $scope.prepareCards($scope.leadsStatusList);
        $scope.leadsRefreshing = false;
        $scope.isLoadingCardCategories = false;
        return;
      }

      const isLeads = true;
      const pageNumber = 1;
      const params = { isLeads, pageNumber, pageSize };
      if (searchFilter) {
        params.searchClientName = searchFilter;
      }
      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          $scope.leadsCards = response.data;
          pipelineSharedData.formatCardLabelsForView($scope.leadsCards);
          $scope.prepareCards($scope.leadsStatusList);
          $scope.leadsRefreshing = false;
          $scope.isLoadingCardCategories = false;
        },
        () => {
          $scope.leadsRefreshing = false;
          $scope.isLoadingCardCategories = false;
        }
      );
    };

    $scope.getAllCards = showLoading => {
      let adviserId = $scope.pipelineSharedData.leadFilterData.adviserId;
      const isCompact = $scope.pipelineSharedData.leadFilterData.isCompact;
      const { isFirstLoad } = pipelineSharedData;

      $scope.isLoadingCardCategories =
        showLoading && $scope.DisplayMode === 'cards';

      userService.GetUserInfo().then(
        response => {
          if (response.data) {
            if (parseInt(response.data.AccessType, 10) === 5) {
              $rootScope.isCorporateUser = true;
            }

            if ($rootScope.isCorporateUser !== true && adviserId === 0) {
              dashboardService.getBrokerBasicInfo().then(() => {
                adviserId = response.data.BrokerId;
              });
            }

            if ($rootScope.isCorporateUser && adviserId === 0) {
              $scope.leadsCards = [];
              if (!$scope.leadsStatusList) {
                $scope.getAllLeadStatus(() => {
                  $scope.prepareCards($scope.leadsStatusList);
                });
              } else {
                $scope.prepareCards($scope.leadsStatusList);
              }
              $scope.defaultSortSet(false);
              $scope.leadsRefreshing = false;
              $scope.isLoadingCardCategories = false;
              pipelineSharedData.isFirstLoad = false;
              return false;
            }

            if (isFirstLoad) {
              $scope.getPipelineCards();
              pipelineSharedData.isFirstLoad = false;
            } else {
              const filters = pipelineSharedData.getSortingForSet(true, false);
              filters.AdvisorId = adviserId || 0;
              filters.IsCompact = isCompact === '1';
              pipelineService.setPipelineCardFilters(filters).then(
                () => {
                  $scope.getPipelineCards();
                },
                () => {
                  $scope.isLoadingCardCategories = false;
                }
              );
            }
          }
        },
        () => {
          $scope.isLoadingCardCategories = false;
        }
      );
    };

    $scope.updateStatusList = () => {
      $scope.kanbanColumns = _.map($scope.kanbanColumns, objCol => {
        // will check if it's new leads
        if (objCol.PipelineStatusID !== 4) {
          objCol.cards = _.map(objCol.cards, obj => {
            if (typeof obj.subStatuses !== 'undefined') {
              if (obj.subStatuses.length === 1) {
                obj.subStatuses = $scope.subStatusList;
              }
            }
            return obj;
          });
        } else {
          objCol.cards = _.map(objCol.cards, obj => {
            if (typeof obj.subStatuses !== 'undefined') {
              if (obj.subStatuses.length > 1) {
                if (obj.selectedSubStatus) {
                  obj.subStatuses = [obj.selectedSubStatus];
                }
              }
            }
            return obj;
          });
        }
        return objCol;
      });
    };
    function newKanbanBoard() {
      return {
        name: 'Kanban Board',
        numberOfColumns: 0,
        totalApplication: 0,
        totalNotProceeding: 0,
        totalApplicationPercentage: 0,
        totalNotProceedingPercentage: 0,
        columns: $scope.kanbanColumns,
        backlogs: [],
      };
    }
    $scope.prepareCards = leadsStatus => {
      if (!leadsStatus) return;

      $scope.mobileViewReady = false;
      $scope.leadsStatus = leadsStatus;
      $scope.kanbanColumns = [];
      $scope.count = 0;

      for (let i = 0; i < $scope.leadsStatus.length; i++) {
        $scope.column = {};
        $scope.column.CategoryName = $scope.leadsStatus[i].CategoryName;
        $scope.column.PipelineCategoryID =
          $scope.leadsStatus[i].PipelineCategoryID;
        $scope.column.name = $scope.leadsStatus[i].PipelineStatus;
        $scope.column.PipelineStatusID = $scope.leadsStatus[i].PipelineStatusID;
        $scope.column.cards = [];
        $scope.column.limitCount = 10;
        $scope.test = [];
        $scope.test = $scope.leadsCards;
        $scope.subStatusList = [];
        $scope.subStatusList = angular.copy($scope.leadsStatus[i].SubStatus);

        $scope.subStatusList.splice(0, 0, {
          pipelineSubStatusID: 0,
          StatusID: $scope.column.PipelineStatusID,
          SubStatusName: 'Please Select Status',
        });

        pipelineSharedData.setColumnSorting($scope.column);

        if (
          $scope.leadsCards &&
          $scope.leadsCards[$scope.column.PipelineStatusID]
        ) {
          $scope.statusWiseCards = $scope.leadsCards[
            $scope.column.PipelineStatusID
          ].map(card => {
            const category = $scope.leadsStatus[i];
            return $scope.buildCardForBoard(card, category);
          });
          $scope.column.cards = $scope.statusWiseCards;
          $scope.column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
            $scope.column
          );
          $scope.kanbanColumns.push($scope.column);
        } else {
          $scope.kanbanColumns.push($scope.column);
        }

        if ($scope.count < $scope.column.cards.length)
          $scope.count = $scope.column.cards.length;
      }
      $scope.fixedColHeight = $scope.count * 115 + 60;
      $rootScope.kanbanBoard = newKanbanBoard();

      $timeout(() => {
        $scope.mobileViewReady = true;
        $scope.isLoadingCardCategories = false;
        setHeight();
      }, 400);
    };
    $scope.buildCardForBoard = (card, cardColumn) => {
      if (!card || !cardColumn) return;

      const {
        PipelineCardsID: PipelineItemId,
        ClientFamilyName: ClientName,
        Probability: labelType,
        LabelArray: labels,
        IsClient,
        AdvisorName,
        AdviserFirstName,
        AdviserLastName,
        EnquirySource,
        ClientFamilyID: clientFamilyId,
        PipelineStatusID,
        PipelineStatus,
        LastModifiedByFamilyId,
        LastModifiedDate,
        LastModifiedFirstName,
        LastModifiedLastName,
        LastModifiedFullName,
        CreateDate: CreateDateOriginal,
        TotalRecords,
      } = card;

      const kanbanCard = {
        PipelineItemId,
        ClientName,
        labelType,
        labels,
        IsClient,
        AdvisorName,
        AdviserFirstName,
        AdviserLastName,
        EnquirySource,
        clientFamilyId,
        PipelineStatusID,
        PipelineStatus,
        LastModifiedByFamilyId,
        LastModifiedDate,
        LastModifiedFirstName,
        LastModifiedLastName,
        LastModifiedFullName,
        CreateDateOriginal,
        TotalRecords,
      };

      if (kanbanCard.CreateDateOriginal) {
        kanbanCard.createDate = moment(kanbanCard.CreateDateOriginal).format(
          'DD MMMM YYYY'
        );
      }

      const cardStatus = $scope.leadsStatusList.find(
        item => item.PipelineStatusID === cardColumn.PipelineStatusID
      );
      kanbanCard.subStatuses = pipelineCardsService.getSubStatusList(
        cardStatus
      );
      if (kanbanCard.subStatuses && kanbanCard.subStatuses.length) {
        const selectedSubStatus = kanbanCard.subStatuses.find(
          item => item.pipelineSubStatusID === card.SubStatusID
        );
        kanbanCard.selectedSubStatus =
          selectedSubStatus || kanbanCard.subStatuses[0];
      }

      return kanbanCard;
    };
    $scope.isLastTwoChosen = function isLastTwoChosen(
      cardObject,
      subStatusId,
      previousSubStatus
    ) {
      const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
        {
          showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
            cardObject.EnquirySource
          ),
        }
      );
      confirmationPopup.result.then(result => {
        if (result.isOk) {
          $scope.moveCardOnSidebar(cardObject.PipelineItemId, 9, cardObject);
          $scope.postPipelineCardsMoveToNextStatus({
            PipelineCardsID: cardObject.PipelineItemId,
            ToStatusID: 9,
            reasonSubstatusID: subStatusId,
            shouldSendNurtureCampaigns: result.shouldSendNurtureCampaigns,
          });
        } else {
          cardObject.selectedSubStatus = previousSubStatus;
        }
      });
    };
    $scope.columnHasMore = column => pipelineCardsService.columnHasMore(column);
    $scope.viewMoreItems = column => {
      if (!column) return;

      column.loadingMore = true;
      const isLeads = true;
      const filterStatusId = column.PipelineStatusID;
      const pageNumber =
        pipelineCardsService.getColumnCurrentPage(column, pageSize) + 1;
      const params = { isLeads, filterStatusId, pageNumber, pageSize };
      const searchClientName = $scope.searchWrapper.searchPipeline;
      if (searchClientName) {
        params.searchClientName = searchClientName;
      }
      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          if (!response || !response.data) return;

          const moreItems = response.data[filterStatusId];
          if (moreItems && moreItems.length) {
            if (!$scope.leadsCards) $scope.leadsCards = {};

            if ($scope.leadsCards[filterStatusId]) {
              $scope.leadsCards[filterStatusId] = [
                ...$scope.leadsCards[filterStatusId],
                ...moreItems,
              ];
            } else {
              $scope.leadsCards[filterStatusId] = moreItems;
            }

            const category = $scope.leadsStatusList.find(
              status => status.PipelineStatusID === filterStatusId
            );
            const cardsForBoard = moreItems.map(card =>
              $scope.buildCardForBoard(card, category)
            );
            if (column.cards) {
              column.cards = [...column.cards, ...cardsForBoard];
            } else {
              column.cards = cardsForBoard;
            }
            column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
              column
            );
          }

          column.loadingMore = false;
        },
        () => {
          column.loadingMore = false;
        }
      );
    };

    $scope.changeSubStatus = (cardObj, subStatusID, previousSubStatus) => {
      if ($scope.focused) {
        const isLastTwo = _.includes($scope.lastTwoSubstatusesArr, subStatusID);
        if (isLastTwo) {
          $scope.isLastTwoChosen(cardObj, subStatusID, previousSubStatus);
        } else {
          pipelineService
            .PipelineCards(cardObj.PipelineItemId, {
              SubStatusID: subStatusID,
              PipelineStatusID: cardObj.PipelineStatusID,
            })
            .then(
              responseCards => {
                toaster.pop(
                  'success',
                  'Set',
                  'Substatus has been Set successfully'
                );
                if (responseCards.data.Result) {
                  cardObj.LastModifiedDate = responseCards.data.LastUpdate;
                }
              },
              () => {
                toaster.pop('error', 'Error', 'Yikes! Something is wrong');
              }
            );
        }
      }
    };

    $scope.checkDropdownFocus = isFocus => {
      $scope.focused = isFocus;
    };

    $scope.setProbability = (probObj, cardObj) => {
      $scope.probabilityToSet = {};
      $scope.probabilityToSet.cardID = cardObj.PipelineItemId;
      $scope.probabilityToSet.probability = probObj.probability;
      pipelineService.SetPipelineCardsProbability($scope.probabilityToSet).then(
        response => {
          if (!response) return;
          toaster.pop(
            'success',
            'Added',
            'Probability has been added successfully.'
          );
          $scope.handleCardUpdated(response.data);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $rootScope.cardApprove = [];
    $rootScope.cardOpportunity = [];
    $rootScope.cardNotProceeding = [];

    /*
  @PARAMS - pipelineCardId is optional, we only use this one to manually remove the card from the list
  */
    $scope.refreshKanbanBoard = (pipelineCardId = null) => {
      pipelineService
        .ConvertionRateGet($scope.selectedPipelineType)
        .then(conversion => {
          const conversions = conversion.data;

          const columnsData = $rootScope.kanbanBoard.columns;
          let cardNumber = -1;
          columnsData.forEach(column => {
            column.totalAmount = 0;
            column.ChartData = [[]];
            column.ChartLabels = [];

            column.cards.forEach(card => {
              column.totalAmount += card.LoanAmount;
              column.ChartData[0].push(card.LoanAmount);
              column.ChartLabels.push('');
            });
          });

          conversions.forEach(conversionIte => {
            if (!conversionIte.IsFinalStatus && !conversionIte.IsNotProceed) {
              // lead steps
              if (conversionIte.StatusId >= 2 && conversionIte.StatusId <= 4) {
                cardNumber = conversionIte.StatusId - 2;
                columnsData[cardNumber].totalPercentage =
                  conversionIte.Rate * 100;
              }
            } else if (
              conversionIte.IsFinalStatus &&
              conversionIte.IsNotProceed
            )
              $rootScope.kanbanBoard.totalNotProceedingPercentage =
                conversionIte.Rate * 100;
            else if (conversionIte.IsFinalStatus && !conversionIte.IsNotProceed)
              $rootScope.kanbanBoard.totalApplicationPercentage =
                conversionIte.Rate * 100;
          });
          /* if(pipelineCardId) this will remove the */
          if (pipelineCardId) {
            _.forEach(columnsData, (object, key) => {
              _.remove(
                columnsData[key].cards,
                removeObject => removeObject.PipelineItemId === pipelineCardId
              );
            });
            $rootScope.kanbanBoard.columns = columnsData;
          } else {
            $rootScope.kanbanBoard.columns = columnsData;
          }
        });
    };

    // open card options
    $scope.openHideTab = cardId => {
      const cardDetails = _.find($rootScope.kanbanBoard.columns, column => {
        return _.find(column.cards, card => {
          return parseInt(card.PipelineItemId, 10) === parseInt(cardId, 10);
        });
      });

      _.forEach(cardDetails.cards, card => {
        if (parseInt(card.PipelineItemId, 10) === parseInt(cardId, 10))
          card.openOptions = !card.openOptions;
      });
    };
    // delete card
    $scope.deleteCard = cardObj => {
      $scope.cardForDel = {};
      $scope.cardForDel.cardsID = cardObj.PipelineItemId;
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            pipelineService.PipelineCardsDelete($scope.cardForDel).then(() => {
              $scope.handleCardDeleted(cardObj);
              $scope.deleteCardTimeout = $timeout(() => {
                SweetAlert.swal(
                  'Success',
                  'Card has been successfully deleted.',
                  'success'
                );
                if ($scope.deleteCardTimeout)
                  $timeout.cancel($scope.deleteCardTimeout);
              }, 100);
            });
          }
        }
      );
    };
    $scope.handleCardDeleted = removedCard => {
      if (!removedCard) return;

      const cardColumn = $scope.kanbanColumns.find(
        column => column.PipelineStatusID === removedCard.PipelineStatusID
      );
      $scope.reloadColumn(cardColumn);
    };
    $scope.reloadColumn = (column, showLoading = true, customPageSize) => {
      if (!column) return;

      column.loading = showLoading;
      const isLeads = true;
      const filterStatusId = column.PipelineStatusID;
      const pageNumber = 1;
      const params = { isLeads, filterStatusId, pageNumber };
      params.pageSize = customPageSize || pageSize;
      const searchClientName = $scope.searchWrapper.searchPipeline;
      if (searchClientName) {
        params.searchClientName = searchClientName;
      }

      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          column.loading = false;
          if (!response || !response.data) return;
          const items = response.data[filterStatusId];
          if (items && items.length) {
            column.cards = items.map(card =>
              $scope.buildCardForBoard(card, column)
            );
          } else {
            column.cards = [];
          }
          column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
            column
          );
        },
        () => {
          column.loading = false;
        }
      );
    };

    // move card
    $scope.moveCard = (
      cardId,
      toColumnId,
      destStatus,
      fromStatus,
      itemToHighlight
    ) => {
      let card = {};
      _.forEach($scope.kanbanBoard.columns, column => {
        const findCard = _.find(column.cards, colCard => {
          return colCard.PipelineItemId === itemToHighlight.PipelineItemId;
        });

        if (findCard) {
          card = findCard;
          card.PipelineStatusID = toColumnId;
          card.PipelineStatus = destStatus;
        }
      });

      pipelineService
        .PipelineCards(itemToHighlight.PipelineItemId, {
          PipelineStatusID: toColumnId,
        })
        .then(
          response => {
            toaster.pop('success', 'Moved', 'Card has been moved successfully');
            if (response.data.Result) {
              card.LastModifiedDate = response.data.LastUpdate;
            }

            const status = _.find($scope.leadsStatus, o => {
              return o.PipelineStatusID === card.PipelineStatusID;
            });

            card.subStatuses = [];
            if (status && status.SubStatus && status.SubStatus.length) {
              card.subStatuses = pipelineCardsService.getSubStatusList(status);
            }

            if (card.subStatuses && card.subStatuses.length) {
              const defaultSelection = card.subStatuses[0];
              card.selectedSubStatus = defaultSelection;

              _.forEach(card.subStatuses, o => {
                if (o.SubStatusName === 'Unable to Contact') {
                  o.SubStatusName = 'UNABLE TO CONTACT';
                }
              });
            }

            $scope.onCardMoved(cardId, fromStatus, destStatus);
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
    };

    $scope.onCardMoved = (cardId, fromStatusName, toStatusName) => {
      if (!cardId || !fromStatusName) return;

      const fromStatusColumn = $scope.kanbanColumns.find(
        status => status.name === fromStatusName
      );
      updateColumnTotalRecords(fromStatusColumn, false);
      $scope.reloadColumnRetainSize(fromStatusColumn);

      const toStatusColumn = $scope.kanbanColumns.find(
        status => status.name === toStatusName
      );
      updateColumnTotalRecords(toStatusColumn, true);
      $scope.reloadColumnRetainSize(toStatusColumn);
    };

    $scope.reloadColumnRetainSize = column => {
      if (column && $scope.columnHasMore(column)) {
        const page = pipelineCardsService.getColumnCurrentPage(
          column,
          pageSize
        );
        const recordsToGet = page * pageSize;
        $scope.reloadColumn(column, false, recordsToGet);
      }
    };

    $scope.openTaskMoveModal = (
      size,
      pipelineCardsID,
      pipelineNewStatusID,
      destStatus,
      fromStatus
    ) => {
      $uibModal.open({
        templateUrl: '/assets/views/pipeline/partials/pipeline_task_move.html',
        controller: 'OpenTaskMoveModalCtrl',
        size,
        resolve: {
          fromStatus: () => fromStatus,
          destStatus: () => destStatus,
          pipelineNewStatusID: () => pipelineNewStatusID,
          pipelineCardsID: () => pipelineCardsID,
        },
      });
    };

    $scope.moveCardOnSidebar = (
      cardId,
      conversionStatus,
      itemToHighlight,
      subStatusId,
      shouldSendNurtureCampaigns
    ) => {
      itemToHighlight.highlight = true;
      const $postData = {
        pipelineCardsID: cardId,
        conversionStatus,
        clientFamilyId: itemToHighlight.clientFamilyId,
        reasonSubstatusID: subStatusId,
        shouldSendNurtureCampaigns,
      };
      contactService
        .getContactAssignedAdviser(itemToHighlight.clientFamilyId)
        .then(({ data: allocatedAdviserId }) => {
          pipelineService
            .PipelineCardsConversionSet($postData)
            .then(response => {
              if (parseInt(response.data, 10) !== 1) {
                toaster.pop('error', 'Error', 'Moving item went wrong');
                itemToHighlight.highlight = false;
                return;
              }

              const setLoanAppObj = {
                LoanScenarioId: 0,
                AdviserId: allocatedAdviserId,
                Title: `Loan Application`,
                StatusName: 'ongoing',
                FamilyId: itemToHighlight.clientFamilyId,
                AssignedLenderId: '0',
              };
              $scope.refreshKanbanBoard(cardId);
              $scope.onCardMoved(cardId, itemToHighlight.PipelineStatus);
              $scope.showStats();
              itemToHighlight.highlight = false;

              return loanScenarioService.scenarioSet(setLoanAppObj);
            })
            .then(({ data: loanScenarioID }) => {
              const setLinkerObj = { pipelineCardsID: cardId, loanScenarioID };
              pipelineService
                .PipelineCardsLoanScenarioSet(setLinkerObj)
                .then(() => {
                  toaster.pop('success', 'Moved', 'Item successfully moved');
                  $state.go('app.loanApplicationDetailsV3', {
                    familyId: itemToHighlight.clientFamilyId,
                    loanAppId: loanScenarioID,
                  });
                });
            });
        });
    };
    $scope.postPipelineCardsMoveToNextStatus = postObject => {
      pipelineService
        .PipelineCardsMoveToNextStatusSet(postObject)
        .then(() => {});
    };
    // board options
    $scope.kanbanSortOptions = {
      itemMoved(event) {
        if (!angular.isUndefined(event.dest.sortableScope.element)) {
          const itemToHighlight = event.source.itemScope.card;
          // Move among columns
          if (pipelineSharedData.checkItemMove(event)) {
            const destStatusName = event.dest.sortableScope.$parent.column.name;
            const fromStatusName =
              event.source.itemScope.modelValue.PipelineStatus;

            // remove the previous card data from custom sort obj
            _.forEach(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
              columnsCustomerSort => {
                _.remove(columnsCustomerSort, obj => {
                  return (
                    String(obj.PipelineItemId) ===
                    String(event.source.itemScope.modelValue.PipelineItemId)
                  );
                });
              }
            );

            // add the new card data to custom sort obj
            $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
              destStatusName
            ] = pipelineSharedData.customerSortObj(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
                destStatusName
              ]
            );
            $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
              destStatusName
            ].push({
              position: String(
                _.size(event.dest.sortableScope.$parent.column.cards) -
                  event.dest.index
              ),
              PipelineItemId: String(
                event.source.itemScope.modelValue.PipelineItemId
              ),
            });

            // manual stringify
            let CustomerSort = '';
            _.forEach(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
              (columnObject, columnKey) => {
                _.forEach(columnObject, item => {
                  CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                    item.position
                  },`;
                });
              }
            );

            if (CustomerSort !== '') {
              CustomerSort = CustomerSort.slice(0, -1);
            }

            $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;

            const filters = pipelineSharedData.getSortingForSet(true, false);
            pipelineService.setPipelineCardFilters(filters).then(() => {
              $scope.moveCard(
                event.source.itemScope.modelValue.PipelineItemId,
                event.dest.sortableScope.$parent.column.PipelineStatusID,
                destStatusName,
                fromStatusName,
                itemToHighlight,
                filters
              );
            });
          } else {
            const movedToStatus = pipelineSharedData.getMoveSwalTitle(event);
            const isApproveApplication =
              event.dest.sortableScope.element[0].id === 'approve';
            const isMoveToOpportunity =
              event.dest.sortableScope.element[0].id === 'opportunity';
            const modalContent = {
              content: `This record will be moved to ${movedToStatus}`,
            };
            if (isMoveToOpportunity) {
              const {
                clientFamilyId,
                PipelineItemId: pipelineItemId,
              } = event.source.itemScope.modelValue;
              leadsToOpportunityConvert(
                clientFamilyId,
                contactService,
                opportunityNewModalService,
                {},
                pipelineItemId
              ).then(res => {
                if (res && !res.isSuccess) revertMoveCardToOtherColumn(event);
              });
            } else {
              if (!isApproveApplication) {
                modalContent.reasonList = $scope.reasonList;
                modalContent.showNurtureCampaignsQuestion = pipelineSharedData.showNurtureCampaignsQuestion(
                  event.source.itemScope.modelValue.EnquirySource
                );
              }
              const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
                modalContent
              );
              confirmationPopup.result.then(result => {
                if (result.isOk) {
                  const pipelineItemId =
                    event.source.itemScope.modelValue.PipelineItemId;
                  $scope.moveCardOnSidebar(
                    pipelineItemId,
                    isApproveApplication ? 1 : 9,
                    itemToHighlight,
                    result.selectedReasonId,
                    result.shouldSendNurtureCampaigns
                  );
                } else {
                  revertMoveCardToOtherColumn(event);
                }
              });
            }
          }
        }
      },
      orderChanged(event) {
        const destStatusName = event.dest.sortableScope.$parent.column.name;

        // remove the previous card data from custom sort obj
        _.forEach(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
          columnsCustomerSortOrder => {
            _.remove(columnsCustomerSortOrder, obj => {
              return (
                String(obj.PipelineItemId) ===
                String(event.source.itemScope.modelValue.PipelineItemId)
              );
            });
          }
        );

        // add the new card data to custom sort obj
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
          destStatusName
        ] = pipelineSharedData.customerSortObj(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
            destStatusName
          ]
        );
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
          destStatusName
        ].push({
          position: String(
            event.dest.sortableScope.$parent.column.cards.length -
              event.dest.index
          ),
          PipelineItemId: String(
            event.source.itemScope.modelValue.PipelineItemId
          ),
        });

        // manual stringify
        let CustomerSort = '';
        _.forEach(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
          (columnObject, columnKey) => {
            _.forEach(columnObject, item => {
              CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                item.position
              },`;
            });
          }
        );

        if (CustomerSort !== '') {
          CustomerSort = CustomerSort.slice(0, -1);
        }

        $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;

        $scope.defaultSortSet(false);
      },
      containerPositioning: 'relative',
      containment: '#container',
      dragStart: pipelineSharedData.handleDragStart,
      dragEnd: pipelineSharedData.handleDragEnd,
      accept(sourceItemHandleScope, destSortableScope) {
        $scope.cardViewStates.isNotProceedingDropAreaEnabled = false;
        $scope.cardViewStates.isOpportunityDropAreaEnabled = false;
        $scope.cardViewStates.isSettledDropAreaEnabled = false;
        $timeout(() => {
          if (destSortableScope && objectLength(destSortableScope.element)) {
            $scope.onDropAreaMouseIn(destSortableScope.element[0].id);
          }
        });
        return true;
      },
      allowDuplicates: true,
    };

    // dynamic board width
    $scope.kanbanBoardWidth = {
      width: '100%',
      measure: '%',
    };
    $scope.$watch('kanbanBoard.columns.length', newLength => {
      $scope.kanbanBoardWidth.width = newLength * 220 + 250;
      $scope.kanbanBoardWidth.measure = 'px';
    });

    $scope.openLinkToLoanAppModal = (size, familyID, cardID, familyName) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/link_loanApp_modal.html',
        resolve: {
          familyID() {
            return familyID;
          },
          cardID() {
            return cardID;
          },
          familyName() {
            return familyName;
          },
        },
        controller: 'LinkToLoanCtrl',
        size,
      });
    };

    $scope.openLeadDetailModal = (size, card) => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/lead_detail_modal.html',
        controller: 'OpenLeadDetailModalCtrl',
        resolve: {
          card() {
            return card;
          },
          probabilityList() {
            return $scope.probabilityList;
          },
          leadStatusList() {
            return $scope.leadsStatusList;
          },
          labels() {
            return $scope.labels;
          },
        },
        size,
        windowTopClass: 'content-no-box-shadow',
      });

      modalInstance.result.then(
        () => {
          if ($scope.DisplayMode === 'list_view') {
            $scope.initListView();
          } else {
            $scope._cardInit();
          }
        },
        () => {
          $scope.getAllLabels();
        }
      );
    };
    $scope.filterXs = false;
    $rootScope.filterXsList = false;
    $scope.openFilter = () => {
      if ($scope.DisplayMode === 'cards') {
        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/pipeline/leads/partials/pipeline_mobile_filters.html',
          resolve: {
            pipelineSharedData() {
              return $scope.pipelineSharedData;
            },
            DisplayMode() {
              return $scope.DisplayMode;
            },
            adviser() {
              return $scope.taskAdviserList[0];
            },
            taskAdviserList() {
              return $scope.taskAdviserList;
            },
            labelsList() {
              return $scope.labels;
            },
            enquirySourceList() {
              return $scope.enquirySourceList;
            },
            referralListCopy() {
              return $scope.referralListCopy;
            },
          },
          size: 'sm',
          windowTopClass: 'content-no-box-shadow',
          controller: 'PipelineModalLeadsFilterCtrl',
        });

        modalInstance.result.then(response => {
          if (response && response.leadFilterData) {
            angular.extend(
              $scope.pipelineSharedData.leadFilterData,
              response.leadFilterData
            );
            $scope.getAllCards(true);
          }
        });
      } else {
        $scope.$broadcast('openListViewMobileFilter');
      }
    };
    $scope.makeFilterFalse = () => {
      $scope.filterXs = false;
      $rootScope.filterXsList = false;
    };
    $scope.filterResult = (adviser, probability) => {
      $scope.filterXs = false;
      $scope.filterAdviser(adviser);
      $scope.filterProbability(probability);
    };

    $scope.openEditLabelModal = size => {
      $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/edit_label_modal.html',
        resolve: {
          labels() {
            return $scope.labels;
          },
        },
        controller: 'OpenEditlabelModalCtrl',
        size,
      });
    };
    $scope.performAction = (lbl, cardObj, index) => {
      $scope.result = $scope.isIncluded(lbl, cardObj, index);

      if ($scope.result === false) {
        $scope.setLabelForCard(lbl, cardObj);
      } else {
        $scope.deleteLabelFromCard(lbl, cardObj);
      }
    };

    $scope.isIncluded = (lbl, cardObj) =>
      pipelineSharedData.isLabelInCard(lbl, cardObj);

    $scope.$watch(
      'kanbanBoard.columns',
      nv => {
        if (_.size(nv) > 0) {
          _.forEach(nv, column => {
            let hasOpenedHelper = false;
            _.forEach(column.cards, card => {
              if (card) {
                if (card.textOpen === true) {
                  hasOpenedHelper = true;
                }
                _.forEach(card.labels, label => {
                  if (label.textOpen === true) {
                    hasOpenedHelper = true;
                  }
                });
              }
            });
            column.isLabelHelperOpen = hasOpenedHelper;
          });
        }
      },
      true
    );

    $scope.deleteLabelFromCard = (lblObj, cardObj) => {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = cardObj.PipelineItemId;
      $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(
        () => {
          toaster.pop(
            'success',
            'Deleted',
            'Label has been deleted from your list.'
          );
          $scope.handleCardLabelDeleted(lblObj, cardObj);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.handleCardLabelDeleted = (removedLabel, card) => {
      if (!removedLabel || !card) return;

      _.remove(
        card.labels,
        label =>
          parseInt(label.labelID, 10) ===
          parseInt(removedLabel.pipelineSettingsLabelID, 10)
      );
    };
    $scope.closeLabelPopover = closeLabelPopover;
    $scope.editLabel = (lbl, card) => {
      $scope.card = card;
      $scope.card.editLabelShown = true;
      card.editLabelShown = $scope.card.editLabelShown;

      $scope.card.lblObjToEdit = lbl;
      card.labelTitle = lbl.LabelName;
      card.lblObjToEdit = $scope.card.lblObjToEdit;
      $scope.pickedColorCode = lbl.ColorName;
    };
    $scope.addlabel = card => {
      $scope.card = card;
      $scope.card.addLabelShown = true;
      card.addLabelShown = $scope.card.addLabelShown;
      $scope.card.lblObjToEdit = null;
      $scope.card.labelTitle = '';
      $scope.pickedColorCode = '';
    };
    $scope.pickedColorCode = '';
    $scope.colorPicked = color => {
      $scope.pickedColorCode = color;
    };
    $scope.updateLabel = card => {
      pipelineSharedData.updateLabels(card, $scope.pickedColorCode).then(
        () => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          $scope.initListView();
          $scope._cardInit();
          card.editLabelShown = false;
          card.addLabelShown = false;
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.deleteLabel = labelObj => {
      if (labelObj.pipelineSettingsLabelID !== undefined) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
          () => {
            toaster.pop(
              'success',
              'Deleted',
              'Label has been deleted successfully.'
            );
            $scope.initListView();
            $scope._cardInit();
            $rootScope.$broadcast('getAllLabels');
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
      }
    };
    $scope.setLabelForCard = (lblObj, cardObj) => {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = cardObj.PipelineItemId;
      $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;
      $scope.pipelineCardsLabelSet.colorCode = lblObj.ColorName;

      pipelineService.PipelineCardsLabelSet($scope.pipelineCardsLabelSet).then(
        response => {
          if (response) {
            toaster.pop(
              'success',
              'Added',
              'Label  has been added successfully.'
            );
            $scope.handleCardUpdated(response.data);
          }
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.handleCardUpdated = updatedCard => {
      if (!updatedCard) return;

      const cardCategory = $scope.leadsStatus.find(
        column => column.PipelineStatus === updatedCard.PipelineStatus
      );
      if (!cardCategory) return;

      updatedCard.LabelArray = pipelineCardsService.convertStrLabelsToObj(
        updatedCard
      );
      const kanbanCard = $scope.buildCardForBoard(updatedCard, cardCategory);
      const cardColumn = $scope.kanbanColumns.find(
        column => column.name === updatedCard.PipelineStatus
      );
      const indexOfCard = _.findIndex(
        cardColumn.cards,
        card => card.PipelineItemId === kanbanCard.PipelineItemId
      );

      if (indexOfCard !== -1) {
        cardColumn.cards[indexOfCard] = kanbanCard;
      }
    };

    pipelineSharedData.isFirstLoad = true;
    $scope._cardInit = () => {
      $scope.isHoverLabel = false;
      $scope.isHoverProb = false;
      $scope.isHoverAdviser = true;
      const leadFilterSortingMode =
        $scope.pipelineSharedData.leadFilterData.sortingMode;
      const sortingModeArray = pipelineSharedData.sortingModeArray(
        leadFilterSortingMode
      );
      pipelineSharedData.convertSortStrToObj(sortingModeArray);
      pipelineSharedData.formatCustomerSortForView();

      $scope.isLoadingCardCategories =
        !$scope.kanbanBoard || _.isEmpty($scope.kanbanBoard.columns);

      $scope.getAllLeadStatus();
      $scope.getAllProbabilities();
      $scope.getAllReferrals();
      $scope.getAllEnquirySource();
      $scope.getAllAdvisers();
      $scope.getAllLabels(() => {
        $scope.getAllCards(
          !$scope.kanbanBoard || _.isEmpty($scope.kanbanBoard.columns)
        );
      });

      $scope.showStats();
    };
    $scope.isHoverDelete = false;

    $scope.initListView = () => {
      if (pipelineSharedData.initListView) {
        pipelineSharedData.initListView();
      } else {
        $scope.initListViewWatch = $scope.$watch(
          () => pipelineSharedData.initListView,
          () => {
            if (!pipelineSharedData.initListView) return;
            pipelineSharedData.initListView();
            $scope.destroyInitListViewWatch();
          }
        );
      }
    };

    $scope.destroyInitListViewWatch = () => {
      if (!$scope.initListViewWatch) return;
      $scope.initListViewWatch();
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    const viewport = () => {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = () => {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
        if (newValue.w < 992) {
          $scope.DisplayMode = 'list_view';
          $scope.showMode('list_view');
        }
      },
      true
    );

    function showModeInit() {
      $scope.isLeads = true;
      pipelineSharedData.isFirstLoad = true;
      pipelineSharedData.leadFilterData = [];
      $scope.isLoadingCardCategories = !$scope.DisplayMode;
      pipelineService.getPipelineCardFilters($scope.isLeads).then(response => {
        const { data: leadsFilters } = response;
        if (!leadsFilters) return;

        pipelineSharedData.leadFilterData = leadsFilters[0] || {
          adviserId: 0,
          IsTableView: false,
          isCompact: '1',
          CustomerSort: '',
          sortingMode: '',
          TableViewExcludeColumns: '',
          isFetchAllPipelineStatusIDs: 1,
        };

        pipelineSharedData.initCommonFilters(pipelineSharedData.leadFilterData);

        if (
          !pipelineSharedData.leadFilterData.SubStatusId ||
          pipelineSharedData.leadFilterData.SubStatusId === 'All'
        ) {
          pipelineSharedData.leadFilterData.SubStatusId = '0';
        } else {
          pipelineSharedData.leadFilterData.SubStatusId = pipelineSharedData.leadFilterData.SubStatusId.toString();
        }

        pipelineSharedData.leadFilterData.IsCompact = pipelineSharedData
          .leadFilterData.IsCompact
          ? '1'
          : '0';
        pipelineSharedData
          .populateLeadsFilter(pipelineSharedData.leadFilterData)
          .then(() => {
            const displayMode =
              pipelineSharedData.leadFilterData.IsTableView ||
              $scope.screenSize < 992
                ? 'list_view'
                : 'cards';
            $scope.showMode(displayMode);
          });
      });

      $scope.searchWrapper = {};
      $scope.searchWrapper.searchPipeline = '';
    }

    $scope.$watch(
      'pipelineSharedData.leadFilterData',
      newVal => {
        if (typeof newVal.isCompact === 'undefined') {
          newVal.isCompact = '0';
        }

        if (
          newVal.enquiryId === 'All' ||
          newVal.enquiryId === 'null' ||
          newVal.enquiryId === null ||
          typeof newVal.enquiryId === 'undefined' ||
          !newVal.enquiryId
        ) {
          newVal.enquiryId = 0;
        }

        if (
          newVal.referralId === 'All' ||
          newVal.referralId === 'null' ||
          newVal.referralId === null ||
          typeof newVal.referralId === 'undefined' ||
          !newVal.referralId
        ) {
          newVal.referralId = -1;
        }

        if (parseInt(newVal.probability, 10) === 1) {
          newVal.probability = 'Hot';
        } else if (parseInt(newVal.probability, 10) === 2) {
          newVal.probability = 'Warm';
        } else if (parseInt(newVal.probability, 10) === 3) {
          newVal.probability = 'Cold';
        } else if (
          newVal.probability === 'All' ||
          newVal.probability === 'null' ||
          newVal.probability === null ||
          typeof newVal.probability === 'undefined' ||
          !newVal.probability
        ) {
          newVal.probability = 'All';
        }
      },
      true
    );

    showModeInit();

    function init() {
      if (
        $stateParams.createdContact !== '' &&
        !$localStorage.pipelineCardSatusName
      ) {
        $scope.openAddCardModal(
          'modalWidthAuto',
          $stateParams.createdContact,
          'leads'
        );
      }
      pipelineSharedData.initCardViewStates();
      $scope.cardViewStates = pipelineSharedData.cardViewStates;
    }

    $scope.refreshLeads = () => {
      $scope.leadsRefreshing = true;
      if ($scope.currentMode === 'list_view') {
        $scope.listViewLoadEventListener = $scope.$on(
          'eventLeadsListViewLoaded',
          () => {
            $scope.leadsRefreshing = false;
            $scope.listViewLoadEventListener();
          }
        );
        $scope.$broadcast('eventRefereshLeadsListView');
      } else {
        pipelineSharedData.isFirstLoad = true;
        $scope.getAllCards(true);
      }
    };

    $scope.onDropAreaMouseIn = dropAreaId => {
      const areaDragged = pipelineSharedData.onDropAreaMouseIn(dropAreaId);
      if (areaDragged) {
        $scope.cardViewStates[areaDragged] = $scope.cardViewStates.cardOnDrag;
      }
    };

    $scope.showStats = () => {
      // stats progress config
      $scope.initializeStatsConfig();

      const dateFormat = 'DD MMM YYYY';
      $scope.toDate = moment().format(dateFormat);
      // range
      switch ($rootScope.dateRangeFilter) {
        case '1Month':
          $scope.fromDate = moment()
            .subtract(1, 'months')
            .format(dateFormat);
          break;
        case '3Months':
          $scope.fromDate = moment()
            .subtract(3, 'months')
            .format(dateFormat);
          break;
        case '6Months':
          $scope.fromDate = moment()
            .subtract(6, 'months')
            .format(dateFormat);
          break;
        case '12Months':
          $scope.fromDate = moment()
            .subtract(1, 'year')
            .format(dateFormat);
          break;
        default:
          break;
      }

      pipelineService
        .getPipelineCardsSummary($scope.fromDate, $scope.toDate, 0)
        .then(response => {
          $scope.statePer = response.data;
          if ($scope.statePer) {
            $scope.convertedToAppsValue = $scope.statePer.Successful;
            $scope.notProceedingValue = $scope.statePer.UnSuccessfulPercent;
            $scope.convertedToAppsDisplayValue = Math.round(
              $scope.statePer.Successful
            );
            $scope.notProceedingDisplayValue = Math.round(
              $scope.statePer.UnSuccessfulPercent
            );
          }
          $scope.statsLoaded = true;
        });
    };

    $scope.initializeStatsConfig = () => {
      if (
        $scope.convertedToAppsStatsOptions &&
        $scope.notProceedingStatsOptions
      )
        return;

      $scope.convertedToAppsStatsOptions = {
        displayPrevious: false,
        barCap: 3,
        trackWidth: 3,
        barWidth: 3,
        size: 36,
        readOnly: true,
        trackColor: 'transparent',
        barColor: '#3D91CC',
        textColor: '#579BCB',
        unit: '%',
      };
      $scope.notProceedingStatsOptions = {};
      angular.extend(
        $scope.notProceedingStatsOptions,
        $scope.convertedToAppsStatsOptions
      );
      $scope.notProceedingStatsOptions.barColor = '#42B4B4';
      $scope.notProceedingStatsOptions.textColor = '#42B4B4';
    };

    $scope.searchCards = (searchFilter = '') => {
      const isInvalidSearchFilter =
        searchFilter.length > 0 && searchFilter.length < 3;
      if (isInvalidSearchFilter) return;

      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        $scope.isLoadingCardCategories = true;
        $scope.getPipelineCards(searchFilter);
      }, 1000);
    };
    $scope.$on('$destroy', () => {
      if ($scope.searchTimeout) {
        $timeout.cancel($scope.searchTimeout);
      }
    });

    $scope.openLabelsModal = card => {
      const modalInstance = $uibModal.open({
        resolve: {
          card() {
            return card;
          },
          labels() {
            return $scope.labels;
          },
        },
        templateUrl:
          '/assets/views/pipeline/partials/pipeline_labels_modal.html',
        size: 'sm',
        windowTopClass: 'content-no-box-shadow',
        controller: 'PipelineLabelsModalCtrl',
      });

      modalInstance.result.then(
        () => {
          $scope.getAllLabels();
        },
        () => {
          $scope.getAllLabels();
        }
      );
    };
    $scope.openAddNotesToContact = familyId => {
      if (!familyId) return;

      const note = {};

      $uibModal.open({
        templateUrl: '/assets/views/contacts/partials/create_note_modal.html',
        controller: 'CreateNoteModalCtrl',
        resolve: {
          familyId: () => familyId,
          note: () => note,
        },
      });
    };

    const viewClient = (data, e) => {
      if (e) e.stopPropagation();
      if (data.ClientFamilyID < 1 && $scope.hiddenClientForNz) return;
      const state = data.IsClient
        ? 'app.contactsSingle'
        : 'app.BusinessAccount';
      $state.go(state, { familyId: data.ClientFamilyID });
    };
    $scope.viewClient = viewClient;

    init();
  });
