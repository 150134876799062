// TODO: move this to PipelineService
class ReportService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Pipeline';
  }

  settingsStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/SettingsStatusGet`);
  }

  token() {
    // TODO: move to jasperService
    return this.httpClient.get('Jasper/Token');
  }

  getUserInfo() {
    return this.httpClient.get('GetUserInfo', {}, true);
  }

  getCountryFiscalYear() {
    // TODO: move to generalService
    return this.httpClient.get('CountryFiscalYearGet');
  }

  reportTypeSettings() {
    // TODO: move to jasperService
    return this.httpClient.get('Jasper/ReportTypeSettings');
  }
}

export default ReportService;
