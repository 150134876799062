import angular from 'angular';

angular
  .module('app')
  .controller('ApplyOnlineSentCtrl', function ApplyOnlineSentCtrl(
    $scope,
    $uibModalInstance
  ) {
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
