export function singleAnswerBuilderForUI(answer) {
  return (
    answer && {
      questionId: parseInt(answer.QuestionId, 10) || 0,
      displayOptionId: parseInt(answer.DisplayOptionId, 10) || 0,
      answerString: answer.answerString && answer.answerString[0],
      answerInt: answer.answerInt && answer.answerInt[0],
      answerBool: answer.answerBool && answer.answerBool[0],
      answerDecimal: answer.answerDecimal && answer.answerDecimal[0],
    }
  );
}

export function singleAnswerBuilderForMyCRM(answer) {
  return (
    answer && {
      LoanScenarioId: answer.loanScenarioId,
      FamilyId: answer.familyId,
      QuestionSectionId: answer.questionSectionId,
      QuestionId: answer.questionId,
      DisplayOptionId: answer.displayOptionId,
      answerString: [answer.answerString],
      answerInt: [answer.answerInt],
      answerBool: [answer.answerBool],
      answerDecimal: [answer.answerDecimal],
    }
  );
}
