import { ANSWER_TYPE } from 'Common/constants/questionAnswerType';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';

export function getBorrowerFullName(borrower, isGuarantor) {
  const borrowers = borrower
    .reduce((accum, item) => {
      if (!isGuarantor && !item.IsGuarantor) {
        accum.push(`${item.FirstName} ${item.LastName}`);
      } else if (isGuarantor && item.IsGuarantor === isGuarantor) {
        accum.push(`${item.FirstName} ${item.LastName}`);
      }
      return accum;
    }, [])
    .join(', ');
  return borrowers;
}

export function getApplicationStatus(pipelineService) {
  return pipelineService.SettingsStatusGet().then(({ data }) => {
    if (!data) return [];
    const statusList = collectionCamelizeBuilderForUI(data);
    const finalStatusList = statusList.reduce((accum, status) => {
      if (status.typeName !== 'Application') return accum;
      return accum.concat(status);
    }, []);
    return finalStatusList;
  });
}

export function getAnswer(answersList, question) {
  const answer = answersList.find(
    ans => ans.questionId === question.QUESTION_ID
  );
  if (!answer || !answer.displayOptionId) return;

  switch (question.DISPLAY_OPTION.ANSWER_TYPE) {
    case ANSWER_TYPE.BOOLEAN:
      return answer.answerBool;
    case ANSWER_TYPE.STRING:
      return answer.answerString;
    case ANSWER_TYPE.INTEGER:
      return answer.answerInt;
    default:
      break;
  }
}

export function getAnswerObjToSave(question, answer) {
  const answerObj = {
    questionId: question.QUESTION_ID,
    displayOptionId: question.DISPLAY_OPTION.ID,
    questionSectionId: 0,
  };

  switch (question.DISPLAY_OPTION.ANSWER_TYPE) {
    case ANSWER_TYPE.BOOLEAN:
      answerObj.answerBool = answer;
      break;
    case ANSWER_TYPE.STRING:
      answerObj.answerString = answer;
      break;
    case ANSWER_TYPE.INTEGER:
      answerObj.answerInt = answer;
      break;
    default:
      break;
  }

  return answerObj;
}
