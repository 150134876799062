import template from './customerCareLockedView.html';
import controller from './customerCareLockedViewCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    message: '<',
    userInfo: '<',
  },
};
