import template from './adviserSelect.html';
import controller from './adviserSelectCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onAdviserChange: '&',
    onToggleShowInput: '&',
    hideLabel: '<',
    adviserModel: '<',
  },
};
