class MergeFieldCategory {
  constructor(data) {
    this.fields = data ? data.MergeFields : null;
  }

  getFieldValue(fieldName) {
    if (!fieldName || !this.fields) return;

    const field = this.fields.find(item => item.Name === fieldName);
    return field ? field.Value : null;
  }
}

export default MergeFieldCategory;
