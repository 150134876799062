import template from './statusDropdownLeads.html';
import controller from './statusDropdownLeadsCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    statusList: '<',
    pipelineStatus: '<',
    onToggleDropdown: '&',
    onSelectStatus: '&',
    onMoveCardOnSidebar: '&',
    isSubStatus: '<',
    pipelineItem: '<',
  },
};
