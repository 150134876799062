app.factory('overviewDashboardService', [
  '$q',
  'overviewDashboardModel',
  function($q, overviewDashboardModel) {
    var factory = this;

    var overviewDashboard = new overviewDashboardModel();

    factory.getLenderUpdates = function(days) {
      var defer = $q.defer();

      overviewDashboard.getLenderUpdates(
        days,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.LayoutGet = function() {
      var defer = $q.defer();

      overviewDashboard.LayoutGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.LayoutListGet = function(position) {
      var defer = $q.defer();

      overviewDashboard.LayoutListGet(
        position,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.LayoutSet = function(layouts) {
      var defer = $q.defer();
      overviewDashboard.LayoutSet(
        layouts,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.UpComingEventTableGet = function() {
      var defer = $q.defer();
      overviewDashboard.UpComingEventTableGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CommissionRecievedSmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.CommissionRecievedSmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CommissionRecievedChartGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.CommissionRecievedChartGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.PipelineStatsGraphViewGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.PipelineStatsGraphViewGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.SystemUpdateTableGet = function() {
      var defer = $q.defer();
      overviewDashboard.SystemUpdateTableGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.InterestRateUpdateTableGet = function() {
      var defer = $q.defer();
      overviewDashboard.InterestRateUpdateTableGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.ClientReviewSmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.ClientReviewSmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.ProviderUpdateTableGet = function() {
      var defer = $q.defer();
      overviewDashboard.ProviderUpdateTableGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.FixedRateReviewSmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.FixedRateReviewSmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetPiplineLodgedGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetPiplineLodgedGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetPiplineSettledGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetPiplineSettledGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetPiplineApprovedGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetPiplineApprovedGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.UpcomingBirthdaySmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.UpcomingBirthdaySmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.TaskSmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.TaskSmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CommissionRecievedListGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.CommissionRecievedListGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.ClientReviewTableGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.ClientReviewTableGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.FixedRateReviewTableGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.FixedRateReviewTableGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetPipelineNewLeadsGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetPipelineNewLeadsGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.PreApprovalExpiryCountGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.PreApprovalExpiryCountGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.PreApprovalExpiryListGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.PreApprovalExpiryListGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetPipelineStatsTableViewGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetPipelineStatsTableViewGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.UpcomingBirthdayTableGet = function(
      userWidgetId,
      reportingStateId
    ) {
      var defer = $q.defer();
      overviewDashboard.UpcomingBirthdayTableGet(
        userWidgetId,
        reportingStateId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.TasksTableGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.TasksTableGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CommissionExpectedListGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.CommissionExpectedListGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CommissionExpectedSmallGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.CommissionExpectedSmallGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodList7Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodList7Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodList8Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodList8Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodListv1Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodListv1Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodListv2Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodListv2Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodListv3Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodListv3Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodListv4Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodListv4Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionAdviserListGet = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionAdviserListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionAdviserCommissionList = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionAdviserCommissionList(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTimePeriodListv5Get = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTimePeriodListv5Get(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionSet = function(OptionToSet) {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionSet(
        OptionToSet,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionGet = function(userWidgetId) {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionGet(
        userWidgetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetYesNoGet = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetYesNoGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionLenderListGet = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionLenderListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetOptionTaskTypeListGet = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetOptionTaskTypeListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetTaskTypeGet = function() {
      var defer = $q.defer();
      overviewDashboard.WidgetTaskTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.WidgetTypeGet = function(widgetName) {
      var defer = $q.defer();
      overviewDashboard.WidgetTypeGet(
        widgetName,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };
    factory.CategorySet = function(categoryObj) {
      var defer = $q.defer();
      overviewDashboard.CategorySet(
        categoryObj,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    return factory;
  },
]);
