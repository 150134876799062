class CustomInputPercentageCtrl {
  inputChange() {
    if (this.inputModel > parseFloat(this.maxAmount)) {
      this.inputModel = parseFloat(this.maxAmount);
    }
    this.onChange();
  }
}

export default CustomInputPercentageCtrl;
