import angular from 'angular';

angular
  .module('app')
  .controller('LoanapExpenseModalCtrl', function LoanapExpenseModalCtrl(
    $scope,
    $uibModalInstance,
    modalType,
    uiService
  ) {
    $scope.uiService = uiService;
    $scope.Title = modalType === 'new' ? 'Add' : 'Edit';

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.saveBreakdown = () => {
      $scope.addExpense();
      $scope.cancel();
    };
  });
