import template from './smsTemplates.html';
import SMSTemplatesCtrl from './smsTemplatesCtrl';

export default {
  template,
  controller: SMSTemplatesCtrl,
  controllerAs: 'vm',
  bindings: {
    showDefaultTemplateSettings: '<',
    onTemplatesUpdated: '&',
  },
};
