import angular from 'angular';
import _ from 'lodash';
import { FIXED_RATE_EXPIRES_ID } from 'Common/constants/customerCareModules';
import moment from 'moment';

angular
  .module('app')
  .controller(
    'FixedRateSendEmailModalCtrl',
    function FixedRateSendEmailModalCtrl(
      $scope,
      $rootScope,
      $uibModalInstance,
      $uibModal,
      $state,
      $controller,
      toaster,
      corporateService,
      contactService,
      customerCareService,
      emailTemplatesService,
      ckEditorConfigService,
      fixedRateListTable,
      fixedRateObj,
      source,
      loadFixedRateTable,
      customerCareTemplateService
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendEmailModalBaseCtrl', { $scope })
      );

      $scope.recipients = [];
      $scope.IsComplete = true;
      if (source === 'single') {
        if (fixedRateObj === '' && fixedRateObj.Email === '') {
          $scope.recipients.email = [{ EmailAddress: '', FamilyFullname: '' }];
        }
        if (fixedRateObj.Email !== '') {
          const expDate = moment(fixedRateObj.ExpiryDate).format('D MMM');
          const notiHeader = `Fixed Rate Expires (${expDate}) Completed via Email`;
          $scope.recipients.email = [
            {
              FamilyID: fixedRateObj.FamilyID,
              FamilyFullname: fixedRateObj.Customer,
              LoanID: fixedRateObj.LoanID,
              EmailAddress: fixedRateObj.Email,
              NotificationHeader: notiHeader,
            },
          ];
        }

        $scope.recipients.CustomerCareType = 'FIXED_RATE_EXPIRY';
      }
      $scope.fixedRateListTable = fixedRateListTable;
      $scope.currentModule = FIXED_RATE_EXPIRES_ID;

      if (source === 'all') {
        $scope.all = true;
        if ($scope.fixedRateListTable.length > 0) {
          $scope.selectAllEmailList = [];
          $scope.ClientList = [];
          for (let i = 0; i < $scope.fixedRateListTable.length; i++) {
            $scope.emailAdd = {};
            if ($scope.fixedRateListTable[i].Email !== '') {
              $scope.emailAdd.FamilyID = $scope.fixedRateListTable[i].FamilyID;
              $scope.emailAdd.FamilyFullname =
                $scope.fixedRateListTable[i].Customer;
              $scope.emailAdd.LoanID = $scope.fixedRateListTable[i].LoanID;

              $scope.emailAdd.EmailAddress = $scope.fixedRateListTable[i].Email;
              $scope.selectAllEmailList.push($scope.emailAdd);
            }
          }
          if ($scope.selectAllEmailList.length > 0) {
            $scope.recipients.email = $scope.selectAllEmailList;
            $scope.noOfPeople = $scope.selectAllEmailList.length;
          }
        }
      }

      $scope.getRecipientAutoSuggestion = userInput => {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then(({ data }) => {
            $scope.selectEmailList = [];

            if (!data) return;

            $scope.withEmail = data;

            $scope.selectEmailList = $scope.withEmail.reduce((accum, curr) => {
              const { Name: FamilyFullname, EmailAddress } = curr;

              if (!EmailAddress) return accum;

              return accum.concat({ FamilyFullname, EmailAddress });
            }, []);
          })
          .catch(() => {
            $scope.selectEmailList = [];
          });
      };

      const getMergeFields = () => {
        $scope.preferredName = '';
        if (typeof fixedRateObj.FamilyID !== 'undefined') {
          $scope.familyId = fixedRateObj.FamilyID;
        } else {
          $scope.familyId = 0;
        }
        if (typeof fixedRateObj.LoanID !== 'undefined') {
          $scope.loanId = fixedRateObj.LoanID;
        } else {
          $scope.loanId = 0;
        }
        $scope.clientId = 0;
        corporateService
          .MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId)
          .then(response => {
            $scope.emailMergeFieldList = response.data;
            $scope.mergeFieldsObj = response.data[0];
            $scope.mergeFields = [];
            $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
            for (let k = 0; k < $scope.mergeFields.length; k++) {
              if ($scope.mergeFields[k].Name === 'Full Name') {
                $scope.preferredName = $scope.mergeFields[k].Value;
              }
            }

            $scope.getEmailTemplates($scope.preferredName);
            $scope.getDefaultTemplate();
          });
      };

      getMergeFields();

      $rootScope.getEmailTemplatesFromEmailPage = () => {
        $scope.getEmailTemplates('');
      };
      $scope.selectedTemplate = {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
        UserTemplateEmailSubject: '',
      };
      $scope.refreshResults = select => {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.sendEmail = form => {
        let firstError = null;
        if (form.$invalid) {
          _.each(form, field => {
            if (field && field[0] !== '$') {
              if (firstError === null && !field.$valid) {
                firstError = field.$name;
              }

              if (field.$pristine) {
                field.$dirty = true;
              }
            }
          });
          angular.element(`.ng-invalid[name=${firstError}]`).focus();
          return;
        }
        $scope.recipientsEmailArray = [];
        angular.forEach($scope.recipients.email, item => {
          $scope.recipientsEmailArray.push(item);
        });
        $scope.email.ClientList = $scope.recipientsEmailArray;
        $scope.email.CustomerCareType = 'FIXED_RATE_EXPIRY';
        $scope.email.IsComplete = $scope.IsComplete;

        customerCareService
          .CustomerCareEmailSendv2($scope.email)
          .then(fixedRateRes => {
            if (fixedRateRes.data && fixedRateRes.data.length) {
              const fixedRateEmailValid = customerCareTemplateService.customerCareResponseMsg(
                fixedRateRes.data,
                'EMAIL'
              );
              if (fixedRateEmailValid) {
                $scope.email.EmailSubject = '';
                $scope.email.EmailBody = '';
                loadFixedRateTable();
                form.$setPristine(true);
              }
              $scope.cancel();
            }
          });
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
