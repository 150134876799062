export const NO_BRANDING_CATEGORY_WARNING = {
  ADVISER: `Sorry! We can’t activate this Adviser 
    because “Branding Category” is not set. 
    Please update this field to proceed with activation.`,
  ADVISER_ORG: `Sorry! We can’t activate this Adviser Organisation because “Branding Category” is not set. 
    Please update this field to proceed with activation.`,
  ADVISER_ORG_OF_ADVISER: `Sorry! We can’t activate this Adviser
    because the Adviser Organisation they belong to, 
    does not have a valid “Branding Category”.
    Please update this field to proceed with activation.`,
};
