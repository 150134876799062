import angular from 'angular';
import _ from 'lodash';
import { setCurrrentDate, setDateFormat } from 'Common/utilities/date';

angular
  .module('app')
  .controller('AdviserRecruitmentCtrl', function AdviserRecruitmentCtrl(
    broadcastDataService,
    corporateService,
    commonFnService,
    contactService,
    generalService,
    dataService,
    queryService,
    mapService,
    $uibModal,
    toaster,
    $filter,
    $state,
    $scope
  ) {
    /* State Params */
    $scope.clientId = $state.params.clientId || 0;
    $scope.familyId = $state.params.familyId || 0;
    /*    VARIABLE(s)    */
    /* Data Type: {Object} */
    $scope.familyRecruitmentDetailsGet = {};
    $scope.editMode = {};
    /* Data Type: {Array} */

    $scope.recruitmentNotProceedingReasonLists = [];
    $scope.recruitmentOnboardingStatusLists = [];
    $scope.adviserPipelineCategories = [];
    $scope.prospectiveRatingLists = [];
    $scope.recruitmentStatusLists = [];
    $scope.perceivedQualityLists = [];
    $scope.inquirySourceLists = [];
    $scope.altInputFormats = [];
    $scope.eDRSchemes = [];
    $scope.setterType = [];
    $scope.adminUsers = [];
    $scope.saveType = [];
    /* Data Type: {String} */
    $scope.dateStaticValue = '1900-01-01';
    $scope.dateFormat = '';

    let watchAdviserRecruitmentEditMode = null;

    /*  FUNCTION REFERENCE      */
    /*  FUNCTION DEFINITION(S)  */
    /**
     *   @name onRetrieveFamilyRecruitmentDetailsSuccess
     *   @desc This will load data from Api: `familyRecruitmentDetailsGet` on success.
     *   @params {response} data from the api result.
     */
    function onRetrieveFamilyRecruitmentDetailsSuccess(response) {
      if ($scope.subMenuVal === 'Recruitment') {
        if (response && response.data) {
          dataService.getAdminUsers().then(adminUsers => {
            if (_.size(adminUsers) > 0) {
              $scope.adminUsers = adminUsers;
            }
            response.data.RecruitedBy_FullName = queryService.getArrValue(
              response.data.RecruitedBy_FamilyId,
              null,
              'FamilyId',
              'FullName',
              $scope.adminUsers
            );
            response.data.RecruitedBy_FullNameTmp = queryService.getArrValue(
              response.data.RecruitedBy_FamilyId,
              null,
              'FamilyId',
              'FullName',
              $scope.adminUsers
            );
          });
          dataService.getRecruitmentStatusLists().then(recruitmentstatus => {
            $scope.recruitmentStatusLists = [];
            if (recruitmentstatus) {
              recruitmentstatus.data &&
                Object.keys(recruitmentstatus.data).forEach(key => {
                  $scope.recruitmentStatusLists.push(
                    recruitmentstatus.data[key]
                  );
                });
              response.data.RecruitmentStatusNameTmp = queryService.getArrValue(
                response.data.RecruitmentStatusId,
                null,
                'RecruitmentStatusID',
                'RecruitmentStatus',
                $scope.recruitmentStatusLists
              );
              response.data.RecruitmentStatusName = queryService.getArrValue(
                response.data.RecruitmentStatusId,
                null,
                'RecruitmentStatusID',
                'RecruitmentStatus',
                $scope.recruitmentStatusLists
              );
            }
          });

          dataService
            .getAdviserPipelineCategory()
            .then(adviserPipelineCategories => {
              if (adviserPipelineCategories.length > 0) {
                $scope.adviserPipelineCategories = adviserPipelineCategories;
                response.data.PipelineCategoryName = queryService.getArrValue(
                  response.data.PipelineCategoryID,
                  null,
                  'APipelineCategoryID',
                  'APipelineCategoryName',
                  $scope.adviserPipelineCategories
                );
              }
            });

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'inquirySourceListGet',
            [],
            'inquirySourceLists',
            response.data,
            'EnquirySourceName',
            'EnquirySourceId',
            'EnquirySourceId',
            'EnquirySource'
          );

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'perceivedQualityListGet',
            [],
            'perceivedQualityLists',
            response.data,
            'PerceivedQualityName',
            'PerceivedQualityId',
            'PerceivedQualityID',
            'PerceivedQuality'
          );

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'prospectiveRatingListGet',
            [],
            'prospectiveRatingLists',
            response.data,
            'ProspectiveRatingName',
            'ProspectiveRatingId',
            'ProspectiveRatingID',
            'ProspectiveRating'
          );

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'recruitmentNotProceedingReasonListGet',
            [],
            'recruitmentNotProceedingReasonLists',
            response.data,
            'NotProceedingReasonsName',
            'NotProceedingReasonsId',
            'RecruitmentNotProceedingReasonsID',
            'RecruitmentNotProceedingReasons'
          );

          queryService.getAllArrayDetails(
            $scope,
            corporateService,
            'recruitmentOnboardingStatusListGet',
            [],
            'recruitmentOnboardingStatusLists',
            response.data,
            'OnboardingStatusName',
            'OnboardingStatusId',
            'RecruitmentOnboardingStatusID',
            'RecruitmentOnboardingStatus'
          );

          $scope.familyRecruitmentDetailsGet = response.data;
        }
      }
    }
    /**
     *   This will load/initialize data needed on load.
     *   @name initialize
     */
    function initialize() {
      dataService.getUserInfo().then(response => {
        $scope.isCorpTeam = response;
      });
      $scope.setterType = dataService.getSetterTypes('Adviser');
      $scope.saveType = dataService.getSaveTypes('Adviser');
      $scope.dateFormat = setDateFormat();
      $scope.altInputFormats = dataService.getDateValidityFormats();
      $scope.currentDate = setCurrrentDate();
      dataService.getAdviserPipelineCategory().then(response => {
        $scope.adviserPipelineCategories = response;
      });
      corporateService
        .familyRecruitmentDetailsGet($scope.familyId, $scope.clientId)
        .then(
          onRetrieveFamilyRecruitmentDetailsSuccess,
          commonFnService.onRetrieveError
        );

      watchAdviserRecruitmentEditMode = $scope.$watch('editModeObj', () => {
        $scope.editMode = $scope.editModeObj;
      });
    }

    $scope.$on('$destroy', () => {
      watchAdviserRecruitmentEditMode();
    });
    /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
    /**
     *   @name querySet
     *   @desc This will save the value from api.
     *   @params {String} setter
     *   @params {String} key
     *   @params {Array} arrays
     *   @params {Boolean} isCorporate
     */
    function querySet(setter, key, field, arrays, isCorporate) {
      $scope.editMode[key] = false;
      commonFnService.setEditMode($scope.editMode, field);
      if (isCorporate) {
        corporateService[setter].apply(null, arrays).then(response => {
          if (response) {
            if (response.status === 200) {
              toaster.pop('success', 'Update', 'successfully updated!');
            }
          }
        });
      }
      toaster.clear();
    }

    $scope.setDateFormatInView = date => {
      return dataService.setDateFormatInView(date);
    };
    /* ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
    $scope.dateEditMode = function(obj, key) {
      return commonFnService.dateEditMode(obj, key, $scope.editMode);
    };

    $scope.formatDateString = function(date) {
      return commonFnService.formatDateString(date);
    };

    $scope.formatDate = function(date) {
      return commonFnService.formatDate(date);
    };

    $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
      return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
    };

    $scope.updateAdviserDetails = function(
      setterType,
      obj,
      key,
      field,
      saveType,
      formName,
      inputName
    ) {
      let dataArray = [];
      if (setterType === 'familyRecruitmentDetailsSet') {
        if (formName && inputName) {
          if (
            !commonFnService.checkFormDateValidation(
              formName,
              inputName,
              obj,
              key
            )
          ) {
            return false;
          }
        } else {
          // inquiry source
          if (key === 'EnquirySourceName') {
            obj.EnquirySourceId = queryService.getArrValue(
              null,
              obj.EnquirySourceName,
              'EnquirySourceId',
              'EnquirySource',
              $scope.inquirySourceLists
            );
            obj.EnquirySourceName = queryService.getArrValue(
              obj.EnquirySourceId,
              null,
              'EnquirySourceId',
              'EnquirySource',
              $scope.inquirySourceLists
            );
          }
          // Perceived Quality
          if (key === 'PerceivedQualityName') {
            obj.PerceivedQualityId = queryService.getArrValue(
              null,
              obj.PerceivedQualityName,
              'PerceivedQualityID',
              'PerceivedQuality',
              $scope.perceivedQualityLists
            );
            obj.PerceivedQualityName = queryService.getArrValue(
              obj.PerceivedQualityId,
              null,
              'PerceivedQualityID',
              'PerceivedQuality',
              $scope.perceivedQualityLists
            );
          }
          // prospective
          if (key === 'ProspectiveRatingName') {
            obj.ProspectiveRatingId = queryService.getArrValue(
              null,
              obj.ProspectiveRatingName,
              'ProspectiveRatingID',
              'ProspectiveRating',
              $scope.prospectiveRatingLists
            );
            obj.ProspectiveRatingName = queryService.getArrValue(
              obj.ProspectiveRatingId,
              null,
              'ProspectiveRatingID',
              'ProspectiveRating',
              $scope.prospectiveRatingLists
            );
          }
          // recruitment
          if (key === 'RecruitmentStatusName') {
            obj.RecruitmentStatusId = queryService.getArrValue(
              null,
              obj.RecruitmentStatusName,
              'RecruitmentStatusID',
              'RecruitmentStatus',
              $scope.recruitmentStatusLists
            );
            obj.RecruitmentStatusName = queryService.getArrValue(
              obj.RecruitmentStatusId,
              null,
              'RecruitmentStatusID',
              'RecruitmentStatus',
              $scope.recruitmentStatusLists
            );
          }
          // not proceeding
          if (key === 'NotProceedingReasonsName') {
            obj.NotProceedingReasonsId = queryService.getArrValue(
              null,
              obj.NotProceedingReasonsName,
              'RecruitmentNotProceedingReasonsID',
              'RecruitmentNotProceedingReasons',
              $scope.recruitmentNotProceedingReasonLists
            );
            obj.NotProceedingReasonsName = queryService.getArrValue(
              obj.NotProceedingReasonsId,
              null,
              'RecruitmentNotProceedingReasonsID',
              'RecruitmentNotProceedingReasons',
              $scope.recruitmentNotProceedingReasonLists
            );
          }
          // onboarding status
          if (key === 'OnboardingStatusName') {
            obj.OnboardingStatusId = queryService.getArrValue(
              null,
              obj.OnboardingStatusName,
              'RecruitmentOnboardingStatusID',
              'RecruitmentOnboardingStatus',
              $scope.recruitmentOnboardingStatusLists
            );
            obj.OnboardingStatusName = queryService.getArrValue(
              obj.OnboardingStatusId,
              null,
              'RecruitmentOnboardingStatusID',
              'RecruitmentOnboardingStatus',
              $scope.recruitmentOnboardingStatusLists
            );
          }
          // recruited by
          if (key === 'RecruitedBy_FullName') {
            if (obj.RecruitedBy_FullName) {
              if (typeof obj.RecruitedBy_FullName === 'object') {
                obj.RecruitedBy_FullName =
                  obj.RecruitedBy_FullName.originalObject.FullName;
                obj.RecruitedBy_FamilyId = queryService.getArrValue(
                  null,
                  obj.RecruitedBy_FullName,
                  'FamilyId',
                  'FullName',
                  $scope.adminUsers
                );
                obj.RecruitedBy_FullNameTmp = queryService.getArrValue(
                  obj.RecruitedBy_FamilyId,
                  null,
                  'FamilyId',
                  'FullName',
                  $scope.adminUsers
                );
              }
            } else {
              obj.RecruitedBy_FullName = '';
              obj.RecruitedBy_FamilyId = 0;
              obj.RecruitedBy_FullNameTmp = '';
            }
          }
          // has two yrs exp
          if (key === 'HaveTwoYrsIndustryExp') {
            obj.HaveTwoYrsIndustryExp = commonFnService.setBooleanToInt(
              obj.HaveTwoYrsIndustryExp
            );
            obj.YearsMortgageAdviser = Number(obj.YearsMortgageAdviser);
          }
          // receive news letter
          if (key === 'ReceiveRecruitNewsLetter') {
            obj.ReceiveRecruitNewsLetter = commonFnService.setBooleanToInt(
              obj.ReceiveRecruitNewsLetter
            );
          }
          // special condition approved
          if (key === 'SpecialConditionsApproved') {
            obj.SpecialConditionsApproved = commonFnService.setBooleanToInt(
              obj.SpecialConditionsApproved
            );
          }
          if (key === 'FranchiseeAwareOfLoanWriter') {
            obj.FranchiseeAwareOfLoanWriter = commonFnService.setBooleanToInt(
              obj.FranchiseeAwareOfLoanWriter
            );
          }
          /* pipline category */
          if (key === 'PipelineCategoryName') {
            obj.PipelineCategoryID = queryService.getArrValue(
              null,
              obj.PipelineCategoryName,
              'APipelineCategoryID',
              'APipelineCategoryName',
              $scope.adviserPipelineCategories
            );
            obj.PipelineCategoryName = queryService.getArrValue(
              obj.PipelineCategoryID,
              null,
              'APipelineCategoryID',
              'APipelineCategoryName',
              $scope.adviserPipelineCategories
            );
          }
        }

        dataArray = [$scope.familyId, $scope.clientId, obj];

        if (dataArray.length > 0) {
          querySet(setterType, key, field, dataArray, true);
        }
        // Ready to Proceed
        if (obj.RecruitmentStatusId === 10) {
          corporateService.setRecruitmentReadyToProceedEmail($scope.familyId);
        }
      }
    };
    /**
     *   Function Call
     */
    initialize();
  });
