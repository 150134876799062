import template from './widgetCheckbox.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    fieldId: '@',
    label: '@',
    value: '<',
    modelValue: '<',
    onUpdate: '&',
  },
};
