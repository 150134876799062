import template from './opportunityNotes.html';
import controller from './opportunityNotesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityId: '<',
    loanId: '<',
    familyId: '<',
    adviserId: '<',
    referralId: '<',
    involvedParties: '<',
  },
};
