import moment from 'moment';

export function getCommissionUpdateData(commission, type) {
  const ExpectedCommissionId = commission.CommissionId;
  const LoanID = commission.LoanId;
  const LoanStructureID = commission.LoanStructureId;
  const DateExpected = commission.expectedDate
    ? moment(commission.expectedDate).format('YYYY-MM-DDThh:mm:ss')
    : null;
  const TypeId = type ? type.Value : null;
  const ExpectedCommission = commission.expectedAmount;
  const IsPaid = commission.isConfirm;
  const DatePaid = commission.confirmDate
    ? moment(commission.confirmDate).format('YYYY-MM-DDThh:mm:ss')
    : null;
  const AmountPaid = commission.isConfirm ? commission.expectedAmount : null;

  return {
    ExpectedCommissionId,
    LoanID,
    LoanStructureID,
    DateExpected,
    TypeId,
    ExpectedCommission,
    IsPaid,
    DatePaid,
    AmountPaid,
  };
}

export function listDataFromObject(object) {
  const {
    ExpectationID: CommissionId,
    LoanID: LoanId,
    LoanStructureID: LoanStructureId,
    AdviserName: BrokerName,
    ClientName,
    TypeName,
    ExpectedCommission: expectedAmount,
    ProviderName: Provider,
    ProviderID: ProviderId,
    FamilyID,
    LoanAmount,
    TotalCount,
  } = object;

  return {
    CommissionId,
    LoanId,
    LoanStructureId,
    BrokerName,
    ClientName,
    TypeName,
    expectedAmount,
    Provider,
    ProviderId,
    LoanAmount,
    FamilyID,
    TotalCount,
  };
}
