import template from './emailAttachmentsField.html';
import controller from './emailAttachmentsFieldCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    placeholder: '@',
    isInvalid: '<',
    validationMessage: '<',
    selectedAttachments: '<',
    onRemoveAttachment: '&',
  },
};
