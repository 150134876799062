import template from './pipelineDropboxLead.html';
import './style.scss';

export default {
  template,
  bindings: {
    kanbanSortOptions: '<',
    cardViewStates: '<',
    cardApprove: '=',
    cardOpportunity: '=',
    cardNotProceeding: '=',
  },
};
