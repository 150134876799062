import template from './providerInfoDownloadables.html';
import controller from './providerInfoDownloadablesCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    downloadables: '<',
    onFavoriteStatusChange: '&',
    onDeleteDoc: '&',
  },
};
