import { ANNOUNCEMENTS } from 'Common/constants/announcements';

class AnnouncementTryItOut {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;

    this.announcementActions = [
      {
        id: ANNOUNCEMENTS.MAX_LVR,
        is_route: true,
        action: {
          route: 'app.loanTools',
          params: { activeTab: 'maximum-lvr' },
        },
      },
      {
        id: ANNOUNCEMENTS.QUICK_LMI,
        is_route: false,
        action: this.uiService.redirectToQuickLMICalculator,
      },
    ];
  }
}

export default AnnouncementTryItOut;
