import { existingReferrerDetailsBuilderForUI } from 'Common/mappers/existingReferrerDetails';

class ReferralService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Referral';
  }

  existingReferrerDetails(referrerEmail) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/existing-referrer-details`, { referrerEmail })
      .then(response => response.data.map(existingReferrerDetailsBuilderForUI));
  }

  referralAssetsFinanceSet(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/asset-finance`, data);
  }
}

export default ReferralService;
