import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular
  .module('app')
  .controller('LoanAppLoanDetailsCtrl', function LoanAppLoanDetailsCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    optionsService,
    SweetAlert,
    loanAppSharedData,
    fundingCalculatorService,
    loanProfilerService,
    $window,
    loanAppDetailsService,
    $localStorage
  ) {
    const lenderPlace = 1;
    $scope.loanDetailsSet = {};
    $scope.LoanDetailsSet = {};
    $scope.LoanApplicationCQPFeeStructureGet = [];
    $scope.LoanApplicationCQPReferralFeeStructureGet = [];
    $scope.loanDetailsSet.LoanFacility = [];
    $scope.loanAppId = $stateParams.loanAppId;

    $scope.validateLoanDetails = () => {
      $scope.isValidLoanDetails =
        // ($scope.loanDetailsSet.LoanAmount !== null && $scope.loanDetailsSet.LoanAmount !== '') &&
        $scope.FundingCalculatorDetails.TotalProposedLending !== null &&
        $scope.FundingCalculatorDetails.TotalProposedLending !== '' &&
        ($scope.loanDetailsSet.LenderId !== null &&
          $scope.loanDetailsSet.LenderId !== '') &&
        ($scope.loanDetailsSet.ReasonForSelectedLender !== null &&
          $scope.loanDetailsSet.ReasonForSelectedLender !== '') &&
        ($scope.loanDetailsSet.ABSPurpose !== null &&
          $scope.loanDetailsSet.ABSPurpose !== '') &&
        $scope.loanDetailsSet.LoanFacility.length > 0 &&
        ($scope.loanDetailsSet.ReasonForLoanFacility !== null &&
          $scope.loanDetailsSet.ReasonForLoanFacility !== '');
      $scope.isStartedLoanDetails =
        ($scope.loanDetailsSet.LoanAmount !== null &&
          $scope.loanDetailsSet.LoanAmount !== '' &&
          !_.isUndefined($scope.loanDetailsSet.LoanAmount)) ||
        ($scope.loanDetailsSet.LenderId !== null &&
          $scope.loanDetailsSet.LenderId !== '' &&
          !_.isUndefined($scope.loanDetailsSet.LenderId)) ||
        ($scope.loanDetailsSet.ReasonForSelectedLender !== null &&
          $scope.loanDetailsSet.ReasonForSelectedLender !== '' &&
          !_.isUndefined($scope.loanDetailsSet.ReasonForSelectedLender)) ||
        ($scope.loanDetailsSet.ABSPurpose !== null &&
          $scope.loanDetailsSet.ABSPurpose !== '' &&
          !_.isUndefined($scope.loanDetailsSet.ABSPurpose)) ||
        $scope.loanDetailsSet.LoanFacility.length > 0 ||
        ($scope.loanDetailsSet.ReasonForLoanFacility !== null &&
          $scope.loanDetailsSet.ReasonForLoanFacility !== '' &&
          !_.isUndefined($scope.loanDetailsSet.ReasonForLoanFacility));

      $scope.countValids($scope.isValidLoanDetails, 'loanDetails');
    };

    /**
     *  Validates every update on object
     */
    $scope.$watch(
      'loanDetailsSet',
      () => {
        loanAppSharedData.lenderId = $scope.loanDetailsSet.LenderId; // set lender id
        $scope.validateLoanDetails();
      },
      true
    );

    $scope.$watch('LoanApplicationCQPFeeStructureGet', () => {
      $scope.validateLoanDetails();
    });

    $scope.$watch('LoanApplicationCQPReferralFeeStructureGet', () => {
      $scope.validateLoanDetails();
    });

    $scope.getLenderId = () => {
      $scope.loanDetailsSet.LenderId = loanAppSharedData.lenderId;
      return loanAppSharedData.lenderId;
    };

    /**
     *   Loan Details Codes Below
     */
    $scope.loanDetailsInit = () => {
      $scope.listLoanFacilities = [];
      $scope.loanDetailsGet();
      contactService.lenderListGet(undefined, lenderPlace).then(response => {
        $scope.lenderList = response.data;
      });

      optionsService.absPurpose().then(response => {
        $scope.absPurposeList = response.data;
      });
    };

    $scope.loanDetailsGet = () => {
      loanScenarioService.loanDetailsGet($scope.loanAppId).then(response => {
        $scope.loanDetailsSet = response.data;
        $scope.loanDetailsSet.LoanScenarioId = $scope.loanAppId;
        if ($scope.loanDetailsSet.LoanAmount < 1) {
          $scope.loanDetailsSet.LoanAmount = null;
        }

        if (
          $scope.loanDetailsSet.LenderId &&
          !_.isUndefined($scope.loanDetailsSet.LenderId)
        ) {
          $scope.loanDetailsSet.LenderId = String(
            $scope.loanDetailsSet.LenderId
          );
        } else {
          $scope.loanDetailsSet.LenderId = null;
        }
        if ($scope.loanDetailsSet && $scope.loanDetailsSet.ABSPurposeId) {
          $scope.loanDetailsSet.ABSPurposeId = String(
            $scope.loanDetailsSet.ABSPurposeId
          );
        } else {
          $scope.loanDetailsSet.ABSPurposeId = null;
        }

        // Set ReasonForSelectedLender
        if (_.isUndefined($scope.loanDetailsSet.ReasonForSelectedLender)) {
          $scope.loanDetailsSet.ReasonForSelectedLender = null;
        }
        // Set ReasonForLoanFacility
        if (_.isUndefined($scope.loanDetailsSet.ReasonForLoanFacility)) {
          $scope.loanDetailsSet.ReasonForLoanFacility = null;
        }
      });
    };

    $scope.loanDetailsSetMethod = () => {
      if (!$scope.loanDetailsSet.ABSPurposeId) {
        $scope.loanDetailsSet.ABSPurposeId = null;
      }
      const loanDetailsParam = _.omit($scope.loanDetailsSet, 'LoanFacility');
      loanScenarioService.loanDetailsSet(loanDetailsParam).then(() => {
        $scope.loanDetailsGet();
      });
    };

    $scope.deleteFacility = loanFacilityId => {
      loanScenarioService
        .loanFacilityDelete($scope.loanAppId, loanFacilityId)
        .then(() => {
          SweetAlert.swal(
            {
              text: 'This record will be removed from your loan facility',
              title: 'Are you sure?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: true,
            },
            confirm => {
              if (confirm) {
                $scope.loanDetailsGet();
              }
            }
          );
        });
    };

    $scope.updateLoanDetailsField = (type, dataValue) => {
      $scope.getLoanApplicationCQP();
      if (!_.isUndefined(dataValue)) {
        $scope.loanDetailsSet.LenderId = dataValue;
        loanAppSharedData.lenderId = dataValue;
      }
      $scope.loanDetailsSetMethod(`Successfully set ${type}`);
    };

    $scope.CQPSet = () => {
      contactService
        .setLoanApplicationCQP($scope.loanAppId, $scope.LoanDetailsSet.Lender)
        .then(applicationCQPresponse => {
          if (applicationCQPresponse) {
            $scope.getLoanApplicationCQP();
          } else {
            toaster.pop('error', 'Error', 'Something went wrong');
          }
        });
    };

    // Add Related Modal
    $scope.openAddLoanStructureModal = loanStructureId => {
      $scope.loanStructureId = loanStructureId;

      $uibModal.open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/newLoanStructureModal.html',
        controller($uibModalInstance, selectOptionsService) {
          $scope.loanProductList = [];
          $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
          };

          optionsService
            .loanProductList($scope.loanDetailsSet.LenderId)
            .then(response => {
              $scope.loanProductList = response.data;
            });

          selectOptionsService.getLoanTerm().then(response => {
            $scope.loanTermSelectOptions = response.data;
          });

          optionsService.getLoanTerms().then(loanTermResponse => {
            $scope.loanTermList = loanTermResponse;
          });

          optionsService.FinancialFrequency().then(response => {
            $scope.loanRepaymentFreqSelectOptions = response.data;
          });

          $scope.LoanStructureSet = {};
          $scope.LoanStructureSet.LoanStructureId =
            typeof $scope.loanStructureId === 'undefined'
              ? 0
              : $scope.loanStructureId;
          $scope.LoanStructureSet.loanScenarioId =
            $scope.loanDetailsSet.LoanScenarioId;
          $scope.getLoanFacility = () => {
            loanScenarioService
              .loanFacilityGet($scope.loanAppId, $scope.loanStructureId)
              .then(response => {
                $scope.LoanStructureSet = response.data;
                $scope.LoanStructureSet.ProductId = String(
                  $scope.LoanStructureSet.ProductId
                );
                $scope.LoanStructureSet.StatementCycleId = String(
                  $scope.LoanStructureSet.StatementCycleId
                );
                $scope.LoanStructureSet.LoanTerm =
                  $scope.LoanStructureSet.LoanTerm;
                $scope.LoanStructureSet.InterestOnlyTerm = String(
                  $scope.LoanStructureSet.InterestOnlyTerm
                );
                $scope.LoanStructureSet.loanScenarioId =
                  $scope.loanDetailsSet.LoanScenarioId;
                $scope.AddLMI =
                  $scope.LoanStructureSet.LMIPremium ||
                  $scope.LoanStructureSet.CapitaliseLMI;
                $scope.baseAndLMIChange();
                // select the owner
                _.forEach($scope.LoanStructureSet.Owner, obj => {
                  $scope.onItemSelect(obj);
                });
              });
          };

          $scope.LoanStructureSet.loanScenarioId =
            $scope.loanDetailsSet.LoanScenarioId;
          $scope.baseAndLMIChange = () => {
            if ($scope.AddLMI && $scope.LoanStructureSet.CapitaliseLMI)
              $scope.TotalLoanAmountCalculation =
                _.parseInt($scope.LoanStructureSet.LMIPremium) +
                _.parseInt($scope.LoanStructureSet.BaseLoanAmount);
            else
              $scope.TotalLoanAmountCalculation = _.parseInt(
                $scope.LoanStructureSet.BaseLoanAmount
              );
          };

          $scope.addLoanStructure = () => {
            $scope.LoanStructureSet.Owner = _.filter(
              $scope.ownersListSelected,
              obj => {
                return obj.ticked === true;
              }
            );
            $scope.cancel();
            loanScenarioService
              .loanFacilitySet($scope.LoanStructureSet)
              .then(() => {
                $scope.loanDetailsGet();
              });
          };

          $scope.modalType = false;
          //* ************************************/
          //  owners multi Select                /
          //* ************************************/
          $scope.localLang = {
            selectAll: '<span>Select All</span>',
            selectNone: '<span>Deselect All </span>',
            reset: "<i class='fa fa-refresh'></i>",
            search: 'Search',
            nothingSelected: '', // default-label is deprecated and replaced with this.
          };
          $scope.ownersList = [];
          $scope.ownersListSelected = [];
          $scope.OwnersGet = () => {
            loanAppDetailsService
              .ownersGet($state.params.loanAppId)
              .then(respond => {
                $scope.ownersListSelected = [];
                $scope.ownersList = respond.data;
                // will get loan facility details if structureid is present
                if ($scope.loanStructureId) {
                  $scope.getLoanFacility();
                  // setting default value
                } else {
                  $scope.LoanStructureSet.LoanTerm = 30;
                  $scope.LoanStructureSet.StatementCycleId = 4;
                  $scope.onSelectAll();
                }
              });
          };

          $scope.OwnersGet();
          $scope.onItemSelect = data => {
            _.forEach($scope.ownersList, (obj, index) => {
              if (data.BorrowerID === obj.BorrowerID) {
                $scope.ownersList[index].ticked = true;
                $scope.ownersList[index].IsInclude = true;
              }
              if (data.ClientEntityId === obj.ClientEntityId) {
                $scope.ownersList[index].ticked = true;
                $scope.ownersList[index].IsInclude = true;
              }
            });
          };

          $scope.ownersListSelectedArray = [];

          $scope.onSelectAll = () => {
            for (let i = 0; i < $scope.ownersList.length; i++) {
              if ($scope.ownersList[i].ClientEntityId > -1) {
                $scope.ownersList[i].ticked = false;
              } else {
                $scope.ownersList[i].ticked = true;
              }
            }
          };

          $scope.onSelectNone = () => {
            if ($scope.ownersList && $scope.ownersList.length) {
              $scope.ownersList.forEach(elem => {
                elem.ticked = false;
              });
            }
          };

          $scope.$watch('ownersListSelected', () => {
            $('.hide-in-mobile .multiSelect')
              .nextAll('div.buttonLabel')
              .remove();
            if ($scope.ownersListSelected.length >= 3) {
              $('.multiSelect .buttonLabel:last-child()').html(
                `${$scope.ownersListSelected.length - 2} more ...` +
                  `<span class="caret"></span>`
              );
            }
            $(
              '.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))'
            ).each(e => {
              $(e.currentTarget).html(
                `${$(
                  e.currentTarget
                ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`
              );
            });
          });
        },
        scope: $scope,
        size: 'sm',
      });
    };
    $scope.loanDetailsInit();

    /**
     *   CQP Codes Below
     */
    $scope.initFeeStructure = () => {
      return {
        LoanApplicationID: $scope.loanAppId,
        LoanApplicationCQPFeeID: 0,
        FeeTypeID: 0,
        Amount: null,
      };
    };

    $scope.initReferralFeeStructure = () => {
      return {
        LoanApplicationID: $scope.loanAppId,
        LoanApplicationCQPReferralFeeID: 0,
        RefereeName: '',
        ReferralFeeAmount: null,
      };
    };

    // getLoanApplicationCQP function
    $scope.getLoanApplicationCQP = () => {
      loanScenarioService
        .CQPCommissionInfoGet($scope.loanAppId)
        .then(CQPCommissionInfoGetResponse => {
          if (
            CQPCommissionInfoGetResponse &&
            CQPCommissionInfoGetResponse.data
          ) {
            $scope.CQP.CQPGet = CQPCommissionInfoGetResponse.data;
            if (!angular.isUndefined($scope.CQP.CQPGet.LenderID)) {
              $scope.CQP.CQPGet.LenderID = $scope.CQP.CQPGet.LenderID.toString();
              $scope.LoanDetailsSet.Lender = $scope.CQP.CQPGet.LenderID.toString();
            } else {
              $scope.CQP.CQPGet.LenderID = '';
              $scope.LoanDetailsSet.Lender = '';
            }
          }
        });
    };

    $scope.getLoanApplicationCQPFeeStructure = () => {
      loanScenarioService
        .getLoanApplicationCQPFeeStructure($scope.loanAppId)
        .then(response => {
          $scope.LoanApplicationCQPFeeStructureGet = response.data;
        });
    };

    $scope.getLoanApplicationCQPReferralFeeStructure = () => {
      loanScenarioService
        .getLoanApplicationCQPReferralFeeStructure($scope.loanAppId)
        .then(response => {
          $scope.LoanApplicationCQPReferralFeeStructureGet = response.data;
          //
        });
    };

    // when
    $scope.selectCQPFreq = ['Weekly', 'Fortnightly', 'Monthly'];

    // Set displayFeesForm to false
    $scope.displayFeesForm = false;
    $scope.displayReferralFeesForm = false;

    // Add another is clicked
    $scope.showAddEditStructureFeesForm = (action, data, platform) => {
      $scope.displayFeesForm = !$scope.displayFeesForm;
      if (platform === 'desktop') {
        if (action === 'edit') {
          data.isEditing = !data.isEditing;
          $scope.isFeeStructureEditingMode = true;

          $scope.CQP.FeeStructure = { ...$scope.CQP.FeeStructure, ...data };
          $scope.CQP.FeeStructure.FeeTypeID = $scope.CQP.FeeStructure.FeeTypeID.toString();
        } else if (action === 'cancel') {
          const editingObj = _.find(
            $scope.LoanApplicationCQPFeeStructureGet,
            'isEditing'
          );
          if (editingObj) editingObj.isEditing = false;
          //
          //

          $scope.isFeeStructureEditingMode = false;
          $scope.CQP.FeeStructure = $scope.initFeeStructure();
        }
      } else if (platform === 'mobile') {
        data.isEditing = !data.isEditing;
      }
    };

    // Add another is clicked
    $scope.showAddEditReferralFeesForm = (action, obj, platform) => {
      $scope.displayReferralFeesForm = !$scope.displayReferralFeesForm;
      if (platform === 'desktop') {
        if (action === 'edit') {
          obj.isEditing = !obj.isEditing;
          $scope.isReferralFeeStructureEditingMode = true;

          angular.extend($scope.CQP.ReferralFeeStructure, obj);
        } else if (action === 'cancel') {
          const editingObj = _.find(
            $scope.LoanApplicationCQPReferralFeeStructureGet,
            'isEditing'
          );
          if (editingObj) editingObj.isEditing = false;
          //
          //

          $scope.isReferralFeeStructureEditingMode = false;
          $scope.CQP.ReferralFeeStructure = $scope.initReferralFeeStructure();
        }
      } else if (platform === 'mobile') {
        obj.isEditing = !obj.isEditing;
      }
    };

    /**
     * submit the form
     * */
    $scope.submitNewStructureFees = (form, advancedOptions, platform, obj) => {
      if (form.$valid) {
        if (platform === 'mobile') {
          obj.LoanApplicationID = $scope.loanAppId;
          obj.Amount = parseInt(obj.Amount, 10);
        }
        const dataCQP = platform === 'desktop' ? $scope.CQP.FeeStructure : obj;
        loanScenarioService
          .setLoanApplicationCQPFeeStructure(dataCQP)
          .then(setLoanApplicationResponse => {
            if (setLoanApplicationResponse && setLoanApplicationResponse.data) {
              $scope.getLoanApplicationCQPFeeStructure();

              if (platform === 'desktop')
                $scope.showAddEditStructureFeesForm('cancel', {}, 'desktop');
              else obj.isEditing = false;
            } else toaster.pop('error', 'Error', 'Error Adding Fee Structure.');
          });
      }
    };

    /**
     * submit the form
     * */
    $scope.submitNewReferralFees = (form, advancedOptions, platform, obj) => {
      if (form.$valid) {
        if (platform === 'mobile') {
          obj.Amount = parseInt(obj.Amount, 10);
          obj.LoanApplicationID = $scope.loanAppId;
        }

        loanScenarioService
          .setLoanApplicationCQPReferralFeeStructure(
            platform === 'desktop' ? $scope.CQP.ReferralFeeStructure : obj
          )
          .then(response => {
            if (response.data) {
              $scope.getLoanApplicationCQPReferralFeeStructure();

              if (platform === 'desktop')
                // $scope.CQP.ReferralFeeStructure = $scope.initReferralFeeStructure();
                $scope.showAddEditReferralFeesForm('cancel', {}, 'desktop');
              else obj.isEditing = false;
            } else
              toaster.pop(
                'error',
                'Error',
                'Error Adding Referral Fee Structure.'
              );
          });
      }
    };

    /**
     * Delete
     */
    $scope.deleteStructure = (type, id) => {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: `This record will be removed from your ${
            type === 'FeeStructure' ? 'Fee Structure' : 'Referral Fee Structure'
          }`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            if (type === 'FeeStructure') {
              loanScenarioService
                .deleteLoanApplicationCQPFeeStructure($scope.loanAppId, id)
                .then(() => {
                  $scope.getLoanApplicationCQPFeeStructure();
                });
            } else {
              loanScenarioService
                .deleteLoanApplicationCQPReferralFeeStructure(
                  $scope.loanAppId,
                  id
                )
                .then(() => {
                  $scope.getLoanApplicationCQPReferralFeeStructure();
                });
            }
          }
        }
      );
    };

    // Filters initials (First and Last Word)
    $scope.filterInitial = str => {
      let initials = '';
      const stringArray = str.split(/(\s+)/);

      let i = 0; // first word
      initials = stringArray[i].charAt(0).toUpperCase();

      i = stringArray.length - 1; // last word
      if (i > 0) initials += stringArray[i].charAt(0).toUpperCase();

      return initials;
    };

    // Lender Changed (watch)
    $scope.$watch('CQP.CQPGet.LenderID', newVal => {
      if (typeof newVal !== 'undefined' && newVal !== 0) {
        //
        const lender = _.find($scope.lenderListGet, o => {
          return o.ProviderId === newVal;
        });
        if (lender) {
          let name = lender.ProviderName.toLowerCase();
          name = name.replace(' ', '_');

          $scope.CQP.LenderLogo = name;
        }
      }
    });

    $scope._init = () => {
      $scope.CQP = {
        FeeStructure: $scope.initFeeStructure(),
        ReferralFeeStructure: $scope.initReferralFeeStructure(),
      };

      // lender
      contactService
        .lenderListGet(undefined, lenderPlace)
        .then(lenderListGetResponse => {
          if (lenderListGetResponse && lenderListGetResponse.data) {
            $scope.lenderListGet = lenderListGetResponse.data;
            $scope.CQP.LenderLogo = '';
          }
        });

      // Loan Application
      $scope.getLoanApplicationCQP();

      $scope.getLoanApplicationCQPFeeStructure();

      $scope.getLoanApplicationCQPReferralFeeStructure();

      // Adviser list
      contactService.taskAdviserListGet().then(response => {
        $scope.CQP.ReferTo = response.data[0];
        $scope.ReferToList = response.data;
      });

      $scope.FundingCalculatorDetails = {};
      $scope.hasFundingCalc = false;
      // Funding Calculator
      loanScenarioService
        .linkedFundingCalculatorGet($scope.loanAppId)
        .then(linked => {
          const brokerEventId = linked.data;

          if (brokerEventId) {
            loanScenarioService
              .fundingCalculatorDetailsGet(brokerEventId)
              .then(response => {
                $scope.FundingCalculatorDetails = response.data;

                $scope.hasFundingCalc = true;
              });
          }
        });
    };

    // init
    $scope._init();

    $scope.setSessionStorage = () => {
      $window.sessionStorage.setItem(
        'loanApplication',
        $state.params.loanAppId
      );
      $window.sessionStorage.setItem(
        'loanApplicationContact',
        $state.params.familyId
      );
    };

    $scope.goToFundsCalc = () => {
      $scope.setSessionStorage();
      $scope.redirectToFundingCalculator();
    };

    $scope.goToLoanProfiler = tabName => {
      $scope.setSessionStorage();
      $localStorage.contactLoanCalculatorLinker = {};
      loanAppDetailsService.goToLoanProfiler($scope.loanAppId, tabName);
    };

    $scope.hasProductSelection = false;
    $rootScope.$on('HAS_PRODUCT_SELECTION_DATA', () => {
      $scope.hasProductSelection = true;
    });
  });
