export function dailyDigestBuilderForUI(data) {
  return {
    notificationSettingId: data.NotificationSettingID,
    notificationSetting: data.NotificationSetting,
    description: data.Description,
    isEnabled: data.IsEnabled,
  };
}

export function userSurveyBuilderForUI(data) {
  return {
    surveyId: data.SurveyID,
    title: data.Title,
    subTitle: data.SubTitle,
    description: data.Description,
    startDate: data.StartDate,
    endDate: data.EndDate,
    embeddedUrl: data.EmbeddedURL,
  };
}
