import './style.scss';
import template from './fileUpload.html';
import FileUploadCtrl from './fileUploadCtrl';

export default {
  template,
  bindings: {
    onCompleteAll: '&', // not required | {expression} // contains `files`
    multiple: '<', // not required | {boolean} will tell if you allow multiple upload
    extensions: '<', // not required | {array} of extensions you wish to accept
    url: '<', // not required | {string}
    familyId: '<', // not required | {string}
    uploadType: '<', // not required | {string}
    autoUpload: '<', // not required | {boolean}
    invalidFileInfo: '=', // not required | {object} eg. {title: 'Bad file type', description: 'Should accept PNG'}
    noUrlParams: '<', // not required | {string}
    parentToChildNotificationRegistration: '&', // not required | {expression}
    onUploading: '&',
  },
  controller: FileUploadCtrl,
  controllerAs: 'vm',
};
