import { getOpportunityStatus } from 'Common/utilities/loanOpportunity';
import { opportunityConvert } from 'Common/utilities/opportunityConversion';
import { flatMap } from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import toastr from 'toastr';

class OpportunityCtrl {
  constructor(
    outstandingRequirementService,
    loanOpportunityService,
    loanScenarioService,
    currentUserService,
    pipelineSharedData,
    modalRenderService,
    clientInfoService,
    pipelineService,
    contactService,
    configService,
    $stateParams,
    $uibModal,
    toaster,
    $state
  ) {
    'ngInject';

    this.outstandingRequirementService = outstandingRequirementService;
    this.loanOpportunityService = loanOpportunityService;
    this.currentUserService = currentUserService;
    this.loanScenarioService = loanScenarioService;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.clientInfoService = clientInfoService;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
    this.configService = configService;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
    this.$state = $state;
  }

  $onInit() {
    this.checkSourceOpener();

    this.familyId = this.$stateParams.sourceFamilyId || null;
    this.loanId = this.$stateParams.loanId || 0;
    this.opportunityId = this.$stateParams.opportunityId || 0;
    this.isBusiness = this.$stateParams.isBusiness || false;
    this.isShowOpportunityFactFind =
      this.configService.feature &&
      this.configService.feature.opportunityFactFind;

    const { tabName } = this.$stateParams;
    this.tabs = [
      'details',
      'calculations',
      'scenarios',
      'communications',
      'notes',
      'activity',
    ];
    this.activeTab = this.tabs.indexOf(tabName) || 0;

    if (!this.opportunityId || !this.loanId) {
      this.$state.go('app.dashboard');
      return;
    }

    this.loanOpportunityService
      .getLoanOpportunityGeneral(this.opportunityId)
      .then(res => {
        if (!res) {
          this.$state.go('app.dashboard');
          return;
        }
        this.opportunityGeneral = res;
      });

    getOpportunityStatus(this.pipelineService).then(statusList => {
      this.statusList = statusList;
    });

    this.outstandingRequirementService.get(this.loanId).then(data => {
      this.requirementsList = data;
    });

    this.loanOpportunityService.getReasonSubstatus().then(res => {
      if (!res.data) return;
      const { data } = res;
      this.reasonList = data;
    });

    this.getDetails();
  }

  getDetails() {
    this.loanOpportunityService
      .getLoanOpportunityDetails(this.opportunityId)
      .then(res => {
        const { data } = res;
        if (!res || !data) return;

        const opportunityDetails = data;

        const { Summary } = opportunityDetails;
        if (!Summary) return;

        const { PrimaryPurpose } = Summary;
        if (!PrimaryPurpose) {
          const QUESTIONDATA = {
            PURPOSE: { QUESTIONID: 5, QUESTIONSECTIONID: 0 },
          };
          const DISPLAYDATA = { MULTIDROPDOWN: 6 };

          Summary.PrimaryPurpose = {
            QuestionSectionId: QUESTIONDATA.PURPOSE.QUESTIONSECTIONID,
            QuestionId: QUESTIONDATA.PURPOSE.QUESTIONID,
            DisplayOptionId: DISPLAYDATA.MULTIDROPDOWN,
            answerInt: [],
          };
        }

        const {
          ReferralCategoryId,
          ReferralItemId,
          ReferrerId,
          ReferrerOrgId,
          ReferrerName,
          ReferrerOrgName,
          ReferrerAgreementTypeId,
        } = Summary;
        Summary.EnquirySource = {
          ReferralCategoryId,
          ReferralItemId,
          ReferrerId,
          ReferrerOrgId,
          ReferrerName,
          ReferrerOrgName,
          ReferrerAgreementTypeId,
        };

        delete Summary.ReferralCategoryId;
        delete Summary.ReferralItemId;
        delete Summary.ReferrerId;
        delete Summary.ReferrerOrgId;

        const { InvolvedParties } = opportunityDetails;
        InvolvedParties.InvolvedPartyPerson = flatMap(
          InvolvedParties.InvolvedPartyPerson
        );
        InvolvedParties.PersonBorrowers = InvolvedParties.InvolvedPartyPerson.filter(
          person => !person.IsGuarantor
        );
        InvolvedParties.RelatedParty = InvolvedParties.InvolvedPartyPerson.filter(
          person => person.IsGuarantor
        );

        if (this.familyId) {
          const sourceFamilyNames = [];
          const {
            InvolvedPartyEntity,
            PersonBorrowers,
            RelatedParty,
          } = InvolvedParties;

          const involvedPartyList = {
            InvolvedPartyEntity,
            PersonBorrowers,
            RelatedParty,
          };

          Object.keys(involvedPartyList).forEach(key => {
            involvedPartyList[key] &&
              involvedPartyList[key].forEach(party => {
                if (party.FamilyId === this.familyId) {
                  const fullName = party.OrganisationId
                    ? party.FamilyFullName
                    : `${party.FirstName} ${party.LastName}`;
                  sourceFamilyNames.push(fullName);
                }
              });
          });

          if (sourceFamilyNames.length)
            this.sourceFamilyName = sourceFamilyNames.join(' & ');
        }

        this.opportunityDetails = opportunityDetails;
      });
  }

  setGeneralInfo() {
    this.loanOpportunityService
      .setLoanOpportunityGeneral(this.opportunityId, this.opportunityGeneral)
      .then(data => {
        if (!data) return;

        this.opportunityGeneral = data;
        toastSaveSuccess(this.toaster);
      });
  }

  changeGeneralInfo(objValue) {
    this.opportunityGeneral = Object.assign({}, this.opportunityGeneral, {
      ...objValue,
    });
    this.setGeneralInfo();
  }

  setAssistantAdiviser(assistantId) {
    this.loanScenarioService.assignAssistant(this.opportunityId, assistantId);
    toastr.success('Assistant adviser is updated!', 'Success');
  }

  changeStatus() {
    const { pipelineStatusId } = this.opportunityGeneral;
    const statusSelected =
      this.statusList.find(
        status => status.PipelineStatusID === pipelineStatusId
      ) || {};
    const { IsConversionStatus } = statusSelected;

    if (!IsConversionStatus) {
      this.opportunityGeneral.pipelineStatusIdBak = pipelineStatusId;
      this.loanOpportunityService
        .setLoanOpportunityStatus(this.loanId, pipelineStatusId)
        .then(() => {
          toastSaveSuccess(this.toaster);
        });
    } else {
      opportunityConvert(
        this.pipelineSharedData,
        this.modalRenderService,
        this.loanOpportunityService,
        pipelineStatusId,
        this.opportunityId,
        this.reasonList
      ).then(res => {
        if (res && res.isOk) {
          toastr.success('Card successfully converted!', 'Success');
          this.$state.go('app.pipeline', { pipelineType: 'opportunity' });
        } else {
          this.opportunityGeneral.pipelineStatusId = this.opportunityGeneral.pipelineStatusIdBak;
        }
      });
    }
  }

  checkSourceOpener() {
    const isContactOpener =
      (this.$stateParams.isContactOpener &&
        !!parseInt(this.$stateParams.isContactOpener, 10)) ||
      false;
    this.isContactOpener = isContactOpener;
    this.isPipelineOpener = !isContactOpener;
  }

  addRequirementModal(requirement) {
    const { InvolvedParties } = this.opportunityDetails;
    const {
      InvolvedPartyEntity,
      PersonBorrowers,
      RelatedParty,
    } = InvolvedParties;

    const involvedPartyList = {
      InvolvedPartyEntity,
      PersonBorrowers,
      RelatedParty,
    };

    const clients = [];
    Object.keys(involvedPartyList).forEach(partyType => {
      const flatClients = involvedPartyList[partyType].reduce(
        (accum, currentVal) => {
          const {
            FamilyId,
            FamilyFullName: FullName,
            PreferredName,
            FirstName,
            LastName,
            PersonId,
            OrganisationId,
          } = currentVal;
          const client = {
            FamilyId,
            FullName: PersonId
              ? `${PreferredName || FirstName} ${LastName}`.trim()
              : FullName,
            IsClient: !!parseInt(PersonId, 10),
            PersonId: PersonId || OrganisationId,
          };

          return accum.concat(client);
        },
        []
      );

      clients.push(...flatClients);
    });

    this.outstandingRequirementService.modal(
      this.loanId,
      requirement,
      clients,
      this.requirementsList
    );
  }

  completeRequirement(requirementObj) {
    this.outstandingRequirementService.complete(requirementObj, this.loanId);
  }

  redirectContact() {
    this.$state.go(
      `app.${this.isBusiness ? 'BusinessAccount' : 'contactsSingle'}`,
      { familyId: this.familyId, activeTab: 'lending' }
    );
  }
}

export default OpportunityCtrl;
