import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'sweetalert';
import { DATE_RANGE } from 'Common/constants/dateRange';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import {
  revertMoveCardToOtherColumn,
  updateColumnTotalRecords,
  closeLabelPopover,
} from 'Common/utilities/kanbanHelper';
import { PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';

angular
  .module('app')
  .controller('PipelineAppCtrl', function PipelineAppCtrl(
    $scope,
    $rootScope,
    $uibModal,
    $q,
    dashboardService,
    $timeout,
    pipelineService,
    contactService,
    $stateParams,
    $localStorage,
    toaster,
    NgTableParams,
    moment,
    pipelineSharedData,
    utilitiesService,
    $state,
    commonFnService,
    pipelineCardsService,
    uiService,
    $window,
    modalRenderService,
    stateService
  ) {
    let familyIDToPass = $state.params.familyId;
    if (!familyIDToPass) {
      familyIDToPass = 0;
    }
    $scope.hiddenClientForNz = false;
    commonFnService.hiddenClientForNz().then(response => {
      $scope.hiddenClientForNz = response;
    });
    $scope.filterTouched = false;
    const pageSize = 14;
    const isLeads = false;
    $scope.sortType = '';
    $scope.sortColumn = '';
    $scope.resolvedData = [];
    $scope.pipelineSharedData = pipelineSharedData;
    $scope.pipelineSharedData.validateFilters();
    $scope.$watch('pipelineSharedData.dataStatus.isUpdatePipelineLead', nv => {
      if (nv) {
        pipelineSharedData.dataStatus.isUpdatePipelineApp = false;
        $scope._initListViewApp();
      }
    });

    $scope.checkFilter = mode => {
      if (
        !$scope.pipelineSharedData ||
        !$scope.pipelineSharedData.leadFilterData ||
        !mode
      )
        return;
      const {
        PipelineStatusIDs: pipelineStatusIds,
        adviserId,
        labelId,
      } = $scope.pipelineSharedData.leadFilterData;
      const haveCardsFilter = adviserId || labelId;
      const haveListViewFilter = pipelineStatusIds.length || haveCardsFilter;

      $scope.isShowListViewFilterApp =
        mode === 'list_view' && haveListViewFilter;
      $scope.showFilterForCardsApp = mode !== 'list_view' && haveCardsFilter;
    };

    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'PipelineAppDemoVideoModalCtrl',
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.colorPickerPopover = {
      content: '',
      templateUrl: 'colorPickerPopover.html',
      templateUrlForLabel: 'colorPickerPopoverForLabel.html',
      title: '',
    };
    $scope.colorPickerPopoverListView = {
      content: '',
      templateUrl: 'colorPickerPopoverForLabelListView.html',
      templateUrlForLabel: 'colorPickerPopoverListView.html',
      title: '',
      rightSide: 'right',
      bottomSide: 'bottom',
    };
    $('.pipelineContainer ').on('click', eve => {
      const popoverArea = $('.colorPickerPopoverWrapper');
      const popovers = angular.element('.popover.fade.in');
      const popoverAreaListView = $('.colorPickerPopoverWrapperListView');
      const elmParent = $(eve.target).closest(
        '.colorPickerPopoverWrapperListView'
      );
      const cardWrap = $window.document.querySelectorAll('.pipeline-wrap-card');
      let elmHashKey = null;
      if (elmParent.length > 0) {
        elmHashKey = elmParent[0].$$hashKey || '';
      }
      if ($scope.DisplayMode === 'cards') {
        // will fix overlap issue and drap
        const popoverParent = $(eve.target).closest('.popover');
        if (
          eve.target.classList.contains('add-pipeline-new-label') ||
          (popoverParent && popoverParent.length)
        ) {
          _.forEach(cardWrap, card => {
            card.classList.add('position-static');
          });
          if (popovers.length > 1) {
            return false;
          }
        } else {
          _.forEach(cardWrap, obj => {
            obj.classList.remove('position-static');
          });
        }

        _.forEach(popoverArea, elm => {
          if (typeof elm.$$hashKey !== 'undefined' && elmHashKey) {
            if (elm.$$hashKey !== elmHashKey) {
              const eventTimeout = $timeout(() => {
                $(elm)
                  .closest('.popover')
                  .prev()
                  .click();
                $timeout.cancel(eventTimeout);
              });
            }
          }
        });
      } else {
        _.forEach(popoverAreaListView, elm => {
          if (typeof elm.$$hashKey !== 'undefined' && elmHashKey) {
            if (elm.$$hashKey !== elmHashKey) {
              $(elm)
                .closest('.popover')
                .prev()
                .click();
            }
          }
        });
      }

      if (
        !$scope.kanbanBoard2 ||
        ($scope.kanbanBoard2 && !$scope.kanbanBoard2.columns)
      ) {
        return;
      }

      if (
        $scope.DisplayMode === 'cards' &&
        !popoverArea.is(eve.target) &&
        popoverArea.has(eve.target).length === 0
      ) {
        _.forEach($scope.kanbanBoard2.columns, column => {
          _.forEach(column.cards, card => {
            if (card.textOpen) {
              card.textOpen = false;
            }

            _.forEach(card.labels, label => {
              if (label.textOpen) {
                label.textOpen = false;
              }
            });
          });
        });
      }
      if (!$scope.resolvedData) {
        return;
      }
      if (
        $scope.DisplayMode === 'list_view' &&
        !popoverAreaListView.is(elmParent) &&
        popoverAreaListView.has(elmParent).length === 0
      ) {
        if ($scope.resolvedData.length > 0) {
          for (let i = 0; i < $scope.resolvedData.length; i++) {
            if ($scope.resolvedData[i].textOpenForListView) {
              $scope.resolvedData[i].textOpenForListView = false;
            }
            for (let k = 0; k < $scope.resolvedData[i].LabelArray.length; k++) {
              if (
                $scope.resolvedData[i].LabelArray[k].textOpenForNoLabel ||
                $scope.resolvedData[i].LabelArray[k].textOpenForColoredLabel
              ) {
                $scope.resolvedData[i].LabelArray[k].textOpenForNoLabel = false;
                $scope.resolvedData[i].LabelArray[
                  k
                ].textOpenForColoredLabel = false;
              }
            }
          }
        }
      }
    });

    $scope.isCorporateUser = () => uiService.isCorporateUser;

    $scope.closeLabelPopover = closeLabelPopover;

    $scope.editLabel = function(lbl, card) {
      $scope.card = card;
      $scope.card.editLabelShown = true;
      card.editLabelShown = $scope.card.editLabelShown;

      $scope.card.lblObjToEdit = lbl;
      card.labelTitle = lbl.LabelName;
      card.lblObjToEdit = $scope.card.lblObjToEdit;
      $scope.pickedColorCode = lbl.ColorName;
    };
    $scope.addlabel = function(card) {
      if (!card) return;

      card.addLabelShown = true;
      card.lblObjToEdit = null;
      card.labelTitle = '';
      $scope.card = card;
      $scope.pickedColorCode = '';
    };
    $scope.pickedColorCode = '';
    $scope.colorPicked = function(color) {
      $scope.pickedColorCode = color;
    };
    $scope.updateLabel = function(card) {
      pipelineSharedData.updateLabels(card, $scope.pickedColorCode).then(
        () => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          $scope.getAllLabels();
          card.editLabelShown = false;
          card.addLabelShown = false;
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.deleteLabel = function(labelObj) {
      if (labelObj.pipelineSettingsLabelID) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
          () => {
            toaster.pop(
              'success',
              'Deleted',
              'Label has been deleted successfully.'
            );
            $scope.reloadApplications();
            $scope.getAllLabels(() => {});
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
      }
    };
    $scope.addlabelFromListView = function(list) {
      $scope.list = list;
      $scope.list.addLabelShown = true;
      list.addLabelShown = $scope.list.addLabelShown;
      $scope.list.lblObjToEdit = null;
      $scope.list.labelTitle = '';
      $scope.pickedColorCode = '';
    };
    $scope.editLabelFromListView = function(lbl, list) {
      $scope.list = list;
      $scope.list.editLabelShown = true;
      list.editLabelShown = $scope.list.editLabelShown;

      $scope.list.lblObjToEdit = lbl;
      list.labelTitle = lbl.LabelName;
      list.lblObjToEdit = $scope.list.lblObjToEdit;
      $scope.pickedColorCode = lbl.ColorName;
    };

    $scope.updateLabelFromListView = function(list) {
      if (!list) return;
      $scope.lblsToUpdate = [];
      pipelineSharedData.updateLabel(
        list,
        $scope.labelObj,
        $scope.lblsToUpdate,
        $scope.pickedColorCode
      );

      pipelineService.PipelineSettingsLabelSet($scope.lblsToUpdate).then(
        () => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          list.addLabelShown = false;
          list.editLabelShown = false;
          $scope.getAllLabels();
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $scope.pipelineTypes = [];
    $scope.selectedPipelineType = 1;
    $scope.pipelineSharedData.dateRangeFilter = DATE_RANGE.Months12;
    $scope.toDate = moment().format('DD MMM YYYY');

    // Change Date Range Filter
    $scope.changeDateRange = function() {
      switch ($scope.pipelineSharedData.dateRangeFilter) {
        case DATE_RANGE.Month1:
          $scope.fromDate = moment()
            .subtract(1, 'months')
            .format('DD MMM YYYY');
          break;
        case DATE_RANGE.Months3:
          $scope.fromDate = moment()
            .subtract(3, 'months')
            .format('DD MMM YYYY');
          break;
        case DATE_RANGE.Month6:
          $scope.fromDate = moment()
            .subtract(6, 'months')
            .format('DD MMM YYYY');
          break;
        case DATE_RANGE.Months12:
          $scope.fromDate = moment()
            .subtract(1, 'year')
            .format('DD MMM YYYY');
          break;
        default:
          break;
      }
      pipelineService
        .getPipelineCardsSummary($scope.fromDate, $scope.toDate, 1)
        .then(response => {
          $rootScope.statePer = response.data;
        });
    };

    pipelineService.PipelineTypeGet().then(pipelineTypeGetResponse => {
      if (pipelineTypeGetResponse && pipelineTypeGetResponse.data) {
        $scope.pipelineTypes = pipelineSharedData.pipelineTypeGet(
          pipelineTypeGetResponse.data
        );
      }
    });

    $scope.selectType = function(PipelineId) {
      $timeout(() => {
        const el = $window.document.querySelectorAll(
          `#tab-${PipelineId} .nav-link`
        );
        angular.element(el).triggerHandler('click');
      }, 0);
      $scope.selectedPipelineType = PipelineId;
    };

    // Change Show State
    $scope.changeShowState = function(showState) {
      $scope.cardViewStates.isShowState = showState;
    };

    $scope.switchSetting = {
      isShowStateSwitch: false,
    };
    $scope.$watch(
      'switchSetting.isShowStateSwitch',
      nv => {
        $scope.cardViewStates.isShowState = nv;
      },
      true
    );

    // Change List View Filter
    $scope.showFilter = function(displayMode) {
      if (displayMode === 'list_view') {
        $scope.isShowListViewFilterApp = !$scope.isShowListViewFilterApp;
      } else {
        $scope.showFilterForCardsApp = !$scope.showFilterForCardsApp;
      }
    };

    $scope.showModeApp = function(mode) {
      $localStorage.piplineAppView = mode;
      $scope.DisplayMode = mode;
      $scope.currentMode = mode;
      $scope.isShowListViewFilterApp = false;

      const isTableView = mode === 'list_view';
      const filters = pipelineSharedData.getSortingForSet(false, isTableView);
      pipelineService.setPipelineCardFilters(filters).then(() => {
        $scope.isFirstLoad = true;
        if (mode === 'list_view') {
          $scope._initListViewApp();
        } else {
          $scope._cardInitApp();
        }
      });

      $scope.showFilterForCards = false;
      $scope.showFilterForCardsApp = false;
      if (mode === 'cards') {
        $scope.showListView = false;
      } else if (mode === 'list_view') {
        $scope.cardViewStates.isShowState = false;
        $scope.showListView = true;
      } else {
        $timeout(() => {
          let elem = $window.document.querySelectorAll('#board.cards');
          angular.element(elem).addClass('hidden');
          elem = $window.document.querySelectorAll('#table.list_view');
          angular.element(elem).addClass('hidden');
        }, 0);
      }

      const container = $window.document.querySelectorAll('.pipelineContainer');
      angular.element(container).removeClass('list_view');
      angular.element(container).removeClass('cards');
      angular.element(container).addClass(mode);
      $scope.checkFilter(mode);
    };

    // Filters
    $scope.filterInitial = function(...args) {
      let i;
      let initials = '';
      if (!args || typeof args[0] === 'undefined') {
        return;
      }
      for (i = 0; i < args.length; i++) {
        if (!_.isNil(args[i]) && _.isString(args[i])) {
          initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };
    $scope.getInitials_v2 = function(name) {
      return utilitiesService.filterInitialOneString(name);
    };

    $scope.getAllAppStatus = function() {
      const promise = pipelineService
        .SettingsStatusGet(true, true)
        .then(response => {
          $scope.statusList = response.data;
          $scope.appsStatusList = [];

          const appStatusGroup = [];
          const otherStatusGroup = [];

          $scope.statusList.forEach(obj => {
            if (obj.TypeName === 'Application') {
              appStatusGroup.push(obj);
            } else if (!obj.PipelineCategoryID) {
              otherStatusGroup.push(obj);
            }
          });

          $scope.statusListDdlAppRadio = [appStatusGroup, otherStatusGroup];
          $scope.appStatusesForFilter = [
            ...appStatusGroup,
            ...otherStatusGroup,
          ];
          $scope.appsStatusList.push(...appStatusGroup);

          $scope.getStatusForFilter($scope.appsStatusList);

          const {
            StatusIds,
            ConversionStatusIDs,
          } = pipelineSharedData.leadFilterData;
          let listOfAppStatusIds = StatusIds ? StatusIds.split(',') : [];
          let listOfConversionStatusIds = ConversionStatusIDs
            ? ConversionStatusIDs.split(',')
            : [];
          listOfAppStatusIds = listOfAppStatusIds.map(id => parseInt(id, 10));
          listOfConversionStatusIds = listOfConversionStatusIds.map(id =>
            parseInt(id, 10)
          );

          pipelineSharedData.leadFilterData.PipelineStatusIDs = [];

          appStatusGroup.forEach(obj => {
            if (
              listOfAppStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
              pipelineSharedData.leadFilterData.isFetchAllPipelineStatusIDs
            ) {
              pipelineSharedData.leadFilterData.PipelineStatusIDs.push(obj);
            }
          });

          otherStatusGroup.forEach(obj => {
            if (
              listOfConversionStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
              pipelineSharedData.leadFilterData.isFetchAllPipelineStatusIDs
            ) {
              pipelineSharedData.leadFilterData.PipelineStatusIDs.push(obj);
            }
          });
        });

      return promise;
    };

    $scope.adviserList = [];
    $scope.getAllAdvisers = function() {
      pipelineSharedData.getAdvisersListData().then(adviserList => {
        $scope.adviserList = adviserList;
        $scope.getAdvisersForFilter($scope.adviserList);
      });
    };

    $scope.countLabelsInList = function(LabelArray) {
      if (_.size(LabelArray) === 0) {
        return 0;
      }
      let countValidLabels = 0;
      _.forEach(LabelArray, label => {
        if (
          _.find($scope.labels, o => {
            return String(o.pipelineSettingsLabelID) === String(label.labelID);
          })
        ) {
          countValidLabels++;
        }
      });

      return countValidLabels;
    };

    $scope.checkLabelExistInList = label =>
      pipelineSharedData.isLabelInList(label, $scope.labels);

    $scope.getAllLabels = function(callback) {
      const promise = pipelineService.PipelineSettingsLabel().then(
        response => {
          $scope.labels = response.data;
          $scope.getLabelsForFilter($scope.labels, callback);
        },
        () => {
          $scope.isCategoriesLoading = false;
        }
      );

      return promise;
    };

    $scope.openAddCardModal = function(size, familyId, subtool) {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/pipeline/leads/partials/add_new_card.html',
        resolve: {
          appsStatusList() {
            return $scope.appsStatusList;
          },
          familyId: () => familyId,
          subtool: () => subtool,
        },
        controller: 'PipelineAppAddCardModalCtrl',
        size,
      });

      if (modalInstance && modalInstance.result) {
        modalInstance.result.then(newCard => {
          if ($scope.DisplayMode === 'list_view') {
            $scope.reloadTableView();
          } else {
            $scope.onCardAdded(newCard);
          }
        });
      }
    };

    $scope.openCreateNewLoanApp = () => {
      uiService.openAddNewLoanApplicationModal(true);
    };

    $scope.onCardAdded = newCard => {
      if (!newCard || !$scope.kanbanColumnsforApp || !$scope.appsStatusList)
        return;

      const cardColumn = $scope.kanbanColumnsforApp.find(
        column => column.name === newCard.PipelineStatus
      );
      if (!cardColumn) return;

      const category = $scope.appsStatusList.find(
        item => item.PipelineStatusID === cardColumn.PipelineStatusID
      );
      if (!category) return;

      if (!$scope.appsCards) $scope.appsCards = {};
      if (!$scope.appsCards[cardColumn.PipelineStatusID]) {
        $scope.appsCards[cardColumn.PipelineStatusID] = [];
      }

      if ($scope.appsCards[cardColumn.PipelineStatusID]) {
        $scope.reloadColumn(cardColumn, false);
      } else {
        $scope.appsCards[cardColumn.PipelineStatusID] = [newCard];
        newCard.LabelArray = pipelineCardsService.convertStrLabelsToObj(
          newCard
        );
        const kanbanCard = pipelineCardsService.buildCardForBoard(
          newCard,
          category
        );
        cardColumn.cards = [kanbanCard];
      }
    };

    $scope.getStatusForFilter = statusList => {
      $scope.statusApps = statusList;
      $scope.appStatusesForFilter = angular.copy($scope.statusApps);
      $scope.appStatusesForFilter.splice(0, 0, {
        PipelineStatusID: 0,
        PipelineStatus: 'All',
      });
      if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
        if ($scope.appStatusesForFilter.length > 0) {
          $scope.selectedStatusForFilter = $scope.appStatusesForFilter[0];
        }
      } else if (
        typeof $scope.pipelineSharedData.leadFilterData.PipelineStatusID !==
          'undefined' &&
        $scope.pipelineSharedData.leadFilterData.PipelineStatusID !==
          'undefined'
      ) {
        $scope.statusId =
          $scope.pipelineSharedData.leadFilterData.PipelineStatusID;
        const filteredItems = $scope.appStatusesForFilter.filter(item => {
          if (item.PipelineStatusID === $scope.statusId) {
            return item;
          }
          return false;
        });
        if (Array.isArray(filteredItems) && filteredItems.length > 0) {
          $scope.selectedStatus = filteredItems[0];
        }
        $scope.selectedStatusForFilter = $scope.selectedStatus;
      } else if ($scope.appStatusesForFilter.length > 0) {
        $scope.selectedStatusForFilter = $scope.appStatusesForFilter[0];
      }
    };

    $scope.getAdvisersForFilter = adviserList => {
      $scope.taskAdviserList = pipelineSharedData.getAdvisersForFilter(
        adviserList
      );
    };

    $scope.getLabelsForFilter = (labels, onLabelsFetched) => {
      $scope.labelsList = pipelineSharedData.getLabels(labels);
      $scope.pipelineSharedData.leadFilterData = pipelineSharedData.getFilterWithLabel(
        $scope.labelsList
      );
      if (_.isFunction(onLabelsFetched)) onLabelsFetched();
    };

    $scope.defaultSortSet = function(getCards, isTableView) {
      const filters = pipelineSharedData.getSortingForSet(false, isTableView);
      pipelineService.setPipelineCardFilters(filters).then(() => {
        if (getCards) {
          $scope.getPipelineCards(filters.AdvisorId);
        }
      });
    };

    $scope.hideShowColumn = function() {
      let $postString = '';

      _.forEach($scope.Table.FilterColumns, o => {
        if (!o.show) {
          $postString += `${o.field},`;
        }
      });
      $postString = $postString.slice(0, -1);

      $scope.pipelineSharedData.leadFilterData.TableViewExcludeColumns = $postString;
      $scope.pipelineSharedData.leadFilterData.TableViewExcludeColumnsArray = $postString.split(
        ','
      );

      $scope.defaultSortSet();
    };

    $scope.filterAdviser = function() {
      $scope.filterTouched = true;
      $scope.getAllCards(true);
    };
    $scope.filterLabel = function() {
      $scope.getAllCards(true);
    };

    $scope.filterAdviserListView = function() {
      $scope.filterTouched = true;
      $scope.getListViewAppsData(true);
    };
    $scope.filterLabelListView = function() {
      $scope.getListViewAppsData(true);
    };
    $scope.filterStatusListView = function(values) {
      pipelineSharedData.leadFilterData.PipelineStatusIDs = [...values];
      let StatusIds = [];
      let ConversionStatusIDs = [];
      pipelineSharedData.leadFilterData.PipelineStatusIDs.forEach(obj => {
        if (obj.IsConversionStatus) {
          ConversionStatusIDs.push(obj.PipelineStatusID);
        } else {
          StatusIds.push(obj.PipelineStatusID);
        }
      });
      StatusIds = StatusIds.join(',');
      ConversionStatusIDs = ConversionStatusIDs.join(',');
      pipelineSharedData.leadFilterData.StatusIds = StatusIds;
      pipelineSharedData.leadFilterData.ConversionStatusIDs = ConversionStatusIDs;

      $scope.getListViewAppsData(true);
    };
    $scope.changeIsCompactApp = function() {
      $scope.defaultSortSet(true, false);
    };
    $scope.setColumnSort = function(column, sortType, index) {
      $timeout(() => {
        const element = angular.element(
          $window.document.querySelector(`#columnBody-${index}`)
        );
        const elemHeight = element[0].offsetHeight;

        if (elemHeight > $scope.kanbanBoardHeight) {
          column.minHeight = angular.copy($scope.kanbanBoardHeight);
        } else {
          column.minHeight = elemHeight;
        }
        column.loading = true;
      }, 0);

      $scope.sorting = true;

      if (sortType !== 'Reset') {
        column.SelectedFilterStatus = sortType;
        column.SelectedFilterStatusObjectify = `${column.name}:${sortType}`;
      } else {
        column.SelectedFilterStatus = '';
        column.SelectedFilterStatusObjectify = '';
      }

      let sortingMode = '';
      _.forEach($scope.kanbanBoard2.columns, boardColumn => {
        if (boardColumn.SelectedFilterStatusObjectify) {
          sortingMode += `${boardColumn.SelectedFilterStatusObjectify},`;
        }
      });

      if (sortingMode !== '') {
        sortingMode = sortingMode.slice(0, -1);
      }

      // reset the filteredData obj
      const sortingModeArray = sortingMode !== '' ? sortingMode.split(',') : []; // array of sorting mode
      const sortingModeObj = {};

      if (sortingModeArray && sortingModeArray.length) {
        _.forEach(sortingModeArray, sortingType => {
          const extractKeyValue = sortingType.split(':');
          sortingModeObj[extractKeyValue[0]] = extractKeyValue[1];
        });
      }

      // overwrite the sort string to be sort obj
      $scope.pipelineSharedData.leadFilterData.sortingMode = sortingMode;
      $scope.pipelineSharedData.leadFilterData.sortingModeObj = sortingModeObj;

      // empty the custom sort
      let CustomerSort = '';
      const CustomerSortObj = {};
      _.forEach(
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
        (columns, columnKey) => {
          if (_.size(columns) > 0 && columnKey !== column.name) {
            _.forEach(columns, item => {
              CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                item.position
              },`;
            });
            CustomerSortObj[columnKey] = columns;
          }
        }
      );
      if (CustomerSort !== '') {
        CustomerSort = CustomerSort.slice(0, -1);
      }
      $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;
      $scope.pipelineSharedData.leadFilterData.CustomerSortObj = CustomerSortObj;

      const isTableView = $scope.DisplayMode === 'list_view';
      const filters = pipelineSharedData.getSortingForSet(isLeads, isTableView);
      filters.SortingMode = sortingMode;
      pipelineService.setPipelineCardFilters(filters).then(
        () => {
          $scope.reloadColumn(column);
        },
        () => {
          column.loading = false;
        }
      );
    };

    $scope.reloadColumn = (column, showLoading = true, customPageSize) => {
      if (!column) return;

      column.loading = showLoading;
      const pageNumber = 1;
      const filterStatusId = column.PipelineStatusID;
      const params = { isLeads, filterStatusId, pageNumber };
      params.pageSize = customPageSize || pageSize;
      const searchClientName = $scope.searchWrapperApp.searchPipelineApp;
      if (searchClientName) {
        params.searchClientName = searchClientName;
      }
      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          column.loading = false;
          if (!response.data) return;

          const items = response.data[filterStatusId];
          if (items && items.length) {
            const category = $scope.appsStatusList.find(
              status => status.PipelineStatusID === column.PipelineStatusID
            );
            if (category) {
              column.cards = items.map(card =>
                pipelineCardsService.buildCardForBoard(card, category)
              );
            }
          } else {
            column.cards = [];
          }
          column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
            column
          );
        },
        () => {
          column.loading = false;
        }
      );
    };

    function setHeight() {
      // init variables
      const winHeight = $window.document.documentElement.clientHeight;
      const headerHeight = $window.document.querySelector(
        'header.navbar.navbar-default.navbar-static-top'
      )
        ? $window.document.querySelector(
            'header.navbar.navbar-default.navbar-static-top'
          ).offsetHeight
        : 55;
      const pipelinePanel = $window.document.querySelector('#pipeline-page');
      const mobileAddCard = $window.document.querySelector('.mobileAddCard');
      const mobileAddCardHeight = mobileAddCard ? 45 : 0;
      const pHeight = `${winHeight -
        headerHeight -
        mobileAddCardHeight -
        50}px`;

      // declare height to panel
      if (!_.isNull(pipelinePanel) && !_.isUndefined(pipelinePanel)) {
        pipelinePanel.style.setProperty('height', pHeight);

        const pipelineFilterWrapperHeight = $window.document.querySelector(
          '#pipelineFilterWrapper'
        )
          ? $window.document.querySelector('#pipelineFilterWrapper')
              .offsetHeight
          : 55;
        $scope.kanbanBoardHeight =
          winHeight -
          headerHeight -
          pipelineFilterWrapperHeight -
          mobileAddCardHeight -
          150;
      }
    }

    setHeight();
    $window.addEventListener('resize', setHeight);
    $scope.allCards = [];

    const getCards = $rootScope.$on('getAllCards', () => {
      $scope.getAllCards(true, 0);
    });
    $scope.$on('$destroy', getCards);
    $scope.getPipelineCards = (adviserId, searchFilter) => {
      const pageNumber = 1;
      const params = { isLeads, pageNumber, pageSize };
      if (searchFilter) {
        params.searchClientName = searchFilter;
      }
      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          $scope.appsCards = [];

          const isCorpUserNoAdviserSelected =
            $scope.isCorporateUser() && adviserId === 0;
          if (isCorpUserNoAdviserSelected) {
            $scope.appsCards = [];
            $scope.prepareCards($scope.appsStatusList);
          } else {
            $scope.appsCards = response.data;
            pipelineSharedData.formatCardLabelsForView($scope.appsCards);
            $scope.prepareCards($scope.appsStatusList);
          }

          $scope.appsRefreshing = false;
        },
        () => {
          $scope.appsRefreshing = false;
          $scope.isCategoriesLoading = false;
        }
      );
    };
    $scope.getAllCards = function(showLoading, advId) {
      const adviserId =
        advId || $scope.pipelineSharedData.leadFilterData.adviserId;
      const isFirstLoad = $scope.isFirstLoad;

      if ($scope.DisplayMode === 'cards')
        $scope.isCategoriesLoading = showLoading;

      if ($scope.isCorporateUser() && adviserId === 0) {
        $scope.appsCards = [];
        $scope.prepareCards($scope.appsStatusList);
        $scope.defaultSortSet(false, false);
        $scope.appsRefreshing = false;
        $scope.isCategoriesLoading = false;
        $scope.isFirstLoad = false;
        return false;
      }

      if (isFirstLoad) {
        $scope.getPipelineCards(adviserId);
        $scope.isFirstLoad = false;
      } else {
        pipelineSharedData.validateFilters();

        const filters = pipelineSharedData.getSortingForSet(false, false);
        filters.advisorId = adviserId || 0;
        pipelineService.setPipelineCardFilters(filters).then(() => {
          $scope.getPipelineCards(adviserId);
        });
      }
    };

    $scope.prepareCards = function(appsStatus) {
      if (!appsStatus) return;

      $scope.mobileViewReady = false;
      $scope.kanbanColumnsforApp = [];
      $scope.count = 0;
      appsStatus.forEach(status => {
        if (!status) return;

        $scope.column = {};
        $scope.column.CategoryName = status.CategoryName;
        $scope.column.PipelineCategoryID = status.PipelineCategoryID;
        $scope.column.name = status.PipelineStatus;
        $scope.column.PipelineStatusID = status.PipelineStatusID;
        $scope.column.cards = [];
        $scope.column.limitCount = 10;
        $scope.column.totalLoanAmount = 0;

        pipelineSharedData.setColumnSorting($scope.column);

        const columnCards =
          $scope.appsCards && $scope.appsCards[$scope.column.PipelineStatusID];
        if (columnCards && columnCards.length) {
          $scope.statusWiseCards = columnCards.map(card => {
            const kanbanCard = pipelineCardsService.buildCardForBoard(
              card,
              status
            );
            $scope.column.totalLoanAmount += kanbanCard.LoanAmount;
            return kanbanCard;
          });

          $scope.column.cards = $scope.statusWiseCards;
          $scope.column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
            $scope.column
          );
          $scope.kanbanColumnsforApp.push($scope.column);
        } else {
          $scope.kanbanColumnsforApp.push($scope.column);
        }

        if ($scope.count < $scope.column.cards.length) {
          $scope.count = $scope.column.cards.length;
        }
      });

      $scope.fixedColHeight = $scope.count * 135 + 60;

      $rootScope.kanbanBoard2 = $scope.newKanbanBoardApp();

      $timeout(() => {
        $scope.mobileViewReady = true;
        $scope.isCategoriesLoading = false;
        setHeight();
      }, 400);
    };

    $scope.changeSubStatus = function(cardObj, subStatusID) {
      pipelineService
        .PipelineCards(cardObj.PipelineItemId, {
          PipelineStatusID: cardObj.PipelineStatusID,
          SubStatusID: subStatusID,
        })
        .then(
          response => {
            toaster.pop('success', 'Set', 'Status has been Set successfully');
            if (response.data.Result) {
              cardObj.LastModifiedDate = response.data.LastUpdate;
            }
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
    };
    $scope.changeSubStatusforListXs = function(listObj, subStatusID) {
      $scope.setSubStatus = {};
      $scope.setSubStatus.CardsID = listObj.PipelineCardsID;
      $scope.setSubStatus.SubStatusID = subStatusID;
      pipelineService.CardsSubStatusSet($scope.setSubStatus).then(
        () => {
          toaster.pop('success', 'Set', 'Status has been Set successfully');

          $scope.reloadApplications();
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $scope.newKanbanBoardApp = () => {
      return {
        name: 'Kanban Board',
        numberOfColumns: 0,
        totalApplication: 0,
        totalNotProceeding: 0,
        totalApplicationPercentage: 0,
        totalNotProceedingPercentage: 0,
        columns: $scope.kanbanColumnsforApp,
        backlogs: [],
      };
    };

    $rootScope.cardApprove = [];
    $rootScope.cardNotProceeding = [];

    $scope.refreshKanbanBoard = function() {
      pipelineService
        .ConvertionRateGet($scope.selectedPipelineType)
        .then(response => {
          const conversions = response.data;
          const columns = $rootScope.kanbanBoard2.columns;
          let cardNumber = -1;
          columns.forEach(column => {
            column.totalAmount = 0;
            column.ChartData = [[]];
            column.ChartLabels = [];

            column.cards.forEach(card => {
              column.totalAmount += card.LoanAmount;
              column.ChartData[0].push(card.LoanAmount);
              column.ChartLabels.push('');
            });
          });

          conversions.forEach(conversion => {
            if (!conversion.IsFinalStatus && !conversion.IsNotProceed) {
              // lead steps
              if (conversion.StatusId >= 2 && conversion.StatusId <= 4) {
                cardNumber = conversion.StatusId - 2;
                columns[cardNumber].totalPercentage = conversion.Rate * 100;
              }
            } else if (conversion.IsFinalStatus && conversion.IsNotProceed) {
              $rootScope.kanbanBoard2.totalNotProceedingPercentage =
                conversion.Rate * 100;
            } else if (conversion.IsFinalStatus && !conversion.IsNotProceed) {
              $rootScope.kanbanBoard2.totalApplicationPercentage =
                conversion.Rate * 100;
            }
          });

          $rootScope.kanbanBoard2.columns = columns;
        });
    };

    // open card options
    $scope.openHideTab = function(cardId) {
      const cardDetails = _.find($rootScope.kanbanBoard2.columns, column => {
        return _.find(column.cards, card => {
          return card.PipelineItemId === cardId;
        });
      });

      _.forEach(cardDetails.cards, card => {
        if (card.PipelineItemId === cardId) {
          card.openOptions = !card.openOptions;
        }
      });
    };
    // delete card
    $scope.deleteCard = function(cardObj) {
      $scope.cardForDel = {};
      $scope.cardForDel.cardsID = cardObj.PipelineItemId;
      swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            pipelineService.PipelineCardsDelete($scope.cardForDel).then(() => {
              $scope.onCardDeleted(cardObj);
              $scope.deletedCardTimeout = $timeout(() => {
                swal(
                  'Success',
                  'Card has been successfully deleted.',
                  'success'
                );
                if ($scope.deletedCardTimeout)
                  $timeout.cancel($scope.deletedCardTimeout);
              }, 100);
            });
          }
        }
      );
    };
    $scope.onCardDeleted = deletedCard => {
      if (!deletedCard) return;
      const cardColumn = $scope.kanbanColumnsforApp.find(
        column => column.PipelineStatusID === deletedCard.PipelineStatusID
      );
      $scope.reloadColumn(cardColumn);
    };
    // move card
    $scope.moveCard = function(
      cardId,
      toColumnId,
      destStatus,
      fromStatus,
      itemToHighlight
    ) {
      let card = {};
      _.forEach($scope.kanbanBoard2.columns, column => {
        const findCard = _.find(column.cards, columnCard => {
          return columnCard.PipelineItemId === itemToHighlight.PipelineItemId;
        });
        if (findCard) {
          card = findCard;
        }
      });
      pipelineService
        .PipelineCards(itemToHighlight.PipelineItemId, {
          PipelineStatusID: toColumnId,
        })
        .then(
          response => {
            toaster.pop('success', 'Moved', 'Card has been moved successfully');
            if (response.data.Result) {
              card.LastModifiedDate = response.data.LastUpdate;
              card.PipelineStatus = destStatus;
              card.PipelineStatusID = toColumnId;

              $scope.onCardMoved(cardId, fromStatus, destStatus);
            }
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
    };

    $scope.onCardMoved = (cardId, fromStatus, toStatus) => {
      if (!cardId || !fromStatus) return;

      $scope.reloadColumnRetainSize(fromStatus, false);
      $scope.reloadColumnRetainSize(toStatus, true);
    };

    $scope.reloadColumnRetainSize = (columnName, increasing) => {
      if (!columnName) return;

      const column = $scope.kanbanColumnsforApp.find(
        status => status.name === columnName
      );
      if (!column) return;

      updateColumnTotalRecords(column, increasing);
      if ($scope.columnHasMore(column)) {
        const page = pipelineCardsService.getColumnCurrentPage(
          column,
          pageSize
        );
        const recordsToGet = page * pageSize;
        $scope.reloadColumn(column, false, recordsToGet);
      }
    };

    $scope.openLinkToLoanAppModal = function(
      size,
      familyID,
      cardID,
      familyName
    ) {
      $uibModal.open({
        resolve: {
          familyID() {
            return familyID;
          },
          cardID() {
            return cardID;
          },
          familyName() {
            return familyName;
          },
        },
        templateUrl:
          '/assets/views/pipeline/leads/partials/link_loanApp_modal.html',
        controller: 'LinkToLoanCtrl',
        size,
      });
    };
    $scope.getReasonList = () => {
      pipelineService
        .getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
        .then(({ reasonList }) => {
          $scope.reasonListView = reasonList || null;
        });
    };
    $scope.getReasonList();
    // board options
    $scope.kanbanSortOptions = {
      itemMoved(event) {
        if (!angular.isUndefined(event.dest.sortableScope.element)) {
          const itemToHighlight = event.source.itemScope.card;
          // Move among columns
          if (pipelineSharedData.checkItemMove(event)) {
            const destStatusName = event.dest.sortableScope.$parent.column.name;
            const fromStatusName =
              event.source.itemScope.modelValue.PipelineStatus;
            // remove the previous card data from custom sort obj
            _.forEach(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
              columnsSortObjectMoved => {
                _.remove(columnsSortObjectMoved, obj => {
                  return (
                    String(obj.PipelineItemId) ===
                    String(event.source.itemScope.modelValue.PipelineItemId)
                  );
                });
              }
            );
            // add the new card data to custom sort obj
            $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
              destStatusName
            ] = pipelineSharedData.customerSortObj(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
                destStatusName
              ]
            );
            $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
              destStatusName
            ].push({
              PipelineItemId: String(
                event.source.itemScope.modelValue.PipelineItemId
              ),
              position: String(
                _.size(event.dest.sortableScope.$parent.column.cards) -
                  event.dest.index
              ),
            });
            // manual stringify
            let CustomerSort = '';
            _.forEach(
              $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
              (columns, columnKey) => {
                _.forEach(columns, item => {
                  CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                    item.position
                  },`;
                });
              }
            );
            if (CustomerSort !== '') {
              CustomerSort = CustomerSort.slice(0, -1);
            }
            $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;

            const filters = pipelineSharedData.getSortingForSet(false, false);
            pipelineService.setPipelineCardFilters(filters).then(() => {
              const PipelineItemId =
                event.source.itemScope.modelValue.PipelineItemId;
              const PipelineStatusID =
                event.dest.sortableScope.$parent.column.PipelineStatusID;
              $scope.moveCard(
                PipelineItemId,
                PipelineStatusID,
                destStatusName,
                fromStatusName,
                itemToHighlight,
                filters
              );
            });
          } else {
            const draggedCard = event.source.itemScope.modelValue;
            const isApproveApplication =
              event.dest.sortableScope.element[0].id === 'approve';
            const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
              {
                content: `This card will now be moved to ${
                  isApproveApplication ? 'Settled' : 'Not Proceeding'
                }`,
                reasonList: isApproveApplication ? null : $scope.reasonListView,
                showNurtureCampaignsQuestion:
                  !isApproveApplication &&
                  pipelineSharedData.showNurtureCampaignsQuestion(
                    draggedCard.EnquirySource
                  ),
              }
            );
            confirmationPopup.result.then(result => {
              if (result.isOk) {
                $scope.onMoveConfirmed(
                  isApproveApplication,
                  draggedCard,
                  itemToHighlight,
                  event,
                  result
                );
              } else {
                revertMoveCardToOtherColumn(event);
              }
            });
          }
        }
      },
      orderChanged(event) {
        const destStatusName = event.dest.sortableScope.$parent.column.name;
        // remove the previous card data from custom sort obj
        _.forEach(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
          columnsSortObjectOrderChange => {
            _.remove(columnsSortObjectOrderChange, obj => {
              return (
                String(obj.PipelineItemId) ===
                String(event.source.itemScope.modelValue.PipelineItemId)
              );
            });
          }
        );
        // add the new card data to custom sort obj
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
          destStatusName
        ] = pipelineSharedData.customerSortObj(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
            destStatusName
          ]
        );
        $scope.pipelineSharedData.leadFilterData.CustomerSortObj[
          destStatusName
        ].push({
          PipelineItemId: String(
            event.source.itemScope.modelValue.PipelineItemId
          ),
          position: String(
            event.dest.sortableScope.$parent.column.cards.length -
              event.dest.index
          ),
        });
        // manual stringify
        let CustomerSort = '';
        _.forEach(
          $scope.pipelineSharedData.leadFilterData.CustomerSortObj,
          (columns, columnKey) => {
            _.forEach(columns, item => {
              CustomerSort += `${columnKey}:${item.PipelineItemId}:${
                item.position
              },`;
            });
          }
        );

        if (CustomerSort !== '') {
          CustomerSort = CustomerSort.slice(0, -1);
        }
        $scope.pipelineSharedData.leadFilterData.CustomerSort = CustomerSort;
        const filters = pipelineSharedData.getSortingForSet(false, false);
        pipelineService.setPipelineCardFilters(filters);
      },
      containerPositioning: 'relative',
      containment: '#container',
      dragStart: pipelineSharedData.handleDragStart,
      dragEnd: pipelineSharedData.handleDragEnd,
      accept(sourceItemHandleScope, destSortableScope) {
        $scope.cardViewStates.isSettledDropAreaEnabled = false;
        $scope.cardViewStates.isNotProceedingDropAreaEnabled = false;
        $timeout(() => {
          if (destSortableScope && !_.isEmpty(destSortableScope.element)) {
            $scope.onDropAreaMouseIn(destSortableScope.element[0].id);
          }
        });
        return true;
      },
      allowDuplicates: true,
    };
    $scope.onMoveConfirmed = (
      isApproveApplication,
      draggedCard,
      itemToHighlight,
      event,
      result
    ) => {
      const isInvalidArgs = !draggedCard || !event || !result;
      if (isInvalidArgs) return;

      if (isApproveApplication && draggedCard.LoanScenarioID) {
        $scope.openMovedCardToSettledModal(
          'md',
          itemToHighlight.clientFamilyId,
          draggedCard.PipelineItemId,
          draggedCard.LoanScenarioID,
          itemToHighlight,
          event
        );
      } else {
        const conversionStatus = isApproveApplication
          ? LOAN_APP_STATUS_TYPES.SETTLE_CONVERSION_STATUS
          : LOAN_APP_STATUS_TYPES.NOT_PROCEEDED_CONVERSION_STATIS;
        $scope.moveCardOnSidebar(
          draggedCard.PipelineItemId,
          conversionStatus,
          itemToHighlight,
          event,
          false,
          result.shouldSendNurtureCampaigns,
          result.selectedReasonId
        );
      }
    };
    $scope.kanbanBoardWidthApp = {
      width: '100',
      measure: '%',
    };
    $scope.$watch('kanbanBoard2.columns.length', newLength => {
      $scope.kanbanBoardWidthApp.width = newLength * 220 + 400;
      $scope.kanbanBoardWidthApp.measure = 'px';
    });
    $scope.openEditLabelModal = function(size) {
      $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/edit_label_modal.html',
        resolve: {
          labels() {
            return $scope.labels;
          },
          listeners: () => {
            return {
              onLabelsSaved: () => {
                $scope.reloadApplications();
              },
              onLabelDeleted: () => {
                $scope.reloadApplications();
              },
            };
          },
        },
        controller: 'PipelineAppEditLabelModalCtrl',
        size,
      });
    };
    $scope.deleteCardFromList = function(listObj) {
      $scope.listForDel = {};
      $scope.listForDel.cardsID = listObj.PipelineCardsID;
      swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            pipelineService.PipelineCardsDelete($scope.listForDel).then(() => {
              $timeout(() => {
                $scope.reloadApplications();
                swal(
                  'Success',
                  'Item has been successfully deleted.',
                  'success'
                );
              }, 100);
            });
          }
        }
      );
    };
    $scope.setLabelForCard = function(lblObj, cardObj) {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = cardObj.PipelineItemId;
      $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelSet($scope.pipelineCardsLabelSet).then(
        response => {
          if (!response) return;
          toaster.pop(
            'success',
            'Added',
            'Label  has been added successfully.'
          );
          $scope.sortType = $rootScope.sortType;
          $scope.sortColumn = $rootScope.sortColumn;
          $scope.onCardUpdated(response.data);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.onCardUpdated = updatedCard => {
      if (!updatedCard) return;

      const cardCategory = $scope.appsStatusList.find(
        category => category.PipelineStatusID === updatedCard.PipelineStatusID
      );
      if (!cardCategory) return;

      updatedCard.LabelArray = pipelineCardsService.convertStrLabelsToObj(
        updatedCard
      );
      const kanbanCard = pipelineCardsService.buildCardForBoard(
        updatedCard,
        cardCategory
      );
      const cardColumn = $scope.kanbanColumnsforApp.find(
        column => column.name === updatedCard.PipelineStatus
      );
      const indexOfCard = _.findIndex(
        cardColumn.cards,
        card => card.PipelineItemId === kanbanCard.PipelineItemId
      );

      if (indexOfCard !== -1) {
        cardColumn.cards[indexOfCard] = kanbanCard;
      }
    };

    $scope.setLabelForCardFromList = function(lblObj, listObj) {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = listObj.PipelineCardsID;
      $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelSet($scope.pipelineCardsLabelSet).then(
        response => {
          toaster.pop(
            'success',
            'Added',
            'Label  has been added successfully.'
          );
          $scope.sortType = $rootScope.sortType;
          $scope.sortColumn = $rootScope.sortColumn;
          $scope.onItemUpdated(response.data);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.onItemUpdated = item => {
      pipelineSharedData.updatePipelineItemInList(
        item,
        $scope.listViewTable,
        $scope.appsStatusList
      );
    };

    $scope.selectStatus = (statusObj, listObj) => {
      pipelineSharedData.selectStatus(statusObj, listObj, () => {
        $scope.expandDroqdown = false;
      });
    };
    $scope.firstTime = true;

    $scope.changeContainerHeight = function() {
      $('.table_container-app').height('auto');
    };
    $scope.toggleDropdown = function($event) {
      if ($scope.firstTime) {
        $scope.firstTime = false;
        $scope.table_containerHeightOriginal = $(
          '.table_container-app'
        ).height();
      }
      $event.preventDefault();
      $event.stopPropagation();
      $scope.expandDroqdown = !$scope.expandDroqdown;
      $('.table_container').height('auto');
    };
    $scope.reloadApplications = () => {
      if ($scope.DisplayMode === 'list_view') {
        $scope.reloadTableView(false, true);
      } else {
        $scope._cardInitApp();
      }
    };

    // Modal for Card that moved to settled
    $scope.openMovedCardToSettledModal = function(
      size,
      familyID,
      cardID,
      LoanScenarioID,
      itemToHighlight,
      event
    ) {
      const $modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/moved_settled_modal.html',
        controller: 'PipelineAppMoveCardToSettledModalCtrl',
        resolve: {
          familyID: () => familyID,
          cardID: () => cardID,
          LoanScenarioID: () => itemToHighlight.LoanID,
          itemToHighlight: () => itemToHighlight,
        },
        size,
      });
      $modalInstance.result.then(
        response => {
          $scope.moveCardOnSidebar(
            response.PipelineItemId,
            response.Status,
            response.itemHighlight,
            event,
            true
          );
        },
        () => {
          revertMoveCardToOtherColumn(event);
        }
      );
    };

    $scope.callPipelineCardsConversionSet = (
      listObj,
      conversionStatus,
      shouldSendNurtureCampaigns,
      selectedReasonId
    ) => {
      const $postData = {
        pipelineCardsID: listObj.PipelineCardsID,
        conversionStatus,
        clientFamilyId: listObj.ClientFamilyID,
        shouldSendNurtureCampaigns,
        reasonSubstatusID: selectedReasonId,
      };
      pipelineService.PipelineCardsConversionSet($postData).then(
        () => {
          toaster.pop('success', 'Moved', 'Item successfully moved');
          $scope.sortType = $rootScope.sortType;
          $scope.sortColumn = $rootScope.sortColumn;
          $scope.reloadApplications();
        },
        () => {}
      );
    };

    $scope.moveCardOnSidebarforListView = function(listObj, conversionStatus) {
      if (
        conversionStatus === LOAN_APP_STATUS_TYPES.SETTLE_CONVERSION_STATUS &&
        listObj.LoanID
      ) {
        modalRenderService
          .openMovedToSettledModal(
            'md',
            listObj.ClientFamilyID,
            listObj.LoanID,
            listObj.PipelineCardsID,
            conversionStatus
          )
          .then(() => {
            const postData = {
              pipelineCardsID: listObj.PipelineCardsID,
              clientFamilyId: listObj.ClientFamilyID,
              conversionStatus,
            };
            pipelineService.PipelineCardsConversionSet(postData).then(() => {
              toaster.pop('success', 'Moved', 'Item successfully moved');
              $scope.sortColumn = $rootScope.sortColumn;
              $scope.sortType = $rootScope.sortType;
              $scope.reloadApplications();
            });
          });
      } else if (
        conversionStatus ===
        LOAN_APP_STATUS_TYPES.NOT_PROCEEDED_CONVERSION_STATIS
      ) {
        modalRenderService
          .renderPipelineStatusMoveConfirmationModal({
            reasonList: $scope.reasonListView,
            showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
              listObj.EnquirySource
            ),
          })
          .result.then(result => {
            if (result.isOk) {
              $scope.callPipelineCardsConversionSet(
                listObj,
                conversionStatus,
                result.shouldSendNurtureCampaigns,
                result.selectedReasonId
              );
            }
          });
      } else {
        $scope.callPipelineCardsConversionSet(listObj, conversionStatus);
      }
    };

    $scope.moveCardOnSidebar = function(
      cardId,
      conversionStatus,
      itemToHighlight,
      event,
      isModalOpened,
      shouldSendNurtureCampaigns,
      selectedReasonId
    ) {
      itemToHighlight.highlight = true;
      $scope.familyId = itemToHighlight.clientFamilyId;
      const $postData = {
        pipelineCardsID: cardId,
        conversionStatus,
        clientFamilyId: itemToHighlight.clientFamilyId,
        shouldSendNurtureCampaigns,
        reasonSubstatusID: selectedReasonId,
      };
      pipelineService.PipelineCardsConversionSet($postData).then(response => {
        if (response.data === 1) {
          toaster.pop('success', 'Moved', 'Item successfully moved');
          const status =
            response.config && response.config.data
              ? response.config.data.conversionStatus
              : undefined;
          if (status === 2) {
            contactService
              .contactAddressGet($scope.familyId)
              .then(addressResponse => {
                $scope.postSettlementAddress = addressResponse.data.filter(
                  item => {
                    if (item.Type === 'Post-Settlement Address') {
                      return item;
                    }
                    return false;
                  }
                );
                if ($scope.postSettlementAddress.length && !isModalOpened) {
                  $scope.openMovedCardToSettledModal(
                    'md',
                    itemToHighlight.clientFamilyId,
                    cardId,
                    itemToHighlight.LoanScenarioID,
                    itemToHighlight,
                    event
                  );
                }
              });
          }
          $scope.refreshKanbanBoard();
          $scope.onCardMoved(cardId, itemToHighlight.PipelineStatus);
          $scope.showStats();
        } else {
          toaster.pop('error', 'Error', 'Moving item went wrong');
        }
        itemToHighlight.highlight = false;
      });
    };
    const getList = $rootScope.$on('getListData', () => {
      $scope.getListViewAppsData(true);
    });
    $scope.$on('$destroy', getList);
    $scope.getListViewAppsData = function(showLoading) {
      let statusId = $scope.pipelineSharedData.leadFilterData.PipelineStatusID;
      let advisersId = $scope.pipelineSharedData.leadFilterData.adviserId;
      let labelId = $scope.pipelineSharedData.leadFilterData.labelId;
      let probability = $scope.pipelineSharedData.leadFilterData.probability;
      const isFirstLoad = $scope.isFirstLoad;

      if ($scope.DisplayMode === 'list_view') {
        $scope.isCategoriesLoading = showLoading;
      }

      $scope.totalTransactionAmount = 0;
      $scope.mainObj = {};
      $scope.mainObj.CurrentPage = 1;
      if (statusId === undefined) {
        statusId = 0;
      }
      if (labelId === undefined) {
        labelId = 0;
      }
      probability = 'All';
      $scope.adviserId = advisersId;
      const corporate = uiService.isCorporateUser;
      if (!corporate && advisersId === 0) {
        dashboardService.getBrokerBasicInfo().then(response => {
          $scope.tests = response.data;
          if ($scope.tests.FamilyId !== '') {
            $scope.familyId = response.data.FamilyId;
            $scope.fullName = response.data.FullName;
            advisersId = $scope.familyId;
          }
        });
      }

      if (pipelineSharedData.toFilter !== '') {
        statusId = pipelineSharedData.selectedStatusForFilter.PipelineStatusID;
        pipelineSharedData.selectedStatusForFilter.PipelineStatusID = 0;
        pipelineSharedData.toFilter = '';
      }
      $scope.filter = {
        $: '',
      };
      $scope.searchChange = function() {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          if ($scope.filter.$.length) {
            if ($scope.mainObj.currentPage === null) {
              const paramsPageHolder = $scope.listViewTableParams.page();
              $scope.mainObj.currentPage = paramsPageHolder;
            }
          } else {
            $scope.mainObj.currentPage = null;
          }

          $scope.reloadTableView();
        }, 1000);
      };

      $scope.Table = { FilterColumns: {} };
      advisersId = advisersId || 0;
      if (isFirstLoad) {
        $scope.initTableParams(showLoading);
        $scope.isFirstLoad = false;
      } else {
        const filters = pipelineSharedData.getSortingForSet(false, true);
        filters.StatusId = statusId;
        filters.AdvisorId = advisersId;
        filters.LabelId = labelId;
        filters.Probability = probability;
        pipelineService.setPipelineCardFilters(filters).then(
          () => {
            $scope.initTableParams(showLoading);
          },
          () => {
            $scope.isCategoriesLoading = false;
          }
        );
      }
    };

    $scope.initTableParams = showLoading => {
      $scope.countPerPage = 0;
      $scope.showTableLoading = showLoading;
      if ($scope.listViewTableParams) {
        $scope.reloadTableView(showLoading);
      } else {
        $scope.listViewTableParams = new NgTableParams(
          {
            count: pageSize,
          },
          {
            counts: [],
            getData($defer, params) {
              if (uiService.isCorporateUser && $scope.adviserId === 0) {
                $scope.listViewTable = [];
                $scope.resolvedData = [];
                $defer.resolve($scope.listViewTable);
                $scope.isCategoriesLoading = false;
                $scope.appsRefreshing = false;
                return;
              }

              const column = params.orderBy();
              const key =
                column && column[0] ? column[0].replace(/(\+)|(-)/g, '') : null;
              let isSortingChanged = (key || '') !== ($scope.sortColumn || '');
              if (column && column.length) {
                $scope.sortColumn = column[0];
              }

              const sorting = params.sorting();
              if (sorting) {
                if (key && sorting[key]) {
                  isSortingChanged =
                    isSortingChanged || sorting[key] !== $scope.sortType;
                  $scope.sortColumn = key;
                  $scope.sortType = sorting[key];
                }
              }

              const triggeredBySorting = isSortingChanged;
              $scope.showTableLoading = !triggeredBySorting;

              if ($scope.sortType && $scope.sortColumn && isSortingChanged) {
                const { sortType, sortColumn } = $scope;
                const filters = pipelineSharedData.getSortingForSet(
                  false,
                  true
                );
                filters.SortType = sortType;
                filters.SortColumn = sortColumn;
                pipelineService.setPipelineCardFilters(filters).then(() => {
                  $scope.getTableView(
                    params.page(),
                    $defer,
                    $scope.showTableLoading
                  );
                  $scope.showTableLoading = true;
                });
              } else {
                $scope.getTableView(
                  params.page(),
                  $defer,
                  $scope.showTableLoading
                );
                $scope.showTableLoading = true;
              }
            },
          }
        );
      }

      $scope.initTableColumns();
      $scope.initTableFilters();
    };

    $scope.initTableColumns = () => {
      $scope.listViewTableParams.cols = [
        {
          field: 'ClientName',
          title: 'Client Name',
          show: true,
        },
        {
          field: 'Lender',
          title: 'Lender',
          show: true,
        },
        {
          field: 'PipelineStatus',
          title: 'Stage',
          show: true,
        },
        {
          field: 'EnquirySource',
          title: 'Enquiry Source',
          show: true,
        },
        {
          field: 'AdvisorName',
          title: 'Adviser',
          show: true,
        },
        {
          field: 'ReferrerName',
          title: 'Referrer',
          show: true,
        },
        {
          field: 'LoanAmount',
          title: 'Amount',
          show: true,
        },
        {
          field: 'SubStatusName',
          title: 'SubStatus',
          show: true,
        },
        {
          field: 'Label',
          title: 'Label',
          show: true,
        },
      ];
    };

    $scope.initTableFilters = () => {
      $scope.Table.FilterColumns = pipelineSharedData.getApplicationTableColumns();
      _.forEach($scope.Table.FilterColumns, col => {
        if (
          _.find(
            $scope.pipelineSharedData.leadFilterData
              .TableViewExcludeColumnsArray,
            exclude => {
              return exclude === col.field;
            }
          )
        ) {
          col.show = false;
        }
      });
    };

    $scope.reloadTableView = (showLoading = true, retainPage) => {
      $scope.showTableLoading = showLoading;
      pipelineSharedData.reloadTableView(
        $scope.listViewTableParams,
        retainPage
      );
    };

    $scope.getTableView = (pageNumber = 1, promise, showLoading = true) => {
      const clientNameFilter = $scope.filter ? $scope.filter.$ : '';
      const params = { isLeads, pageNumber, pageSize };
      if (clientNameFilter) {
        params.searchClientName = clientNameFilter;
      }
      $scope.isCategoriesLoading = showLoading;

      pipelineService.getPipelineCardViewTable(params).then(
        response => {
          $scope.appsRefreshing = false;
          const corporate = uiService.isCorporateUser;
          if (corporate && $scope.advisersId === 0) {
            $scope.defaultSortSet(false, true);
            return;
          }

          $scope.listViewTable =
            pipelineSharedData.formatListViewData(
              response.data,
              $scope.appsStatusList
            ) || [];
          $scope.resolvedData = $scope.listViewTable;
          const totalRecords = pipelineSharedData.getTotalRecords(
            $scope.listViewTable
          );
          $scope.listViewTableParams.total(totalRecords);

          if (promise) {
            promise.resolve($scope.listViewTable);
          }

          const timeout = showLoading ? 700 : 0;
          $scope.loaderTimeout = $timeout(() => {
            $scope.isCategoriesLoading = false;
            if ($scope.loaderTimeout) $timeout.cancel($scope.loaderTimeout);
          }, timeout);
        },
        () => {
          $scope.appsRefreshing = false;
          $scope.isCategoriesLoading = false;
          $scope.listViewTableParams.resolve([]);
          $scope.listViewTableParams.total(0);
        }
      );
    };
    $scope.$on(
      '$destroy',
      () => $scope.loaderTimeout && $timeout.cancel($scope.loaderTimeout)
    );

    $scope.filterXsApp = false;
    $rootScope.filterXsAppList = false;
    $scope.openFilter = function() {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/applications/partials/pipeline_mobile_filters.html',
        resolve: {
          pipelineSharedData() {
            return $scope.pipelineSharedData;
          },
          taskAdviserList() {
            return $scope.taskAdviserList;
          },
          adviser() {
            return $scope.taskAdviserList[0];
          },
          labelsList() {
            return $scope.labels;
          },
          DisplayMode() {
            return $scope.DisplayMode;
          },
        },
        size: 'sm',
        windowTopClass: 'content-no-box-shadow',
        controller: 'PipelineModalAppFilterCtrl',
      });
      modalInstance.result.then(response => {
        if (response && response.leadFilterData) {
          angular.extend(
            $scope.pipelineSharedData.leadFilterData,
            response.leadFilterData
          );
          if ($scope.DisplayMode === 'cards') {
            $scope.getAllCards(true);
          } else {
            $scope.getListViewAppsData(true);
          }
        }
      });
    };
    $scope.makeFilterFalse = function() {
      $scope.filterXsApp = false;
      $rootScope.filterXsAppList = false;
    };
    $scope.filterResult = function(adviser) {
      $scope.filterXsApp = false;
      $scope.filterAdviser(adviser);
    };
    $scope.filterResultList = function(status, adviser, label) {
      $rootScope.filterXsAppList = false;
      $scope.filterStatus(status);
      $scope.filterAdviser(adviser);
      $scope.filterLabel(label);
    };
    $scope.openAppDetailModal = function(size, list) {
      $scope.appDetailModalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/applications/partials/app_detail_modal.html',
        resolve: {
          appsStatusList() {
            return $scope.appsStatusList;
          },
          labels() {
            return $scope.labels;
          },
          list() {
            return list;
          },
          workbench() {
            return $scope.workbench;
          },
        },
        controller: 'PipelineAppDetailModalCtrl',
        size,
        windowTopClass: 'content-no-box-shadow',
      });
      $scope.appDetailModalInstance.result.then(
        response => {
          if (response) {
            if (
              response.statusObj &&
              response.statusObj.PipelineStatus &&
              !response.pipelineConversionStatus
            ) {
              $scope.setStat(response.statusObj, response.card, '');
            }
            if (
              response.lblObj &&
              response.lblObj.ColorName &&
              response.lblObj.ColorName.trim()
            ) {
              $scope.setLbl(response.lblObj, response.card);
            }
          }

          if ($scope.DisplayMode === 'list_view') {
            $scope._initListViewApp();
          } else {
            $scope._cardInitApp();
          }
        },
        () => {
          $scope.getAllLabels();
        }
      );
    };
    $scope.openLabelsModal = function(card) {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/partials/pipeline_labels_modal.html',
        resolve: {
          card() {
            return card;
          },
          labels() {
            return $scope.labels;
          },
        },
        size: 'sm',
        windowTopClass: 'content-no-box-shadow',
        controller: 'PipelineLabelsModalCtrl',
      });

      modalInstance.result.then(
        () => {
          $scope.getAllLabels();
        },
        () => {
          $scope.getAllLabels();
        }
      );
    };
    $scope.performAction = function(lbl, cardObj, index) {
      $scope.result = $scope.isIncludedForCard(lbl, cardObj, index);
      if ($scope.result === false) {
        $scope.setLabelForCard(lbl, cardObj);
      } else {
        $scope.deleteLabelFromCard(lbl, cardObj);
      }
    };
    $scope.performActionList = function(lbl, listObj, index) {
      $scope.result = $scope.isIncludedForList(lbl, listObj, index);
      if ($scope.result === false) {
        $scope.setLabelForCardFromList(lbl, listObj);
      } else {
        $scope.deleteLabelFromCardForList(lbl, listObj);
      }
    };
    $scope.isIncludedForList = function(lbl, list) {
      const indexOfLblInList = _.findIndex(list.LabelArray, l => {
        return (
          parseInt(l.labelID, 10) === parseInt(lbl.pipelineSettingsLabelID, 10)
        );
      });
      const isMatch = indexOfLblInList !== -1;
      return isMatch;
    };

    $scope.isIncludedForCard = (lbl, cardObj) =>
      pipelineSharedData.isLabelInCard(lbl, cardObj);

    $scope.$watch(
      'kanbanBoard2.columns',
      nv => {
        if (_.size(nv) > 0) {
          _.forEach(nv, column => {
            let hasOpenedHelper = false;
            _.forEach(column.cards, item => {
              if (item.textOpen === true) {
                hasOpenedHelper = true;
              }
              _.forEach(item.labels, label => {
                if (label.textOpen === true) {
                  hasOpenedHelper = true;
                }
              });
            });
            column.isLabelHelperOpen = hasOpenedHelper;
          });
        }
      },
      true
    );
    $scope.deleteLabelFromCardForList = function(lblObj, listObj) {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = listObj.PipelineCardsID;
      $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(
        () => {
          toaster.pop(
            'success',
            'Deleted',
            'Label has been deleted from your list.'
          );
          $scope.onItemLabelDeleted(lblObj, listObj);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.onItemLabelDeleted = (removedLabel, item) => {
      pipelineSharedData.removeLabelFromItem(removedLabel, item);
    };
    $scope.deleteLabelFromCard = function(lblObj, cardObj) {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = cardObj.PipelineItemId;
      $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(
        () => {
          toaster.pop(
            'success',
            'Deleted',
            'Label has been deleted from your list.'
          );
          $scope.onCardLabelDeleted(lblObj, cardObj);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.onCardLabelDeleted = (removedLabel, card) => {
      if (!removedLabel || !card) return;

      _.remove(
        card.labels,
        label =>
          parseInt(label.labelID, 10) ===
          parseInt(removedLabel.pipelineSettingsLabelID, 10)
      );
    };

    $scope.loadCards = () => {
      $scope._cardInitApp();
    };

    $scope.loadTableView = () => {
      $scope.getListViewAppsData(true);
    };

    const getApplicationCardView = $rootScope.$on('_cardInitApp', () => {
      $scope._cardInitApp();
    });
    $scope.$on('$destroy', getApplicationCardView);
    const getApplicationListView = $rootScope.$on('_initListViewApp', () => {
      $scope._initListViewApp();
    });
    $scope.$on('$destroy', getApplicationListView);

    $scope.getAllRequiredLists = () => {
      const promises = [];

      const isStatusListEmpty =
        !$scope.appsStatusList || !$scope.appsStatusList.length;
      if (isStatusListEmpty) {
        const getAppStatusesPromise = $scope.getAllAppStatus();
        promises.push(getAppStatusesPromise);
      }

      const isAdviserListEmpty =
        !$scope.adviserList || !$scope.adviserList.length;
      if (isAdviserListEmpty) {
        const getAdvisersPromise = $scope.getAllAdvisers();
        promises.push(getAdvisersPromise);
      }

      const isLabelsListEmpty = !$scope.labels || !$scope.labels.length;
      if (isLabelsListEmpty) {
        const getLabelsPromise = $scope.getAllLabels();
        promises.push(getLabelsPromise);
      }

      if (!promises.length) {
        const emptyPromise = $q.defer();
        promises.push(emptyPromise);
        emptyPromise.resolve();
      }

      return $q.all(promises);
    };

    $scope._cardInitApp = function() {
      $scope.isHoverLabel = false;
      // Below are extracting sortMode from string to object
      const leadFilterSortingMode =
        $scope.pipelineSharedData.leadFilterData.sortingMode;
      const sortingModeArray = pipelineSharedData.sortingModeArray(
        leadFilterSortingMode
      );
      const sortingModeObj = {};
      if (_.size(sortingModeArray) > 0) {
        _.forEach(sortingModeArray, mode => {
          const extractKeyValue = mode.split(':');
          sortingModeObj[extractKeyValue[0]] = extractKeyValue[1];
        });
      }
      // overwrite the sort string to be sort obj
      $scope.pipelineSharedData.leadFilterData.sortingModeObj = sortingModeObj;
      pipelineSharedData.formatCustomerSortForView();
      $scope.isCategoriesLoading =
        (!$scope.kanbanBoard2 || _.isEmpty($scope.kanbanBoard2.columns)) &&
        $scope.DisplayMode === 'cards';
      $scope.getAllRequiredLists().then(() => {
        const shouldShowLoading =
          !$scope.kanbanBoard2 ||
          !$scope.kanbanBoard2.columns ||
          !$scope.kanbanBoard2.columns.length;
        $scope.getAllCards(shouldShowLoading);
      });
      $scope.showStats();
    };
    $scope._initListViewApp = function() {
      $scope.sortType = $rootScope.sortType;
      $scope.sortColumn = $rootScope.sortColumn;
      $scope.isCategoriesLoading =
        _.isEmpty($scope.listViewTable) && $scope.DisplayMode === 'list_view';
      $scope.getAllRequiredLists().then(() => {
        if (
          $scope.pipelineSharedData.leadFilterData &&
          (typeof $scope.pipelineSharedData.leadFilterData.adviserId !==
            'undefined' &&
            typeof $scope.pipelineSharedData.leadFilterData.adviserId !==
              'undefined')
        ) {
          $scope.adviserId = $scope.pipelineSharedData.leadFilterData.adviserId;
        } else {
          $scope.adviserId = 0;
        }
        // override filter adviser ID
        if (pipelineSharedData.appFilterData.adviserId) {
          const sharedAdviserId = pipelineSharedData.appFilterData.adviserId;
          if (
            $scope.adviser &&
            typeof $scope.pipelineSharedData.leadFilterData.adviserId !==
              'undefined'
          ) {
            $scope.pipelineSharedData.leadFilterData.adviserId = sharedAdviserId;
          }
          $scope.adviserId = sharedAdviserId;
        }
        // will apply to shared data
        if ($scope.adviserId) {
          pipelineSharedData.appFilterData.adviserId = $scope.adviserId;
        }
        if (
          $scope.pipelineSharedData.leadFilterData &&
          (typeof $scope.pipelineSharedData.leadFilterData.labelId !==
            'undefined' &&
            $scope.pipelineSharedData.leadFilterData.labelId !== 'undefined')
        ) {
          $scope.labelId = $scope.pipelineSharedData.leadFilterData.labelId;
        } else {
          $scope.labelId = 0;
        }
        // override filter label ID
        let sharedLabelId;
        if (pipelineSharedData.appFilterData.labelId) {
          sharedLabelId = pipelineSharedData.appFilterData.labelId;
          if (
            $scope.selectedLabel &&
            typeof $scope.selectedLabel.pipelineSettingsLabelID !== 'undefined'
          ) {
            $scope.selectedLabel.pipelineSettingsLabelID = sharedLabelId;
          }
          $scope.labelId = sharedLabelId;
        }
        // will apply to shared data
        if ($scope.labelId) {
          pipelineSharedData.appFilterData.labelId = $scope.labelId;
        }
        if (
          $scope.pipelineSharedData.leadFilterData &&
          (typeof $scope.pipelineSharedData.leadFilterData.PipelineStatusID !==
            'undefined' &&
            $scope.pipelineSharedData.leadFilterData.PipelineStatusID !==
              'undefined')
        ) {
          $scope.statusId =
            $scope.pipelineSharedData.leadFilterData.PipelineStatusID;
        } else {
          $scope.statusId = 0;
        }
        // override filter label ID
        if (pipelineSharedData.appFilterData.pipelineStatusId) {
          const sharedStatusId =
            pipelineSharedData.appFilterData.pipelineStatusId;
          if (
            $scope.selectedStatusForFilter &&
            typeof $scope.selectedStatusForFilter.PipelineStatusID !==
              'undefined'
          ) {
            $scope.selectedStatusForFilter.PipelineStatusI = sharedStatusId;
          }
          $scope.statusId = sharedLabelId;
        }
        // will apply to shared data
        if ($scope.statusId) {
          pipelineSharedData.appFilterData.pipelineStatusId = $scope.statusId;
        }
        $scope.getListViewAppsData(_.isEmpty($scope.listViewTable));
      });
    };
    $scope.isHoverDelete = false;
    $scope.$watch(
      'pipelineSharedData.leadFilterData',
      nv => {
        if (typeof nv.isCompact === 'undefined') {
          nv.isCompact = '0';
        }
        if (
          nv.enquiryId === 'All' ||
          nv.enquiryId === 'null' ||
          nv.enquiryId === null ||
          typeof nv.enquiryId === 'undefined' ||
          !nv.enquiryId
        ) {
          nv.enquiryId = 0;
        }
        if (
          nv.referralId === 'All' ||
          nv.referralId === 'null' ||
          nv.referralId === null ||
          typeof nv.referralId === 'undefined' ||
          !nv.referralId
        ) {
          nv.referralId = -1;
        }
      },
      true
    );
    function init() {
      $scope.isLeads = false;
      $scope.isFirstLoad = true;
      pipelineSharedData.leadFilterData = [];
      $scope.isCategoriesLoading = !$scope.DisplayMode;

      pipelineService.getPipelineCardFilters($scope.isLeads).then(response => {
        const { data } = response;
        if (!data) return;

        pipelineSharedData.leadFilterData = data[0] || {
          adviserId: 0,
          IsTableView: false,
          isCompact: '1',
          CustomerSort: '',
          sortingMode: '',
          TableViewExcludeColumns: '',
          isFetchAllPipelineStatusIDs: 1,
        };
        pipelineSharedData.initCommonFilters(pipelineSharedData.leadFilterData);
        $scope.DisplayMode = pipelineSharedData.leadFilterData.IsTableView
          ? 'list_view'
          : 'cards';
        pipelineSharedData.leadFilterData.isCompact = pipelineSharedData
          .leadFilterData.isCompact
          ? '1'
          : '0';
        pipelineSharedData
          .populateApplicationFilters(pipelineSharedData.leadFilterData)
          .then(() => {
            if ($scope.DisplayMode === 'cards') {
              $scope.showModeApp('cards');
            } else {
              $scope.showModeApp('list_view');
            }
          });
      });
      if ($stateParams.createdContact !== '') {
        $scope.openAddCardModal(
          'modalWidthAuto',
          $stateParams.createdContact,
          'application'
        );
      }
      $scope.searchWrapperApp = {};
      $scope.searchWrapperApp.searchPipelineApp = '';

      pipelineSharedData.initCardViewStates();
      $scope.cardViewStates = pipelineSharedData.cardViewStates;
    }
    $scope.refreshApplications = function() {
      $scope.appsRefreshing = true;
      if ($scope.DisplayMode === 'list_view') {
        $scope.isFirstLoad = true;
        $scope.reloadTableView();
      } else {
        $scope.isFirstLoad = true;
        $scope.getAllCards(true);
      }
    };
    $scope.onDropAreaMouseIn = function(dropAreaId) {
      const areaDragged = pipelineSharedData.onDropAreaMouseIn(dropAreaId);
      if (areaDragged) {
        $scope.cardViewStates[areaDragged] = $scope.cardViewStates.cardOnDrag;
      }
    };
    $scope.showStats = function() {
      // stats progress config
      $scope.initializeStatsConfig();
      const dateFormat = 'DD MMM YYYY';
      $scope.toDate = moment().format(dateFormat);
      switch ($scope.pipelineSharedData.dateRangeFilter) {
        case DATE_RANGE.Month1:
          $scope.fromDate = moment()
            .subtract(1, 'months')
            .format(dateFormat);
          break;
        case DATE_RANGE.Months3:
          $scope.fromDate = moment()
            .subtract(3, 'months')
            .format(dateFormat);
          break;
        case DATE_RANGE.Month6:
          $scope.fromDate = moment()
            .subtract(6, 'months')
            .format(dateFormat);
          break;
        case DATE_RANGE.Months12:
          $scope.fromDate = moment()
            .subtract(1, 'year')
            .format(dateFormat);
          break;
        default:
          break;
      }
      pipelineService
        .getPipelineCardsSummary($scope.fromDate, $scope.toDate, 1)
        .then(response => {
          $scope.statePer = response.data;
          if ($scope.statePer) {
            $scope.convertedToSettledValue = $scope.statePer.Successful;
            $scope.notProceedingValue = $scope.statePer.UnSuccessfulPercent;
            $scope.convertedToSettledDisplayValue = Math.round(
              $scope.statePer.Successful
            );
            $scope.notProceedingDisplayValue = Math.round(
              $scope.statePer.UnSuccessfulPercent
            );
          }
          $scope.statsLoaded = true;
        });
    };
    $scope.initializeStatsConfig = function() {
      if (
        $scope.convertedToSettledStatsOptions &&
        $scope.notProceedingStatsOptions
      )
        return;
      $scope.convertedToSettledStatsOptions = {
        displayPrevious: false,
        barCap: 3,
        trackWidth: 3,
        barWidth: 3,
        size: 36,
        readOnly: true,
        trackColor: 'transparent',
        barColor: '#3D91CC',
        textColor: '#579BCB',
        unit: '%',
      };
      $scope.notProceedingStatsOptions = {};
      angular.extend(
        $scope.notProceedingStatsOptions,
        $scope.convertedToSettledStatsOptions
      );
      $scope.notProceedingStatsOptions.barColor = '#42B4B4';
      $scope.notProceedingStatsOptions.textColor = '#42B4B4';
    };
    $scope.searchCards = (searchFilter = '') => {
      const isInvalidSearchFilter =
        searchFilter.length > 0 && searchFilter.length < 3;
      if (isInvalidSearchFilter) return;

      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        $scope.isCategoriesLoading = true;
        const adviserId = pipelineSharedData.leadFilterData
          ? pipelineSharedData.leadFilterData.adviserId
          : 0;
        $scope.getPipelineCards(adviserId, searchFilter);
      }, 1000);
    };
    $scope.columnHasMore = column => pipelineCardsService.columnHasMore(column);
    $scope.viewMoreItems = column => {
      if (!column) return;

      column.loadingMore = true;
      const filterStatusId = column.PipelineStatusID;
      const pageNumber =
        pipelineCardsService.getColumnCurrentPage(column, pageSize) + 1;
      const params = { isLeads, filterStatusId, pageNumber, pageSize };
      const searchClientName = $scope.searchWrapperApp.searchPipelineApp;
      if (searchClientName) {
        params.searchClientName = searchClientName;
      }
      pipelineService.getGroupedPipelineCards(params).then(
        response => {
          if (!response || !response.data) return;

          const moreItems = response.data[filterStatusId];
          if (moreItems && moreItems.length) {
            if (!$scope.appsCards) $scope.appsCards = {};

            if ($scope.appsCards[filterStatusId]) {
              $scope.appsCards[filterStatusId] = [
                ...$scope.appsCards[filterStatusId],
                ...moreItems,
              ];
            } else {
              $scope.appsCards[filterStatusId] = moreItems;
            }
            const category = $scope.appsStatusList.find(
              status => status.PipelineStatusID === filterStatusId
            );
            const cardsForBoard = moreItems.map(card =>
              pipelineCardsService.buildCardForBoard(card, category)
            );
            if (column.cards) {
              column.cards = [...column.cards, ...cardsForBoard];
            } else {
              column.cards = cardsForBoard;
            }
            column.TotalRecords = pipelineCardsService.getColumnTotalRecords(
              column
            );
          }

          column.loadingMore = false;
        },
        () => {
          column.loadingMore = false;
        }
      );
    };

    $scope.stopPropagation = e => {
      if (e) {
        e.stopPropagation();
      }
    };

    $scope.workbench = (e, card) => {
      if (
        e.target &&
        e.target.nodeName === 'A' &&
        e.target.classList.contains('ng-binding')
      ) {
        return false;
      }

      if (!card || !card.LoanID) return;
      if (!isMobileView($window.innerWidth)) {
        const modalInstance = $uibModal.open({
          templateUrl: 'assets/views/loanApplication/components/workbench.html',
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            cLoanId: () => card.LoanID,
            CLoanStatusId: () => String(card.LoanStatusID),
            CPipelineCardsID: () => card.PipelineItemId,
            cLoanScenarioId: () => card.LoanScenarioID,
            cFamilyId: () => card.clientFamilyId,
            cEnquirySource: () => card.EnquirySource,
          },
          controller: 'WorkbenchModalCtrl',
          controllerAs: 'vm',
          windowTopClass: 'workbench-modal',
        });

        modalInstance.result.then(
          () => {},
          result => {
            if (String(card.LoanStatusID) !== String(result.loanStatusId)) {
              if ($scope.DisplayMode === 'cards') {
                $scope.getAllCards(true);
              } else {
                $scope.getListViewAppsData(true);
              }
            }
          }
        );
      } else {
        $scope.appDetailModalInstance.dismiss();
        const params = {
          LoanID: card.LoanID,
          LoanStatusID: String(card.LoanStatusID),
          PipelineCardsID: card.PipelineItemId,
          LoanScenarioID: card.LoanScenarioID,
          familyId: card.clientFamilyId,
          enquirySource: card.EnquirySource,
        };
        stateService.savePrevState(
          $state.$current.self,
          $state.$current.locals.globals.$stateParams
        );
        $state.go('app.mobileWorkbench', {
          LoanID: params.LoanID,
          LoanStatusID: params.LoanStatusID,
          PipelineCardsID: params.PipelineCardsID,
          LoanScenarioID: params.LoanScenarioID,
          familyId: params.familyId,
          enquirySource: params.enquirySource,
        });
      }
    };

    init();

    $scope.$on('$destroy', () => {
      if ($scope.searchTimeout) {
        $timeout.cancel($scope.searchTimeout);
      }
    });
  });
