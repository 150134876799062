import template from './relatedPartySupplier.html';
import controller from './relatedPartySupplierCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    supplierList: '<',
  },
};
