import template from './providersContainer.html';
import controller from './providersContainerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    providerId: '<',
    providerCountryCode: '<',
    providerName: '<',
    providerRatesData: '<',
    providerVariableRate: '<',
    providerInsuranceId: '<',
    providerLenderId: '<',
    providerCategories: '<',
    providerDescription: '<',
    providerCode: '<',
  },
};
