import { ADVISER_TAB } from 'Common/constants/contactTabIndex';

class ContactModel {
  constructor($q, $state, $window, contactService) {
    'ngInject';

    this.$q = $q;
    this.$state = $state;
    this.$window = $window;
    this.contactService = contactService;
    this.isAdviserContactList = false;
  }

  getContactDataModel(role, countryCode, countryBirth, sourceFamilyId = null) {
    this.data = {
      SourceFamilyID: sourceFamilyId,
      FamilyId: '0',
      LastName: '',
      FirstName: '',
      MiddleName: '',
      PreferredName: '',
      Title: '',
      Gender: '',
      DOB: '',
      Role: role,
      Employment: [
        {
          Occupation: '',
          OccupationClass: 0,
          Employer: '',
        },
      ],
      SmokerStatus: '',
      Deceased: false,
      DOBReminder: false,
      BestTimeToCall: '',
      IsResident: true,
      Phone: [
        {
          Type: 'Mobile',
          Number: '',
        },
      ],
      Email: [
        {
          Type: 'Email',
          EmailAddress: '',
        },
      ],
      Notes: '',
      CountryCode: countryCode,
      CountryOfBirth: countryBirth,
      ResidencyId: '',
      MaritalStatusId: '',
      PreferredContactMethodId: '',
      customerConsentOptions: { ConsentMarketing: true },
      isIncluded: true,
      IsFirstHomeBuyers: false,

      IsCustomerOfLender: false,
      IsEmployeeOfLender: false,
    };
    return this.data;
  }

  setCurrentTab(contactType, familyID) {
    if (contactType === 'adviser') {
      this.$window.sessionStorage.setItem(
        'currentTab',
        ADVISER_TAB.COMMUNICATIONS
      );
      this.$state.go('app.contactsAdviser', {
        familyId: familyID,
        clientId: 0,
      });
    } else if (contactType === 'client') {
      this.$window.sessionStorage.setItem('currentTabClient', 'emails');
      this.$state.go('app.contactsSingle', {
        familyId: familyID,
        activeTab: 'emails',
      });
    }
  }

  contactTypeChecker(familyId) {
    const defer = this.$q.defer();
    this.contactService.clientInformGet(familyId).then(response => {
      const data = response.data;
      const entity = data.filter(contact => contact.isEntity);
      if (entity && entity.length) {
        defer.resolve('entity');
      } else {
        defer.resolve('client');
      }
    });
    return defer.promise;
  }

  redirectToContactDetails(familyId, activeTab) {
    this.contactTypeChecker(familyId).then(type => {
      if (type === 'client') {
        this.$state.go('app.contactsSingle', { familyId, activeTab });
      } else {
        this.$state.go('app.BusinessAccount', { familyId, activeTab });
      }
    });
  }
}

export default ContactModel;
