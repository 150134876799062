import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('commissionsReceived', function commissionsReceived(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/commissionsReceived.html',

      link(scope) {
        scope.commissionReceivedTableDropDownList = [];
        scope.commissionReceivedTableDropDowns = {};
        scope.filteredCommissionReceivedDropDownList = [];
        scope.commissionReceivedMultiAdviser = [];
        scope.commissionReceivedMultiAdviserObj = {};
        scope.commissionReceivedMultiLender = [];
        scope.commissionReceivedMultiLenderObj = {};
        scope.WidgetOptionGetData = [];
        scope.outp = [];
        scope.outpLender = [];

        function CommissionRecievedSmallGet() {
          overviewDashboardService
            .CommissionRecievedSmallGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.commissionRecievedSmallData = response.data;
              },
              () => {}
            );
        }

        function WidgetOptionTimePeriodListv3Get() {
          overviewDashboardService.WidgetOptionTimePeriodListv3Get().then(
            response => {
              scope.commissionReceivedTableDropDownList = response.data;
              scope.commissionReceivedTableDropDowns =
                scope.commissionReceivedTableDropDownList[0];
              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  res => {
                    scope.WidgetOptionGetData = res.data;
                    for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                      if (
                        parseInt(
                          scope.WidgetOptionGetData[i].WidgetOptionId,
                          10
                        ) === 5
                      ) {
                        const widgetOptionValue =
                          scope.WidgetOptionGetData[i].Widget_OptionValue;
                        scope.filteredCommissionReceivedDropDownList = scope.commissionReceivedTableDropDownList.filter(
                          item =>
                            parseInt(item.OptionValueId, 10) ===
                            parseInt(widgetOptionValue, 10)
                        );

                        scope.commissionReceivedTableDropDowns =
                          scope.filteredCommissionReceivedDropDownList[0];
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }
        function WidgetOptionAdviserCommissionList() {
          overviewDashboardService.WidgetOptionAdviserCommissionList().then(
            response => {
              scope.WidgetOptionComAdviserList = response.data;
              for (
                let i = 0;
                i < scope.WidgetOptionComAdviserList.length;
                i++
              ) {
                scope.commissionReceivedMultiAdviserObj = {
                  FamilyName: scope.WidgetOptionComAdviserList[i].FamilyName,
                  FamilyId: scope.WidgetOptionComAdviserList[i].FamilyId,
                  OptionValueId:
                    scope.WidgetOptionComAdviserList[i].OptionValueId,
                  OptionDB_Value:
                    scope.WidgetOptionComAdviserList[i].OptionDB_Value,
                  ticked: false,
                  WidgetOptionId: scope.WidgetOptionComAdviserList[i].OptionId,
                };
                scope.commissionReceivedMultiAdviser.push(
                  scope.commissionReceivedMultiAdviserObj
                );
              }
              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  res => {
                    scope.WidgetOptionGetData = res.data;
                    scope.WidgetOptionGetAdviser = {};
                    scope.Advisers = [];
                    for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                      if (
                        parseInt(
                          scope.WidgetOptionGetData[j].WidgetOptionId,
                          10
                        ) === 9
                      ) {
                        scope.Advisers =
                          scope.WidgetOptionGetData[j].SelectedUerValues;
                        const AdvisersArray = scope.Advisers.split(',');
                        scope.commissionReceivedMultiAdviser = scope.commissionReceivedMultiAdviser.map(
                          obj => {
                            if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                              obj.ticked = true;
                              return obj;
                            }

                            return obj;
                          }
                        );
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }
        function WidgetOptionLenderListGet() {
          overviewDashboardService.WidgetOptionLenderListGet().then(
            response => {
              scope.WidgetOptionLenderList = response.data;
              for (let i = 0; i < scope.WidgetOptionLenderList.length; i++) {
                scope.commissionReceivedMultiLenderObj = {
                  ProviderName: scope.WidgetOptionLenderList[i].ProviderName,
                  ProviderId: scope.WidgetOptionLenderList[i].ProviderId,
                  OptionValueId: scope.WidgetOptionLenderList[i].OptionValueId,
                  OptionDB_Value:
                    scope.WidgetOptionLenderList[i].OptionDB_Value,
                  ticked: false,
                  WidgetOptionId: scope.WidgetOptionLenderList[i].OptionId,
                };
                scope.commissionReceivedMultiLender.push(
                  scope.commissionReceivedMultiLenderObj
                );
              }
              overviewDashboardService
                .WidgetOptionGet(scope.myParam.Widget_UserID)
                .then(
                  res => {
                    scope.WidgetOptionGetData = res.data;
                    scope.WidgetOptionGetLender = {};
                    scope.Lenders = [];
                    for (let j = 0; j < scope.WidgetOptionGetData.length; j++) {
                      if (
                        parseInt(
                          scope.WidgetOptionGetData[j].WidgetOptionId,
                          10
                        ) === 6
                      ) {
                        scope.Lenders =
                          scope.WidgetOptionGetData[j].SelectedUerValues;
                        const LendersArray = scope.Lenders.split(',');
                        scope.commissionReceivedMultiLender = scope.commissionReceivedMultiLender.map(
                          obj => {
                            if (LendersArray.indexOf(obj.ProviderId) > -1) {
                              obj.ticked = true;
                              return obj;
                            }

                            return obj;
                          }
                        );
                      }
                    }
                  },
                  () => {}
                );
            },
            () => {}
          );
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(CommissionRecievedSmallGet)
            .catch(displayError);
        }

        scope.updateMultiselectFilterDataLender = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSetLender = {};
          scope.MultiOptionSetForAllLender = {};
          const itemData = _.find(scope.commissionReceivedMultiLender, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.commissionReceivedMultiLender, item => {
              if (item.ProviderName === clickedItem.ProviderName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.ProviderName !== 'All') {
            if (_.size(scope.commissionReceivedMultiLender) !== 0) {
              scope.commissionReceivedMultiLender[0].ticked = false;
            }

            dataToSend = scope.commissionReceivedMultiLender
              .filter(
                item => item.ProviderName !== 'All' && item.ticked === true
              )
              .map(obj => {
                return { PersonId: obj.ProviderId };
              });

            scope.MultiOptionSetLender.SelectedUser = dataToSend;
            scope.MultiOptionSetLender.WidgetOptionId =
              scope.WidgetOptionLenderList[0].OptionId;
            scope.MultiOptionSetLender.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetLender.Widget_OptionValue =
              scope.WidgetOptionLenderList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetLender);
          } else {
            const all = scope.outpLender.filter(
              item => item.ProviderName === 'All'
            );
            if (all.length > 0 && scope.outpLender.length > 1) {
              scope.commissionReceivedMultiLender = scope.commissionReceivedMultiLender.map(
                obj => {
                  if (obj.ProviderName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.commissionReceivedMultiLender[0].ProviderId,
            });

            scope.MultiOptionSetForAllLender.SelectedUser = dataToSend;
            scope.MultiOptionSetForAllLender.WidgetOptionId =
              scope.WidgetOptionLenderList[0].OptionId;
            scope.MultiOptionSetForAllLender.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAllLender.Widget_OptionValue =
              scope.WidgetOptionLenderList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAllLender);
          }
        };
        scope.updateMultiselectFilterDataAdviser = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.commissionReceivedMultiAdviser, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.commissionReceivedMultiAdviser, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.commissionReceivedMultiAdviser) !== 0) {
              scope.commissionReceivedMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.commissionReceivedMultiAdviser
              .filter(item => item.FamilyName !== 'All' && item.ticked === true)
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId =
              scope.WidgetOptionComAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue =
              scope.WidgetOptionComAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.commissionReceivedMultiAdviser = scope.commissionReceivedMultiAdviser.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.commissionReceivedMultiAdviser[0].FamilyId,
            });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId =
              scope.WidgetOptionComAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue =
              scope.WidgetOptionComAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };
        scope.showNextVal = function(displayObj) {
          scope.currentObj = displayObj;
          const displayPos = scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;
          if (
            arrayPos + 1 >
            scope.commissionReceivedTableDropDownList.length - 1
          ) {
            scope.commissionReceivedTableDropDowns =
              scope.commissionReceivedTableDropDownList[0];
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                response => {
                  scope.WidgetOptionGetData = response.data;
                },
                () => {}
              );
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue:
                scope.commissionReceivedTableDropDowns.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }
          scope.commissionReceivedTableDropDowns =
            scope.commissionReceivedTableDropDownList[arrayPos + 1];
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;
              },
              () => {}
            );
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue:
              scope.commissionReceivedTableDropDowns.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };
        scope.showPrevVal = function(displayObj) {
          scope.currentObj = displayObj;
          const displayPos = scope.currentObj.OptionDB_Value;
          const arrayPos = displayPos - 1;

          if (arrayPos - 1 === -1) {
            const lastPos =
              scope.commissionReceivedTableDropDownList.length - 1;
            scope.commissionReceivedTableDropDowns =
              scope.commissionReceivedTableDropDownList[lastPos];
            overviewDashboardService
              .WidgetOptionGet(scope.myParam.Widget_UserID)
              .then(
                response => {
                  scope.WidgetOptionGetData = response.data;
                },
                () => {}
              );
            scope.objForWidgetOptionSet = {
              WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionId,
              Widget_UserID: scope.myParam.Widget_UserID,
              Widget_OptionValue:
                scope.commissionReceivedTableDropDowns.OptionValueId,
              SelectedUser: null,
            };

            saveForTimePeriod(scope.objForWidgetOptionSet);
            return;
          }
          scope.commissionReceivedTableDropDowns =
            scope.commissionReceivedTableDropDownList[arrayPos - 1];
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;
              },
              () => {}
            );
          scope.objForWidgetOptionSet = {
            WidgetOptionId: scope.commissionReceivedTableDropDowns.OptionId,
            Widget_UserID: scope.myParam.Widget_UserID,
            Widget_OptionValue:
              scope.commissionReceivedTableDropDowns.OptionValueId,
            SelectedUser: null,
          };

          saveForTimePeriod(scope.objForWidgetOptionSet);
        };
        scope.doAction = function(msg) {
          if (msg === 'edit') scope.customizeMode = true;
          else scope.customizeMode = false;
          if (msg === 'notEdit') {
            scope.customizeMode = false;
          } else scope.customizeMode = true;
        };
        function init() {
          CommissionRecievedSmallGet();
          WidgetOptionTimePeriodListv3Get();
          WidgetOptionAdviserCommissionList();
          WidgetOptionLenderListGet();
        }

        init();
      },
    };
  });
