import template from './imageCircle.html';
import controller from './imageCircleCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    imageSource: '<',
    fullName: '@',
    initials: '@',
    color: '@',
    size: '@',
  },
};
