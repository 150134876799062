import angular from 'angular';

angular
  .module('app')
  .directive('customerCommentTable', function customerCommentTableDirective(
    $timeout,
    overviewDashboardService,
    $uibModal,
    npsService,
    userService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/customerCommentTable.html',

      link(scope) {
        scope.slickConfig = { method: {} };
        scope.dataLoaded = false;
        scope.showSlick = false;
        scope.commentsData = [];
        scope.interval = 'allDates';

        function convertToTableView(data) {
          scope.commentsData = [];
          const totalData = data.length;
          const round = Math.floor(totalData / 5) * 5 - 1;

          const modStart = Math.floor(totalData / 5) * 5;
          const mod = Math.floor(totalData % 5);
          for (let i = 0; i < round; i += 5) {
            const testData = [];
            testData.push(data[i]);
            testData.push(data[i + 1]);
            testData.push(data[i + 2]);
            testData.push(data[i + 3]);
            testData.push(data[i + 4]);

            scope.commentsData.push(testData);
          }

          if (parseInt(mod, 10) !== 0) {
            const lastItem = [];
            for (let i = modStart; i < totalData; i++) {
              lastItem.push(data[i]);
            }
            scope.commentsData.push(lastItem);
          }
          scope.dataLoaded = true;
          scope.showSlick = true;
          if (scope.commentsData.length > 0) {
            $timeout(() => {
              try {
                scope.slickConfig.method.slickGoTo(0);
              } catch (error) {
                // Continue regardless of error
              }
            }, 1000);
          }
        }

        scope.commentList = [];
        const getSurveyResponseTable = () => {
          npsService
            .getSurveyResponse(0, 1, 0, 0, scope.adviserFamilyId, true)
            .then(response => {
              if (response && response.data) {
                let data = response.data.SurveyResponses
                  ? response.data.SurveyResponses
                  : [];
                if (data.length) {
                  data = data.filter(item => {
                    return item.ResponseReceived;
                  });
                  convertToTableView(data);
                  scope.commentList = data;
                } else {
                  convertToTableView(data);
                  scope.commentList = data;
                }
              }
            });
        };
        const getUserInfo = () => {
          userService.GetUserInfo().then(response => {
            scope.adviserFamilyId = response.data.FamilyId || 0;
            getSurveyResponseTable();
          });
        };

        getUserInfo();

        scope.showCustomerCommentModal = function(data) {
          $uibModal.open({
            templateUrl:
              'assets/views/overviewDashboard/modals/customerCommentSingleModal.html',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            controller: 'CustomerCommentModalSingleCtrl',
            resolve: {
              comments() {
                return data;
              },
              alldata() {
                return scope.commentList;
              },
            },
          });
        };
      },
    };
  });
