import { pluralize } from 'Common/utilities/string';

const MONTHS_IN_YEAR = 12;

export function getFinanceCatIDsWithEmployer(financeCategories) {
  const financeCatWithEmployer = ['Novated Lease'];
  const financeCatWithEmployerIDs = financeCategories
    .filter(elem => financeCatWithEmployer.includes(elem.name))
    .map(elem => elem.value);
  return financeCatWithEmployerIDs;
}

export function getLoanTermInMonths(termValue, showUnit = true) {
  const monthValue = termValue ? Math.round(termValue * MONTHS_IN_YEAR) : 0;
  return showUnit ? pluralize(monthValue, 'month') : monthValue;
}

export function getFrequencyText(term) {
  const termNumber = term <= MONTHS_IN_YEAR ? term : term / MONTHS_IN_YEAR;
  const termString = term <= MONTHS_IN_YEAR ? 'month' : 'year';
  return `every ${pluralize(termNumber, termString)}`;
}
