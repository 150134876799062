import angular from 'angular';

angular
  .module('app')
  .directive('netPromoterScore', function netPromoterScore() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/netPromoterScore.html',

      link() {
        function init() {}

        init();
      },
    };
  });
