app.factory('incomeSharedService', [
  'uiService',
  function(uiService) {
    var factory = this;

    // methods init
    angular.extend(factory, {
      incomeServices: {
        removeGross,
        convertNetGross,
        getNewIncomeModel,
        findJoint,
        selectedType,
      },
    });

    // actual methods
    function removeGross(objectWithGross) {
      var toReturn = {};
      toReturn = _.omit(objectWithGross, ['IsGross']);

      return toReturn;
    }

    function findJoint(object) {
      const incomeObj = _.find(object, obj => {
        return parseInt(obj.BorrowerID, 10) === 0;
      });
      return incomeObj;
    }

    function selectedType(expenseType, typeId) {
      const expenseObj = _.find(expenseType, o => {
        return _.isEqual(o.Value, typeId);
      });
      return expenseObj;
    }

    function convertNetGross(toConvertValue, isToBoolean) {
      if (isToBoolean === true) {
        var toReturn = false;
        toConvertValue === 'grossTemp' ? (toReturn = true) : (toReturn = false);
      } else {
        var toReturn = '';
        toConvertValue === true
          ? (toReturn = 'grossTemp')
          : (toReturn = 'netTemp');
      }

      return toReturn;
    }

    function getNewIncomeModel(familyId) {
      return {
        IsEvidenceOfTenancy: true,
        Id: 0,
        FamilyId: familyId,
        FinancialId: 0,
        TypeId: '',
        Description: '',
        Amount: null,
        CurrencyCode: 'USD',
        Borrowers: [],
        FrequencyID: 4,
        Provider: '',
        IsGross: 'grossTemp',
      };
    }

    factory.deleteFirstIncomeForNZ = employment => {
      const { IsCurrent, Income } = employment;
      const isValidForPoppingIncome =
        !IsCurrent &&
        Income &&
        Income.length === 1 &&
        uiService.isCountry('New Zealand');

      if (isValidForPoppingIncome) {
        Income.pop();
      }
    };

    return factory;
  },
]);
