import angular from 'angular';
import swal from 'sweetalert';
import { WORK_BENCH_STATES } from 'Common/constants/workbench';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';

class WorkbenchTabsCtrl {
  constructor(
    $window,
    $uibModal,
    $filter,
    loanScenarioService,
    taskService,
    tasksSharedData,
    contactService,
    tasksService
  ) {
    'ngInject';

    this.$window = $window;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.loanScenarioService = loanScenarioService;
    this.WORK_BENCH_STATES = WORK_BENCH_STATES;
    this.taskService = taskService;
    this.tasksSharedData = tasksSharedData;
    this.contactService = contactService;
    this.tasksService = tasksService;
  }

  // RECORDS TAB
  setView(workbenchView, objToEdit) {
    this.workbenchView = workbenchView;

    this.emailToEdit = null;
    this.mailToEdit = null;
    this.noteToEdit = null;

    switch (this.workbenchView) {
      case WORK_BENCH_STATES.IS_EMAIL:
        this.emailToEdit = objToEdit ? Object.assign({}, objToEdit) : null;
        this.onEmailEdit({ $event: { emailToEdit: this.emailToEdit } });
        break;
      case WORK_BENCH_STATES.IS_MAIL:
        this.mailToEdit = objToEdit ? Object.assign({}, objToEdit) : null;
        this.onMailEdit({ $event: { mailToEdit: this.mailToEdit } });
        break;
      case WORK_BENCH_STATES.IS_NOTE:
        this.noteToEdit = objToEdit ? Object.assign({}, objToEdit) : null;
        this.onNoteEdit({ $event: { noteToEdit: this.noteToEdit } });
        break;
      default:
        break;
    }

    // below code are responsible for changing modal's width
    const el = this.$window.document.querySelectorAll('.workbench-modal');
    angular
      .element(el)
      .addClass(this.WORK_BENCH_STATES.ACTIONS[this.workbenchView - 1]);

    if (!this.isMobileViewScreen) {
      this.onViewChange({ $event: { workbenchView } });
    }
  }

  editLog(data) {
    this.setView(data.LogTypeID, data);
  }

  deleteLog(data) {
    swal(
      {
        title: 'Are you sure you want to delete this log?',
        text: 'You will not be able to retrieve this later',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: true,
      },
      confirm => {
        if (confirm) {
          const deleteData = {
            loanId: this.loanId,
            workbenchLogID: data.WorkbenchLogID,
          };
          this.loanScenarioService
            .workbenchRequirementLogDelete(deleteData)
            .then(() => {
              this.onRefreshLog();
            });
        }
      }
    );
  }

  taskCompletionSet(task) {
    this.taskService
      .taskSingleCompletion(task.ActivityID, task.FamilyID, task.IsCompleted)
      .then(() => {
        this.getTaskList(this.showCompleted);
      });
  }

  toggleOneComplete(task) {
    if (!task.IsCompleted) {
      this.taskCompletionSet(task);
      return;
    }

    this.tasksSharedData.completionConfirmation(
      () => {
        this.taskCompletionSet(task);
      },
      () => {
        task.IsCompleted = false;
      }
    );
  }

  getTaskList(isShowCompleted = false) {
    this.isLoadingTask = true;
    const adviserFilter = this.adviserInfo.FamilyId;
    const dateFilter = 'all';
    const createdByFilter = '';
    const familyId = 0;
    // const { pageNumber, pageSize, sortColumn, sortType, taskSearch } = '';

    const getParams = {
      adviserFilter,
      dateFilter,
      familyId,
      createdByFilter,
      isShowCompleted,
      loanId: this.loanId,
    };

    this.tasksService.getTaskList(getParams).then(res => {
      if (!res || !res.data) return;

      this.taskList = res.data.TaskList ? res.data.TaskList : [];
      this.isLoadingTask = false;
      if (!isShowCompleted) {
        if (this.taskGroup.length > 4) {
          this.taskGroup[0].tasks = this.taskList.filter(o => o.IsOverDue);
          this.taskGroup[1].tasks = this.taskList.filter(o => o.IsDueToday);
          this.taskGroup[2].tasks = this.taskList.filter(o => o.IsDueTomorrow);
          this.taskGroup[3].tasks = this.taskList.filter(o => o.IsDueThisWeek);
          this.taskGroup[4].tasks = this.taskList.filter(o => o.IsDueLater);
        }
      }
    });
  }

  toggleTaskDetails(task) {
    this.showDetails = {};
    task.showDetails = !task.showDetails;
  }

  getBrokerInfo() {
    this.contactService.getUserInfo().then(response => {
      if (response.data && response.data.FamilyId) {
        this.adviserInfo = response.data;
        this.getTaskList();
      }
    });
  }

  openTaskTemplatedModal(taskId = 0) {
    const propsCopy = { ...this.props, taskId, familyList: this.familyList };
    const modalInstance = this.$uibModal.open({
      template: `<task-templated modal-instance="vm.modalInstance" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId">`,
      size: 'lg',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      windowClass: 'view-task-window',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.taskResult(modalInstance);
  }

  openTaskFormModal(taskId = 0) {
    const propsCopy = { ...this.props, taskId, familyList: this.familyList };
    const modalInstance = this.$uibModal.open({
      template: `<task-form-modal modal-instance="vm.modalInstance" family-id="vm.props.familyId" family-list="vm.props.familyList" loan-id="vm.props.loanId" task-id="vm.props.taskId"></task-form-modal>`,
      windowClass: 'edit-task-window',
      size: 'md',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props: () => propsCopy,
      },
    });
    this.taskResult(modalInstance);
  }

  taskResult(modalInstance) {
    modalInstance.result.then(response => {
      if (response === 'saved') {
        this.getTaskList();
      }
    });
  }

  /* init models for all tabs */
  $onInit() {
    this.isMobileViewScreen = isMobileView(this.$window.innerWidth);
    this.showSeeMore = true;
    this.recordLimit = 1;
    // RECORDS
    this.logList = [];
    this.emailToEdit = {};
    this.mailToEdit = {};
    this.noteToEdit = {};
    this.taskList = [];
    this.showCompleted = false;
    this.adviserInfo = {};
    this.isLoadingTask = false;
    this.taskGroup = [
      {
        name: 'Overdue',
        tableClass: 'table-overdue',
      },
      {
        name: 'Today',
        tableClass: 'table-today',
      },
      {
        name: 'Tomorrow',
        tableClass: 'table-tomorrow',
      },
      {
        name: 'This Week',
        tableClass: 'table-this-week',
      },
      {
        name: 'Later',
        tableClass: 'table-later',
      },
    ];

    this.TASK_GROUP_INDEX = {
      OVERDUE: 0,
      TODAY: 1,
      TOMORROW: 2,
      THIS_WEEK: 3,
      LATER: 4,
    };

    this.props = {
      familyId: this.familyId,
      loanId: this.loanId,
    };

    this.getBrokerInfo();
  }
  seeMoreClicked() {
    this.recordLimit = this.logList.length;
  }
}

export default WorkbenchTabsCtrl;
