import angular from 'angular';

angular
  .module('app')
  .controller('GoogleAuthCodeCtrl', function GoogleAuthCodeCtrl(
    $scope,
    $window
  ) {
    function getUrlVars() {
      const vars = [];
      let hash;
      const hashes = $window.location.href
        .slice($window.location.href.indexOf('?') + 1)
        .split('&');
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    }

    function init() {
      $window.opener.calendarauth(getUrlVars().code, 'getcalendar');
      $window.close();
    }

    init();
  });
