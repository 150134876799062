import { ALLOWED_ACTION } from 'Common/constants/accessPermission';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class ReferralAgreementService {
  constructor(
    $q,
    $uibModal,
    referralsService,
    currentUserService,
    corporateModelService
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.referralsService = referralsService;
    this.currentUserService = currentUserService;
    this.corporateModelService = corporateModelService;
  }

  launchNewReferralModal(referralType, currentState, scope) {
    const size = 'lg';
    this.$uibModal.open({
      templateUrl:
        '/assets/views/referrals/partials/referral_agreement_modal.html',
      scope,
      controller: 'AddReferralAgreementModalCtrl',
      size,
      windowClass: 'referral_type',
      resolve: {
        currentState() {
          return currentState;
        },
        referralType() {
          return referralType;
        },
      },
    });
  }
  getOrganizationTypes(scope) {
    scope.referralModel.organisationTypeList = [];
    this.referralsService.getOrganizationTypes().then(response => {
      const data = response.data;
      if (data && data.length) {
        data.splice(3, 1);
        scope.referralModel.organisationTypeList = data;
        scope.referralModel.selectedOrgType = data[0].OrganizationTypeId;
      }
    });
  }
  getCreateAgreementAccess() {
    return this.corporateModelService
      .accessControlMatrixes('ReferralAgreementDetail')
      .then(data => {
        if (this.currentUserService.accessType !== ACCESS_TYPE.CORPORATE)
          return true;
        if (!data || !data.length) return false;
        const fullAccessList = data.filter(
          x => x.allowedActionId === ALLOWED_ACTION.READ_AND_WRITE
        );
        return fullAccessList && fullAccessList.length;
      });
  }
}

export default ReferralAgreementService;
