import angular from 'angular';
import _ from 'lodash';
import { assetFinanceIgnore } from 'Common/utilities/security';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import {
  CLIENT_REFERRAL,
  ADDITIONAL_REFERRAL,
  REFERRAL_ITEM,
} from 'Common/constants/enquirySource';
import { displayField } from 'Common/utilities/displayField';
import { typelessEqual } from 'Common/utilities/math';
import { parseIntValue } from 'Common/utilities/parse';
import { LENDER_ID } from 'Common/constants/lenderConstants';

angular
  .module('app')
  .controller(
    'LoanInformationAddExistingCtrl',
    function LoanInformationAddExistingCtrl(
      $q,
      $scope,
      contactService,
      imageDataURI,
      SweetAlert,
      $timeout,
      $uibModalInstance,
      $uibModal,
      toaster,
      utilitiesService,
      colorService,
      loanScenarioService,
      insuranceAppService,
      optionsService,
      $state,
      $rootScope,
      selectOptionsService,
      dashboardService,
      financialCalculatorService,
      modalRenderService,
      userService,
      generalService,
      commonFnService,
      enquirySourceService,
      modelDataService,
      loanTermService,
      loanService,
      corporateModelService
    ) {
      $scope.assetFinanceIgnore = assetFinanceIgnore;
      $scope.lenderOtherID = LENDER_ID.OTHERS;
      let borrowers;
      $scope.loanModalTab = 'loan-details-add-existing';
      $scope.allocatedAdviserId = 0;

      $scope.Settings = {
        showEntities: false,
        toEditSecurity: false,
        toEditImportantDates: false,
      };

      $scope.client = {
        selected: {},
      };

      $scope.$watch(
        'entityList',
        loanInformationExisting => {
          $scope.Settings.showEntities = false;
          if (loanInformationExisting && loanInformationExisting.length > 0) {
            $scope.Settings.showEntities = true;
          }
        },
        true
      );

      $scope.datePopUp = {
        settlementDate: false,
        submittedDate: false,
        financeDate: false,
        approvalDate: false,
        approvalExpiry: false,
        preApprovalExpiry: false,
        signingDate: false,
      };
      $scope.referralDetails = {
        referrer: {},
      };

      // Models
      angular.extend($scope, {
        LoanDetailsSet: {
          IsEstimated: true,
          Loan: {
            LoanID: 0,
            LoanStatus: {
              LoanStatusID: LOAN_STATUS.EXISTING.SETTLED.toString(),
            },
            Lender: {
              LenderId: 0,
              OtherLenderName: '',
            },
            Borrower: [],
            FamilyList: [
              {
                ReferrerID: null,
                ReferrerOrgID: null,
                ReferralCategoryId: null,
                ReferralItemId: null,
              },
            ],
          },
          LoanStructure: [],
          SecurityInfo: {
            LoanSecurityId: 0,
            SecurityInfoId: null,
            IsPrimary: true,
            SecurityTypeId: 0,
            Ownership: [
              {
                BorrowerID: -1,
                FirstName: 'All Owner',
                ticked: false,
                spc_ZvKFr: 0,
                idx_ZvKFr: 0,
              },
            ],
            TransactionId: 0,
            IsRefinance: true,
            PropertyDetails: {
              LoanSecurityId: 0,
              StatusId: 0,
              PropertyPrimaryPurposeId: 0,
              HoldingId: 0,
              ZoningId: 0,
              PropertyTypeId: 0,
              CurrentValue: 0,
              LandValue: 0,
              EstimatedPrice: 0,
              EstimatedBasesId: 0,
              ContratctPrice: 0,
              ContractDate: new Date(),
              LicensedRealEstateAgentContract: true,
              IsPreApproved: true,
              AddressId: 0,
              TitleTypeId: 0,
              TitleId: 0,
              PropertyState: '',
              IdentifierList: [
                {
                  Security_TitleIdentityId: 0,
                  IdentityTypeId: 0,
                  Value: '',
                },
              ],
              ContactForAccess: {
                ContactForAccess: 0,
                CompanyName: '',
                ContactTitle: '',
                ContactFirstName: '',
                ContactLastName: '',
                PhoneAreaCode: '',
                PhoneNumber: '',
                FaxAreaCode: '',
                FaxNumber: '',
                ContactTypeId: 0,
                ContactType: '',
              },
            },
            FundingCalParametersId: 0,
            MortgageList: [
              {
                LoanSecurityInfoId: 0,
                SecurityMortgageId: 0,
                MortgagePriorityId: 0,
                Ownership: [
                  {
                    Percentage: 0,
                    BorrowerID: 0,
                    FirstName: '',
                    LastName: '',
                    IsInclude: true,
                    IsEntity: true,
                  },
                ],
                CreditorId: 0,
                Limit: 0,
                AccountName: '',
                CurrentBalance: 0,
                AccontNumber: '',
                BSB: '',
                InterestTaxDeductible: true,
              },
            ],
          },
        },
      });

      $scope.setLoanDetails = {
        LoanID: 0,
        LoanStatus: {},
        Lender: {},
        LoanAmount: '',
        SettlementDate: '',
        FinanceDate: '',
        SubmittedDate: '',
        ApprovalDate: '',
        ApprovalExpiry: '',
        PreApprovalExpiry: '',
        ReferenceNumber: '',
        Borrower: [],
      };

      $scope.entityList = [];
      $scope.borrowerList = [];
      $scope.guarantorList = [];

      // Borrowers
      //-------------------------------------------------------------------------
      $scope.borrowerDefault = function() {
        contactService.familyInfoGet($scope.params.familyId).then(response => {
          const data = [];

          angular.forEach(response.data, valueParam => {
            let borrowerDefaultValue = valueParam;
            if (borrowerDefaultValue.Role === 'Adult') {
              borrowerDefaultValue = commonFnService.processBorrowerNames(
                borrowerDefaultValue
              );
              borrowerDefaultValue.BorrowerID = parseInt(
                borrowerDefaultValue.PersonId,
                10
              );
              borrowerDefaultValue.IsInclude = true;
              borrowerDefaultValue.IsEntity =
                borrowerDefaultValue.isEntity === true;
              data.push(borrowerDefaultValue);
            }
          });

          $scope.borrowerList = data;
          if (!data.length) return;
          $scope.allocatedAdviserId = data[0].AllocatedAdviserID;
        });
      };
      $scope.getBorrowersList = function() {
        const tempIsIncludeGuarantor = true;

        contactService
          .borrowersDetailsGet(
            $scope.params.familyId,
            $scope.params.loanId,
            tempIsIncludeGuarantor
          )
          .then(respond => {
            const entityList = [];
            // let borrowerList = [];
            const guarantorList = [];
            const borrowerIDList = [];
            $scope.listBorrowerValues = [];
            angular.forEach(respond.data, value => {
              if (borrowerIDList.indexOf(value.BorrowerID) === -1) {
                borrowerIDList.push(value.BorrowerID);
                $scope.listBorrowerValues.push(value);
              }
            });

            angular.forEach($scope.listBorrowerValues, valueParam => {
              let value = valueParam;
              value = commonFnService.processBorrowerNames(value);

              if (value.IsEntity) {
                entityList.push(value);
              } else if (value.IncludeGuarantor) {
                guarantorList.push(value);
              }
            });
            $scope.entityList = entityList;
            $scope.guarantorList = guarantorList;
          });
      };

      /* if($scope.params.loanInformationAction !== 'create'){
    $scope.getBorrowersList();
  }else */
      $scope.getBorrowersList();
      $scope.borrowerDefault();

      // Involved Parties - Add Applicant
      //-------------------------------------------
      $scope.searchApplicant = false;
      $scope.addApplicant = function() {
        $scope.searchApplicant = !$scope.searchApplicant;
        $scope.searchGuarantor = false;
        $scope.searchEntity = false;
      };

      $scope.searchGuarantor = false;
      $scope.addGuarantor = function() {
        $scope.searchGuarantor = !$scope.searchGuarantor;
        $scope.searchApplicant = false;
        $scope.searchEntity = false;
      };

      $scope.searchEntity = false;
      $scope.addEntity = function() {
        $scope.searchEntity = !$scope.searchEntity;
        $scope.searchApplicant = false;
        $scope.searchGuarantor = false;
      };

      $scope.searchClient = function(searchString, partyType) {
        $timeout.cancel($scope.searchTimeOut);
        if (searchString && searchString.length > 2) {
          $scope.getClientList = null;
          $scope.searchTimeOut = $timeout(() => {
            loanScenarioService
              .scenarioSearchClientGet(searchString, partyType)
              .then(response => {
                const { data } = response;
                const allBorrowerID = $scope.borrowerList
                  .concat($scope.guarantorList)
                  .map(list => parseInt(list.BorrowerID, 10));
                $scope.getClientList = data
                  .map(item => {
                    item.initials = utilitiesService.filterInitial(
                      item.FirstName,
                      item.LastName
                    );
                    if (
                      !allBorrowerID.includes(parseInt(item.ClientEntityId, 10))
                    )
                      return item;
                    return false;
                  })
                  .filter(item => item);
              });
          }, 500);
        }
      };

      $scope.cancelExistingContact = function() {
        $scope.searchApplicant = false;
      };

      $scope.cancelExistingContactGuarantor = function() {
        $scope.searchGuarantor = false;
      };

      $scope.selectClient = function(clientInfo, partyType) {
        $scope.selectedClient = clientInfo;
        const newBorrrower = {};
        newBorrrower.BorrowerID = parseInt(clientInfo.ClientEntityId, 10);
        newBorrrower.FirstName = clientInfo.FirstName;
        newBorrrower.LastName = clientInfo.LastName;
        newBorrrower.IsInclude = true;
        newBorrrower.IsEntity = partyType !== 1;
        if (clientInfo.LastName === '') {
          newBorrrower.initials = utilitiesService.filterInitialOneString(
            clientInfo.FirstName
          );
          newBorrrower.DisplayName = clientInfo.FirstName;
        } else {
          newBorrrower.initials = utilitiesService.filterInitial(
            clientInfo.FirstName,
            clientInfo.LastName
          );
          newBorrrower.background = colorService.getRandomColor();
          newBorrrower.DisplayName = `${clientInfo.FirstName} ${
            clientInfo.LastName
          }`;
        }

        if (parseInt(partyType, 10) === 1) {
          $scope.borrowerList.push(newBorrrower);
        } else if (parseInt(partyType, 10) === 2) {
          $scope.entityList.push(newBorrrower);
        }
        $scope.borrowingEntity.push(newBorrrower);
        $scope.ownershipList.push(newBorrrower);
        $scope.refreshBorrowers();
      };

      $scope.refreshBorrowers = () => {
        $scope.borrowingEntity = _.filter(
          $scope.borrowingEntity,
          o => parseInt(o.BorrowerID, 10) !== 0
        );
        $scope.ownershipList = _.filter(
          $scope.ownershipList,
          o => parseInt(o.BorrowerID, 10) !== -1
        );

        $scope.borrowingEntity = _.map($scope.borrowingEntity, o => {
          if (o.BorrowerID !== 0) {
            o.ticked = false;
          }
          return o;
        });

        $scope.ownershipList = _.map($scope.ownershipList, o => {
          if (o.BorrowerID !== 0) {
            o.ticked = false;
          }
          return o;
        });

        if ($scope.borrowingEntity && $scope.borrowingEntity.length > 1) {
          const temp = {
            Percentage: 0,
            BorrowerID: 0,
            FirstName: 'All',
            LastName: 'Joint',
            PreferedName: 'Joint',
            ticked: true,
            disabled: true,
          };
          $scope.borrowingEntity = [temp, ...$scope.borrowingEntity];
        }

        if ($scope.ownershipList && $scope.ownershipList.length > 1) {
          const temp = {
            Percentage: 0,
            BorrowerID: -1,
            FirstName: 'All',
            LastName: 'Owner',
            PreferedName: 'Owner',
            ticked: true,
            disabled: true,
          };
          $scope.ownershipList = [temp, ...$scope.ownershipList];
        }

        if ($scope.borrowingEntity && $scope.borrowingEntity.length) {
          $scope.borrowingEntity[0].ticked = true;
        }
      };

      $scope.selectGuarantor = function(clientInfo) {
        clientInfo.IsGuarantor = true;
        clientInfo.IsAddKid = false;
        clientInfo.IsApplicant = false;
        clientInfo.IsSolicitor = false;
        clientInfo.IsAccountant = false;

        const newBorrrower = {};
        newBorrrower.BorrowerID = parseInt(clientInfo.ClientEntityId, 10);
        newBorrrower.FirstName = clientInfo.FirstName;
        newBorrrower.LastName = clientInfo.LastName;
        newBorrrower.IsInclude = true;

        if (clientInfo.LastName === '') {
          newBorrrower.initials = utilitiesService.filterInitialOneString(
            clientInfo.FirstName
          );
          newBorrrower.DisplayName = clientInfo.FirstName;
        } else {
          newBorrrower.initials = utilitiesService.filterInitial(
            clientInfo.FirstName,
            clientInfo.LastName
          );
          newBorrrower.background = colorService.getRandomColor();
          newBorrrower.DisplayName = `${clientInfo.FirstName} ${
            clientInfo.LastName
          }`;
        }

        newBorrrower.IncludeGuarantor = 1;
        $scope.guarantorList.push(newBorrrower);
        $scope.borrowingEntity.push(newBorrrower);
        $scope.ownershipList.push(newBorrrower);
        $scope.refreshBorrowers();
      };

      $scope.cancelSearch = function() {
        $scope.searchApplicant = !$scope.searchApplicant;
      };

      const borrowersDetailsGet = () => {
        $scope.borrowingEntity = {};
        contactService
          .borrowersDetailsGet(
            $scope.params.familyId,
            $scope.params.loanId,
            true
          )
          .then(response => {
            const temp = {
              Percentage: 0,
              BorrowerID: 0,
              FirstName: 'All',
              LastName: 'Joint',
              PreferedName: 'Joint',
              ticked: true,
              disabled: true,
            };
            const tmp = [];
            if (
              $scope.borrowingEntityListSelected.length < 1 ||
              $scope.borrowingEntityListSelected.length === response.data.length
            ) {
              response.data &&
                Object.keys(response.data).forEach(x => {
                  const value = response.data[x];
                  value.PreferedName = `${value.FirstName} ${value.LastName}`;
                  tmp.push(value);
                });
              if (tmp.length === 1) {
                tmp[0].ticked = true;
              } else {
                tmp.unshift(temp);
              }
              $scope.borrowingEntity = tmp;
            } else {
              temp.ticked = false;
              tmp.push(
                commonFnService.processTickedBorrower(response, borrowers, temp)
              );
              $scope.borrowingEntity = tmp;
            }
          });

        // Filter Select all
        $scope.filterRefreshData = [];

        $scope.localLang = {
          selectAll: '<span>Select All</span>',
          selectNone: '<span>Deselect All </span>',
          reset: "<i class='fa fa-refresh'></i>",
          search: 'Search',
          nothingSelected: '', // default-label is deprecated and replaced with this.
          // nothingSelected: "<div class='buttonLabel'>All Clients</div>"         //default-label is deprecated and replaced with this.
        };

        $scope.onSelectAll = function() {
          if (
            $scope.filterRefreshData[0] &&
            $scope.filterRefreshData[0].ownership
          ) {
            $scope.filterRefreshData[0].ownership = [];
          }
          for (let z = 0; z < $scope.borrowingEntity.length; z++) {
            if ($scope.borrowingEntity[z].BorrowerID > -1) {
              $scope.borrowingEntity[z].ticked = false;
            } else {
              $scope.borrowingEntity[z].ticked = true;
            }
          }
        };

        $scope.borrowingEntityListSelected = [];
        $scope.onItemSelect = function(data) {
          let isJointFamily = true;
          if (parseInt(data.BorrowerID, 10) !== 0) {
            for (let z = 0; z < $scope.borrowingEntity.length; z++) {
              if (
                (typeof $scope.borrowingEntity[z].ticked === 'undefined' ||
                  !$scope.borrowingEntity[z].ticked) &&
                $scope.borrowingEntity[z].BorrowerID >= 1
              ) {
                isJointFamily = false;
              }
            }
          }

          // will be true if user clicks on joint
          if (parseInt(data.BorrowerID, 10) === 0) {
            isJointFamily = true;
          }
          if (!isJointFamily) {
            $scope.borrowingEntity = _.map($scope.borrowingEntity, obj => {
              if (parseInt(obj.BorrowerID, 10) === 0) {
                obj.ticked = false;
              }
              return obj;
            });
          } else {
            const findJoint = _.find($scope.borrowingEntity, obj => {
              return obj.BorrowerID === 0;
            });
            // need to check if single owner
            if (!_.isEmpty(findJoint)) {
              $scope.borrowingEntity = _.map($scope.borrowingEntity, obj => {
                obj.ticked = obj.BorrowerID === 0;
                return obj;
              });
            }
          }
        };
      };

      borrowersDetailsGet();

      $scope.removeBorrowerApplicant = (borrower, valueIndex, partyType) => {
        const applicants = {
          guarantors: $scope.guarantorList,
          entities: $scope.entityList,
          borrowers: $scope.borrowerList,
        };
        loanService
          .removeBorrowerApplicant(applicants, valueIndex, partyType)
          .then(() => {
            $scope.borrowingEntity = _.filter(
              $scope.borrowingEntity,
              o =>
                parseInt(o.BorrowerID, 10) !== parseInt(borrower.BorrowerID, 10)
            );
            $scope.ownershipList = _.filter(
              $scope.ownershipList,
              o =>
                parseInt(o.BorrowerID, 10) !== parseInt(borrower.BorrowerID, 10)
            );
            $scope.refreshBorrowers();
          })
          .catch(() => {
            toaster.pop('warning', 'Error', 'You need at least 1 applicant');
          });
      };

      // Lenders
      //-------------------------------------------------------------------------
      $scope.lenderList = [];
      // Retrieve Lender List
      const lenderPlace = 2;
      contactService.lenderListGet(true, lenderPlace).then(respond => {
        angular.forEach(respond.data, value => {
          $scope.lenderList.push({
            LenderId: parseInt(value.ProviderId, 10),
            LenderName: value.ProviderName,
            Category: value.Category,
            OtherLenderName: value.OtherLenderName,
          });
        });
      });

      // Open Date Popup
      $scope.openDate = function(dateField) {
        $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
      };
      $scope.format = 'dd MMM yyyy';
      $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      $scope.cancel = function(obj) {
        $uibModalInstance.close(obj);
      };

      $scope.LoanSplitsProductList = [
        { ProductID: '', ProductName: 'Please select a lender first' },
      ];
      $scope.getProductlist = function(value) {
        contactService.loanProductListGet(value.LenderId).then(response => {
          $scope.LoanSplitsProductList = response.data;
          /* put the "OTHER" product in the end of the list */
          const otherProduct = _.remove($scope.LoanSplitsProductList, list => {
            return list.ProductName === 'Other';
          });
          $scope.LoanSplitsProductList.push(otherProduct[0]);
          /* end of putting "OTHER" product in the end of the list */
          if ($scope.LoanSplitsProductList.length === 1) {
            _.forEach($scope.LoanDetailsSet.LoanStructure, () => {
              value.ProductDetails = $scope.LoanSplitsProductList[0];
            });
          } else {
            _.forEach($scope.LoanDetailsSet.LoanStructure, () => {
              value.ProductDetails = {};
            });
          }
        });
      };

      // Involved Parties Section
      // -------------------------------------------------------------------------

      // Loan Split
      // ------------------------------------------------------------------------

      // model
      angular.extend($scope, {
        //   LoanStructureListGet: []
        selectRateType: [],
        selectLoanType: [
          'Principal and Interest',
          'Interest Only',
          'Revolving Credit',
        ],
      });
      if ($rootScope.crmCountry === 'Australia')
        $scope.selectRateType = ['Variable', 'Fixed'];
      else $scope.selectRateType = ['Floating', 'Fixed'];
      // Terms select options
      $scope.selectTerms = [0, 0.25, 0.5, 0.75, 1.5];
      for (let i = 1; i < 31; i++) {
        $scope.selectTerms.push(i);
      }

      const loanTermMonth = loanTermService.getLoanTermMonthList();
      optionsService.getLoanTerms().then(loanTermResponse => {
        $scope.loanTermsList = [...loanTermMonth, ...loanTermResponse];
      });

      // Repayment Frequency select option
      if ($scope.crmCountry === 'Indonesia') {
        $scope.selectRepaymentFreq = [{ Name: 'Monthly', id: 'Monthly' }];
      } else {
        $scope.selectRepaymentFreq = [
          { Name: 'Yearly', id: 'Yearly' },
          // { Name: 'Half-Yearly', id: "Half-Yearly" },
          // { Name: 'Quarterly', id: "Quarterly" },
          { Name: 'Monthly', id: 'Monthly' },
          { Name: 'Fortnightly', id: 'Fortnightly' },
          { Name: 'Weekly', id: 'Weekly' },
        ];
      }

      // method

      /*
    getRepaymentParams for dataNumber(raw inputs):
          1 = Rate Type
          2 = Loan Amount
          3 = Interest Rate
          4 = Loan Term
  */
      $scope.calculateRepayment = {};
      $scope.getRepaymentModel = function() {
        return {
          required: {
            rateType: '',
            loanAmount: 0,
            interestRate: 0,
            loanTerms: 0,
            isComplete: false,
          },
          optional: {
            repaymentFrequency: 12,
            isInterestOnlyLegend: 1,
            isInterestOnly: false,
          },
          dontCalculateRepayment: false,
        };
      };

      angular.extend($scope.calculateRepayment, {
        repaymentVariables: [],
        getRepaymentParams(dataInput, dataLegend, splitNumber) {
          const requiredVariables =
            $scope.calculateRepayment.repaymentVariables[splitNumber].required;

          if (!_.isNull(dataLegend) && !_.isUndefined(dataLegend)) {
            switch (dataLegend) {
              case 1:
                requiredVariables.rateType = dataInput;
                break;
              case 2:
                requiredVariables.loanAmount = dataInput;
                break;
              case 3:
                requiredVariables.interestRate = dataInput;
                break;
              case 4:
                requiredVariables.loanTerms = dataInput;
                break;
              default:
                break;
            }
          }
        },
        isRequiredComplete(splitNumber) {
          const requiredVariables =
            $scope.calculateRepayment.repaymentVariables[splitNumber].required;
          const isLoanAmountEndsWithDot = _.endsWith(
            requiredVariables.loanAmount,
            '.'
          );
          const isValidInterestRate =
            (requiredVariables.interestRate &&
              !!parseFloat(requiredVariables.interestRate)) ||
            parseFloat(requiredVariables.interestRate) === 0;
          requiredVariables.isComplete =
            !isLoanAmountEndsWithDot &&
            requiredVariables.rateType &&
            requiredVariables.loanAmount &&
            isValidInterestRate &&
            requiredVariables.loanTerms;

          return requiredVariables.isComplete;
        },
        convertInputToParams(splitNumber) {
          const variables =
            $scope.calculateRepayment.repaymentVariables[splitNumber];
          const optionalVariables =
            $scope.calculateRepayment.repaymentVariables[splitNumber].optional;

          if (
            !_.isNull(
              $scope.LoanDetailsSet.LoanStructure[splitNumber]
                .RepaymentFrequency
            ) &&
            !_.isEmpty(
              $scope.LoanDetailsSet.LoanStructure[splitNumber]
                .RepaymentFrequency
            ) &&
            !_.isUndefined(
              $scope.LoanDetailsSet.LoanStructure[splitNumber]
                .RepaymentFrequency
            )
          ) {
            switch (
              $scope.LoanDetailsSet.LoanStructure[splitNumber]
                .RepaymentFrequency
            ) {
              case 'Yearly':
                optionalVariables.repaymentFrequency = 1;
                break;
              case 'Monthly':
                optionalVariables.repaymentFrequency = 12;
                break;
              case 'Fortnightly':
                optionalVariables.repaymentFrequency = 26;
                break;
              case 'Weekly':
                optionalVariables.repaymentFrequency = 52;
                break;
              default:
                break;
            }
          }
          if (
            !_.isNull(
              $scope.LoanDetailsSet.LoanStructure[splitNumber].LoanStructureType
            ) &&
            !_.isEmpty(
              $scope.LoanDetailsSet.LoanStructure[splitNumber].LoanStructureType
            ) &&
            !_.isUndefined(
              $scope.LoanDetailsSet.LoanStructure[splitNumber].LoanStructureType
            )
          ) {
            switch (
              $scope.LoanDetailsSet.LoanStructure[splitNumber].LoanStructureType
            ) {
              case 'Principal and Interest':
                variables.dontCalculateRepayment = false;
                optionalVariables.isInterestOnlyLegend = 1;
                optionalVariables.isInterestOnly = false;
                break;
              case 'Interest Only':
                variables.dontCalculateRepayment = false;
                optionalVariables.isInterestOnlyLegend = 2;
                optionalVariables.isInterestOnly = true;
                break;
              case 'Revolving Credit':
                variables.dontCalculateRepayment = true;
                optionalVariables.isInterestOnlyLegend = 3;
                break;
              default:
                break;
            }
          }
        },
        repaymentCalculator(splitNumber) {
          const variables =
            $scope.calculateRepayment.repaymentVariables[splitNumber];
          const requiredVariables =
            $scope.calculateRepayment.repaymentVariables[splitNumber].required;
          const optionalVariables =
            $scope.calculateRepayment.repaymentVariables[splitNumber].optional;

          if (
            $scope.calculateRepayment.isRequiredComplete(splitNumber) &&
            !variables.dontCalculateRepayment
          ) {
            financialCalculatorService
              .getRepaymentAmountFloatingType(
                requiredVariables.loanAmount,
                requiredVariables.interestRate,
                requiredVariables.loanTerms,
                optionalVariables.repaymentFrequency,
                optionalVariables.isInterestOnly
              )
              .then(response => {
                if (!response.data) return;
                const repaymentValue = response.data.toFixed(2);
                $scope.LoanDetailsSet.LoanStructure[
                  splitNumber
                ].RepaymentAmount = repaymentValue;
              });
          }
        },
        requiredParamsSteps(dataInput, dataLegend, splitNumberParam) {
          let splitNumber = splitNumberParam;
          splitNumber -= 1;

          $scope.calculateRepayment.getRepaymentParams(
            dataInput,
            dataLegend,
            splitNumber
          );
          $scope.calculateRepayment.repaymentCalculator(splitNumber);
        },
        optionalParamsSteps(splitNumber) {
          $scope.calculateRepayment.convertInputToParams(splitNumber);
          $scope.calculateRepayment.repaymentCalculator(splitNumber);
        },
      });
      angular.extend($scope, {
        getSplitModel() {
          return {
            LoanId: '',
            LoanStructureId: '',
            AccountNumber: '',
            ProductID: '',
            Product: '',
            InterestRateType: '',
            InterestRateTerm: 0,
            FixedRateBegin: new Date(),
            FixedRateExpiry: new Date(),
            InterestOnlyTerm: 0,
            RepaymentAmount: 0,
            RepaymentFrequency: '',
            IsDefault: true,
            LiabilityId: '',
            Lender: '',
            LenderId: 0,
            CardLimit: 0,
            CardPaidInFull: true,
            ToBeRefinanced: true,
            LoanStructureType: '',
            LoanTerm: 0,
            LoanInterestRate: 0,
            MonthlyExpense: 0,
            IsRental: true,
            OwnerFamily: '',
            Owners: [],
            Type: '',
            PropertyType: '',
            TypeOther: '',
            Description: '',
            Value: 0,
            Borrowers: [
              {
                BorrowerID: 0,
                FirstName: '',
                LastName: '',
                IsInclude: true,
                IsEntity: true,
              },
            ],
            OtherInformation: '',
            FinancialID: '',
          };
        },
        addAnotherSplit() {
          $scope.LoanDetailsSet.LoanStructure.push($scope.getSplitModel());
          $scope.calculateRepayment.repaymentVariables.push(
            $scope.getRepaymentModel()
          );
        },
        removeSplit(split) {
          _.remove($scope.LoanDetailsSet.LoanStructure, o => {
            return _.isEqual(o, split);
          });
        },
        loanSplitInit() {
          $scope.addAnotherSplit();
        },
      });

      $scope.loanSplitInit();

      const lastFilter = [];

      /* SECURITY */
      $scope.ownershipList = {};

      contactService
        .borrowersDetailsGet($scope.params.familyId, $scope.params.loanId, true)
        .then(response => {
          const temp = {
            BorrowerID: -1,
            FirstName: 'All Owner',
            ticked: false,
          };
          response.data.unshift(temp);
          const tmp = [];
          response.data &&
            Object.keys(response.data).forEach(x => {
              const value = response.data[x];
              if (lastFilter.indexOf('All') > -1) {
                value.ticked = parseInt(value.BorrowerID, 10) === -1;
              } else if (lastFilter.indexOf(response.data[x].FirstName) > -1) {
                value.ticked = true;
              }
              tmp.push(value);
            });

          $scope.ownershipList = tmp;
        });
      // Filter Select all
      $scope.filterRefreshDataOwners = [
        {
          ownership: [],
        },
      ];

      $scope.localLangOwners = {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected:
          "<div class='buttonLabel padding-left-10'>All Owner</div>", // default-label is deprecated and replaced with this.
      };

      $scope.onSelectAllOwners = function() {
        $scope.filterRefreshDataOwners[0].ownership = [];
        for (let z = 0; z < $scope.ownershipList.length; z++) {
          if ($scope.ownershipList[z].BorrowerID > -1) {
            $scope.ownershipList[z].ticked = false;
          } else {
            $scope.ownershipList[z].ticked = true;
          }
        }
      };

      function isOtherTickedOwners(data) {
        let bool = false;
        $scope.filterRefreshDataOwners[0].ownership = [];
        data.forEach(val => {
          if (val.BorrowerID > -1 && val.ticked) {
            bool = true;
            $scope.filterRefreshDataOwners[0].ownership.push(val.BorrowerID);
          }
        });

        return bool;
      }

      $scope.ownerListSelected = [];
      $scope.onItemSelectOwners = function(data) {
        if (parseInt(data.BorrowerID, 10) === -1) {
          for (let z = 0; z < $scope.ownershipList.length; z++) {
            if ($scope.ownershipList[z].BorrowerID > -1) {
              $scope.ownershipList[z].ticked = false;
            } else {
              $scope.ownershipList[z].ticked = true;
            }
          }
        } else {
          for (let z = 0; z < $scope.ownershipList.length; z++) {
            if (
              parseInt($scope.ownershipList[z].BorrowerID, 10) === -1 &&
              !isOtherTickedOwners($scope.ownershipList)
            ) {
              $scope.ownershipList[z].ticked = true;
              $scope.ownerListSelected.push($scope.ownershipList[z]);
            } else if (
              parseInt($scope.ownershipList[z].BorrowerID, 10) === -1
            ) {
              $scope.ownershipList[z].ticked = false;
            }
          }
        }

        // $scope.LoanDetailsSet.SecurityInfo.Ownership = $scope.ownerListSelected;
      };

      optionsService.getSecurityType().then(response => {
        _.forEach(response.data, value => {
          if (!_.isUndefined(value.Value) && value.Value !== '') {
            value.Value = parseInt(value.Value, 10);
          }
        });

        $scope.securityTypeOptions = response.data;
      });

      optionsService.getTransactionType().then(response => {
        angular.forEach(response.data, value => {
          value.Value = parseInt(value.Value, 10);
        });

        $scope.TransactionTypeList = response.data;
      });

      optionsService.getStatusType().then(response => {
        $scope.propertyDevelopmentStatusOptions = parseIntValue(response.data);
      });

      optionsService.getPrimaryPurposeType().then(response => {
        angular.forEach(response.data, value => {
          value.Value = parseInt(value.Value, 10);
        });

        $scope.PropertyPrimaryPurposeList = response.data;
      });

      optionsService.getHoldingType().then(response => {
        angular.forEach(response.data, value => {
          value.Value = parseInt(value.Value, 10);
        });

        $scope.HoldingList = response.data;
      });

      optionsService.getZoningType().then(response => {
        angular.forEach(response.data, value => {
          value.Value = parseInt(value.Value, 10);
        });

        $scope.ZoningList = response.data;
      });

      $scope.getPropertyType = function(id) {
        optionsService.getPropertyType(id).then(response => {
          angular.forEach(response.data, value => {
            value.Value = parseInt(value.Value, 10);
          });

          $scope.propertyTypeList = response.data;
        });
      };

      optionsService.AssetValueBasisGet().then(response => {
        angular.forEach(response.data, value => {
          value.Value = parseInt(value.Value, 10);
        });

        $scope.assetValueBasisList = response.data;
      });

      $scope.$watch(
        'LoanDetailsSet.SecurityInfo.PropertyDetails.ZoningId',
        nv => {
          if (nv) $scope.getPropertyType(nv);
        },
        true
      );

      // address
      $scope.security = {};
      $scope.security.getFormattedAddress = function() {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.security.Address.formatted_address)
            .then(
              loanInfoExistingResp => {
                $scope.security.addressSuggestions = loanInfoExistingResp.data;
                $scope.security.showSuggestionModal = true;
              },
              () => {
                toaster.pop('error', 'Warning', 'Something went wrong');
              }
            );
        }, 500);
      };

      $scope.security.closeAutoHomeAddress = function() {
        $scope.security.addressSuggestions = [];
      };

      $scope.security.pickAddressSuggestion = function(homeAddress) {
        $scope.security.Address = homeAddress;

        // Address to post
        $scope.security.ContactAddress = homeAddress;

        $scope.security.closeAutoHomeAddress();
        $scope.security.showSuggestionModal = false;
        $scope.security.saveAddress();
      };

      $scope.security.saveAddress = function() {
        const loanInfoExistingAddressModel = {
          formatted_address: $scope.security.Address
            ? $scope.security.Address.formatted_address
            : '',
          ...modelDataService.addressMakeModelData(),
          ...$scope.security,
        };
        dashboardService
          .addressDetailsSet(loanInfoExistingAddressModel)
          .then(response => {
            $scope.LoanDetailsSet.SecurityInfo.PropertyDetails.AddressId =
              response.data;
          });
      };

      // Enquiry Source
      //-------------------------------------------------------------------------
      $scope.contactInfoSetValue = {};
      $scope.getEnquirySourceList = () => {
        if (!$scope.LoanDetailsSet.EnquirySource) {
          contactService
            .contactFamilyInfoGet($scope.familyId)
            .then(response => {
              if (!response || !response.data) return;
              const { ReferralItemId } = response.data;
              const source = _.find(
                $scope.EnquirySourceList,
                obj => obj.ReferralItemId === ReferralItemId
              );
              if (!source) return;
              $scope.processEnquirySource(source.ReferralCategoryId);
              $scope.LoanDetailsSet.EnquirySource = source;
              const isClientValid =
                $scope.displayField(ReferralItemId, $scope.clientEnquiryList) &&
                response.data.ClientReferrerFamilyId &&
                response.data.ClientReferrerName;
              const isDetailsReferral = $scope.displayField(
                ReferralItemId,
                $scope.additionalEnquiryList
              );
              const {
                ReferralCategory,
                ReferralCategoryId,
                ReferralItem,
                ReferrerAgreementTypeId,
                ReferrerID,
                ReferrerOrgID,
                ReferrerName,
                ReferrerOrgName,
              } = response.data;
              const referrerId =
                ReferrerName && ReferrerName.trim() ? ReferrerID : 0;
              const referrerOrgId =
                ReferrerOrgName && ReferrerOrgName.trim() ? ReferrerOrgID : 0;
              const defaultReferral = {
                ReferralCategory,
                ReferralCategoryId,
                ReferralItem,
                ReferralItemId,
                ReferrerAgreementTypeId,
                ReferrerID: referrerId,
                ReferrerOrgID: referrerOrgId,
                ReferrerName,
                ReferrerOrgName,
              };
              $scope.LoanDetailsSet.Loan = {
                ...$scope.LoanDetailsSet.Loan,
                ...defaultReferral,
              };
              $scope.LoanDetailsSet.Loan.FamilyList[0] = {
                ...$scope.LoanDetailsSet.Loan.FamilyList[0],
                ...defaultReferral,
              };
              $scope.referralDetails.referrer =
                $scope.ReferralList &&
                $scope.ReferralList.find(referral => {
                  const isAgreementMatched =
                    referral.AgreementTypeId === ReferrerAgreementTypeId;
                  const isReferrerMatched =
                    referral.ReferralMemberId === referrerId;
                  const isReferrerOrgMatched =
                    referral.ReferralOrganizationId === referrerOrgId;
                  return (
                    isAgreementMatched &&
                    isReferrerMatched &&
                    isReferrerOrgMatched
                  );
                });
              if (isClientValid) {
                $scope.client.selected = {
                  originalObject: {
                    FamilyID: response.data.ClientReferrerFamilyId,
                    FamilyFullName: response.data.ClientReferrerName,
                  },
                };
              } else if (isDetailsReferral) {
                $scope.client.additionalDetails =
                  response.data.OtherContactReferrerName || '';
              }
            });
        }
      };
      const checkFamilyFirstLoan = () => {
        corporateModelService
          .isFirstFamilyLoan($scope.params.familyId)
          .then(response => {
            if (!response || !response.data) return;
            $scope.getEnquirySourceList();
          });
      };
      const getEnquirySourceList = () => {
        enquirySourceService.getEnquirySourceList().then(data => {
          if (!data || !data.length) return;
          $scope.EnquirySourceList = data;
          checkFamilyFirstLoan();
        });
      };

      $scope.ReferralList = [];

      const getReferrerList = () => {
        const familyId = $scope.params.familyId || 0;
        contactService.contactFamilyInfoGet(familyId).then(response => {
          if (response.data) {
            const brokerId = response.data.BrokerID || 0;
            if (brokerId > 0) {
              optionsService
                .getReferencedReferralList(brokerId)
                .then(respond => {
                  $scope.ReferralList = respond.data;
                });
            }
          }
          getEnquirySourceList();
        });
      };
      getReferrerList();

      $scope.setSelectedReferral = selectedData => {
        if (!selectedData) return;
        const referral = {
          ReferrerAgreementTypeId: selectedData.AgreementTypeId,
          ReferrerID: selectedData.ReferralMemberId,
          ReferrerOrgID: selectedData.ReferralOrganizationId,
          ReferrerName: selectedData.ReferralMemberName,
          ReferrerOrgName: selectedData.ReferralOrganizationName,
        };
        $scope.LoanDetailsSet.Loan = {
          ...$scope.LoanDetailsSet.Loan,
          ...referral,
        };
        $scope.LoanDetailsSet.Loan.FamilyList[0] = {
          ...$scope.LoanDetailsSet.Loan.FamilyList[0],
          ...referral,
        };
      };

      // Enquiry is changed
      $scope.enquiryChanged = function() {
        const EnquirySource = $scope.LoanDetailsSet.EnquirySource;

        $scope.LoanDetailsSet.Loan.ReferralCategoryId =
          EnquirySource.ReferralCategoryId;
        $scope.LoanDetailsSet.Loan.ReferralItemId =
          EnquirySource.ReferralItemId;
        $scope.LoanDetailsSet.Loan.FamilyList[0].ReferralCategoryId =
          EnquirySource.ReferralCategoryId;
        $scope.LoanDetailsSet.Loan.FamilyList[0].ReferralItemId =
          EnquirySource.ReferralItemId;
        if (parseInt(EnquirySource.ReferralCategoryId, 10) === -1) {
          $scope.isReferral = true;
        } else {
          $scope.isReferral = false;
        }
      };

      const parseEnquiryDetails = () => {
        const isValid =
          $scope.LoanDetailsSet &&
          $scope.LoanDetailsSet.Loan &&
          $scope.LoanDetailsSet.Loan.FamilyList &&
          $scope.LoanDetailsSet.Loan.FamilyList.length &&
          $scope.LoanDetailsSet.EnquirySource;
        if (!isValid) return;
        const referralItemId =
          $scope.LoanDetailsSet.EnquirySource.ReferralItemId;
        const isIncludeClient = $scope.displayField(
          referralItemId,
          $scope.clientEnquiryList
        );
        const isIncludeDetails = $scope.displayField(
          referralItemId,
          $scope.additionalEnquiryList
        );
        const clientId = $scope.client.selected.originalObject
          ? $scope.client.selected.originalObject.FamilyID
          : 0;
        $scope.LoanDetailsSet.Loan.ClientReferrerFamilyId = isIncludeClient
          ? clientId
          : 0;
        $scope.LoanDetailsSet.Loan.FamilyList[0].ClientReferrerFamilyId = isIncludeClient
          ? clientId
          : 0;
        $scope.LoanDetailsSet.Loan.OtherContactReferrerName = isIncludeDetails
          ? $scope.client.additionalDetails
          : '';
        $scope.LoanDetailsSet.Loan.FamilyList[0].OtherContactReferrerName = isIncludeDetails
          ? $scope.client.additionalDetails
          : '';
      };

      const checkReferralRequired = () => {
        if (
          !$scope.LoanDetailsSet.Loan ||
          !$scope.LoanDetailsSet.Loan.FamilyList ||
          !$scope.LoanDetailsSet.Loan.FamilyList.length
        )
          return false;
        const isReferral =
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferralItemId ===
          REFERRAL_ITEM.REFERRAL;
        const referrerId =
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerID || 0;
        const referrerName =
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerName &&
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerName.trim();
        const referrerOrgId =
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerOrgID || 0;
        const referrerOrgName =
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerOrgName &&
          $scope.LoanDetailsSet.Loan.FamilyList[0].ReferrerOrgName.trim();
        $scope.isReferrerRequired =
          isReferral &&
          !(referrerId && referrerName && referrerOrgId && referrerOrgName);
        return $scope.isReferrerRequired;
      };

      // Save Loan Details
      $scope.saveLoanDetails = function() {
        if (checkReferralRequired()) return;
        $scope.LoanDetailsSet.Loan.IsEstimated =
          $scope.LoanDetailsSet.IsEstimated;
        parseEnquiryDetails();

        _.forEach($scope.borrowerList, o => {
          o.IsInclude = true;
          $scope.LoanDetailsSet.Loan.Borrower.push(o);
        });

        _.forEach($scope.guarantorList, o => {
          o.IsInclude = true;
          $scope.LoanDetailsSet.Loan.Borrower.push(o);
        });

        _.forEach($scope.entityList, o => {
          o.IsInclude = true;
          $scope.LoanDetailsSet.Loan.Borrower.push(o);
        });

        if (_.size($scope.LoanDetailsSet.SecurityInfo.Ownership) < 1) {
          _.map($scope.ownershipList, o => {
            o.ticked = true;
            o.IsInclude = true;
            return o;
          });
          $scope.LoanDetailsSet.SecurityInfo.Ownership = $scope.ownershipList;
        }

        _.map($scope.LoanDetailsSet.LoanStructure, o => {
          if (o && o.ProductDetails && o.ProductDetails.ProductID) {
            o.ProductID = o.ProductDetails.ProductID;
          }
          return o;
        });

        $scope.LoanDetailsSet.LoanStructure.forEach(structure => {
          const { Borrowers } = structure;
          if (Borrowers && Borrowers.length && Borrowers[0].BorrowerID < 1) {
            structure.Borrowers = [];

            $scope.borrowingEntity.forEach(borrower => {
              if (borrower.BorrowerID > 0) {
                Borrowers.push(borrower);
              }
            });
          }

          Borrowers.map(obj => {
            obj.IsInclude = true;
            return obj;
          });
        });

        // update Loan Intrest rate to its decimal form on passing data, not the percentage.
        angular.forEach($scope.LoanDetailsSet.LoanStructure, value => {
          value.LoanInterestRate /= 100;
        });

        if (!$scope.Settings.toEditSecurity) {
          $scope.LoanDetailsSet.SecurityInfo.SecurityInfoId = null;
        }

        _.forEach($scope.LoanDetailsSet.LoanStructure, loan => {
          if (loan.InterestRateType === 'Variable') {
            loan.InterestRateType = 'Floating';
          }
        });

        contactService.LoanDetailsSet($scope.LoanDetailsSet).then(response => {
          const openNewCreatedLoan = () => {
            if (
              $scope.guarantorList &&
              $scope.guarantorList.length > 0 &&
              response.data &&
              response.data > 1
            ) {
              let counter = 1;
              $scope.guarantorList.forEach(clientInfo => {
                loanScenarioService
                  .contactLoanClientSet(
                    response.data,
                    clientInfo.BorrowerID,
                    true,
                    true
                  )
                  .then(() => {
                    if (counter === _.size($scope.guarantorList)) {
                      $scope.cancel({
                        action: 'edit',
                        familyId: $scope.params.familyId,
                        loanId: response.data,
                      });
                    } else {
                      counter++;
                    }
                  });
              });
            } else if (response.data && response.data > 1) {
              $scope.cancel({
                action: 'edit',
                familyId: $scope.params.familyId,
                loanId: response.data,
              });
            }
          };
          if (
            typelessEqual(
              $scope.LoanDetailsSet.Loan.LoanStatus.LoanStatusID,
              LOAN_STATUS.EXISTING.SETTLED
            )
          ) {
            modalRenderService
              .openMovedToSettledModal(
                'modalLinkLoanToPipeline',
                $state.params.familyId,
                response.data
              )
              .then(() => {
                openNewCreatedLoan();
              });
          } else {
            openNewCreatedLoan();
          }
        });
      };
      $scope.clientEnquiryList = CLIENT_REFERRAL;
      $scope.additionalEnquiryList = ADDITIONAL_REFERRAL;
      $scope.displayField = displayField;
      $scope.clientSearchApi = userInputString => {
        const defer = $q.defer();
        defer.resolve([]);
        if (!userInputString || !$scope.allocatedAdviserId)
          return defer.promise;
        return contactService.searchFamilyContactType(
          userInputString,
          'Client',
          $scope.allocatedAdviserId
        );
      };
    }
  );
