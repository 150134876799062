import './style.scss';
import template from './announcementModal.html';
import AnnouncementModalCtrl from './announcementModalCtrl';

export default {
  template,
  bindings: {
    modalInstance: '<',
    featuresList: '=',
  },
  controller: AnnouncementModalCtrl,
  controllerAs: 'vm',
};
