import {
  getPhoneNumberByType,
  getEmailByType,
} from 'Common/utilities/contact.js';

export function manualIFinanceBuilderForUI(manualIFinance) {
  return (
    manualIFinance && {
      lenderName: manualIFinance.Lender,
      assetVariant: manualIFinance.ProductName,
      establishmentFee: manualIFinance.EstablishmentFee,
      accountKeepingFee: manualIFinance.AccountKeepingFee,
      repaymentAmount: manualIFinance.Repayment,
      repaymentFrequency: manualIFinance.RepaymentFrequency,
      interestRate: manualIFinance.InterestRate,
    }
  );
}

export function manualIFinanceBuilderForMyCRM(manualIFinance) {
  return (
    manualIFinance && {
      Lender: manualIFinance.lenderName,
      ProductName: manualIFinance.assetVariant,
      EstablishmentFee: manualIFinance.establishmentFee,
      AccountKeepingFee: manualIFinance.accountKeepingFee,
      Repayment: manualIFinance.repaymentAmount,
      RepaymentFrequency: manualIFinance.repaymentFrequency,
      InterestRate: manualIFinance.interestRate,
    }
  );
}

export function assetSupplierBuilderForMyCRM(assetSupplier) {
  return (
    assetSupplier && {
      AssetSupplierId: assetSupplier.assetSupplierId,
      FirstName: assetSupplier.firstname,
      LastName: assetSupplier.lastname,
      Title: assetSupplier.title,
      Gender: assetSupplier.gender,
      ACN: assetSupplier.abnacn,
      CompanyName: assetSupplier.companyName,
      DealerNumber: assetSupplier.dealerNumber,
      Phone: [
        {
          Type: 'Fax',
          Number: assetSupplier.fax,
        },
        {
          Type: 'Work',
          Number: assetSupplier.officePhone,
        },
        {
          Type: 'Mobile',
          Number: assetSupplier.mobile,
        },
      ],
      AddressId: assetSupplier.addressId,
      Email: [
        {
          Type: 'Email',
          EmailAddress: assetSupplier.email,
        },
      ],
    }
  );
}

export function assetSupplierBuilderForUI(assetSupplier) {
  return (
    assetSupplier && {
      assetSupplierId: assetSupplier.AssetSupplierId,
      firstname: assetSupplier.FirstName,
      lastname: assetSupplier.LastName,
      title: assetSupplier.Title,
      gender: assetSupplier.Gender,
      abnacn: assetSupplier.ACN,
      companyName: assetSupplier.CompanyName,
      dealerNumber: assetSupplier.DealerNumber,
      officePhone: getPhoneNumberByType(assetSupplier.Phone, 'Work'),
      mobile: getPhoneNumberByType(assetSupplier.Phone, 'Mobile'),
      fax: getPhoneNumberByType(assetSupplier.Phone, 'Fax'),
      addressId: assetSupplier.AddressId,
      email: getEmailByType(assetSupplier.Email, 'Email'),
    }
  );
}
