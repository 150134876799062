class SearchPreferenceSetting {
  getSearchPreference() {
    this.preference = {
      priority1: true,
      priority2: true,
      priority3: true,
      typeResidential: true,
      typeCommercial: true,
      typeSMSF: true,
      typePersonal: true,
      typeInvestment: true,
      typeHomeBuyer: true,
      typeBusiness: true,
      typeReverseMortgage: true,
      typeCarMortgage: true,
      typeLowDoc: true,
      typeRefinance: true,
      statusActive: true,
      radius: 100,
    };
    return this.preference;
  }
}

export default SearchPreferenceSetting;
