import template from './selectGdriveFolderModal.html';
import selectGdriveFolderModalCtrl from './selectGdriveFolderModalCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    modalInstance: '<',
    resolvedData: '&',
    hasChosenAFolder: '&',
  },
  controller: selectGdriveFolderModalCtrl,
  controllerAs: 'vm',
};
