export default class ImageCircleCtrl {
  constructor(utilitiesService) {
    'ngInject';

    this.utilitiesService = utilitiesService;
  }

  $onInit() {
    if (!this.initials && this.fullName) {
      this.initials = this.utilitiesService.filterInitialOneString(
        this.fullName.trim()
      );
    }
  }
}
