import './style.scss';
import template from './ratingStar.html';
import RatingStarCtrl from './ratingStarCtrl';

export default {
  template,
  controller: RatingStarCtrl,
  controllerAs: 'vm',
  bindings: {
    ratingValue: '<',
    maxValue: '<',
    fillStyle: '<',
    onRatingClick: '&',
    isDisabled: '<',
  },
};
