import CrmTableViewCtrl from '../crmTableViewCtrl';

export default class CrmTableViewOpportunity extends CrmTableViewCtrl {
  constructor(pipelineService, $interval) {
    'ngInject';

    super($interval);
    this.pipelineService = pipelineService;
    this.lastSortColumn = this.filterSettings.sortColumn;
    this.currentPage = 1;
  }

  $onInit() {
    this.componentCells = ['pipelineStatus', 'labelsList', 'manageLabelOpen'];
  }

  rowDeletion(rowData, $event) {
    const isOfflineDeleting = this.totalRecords <= this.pageSize;
    const { loanId, id: rowId } = rowData;
    const params = {
      $e: {
        loanId,
        event: $event,
        successCallback: isOfflineDeleting
          ? this.removeRow
          : this.onRefreshTable,
        successCallbackParams: isOfflineDeleting ? { rowId } : {},
      },
    };

    this.onDeletePipelineItem(params);
  }
}
