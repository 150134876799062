import angular from 'angular';
import workbenchRequirementTemplates from './workbenchRequirementTemplates/workbenchRequirementTemplates';
import workbenchRequirementTemplatesModal from './workbenchRequirementTemplatesModal';
import './style.scss';

export default angular
  .module('components.workbenchRequirementTemplatesModal', [])
  .component('workbenchRequirementTemplates', workbenchRequirementTemplates)
  .component(
    'workbenchRequirementTemplatesModal',
    workbenchRequirementTemplatesModal
  ).name;
