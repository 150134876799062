class UsersProfileService {
  constructor() {
    'ngInject';

    this.editMode = {
      PreferredName: false,
      SecondaryEmail: false,
      HomeNumber: false,
      WorkNumber: false,
      Mobile: false,
      LanguagesSpoken: false,
      EmergencyContactName: false,
      EmergencyContactNumber: false,
      EmergencyContactNatureOfRelationship: false,
      BusinessAddress: false,
      HomeAddress: false,
      PostalAddress: false,
    };

    this.editModeText = {
      PreferredName: 'Preferred First Name',
      SecondaryEmail: 'Secondary Email',
      HomeNumber: 'Home Phone',
      WorkNumber: 'Business Phone',
      Mobile: 'Mobile #',
      LanguagesSpoken: 'Language Spoken',
      EmergencyContactName: 'Emergency Contact Person',
      EmergencyContactNumber: 'Emergency Contact #',
      EmergencyContactNatureOfRelationship: 'Nature of Relationship',
      BusinessAddress: 'Business Address',
      HomeAddress: 'Home Address',
      PostalAddress: 'Postal Address',
    };

    this.editModeKeys = {
      PreferredName: 'PreferredName',
      SecondaryEmail: 'SecondaryEmail',
      HomeNumber: 'HomeNumber',
      WorkNumber: 'WorkNumber',
      Mobile: 'Mobile',
      LanguagesSpoken: 'LanguagesSpoken',
      EmergencyContactName: 'EmergencyContactName',
      EmergencyContactNumber: 'EmergencyContactNumber',
      EmergencyContactNatureOfRelationship:
        'EmergencyContactNatureOfRelationship',
      BusinessAddress: 'BusinessAddress',
      HomeAddress: 'HomeAddress',
      PostalAddress: 'PostalAddress',
    };
  }
  getEditMode() {
    return this.editMode;
  }

  getEditModeText() {
    return this.editModeText;
  }

  getEditModeKeys() {
    return this.editModeKeys;
  }
}

export default UsersProfileService;
