import template from './showDocumentListOnHover.html';
import controller from './showDocumentListOnHoverCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    documentList: '<',
  },
};
