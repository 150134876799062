import { ASSET_INSURANCE_DETAILS } from 'Common/constants/questions';

export const excludedInsuranceQuestions = [
  23,
  103,
  104,
  105,
  106,
  107,
  126,
  127,
  128,
  129,
  130,
  ASSET_INSURANCE_DETAILS.INSURER.QUESTION_ID,
  ASSET_INSURANCE_DETAILS.INSURER.QUESTION_ID_NZ,
  ASSET_INSURANCE_DETAILS.INSURANCE_BROKER.QUESTION_ID,
  ASSET_INSURANCE_DETAILS.INSURANCE_BROKER.QUESTION_ID_NZ,
  ASSET_INSURANCE_DETAILS.POLICY_NUMBER.QUESTION_ID,
  ASSET_INSURANCE_DETAILS.POLICY_NUMBER.QUESTION_ID_NZ,
  ASSET_INSURANCE_DETAILS.EXPIRY_DATE.QUESTION_ID,
  ASSET_INSURANCE_DETAILS.EXPIRY_DATE.QUESTION_ID_NZ,
];
