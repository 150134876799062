import template from './beneficiaryField.html';
import beneficiaryFieldCtrl from './beneficiaryFieldCtrl';

export default {
  template,
  controller: beneficiaryFieldCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    beneficiary: '=',
    beneficiaryNumber: '=',
    onRemoveBeneficiary: '&',
    formReference: '<',
    isSubmitted: '=',
    formHasError: '=',
  },
};
