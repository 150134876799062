export default class PipelineReasonNotProceedingModalCtrl {
  constructor() {
    'ngInject';

    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.modalInstance.close({ isOk: false, selectedReasonId: 0 });
  }

  save(params) {
    this.modalInstance.close({ isOk: true, ...params });
  }
}
