export const DEFAULT_DOCUMENT_EXTENSIONS = [
  'pdf',
  'docx',
  'doc',
  'odt',
  'xls',
  'xlsx',
  'ods',
  'odp',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'rtf',
  'odt',
  'txt',
  'tif',
  'bmp',
  'gif',
  'html',
];

export const INVALID_FILE_INFO = {
  TITLE: 'Invalid',
  DESCRIPTION: 'Invalid file NOT added',
};
