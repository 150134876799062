import template from './addressSelector.html';
import controller from './addressSelectorCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    onAddressSelected: '&',
    placeholder: '@',
    excludeNonGeoCoded: '<',
  },
};
