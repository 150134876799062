export default class ReferralModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Referrer';
  }

  getReferrerContactInfo(loanId) {
    return this.httpClient.get(`${this.apiBaseUrl}/contact-info`, { loanId });
  }
}
