import controller from './currencyFormattingCtrl';

export default {
  template: '{{vm.amountValue}}',
  controller,
  controllerAs: 'vm',
  bindings: {
    amount: '<',
    symbol: '@',
    precision: '<',
    acceptZero: '<',
  },
};
