import domtoimage from 'dom-to-image';
import angular from 'angular';
import {
  downloadResource,
  downloadImgAsPDF,
} from 'Common/utilities/downloadDataHelper';

export default class DownloadHtmlToImgMenu {
  downloadHtmlAs(type) {
    const nodeToDownload = angular.element(`#${this.htmlNodeIdToPrint}`)[0];
    const fileName = `insurance quote comparison graph.${type}`;
    const toImgConverter = type === 'png' ? 'toPng' : 'toJpeg';
    domtoimage[toImgConverter](nodeToDownload, {
      filter: node => !angular.element(node).attr('dom-to-img-ignore'),
      quality: 1,
    }).then(dataUrl => {
      if (type === 'pdf') {
        const { width, height } = nodeToDownload.getBoundingClientRect();
        const configuration = {
          orientation: this.pdfOrientation,
          width,
          height,
        };
        downloadImgAsPDF(dataUrl, fileName, configuration);
      } else {
        downloadResource(dataUrl, fileName);
      }
    });
  }
}
