class documentTypeIconCtrl {
  constructor() {
    'ngInject';

    this.type = {
      pdf: { isShow: false },
      xls: { isShow: false },
      powerpoint: { isShow: false },
      text: { isShow: false },
      generic: { isShow: false },
    };
    this.documentId = parseInt(this.documentId, 10);
  }

  setDocumentType(contentType) {
    const { pdf, xls, powerpoint, text, generic } = this.type;

    if (contentType === 'application/pdf') {
      pdf.isShow = true;
    } else if (contentType === 'application/vnd.ms-excel') {
      xls.isShow = true;
    } else if (contentType === 'application/vnd.ms-powerpoint') {
      powerpoint.isShow = true;
    } else if (contentType === 'application/ms-word') {
      text.isShow = true;
    } else {
      generic.isShow = true;
    }
  }

  $onInit() {
    this.setDocumentType(this.contentType);
  }
}

export default documentTypeIconCtrl;
