import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import moment from 'moment';

export default class PipelineOpportunitySettings {
  constructor(services) {
    const { pipelineService, contactService, $filter, $sce } = services;

    const headerSettings = { isHidden: 0, isSortable: 1 };

    this.getHeaders = () => {
      return contactService.getUserInfo().then(({ data }) => {
        if (!data) return [];
        const { AccessType } = data;
        const isCorporateUser = AccessType === ACCESS_TYPE.CORPORATE;

        return [
          {
            offlineSortObjKey: 'contactNames',
            isExclusionIncluded: false,
            dataSortObjectExcludedKey: '',
            dataSortObjectKey: 'ClientName',
            label: 'Client Name',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'title',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'OpportunityName',
            dataSortObjectKey: 'OpportunityName',
            label: 'Opportunity Name',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'proposedLoanAmount',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'ProposedLoan',
            dataSortObjectKey: 'ProposedLoan',
            label: 'Proposed Loan',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'pipelineStatus',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Status',
            dataSortObjectKey: 'Status',
            label: 'Status',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'enquiry',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'EnquirySource',
            dataSortObjectKey: 'EnquirySource',
            label: 'Enquiry Source',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'adviserFullName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'AdvisorName',
            dataSortObjectKey: 'Adviser',
            label: 'Adviser',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'referrerName',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'Referrer',
            dataSortObjectKey: 'Referrer',
            label: 'Referrer',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'statusUpdated',
            isExclusionIncluded: true,
            dataSortObjectExcludedKey: 'SubStatusUpdated',
            dataSortObjectKey: 'StatusUpdated',
            label: 'Status Updated',
            ...headerSettings,
          },
          {
            offlineSortObjKey: 'labelsList',
            isExclusionIncluded: !isCorporateUser,
            dataSortObjectExcludedKey: 'Labels',
            dataSortObjectKey: 'Labels',
            label: 'Labels',
            ...headerSettings,
          },
          { label: 'id', isHidden: 1 },
          { label: 'manageLabelOpen', isHidden: 1 },
          { label: 'loanId', isHidden: 1 },
          { label: 'loanScenarioId', isHidden: 1 },
          { label: 'pipelineStatusId', isHidden: 1 },
        ];
      });
    };

    this.getTableData = (pageNumber, pageSize) => {
      return pipelineService
        .getOpportunityTable({ pageNumber, pageSize })
        .then(response => {
          if (!response || !response.length) {
            this.showLoader = false;
            return { totalRecords: 0, tableData: [] };
          }

          const { totalRecords } = response[0];
          const tableData = response.reduce((accum, current) => {
            const {
              contactNames,
              title,
              proposedLoanAmount,
              pipelineStatus,
              enquiry,
              adviserFullName,
              referrerName,
              statusUpdated,
              pipelineStatusId,
              loanId,
              loanScenarioId,
              labels,
            } = current;
            if (!loanScenarioId) return accum;
            return accum.concat({
              contactNames,
              title,
              proposedLoanAmount: $filter('currency')(
                proposedLoanAmount,
                '$',
                2
              ),
              pipelineStatus,
              enquiry,
              adviserFullName: $sce.trustAsHtml(`
                  <span class="adviser">
                    <h4>${adviserFullName.charAt(0)}</h4>
                    <p>${adviserFullName.split(' ')[0]}</p>
                  </span>
                `),
              referrerName,
              statusUpdated: statusUpdated
                ? moment(new Date(statusUpdated)).format('YYYY MM DD hh:mm:ss')
                : '',
              labelsList: pipelineLabelBuilder(labels),
              id: loanScenarioId,
              manageLabelOpen: false,
              loanId,
              loanScenarioId,
              pipelineStatusId,
            });
          }, []);
          return { totalRecords, tableData };
        });
    };
  }
}
