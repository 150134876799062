export const SECTION_ID = {
  APPLICANTS: 1,
  CUSTOMER_OBJECTIVES: 2,
  LOAN_FUNDING_DETAILS: 3,
  FINANCIAL_POSITION: 6,
  INSURANCE: 7,
  UPLOAD_VIEW: 8,
  DECLARATIONS: 9,
  BROKER_ADVISER_NOTES: 10,
  LENDER_SUBMISSION: 12,
  BROKER_DECLARATION: 13,
  REFERRER_INFORMATION: 11,
};

export const LIST_PURPOSE_QUESTION_ID = {
  AU: 5,
  NZ: 54,
};

export const PREFERRED_LENDER_QUESTION_ID = {
  AU: 7,
  NZ: 56,
};

export const NOTES_TO_SHOW_QUESTION_ID = {
  AU: 41,
  NZ: 88,
};
