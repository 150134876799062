import template from './entityContactDetails.html';
import entityContactDetailsCtrl from './entityContactDetailsCtrl';

export default {
  template,
  controller: entityContactDetailsCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    entityData: '=',
    formReference: '<',
  },
};
