import angular from 'angular';

angular
  .module('app')
  .directive('pagination', function pagination($timeout, $rootScope) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        totalRecords: '=',
        pageClicked: '&',
      },
      templateUrl: 'assets/views/pagination/pagination.html',

      link(scope) {
        scope.pageChanged = function() {
          scope.pageClicked({ numb: scope.bigCurrentPage - 1 });
        };

        scope.maxSize = 5;
        scope.bigTotalItems = scope.totalRecords;
        scope.bigCurrentPage = 1;

        const pagerRecordChangedBroadcastHandler = $rootScope.$on(
          'pagerRecordChanged',
          (event, args) => {
            scope.bigTotalItems = args.totalRecords;
          }
        );
        scope.$on('$destroy', pagerRecordChangedBroadcastHandler);
      },
    };
  });
