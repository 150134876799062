import { getApplicationStatus } from 'Common/utilities/loanApplication';
import { objectLength } from 'Common/utilities/objectValidation';
import {
  PIPELINE_TYPES_STR,
  PIPELINE_CATEGORIES,
  PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS,
} from 'Common/constants/pipelineType';
import {
  PIPELINE_STATUS,
  CONVERSION_PIPELINE_STATUS,
} from 'Common/constants/pipelineStatus';
import CrmTableViewCtrl from '../crmTableViewCtrl';

export default class TableViewApplications extends CrmTableViewCtrl {
  constructor(
    $interval,
    utilitiesService,
    pipelineService,
    contactService,
    modalRenderService,
    toaster,
    pipelineSharedData
  ) {
    'ngInject';

    super($interval);
    this.currentPage = 1;
    this.componentCellKeys = [
      'lender',
      'pipelineStatus',
      'lastModifiedDate',
      'settlementDate',
      'labelsList',
      'familyInfo',
      'manageLabelOpen',
    ];
    this.utilitiesService = utilitiesService;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
    this.modalRenderService = modalRenderService;
    this.toaster = toaster;
    this.pipelineSharedData = pipelineSharedData;

    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.getReasons();
    getApplicationStatus(this.pipelineService).then(response => {
      this.statusList = response;
    });

    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  getReasons() {
    this.pipelineService
      .getPipelineCategoryReasons(PIPELINE_CATEGORIES.APPLICATION)
      .then(({ data }) => {
        this.reasonList = data;
      });
  }

  rowDeletion(rowData, event) {
    const isOfflineDeleting = this.totalRecords <= this.pageSize;
    const { loanID: loanId, loanScenarioID: rowId } = rowData;
    const params = {
      $e: {
        loanId,
        event,
        successCallback: isOfflineDeleting
          ? this.removeRow
          : this.onRefreshTable,
        successCallbackParams: isOfflineDeleting
          ? { rowId, primaryKey: 'loanScenarioID' }
          : {},
      },
    };

    this.onDeletePipelineItem(params);
  }

  statusChange(pipelineItem, newPipelineStatus) {
    const {
      pipelineStatusID: PipelineStatusId,
      pipelineStatus,
    } = newPipelineStatus;
    const { loanID } = pipelineItem;
    // offline mutation for display purposes
    pipelineItem.pipelineStatus = pipelineStatus;
    pipelineItem.lastModifiedDate = new Date();

    return this.pipelineService
      .putApplicationLoanStatus(loanID, {
        PipelineStatusId,
        ShouldSendNurtureCampaigns: false,
      })
      .then(({ succeeded }) => {
        if (succeeded) {
          this.toaster.pop(
            'success',
            'Success',
            'Application successfully moved!'
          );
        } else {
          this.toaster.pop('error', 'Error', 'Something went wrong');
        }
      });
  }

  convertCard(pipelineItem, pipelineStatusId, movedToStatus) {
    const { loanID, pipelineCardsID, enquirySource } = pipelineItem;
    const modalContent = {
      content: `This record will be moved to ${movedToStatus}`,
    };
    if (pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED) {
      modalContent.reasonList = this.reasonList;
      modalContent.showNurtureCampaignsQuestion = this.pipelineSharedData.showNurtureCampaignsQuestion(
        enquirySource
      );
    }
    const confirmationPopup = this.modalRenderService.renderPipelineStatusMoveConfirmationModal(
      modalContent
    );
    confirmationPopup.result.then(result => {
      const { selectedReasonId: ReasonSubstatusId, isOk } = result;

      if (isOk) {
        const args = {
          LoanId: loanID,
          ConversionStatusId: pipelineStatusId,
          ReasonSubstatusId,
          ShouldSendNurtureCampaigns: false,
        };
        this.pipelineService
          .putApplicationLoanConversionStatus(loanID, args)
          .then(({ succeeded }) => {
            this.onRefreshStats();
            if (succeeded) {
              this.toaster.pop(
                'success',
                'Success',
                'Application successfully moved!'
              );
              const { loanScenarioID: rowId } = pipelineItem;
              const rowRemoveArgs = { rowId, primaryKey: 'loanScenarioID' };
              this.removeRow(rowRemoveArgs);
              if (pipelineStatusId === PIPELINE_STATUS.SETTLED) {
                this.modalRenderService.openMovedToSettledModal(
                  'modalLinkLoanToPipeline',
                  0,
                  loanID,
                  pipelineCardsID
                );
              }
            } else {
              this.toaster.pop('error', 'Error', 'Something went wrong');
            }
          });
      }
    });
  }

  showMenu(rowData) {
    const { loanID, familyInfo } = rowData;
    if (!objectLength(familyInfo)) {
      this.contactService
        .getFamiliesPerLoan(loanID)
        .then(({ succeeded, data }) => {
          if (!succeeded || !data) return;

          const { familyInfo: families } = data;
          const clients = families.filter(
            family => family.isClient && !family.isGuarantor
          );
          const entities = families.filter(family => !family.isClient);
          const guarantors =
            this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY
              ? families.filter(family => family.isClient && family.isGuarantor)
              : [];

          rowData.familyInfo = [...clients, ...entities, ...guarantors];
        });
    }
  }

  toggleIsShrink(event) {
    event && event.stopPropagation();
    this.isShrink = !this.isShrink;
  }

  processParentNotification(eventHandler, data = {}) {
    const isRemoveCard =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;

    if (isRemoveCard) {
      const { loanScenarioId: rowId } = data;
      const rowRemoveArgs = { rowId, primaryKey: 'loanScenarioID' };
      this.removeRow(rowRemoveArgs);
    }
  }
}
