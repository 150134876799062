import './style.scss';
import template from './income.html';
import IncomeCtrl from './incomeCtrl';

export default {
  template,
  bindings: {
    formReference: '<',
    onAddIncome: '&',
    employment: '=',
    isLoanApp: '<',
    loanAppId: '<',
    familyId: '<',
  },
  controller: IncomeCtrl,
  controllerAs: 'vm',
};
