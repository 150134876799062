import { COLOR, GMAP_COLOR } from 'Common/constants/colors';

export function mapCustomConfig() {
  return {
    map: {
      mapTypeControl: true,
      zoomControl: true,
      streetViewControl: true,
      zoom: 15,
    },
    infowindow: {
      hidden: false,
      enableEventPropagation: true,
      zIndex: 999,
      maxWidth: 150,
      maxHeight: 150,
      boxStyle: {
        backgroundColor: COLOR.DARKER_BLUER,
        opacity: 0.75,
      },
    },
    marker: {
      icon: '/assets/images/icon/pin.png',
    },
    hasInfoWindow: false,
  };
}

export function circleDefaultOptions() {
  return {
    strokeColor: COLOR.DARKER_BLUE,
    strokeOpacity: 0.37,
    strokeWeight: 0,
    fillColor: COLOR.DARKER_BLUE,
    fillOpacity: 0.37,
    radius: 10,
  };
}

export function mycrmCustomGMapStyles() {
  return [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ color: GMAP_COLOR.DARK_SLATE_GRAY }],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [{ color: GMAP_COLOR.LANDSCAPE }],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi.business',
      elementType: 'geometry.fill',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [{ color: GMAP_COLOR.WATER }, { visibility: 'on' }],
    },
  ];
}

export function displayMap(editMode, obj) {
  return (
    obj &&
    !editMode &&
    obj.formatted_address &&
    obj.latitude &&
    obj.longitude &&
    !obj.isCustomAddress
  );
}
export function displayCustomAddress(editMode, obj) {
  return (
    obj &&
    !editMode &&
    obj.formatted_address &&
    ((!obj.latitude && !obj.longitude) || obj.isCustomAddress)
  );
}

export function getGoogleMapInstance() {
  if (!window.google || !window.google.maps) return false; // eslint-disable-line
  return window.google.maps; // eslint-disable-line
}
