export default class InsuranceBenefitWidgetCtrl {
  $onInit() {
    if (!this.fieldsConfig || !this.fieldsConfig.length) {
      throw new Error('Missing fields-config for insurance-benefit-widget');
    }
    this.initFields();
  }

  $onChanges() {
    this.initFields();
  }

  initFields() {
    this.previewField = this.fieldsConfig.find(
      field => field.name === this.previewFieldName
    );
    this.previewValueFromOptions = this.getPreviewValueFromOptions();
    this.mainFields = this.fieldsConfig.filter(field => !field.isOption);
    this.optionFields = this.fieldsConfig.filter(field => field.isOption);
    this.isEditMode = this.isNewBenefit;
  }

  getPreviewValueFromOptions() {
    if (!this.isPreviewValueFromOptions || !this.previewField.options)
      return null;
    const value = this.benefitCover[this.previewField.name];
    const option = this.previewField.options.find(item => {
      return item[this.previewField.optionValueProperty] === value;
    });
    return option && option[this.previewField.optionNameProperty];
  }

  getFieldId(field) {
    const personId = this.person.ClientId || this.person.indexId;
    return `${this.benefitId}_${this.benefitIndex}_${personId}_${field.name}`;
  }

  toggleEditMode(skipValidation) {
    if (this.isEditMode && !skipValidation) {
      this.insuranceBenefitWidgetForm.$setSubmitted();
      if (!this.insuranceBenefitWidgetForm.$valid) return;
    }

    this.isEditMode = !this.isEditMode;
    if (this.isEditMode && this.onOpenForm) {
      this.onOpenForm({ insuredPerson: this.insuredPerson });
    } else if (!this.isEditMode && this.onCloseForm) {
      this.onCloseForm({ insuredPerson: this.insuredPerson });
    }
    if (this.onEditModeToggle) {
      this.onEditModeToggle({ person: this.person, toggled: this.isEditMode });
    }
  }

  toggleOptions() {
    this.isOptionsShown = !this.isOptionsShown;
  }

  onFieldUpdate(value, field) {
    if (!this.onUpdate) return;
    const fieldUpdate = {
      value,
      field: field.name,
      benefitId: this.benefitId,
      benefitIndex: this.benefitIndex,
      personIndex: this.person.indexId,
      personId: this.person.ClientId,
    };
    this.onUpdate({ fieldUpdate });
  }

  saveChanges() {
    this.insuranceBenefitWidgetForm.$setSubmitted();
    if (!this.insuranceBenefitWidgetForm.$valid) return;
    this.toggleEditMode();
    if (this.onSave) {
      const benefitUpdates = {
        benefitId: this.benefitId,
        benefitIndex: this.benefitIndex,
        personIndex: this.person.indexId,
        personId: this.person.ClientId,
      };
      this.onSave({ benefitUpdates });
    } else {
      this.isNewBenefit = false;
    }
    this.previewValueFromOptions = this.getPreviewValueFromOptions();
  }

  deleteBenefit() {
    if (this.onDelete) {
      const benefit = {
        benefitId: this.benefitId,
        benefitIndex: this.benefitIndex,
        person: this.person,
        isEditMode: this.isEditMode,
      };
      this.onDelete({ benefit });
    }
  }
}
