import { NEXT_THREE_MONTHS } from 'Common/constants/timePeriod';

class CorporateWidgetDefaultService {
  getDefaultTimePeriod() {
    this.defaultTimePeriod = {
      WidgetOptionId: NEXT_THREE_MONTHS.WIDGET_OPTION_ID,
      DisplayValue: NEXT_THREE_MONTHS.DISPLAY_VALUE,
      OptionName: NEXT_THREE_MONTHS.OPTION_NAME,
      Widget_OptionValue: NEXT_THREE_MONTHS.WIDGET_OPTION_VALUE,
      DB_Value: NEXT_THREE_MONTHS.DB_VALUE,
      SelectedUerValues: NEXT_THREE_MONTHS.SELECTED_USER_VALUES,
      SelectedUserDisplayValues: NEXT_THREE_MONTHS.SELECTED_USER_DISPLAY_VALUES,
    };

    return this.defaultTimePeriod;
  }
}

export default CorporateWidgetDefaultService;
