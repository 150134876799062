export const SECTION_STRING = {
  INVOLVED_PARTY_PERSON: 'PersonBorrowers',
  INVOLVED_PARTY_ENTITY: 'InvolvedPartyEntity',
  RELATED_PARTY: 'RelatedParty',
};

export const PRIMARY_PURPOSE = {
  QUESTION_ID: 5,
  DISPLAY_OPTION_ID: 6,
};
