import angular from 'angular';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';

angular
  .module('app')
  .controller('PrincipalDetailsCtrl', function PrincipalDetailsCtrl(
    $scope,
    $timeout,
    toaster,
    SweetAlert,
    corporateService,
    onboardingService,
    commonFnService,
    modelDataService,
    dataService,
    dashboardService,
    onboardingDataService,
    broadcastDataService,
    addressModalService
  ) {
    $scope.principalDetails = {};
    $scope.principalDetails.fullData = [];
    $scope.entityTypeIsCompany = false;
    $scope.entityTypeIsPartnership = false;
    $scope.entityTypeIsSoleTrader = false;
    $scope.mobileMask = null;
    $scope.isOwnBrand = false;
    $scope.isPreferredEmailValid = false;
    $scope.contactAddress = {
      homeAddress: modelDataService.addressModelData(),
      isMailingAddress: false,
      businessAddress: modelDataService.addressModelData(),
      mailingAddress: modelDataService.addressModelData(),
    };

    let timeOut = null;

    const getFamilyAddress = () => {
      corporateService
        .familyAddressGet(onboardingDataService.getFamilyId())
        .then(response => {
          if (response && response.data) {
            const addressTmp = commonFnService.setAddressTypes(response.data);
            if (addressTmp) {
              $scope.contactAddress.homeAddress = { ...addressTmp.home };
            }
          }
        })
        .then(() => {
          $scope.principalDetails.address = $scope.contactAddress.homeAddress;
        });
    };

    const savePrincipalAddress = addressObj => {
      dashboardService.addressDetailsSet(addressObj).then(response => {
        if (response && response.data) {
          corporateService.familyAddressSet(
            onboardingDataService.getFamilyId(),
            onboardingDataService.getClientId(),
            response.data,
            false,
            false,
            false
          );
        }
      });
    };

    $scope.selecthomeAddress = addressObj => {
      savePrincipalAddress(addressObj);
    };

    $scope.addressValueOnBlur = address => {
      const addressObjTmp = modelDataService.addressModelData();
      if (address) {
        addressObjTmp.formatted_address = address;
        savePrincipalAddress(addressObjTmp);
      }
    };

    const populatePreferredEmail = email => {
      const preferredEmailSplit = email.split('@');
      if (preferredEmailSplit.length) {
        return preferredEmailSplit[0];
      }
      return email;
    };

    const getPrincipalDetails = () => {
      onboardingService
        .getRecruitmentContact(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(response => {
          if (response && response.data) {
            $scope.principalDetails.fullData = response.data[0];
            const data = response.data[0];
            if (!data.SecondaryEmail) data.SecondaryEmail = data.Email;
            $scope.principalDetails.givenName = data.FirstName || '';
            $scope.principalDetails.middlename = data.MiddleName || '';
            $scope.principalDetails.surname = data.LastName || '';
            $scope.principalDetails.mobileNumber = data.Mobile || '';
            $scope.principalDetails.personalEmail = data.SecondaryEmail;
            $scope.principalDetails.isBusinessPartner = data.isBusinessPartner;
            $scope.principalDetails.isCompanyDirector = data.isCompanyDirector;
            $scope.principalDetails.FamilyId = data.FamilyId || 0;
            $scope.principalDetails.isSoleTrader = true;

            if (!$scope.isOwnBrand) {
              if (data.Email) {
                $scope.principalDetails.loanMarketEmail = populatePreferredEmail(
                  data.Email
                );
              } else {
                $scope.principalDetails.loanMarketEmail = onboardingDataService.suggestPreferredEmail(
                  data.FirstName,
                  data.LastName
                );
              }
            } else {
              $scope.principalDetails.loanMarketEmail = data.Email || '';
            }
            if ($scope.principalDetails.loanMarketEmail) {
              $scope.savePrincipalPreferredEmail();
            }
          }
        })
        .then(() => {
          const dataTmp = {
            principalDetails: $scope.principalDetails,
          };
          onboardingDataService.setAllOnboardingDataForPreview(dataTmp);
        });
    };

    $scope.savePrincipalPreferredEmail = () => {
      if (timeOut) {
        $timeout.cancel(timeOut);
      }
      timeOut = $timeout(() => {
        onboardingDataService
          .checkEmail(
            `${$scope.principalDetails.loanMarketEmail}@loanmarket.com.au`,
            0
          )
          .then(isExist => {
            $scope.isPreferredEmailValid = !isExist;
            if ($scope.isPreferredEmailValid) {
              angular.element('#preferedEmail').removeClass('invalid');
              $scope.savePrincipalDetails(null, null, true);
            } else {
              angular.element('#preferedEmail').addClass('invalid');
            }
          });
      }, 1000);
    };

    $scope.savePrincipalDetails = (
      formName,
      inputName,
      isCustomPreferredEmail
    ) => {
      if (formName && inputName) {
        if (
          !onboardingDataService.checkFormAndInputIsValid(formName, inputName)
        ) {
          return false;
        }
      }
      if (!$scope.isOwnBrand && !isCustomPreferredEmail && !inputName) {
        $scope.principalDetails.loanMarketEmail = onboardingDataService.suggestPreferredEmail(
          $scope.principalDetails.givenName,
          $scope.principalDetails.surname
        );
        $scope.savePrincipalPreferredEmail();
      }
      const data = {
        ClientID: $scope.principalDetails.fullData.ClientID || 0,
        FamilyId: $scope.principalDetails.fullData.FamilyId || 0,
        FirstName: $scope.principalDetails.givenName || '',
        MiddleName: $scope.principalDetails.middlename || '',
        LastName: $scope.principalDetails.surname || '',
        Mobile: $scope.principalDetails.mobileNumber || '',
        SecondaryEmail: $scope.principalDetails.personalEmail || '',
        Email: $scope.isOwnBrand
          ? $scope.principalDetails.loanMarketEmail
          : `${$scope.principalDetails.loanMarketEmail}@loanmarket.com.au`,
        isBusinessPartner: commonFnService.setBooleanToInt(
          $scope.principalDetails.isBusinessPartner
        ),
        isCompanyDirector: commonFnService.setBooleanToInt(
          $scope.principalDetails.isCompanyDirector
        ),
      };
      onboardingService.setRecruitmentContact(
        onboardingDataService.getFamilyId(),
        data
      );
    };

    const onModalAddressResult = response => {
      if (!response) return;
      $scope.contactAddress.homeAddress.formatted_address =
        response.homeAddress.formatted_address;
      $scope.contactAddress.homeAddress.isBusiness = false;
      $scope.contactAddress.homeAddress.isMailing = false;

      $scope.contactAddress.mailingAddress.formatted_address =
        response.mailingAddress.formatted_address;
      $scope.contactAddress.mailingAddress.isBusiness = false;
      $scope.contactAddress.mailingAddress.isMailing = true;

      $scope.contactAddress.businessAddress.formatted_address =
        response.businessAddress.formatted_address;
      $scope.contactAddress.businessAddress.isBusiness = true;
      $scope.contactAddress.businessAddress.isMailing = false;
    };

    $scope.launchAddressModal = value => {
      const size = 'md';
      const modalInstance = addressModalService.launchAddressModal(
        size,
        value.FamilyId,
        false,
        $scope.contactAddress
      );
      modalInstance.result.then(response => {
        $scope.timeOutAdviserInstance = $timeout(() => {
          if (value.FamilyId) {
            getFamilyAddress();
            toaster.pop('success', 'Successfully Updated', 'Address');
          }
          onModalAddressResult(response);
        }, 500);
      });
    };

    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeOutAdviserInstance);
    });

    const intialize = () => {
      $scope.entityTypeIsCompany =
        onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.COMPANY_TRUST
        );
      $scope.entityTypeIsPartnership =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP_TRUST
        );
      $scope.entityTypeIsSoleTrader =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER_TRUST
        );
      $scope.isOwnBrand = onboardingDataService.isOwnBrand(
        BRANDING_CATEGORY_TYPE.OWN_BRAND
      );
      /* set Mobile Mask */
      $scope.mobileMask = broadcastDataService.getMobileFormat(
        onboardingDataService.getCountryId()
      );

      /* get Principal Details */
      getPrincipalDetails();
      /* get Principal Family Address Details */
      getFamilyAddress();
    };

    intialize();
  });
