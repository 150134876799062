export const AGREEMENT_TYPE = {
  LOAN_MARKET: 1,
  RAY_WHITE: 2,
  THIRD_PARTY: 3,
  WEALTH_MARKET: 4,
};

export const REQUEST_ID = {
  COMPANY: 1,
  INDIVIDUAL: 2,
  PARTNERSHIP: 3,
  RAY_WHITE: 4,
};
