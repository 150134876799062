import angular from 'angular';

/*
 *  @name excludeData
 *  @desc simple filter to remove given data
 *  @param [array] array of objects, [propertyName] name of property to match, [toExclude] list of values to exlude
 *  @return fitered data
 */
angular.module('app').filter('excludeData', function excludeData() {
  return (array, propertyName, toExclude) => {
    if (array) {
      const filteredData = array.filter(
        obj => !toExclude.includes(obj[propertyName])
      );
      return filteredData;
    }
  };
});
