export const ACCESS_TYPE = {
  PRINCIPAL_ADVISER: 1,
  ADVISER: 2,
  ADMIN_ASSISTANT: 3,
  ASSISTANT: 4,
  CORPORATE: 5,
  REFERRER: 9,
};

export const CORP_ACCESS_TYPE = {
  PROFILE_ADMIN: 1,
  STANDARD: 2,
};

export default { CORP_ACCESS_TYPE, ACCESS_TYPE };
