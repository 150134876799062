import angular from 'angular';

angular
  .module('app')
  .factory('applicationSharedService', applicationSharedServiceFun);

function applicationSharedServiceFun() {
  const factory = this;

  const countryCodeAndBirthday = function countryCodeAndBirthday(crmCountry) {
    let country;

    if (crmCountry === 'Australia') {
      country = 'AU';
    } else if (crmCountry === 'New Zealand') {
      country = 'NZ';
    } else {
      country = '-1';
    }
    return country;
  };

  factory.countryCodeAndBirthday = countryCodeAndBirthday;

  factory.getExcludedApplicants = partApplicants => {
    if (!partApplicants || !partApplicants.length)
      return { excludedApplicants: [], excludedIds: [] };

    const excludededApplicants = partApplicants.reduce(
      (accum, party) => {
        if (!party.isIncluded) {
          accum.excludedApplicants = [...accum.excludedApplicants, party];
          accum.excludedIds = [...accum.excludedIds, party.PersonId];
        }
        return accum;
      },
      { excludedApplicants: [], excludedIds: [] }
    );

    return excludededApplicants;
  };

  return factory;
}
