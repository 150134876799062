import template from './contactPoints.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    contactPoints: '<',
  },
};
