import angular from 'angular';

angular
  .module('app')
  .controller(
    'GetFixedRateDetailModalCtrl',
    function GetFixedRateDetailModalCtrl($scope, $uibModalInstance, fixedRate) {
      $scope.fixedRate = fixedRate;
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
