import angular from 'angular';

angular
  .module('app')
  .directive('smallWidgetSettings', function smallWidgetSettings() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        myParam: '=',
        indexInArray: '=',
      },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/smallWidgetSettings.html',

      link(scope) {
        function init() {}

        scope.updateWidgetName = function(widgetId) {
          scope.myParam.WidgetName = scope.myParam.dropDownOption.filter(
            item => parseInt(item.WidgetId, 10) === parseInt(widgetId, 10)
          )[0].WidgetName;

          scope.myParam.DisplayName =
            parseInt(widgetId, 10) === 0 ? '' : scope.myParam.WidgetName;
          scope.myParam.Widget_UserID = 0;
        };

        init();
      },
    };
  });
