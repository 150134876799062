import angular from 'angular';

angular.module('app').directive('fieldType', function fieldType($rootScope) {
  const directive = {
    scope: {
      type: '@type',
      isDesc: '@isDesc',
      fieldKeyValue: '@fieldKeyValue',
      fieldLabel: '@fieldLabel',
      setSaveType: '@setSaveType',
      objectDataValue: '@objectDataValue',
      fieldKey: '=',
      objectSetType: '=',
      object: '=',
      selectArr: '=',
      selectObj: '=',
      selectKey: '@selectKey',
      editInputType: '@editInputType',
      readOnly: '@readOnly',
      isMultiSelect: '@isMultiSelect',
      itemArr: '=',
      itemLabel: '@itemLabel',
      outputLabel: '=',
      withIcon: '@withIcon',
      isLetterIcon: '@isLetterIcon',
      isIcon: '@isIcon',
      iconClass: '@iconClass',
      isPhone: '@isPhone',
      placeholder: '@placeholder',
      maskValue: '@maskValue',
    },
    templateUrl: 'assets/views/field-template/field-type.html',
    link(scope) {
      function UpdateField(obj, key, field) {
        const tmp = [];

        if (scope.itemArr) {
          scope.itemArr &&
            Object.keys(scope.itemArr).forEach(x => {
              if (scope.itemArr[x].ticked) {
                tmp.push(scope.itemArr[x].name);
              }
            });
          obj[key] = tmp.join(', ');
        }
        $rootScope.$broadcast(
          'UPDATE_FIELD_DETAIL',
          scope.objectSetType,
          obj,
          key,
          field,
          scope.setSaveType
        );
      }

      scope.UpdateField = UpdateField;

      function checkValue() {}

      scope.checkValue = checkValue;

      scope.localLang = {
        selectAll: "<span><i class='fa fa-check'></i> Select All</span>",
        selectNone: "<span><i class='fa fa-close'></i> Deselect All </span>",
        reset: "<i class='fa fa-refresh'></i>",
        search: 'Search',
        nothingSelected: "<div class='buttonLabel deselect'>Deselect All</div>", // default-label is deprecated and replaced with this.
      };

      function SelectAllItem(obj, key) {
        const tmp = [];
        scope.itemArr &&
          Object.keys(scope.itemArr).forEach(x => {
            tmp.push(scope.itemArr[x].name);
          });
        obj[key] = tmp.join(', ');
        $rootScope.$broadcast(
          'UPDATE_FIELD_DETAIL',
          scope.objectSetType,
          obj,
          key,
          null,
          scope.setSaveType
        );
      }

      scope.SelectAllItem = SelectAllItem;

      function SelectItem(obj, key) {
        const tmp = [];
        scope.itemArr &&
          Object.keys(scope.itemArr).forEach(x => {
            if (scope.itemArr[x].ticked) {
              tmp.push(scope.itemArr[x].name);
            }
          });
        obj[key] = tmp.join(', ');
        $rootScope.$broadcast(
          'UPDATE_FIELD_DETAIL',
          scope.objectSetType,
          obj,
          key,
          null,
          scope.setSaveType
        );
      }

      scope.SelectItem = SelectItem;

      function DeselectItem(obj, key) {
        obj[key] = '';
        $rootScope.$broadcast(
          'UPDATE_FIELD_DETAIL',
          scope.objectSetType,
          obj,
          key,
          null,
          scope.setSaveType
        );
      }

      scope.DeselectItem = DeselectItem;
    },
  };
  return directive;
});
