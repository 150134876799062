import { adviserAssistantsBuilderForUI } from 'Common/mappers/options';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class AssistantAdviserSelectCtrl {
  constructor(
    optionsService,
    userService,
    utilitiesService,
    currentUserService
  ) {
    'ngInject';

    this.optionsService = optionsService;
    this.userService = userService;
    this.utilitiesService = utilitiesService;
    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.onLoadDeclareConstants();
    this.displayMode = this.DISPLAY_MODES.BUTTON_VIEW;
    this.assistantModel = {};
    this.onLoadGetUserInfo();
  }

  onLoadDeclareConstants() {
    this.DISPLAY_MODES = {
      BUTTON_VIEW: 1,
      LABEL_VIEW: 2,
      SELECT_VIEW: 3,
    };

    this.ASSISTANT_ACCESS_TYPES = [
      ACCESS_TYPE.ADMIN_ASSISTANT,
      ACCESS_TYPE.ASSISTANT,
    ];
  }

  onLoadGetUserInfo() {
    const { accessType, familyId: userId } = this.currentUserService;

    this.displayMode =
      this.ASSISTANT_ACCESS_TYPES.indexOf(accessType) === -1
        ? this.DISPLAY_MODES.BUTTON_VIEW
        : this.DISPLAY_MODES.LABEL_VIEW;
    this.showChangeLink &&
      this.changeDisplayMode(this.DISPLAY_MODES.LABEL_VIEW);

    this.onLoadGetAssistantList(userId);
  }

  onLoadGetAssistantList(userId) {
    this.optionsService
      .adviserAssistantsGet(userId)
      .then(response => {
        if (!response || !response.data || !response.data.length) return;
        this.assistantList = response.data.map(obj =>
          adviserAssistantsBuilderForUI(obj)
        );
      })
      .then(() => {
        this.onLoadGetAssignedAssistant();
      });
  }

  onLoadGetAssignedAssistant() {
    if (!this.assistantId) {
      this.assistantModel = {
        assistantName: 'None',
        assistantId: 0,
      };
      this.getAssistantInitials();
      return;
    }
    this.assistantModel = this.assistantList.find(
      obj => obj.assistantId === this.assistantId
    );
    this.getAssistantInitials();
  }

  changeDisplayMode(newDisplayMode) {
    this.displayMode = newDisplayMode;
    newDisplayMode === this.DISPLAY_MODES.BUTTON_VIEW && this.resetAssistant();
  }

  onClickChangeAssistant() {
    if (!this.assistantModel.assistantId) {
      this.changeDisplayMode(this.DISPLAY_MODES.LABEL_VIEW);
      return;
    }
    this.onAssistantChange({
      $e: { familyId: this.assistantModel.assistantId },
    });
    this.getAssistantInitials();
    this.changeDisplayMode(this.DISPLAY_MODES.LABEL_VIEW);
  }

  resetAssistant() {
    this.assistantModel = {};
    this.onAssistantChange({ $e: { familyId: 0 } });
  }

  getAssistantInitials() {
    this.assistantInitials = this.utilitiesService.filterInitialOneString(
      this.assistantModel.assistantName
    );
  }
}
