import angular from 'angular';
import _ from 'lodash';
import moment from 'angular-moment';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import { PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular
  .module('app')
  .controller('AddPersonModalCtrl', function AddPersonModalCtrl(
    $scope,
    $uibModalInstance,
    utilitiesService,
    toaster,
    contactService,
    selectedPartyType,
    person,
    loanScenarioService,
    $state,
    $rootScope,
    broadcastDataService,
    applicationSharedService,
    requester,
    allocatedAdviserList,
    assistantAdvisersList,
    AssignedAdviserId,
    requesterLabel,
    genderService,
    $q,
    contactSharedDataService,
    loanAppDetailsService,
    userFamilyId,
    lendingCategoryId
  ) {
    // Controller code is based on loan app (with editing feature supported)
    $scope.allocatedAdviserList = allocatedAdviserList;
    $scope.assistantAdvisersList = assistantAdvisersList;
    $scope.AssignedAdviserId = AssignedAdviserId;
    $scope.applicantLabel = requesterLabel;
    $scope.showAllocatedAdviser = () => {
      const vm = $scope;
      return (
        vm.getAdviserIfEditLoaded ||
        (vm.advisersList && vm.advisersList.length > 0) ||
        (vm.brokersList && vm.brokersList.length > 0) ||
        (vm.assistantAdvisersList && vm.assistantAdvisersList.length > 0) ||
        parseInt(vm.accessType, 10) === ACCESS_TYPE.CORPORATE
      );
    };

    // models
    angular.extend($scope, {
      partyApplicants: [],
      Settings: {
        MaritalStatusAdded: false,
      },
    });

    $scope.ContactSet = {};
    const countryCode = applicationSharedService.countryCodeAndBirthday(
      $rootScope.crmCountry
    );
    const countryBirth = applicationSharedService.countryCodeAndBirthday(
      $rootScope.crmCountry
    );
    // Default
    $scope.saveText = requesterLabel;
    $scope.Settings.SelectedParty = 'Applicants';
    if (person) {
      // We need to know which of the family members are included in the loan app
      // Task 1: We need to know what family object to use
      $scope.Settings.isEdit = true;
      let loanAppFamilyCon = [];
      _.forEach($scope.listApplicants, family => {
        if (_.isEqual(_.first(family).FamilyId, person.FamilyId)) {
          // take a copy of the family object
          loanAppFamilyCon = angular.copy(family);
        }
      });

      contactService.clientInformGet(person.FamilyId).then(family => {
        let ApplicantNumber = 1;
        _.forEach(family.data, member => {
          if (member.Role === 'Adult') {
            contactService
              .clientInformGet(person.FamilyId, member.PersonId)
              .then(response => {
                const ContactSet = $scope.getNewContactModel('Adult');
                if (
                  ContactSet.customerConsentOptions.ConsentMarketing !== true
                ) {
                  ContactSet.customerConsentOptions.ConsentMarketing = false;
                }
                angular.extend(ContactSet, response.data[0]);
                if (_.size(ContactSet.Email) > 0) {
                  ContactSet.EmailAddress = ContactSet.Email[0].EmailAddress;
                }
                if (_.isUndefined(ContactSet.IsResident)) {
                  ContactSet.IsResident = false;
                }
                // find country codes
                let CitizenshipObj = _.find($scope.countryList, o => {
                  return (
                    parseInt(o.CountryID, 10) ===
                    parseInt(ContactSet.CitizenshipId, 10)
                  );
                });
                let CountryOfResidencyObj = _.find($scope.countryList, o => {
                  return (
                    parseInt(o.CountryID, 10) ===
                    parseInt(ContactSet.CountryOfResidencyId, 10)
                  );
                });
                let CountryOfBirthObj = _.find($scope.countryList, o => {
                  return (
                    parseInt(o.CountryID, 10) ===
                    parseInt(ContactSet.CountryOfBirthId, 10)
                  );
                });
                if (!CitizenshipObj) {
                  CitizenshipObj = {
                    CountryCode: '',
                  };
                }
                if (!CountryOfResidencyObj) {
                  CountryOfResidencyObj = {
                    CountryCode: '',
                  };
                }
                if (!CountryOfBirthObj) {
                  CountryOfBirthObj = {
                    CountryCode: '',
                  };
                }
                // Task 2: If the member is included in the loanAppFamilyCon put isIncluded property = true, else false
                const familyMember = _.find(loanAppFamilyCon, mem => {
                  return mem.PersonId === ContactSet.PersonId;
                });
                angular.extend(ContactSet, {
                  FamilyId: person.FamilyId,
                  CountryCode: CitizenshipObj.CountryCode,
                  ResidencyCountryCode: CountryOfResidencyObj.CountryCode,
                  CountryOfBirth: CountryOfBirthObj.CountryCode,
                  ResidencyId: ContactSet.ResidencyId.toString(),
                  MaritalStatusId: ContactSet.MaritalStatusId.toString(),
                  DOBGet: moment(ContactSet.DOB).format('DD/MM/YYYY'),
                  // DOB: utilitiesService.formatDate(ContactSet.DOB, true),
                  PartyType: selectedPartyType,
                  ApplicantNumber: ApplicantNumber++,
                  PreferredContactMethodId: ContactSet.PreferredContactMethodId.toString(),
                  isIncluded: !!familyMember, // to be used on tickbox
                  isExisting: !!familyMember, // to be used on knowing if it's currently existing
                });
                // Push it
                $scope.partyApplicants.push(ContactSet);
                // set contact set for selected person
                if (
                  parseInt(member.PersonId, 10) ===
                  parseInt(person.PersonId, 10)
                ) {
                  $scope.ContactSet = ContactSet;
                }
              });
          }
        });
      });
    } else {
      $scope.Settings.isEdit = false;
      $scope.ContactSet = contactSharedDataService.getContactDataModel(
        'Adult',
        countryCode,
        countryBirth
      );
      $scope.ContactSet.PartyType = selectedPartyType;
      $scope.ContactSet.ApplicantNumber = 1;
      $scope.ContactSet.customerConsentOptions = { ConsentMarketing: true };
      $scope.partyApplicants.push($scope.ContactSet);
    }

    $scope.titles = [
      { Value: 'Mr', Name: 'Mr' },
      { Value: 'Mrs', Name: 'Mrs' },
      { Value: 'Ms', Name: 'Ms' },
      { Value: 'Miss', Name: 'Miss' },
      { Value: 'Master', Name: 'Master' },
      { Value: 'Doctor', Name: 'Doctor' },
    ];

    $scope.$watch('ContactSet.Title', newVal => {
      genderService.changeGender(newVal, $scope.ContactSet.Gender);
    });

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.datePopUp = {
      DOB: false,
    };
    // Open Date Popup
    $scope.openDate = function(dateField) {
      $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
    };

    $scope.format = 'dd MMM yyyy';
    $scope.altInputFormats = ['M!/d!/yyyy'];
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };

    // methods
    angular.extend($scope, {
      setMaritalStatus(bool) {
        $scope.Settings.MaritalStatusAdded = bool;
      },
      showApplicantPopover(bool) {
        $scope.Settings.isShowApplicantPopover = bool;
        if (!bool) {
          $scope.Settings.isShowApplicantDeletePopover = true;
        }
      },
      showApplicantDeletePopover(bool) {
        $scope.Settings.isShowApplicantDeletePopover = bool;
      },
      addPhone() {
        $scope.ContactSet.Phone.push({
          Type: 'Mobile',
          Number: '',
        });
      },
      removeContact() {
        /* Remove Party Applicant if $scope.ContactSet.MaritalStatusId === 2 && partyApplicant > 1 */
        if (_.size($scope.partyApplicants) > 1) {
          $scope.partyApplicants.pop();
        }
      },
      addContact(type, isMarried) {
        switch (type) {
          case 'Applicant':
            if (_.size($scope.partyApplicants) < 2) {
              if (!isMarried) {
                $scope.ContactSet = contactSharedDataService.getContactDataModel(
                  'Adult',
                  countryCode,
                  countryBirth
                );
                $scope.ContactSet.PartyType = '1';
                $scope.ContactSet.ApplicantNumber =
                  _.size($scope.partyApplicants) + 1;
                $scope.ContactSet.customerConsentOptions = {
                  ConsentMarketing: true,
                };
                if ($scope.Settings.isEdit) {
                  $scope.ContactSet.isIncluded = true;
                }

                $scope.partyApplicants.push($scope.ContactSet);
              } else {
                const ContactSet = $scope.getNewContactModel('Adult');
                ContactSet.PartyType = '1';
                ContactSet.ApplicantNumber = _.size($scope.partyApplicants) + 1;
                ContactSet.MaritalStatusId = '2';
                ContactSet.customerConsentOptions = { ConsentMarketing: true };

                if ($scope.Settings.isEdit) {
                  ContactSet.isIncluded = true;
                }

                $scope.partyApplicants.push(ContactSet);
                $scope.setMaritalStatus(true);
              }
              $scope.Settings.SelectedParty = 'Applicants';
            }
            break;
          default:
            break;
        }
      },
      selectContact(party) {
        $scope.searchContactText = party.FamilyFullName;
        $scope.selectedFamily = party;
        $scope.showContactList = false;

        if (party.FirstName === '' || !party.FirstName) {
          party.errFirstName = true;
        } else {
          party.errFirstName = false;
        }
        if (party.LastName === '' || !party.LastName) {
          party.errLastName = true;
        } else {
          party.errLastName = false;
        }
        const tempAccessType = parseInt($scope.accessType, 10);
        const isAdviserNotEmpty = _.isEmpty(
          $scope.allocatedAdviser.adviserSelected.FullName.description
        );
        if (tempAccessType === 5) {
          if (isAdviserNotEmpty) {
            party.errAdviser = true;
          } else party.errAdviser = false;
        }
        switch (party.PartyType) {
          case '1':
            $scope.setMaritalStatus(false);
            $scope.Settings.SelectedParty = 'Applicants';
            $scope.ContactSet = party;
            break;
          default:
            break;
        }
      },
      sampleChange(id) {
        $scope.AssignedAdviserId = id;
      },
      removeSecondApplicant() {
        $scope.partyApplicants.splice(1, 1);
        $scope.selectContact(_.first($scope.partyApplicants));
      },
      checkMaritalStatus() {
        // NOTE: Married status = 3; make sure the number of applicants is still = 1
        if (
          parseInt($scope.ContactSet.MaritalStatusId, 10) === 2 &&
          _.size($scope.partyApplicants) === 1
        ) {
          $scope.setMaritalStatus(true);
          $scope.isMarried = true;
        } else {
          $scope.setMaritalStatus(false);
        }
        if (!_.isUndefined($scope.isMarried))
          if (
            $scope.isMarried === true &&
            parseInt($scope.ContactSet.MaritalStatusId, 10) !== 2 &&
            _.size($scope.partyApplicants) > 1
          ) {
            $scope.removeContact('Applicant');
            $scope.removeSecondApplicant();
          }
      },
      saveClientAccount() {
        // save applicants
        const partyApplicants = angular.copy($scope.partyApplicants);
        const {
          excludedApplicants,
          excludedIds,
        } = applicationSharedService.getExcludedApplicants(partyApplicants);

        // trap ids, we cannot exclude 2 members (this means no adult is selected)
        if (_.size(excludedApplicants) > 1) {
          toaster.pop('error', 'Error Saving', 'Cannot save empty members');
          return false;
        }
        if (excludedApplicants && excludedApplicants.length) {
          // find if the person is currently existing
          if (excludedApplicants[0].isExisting) {
            $scope.deleteClient(
              {
                PersonId: excludedApplicants[0].PersonId,
                IsApplicant: true,
                Role: '1',
              },
              true
            );
          }
        }

        // Map the included parties
        _.map(partyApplicants, party => {
          party.Email = [{ Type: 'Email', EmailAddress: party.EmailAddress }];
          party.DOB = utilitiesService.formatDate(party.DOBGet, true);

          if (parseInt(party.CountryCode, 10) === -1) {
            party.CountryOfBirth = party.CitizenshipCountryManual;
          } else {
            party.CountryOfBirth = party.CountryCode;
          }

          let CitizenshipObj = _.find(
            $scope.countryList,
            o => o.CountryCode === party.CountryCode
          );
          let CountryOfResidencyObj = _.find(
            $scope.countryList,
            o => o.CountryCode === party.ResidencyCountryCode
          );
          let CountryOfBirthObj = _.find(
            $scope.countryList,
            o => o.CountryCode === party.CountryOfBirth
          );
          if (!CitizenshipObj) {
            CitizenshipObj = {
              CountryID: 0,
            };
          }
          if (!CountryOfResidencyObj) {
            CountryOfResidencyObj = {
              CountryID: 0,
            };
          }
          if (!CountryOfBirthObj) {
            CountryOfBirthObj = {
              CountryID: 0,
            };
          }
          let adviserId = $scope.AssignedAdviserId;
          if (parseInt($scope.accessType, 10) === ACCESS_TYPE.CORPORATE) {
            const allocatedAdviser =
              $scope.allocatedAdviser.adviserSelected.FullName;
            if (allocatedAdviser && allocatedAdviser.description) {
              adviserId = allocatedAdviser.description.FamilyId;
            }
          }
          party.AllocatedAdviserID = adviserId;

          angular.extend(party, {
            CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
            CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
            CountryOfBirthId: parseInt(CountryOfBirthObj.CountryID, 10),
            ResidencyId: parseInt(party.ResidencyId, 10),
          });
        });

        contactService
          .addContact(partyApplicants)
          .then(response => {
            const FamilyId = response.data;

            if (requester === REQUESTER.NEW_LOAN_APP_MODAL) {
              $uibModalInstance.close({ FamilyId });
            } else {
              $uibModalInstance.close({
                FamilyId,
                PartyType: '1',
                excludedIds,
              });
              if (parseInt($scope.accessType, 10) === ACCESS_TYPE.CORPORATE) {
                $scope.AssignedAdviserId =
                  $scope.allocatedAdviser.adviserSelected.FullName.description.FamilyId;
              }

              return FamilyId;
            }
          })
          .then(FamilyId => {
            contactService.putAssignAdviser(FamilyId, $scope.AssignedAdviserId);

            return FamilyId;
          })
          .then(FamilyId => {
            const defer = $q.defer();
            const postData = {
              LoanScenarioId: 0,
              FamilyId,
              AdviserId: $scope.AssignedAdviserId,
              LendingCategoryId: lendingCategoryId,
            };

            loanScenarioService.scenarioSet(postData).then(
              res => {
                defer.resolve({ FamilyId, loanAppId: res.data });
              },
              () => {
                defer.reject({});
              }
            );

            return defer.promise;
          })
          .then(({ FamilyId, loanAppId }) => {
            let adviserFamilyId = userFamilyId;
            const adviserAccessList = [
              ACCESS_TYPE.ADVISER,
              ACCESS_TYPE.PRINCIPAL_ADVISER,
            ];
            if (adviserAccessList.indexOf($scope.accessType) === -1) {
              adviserFamilyId = $scope.AssignedAdviserId;
            }
            loanAppDetailsService.setInsuranceDefault(
              loanAppId,
              FamilyId,
              adviserFamilyId
            );
            $state.go('app.loanApplicationDetailsV3', {
              familyId: FamilyId,
              loanAppId,
            });
            $rootScope.$on('$stateChangeSuccess', () => {
              broadcastDataService.InlinePreloader = {
                preLoaderText: '',
                preLoaderSize: '',
                showPreLoader: false,
              };
            });
            toaster.pop(
              'success',
              'Successful!',
              'Contact has been assigned to an adviser.'
            );
            toaster.pop(
              'success',
              'Added!',
              'Contact has been Added Successfully.'
            );
            $scope.dismissModal();
          })
          .catch(() => {
            toaster.pop('error', 'Error!', 'Oooops, something went wrong.');
          });
      },

      saveAccount(form) {
        let errKey = 0;
        const boolChecker = true;
        _.forEach($scope.partyApplicants, (value, key) => {
          if (value.isIncluded) {
            if (!value.FirstName || value.FirstName === '') {
              form.$invalid = true;
              errKey = key;
            } else if (!value.LastName || value.LastName === '') {
              form.$invalid = true;
              errKey = key;
            }
          }
          if (
            parseInt($scope.accessType, 10) === PERSON_PARTY_TYPES.SOLICITOR &&
            _.isEmpty(
              $scope.allocatedAdviser.adviserSelected.FullName.description
            )
          ) {
            form.$invalid = true;
            errKey = key;
          }
        });

        if (form.$invalid) {
          const partyApplicants = $scope.partyApplicants[errKey];
          $scope.selectContact(partyApplicants);
        } else {
          $scope.$watch('submitClicked', () => {
            $scope.submitClicked = boolChecker;
          });
          $scope.saveClientAccount();
          $scope.submitClicked = false;
        }
      },
    });
  });
