import template from './linkedPercentageField.html';
import controller from './linkedPercentageFieldCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fieldLabel: '@',
    baseAmount: '<',
    initAmountValue: '<',
    maxPercentage: '<',
    minPercentage: '<',
    onValuesChange: '&',
  },
};
