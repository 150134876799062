import { isFunction } from 'lodash';
import {
  quoteDataBuilderForUI,
  quoteDataBuilderForAPI,
  quoteDataPeopleEntityBuilderForAPI,
} from 'Common/mappers/insurance';
import { launchInsuranceQuoteReportModal } from 'Common/utilities/insurance';
import { toastError } from 'Common/utilities/alert';

class InsurerPremiumComparisonCtrl {
  constructor($uibModal, insuranceQuoteService, toaster) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.insuranceQuoteService = insuranceQuoteService;
    this.toaster = toaster;
  }

  $onInit() {
    this.initializeChart();
    if (isFunction(this.onInsurerPremiumComparisonInit)) {
      const api = {
        refreshPremiumComparison: () => this.refreshPremiumComparison(),
      };
      this.onInsurerPremiumComparisonInit({ api });
    }
  }

  initializeChart() {
    this.chart = { isLoading: true };
    this.initializeGraphColors().then(() => {
      this.initializeChartData();
    });
  }

  initializeGraphColors() {
    return this.insuranceQuoteService
      .insuranceQuoteBenefitColorGet()
      .then(response => {
        this.graphColors = response;
      })
      .catch(() => {
        toastError({
          data: {
            Message: 'Unable to load the graph at the moment.',
          },
        });
        this.chart.isLoading = false;
      });
  }

  initializeChartData() {
    this.insuranceQuoteService
      .insuranceQuoteMonsterGet(quoteDataBuilderForAPI(this.quoteData))
      .then(
        response => {
          this.insuranceQuote = response.data && response.data.ProviderResult;
        },
        () => {
          this.toaster.pop('error', 'Error', 'Unable to load the chart data.');
        }
      )
      .finally(() => {
        this.chart.isLoading = false;
      });
  }

  generatePDFReport() {
    const { quoteId, familyId, insuredPersons: peopleEntity } = this.quoteData;
    const reportData = {
      quoteId,
      familyId,
      peopleEntity: quoteDataPeopleEntityBuilderForAPI(peopleEntity),
      insurersList: this.insuranceQuote,
    };
    launchInsuranceQuoteReportModal(this.$uibModal, reportData);
  }

  refreshPremiumComparison() {
    this.chart.isLoading = true;
    this.initializeChartData();
  }

  onQuoteUpdated(updatedQuoteData, updatedGraphData) {
    this.quoteData = quoteDataBuilderForUI(updatedQuoteData);
    this.insuranceQuote = updatedGraphData && updatedGraphData.ProviderResult;
    this.chart.isLoading = false;

    if (isFunction(this.onQuoteDataUpdated)) {
      this.onQuoteDataUpdated({ quoteData: this.quoteData });
    }
  }
}

export default InsurerPremiumComparisonCtrl;
