import './style.scss';
import template from './usersSubscription.html';
import UsersSubscriptionCtrl from './usersSubscriptionCtrl';

export default {
  template,
  bindings: {
    adviserInfo: '<',
    orderedById: '<',
    subscriptionEnabled: '=',
  },
  controller: UsersSubscriptionCtrl,
  controllerAs: 'vm',
};
