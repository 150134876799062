import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';

export function pipelineApplicationTableMap(row, $filter) {
  const {
    clientName,
    lender,
    loanAmount,
    pipelineStatus,
    advisorName,
    enquirySource,
    referrerName,
    lastModifiedDate,
    labels,
    loanID,
    loanScenarioID,
    loanStatusID,
    lenderID,
    country,
    pipelineCardsID,
    loanDescription,
    adviserProfilePicture,
    settlementDate,
    isEstimated,
  } = row;

  return {
    clientName,
    lender,
    loanAmount: $filter('currency')(loanAmount, '$', 2),
    loanDescription: loanDescription ? loanDescription.toUpperCase() : '',
    pipelineStatus,
    enquirySource: referrerName || enquirySource,
    advisorName,
    settlementDate,
    lastModifiedDate,
    labels,
    labelsList: pipelineLabelBuilder(labels),
    loanID,
    loanScenarioID,
    loanStatusID,
    lenderID,
    country,
    pipelineCardsID,
    adviserProfilePicture,
    isEstimated,
  };
}
