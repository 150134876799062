import angular from 'angular';

angular
  .module('app')
  .directive('interestRateUpdatesTable', function interestRateUpdatesTable(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/interestRateUpdatesTable.html',

      link(scope) {
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };
        scope.interestRateUpdateTableData = [];
        function interestRateUpdateTableGet() {
          overviewDashboardService.InterestRateUpdateTableGet().then(
            response => {
              scope.interestRateUpdateTableData = [];

              const totalData = response.data.length;
              const round = Math.floor(totalData / 5) * 5 - 1;
              const modStart = Math.floor(totalData / 5) * 5;
              const mod = Math.floor(totalData % 5);
              for (let i = 0; i < round; i += 5) {
                const testData = [];
                testData.push(response.data[i]);
                testData.push(response.data[i + 1]);
                testData.push(response.data[i + 2]);
                testData.push(response.data[i + 3]);
                testData.push(response.data[i + 4]);
                scope.interestRateUpdateTableData.push(testData);
              }

              if (parseInt(mod, 10) !== 0) {
                const lastItem = [];
                for (let i = modStart; i < totalData; i++) {
                  lastItem.push(response.data[i]);
                  // lastItem.push({date:"19 Jul 2016",imgSrc:imgSrcArr[Math.floor(Math.random()*(5))]});
                }
                scope.interestRateUpdateTableData.push(lastItem);
              }

              scope.dataLoaded = true;

              if (scope.interestRateUpdateTableData.length > 0) {
                $timeout(() => {
                  try {
                    scope.slickConfig.method.slickGoTo(0);
                  } catch (error) {
                    // Continue regardless of error
                  }
                }, 1000);
              }
            },
            () => {}
          );
        }
        function init() {
          interestRateUpdateTableGet();
        }
        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'interestRateUpdatesPopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') scope.customizeMode = true;
          else scope.customizeMode = false;
        };

        init();
      },
    };
  });
