import template from './pipelineApplicationThreeDotMenu.html';
import './style.scss';

export default {
  template,
  bindings: {
    isLockLoanappFeatureEnabled: '<',
    card: '<',
    isTableView: '<',
    onMenuClick: '&',
    onViewBorrower: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onViewSubmission: '&',
    onToggleIsShrink: '&',
    onCardClick: '&',
    onViewLoanInformation: '&',
    onDeletePipelineItem: '&',
  },
};
