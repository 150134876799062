import angular from 'angular';
import $ from 'jquery';

angular
  .module('app')
  .controller('UserPermissionsCtrl', function UserPermissionsCtrl(
    $scope,
    $timeout,
    $uibModal,
    userService,
    $stateParams,
    $window,
    $location,
    toaster
  ) {
    userService.UserPermissionListGet(0).then(response => {
      $scope.users = response.data;
      $scope.AdminFamilyId = $scope.users[0].AdminFamilyId;

      $timeout(() => {
        $('.user-box .panel').matchHeight();
      }, 500);
    });

    $scope.UserPermissionSet = function(info) {
      const data = [
        {
          AdminFamilyId: info.AdminFamilyId,
          AdviserFamilyId: info.AdviserFamilyId,
          Permission: info.Permission,
        },
      ];
      userService.UserPermissionSet(data).then(response => {
        if (parseInt(response.data, 10) === 1) {
          toaster.pop('success', 'User Updated', 'User info has been updated');
        }
      });
    };

    $scope.permissions = [
      {
        value: 'User',
        name: 'User',
      },
      {
        value: 'CompanyAdministrator',
        name: 'Company Admin',
      },
    ];

    $scope.openEditUserPermission = function(user) {
      userService.UserPermissionListGet(user.AdviserFamilyId).then(response => {
        $scope.editUser = user;
        $scope.usersListPermissions = response.data;
      });

      $uibModal.open({
        templateUrl: '/assets/views/user/partials/edit_permission_modal.html',
        controller: 'EditPermissionCtrl',
        size: 'md',
        windowClass: 'user-permission-window',
        scope: $scope,
      });
    };

    $scope.openAddUserPermission = function() {
      $scope.addUser = [];
      $uibModal.open({
        templateUrl: '/assets/views/user/partials/add_permission_modal.html',
        controller: 'AddUserPermissionCtrl',
        windowClass: 'user-permission-window',
        size: 'md',
        scope: $scope,
      });
    };
  });
