class GroupOptionCtrl {
  toggleEditItem(primaryPropertyValue, $event) {
    $event.stopPropagation();
    const item =
      this.list.find(
        obj => obj[this.primaryProperty] === primaryPropertyValue
      ) || {};
    item.isEdit = !item.isEdit;

    if (!item.isEdit) {
      this.onToggleItem({ data: item, sourceIsTick: false });
    }
  }

  toggleSelectItem(primaryPropertyValue) {
    const item =
      this.list.find(
        obj => obj[this.primaryProperty] === primaryPropertyValue
      ) || {};
    if (item.isEdit) return;
    item[this.tickProperty] = !item[this.tickProperty];
    this.onToggleItem({ data: item, sourceIsTick: true });
  }
}

export default GroupOptionCtrl;
