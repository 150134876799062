import template from './opportunityMenu.html';

export default {
  template,
  bindings: {
    card: '<',
    onViewOpportunity: '&',
    onCardClick: '&',
    onDeletePipelineItem: '&',
    onViewBorrower: '&',
    isShowTimeline: '<',
    conversionOptions: '<',
    onConvertCard: '&',
    isCorporateUser: '<',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    hideManageLabels: '<',
  },
};
