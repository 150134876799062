import angular from 'angular';
import _ from 'lodash';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular
  .module('app')
  .controller('SatisfactionSurveyCtrl', function SatisfactionSurveyCtrl(
    $scope,
    $window,
    $filter,
    $uibModal,
    $timeout,
    $localStorage,
    $rootScope,
    NgTableParams,
    corporateService,
    toaster,
    npsService,
    DEMO_VIDEO,
    userService
  ) {
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'OpenModalForVideoCtrl',
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    $scope.surveyModel = {};
    $scope.surveyModel.noResponse = false;

    $scope.isCorporateUser = false;
    $scope.isPrincipalAdviser = false;
    $scope.isAdviser = false;
    const getTableList = () => {
      $scope.tableParams = new NgTableParams(
        {
          page: 1,
          count: 10, // count per page
          sorting: {
            ResponseReceived: 'desc',
          },
        },
        {
          total: 0, // length of data
          getData($defer, params) {
            $scope.surveyModel.isLoading = true;
            const isSelectedUser = $scope.surveyModel.selectedAdviser > 0;
            const noResponse = $scope.surveyModel.noResponse ? 1 : 0;
            const timePeriod = $scope.surveyModel.timePeriod.OptionDB_value;
            const pageNumber = params.page();
            const countPerPage = params.count();
            const selectedUser = $scope.surveyModel.selectedAdviser;
            const searchText = $scope.surveyModel.searchSurvey;
            const orderBy = params.sorting();
            const orderByKeys = Object.keys(orderBy);
            const sortBy = orderByKeys.length
              ? orderByKeys[0]
              : 'ResponseReceived';
            const sortOrder = (orderBy && orderBy[sortBy]) || 'desc';
            npsService
              .getSurveyResponse(
                timePeriod,
                pageNumber,
                countPerPage,
                noResponse,
                selectedUser,
                isSelectedUser,
                0,
                searchText,
                sortBy,
                sortOrder
              )
              .then(response => {
                if (response && response.data) {
                  const data = response.data.SurveyResponses || [];
                  params.total(response.data.SurveyCount);
                  $scope.surveyModel.npsScore = response.data.NpsScore;
                  $scope.surveyModel.promoters = response.data.Promoters;
                  $scope.surveyModel.passives = response.data.Passives;
                  $scope.surveyModel.detractors = response.data.Detractors;
                  $defer.resolve(data);
                  $scope.surveyModel.isLoading = false;
                }
              });
          },
        }
      );
    };
    const getAdviserList = () => {
      corporateService.activeLoanWritersGet().then(response => {
        let data = _.filter(response.data, item => {
          return item.FullName !== '';
        });
        if ($scope.isAdviser) {
          $scope.surveyModel.selectedAdviser = $scope.surveyModel.userFamilyId;
        } else {
          const obj = {
            FamilyId: 0,
            FullName: 'All Adviser',
          };
          data = [obj, ...data];
          $scope.surveyModel.selectedAdviser = 0;
        }
        $scope.surveyModel.adviserList = data;
      });
    };
    const getUserInfo = () => {
      userService.GetUserInfo().then(response => {
        const data = response.data;
        if (data) {
          $scope.isCorporateUser = data.AccessType === ACCESS_TYPE.CORPORATE;
          $scope.isPrincipalAdviser =
            data.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER;
          $scope.isAdviser = data.AccessType === ACCESS_TYPE.ADVISER;
          getAdviserList();
          getTableList();
          $scope.surveyModel.userFamilyId = data.FamilyId;

          if ($scope.timePeriod && $scope.timePeriod.length) {
            $scope.surveyModel.timePeriod = $scope.timePeriod[0];
          }
        }
      });
    };
    getUserInfo();

    $scope.surveyTableModel = [];

    $scope.timePeriod = [
      {
        OptionID: 15,
        OptionDisplayName: 'All',
        OptionDB_value: '0',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'This Year',
        OptionDB_value: '1',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'This Month',
        OptionDB_value: '2',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'Last Month',
        OptionDB_value: '3',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'Last 2 Months',
        OptionDB_value: '4',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'Last 3 Months',
        OptionDB_value: '5',
        Option_ValueID: 41,
      },
      {
        OptionID: 15,
        OptionDisplayName: 'Last Year',
        OptionDB_value: '6',
        Option_ValueID: 41,
      },
    ];

    $scope.timePeriodChange = function() {
      getTableList();
    };

    $scope.isSurveyEdit = [];
    $scope.editSurvey = function(id) {
      $scope.isSurveyEdit[id] = !$scope.isSurveyEdit[id];
    };

    $scope.tickNoResponse = function() {
      getTableList();
    };

    $scope.resendSurvey = function(size, data) {
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/communicate/modal/resendSurveyModal.html',
        controller: 'ResendSurveyModalCtrl',
        controllerAs: 'vm',
        size,
        windowClass: '',
        resolve: {
          surveyData: () => data,
        },
      });
      modalInstance.result.then(() => {
        getTableList();
      });
    };
    $scope.searchSurvey = () => {
      getTableList();
    };
  });
