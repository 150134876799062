import { shortenAmount } from 'Common/utilities/currency';

class CompanyOwnersCtrl {
  $onInit() {
    this.formatShares = shortenAmount;
  }

  addNewDirector() {
    this.entityData.Directors = [
      ...(this.entityData.Directors || []),
      { Director: '', Shares: null, IsEditMode: true },
    ];
  }

  editDirector(directorIndex, director) {
    this.updateDirectorInList(directorIndex, {
      ...director,
      prevState: { ...director },
      IsEditMode: true,
    });
  }

  saveDirector(directorIndex, director) {
    this.updateDirectorInList(directorIndex, {
      ...director,
      IsEditMode: false,
    });
  }

  updateDirectorInList(directorIndex, director) {
    if (!this.entityData.Directors) return;
    this.entityData.Directors = [
      ...this.entityData.Directors.slice(0, directorIndex),
      director,
      ...this.entityData.Directors.slice(directorIndex + 1),
    ];
  }

  deleteDirector(directorIndex) {
    if (!this.entityData.Directors) return;
    this.entityData.Directors = [
      ...this.entityData.Directors.slice(0, directorIndex),
      ...this.entityData.Directors.slice(directorIndex + 1),
    ];
  }
}

export default CompanyOwnersCtrl;
