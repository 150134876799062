import { isFunction } from 'lodash';
import { toastError } from 'Common/utilities/alert';

class InsurerBrochuresCtrl {
  constructor(insuranceQuoteService) {
    'ngInject';

    this.insuranceQuoteService = insuranceQuoteService;
    this.initializeBrochures();
  }

  initializeBrochures() {
    if (isFunction(this.onInsurerBrochuresInit)) {
      const api = {
        displayBrochures: benefitType => this.displayBrochures(benefitType),
      };
      this.onInsurerBrochuresInit({ api });
    }
  }

  displayBrochures(benefitType) {
    this.isLoading = true;
    this.insuranceQuoteService
      .insuranceQuoteBrochureGet(benefitType, this.insurerIds)
      .then(
        response => {
          this.brochures = response;
        },
        () => {
          toastError({
            data: {
              Message: 'Unable to load the brochures at the moment.',
            },
          });
        }
      )
      .finally(() => {
        this.isLoading = false;
      });
  }
}

export default InsurerBrochuresCtrl;
