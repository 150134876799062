import angular from 'angular';
import { forEach } from 'lodash';

angular
  .module('app')
  .controller('InsurerListCtrl', function InsurerListCtrl(
    $scope,
    $uibModalInstance,
    $uibModal,
    toaster,
    $http,
    insurerTempData,
    insurersListTempData,
    $timeout
  ) {
    $scope.insurer = insurerTempData;
    $scope.insurersList = insurersListTempData;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.close = function() {
      $uibModalInstance.dismiss('close');
    };

    $scope.proceedWithSelection = function(value) {
      // set heart selected in $scope.insurersList
      forEach($scope.insurersList, obj => {
        if (obj.ProviderId === value.ProviderId) {
          obj.IsSelected = !obj.IsSelected;

          // show modal for insurer selected
          if (obj.IsSelected) {
            const modalInstance = $uibModal.open({
              templateUrl:
                '/assets/views/insuranceTools/modals/insurerSelectedModal.html',
              windowClass: 'insurersList-modal-window',
              controller() {
                // close modal after 2 seconds
                $timeout(() => {
                  modalInstance.dismiss('close');
                }, 2000);
              },
            });
          }
        }
      });
    };
  });
