import { findIndex } from 'lodash';
import toastr from 'toastr';
import {
  displayError,
  toastError,
  showActionConfirmation,
} from 'Common/utilities/alert';
import { INSURANCE_TYPES } from 'Common/constants/insuranceOptions';
import { COVER_TYPE } from 'Common/constants/coverType';

export default class InsuranceNotesTemplateSelectorCtrl {
  constructor(insuranceProfilerService) {
    'ngInject';

    this.insuranceProfilerService = insuranceProfilerService;
  }

  $onInit() {
    this.newTemplate = {
      benefitTypeId: this.benefitTypeId,
      insuranceType: INSURANCE_TYPES.PROFILER,
    };
    this.templatesInitiallyLoaded = false;
    this.optionLabelType();
  }

  optionLabelType() {
    if (this.benefitTypeId === COVER_TYPE.GENERAL_NOTES) {
      this.OptionLabel = 'Quick Template';
    } else {
      this.OptionLabel = 'Template Options';
    }
  }

  onDropdownToggled(isOpen) {
    if (isOpen) {
      this.loadTemplates();
    }
    this.isLoadingTemplates = isOpen;
  }

  loadTemplates() {
    this.insuranceProfilerService
      .profilerNoteTemplatesGet(this.benefitTypeId)
      .then(response => {
        const [benefitTemplates] = [...response];
        this.templates = benefitTemplates.templates;
        this.templatesInitiallyLoaded = this.templates && this.templates.length;
      })
      .catch(error => this.handleLoadTemplatesError(error))
      .finally(() => {
        this.isLoadingTemplates = false;
      });
  }

  handleLoadTemplatesError(error) {
    if (error.status === 404) {
      this.templates = [];
    } else {
      toastError(error);
    }
  }

  toggleAddTemplate(event) {
    event && event.stopPropagation();
    this.isAddNewTemplateToggled = !this.isAddNewTemplateToggled;
    this.newTemplate = {
      ...this.newTemplate,
      name: '',
      content: '',
    };
  }

  saveNewTemplate(event) {
    event && event.stopPropagation();
    this.isSavingNewTemplate = true;
    this.newTemplate = {
      ...this.newTemplate,
      content: this.contentForUpdate,
    };
    this.insuranceProfilerService
      .profilerNoteTemplateSet(this.newTemplate)
      .then(savedTemplate => {
        this.templates = [...this.templates, savedTemplate];
        toastr.success(
          `${this.newTemplate.name} is successfully added!`,
          'Saved'
        );
        this.toggleAddTemplate();
      })
      .catch(displayError);
  }

  applyCurrentContentTo(template, event) {
    event && event.stopPropagation();

    showActionConfirmation(
      'Update Confirmation',
      `Are you sure you want to update the content of ${
        template.name
      } with the current note?`,
      confirm => {
        if (!confirm) return;
        template.content = this.contentForUpdate;
        this.saveTemplate(template);
      }
    );
  }

  saveTemplate(template) {
    this.insuranceProfilerService
      .profilerNoteTemplateUpdate(template, template.id)
      .then(() => {
        toastr.success(
          'Changes to the template content are successfully saved!',
          'Saved'
        );
      })
      .catch(displayError);
  }

  getTemplateIndex(queriedTemplate) {
    return findIndex(
      this.templates,
      template => template.id === queriedTemplate.id
    );
  }

  setSelectedTemplate(template) {
    this.selectedTemplate = template;
    if (
      this.onTemplateSelected &&
      typeof this.onTemplateSelected === 'function'
    ) {
      this.onTemplateSelected({ template });
    }
  }

  confirmDelete(template, event) {
    event && event.stopPropagation();

    showActionConfirmation(
      'Delete Confirmation',
      `Are you sure you want to delete ${template.name}?`,
      confirm => {
        if (confirm) {
          this.deleteTemplate(template);
        }
      }
    );
  }

  deleteTemplate(template) {
    this.insuranceProfilerService
      .profilerNoteTemplateDelete(template.id)
      .then(() => {
        toastr.success(
          `${template.name} successfully deleted`,
          'Template Deleted'
        );
        const deleteTemplateIndex = this.getTemplateIndex(template);
        this.templates = [
          ...this.templates.slice(0, deleteTemplateIndex),
          ...this.templates.slice(deleteTemplateIndex + 1),
        ];
        if (this.selectedTemplate && this.selectedTemplate.id === template.id) {
          this.selectedTemplate = {};
        }
      })
      .catch(toastError);
  }
}
