import angular from 'angular';

angular
  .module('app')
  .controller('ReferralDetailsModalCtrl', function ReferralDetailsModalCtrl(
    $scope,
    $uibModalInstance,
    toaster,
    referral,
    $state
  ) {
    $scope.referralDetail = referral;
    $scope.viewNotes = function(data) {
      $uibModalInstance.dismiss('ViewNotes');
      if (!data.WithNote) {
        toaster.pop('info', 'Notice', 'No Notes to view.');
      } else {
        $state.go('app.contactsLoans', {
          familyId: data.FamilyID,
          activeTab: 'notes',
        });
      }
    };
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.close = function() {
      $uibModalInstance.dismiss('close');
    };
  });
