export function teamContactDetailBuilderForUI(detail) {
  return {
    detailId: detail.DetailId,
    teamName: detail.TeamName,
    teamDescription: detail.TeamDescription,
    phoneNumber: detail.PhoneNumber,
    email: detail.Email,
    teamUrl: detail.TeamURL,
    createdByFamilyId: detail.CreatedByFamilyId,
    createdDate: detail.CreatedDate,
    modifiedByUserId: detail.ModifiedByUserId,
    lastModifiedDate: detail.LastModifiedDate,
    groupingNumber: detail.GroupingNumber,
    groupingOrderBy: detail.GroupingOrderBy,
    orderBy: detail.OrderBy,
    showImgOnly: detail.ShowImgOnly,
    corpTeamGroupName: detail.CorpTeamGroupName,
    corpTeamGroupDesc: detail.CorpTeamGroupDesc,
    teamContactUrls: detail.TeamContactURLS,
  };
}
