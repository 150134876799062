import '../style.scss';
import template from './subscriptionESignModal.html';
import controller from '../subscriptionModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    familyId: '<',
    subscriptionData: '<',
    orderedById: '<',
  },
};
