import template from './assetInsuranceDetails.html';
import controller from './assetInsuranceDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    familyId: '<',
  },
};
