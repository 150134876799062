class CreditChecksService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'CreditEnquiry';
  }

  getCreditChecks(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/ClientCreditCheck`, params);
  }

  setCreditChecks(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/ClientCreditCheck`, data);
  }
}

export default CreditChecksService;
