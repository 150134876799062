import template from './compareView.html';
import CompareViewCtrl from './compareViewCtrl';

export default {
  template,
  bindings: {
    brokerEventId: '<',
    loanScenarioId: '<',
    loanAppId: '<',
    generalSettings: '<',
    loanStructureWiseSelectedProducts: '=',
    onFavouriteUpdated: '&',
  },
  controller: CompareViewCtrl,
  controllerAs: 'vm',
};
