import template from './statusDropdownApplications.html';
import controller from './statusDropdownApplicationsCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    statusList: '<',
    pipelineStatus: '<',
    onToggleDropdown: '&',
    onSelectStatus: '&',
    onConvertCard: '&',
    pipelineStatusId: '<',
  },
};
