class CorporateTeamService {
  constructor(crmConfirmation) {
    'ngInject';

    this.crmConfirmation = crmConfirmation;
  }
  promptSuccessMessage() {
    return this.crmConfirmation.open({
      type: 'success',
      title: 'Enquiry Sent',
      description: '',
      buttonText: 'Okay got it!',
    });
  }
  getTeamIcon(id) {
    this.iconList = [
      {
        detailId: 1,
        icon: '<icon-agreements></icon-agreements>',
      },
      {
        detailId: 2,
        icon: '<icon-onboarding></icon-onboarding>',
      },
      {
        detailId: 3,
        icon: '<icon-onboarding></icon-onboarding>',
      },
      {
        detailId: 4,
        icon: '<icon-state-team></icon-state-team>',
      },
      {
        detailId: 5,
        icon: '<icon-state-team></icon-state-team>',
      },
      {
        detailId: 6,
        icon: '<icon-state-team></icon-state-team>',
      },
      {
        detailId: 7,
        icon: '<icon-state-team></icon-state-team>',
      },
      {
        detailId: 8,
        icon: '<icon-state-team></icon-state-team>',
      },
      {
        detailId: 9,
        icon: '<icon-commissions></icon-commissions>',
      },
      {
        detailId: 10,
        icon: '<icon-accounts></icon-accounts>',
      },
      {
        detailId: 11,
        icon: '<icon-marketing></icon-marketing>',
      },
      {
        detailId: 12,
        icon: '<icon-compliance></icon-compliance>',
      },
      {
        detailId: 13,
        icon: '<icon-it></icon-it>',
      },
      {
        detailId: 12,
        icon: '<icon-compliance></icon-compliance>',
      },
    ];

    const iconObj = this.iconList.find(icon => icon.detailId === id);
    if (!iconObj) return '';
    return iconObj.icon;
  }
}

export default CorporateTeamService;
