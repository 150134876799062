class OutstandingRequirementsModalCtrl {
  constructor($uibModalInstance, loanId, requirement, clientsList) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.loanId = loanId;
    this.requirement = requirement;
    this.clientsList = clientsList;
  }
}

export default OutstandingRequirementsModalCtrl;
