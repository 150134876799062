import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import BROKER_NOTES_ID from 'Common/constants/loanAppBrokerNotes';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import { ROLE_TYPE } from 'Common/constants/roleType';
import { ageToDOBGet } from 'Common/utilities/dob';
import {
  BORROWER_PARTY_TYPES,
  COMPANY_TYPES,
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import { SEARCH_TYPES } from 'Common/constants/searchType';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';

angular
  .module('app')
  .controller('LoanAppApplicationCtrl', function LoanAppApplicationCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    optionsService,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    colorService,
    $document,
    SweetAlert,
    fundingCalculatorService,
    $q,
    generalService,
    dashboardService,
    financialsSharedService,
    loanAppSharedData,
    applicationSharedService,
    uiService,
    $window,
    contactSharedDataService,
    loanService,
    identificationService,
    configService
  ) {
    let clickCounter = 0;
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    /* end of listeners */

    $scope.uiService = uiService;
    $scope.PERSON_PARTY_TYPES = PERSON_PARTY_TYPES;
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.isAssetFinanceActive =
      configService &&
      configService.feature &&
      configService.feature.assetFinance;

    loanApplicationServices.loanSummaryGet($state.params.loanAppId).then(() => {
      $scope.processApplicantSection = () => {
        if (clickCounter !== 0) return;

        clickCounter++;
        $scope.BROKER_NOTES_ID = BROKER_NOTES_ID;
        $scope.loanAppSharedData = $scope.loanAppSharedData;
        $scope.financialsSharedService = financialsSharedService;

        $scope.loanAppId = $state.params.loanAppId;
        $scope.familyId = $state.params.familyId;
        $scope.listEmploymentHistoryLength = 0;
        $scope.listAddressHistoryLength = 0;
        $scope.listAccountants = [];
        $scope.listGuarantors = [];
        $scope.listSolicitors = [];
        $scope.listAssetSuppliers = [];
        $scope.familyIdentification = {};
        $scope.submitClicked = false; /* if ture submit button will be disable */
        $scope.loanAppLendingCategory =
          $scope.loanAppSharedData &&
          $scope.loanAppSharedData.loanAppSummary &&
          $scope.loanAppSharedData.loanAppSummary.LoanDetails &&
          $scope.loanAppSharedData.loanAppSummary.LoanDetails.LendingCategoryId;
        $scope.showAssetSupplierRelatedParty =
          $scope.isAssetFinanceActive &&
          $scope.loanAppLendingCategory === LENDING_CATEGORY.ASSET_FINANCE;

        $scope.RelatedPartySettings = {
          NoSolicitors: true,
          NoAccountants: true,
        };

        const partyTypeApplicants = '1'; // check
        const partyTypeCompany = '2'; // check
        const partyTypeGuarantors = '3'; // use 1
        const partyTypeDependants = '4'; // use 1
        const partyTypeSolicitor = '5'; // use 3
        const partyTypeAccountant = '6'; // use 3
        const partyTypePartnership = '7';
        $scope.COMPANY_TYPES = COMPANY_TYPES;

        /**
         * Validation for Section
         */
        const runValidation = () => {
          const involvedPartiesValidation =
            _.size($scope.listApplicants) + _.size($scope.listTrustCompanies) >
            0;
          const startedInvolvedPartiesValidation = $scope.countApplicants() > 0;
          const employmentValidation =
            $scope.hasEmployment && $scope.isEmploymentYearsValid;
          const addressHistoryValidation = $scope.listAddressHistoryLength > 0;
          const identificationValidation =
            $scope.familyIdentification &&
            $scope.familyIdentification.Identification &&
            $scope.familyIdentification.Identification.length > 0;
          if (uiService.isCountry('New Zealand')) {
            $scope.isValidApplicants =
              (involvedPartiesValidation &&
                employmentValidation &&
                addressHistoryValidation) ||
              ($scope.loanAppSharedData.entityOnlyApplicant &&
                addressHistoryValidation);
            $scope.isStartedApplicants =
              startedInvolvedPartiesValidation ||
              employmentValidation ||
              addressHistoryValidation;
          } else {
            $scope.isValidApplicants =
              (involvedPartiesValidation &&
                employmentValidation &&
                addressHistoryValidation &&
                identificationValidation) ||
              ($scope.loanAppSharedData.entityOnlyApplicant &&
                addressHistoryValidation);
            $scope.isStartedApplicants =
              startedInvolvedPartiesValidation ||
              employmentValidation ||
              addressHistoryValidation ||
              identificationValidation;
          }
          const sectionObject = $scope.loanAppSharedData.getSectionObjectById(
            $scope.loanAppSharedData.completionListObject,
            SECTION_ID.APPLICANTS
          );
          $scope.countValids($scope.isValidApplicants, 'applicants');
          $scope.loanAppSharedData.completionStatusSet(
            SECTION_ID.APPLICANTS,
            sectionObject.IsCompleted,
            $scope.isValidApplicants
          );
        };

        $scope.validateApplicants = () => {
          if ($scope.clientListLoaded) {
            runValidation();
          } else {
            $scope.getScenarioClientList().then(() => {
              runValidation();
              $scope.clientListLoaded = true;
            });
          }
        };

        const redirectToCorrectFamily = familyId => {
          const newStateParams = {
            ...$state.params,
            familyId,
          };
          $state.go('app.loanApplicationDetailsV3', newStateParams);
        };

        const checkTrustCompany = trustCompany => {
          const { FamilyId: familyId } = trustCompany[0];
          redirectToCorrectFamily(familyId);
        };

        const checkMainApplicant = applicants => {
          const flatListApplicants = _.flattenDeep(applicants);
          const flatListTrustCompanies = $scope.listTrustCompanies;
          if (flatListApplicants.length <= 1 && flatListTrustCompanies.length)
            checkTrustCompany(flatListTrustCompanies);
          if (!flatListApplicants.length) return;
          const { FamilyId: familyId } = flatListApplicants[0];
          const isCurrentFamilyIdPresent = !!flatListApplicants.filter(
            obj =>
              parseInt(obj.FamilyId, 10) ===
              parseInt($state.params.familyId, 10)
          ).length;
          const validForRedirection = !isCurrentFamilyIdPresent && familyId;
          if (validForRedirection) redirectToCorrectFamily(familyId);
        };

        /**
         *  Validates every update on object
         */
        const listApplicantsWatcher = $scope.$watch(
          'listApplicants',
          (nv, ov) => {
            $scope.validateApplicants();
            $scope.loanAppSharedData.listApplicantsUpdated =
              ov && nv && nv.length;
            $scope.loanAppSharedData.listApplicants = nv;
          }
        );

        const updateEmploymentWatcher = $scope.$watch(
          'loanAppSharedData.UPDATE_EMPLOYMENT',
          nv => {
            if (nv && _.size(nv) > 0) {
              $scope.isEmploymentYearsValid = nv.invalidCount < 1;
              $scope.hasEmployment = nv.hasEmployment;
              $scope.validateApplicants();
            } else {
              $scope.validateApplicants();
            }
          },
          true
        );

        const updateAddressWatcher = $scope.$watch(
          'loanAppSharedData.UPDATE_ADDRESS',
          nv => {
            $scope.listAddressHistoryLength = nv && nv.length;
            $scope.validateApplicants();
          },
          true
        );

        const allIdentificationsWatcher = $scope.$watch(
          'allIdentifications',
          () => {
            $scope.validateApplicants();
          },
          true
        );

        $scope.$on('$destroy', () => {
          if (listApplicantsWatcher) listApplicantsWatcher();
          if (updateEmploymentWatcher) updateEmploymentWatcher();
          if (updateAddressWatcher) updateAddressWatcher();
          if (allIdentificationsWatcher) allIdentificationsWatcher();
        });

        $scope.noteListInit = () => {
          loanScenarioService
            .brokerNotesGet($scope.loanAppId)
            .then(response => {
              $scope.assess = {
                borrowers: {},
                guarantors: {},
                dependants: {},
                relatedParties: {},
                employment: {},
                addressHistory: {},
                identification: {},
                Deposits: {},
              };

              angular.forEach(response.data, value => {
                switch (value.SubsectionId) {
                  case BROKER_NOTES_ID.BORROWERS:
                    $scope.assess.borrowers = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.RELATED_PARTIES:
                    $scope.assess.relatedParties = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.EMPLOYMENT:
                    $scope.assess.employment = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.ADDRESS_HISTORY:
                    $scope.assess.addressHistory = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.IDENTIFICATION:
                    $scope.assess.identification = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.DEPOSIT:
                    $scope.assess.Deposits = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.GUARANTORS:
                    $scope.assess.guarantors = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  case BROKER_NOTES_ID.DEPENDANTS:
                    $scope.assess.dependants = {
                      brokerNotes: value.BrokerNotes,
                    };
                    break;
                  default:
                    break;
                }
              });
            });
        };

        $scope.noteListInit();

        $scope.saveBrokerNote = (subsect, content) => {
          loanScenarioService
            .brokerNotesSet($scope.loanAppId, subsect, content)
            .then(() => {
              toaster.pop('info', 'Note in this section was updated.');
            });
        };

        $scope.getNewContactModel = role => {
          const countryCode = applicationSharedService.countryCodeAndBirthday(
            uiService.getCrmCountry()
          );
          const countryOfBirth = applicationSharedService.countryCodeAndBirthday(
            uiService.getCrmCountry()
          );
          const familyId = '0';
          return contactSharedDataService.getContactDataModel(
            role,
            countryCode,
            countryOfBirth,
            familyId
          );
        };

        $scope.IdentificationSet = {};
        $scope.AddressHistorySet = {};
        $scope.EmploymentSet = {};

        $scope.Dependants = $scope.getNewContactModel('Child');
        $scope.Dependants.NoDependants = true;

        const onDocumentClick = () => {
          $scope.selectedPartyType = false;
          $scope.isShowGuarantorContats = false;
          $scope.isShowBorrowerContact = false;
          $scope.isShowComapniesContact = false;
        };
        $document.on('click', onDocumentClick);
        $scope.$on('$destroy', () => {
          $document.off('click', onDocumentClick);
        });

        $scope.isWarning = list => {
          return $scope.LoanAppFormWarning && list.length < 1;
        };

        $scope.isShowGuarantorContats = false;
        $scope.isShowBorrowerContact = false;
        $scope.isShowComapniesContact = false;

        // Remove function below
        $scope.resetSearch = (type, searchType, event) => {
          if (event) {
            event.stopPropagation();
          }
          const selectedPartyType = String(type);
          $scope.selectedClientName = '';

          $scope.selectedPartyType = selectedPartyType;
          switch (searchType) {
            case SEARCH_TYPES.BORROWER:
              $scope.isShowBorrowerContact = !$scope.isShowBorrowerContact;
              $scope.isShowGuarantorContats = false;
              $scope.isShowComapniesContact = false;
              break;
            case SEARCH_TYPES.GUARANTOR:
              $scope.isShowGuarantorContats = !$scope.isShowGuarantorContats;
              $scope.isShowBorrowerContact = false;
              $scope.isShowComapniesContact = false;
              break;
            case SEARCH_TYPES.COMPANY:
              $scope.isShowComapniesContact = !$scope.isShowComapniesContact;
              $scope.isShowGuarantorContats = false;
              $scope.isShowBorrowerContact = false;
              break;
            default:
              $scope.isShowComapniesContact = false;
              $scope.isShowGuarantorContats = false;
              $scope.isShowBorrowerContact = false;
              break;
          }
          $scope.selectedClient = null;
          $scope.getClientList = [];

          const applicantSearchTimeout = $timeout(() => {
            const el = $window.document.querySelectorAll('.applicant-search');
            angular.element(el).focus();
          }, 0);
          $scope.$on('$destroy', () => {
            $timeout.cancel(applicantSearchTimeout);
          });
        };
        $scope.resetSearch(0);

        $scope.getScenarioClientList = (isRefresh = true) => {
          return $scope.loanAppSharedData
            .getLoanAppApplicants($scope.loanAppId, isRefresh)
            .then(respond => {
              if (!respond || !respond.data) return;

              let listAllParties = [];

              $scope.InvolvedPartyPerson = respond.data.InvolvedPartyPerson;
              $scope.Dependent = respond.data.Dependent;

              $scope.DependantsFamilyDropdown = [];

              if (_.size($scope.InvolvedPartyPerson) > 0) {
                _.forEach($scope.InvolvedPartyPerson, family => {
                  // get the first person in the family
                  const first = _.first(family);
                  $scope.DependantsFamilyDropdown.push({
                    FamilyId: first.FamilyId,
                    FamilyFullName: first.FamilyFullName,
                  });
                });

                // Assign the first object's family id to dependants
                $scope.Dependants.FamilyId = _.first(
                  $scope.DependantsFamilyDropdown
                ).FamilyId;
              }

              // Filter then put background on each APPLICANTS

              $scope.listApplicants = _.filter(
                $scope.InvolvedPartyPerson,
                arr => {
                  const filterApplicants = people => {
                    people.initials = utilitiesService.filterInitial(
                      people.FirstName,
                      people.LastName
                    );
                    people.background =
                      $scope.loanAppSharedData.backgroundList[
                        people.FamilyId.toString()
                      ] || $scope.getBGcolor(people.FamilyId);

                    listAllParties.push(people);

                    return people.IsApplicant;
                  };

                  const obj = _.filter(arr, filterApplicants);

                  if (_.size(obj) > 0) return obj;
                }
              );
              if ($scope.listApplicants.length === 1) {
                $scope.originalApplicants = {
                  ...$scope.listApplicants[0],
                };
                $scope.originalApplicantsId =
                  $scope.originalApplicants[0].FamilyId;
              }

              // Filter then put background on each COMPANIES
              const companies = _.map(
                ($scope.listTrustCompanies = _.filter(
                  respond.data.InvolvedPartyEntity,
                  obj => {
                    return obj.IsApplicant;
                  }
                )),
                o => {
                  o.initials = utilitiesService.filterInitialOneString(
                    o.FamilyFullName
                  );
                  o.background =
                    $scope.loanAppSharedData.backgroundList[
                      o.FamilyId.toString()
                    ] ||
                    $scope.getBGcolor(o.FamilyId) ||
                    colorService.getRandomColor();

                  return o;
                }
              );
              if (companies && companies.length) {
                listAllParties = [...listAllParties, ...companies];
              }

              // Filter then put background on each GUARANTORS
              $scope.listGuarantors = _.filter(
                $scope.InvolvedPartyPerson,
                arr => {
                  const filterGuarantors = people => {
                    people.initials = utilitiesService.filterInitial(
                      people.FirstName,
                      people.LastName
                    );
                    people.background =
                      $scope.loanAppSharedData.backgroundList[
                        people.FamilyId.toString()
                      ] || $scope.getBGcolor(people.FamilyId);
                    listAllParties.push(people);

                    return people.IsGuarantor;
                  };
                  const obj = _.filter(arr, filterGuarantors);
                  if (_.size(obj) > 0) return obj;
                }
              );

              const dependents = _.map(
                ($scope.listDependants = _.filter($scope.Dependent, obj => {
                  return obj.Role === ROLE_TYPE.DEPENDENT;
                })),
                o => {
                  if (o.FamilyId) {
                    o.initials = utilitiesService.filterInitialOneString(
                      o.PreferredName
                    );
                    o.background =
                      $scope.loanAppSharedData.backgroundList[
                        o.FamilyId.toString()
                      ] || $scope.getBGcolor(o.FamilyId);
                  }

                  return o;
                }
              );
              if (dependents && dependents.length) {
                listAllParties = [...listAllParties, ...dependents];
              }

              if (_.size($scope.listDependants) > 0)
                $scope.Dependants.NoDependants = false;

              const solicitorsAndAccountantAddBackgroundInitials = object => {
                object.initials = utilitiesService.filterInitial(
                  object.FirstName,
                  object.LastName
                );
                object.background =
                  $scope.loanAppSharedData.backgroundList[
                    object.FamilyId.toString()
                  ] || $scope.getBGcolor(object.FamilyId);

                return object;
              };
              // Filter then put background on each SOLICITOR
              const solicitors = _.map(
                ($scope.listSolicitors = _.filter(
                  respond.data.RelatedParty,
                  obj => {
                    return obj.IsSolicitor;
                  }
                )),
                o => {
                  const modifiedObject = solicitorsAndAccountantAddBackgroundInitials(
                    o
                  );
                  return modifiedObject;
                }
              );
              if (solicitors && solicitors.length) {
                listAllParties = [...listAllParties, ...solicitors];
              }

              if (_.size($scope.listSolicitors) > 0)
                $scope.RelatedPartySettings.NoSolicitors = false;

              // Filter then put background on each ACCOUNTANTS
              const accountants = _.map(
                ($scope.listAccountants = _.filter(
                  respond.data.RelatedParty,
                  obj => {
                    return obj.IsAccountant /* === 'Accountant' */;
                  }
                )),
                o => {
                  const modifiedObjectAccountant = solicitorsAndAccountantAddBackgroundInitials(
                    o
                  );
                  return modifiedObjectAccountant;
                }
              );
              if (accountants && accountants.length) {
                listAllParties = [...listAllParties, ...accountants];
              }

              if (_.size($scope.listAccountants) > 0)
                $scope.RelatedPartySettings.NoAccountants = false;

              $scope.listAssetSuppliers = respond.data.RelatedParty.filter(
                obj => obj.IsAssetSupplier
              ).map(obj => solicitorsAndAccountantAddBackgroundInitials(obj));
              if (
                $scope.listAssetSuppliers &&
                $scope.listAssetSuppliers.length
              ) {
                listAllParties = [
                  ...listAllParties,
                  ...$scope.listAssetSuppliers,
                ];
              }

              $scope.loanAppSharedData.entityOnlyApplicant =
                !_.size($scope.listApplicants) &&
                !_.size($scope.listGuarantors) &&
                _.size($scope.listTrustCompanies) > 0;

              return {
                listApplicants: $scope.listApplicants,
                listGuarantors: $scope.listGuarantors,
                listTrustCompanies: $scope.listTrustCompanies,
                listAccountants: $scope.listAccountants,
                listSolicitors: $scope.listSolicitors,
                listDependants: $scope.listDependants,
              };
            });
        };
        $scope.getScenarioClientList(false);

        $scope.countApplicants = () => {
          // @return completed/started/false
          const listAllParties = _.size($scope.listApplicants);
          const listDependants = _.size($scope.listDependants);

          const listTrustCompanies = _.size($scope.listTrustCompanies);
          const listGuarantors = _.size($scope.listGuarantors);

          const listSolicitors = _.size($scope.listSolicitors);
          const listAccountants = _.size($scope.listAccountants);

          return (
            listAllParties +
            listDependants +
            listTrustCompanies +
            listGuarantors +
            listSolicitors +
            listAccountants
          );
        };

        // Delete client
        $scope.deleteClient = (
          client,
          dontRefresh,
          partyApplicants,
          valueIndex,
          event
        ) => {
          if (event) event.stopPropagation();

          const applicants = {
            guarantors: $scope.listGuarantors,
            entities: $scope.listTrustCompanies,
            borrowers: $scope.listApplicants,
            solicitors: $scope.listSolicitors,
            accountants: $scope.listAccountants,
            dependants: $scope.listDependants,
          };
          loanService
            .removeBorrowerApplicant(applicants, valueIndex, partyApplicants)
            .then(() => {
              // check if the client is applicant, trap it so we cannot remove the last applicant
              if (client.IsApplicant && client.Role === ROLE_TYPE.APPLICANT) {
                const isNotValidApplicant =
                  $scope.listApplicants &&
                  $scope.listApplicants.length === 1 &&
                  $scope.listApplicants[0].length === 1;
                const hasApplicants = partyApplicants && partyApplicants.length;
                if (hasApplicants) {
                  const result = _.find(
                    partyApplicants,
                    applicant => applicant.isIncluded
                  );
                  const isNotValidPartyApplicant =
                    result &&
                    result.length === 1 &&
                    parseInt(result[0].PersonId, 10) ===
                      parseInt(client.PersonId, 10);
                  if (isNotValidPartyApplicant) {
                    toaster.pop(
                      'error',
                      'Error',
                      'Must have atleast 1 applicant'
                    );
                    return false;
                  }
                } else if (isNotValidApplicant) {
                  toaster.pop(
                    'error',
                    'Error',
                    'Must have atleast 1 applicant'
                  );
                  return false;
                }
              }

              // else delete
              loanScenarioService
                .scenarioClientDelete(
                  $scope.loanAppId,
                  client.PersonId ? client.PersonId : client.OrganisationId,
                  !!client.PersonId
                )
                .then(() => {
                  if (!dontRefresh) {
                    $scope.getScenarioClientList();
                    $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = {
                      RefreshAddress: true,
                      RefreshEmployment: true,
                      RefreshApplication: true,
                      RefreshAsset: true,
                      RefreshIncome: true,
                      RefreshExpense: true,
                      RefreshLiability: true,
                      RefreshLoanapp: true,
                    };
                  }
                });

              checkMainApplicant($scope.listApplicants);
            })
            .catch(() => {
              toaster.pop('warning', 'Error', 'You need at least 1 applicant');
            });
        };

        $scope.searchSetting = {
          searchInterval: 3,
          resetInterval() {
            $scope.searchSetting.searchInterval = 3;
          },
          name: '',
          searchtype: '',
        };
        // initiate Search Countdown
        $scope.searchClient = (selectedClientName, searchTypes) => {
          $scope.searchSetting.resetInterval();
          $scope.searchSetting.name = selectedClientName;
          $scope.searchSetting.searchtype = searchTypes;

          const stop = $interval(() => {
            if ($scope.searchSetting.searchInterval > 1) {
              $scope.searchSetting.searchInterval -= 1;
            } else {
              $interval.cancel(stop);
            }
          }, $scope.searchSetting.searchInterval * 1000);
        };

        const searchIntervalWatcher = $scope.$watch(
          'searchSetting.searchInterval',
          interval => {
            if (interval === 1)
              $scope.getSearchedClients($scope.searchSetting.name);
          },
          true
        );
        $scope.$on('$destroy', () => {
          if (searchIntervalWatcher) searchIntervalWatcher();
        });

        // initiate api call
        $scope.getSearchedClients = searchString => {
          $scope.clientNotFound = false;

          if (searchString !== '' && searchString !== null) {
            // Override : Some part type are equivalent to the same party type
            let partyType = $scope.selectedPartyType;
            switch ($scope.searchSetting.searchtype) {
              case 'borrowers':
              case 'guarantors':
                partyType = BORROWER_PARTY_TYPES.PERSON;
                break;
              default:
                if (
                  partyType === partyTypeGuarantors ||
                  partyType === partyTypeDependants
                )
                  partyType = partyTypeApplicants;

                if (
                  partyType === partyTypeSolicitor ||
                  partyType === partyTypeAccountant
                )
                  partyType = BORROWER_PARTY_TYPES.PERSON;
                break;
            }

            loanScenarioService
              .scenarioSearchClientGet(
                searchString,
                partyType,
                $stateParams.loanAppId
              )
              .then(response => {
                const data = response.data;
                $scope.getClientList = _.map(data, o => {
                  o.initials =
                    o.FirstName && o.LastName
                      ? utilitiesService.filterInitial(o.FirstName, o.LastName)
                      : '';
                  return o;
                });
              });
          }
        };

        // save selected client to loan app
        $scope.saveSelectClient = toRefresh => {
          $scope.selectedClient.AssignedLoanScenarioId = $scope.loanAppId;
          $scope.selectedClient.IsApplicant = false;
          $scope.selectedClient.IsGuarantor = false;
          $scope.selectedClient.IsSolicitor = false;
          $scope.selectedClient.IsAccountant = false;
          $scope.selectedClient.AddRelatedChildren = false;
          // Override the same party type but different Desc
          if ($scope.selectedPartyType === partyTypeApplicants) {
            $scope.selectedClient.ClientTypeDesc = 'Borrower';
            $scope.selectedClient.IsApplicant = true;
            $scope.selectedClient.AddRelatedChildren = true;
          }

          if ($scope.selectedPartyType === partyTypeCompany) {
            $scope.selectedClient.ClientTypeDesc = 'Trust';
            $scope.selectedClient.IsApplicant = true;
          }

          if ($scope.selectedPartyType === partyTypePartnership) {
            $scope.selectedClient.ClientTypeDesc = 'Partnership';
            $scope.selectedClient.IsClient = false;
            $scope.selectedClient.IsApplicant = true;
          }

          if ($scope.selectedPartyType === partyTypeGuarantors) {
            $scope.selectedClient.ClientTypeDesc = 'Guarantor';
            $scope.selectedClient.IsGuarantor = true;
          }

          if ($scope.selectedPartyType === partyTypeSolicitor) {
            $scope.selectedClient.ClientTypeDesc = 'Solicitor';
            $scope.selectedClient.IsSolicitor = true;
          }

          if ($scope.selectedPartyType === partyTypeAccountant) {
            $scope.selectedClient.ClientTypeDesc = 'Accountant';
            $scope.selectedClient.IsAccountant = true;
          }

          loanScenarioService.scenarioApplicantSet($scope.selectedClient).then(
            () => {
              /**
               * commented out to avoid flooding of notification
               */
              if (toRefresh) {
                $scope.resetSearch(0);
                $scope.getScenarioClientList();

                // initialize again the sections that have dependencies on applicants
                $scope.loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS = {
                  RefreshAddress: true,
                  RefreshEmployment: true,
                  RefreshApplication: true,
                  RefreshAsset: true,
                  RefreshIncome: true,
                  RefreshExpense: true,
                  RefreshLiability: true,
                  RefreshLoanapp: true,
                };
              }
            },
            () => {
              toaster.pop(
                'warning',
                'Unable to Add',
                'Parties were not added, please try again'
              );
            }
          );
        };

        // Select a client or loop through client from family id you've created from the create contact
        $scope.selectClient = (
          client,
          isFromAddNewModal,
          createdFamilyId,
          PartyType,
          excldIds
        ) => {
          let excludedIds = [];
          if (excldIds) {
            excludedIds = excldIds;
          }

          if (!isFromAddNewModal && !createdFamilyId) {
            $scope.selectedClient = client;
            $scope.selectedClientName = `${$scope.selectedClient.FirstName} ${
              $scope.selectedClient.LastName
            }`;
            $scope.saveSelectClient(1);
          } else if (isFromAddNewModal && createdFamilyId) {
            // PartyType
            $scope.selectedPartyType = PartyType;

            // everything except 2 which is a company

            if (
              parseInt(PartyType, 10) !== ENTITY_PARTY_TYPES.COMPANY &&
              parseInt(PartyType, 10) !== ENTITY_PARTY_TYPES.PARTNERSHIP
            ) {
              // Get all the clients you've created from the create contact
              contactService.clientInformGet(createdFamilyId).then(response => {
                _.remove(response.data, o => {
                  return o.Role === 'Child';
                });

                // Get the whole size of clients
                _.forEach(response.data, obj => {
                  // if client is to be excluded, decrement the count
                  const isUserExcluded = _.find(excludedIds, id => {
                    return id === obj.PersonId;
                  });
                  if (!isUserExcluded) {
                    $scope.selectedClient = {
                      ClientEntityId: obj.PersonId,
                      ClientTypeDesc: '',
                      ClientTypeId: '1',
                      FirstName: obj.FirstName,
                      LastName: obj.LastName,
                      PreferedName: obj.PreferredName,
                      IsClient: true,
                      ClientID: 0,
                    };
                    $scope.saveSelectClient(true);
                  }
                });
              });
            } else {
              contactService
                .organisationInfoGet(createdFamilyId)
                .then(response => {
                  $scope.selectedClient = {
                    ClientEntityId: response.data.OrganisationId,
                    ClientTypeDesc: response.data.OrganisationType,
                    ClientTypeId:
                      response.data.OrganisationType === 'Company' ||
                      response.data.OrganisationType === 'Trust'
                        ? '2'
                        : '7',
                    PreferedName: response.data.Name,
                    IsClient: false,
                    ClientID: 0,
                  };

                  $scope.saveSelectClient(1);
                });
            }
          } else {
            toaster.pop('error', 'Ooops', 'Something went wrong');
            return false;
          }
        };

        optionsService.MaritalStatusGet().then(
          response => {
            $scope.maritalStatus = response.data;
          },
          () => {
            $scope.maritalStatus = [];
          }
        );

        optionsService.ResidencyStatusGet().then(
          response => {
            $scope.residencyStatus = response.data;
          },
          () => {
            $scope.residencyStatus = [];
          }
        );

        optionsService.countryListGet().then(
          response => {
            $scope.countryList = response.data;
          },
          () => {
            $scope.countryList = [];
          }
        );

        optionsService.PreferredContactMethodGet().then(
          response => {
            $scope.preferredContactMethod = response.data;
          },
          () => {
            $scope.preferredContactMethod = [];
          }
        );

        // Add Related Modal
        $scope.applicantModal = (type, person) => {
          // $scope.accountType = type;

          const modalInstance = $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
            controller: 'ApplicantModalCtrl',
            scope: $scope,
            size: 'lg',
            resolve: {
              selectedPartyType() {
                return String(type);
              },
              person() {
                return person;
              },
              type() {
                return type;
              },
              requester() {
                return null;
              },
              listApplicants() {
                return null;
              },
              assignedAdviserIdFromRequester() {
                return null;
              },
            },
            backdrop: 'static',
            keyboard: false,
          });

          modalInstance.result.then(
            response => {
              $scope.selectClient(
                {},
                true,
                response.familyId,
                response.PartyType,
                response.excludedIds
              );
            },
            () => {
              $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
            }
          );
        };
        // Add Related Modal
        $scope.companyRelatedPartyModal = (type, person) => {
          const modalInstance = $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
            controller: 'AddCompanyRelatedModalCtrl',
            scope: $scope,
            size: 'md',
            resolve: {
              selectedPartyType() {
                return String(type);
              },
              person() {
                return person;
              },
              requester() {
                return REQUESTER.STANDARD;
              },
            },
            backdrop: 'static',
            keyboard: false,
          });

          modalInstance.result.then(
            response => {
              if (!response.isEdit)
                $scope.selectClient(
                  {},
                  true,
                  response.FamilyId,
                  response.PartyType
                );
              else $scope.getScenarioClientList();
            },
            () => {
              $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
            }
          );
        };

        $scope.EntityModal = (type, entity = null) => {
          const selectedCompanyType =
            type === COMPANY_TYPES.COMPANY_STRING
              ? COMPANY_TYPES.COMPANY
              : COMPANY_TYPES.TRUST;
          const modalInstance = $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/clientAssess/modal/addTrust.html',
            controller: 'AddTrustCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              selectedPartyType() {
                return parseInt(selectedCompanyType, 10);
              },
              entity() {
                return entity;
              },
            },
            backdrop: 'static',
            keyboard: false,
          });

          modalInstance.result.then(
            response => {
              if (!response.isEdit) {
                $scope.selectedPartyType = partyTypeCompany;
                $scope.selectClient(
                  {},
                  true,
                  response.FamilyId,
                  response.PartyType
                );
              } else {
                $scope.getScenarioClientList();
              }
            },
            () => {
              $rootScope.$broadcast('REFRESH_RELATED_CLIENTS');
            }
          );
        };

        $scope.addCompany = () => {
          $scope.companyRelatedPartyModal(partyTypeCompany);
        };

        $scope.calculateAge = dob => {
          // Check first if DOB is valid date
          if (moment(dob, 'DD/MM/YYYY', true).isValid())
            $scope.Dependants.Age = moment().diff(
              moment(dob, 'DD-MM-YYYY'),
              'years'
            );
          else $scope.Dependants.Age = 0;
        };

        $scope.calculateDOB = () => {
          $scope.Dependants.DOBGet = ageToDOBGet($scope.Dependants.Age);
        };

        // Add Dependants button
        $scope.addDependants = ({ $invalid, $error }) => {
          if ($invalid) {
            $error.required.forEach(obj => obj.$setDirty());
            return;
          }
          $scope.isAddingDependants = true;
          $scope.Dependants.PersonId = '0';
          if (!$scope.Dependants.DOBGet) {
            $scope.Dependants.DOBGet = ageToDOBGet($scope.Dependants.Age);
          }

          $scope.Dependants.DOB = $scope.Dependants.DOBGet.split('/')
            .reverse()
            .join('-');

          contactService.familyInfoSet([$scope.Dependants]).then(response => {
            const PersonId = response.data;

            $scope.selectedPartyType = partyTypeDependants;
            $scope.Dependants.ClientEntityId = PersonId;

            $scope.selectedClient = {
              FamilyID: $scope.familyId,
              ClientEntityId: PersonId,
              FirstName: $scope.Dependants.FirstName,
              LastName: ' ',
              PreferedName: '',
              IsClient: true,
              AssignedLoanScenarioId: $scope.loanAppId,
              IsApplicant: false,
              IsGuarantor: false,
              IsSolicitor: false,
              IsAccountant: false,
              AddRelatedChildren: false,
              Age: $scope.Dependants.Age,
              DOB: $scope.Dependants.DOB,
              DOBGet: $scope.Dependants.DOBGet,
            };

            $scope.Dependants = $scope.getNewContactModel('Child');

            $scope.selectedClientName = $scope.selectedClient.FirstName;

            $scope.saveSelectClient(1);
          });
        };

        $scope.deleteDependant = (
          dependant,
          dontRefresh,
          partyApplicants,
          valueIndex
        ) => {
          $scope.deleteClient(
            dependant,
            dontRefresh,
            partyApplicants,
            valueIndex
          );
        };

        /**
         *   Section : Identification
         */

        // model
        angular.extend($scope, {
          getFamilyIdentityStatusModel() {
            return {
              LoanApplicationId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              FamilyIdentityCheckId: 0,
              FaceToFaceIdentityCheck: false,
              IsExistingCustomer: false,
            };
          },
        });

        // method
        angular.extend($scope, {
          GetFamilyIdentityStatus() {
            loanScenarioService
              .FamilyIdentityStatusGet(
                $scope.loanAppId,
                $scope.familyIdentification.FamilyId
              )
              .then(response => {
                // zero out FamilyIdentityCheckId first
                angular.extend($scope.familyIdentityStatusSet, {
                  FamilyIdentityCheckId: 0,
                });

                angular.extend($scope.familyIdentityStatusSet, response.data);
              });
          },
          SetFamilyIdentityStatus() {
            loanScenarioService
              .FamilyIdentityStatusSet($scope.familyIdentityStatusSet)
              .then(() => {
                $scope.GetFamilyIdentityStatus();
              });
          },
          SetFamilyIdentification(getFirst, familyId) {
            let family = {};
            if (getFirst) {
              family = _.head($scope.allIdentifications);
            } else {
              family = _.find($scope.allIdentifications, o => {
                return parseInt(o.FamilyId, 10) === parseInt(familyId, 10);
              });
            }

            // Assign background color to $scope.familyIdentification.FamilyId
            $scope.getBGcolor($scope.familyIdentification.FamilyId);
            angular.extend($scope.familyIdentification, family);
            $scope.familyIdentification.FamilyId = String(
              $scope.familyIdentification.FamilyId
            );
            $scope.familyIdentification.initials = utilitiesService.filterInitialOneString(
              family.FamilyName
            );
            $scope.familyIdentification.background = $scope.getBGcolor(
              $scope.familyIdentification.FamilyId
            );

            _.map($scope.familyIdentification.Identification, o => {
              o.ClientInitials = utilitiesService.filterInitialOneString(
                `${o.ClientFirstName} ${o.ClientLastName}`
              );

              // get the background assigned to FamilyId
              o.background = $scope.familyIdentification.background;
              return o;
            });
          },
          GetIdentification(selectedFamilyId) {
            loanScenarioService
              .IdentificationGet($scope.loanAppId)
              .then(response => {
                $scope.allIdentificationFamilies = [];
                const tempList = [];
                if (
                  ($scope.listApplicants && $scope.listApplicants.length) ||
                  ($scope.listGuarantors && $scope.listGuarantors.length)
                ) {
                  $scope.listApplicants &&
                    $scope.listApplicants.map(applicant => {
                      return applicant.map(o => {
                        tempList.push({
                          FamilyId: o.FamilyId,
                          FamilyName: o.FamilyFullName,
                          background: $scope.getBGcolor(o.FamilyId),
                          initials: utilitiesService.filterInitialOneString(
                            o.FamilyFullName
                          ),
                          listPriority: o.IsApplicant ? 0 : 1,
                        });
                        return tempList;
                      });
                    });
                  $scope.listGuarantors &&
                    $scope.listGuarantors.map(applicant => {
                      return applicant.map(o => {
                        tempList.push({
                          FamilyId: o.FamilyId,
                          FamilyName: o.FamilyFullName,
                          background: $scope.getBGcolor(o.FamilyId),
                          initials: utilitiesService.filterInitialOneString(
                            o.FamilyFullName
                          ),
                          listPriority: o.IsApplicant ? 0 : 1,
                        });
                        return tempList;
                      });
                    });

                  $scope.allIdentifications = response.data;

                  if (tempList && tempList.length) {
                    const uniqIdentificationFamilies = _.uniqBy(
                      tempList,
                      'FamilyId'
                    );
                    $scope.allIdentificationFamilies = _.orderBy(
                      uniqIdentificationFamilies,
                      ['listPriority'],
                      ['asc']
                    );
                  }

                  if (selectedFamilyId)
                    $scope.SetFamilyIdentification(false, selectedFamilyId);
                  else {
                    $scope.SetFamilyIdentification(true);
                  }
                } else {
                  $scope.getFamilyForIdentification(true).then(res => {
                    $scope.familyIdentification.FamilyId = res;
                  });
                }
              });
          },
          identificationInit() {
            $scope.getFamilyForIdentification(true).then(response => {
              $scope.familyIdentityStatusSet = $scope.getFamilyIdentityStatusModel();
              $scope.allIdentifications = [];
              $scope.allIdentificationFamilies = [];
              $scope.familyIdentification = {};
              $scope.familyIdentification.FamilyId = response;
              $scope.familyIdentification.Identification = [];
              // call other apis
              $scope.GetIdentification();
              $scope.getDocType();
            });
          },
          changeIdentificationFamily(familyId) {
            $scope.SetFamilyIdentification(false, familyId);
          },
          getDocType() {
            optionsService.identificationDocumentsTypeGet().then(({ data }) => {
              $scope.documentTypeList = data || [];
            });
          },
          getFamilyForIdentification(idOnly = false) {
            return $scope.getScenarioClientList().then(() => {
              let family = {};
              if (
                !_.size($scope.listApplicants) &&
                _.size($scope.listGuarantors) &&
                _.size($scope.listTrustCompanies)
              ) {
                const guarantors = _.head($scope.listGuarantors);
                const firstGuarantor = _.head(guarantors);
                family = firstGuarantor;
              } else {
                family = $scope.familyIdentification
                  ? $scope.familyIdentification
                  : 0;
              }
              if (idOnly) {
                return family.FamilyId;
              }
              return family;
            });
          },
          identificationModal(modalType, identification) {
            $scope.getFamilyForIdentification(true).then(response => {
              const familyId = response;
              identificationService
                .addEditIdentificationModal({
                  modalType,
                  identification,
                  familyId,
                  documentTypeList: $scope.documentTypeList,
                  loanAppId: $scope.loanAppId,
                })
                .then(() => {
                  $scope.GetIdentification(familyId);
                });
            });
          },
          deleteIdentification(identificationId) {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text:
                  'This record will be removed from your identification list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              },
              confirm => {
                if (confirm) {
                  loanScenarioService
                    .IdentificationDelete(identificationId)
                    .then(() => {
                      $timeout(() => {
                        $scope.GetIdentification();
                        SweetAlert.swal({
                          title: 'Success',
                          text:
                            'Identification has been been successfully deleted.',
                          type: 'success',
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }, 100);
                      $scope.validateApplicants();
                    });
                }
              }
            );
          },
          changeIdentificationClient(list) {
            if (!angular.isUndefined($scope.IdentificationSet.ClientEntityId)) {
              const clientEntity = _.find(list, o => {
                return o.PersonId === $scope.IdentificationSet.ClientEntityId;
              });

              $scope.IdentificationSet.NameOnDoc =
                clientEntity && clientEntity.PersonId
                  ? `${clientEntity.FirstName} ${clientEntity.MiddleName} ${
                      clientEntity.LastName
                    }`
                  : (clientEntity && clientEntity.Name) || '';
            } else {
              $scope.IdentificationSet.NameOnDoc = '';
            }
          },
        });
        $scope.identificationInit();
        $scope.getDocType();

        $scope.$watch('familyIdentification.FamilyId', nv => {
          angular.extend($scope.familyIdentityStatusSet, {
            FamilyId: String(nv),
          });

          $scope.GetFamilyIdentityStatus();
        });

        $scope.$watch(
          'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
          nv => {
            if (nv && _.size(nv) > 0 && nv.RefreshApplication) {
              $scope.identificationInit();
              nv.RefreshApplication = false;

              $scope.isAddingDependants = false;
            }
          }
        );
      };

      const returnListApplicantsGuarantors = listApplicants => {
        const tempApplicantList = [];
        const tempGuarantorList = [];

        Object.keys(listApplicants).forEach(key => {
          listApplicants[key].forEach(familyObject => {
            if (familyObject.IsApplicant) {
              tempApplicantList.push(familyObject);
            }
            if (familyObject.IsGuarantor) {
              tempGuarantorList.push(familyObject);
            }
          });
        });

        return {
          applicants: tempApplicantList,
          guarantors: tempGuarantorList,
        };
      };

      const sectionObject = $scope.loanAppSharedData.getSectionObjectById(
        $scope.loanAppSharedData.completionListObject,
        SECTION_ID.APPLICANTS
      );
      $scope.countValids(sectionObject.IsCompleted, 'applicants');
      if (!sectionObject.IsCompleted) {
        $scope.processApplicantSection();
      } else {
        const applicantGuarantor = returnListApplicantsGuarantors(
          $scope.loanAppSharedData.loanAppSummary.ApplicantGuarantorList
            .InvolvedPartyPerson
        );
        const applicants = applicantGuarantor.applicants;
        const guarantors = applicantGuarantor.guarantors;
        $scope.isValidApplicants = sectionObject.IsCompleted;
        $scope.listApplicants = [];
        $scope.listGuarantors = [];
        $scope.listApplicants[0] = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          applicants,
          $scope.getBGcolor
        );
        $scope.listGuarantors[0] = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          guarantors,
          $scope.getBGcolor
        );
        $scope.listTrustCompanies = $scope.loanAppSharedData.populateBackgroundForApplicantSection(
          $scope.loanAppSharedData.loanAppSummary.ApplicantGuarantorList
            .InvolvedPartyEntity,
          $scope.getBGcolor
        );
        $scope.isStartedApplicants = true;
      }
    });
  });
