import { getLeadStatus } from 'Common/utilities/loanLead';
import { pipelineStatusBuilderForUI } from 'Common/mappers/pipeline';
import { CONVERSION_PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { PIPELINE_CATEGORIES } from 'Common/constants/pipelineType';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import toastr from 'toastr';
import moment from 'moment';
import CrmTableViewCtrl from '../crmTableViewCtrl';

export default class TableViewApplications extends CrmTableViewCtrl {
  constructor(
    $interval,
    $state,
    opportunityNewModalService,
    loanScenarioService,
    modalRenderService,
    pipelineSharedData,
    utilitiesService,
    pipelineService,
    contactService
  ) {
    'ngInject';

    super($interval);
    this.$state = $state;
    this.opportunityNewModalService = opportunityNewModalService;
    this.loanScenarioService = loanScenarioService;
    this.modalRenderService = modalRenderService;
    this.pipelineSharedData = pipelineSharedData;
    this.utilitiesService = utilitiesService;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
  }

  $onInit() {
    this.componentCellKeys = [
      'dateCreated',
      'lastUpdated',
      'pipelineStatus',
      'subStatusName',
      'labelsList',
      'isopenSort',
      'probability',
      'familyInfo',
      'manageLabelOpen',
    ];
    this.getReasons();
    getLeadStatus(this.pipelineService).then(response => {
      this.statusList = response.filter(o => !o.IsConversionStatus);
      this.forSubStatusList = response.map(pipelineStatusBuilderForUI);
    });
  }

  getReasons() {
    this.pipelineService
      .getPipelineCategoryReasons(PIPELINE_CATEGORIES.APPLICATION)
      .then(({ data }) => {
        this.reasonList = data || [];
      });
  }

  rowDeletion(rowData, event) {
    const isOfflineDeleting = this.totalRecords <= this.pageSize;
    const { pipelineCardsId } = rowData;
    const params = {
      $e: {
        pipelineCardsId,
        event,
        successCallback: isOfflineDeleting
          ? this.removeRow
          : this.onRefreshTable,
        successCallbackParams: isOfflineDeleting
          ? {
              rowId: pipelineCardsId,
              primaryKey: 'pipelineCardsId',
            }
          : {},
      },
    };

    this.onDeletePipelineItem(params);
  }

  toggleIsShrink(event) {
    event && event.stopPropagation();
    this.isShrink = !this.isShrink;
  }

  changeSubStatus({ card, subStatus }, showToaster = true) {
    card.subStatusName = subStatus.SubStatusName;
    card.subStatusId = subStatus.pipelineSubStatusID;

    const params = {
      CardsID: card.pipelineCardsId,
      SubStatusID: subStatus.pipelineSubStatusID,
    };
    this.pipelineService.CardsSubStatusSet(params).then(({ data }) => {
      if (data && showToaster)
        toastr.success('Substatus Successfully Saved!', 'Success');
    });
  }

  changePipelineStatus(pipelineItem, newStatus) {
    pipelineItem.pipelineStatus = newStatus.PipelineStatus;
    pipelineItem.subStatusId = 0;
    pipelineItem.subStatusName = '';

    this.pipelineService
      .PipelineCards(pipelineItem.pipelineCardsId, {
        PipelineStatusID: newStatus.PipelineStatusID,
      })
      .then(({ Result, LastUpdated }) => {
        if (!Result || !LastUpdated) return;
        this.changeSubStatus(
          {
            subStatus: {
              subStatusId: 0,
              subStatusName: '',
            },
            card: pipelineItem,
          },
          false
        );
        pipelineItem.lastUpdated = moment(new Date(LastUpdated)).format(
          'DD MMM YYYY hh:mm:ss A'
        );
        toastr.success('Status Successfully Saved!', 'Success');
      });
  }

  convertCard(pipelineItem, convertToStatus) {
    const { pipelineCardsId, enquirySource } = pipelineItem;
    const {
      PIPELINE_STATUS: conversionStatus,
      PIPELINE_STATUS_ID: conversionId,
    } = convertToStatus;

    const modalContent = {
      content: `This record will be moved to ${conversionStatus}`,
    };
    if (conversionId === CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED) {
      modalContent.reasonList = this.reasonList;
      modalContent.showNurtureCampaignsQuestion = this.pipelineSharedData.showNurtureCampaignsQuestion(
        enquirySource
      );
    }

    if (conversionId === CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY) {
      this.convertLeadToOpportunity(pipelineItem);
    } else {
      this.modalRenderService
        .renderPipelineStatusMoveConfirmationModal(modalContent)
        .result.then(
          ({ isOk, selectedReasonId, shouldSendNurtureCampaigns }) => {
            if (!isOk) return;

            pipelineItem.reasonSubstatusID = selectedReasonId;
            this.updateCardConversionStatus(
              pipelineItem,
              conversionId,
              shouldSendNurtureCampaigns
            );
            if (!selectedReasonId) {
              this.convertLeadToApplication(pipelineItem);
            } else {
              this.removeRow({
                rowId: pipelineCardsId,
                primaryKey: 'pipelineCardsId',
              });
            }
            toastr.success('Lead successfully converted!', 'Success');
            this.onRefreshStats();
          }
        );
    }
  }

  convertLeadToOpportunity(pipelineItem) {
    const { clientFamilyId, pipelineCardId: rowId } = pipelineItem;
    leadsToOpportunityConvert(
      clientFamilyId,
      this.contactService,
      this.opportunityNewModalService,
      {},
      rowId
    ).then(res => {
      if (!res && !res.isSuccess) return;
      this.updateCardConversionStatus(
        pipelineItem,
        CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY
      );
      this.removeRow({
        rowId,
        primaryKey: 'pipelineCardsId',
      });
    });
  }

  convertLeadToApplication(pipelineItem) {
    const { clientFamilyId: familyId, adviserId } = pipelineItem;
    const setLoanAppObj = {
      LoanScenarioId: 0,
      AdviserId: adviserId || 0,
      Title: `Loan Application`,
      StatusName: 'ongoing',
      FamilyId: familyId,
      AssignedLenderId: '0',
    };

    this.loanScenarioService
      .scenarioSet(setLoanAppObj)
      .then(({ data: loanAppId }) => {
        if (!loanAppId) return;
        const routerParams = {
          familyId,
          loanAppId,
        };
        this.$state.go('app.loanApplicationDetailsV3', routerParams);
      });
  }

  updateCardConversionStatus(
    pipelineItem,
    conversionStatus,
    shouldSendNurtureCampaigns
  ) {
    const {
      pipelineCardsId: pipelineCardsID,
      clientFamilyId: clientFamilyID,
      reasonSubstatusID,
    } = pipelineItem;
    const args = {
      pipelineCardsID,
      conversionStatus,
      clientFamilyID,
      reasonSubstatusID: reasonSubstatusID || 0,
      shouldSendNurtureCampaigns,
    };
    return this.pipelineService.PipelineCardsConversionSet(args);
  }
}
