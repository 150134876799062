import template from './leadAllocationCount.html';
import LeadAllocationCountCtrl from './leadAllocationCountCtrl';

export default {
  template,
  bindings: {
    leadProfileDetails: '<',
    title: '@',
    dimension: '@',
  },
  controller: LeadAllocationCountCtrl,
  controllerAs: 'vm',
};
