import angular from 'angular';

/**
 * @usage: add toggler attribute with desired scope variable ie. toggler="isOpen"
 * @description: click/unclick of the element will toggle the scope var, clicking outside the document will falsify it
 */
angular.module('app').directive('toggler', function toggler($document) {
  return {
    scope: {
      toggler: '=',
    },
    link(scope, elem) {
      const toggle = e => {
        if (e.target === elem[0]) {
          scope.toggler = !scope.toggler;
        } else {
          scope.toggler = false;
        }
      };

      $document.on('click', toggle);

      scope.$on('$destroy', () => {
        $document.off('click', toggle);
      });
    },
  };
});
