import {
  assetSupplierBuilderForMyCRM,
  assetSupplierBuilderForUI,
} from 'Common/mappers/loanScenario';

export default class LoanScenarioModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'LoanScenario';
  }

  cpaDocument(param) {
    return this.httpClient.get(`${this.apiBaseUrl}/cpa-document`, param);
  }

  postAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier)
    );
  }

  getAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`, {
        assetSupplierId,
      })
      .then(response => assetSupplierBuilderForUI(response.data));
  }

  updateAssetSuplier(loanApplicationId, assetSupplier) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      assetSupplierBuilderForMyCRM(assetSupplier)
    );
  }

  deleteAssetSupplier(loanApplicationId, assetSupplierId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${loanApplicationId}/asset-supplier`,
      { assetSupplierId }
    );
  }

  getApplicantEmployment(loanApplicationId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ApplicantEmployment`, {
      loanApplicationId,
    });
  }
}
