import template from './onboardingLinkNavigation.html';
import OnboardingLinkNavigationCtrl from './onboardingLinkNavigationCtrl';

export default {
  template,
  bindings: {
    panelColor: '@',
    iconHeader: '@',
    panelHeaderTitle: '@',
    data: '<',
    linkIcon: '@',
  },
  controller: OnboardingLinkNavigationCtrl,
  controllerAs: 'vm',
};
