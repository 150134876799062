import angular from 'angular';
import { taskTemplateBuilderForUI } from 'Common/mappers/tasks';
import {
  updateTemplateTaskSettings,
  calculatePrevNext,
} from './util/taskTemplate';

const TEMPLATE_DATA_CACHE = 'templateDataCache';
const TASK_TEMPLATE_CACHE = 'taskTemplateCache';

angular
  .module('app')
  .controller('TaskTemplateCtrl', function TaskTemplateCtrl(
    taskService,
    $scope,
    $cacheFactory,
    businessService
  ) {
    $scope.taskTemplateDetailList = [];
    $scope.cloneTaskTemplateDetails = [];
    $scope.isTaskTemplateLoading = true;
    $scope.defaultPagiLength = 5;
    $scope.taskTemplatePage = {
      current: 1,
    };
    const setIsTaskTemplateLoading = bool => {
      $scope.isTaskTemplateLoading = bool;
    };
    const taskTemplateCache =
      $cacheFactory.get(TEMPLATE_DATA_CACHE) ||
      $cacheFactory(TEMPLATE_DATA_CACHE);
    const cacheScopeTaskData = data =>
      taskTemplateCache.put(
        TASK_TEMPLATE_CACHE,
        data.map(taskTemplateBuilderForUI)
      );
    const getCacheScopeTaskData = () =>
      taskTemplateCache.get(TASK_TEMPLATE_CACHE);
    const updateScopeTaskData = data => {
      const taskCache = getCacheScopeTaskData();
      const total = taskCache ? taskCache.length : 0;
      const current = $scope.taskTemplatePage.current;
      const { prev, next } = calculatePrevNext(
        current,
        total,
        $scope.defaultPagiLength
      );

      $scope.taskTemplateDetailList = data
        .map(taskTemplateBuilderForUI)
        .slice(prev, next);
    };
    const updateTaskDataSettings = (id, data) => {
      const mapTaskDataWithList = updateTemplateTaskSettings(id, data);
      const updatedTaskData = getCacheScopeTaskData().map(mapTaskDataWithList);

      cacheScopeTaskData(updatedTaskData);
      updateScopeTaskData(updatedTaskData);
    };

    $scope.taskTemplateTotal = () => {
      const total = getCacheScopeTaskData();
      if (total && total.length) return total.length;
      return 0;
    };

    $scope.updateTaskTemplatePagi = () => {
      const data = getCacheScopeTaskData();
      updateScopeTaskData(data);
    };

    $scope.loadTaskTemplateList = id => {
      taskService
        .getTaskTemplateSettings(id)
        .then(({ data }) => updateTaskDataSettings(id, data));
    };

    $scope.getTaskTemplateListFullDetails = () => {
      setIsTaskTemplateLoading(true);
      taskService
        .taskTemplateListGet()
        .then(({ data }) => {
          cacheScopeTaskData(data);
          updateScopeTaskData(data);
          return businessService.TaskTemplateListFullDetailsGet();
        })
        .then(({ data }) => {
          cacheScopeTaskData(data);
          updateScopeTaskData(data);
          setIsTaskTemplateLoading(false);
        });
    };
    $scope.getTaskTemplateListFullDetails();
  });
