import { splitString } from 'Common/utilities/string';
import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';

export function getCurrentStatusIndex(statusList, statusBasis, pipelineStatus) {
  return statusList.findIndex(card => card[statusBasis] === pipelineStatus);
}

export function involvedPartiesBuilder(card) {
  const clientsList = splitString(card.clients).map(contactName => {
    return { name: contactName, isClient: true };
  });
  const entitiesList = splitString(card.entities).map(contactName => {
    return { name: contactName, isEntity: true };
  });
  const guarantorsList = splitString(card.guarantors).map(contactName => {
    return { name: contactName, isGuarantor: true };
  });

  return [...clientsList, ...entitiesList, ...guarantorsList];
}

export function applicationTimelineCardBuilder(
  utilitiesService,
  card,
  statusList
) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusID',
      card.pipelineStatusId
    ),
    involvedParties: involvedPartiesBuilder(card),
    loanAmount: card.proposedLoanAmount,
    contactNamesLimit: 3,
    loanScenarioID: card.loanScenarioId,
    labelsList: pipelineLabelBuilder(card.labels),
    loanID: card.loanId,
    loanStatusID: card.loanStatusId,
    pipelineCardsID: card.pipelineCardsId,
    lenderID: card.lenderId,
    lender: card.lenderName,
    primaryPurposeCategories: card.loanPurposeCategory,
  };
}

export function opportunityTimelineCardBuilder(
  utilitiesService,
  card,
  statusList
) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusId',
      card.pipelineStatusId
    ),
    involvedParties: involvedPartiesBuilder(card),
    contactNamesLimit: 3,
    loanScenarioID: card.loanScenarioId,
    labelsList: pipelineLabelBuilder(card.labels),
    loanID: card.loanId,
  };
}

export function leadsTimelineCardBuilder(utilitiesService, card, statusList) {
  return {
    ...card,
    adviserInitials: utilitiesService.filterInitialOneString(
      card.adviserFullName
    ),
    currentStatusIdx: getCurrentStatusIndex(
      statusList,
      'pipelineStatusId',
      card.pipelineStatusId
    ),
    labelsList: pipelineLabelBuilder(card.labels),
  };
}
