import template from './contactInfoLink.html';
import controller from './contactInfoLinkCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    type: '@',
    link: '<',
  },
};
