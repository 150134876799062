import template from './leadStatus.html';
import LeadStatusCtrl from './leadStatusCtrl';
import './style.scss';

export default {
  template,
  controller: LeadStatusCtrl,
  bindings: {
    statusId: '=',
  },
  controllerAs: 'vm',
};
