import template from './loanAppDiscountsRate.html';
import loanAppDiscountsRateCtrl from './loanAppDiscountsRateCtrl';
import './style.scss';

export default {
  template,
  controller: loanAppDiscountsRateCtrl,
  controllerAs: 'vm',
  bindings: {
    productId: '<',
    loanId: '<',
    loanApplicationId: '<',
    brokerEventId: '<',
    numberOfProduct: '<',
    isOnLoanDetailsModal: '<',
    getInitialActualRate: '&',
  },
};
