import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import { forEach, findIndex, filter } from 'lodash';
import { toastSaveSuccess } from 'Common/utilities/alert';
import { filterClientList } from 'Common/utilities/client';

export default class ManagePartiesCtrl {
  constructor(
    $scope,
    $state,
    $timeout,
    $document,
    $uibModal,
    $rootScope,
    toaster,
    uiService,
    userService,
    contactService,
    generalService,
    corporateService,
    dashboardService,
    utilitiesService,
    loanScenarioService,
    broadcastDataService,
    loanOpportunityService,
    loanApplicationServices
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$document = $document;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.toaster = toaster;
    this.uiService = uiService;
    this.userService = userService;
    this.contactService = contactService;
    this.generalService = generalService;
    this.corporateService = corporateService;
    this.dashboardService = dashboardService;
    this.utilitiesService = utilitiesService;
    this.loanScenarioService = loanScenarioService;
    this.broadcastDataService = broadcastDataService;
    this.loanOpportunityService = loanOpportunityService;
    this.loanApplicationServices = loanApplicationServices;
  }

  $onInit() {
    this.REQUESTER_TYPE = CONTACT_REQUESTER.REQUESTER_TYPE;
    this.SHOW_EXISTING_PANEL = CONTACT_REQUESTER.SHOW_EXISTING_PANEL;
    this.resetAdviser();
    this.formData = {
      borrower: [],
      guarantor: [],
    };
    this.displayFamily = {
      borrower: [],
      guarantor: [],
    };
    this.displayCompany = {
      borrower: [],
    };
    this.saveDisabled = false;
    this.showThisPanel = 0;
    this.showClientList = false;
    this.searchClientName = '';
    this.clientList = [];
    this.existingFamilies = [];
    this.excludedIds = [];
    this.includedIds = [];
    this.showAdviserLabel = true;
    this.showAdvisers = false;
    this.isAdviserAccess = false;

    this.assistantObj = {
      name: '',
      familyId: 0,
    };

    this.setInvolvedPartiesOnLoad();
    this.setUITitles();
  }

  setUITitles() {
    if (this.showSelectAdviser) {
      this.title = 'Create New Opportunity';
      this.titleSubtext = 'Who will be involved in this Opportunity?';
      this.saveLabel = 'Create Opportunity';
    } else {
      this.title = 'Manage Involved Parties';
      this.titleSubtext = '';
      this.saveLabel = 'Save Changes';
    }
  }

  setInvolvedPartiesOnLoad() {
    if (this.involvedParties) {
      const { InvolvedPartyPerson, InvolvedPartyEntity } = this.involvedParties;
      this.onLoadGetIncludedIds(InvolvedPartyPerson);
      this.onLoadSetPersons(InvolvedPartyPerson);
      this.onLoadSetEntities(InvolvedPartyEntity);
    }

    this.userService.GetUserInfo().then(response => {
      const { data } = response;
      if (!data) return;
      const { AccessType, FamilyId, FullName } = data;
      const isUserAdviser =
        AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
        AccessType === ACCESS_TYPE.ADVISER;
      const isUserAssistant =
        AccessType === ACCESS_TYPE.ADMIN_ASSISTANT ||
        AccessType === ACCESS_TYPE.ASSISTANT;
      this.showAdviserLabel = false;
      this.showAdvisers = false;

      if (isUserAdviser) {
        this.selectedAdviserId = this.selectedAdviserId || FamilyId;
        this.showAdviserLabel = true;
        this.showAdvisers = false;
        this.onLoadGetAdviser();
      } else if (isUserAssistant) {
        this.assistantObj = {
          name: FullName,
          familyId: FamilyId,
        };
        this.onLoadGetAdviser();
      }
    });
  }

  onLoadGetIncludedIds(InvolvedPartyPerson) {
    forEach(InvolvedPartyPerson, val => {
      this.includedIds.push(val.PersonId);
    });
  }

  onLoadSetPersons(InvolvedPartyPerson) {
    this.saveDisabled = true;
    forEach(InvolvedPartyPerson, val => {
      const clientType = val.IsApplicant
        ? CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER
        : CONTACT_REQUESTER.REQUESTER_TYPE.GUARANTOR;
      const isInCurrentList =
        this.existingFamilies.indexOf(val.FamilyId) !== -1;
      if (!isInCurrentList) {
        this.getClientInformation(val, clientType, true, false, true);
      }
    });
    this.saveDisabled = false;
  }

  getClientInformation(
    family,
    requesterType,
    updateDisplayList = true,
    isNew = false,
    isInitialLoad = false
  ) {
    const returnData = [];
    const FamilyId =
      family.FamilyId ||
      family.FamilyID ||
      family.ResultFamilyID ||
      family.familyId;
    let idx = 0;
    const displayData = [];
    this.addToExistingFamilies(FamilyId);

    this.contactService.clientInformGet(FamilyId).then(clients => {
      forEach(clients.data, value => {
        if (value.Role === 'Adult') {
          if (isNew) {
            family.partyApplicants[idx].PersonId = value.PersonId;
          }
          const isInitiallyExcluded =
            isInitialLoad && this.includedIds.indexOf(value.PersonId) === -1;
          if (isInitiallyExcluded) return;
          displayData.push(value);
          returnData.push({
            ClientEntityId: value.PersonId,
            IsClient: true,
            AddRelatedChildren:
              requesterType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER,
            ClientName: `${value.FirstName} ${value.LastName}`,
            FamilyId,
          });
        }
        idx++;
      });
      if (updateDisplayList) {
        this.storeClientDisplayList(FamilyId, displayData, requesterType);
      } else if (!updateDisplayList && isNew) {
        this.storeClientDisplayList(
          FamilyId,
          family.partyApplicants,
          requesterType
        );
      }
      this.storeClientList(returnData, requesterType);
    });
  }

  onLoadSetEntities(InvolvedPartyEntity) {
    forEach(InvolvedPartyEntity, val => {
      const isInCurrentList =
        this.existingFamilies.indexOf(val.FamilyId) !== -1;
      if (!isInCurrentList) {
        this.getEntityInformation(
          val.FamilyId,
          CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER
        );
      }
    });
  }

  onLoadGetAdviser() {
    if (!this.selectedAdviserId) return;
    this.contactService
      .clientInformGet(this.selectedAdviserId)
      .then(response => {
        const { data } = response;
        if (!data || !data.length) return;

        const adviserObj = data[0];
        this.selectedAdviser = {
          ...adviserObj,
          AdviserFamilyId: this.selectedAdviserId,
        };
      });
  }

  saveParties() {
    if (!this.selectedAdviser && this.showSelectAdviser) {
      this.toaster.pop({
        type: 'error',
        body: 'Please set an adviser.',
      });
      return false;
    }

    const { borrower, guarantor } = this.formData;
    const borrowerList = this.convertFormDataToAPIData(borrower);
    if (!borrowerList.length) {
      this.toaster.pop({
        type: 'error',
        body: 'Please fill at least 1 Borrower. ',
      });
      return false;
    }

    const guarantorList = this.convertFormDataToAPIData(guarantor);
    const sendParam = {
      Borrowers: borrowerList,
      Guarantors: guarantorList,
    };
    this.saveDisabled = true;

    if (this.selectedAdviser && this.showSelectAdviser) {
      this.postNewOpportunity({
        ...sendParam,
        AllocatedAdviserID: this.selectedAdviser.AdviserFamilyId,
        AssistantFamilyId: this.assistantObj.familyId,
        isFromManageParties: true,
      });
    } else {
      this.postManageParties(sendParam);
    }
  }

  postManageParties(params) {
    this.loanOpportunityService
      .setInvolvedParties(this.opportunityId, params)
      .then(
        () => {
          toastSaveSuccess(this.toaster);
          this.modalInstance.close();
        },
        () => {
          this.saveDisabled = false;
        }
      );
  }

  postNewOpportunity(params) {
    this.modalInstance.close({ ...params });
  }

  setAssistant(familyId) {
    this.assistantObj.familyId = familyId;
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  companyModal(partyType, requestType) {
    const modalInstance = this.openCompanyModal(null, partyType);

    modalInstance.result.then(response => {
      const { FamilyId, FamilyID } = response;
      const entityId = FamilyID || FamilyId;
      if (requestType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER) {
        this.getEntityInformation(
          entityId,
          CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER
        );
      } else {
        this.getEntityInformation(
          entityId,
          CONTACT_REQUESTER.REQUESTER_TYPE.GUARANTOR
        );
      }
    });
  }

  editCompany(requestType, entity) {
    const partyType = ENTITY_PARTY_TYPES.COMPANY;
    const modalInstance = this.openCompanyModal(entity, partyType);

    modalInstance.result.then(response => {
      const { FamilyId } = response;
      this.getEntityInformation(FamilyId, requestType, true);
    });
  }

  openCompanyModal(entity, partyType) {
    return this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/clientAssess/modal/personCompany.add.html',
      controller: 'AddCompanyRelatedModalCtrl',
      size: 'md',
      resolve: {
        selectedPartyType: () => String(partyType),
        person: () => entity,
        requester: () => CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL,
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  removeFromExistingFamilies(FamilyId) {
    this.existingFamilies = this.existingFamilies.filter(val => {
      return parseInt(val, 10) !== parseInt(FamilyId, 10);
    });
  }

  removeFamily(requestType, family) {
    if (!family || !family[0]) return;
    const familyId = family[0].FamilyId;
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      const index = findIndex(
        this.displayFamily.borrower,
        obj => obj === family
      );
      this.displayFamily.borrower.splice(index, 1);
    } else {
      const index = findIndex(
        this.displayFamily.guarantor,
        obj => obj === family
      );
      this.displayFamily.guarantor.splice(index, 1);
    }
    this.removeFromExistingFamilies(familyId);
    this.removeFromFamilyFormData(requestType, familyId);
  }

  removeFromFamilyFormData(requestType, FamilyId) {
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.formData.borrower = filterClientList(
        FamilyId,
        this.formData.borrower
      );
    } else {
      this.formData.guarantor = filterClientList(
        FamilyId,
        this.formData.guarantor
      );
    }
  }

  removeCompany(requestType, entity) {
    const { FamilyId } = entity;
    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.displayCompany.borrower = filterClientList(
        FamilyId,
        this.displayCompany.borrower
      );
      this.formData.borrower = filterClientList(
        FamilyId,
        this.formData.borrower
      );
    }
    this.removeFromExistingFamilies(FamilyId);
  }

  editFamily(type, family) {
    if (!family || !family[0]) return;
    const listApplicants =
      type === PERSON_PARTY_TYPES.APPLICANT
        ? this.displayFamily.borrower
        : this.displayFamily.guarantor;
    const modalInstance = this.openApplicantModal(
      family[0],
      type,
      listApplicants
    );

    modalInstance.result.then(response => {
      const { FamilyId, excludedIds, partyApplicants } = response;

      this.updateFamilyList(partyApplicants, FamilyId, family);

      const requesterType =
        type === PERSON_PARTY_TYPES.APPLICANT
          ? this.REQUESTER_TYPE.BORROWER
          : this.REQUESTER_TYPE.GUARANTOR;
      this.removeFromFamilyFormData(requesterType, FamilyId);
      this.getClientInformation(response, requesterType, false);
      this.spliceFamilyDisplayList(family, excludedIds);
    });
  }

  spliceFamilyDisplayList(family, excludedIds) {
    if (excludedIds && excludedIds.length) {
      const index = findIndex(family, o => o.PersonId === excludedIds[0]);
      family.splice(index, 1);
      this.excludedIds.push(excludedIds[0]);
    } else {
      forEach(family, o => {
        const index = this.excludedIds.indexOf(o.PersonId);
        if (index === -1) return;
        this.excludedIds.splice(index, 1);
      });
    }
  }

  updateFamilyList(partyApplicants, FamilyID, family) {
    let idx = 0;

    partyApplicants.forEach(member => {
      const memberData = this.clientDisplayListModel(FamilyID, member);
      family.splice(idx, 1, memberData);
      idx++;
    });
  }

  personModal(type = 1, requestType, assignedAdviserIdFromRequester) {
    this.showAddExistingPanel(0);
    const person = null;
    const modalInstance = this.openApplicantModal(
      person,
      type,
      null,
      assignedAdviserIdFromRequester
    );

    modalInstance.result.then(response => {
      this.getClientInformation(response, requestType, false, true);
    });
  }

  openApplicantModal(
    person,
    type,
    listApplicants,
    assignedAdviserIdFromRequester
  ) {
    return this.$uibModal.open({
      templateUrl:
        '/assets/views/loanApplication/clientAssess/modal/addApplicants_v2.html',
      controller: 'ApplicantModalCtrl',
      size: 'lg',
      resolve: {
        selectedPartyType: () => `${type}`,
        type: () => `${type}`,
        person: () => person,
        requester: () => CONTACT_REQUESTER.REQUESTER.NEW_LOAN_APP_MODAL,
        listApplicants: () => listApplicants,
        assignedAdviserIdFromRequester: () => +assignedAdviserIdFromRequester,
      },
      backdrop: 'static',
      keyboard: false,
    });
  }

  searchClient(searchType) {
    this.clientList = [];
    this.$timeout.cancel(this.searchTimeOut);
    const isSearchParamsValid =
      this.searchClientName.length >= 3 &&
      this.selectedAdviser &&
      this.selectedAdviser.AdviserFamilyId;
    if (isSearchParamsValid) {
      this.searchTimeOut = this.$timeout(() => {
        this.loanScenarioService
          .scenarioSearchClientGet(this.searchClientName, searchType)
          .then(response => {
            if (!response || !response.data) return;
            const { data: familyList } = response;
            this.showClientList = true;
            if (familyList.length) {
              const clientList = familyList.filter(obj => {
                if (obj.FirstName)
                  obj.FullName = obj.LastName
                    ? `${obj.FirstName} ${obj.LastName}`
                    : obj.FirstName;
                if (searchType === this.SHOW_EXISTING_PANEL.GUARANTOR.PERSON)
                  return obj.IsClient;
                return obj;
              });
              this.clientList = clientList.length
                ? clientList.filter(
                    obj => this.existingFamilies.indexOf(obj.FamilyID) === -1
                  )
                : [];
            }
          })
          .catch(() => {
            this.showClientList = false;
          });
      }, 300);
    } else {
      this.showClientList = false;
    }
  }

  selectClient(clientData, requesterType) {
    if (requesterType === this.SHOW_EXISTING_PANEL.BORROWER.COMPANY) {
      this.getEntityInformation(
        clientData.FamilyID || clientData.FamilyId,
        this.REQUESTER_TYPE.BORROWER
      );
    } else if (requesterType === this.SHOW_EXISTING_PANEL.BORROWER.PERSON) {
      this.getClientInformation(clientData, this.REQUESTER_TYPE.BORROWER);
    } else if (requesterType === this.SHOW_EXISTING_PANEL.GUARANTOR.PERSON) {
      this.getClientInformation(clientData, this.REQUESTER_TYPE.GUARANTOR);
    }
    this.showClientList = false;
    this.showThisPanel = false;
  }

  showAddExistingPanel(panelNumber) {
    this.showThisPanel = panelNumber;
    this.searchClientName = '';
  }

  clientDisplayListModel(FamilyId, member) {
    member.FullName = `${member.FirstName} ${member.LastName}`;
    return {
      ...member,
      initials: this.utilitiesService.filterInitial(
        member.FirstName,
        member.LastName
      ),
      FamilyId,
    };
  }

  entityDisplayListModel(FamilyId, entity) {
    return {
      ...entity,
      FamilyId,
      ClientName: entity.Name,
      ClientInitials: this.utilitiesService.filterInitialOneString(entity.Name),
    };
  }

  storeClientDisplayList(FamilyId, familyData, requestType) {
    const returnData = [];
    forEach(familyData, member => {
      if (member.Role === 'Adult') {
        returnData.push(this.clientDisplayListModel(FamilyId, member));
      }
    });

    if (requestType === this.REQUESTER_TYPE.BORROWER) {
      this.displayFamily.borrower.push(returnData);
    }
    if (requestType === this.REQUESTER_TYPE.GUARANTOR) {
      this.displayFamily.guarantor.push(returnData);
    }
  }

  storeClientList(familyData, requestType) {
    const prop =
      requestType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER
        ? 'borrower'
        : 'guarantor';
    const formData = { ...this.formData };

    if (!formData[prop] || (formData[prop] && !formData[prop].length))
      formData[prop] = [];
    formData[prop] = [...formData[prop], ...familyData];
    this.formData = { ...formData };
  }

  getEntityInformation(FamilyId, requesterType, isEditData = false) {
    const returnData = [];
    this.saveDisabled = true;
    this.addToExistingFamilies(FamilyId);
    this.contactService
      .organisationInfoGet(FamilyId)
      .then(response => {
        const { data } = response;
        returnData.push({
          ClientEntityId: data.OrganisationId,
          IsClient: false,
          AddRelatedChildren: false,
          FamilyId,
          ClientName: data.Name,
        });

        if (!isEditData) {
          this.storeEntityDisplayList(response.data, FamilyId, requesterType);
        } else {
          const index = findIndex(
            this.displayCompany.borrower,
            obj => obj.FamilyId === FamilyId
          );
          this.displayCompany.borrower.splice(
            index,
            1,
            this.entityDisplayListModel(FamilyId, data)
          );

          const idx = this.formData.findIndex(
            obj => obj.ClientEntityId === FamilyId
          );
          this.formData.borrower.splice(idx, 1);
        }
        this.storeClientList(returnData, requesterType);
      })
      .finally(() => {
        this.saveDisabled = false;
      });
  }

  storeEntityDisplayList(entity, FamilyId, requesterType) {
    if (requesterType === CONTACT_REQUESTER.REQUESTER_TYPE.BORROWER) {
      this.displayCompany.borrower.push(
        this.entityDisplayListModel(FamilyId, entity)
      );
    }
  }

  getAdvisersList() {
    this.userService.GetUserInfo().then(response => {
      this.allocatedAdviserList = null;
      this.assistantAdvisersList = null;
      this.advisersList = null;
      this.AssignedAdviserId = 0;
      this.userInfo = response.data;

      if (
        this.userInfo.AccessType === ACCESS_TYPE.ADVISER ||
        this.userInfo.AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER
      ) {
        this.corporateService
          .getAdvisersDropdown(this.adviserFamilyId, 0, 0, true)
          .then(brokers => {
            if (!brokers.data || !brokers.data.length) return;
            this.advisersList = brokers.data;
            this.AssignedAdviserId = brokers.data[0].FamilyId;
          });
      } else {
        this.corporateService
          .getAdvisersDropdown(this.userInfo.FamilyId, 0, 0, true)
          .then(getAdvisersDropResponse => {
            if (
              !getAdvisersDropResponse.data ||
              !getAdvisersDropResponse.data.length
            )
              return;
            if (this.userInfo.AccessType === ACCESS_TYPE.CORPORATE) {
              this.allocatedAdviserList = filter(
                getAdvisersDropResponse.data,
                data => {
                  return data !== '';
                }
              );
            } else {
              this.assistantAdvisersList = filter(
                getAdvisersDropResponse.data,
                data => {
                  return data.FullName !== '';
                }
              );
              this.AssignedAdviserId = this.assistantAdvisersList[0].FamilyId;
            }
            this.advisersList =
              this.allocatedAdviserList || this.assistantAdvisersList;
          });
      }
    });
  }

  getLendingCategories() {
    this.optionsService.getLendingCategories().then(
      response => {
        if (!response || !response.length) return;
        this.lendingCategories = response;
        this.selectedLendingCategory = this.lendingCategories[0];
      },
      () => {
        this.lendingCategories = [];
      }
    );
  }

  resetAdviser() {
    this.showAdvisers = false;
    this.showAdviserLabel = false;

    this.allocatedAdviser = {
      adviserSelected: {},
      adviserInitialValue: '',
    };
    this.selectedAdviser = null;
    this.disableAdding();
  }

  disableAdding() {
    this.isAddingDisabled = true;

    this.formData = {
      borrower: [],
      guarantor: [],
    };
    this.displayFamily = {
      borrower: [],
      guarantor: [],
    };
    this.displayCompany = {
      borrower: [],
    };
    this.clientList = [];
    this.existingFamilies = [];
  }

  showAdvisersField(clearField) {
    if (clearField) {
      this.resetAdviser();
      this.$scope.$broadcast('angucomplete-alt:clearInput');
    }
    this.showAdvisers = true;
    this.showAdviserLabel = false;
  }

  cancelAdviserChanges(adviser) {
    if (adviser) {
      this.selectedAdviser = adviser;
      this.showAdvisers = false;
      this.showAdviserLabel = true;
    } else {
      this.showAdvisers = false;
      this.showAdviserLabel = false;
    }
  }

  setAdviser(adviser = null) {
    if (!adviser) {
      const { originalObject } = this.allocatedAdviser.adviserSelected;
      this.selectedAdviser = {
        ...originalObject,
        AdviserFamilyId: originalObject.BrokerId || originalObject.FamilyId,
        AdviserInitials: this.utilitiesService.filterInitialOneString(
          originalObject.FullName
        ),
      };
    } else {
      this.selectedAdviser = {
        ...adviser,
        AdviserFamilyId: adviser.BrokerId || adviser.FamilyId,
        AdviserInitials: this.utilitiesService.filterInitialOneString(
          adviser.FullName
        ),
      };
      this.allocatedAdviser.adviserInitialValue = adviser.FullName;
    }
    this.showAdvisers = false;
    this.showAdviserLabel = true;
    this.isAddingDisabled = false;
  }

  convertFormDataToAPIData(listObject) {
    if (!listObject) return false;
    const arrayObject = listObject
      .filter(family => this.excludedIds.indexOf(family.ClientEntityId) === -1)
      .map(obj => {
        const {
          ClientEntityId,
          AddRelatedChildren,
          IsClient,
          FamilyId,
          ClientName: DisplayName,
        } = obj;
        return {
          ClientEntityId,
          AddRelatedChildren,
          IsClient,
          FamilyId,
          DisplayName,
        };
      });
    return arrayObject;
  }

  setFirstHomeBuyers(loanAppId) {
    const borrower = this.formData.borrower.filter(obj => obj.IsClient);
    const guarantor = this.formData.guarantor.filter(obj => obj.IsClient);

    const fullList = [...borrower, ...guarantor];
    forEach(fullList, member => {
      this.loanScenarioService.LoanAppIsFirstHomeBuyerSet(
        member.ClientEntityId,
        loanAppId,
        member.IsFirstHomeBuyer
      );
    });
  }

  addToExistingFamilies(FamilyId) {
    this.existingFamilies.push(FamilyId.toString());
  }

  checkUserInfo() {
    this.userService.GetUserInfo().then(response => {
      const { AccessType, FamilyId } = response.data;
      this.isAdviserAccess = false;
      const isAdviser =
        AccessType === ACCESS_TYPE.ADVISER ||
        AccessType === ACCESS_TYPE.PRINCIPAL_ADVISER;
      if (isAdviser) {
        this.setAdviser(response.data);
        this.allocatedAdviser.adviserSelected = {
          originalObject: response.data,
        };
        this.adviserFamilyId = FamilyId;
        this.isAdviserAccess = true;
      }
    });
  }
}
