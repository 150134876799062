import angular from 'angular';

angular
  .module('app')
  .controller(
    'PreApprovalDetailsModalCtrl',
    function PreApprovalDetailsModalCtrl(
      $scope,
      $uibModalInstance,
      preApproval
    ) {
      $scope.preApproval = preApproval;
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
