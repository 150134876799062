import angular from 'angular';
import _ from 'lodash';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { brokerStatusFilterProspect } from 'Common/utilities/brokerStatusFilterProspect';
import { bitwiseXOR } from 'Common/utilities/math';

angular
  .module('app')
  .factory('dataService', function dataService(
    corporateModelService,
    broadcastDataService,
    wmComplianceService,
    corporateService,
    commonFnService,
    contactService,
    optionsService,
    generalService,
    configService,
    loanToolsService,
    $q
  ) {
    /*
     *   =====================================
     *         FUNCTION DEFINITION(S)
     *   =====================================
     */
    const getAdviserOrganizationEntityTypes = () => {
      const defer = $q.defer();
      corporateService.adviserOrganizationEntityTypesGet().then(
        response => {
          const adviserOrganizationEntityTypesGet = [];
          if (response) {
            _.forEach(response.data, value => {
              adviserOrganizationEntityTypesGet.push(value);
            });

            return defer.resolve(adviserOrganizationEntityTypesGet);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getAdvisersUnderOrganization = id => {
      const defer = $q.defer();
      corporateService.advisersUnderOrganizationGet(id).then(
        response => {
          let advisersUnderOrganizations = [];
          if (response) {
            advisersUnderOrganizations = response.data;
            return defer.resolve(advisersUnderOrganizations);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getAdviserPipelineCategory = () => {
      const defer = $q.defer();
      generalService.adviserPipelineCategoryGet().then(
        response => {
          const adviserPipelineCategories = [];
          if (response) {
            _.forEach(response.data, value => {
              adviserPipelineCategories.push(value);
            });
            return defer.resolve(adviserPipelineCategories);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getRecruitmentStatusLists = () => {
      const defer = $q.defer();
      corporateService.recruitmentStatusListGet().then(
        response => {
          let recruitmentStatusLists = [];
          if (response) {
            recruitmentStatusLists = response;
            return defer.resolve(recruitmentStatusLists);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getAssistantStatusLists = () => {
      let statusLists = [];
      statusLists = [
        { value: 1, name: 'Active' },
        { value: 0, name: 'Inactive' },
      ];
      return statusLists;
    };

    const getQualificationDetails = () => {
      const defer = $q.defer();
      wmComplianceService.qualificationsGet().then(
        response => {
          if (response) {
            const qualifications = [];
            angular.forEach(response.data, value => {
              qualifications.push(value);
            });
            return defer.resolve(qualifications);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getDateValidityFormats = () => {
      let altInputFormats = [];
      altInputFormats = ['dd-MM-yyyy', 'dd/MM/yyyy'];
      return altInputFormats;
    };

    const getOperatingCountries = () => {
      const defer = $q.defer();
      corporateService.operatingCountriesGet().then(
        response => {
          const operatingCountries = [];
          if (response) {
            _.forEach(response.data, (value, key) => {
              if (key < 3) {
                operatingCountries.push(value);
              }
            });
            return defer.resolve(operatingCountries);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getAddressTypeDetails = () => {
      let addressTypeDetails = [];
      addressTypeDetails = [
        { id: 1, name: 'Business Address' },
        { id: 2, name: 'Home Address' },
        { id: 3, name: 'Mailing Address' },
      ];
      return addressTypeDetails;
    };

    const getIndustryMembership = () => {
      let industryMemberships = [];
      industryMemberships = [
        { id: 1, name: 'AFA' },
        { id: 2, name: 'FPA' },
        { id: 3, name: 'SPAA' },
      ];
      return industryMemberships;
    };

    const getBrandingCategories = countryId => {
      const defer = $q.defer();
      corporateService.brandingCategoryListGet(countryId).then(
        response => {
          const brandingCategoryListGet = [];
          if (response) {
            response.data.forEach(value => {
              brandingCategoryListGet.push(value);
            });
            return defer.resolve(brandingCategoryListGet);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getOrganizationTypes = () => {
      const defer = $q.defer();
      corporateService.organizationTypesGet().then(
        response => {
          let organizationTypesGet = [];
          if (response) {
            organizationTypesGet = response.data;
            return defer.resolve(organizationTypesGet);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getCorpLeadCampaign = () => {
      const defer = $q.defer();
      corporateService.corpLeadCampaignGet().then(
        response => {
          const corpLeadCampaignGet = [];
          _.forEach(response.data, value => {
            corpLeadCampaignGet.push(value);
          });
          return defer.resolve(corpLeadCampaignGet);
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getEmploymentDetail = () => {
      const defer = $q.defer();
      wmComplianceService.employmentRoleGet().then(
        response => {
          const employmentRoles = [];
          if (response) {
            _.forEach(response.data, value => {
              employmentRoles.push(value);
            });
            return defer.resolve(employmentRoles);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getContactTypeRoles = () => {
      const defer = $q.defer();
      corporateService.contactTypeRolesGet().then(
        response => {
          const contactTypeRoles = [];
          if (response) {
            response.data.forEach(value => {
              contactTypeRoles.push(value);
            });
            return defer.resolve(contactTypeRoles);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getReportingRegions = countryId => {
      const defer = $q.defer();
      corporateService.reportingRegionsGet(countryId).then(
        response => {
          const reportingRegions = [];
          if (response) {
            response.data.forEach(value => {
              reportingRegions.push(value);
            });
            return defer.resolve(reportingRegions);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getAdviserOrgTypes = () => {
      const defer = $q.defer();
      corporateService.adviserOrgTypesGet().then(
        response => {
          const adviserOrgTypesGet = [];
          if (response) {
            _.forEach(response.data, value => {
              adviserOrgTypesGet.push(value);
            });
            return defer.resolve(adviserOrgTypesGet);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getCommissionTiers = (userId, commissionTierId) => {
      return corporateModelService
        .getCommissionTiers(userId, commissionTierId)
        .then(response => {
          if (!response || !response.data) return;
          return response.data.map(item => item);
        });
    };

    const getAdviserOrgLists = id => {
      const defer = $q.defer();
      corporateService.adviserOrganizationListGet().then(
        response => {
          if (response) {
            let selectedAdviserOrg = [];
            const adviserOrg = [];
            let isEmptyOrg = true;

            _.forEach(response.data, value => {
              if (value.AdviserOrganizatioName) {
                value.icon = '<i class="fa fa-cog violet-color"></i>';
                if (id === value.AdviserOrganizationId) {
                  value.ticked = true;
                  selectedAdviserOrg.push(value);
                  isEmptyOrg = false;
                } else {
                  value.ticked = false;
                }
                if (value.ticked) {
                  adviserOrg.unshift(value);
                } else {
                  adviserOrg.push(value);
                }
              }
            });

            if (isEmptyOrg) {
              const data = {
                AdviserOrganizationId: 0,
                AdviserOrganizatioName: 'Please Select Org',
                AddressId: 0,
                Phone: '',
                Fax: '',
                ticked: true,
              };
              data.icon = '<i class="fa fa-cog violet-color"></i>';
              adviserOrg.unshift(data);
              selectedAdviserOrg = adviserOrg.length > 0 ? adviserOrg[0] : [];
            }
            const adviserOrgData = {
              selected: selectedAdviserOrg,
              data: adviserOrg,
              isEmptyOrg,
            };
            defer.resolve(adviserOrgData);
          }
        },
        err => {
          defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getLevelAccessList = () => {
      let levelAccessList = [];
      levelAccessList = [
        { id: 1, value: 'Email Only' },
        { id: 2, value: 'Full system access' },
        { id: 3, value: 'MyCRM access with no charge' },
      ];
      return levelAccessList;
    };

    const getCessationReason = () => {
      const defer = $q.defer();
      corporateService.cessationReasonGet().then(
        response => {
          const cessationReasons = [];
          if (response) {
            _.forEach(response.data, value => {
              cessationReasons.push(value);
            });
            return defer.resolve(cessationReasons);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getPreVetStatuses = () => {
      let preVetStatuses = [];
      preVetStatuses = [
        { id: 1, name: 'Completed' },
        { id: 2, name: 'Incomplete' },
        { id: 3, name: 'Extended' },
      ];
      return preVetStatuses;
    };

    const getSpecialCourses = () => {
      const defer = $q.defer();
      wmComplianceService.specialistCourseGet().then(
        response => {
          if (response) {
            const specialistCourses = [];
            _.forEach(response.data, value => {
              specialistCourses.push(value);
            });
            return defer.resolve(specialistCourses);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getLeadPriorities = () => {
      let leadPriorities = [];
      leadPriorities = [
        { id: 1, name: 'Level 1' },
        { id: 2, name: 'Level 2' },
        { id: 3, name: 'Level 3' },
      ];
      return leadPriorities;
    };

    const getBrokerStatus = () => {
      const defer = $q.defer();
      corporateService.brokerStatusListGet().then(
        response => {
          if (response && response.data) {
            const brokerStatusLists = [];
            const brokerStatusFilterProspectData = brokerStatusFilterProspect(
              response.data
            );
            brokerStatusLists.push(...brokerStatusFilterProspectData);
            defer.resolve(brokerStatusLists);
          }
        },
        err => {
          defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getAddressTypes = () => {
      let addressTypes = [];
      addressTypes = ['Business Address', 'Home Address', 'Mailing Address'];
      return addressTypes;
    };

    const getAuditRatings = () => {
      let auditRatings = [];
      auditRatings = [
        { id: 1, name: 'Low' },
        { id: 2, name: 'Medium' },
        { id: 3, name: 'High' },
        { id: 4, name: 'Critical' },
      ];
      return auditRatings;
    };

    const getRecentViewed = () => {
      broadcastDataService.recentView.get().then(res => {
        broadcastDataService.recentView.list = res;
      });
    };
    const getMortageTools = () => {
      let mortageTools = [];
      mortageTools = ['None', 'Full', 'Limited'];
      return mortageTools;
    };

    const getFontClasses = contactType => {
      let fontClass = [];
      switch (contactType) {
        case 'Assistant':
          fontClass = ['fa-ban text-white', 'fa-check text-white'];
          break;
        default:
          fontClass = [
            'fa-paper-plane text-white',
            'fa-check text-white',
            'violet-color fa-times',
            'fa-search text-white',
            'fa-hand-paper-o text-white',
            'fa-ban text-white',
          ];
          break;
      }
      return fontClass;
    };

    const getGenderTypes = () => {
      let genders = [];
      genders = [
        { id: 1, value: 'Male' },
        { id: 2, value: 'Female' },
        { id: 3, value: 'Unknown' },
      ];
      return genders;
    };

    const getBtnClasses = contactType => {
      let btnClass = [];
      switch (contactType) {
        case 'Assistant':
          btnClass = ['btn-danger', 'btn-light-blue'];
          break;
        default:
          btnClass = [
            'btn-success',
            'btn-light-blue',
            'btn-default',
            'btn-warning',
            'btn-info',
            'btn-danger',
          ];
          break;
      }
      return btnClass;
    };

    const getAccessType = contactType => {
      const defer = $q.defer();
      optionsService.brokerAccessTypes().then(
        response => {
          if (response && response.data) {
            const accessTypes = [];
            _.forEach(response.data, value => {
              switch (contactType) {
                case 'Adviser':
                  if (value.AccessTypeId < 3) {
                    accessTypes.push(value);
                  }
                  break;
                case 'Assistant':
                  if (value.AccessTypeId > 2) {
                    accessTypes.push(value);
                  }
                  break;
                default:
                  break;
              }
            });
            return defer.resolve(accessTypes);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };
    const getSetterTypes = contactType => {
      let setterTypes = [];
      switch (contactType) {
        case 'Adviser':
          setterTypes = [
            'contactSet',
            'familyComplianceSet',
            'familyMarketingDetailSet',
            'familyWebManagementSet',
            'familyBrokerSupportSet',
            'leadsProfileSet',
            'familyRecruitmentDetailsSet',
            'adviserPreviousNameSet',
            'appointmentDetailSet',
            'employmentDetailSet',
            'educationDetailSet',
            'qualificationDetailsSet',
            'recentAuditResultSet',
            'stdPreVetSet',
            'specialistAdviceSRSet',
            'generalSet',
            'familyAchievementSet',
          ];
          break;
        case 'Adviser Org':
          setterTypes = ['adviserOrganizationSet'];
          break;
        case 'Referrer Org':
          setterTypes = [
            'referrerOrgSet',
            'referrerOrgDirectorSet',
            'setReferrerOrgMembership',
            'setReferrerOrgRelationship',
          ];
          break;
        default:
          break;
      }

      return setterTypes;
    };

    const getAdviceTypes = () => {
      const defer = $q.defer();
      wmComplianceService.adviceTypeGet().then(
        response => {
          if (response) {
            const adviceTypes = [];
            _.forEach(response.data, value => {
              adviceTypes.push(value);
            });
            return defer.resolve(adviceTypes);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const getAuthLevels = () => {
      let authLevels = [];
      authLevels = [
        { id: 1, name: 'Level 1' },
        { id: 2, name: 'Level 2' },
        { id: 3, name: 'Level 3' },
      ];
      return authLevels;
    };

    const getAdminUsers = () => {
      const defer = $q.defer();
      contactService.getAdminUser().then(
        response => {
          let adminUsers = [];
          if (response) {
            adminUsers = response.data;
            defer.resolve(adminUsers);
          }
        },
        err => {
          defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getNameTitles = () => {
      let nameTitles = [];
      nameTitles = ['Mr.', 'Ms.', 'Mrs.'];
      return nameTitles;
    };

    const getEdrSchemes = () => {
      const defer = $q.defer();
      generalService.eDRSchemesGet().then(
        response => {
          let eDRSchemes = [];
          if (response) {
            eDRSchemes = response.data;
            defer.resolve(eDRSchemes);
          }
        },
        err => {
          return defer.reject(err);
        }
      );
      return defer.promise;
    };

    const getAllMonths = () => {
      let reviewMonths = [];
      reviewMonths = [
        'No Review Month',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      return reviewMonths;
    };

    const getSaveTypes = contactType => {
      let saveTypes = {};
      switch (contactType) {
        case 'Adviser':
          saveTypes = {
            all: 0,
            adviserDetails: 1,
            additionalDetails: 2,
            franchiseDetails: 3,
          };
          break;
        case 'Adviser Org':
          saveTypes = {
            all: 0,
            businessDetails: 1,
            contact: 2,
            agreements: 3,
            marketing: 4,
          };
          break;
        default:
          break;
      }
      return saveTypes;
    };

    const getUserInfo = isDetails => {
      const defer = $q.defer();
      contactService.getUserInfo().then(response => {
        if (isDetails) {
          return defer.resolve(response);
        }
        if (response.data.CorpAccessTypeID === 1) {
          return defer.resolve(true);
        }
        return defer.resolve(false);
      });
      return defer.promise;
    };

    const getWMSplits = () => {
      let splits = [];
      splits = [{ id: 1, name: 'TBC 1' }, { id: 2, name: 'TBC 2' }];
      return splits;
    };

    const getAllWeeks = () => {
      let allWeeks = [];
      allWeeks = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
      return allWeeks;
    };

    const getLoanType = () => {
      let loanType = [];
      loanType = [
        'Residential',
        'Commercial',
        'Reverse Mortgage',
        'SMSF',
        'Car',
        'Personal',
        'Low Doc',
        'Investment',
        'Refinance',
        'First Home Buyer',
      ];
      return loanType;
    };

    const getAdviserTabs = countryId => {
      const { userSubscription } = configService.feature;
      const showSubscriptionMangement = userSubscription || 0;
      const data = [
        {
          index: 0,
          name: 'Adviser Details',
          page: 'assets/views/corporate/adviser/adviser-details.html',
          value: 'adviser-details',
          show: 1,
        },
        {
          index: 1,
          name: 'Business Details',
          page: 'assets/views/corporate/adviser/franchisee-details.html',
          value: 'franchisee-details',
          show: 1,
        },
        {
          index: 2,
          name: 'Marketing',
          page: 'assets/views/corporate/adviser/marketing.html',
          value: 'marketing',
          show: 1,
        },
        {
          index: 3,
          name: 'Support Services',
          page: 'assets/views/corporate/adviser/broker-support.html',
          value: 'broker-support',
          show: 1,
        },
        {
          index: 4,
          name: 'Subscriptions',
          page: 'assets/views/corporate/adviser/subscriptions.html',
          value: 'subscriptions',
          show:
            showSubscriptionMangement && countryId === COUNTRY_TYPE.AUSTRALIA,
        },
        {
          index: 5,
          name: 'Agreements',
          page: 'assets/views/corporate/adviser/agreements.html',
          value: 'agreements',
          show: 1,
        },
        {
          index: 6,
          name: 'Accreditation',
          page: 'assets/views/corporate/adviser/accreditation.html',
          value: 'accreditation',
          show: 1,
        },
        {
          index: 7,
          name: 'Communications',
          page: 'assets/views/contacts/client/emails.html',
          value: 'communication',
          show: 1,
        },
        {
          index: 8,
          name: 'Notes',
          page: 'assets/views/contacts/client/notes.html',
          value: 'notes',
          show: 1,
        },
        {
          index: 9,
          name: 'Documents',
          page: 'assets/views/contacts/client/documents.html',
          value: 'documents',
          show: 1,
        },
      ];

      return data.filter(tab => {
        return tab.show;
      });
    };

    const mapFieldAccessMatrixes = (
      pageName = null,
      tabName = null,
      subTabName = null,
      panelName = null,
      fieldName = null
    ) => {
      const defer = $q.defer();
      /*
       * @var allReadOnly string will either be true for read ,etc.
       * @var fieldReadOnly Object, listing all status of each fields if confirming with current allReadOnly
       */
      const fieldSettingMode = {
        fieldReadOnly: {},
        allPanelReadOnly: {},
        allReadOnly: null,
      };
      corporateModelService
        .accessControlMatrixes(
          pageName,
          tabName,
          subTabName,
          panelName,
          fieldName
        )
        .then(response => {
          if (!response) return;
          /* need to find first the overall access status cause this is in the top layer of the array 4 condition to prove all access matrix */
          const defaultFieldSettingMode = response.find(
            data => data.fieldName === ''
          ) || { allowedAction: 'Read and Write' };
          fieldSettingMode.allReadOnly =
            defaultFieldSettingMode.allowedAction === 'Read Only';
          response.forEach(
            data => {
              /* We need to set this up first */
              if (data.panelName) {
                fieldSettingMode.allPanelReadOnly[data.panelName] =
                  data.allowedAction === 'Read Only';
              }
              if (fieldSettingMode.allReadOnly !== null && data.fieldName) {
                // checking if this data is null not true or false
                fieldSettingMode.fieldReadOnly[data.fieldName] =
                  (data.allowedAction === 'Read Only') !==
                  fieldSettingMode.allReadOnly;
              }
            },
            err => {
              defer.reject(err);
            }
          );
          defer.resolve(fieldSettingMode);
        });
      return defer.promise;
    };

    const checkAccessPermission = (allReadOnly, fieldStatus) => {
      /*
       * this will check all the possible scenario
       * if all field is readonly and no field specify = true
       * if all field is not readonly and field is specify to readonly = true
       * if all field is read and write and no field specify = true
       * if all field is not read and write and field is specify to read and write = true
       */
      // add value if undefined
      const fieldStatusValue =
        typeof fieldStatus !== 'undefined' ? fieldStatus : false;
      // Only use with ng-show
      if (allReadOnly === null) return false;
      // using bitwise XOR operation
      return bitwiseXOR(allReadOnly, fieldStatusValue);
    };

    const setDateFormatInView = date => {
      if (date && commonFnService.formatDateString(date) !== '1900-01-01') {
        return commonFnService.formatDate(date);
      }
      return 'None';
    };

    const getImportantDocFlags = () => {
      return generalService
        .getImportantDocumentFlags()
        .then(response => {
          const { data } = response;
          return data || [];
        })
        .catch(() => {
          return [];
        });
    };

    const getImportantDocuments = doctype => {
      const defer = $q.defer();
      getImportantDocFlags().then(importantDocumentFlags => {
        loanToolsService.getImportantDocs().then(
          data => {
            if (!data || !data.length) return defer.resolve({});
            data.forEach(doc => {
              const existingImportantDocFlags = importantDocumentFlags.filter(
                flag =>
                  Number(flag.DocumentFlagId) === Number(doc.documentFlagId)
              );
              if (existingImportantDocFlags.length) {
                if (existingImportantDocFlags[0].DocumentFlagDesc === doctype) {
                  return defer.resolve(doc);
                }
              }
            });
            return defer.resolve({});
          },
          err => {
            return defer.reject(err);
          }
        );
      });
      return defer.promise;
    };

    const getAssistantLists = adviserData => {
      return corporateService.getAssistantList(0, 0).then(assistants => {
        if (!assistants || !assistants.data) return;
        return adviserData
          ? [...adviserData, ...assistants.data]
          : assistants.data;
      });
    };
    /*
     *   =====================================
     *           FUNCTION REFERENCE
     *   =====================================
     */
    const service = {
      getAdviserOrganizationEntityTypes,
      getAdvisersUnderOrganization,
      getAdviserPipelineCategory,
      getRecruitmentStatusLists,
      getImportantDocFlags,
      getImportantDocuments,
      getAssistantStatusLists,
      getQualificationDetails,
      getAssistantLists,
      getDateValidityFormats,
      mapFieldAccessMatrixes,
      getOperatingCountries,
      checkAccessPermission,
      getAddressTypeDetails,
      getIndustryMembership,
      getBrandingCategories,
      getOrganizationTypes,
      getCorpLeadCampaign,
      getEmploymentDetail,
      setDateFormatInView,
      getContactTypeRoles,
      getReportingRegions,
      getAdviserOrgTypes,
      getCommissionTiers,
      getAdviserOrgLists,
      getLevelAccessList,
      getCessationReason,
      getPreVetStatuses,
      getSpecialCourses,
      getLeadPriorities,
      getBrokerStatus,
      getAddressTypes,
      getAuditRatings,
      getRecentViewed,
      getMortageTools,
      getAdviserTabs,
      getFontClasses,
      getGenderTypes,
      getSetterTypes,
      getAdviceTypes,
      getBtnClasses,
      getAccessType,
      getAdminUsers,
      getAuthLevels,
      getNameTitles,
      getEdrSchemes,
      getAllMonths,
      getSaveTypes,
      getUserInfo,
      getWMSplits,
      getAllWeeks,
      getLoanType,
    };
    return service;
  });
