import angular from 'angular';

angular
  .module('app')
  .controller('CompareLoanCalculatorCtrl', function CompareLoanCalculatorCtrl(
    $scope,
    $rootScope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    $filter,
    $element,
    $window,
    SweetAlert,
    $localStorage,
    DEMO_VIDEO,
    toaster,
    loanCalculatorSharedData,
    uiService,
    formattingService,
    optionsService
  ) {
    $scope.uiService = uiService;
    // Open Model For Demo Video
    $scope.openModalForDemoVideo = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId() {
            return DEMO_VIDEO.COMPARE_LOAN_CALCULATOR.Url;
          },
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };
    optionsService.getLoanTerms().then(loanTermResponse => {
      $scope.loanTermList = loanTermResponse;
    });
    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    const viewport = function() {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function() {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        $scope.screenSize = newValue.w;
      },
      true
    );

    $scope.series = ['My Lender', 'Other Lender'];

    $scope.colors = [
      {
        fillColor: 'rgba(220,220,220,0)',
        strokeColor: '#f9b7b7',
        pointColor: '#fff',
        pointStrokeColor: '#fd7575',
        pointHighlightFill: '#fd7575',
        pointHighlightStroke: '#fd7575',
      },
      {
        fillColor: 'rgba(151,187,205,0)',
        strokeColor: '#abc5de',
        pointColor: '#fff',
        pointStrokeColor: '#6297cb',
        pointHighlightFill: '#6297cb',
        pointHighlightStroke: '#6297cb',
      },
    ];
    $scope.options = loanCalculatorSharedData.chartOptions;
    $scope.MyLender = {
      RepaymentTableArray: [],
      RepaymentTableArrayChart: [],
      TitleArray: [],
    };
    $scope.OtherLender = {
      RepaymentTableArray: [],
      RepaymentTableArrayChart: [],
      TitleArray: [],
    };
    $scope.compare = {
      loanTermMine: 30,
      loanTermOther: 30,

      otherFeesFrequencyMine: 'Monthly',
      otherFeesFrequencyOther: 'Monthly',
      loanMine: $scope.uiService.isCountry('Indonesia') ? '400000' : '400,000',
      loanOther: $scope.uiService.isCountry('Indonesia') ? '400000' : '400,000',
      fixedRateMine: 5,
      fixedRateOther: 5,
      ongoingRateMine: 5,
      ongoingRateOther: 5,
      fixedPeriodMine: 0,
      fixedPeriodOther: 0,
      upFrontFeesMine: 0,
      upFrontFeesOther: 0,
      upFrontCashMine: 0,
      upFrontCashOther: 0,
      otherFeesMine: 0,
      otherFeesOther: 0,
      dischargedFeesMine: 0,
      dischargedFeesOther: 0,
    };
    $scope.resultPanelData = {};

    function getParsed(amount) {
      const replaced = amount.replace(/,/g, '');
      const parsed = parseFloat(replaced);
      return parsed;
    }
    $scope.formatToCurrency = formattingService.calculatorFormatCurrency;
    $scope.formatInterestRate = amount => {
      return formattingService.calculatorFormatInterestRate(amount);
    };

    $scope.showBottom = true;
    $scope.changeShowBottom = function(val) {
      $scope.showBottom = val;
    };
    $scope.loadTable = function() {
      const copiedObj = {};
      $scope.compareTtileArr = [];
      angular.copy($scope.compare, copiedObj);
      copiedObj.loanTermMine = parseInt(copiedObj.loanTermMine, 10);
      copiedObj.loanTermOther = parseInt(copiedObj.loanTermOther, 10);
      copiedObj.loanMine = parseFloat(getParsed(copiedObj.loanMine));
      copiedObj.loanOther = parseFloat(getParsed(copiedObj.loanOther));
      copiedObj.fixedRateMine = parseFloat(copiedObj.fixedRateMine);
      copiedObj.fixedRateOther = parseFloat(copiedObj.fixedRateOther);
      copiedObj.fixedPeriodMine = parseInt(copiedObj.fixedPeriodMine, 10);
      copiedObj.fixedPeriodOther = parseInt(copiedObj.fixedPeriodOther, 10);
      copiedObj.ongoingRateMine = parseFloat(copiedObj.ongoingRateMine);
      copiedObj.ongoingRateOther = parseFloat(copiedObj.ongoingRateOther);
      copiedObj.upFrontFeesMine = parseFloat(copiedObj.upFrontFeesMine);
      copiedObj.upFrontFeesOther = parseFloat(copiedObj.upFrontFeesOther);
      copiedObj.upFrontCashMine = parseFloat(copiedObj.upFrontCashMine);
      copiedObj.upFrontCashOther = parseFloat(copiedObj.upFrontCashOther);
      copiedObj.otherFeesMine = parseFloat(copiedObj.otherFeesMine);
      copiedObj.otherFeesOther = parseFloat(copiedObj.otherFeesOther);
      copiedObj.dischargedFeesMine = parseFloat(copiedObj.dischargedFeesMine);
      copiedObj.dischargedFeesOther = parseFloat(copiedObj.dischargedFeesOther);

      loanToolsService.getCompareLoanCalculations(copiedObj).then(response => {
        $scope.calculationObject = response.data;

        $scope.constructDataForGraph();
        $scope.constructDataForXSTableMyLender();
        $scope.constructDataForXSTableOtherLender();
        if (
          $scope.calculationObject.MyLender.TotalRepayments >=
          $scope.calculationObject.OtherLender.TotalRepayments
        ) {
          $scope.difference =
            $scope.calculationObject.MyLender.TotalRepayments -
            $scope.calculationObject.OtherLender.TotalRepayments;
          $scope.differenceText = 'My Lender Cost Difference v.s Other Lender';
        } else {
          $scope.difference =
            $scope.calculationObject.OtherLender.TotalRepayments -
            $scope.calculationObject.MyLender.TotalRepayments;
          $scope.differenceText = 'Other Lender Cost Difference v.s My Lender';
        }
      });
    };

    $scope.constructDataForGraph = function() {
      const arrMyLender = [];
      const arrOtherLender = [];
      $scope.labels = [];

      for (
        let i = 0;
        i < $scope.calculationObject.MyLender.RepaymentTableArrayChart.length;
        i++
      ) {
        const data =
          $scope.calculationObject.MyLender.RepaymentTableArrayChart[i];
        arrMyLender.push(data[1].toFixed(2));
        $scope.labels.push(i);
      }
      for (
        let i = 0;
        i <
        $scope.calculationObject.OtherLender.RepaymentTableArrayChart.length;
        i++
      ) {
        const data =
          $scope.calculationObject.OtherLender.RepaymentTableArrayChart[i];
        arrOtherLender.push(data[1].toFixed(2));
      }

      $scope.data = [arrMyLender, arrOtherLender];
      $scope.graphData = $scope.data;
      $scope.totalRepaymentAmountMyLender =
        $scope.calculationObject.MyLender.TotalRepayments;

      $scope.totalRepaymentAmountOtherLender =
        $scope.calculationObject.OtherLender.TotalRepayments;
    };

    $scope.constructDataForXSTableMyLender = function() {
      let maxPeriod = 0;
      let yrCount = 1;
      let prdCount = 1;
      const splicedArray = [];
      switch ($scope.compare.otherFeesFrequencyMine) {
        case 'Monthly':
          maxPeriod = 12;
          break;
        case 'Weekly':
          maxPeriod = 52;
          break;
        case 'Fortnightly':
          maxPeriod = 26;
          break;
        default:
          break;
      }

      $scope.myLenderTableArrayXs = [];
      angular.copy(
        $scope.calculationObject.MyLender.RepaymentTableArray,
        splicedArray
      );

      for (let i = 0; i < splicedArray.length; i++) {
        if (prdCount > maxPeriod) {
          prdCount = 1;
          yrCount += 1;
        }

        const row = splicedArray[i];
        $scope.myLenderTableArrayXs.push({
          id: i,
          selectText: `Year ${yrCount} Period ${prdCount}`,
          yearText: `Year ${yrCount}`,
          periodText: `Period ${prdCount}`,

          PrincipalPaid: row[0],
          PrincipalToPay: row[1],
          AccumulativePrincipalPaid: row[2],
          InterestPaid: row[3],
          AccumulativeInterestPaid: row[4],
        });

        prdCount += 1;
      }

      $scope.selectedRepaymentXsMyLender = $scope.myLenderTableArrayXs[0];
    };
    $scope.constructDataForXSTableOtherLender = function() {
      let maxPeriod = 0;
      let yrCount = 1;
      let prdCount = 1;
      const splicedArray = [];
      switch ($scope.compare.otherFeesFrequencyMine) {
        case 'Monthly':
          maxPeriod = 12;
          break;
        case 'Weekly':
          maxPeriod = 52;
          break;
        case 'Fortnightly':
          maxPeriod = 26;
          break;
        default:
          break;
      }

      $scope.otherLenderTableArrayXs = [];
      angular.copy(
        $scope.calculationObject.OtherLender.RepaymentTableArray,
        splicedArray
      );

      for (let i = 0; i < splicedArray.length; i++) {
        if (prdCount > maxPeriod) {
          prdCount = 1;
          yrCount += 1;
        }

        const row = splicedArray[i];
        $scope.otherLenderTableArrayXs.push({
          id: i,
          selectText: `Year ${yrCount} Period ${prdCount}`,
          yearText: `Year ${yrCount}`,
          periodText: `Period ${prdCount}`,

          PrincipalPaid: row[0],
          PrincipalToPay: row[1],
          AccumulativePrincipalPaid: row[2],
          InterestPaid: row[3],
          AccumulativeInterestPaid: row[4],
        });

        prdCount += 1;
      }

      $scope.selectedRepaymentXsOtherLender = $scope.otherLenderTableArrayXs[0];
    };

    function getLendersList() {
      loanToolsService.getLendersList().then(response => {
        $scope.lenderList = response.data;
      });
    }

    $scope.openPdfPreviewModal = function(size) {
      $uibModal.open({
        windowClass: 'pdfGenerator',
        templateUrl:
          '/assets/views/loanCalculator/partials/compare_loan_pdf.html',
        resolve: {
          loanProviderMine() {
            return $scope.loanProviderMine;
          },
          loanProviderOther() {
            return $scope.loanProviderOther;
          },
          graphData() {
            return $scope.graphData;
          },
          options() {
            return $scope.options;
          },
          labels() {
            return $scope.labels;
          },
          colors() {
            return $scope.colors;
          },
          loanAmountMine() {
            return $scope.compare.loanMine;
          },
          loanAmountOther() {
            return $scope.compare.loanOther;
          },
          loanTermMine() {
            return $scope.compare.loanTermMine;
          },
          loanTermOther() {
            return $scope.compare.loanTermOther;
          },
          interestRatesMine() {
            return $scope.compare.fixedRateMine;
          },
          interestRatesOther() {
            return $scope.compare.fixedRateOther;
          },
          otherFeesFrequencyMine() {
            return $scope.compare.otherFeesFrequencyMine;
          },
          otherFeesFrequencyOther() {
            return $scope.compare.otherFeesFrequencyOther;
          },
          interestSaved() {
            return $scope.interestSaved;
          },
          timeSaved() {
            return $scope.timeSaved;
          },
          totalRepaymentAmountMyLender() {
            return $scope.totalRepaymentAmountMyLender;
          },
          totalRepaymentAmountOtherLender() {
            return $scope.totalRepaymentAmountOtherLender;
          },
          differenceText() {
            return $scope.differenceText;
          },
          difference() {
            return $scope.difference;
          },
          myLenderFixedMonthlyRepayment() {
            return $scope.calculationObject.MyLender.FixedMonthlyRepayment;
          },
          otherLenderFixedMonthlyRepayment() {
            return $scope.calculationObject.OtherLender.FixedMonthlyRepayment;
          },
          myLenderOngoingMonthlyRepayments() {
            return $scope.calculationObject.MyLender.OngoingMonthlyRepayments;
          },
          otherLenderOngoingMonthlyRepayments() {
            return $scope.calculationObject.OtherLender
              .OngoingMonthlyRepayments;
          },
          series() {
            return $scope.series;
          },
          myLenderTableArrayXs() {
            return $scope.myLenderTableArrayXs;
          },
          otherLenderTableArrayXs() {
            return $scope.otherLenderTableArrayXs;
          },
        },
        otherLenderFixedMonthlyRepayment() {
          return $scope.calculationObject.MyLender.OtherLender;
        },
        controller: 'CompareLoanPdfPreviewModalCtrl',
        size,
      });
    };
    function init() {
      $scope.loadTable();
      getLendersList();
    }

    init();
  });
