import { isMobileValid } from 'Common/utilities/mobile';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class UsersProfileComponentCtrl {
  constructor(
    contactService,
    corporateService,
    commonFnService,
    broadcastDataService,
    usersProfileService,
    addressModalService,
    dataService,
    $timeout,
    toaster,
    configService,
    userService
  ) {
    'ngInject';

    this.userService = userService;
    this.contactService = contactService;
    this.corporateService = corporateService;
    this.commonFnService = commonFnService;
    this.broadcastDataService = broadcastDataService;
    this.usersProfileService = usersProfileService;
    this.addressModalService = addressModalService;
    this.configService = configService;
    this.dataService = dataService;
    this.$timeout = $timeout;
    this.toaster = toaster;
  }

  $onInit() {
    this.getUserInfo();
    this.editMode = this.usersProfileService.getEditMode();
    this.editModeKeys = this.usersProfileService.getEditModeKeys();
    this.editModeText = this.usersProfileService.getEditModeText();
    this.brokerInfo = {};
    this.brokerBusinessAddress = {};
    this.brokerHomeAddress = {};
    this.brokerPostalAddress = {};
    this.userInfo = {};
    this.addressTypeMode = false;
    this.addressTypeList = this.dataService.getAddressTypeDetails();
    this.digests = [];
    this.notificationActive =
      (this.configService.feature &&
        this.configService.feature.userProfileNotification) ||
      0;
    this.getDailyDigest();
  }

  $onChanges(changes) {
    this.familyId = changes.familyId.currentValue;
    this.getUserInform();
  }

  getDailyDigest() {
    this.userService.getUserDigest().then(response => {
      this.digests = response;
    });
  }

  updateDigestSetting(digest) {
    this.userService.updateUserDigest(digest).then(() => {
      this.toaster.pop(
        'success',
        'Success!',
        `${digest.notificationSetting} successfully updated.`
      );
    });
  }
  getUserInfo() {
    this.userService.GetUserInfo().then(response => {
      const { data } = response;
      if (!data) return;
      this.userInfo = data;
    });
  }
  hiddenForAssistant() {
    const assistantList = [ACCESS_TYPE.ADMIN_ASSISTANT, ACCESS_TYPE.ASSISTANT];
    return assistantList.indexOf(this.userInfo.AccessType) !== -1;
  }
  getUserInform() {
    if (this.familyId) {
      this.corporateContactGet();
      this.familyWebManagementGet();
      this.familyAddressGet(true, true, true);
    }
  }
  corporateContactGet() {
    if (this.familyId) {
      this.corporateService
        .corporateContactGet(0, this.familyId)
        .then(corporateContactGetResponse => {
          this.brokerInfo = corporateContactGetResponse.data;
          this.mobileMask = this.broadcastDataService.getMobileFormat(
            this.brokerInfo.ReportingCountryId
          );
          this.phoneMask = this.broadcastDataService.getPhoneFormat(
            this.brokerInfo.ReportingCountryId
          );
        });
    }
  }

  familyWebManagementGet() {
    if (this.familyId) {
      this.corporateService
        .familyWebManagementGet(this.familyId, 0)
        .then(response => {
          const { data } = response;
          if (data) {
            this.webManagement = data;
          } else {
            this.webManagement = {
              AddressTypeID: 0,
              HideStreetDetail: false,
            };
          }
        });
    }
  }
  updateWebManagement() {
    this.corporateService
      .familyWebManagementSet(this.familyId, 0, this.webManagement)
      .then(response => {
        if (response.data) {
          this.toaster.pop('success', 'Successfully updated!', 'Success');
          this.addressTypeMode = false;
        } else {
          this.toaster.pop('error', 'Unable to update web management', 'Error');
        }
      });
  }

  addressTypeToggle() {
    this.addressTypeMode = !this.addressTypeMode;
  }

  getAddressTypeLabel(id) {
    if (!id || !this.addressTypeList) return 'None';
    const addressType = this.addressTypeList.find(
      type => parseInt(type.id, 10) === parseInt(id, 10)
    );
    return addressType ? addressType.name : 'None';
  }

  familyAddressGet(
    updateHome = false,
    updatePostal = false,
    updateBusiness = false
  ) {
    if (this.familyId) {
      this.corporateService
        .familyAddressGet(this.familyId)
        .then(familyAddressGetResponse => {
          if (
            familyAddressGetResponse.data &&
            familyAddressGetResponse.data.length
          ) {
            this.updateBusiness(updateBusiness, familyAddressGetResponse.data);
            this.updatePostal(updatePostal, familyAddressGetResponse.data);
            this.updateHome(updateHome, familyAddressGetResponse.data);
          } else {
            this.brokerBusinessAddress = {};
            this.brokerHomeAddress = {};
            this.brokerPostalAddress = {};
          }
          this.edittingAddress = false;
        });
    }
  }

  updateBusiness(updateBusiness, addressData) {
    if (updateBusiness) {
      this.brokerBusinessAddress =
        addressData && addressData.find(obj => obj.isBusiness);
    }
  }

  updatePostal(updatePostal, addressData) {
    if (updatePostal) {
      this.brokerPostalAddress =
        addressData && addressData.find(obj => obj.isMailing);
    }
  }

  updateHome(updateHome, addressData) {
    if (updateHome) {
      this.brokerHomeAddress =
        addressData &&
        addressData.find(obj => !obj.isBusiness && !obj.isMailing);
    }
  }

  setEditToFalse(propertyKey) {
    if (propertyKey) {
      this.editMode[propertyKey] = false;
    }
  }
  setEditToTrue(propertyKey) {
    if (propertyKey) {
      this.editMode[propertyKey] = true;
    }
  }

  updateAdviserDetails(propertyKey, displayName) {
    const checkPassKey =
      (propertyKey === 'HomeNumber' ||
        propertyKey === 'WorkNumber' ||
        propertyKey === 'Mobile' ||
        propertyKey === 'EmergencyContactNumber') &&
      this.brokerInfo[propertyKey];
    if (checkPassKey) {
      const checkValidMobile = isMobileValid(this.brokerInfo[propertyKey]);
      if (!checkValidMobile) {
        this.toaster.pop('error', 'Error', 'Invalid Number.');
        return false;
      }
    }
    this.setEditToFalse(propertyKey);
    this.contactService
      .subscriptionAdviserDetailsSet(this.brokerInfo)
      .then(response => {
        if (response && response.data) {
          this.toaster.pop('success', 'Successfully updated!', displayName);
        }
      });
  }

  saveAddress(propertyKey, addressType, addressData) {
    this.setEditToFalse(propertyKey);
    const isBusiness = addressType === this.editModeText.BusinessAddress;
    const isMailing = addressType === this.editModeText.PostalAddress;
    const isHome = !isMailing && !isBusiness;
    if (this.familyId) {
      this.commonFnService
        .saveAddress(
          this.familyId,
          0,
          addressData,
          isMailing,
          isBusiness,
          false
        )
        .then(() => {
          this.familyAddressGet(isHome, isMailing, isBusiness);
        });
    }
  }

  launchAddressModal() {
    const size = 'md';
    const isOrgAddress = false;
    const modalInstance = this.addressModalService.launchAddressModal(
      size,
      this.familyId,
      isOrgAddress
    );
    modalInstance.result.then(() => {
      this.edittingAddress = true;
      this.timeOutInstance = this.$timeout(() => {
        const isHome = true;
        const isPostal = true;
        const isBusiness = true;
        this.familyAddressGet(isHome, isPostal, isBusiness);
        this.toaster.pop('success', 'Successfully Updated', 'Address');
      }, 500);
    });
  }

  $onDestroy() {
    this.$timeout.cancel(this.timeOutInstance);
  }
}
export default UsersProfileComponentCtrl;
