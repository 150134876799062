import template from './sendCreditGuideModal.html';
import SendCreditGuideModalCtrl from './sendCreditGuideModalCtrl';
import './style.scss';

export default {
  template,
  controller: SendCreditGuideModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    contactDetails: '<',
  },
};
