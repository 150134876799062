import { INSURANCE_TYPES } from 'Common/constants/insuranceOptions';
import { isValidEmailAddress } from 'Common/utilities/string';
import { displayError } from 'Common/utilities/alert';
import { flatMap } from 'lodash';
import swal from 'sweetalert';

export default class InsuranceSendReportFormCtrl {
  constructor(contactService, insuranceReportService) {
    'ngInject';

    this.contactService = contactService;
    this.insuranceReportService = insuranceReportService;
    this.INSURANCE_TYPES = INSURANCE_TYPES;
  }

  $onInit() {
    if (this.type === INSURANCE_TYPES.QUICK_QUOTE && !this.reportSettings) {
      throw new Error(
        'insurance-send-report-form is missing the report-settings attribute'
      );
    }

    if (!this.reportData) {
      throw new Error(
        'insurance-send-report-form is missing the report-data attribute'
      );
    }

    this.initEmail();
    this.loadClientEmailAddress();
  }

  initEmail() {
    this.email = {
      familyId: this.familyId,
      attachments: this.getAttachments(),
      sendToCurrentUser: true,
    };

    switch (this.type) {
      case INSURANCE_TYPES.QUICK_QUOTE:
        this.email = {
          ...this.email,
          quoteId: this.reportData.quoteId,
          reportOptions: this.reportSettings.ReportOptions,
        };
        break;
      case INSURANCE_TYPES.PROFILER:
        this.email = {
          ...this.email,
          profilerId: this.reportData.profilerId,
        };
        break;
      default:
    }
  }

  getAttachments() {
    return this.reportDocument && [this.reportDocument];
  }

  loadClientEmailAddress() {
    if (!this.familyId) return;

    this.contactService.clientInformGet(this.familyId).then(response => {
      const { data } = response;
      this.email.recipientsEmailAddresses =
        data &&
        data.length &&
        data.reduce((accum, clientInfo) => {
          if (clientInfo.Email && clientInfo.Email.length) {
            const [emailAddr] = [...clientInfo.Email];
            return [...accum, emailAddr.EmailAddress];
          }
          return accum;
        }, []);
    });
  }

  onSelectRecipient(recipient) {
    if (!isValidEmailAddress(recipient)) {
      this.isRecipientsInValid = true;
      this.recipientsValidationMessage = 'Invalid email address format';
      return;
    }

    if (this.email.recipientsEmailAddresses) {
      this.email.recipientsEmailAddresses = [
        ...this.email.recipientsEmailAddresses,
        recipient,
      ];
    } else {
      this.email.recipientsEmailAddresses = [recipient];
    }

    this.clearValidationMessage('recipients');
  }

  onRemoveRecipient(recipient) {
    const indexOfRecipient = this.email.recipientsEmailAddresses.indexOf(
      recipient
    );
    this.email.recipientsEmailAddresses = [
      ...this.email.recipientsEmailAddresses.slice(0, indexOfRecipient),
      ...this.email.recipientsEmailAddresses.slice(indexOfRecipient + 1),
    ];

    this.clearValidationMessage('recipients');
  }

  onUpdatedSubject(subject) {
    this.email.subject = subject;
  }

  onUpdateBody(body) {
    this.email.body = body;
  }

  onRemoveAttachment(attachment) {
    if (!this.email.attachments || this.email.attachments.length === 1) {
      this.isAttachmentsInvalid = true;
      this.attachmentsValidationMessage = 'The report attachment is required';
      return;
    }

    const indexOfAttachment = this.email.attachments.indexOf(attachment);
    this.email.attachments = [
      ...this.email.attachments.slice(0, indexOfAttachment),
      ...this.email.attachments.slice(indexOfAttachment + 1),
    ];

    this.clearValidationMessage('attachments');
  }

  sendReport() {
    if (!this.isFormValid()) return;

    this.isSendingReport = true;
    this.insuranceReportService
      .sendReportViaEmail(this.type, this.getEmailToSend())
      .then(() => {
        swal(
          {
            title: 'Report sent successfully',
            text: '',
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#42B4B4',
            confirmButtonText: 'Got it',
            closeOnConfirm: true,
          },
          () => {
            this.onReportSent && this.onReportSent();
          }
        );
      })
      .catch(displayError)
      .finally(() => {
        this.isSendingReport = false;
      });
  }

  getEmailToSend() {
    const recipients = this.getRecipients();
    if (this.type === INSURANCE_TYPES.QUICK_QUOTE) {
      const benefitIds = this.getBenefitIds().join(',');
      const providerIds = this.getProviderIds().join(',');
      return { ...this.email, benefitIds, providerIds, recipients };
    }
    return { ...this.email, recipients };
  }

  clearValidationMessage(section) {
    switch (section) {
      case 'recipients':
        this.isRecipientsInValid = false;
        this.recipientsValidationMessage = null;
        break;
      case 'attachments':
        this.isAttachmentsInvalid = false;
        this.attachmentsValidationMessage = null;
        break;
      default:
    }
  }

  isFormValid() {
    this.validateRecipients();
    return !this.isRecipientsInValid;
  }

  validateRecipients() {
    const { recipientsEmailAddresses } = this.email;
    this.isRecipientsInValid =
      !recipientsEmailAddresses || !recipientsEmailAddresses.length;
    if (this.isRecipientsInValid) {
      this.recipientsValidationMessage = 'At least one recipient is required';
    }
  }

  getRecipients() {
    const { recipientsEmailAddresses } = this.email;
    return (
      recipientsEmailAddresses &&
      recipientsEmailAddresses.map(emailAddress => {
        return { emailAddress };
      })
    );
  }

  getBenefitIds() {
    const { peopleEntity } = this.reportData;
    const benefitList = flatMap(peopleEntity, entity => entity.BenefitList);
    return (
      benefitList &&
      benefitList.length &&
      benefitList.reduce((accum, benefit) => {
        return benefit.BenefitId ? [...accum, benefit.BenefitId] : accum;
      }, [])
    );
  }

  getProviderIds() {
    const { ProviderID: providerId } = this.email.reportOptions;
    if (providerId) return [providerId];

    const { insurersList } = this.reportData;
    return insurersList && insurersList.map(insurer => insurer.ProviderId);
  }
}
