/**
 * Add several features to panels.
 */

import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('ctPaneltool', function ctPaneltool() {
  const templates = {
    /* jshint multistr: true */
    settings:
      "<a href='#' class='btn btn-transparent btn-sm' panel-settings='' tooltip-placement='top' uib-tooltip='Settings' ng-click='{{panelId}} = !{{panelId}}' ng-init='{{panelId}}=false'><i ng-if='{{panelId}}' class='ti-plus'></i><i ng-if='!{{panelId}}' class='ti-settings'></i></a>",
    collapse:
      "<a href='#' class='btn btn-transparent btn-sm' panel-collapse='' tooltip-placement='top' uib-tooltip='Collapse' ng-click='{{panelId}} = !{{panelId}}' ng-init='{{panelId}}=false'><i ng-if='{{panelId}}' class='ti-plus'></i><i ng-if='!{{panelId}}' class='ti-minus'></i></a>",
    refresh:
      "<a href='#' class='btn btn-transparent btn-sm' panel-refresh='' tooltip-placement='top' uib-tooltip='Refresh' data-spinner='{{spinner}}'><i class='fa fa-circle-o'></i></a>",
    expand:
      "<a href='#' class='btn btn-transparent btn-sm hidden-sm hidden-xs' panel-expand=''><i class='fa fa-expand' ng-show='!isPanelFullscreen'></i><i class='fa fa-compress' ng-show='isPanelFullscreen'></i></a>",
    dismiss:
      "<a href='#' class='btn btn-transparent btn-sm' panel-dismiss='' tooltip-placement='top' uib-tooltip='Close'><i class='ti-close'></i></a>",
  };
  return {
    restrict: 'E',
    template(elem, attrs) {
      let temp = '';
      if (attrs.toolSettings)
        temp += templates.settings.replace(
          /{{panelId}}/g,
          elem
            .parent()
            .parent()
            .attr('id')
        );
      if (attrs.toolCollapse)
        temp += templates.collapse.replace(
          /{{panelId}}/g,
          elem
            .parent()
            .parent()
            .attr('id')
        );
      if (attrs.toolRefresh)
        temp += templates.refresh.replace(/{{spinner}}/g, attrs.toolRefresh);
      if (attrs.toolExpand) temp += templates.expand;
      if (attrs.toolDismiss) temp += templates.dismiss;
      return temp;
    },
  };
});

angular.module('app').directive('panelExpand', function panelExpand($compile) {
  return {
    restrict: 'A',
    replace: false,
    terminal: true, // this setting is important, see explanation below
    priority: 1001, // this setting is important, see explanation below
    scope: true,
    link(scope, element) {
      scope.isPanelFullscreen = false;
      scope.fullscreenTooltip = 'Expand';
      const panel = element.closest('.panel');
      element.attr('uib-tooltip', '{{fullscreenTooltip}}');
      element.attr('tooltip-placement', 'top');
      element.attr('ng-click', 'toggleFullScreen()');
      element.removeAttr('panel-expand');
      // remove the attribute to avoid indefinite loop
      element.removeAttr('data-panel-expand');

      // also remove the same attribute with data- prefix in case users specify data-common-things in the html
      const el = $compile(
        "<div fullscreen='isPanelFullscreen' only-watched-property class='panel-fullscreen'></div>"
      )(scope);
      panel.before(el).appendTo(el);

      scope.toggleFullScreen = function() {
        scope.isPanelFullscreen = !scope.isPanelFullscreen;
        if (scope.isPanelFullscreen) {
          scope.fullscreenTooltip = 'Compress';
        } else {
          scope.fullscreenTooltip = 'Expand';
        }
      };
      $compile(element)(scope);
    },
  };
});

angular.module('app').directive('panelRefresh', function panelRefresh() {
  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      function($scope, $element) {
        const refreshEvent = 'panel-refresh';
        const csspinnerClass = 'csspinner';
        const defaultSpinner = 'load1';

        // method to clear the spinner when done
        function removeSpinner() {
          this.removeClass(csspinnerClass);
        }

        // catch clicks to toggle panel refresh
        $element.on('click', function() {
          const $this = $(this);
          const panel = $this.parents('.panel').eq(0);
          const spinner = $this.data('spinner') || defaultSpinner;

          // start showing the spinner
          panel.addClass(`${csspinnerClass} ${spinner}`);

          // attach as public method
          panel.removeSpinner = removeSpinner;

          // Trigger the event and send the panel object
          $this.trigger(refreshEvent, [panel]);
        });
      },
    ],
  };
});

angular.module('app').directive('panelDismiss', function panelDismiss() {
  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      function($scope, $element) {
        $element.on('click', function() {
          const parent = $(this).closest('.panel');

          function destroyPanel() {
            const col = parent.parent();
            parent.fadeOut(300, function() {
              $(this).remove();
              if (col.is('[class*="col-"]') && col.children('*').length === 0) {
                col.remove();
              }
            });
          }

          destroyPanel();
        });
      },
    ],
  };
});

(function() {
  /* eslint-disable angular/document-service, angular/timeout-service */
  $(window.document).on('panel-refresh', '.panel', (e, panel) => {
    // perform any action when a .panel triggers a the refresh event
    setTimeout(() => {
      // when the action is done, just remove the spinner class
      panel.removeSpinner();
    }, 3000);
  });
})();
