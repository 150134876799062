import template from './smsTemplatesManagementModal.html';
import SmsTemplatesManagementModalCtrl from './smsTemplatesManagementModalCtrl';

export default {
  template,
  controller: SmsTemplatesManagementModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    showDefaultTemplateSettings: '<',
  },
};
