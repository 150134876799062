import angular from 'angular';
import _ from 'lodash';
import { SETTINGS } from 'Common/constants/dashboardViewSettings';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular
  .module('app')
  .factory(
    'overviewDashboardSharedService',
    (optionsService, $q, loanToolsService, userService) => {
      const factory = {};
      // init methods
      factory.getCountryList = getCountryList;
      factory.LoanRateGet = LoanRateGet;
      factory.mapLoanRateToTable = mapLoanRateToTable;

      // actual methods
      function getCountryList() {
        const defer = $q.defer();
        optionsService.countryListGet().then(response => {
          return defer.resolve(response.data);
        });
        return defer.promise;
      }

      function LoanRateGet() {
        const defer = $q.defer();
        loanToolsService.LoanRateGet().then(response => {
          return defer.resolve(response.data);
        });
        return defer.promise;
      }
      /*
    @name - mapLoanRateToTable
    @desc - this map function exploit the arrangements of data being return from api,
    @param - rawLoanRateData (object)
    @return - mlrt.mappedLoanRate (mapped loanrate)
    @dependent functions - pushAnotherModel, pushDetailsToCurrentModel
  */
      function mapLoanRateToTable(rawLoanRateData) {
        const mlrt = this;
        mlrt.mappedLoanRate = [];
        mlrt.currentLender = '1';
        mlrt.prevLender = '2';

        _.forEach(rawLoanRateData, object => {
          mlrt.currentLender = object.LenderName;

          if (mlrt.currentLender !== mlrt.prevLender) {
            pushAnotherModelDependent(mlrt, object);
          } else {
            pushDetailsToCurrentModelDependent(mlrt, object);
          }
        });
        return mlrt.mappedLoanRate;
      }

      function checkTermText(object) {
        let termText;
        if (_.isEmpty(object.LoanTermText)) {
          termText = object.LoanTerm;
        } else {
          termText = object.LoanTermText;
        }
        return termText;
      }

      function pushAnotherModelDependent(mlrt, object) {
        const termText = checkTermText(object);
        const model = {
          LenderName: object.LenderName,
          LenderID: object.LenderID,
          CountryCode: object.CountryCode,
          Years: [termText],
        };
        model.Years[object.LoanTermText] = {
          Name: termText,
          CurrentRate: object.CurrentRate,
          PrevRate: object.PrevRate,
          LoanTerm: object.LoanTerm,
        };

        mlrt.mappedLoanRate.push(model);
        mlrt.prevLender = object.LenderName;
      }

      function pushDetailsToCurrentModelDependent(mlrt, object) {
        const termText = checkTermText(object);
        const indexToPush = mlrt.mappedLoanRate.length - 1;
        const loanTermTextObjectModel = {
          Name: termText,
          CurrentRate: object.CurrentRate,
          PrevRate: object.PrevRate,
          LoanTerm: object.LoanTerm,
        };
        mlrt.mappedLoanRate[indexToPush].Years[
          termText
        ] = loanTermTextObjectModel;
        mlrt.prevLender = object.LenderName;
      }

      factory.doActionShared = (msg, scope) => {
        if (msg === SETTINGS.CUSTOMISE) {
          scope.customizeMode = true;
          scope.calendarMode = false;
          scope.tableMode = false;
        } else if (msg === SETTINGS.CALENDAR) {
          scope.customizeMode = false;
          scope.calendarMode = true;
          scope.tableMode = false;
        } else {
          scope.customizeMode = false;
          scope.calendarMode = false;
          scope.tableMode = true;
        }
      };

      factory.doActionCommision = (msg, CategorySet, scopeObj) => {
        if (msg === 'Customise') scopeObj.customizeMode = true;
        else scopeObj.customizeMode = false;

        if (msg === 'Graph') {
          scopeObj.categoryObj.categoryName = 'Graphic Widget';
          scopeObj.categoryObj.userWidgetID = scopeObj.myParam.Widget_UserID;
          CategorySet(scopeObj.categoryObj);

          scopeObj.graphMode = true;
        } else {
          scopeObj.categoryObj.categoryName = 'Big Widget';
          scopeObj.categoryObj.userWidgetID = scopeObj.myParam.Widget_UserID;
          CategorySet(scopeObj.categoryObj);

          scopeObj.graphMode = false;
        }
        return scopeObj;
      };

      factory.makeSelectNoneLender = (scopeObj, SaveForTimePeriod) => {
        for (let i = 0; i < scopeObj.WidgetOptionLenderList.length; i++) {
          scopeObj.WidgetOptionLenderList[i].ticked = false;
          scopeObj.commissionReceivedMultiLenderList.push(
            scopeObj.WidgetOptionLenderList[i]
          );
        }
        scopeObj.setIsFiltered.Widget_OptionValue = '';
        scopeObj.setIsFiltered.WidgetOptionId = 6;
        scopeObj.setIsFiltered.Widget_UserID = scopeObj.myParam.Widget_UserID;
        scopeObj.setIsFiltered.SelectedUser = [];
        SaveForTimePeriod(scopeObj.setIsFiltered);
        return scopeObj;
      };

      factory.updateTimePeriod = (
        commissionReceivedTableDropDowns,
        SaveForTimePeriod,
        myParam
      ) => {
        if (!commissionReceivedTableDropDowns) return;
        let commissionReceivedTableDropDownsObj = commissionReceivedTableDropDowns;
        let commissionReceivedTableDropDownsObjToSet = {};
        commissionReceivedTableDropDownsObjToSet.Widget_UserID =
          myParam.Widget_UserID;
        commissionReceivedTableDropDownsObjToSet.Widget_OptionValue =
          commissionReceivedTableDropDownsObj.OptionValueId;
        commissionReceivedTableDropDownsObjToSet.WidgetOptionId =
          commissionReceivedTableDropDownsObj.OptionId;
        commissionReceivedTableDropDownsObjToSet.SelectedUser = null;
        SaveForTimePeriod(commissionReceivedTableDropDownsObjToSet);
      };

      factory.isCorpShared = scope => {
        userService.GetUserInfo().then(response => {
          if (response.data) {
            if (
              parseInt(response.data.AccessType, 10) === ACCESS_TYPE.CORPORATE
            ) {
              scope.isCorporateUser = true;
            }
          }
        });
      };

      return factory;
    }
  );
