import template from './tableViewApplications.html';
import controller from './tableViewApplicationsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    parentToChildNotificationRegistration: '&',
    tableData: '<',
    tableHeaders: '<',
    freezeColumns: '<',
    filterSettings: '<',
    totalRecords: '<',
    showLoader: '<',
    pageSize: '<',
    pageNumber: '<',
    onPageChange: '&',
    onRedirectToApplication: '&',
    onRefreshTable: '&',
    onViewBorrower: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onClickPipelineItem: '&',
    onDeletePipelineItem: '&',
    onColumnSort: '&',
    onRefreshStats: '&',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    isCorporateUser: '<',
    onMenuClick: '&',
    isLockLoanappFeatureEnabled: '<',
  },
};
