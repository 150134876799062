import template from './pipelineKanbanColumn.html';
import './style.scss';

export default {
  template,
  bindings: {
    isSmallDevice: '<',
    column: '<',
    columnIndex: '<',
    isCorporateUser: '<',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    isShrink: '<',
    isCompact: '<',
    isLockLoanappFeatureEnabled: '<',
    kanbanBoardHeight: '<',
    pipelineType: '<',
    pipelineTypes: '<',
    kanbanBoardOptions: '<',
    cardViewStates: '<',
    probabilityList: '<',
    statusList: '<',
    onMenuClick: '&',
    onViewBorrower: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onSetColumnSort: '&',
    onToggleIsShrink: '&',
    onClickPipelineItem: '&',
    onDeletePipelineItem: '&',
    onChangeProbability: '&',
    onChangeSubStatus: '&',
    onLeadAddNote: '&',
    settings: '<',
    onOpenMoveCard: '&',
    onOpenWorkbench: '&',
    onViewMore: '&',
    kanbanSortingList: '<',
  },
};
