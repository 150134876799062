import { getLeadStatus } from 'Common/utilities/loanLead';
import { getOpportunityStatus } from 'Common/utilities/loanOpportunity';
import { getApplicationStatus } from 'Common/utilities/loanApplication';
import { pipelineStatusBuilderForUI } from 'Common/mappers/pipeline';
import {
  applicationTimelineCardBuilder,
  opportunityTimelineCardBuilder,
  leadsTimelineCardBuilder,
} from 'Common/utilities/timeline';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  PIPELINE_TYPES_STR,
  PIPELINE_CATEGORIES,
} from 'Common/constants/pipelineType';
import { displayError } from 'Common/utilities/alert';
import { objectLength } from 'Common/utilities/objectValidation';
import { PROBABILITIES } from 'Common/constants/probabilities';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import {
  CONVERSION_PIPELINE_STATUS,
  CONVERSION_PIPELINE_OPTIONS,
} from 'Common/constants/pipelineStatus';
import {
  removeLabelFromCard,
  updateLabelOfCard,
} from 'Common/utilities/kanbanHelper';
import swal from 'sweetalert';
import toastr from 'toastr';

export default class TimelineCtrl {
  constructor(
    $rootScope,
    $uibModal,
    $timeout,
    $state,
    $stateParams,
    cardRedirectionsService,
    loanOpportunityService,
    contactModelService,
    clientInfoService,
    utilitiesService,
    pipelineService,
    contactService,
    configService,
    uiService,
    modalRenderService,
    loanScenarioService,
    opportunityNewModalService,
    pipelineSharedData,
    currentUserService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.cardRedirectionsService = cardRedirectionsService;
    this.loanOpportunityService = loanOpportunityService;
    this.contactModelService = contactModelService;
    this.clientInfoService = clientInfoService;
    this.utilitiesService = utilitiesService;
    this.pipelineService = pipelineService;
    this.contactService = contactService;
    this.configService = configService;
    this.uiService = uiService;
    this.modalRenderService = modalRenderService;
    this.loanScenarioService = loanScenarioService;
    this.opportunityNewModalService = opportunityNewModalService;
    this.pipelineSharedData = pipelineSharedData;
    this.currentUserService = currentUserService;

    this.CONVERSION_PIPELINE_OPTIONS = CONVERSION_PIPELINE_OPTIONS;
    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.processParentNotification = this.processParentNotification.bind(this);
    this.getTimelineCards = this.getTimelineCards.bind(this);
    this.onDeletePipelineItem = this.onDeletePipelineItem.bind(this);
    this.refreshCardsAndLendingList = this.refreshCardsAndLendingList.bind(
      this
    );
    this.refreshCard = this.refreshCard.bind(this);
  }

  $onInit() {
    this.isCorporateUser = this.currentUserService.isCorporate;
    this.probabilityList = PROBABILITIES;
    this.declareConstants();
    this.isLockLoanappFeatureEnabled =
      (this.configService.feature && this.configService.feature.lockLoanapp) ||
      0;

    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    this.getOpportunityRedirectParams();
  }

  leadAddNote(familyId) {
    this.modalRenderService.leadAddNote(familyId).then(
      () => {
        this.onRefreshNotes();
      },
      () => {
        this.onRefreshNotes();
      }
    );
  }

  getOpportunityRedirectParams() {
    this.familyId &&
      this.contactService.clientInformGet(this.familyId).then(res => {
        const { data } = res;
        if (!data) return;

        this.clients =
          data.filter(obj => obj.Role === 'Adult' || obj.isEntity) || [];

        const isContactOpener = 1;
        const tabName = 'details';
        const clients = { information: this.clients };
        const { headerName } = this.clientInfoService.getClientHeader(clients);
        const sourceFamilyId = this.familyId;
        const sourceFamilyName = headerName || '';
        const { isBusiness } = this;

        this.redirectParams = {
          sourceFamilyId,
          sourceFamilyName,
          isContactOpener,
          isBusiness,
          tabName,
        };
      });
  }
  declareConstants() {
    this.getPipelineLabelsSettings();

    getLeadStatus(this.pipelineService)
      .then(statusList => {
        const finalList = statusList.reduce((accum, elem) => {
          if (elem.IsConversionStatus) return accum;
          return accum.concat(pipelineStatusBuilderForUI(elem));
        }, []);
        return finalList;
      })
      .then(statusList => {
        this.leadStatusList = statusList;
      });

    getOpportunityStatus(this.pipelineService)
      .then(statusList => {
        const finalList = statusList.reduce((accum, elem) => {
          if (elem.IsConversionStatus) return accum;
          return accum.concat(pipelineStatusBuilderForUI(elem));
        }, []);
        return finalList;
      })
      .then(statusList => {
        this.opportunityStatusList = statusList;
      });

    getApplicationStatus(this.pipelineService)
      .then(res => {
        this.applicationStatusList = res;
      })
      .then(() => {
        this.getTimelineCards();
      });
  }

  getTimelineCards() {
    this.contactModelService
      .getLoanTimeline(this.familyId)
      .then(cards => {
        const cardList = cards.reduce((accum, obj) => {
          switch (obj.pipelineCategory) {
            case 'New Lead':
              return accum.concat(
                leadsTimelineCardBuilder(
                  this.utilitiesService,
                  obj,
                  this.leadStatusList
                )
              );
            case 'Application':
              return accum.concat(
                applicationTimelineCardBuilder(
                  this.utilitiesService,
                  obj,
                  this.applicationStatusList
                )
              );
            case 'Opportunity':
              return accum.concat(
                opportunityTimelineCardBuilder(
                  this.utilitiesService,
                  obj,
                  this.opportunityStatusList
                )
              );
            default:
              return accum;
          }
        }, []);
        return cardList;
      })
      .then(cardList => {
        this.cards = cardList;
        this.setSlickSettings();
      });
  }

  toggleLabelShrink(event) {
    event && event.stopPropagation();
    this.isLabelShrinked = !this.isLabelShrinked;
  }

  setSlickSettings() {
    this.slickConfig = null;
    this.$timeout(() => {
      this.slickConfig = {
        enabled: true,
        draggable: true,
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: this.cards.length > 2 ? 3 : this.cards.length,
        slidesToScroll: this.cards.length > 2 ? 3 : this.cards.length,
      };
    }, 200);
  }

  changeCardStatus(params) {
    const { event, card, statusIdx, status, pipelineType } = params;
    event && event.stopPropagation();
    if (status && status.IsConversionStatus) return;
    card.currentStatusIdx = statusIdx;
    card.pipelineStatus = status.pipelineStatusID || status.pipelineStatusId;
    card.pipelineStatusId = card.pipelineStatus;
    card.subStatusName = null;

    switch (pipelineType) {
      case PIPELINE_TYPES_STR.APPLICATION:
        this.pipelineService
          .putApplicationLoanStatus(card.loanId, {
            PipelineStatusId: status.pipelineStatusID,
            ShouldSendNurtureCampaigns: false,
          })
          .then(() => {
            this.refreshCardsAndLendingList(false);
            toastr.success('Loan status is now updated', 'Success');
          });
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.loanOpportunityService
          .setLoanOpportunityStatus(card.loanId, status.pipelineStatusId)
          .then(() => {
            this.refreshCardsAndLendingList(false);
            toastr.success('Opportunity status is now updated', 'Success');
          });
        break;
      case PIPELINE_TYPES_STR.LEADS:
        this.pipelineService
          .PipelineCards(card.pipelineCardsId, {
            PipelineStatusID: status.pipelineStatusId,
          })
          .then(() => {
            this.refreshCardsAndLendingList(false);
            toastr.success('Leads status is now updated', 'Success');
          });
        break;
      default:
        break;
    }
  }

  viewLoanInformation(event, card) {
    event && event.stopPropagation();
    if (!card.familyInfo || !objectLength(card.familyInfo)) {
      this.cardRedirectionsService.getFamiliesPerLoan(card).then(() => {
        this.openLoanInformationModal(card);
      });
    } else {
      this.openLoanInformationModal(card);
    }
  }

  refreshCardsAndLendingList(isUpdateCards = true) {
    isUpdateCards && this.getTimelineCards();
    this.$rootScope.$broadcast('UPDATE_LENDING_ON_CHANGE_STATUS', true);
  }

  openLoanInformationModal(card) {
    const { loanID: loanId } = card;
    const familyId = card.familyInfo[0].familyId;

    const params = {
      loanInformationAction: 'edit',
      familyId: parseInt(familyId, 10),
      loanId,
    };

    return this.$uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
        controller: 'LoanInformationCtrl',
        size: 'wide',
        resolve: {
          params: () => params,
          loanType: () => 'npw',
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(
        () => {
          this.refreshCard(card, PIPELINE_TYPES_STR.APPLICATION);
        },
        () => {
          this.refreshCard(card, PIPELINE_TYPES_STR.APPLICATION);
        }
      );
  }

  getPipelineLabelsSettings(refreshParams = null) {
    this.pipelineService.PipelineSettingsLabel().then(({ data }) => {
      this.labelsSettings = collectionCamelizeBuilderForUI(data);
    });
    refreshParams &&
      refreshParams.updatedLabel &&
      refreshParams.updatedLabel.labelId &&
      this.refreshCardLabels(refreshParams);
  }

  refreshCardLabels(refreshParams) {
    const { updatedLabel, action } = refreshParams;
    if (!action) return;
    if (action === 'update') {
      this.cards = this.cards.map(card =>
        updateLabelOfCard(card, updatedLabel)
      );
    } else if (action === 'delete') {
      this.cards = this.cards.map(card =>
        removeLabelFromCard(card, updatedLabel.labelId)
      );
    }
  }

  processParentNotification(action, data) {
    if (action === 'refresh-timeline') {
      this.getTimelineCards();
    }
    if (action === 'refresh-card') {
      this.getTimelineCards();
      const { card, cardType } = data;
      this.refreshCard({ card, cardType });
    }
  }

  onDeletePipelineItem(card) {
    const cardIdx = this.cards.indexOf(card);
    this.cards.splice(cardIdx, 1);
    this.setSlickSettings();
    this.refreshCardsAndLendingList(false);
  }

  deletePipelineItem(
    event,
    deletePrimaryProperty,
    onSucess,
    onSuccessParams,
    isLeads
  ) {
    event.stopPropagation();
    swal(
      {
        title: 'Are you sure?',
        text: 'This record will be removed from your pipeline list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      },
      confirm => {
        if (confirm) {
          const service = isLeads ? 'pipelineService' : 'contactService';
          const method = isLeads ? 'PipelineCardsDelete' : 'loanDelete';
          const args = isLeads
            ? { cardsID: deletePrimaryProperty }
            : deletePrimaryProperty;

          this[service][method](args)
            .then(() => {
              onSucess && onSucess(onSuccessParams);
            })
            .catch(err => {
              displayError(err);
            })
            .finally(() => {
              swal({
                title: 'Success',
                text: 'Loan has been been successfully deleted.',
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            });
        }
      }
    );
  }

  changeProbability(pipelineCardsId, probability) {
    const card =
      this.cards.find(o => o.pipelineCardsId === pipelineCardsId) || null;
    if (!card || card.probability === probability) return;

    card.probability = probability;

    const args = { cardID: card.pipelineCardsId, probability };
    this.pipelineService.SetPipelineCardsProbability(args).then(({ data }) => {
      if (data) {
        toastr.success('Probability Successfully Saved!', 'Success');
      }
    });
  }

  changeSubStatus(pipelineCardsId, subStatus) {
    const card =
      this.cards.find(o => o.pipelineCardsId === pipelineCardsId) || null;
    if (!card || card.subStatusId === subStatus.pipelineSubStatusID) return;

    card.pipelineStatusId = subStatus.StatusID;
    card.subStatusId = subStatus.pipelineSubStatusID;
    card.subStatusName = subStatus.SubStatusName;

    const args = {
      CardsID: pipelineCardsId,
      SubStatusID: subStatus.pipelineSubStatusID,
    };
    this.pipelineService.CardsSubStatusSet(args).then(({ data }) => {
      if (data) {
        toastr.success('Substatus Successfully Saved!', 'Success');
      }
    });
  }

  convertCardToApplication(card, e, sourceCardTypes) {
    if (sourceCardTypes.isLeads) {
      const { pipelineCardsId: pipelineCardsID, clientFamilyId } = card;
      const { pipelineStatusId: conversionStatus } = e;
      const args = {
        pipelineCardsID,
        conversionStatus,
        clientFamilyId,
        reasonSubstatusID: 0,
        shouldSendNurtureCampaigns: false,
      };

      this.pipelineService.PipelineCardsConversionSet(args).then(() => {
        const { clientFamilyId: familyId, adviserId } = card;
        const setLoanAppObj = {
          LoanScenarioId: 0,
          AdviserId: adviserId || 0,
          Title: `Loan Application`,
          StatusName: 'ongoing',
          FamilyId: familyId,
          AssignedLenderId: '0',
        };

        this.loanScenarioService
          .scenarioSet(setLoanAppObj)
          .then(({ data: loanAppId }) => {
            const routerParams = { familyId, loanAppId };
            toastr.success('Lead successfully converted!', 'Success');
            this.$state.go('app.loanApplicationDetailsV3', routerParams);
          });
      });
    } else if (sourceCardTypes.isOpportunity) {
      const { loanScenarioId } = card;
      const { pipelineStatusId: ConversionStatusId } = e;
      const conversionStatus = { ConversionStatusId, ReasonSubStatusId: 0 };
      this.loanOpportunityService
        .setConversion(loanScenarioId, conversionStatus)
        .then(() => {
          this.refreshCardsAndLendingList();
        });
    }
  }

  convertCardToOpportunity(card) {
    const { clientFamilyId, pipelineCardsId } = card;
    leadsToOpportunityConvert(
      clientFamilyId,
      this.contactService,
      this.opportunityNewModalService,
      {},
      pipelineCardsId
    );
  }

  convertCardToSettled(card, e) {
    const { loanId: LoanId, pipelineCardsId } = card;
    const { pipelineStatusId: ConversionStatusId } = e;
    const args = {
      LoanId,
      ConversionStatusId,
      ShouldSendNurtureCampaigns: false,
    };
    this.pipelineService
      .putApplicationLoanConversionStatus(LoanId, args)
      .then(() => {
        this.refreshCardsAndLendingList();
        return this.modalRenderService.openMovedToSettledModal(
          'modalLinkLoanToPipeline',
          this.$stateParams.familyId,
          LoanId,
          pipelineCardsId
        );
      })
      .then(() => {
        this.$rootScope.$broadcast('UPDATE_LENDING_ON_CHANGE_STATUS', true);
      });
  }

  convertCardToNotProceeded(
    card,
    e,
    selectedReasonId = 0,
    shouldSendNurtureCampaigns,
    sourceCardTypes
  ) {
    if (sourceCardTypes.isLeads) {
      const { pipelineCardsId: pipelineCardsID, clientFamilyId } = card;
      const { pipelineStatusId: conversionStatus } = e;
      const args = {
        pipelineCardsID,
        conversionStatus,
        clientFamilyId,
        reasonSubstatusID: selectedReasonId,
        shouldSendNurtureCampaigns: shouldSendNurtureCampaigns || false,
      };
      this.pipelineService.PipelineCardsConversionSet(args).then(() => {
        this.refreshCardsAndLendingList();
      });
    } else if (sourceCardTypes.isOpportunity) {
      const { loanScenarioId } = card;
      const { pipelineStatusId: ConversionStatusId } = e;
      const conversionStatus = {
        ConversionStatusId,
        ReasonSubStatusId: selectedReasonId,
      };
      this.loanOpportunityService
        .setConversion(loanScenarioId, conversionStatus)
        .then(() => {
          this.refreshCardsAndLendingList();
        });
    } else if (sourceCardTypes.isApplication) {
      const { loanId: LoanId } = card;
      const { pipelineStatusId: ConversionStatusId } = e;
      const args = {
        LoanId,
        ConversionStatusId,
        ReasonSubstatusId: selectedReasonId,
        ShouldSendNurtureCampaigns: shouldSendNurtureCampaigns || false,
      };
      this.pipelineService
        .putApplicationLoanConversionStatus(LoanId, args)
        .then(() => {
          this.refreshCardsAndLendingList();
        });
    }
  }

  convertCard(card, e, cardType) {
    if (!e || !card) return;
    if (e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY) {
      this.convertCardToOpportunity(card);
    } else {
      const { enquiry } = card;

      const isLeads = cardType === PIPELINE_TYPES_STR.LEADS;
      const isApplication = cardType === PIPELINE_TYPES_STR.APPLICATION;
      const isOpportunity = cardType === PIPELINE_TYPES_STR.OPPORTUNITY;
      const service =
        isLeads || isApplication ? 'pipelineService' : 'loanOpportunityService';

      let method;
      const args = {};
      if (isLeads) method = 'settingsReasonGet';
      else if (isApplication) {
        method = 'getPipelineCategoryReasons';
        args.categoryId = PIPELINE_CATEGORIES.APPLICATION;
      } else if (isOpportunity) method = 'getReasonSubstatus';

      let moveTitle;
      switch (e.pipelineStatusId) {
        case CONVERSION_PIPELINE_STATUS.TO_APPLICATION:
          moveTitle = 'Application';
          break;
        case CONVERSION_PIPELINE_STATUS.TO_OPPORTUNITY:
          moveTitle = 'Opportunity';
          break;
        case CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED:
          moveTitle = 'Not Proceeded';
          break;
        case CONVERSION_PIPELINE_STATUS.TO_SETTLED:
          moveTitle = 'Settled';
          break;
        default:
          moveTitle = '';
          break;
      }

      const sourceCardTypes = { isLeads, isApplication, isOpportunity };

      this[service][method](isApplication ? args.categoryId : null).then(
        res => {
          if (!res || !res.data) return;
          const { data: reasonList } = res;

          const modalContent = {
            content: `This record will be moved to ${moveTitle}`,
          };
          if (
            e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED
          ) {
            modalContent.reasonList = reasonList;
            modalContent.showNurtureCampaignsQuestion = this.pipelineSharedData.showNurtureCampaignsQuestion(
              enquiry
            );
          }
          const confirmationPopup = this.modalRenderService.renderPipelineStatusMoveConfirmationModal(
            modalContent
          );
          confirmationPopup.result.then(result => {
            if (!result || !result.isOk) return;

            if (
              e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_APPLICATION
            ) {
              this.convertCardToApplication(card, e, sourceCardTypes);
            } else if (
              e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_SETTLED
            ) {
              this.convertCardToSettled(card, e, sourceCardTypes);
            } else if (
              e.pipelineStatusId === CONVERSION_PIPELINE_STATUS.TO_NOT_PROCEEDED
            ) {
              const { selectedReasonId, shouldSendNurtureCampaigns } = result;
              this.convertCardToNotProceeded(
                card,
                e,
                selectedReasonId,
                shouldSendNurtureCampaigns,
                sourceCardTypes
              );
            }
          });
        }
      );
    }
  }

  refreshCard(params = {}) {
    const { card, cardType } = params;

    if (!card || !cardType) {
      this.refreshCardsAndLendingList(true);
      return;
    }
    const { loanId, loanID } = card;
    const cardLoanId = loanId || loanID;
    this.$rootScope.$broadcast('UPDATE_LENDING_ON_CHANGE_STATUS', true);

    this.contactModelService
      .getLoanTimelineCard(this.familyId, cardLoanId)
      .then(response => {
        if (!objectLength(response)) {
          this.refreshCardsAndLendingList(true);
          return;
        }
        const cardIdx = this.cards.findIndex(o => o.loanId === cardLoanId);
        const isApplication = cardType === PIPELINE_TYPES_STR.APPLICATION;
        const newCard = isApplication
          ? applicationTimelineCardBuilder(
              this.utilitiesService,
              response,
              this.applicationStatusList
            )
          : opportunityTimelineCardBuilder(
              this.utilitiesService,
              response,
              this.opportunityStatusList
            );
        this.cards[cardIdx] = newCard;
        this.setSlickSettings();
      });
  }
}
