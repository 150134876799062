import template from './brokerAllocationTool.html';
import BrokerAllocationToolCtrl from './brokerAllocationToolCtrl';

export default {
  template,
  controller: BrokerAllocationToolCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '=',
    postalCode: '<',
    showAllocationButton: '<',
  },
};
