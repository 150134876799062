import template from './workbenchTask.html';
import controller from './workbenchTaskCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanId: '<',
    familyId: '<',
    familyList: '<',
    quoteId: '<',
    workBenchDetails: '<',
    onNewTask: '&',
  },
};
