import { findIndex } from 'lodash';

export default class WidgetDropdownCtrl {
  $onInit() {
    this.initModelValue();
    this.loadSubdropdownOptions();
  }

  $onChanges() {
    this.loadSubdropdownOptions();
  }

  initModelValue() {
    const hasDefaultValueIndex =
      typeof this.defaultValueIndex !== 'undefined' &&
      this.defaultValueIndex !== null;
    if (!this.modelValue && hasDefaultValueIndex) {
      this.modelValue = this.options[this.defaultValueIndex][
        this.optionValueProperty
      ];
      this.onMainValueChanged();
    }
  }

  onMainValueChanged() {
    if (this.onUpdate) {
      this.onUpdate({ value: this.modelValue });
    }
    this.loadSubdropdownOptions();
  }

  loadSubdropdownOptions() {
    if (this.subDropdown && this.subDropdown.isConditionalOption) {
      const mainValueIndex = findIndex(
        this.options,
        option => option[this.optionValueProperty] === this.modelValue
      );
      const subDropdownIndex = mainValueIndex !== -1 ? mainValueIndex : 0;
      const conditionalOption = this.subDropdown.conditionalOptions[
        subDropdownIndex
      ];
      this.subDropdown = { ...this.subDropdown, ...conditionalOption };
    }
  }
}
