import { INSURANCE_OPTIONS } from 'Common/constants/insuranceOptions';

export function onWaitingPeriodChanged(person, waitPeriod) {
  const isValidPerson =
    person &&
    person.isWOPRequired &&
    person.BenefitList &&
    !!person.BenefitList.length;
  if (!isValidPerson || !waitPeriod) return;

  const waiverOfPremiumBenefit = person.BenefitList.find(
    benefit =>
      parseInt(benefit.BenefitId, 10) ===
      INSURANCE_OPTIONS.BENEFIT_TYPE.WAIVER_OF_PREMIUM
  );
  if (waiverOfPremiumBenefit && waiverOfPremiumBenefit.WaiverOfPremium) {
    waiverOfPremiumBenefit.WaiverOfPremium.WeekWaitPeriod = waitPeriod;
  }
}
