import angular from 'angular';

angular
  .module('app')
  .controller('GmailCtrl', function GmailCtrl($window, $location) {
    function init() {
      $window.opener.authGoogle($location.search().code);
      $window.close();
    }
    init();
  });
