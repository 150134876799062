import template from './bouncingTooltip.html';

export default {
  template,
  bindings: {
    tooltipTitle: '@',
    tooltipMessage: '@',
    postion: '@',
    onDismiss: '&',
  },
};
