import { SECTION_ID } from 'Common/constants/loanappSectionIds';

const constantValue = {
  section: {
    simulate: 'simulate',
    scoring: 'scoring',
    submission: 'submission',
  },
  sectionDetails: {
    simulate: false,
    scoring: false,
    submission: false,
  },
  buttonClick: {
    simulate: false,
    scoring: false,
    submission: false,
  },
  buttonLabel: {
    simulate: 'Start Calculation',
    scoring: 'Start Calculation',
    submission: 'Submit Loan to Lender',
  },
};

class IndonesianActionsPanelCtrl {
  constructor(toaster, briServices, loanAppSharedData) {
    'ngInject';

    this.toaster = toaster;
    this.briServices = briServices;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.section = constantValue.section;
    this.buttonClick = constantValue.buttonClick;
    this.buttonLabel = constantValue.buttonLabel;
    this.sectionDetails = constantValue.sectionDetails;
    this.sectionStatus = this.section.simulate;
  }

  $onChanges(changes) {
    const { loanAppId } = changes;
    if (!loanAppId || !loanAppId.currentValue) return;
    this.loanAppId = loanAppId.currentValue;
  }

  setSimulate() {
    const { IsCompleted } = this.loanAppSharedData.getSectionObjectById(
      this.loanAppSharedData.completionListObject,
      SECTION_ID.FINANCIAL_POSITION
    );
    if (!IsCompleted) {
      this.toaster.pop(
        'error',
        'Invalid',
        'Please complete financial/salary information before starting the simulate calculation.'
      );
      return;
    } else if (this.buttonClick.simulate) return;

    this.buttonClick.simulate = true;
    this.briServices
      .postSimulate()
      .then(response => {
        if (!response) return;
        this.simulateData = response;
        this.sectionDetails.simulate = true;
        if (this.simulateData.status) {
          this.sectionStatus = this.section.scoring;
        }
      })
      .finally(() => {
        this.buttonClick.simulate = false;
        this.buttonLabel.simulate = 'Recalculate';
      });
  }

  getScoring() {
    if (!this.buttonClick.scoring) {
      this.buttonClick.scoring = true;
      this.briServices
        .postScoring()
        .then(response => {
          if (!response) return;
          this.scoringData = response;
          this.sectionDetails.scoring = true;
        })
        .finally(() => {
          this.buttonClick.scoring = false;
          this.sectionStatus = this.section.submission;
        });
    }
  }
}

export default IndonesianActionsPanelCtrl;
