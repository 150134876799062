import template from './matchCommissionModal.html';
import MatchCommissionModalCtrl from './matchCommissionModalCtrl';
import './style.scss';

export default {
  template,
  controller: MatchCommissionModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    commission: '<',
    familyIdToMatch: '<',
    onCommissionMatched: '&',
  },
};
