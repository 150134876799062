import template from './manageParties.html';
import managePartiesCtrl from './managePartiesCtrl';

export default {
  template,
  controller: managePartiesCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    opportunityId: '<',
    involvedParties: '<',
    selectedAdviserId: '<',
    showSelectAdviser: '<',
  },
};
