import template from './smsTemplatesSelection.html';
import SMSTemplateSelectionCtrl from './smsTemplatesSelectionCtrl';

export default {
  template,
  controller: SMSTemplateSelectionCtrl,
  controllerAs: 'vm',
  bindings: {
    onInitialized: '&',
    onTemplateSelected: '&',
    selectedTemplate: '=',
    module: '<',
  },
};
