import template from './timelineChecks.html';
import './style.scss';

export default {
  template,
  bindings: {
    statusList: '<',
    card: '<',
    timelineStatus: '<',
    modifierClass: '@',
    isSingle: '<',
    onChangeCardStatus: '&',
  },
};
