class RatingStarCtrl {
  getArrayNumber() {
    const number = this.maxValue;
    return new Array(number);
  }
  getClass(value) {
    return value <= this.rating ? this.fillStyle : '';
  }
  onHover(value) {
    if (!this.isDisabled) {
      this.rating = value;
    }
  }
  onLeave() {
    if (!this.isDisabled) {
      this.rating = this.ratingValue;
    }
  }
  setRating(rating) {
    if (!this.isDisabled) {
      this.onRatingClick({ rating });
    }
  }
  $onInit() {
    this.rating = parseInt(this.ratingValue, 10);
  }
  $onChanges() {
    this.rating = parseInt(this.ratingValue, 10);
  }
}

export default RatingStarCtrl;
