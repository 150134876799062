import template from './usersEmailSignature.html';
import controller from './usersEmailSignatureCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    familyId: '<',
    adviserInfo: '<',
  },
  controller,
  controllerAs: 'vm',
};
