import { benefitTypeForUI } from 'Common/mappers/insurance';
import {
  insuranceQuoteBrochureBuilderForUI,
  sendReportViaEmailBuilderForMyCRM,
  insuranceQuoteBenefitColorBuilderForUI,
} from 'Common/mappers/insuranceQuote';
import { recipientBuilderForMyCRM } from 'Common/mappers/sharedMappers';
import { insurerMergeFieldsBuilderForUI } from 'Common/mappers/emailTemplate';

class InsuranceQuoteService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
    this.quoteBaseUrl = 'quote';
  }

  insuranceQuoteDelete(quoteId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/InsuranceQuoteDelete`, {
      quoteId,
    });
  }

  deleteInsuranceProfilerByProfilerID(profilerId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/DeleteInsuranceProfilerByProfilerID`,
      { profilerId }
    );
  }

  insuranceQuoteSet(
    quoteId = 0,
    familyId = 0,
    noOfClients = 0,
    noOfDependents = 0
  ) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceQuoteSet`,
      {},
      { quoteId, familyId, noOfClients, noOfDependents }
    );
  }

  insuranceQuoteGet(module, quoteId = 0, familyId = 0) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteGet`, {
      module,
      quoteId,
      familyId,
    });
  }

  insuranceQuoteMonsterGet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceQuoteMonsterGet`,
      data
    );
  }

  insuranceQuoteIdGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteIdGet`);
  }

  insuranceClientIdGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceClientIdGet`);
  }

  insuranceClientBenefitsSet(data, quoteId, QuoteEntityInput = '') {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceClientBenefitsSet`,
      data,
      { quoteId, QuoteEntityInput }
    );
  }

  insuranceQuoteBrochureGet(benefitId, providerIds) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceQuoteBrochureGet`, {
        benefitId,
        providerIds,
      })
      .then(response => response.data.map(insuranceQuoteBrochureBuilderForUI));
  }

  insuranceRecommendationGet(providerId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceRecommendationGet`,
      { providerId }
    );
  }

  insuranceInsurerReportGet(quoteId, data, reportSettings = '') {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceInsurerReportGet`,
      data,
      { quoteId, reportSettings }
    );
  }

  insuranceBenefitDelete(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/InsuranceBenefitDelete`,
      params
    );
  }

  insuranceQuoteUserSettingsGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteUserSettingsGet`
    );
  }

  insuranceQuoteUserSettingsSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceQuoteUserSettingsSet`,
      data
    );
  }

  insuranceQuoteMortgageBenefitPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteMortgageBenefitPeriodGet`
    );
  }

  insuranceQuoteIPBenefitPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteIPBenefitPeriodGet`
    );
  }

  insuranceQuoteWeekWaitPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteWeekWaitPeriodGet`
    );
  }

  insuranceQuoteCalculationPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteCalculationPeriodGet`
    );
  }

  insuranceQuotePolicyTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuotePolicyTypeGet`
    );
  }

  insuranceQuoteBenefitTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteBenefitTypeGet`
    );
  }

  insuranceQuoteLoadingGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteLoadingGet`);
  }

  insuranceQuoteExcessGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteExcessGet`);
  }

  insuranceQuoteOccupationGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteOccupationGet`
    );
  }

  insuranceQuoteFrequencyGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteFrequencyGet`);
  }

  insuranceQuoteBenefitGroupGet() {
    const enableCache = true;
    const removeCache = false;
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteBenefitGroupGet`,
      null,
      enableCache,
      removeCache
    );
  }

  insuranceQuoteBenefitColorGet() {
    const enableCache = true;
    const removeCache = false;
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/InsuranceQuoteBenefitColorGet`,
        null,
        enableCache,
        removeCache
      )
      .then(
        response =>
          response.data &&
          response.data.map(insuranceQuoteBenefitColorBuilderForUI)
      );
  }

  insuranceQuoteStarRatingGet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceQuoteStarRatingGet`,
      data
    );
  }

  insuranceQuoteIPCoverTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteIPCoverTypeGet`
    );
  }

  insuranceQuoteOwnAnyOccupationGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteOwnAnyOccupationGet`
    );
  }

  insuranceQuoteAcceleratedGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteAcceleratedGet`
    );
  }

  insuranceQuoteEmployedStatusGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteEmployedStatusGet`,
      null,
      true,
      false
    );
  }

  insuranceQuoteByClientIDDelete(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/InsuranceQuoteByClientIDDelete`,
      params
    );
  }

  profilerByClientIDDelete(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ProfilerByClientIDDelete`,
      params
    );
  }

  getInsurerMergeField(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/mergefields/${params}`)
      .then(
        response =>
          response.data && insurerMergeFieldsBuilderForUI(response.data)
      );
  }

  insuranceBenefitTypeGet() {
    const enableCache = true;
    const removeCache = false;
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/InsuranceBenefitTypeGet`,
        {},
        enableCache,
        removeCache
      )
      .then(response => response.data.map(benefitTypeForUI));
  }

  sendQuoteReportViaEmail(data) {
    const { FamilyID, ...reportOptions } = data.reportOptions;
    const email = sendReportViaEmailBuilderForMyCRM({ ...data, reportOptions });
    email.Recipients =
      data.recipients && data.recipients.map(recipientBuilderForMyCRM);
    return this.httpClient.post(`${this.quoteBaseUrl}/email`, email);
  }
}

export default InsuranceQuoteService;
