import template from './template.html';
import ToggleNotesCtrl from './toggleNotesCtrl';
import './style.scss';

export default {
  template,
  controller: ToggleNotesCtrl,
  controllerAs: 'vm',
  bindings: {
    label: '<',
    brokerNotes: '=',
    brokerNotesId: '<',
    onSaveBrokerNote: '&',
  },
};
