import angular from 'angular';

angular
  .module('app')
  .controller('MobileSelectModalCtrl', function MobileSelectModalCtrl(
    $scope,
    $uibModalInstance
  ) {
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
