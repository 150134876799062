import template from './selectRadio.html';
import SelectRadioCtrl from './selectRadioCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    values: '<',
    options: '<',
    optionId: '<',
    optionLabel: '<',
    radioWidth: '@',
    onChange: '&',
  },
  controller: SelectRadioCtrl,
  controllerAs: 'vm',
};
