export const CONTACT_STATUS = {
  LEAD: 1,
  APPLICATION: 2,
  EXISTING_CLIENT: 3,
  PROFESSIONAL_PARTNER: 4,
  PREVIOUS_CLIENT: 5,
};

export const CONTACT_STATUS_COLOR = {
  LEAD: '#9a86a8',
  APPLICATION: '#42b4b4',
  EXISTING_CLIENT: '#579bcb',
  PROFESSIONAL_PARTNER: '#54478e',
  PREVIOUS_CLIENT: '#cdc3d2',
};
