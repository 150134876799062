import template from './pipelineMobileDemoModal.html';
import controller from './pipelineMobileDemoModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    videos: '<',
  },
};
