import angular from 'angular';

angular
  .module('app')
  .controller('TemplatesCtrl', function TemplatesCtrl(
    loanToolsService,
    $timeout
  ) {
    const vm = this;

    /* Models */
    vm.templateList = [];
    vm.searchDocumentFile = '';

    vm.isDownloading = false;
    vm.docIdBeingDownloaded = 0;

    const populateTemplateList = () => {
      vm.templateList = null;
      loanToolsService.resourceTemplatesGet().then(response => {
        vm.templateList = response.data;
      });
    };

    vm.searchDocumentCall = searchStr => {
      $timeout.cancel(vm.searchTimeout);
      vm.searchTimeout = $timeout(() => {
        vm.templateList = null;
        loanToolsService.resourceTemplatesSearch(searchStr).then(response => {
          vm.templateList = response.data;
        });
      }, 2000);
    };

    /* calling init */
    const init = () => {
      populateTemplateList();
    };
    init();
  });
