import template from './clientListSummaryOfCover.html';
import controller from './clientListSummaryOfCoverCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    profilerData: '<',
    modalInstance: '<',
  },
};
