import angular from 'angular';
import $ from 'jquery';

angular
  .module('app')
  .directive('perfectScrollbar', function perfectScrollbarDirective(
    $parse,
    $window,
    $timeout,
    $rootScope
  ) {
    const psOptions = [
      'wheelSpeed',
      'wheelPropagation',
      'minScrollbarLength',
      'useBothWheelAxes',
      'useKeyboard',
      'suppressScrollX',
      'suppressScrollY',
      'scrollXMarginOffset',
      'scrollYMarginOffset',
      'includePadding', // , 'onScroll', 'scrollDown'
    ];

    return {
      transclude: true,
      template: '<div><div ng-transclude></div></div>',
      replace: true,
      link($scope, $elem, $attr) {
        const jqWindow = angular.element($window);
        const options = {};

        function update(event) {
          $scope.$evalAsync(() => {
            // if($elem.hasOwnProperty('perfectScrollbar')) {
            if ($attr.scrollDown === 'true' && event !== 'mouseenter') {
              $timeout(() => {
                $($elem).scrollTop($($elem).prop('scrollHeight'));
              }, 100);
            }
            $elem.perfectScrollbar('update');
            // }
          });
        }

        if (($rootScope.app && !$rootScope.app.isMobile) || !$rootScope.app) {
          for (let i = 0, l = psOptions.length; i < l; i++) {
            const opt = psOptions[i];
            if ($attr[opt] !== undefined) {
              options[opt] = $parse($attr[opt])();
            }
          }

          $scope.$evalAsync(() => {
            // if($elem.hasOwnProperty('perfectScrollbar')) {
            $elem.perfectScrollbar(options);
            const onScrollHandler = $parse($attr.onScroll);
            $elem.scroll(() => {
              const scrollTop = $elem.scrollTop();
              const scrollHeight = $elem.prop('scrollHeight') - $elem.height();
              const scrollHandlerTimeout = $timeout(() => {
                onScrollHandler($scope, {
                  scrollTop,
                  scrollHeight,
                });
                $timeout.cancel(scrollHandlerTimeout);
              });
            });
            // }
          });

          // This is necessary when you don't watch anything with the scrollbar
          $elem.bind('mousemove', update);

          // Possible future improvement - check the type here and use the appropriate watch for non-arrays
          if ($attr.refreshOnChange) {
            $scope.$watchCollection($attr.refreshOnChange, () => {
              update();
            });
          }

          // this is from a pull request - I am not totally sure what the original issue is but seems harmless
          if ($attr.refreshOnResize) {
            jqWindow.on('resize', update);
          }

          $elem.bind('$destroy', () => {
            jqWindow.off('resize', update);
            // if($elem.hasOwnProperty('perfectScrollbar')) {
            try {
              $elem.perfectScrollbar('destroy');
            } catch (err) {
              // continue regardless of error
            }
            // }
          });
        }
      },
    };
  });
