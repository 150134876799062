import { getFullName, hasFirstnameOrLastname } from 'Common/utilities/contact';

class ApplicantAddRelatedPartyCtrl {
  $onInit() {
    this.getFullName = getFullName;
    this.hasFirstnameOrLastname = hasFirstnameOrLastname;
  }

  $onChanges(changes) {
    const {
      partyList,
      settingsNoParty,
      showSearchPanel,
      searchResult,
      loanAppFormWarning,
    } = changes;

    if (partyList) {
      this.partyList = partyList.currentValue;
    }

    if (settingsNoParty) {
      this.noParty = settingsNoParty.currentValue;
    }

    if (loanAppFormWarning) {
      this.loanAppFormWarning = loanAppFormWarning.currentValue;
    }

    if (showSearchPanel) {
      this.showSearchPanel = showSearchPanel.currentValue;
    }

    if (searchResult) {
      this.searchResult = searchResult.currentValue;
    }
  }

  addButtonClicked(event) {
    this.searchInput = '';
    this.onAddPartyClick({ type: this.partyTypeId });
    event.stopPropagation();
  }
}

export default ApplicantAddRelatedPartyCtrl;
