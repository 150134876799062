import { assetSupplierBuilderForMyCRM } from 'Common/mappers/loanScenario';
import { PERSON_PARTY_TYPES } from 'Common/constants/partyTypes';

export function appendSupplierFixedData(loanAppId, assetSupplier) {
  const supplierSet = assetSupplier;
  supplierSet.AssignedLoanScenarioId = loanAppId;
  supplierSet.ClientEntityId =
    assetSupplier.ClientEntityId || assetSupplier.PersonId;
  supplierSet.ClientTypeId = assetSupplier.ClientTypeId || 1;
  supplierSet.IsClient = assetSupplier.IsClient || true;
  supplierSet.ClientTypeDescription = 'Asset Supplier';
  supplierSet.IsAssetSupplier = true;
  supplierSet.IsApplicant = false;

  return supplierSet;
}

export function formatAssetSupplierForDisplay(supplier) {
  return {
    ...supplier,
    FamilyId: supplier.FamilyID,
    PersonId: supplier.ClientEntityId,
  };
}

export function formatAssetSupplierForContact(assetSupplier) {
  const newContact = assetSupplierBuilderForMyCRM(assetSupplier);
  newContact.FamilyId = 0;
  newContact.IsIncluded = true;
  newContact.PartyType = PERSON_PARTY_TYPES.ASSET_SUPPLIER;
  return [newContact];
}
