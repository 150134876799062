import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import {
  subscriptionBuilderForMyCRM,
  subIncomeTypeBuilderForUI,
  summarySectionBuilderForUI,
  commissionContactsListBuilderForUI,
} from 'Common/mappers/contact';
import {
  reviewFactFindBuilderForUI,
  factFindPricingBuilderForUI,
} from 'Common/mappers/factFind';

class ContactModelService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'contacts';
  }

  setGmailSignature(html) {
    return this.httpClient.post(`google/gmail-signature`, html);
  }
  gmailSyncDeactivate(params) {
    return this.httpClient.post(`google/gmail-sync-deactivate`, {}, params);
  }
  getLoanTimeline(id) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${id}/loan-timeline`)
      .then(res => res && collectionCamelizeBuilderForUI(res.data));
  }

  getLoanTimelineCard(familyId, loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${familyId}/loan-timeline/${loanId}`)
      .then(res => res && collectionCamelizeBuilderForUI(res.data));
  }

  subscription(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/Subscriptions`,
      subscriptionBuilderForMyCRM(data)
    );
  }

  updateSubscription(data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/Subscriptions`,
      subscriptionBuilderForMyCRM(data)
    );
  }

  getLoans(id) {
    return this.httpClient.get(`${this.apiBaseUrl}/${id}/loans`);
  }

  sendCreditGuide(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/credit-guide`, data);
  }
  getSubIncomeType() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/sub-income-type`)
      .then(response => response.data.map(subIncomeTypeBuilderForUI));
  }
  taskAdviserListGet(loanWritersOnly = null) {
    return this.httpClient.get(`${this.apiBaseUrl}/TaskAdviserListGet`, {
      loanWritersOnly,
    });
  }
  postMergeFamilyProfile(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/MergeFamilyProfile`,
      {},
      params
    );
  }
  getBrokerCommissionAccess() {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerCommissionAccess`);
  }
  checkFactFind(clientIds, loanAppId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/fact-find-check/${loanAppId}`, {
        clientIds,
      })
      .then(
        response => response.data && reviewFactFindBuilderForUI(response.data)
      );
  }
  getFactFindPricing() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-fact-find-pricing`)
      .then(
        response =>
          response.data && response.data.map(factFindPricingBuilderForUI)
      );
  }
  getSummarySection() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/section`)
      .then(
        response =>
          response.data && response.data.map(summarySectionBuilderForUI)
      );
  }
  updateSummarySection(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/section`, {}, params);
  }
  mailTemplateSet(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/MailTemplate`, {}, params);
  }
  familyListGet(params) {
    return this.httpClient.get(`${this.apiBaseUrl}/FamilyListGet`, params);
  }
  commissionContactsList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/commission-contacts-list`, params)
      .then(
        res => res.data && res.data.map(commissionContactsListBuilderForUI)
      );
  }
}

export default ContactModelService;
