import { INSURANCE_TYPES } from 'Common/constants/insuranceOptions';

export default class InsuranceProfilerSendReportModalCtrl {
  $onInit() {
    this.reportType = INSURANCE_TYPES.PROFILER;
    this.reportDocument = { fileName: 'Insurance Profiler Report.pdf' };
  }

  close() {
    this.modalInstance.dismiss();
  }

  onReportSent() {
    this.modalInstance.close();
  }
}
