import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { pipelineLabelBuilder } from 'Common/utilities/pipelineLabels';
import { splitString } from 'Common/utilities/string';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import { PIPELINE_TYPES_STR } from 'Common/constants/pipelineType';

/**
 * Action to perform on move failure.
 * remove the item from destination Column.
 * insert the item again in original Column.
 */
export function revertMoveCardToOtherColumn(event, sourceModelProp = 'card') {
  if (event) {
    event.dest.sortableScope.removeItem(event.dest.index);
    event.source.itemScope.sortableScope.insertItem(
      event.source.index,
      event.source.itemScope[sourceModelProp]
    );
  }
}

export function updateColumnTotalRecords(column, increase) {
  if (!column) return;

  let currentRecord = column.TotalRecords;
  if (currentRecord <= 0 && !increase) return;

  if (increase) {
    currentRecord++;
  } else {
    currentRecord--;
  }

  column.TotalRecords = currentRecord;
  return (
    column.cards &&
    column.cards.map(card => {
      card.TotalRecords = currentRecord;
      return card;
    })
  );
}

export function closeLabelPopover(card, lbl) {
  if (card) {
    card.textOpen = false;
    card.addLabelShown = false;
    card.editLabelShown = false;
  }
  if (lbl) {
    lbl.textOpen = false;
  }
}

export function kanbanHeight(windowObj, currentKanbanBoardHeight = 0) {
  let kanbanBoardHeight = currentKanbanBoardHeight;
  const winHeight = windowObj.document.documentElement.clientHeight;
  const headerHeight = windowObj.document.querySelector(
    'header.navbar.navbar-default.navbar-static-top'
  )
    ? windowObj.document.querySelector(
        'header.navbar.navbar-default.navbar-static-top'
      ).offsetHeight
    : 55;
  const pipelinePanel = windowObj.document.querySelector(
    '.new-pipeline-container'
  );
  const mobileAddCard = windowObj.document.querySelector('.mobileAddCard');
  const mobileAddCardHeight = mobileAddCard ? 45 : 0;
  const pHeight = `${winHeight - headerHeight - 50}px`;

  // declare height to panel
  if (pipelinePanel) {
    pipelinePanel.style.setProperty('height', pHeight);

    const pipelineFilterWrapperHeight = windowObj.document.querySelector(
      '#pipelineFilterWrapper'
    )
      ? windowObj.document.querySelector('#pipelineFilterWrapper').offsetHeight
      : 55;
    kanbanBoardHeight =
      winHeight -
      headerHeight -
      pipelineFilterWrapperHeight -
      mobileAddCardHeight -
      150;
  }

  return kanbanBoardHeight;
}

export function kanbanItemMoved(
  event,
  pipelineSharedData,
  itemMovedPostCallback,
  itemMovedPostSuccessCallback,
  itemConvertPostCallback,
  itemConvertPostSuccessCallback,
  sourceModelProp = 'card',
  targetIdProp = 'loanId',
  modalRenderService,
  reasonList,
  isShowNurtureCampaignsQuestion,
  contactService,
  opportunityNewModalService
) {
  const isUndefinedElement =
    event.dest && event.dest.sortableScope && !event.dest.sortableScope.element;
  if (isUndefinedElement) return;

  const itemToHighlight = event.source.itemScope.card;
  const sourceColumn =
    (event.source.sortableScope.$parent &&
      event.source.sortableScope.$parent.$parent &&
      event.source.sortableScope.$parent.$parent.$parent &&
      event.source.sortableScope.$parent.$parent.$parent.column) ||
    {};
  const destinationColumn =
    (event.dest.sortableScope.$parent &&
      event.dest.sortableScope.$parent.$parent &&
      event.dest.sortableScope.$parent.$parent.$parent &&
      event.dest.sortableScope.$parent.$parent.$parent.column) ||
    {};

  // Move among columns
  if (
    pipelineSharedData.checkItemMove(event) &&
    Object.keys(destinationColumn).length
  ) {
    // itemMovedPostCallback needs to return the promise itself
    itemMovedPostCallback &&
      typeof itemMovedPostCallback === 'function' &&
      itemMovedPostCallback(itemToHighlight, destinationColumn)
        .then(({ data }) => {
          sourceColumn &&
            sourceColumn.totalRecords &&
            sourceColumn.totalRecords--;
          destinationColumn &&
            destinationColumn.totalRecords &&
            destinationColumn.totalRecords++;
          itemMovedPostSuccessCallback &&
            typeof itemMovedPostSuccessCallback === 'function' &&
            itemMovedPostSuccessCallback(
              itemToHighlight,
              destinationColumn,
              data
            );
        })
        .catch(() => {
          sourceColumn.totalRecords++;
          destinationColumn.totalRecords--;
          revertMoveCardToOtherColumn(event, sourceModelProp);
        });
  } else {
    const movedToStatus = pipelineSharedData.getMoveSwalTitle(event);
    const isApproveApplication =
      event.dest.sortableScope.element[0].id === 'approve';
    const isMoveToOpportunity =
      event.dest.sortableScope.element[0].id === 'opportunity';
    const isSettledApplication =
      event.dest.sortableScope.element[0].id === 'approve' &&
      event.dest.sortableScope.element[0].dataset.source === 'application';
    const modalContent = {
      content: `This record will be moved to ${movedToStatus}`,
    };

    if (isMoveToOpportunity) {
      const {
        clientFamilyID,
        pipelineCardsID: pipelineItemId,
      } = event.source.itemScope.modelValue;
      leadsToOpportunityConvert(
        clientFamilyID,
        contactService,
        opportunityNewModalService,
        {},
        pipelineItemId
      ).then(res => {
        if (res && !res.isSuccess) revertMoveCardToOtherColumn(event);
      });
    } else {
      if (!isApproveApplication) {
        modalContent.reasonList = reasonList;
        modalContent.showNurtureCampaignsQuestion =
          isShowNurtureCampaignsQuestion &&
          pipelineSharedData.showNurtureCampaignsQuestion(
            event.source.itemScope.modelValue.enquiry ||
              event.source.itemScope.modelValue.enquirySource
          );
      }
      const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
        modalContent
      );
      confirmationPopup.result.then(result => {
        if (result.isOk) {
          const targetId = itemToHighlight[targetIdProp];
          let pipelineStatusId;
          if (isSettledApplication) {
            pipelineStatusId = PIPELINE_STATUS.SETTLED;
          } else if (isApproveApplication) {
            pipelineStatusId = PIPELINE_STATUS.APPLICATION;
          } else {
            pipelineStatusId = PIPELINE_STATUS.NOT_PROCEEDED;
          }
          const { selectedReasonId, shouldSendNurtureCampaigns } = result;

          // itemConvertPostCallback needs to return the promise itself
          itemConvertPostCallback &&
            typeof itemConvertPostCallback === 'function' &&
            itemConvertPostCallback(
              targetId,
              pipelineStatusId,
              selectedReasonId,
              shouldSendNurtureCampaigns,
              itemToHighlight
            ).then(response => {
              if (response) {
                sourceColumn &&
                  sourceColumn.totalRecords &&
                  sourceColumn.totalRecords--;
                itemConvertPostSuccessCallback &&
                  typeof itemConvertPostSuccessCallback === 'function' &&
                  itemConvertPostSuccessCallback(
                    isApproveApplication,
                    response,
                    itemToHighlight,
                    pipelineStatusId
                  );
              } else {
                revertMoveCardToOtherColumn(event, sourceModelProp);
              }
            });
        } else {
          revertMoveCardToOtherColumn(event, sourceModelProp);
        }
      });
    }
  }
}

export function kanbanCustomItemMove(
  itemMovedPostCallback,
  itemMovedPostSuccessCallback,
  itemToHighlight,
  destinationColumn,
  isConversion,
  targetIdProp = 'loanId',
  itemConvertPostCallback,
  itemConvertPostSuccessCallback,
  selectedReasonId,
  contactService,
  opportunityNewModalService
) {
  if (!isConversion) {
    // itemMovedPostCallback needs to return the promise itself
    return (
      itemMovedPostCallback &&
      typeof itemMovedPostCallback === 'function' &&
      itemMovedPostCallback(itemToHighlight, destinationColumn).then(
        ({ data }) => {
          return (
            itemMovedPostSuccessCallback &&
            typeof itemMovedPostSuccessCallback === 'function' &&
            itemMovedPostSuccessCallback(
              itemToHighlight,
              destinationColumn,
              data
            )
          );
        }
      )
    );
  }

  const targetId = itemToHighlight[targetIdProp];
  const { pipelineStatusId } = destinationColumn;
  const isApproveApplication = pipelineStatusId === PIPELINE_STATUS.APPLICATION;
  const isMoveToOpportunity =
    pipelineStatusId === PIPELINE_STATUS.TO_OPPORTUNITY;

  if (isMoveToOpportunity) {
    const { clientFamilyID, pipelineCardsID: pipelineItemId } = itemToHighlight;
    return leadsToOpportunityConvert(
      clientFamilyID,
      contactService,
      opportunityNewModalService,
      {},
      pipelineItemId
    );
  }

  // itemConvertPostCallback needs to return the promise itself
  return (
    itemConvertPostCallback &&
    typeof itemConvertPostCallback === 'function' &&
    itemConvertPostCallback(
      targetId,
      pipelineStatusId,
      selectedReasonId,
      false,
      itemToHighlight
    ).then(response => {
      if (response) {
        return (
          itemConvertPostSuccessCallback &&
          typeof itemConvertPostSuccessCallback === 'function' &&
          itemConvertPostSuccessCallback(
            isApproveApplication,
            response,
            itemToHighlight,
            pipelineStatusId
          )
        );
      }
    })
  );
}

export function getFirstSortItem(
  card,
  destinationColumn,
  primaryKeyObject = 'loanId'
) {
  if (!card || !destinationColumn) return {};

  const primaryKey = card[primaryKeyObject];
  const columnName = destinationColumn.name;
  const itemPosition = destinationColumn.cards.length;

  return { primaryKey, columnName, itemPosition };
}

export function getKanbanSortItem(
  card,
  destination,
  primaryKeyObject = 'loanId'
) {
  if (!card || !destination) return {};

  const primaryKey = card[primaryKeyObject];
  const columnName = destination.name;
  const itemPosition = Math.abs(
    destination.cards.findIndex(
      o => parseInt(o[primaryKeyObject], 10) === parseInt(primaryKey, 10)
    ) -
      (destination.cards.length - 1)
  );

  return { primaryKey, columnName, itemPosition };
}

export function leadCardMap(card) {
  if (!card) return {};

  card.labelsList = pipelineLabelBuilder(card.labels);
  return card;
}

export function opportunityCardMap(card) {
  if (!card) return {};

  const clientsList = splitString(card.clients).map(contactName => {
    return { name: contactName, isClient: true };
  });
  const entitiesList = splitString(card.entities).map(contactName => {
    return { name: contactName, isEntity: true };
  });
  const guarantorsList = splitString(card.guarantors).map(contactName => {
    return { name: contactName, isGuarantor: true };
  });

  card.involvedParties = [...clientsList, ...entitiesList, ...guarantorsList];
  card.contactNamesLimit = 3;
  card.labelsList = pipelineLabelBuilder(card.labels);
  return card;
}

export function applicationCardMap(card) {
  if (!card) return {};

  const clientsList = splitString(card.clients).map(contactName => {
    return { name: contactName, isClient: true };
  });
  const entitiesList = splitString(card.entities).map(contactName => {
    return { name: contactName, isEntity: true };
  });

  card.involvedParties = [...clientsList, ...entitiesList];
  card.contactNamesLimit = 3;

  card.labelsList = pipelineLabelBuilder(card.labels);
  return card;
}

export function removeLabelFromCard(card, labelId) {
  if (!card || !labelId || !card.labelsList) return card;
  const { labelsList } = card;
  card.labelsList = labelsList.reduce((accum, elem) => {
    if (parseInt(elem.labelId, 10) === parseInt(labelId, 10)) return accum;
    return accum.concat(elem);
  }, []);
  return card;
}

export function updateLabelOfCard(card, updatedLabel) {
  if (!card || !updatedLabel || !card.labelsList || !card.labelsList.length)
    return card;
  const { labelName, labelColor } = updatedLabel;

  card.labelsList = card.labelsList.map(o => {
    if (parseInt(o.labelId, 10) === parseInt(updatedLabel.labelId, 10)) {
      o.labelName = labelName;
      o.labelColor = labelColor;
    }
    return o;
  });
  return card;
}

export function getElementYOffset(element, win) {
  if (!element || !element[0]) return;

  const rawElem = element[0];
  const rect = rawElem.getBoundingClientRect();
  if (!rect.width && !rect.height && !rawElem.getClientRects().length) return;

  const doc = rawElem.ownerDocument;
  const docElem = doc.documentElement;
  return rect.top + win.pageYOffset - docElem.clientTop;
}

export function getKanbanSortingList(pipelineType) {
  const isOpportunity = pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
  const resetOption = { mode: 'Reset', title: 'Reset Order' };

  if (isOpportunity) {
    return [
      {
        mode: 'DateAsc',
        title: 'Sort by Date Ascending',
      },
      {
        mode: 'DateDesc',
        title: 'Sort by Date Descending',
      },
      {
        mode: 'LoanAsc',
        title: 'Sort by Proposed Loan Ascending',
      },
      {
        mode: 'LoanDesc',
        title: 'Sort by Proposed Loan Descending',
      },
      { ...resetOption },
    ];
  }

  return [
    {
      mode: 'DateAsc',
      title: 'Sort by Date Ascending',
    },
    {
      mode: 'DateDesc',
      title: 'Sort by Date Descending',
    },
    {
      mode: 'SubLender',
      title: 'Sort by Sub Lender',
    },
    { ...resetOption },
  ];
}
