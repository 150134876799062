import angular from 'angular';
import _ from 'lodash';

class EnquirySourceService {
  constructor(optionsService, $q) {
    'ngInject';

    this.optionsService = optionsService;
    this.$q = $q;
  }
  getEnquirySourceList(scope = {}) {
    const defer = this.$q.defer();
    this.optionsService.getEnquirySourceList().then(
      response => {
        const data = response.data;
        let sourceList = [];
        if (data && data.length) {
          data.forEach(enquiry => {
            const items = enquiry.EnquirySourceItem;
            if (items && items.length) {
              items.forEach(item => {
                const temp = {
                  ReferralCategoryId: enquiry.ReferralCategoryId,
                  ReferralCategoryName: enquiry.ReferralCategoryName,
                  ReferralItemId: item.ReferralItemId,
                  ReferralItemName: item.ReferralItemName,
                };
                sourceList = [...sourceList, temp];
              });
            }
          });
        }
        scope.EnquirySourceList = sourceList;
        defer.resolve(scope.EnquirySourceList);
      },
      () => {
        defer.reject([]);
      }
    );
    return defer.promise;
  }

  getEnquirySourceListData(data) {
    this.enquirySourceData = data;
    return this.enquirySourceData.reduce((accum, enquiry) => {
      let newAccum = accum;
      if (enquiry.EnquirySourceItem && enquiry.EnquirySourceItem.length) {
        _.each(enquiry.EnquirySourceItem, item =>
          angular.extend(item, {
            ReferralCategoryId: enquiry.ReferralCategoryId,
            ReferralCategoryName: enquiry.ReferralCategoryName,
          })
        );
        newAccum = accum.concat(enquiry.EnquirySourceItem);
      }
      return newAccum;
    }, []);
  }

  selectEnquiry(pipelineSharedData, enquirySourceList) {
    this.pipelineSharedData = pipelineSharedData;
    if (!pipelineSharedData.leadFilterData) {
      pipelineSharedData.validateFilters();
      if (enquirySourceList.length > 0) {
        pipelineSharedData.leadFilterData.enquiryId = enquirySourceList[0];
      }
    } else {
      const resultData = _.find(
        enquirySourceList,
        item =>
          item.ReferralItemId ===
            pipelineSharedData.leadFilterData.ReferralItemID &&
          item.ReferralCategoryId ===
            pipelineSharedData.leadFilterData.ReferralCategoryID
      );
      if (resultData) {
        pipelineSharedData.leadFilterData.enquiryId = resultData;
      }
    }
  }
}

export default EnquirySourceService;
