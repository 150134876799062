import template from './providerUploadDocuments.html';
import controller from './providerUploadDocumentsCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    modalData: '<',
  },
};
