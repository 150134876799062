import template from './insurerPremiumComparison.html';
import controller from './insurerPremiumComparisonCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    quoteData: '<',
    onInsurerPremiumComparisonInit: '&',
    onQuoteDataUpdated: '&',
  },
};
