import template from './pipelineDropboxApplication.html';
import './style.scss';

export default {
  template,
  bindings: {
    kanbanSortOptions: '<',
    cardViewStates: '<',
    dropboxModel: '=',
  },
};
