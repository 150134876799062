import angular from 'angular';

angular
  .module('app')
  .controller('AddPropertyModalCtrl', function AddPropertyModalCtrl(
    $scope,
    $stateParams,
    $uibModalInstance,
    saveProperty,
    fundingCalculatorService,
    fundingCalculatorParameters,
    setBlankObject,
    getSearchAddressSuggestion,
    closeAutoHomeAddress
  ) {
    $scope.brokerEventId = $stateParams.brokerEventId;
    $scope.fundingCalculatorParameters = fundingCalculatorParameters;
    $scope.setBlankObject = setBlankObject;
    $scope.getSearchAddressSuggestion = getSearchAddressSuggestion;
    $scope.closeAutoHomeAddress = closeAutoHomeAddress;

    // Get Property Parameters
    $scope.propertyParametersList = {};
    fundingCalculatorService
      .getPropertyParameters()
      .then(propertyParameters => {
        $scope.propertyParametersList = propertyParameters;
        $scope.setBlankObject();
        if (
          parseInt(
            $scope.fundingCalculatorParameters.fundingCalParametersId,
            10
          ) !== 0
        ) {
          fundingCalculatorService
            .getFundingCalculatorParametersByProperty(
              $scope.brokerEventId,
              $scope.fundingCalculatorParameters.fundingCalParametersId
            )
            .then(paramsResponse => {
              $scope.fundingCalculatorParameters = paramsResponse.data;
            });
        }
      });

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.resetFundingCalculatorData = () => {
      $scope.fundingCalculatorParameters.purchaseInfo.refinanceAmount = '';
      $scope.fundingCalculatorParameters.landValue = '';
    };
    $scope.saveProperty = function(formData) {
      const data = saveProperty(formData, $scope.fundingCalculatorParameters);
      if (data !== 'Invalid') {
        $scope.cancel();
      }
    };
  });
