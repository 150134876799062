import template from './workbenchLogs.html';
import controller from './workbenchLogsCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    logList: '<',
    onSendEmailClick: '&',
    onSendMailClick: '&',
    onAddNoteClick: '&',
    onEditLog: '&',
    onDeleteLog: '&',
  },
};
