import angular from 'angular';

angular
  .module('app')
  .directive('loanappToggleHeading', function loanappToggleHeading() {
    return {
      restrict: 'A',
      link(scope, element) {
        element.on('click', function(e) {
          e.preventDefault();
          element.toggleClass('is-active');
          const parent = angular.element(this).parent('.level-2-section');
          parent.toggleClass('is-active');
          if (parent.hasClass('is-active')) {
            parent.removeClass('is-collapsed');
            element.removeClass('is-active');
          } else {
            parent.addClass('is-collapsed');
            element.addClass('is-collapsed');
          }
        });
      },
    };
  });
