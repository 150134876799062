import template from './providerViewKeyContacts.html';
import controller from './providerKeyContactsCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    providerData: '<',
  },
};
