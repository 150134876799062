import angular from 'angular';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class ImportantDocumentModalCtrl {
  constructor(
    userInfo,
    loanToolsService,
    $uibModalInstance,
    corporateService,
    contactService,
    toaster,
    documentId,
    isUploading,
    importantDocs,
    importantDocumentFlagId,
    importantDocumentFlags,
    importantDocumentService,
    corporateModelService
  ) {
    this.userInfo = userInfo;
    this.loanToolsService = loanToolsService;
    this.$uibModalInstance = $uibModalInstance;
    this.corporateService = corporateService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.documentId = documentId;
    this.isUploading = isUploading;
    this.importantDocumentFlags = importantDocumentFlags;
    this.importantDocumentFlagId = importantDocumentFlagId;
    this.importantDocumentService = importantDocumentService;
    this.corporateModelService = corporateModelService;
    this.importantDocs = importantDocs;
  }

  cancel() {
    this.$uibModalInstance.dismiss();
  }

  importantDocumentDetailsGet() {
    this.corporateModelService
      .importantDocumentDetailsGet(this.documentId)
      .then(data => {
        if (data) {
          this.details = data.documentDetails || {};
          this.details.documentFlagId = this.importantDocumentFlagId
            ? this.importantDocumentFlagId.documentFlagId
            : 0;
          this.thumbnail = data.thumbnail || {};
        }
      });
  }

  updateDocument() {
    this.details.regionId = this.userInfo.CountryId;
    this.corporateModelService
      .importantDocumentSet(this.details)
      .then(respond => {
        if (!respond) return;
        const { data } = respond;
        if (data === -1) {
          this.toaster.pop(
            'error',
            'Oops!',
            'You already have selected a credit guide.'
          );
        } else {
          this.isProgressBarVisible = false;
          this.toaster.pop(
            'success',
            'Updated Info',
            'Successfully update file information'
          );
          this.loanToolsService.getImportantDocs();
          this.$uibModalInstance.close(true);
        }
      });
  }

  successUpload(response) {
    if (response && response.length > 0) {
      this.details.documentKey = response[0].DocumentKey;
      this.updateDocument();
    } else {
      this.isProgressBarVisible = false;
    }
  }

  removeImportantDocTag() {
    this.showFlagChoices = false;
    this.showWarningMessage = false;
    this.details.documentFlagId = 0;
  }

  selectionChange(flagId) {
    this.showWarningMessage = !!this.importantDocs.find(
      flag => Number(flag.documentFlagId) === Number(flagId)
    );
  }
  $onInit() {
    this.showWarningMessage = false;
    this.importantDocumentDetailsGet();
    this.contactService.getUserInfo().then(response => {
      if (!response || !response.data || !response.data.AccessType) return;
      this.userInfo = response.data;
      this.isCorporateUser = this.userInfo.AccessType === ACCESS_TYPE.CORPORATE;
    });
    if (this.importantDocumentFlagId) {
      this.showFlagChoices =
        !!(this.importantDocumentFlagId.documentFlagId > 0) || false;
    }
    this.saveLabel = this.isUploading ? 'Finish Uploading' : 'Update File';
    this.headerLabel = this.isUploading ? 'Upload File' : 'Edit File';
    this.toUploadReady = this.importantDocumentService.toUploadReady;
    this.uploadFile = this.importantDocumentService.uploadFile;
    this.errorUpload = this.importantDocumentService.errorUpload;
  }
}
angular
  .module('app')
  .controller('ImportantDocumentModalCtrl', ImportantDocumentModalCtrl);
