import template from './involvedPartyRows.html';

export default {
  template,
  bindings: {
    onOpenEditClientModal: '&',
    onOpenDeleteClientModal: '&',
    onToggleOtherInfo: '&',
    clientList: '<',
    isEnableDeleteParty: '<',
  },
};
