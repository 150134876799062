import './style.scss';
import template from './complianceReviewRating.html';
import ComplianceReviewRatingCtrl from './complianceReviewRatingCtrl';

export default {
  template,
  controller: ComplianceReviewRatingCtrl,
  controllerAs: 'vm',
  bindings: {
    familyId: '=',
  },
};
