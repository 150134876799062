import template from './nameWithIcon.html';
import './style.scss';

export default {
  template,
  bindings: {
    name: '@',
    content: '@',
    bgColor: '@',
  },
};
