import {
  getCalculatedAmount,
  getCalculatedPercentage,
  validateRange,
} from 'Common/utilities/linkedPercentageFieldUtil';

class LinkedPercentageFieldCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
    this.getCalculatedAmount = getCalculatedAmount;
    this.getCalculatedPercentage = getCalculatedPercentage;
    this.validateRange = validateRange;
  }

  $onInit() {
    this.setMinMaxAmount();
  }

  $onChanges(changes) {
    this.setMinMaxAmount();
    if (!this.hasInitialized) {
      const { initAmountValue } = changes;

      if (initAmountValue && initAmountValue.currentValue) {
        this.amount = initAmountValue.currentValue;
        this.setPercentage();
        this.hasInitialized = true;
      }

      return;
    }

    this.setAmount();
  }

  setPercentage() {
    this.amount = this.validateRange(
      this.amount,
      this.minAmount,
      this.maxAmount
    );
    this.percentage = this.getCalculatedPercentage(
      this.baseAmount,
      this.amount
    );
    this.emitValuesToParent(this.percentage, this.amount);
  }

  setAmount() {
    this.percentage = this.validateRange(
      this.percentage,
      this.minPercentage,
      this.maxPercentage
    );
    this.amount = this.getCalculatedAmount(
      this.baseAmount,
      this.percentage
    ).toFixed(2);
    this.emitValuesToParent(this.percentage, this.amount);
  }

  emitValuesToParent(percentage, amount) {
    this.onValuesChange({ percentage, amount });
  }

  setMinMaxAmount() {
    this.minAmount = this.getCalculatedAmount(
      this.baseAmount,
      this.minPercentage
    );
    this.maxAmount = this.getCalculatedAmount(
      this.baseAmount,
      this.maxPercentage
    );
  }
}

export default LinkedPercentageFieldCtrl;
