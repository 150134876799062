export function isBoolean(value) {
  return typeof value === 'boolean' || value === 'true' || value === 'false';
}

export function objectLength(object = {}) {
  return Object.keys(object).length;
}

export function isValidObjectValue(fn) {
  try {
    return fn();
  } catch (e) {
    return false;
  }
}
