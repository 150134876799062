import {
  insurancePipelineLabelForUI,
  insurancePipelineLabelForMyCRM,
  insurancePipelineColumnForUI,
  insuranceFamilyListForUI,
  insuranceQuoteForUI,
  insuranceProvidersQuoteForUI,
  insurancePipelineConversionSummaryForUI,
  insuranceNotProceededReasonForUI,
  insuranceProviderForUI,
  insuranceWorbenchDetailsForUI,
  insuranceBenefitForUI,
  benefitCoverBuilderForUI,
  benefitCoverBuilderForMyCRM,
  insurancePolicyOwnershipBuilderForUI,
} from 'Common/mappers/insurancePipeline';
import {
  mapInsurancePipelineStatus,
  mapInsurancePipelineTable,
  mapInsurancePipelineFiltersForUI,
  mapInsurancePipelineFiltersForMyCRM,
  mapInsuranceAppCoverDetails,
} from 'Common/utilities/insurancePipeline';

export default class InsurancePipelineService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-pipeline';
    this.contacts = 'contacts/';
    this.insuranceQuote = 'InsuranceQuote/';
  }

  getInsurancePipelineStatus(statusName, includeCancelled) {
    const doCache = true;
    const doRemoveFromCache = false;
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/status`,
        { statusName, includeCancelled },
        doCache,
        doRemoveFromCache
      )
      .then(({ data }) => data && data.map(mapInsurancePipelineStatus));
  }

  getInsurancePipelineCards(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/cards`, params)
      .then(({ data }) => data && data.map(insurancePipelineColumnForUI));
  }

  getInsurancePipelineLabels() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/label`, {})
      .then(({ data }) => data && data.map(insurancePipelineLabelForUI));
  }

  saveInsurancePipelineLabel(label) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/label`,
      insurancePipelineLabelForMyCRM(label)
    );
  }

  deleteInsurancePipelineLabel(labelId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/label`, { labelId });
  }

  addQuoteLabel(params = {}) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.quoteId}/label`,
      {},
      params
    );
  }

  getInsuranceQuoteProviders(quoteId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${quoteId}/providers`)
      .then(({ data }) => data && data.map(insuranceProvidersQuoteForUI));
  }

  getInsuranceProviders() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/providers`)
      .then(({ data }) => data && data.map(insuranceProviderForUI));
  }

  saveInsuranceApplication(params = {}) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.quoteId}/card/status`,
      {},
      params
    );
  }

  workbenchNextWorkDate(params = {}) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.quoteId}/workbench`,
      {},
      params
    );
  }

  getSearchFamily(filters) {
    const params = {
      searchCriteria: '',
      byPassFilter: true,
      ...filters,
    };
    return this.httpClient
      .get(`${this.contacts}FamilyListGet`, params)
      .then(
        ({ data }) => data && data.FamilyList.map(insuranceFamilyListForUI)
      );
  }

  insuranceQuoteGet(filters) {
    const params = {
      module: 1,
      quoteId: 0,
      familyId: 0,
      ...filters,
    };
    return this.httpClient
      .get(`${this.insuranceQuote}InsuranceQuoteGet`, params)
      .then(({ data }) => data && data.map(insuranceQuoteForUI));
  }

  addPipelineItemLabel(params = {}) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.quoteId}/label`,
      {},
      params
    );
  }

  deletePipelineItemLabel(params = {}) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/${params.quoteId}/label`,
      params
    );
  }

  movePipelineCardToStatus(params = {}) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${params.quoteId}/card/status`,
      {},
      params
    );
  }

  getPipelineCoversionSummary() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/conversion`)
      .then(
        ({ data }) => data && data.map(insurancePipelineConversionSummaryForUI)
      );
  }

  getNotProceededReasons(statusId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/reasons`, { statusId }, true, false)
      .then(({ data }) => data && data.map(insuranceNotProceededReasonForUI));
  }

  getInsurancePipelineTableItems(params = {}) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/tables`, params)
      .then(({ data }) => mapInsurancePipelineTable(data));
  }

  deletePipelineCard(quoteId = 0) {
    return this.httpClient.delete(`${this.apiBaseUrl}/cards/${quoteId}`);
  }

  getInsurancePipelineFilters() {
    return this.httpClient.get(`${this.apiBaseUrl}/filter`).then(({ data }) => {
      const hasData = data && !data.Succeeded && !data.Messages;
      return mapInsurancePipelineFiltersForUI(hasData ? data : null);
    });
  }

  saveInsurancePipelineFilters(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/filter`,
      mapInsurancePipelineFiltersForMyCRM(data)
    );
  }

  getInsurancePipelineWorkbenchRecords(quoteId = 0) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/${quoteId}/workbench/records`
    );
  }

  deleteInsurancePipelineWorkbenchRecord(workbenchId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/workbench/${workbenchId}`
    );
  }

  getClientWorkBenchDetails(quoteId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${quoteId}/workbench/details`)
      .then(({ data }) => {
        const hasData = data && !data.Succeeded && !data.Messages;
        return insuranceWorbenchDetailsForUI(hasData ? data : null);
      });
  }

  getInsuranceAppCoverDetails(quoteId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${quoteId}/workbench/covers`)
      .then(({ data }) => mapInsuranceAppCoverDetails(data));
  }

  saveInsuranceAppCoverDetails(quoteId, params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${quoteId}/workbench/covers`,
      {},
      params
    );
  }

  saveInsuranceAppBenefitCover(benefitCoverId, params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/workbench/covers/${benefitCoverId}/benefit`,
      {},
      params
    );
  }

  deleteInsuranceAppBenefitCover(benefitCoverId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/workbench/covers/${benefitCoverId}/benefit`
    );
  }

  getInsuranceBenefits(providerId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/workbench/benefits`, { providerId })
      .then(({ data }) => data && data.map(insuranceBenefitForUI));
  }

  getBenefitCover(benefitCoverId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/workbench/${benefitCoverId}/benefit`)
      .then(({ data }) => benefitCoverBuilderForUI(data));
  }

  saveBenefitCover(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/workbench/benefit`,
      benefitCoverBuilderForMyCRM(data)
    );
  }

  getInsurancePolicyOwnership(quoteId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/workbench/policyownership/${quoteId}`)
      .then(({ data }) => data.map(insurancePolicyOwnershipBuilderForUI));
  }
}
