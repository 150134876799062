import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { PIPELINE_FILTER_TYPES } from 'Common/constants/pipelineFilterTypes';
import { objectLength } from 'Common/utilities/objectValidation';
import moment from 'moment';

export default class PipelineFiltersCtrl {
  constructor($timeout, contactService, pipelineSharedData, utilitiesService) {
    'ngInject';

    this.$timeout = $timeout;
    this.contactService = contactService;
    this.pipelineSharedData = pipelineSharedData;
    this.utilitiesService = utilitiesService;
  }

  $onInit() {
    if (
      this.pipelineType ===
      (this.pipelineTypes && this.pipelineTypes.APPLICATION)
    ) {
      this.filterColumns = this.pipelineSharedData.getApplicationTableColumns();
    } else if (this.pipelineType === this.pipelineTypes.LEADS) {
      this.filterColumns = this.pipelineSharedData.getLeadsTableColumns();
    }

    this.activeRangeIndex = null;
    this.displayFilterDate = '';
    this.selectedRange = { isOpen: false };
    this.filterRange = PICKER_RANGE;
    this.currentLabelSetting = 0;

    if (this.isSmallDevice && this.settings) {
      // below are for mobile modal filter
      this.activeFilters = [];
      let filters =
        (this.pipelineType &&
          PIPELINE_FILTER_TYPES[this.pipelineType.toUpperCase()]) ||
        [];
      if (this.isCorporateUser) {
        filters = filters.filter(o => o.FILTER_KEY !== 'labelId');
      }
      this.filterTypes = filters.map(filter => {
        const {
          FILTER_KEY: key,
          FILTER_TITLE: title,
          DEFAULT_VALUE: defaultValue,
          IS_SELECTED: isSelected,
        } = filter;
        const newFilter = { key, title, defaultValue, isSelected };
        return newFilter;
      });
      this.smallDeviceSettings = { ...this.settings };

      const hasEnquirySelected =
        this.pipelineType === this.pipelineTypes.LEADS &&
        typeof this.smallDeviceSettings.referralCategoryID === 'number' &&
        typeof this.smallDeviceSettings.referralItemID === 'number';
      if (hasEnquirySelected) {
        const findEnquiryObject = this.enquirySourceList.find(
          item =>
            item.ReferralItemId === this.smallDeviceSettings.referralItemID &&
            item.ReferralCategoryId ===
              this.smallDeviceSettings.referralCategoryID
        );
        if (findEnquiryObject)
          this.smallDeviceSettings.enquiryObject = { ...findEnquiryObject };
      }

      this.fillActiveFilters();
    }
  }

  $onChanges(changes) {
    if (!changes) return;
    const { labelsList, advisersList, settings } = changes;

    if (labelsList) {
      const { currentValue } = labelsList;
      if (!currentValue) return;

      this.finalLabelsList = [
        { pipelineSettingsLabelID: 0, labelName: 'All' },
        ...currentValue,
      ];
    }

    if (advisersList) {
      const { currentValue } = advisersList;
      if (!currentValue) return;

      this.contactService.getUserInfo().then(({ data }) => {
        const { AccessType } = data;
        const isCorporate = AccessType === ACCESS_TYPE.CORPORATE;

        const advisersFinalList = currentValue.map(adviser => {
          if (!adviser.brokerId)
            adviser.displayName = isCorporate ? 'None' : 'All';
          return adviser;
        });

        this.advisersList = this.isSmallDevice
          ? advisersFinalList.filter(o => o.brokerId)
          : advisersFinalList;
      });
    }

    if (settings) {
      const { currentValue } = settings;
      if (!currentValue) return;

      this.currentLabelSetting =
        currentValue.labelId !== null ? currentValue.labelId : 0;
      const { tableViewExcludedColumns } = currentValue;
      const tableViewExcludedColumnsArray =
        (tableViewExcludedColumns && tableViewExcludedColumns.split(',')) || [];
      tableViewExcludedColumnsArray.forEach(columnName => {
        if (this.filterColumns && this.filterColumns[columnName])
          this.filterColumns[columnName].show = false;
      });
    }
  }

  $onDestroy() {
    if (this.isSmallDevice) this.$timeout.cancel(this.applyingFilterTimeout);
  }

  hideShowColumn() {
    const excludedList = [];
    Object.keys(this.filterColumns).forEach(columnName => {
      if (!this.filterColumns[columnName].show) excludedList.push(columnName);
    });
    const excludedListString = excludedList.join(',');
    this.onChangeExcludeColumnsSettings({
      tableViewExcludedColumns: excludedListString,
    });
  }

  calendarUpdated(filterData) {
    const {
      DateCreatedStart: dateCreatedStart,
      DateCreatedEnd: dateCreatedEnd,
    } = filterData;
    const displayFilterDate = this.formatDateFilter(filterData);
    this.onChangeSettings({
      $e: { dateCreatedStart, dateCreatedEnd, displayFilterDate },
      triggerChildNotification: true,
    });
  }

  calendarUpdatedMobile(filterData) {
    this.smallDeviceSettings.displayFilterDate = this.formatDateFilter(
      filterData
    );

    const {
      DateCreatedStart: dateCreatedStart,
      DateCreatedEnd: dateCreatedEnd,
    } = filterData;
    this.smallDeviceSettings.dateCreatedStart = dateCreatedStart;
    this.smallDeviceSettings.dateCreatedEnd = dateCreatedEnd;
  }

  formatDateFilter(filterData) {
    if (!filterData) return;

    const { DateCreatedStart, DateCreatedEnd } = filterData;
    if (!DateCreatedStart) return;

    if (this.activeRangeIndex === PICKER_RANGE.TODAY) {
      return `Today, ${moment(DateCreatedStart).format('DD MMM YYYY')}`;
    }
    return `From ${moment(DateCreatedStart).format('DD MMM')} to ${moment(
      DateCreatedEnd
    ).format('DD MMM YYYY')}`;
  }

  rangeSelected(activeRangeIndex) {
    if (typeof activeRangeIndex === 'undefined') return; // filter only if undefined, can be null or zero
    this.activeRangeIndex = activeRangeIndex;
    if (this.activeRangeIndex !== PICKER_RANGE.CUSTOM)
      this.selectedRange.isOpen = false;
  }

  fillActiveFilters() {
    if (!this.smallDeviceSettings) return;

    this.filterTypes.map(filter => {
      const { key } = filter;
      const value = this.smallDeviceSettings[key];
      const testNumber = typeof value === 'number' && value > 0;
      const testString =
        typeof value === 'string' &&
        value.length &&
        value.toLowerCase() !== 'all' &&
        value.toLowerCase() !== 'none';
      const testEnquiry =
        key === 'enquiryObject' &&
        this.smallDeviceSettings.enquiryObject &&
        this.smallDeviceSettings.enquiryObject.ReferralCategoryId !== 0 &&
        this.smallDeviceSettings.enquiryObject.ReferralItemId !== 0;

      if (testNumber || testString || testEnquiry) {
        filter.isSelected = true;
        this.activeFilters = [...this.activeFilters, { ...filter }];
      }

      return filter;
    });
    this.filterAdviserInfo(this.smallDeviceSettings.advisorId);
  }

  filterAdviserInfo(adviserId) {
    this.selectedAdviser = {
      ...this.advisersList.find(o => o.brokerId === adviserId),
    };
    this.selectedAdviser.initials = this.utilitiesService.filterInitial(
      this.selectedAdviser.firstName,
      this.selectedAdviser.lastName
    );
  }

  openAddFilterPopover(bool = true) {
    this.isAddFilterPopoverOpen = bool;
  }

  applyFilter() {
    if (this.isDisableSaveFilterEdit) return;
    this.applyingFilter = true;

    this.applyingFilterTimeout = this.$timeout(() => {
      this.selectedFilterType.isSelected = true;

      this.activeFilters = [
        ...this.activeFilters,
        { ...this.selectedFilterType },
      ];

      this.filterTypes = this.filterTypes.map(filter => {
        if (filter.key === this.selectedFilterType.key) {
          filter.isSelected = true;
        }
        return filter;
      });

      if (this.selectedFilterType.key === 'advisorId') {
        this.filterAdviserInfo(this.smallDeviceSettings.advisorId);
      }

      this.selectedFilterType = null;
      this.applyingFilter = false;
      this.isAddFilterPopoverOpen = false;
    }, 1000);
  }

  editFilter(selectedFilter) {
    this.activeFilters.map(filter => {
      if (filter.key === selectedFilter.key) {
        filter.isEdit = !filter.isEdit;
      }
      return filter;
    });
    if (selectedFilter.key === 'advisorId') {
      this.filterAdviserInfo(this.smallDeviceSettings.advisorId);
    }
    this.disableSaveFilter();
  }

  removeFilter(selectedFilter) {
    this.filterTypes.map(filter => {
      if (filter.key === selectedFilter.key) {
        filter.isEdit = false;
        filter.isSelected = false;
      }
      return filter;
    });

    this.activeFilters.map(filter => {
      if (filter.key === selectedFilter.key) {
        filter.isEdit = false;
        filter.isSelected = false;
        this.smallDeviceSettings[selectedFilter.key] = filter.defaultValue;
      }
      return filter;
    });

    if (selectedFilter.key === 'advisorId') {
      this.filterAdviserInfo(this.smallDeviceSettings.advisorId);
    } else if (selectedFilter.key === 'displayFilterDate') {
      this.smallDeviceSettings.dateCreatedStart = null;
      this.smallDeviceSettings.dateCreatedEnd = null;
    }
    this.disableSaveFilter();
  }

  disableSaveFilter() {
    this.isDisableSaveFilter = !!this.activeFilters.find(
      filter => filter.isEdit
    );
  }

  disableSaveFilterEdit() {
    if (!this.selectedFilterType) return;

    const value = this.smallDeviceSettings[this.selectedFilterType.key];

    const testNumber = typeof value === 'number' && value > 0;
    const testString =
      typeof value === 'string' &&
      value.length &&
      value.toLowerCase() !== 'all' &&
      value.toLowerCase() !== 'none';
    const testEnquiry =
      this.selectedFilterType.key === 'enquiryObject' &&
      this.smallDeviceSettings.enquiryObject.ReferralCategoryId !== 0 &&
      this.smallDeviceSettings.enquiryObject.ReferralItemId !== 0;

    const isDisabled = !(testNumber || testString || testEnquiry);
    this.isDisableSaveFilterEdit = isDisabled;
    return isDisabled;
  }

  saveFilter() {
    if (!this.isSmallDevice) return;

    const changes = this.activeFilters.reduce((accum, current) => {
      if (current.key === 'enquiryObject') {
        accum.referralItemID = this.smallDeviceSettings.enquiryObject.ReferralItemId;
        accum.referralCategoryID = this.smallDeviceSettings.enquiryObject.ReferralCategoryId;
      } else if (
        current.key === 'displayFilterDate' &&
        this.smallDeviceSettings.displayFilterDate
      ) {
        const { leadFilterData } = this.pipelineSharedData;
        const {
          DateCreatedStart: dateCreatedStart,
          DateCreatedEnd: dateCreatedEnd,
        } = leadFilterData;
        accum.dateCreatedStart = dateCreatedStart;
        accum.dateCreatedEnd = dateCreatedEnd;
      } else {
        accum[current.key] = this.smallDeviceSettings[current.key];
      }
      return accum;
    }, {});
    this.modalInstance.close({
      changes: changes && objectLength(changes) ? { ...changes } : null,
    });
  }

  cancel() {
    this.modalInstance && this.modalInstance.close();
  }
}
