import angular from 'angular';
import { INSURANCE_OPTIONS } from 'Common/constants/insuranceOptions';

angular.module('app').directive('resize', function resizeFunc($window) {
  return function(scope) {
    scope.isMobileDisplayView = false;

    const screenWidth = () => {
      scope.windowWidth = $window.innerWidth;
      if (scope.windowWidth > INSURANCE_OPTIONS.DEVICE_WIDTH.MOBILE) {
        scope.isMobileDisplayView = false;
      } else if (scope.windowWidth <= INSURANCE_OPTIONS.DEVICE_WIDTH.MOBILE) {
        scope.isMobileDisplayView = true;
      }
    };

    $window.addEventListener('resize', screenWidth);
    scope.$on('$destroy', () => {
      $window.removeEventListener('resize', screenWidth);
    });

    screenWidth();
  };
});
