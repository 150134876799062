import { OpportunityNotes } from 'Common/services/opportunityNotes';
import { getTextCharacterLimit } from 'Common/utilities/opportunityNotes';
import {
  toastError,
  displayError,
  toastSuccess,
  alertSucess,
} from 'Common/utilities/alert';

export default class OpportunityNotesModalCtrl {
  constructor(loanOpportunityService, referralsService, contactService) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.referralsService = referralsService;
    this.contactService = contactService;
  }

  $onInit() {
    this.opportunityNotes = new OpportunityNotes(this.noteObj);
    this.noteObj = this.opportunityNotes.note;
    this.characterCount = `0 / 140`;
    this.onLoadCheckSharingOptions();
  }

  onLoadCheckSharingOptions() {
    const minimumNumberLength = 8;
    const isNumberValid =
      this.noteObj &&
      this.noteObj.smsObj &&
      this.noteObj.smsObj.mobile &&
      this.noteObj.smsObj.mobile.length > minimumNumberLength;
    const isEmailValid =
      this.noteObj &&
      this.noteObj.emailObj &&
      this.noteObj.emailObj.referrerEmail &&
      this.noteObj.emailObj.referrerEmail.length;

    this.disableShareSms = !(
      this.referralId &&
      this.isSmsEnabled &&
      isNumberValid
    );
    this.disableShareEmail = !(this.referralId && isEmailValid);
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  noteAPICall(communicationId) {
    if (communicationId)
      return this.loanOpportunityService.updateLoanOpportunityNote(
        this.noteObj,
        communicationId
      );
    return this.loanOpportunityService.setLoanOpportunityNote(
      this.noteObj,
      communicationId
    );
  }

  saveNote() {
    this.isSaving = true;
    const { communicationId } = this.noteObj;
    if (this.isNotOpportunityModule) {
      this.saveNoteContacts(communicationId);
    } else {
      this.isNotOpportunityModule = false;
      this.noteAPICall(communicationId)
        .then(response => {
          const { communicationId: resCommunicationId } = response;
          if (resCommunicationId) {
            if (this.noteObj.referrerShareSms)
              this.shareToReferrerViaSms(resCommunicationId);
            if (this.noteObj.referrerShareEmail)
              this.shareToReferrerViaEmail(resCommunicationId);
            this.modalInstance.close();
          } else {
            toastError();
            this.isSaving = false;
          }
        })
        .catch(err => {
          displayError(err);
        });
    }
  }

  saveNoteActivityDetailsFormat() {
    const { smsObj, emailObj, activityDetails } = this.noteObj;
    const smsBody = smsObj.body ? `[smsBody]${smsObj.body}` : '';
    const emailBody = emailObj.body ? `[emailBody]${emailObj.body}` : '';
    const notes = `${activityDetails}${smsBody}${emailBody}`;
    return notes;
  }

  saveNoteContacts(communicationId) {
    const { referrerShareSms, referrerShareEmail } = this.noteObj;
    const isSharedWithReferrer = referrerShareSms || referrerShareEmail;
    const activityDetails = isSharedWithReferrer
      ? this.saveNoteActivityDetailsFormat()
      : this.noteObj.activityDetails;
    const notePayload = {
      ...this.noteObj,
      isSharedWithReferrer,
      activityDetails,
    };
    this.contactService
      .noteSet(notePayload, communicationId)
      .then(response => {
        const alertMsg = `Note has been successfully created.`;
        alertSucess(null, alertMsg, true);
        if (!response.data) this.modalInstance.close();
        if (this.noteObj.referrerShareSms)
          this.shareToReferrerViaSms(response.data);
        if (this.noteObj.referrerShareEmail)
          this.shareToReferrerViaEmail(response.data);
        this.modalInstance.close();
      })
      .catch(err => {
        displayError(err);
      });
  }

  setEmailAndSMSBody(data, isReferrer) {
    const { activityDetails, clientName, brokerName } = this.noteObj;
    const { body } = data;
    const bodyContent = activityDetails
      ? `Update on ${clientName} from ${brokerName}: ${activityDetails}`
      : '';
    return isReferrer ? bodyContent : body;
  }

  getNoteCopySmsToShare() {
    if (!this.isNotOpportunityModule) return;
    const { smsObj, referrerShareSms } = this.noteObj;
    smsObj.body = this.setEmailAndSMSBody(smsObj, referrerShareSms);
  }

  getNoteCopyEmailToShare() {
    if (!this.isNotOpportunityModule) return;
    const { emailObj, referrerShareEmail } = this.noteObj;
    emailObj.body = this.setEmailAndSMSBody(emailObj, referrerShareEmail);
  }

  shareToReferrerViaSms(communicationId) {
    const { smsObj } = this.noteObj;
    this.loanOpportunityService
      .shareReferrerViaSms(communicationId, smsObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) toastSuccess(`Successfully sent SMS to Referrer`);
      });
  }

  shareToReferrerViaEmail(communicationId) {
    const { emailObj } = this.noteObj;
    this.loanOpportunityService
      .shareReferrerViaEmail(communicationId, emailObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) toastSuccess('Successfully sent Email to Referrer');
      });
  }

  changeSmsBody(textAreaModel) {
    this.noteObj.smsObj.body = textAreaModel;
    this.getCharacterCount();
  }

  getCharacterCount() {
    const currentCharacterCount =
      (this.noteObj &&
        this.noteObj.smsObj.body &&
        this.noteObj.smsObj.body.length) ||
      0;
    const baseCharacterLimit = 140;
    const characterLimit = getTextCharacterLimit(
      baseCharacterLimit,
      currentCharacterCount
    );
    this.characterCount = `${currentCharacterCount} / ${characterLimit}`;
  }

  changeEmailObj(emailModel) {
    this.noteObj.emailObj = { ...emailModel };
  }
}
