import template from './searchInputField.html';
import controller from './searchInputFieldCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    inputChanged: '&',
    inputStatus: '<',
    onStateChange: '&',
  },
};
