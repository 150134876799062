import template from './pipelineStats.html';
import controller from './pipelineStatsCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    proceededLabel: '@',
    notProceededLabel: '@',
    proceededStatsColor: '@',
    notProceededStatsColor: '@',
    proceededStatsValue: '<',
    notProceededStatsValue: '<',
  },
};
