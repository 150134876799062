import template from './widgetField.html';
import controller from './widgetFieldCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fieldId: '@',
    model: '<',
    field: '<',
    onUpdate: '&',
    onSubFieldUpdate: '&',
  },
};
