class StateService {
  constructor($localStorage) {
    'ngInject';

    this.$localStorage = $localStorage;
  }
  savePrevState(self, parameters = null) {
    this.$localStorage.prevState = {
      self,
      param: parameters,
    };
  }

  getPreviousState() {
    return this.$localStorage.prevState;
  }
  destroyPrevState() {
    this.$localStorage.prevState = null;
  }
}

export default StateService;
