import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { leadsToOpportunityConvert } from 'Common/utilities/opportunityConversion';
import { toastError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('PipelineListViewCtrl', function PipelineListViewCtrl(
    $scope,
    $rootScope,
    $http,
    SweetAlert,
    configService,
    $filter,
    $window,
    dashboardService,
    imageDataURI,
    $timeout,
    $templateCache,
    overviewDashboardService,
    contactService,
    opportunityNewModalService,
    pipelineService,
    toaster,
    NgTableParams,
    $uibModal,
    uiService,
    userService,
    communicateService,
    optionsService,
    pipelineSharedData,
    $q,
    modalRenderService,
    enquirySourceService
  ) {
    /** ******************************************** */
    /* STATUS IDS                                  */
    /* 1 = Not Proceeding
       2 = New Leads ( or Lead Step 1 )
       3 = Appointment Made ( or Lead Step 2 )
       4 = Pre-Approved ( or Lead Step 3 )
       5 = To Application ( or Application Step 1 ) */
    /** ******************************************** */
    $scope.itemStatusList = [];
    $scope.itemStatusList[1] = 'Not Proceeding';
    $scope.itemStatusList[2] = 'New Leads';
    $scope.itemStatusList[3] = 'Appointment Made';
    $scope.itemStatusList[4] = 'Pre-Approved';
    $scope.itemStatusList[5] = 'To Application';
    $rootScope.sortType = '';
    $rootScope.sortColumn = '';
    $scope.resolvedData = [];
    $scope.pipelineSharedData = pipelineSharedData;
    $scope.totalTransactionAmount = 0;

    $scope.listViewTable = {};
    const pageSize = 14;

    $scope.colorPickerPopoverList = {
      content: '',
      templateUrl: 'colorPickerPopover2.html',
      templateUrlForLabel: 'colorPickerPopoverForLabel2.html',

      title: '',
    };
    $scope.$watch('pipelineSharedData.dataStatus.isUpdatePipelineLead', nv => {
      if (nv) {
        pipelineSharedData.dataStatus.isUpdatePipelineLead = false;
        $scope._initListView();
      }
    });
    $scope.$watch(
      'pipelineSharedData.leadFilterData',
      nv => {
        if (typeof nv.isCompact === 'undefined') {
          nv.isCompact = '0';
        }

        if (
          nv.enquiryId === 'All' ||
          nv.enquiryId === 'null' ||
          nv.enquiryId === null ||
          typeof nv.enquiryId === 'undefined' ||
          !nv.enquiryId
        ) {
          nv.enquiryId = 0;
        }

        if (
          nv.referralId === 'All' ||
          nv.referralId === 'null' ||
          nv.referralId === null ||
          typeof nv.referralId === 'undefined' ||
          !nv.referralId
        ) {
          nv.referralId = -1;
        }

        if (parseInt(nv.probability, 10) === 1) {
          nv.probability = 'Hot';
        } else if (parseInt(nv.probabilitym, 10) === 2) {
          nv.probability = 'Warm';
        } else if (parseInt(nv.probability, 10) === 3) {
          nv.probability = 'Cold';
        } else if (
          nv.probability === 'All' ||
          nv.probability === 'null' ||
          nv.probability === null ||
          typeof nv.probability === 'undefined' ||
          !nv.probability
        ) {
          nv.probability = 'All';
        }
      },
      true
    );

    $('.pipelineContainer ').on('click', eve => {
      const popoverArea = $('.colorPickerPopoverWrapper');
      const elmParent = $(eve.target).closest('.colorPickerPopoverWrapper');
      if (!$scope.resolvedData) {
        return;
      }
      let elmHashKey = null;
      if (elmParent.length > 0) {
        elmHashKey = elmParent[0].$$hashKey || '';
      }

      _.forEach(popoverArea, elm => {
        if (typeof elm.$$hashKey !== 'undefined' && elmParent.length > 0) {
          if (elm.$$hashKey !== elmHashKey) {
            const eventTimeout = $timeout(() => {
              $(elm)
                .closest('.popover')
                .prev()
                .click();
              $timeout.cancel(eventTimeout);
            });
          }
        }
      });
      if (
        !popoverArea.is(elmParent) &&
        popoverArea.has(elmParent).length === 0
      ) {
        if ($scope.resolvedData.length > 0) {
          for (let i = 0; i < $scope.resolvedData.length; i++) {
            if ($scope.resolvedData[i].textOpen) {
              $scope.resolvedData[i].textOpen = false;
            }

            for (let k = 0; k < $scope.resolvedData[i].LabelArray.length; k++) {
              if (
                $scope.resolvedData[i].LabelArray[k].textOpenForNoLabel ||
                $scope.resolvedData[i].LabelArray[k].textOpenForColoredLabel
              ) {
                $scope.resolvedData[i].LabelArray[k].textOpenForNoLabel = false;
                $scope.resolvedData[i].LabelArray[
                  k
                ].textOpenForColoredLabel = false;
              }
            }
          }
        }
      }
    });

    $scope.filterRange = PICKER_RANGE;

    $scope.activeRangeIndex = null;
    $scope.selectedRange = {
      isOpen: false,
    };

    $scope.formatDateFilter = () => {
      if (
        !$scope.pipelineSharedData ||
        !$scope.pipelineSharedData.leadFilterData
      )
        return;
      const {
        DateCreatedStart,
        DateCreatedEnd,
      } = $scope.pipelineSharedData.leadFilterData;
      if (!DateCreatedStart) return;

      if ($scope.activeRangeIndex === PICKER_RANGE.TODAY) {
        return `Today, ${moment(DateCreatedStart).format('DD MMM YYYY')}`;
      }
      return `From ${moment(DateCreatedStart).format('DD MMM')} to ${moment(
        DateCreatedEnd
      ).format('DD MMM YYYY')}`;
    };

    $scope.onRangeSelected = activeRangeIndex => {
      if (typeof activeRangeIndex === 'undefined') return; // filter only if undefined, can be null or zero
      $scope.activeRangeIndex = activeRangeIndex;
      if (activeRangeIndex !== PICKER_RANGE.CUSTOM)
        $scope.selectedRange.isOpen = false;
    };
    $scope.calendarUpdated = filterData => {
      if (!filterData) return;
      $scope.pipelineSharedData.leadFilterData.DateCreatedStart = filterData.DateCreatedStart
        ? filterData.DateCreatedStart
        : '';
      $scope.pipelineSharedData.leadFilterData.DateCreatedEnd = filterData.DateCreatedEnd
        ? filterData.DateCreatedEnd
        : '';
      $scope.getListViewLeadsData(true);
      $scope.displayFilterDate = $scope.formatDateFilter();
    };

    $scope.addlabelFromListView = list => {
      $scope.list = list;
      $scope.list.addLabelShown = true;
      $scope.list.lblObjToEdit = null;
      $scope.list.labelTitle = '';
      list.addLabelShown = $scope.list.addLabelShown;
      $scope.pickedColorCode = '';
    };
    $scope.editLabel = (lbl, list) => {
      $scope.list = list;
      $scope.list.editLabelShown = true;
      list.editLabelShown = $scope.list.editLabelShown;

      $scope.list.lblObjToEdit = lbl;
      list.labelTitle = lbl.LabelName;
      list.lblObjToEdit = $scope.list.lblObjToEdit;
      $scope.pickedColorCode = lbl.ColorName;
    };
    $scope.deleteLabel = labelObj => {
      if (labelObj.pipelineSettingsLabelID !== undefined) {
        $scope.deletelbl = {};
        $scope.deletelbl.LabelID = labelObj.pipelineSettingsLabelID;
        pipelineService.PipelineSettingLabelDelete($scope.deletelbl).then(
          () => {
            toaster.pop(
              'success',
              'Deleted',
              'Label has been deleted successfully.'
            );

            $scope.reloadTableView(true, false);
            $scope.getLabels();
          },
          () => {
            toaster.pop('error', 'Error', 'Yikes! Something is wrong');
          }
        );
      }
    };
    $scope.updateLabel = list => {
      $scope.lblsToUpdate = [];
      pipelineSharedData.updateLabel(
        list,
        $scope.labelObj,
        $scope.lblsToUpdate,
        $scope.pickedColorCode
      );

      pipelineService.PipelineSettingsLabelSet($scope.lblsToUpdate).then(
        () => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          list.addLabelShown = false;
          list.editLabelShown = false;
          $scope.getLabels();
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.colorPicked = color => {
      $scope.pickedColorCode = color;
    };

    $scope.filterInitial = (...args) => {
      let i;
      let initials = '';
      if (!args || args[0] === undefined) {
        return;
      }
      for (i = 0; i < args.length; i++) {
        if (!_.isNil(args[i]) && _.isString(args[i])) {
          initials = `${initials}${args[i].charAt(0).toUpperCase()}`;
        }
      }
      return initials;
    };
    $scope.getStatus = () => {
      pipelineService.SettingsStatusGet(false, true).then(response => {
        $scope.statusList = response.data;
        $scope.statusDataList = [
          { SubStatus: [{ pipelineSubStatusID: 0, SubStatusName: 'All' }] },
          ...response.data,
        ];
        const leadStatusGroup = [];
        const otherStatusGroup = [];

        $scope.statusList.forEach(obj => {
          if (obj.TypeName === 'Lead') {
            leadStatusGroup.push(obj);
          } else if (!obj.PipelineCategoryID) {
            otherStatusGroup.push(obj);
          }
        });
        $scope.statusListDdlLeadsRadio = [leadStatusGroup, otherStatusGroup];
        $scope.statusListDdlLeads = [...leadStatusGroup, ...otherStatusGroup];

        const {
          StatusIds,
          ConversionStatusIDs,
        } = pipelineSharedData.leadFilterData;
        let listOfStatusIds = StatusIds ? StatusIds.split(',') : [];
        let listOfConversionStatusIds = ConversionStatusIDs
          ? ConversionStatusIDs.split(',')
          : [];
        listOfStatusIds = listOfStatusIds.map(id => parseInt(id, 10));
        listOfConversionStatusIds = listOfConversionStatusIds.map(id =>
          parseInt(id, 10)
        );

        pipelineSharedData.leadFilterData.PipelineStatusIDs = [];

        leadStatusGroup.forEach(obj => {
          if (
            listOfStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
            pipelineSharedData.leadFilterData.isFetchAllPipelineStatusIDs
          ) {
            pipelineSharedData.leadFilterData.PipelineStatusIDs.push(obj);
          }
        });

        otherStatusGroup.forEach(obj => {
          if (
            listOfConversionStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
            pipelineSharedData.leadFilterData.isFetchAllPipelineStatusIDs
          ) {
            pipelineSharedData.leadFilterData.PipelineStatusIDs.push(obj);
          }
        });

        $scope.statusListDdlLeads = $scope.statusListDdlLeads.filter(
          obj => obj.PipelineStatus !== 'Not Proceeded'
        );

        $scope.statusListDdl = [...$scope.statusListDdlLeads];
      });
    };
    $scope.getAdvisersForFilter = () => {
      pipelineSharedData.getAdvisersListData().then(adviserList => {
        $scope.adviserList = adviserList;
        $scope.taskAdviserList = angular.copy($scope.adviserList);
        if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
          if ($scope.taskAdviserList.length > 0) {
            $scope.pipelineSharedData.leadFilterData.adviserId = angular.copy(
              $scope.taskAdviserList[0].AdvisorsID
            );
          }
        }
      });
    };
    $scope.getLabelsForFilter = callback => {
      pipelineService.PipelineSettingsLabel().then(
        response => {
          $scope.labels = response.data;
          $scope.labelsList = angular.copy($scope.labels);
          $scope.labelsList.splice(0, 0, {
            pipelineSettingsLabelID: 0,
            LabelName: 'All',
          });
          if (
            typeof $scope.pipelineSharedData.leadFilterData === 'undefined' ||
            !_.find($scope.labelsList, o => {
              return (
                String(o.pipelineSettingsLabelID) ===
                String($scope.pipelineSharedData.leadFilterData.labelId)
              );
            })
          ) {
            if ($scope.labelsList.length > 0) {
              $scope.pipelineSharedData.leadFilterData.labelId = angular.copy(
                $scope.labelsList[0].pipelineSettingsLabelID
              );
            }
          }

          // This is to proceed with the fetching of actual data
          callback();
        },
        () => {
          $scope.pipelineSharedData.isCategoriesLoading = false;
        }
      );
    };
    $scope.getProbabilitiesForFilter = () => {
      $scope.probabilityListforFilter = [
        {
          probability: 'Hot',
          probabilityId: 1,
          probabilityColor: '#ec7777',
        },
        {
          probability: 'Warm',
          probabilityId: 2,
          probabilityColor: '#fa9c5d',
        },
        {
          probability: 'Cold',
          probabilityId: 3,
          probabilityColor: '#66a3cf',
        },
      ];
      $scope.probabilityListforFilter = angular.copy(
        $scope.probabilityListforFilter
      );
      $scope.probabilityListforFilter.splice(0, 0, {
        probabilityId: 0,
        probability: 'All',
      });
      if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
        if ($scope.probabilityListforFilter.length > 0) {
          $scope.pipelineSharedData.leadFilterData.probability = angular.copy(
            $scope.probabilityListforFilter[0].probability
          );
        }
      }
    };
    $scope.getAllEnquirySource = () => {
      $scope.enquirySourceList = [];
      $scope.enquiryList = [];

      optionsService.getEnquirySourceList().then(pipelineListResponse => {
        $scope.enquiryList = pipelineListResponse.data;
        $scope.enquirySourceList = enquirySourceService.getEnquirySourceListData(
          pipelineListResponse.data
        );
        $scope.enquirySourceList.splice(0, 0, {
          ReferralItemId: 0,
          ReferralItemName: 'All',
          ReferralCategoryId: 0,
          ReferralCategoryName: '',
        });
        enquirySourceService.selectEnquiry(
          $scope.pipelineSharedData,
          $scope.enquirySourceList
        );
      });
    };

    $scope.getAllReferrals = () => {
      optionsService.getReferencedReferralList(undefined).then(response => {
        $scope.referralList = response.data;
        $scope.referralListCopy = angular.copy($scope.referralList);
        $scope.referralListCopy.splice(0, 0, {
          ReferralMemberId: -1,
          ReferralOrganizationName: 'All',
        });
        if (typeof $scope.pipelineSharedData.leadFilterData === 'undefined') {
          if ($scope.referralListCopy && $scope.referralListCopy.length > 0) {
            $scope.pipelineSharedData.leadFilterData.referralId =
              $scope.referralListCopy[0].ReferralMemberId;
          }
        }

        _.forEach($scope.referralListCopy, o => {
          if (o.ReferralOrganizationName !== 'All') {
            o.ReferralOrganizationName +=
              (o.ReferralOrganizationName !== '' ? ' - ' : '') +
              o.ReferralMemberName;
          }
        });
      });
    };

    $scope.defaultSortSet = () => {
      pipelineService.setPipelineCardFilters(
        pipelineSharedData.getSortingForSet(true, true)
      );
    };

    $scope.hideShowColumn = () => {
      let $postString = '';

      _.forEach($scope.Table.FilterColumns, o => {
        if (!o.show) {
          $postString += `${o.field},`;
        }
      });
      $postString = $postString.slice(0, -1);

      $scope.pipelineSharedData.leadFilterData.TableViewExcludeColumns = $postString;
      $scope.pipelineSharedData.leadFilterData.TableViewExcludeColumnsArray = $postString.split(
        ','
      );

      $scope.defaultSortSet();
    };

    // filter for leads listview
    $scope.filterStatus = values => {
      pipelineSharedData.leadFilterData.PipelineStatusIDs = [...values];
      let StatusIds = [];
      let ConversionStatusIDs = [];
      pipelineSharedData.leadFilterData.PipelineStatusIDs.forEach(obj => {
        if (obj.IsConversionStatus) {
          ConversionStatusIDs.push(obj.PipelineStatusID);
        } else {
          StatusIds.push(obj.PipelineStatusID);
        }
      });
      StatusIds = StatusIds.join(',');
      ConversionStatusIDs = ConversionStatusIDs.join(',');
      pipelineSharedData.leadFilterData.StatusIds = StatusIds;
      pipelineSharedData.leadFilterData.ConversionStatusIDs = ConversionStatusIDs;

      $scope.getListViewLeadsData(true);
    };
    $scope.filterAdviser = () => {
      $scope.getListViewLeadsData(true);
    };
    $scope.filterLabel = () => {
      $scope.getListViewLeadsData(true);
    };
    $scope.filterProbability = () => {
      $scope.getListViewLeadsData(true);
    };
    $scope.filterEnquiry = () => {
      const isValidEnquiryId =
        $scope.pipelineSharedData &&
        $scope.pipelineSharedData.leadFilterData &&
        $scope.pipelineSharedData.leadFilterData.enquiryId;
      if (isValidEnquiryId) {
        $scope.pipelineSharedData.leadFilterData.ReferralItemID =
          $scope.pipelineSharedData.leadFilterData.enquiryId.ReferralItemId ||
          0;
        $scope.pipelineSharedData.leadFilterData.ReferralCategoryID =
          $scope.pipelineSharedData.leadFilterData.enquiryId
            .ReferralCategoryId || 0;
      }
      $scope.getListViewLeadsData(
        $scope.pipelineSharedData &&
          $scope.pipelineSharedData.leadFilterData &&
          !_.isNull($scope.pipelineSharedData.leadFilterData.enquiryId) &&
          !_.isUndefined($scope.pipelineSharedData.leadFilterData.enquiryId)
      );
    };
    $scope.filterReferral = () => {
      $scope.getListViewLeadsData(true);
    };
    $scope.filterSubStatus = () => {
      $scope.getListViewLeadsData(true);
    };
    // filterEnd

    // deleteStart
    $scope.deleteCardFromList = listObj => {
      $scope.listForDel = {};
      $scope.listForDel.cardsID = listObj.PipelineCardsID;

      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            pipelineService.PipelineCardsDelete($scope.listForDel).then(() => {
              $timeout(() => {
                $scope._initListView();

                SweetAlert.swal(
                  'Success',
                  'Item has been successfully deleted.',
                  'success'
                );
              }, 100);
            });
          }
        }
      );
    };

    const setFilterOptionsToAll = $rootScope.$on('getListViewLeadsData', () => {
      $scope.getListViewLeadsData();
    });
    $scope.$on('$destroy', setFilterOptionsToAll);
    $scope.getListViewLeadsData = showLoading => {
      let statusId = $scope.pipelineSharedData.leadFilterData.PipelineStatusID;
      let advisersId = $scope.pipelineSharedData.leadFilterData.adviserId;
      let labelId = $scope.pipelineSharedData.leadFilterData.labelId;
      let probability = $scope.pipelineSharedData.leadFilterData.probability;
      let enquirySourceId = $scope.pipelineSharedData.leadFilterData.enquiryId;
      let referralId = $scope.pipelineSharedData.leadFilterData.referralId;
      const { isFirstLoad } = pipelineSharedData;

      $scope.pipelineSharedData.isCategoriesLoading = showLoading;

      // PipelineCardsTableViewGet
      $scope.totalTransactionAmount = 0;
      $scope.mainObj = {};
      $scope.mainObj.CurrentPage = 1;
      if (statusId === undefined) {
        statusId = 0;
      }

      if (labelId === undefined) {
        labelId = 0;
      }
      if (probability === '' || probability === undefined) {
        probability = 'All';
      }
      if (
        enquirySourceId === '' ||
        enquirySourceId === undefined ||
        enquirySourceId === 0
      ) {
        enquirySourceId = 'All';
      }
      if (referralId === '' || referralId === undefined || referralId === -1) {
        referralId = 'All';
      }

      userService.GetUserInfo().then(
        response => {
          if (response.data) {
            if (response.data.AccessType === 5) {
              $rootScope.isCorporateUser = true;
            }
            $scope.adviserId = advisersId;
            if (!uiService.isCorporateUser && $scope.adviserId === 0) {
              dashboardService.getBrokerBasicInfo().then(brokerInfoResponse => {
                $scope.familyId = brokerInfoResponse.data.BrokerId;
                $scope.fullName = brokerInfoResponse.data.FullName;
              });
              $scope.adviserId = $scope.familyId;
            }

            if (uiService.isCorporateUser && $scope.adviserId === 0) {
              $scope.initTableParam();
              $scope.listViewTable = [];

              $scope.defaultSortSet();
              $scope.$emit('eventLeadsListViewLoaded');
              $scope.pipelineSharedData.isCategoriesLoading = false;
              pipelineSharedData.isFirstLoad = false;
              return false;
            }

            advisersId =
              typeof advisersId === 'undefined' || advisersId === 'undefined'
                ? 0
                : advisersId;

            if (isFirstLoad) {
              $scope.initTableParam(showLoading);
              pipelineSharedData.isFirstLoad = false;
            } else {
              const data = pipelineSharedData.getSortingForSet(true, true);
              data.SortColumn =
                $scope.pipelineSharedData.leadFilterData.SortColumn ||
                'StatusUpdated';
              data.SortType =
                $scope.pipelineSharedData.leadFilterData.SortType || 'DESC';
              data.AdvisorId = advisersId;

              pipelineService.setPipelineCardFilters(data).then(() => {
                $scope.initTableParam(showLoading);
              });
            }
          }
        },
        () => {
          $scope.pipelineSharedData.isCategoriesLoading = false;
        }
      );
    };

    $scope.getTableView = (
      pageNumber = 1,
      tablePromise,
      showLoading = true
    ) => {
      const isLeads = true;
      const searchClientName = $scope.filter ? $scope.filter.$ : '';
      const params = { isLeads, pageNumber, pageSize };
      if (searchClientName) {
        params.searchClientName = searchClientName;
      }
      $scope.pipelineSharedData.isCategoriesLoading = showLoading;

      pipelineService.getPipelineCardViewTable(params).then(
        response => {
          $scope.firstTime = true;
          $scope.$emit('eventLeadsListViewLoaded');
          if ($('.table_container')) {
            $('.table_container').height('auto');
          }
          if (uiService.isCorporateUser && $scope.adviserId === 0) {
            $scope.pipelineSharedData.isCategoriesLoading = false;
            return;
          }

          $scope.listViewTable =
            pipelineSharedData.formatListViewData(
              response.data,
              $scope.statusListDdlLeads
            ) || [];
          const totalRecords = pipelineSharedData.getTotalRecords(
            $scope.listViewTable
          );
          $scope.listViewTableParams.total(totalRecords);

          const timeout = showLoading ? 700 : 0;
          $scope.loaderTimeout = $timeout(() => {
            $scope.pipelineSharedData.isCategoriesLoading = false;
            if ($scope.loaderTimeout) $timeout.cancel($scope.loaderTimeout);
          }, timeout);

          if (tablePromise) {
            tablePromise.resolve($scope.listViewTable);
          }
        },
        () => {
          $scope.leadsRefreshing = false;
          $scope.pipelineSharedData.isCategoriesLoading = false;
          $scope.listViewTableParams.total(0);
          tablePromise.resolve([]);
        }
      );
    };
    $scope.$on('$destroy', () => {
      $scope.loaderTimeout && $timeout.cancel($scope.loaderTimeout);
      $scope.waitFilterTime && $timeout.cancel($scope.waitFilterTime);
    });

    $scope.filter = {
      $: '',
    };
    $scope.searchChange = () => {
      $timeout.cancel($scope.searchTimeout);
      $scope.searchTimeout = $timeout(() => {
        if ($scope.filter.$.length > 0) {
          if (!$scope.mainObj.currentPage) {
            $scope.mainObj.currentPage = $scope.listViewTableParams.page();
          }
        } else {
          $scope.mainObj.currentPage = null;
        }

        $scope.reloadTableView();
      }, 1000);
    };
    $scope.$on('$destroy', () => {
      if ($scope.searchTimeout) {
        $timeout.cancel($scope.searchTimeout);
      }
    });

    $scope.Table = { FilterColumns: {} };
    $scope.initTableParam = showLoading => {
      $scope.showTableLoading = showLoading;
      if ($scope.listViewTableParams) {
        $scope.reloadTableView(false, showLoading);
      } else {
        $scope.listViewTableParams = new NgTableParams(
          {
            count: pageSize,
            page: 1,
          },
          {
            counts: [],
            getData($defer, params) {
              if (uiService.isCorporateUser && $scope.adviserId === 0) {
                $scope.listViewTable = [];
                $defer.resolve($scope.listViewTable);
                $scope.$emit('eventLeadsListViewLoaded');
                $scope.pipelineSharedData.isCategoriesLoading = false;
                return;
              }
              const column = params.orderBy();
              const key =
                column && column[0] ? column[0].replace(/(\+)|(-)/g, '') : null;
              let isSortingChanged = (key || '') !== ($scope.sortColumn || '');
              $scope.sortColumn = column[0];

              const sorting = params.sorting();
              if (key && sorting[key]) {
                isSortingChanged =
                  isSortingChanged || sorting[key] !== $scope.sortType;
                $scope.sortColumn = key;
                $scope.sortType = sorting[key];
              }

              const triggeredBySort = isSortingChanged;
              $scope.showTableLoading = !triggeredBySort;

              if ($scope.sortType && $scope.sortColumn && isSortingChanged) {
                const { sortColumn, sortType, isLeads } = $scope;
                const cardFilters = pipelineSharedData.getSortingForSet(
                  isLeads,
                  true
                );
                cardFilters.SortColumn = sortColumn;
                cardFilters.SortType = sortType;

                pipelineService.setPipelineCardFilters(cardFilters).then(() => {
                  $scope.getTableView(
                    params.page(),
                    $defer,
                    $scope.showTableLoading
                  );
                  $scope.showTableLoading = true;
                  return $defer.promise;
                });
              } else {
                $scope.getTableView(
                  params.page(),
                  $defer,
                  $scope.showTableLoading
                );
                $scope.showTableLoading = true;
                return $defer.promise;
              }
            },
          }
        );
      }
      if (uiService.isCorporateUser) {
        $scope.listViewTableParams.cols = [
          { field: 'ClientName', title: 'Client Name', show: true },
          { field: 'Probability', title: 'Probability', show: true },
          { field: 'PipelineStatus', title: 'Stage', show: true },
          { field: 'EnquirySource', title: 'Enquiry Source', show: true },
          { field: 'AdvisorName', title: 'Adviser', show: true },
          { field: 'Referrer', title: 'Referrer Name', show: true },
          { field: 'Substatus', title: 'SubStatusName', show: true },
          { field: 'SubStatusUpdated', title: 'SubStatus Updated', show: true },
          { field: 'Label', title: 'Label', show: !$scope.isCorporateUser },
          { field: 'Email', title: 'Email', show: true },
          { field: 'Mobile', title: 'Mobile', show: true },
          { field: 'PostCode', title: 'PostCode', show: true },
          { field: 'CreateDate', title: 'CreateDate', show: true },
          {
            field: 'NoAutoAllocationReason',
            title: 'No Auto Allocation Reason',
            show: true,
          },
        ];

        // This for column filtering
        $scope.Table.FilterColumns = {
          Probability: {
            field: 'Probability',
            title: 'Probability',
            show: true,
          },
          PipelineStatus: {
            field: 'PipelineStatus',
            title: 'Stage',
            show: true,
          },
          EnquirySource: {
            field: 'EnquirySource',
            title: 'Enquiry Source',
            show: true,
          },
          AdvisorName: { field: 'AdvisorName', title: 'Adviser', show: true },
          Referrer: {
            field: 'ReferrerName',
            title: 'Referrer Name',
            show: true,
          },
          Substatus: { field: 'Substatus', title: 'Substatus', show: true },
          SubStatusUpdated: {
            field: 'SubStatusUpdated',
            title: 'Substatus Updated',
            show: true,
          },
          Label: {
            field: 'Label',
            title: 'Label',
            show: !$scope.isCorporateUser,
          },
          Email: { field: 'Email', title: 'Email', show: true },
          Mobile: { field: 'Mobile', title: 'Mobile', show: true },
          PostCode: { field: 'PostCode', title: 'PostCode', show: true },
          CreateDate: {
            field: 'CreateDate',
            title: 'Date Created',
            show: true,
          },
          NoAutoAllocationReason: {
            field: 'NoAutoAllocationReason',
            title: 'No Auto Allocation Reason',
            show: true,
          },
        };
      } else {
        $scope.listViewTableParams.cols = [
          { field: 'ClientName', title: 'Client Name', show: true },
          { field: 'Probability', title: 'Probability', show: true },
          { field: 'PipelineStatus', title: 'Stage', show: true },
          { field: 'EnquirySource', title: 'Enquiry Source', show: true },
          { field: 'AdvisorName', title: 'Adviser', show: true },
          { field: 'Referrer', title: 'Referrer Name', show: true },
          { field: 'Substatus', title: 'SubStatusName', show: true },
          { field: 'SubStatusUpdated', title: 'SubStatus Updated', show: true },
          { field: 'Label', title: 'Label', show: true },
          { field: 'CreateDate', title: 'CreateDate', show: true },
        ];

        // This for column filtering
        $scope.Table.FilterColumns = {
          Probability: {
            field: 'Probability',
            title: 'Probability',
            show: true,
          },
          PipelineStatus: {
            field: 'PipelineStatus',
            title: 'Stage',
            show: true,
          },
          EnquirySource: {
            field: 'EnquirySource',
            title: 'Enquiry Source',
            show: true,
          },
          AdvisorName: { field: 'AdvisorName', title: 'Adviser', show: true },
          Referrer: { field: 'Referrer', title: 'Referrer Name', show: true },
          Substatus: { field: 'Substatus', title: 'Substatus', show: true },
          SubStatusUpdated: {
            field: 'SubStatusUpdated',
            title: 'Substatus Updated',
            show: true,
          },
          Label: { field: 'Label', title: 'Label', show: true },
          CreateDate: {
            field: 'CreateDate',
            title: 'Date Created',
            show: true,
          },
        };
      }

      _.forEach($scope.Table.FilterColumns, pipelineListCol => {
        if (
          _.find(
            $scope.pipelineSharedData.leadFilterData
              .TableViewExcludeColumnsArray,
            pipelineListExclude => {
              return pipelineListExclude === pipelineListCol.field;
            }
          )
        ) {
          pipelineListCol.show = false;
        }
      });
    };

    $scope.changeSubStatusForListView = function(cardsId, subStatusId) {
      const setSubStatus = {
        CardsID: cardsId,
        SubStatusID: subStatusId,
      };
      pipelineService.CardsSubStatusSet(setSubStatus).then(
        () => {
          toaster.pop('success', 'Set', 'Sub Status has been Set successfully');
          if (!$scope.listViewTableParams) return;
          $scope.listViewTableParams.reload();
        },
        () => {
          toastError('Yikes! Something is wrong');
        }
      );
    };

    $scope.performActionForList = (lbl, cardObj, index) => {
      $scope.result = $scope.isIncluded(lbl, cardObj, index);
      if (!$scope.result) {
        $scope.setLabelForCard(lbl, cardObj);
      } else {
        $scope.deleteLabelFromCard(lbl, cardObj);
      }
    };
    $scope.isIncluded = (lbl, list) => {
      const indexOfLabelInList = _.findIndex(list.LabelArray, l => {
        return (
          parseInt(l.labelID, 10) === parseInt(lbl.pipelineSettingsLabelID, 10)
        );
      });
      const isIncluded = indexOfLabelInList !== -1;
      return isIncluded;
    };

    $scope.selectStatus = (statusObj, listObj) => {
      pipelineSharedData.selectStatus(statusObj, listObj, () => {
        $scope.expandDroqdown = false;
      });
    };
    $scope.openLinkToLoanAppModal = (size, familyID, cardID, familyName) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/link_loanApp_modal.html',
        resolve: {
          familyID() {
            return familyID;
          },
          cardID() {
            return cardID;
          },
          familyName() {
            return familyName;
          },
        },
        controller: 'LinkToLoanCtrl',
        size,
      });
    };
    $scope.getReasonList = () => {
      $scope.lastTwoSubstatus = [];
      pipelineService.settingsReasonGet().then(response => {
        if (response.data) {
          $scope.reasonListView = response.data;
          _.forEach($scope.reasonList, object => {
            $scope.lastTwoSubstatus.push(object.PipelineSubStatusID);
          });
        }
      });
    };
    $scope.getReasonList();
    $scope.moveCardOnSidebarNotProceedingModal = listObj => {
      const confirmationPopup = modalRenderService.renderPipelineStatusMoveConfirmationModal(
        {
          reasonList: $scope.reasonListView,
          showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
            listObj.EnquirySource
          ),
        }
      );
      confirmationPopup.result.then(result => {
        if (result.isOk) {
          pipelineService
            .PipelineCardsMoveToNextStatusSet({
              PipelineCardsID: listObj.PipelineCardsID,
              ToStatusID: 9,
              reasonSubstatusID: result.selectedReasonId,
              shouldSendNurtureCampaigns: result.shouldSendNurtureCampaigns,
            })
            .then(() => {
              $scope.moveCardOnSidebarProceedure(listObj, 9);
            });
        }
      });
    };
    $scope.moveCardOnSidebarProceedure = (listObj, conversionStatus) => {
      const $postData = {
        pipelineCardsID: listObj.PipelineCardsID,
        conversionStatus,
        clientFamilyId: listObj.ClientFamilyID,
      };

      pipelineService.PipelineCardsConversionSet($postData).then(
        () => {
          toaster.pop('success', 'Moved', 'Item successfully moved');
          if (parseInt(conversionStatus, 10) === 1) {
            $scope.openLinkToLoanAppModal(
              'modalLinkLoanToPipeline',
              listObj.ClientFamilyID,
              listObj.PipelineCardsID,
              listObj.ClientName
            );
          }

          $scope._initListView();
        },
        () => {}
      );
    };

    $scope.moveCardOnSidebar = (listObj, conversionStatus) => {
      if (conversionStatus === PIPELINE_STATUS.NOT_PROCEEDED) {
        $scope.moveCardOnSidebarNotProceedingModal(listObj);
      } else if (conversionStatus === PIPELINE_STATUS.NEW_OPPORTUNITY) {
        const { ClientFamilyID, PipelineCardsID } = listObj;
        leadsToOpportunityConvert(
          ClientFamilyID,
          contactService,
          opportunityNewModalService,
          {},
          PipelineCardsID
        );
      } else {
        $scope.moveCardOnSidebarProceedure(listObj, conversionStatus);
      }
    };

    $scope.firstTime = true;

    $scope.changeContainerHeight = () => {
      $('.table_container').height('auto');
    };

    $scope.toggleDropdown = $event => {
      if ($scope.firstTime) {
        $scope.firstTime = false;
        $scope.table_containerHeightOriginal = $('.table_container').height();
      }
      $event.preventDefault();
      $event.stopPropagation();
      $scope.expandDroqdown = !$scope.expandDroqdown;
      $('.table_container').height('auto');
    };

    // filter for leads listview end
    $scope.openListDetailModal = (size, list) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/list_detail_modal.html',
        resolve: {
          statusListDdl() {
            return $scope.statusListDdl;
          },
          probabilityList() {
            return $scope.probabilityList;
          },
          labels() {
            return $scope.labels;
          },
          checkLabelExistInList() {
            return $scope.checkLabelExistInList;
          },
          deleteLabelFromCard() {
            return $scope.deleteLabelFromCard;
          },
          list: () => list,
          listeners: () => {
            return {
              onProbabilitySet: () => {
                $scope._initListView();
              },
              onStatusChanged: () => {
                $scope.expandDroqdown = false;
              },
              onMoveCardOnSidebar: (leadsList, convertionStatus) => {
                $scope.moveCardOnSidebar(leadsList, convertionStatus);
              },
              onLabelSet: () => {
                $scope._initListView();
              },
            };
          },
        },
        controller: 'PipelineLeadsListDetailModalCtrl',
        size,
      });
    };

    $scope.countLabelsInList = piplineListLabelArray => {
      if (piplineListLabelArray && piplineListLabelArray.length === 0) {
        return 0;
      }
      let countValidLabels = 0;
      _.forEach(piplineListLabelArray, label => {
        if (
          _.find($scope.labels, item => {
            return (
              String(item.pipelineSettingsLabelID) === String(label.labelID)
            );
          })
        ) {
          countValidLabels++;
        }
      });
      return countValidLabels;
    };
    $scope.checkLabelExistInList = label => {
      const findLabel = _.find($scope.labels, o => {
        return String(o.pipelineSettingsLabelID) === String(label.labelID);
      });

      if (_.size(findLabel) > 0) {
        label.labelColor = findLabel.ColorName;
        return true;
      }
      return false;
    };
    $scope.deleteLabelFromCard = (lblObj, listObj) => {
      $scope.deleteLblFromCard = {};
      $scope.deleteLblFromCard.cardsId = listObj.PipelineCardsID;
      $scope.deleteLblFromCard.labelsID = lblObj.pipelineSettingsLabelID;
      pipelineService.PipelineCardsLabelsDelete($scope.deleteLblFromCard).then(
        () => {
          toaster.pop(
            'success',
            'Deleted',
            'Label has been deleted from your list.'
          );
          $scope.handleItemLabelDeleted(lblObj, listObj);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.handleItemLabelDeleted = (lblObj, listItem) => {
      pipelineSharedData.removeLabelFromItem(lblObj, listItem);
    };

    $scope.filterResult = (status, adviser, label) => {
      $rootScope.filterXsList = false;
      $scope.filterStatus();
      $scope.filterAdviser(adviser);
      $scope.filterLabel(label);
    };

    const getLabelsTableView = $rootScope.$on('getLabels', () => {
      $scope.getLabels();
    });
    $scope.$on('$destroy', getLabelsTableView);
    $scope.getLabels = () => {
      pipelineService.PipelineSettingsLabel().then(
        response => {
          $scope.labels = response.data;
        },
        () => {}
      );
    };
    $scope.setLabelForCard = (lblObj, listObj) => {
      $scope.pipelineCardsLabelSet = {};
      $scope.pipelineCardsLabelSet.cardID = listObj.PipelineCardsID;
      $scope.pipelineCardsLabelSet.labelsID = lblObj.pipelineSettingsLabelID;

      pipelineService.PipelineCardsLabelSet($scope.pipelineCardsLabelSet).then(
        response => {
          toaster.pop('success', 'Added', 'Label has been added successfully.');
          listObj.Labels = lblObj.ColorName;
          $scope.handlePipelineItemUpdate(response.data);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    $scope.makeProbability = () => {
      // /Pipeline/PipelineCardsProbabilitySet
      $scope.probabilityList = [
        {
          probability: 'Hot',
          probabilityId: 1,
          probabilityColor: '#ec7777',
        },
        {
          probability: 'Warm',
          probabilityId: 2,
          probabilityColor: '#fa9c5d',
        },
        {
          probability: 'Cold',
          probabilityId: 3,
          probabilityColor: '#66a3cf',
        },
      ];
    };
    $scope.setProbability = (probObj, listObj) => {
      $scope.probabilityToSet = {};
      $scope.probabilityToSet.cardID = listObj.PipelineCardsID;
      $scope.probabilityToSet.probability = probObj.probability;

      pipelineService.SetPipelineCardsProbability($scope.probabilityToSet).then(
        response => {
          toaster.pop(
            'success',
            'Added',
            'Probability has been added successfully.'
          );
          $scope.handlePipelineItemUpdate(response.data);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $scope.handlePipelineItemUpdate = item => {
      pipelineSharedData.updatePipelineItemInList(
        item,
        $scope.listViewTable,
        $scope.statusList
      );
    };

    const getTableViewWatcher = $rootScope.$on('_initListView', () => {
      $scope._initListView();
    });
    $scope.$on('$destroy', getTableViewWatcher);
    pipelineSharedData.isFirstLoad = true;
    $scope._initListView = () => {
      $scope.sortType = $rootScope.sortType;
      $scope.sortColumn = $rootScope.sortColumn;
      $scope.isLeads = true;

      const isEmptyListView =
        !$scope.listViewTable ||
        ($scope.listViewTable && !$scope.listViewTable.length);
      $scope.pipelineSharedData.isCategoriesLoading = isEmptyListView;

      $scope.getStatus();
      $scope.getAdvisersForFilter();
      $scope.getAllEnquirySource();
      $scope.makeProbability();
      $scope.getProbabilitiesForFilter();
      $scope.getAllReferrals();
      $scope.getLabelsForFilter(() => {
        $scope.getListViewLeadsData(_.isEmpty($scope.listViewTable));
      });

      $scope.isHoverLabel = false;

      // listen for refresh event
      $scope.$on('eventRefereshLeadsListView', () => {
        pipelineSharedData.isFirstLoad = true;
        $scope.reloadTableView();
      });

      $scope.dropdownParentEl = angular.element(
        $window.document.querySelector('#table')
      );
    };

    $scope.reloadTableView = (retainPage, showLoading = true) => {
      $scope.showTableLoading = showLoading;
      pipelineSharedData.reloadTableView(
        $scope.listViewTableParams,
        retainPage
      );
    };

    $scope.$on('openListViewMobileFilter', () => {
      $scope.openFilter();
    });
    $scope.openFilter = () => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/pipeline/leads/partials/pipeline_mobile_filters.html',
        resolve: {
          pipelineSharedData() {
            return $scope.pipelineSharedData;
          },
          DisplayMode() {
            return $scope.DisplayMode;
          },
          adviser() {
            return $scope.taskAdviserList[0];
          },
          taskAdviserList() {
            return $scope.taskAdviserList;
          },
          labelsList() {
            return $scope.labels;
          },
          enquirySourceList() {
            return $scope.enquirySourceList;
          },
          referralListCopy() {
            return $scope.referralListCopy;
          },
        },
        size: 'sm',
        windowTopClass: 'content-no-box-shadow',
        controller: 'PipelineModalLeadsFilterCtrl',
      });

      modalInstance.result.then(response => {
        if (response && response.leadFilterData) {
          angular.extend(
            $scope.pipelineSharedData.leadFilterData,
            response.leadFilterData
          );
          $scope.getListViewLeadsData(true);
        }
      });
    };

    $scope.isMobile = () => {
      return $window.innerWidth <= 992;
    };

    const vm = this;
    vm.$onInit = function() {
      pipelineSharedData.initListView = $scope._initListView;
      $scope.$on('$destroy', () => {
        pipelineSharedData.initListView = null;
      });
    };
  });
