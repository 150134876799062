import angular from 'angular';
import _ from 'lodash';
import { FIXED_RATE_EXPIRES_ID } from 'Common/constants/customerCareModules';
import moment from 'moment';

angular
  .module('app')
  .controller('FixedRateSMSModalCtrl', function FixedRateSMSModalCtrl(
    $scope,
    $uibModalInstance,
    $state,
    $controller,
    toaster,
    optionsService,
    contactService,
    customerCareService,
    fixedRateListTable,
    fixedRateObj,
    customerCareTemplateService,
    source,
    loadFixedRateTable
  ) {
    const vm = this;
    angular.extend(
      vm,
      $controller('CustomerCareSendMessageModalBaseCtrl', { $scope })
    );

    $scope.recipients = [];
    $scope.smsList = [];
    $scope.callingCode = '';
    $scope.tst = '';
    $scope.currentModule = FIXED_RATE_EXPIRES_ID;

    $scope.IsComplete = true;
    const getCountryList = () => {
      optionsService.countryListGet().then(
        response => {
          $scope.countryList = response.data;
          $scope.countryObj = $scope.countryList.filter(item => {
            if (fixedRateObj.Country === item.CountryCode) {
              return item;
            }
            return false;
          });
          $scope.country = $scope.countryObj[0];
          $scope.selectedCountryCode = $scope.country
            ? $scope.country.CountryCode
            : null;
          $scope.makeObject($scope.selectedCountryCode);
        },
        () => {}
      );
    };

    $scope.makeObject = selectedCountryCode => {
      if (source === 'single') {
        if (fixedRateObj.Mobile !== '') {
          if (typeof selectedCountryCode !== 'undefined') {
            $scope.countryObj = $scope.countryList.filter(item => {
              if (selectedCountryCode === item.CountryCode) {
                return item;
              }
              return false;
            });

            if (typeof $scope.countryObj !== 'undefined') {
              $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
            }
          }

          const expDate = moment(fixedRateObj.ExpiryDate).format('D MMM');
          const notiHeader = `Fixed Rate Expires (${expDate}) Completed via SMS`;
          $scope.smsList.sms = [
            {
              FamilyFullname: fixedRateObj.Customer,
              LoanID: fixedRateObj.LoanID,
              FamilyID: fixedRateObj.FamilyID,
              MobileNumber: $scope.callingCode + fixedRateObj.Mobile,
              NotificationHeader: notiHeader,
            },
          ];
          $scope.recipients.email = [
            {
              EmailAddress: fixedRateObj.EmailAddress,
              FamilyFullname: fixedRateObj.Customer,
              MobileNumber: fixedRateObj.Mobile,
            },
          ];
        }
      }
      $scope.fixedRateListTable = fixedRateListTable;
      if (source === 'all') {
        $scope.all = true;
        if (typeof selectedCountryCode !== 'undefined') {
          $scope.countryObj = $scope.countryList.filter(item => {
            if (selectedCountryCode === item.CountryCode) {
              return item;
            }
            return false;
          });

          if (typeof $scope.countryObj !== 'undefined') {
            $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
          }
        }
        if ($scope.fixedRateListTable.length > 0) {
          $scope.selectSMSList = [];
          $scope.emailRecipients = [];

          for (let i = 0; i < $scope.fixedRateListTable.length; i++) {
            $scope.smsAdd = {};
            $scope.emailAdd = {};
            if ($scope.fixedRateListTable[i].Mobile !== '') {
              $scope.smsAdd.FamilyFullname =
                $scope.fixedRateListTable[i].Customer;
              $scope.smsAdd.LoanID = $scope.fixedRateListTable[i].LoanID;

              $scope.smsAdd.FamilyID = $scope.fixedRateListTable[i].FamilyID;
              $scope.smsAdd.MobileNumber =
                $scope.callingCode + $scope.fixedRateListTable[i].Mobile;

              $scope.selectSMSList.push($scope.smsAdd);

              $scope.emailAdd.EmailAddress = fixedRateListTable[i].EmailAddress;
              $scope.emailAdd.FamilyFullname = fixedRateListTable[i].Customer;
              $scope.emailAdd.MobileNumber =
                $scope.callingCode + fixedRateListTable[i].Mobile;
              $scope.emailRecipients.push($scope.emailAdd);
            }
          }
          if ($scope.selectSMSList.length > 0) {
            $scope.smsList.sms = $scope.selectSMSList;
            $scope.noOfPeople = $scope.selectSMSList.length;
          }
          if ($scope.emailRecipients.length > 0) {
            $scope.recipients.email = $scope.emailRecipients;
          }
        }
      }
    };

    $scope.countryChanged = selectedCountryCode => {
      $scope.makeObject(selectedCountryCode);
    };

    $scope.selectedTemplate = [
      {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
      },
    ];

    $scope.refreshResults = select => {
      const search = select.search;

      $scope.getRecipientAutoSuggestion(search);
    };

    $scope.sendSMS = form => {
      let firstError = null;
      if (form.$invalid) {
        _.each(form, field => {
          if (field && field[0] !== '$') {
            if (firstError === null && !field.$valid) {
              firstError = field.$name;
            }

            if (field.$pristine) {
              field.$dirty = true;
            }
          }
        });
        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        return;
      }

      $scope.recipientsSMSArray = [];
      $scope.smsToSend = {};
      $scope.smsToSend.IsComplete = $scope.IsComplete;

      $scope.smsToSend.Message = $scope.sms.Message;
      $scope.smsToSend.CustomerCareType = 'FIXED_RATE_EXPIRY';
      angular.forEach($scope.smsList.sms, item => {
        $scope.recipientsSMSArray.push(item);
      });
      $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;

      customerCareService.CustomerCareSMSSend($scope.smsToSend).then(
        fixedRateRes => {
          if (fixedRateRes.data && fixedRateRes.data.length) {
            const fixedRateSMSValid = customerCareTemplateService.customerCareResponseMsg(
              fixedRateRes.data,
              'SMS'
            );
            if (fixedRateSMSValid) {
              loadFixedRateTable();
              $scope.smsToSend.Message = '';
              form.$setPristine(true);
            }
            $scope.cancel();
          }
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };
    const init = () => {
      getCountryList();

      $scope.phone = fixedRateObj.MobileNo;
      $scope.content =
        '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
      $scope.familyId = fixedRateObj.FamilyID;
    };

    init();
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
