import template from './textAreaHeightAuto.html';
import controller from './textAreaHeightAutoCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    placeholder: '@',
    ngModel: '<',
    isBordered: '<',
    onNgBlur: '&', // returns data variable on your catch
    onNgChange: '&', // returns the model
  },
};
