import angular from 'angular';
import _ from 'lodash';
import { haversineInKM } from 'Common/utilities/math';

angular
  .module('app')
  .controller('SearchNearbyCtrl', function SearchNearbyCtrl(
    $scope,
    $uibModalInstance,
    broadcastDataService,
    corporateService,
    generalService,
    mapService,
    $compile,
    $window,
    $timeout
  ) {
    $scope.contactHomeAddress = 'Australia';
    $scope.defaultAddress = 'Australia';
    $scope.addressModel = {};
    $scope.addressModel.postalSearch = '';
    let zoomValue = 5;
    $scope.noPostalCode = false;
    $scope.allocation.isAllocationShow = false;
    $scope.mapModel = broadcastDataService;

    $scope.cancel = function() {
      $scope.allocation.isAllocationShow = true;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.$watch('mapModel.map.isCancelledTriggered', () => {
      if ($scope.mapModel.map.isCancelledTriggered) {
        $scope.allocation.isAllocationShow = true;
        $uibModalInstance.dismiss('cancel');
        $scope.mapModel.map.isCancelledTriggered = false;
      }
    });

    // Preferences
    $scope.isPreference = false;
    $scope.setPreferences = function() {
      $scope.isPreference = !$scope.isPreference;
    };

    function checkPostal() {
      if (
        typeof $scope.utmModel.PostingCode !== 'undefined' &&
        $scope.utmModel.PostingCode.trim() !== ''
      ) {
        $scope.addressModel.postalSearch = $scope.utmModel.PostingCode;
        $scope.contactHomeAddress = `Postal Code ${
          $scope.addressModel.postalSearch
        }`;
        $scope.defaultAddress = $scope.addressModel.postalSearch;
      } else {
        $scope.addressModel.postalSearch = '';
        $scope.contactHomeAddress = 'Australia';
        $scope.defaultAddress = 'Australia';
      }
    }
    checkPostal();

    function initialLoading() {
      const getSearchNearbyAdviserDetail = 0;
      const searchNearbyWithGeoCode = 1;
      const seachNearbyValidateStatusArr =
        typeof $scope.statusArrayId !== 'undefined'
          ? $scope.statusArrayId.join()
          : '1';
      const seachNearbyValidatePriorityIds =
        typeof $scope.priorityIds !== 'undefined'
          ? $scope.priorityIds.join()
          : '';
      const seachNearbyValidateCampaignListId = (typeof $scope.campaignListId !==
      'undefined'
        ? $scope.campaignListId.join()
        : '',
      '1,5');
      corporateService
        .advisersGet(
          0,
          0,
          1,
          getSearchNearbyAdviserDetail,
          searchNearbyWithGeoCode,
          seachNearbyValidateStatusArr,
          seachNearbyValidatePriorityIds,
          seachNearbyValidateCampaignListId
        )
        .then(adviser => {
          $scope.allocatedAdviserMap = _.uniqBy(adviser.data, 'FamilyId');

          let $adviserList = [];
          const cappedAdviser = $scope.allocatedAdviserMap;
          $adviserList = cappedAdviser;

          $scope.clientDetailsLocation = [];

          $scope.adviserSelected = function(data) {
            $scope.selectedAdviser = data;
          };

          function loadMapDetails(geocoded) {
            $scope.distance = [];
            const latitude = geocoded.latitude || 0;
            const longitude = geocoded.longitude || 0;
            const formattedAddress = geocoded.formatted_address || 0;

            $adviserList &&
              Object.keys($adviserList).forEach(searhNearbyAdviser => {
                if (
                  $adviserList[searhNearbyAdviser].BusinessAddressLatitude &&
                  $adviserList[searhNearbyAdviser].BusinessAddressLongitude
                ) {
                  $scope.distance.push({
                    distance: haversineInKM(
                      latitude,
                      longitude,
                      $adviserList[searhNearbyAdviser].BusinessAddressLatitude,
                      $adviserList[searhNearbyAdviser].BusinessAddressLongitude
                    ),
                    details: $adviserList[searhNearbyAdviser],
                    address:
                      $adviserList[searhNearbyAdviser].BusinessAddressFormatted,
                    lat:
                      $adviserList[searhNearbyAdviser].BusinessAddressLatitude,
                    lng:
                      $adviserList[searhNearbyAdviser].BusinessAddressLongitude,
                  });
                } else if (
                  $adviserList[searhNearbyAdviser].HomeAddressLatitude &&
                  $adviserList[searhNearbyAdviser].HomeAddressLongitude
                ) {
                  $scope.distance.push({
                    distance: haversineInKM(
                      latitude,
                      longitude,
                      $adviserList[searhNearbyAdviser].HomeAddressLatitude,
                      $adviserList[searhNearbyAdviser].HomeAddressLongitude
                    ),
                    details: $adviserList[searhNearbyAdviser],
                    address:
                      $adviserList[searhNearbyAdviser].HomeAddressFormatted,
                    lat: $adviserList[searhNearbyAdviser].HomeAddressLatitude,
                    lng: $adviserList[searhNearbyAdviser].HomeAddressLongitude,
                  });
                } else if (
                  $adviserList[searhNearbyAdviser].PostalAddressLatitude &&
                  $adviserList[searhNearbyAdviser].PostalAddressLatitude
                ) {
                  $scope.distance.push({
                    distance: haversineInKM(
                      latitude,
                      longitude,
                      $adviserList[searhNearbyAdviser].PostalAddressLatitude,
                      $adviserList[searhNearbyAdviser].PostalAddressLongitude
                    ),
                    details: $adviserList[searhNearbyAdviser],
                    address:
                      $adviserList[searhNearbyAdviser].PostalAddressFormatted,
                    lat: $adviserList[searhNearbyAdviser].PostalAddressLatitude,
                    lng:
                      $adviserList[searhNearbyAdviser].PostalAddressLongitude,
                  });
                }
              });

            $scope.locations = [];
            let toolTipContent;
            let compiled;
            if ($scope.distance && $scope.distance.length) {
              const loctmp = [];
              $scope.clientDetailsLocation = [];
              $scope.letterIcon = [];
              const obj = {};
              $scope.locations = [];
              let counter = 1;

              angular.forEach($scope.distance, disValSerchNearby => {
                let fullName = '';
                let email = '';
                let workNumber = '';
                let mobileNumber = '';
                let familyId = 0;
                if (_.size(disValSerchNearby.details) > 0) {
                  fullName =
                    typeof disValSerchNearby.details.FullName !== 'undefined'
                      ? disValSerchNearby.details.FullName
                      : '';
                  email =
                    typeof disValSerchNearby.details.Email !== 'undefined'
                      ? disValSerchNearby.details.Email
                      : '';
                  workNumber =
                    typeof disValSerchNearby.details.WorkNumber !== 'undefined'
                      ? disValSerchNearby.details.WorkNumber
                      : '';
                  mobileNumber =
                    typeof disValSerchNearby.details.MobileNumber !==
                    'undefined'
                      ? disValSerchNearby.details.MobileNumber
                      : '';
                  familyId =
                    typeof disValSerchNearby.details.FamilyId !== 'undefined'
                      ? disValSerchNearby.details.FamilyId
                      : 0;
                }

                const tmpLetterSearhNearby =
                  fullName.length > 0 ? fullName.split(' ') : '';
                if (tmpLetterSearhNearby.length > 1) {
                  const firstLetter = tmpLetterSearhNearby[0].toUpperCase();
                  const secondLetter = tmpLetterSearhNearby[1].toUpperCase();
                  $scope.letterIcon =
                    firstLetter.charAt(0) + secondLetter.charAt(0);
                } else if (tmpLetterSearhNearby.length === 1) {
                  const firstLetter = tmpLetterSearhNearby[0].toUpperCase();
                  $scope.letterIcon = firstLetter.charAt(0);
                } else {
                  $scope.letterIcon = '';
                }

                toolTipContent =
                  `${'<div class="col-md-12 map-allocate-adviser">' +
                    '<div class="margin-bottom-5 letter-icon-wrapper ng-isolate-scope size-sm box-round" data="'}${
                    $scope.letterIcon
                  }" char-count="2" color="#7f6AA1"><span class="letter-icon ng-binding">${
                    $scope.letterIcon
                  }</span></div>` +
                  `<h5 class="violet-color margin-left-10 inline-block"><b>${fullName}</b></h5>` +
                  `<h5><a href="#">${email}</a></h5>` +
                  `<h5><span class="violet-color"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;&nbsp;${workNumber}</span></h5>` +
                  `<h5><span class="violet-color"><i class="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;${mobileNumber}</span></h5>` +
                  `<h5><span class="violet-color">${
                    disValSerchNearby.address
                  }</span></h5>` +
                  `<button id="allocated" class="btn btn-wide" ng-click="allocateAdviser(${familyId},true, '${fullName}')"><b>Allocate</b></button>` +
                  `</div>`;

                compiled = $compile(toolTipContent)($scope);
                if (
                  $scope.noPostalCode ||
                  (!$scope.noPostalCode &&
                    disValSerchNearby.distance <=
                      $scope.searchPreference.radius)
                ) {
                  const newKey = `${disValSerchNearby.lat}${
                    disValSerchNearby.lng
                  }`;
                  if (obj[newKey]) {
                    obj[newKey]++;
                  } else {
                    obj[newKey] = 1;
                  }
                  const val = [
                    disValSerchNearby.address,
                    disValSerchNearby.lat,
                    disValSerchNearby.lng,
                    compiled[0],
                  ];

                  loctmp.push(val);
                  $scope.clientDetailsLocation.push(disValSerchNearby);
                }

                if (counter === $scope.distance.length) {
                  $scope.locations = loctmp;
                  $scope.mapDetails = mapService.mapInitialization(
                    'map-address-adviser-modal',
                    latitude,
                    longitude,
                    zoomValue,
                    formattedAddress,
                    true,
                    $scope.locations,
                    true,
                    toolTipContent
                  );

                  $scope.map = $scope.mapDetails[0];
                  $scope.loadingBroker = false;
                }

                counter++;
              });

              if ($scope.distance && $scope.distance.length === 0) {
                if ($scope.mapModel.map.isLoaded) {
                  $scope.mapDetails = mapService.mapInitialization(
                    'map-address-adviser-modal',
                    latitude,
                    longitude,
                    5,
                    formattedAddress,
                    true,
                    $scope.locations,
                    true,
                    toolTipContent
                  );

                  $scope.map = $scope.mapDetails[0];
                  $scope.loadingBroker = false;
                  $scope.allocation.isAllocationShow = true;
                }
              }
            } else if ($scope.mapModel.map.isLoaded) {
              $scope.mapDetails = mapService.mapInitialization(
                'map-address-adviser-modal',
                latitude,
                longitude,
                5,
                formattedAddress,
                true,
                $scope.locations,
                true,
                toolTipContent
              );

              $scope.map = $scope.mapDetails[0];
              $scope.loadingBroker = false;
              $scope.allocation.isAllocationShow = true;
            }
          }

          function setAddress(addressData) {
            if (addressData) {
              generalService.postCodeSearch(addressData).then(placeResponse => {
                const placeData = placeResponse.data && placeResponse.data[0];
                if (placeData && placeData.formatted_address) {
                  loadMapDetails(placeData);
                }
              });
            } else {
              $scope.clientDetailsLocation = 'No Address';
            }
          }

          if ($scope.mapModel.map.isLoaded) {
            setAddress($scope.defaultAddress);
          } else {
            mapService.loadAPI().then(() => {
              $scope.mapModel.map.isLoaded = true;
              setAddress($scope.defaultAddress);
            });
          }

          $scope.$watch('addressModel.postalSearch', (newValue, oldValue) => {
            if (oldValue) {
              if (oldValue.length > 0 && newValue.length === 0) {
                $scope.noPostalCode = true;
                zoomValue = 5;
                $scope.contactHomeAddress = 'Australia';
                $scope.defaultAddress = 'Australia';
                setAddress($scope.defaultAddress);
              }
            }
          });

          $scope.searchPostCode = function() {
            zoomValue = 8;
            $scope.noPostalCode = false;
            $scope.contactHomeAddress = `Postal Code ${
              $scope.addressModel.postalSearch
            }`;
            $scope.defaultAddress = $scope.addressModel.postalSearch;
            $scope.loadingBroker = true;
            generalService
              .postCodeSearch($scope.defaultAddress)
              .then(placeResponse => {
                const placeData = placeResponse.data && placeResponse.data[0];
                if (placeData && placeData.formatted_address) {
                  loadMapDetails(placeData);
                } else {
                  $scope.initialLoading = false;
                  $scope.clientDetailsLocation = [];
                  $scope.loadingBroker = false;
                }
              });
            $scope.initialLoading = false;
          };
        });
    }

    $scope.refreshResult = function() {
      $scope.initialLoading = false;
      zoomValue = 5;
      $scope.noPostalCode = false;
      $scope.isPreference = false;
      $scope.campaignListId = [];
      const statusArray = [];
      $scope.statusArrayId = [];
      if ($scope.searchPreference.statusActive) {
        statusArray.push('Active');
        $scope.statusArrayId.push(1);
      }
      if ($scope.searchPreference.statusSuspended) {
        statusArray.push('Suspended');
        $scope.statusArrayId.push(4);
      }
      if ($scope.searchPreference.statusInactive) {
        statusArray.push('Inactive');
        $scope.statusArrayId.push(3);
      }
      if ($scope.searchPreference.statusTemporary) {
        statusArray.push('Temporarily Inactive');
        $scope.statusArrayId.push(2);
      }

      $scope.priorityIds = [];

      if ($scope.searchPreference.priority1) {
        $scope.priorityIds.push(1);
      }
      if ($scope.searchPreference.priority2) {
        $scope.priorityIds.push(2);
      }
      if ($scope.searchPreference.priority3) {
        $scope.priorityIds.push(3);
      }
      $scope.lead.campaignList.forEach(data => {
        if (data.IsChecked) {
          $scope.campaignListId.push(data.CorpLeadCampaignID);
        }
      });

      initialLoading();
    };

    $scope.lead = {};
    $scope.lead.campaignList = [];
    function getLeadCampaign() {
      $scope.lead.campaignList = [];
      corporateService.corpLeadCampaignGet().then(response => {
        if (response.data.length > 0) {
          _.map(response.data, data => {
            data.IsChecked = false;
          });
          $scope.lead.campaignList = response.data;
        }
      });
    }
    getLeadCampaign();

    $scope.searchPreference = {};
    $scope.searchPreference.priority1 = true;
    $scope.searchPreference.priority2 = true;
    $scope.searchPreference.priority3 = true;

    $scope.searchPreference.typeResidential = true;
    $scope.searchPreference.typeCommercial = true;
    $scope.searchPreference.typeSMSF = true;
    $scope.searchPreference.typePersonal = true;
    $scope.searchPreference.typeInvestment = true;
    $scope.searchPreference.typeHomeBuyer = true;
    $scope.searchPreference.typeBusiness = true;
    $scope.searchPreference.typeReverseMortgage = true;
    $scope.searchPreference.typeCarMortgage = true;
    $scope.searchPreference.typeLowDoc = true;
    $scope.searchPreference.typeRefinance = true;

    $scope.searchPreference.statusActive = true;
    $scope.searchPreference.radius = 100;

    function callTimer() {
      $timeout(() => {
        if (
          typeof $window.google === 'object' &&
          typeof $window.google.maps === 'object'
        ) {
          $scope.mapModel.map.isLoaded = true;
          initialLoading();
        } else {
          mapService.loadAPI().then(() => {
            $scope.mapModel.map.isLoaded = true;
            initialLoading();
          });
          callTimer();
        }
      }, 3000);
    }

    if ($scope.mapModel.map.isLoaded) {
      initialLoading();
    } else {
      callTimer();
    }
  });
