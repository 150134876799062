import template from './guarantors.html';
import controller from './guarantorsCtrl';

export default {
  template,
  bindings: {
    onShowAddExistingPanel: '&',
    searchClientName: '=',
    onSearchClient: '&',
    onSelectClient: '&',
    onRemoveFamily: '&',
    onCompanyModal: '&',
    showClientList: '<',
    showThisPanel: '<',
    onPersonModal: '&',
    displayFamily: '<',
    onEditFamily: '&',
    clientList: '<',
    selectedAdviser: '<',
    showSelectAdviser: '<',
    formData: '<',
  },
  controller,
  controllerAs: 'vm',
};
