import angular from 'angular';
import pipeline from './pipeline';
import pipelineKanban from './pipelineKanban';
import pipelineKanbanColumn from './pipelineKanban/pipelineKanbanColumn';
import leadCard from './pipelineKanban/pipelineKanbanColumn/leadCard';
import applicationCard from './pipelineKanban/pipelineKanbanColumn/applicationCard';
import opportunityCard from './pipelineKanban/pipelineKanbanColumn/opportunityCard';
import contactNameCell from './pipelineTable/contactNameCell';
import statusDropdown from './pipelineTable/statusDropdown';
import statusDropdownLeads from './pipelineTable/statusDropdownLeads';
import statusDropdownApplications from './pipelineTable/statusDropdownApplications';
import pipelineTable from './pipelineTable';
import pipelineDropboxOpportunity from './pipelineKanban/pipelineDropboxOpportunity';
import pipelineDropboxLead from './pipelineKanban/pipelineDropboxLead';
import pipelineDropboxApplication from './pipelineKanban/pipelineDropboxApplication';
import pipelineFilters from './pipelineFilters';
import pipelineLeadFilter from './pipelineFilters/pipelineLeadFilter';
import pipelineApplicationFilter from './pipelineFilters/pipelineApplicationFilter';
import pipelineFiltersMobileModal from './pipelineFilters/pipelineFiltersMobileModal';
import pipelineApplicationThreeDotMenu from './pipelineApplicationThreeDotMenu';
import pipelineMoveCardModal from './pipelineKanban/pipelineMoveCardModal';
import pipelineMobileDemoModal from './pipelineKanban/pipelineMobileDemoModal';
import pipelineReasonNotProceedingModal from './pipelineReasonNotProceedingModal';
import applicationMenu from './pipelineKanban/pipelineKanbanColumn/threeDotMenus/applicationMenu';
import leadMenu from './pipelineKanban/pipelineKanbanColumn/threeDotMenus/leadMenu';
import opportunityMenu from './pipelineKanban/pipelineKanbanColumn/threeDotMenus/opportunityMenu';
import threeDotModal from './pipelineKanban/pipelineKanbanColumn/threeDotMenus';
import probabilityDropdown from './pipelineTable/probabilityDropdown';

export default angular
  .module('components.pipeline', [])
  .component('pipeline', pipeline)
  .component('pipelineKanban', pipelineKanban)
  .component('pipelineKanbanColumn', pipelineKanbanColumn)
  .component('leadCard', leadCard)
  .component('applicationCard', applicationCard)
  .component('opportunityCard', opportunityCard)
  .component('contactNameCell', contactNameCell)
  .component('statusDropdown', statusDropdown)
  .component('statusDropdownLeads', statusDropdownLeads)
  .component('statusDropdownApplications', statusDropdownApplications)
  .component('pipelineDropboxOpportunity', pipelineDropboxOpportunity)
  .component('pipelineDropboxLead', pipelineDropboxLead)
  .component('pipelineDropboxApplication', pipelineDropboxApplication)
  .component('pipelineTable', pipelineTable)
  .component('pipelineFilters', pipelineFilters)
  .component('pipelineLeadFilter', pipelineLeadFilter)
  .component('pipelineApplicationFilter', pipelineApplicationFilter)
  .component('pipelineFiltersMobileModal', pipelineFiltersMobileModal)
  .component('pipelineApplicationThreeDotMenu', pipelineApplicationThreeDotMenu)
  .component('pipelineMoveCardModal', pipelineMoveCardModal)
  .component('pipelineMobileDemoModal', pipelineMobileDemoModal)
  .component(
    'pipelineReasonNotProceedingModal',
    pipelineReasonNotProceedingModal
  )
  .component('applicationMenu', applicationMenu)
  .component('leadMenu', leadMenu)
  .component('opportunityMenu', opportunityMenu)
  .component('threeDotModal', threeDotModal)
  .component('probabilityDropdown', probabilityDropdown).name;
