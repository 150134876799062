import template from './addKeyContactForm.html';
import controller from './keyContacFormtCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    formData: '<',
    toggleForm: '=',
    providerData: '<',
    keycontactData: '<',
    contactRegions: '<',
    defaultState: '<',
    auOnly: '=',
    onSave: '&',
    onFormHidden: '&',
  },
};
