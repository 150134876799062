import { SHARING_VALUE } from 'Common/constants/documentSharing';

class DocumentSharingCtrl {
  constructor(generalService, contactService, toaster) {
    'ngInject';

    this.generalService = generalService;
    this.contactService = contactService;
    this.toaster = toaster;
  }
  getSharedWithCorporateTeamList() {
    if (!this.documentObject.SharedWithCorporateTeamId) return [];
    const list = this.documentObject.SharedWithCorporateTeamId.split(',');
    const parsedList =
      list &&
      list.reduce((accum, value) => {
        accum.push(parseInt(value, 10));
        return accum;
      }, []);
    return parsedList;
  }
  getSharedTeamValue() {
    const documentShared = this.getSharedWithCorporateTeamList();
    this.sharedWith =
      this.corporateTeams &&
      this.corporateTeams.filter(team => {
        team.owner = documentShared.indexOf(team.corporateTeamId) === 0;
        return documentShared.indexOf(team.corporateTeamId) !== -1;
      });
    this.ownerTeam = this.sharedWith && this.sharedWith.find(x => x.owner);
  }
  getCorporateTeam() {
    this.generalService.getCorporateTeams().then(teams => {
      if (!teams || !teams.length) return;
      this.corporateTeams = [...teams];
      const filteredList = teams.filter(
        x => x.corporateTeamId !== this.userInfo.CorporateTeamId
      );
      this.filteredCorporateTeams =
        filteredList &&
        filteredList.map(team => {
          team.isChecked = false;
          return team;
        });
      this.getSharedTeamValue();
    });
  }

  populateTeamList() {
    if (!this.documentObject || !this.documentObject.SharedWithCorporateTeamId)
      return;
    const sharedAccessList = this.getSharedWithCorporateTeamList();
    if (!sharedAccessList || !sharedAccessList.length) return;
    this.filteredCorporateTeams =
      this.filteredCorporateTeams &&
      this.filteredCorporateTeams.map(team => {
        team.isChecked = sharedAccessList.indexOf(team.corporateTeamId) !== -1;
        return team;
      });
  }

  displaySharingToggle() {
    if (!this.userInfo.CorporateTeamId) return false;
    const sharedByTeamList = this.getSharedWithCorporateTeamList();
    if (!sharedByTeamList || !sharedByTeamList.length) return false;
    if (sharedByTeamList.indexOf(SHARING_VALUE.ALL_CORPORATE) !== -1)
      return true;
    if (sharedByTeamList.indexOf(this.userInfo.CorporateTeamId) === 0)
      return true;
    return false;
  }

  updateDocumentInfo(params) {
    const { ids, message, closeMenu } = params;
    const documentObj = this.documentObject;
    documentObj.SharedWithCorporateTeamId = ids;
    const obj = {
      Title: documentObj.Name,
      Tags: documentObj.Tags ? documentObj.Tags.join() : '',
      Description: documentObj.Description || '',
      DoucmentID: documentObj.DocumentId,
      FamilyID: this.familyId || 0,
      OrganisationId: this.adviserOrgId || 0,
      SharedWithCorporateTeamId: documentObj.SharedWithCorporateTeamId,
      UploadedByCorporateTeamId: null,
    };
    if (closeMenu) {
      this.dropdownOpen = false;
    }
    if (this.noSavingAction) {
      this.getSharedTeamValue();
      this.output = ids;
      return;
    }
    this.contactService.documentPropertySet([obj]).then(
      () => {
        this.getSharedTeamValue();
        this.toaster.pop(
          'success',
          'Success',
          message || `Successfully shared with other team`
        );
      },
      () => {
        this.toaster.pop('error', 'Failed to Share', 'Unable to set sharing.');
        documentObj.SharedWithCorporateTeamId = documentObj.SharedWithCorporateTeamId
          ? `0`
          : `${this.userInfo.CorporateTeamId}`;
      }
    );
  }
  setDocumentInfo() {
    const sharedTeam = this.getSharedWithCorporateTeamList();
    const sharedTeamId =
      sharedTeam && sharedTeam.indexOf(SHARING_VALUE.ALL_CORPORATE) !== -1
        ? `${this.userInfo.CorporateTeamId || 0}`
        : `0`;
    const filterTeam =
      this.corporateTeams &&
      this.corporateTeams.find(
        x => x.corporateTeamId === parseInt(sharedTeamId, 10)
      );
    const sharedForTeam =
      filterTeam && filterTeam.corporateTeam
        ? `${filterTeam.corporateTeam} Team`
        : `All Corporate Team`;
    const message = `Successfully set sharing for ${sharedForTeam} Team`;
    this.updateDocumentInfo({ ids: sharedTeamId, message });
  }
  saveTeamListAccess() {
    const teamList =
      this.filteredCorporateTeams &&
      this.filteredCorporateTeams.filter(x => x.isChecked);
    if (!teamList || !teamList.length) return;
    const sharedIds = teamList.reduce((accum, value) => {
      accum.push(value.corporateTeamId);
      return accum;
    }, []);
    if (!sharedIds || !sharedIds.length) return;
    const ownerTeam =
      this.documentObject.SharedWithCorporateTeamId &&
      this.documentObject.SharedWithCorporateTeamId.charAt(0);
    const ids = `${ownerTeam},${sharedIds.toString()}`;
    const closeMenu = true;
    this.updateDocumentInfo({ ids, closeMenu });
  }
  removeTeamAccess(id) {
    if (!this.sharedWith) return;
    const teamWithAccess = this.sharedWith.filter(
      x => x.corporateTeamId !== id
    );
    const teamIds =
      teamWithAccess &&
      teamWithAccess.reduce((accum, value) => {
        accum.push(value.corporateTeamId);
        return accum;
      }, []);
    if (!teamIds || !teamIds.length) return;
    const removedTeam = this.sharedWith.find(x => x.corporateTeamId === id);
    const message = `Successfully remove sharing with ${removedTeam.corporateTeam ||
      ``} Team `;
    this.updateDocumentInfo({ ids: teamIds.join(), message });
  }
  $onInit() {
    this.getCorporateTeam();
  }
}

export default DocumentSharingCtrl;
