import template from './pipelineLabelForm.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    labelName: '<',
    labelColor: '<',
    onChangeName: '&',
    onSelectColor: '&',
  },
};
