import angular from 'angular';
import $ from 'jquery';
import { getNumberView, removeCountryCode } from 'Common/utilities/phone';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { REFERRER_TAB } from 'Common/constants/corporateRestricted';

angular
  .module('app')
  .controller('ReferrerDetailCtrl', function ReferrerDetailCtrl(
    $scope,
    $rootScope,
    $http,
    $filter,
    $timeout,
    corporateService,
    contactService,
    regionalizationService,
    $sce,
    $state,
    $stateParams,
    SweetAlert,
    mapService,
    $uibModal,
    toaster,
    $q,
    generalService,
    broadcastDataService,
    referralDetailService,
    $window,
    crmConfirmation,
    configService
  ) {
    // Filter
    $filter('lowercase')();
    // state params
    $scope.familyId = $stateParams.familyId;
    $scope.clientId = $stateParams.clientId;
    // Variables
    $scope.hiddenTabs = false;
    $scope.editMode = {};
    $scope.membershipReferrers = [];
    $scope.membershipRoles = [];
    $scope.adviserUnderOrganization = [];
    $scope.referrerForm = {};

    // array
    $scope.tabs_referrers = [
      {
        name: 'Details',
        page: 'assets/views/corporate/referrer/details.html',
        value: 'details',
      },
      {
        name: 'Members',
        page: 'assets/views/corporate/referrer/members.html',
        value: 'members',
      },
      {
        name: 'Relationships',
        page: 'assets/views/corporate/referrer/relationships.html',
        value: 'relationships',
      },
    ];

    $scope.nameTitles = ['Mr.', 'Ms.', 'Mrs.'];

    $scope.details = [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Aland Islands', code: 'AX' },
      { name: 'Albania', code: 'AL' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'AndorrA', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
    ];

    $scope.setterType = [
      'contactSet',
      'setReferrerOrgMembership',
      'setReferrerOrgRelationship',
    ];

    $scope.saveType = {
      all: 0,
      adviserDetails: 1,
      additionalDetails: 2,
      franchiseDetails: 3,
    };

    $scope.brokerStatusLists = ['Inactive', 'Active'];

    const btnClass = ['btn-danger', 'btn-light-blue'];
    const fontClass = ['fa-minus text-white', 'fa-check text-white'];

    //* *****
    // Public
    // Function : this will set all the required class.
    // @ref Arrays btnClass and fontClass
    // @params
    //     status (string), statusId (interger), isbtn (boolean)
    //* ****
    $scope.setClasses = function(status, stausId, isbtn) {
      return isbtn ? btnClass[stausId] : fontClass[stausId];
    };

    const getCountryCallingCode = userCountryId => {
      $scope.userCallingCode = '00';
      contactService.getOperatingCountry().then(response => {
        const { data } = response;
        if (data) {
          const countryList = data.splice(0, 3);
          countryList.forEach(item => {
            if (parseInt(item.CountryId, 10) === parseInt(userCountryId, 10)) {
              $scope.userCallingCode = item.CallingCode;
            }
          });
        }
      });
    };
    $scope.setEditMode = (field, mode) => {
      $scope.editMode[field] = !mode;
    };

    contactService.getUserInfo().then(response => {
      if (response.data) {
        $scope.isProfileAdmin = response.data.CorpAccessTypeID === 1;
        $scope.mobileMask = broadcastDataService.getMobileFormat(
          response.data.CountryId
        );
        $scope.phoneMask = broadcastDataService.getPhoneFormat(
          response.data.CountryId
        );
        getCountryCallingCode(response.data.CountryId);
        $scope.isEnabledFeature =
          response.data.AccessType === ACCESS_TYPE.CORPORATE;
        $scope.userInfo = response.data;
      }
    });

    if ($scope.familyId && $scope.clientId) {
      $scope.selectedContacts = $scope.tabs_referrers[0].name;

      $scope.$watch('selectedContacts', newValue => {
        if ($scope.tabs_referrers[newValue]) {
          $scope.getReferrerDetails($scope.tabs_referrers[newValue].name);
        }
      });
    }

    const _initData = function(getter, id, id2, ...args) {
      const arg = args[0] || null;
      const callback = args[1] ? args[0] : arg;
      const argus = args[1] || [];

      const queryCallback = function(value) {
        $scope[getter] = [];
        $scope[getter] = value.data;

        if (callback) {
          callback(...argus);
        }
      };

      if (id && id2) {
        corporateService[getter](id, id2).then(queryCallback);
      } else if (id) {
        corporateService[getter](id).then(queryCallback);
      } else {
        corporateService[getter]().then(queryCallback);
      }
    };

    const _queryGet = function(getter, ids, callbackParam, ...args) {
      let callback = callbackParam;
      callback = args[0] || callbackParam;
      const argus = args[1] || [];

      const queryCallback = function(value) {
        $scope[getter] = [];
        $scope[getter] = value.data;

        if (callback) {
          callback(...argus);
        }
      };

      corporateService[getter].apply(null, ids).then(queryCallback);
    };

    const _getArrValue = function(id, name, idKey, nameKey, arrays) {
      let arrValue;
      angular.forEach(arrays, array => {
        if (id) {
          if (id === array[idKey]) {
            arrValue = array[nameKey];
          }
        }

        if (name) {
          if (name === array[nameKey]) {
            arrValue = array[idKey];
          }
        }
      });

      return arrValue;
    };

    function getRecentView() {
      broadcastDataService.recentView.get().then(res => {
        broadcastDataService.recentView.list = res;
      });
    }

    $scope.getNumberView = getNumberView;
    $scope.getReferrerDetails = function(selectedContacts) {
      if (selectedContacts === 'Details') {
        $scope.membershipReferrers = [];
        _initData('brokerStatusListGet', null, null, () => {
          _initData(
            'corporateContactGet',
            $scope.clientId,
            $scope.familyId,
            () => {
              // $scope.corporateContactGet.BrokerStatusName = _getArrValue($scope.corporateContactGet.BrokerStatusId, null, 'BrokerStatusId', 'BrokerStatusName', $scope.brokerStatusListGet);
              getRecentView();
              corporateService
                .corporateContactGet($scope.clientId, $scope.familyId)
                .then(corporateAdviser => {
                  if (!corporateAdviser.data) return;
                  $scope.corporateContactGet = corporateAdviser.data;

                  $scope.corporateContactGet.FullName = `${
                    $scope.corporateContactGet.FirstName
                  } ${$scope.corporateContactGet.LastName}`;
                  $scope.corporateContactGet.FullNameMember = `${
                    $scope.corporateContactGet.Title
                  } ${$scope.corporateContactGet.FirstName} ${
                    $scope.corporateContactGet.LastName
                  }`;
                  if (
                    typeof $scope.corporateContactGet.WorkNumber === 'string'
                  ) {
                    const phoneDigit =
                      $scope.corporateContactGet.ReportingCountryId === 1
                        ? -6
                        : -9;
                    $scope.corporateContactGet.WorkNumberAlt = $scope.corporateContactGet.WorkNumber.replace(
                      / /g,
                      ''
                    ).slice(phoneDigit);
                  }
                  if (
                    $scope.corporateContactGet.BrokerStatusId ||
                    $scope.corporateContactGet.BrokerStatusId === 0
                  ) {
                    $scope.corporateContactGet.BrokerStatusName =
                      $scope.brokerStatusLists[
                        $scope.corporateContactGet.BrokerStatusId
                      ];
                  }
                  $scope.corporateContactGet.WorkNumber = removeCountryCode(
                    $scope.corporateContactGet.WorkNumber
                  );
                });
            }
          );
        });
      } else if (selectedContacts === 'Members') {
        $scope.referrerOrganizationMembershipsByReferrerGet = [];
        _initData('getReferrerOrganization', null, null, () => {
          regionalizationService.GetMembershipRoles().then(response => {
            $scope.membershipRoles = response.data;
            _initData(
              'referrerOrganizationMembershipsByReferrerGet',
              $scope.familyId,
              $scope.clientId
            );
          });
        });
      } else if (selectedContacts === 'Relationships') {
        $scope.referrerOrganizationRelationshipsGet = [];
        _initData('adviserOrganizationListGet', null, null, () => {
          _initData(
            'referrerOrganizationRelationshipsGet',
            $scope.familyId,
            $scope.clientId,
            () => {
              angular.forEach(
                $scope.referrerOrganizationRelationshipsGet,
                value => {
                  if (value.OrganizationId) {
                    _initData(
                      'advisersUnderOrganizationGet',
                      value.OrganizationId,
                      null
                    );
                  }
                }
              );
            }
          );
        });
      }
    };

    $scope.inputChanged = function(data, parent) {
      // $('#ex-adviser-' + parent.RelationshipId + '_value').val("");
      // parent.Adviser = {};
      parent.ReferrerOrganization = data;
      parent.OrganizationId = data.originalObject.AdviserOrganizationId;
      parent.OrganizationName = data.originalObject.AdviserOrganizatioName;
      _initData('advisersUnderOrganizationGet', parent.OrganizationId, null);
      // parent.AdviserName = '';
    };

    const _setEditMode = function(key) {
      $scope.editMode[key] = false;
      return $scope.editMode[key];
    };

    //* *****
    // Private
    // Function : Update all adviser details
    // @params
    // @return
    //* ****
    $scope.setStatus = function(statusValue, name) {
      if ($scope.corporateContactGet) {
        $scope.corporateContactGet.BrokerStatusName = name;
        $scope.corporateContactGet.BrokerStatusId = statusValue;
        $scope.updateReferrerDetails(
          'contactSet',
          $scope.corporateContactGet,
          'BrokerStatusId',
          'all',
          'BrokerStatusId'
        );
      }
    };

    const _setUpadateReferrer = function(setType, data, key, saveType) {
      $scope.editMode[key] = false;
      _setEditMode(key);
      corporateService.contactSet(saveType, data).then(response => {
        if (response) {
          toaster.pop('success', 'Update', 'successfully updated!');
        }
      });
      toaster.clear();
    };

    $scope.updateReferrerDetails = function(
      setterType,
      objParam,
      key,
      saveType
    ) {
      const isCorporate = $scope.userInfo.AccessType === ACCESS_TYPE.CORPORATE;
      if (!isCorporate && objParam.NumberOfAgreementsInvolved !== 1) {
        crmConfirmation.open({
          type: 'warning',
          title: 'Updates Restricted',
          description:
            'Updates for this referrer is restricted. Please contact Agreements Team for assistance.',
          buttonText: 'Okay Got It!',
        });
        return;
      }
      let obj = objParam;
      if (key === 'WorkNumberAlt') {
        obj.WorkNumber =
          obj.WorkNumberAlt !== '' ? `0${obj.WorkNumberAlt}` : '';
      }
      if (setterType === 'setReferrerOrgMembership') {
        obj.FamilyId = $scope.familyId;
        obj.ClientId = $scope.clientId;
        obj.BrokerStatusId = _getArrValue(
          null,
          obj.BrokerStatusName,
          'BrokerStatusId',
          'BrokerStatusName',
          $scope.brokerStatusLists
        );

        if (obj.ReferrerOrganization) {
          obj.ReferrerOrganizationName =
            obj.ReferrerOrganization.originalObject.ReferrerOrganizationName;
          obj.ReferrerOrganizationId =
            obj.ReferrerOrganization.originalObject.ReferrerOrganizationId;
        }
        obj.RoleId = _getArrValue(
          null,
          obj.RoleDescription,
          'RoleId',
          'RoleDesc',
          $scope.membershipRoles
        );
        obj.Status = obj.Status;
        corporateService.setReferrerOrgMembership(obj).then(() => {
          $scope.editMode[key] = false;
          toaster.pop('success', 'Update', 'Membership Successfully Update!');
        });
      } else if (setterType === 'setReferrerOrgRelationship') {
        obj.FamilyId = $scope.familyId;
        obj.ClientId = $scope.clientId;
        if (obj.Adviser && obj.ReferrerOrganization) {
          obj.AdviserName = obj.Adviser.originalObject.FullName;
          obj = {
            AdviserFamilyId: obj.Adviser.originalObject.FamilyId,
            Status: obj.Status,
            OrganizationId:
              obj.ReferrerOrganization.originalObject.AdviserOrganizationId,
            Relationship: obj.RelationshipId,
          };
        }
        corporateService.setReferrerOrgRelationship(obj).then(() => {
          $scope.editMode[key] = false;
          toaster.pop('success', 'Update', 'Relationship Successfully Update!');
        });
      } else {
        _setUpadateReferrer(setterType, obj, key, $scope.saveType[saveType]);
      }
    };

    $scope.selectContacts = function(selectedContacts) {
      $timeout(() => {
        const el = $window.document.querySelectorAll(
          `#${selectedContacts} .nav-link`
        );
        angular.element(el).triggerHandler('click');
      }, 0);
      $scope.selectedContacts = selectedContacts;
    };

    $scope.openHiddenTabs = function() {
      $scope.hiddenTabs = $scope.hiddenTabs === false;
    };
    $scope.closeHiddenTabs = function() {
      $scope.hiddenTabs = false;
    };

    $scope.calcWidth = function() {
      let navwidth = 0;
      $scope.hiddenTabCount = $('.more li').length;

      const morewidth = $('ul.nav-tabs .more').outerWidth(true);

      $('.nav-tabs > li.contact-parent-tab').each(function() {
        const vm = this;
        navwidth += $(vm).outerWidth(true);
      });

      const availablespaceRef = $('ul.nav-tabs').outerWidth(true) - morewidth;

      if (navwidth > availablespaceRef) {
        const lastItemRef = $('.nav-tabs > li.contact-parent-tab').last();
        lastItemRef.attr('data-width', lastItemRef.outerWidth(true));
        lastItemRef.prependTo($('.nav-tabs .more ul'));
        $scope.calcWidth();
      } else {
        const firstMoreElement = $('.nav-tabs li.more li').first();

        if (navwidth + firstMoreElement.data('width') < availablespaceRef) {
          firstMoreElement.insertBefore($('.nav-tabs .more'));
        }
      }

      $scope.toggleOverflowTabs();
    };

    $scope.toggleOverflowTabs = function() {
      $scope.narrowNav = referralDetailService.toggleOverflowTabs(
        $scope.hiddenTabCount
      );
    };

    $scope.hiddenTabCount = $('.more li').length;
    $scope.toggleOverflowTabs();

    $timeout(() => {
      $scope.calcWidth();
    });

    $(window).on('resize orientationchange load', () => {
      $scope.calcWidth();
    });

    $scope.addNewOrgMember = function() {
      // $rootScope.referrerOrganizationId = $stateParams.referrerOrgId;
      // $rootScope.modeForModal = 'add';
      $uibModal.open({
        templateUrl: 'assets/views/corporate/referrer-org/referrer_member.html',
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        controller: 'ReferrerAsMemberNewCtrl',
        resolve: {
          referrerOrganizationId() {
            return 0;
          },
          modeForModal() {
            return 'add';
          },
          callback() {
            return $scope.getReferrerDetails('Members');
          },
        },
      });
    };

    $scope.addNewOrgMemberExisting = function() {
      $uibModal
        .open({
          templateUrl:
            'assets/views/corporate/referrer-org/referrer_member.html',
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          controller: 'ReferrerAsMemberNewCtrl',
          resolve: {
            referrerOrganizationId() {
              return $stateParams.referrerOrgId;
            },
            modeForModal() {
              return 'existing';
            },
            callback() {
              return $scope.loadMembers();
            },
          },
        })
        .closed.then(() => {
          $scope.getReferrerDetails('Members');
          $scope.getReferrerDetails('Relationships');
        });
    };

    $scope.loadMembers = function() {
      _queryGet(
        'referrerOrganizationMembershipsByOrganizationGet',
        [$scope.referrerOrgId],
        () => {}
      );
    };

    $scope.isDateValid = function(date) {
      const d = new Date(date);
      return parseInt(d.getFullYear(), 10) > 1;
    };

    $scope.convertToDate = function(date) {
      return new Date(date);
    };

    function checkEmailValidity(email, familyId) {
      const query = $q.defer();
      generalService.getCheckIfEmailExists(email, familyId).then(response => {
        query.resolve(response.data > 0);
      });
      return query.promise;
    }

    function updateEmailReferrer(setterType, obj, key, saveType, field) {
      if (key === 'Email') {
        checkEmailValidity(obj.Email, $scope.familyId).then(prom => {
          if (prom) {
            $scope.getReferrerDetails('Details');
            SweetAlert.swal(
              'Email Validation',
              'Email is already used by other user.',
              'error'
            );
          } else {
            $scope.updateReferrerDetails(setterType, obj, key, saveType, field);
          }
        });
      } else {
        $scope.updateReferrerDetails(setterType, obj, key, saveType, field);
      }
    }

    $scope.updateEmailReferrer = updateEmailReferrer;

    $scope.stringToProperCase = function(stringValue) {
      return (
        stringValue &&
        stringValue.charAt(0).toUpperCase() +
          stringValue.substring(1, stringValue.length).toLowerCase()
      );
    };
    $scope.setReferrerStatus = function(status) {
      if ($scope.corporateContactGet) {
        $scope.corporateContactGet.IsActive = status;
        $scope.updateReferrerDetails(
          'contactSet',
          $scope.corporateContactGet,
          'IsActive',
          'all',
          'IsActive'
        );
      }
    };
    $scope.displayConnectPanel =
      (configService.feature && configService.feature.referrerConnect) || 0;

    $scope.displayReferrerTab = tab => {
      if (!tab || !tab.value) return false;
      return $scope.isEnabledFeature || REFERRER_TAB.indexOf(tab.value) === -1;
    };
  });
