import template from './referrerConnect.html';
import ReferrerConnectCtrl from './referrerConnectCtrl';

export default {
  template,
  bindings: {
    familyId: '=',
  },
  controller: ReferrerConnectCtrl,
  controllerAs: 'vm',
};
