import angular from 'angular';

angular
  .module('app')
  .directive('importantDocumentThumb', function importantDocumentThumb() {
    return {
      link(scope, elem, attr) {
        elem.attr(
          'src',
          `data:application/png;base64,${attr.thumbnailContent}`
        );
        elem.attr('style', `width:${attr.thumbWidth}px;`);
      },
    };
  });
