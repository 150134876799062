import angular from 'angular';

angular
  .module('app')
  .directive('selectNormalContactsDir', function selectNormalContactsDir(
    $window
  ) {
    return {
      restrict: 'A',
      scope: {
        selectedContacts: '@',
      },
      link(scope, element) {
        if (element.context.localName === 'select') {
          element.on('change', () => {
            const el = $window.document.querySelectorAll(
              `#${scope.selectedContacts} .nav-link`
            );
            angular.element(el).triggerHandler('click');
          });
        } else {
          element.on('click', () => {
            const el = $window.document.querySelectorAll(
              `#${scope.selectedContacts} .nav-link`
            );
            angular.element(el).triggerHandler('click');
          });
        }
      },
    };
  });
