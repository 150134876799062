import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

angular
  .module('app')
  .controller('LoanappAssetModalCtrl', function LoanappAssetModalCtrl(
    $scope,
    $uibModalInstance,
    modalType,
    isRealEstate,
    $timeout,
    SweetAlert,
    loanScenarioService,
    optionsService,
    $rootScope,
    toaster,
    generalService,
    loanAppSharedData
  ) {
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.formSubmittedValidation = false;
    // Create a loan term list
    $scope.loanTermList = [];
    $scope.rentalVerificationTypeList = [];

    optionsService.getLoanTerms().then(loanTermResponse => {
      $scope.loanTermList = loanTermResponse;
    });

    // Set type for real estate since there are no dropdown
    if (isRealEstate) {
      $scope.getAddressList();
      $scope.AssetsSet.Type = _.find($scope.assetTypes, o => {
        return o.Desc === 'Real Estate';
      });
    }

    $scope.frequencyTypes = [];
    optionsService.FinancialFrequency().then(response => {
      _.map(response.data, o => {
        o.Desc = o.Name;
        o.Value = parseInt(o.Value, 10);

        return o;
      });

      $scope.frequencyTypes = response.data;
    });

    if ($rootScope.crmCountry === 'New Zealand') {
      optionsService.RentalVerificationTypeGet().then(response => {
        $scope.rentalVerificationTypeList = response.data;
      });
    }

    // Models
    angular.extend($scope, {
      Title: modalType === 'new' ? 'Add' : 'Edit',
      SaveTitle: modalType === 'new' ? 'Add' : 'Edit',
      Years: [],
    });
    for (let i = moment().year(); i >= 1930; i--) {
      $scope.Years.push(i);
    }

    // Methods
    angular.extend($scope, {
      cancel() {
        $uibModalInstance.dismiss('cancel');
      },

      fullAutoAddress() {
        // setter
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          if (_.isUndefined($scope.AssetsSet.FullAddress))
            $scope.AssetsSet.FullAddress = {};

          generalService
            .placeSearch($scope.AssetsSet.FullAddress.formatted_address)
            .then(respond => {
              $scope.fullAutoAddressDetails = respond.data;
            });
        }, 500);
      },
      selectFullAddress(fullAddress) {
        $scope.AssetsSet.FullAddress = fullAddress;
        $scope.closeAutoFullAddress();
      },
      closeAutoFullAddress() {
        $scope.fullAutoAddressDetails = [];
      },
      saveBreakdown(form) {
        if (form) {
          let assetType = _.find($scope.assetTypes, o => {
            return o.Desc === $scope.AssetsSet.Type.Desc;
          });
          if (!assetType) {
            assetType = {
              Group: '',
            };
          }
          const validateAssetValue =
            _.isUndefined($scope.AssetsSet.Value) ||
            _.isNull($scope.AssetsSet.Value) ||
            $scope.AssetsSet.Value === 0;
          if (assetType.Group === 'Vehicle' && validateAssetValue) {
            toaster.pop('warning', 'Warning', 'Amount is required');
            return false;
          }
          if (
            assetType.Group === 'Real Estate' &&
            (_.isUndefined($scope.AssetsSet.FullAddress) ||
              _.size($scope.AssetsSet.FullAddress) < 1)
          ) {
            toaster.pop('warning', 'Warning', 'Address is required');
            return false;
          }
          $scope.formSubmittedValidation = true;
          $scope.addAsset(true, modalType);
          $scope.cancel();
          $scope.displayAssetsForm = false;
        }
      },
      addRentalIncome() {
        $scope.AssetsSet.RentalIncome.push(
          $scope.getNewRentalIncomeModel(
            _.size($scope.AssetsSet.RentalIncome) + 1
          )
        );
      },
      removeRentalIncome(income) {
        if (income === 0) {
          $scope.AssetsSet.RentalIncome = [];
        } else if (income.Id === 0) {
          _.remove($scope.AssetsSet.RentalIncome, o => {
            return _.isEqual(o, income);
          });

          _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
            o.index = key + 1; // putting index 1 to n
          });
        } else {
          SweetAlert.swal(
            {
              title: 'Are you sure?',
              text: 'This record will be removed from your income list',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: false,
            },
            confirm => {
              if (confirm) {
                loanScenarioService
                  .IncomeInfoDelete($scope.loanAppId, income.Id)
                  .then(() => {
                    $timeout(() => {
                      $scope.financialsSharedService.IncomeInfoGet();

                      _.remove($scope.AssetsSet.RentalIncome, o => {
                        return _.isEqual(o, income);
                      });

                      _.forEach($scope.AssetsSet.RentalIncome, (o, key) => {
                        o.index = key + 1; // putting index 1 to n
                      });

                      SweetAlert.swal({
                        title: 'Success',
                        text: 'Income has been been successfully deleted.',
                        type: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                      });
                    }, 100);
                  });
              }
            }
          );
        }
      },
      addMortgageLiability() {
        $scope.AssetsSet.MortgageLiability.push(
          $scope.getNewMortgageLiabilityModel(
            _.size($scope.AssetsSet.MortgageLiability) + 1
          )
        );
      },
      removeMortgageLiability(mortgage) {
        if (mortgage === 0) {
          $scope.AssetsSet.MortgageLiability = [];
        } else {
          mortgage.removeLiability = () => {
            _.remove($scope.AssetsSet.MortgageLiability, o => {
              return _.isEqual(o, mortgage);
            });

            _.forEach($scope.AssetsSet.MortgageLiability, (o, key) => {
              o.index = key + 1; // putting index 1 to n
            });
          };

          if (!mortgage.Id) {
            mortgage.removeLiability();
          } else {
            SweetAlert.swal(
              {
                title: 'Are you sure?',
                text: 'This record will be removed from your mortgage list',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              },
              confirm => {
                if (confirm) {
                  loanScenarioService
                    .LiabilityInfoDelete($scope.loanAppId, mortgage.Id)
                    .then(() => {
                      $timeout(() => {
                        // $rootScope.$broadcast('REFRESH_LIABILITY_SECTION');
                        $scope.financialsSharedService.LiabilityInfoGet();

                        mortgage.removeLiability();

                        SweetAlert.swal({
                          title: 'Success',
                          text: 'Liability has been been successfully deleted.',
                          type: 'success',
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }, 100);
                    });
                }
              }
            );
          }
        }
      },
    });

    $scope.$watch('AssetsSet.RentalIncome', () => {
      if (_.size($scope.AssetsSet.RentalIncome) > 0) {
        $scope.AssetsSet.IsRentalIncome = true;
      } else {
        $scope.AssetsSet.IsRentalIncome = false;
      }
    });

    $scope.$watch('AssetsSet.MortgageLiability', () => {
      if (_.size($scope.AssetsSet.MortgageLiability) > 0) {
        $scope.AssetsSet.IsExistingMortgages = true;
      } else {
        $scope.AssetsSet.IsExistingMortgages = false;
      }
    });
  });
