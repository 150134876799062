import template from './pipelineMoveCardModal.html';
import controller from './pipelineMoveCardModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    modalInstance: '<',
    selectedColumnId: '<',
    columnList: '<',
    onMoveCard: '&',
  },
};
