import angular from 'angular';
import insuranceBenefitWidget from './insuranceBenefitWidget';
import widgetField from './widgetField';
import widgetCheckbox from './widgetCheckbox';
import widgetCurrencyInput from './widgetCurrencyInput';
import widgetDropdown from './widgetDropdown';

export default angular
  .module('components.insuranceBenefitWidget', [])
  .component('insuranceBenefitWidget', insuranceBenefitWidget)
  .component('widgetField', widgetField)
  .component('widgetCheckbox', widgetCheckbox)
  .component('widgetCurrencyInput', widgetCurrencyInput)
  .component('widgetDropdown', widgetDropdown).name;
