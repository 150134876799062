import template from './quantityDisplay.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    quantity: '<',
    labelSingular: '@',
    labelPlural: '@',
  },
};
