import template from './downloadHtmlToImgMenu.html';
import controller from './downloadHtmlToImgMenuCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    pdfOrientation: '@',
    customMenuItemTemplate: '@',
    htmlNodeIdToPrint: '@',
    onCustomMenuItemClick: '&',
  },
};
