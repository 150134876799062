import swal from 'sweetalert';
import { isMobileValid } from 'Common/utilities/mobile';
import { FACT_FIND_HEADINGS_VALUES } from 'Common/constants/factFindHeadings';

export default class RequestFactFindCtrl {
  constructor(
    toaster,
    $uibModal,
    crmConfirmation,
    loanScenarioService,
    loanApplicationServices,
    onlineFactFindModal,
    configService
  ) {
    'ngInject';

    this.toaster = toaster;
    this.uibModal = $uibModal;
    this.crmConfirmation = crmConfirmation;
    this.loanScenarioService = loanScenarioService;
    this.loanApplicationServices = loanApplicationServices;
    this.onlineFactFindModal = onlineFactFindModal;
    this.factFindMessageRequired =
      configService.feature.factFindMessageRequired || 0;
  }

  $onInit() {
    this.sendHeadings = {
      YourGoals: false,
      YourHouseHold: false,
      YourHome: false,
      ToPurchase: false,
      OtherProperties: false,
      Assets: false,
      Liabilities: false,
      Income: false,
      Expenses: false,
      YourDetails: false,
      CoApplicants: false,
      AddressHistory: false,
      EmploymentHistory: false,
      ForeseeableChanges: false,
      CreditHistory: false,
      SupportingDocuments: false,
    };
    this.getHeadings();
    this.getApplicationDetails();
  }

  getHeadings() {
    this.loanApplicationServices.getFactFindHeadings().then(response => {
      this.requestSections = response.map(obj => {
        const selected = false;
        return { selected, ...obj };
      });
    });
  }

  getCountry() {
    this.optionsService.countryListGet().then(
      response => {
        this.country = (response && response.data) || [];
      },
      () => {
        this.country = [];
      }
    );
  }

  getApplicationDetails(updated = false) {
    if (this.loanId) {
      this.loanScenarioService
        .scenarioApplicantListGet(this.loanId)
        .then(response => {
          if (!response || !response.data || !response.data.InvolvedPartyPerson)
            return;
          const { InvolvedPartyPerson } = response.data;
          this.getInvolveParty(InvolvedPartyPerson, updated);
        });
    }
  }

  getInvolveParty(party, updated) {
    if (!party) return;
    this.involvedParty = this.onlineFactFindModal.getInvolveParty(party);
    if (!this.involvedParty || !this.involvedParty.length) return;
    this.applicantInfo = updated
      ? this.involvedParty.find(
          obj => obj.data.PersonId === this.applicantInfo.data.PersonId
        )
      : this.involvedParty[0];
    this.updateApplicant();
  }

  updateApplicant() {
    if (this.applicantInfo && this.applicantInfo.data) {
      const { Email, Phone } = this.applicantInfo.data;
      this.applicantEmail = Email && Email.find(obj => obj.Type === 'Email');
      this.applicantMobileNumber =
        Phone && Phone.find(obj => obj.Type === 'Mobile');
      this.checkEmail =
        (this.applicantEmail && this.applicantEmail.EmailAddress) || false;
      this.checkMobile =
        (this.applicantMobileNumber && this.applicantMobileNumber.Number) ||
        false;
      if (this.applicantMobileNumber && this.applicantMobileNumber.Number) {
        this.isValidMobile = isMobileValid(this.applicantMobileNumber.Number);
      }
    }
  }

  openClientEditModal(isChild) {
    if (
      !this.applicantInfo ||
      !this.applicantInfo.data ||
      !this.applicantInfo.data.PersonId
    )
      return;
    const countryList = this.country;
    const { familyId, data } = this.applicantInfo;
    this.uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/add_client_modal_v2.html',
        controller: 'AddClientModalCtrl',
        size: 'lg',
        resolve: {
          countryList: () => countryList,
          isChild: () => isChild,
          clientId: () => data.PersonId,
          applicantFamilyId: () => familyId,
        },
      })
      .result.then(modalResponse => {
        if (modalResponse.updateApplicant) {
          this.getApplicationDetails(true);
        }
      });
  }

  send() {
    const isHeadingSectionDone =
      this.requestSections && this.requestSections.find(item => item.selected);
    if (isHeadingSectionDone) {
      this.sendHeadings.LoanApplicationId = parseInt(this.loanId, 10);
      this.sendHeadings.FamilyId = parseInt(this.applicantInfo.familyId, 10);
      this.sendHeadings.ClientId = parseInt(
        this.applicantInfo.data.PersonId,
        10
      );
      this.sendHeadings.ClientMessage = this.clientMessage || '';
      this.modalInstance.close({ factFindSend: true });
      this.loanApplicationServices
        .partialShare(
          this.sendHeadings,
          this.applicantEmail.EmailAddress,
          this.applicantMobileNumber.Number
        )
        .then(response => {
          if (response && response.data) {
            this.crmConfirmation
              .open({
                type: 'success',
                title:
                  'A request to complete specific fact find sections has been sent!',
                description: `Your customer will receive an email within a few minutes`,
                buttonText: 'Got it',
              })
              .result.then(crmResponse => {
                if (crmResponse) {
                  this.modalInstance.close({
                    factFindSend: true,
                    factFindType: '',
                  });
                }
              });
          } else {
            swal({
              title: 'Failed to send invite!',
              text: 'Please contact support',
              type: 'error',
              showCancelButton: false,
            });
          }
        });
    } else {
      this.toaster.pop(
        'warning',
        'Required Field',
        'Please select specific fact find sections.'
      );
    }
  }

  updateRequestSections(sectionId, value) {
    switch (sectionId) {
      case FACT_FIND_HEADINGS_VALUES.YOUR_GOAL:
        this.sendHeadings.YourGoals = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.YOUR_HOUSEHOLD:
        this.sendHeadings.YourHouseHold = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.YOUR_HOME:
        this.sendHeadings.YourHome = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.TO_PURCHASE:
        this.sendHeadings.ToPurchase = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.OTHER_PROPERTIES:
        this.sendHeadings.OtherProperties = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.ASSETS:
        this.sendHeadings.Assets = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.LIABILITIES:
        this.sendHeadings.Liabilities = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.INCOME:
        this.sendHeadings.Income = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.EXPENSES:
        this.sendHeadings.Expenses = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.YOUR_DETAILS:
        this.sendHeadings.YourDetails = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.CO_APPLICATIONS:
        this.sendHeadings.CoApplicants = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.ADDRESS_HISTORY:
        this.sendHeadings.AddressHistory = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.EMPLOYMENT_HISTORY:
        this.sendHeadings.EmploymentHistory = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.FORSEEABLE_CHANGES:
        this.sendHeadings.ForeseeableChanges = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.CREDIT_HISTORY:
        this.sendHeadings.CreditHistory = value;
        break;
      case FACT_FIND_HEADINGS_VALUES.SUPPORTING_DOCUMENTS:
        this.sendHeadings.SupportingDocuments = value;
        break;
      default:
    }
  }

  disableSendButton() {
    return (
      !this.applicantInfo ||
      !this.checkMobile ||
      !this.checkEmail ||
      !this.isValidMobile ||
      (!this.clientMessage && this.factFindMessageRequired)
    );
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
