import quoteToContactCtrl from './quoteToContactCtrl';

class SaveToContactCtrl {
  constructor($window, $uibModal, toaster) {
    'ngInject';

    this.$window = $window;
    this.$uibModal = $uibModal;
    this.toaster = toaster;
    this.prefix = this.formData.prefix;
  }

  prepareFormData(peopleEntity) {
    if (peopleEntity && peopleEntity.length) {
      if (this.formData && !this.formData.FamilyFullName) {
        const mainContact = peopleEntity[0];
        this.formData.FamilyFullName = `${mainContact.FirstName} ${
          mainContact.LastName
        }`;
      }
    }
  }

  clickListener(type) {
    if (this.formData[`${this.prefix}Id`]) {
      this.prepareFormData(this.formData.PeopleEntity);
      this.$uibModal.open({
        templateUrl:
          '/src/components/insurancesSaveToContact/saveToContact.html',
        controller: quoteToContactCtrl,
        controllerAs: 'vm',
        size: 'md',
        backdrop: 'static',
        windowClass: 'insurances-save-to-contact-modal',
        resolve: {
          formData: this.formData,
          listenFrom: { types: type },
        },
      });
    } else {
      this.toaster.pop(
        'warning',
        'Warning',
        'Please calculate first before saving to existing client.'
      );
    }
  }
}

export default SaveToContactCtrl;
