import angular from 'angular';
import _ from 'lodash';
import { isMobileValid } from 'Common/utilities/mobile';
import { clientWindowWatcher } from 'Common/utilities/clientWindowWatcher';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('AddClientModalCtrl', function AddClientModalCtrl(
    $scope,
    $window,
    $uibModalInstance,
    isChild,
    optionsService,
    contactService,
    $rootScope,
    clientId,
    applicantFamilyId,
    toaster,
    $timeout,
    utilitiesService,
    colorService,
    uiService,
    countryList
  ) {
    if (countryList) {
      $scope.countryList = countryList;
    }
    if (applicantFamilyId) {
      $scope.familyId = applicantFamilyId;
    }
    const familyId = $scope.familyId || applicantFamilyId;
    $scope.titles = {};
    $scope.$watch('contact[0].Title', () => {
      const maleTitle = ['Mr', 'Master'];
      const femaleTitle = ['Mrs', 'Ms', 'Miss'];
      if ($scope.contact && $scope.contact.length > 0) {
        if (
          typeof $scope.contact[0].Title !== 'undefined' &&
          $scope.contact[0].Title !== ''
        ) {
          if (maleTitle.indexOf($scope.contact[0].Title) !== -1) {
            $scope.contact[0].Gender = 'Male';
          } else if (femaleTitle.indexOf($scope.contact[0].Title) !== -1) {
            $scope.contact[0].Gender = 'Female';
          }
        }
      }
    });

    optionsService.MaritalStatusGet().then(
      response => {
        $scope.maritalStatus = response.data;
      },
      () => {
        $scope.maritalStatus = [];
      }
    );

    optionsService.ResidencyStatusGet().then(
      response => {
        $scope.residencyStatus = response.data;
      },
      () => {
        $scope.residencyStatus = [];
      }
    );

    $scope.contactDOB = {
      openDate: dateField => {
        $scope.contactDOB.datePopUp[dateField] = !$scope.contactDOB.datePopUp[
          dateField
        ];
      },
      format: 'dd/MM/yyyy',
      altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
      dateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
      datePopUp: {
        DOB: false,
      },
    };

    contactService.titleType().then(
      response => {
        $scope.titles = response.data;
      },
      () => {
        $scope.titles = {};
      }
    );
    if (typeof clientId === 'undefined') {
      let country;
      switch ($rootScope.crmCountry) {
        case 'Australia':
          country = 'AU';
          break;
        case 'New Zealand':
          country = 'NZ';
          break;
        default:
          country = '-1';
      }
      $scope.Title = 'Add';
      $scope.contact = [
        {
          FamilyId: familyId,
          LastName: '',
          FirstName: '',
          MiddleName: '',
          PreferredName: '',
          Title: '',
          Gender: '',
          DOB: '',
          Employment: [
            {
              Occupation: '',
              OccupationClass: 0,
              Employer: '',
            },
          ],
          SmokerStatus: '',
          Deceased: false,
          DOBReminder: false,
          BestTimeToCall: '',
          Phone: [
            {
              Type: 'Home',
              Number: '',
            },
            {
              Type: 'Work',
              Number: '',
            },
            {
              Type: 'Mobile',
              Number: '',
            },
          ],
          Email: [
            {
              Type: 'Email',
              EmailAddress: '',
            },
          ],
          Notes: '',
          IsResident: false,

          CountryCode: country,
          customerConsentOptions: { ConsentMarketing: true },
        },
      ];
    } else {
      $scope.Title = 'Edit';
      contactService.clientInformGet(familyId, clientId).then(response => {
        $scope.contact = response.data;
        const contactPhoneCopy = angular.copy($scope.contact[0].Phone);
        if (_.isUndefined($scope.contact[0].IsResident)) {
          $scope.contact[0].IsResident = false;
        }
        if ($scope.contact[0].MaritalStatusId !== undefined) {
          $scope.maritalStatus = Array.isArray($scope.maritalStatus)
            ? $scope.maritalStatus
            : [];
          const maritalStatus = $scope.maritalStatus.filter(item => {
            return (
              parseInt(item.Value, 10) ===
              parseInt($scope.contact[0].MaritalStatusId, 10)
            );
          });
          if (maritalStatus && maritalStatus.length) {
            $scope.contact[0].MaritalStatusId = maritalStatus[0].Value;
          }
        }

        // find country codes
        let CitizenshipObj = _.find($scope.countryList, o => {
          return o.CountryID === $scope.contact[0].CitizenshipId;
        });
        let CountryOfResidencyObj = _.find($scope.countryList, o => {
          return o.CountryID === $scope.contact[0].CountryOfResidencyId;
        });
        if (!CitizenshipObj) {
          CitizenshipObj = {
            CountryCode: '',
          };
        }
        if (!CountryOfResidencyObj) {
          CountryOfResidencyObj = {
            CountryCode: '',
          };
        }
        $scope.contact[0].Phone = [];

        // Modify the sequence of phone
        $scope.contact[0].Phone[0] = _.find(contactPhoneCopy, obj => {
          return obj.Type === 'Home';
        }) || { Type: 'Home', Number: null };

        $scope.contact[0].Phone[1] = _.find(contactPhoneCopy, obj => {
          return obj.Type === 'Work';
        }) || { Type: 'Work', Number: null };

        $scope.contact[0].Phone[2] = _.find(contactPhoneCopy, obj => {
          return obj.Type === 'Mobile';
        }) || { Type: 'Mobile', Number: null };
        if (
          typeof $scope.contact[0].DOB === 'undefined' ||
          $scope.contact[0].DOB === ''
        ) {
          $scope.contact[0].DOB = '';
        } else {
          $scope.contact[0].DOB = new Date($scope.contact[0].DOB);
        }
        angular.extend($scope.contact[0], {
          FamilyId: familyId,
          CountryCode: CitizenshipObj.CountryCode,
          CountryOfResidencyCode: CountryOfResidencyObj.CountryCode,
          CountryOfBirthCode: $scope.getCountryOfBirthCode(),
          ResidencyId: String($scope.contact[0].ResidencyId),
          MaritalStatusId: String($scope.contact[0].MaritalStatusId),
        });

        if (_.size($scope.contact[0])) {
          $scope.contact[0].Employment[0].OccupationClass = String(
            $scope.contact[0].Employment[0].OccupationClass
          );
        }
      });
    }

    $scope.getClientByFamilyId = function(isHttpRequest) {
      contactService
        .clientInformGet($scope.familyId, null, isHttpRequest)
        .then(clientInformGetResponse => {
          $scope.clientsAdultData = [];
          $scope.clientsChildData = [];
          $scope.numberOfAdults = 0;
          $scope.clientsDataObject = clientInformGetResponse.data;

          if ($scope.clientsDataObject && $scope.clientsDataObject.length) {
            $scope.clientsAdultData = $scope.clientsDataObject.reduce(
              (initial, currentObj) => {
                if (currentObj.Role !== 'Child') {
                  initial.push(currentObj);
                }
                return initial;
              },
              []
            );
            $scope.clientsChildData = $scope.clientsDataObject.reduce(
              (initial, currentObj) => {
                if (currentObj.Role === 'Child') {
                  const ageDifMs = Date.now() - new Date(initial.DOB);
                  const ageDate = new Date(ageDifMs); // miliseconds from epoch
                  initial.background = colorService.getRandomColor();
                  initial.initials = utilitiesService.filterInitial(
                    initial.FirstName,
                    initial.LastName
                  );
                  initial.Age = Math.abs(ageDate.getUTCFullYear() - 1970);
                  initial.push(currentObj);
                }
                return initial;
              },
              []
            );
          }
          angular.element('.clients-list-box').matchHeight();
          $scope.numberOfAdults = $scope.clientsAdultData.length;
          $uibModalInstance.close({ updateApplicant: true });
          $scope.clientsData = $scope.clientsDataObject;
        });
    };
    $scope.addClient = function(form) {
      let mobileNumber = null;
      const isMobileAvailable =
        $scope.contact &&
        $scope.contact.length &&
        $scope.contact[0].Phone &&
        $scope.contact[0].Phone.length;
      if (isMobileAvailable) {
        const mobileObject = $scope.contact[0].Phone.find(
          obj => obj.Type === 'Mobile'
        );
        if (mobileObject.Number) mobileNumber = mobileObject.Number;
      }
      if (mobileNumber) {
        const checkMobileIfValid = isMobileValid(mobileNumber);
        if (!checkMobileIfValid) {
          toaster.pop('error', 'Error', 'Invalid Number.');
          return false;
        }
      }
      let firstError = null;
      if (form.$invalid) {
        firstError = null;
        form &&
          Object.keys(form).forEach(field => {
            if (field[0] !== '$') {
              if (firstError === null && !form[field].$valid) {
                firstError = form[field].$name;
              }

              if (form[field].$pristine) {
                form[field].$dirty = true;
              }
            }
          });

        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        toaster.pop(
          'warning',
          'Invalid Form',
          'Please fill up the form properly'
        );
        return;
      }
      //
      $timeout(() => {
        $scope.contact[0].CountryOfBirth = $scope.contact[0].CountryCode;

        // let just pass in the 3 new properties
        /*
                        @CitizenshipId
                        @CountryOfResidencyId
                        @ResidencyId
                    */
        let CitizenshipObj = _.find($scope.countryList, o => {
          return o.CountryCode === $scope.contact[0].CountryCode;
        });
        let CountryOfResidencyObj = _.find($scope.countryList, o => {
          return o.CountryCode === $scope.contact[0].CountryOfResidencyCode;
        });
        if (!CitizenshipObj) {
          CitizenshipObj = {
            CountryID: 0,
          };
        }
        if (!CountryOfResidencyObj) {
          CountryOfResidencyObj = {
            CountryID: 0,
          };
        }

        angular.extend($scope.contact[0], {
          CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
          CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
          CountryOfBirthId: $scope.getCountryOfBirthId(),
          ResidencyId: parseInt($scope.contact[0].ResidencyId, 10),
          MaritalStatusId: parseInt($scope.contact[0].MaritalStatusId, 10),
          DOB: $scope.contact[0].DOB,
        });

        if (
          $scope.contact[0].customerConsentOptions.ConsentMarketing !== true
        ) {
          $scope.contact[0].customerConsentOptions.ConsentMarketing = false;
        }

        if (
          !_.isUndefined($scope.contact[0].Address) &&
          !_.isUndefined($scope.contact[0].Address[0])
        ) {
          if ($scope.contact[0].Address[0].Type === 'Current Address') {
            $scope.contact[0].Address[0].Type = 'Home';
          }
        }

        contactService
          .addContact($scope.contact)
          .then(() => {
            $scope.getClientByFamilyId();
            if (typeof clientId !== 'undefined') {
              toaster.pop('success', 'Updated', 'client successfully updated');
            } else {
              toaster.pop('success', 'Added', 'client successfully added');
            }
          })
          .catch(displayError);
      }, 500);
    };

    $scope.isCountryOfBirthShown = () => {
      return uiService.isCountry('New Zealand');
    };

    $scope.getCountryOfBirthCode = () => {
      const contact = $scope.contact ? $scope.contact[0] : null;
      if (!$scope.countryList || !contact) return '';
      const contactCountryOfBirthId = contact.CountryOfBirthId;
      const CountryOfBirthObj = $scope.countryList.find(
        country => country.CountryID === contactCountryOfBirthId
      );
      return CountryOfBirthObj ? CountryOfBirthObj.CountryCode : '';
    };

    $scope.getCountryOfBirthId = () => {
      const contact = $scope.contact ? $scope.contact[0] : null;
      if (!$scope.countryList || !contact) return 0;
      const contactCountryOfBirthCode = contact.CountryOfBirthCode;
      const CountryOfBirthObj = $scope.countryList.find(
        country => country.CountryCode === contactCountryOfBirthCode
      );
      return CountryOfBirthObj ? parseInt(CountryOfBirthObj.CountryID, 10) : 0;
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // Change Page View On Change Screen Size
    //-------------------------------------------------------------
    $scope.screenSize = $window.innerWidth;
    if ($scope.screenSize < 992) {
      $scope.isNotesPanelDisplayed = false;
      $scope.isAdditionalPanelDisplayed = false;
    } else {
      $scope.isNotesPanelDisplayed = true;
      $scope.isAdditionalPanelDisplayed = true;
    }
    const viewport = function() {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = $window.document.documentElement || $window.document.body;
      }
      return {
        width: e[`${a}Width`],
      };
    };

    $scope.getWindowWidth = function() {
      return {
        w: viewport().width,
      };
    };

    $scope.$watch(
      $scope.getWindowWidth,
      newValue => {
        const addClientWindowWatcherObj = clientWindowWatcher(newValue);
        Object.keys(addClientWindowWatcherObj).forEach(key => {
          $scope[key] = addClientWindowWatcherObj[key];
        });
      },
      true
    );

    // equalize code provided by Edward
    function equalize(target) {
      // manifest
      const columnHeights = [];
      const columns = $window.document.querySelectorAll(target);
      // loop through targets and fetch heights
      for (let i = 0; i < columns.length; i++) {
        // fetch column heights
        const colHeight = columns[i].offsetHeight;
        // append values into array
        columnHeights[i] = colHeight;
      }
      // sort heights from tallest to shortest
      columnHeights.sort((a, b) => {
        return a > b ? -1 : 1;
      });
      // set a hard code height to equalize
      if (_.size(columns) > 0) {
        _.forEach(columns, el => {
          el.style.setProperty('height', `${columnHeights[0]}px`);
        });
      }
    }

    $scope.reevaluateEqualize = function() {
      equalize('[data-equalize]');
    };
    function equalizeCaller() {
      equalize('[data-equalize]');
    }
    $timeout(equalizeCaller, 1000);
  });
