import { displayError } from 'Common/utilities/alert';
import { WORK_BENCH_STATES } from 'Common/constants/workbench';
import { filter, find, forEach } from 'lodash';
import swal from 'sweetalert';

export default class InsuranceAppWorkbenchModalCtrl {
  constructor(insurancePipelineService, contactService, clientInfoService) {
    'ngInject';

    this.contactService = contactService;
    this.insurancePipelineService = insurancePipelineService;
    this.clientInfoService = clientInfoService;
    this.WORK_BENCH_STATES = WORK_BENCH_STATES;
  }

  $onInit() {
    this.workbenchView = WORK_BENCH_STATES.IS_MAIN;
    this.logList = [];
    this.familyList = [];
    this.clientsList = [];
    this.clientDetails = [];
    this.homePhone = [];
    this.mobilePhone = [];
    this.workBenchDetails = {};
    this.isStatusChange = false;
    this.loadWorkbenchLogs();
    this.getFamilyMembers();
    this.getWorkBenchDetails();
  }

  getWorkBenchDetails() {
    this.insurancePipelineService
      .getClientWorkBenchDetails(this.quoteId)
      .then(res => {
        this.workBenchDetails = res;
      });
  }
  loadWorkbenchLogs() {
    this.insurancePipelineService
      .getInsurancePipelineWorkbenchRecords(this.quoteId)
      .then(({ data }) => {
        this.logList = data;
      })
      .catch(displayError);
  }

  setView(workbenchView, data) {
    this.workbenchView = workbenchView;
    switch (this.workbenchView) {
      case WORK_BENCH_STATES.IS_EMAIL:
        this.emailToEdit = data;
        break;
      case WORK_BENCH_STATES.IS_MAIL:
        this.mailToEdit = data;
        break;
      case WORK_BENCH_STATES.IS_NOTE:
        this.noteToEdit = data;
        break;
      default:
    }
  }

  clientInformGet(familyId) {
    this.contactService.clientInformGet(familyId, null).then(response => {
      const { data } = response;
      if (!data) return;

      this.clientDetails = filter(response.data, obj => {
        return obj.Role === 'Adult';
      });

      forEach(this.clientDetails, obj => {
        const home = find(obj.Phone, phone => {
          return phone.Type === 'Home';
        });

        if (home) {
          this.homePhone.push(home);
        }

        const mobile = find(obj.Phone, phone => {
          return phone.Type === 'Mobile';
        });

        if (mobile) {
          this.mobilePhone.push(mobile);
        }
        return obj;
      });
    });
  }

  getFamilyMembers() {
    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      this.clientsList = data || [];
      this.familyList =
        data &&
        [this.clientInfoService.getClientHeader({ information: data })].map(
          family => {
            return {
              FamilyId: this.familyId,
              FamilyFullName: family.headerName,
              IsClient: true,
            };
          }
        );
    });
  }

  partyChange(familyId, isClient) {
    if (!familyId) return;

    if (isClient) {
      this.clientInformGet(familyId);
    } else {
      this.organisationInfoGet(familyId);
    }
  }

  editLog(log) {
    this.setView(log.LogTypeID, log);
  }

  deleteLog(log) {
    swal(
      {
        title: 'Are you sure you want to delete this log?',
        text: 'You will not be able to retrieve this later',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete it!',
        closeOnConfirm: true,
      },
      confirm => {
        if (confirm) {
          this.insurancePipelineService
            .deleteInsurancePipelineWorkbenchRecord(log.WorkbenchLogID)
            .then(() => this.loadWorkbenchLogs())
            .catch(displayError);
        }
      }
    );
  }

  onCoverDetailsInit(api) {
    this.coverDetailsView = api;
  }

  onStatusChange() {
    this.isStatusChange = true;
    this.coverDetailsView.refresh();
  }

  onCoversUpdated() {
    this.coversUpdated = true;
  }

  onNextWorkDateChange() {
    this.loadWorkbenchLogs();
    this.onCoversUpdated();
    this.getWorkBenchDetails();
  }

  back(e) {
    this.workbenchView = WORK_BENCH_STATES.IS_MAIN;
    if (e && e.refresh) {
      this.loadWorkbenchLogs();
    }
  }

  close() {
    this.modalInstance.close({
      isStatusChange: this.isStatusChange,
      coversUpdated: this.coversUpdated,
    });
  }
}
