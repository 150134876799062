import angular from 'angular';

angular.module('app').directive('colorPickerTextBox', [
  function colorPickerTextBox() {
    return {
      restrict: 'E',
      scope: {
        ngModel: '=',
        options: '=',
      },
      template:
        '<div class="input-group spectrum-color-picker">' +
        '<input class="form-control input-sm no-border" ng-model="ngModel" type="text">' +
        '<span class="input-group-addon">' +
        '<spectrum-colorpicker ng-model="ngModel" options="options"></spectrum-colorpicker>' +
        '</span>' +
        '</div>',
    };
  },
]);
