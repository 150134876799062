import template from './datePicker.html';
import controller from './datePickerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isRequired: '<',
    config: '<',
  },
};
