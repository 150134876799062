import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller('AddTaskCtrl', function AddTaskCtrl(
    $scope,
    $stateParams,
    $uibModalInstance,
    $timeout,
    toaster,
    contactService,
    dashboardService,
    taskService,
    triggerFilter,
    hasFilter,
    tasksSharedData,
    clientInfoService,
    generalSearchService,
    taskDetailService,
    currentUserService,
    contactModelService
  ) {
    $scope.taskSet = {
      FamilyId: 0,
      TaskType: 'Business',
      Notes: '',
    };
    $scope.taskSetAssingnToInitObj = {};
    $scope.taskSetCreatedByObj = {};
    if (parseInt($stateParams.familyId, 10) >= 1) {
      contactService.clientInformGet($stateParams.familyId).then(response => {
        const data = response.data;
        $scope.taskInput.familyValue = clientInfoService.getFullName(data);
        $scope.taskSet.FamilyId = $stateParams.familyId;
      });
    }
    $scope.mainObject = {};
    $scope.mainObject.isSearchEnable = false;
    $scope.familyId = $stateParams.familyId;
    $scope.mainObject.datePopUp = false;
    $scope.isSaveTaskDisable = false;
    $scope.format = 'dd MMM yyyy';
    $scope.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    $scope.NOTE_DEFAULT_HEIGHT = {
      ADD_MODE: 130,
      EDIT_MODE: 190,
    };

    $scope.onUpdateSelectAdviser = (data, isAssignTo) => {
      if (!data || !data.originalObject) return;
      if (isAssignTo) {
        $scope.updateFamilyOnAdviserChange(data.originalObject.BrokerID);
        $scope.taskSet.assignedClientID = `${data.originalObject.BrokerID}`;
      } else {
        $scope.taskSet.CreatedByUser = `${data.originalObject.BrokerID}`;
      }
    };

    const initializeSearchLists = (isAssignTo, objectInit) => {
      if (isAssignTo) $scope.taskSetAssingnToInitObj = objectInit;
      $scope.taskSetCreatedByObj = objectInit;
    };

    const findSelectedAdviser = id => {
      const filteredAdviserList = $scope.taskAdviserList.filter(
        adviser => parseInt(adviser.BrokerID, 10) === parseInt(id, 10)
      );
      return filteredAdviserList && filteredAdviserList.length
        ? filteredAdviserList[0].DisplayName
        : '';
    };
    // Open Date Popup
    $scope.openDate = () => {
      $scope.mainObject.datePopUp = !$scope.mainObject.datePopUp;
    };
    $scope.updateFamilyOnAdviserChange =
      tasksSharedData.updateFamilyOnAdviserChange;

    $scope.closeFamilyList = () => {
      $scope.familyDetailsList = taskDetailService.closeFamilyList(
        $scope.taskInput,
        $scope.mainObject
      );
    };

    $scope.selectFamily = (id, contactFullName) => {
      $scope.familyDetailsList = taskDetailService.selectFamily(
        id,
        contactFullName,
        $scope.taskInput,
        $scope.taskSet,
        $scope.mainObject
      );
    };

    // For Add Note
    $scope.addNotes = () => {
      if (
        typeof $scope.taskSet.Notes === 'undefined' ||
        $scope.taskSet.Notes.trim() === ''
      ) {
        $scope.showNotes = true;
      }
    };

    const taskCompletionSet = task => {
      taskService
        .taskCompletionSet(task.FamilyID, task.TaskID, task.IsCompleted)
        .then(response => {
          if (response.data === 1) {
            if (hasFilter) triggerFilter();
          } else {
            task.IsCompleted = !task.IsCompleted;
          }
        });
    };

    // Set Completion Task
    $scope.toggleOneComplete = task => {
      task.IsCompleted = !task.IsCompleted;

      if (!task.IsCompleted) {
        taskCompletionSet(task);
        return;
      }

      tasksSharedData.completionConfirmation(
        () => {
          taskCompletionSet(task);
        },
        () => {
          task.IsCompleted = false;
        }
      );
    };

    $scope.$watch('duedateview', newVal => {
      if (newVal === 'date') {
        angular.element('.datepicker').show();
        angular.element('.timepicker').hide();
      } else {
        angular.element('.timepicker').show();
        angular.element('.datepicker').hide();
      }
    });
    $scope.isCorporateUser =
      currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased =
      COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    contactModelService.taskAdviserListGet(isLoanWriter).then(response => {
      $scope.taskAdviserList = response.data;
      $scope.taskAdviserList = _.reject(response.data, ['BrokerID', 0]);
      angular.forEach(response.data, item => {
        if (typeof item.BrokerID !== 'undefined')
          item.BrokerID = item.BrokerID.toString();
      });
      if (parseInt($scope.task_id, 10) === 0) {
        dashboardService.getBrokerBasicInfo().then(brokerinfo => {
          $scope.taskSet.assignedClientID = brokerinfo.data.BrokerId;
          $scope.taskSet.CreatedByUser = brokerinfo.data.BrokerId;

          initializeSearchLists(true, {
            brokerID: $scope.taskSet.assignedClientID,
            assignedClientName: brokerinfo.data.FullName,
          });
          initializeSearchLists(false, {
            brokerID: $scope.taskSet.CreatedByUser,
            createdByUser: brokerinfo.data.FullName,
          });
        });
      }

      if ($scope.task_id !== 0) {
        taskService.taskDetailsGet($scope.task_id).then(respond => {
          if (!respond || !respond.data) return;
          $scope.taskSet = respond.data;
          if ($scope.isCorporateUser) {
            const assignedClientName = findSelectedAdviser(
              $scope.taskSet.assignedClientID
            );
            initializeSearchLists(true, {
              brokerID: $scope.taskSet.assignedClientID,
              assignedClientName,
            });
            const createdByUser = findSelectedAdviser(
              $scope.taskSet.CreatedByUser
            );
            initializeSearchLists(false, {
              brokerID: $scope.taskSet.CreatedByUser,
              createdByUser,
            });
          }
          $scope.selectFamily(
            $scope.taskSet.FamilyID,
            $scope.taskSet.ClientFamilyName
          );
          $scope.taskSet.DueDate = new Date($scope.taskSet.DueDate);
          if (
            typeof $scope.taskSet !== 'undefined' &&
            typeof $scope.taskSet.CreatedByUser !== 'undefined'
          ) {
            $scope.taskSet.CreatedByUser =
              $scope.taskSet && $scope.taskSet.CreatedByUser
                ? $scope.taskSet.CreatedByUser.toString()
                : '';
          }

          $scope.showNotes = true;
          if (
            typeof $scope.taskSet.Notes === 'undefined' ||
            $scope.taskSet.Notes.trim() === ''
          ) {
            $scope.showNotes = false;
            $scope.selectFamily(
              $scope.taskSet.FamilyID,
              $scope.taskSet.ClientFamilyName
            );
          }
        });
      } else {
        $scope.taskSet.DueDate = new Date();
      }
    });
    $scope.taskInput = {};
    $scope.ctr = 1;
    $scope.searchFamilyInput = function(searchString) {
      $scope.mainObject.isSearchEnable = true;
      if (searchString) {
        dashboardService.getGenralSearchResult(searchString).then(response => {
          generalSearchService
            .getClientResult(response, searchString)
            .then(searchResult => {
              $scope.familyDetailsList = generalSearchService.processClientSearch(
                searchResult.allResults
              );
            });
        });
      }
    };
    contactService.getAllByFilter('*').then(respond => {
      angular.forEach(respond.data.FamilyList, value => {
        if (value.FamilyID === $scope.familyId) {
          $scope.taskInput.familyValue = value.FamilyFullName;
          if (parseInt($scope.template_task_id, 10) === 0) {
            $scope.taskSet.FamilyId = value.FamilyID;
          }
        }
      });
    });

    $scope.saveNewTask = function() {
      $scope.isSaveTaskDisable = true;
      if (
        typeof $scope.taskInput.familyValue === 'undefined' ||
        $scope.taskInput.familyValue === ''
      ) {
        $scope.taskSet.FamilyId = 0;
      }
      const postData = {
        FamilyID: $scope.taskSet.FamilyId,
        Title: $scope.taskSet.Title,
        assignedClientID: $scope.taskSet.assignedClientID,
        CreatedByUser:
          $scope.taskSet && $scope.taskSet.CreatedByUser
            ? $scope.taskSet.CreatedByUser.toString()
            : '',
        DueDate: moment($scope.taskSet.DueDate).format('YYYY-MM-DD'),
        Notes: $scope.taskSet.Notes,
        ConnectID: $scope.taskSet.ConnectID,
      };

      const dismissModalTimeOut = () => {
        triggerFilter();
        $timeout(() => {
          $uibModalInstance.dismiss('cancel');
        }, 100);
      };

      let msg;
      let serviceMethod;
      if ($scope.task_id) {
        postData.TaskID = $scope.task_id;
        msg = 'Updated';
        serviceMethod = 'updateTask';
      } else {
        msg = 'Added';
        serviceMethod = 'storeTask';
      }

      taskService[serviceMethod](postData).then(() => {
        toaster.pop('success', msg, `Task successfully ${msg.toLowerCase()}`);
        dismissModalTimeOut();
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.isSaveDisable = () => {
      return (
        $scope.isSaveTaskDisable ||
        ($scope.loanDataList && $scope.loanDataList.length <= 0) ||
        $scope.mainObject.type === 'view'
      );
    };
  });
