import swal from 'sweetalert';
import { getToken, setToken, removeToken } from 'Common/utilities/token';
import { responseBuilderForUI } from 'Common/mappers/response';
import { constructConfigRequest } from 'Common/utilities/request';

app.factory('httpRequest', function(
  $http,
  configService,
  $window,
  $timeout,
  sessionService
) {
  $http.defaults.headers.common.Authorization = getToken();
  const updateSessionTime = function(isSessionAlreadyUpdated) {
    sessionService.resetSessionTime(isSessionAlreadyUpdated);
  };

  const doLogout = function() {
    removeToken();
    $http
      .post(`${configService.resource}/Logout`, null, constructConfigRequest())
      .then(() => window.location.assign(configService.login))
      .catch(() => window.location.assign(configService.login));
  };

  const updateSessionToken = function(
    type,
    url,
    data,
    successCallback,
    errorCallback,
    message
  ) {
    $http
      .post(
        `${configService.resource}/UpdateSession`,
        null,
        constructConfigRequest()
      )
      .then(response => {
        setToken(`Bearer ${response.data}`);
        configService.token = `Bearer ${response.data}`;
        $http.defaults.headers.common.Authorization = getToken();
        if (type === 'post' || type === 'put')
          $http[type](url, data).then(successCallback, errorCallback);
        else $http[type](url).then(successCallback, errorCallback);
      })
      .catch(() => {
        if (message) {
          swal(
            message,
            'You will be redirected automatically to the login page.',
            'error'
          );
        }
        $timeout(() => doLogout(), 2000);
      });
  };

  const errorCallback = function(
    e,
    successCallback,
    customErrorCallback,
    type,
    url,
    data
  ) {
    const errorStatus = [404, 400, 500];
    let errorMessage;
    if (e.status === 401) {
      const message = e.message || 'Session Expired';
      updateSessionToken(
        type,
        url,
        data,
        successCallback,
        errorCallback,
        message
      );
    }
    switch (e.status) {
      case 400:
        errorMessage = e.messages;
        break;
      default:
        errorMessage = e.statusText;
        break;
    }
    if (errorStatus.indexOf(e.status) !== -1) {
      swal('Yikes! Something is wrong', 'Please contact support.', 'error');
    }
    customErrorCallback(e);
  };
  const getResourceUrl = function(url) {
    const splitUrl = url.split('?');

    if (
      typeof configService.exclude !== 'undefined' &&
      configService.exclude.indexOf(splitUrl[0]) !== -1
    ) {
      url = `${$window.location.protocol}//${
        $window.location.host
      }/json/${url.replace(/\?/, '.json?')}`;
    } else {
      url = `${configService.resource}/${url}`;
    }

    return url;
  };

  const getMethod = function(url, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .get(url, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(
          response,
          successCallback,
          () => {
            customErrorCallback(response);
          },
          'get',
          url
        );
      });
  };

  const postMethod = function(url, data, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .post(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(
          response,
          successCallback,
          () => {
            customErrorCallback(response);
          },
          'post',
          url
        );
      });
  };

  const putMethod = function(url, data, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .put(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(
          response,
          successCallback,
          () => {
            customErrorCallback(response);
          },
          'put',
          url
        );
      });
  };

  const patchMethod = function(
    url,
    data,
    successCallback,
    customErrorCallback
  ) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .patch(url, data, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(
          response,
          successCallback,
          () => {
            customErrorCallback(response);
          },
          'patch',
          url
        );
      });
  };

  const deleteMethod = function(url, successCallback, customErrorCallback) {
    if (typeof customErrorCallback === 'undefined') {
      customErrorCallback = function() {};
    }
    url = getResourceUrl(url);
    updateSessionTime();
    $http
      .delete(url, constructConfigRequest())
      .then(response => successCallback(responseBuilderForUI(response)))
      .catch(e => {
        const response = responseBuilderForUI(e);
        errorCallback(
          response,
          successCallback,
          () => {
            customErrorCallback(response);
          },
          'delete',
          url
        );
      });
  };

  return {
    get: getMethod,
    post: postMethod,
    put: putMethod,
    delete: deleteMethod,
    patch: patchMethod,
  };
});
