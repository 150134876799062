export const PIPELINE_FILTER_TYPES = {
  APPLICATION: [
    {
      FILTER_KEY: 'advisorId',
      FILTER_TITLE: 'Adviser Name',
      DEFAULT_VALUE: 0,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'labelId',
      FILTER_TITLE: 'Labels',
      DEFAULT_VALUE: 0,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'searchClientName',
      FILTER_TITLE: 'Search',
      DEFAULT_VALUE: null,
      IS_SELECTED: false,
    },
  ],
  LEADS: [
    {
      FILTER_KEY: 'subStatusId',
      FILTER_TITLE: 'Substatus',
      DEFAULT_VALUE: 0,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'advisorId',
      FILTER_TITLE: 'Adviser Name',
      DEFAULT_VALUE: 0,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'labelId',
      FILTER_TITLE: 'Labels',
      DEFAULT_VALUE: 0,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'probability',
      FILTER_TITLE: 'Probability',
      DEFAULT_VALUE: 'All',
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'enquiryObject',
      FILTER_TITLE: 'Enquiry Source',
      DEFAULT_VALUE: { ReferralCategoryId: 0, ReferralItemId: 0 },
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'referral',
      FILTER_TITLE: 'Referral',
      DEFAULT_VALUE: 'All',
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'displayFilterDate',
      FILTER_TITLE: 'Date Created',
      DEFAULT_VALUE: null,
      IS_SELECTED: false,
    },
    {
      FILTER_KEY: 'searchClientName',
      FILTER_TITLE: 'Search',
      DEFAULT_VALUE: null,
      IS_SELECTED: false,
    },
  ],
};
