const BROKER_NOTES_ID = {
  INVOLVED_PARTIES: 1,
  RELATED_PARTIES: 2,
  EMPLOYMENT: 3,
  ADDRESS_HISTORY: 4,
  IDENTIFICATION: 5,
  DEPOSIT: 6,
  BORROWERS: 19,
  GUARANTORS: 20,
  DEPENDANTS: 21,
};

export default BROKER_NOTES_ID;
