import angular from 'angular';
import insuranceList from './insuranceList';
import saveQuotesinsurance from './saveQuotesinsurance';
import savedInsuranceProfilers from './savedInsuranceProfilers';

export default angular.module('clientInsurance.scenes', [
  insuranceList,
  saveQuotesinsurance,
  savedInsuranceProfilers,
]).name;
