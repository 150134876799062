import './style.scss';
import template from './template.html';
import CurrencyInputDropdownCtrl from './currencyInputDropdownCtrl';

export default {
  template,
  bindings: {
    value: '=',
    countryCode: '=',
    xeCountryId: '=',
    currencyCode: '=',
    currencyCodeId: '=',
    isRequired: '=',
    isInvalid: '=',
  },
  controller: CurrencyInputDropdownCtrl,
  controllerAs: 'vm',
};
