import './style.scss';
import template from './existingAddressInput.html';
import existingAddressInputCtrl from './existingAddressInputCtrl';

export default {
  template,
  controller: existingAddressInputCtrl,
  controllerAs: 'vm',
  bindings: {
    addressModel: '=',
    contactFamilyId: '<',
  },
};
