app.factory('simpologyLoanAppService', [
  '$q',
  'simpologyLoanAppModel',
  function($q, simpologyLoanAppModel) {
    var factory = this;

    var simpologyLoanApp = new simpologyLoanAppModel();

    factory.SimpologyLoanApplicationSubmission = function(loanApplicationId) {
      var defer = $q.defer();
      simpologyLoanApp.SimpologyLoanApplicationSubmission(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.SimpologyLoanApplicationSubmissionStatus = function(
      loanApplicationId
    ) {
      var defer = $q.defer();
      simpologyLoanApp.SimpologyLoanApplicationSubmissionStatus(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.SimpologyLoanApplicationView = function(loanApplicationId) {
      const defer = $q.defer();
      simpologyLoanApp.SimpologyLoanApplicationView(
        loanApplicationId,
        response => defer.resolve(response),
        error => defer.reject(error)
      );
      return defer.promise;
    };

    return factory;
  },
]);
