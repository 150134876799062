import template from './corporateTeamModal.html';
import CorporateTeamModalCtrl from './corporateTeamModalCtrl';

export default {
  template,
  controller: CorporateTeamModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    team: '<',
    email: '<',
  },
};
