import angular from 'angular';

angular
  .module('app')
  .controller('NotificationSidebarCtrl', function NotificationSidebarCtrl(
    $scope,
    $http,
    $timeout,
    $uibModal,
    $stateParams,
    loanToolsService,
    dashboardService,
    $filter,
    $element,
    $window,
    toaster,
    SweetAlert,
    configService,
    contactService,
    notificationSidebarService,
    $rootScope
  ) {
    const init = () => {
      if ($rootScope.initialView) $scope.currentView = $rootScope.initialView;
      const viewSwitchHandler = $rootScope.$on(
        'setNotificationView',
        (event, args) => {
          $scope.currentView = args.view;
        }
      );
      $scope.$on('$destroy', viewSwitchHandler);
    };
    init();
  });
