import template from './opportunity.html';
import controller from './opportunityCtrl';
import './style.scss';

export default {
  template,
  bindings: {},
  controller,
  controllerAs: 'vm',
};
