import angular from 'angular';
import { BIRTHDAYS_ID } from 'Common/constants/customerCareModules';

angular
  .module('app')
  .controller('BirthdaysSendMailModalCtrl', function birthdaysSendMailModalCtrl(
    $scope,
    $uibModalInstance,
    $state,
    $controller,
    contactService,
    mailTemplateService,
    birthdayBatchList,
    birthdayObj,
    source
  ) {
    const vm = this;
    angular.extend(
      vm,
      $controller('CustomerCareSendMailModalBaseCtrl', { $scope })
    );

    $scope.recipients = {};
    $scope.recipients.email = [];
    $scope.birthdayBatchList = birthdayBatchList;
    $scope.currentModule = BIRTHDAYS_ID;
    if (source === 'selected') {
      $scope.all = true;
      $scope.noOfPeople = $scope.birthdayBatchList.length;
    }
    $scope.selectEmailList = [];

    const init = () => {
      $scope.familyId = birthdayObj ? birthdayObj.FamilyID : null;

      // Ckeditor Tollbar Configuration
      $scope.options = {
        language: 'en',
        allowedContent: true,
        uiColor: '#DFD6E4',
        height: 300,
        resize_enabled: false,
        removePlugins: 'elementspath',
        toolbar: [
          { items: ['BGColor'] },
          { items: ['JustifyLeft'] },
          { items: ['BulletedList', '-'] },
          { items: ['Link'] },
          { items: ['Image'] },
          { items: ['Table'] },
          { items: ['-'] },
          '/',
          { items: ['Format'] },
          { items: ['Font'] },
          { items: ['FontSize', '-'] },
          { items: ['Bold'] },
          { items: ['Italic'] },
          { items: ['Underline'] },
        ],
      };
      $scope.subject = 'Happy Birthday!';
      $scope.content = '';
    };

    init();

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
