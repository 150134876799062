import template from './lmiTable.html';
import './style.scss';

export default {
  template,
  bindings: {
    lmiPremiumObj: '<',
    goalType: '<',
    brokerEventId: '<',
    mortgageObj: '=',
    isSaveToFundingCalcDisabled: '=',
    selectedLenderObj: '=',
    selectedLenderShow: '=',
  },
  controller: 'LmiTableCtrl',
  controllerAs: 'vm',
};
