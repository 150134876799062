import template from './addressField.html';
import AddressFieldCtrl from './addressFieldCtrl';

export default {
  template,
  bindings: {
    addressModel: '=',
  },
  controller: AddressFieldCtrl,
  controllerAs: 'vm',
};
