import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';

angular
  .module('app')
  .controller('ReferralCtrl', function ReferralCtrl(
    $scope,
    $http,
    configService,
    $filter,
    referralsService,
    $timeout,
    $uibModal,
    moment,
    NgTableParams,
    corporateService,
    userService,
    $state,
    onboardingService,
    $anchorScroll,
    $location,
    $interval,
    $q,
    $window,
    referralAgreementService,
    dataService,
    DEMO_VIDEO
  ) {
    // init Function

    let familyId = 0;
    $scope.referrerModel = {};
    $scope.referrerTable = {};
    $scope.referrerModel.isCorporateUser = false;
    $scope.editMode = {};
    $scope.referrers = {};
    $scope.referrers.isVisible = true;
    $scope.referrers.isActive = false;
    $scope.referrers.isReferrers = true;
    $scope.referrers.isActiveAdviser = false;
    $scope.referrers.dateCreated = moment().format('DD MMM YYYY');
    $scope.referrers.createDateFrom = moment().format('DD MMM YYYY');
    $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
    $scope.referrers.agreementCreated = null;
    $scope.referrers.createDateFromAgreement = null;
    $scope.referrers.createDateToAgreement = null;
    $scope.referrers.searchReferral = '';
    $scope.referrers.adviserActive = false;
    $scope.referrers.businessUnitList = [];
    $scope.referrers.businessUnitId = 0;
    $window.sessionStorage.removeItem('currentTab');
    $scope.referralFilters = {};

    // Open Model For Demo Video
    $scope.openModalForDemoVideo = size => {
      $uibModal.open({
        templateUrl: '/assets/views/partials/demo_video_modal.html',
        controller: 'DemoVideoModalCtrl',
        resolve: {
          videoId: () => DEMO_VIDEO.REFERRAL_AGREEMENT_REQUEST.Url,
        },
        size,
        windowClass: 'demo-video-modal-window-class',
      });
    };

    const getLastFilter = filters => {
      if (_.size(filters.DateFrom) > 0) {
        $scope.referrers.createDateFrom = moment
          .parseZone(filters.DateFrom)
          .format('DD MMM YYYY');
      } else {
        $scope.referrers.createDateFrom = null;
        $scope.referrers.dateCreated = null;
      }
      if (_.size(filters.DateTo) > 0) {
        $scope.referrers.createDateTo = moment
          .parseZone(filters.DateTo)
          .format('DD MMM YYYY');
        if ($scope.referrers.createDateFrom === $scope.referrers.createDateTo) {
          $scope.referrers.dateCreated = $scope.referrers.createDateFrom;
        } else {
          $scope.referrers.dateCreated = `${
            $scope.referrers.createDateFrom
          } - ${$scope.referrers.createDateTo}`;
        }
      } else {
        $scope.referrers.createDateTo = null;
        $scope.referrers.dateCreated = null;
      }

      if (_.size(filters.DateCreatedFrom) > 0) {
        $scope.referrers.createDateFromAgreement = moment
          .parseZone(filters.DateCreatedFrom)
          .format('DD MMM YYYY');
      } else {
        $scope.referrers.createDateFromAgreement = null;
        $scope.referrers.agreementCreated = null;
      }
      if (_.size(filters.DateCreatedTo) > 0) {
        $scope.referrers.createDateToAgreement = moment
          .parseZone(filters.DateCreatedTo)
          .format('DD MMM YYYY');
        if (
          $scope.referrers.createDateFromAgreement ===
          $scope.referrers.createDateTo
        ) {
          $scope.referrers.agreementCreated =
            $scope.referrers.createDateFromAgreement;
        } else {
          $scope.referrers.agreementCreated = `${
            $scope.referrers.createDateFromAgreement
          } - ${$scope.referrers.createDateToAgreement}`;
        }
      } else {
        $scope.referrers.createDateToAgreement = null;
        $scope.referrers.agreementCreated = null;
      }

      if (typeof filters.AgreementStatus !== 'undefined') {
        $scope.referralFilters.status = filters.AgreementStatus;
      }

      if (typeof filters.Processor !== 'undefined') {
        $scope.referralFilters.processor = filters.Processor;
      }

      if (typeof filters.AdviserOrgID !== 'undefined') {
        $scope.referralFilters.adviserOrg = filters.AdviserOrgID;
      }

      if (typeof filters.AdviserID !== 'undefined') {
        $scope.referralFilters.adviser = filters.AdviserID;
      }

      if (filters.BusinessUnitID) {
        $scope.referralFilters.businessUnitId = filters.BusinessUnitID;
        $scope.referrers.businessUnitId = filters.BusinessUnitID;
      }

      if (filters.ReferrerOrgID && filters.SearchRefOrgName) {
        $scope.referralFilters.referrerOrg = filters.ReferrerOrgID;
        $scope.referrers.referrerOrg = filters.ReferrerOrgID;
        $scope.referrers.referrerOrgName = filters.SearchRefOrgName || '';
        $scope.referrers.referrerOrgObj = {
          originalObject: {
            ReferrerOrgId: filters.ReferrerOrgID,
            ReferrerOrgName: $scope.referrers.referrerOrgName,
          },
        };
      } else {
        $scope.referrers.referrerOrg = 0;
        $scope.referrers.referrerOrgName = '';
      }

      if (filters.ReferrerID && filters.SearchRefName) {
        $scope.referralFilters.referrer = filters.ReferrerID;
        $scope.referrers.referrer = filters.ReferrerID;
        $scope.referrers.referrerName = filters.SearchRefName || '';
        $scope.referrers.referrerObj = {
          originalObject: {
            Referrer_FamilyId: filters.ReferrerID,
            Referrer_Name: $scope.referrers.referrerName,
          },
        };
      } else {
        $scope.referrers.referrer = 0;
        $scope.referrers.referrerName = '';
      }

      if (typeof filters.AgreementIsActive !== 'undefined') {
        $scope.referrers.isActive = filters.AgreementIsActive === 1;
      } else {
        $scope.referrers.isActive = null;
      }

      if (typeof filters.AdviserStatus !== 'undefined') {
        $scope.referrers.adviserActive = filters.AdviserStatus === 1;
      } else {
        $scope.referrers.adviserActive = null;
      }

      $scope.init();
    };
    const getPreferenceValidation = preferences => {
      const {
        AgreementStatus,
        Processor,
        DateFrom,
        DateTo,
        DateCreatedFrom,
        DateCreatedTo,
        AgreementIsActive,
        AdviserOrgID,
        AdviserID,
        AdviserStatus,
        ReferrerOrgID,
        ReferrerID,
        BusinessUnitID,
      } = preferences;
      $scope.isPreferenceSetToAll =
        !AgreementStatus &&
        !Processor &&
        !DateFrom &&
        !DateTo &&
        !DateCreatedFrom &&
        !DateCreatedTo &&
        _.isNil(AgreementIsActive) &&
        !AdviserOrgID &&
        !AdviserID &&
        _.isNil(AdviserStatus) &&
        !ReferrerOrgID &&
        !ReferrerID &&
        !BusinessUnitID;
    };
    const getAdviserAgreementSearchOptions = () => {
      referralsService.getAdviserAgreementSearchOptions().then(response => {
        if (response.data && response.data.length) {
          getLastFilter(response.data[0]);
          getPreferenceValidation(response.data[0]);
        }
      });
    };
    const getUserInfo = () => {
      userService.GetUserInfo().then(response => {
        familyId = response.data.FamilyId;
        if (response.data.AccessType === 5) {
          $scope.referrerModel.isCorporateUser = true;
        }
        getAdviserAgreementSearchOptions();
      });
    };
    getUserInfo();

    $scope.referrerOrgList = [];
    $scope.referrerList = [];
    $scope.referrerModel.isReferrersLoading = false;
    $scope.searchAPIReferrerOrg = searchText => {
      if (searchText) {
        $scope.$broadcast('angucomplete-alt:clearInput', 'referrer');
        return referralsService
          .getReferrerOrgList(searchText)
          .then(response => {
            const { data } = response;
            if (!data || !data.length) return [];
            const orgList = _.uniqBy(data, i =>
              [i.ReferrerOrgId, i.ReferrerOrgName].join()
            );
            $scope.referrerOrgList = orgList.filter(row => {
              return row.ReferrerOrgName && row.ReferrerOrgId;
            });
            return $scope.referrerOrgList;
          });
      }
    };
    $scope.searchAPIReferrer = searchText => {
      const isContainReferrerOrg =
        $scope.referrers.referrerOrgObj &&
        $scope.referrers.referrerOrgObj.originalObject;
      if (searchText && isContainReferrerOrg) {
        const referrerOrgId =
          $scope.referrers.referrerOrgObj.originalObject.ReferrerOrgId;
        return referralsService
          .getReferrerOrgMembership(referrerOrgId)
          .then(response => {
            const { data } = response;
            if (!data || !data.length) return [];
            const filteredList = data.filter(
              ref =>
                ref.ReferrerFullName &&
                ref.Referrer_FamilyId &&
                ref.ReferrerFullName.toLowerCase().includes(
                  searchText.toLowerCase()
                )
            );
            if (!filteredList || !filteredList.length) return [];
            $scope.referrerList = filteredList.map(ref => {
              ref.Referrer_Name = ref.ReferrerFullName;
              return ref;
            });
            $scope.referrers.referrer = 0;
            return $scope.referrerList;
          });
      } else if (searchText) {
        return referralsService
          .getReferralReferrerList(searchText)
          .then(response => {
            const referrerList = response.data;
            if (referrerList && referrerList.length) {
              const filteredList = referrerList.filter(data => {
                return data.Referrer_Name && data.Referrer_FamilyId;
              });
              $scope.referrerList = _.uniqBy(filteredList, i =>
                [i.Referrer_FamilyId, i.Referrer_Name].join()
              );
            }
            return $scope.referrerList;
          });
      }
    };

    $scope.referrers.adviserOrg = {};

    $scope.splitReferrers = function(referrers) {
      let referrerLists = [];
      if (referrers) {
        referrerLists = referrers.split(',');
      }
      return referrerLists;
    };

    $scope.isSetting = false;
    $scope.changeView = function() {
      if ($scope.isSetting) {
        $('#carousel').slick('slickPrev');
      } else {
        $('#carousel').slick('slickNext');
      }
      $scope.isSetting = !$scope.isSetting;
    };

    $scope.referralDropdown = ['Referral'];

    $scope.downloadDocuSign = function(agreementId) {
      corporateService.getAgreementDocument(agreementId).then(response => {
        const pdfAsDataUri = `data:application/pdf;base64,${
          response.data.DocumentContent
        }`;
        $window.open(pdfAsDataUri, '_blank');
      });
    };
    $scope.sendDocuSign = function(agreementId) {
      $scope.isDownloadingAgreement = true;
      corporateService
        .setSendDocuSignAgreementToRecipients(agreementId)
        .then(response => {
          if (response.data > 0) {
            $scope.isDownloadingAgreement = false;
            swal('Success', 'Docusign has been sent through email.', 'success');
          }
        });
    };

    $scope.viewReferrer = function(data) {
      data.showReferrers = !data.showReferrers;
    };

    $scope.referrerModel.loadingReferrals = false;

    const getReferralAgreement = (page, count, sortByColumnName, sortOrder) => {
      const defer = $q.defer();
      const agreementStatusId = $scope.referrers.status
        ? $scope.referrers.status
        : '';
      const processorId = $scope.referrers.processor || 0;
      const adviserOrgId = $scope.referrers.adviserOrg || 0;
      const adviserId = $scope.referrers.adviser || 0;
      const referrerOrgId = $scope.referrers.referrerOrg || 0;
      const referrerId = $scope.referrers.referrer || 0;
      const dateFrom = $scope.referrers.createDateFrom
        ? $filter('date')(new Date($scope.referrers.createDateFrom), 'yyyy-M-d')
        : null;
      const dateTo = $scope.referrers.createDateTo
        ? $filter('date')(new Date($scope.referrers.createDateTo), 'yyyy-M-d')
        : null;
      const createdDateFrom = $scope.referrers.createDateFromAgreement
        ? $filter('date')(
            new Date($scope.referrers.createDateFromAgreement),
            'yyyy-M-d'
          )
        : null;
      const createdDateTo = $scope.referrers.createDateToAgreement
        ? $filter('date')(
            new Date($scope.referrers.createDateToAgreement),
            'yyyy-M-d'
          )
        : null;
      const active = $scope.referrers.isActive ? 1 : 0;
      const isActive = $scope.referrers.isActive ? active : null;
      const adviserActive = $scope.referrers.adviserActive ? 1 : 0;
      const isAdviserActive = $scope.referrers.adviserActive
        ? adviserActive
        : null;
      const businessUnitId = $scope.referrers.businessUnitId || 0;
      const showAllAdviser = 1;
      const referrerOrgName = $scope.referrers.referrerOrgName || '';
      const referrerName = $scope.referrers.referrerName || '';

      referralsService
        .getReferralList(
          page,
          count,
          $scope.referrers.searchReferral,
          agreementStatusId,
          processorId,
          dateFrom,
          dateTo,
          adviserOrgId,
          adviserId,
          referrerOrgId,
          referrerId,
          isActive,
          showAllAdviser,
          isAdviserActive,
          createdDateFrom,
          createdDateTo,
          businessUnitId,
          referrerOrgName,
          referrerName,
          sortByColumnName,
          sortOrder
        )
        .then(response => {
          const { data: referrals } = response;
          if (!referrals || !referrals.ReferrerList) defer.resolve([]);

          referrals.ReferrerList.map(data => {
            data.referrerLists = [];
            data.showReferrers = true;

            data.AdviserOrg =
              typeof data.AdviserOrg === 'string'
                ? data.AdviserOrg.trim()
                : data.AdviserOrg;
            data.Adviser =
              typeof data.Adviser === 'string'
                ? data.Adviser.trim()
                : data.Adviser;
            data.ReferrerOrg =
              typeof data.ReferrerOrg === 'string'
                ? data.ReferrerOrg.trim()
                : data.ReferrerOrg;

            if (data.Referrers) {
              const splittedReferrers = data.Referrers.split(',');
              data.referrerLists = splittedReferrers.filter(row => !!row);
              if (data.referrerLists.length > 1) {
                data.showReferrers = false;
              }
            }
            return data;
          });
          defer.resolve(response.data);
          if ($scope.initialLoading) {
            $scope.initialLoading = false;
          }
        });
      return defer.promise;
    };
    const getTableList = () => {
      $scope.tableParams = new NgTableParams(
        {
          page: 1,
          count: 10, // count per page
          sorting: {
            AgreementId: 'desc',
          },
        },
        {
          counts: [],
          total: 0, // length of data
          getData($defer, params) {
            $scope.referrerModel.loadingReferrals = true;
            const orderBy = params.sorting();
            const sortByColumnName = orderBy
              ? Object.keys(orderBy)[0]
              : 'AgreementId';
            const sortOrder = orderBy
              ? orderBy[Object.keys(orderBy)[0]]
              : 'DESC';
            const sortOrderUpper = sortOrder && sortOrder.toUpperCase();
            getReferralAgreement(
              params.page(),
              params.count(),
              sortByColumnName,
              sortOrderUpper
            ).then(response => {
              const dataCount = response.Count;
              $scope.referralData = response.ReferrerList;
              params.total(dataCount);
              $defer.resolve($scope.referralData);
              $scope.referralDataCount = dataCount;
              $scope.referrerModel.loadingReferrals = false;
              $location.hash('top-navbar');
              $anchorScroll();
            });
          },
        }
      );
    };
    $scope.currentPage = 1;

    const parseParamValueToId = () => {
      $scope.referrers.processor = 0;
      $scope.referrers.adviserOrg = 0;
      $scope.referrers.adviser = 0;
      $scope.referrers.referrerOrg = 0;
      $scope.referrers.referrer = 0;
      $scope.referrers.referrerName = '';
      $scope.referrers.referrerOrgName = '';
      if (
        $scope.referrers.processorObj &&
        $scope.referrers.processorObj.originalObject
      ) {
        $scope.referrers.processor =
          $scope.referrers.processorObj.originalObject.FamilyId;
      }
      if (
        $scope.referrers.adviserOrgObj &&
        $scope.referrers.adviserOrgObj.originalObject
      ) {
        $scope.referrers.adviserOrg =
          $scope.referrers.adviserOrgObj.originalObject.AdviserOrganizationId;
      }
      if (
        $scope.referrers.adviserObj &&
        $scope.referrers.adviserObj.originalObject
      ) {
        $scope.referrers.adviser =
          $scope.referrers.adviserObj.originalObject.FamilyId;
      }
      if (
        $scope.referrers.referrerOrgObj &&
        $scope.referrers.referrerOrgObj.originalObject
      ) {
        $scope.referrers.referrerOrg =
          $scope.referrers.referrerOrgObj.originalObject.ReferrerOrgId;
        $scope.referrers.referrerOrgName =
          $scope.referrers.referrerOrgObj.originalObject.ReferrerOrgName;
      }
      if (
        $scope.referrers.referrerObj &&
        $scope.referrers.referrerObj.originalObject
      ) {
        $scope.referrers.referrer =
          $scope.referrers.referrerObj.originalObject.Referrer_FamilyId;
        $scope.referrers.referrerName =
          $scope.referrers.referrerObj.originalObject.Referrer_Name;
      }
    };

    $scope.changeSelection = (currentPage = 1) => {
      $scope.isPreferenceSetToAll = false;
      parseParamValueToId();
      if ($scope.tableParams) {
        $scope.tableParams.page(currentPage);
        $scope.tableParams.reload();
      } else {
        getTableList();
      }
    };

    $scope.isAgreementActive = function(value) {
      return value === 1;
    };

    $scope.referrers.checkbox = {};
    $scope.referrers.selectedReferral = [];
    $scope.setChecked = function(id) {
      $scope.referrers.checkbox[id] =
        $scope.referrers.checkbox[id] === 'undefined'
          ? true
          : !$scope.referrers.checkbox[id];
      if ($scope.referrers.checkbox[id]) {
        $scope.referrers.selectedReferral.push(id);
      } else {
        const index = $scope.referrers.selectedReferral.indexOf(id);
        $scope.referrers.selectedReferral.splice(index, 1);
      }
    };

    $scope.adviserOrganisationList = [
      {
        AdviserOrganizationId: 0,
        AdviserOrganizatioName: 'All',
        AddressId: 0,
        Phone: '',
        Fax: '',
      },
    ];
    $scope.referrers.adviserOrg = 0;
    const adviserOrganizationListGet = () => {
      onboardingService.getRecruitmentContact(familyId, 0).then(response => {
        const orgId = response.data[0].AdviserOrganizationId;
        corporateService
          .adviserOrganizationListGet()
          .then(adviserOrganizationListGetResponse => {
            $scope.adviserOrganisationList = _.filter(
              adviserOrganizationListGetResponse.data,
              data => {
                return data.AdviserOrganizatioName !== '';
              }
            );
            if ($scope.referrerModel.isCorporateUser) {
              $scope.referrers.adviserOrg =
                $scope.referralFilters.adviserOrg || 0;
            } else {
              $scope.referrers.adviserOrg = orgId;
            }
            $scope.referrers.adviserOrgObj = {
              originalObject: $scope.adviserOrganisationList.find(
                org => org.AdviserOrganizationId === $scope.referrers.adviserOrg
              ),
            };
            $scope.getAdvisersDropdown();
          });
      });
    };

    $scope.adviserList = [
      {
        FamilyId: 0,
        FullName: 'All',
      },
    ];
    $scope.referrers.adviser = 0;
    $scope.getAdvisersDropdown = () => {
      const searchOrg = $scope.referrerModel.isCorporateUser ? 0 : 1;
      corporateService
        .getAdvisersDropdown(familyId, searchOrg)
        .then(response => {
          const { data } = response;
          if (!data || !data.length) return;
          $scope.adviserList = data.filter(adviser => !!adviser.FullName);
          const defaultFamilyId = $scope.referrerModel.isCorporateUser
            ? 0
            : familyId;
          $scope.referrers.adviser =
            $scope.referralFilters.adviser || defaultFamilyId;
          $scope.referrers.adviserObj = {
            originalObject: $scope.adviserList.find(
              adviser => adviser.FamilyId === $scope.referrers.adviser
            ),
          };
          $scope.referrerModel.isAdvisersLoading = false;
          if (!$scope.isPreferenceSetToAll) {
            getTableList();
          }
        });
    };

    const getAgreementStatusLookup = () => {
      $scope.agreementStatusList = [];
      corporateService.getAgreementStatusLookup().then(response => {
        const data = {
          AgreementStatusId: 0,
          AgreementStatus: 'All Status',
        };
        response.data.unshift(data);
        $scope.agreementStatusList = response.data;

        $scope.referrers.status =
          typeof $scope.referralFilters.status !== 'undefined' &&
          $scope.referralFilters.status !== ''
            ? $scope.referralFilters.status
            : 0;
      });
    };

    const getProcessor = () => {
      $scope.processorList = [];
      corporateService.getAdminUsers().then(response => {
        $scope.processorList = response.data;
        $scope.referrers.processor = $scope.referralFilters.processor || 0;
        $scope.referrers.processorObj = {
          originalObject: $scope.processorList.find(
            processor => processor.FamilyId === $scope.referrers.processor
          ),
        };
        $scope.referrerModel.isProcessorLoading = false;
      });
    };

    $scope.viewDetails = function(agreementId) {
      referralsService.getReferrerOrgSummary(agreementId).then(response => {
        const typeId = response.data.AgreementTYpeID === 2 ? 4 : 0;
        const orgTypeId =
          response.data.AgreementTYpeID === 3
            ? response.data.OrganisationTypeId
            : typeId;
        const agreementTypeId = response.data.AgreementTYpeID;
        $state.go('app.newReferral', {
          referralType: orgTypeId,
          agreementId,
          agreementTypeId,
          requestId: 1,
        });
      });
    };

    $scope.removeTimeZone = function(date) {
      let dateConverted = '';
      if (typeof date !== 'undefined') {
        dateConverted = date.substring(0, date.indexOf('T')); // Remove string from Time to timezone
      } else {
        dateConverted = date;
      }
      return dateConverted;
    };

    const getBusinessUnit = () => {
      corporateService.businessUnitGet().then(response => {
        if (_.size(response.data)) {
          const obj = {
            BusinessUnitID: 0,
            BusinessUnitName: 'All',
          };
          response.data.unshift(obj);
          $scope.referrers.businessUnitList = response.data;
        }
      });
    };

    $scope.init = function() {
      $scope.initialLoading = true;
      getAgreementStatusLookup();
      getProcessor();
      adviserOrganizationListGet();
      getBusinessUnit();
      /* initialize access matrix for referal */
      $scope.editMode = {};
      referralAgreementService.getCreateAgreementAccess().then(response => {
        $scope.editMode.allReadOnly = !response;
      });
    };

    // Get And Set Date
    $scope.getSetDate = function(type) {
      let startOfWeek = '';
      let endOfWeek = '';
      let startOfMonth = '';
      let endOfMonth = '';
      if (type === 'Today') {
        $scope.referrers.dateCreated = moment().format('DD MMM YYYY');
        $scope.referrers.createDateFrom = moment().format('DD MMM YYYY');
        $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
      }
      if (type === 'Yesterday') {
        const Yesterday = moment().subtract(1, 'day');
        $scope.referrers.dateCreated = moment(Yesterday).format('DD MMM YYYY');
        $scope.referrers.createDateFrom = moment(Yesterday).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(Yesterday).format('DD MMM YYYY');
      }

      if (type === 'This Week') {
        startOfWeek = moment()
          .startOf('isoWeek')
          .toDate();
        endOfWeek = moment()
          .endOf('isoWeek')
          .toDate();
        $scope.referrers.dateCreated = `${moment(startOfWeek).format(
          'DD MMM YYYY'
        )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFrom = moment(startOfWeek).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(endOfWeek).format('DD MMM YYYY');
      }

      if (type === 'Last Week') {
        startOfWeek = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .toDate();
        endOfWeek = moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek')
          .toDate();
        $scope.referrers.dateCreated = `${moment(startOfWeek).format(
          'DD MMM YYYY'
        )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFrom = moment(startOfWeek).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(endOfWeek).format('DD MMM YYYY');
      }
      if (type === 'This Month') {
        startOfMonth = moment()
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .endOf('month')
          .toDate();
        $scope.referrers.dateCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFrom = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }

      if (type === 'Last Month') {
        startOfMonth = moment()
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .subtract(1, 'months')
          .endOf('month')
          .toDate();
        $scope.referrers.dateCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFrom = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'Last 3 Months') {
        startOfMonth = moment()
          .subtract(3, 'months')
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .subtract(1, 'months')
          .endOf('month')
          .toDate();
        $scope.referrers.dateCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFrom = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'On') {
        $scope.referrers.dateCreated = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateFrom = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'Before') {
        $scope.referrers.dateCreated = `Before ${moment(
          $scope.beforeDate
        ).format('DD MMM YYYY')}`;
        const beforeDay = moment($scope.beforeDate).subtract(1, 'day');
        $scope.referrers.createDateFrom = moment('1 Jan 2015').format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment(beforeDay).format('DD MMM YYYY');
      }
      if (type === 'After') {
        $scope.referrers.dateCreated = `After ${moment($scope.afterDate).format(
          'DD MMM YYYY'
        )}`;
        const afterDay = moment($scope.afterDate).add(1, 'day');
        $scope.referrers.createDateFrom = moment(afterDay).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateTo = moment().format('DD MMM YYYY');
      }
      if (type === 'Range') {
        $scope.referrers.dateCreated = `${moment(
          $scope.referrers.fromDate
        ).format('DD MMM YYYY')} - ${moment($scope.referrers.toDate).format(
          'DD MMM YYYY'
        )}`;
        $scope.referrers.createDateFrom = moment(
          $scope.referrers.fromDate
        ).format('DD MMM YYYY');
        $scope.referrers.createDateTo = moment($scope.referrers.toDate).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'All') {
        $scope.referrers.dateCreated = null;
        $scope.referrers.createDateFrom = null;
        $scope.referrers.createDateTo = null;
      }
    };

    $scope.getSetDateAgreement = function(type) {
      let startOfWeek;
      let endOfWeek;
      let startOfMonth;
      let endOfMonth;
      if (type === 'Today') {
        $scope.referrers.agreementCreated = moment().format('DD MMM YYYY');
        $scope.referrers.createDateFromAgreement = moment().format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment().format('DD MMM YYYY');
      }
      if (type === 'Yesterday') {
        const Yesterday = moment().subtract(1, 'day');
        $scope.referrers.agreementCreated = moment(Yesterday).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateFromAgreement = moment(Yesterday).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(Yesterday).format(
          'DD MMM YYYY'
        );
      }

      if (type === 'This Week') {
        startOfWeek = moment()
          .startOf('isoWeek')
          .toDate();
        endOfWeek = moment()
          .endOf('isoWeek')
          .toDate();
        $scope.referrers.agreementCreated = `${moment(startOfWeek).format(
          'DD MMM YYYY'
        )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(startOfWeek).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(endOfWeek).format(
          'DD MMM YYYY'
        );
      }

      if (type === 'Last Week') {
        startOfWeek = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .toDate();
        endOfWeek = moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek')
          .toDate();
        $scope.referrers.agreementCreated = `${moment(startOfWeek).format(
          'DD MMM YYYY'
        )} - ${moment(endOfWeek).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(startOfWeek).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(endOfWeek).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'This Month') {
        startOfMonth = moment()
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .endOf('month')
          .toDate();
        $scope.referrers.agreementCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }

      if (type === 'Last Month') {
        startOfMonth = moment()
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .subtract(1, 'months')
          .endOf('month')
          .toDate();
        $scope.referrers.agreementCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'Last 3 Months') {
        startOfMonth = moment()
          .subtract(3, 'months')
          .startOf('month')
          .toDate();
        endOfMonth = moment()
          .subtract(1, 'months')
          .endOf('month')
          .toDate();
        $scope.referrers.agreementCreated = `${moment(startOfMonth).format(
          'DD MMM YYYY'
        )} - ${moment(endOfMonth).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(startOfMonth).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(endOfMonth).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'On') {
        $scope.referrers.agreementCreated = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateFromAgreement = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment($scope.onDate).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'Before') {
        $scope.referrers.agreementCreated = `Before ${moment(
          $scope.beforeDate
        ).format('DD MMM YYYY')}`;
        const beforeDay = moment($scope.beforeDate).subtract(1, 'day');
        $scope.referrers.createDateFromAgreement = moment('1 Jan 2015').format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment(beforeDay).format(
          'DD MMM YYYY'
        );
      }
      if (type === 'After') {
        $scope.referrers.agreementCreated = `After ${moment(
          $scope.afterDate
        ).format('DD MMM YYYY')}`;
        const afterDay = moment($scope.afterDate).add(1, 'day');
        $scope.referrers.createDateFromAgreement = moment(afterDay).format(
          'DD MMM YYYY'
        );
        $scope.referrers.createDateToAgreement = moment().format('DD MMM YYYY');
      }
      if (type === 'Range') {
        $scope.referrers.agreementCreated = `${moment(
          $scope.referrers.fromDateAgreement
        ).format('DD MMM YYYY')} - ${moment(
          $scope.referrers.toDateAgreement
        ).format('DD MMM YYYY')}`;
        $scope.referrers.createDateFromAgreement = moment(
          $scope.referrers.fromDateAgreement
        ).format('DD MMM YYYY');
        $scope.referrers.createDateToAgreement = moment(
          $scope.referrers.toDateAgreement
        ).format('DD MMM YYYY');
      }
      if (type === 'All') {
        $scope.referrers.agreementCreated = null;
        $scope.referrers.createDateFromAgreement = null;
        $scope.referrers.createDateToAgreement = null;
      }
    };

    // Show Filter In Mobile
    $scope.showfilter = function() {
      $scope.isFilterDiv = true;
    };

    // Hide Filter In Mobile
    $scope.hidefilter = function() {
      $scope.isFilterDiv = false;
    };

    // Open Model For New Add Referral
    //-----------------------------------------------------------------------------
    const currentState = {
      state: 'app.referral',
      params: {},
    };
    $scope.addReferralAgreement = referralType => {
      $window.sessionStorage.removeItem('currentTab');
      referralAgreementService.launchNewReferralModal(
        referralType,
        currentState,
        $scope
      );
    };

    // Open Model For Add Referral
    //-----------------------------------------------------------------------------
    $scope.openModelForAddReferral = function(size) {
      $uibModal.open({
        templateUrl: '/assets/views/referrals/partials/add_referral_modal.html',
        controller: 'openModelForAddReferralCtrl',
        size,
        windowClass: 'referral_type',
        resolve: {},
      });
    };

    // equalize code provided by Edward
    const equalize = target => {
      // manifest
      // manifest values
      const columnHeights = [];
      const columns = $window.document.querySelectorAll(target);

      // loop through targets and fetch heights
      for (let i = 0; i < columns.length; i++) {
        columns[i].style.removeProperty('height');
        // fetch column heights
        const colHeight = columns[i].offsetHeight;
        // append values into array
        columnHeights[i] = colHeight;
      }
      // sort heights from tallest to shortest
      columnHeights.sort((a, b) => {
        return a > b ? -1 : 1;
      });

      // set a hard code height to equalize
      _.forEach(columns, el => {
        el.style.setProperty('height', `${columnHeights[0]}px`);
      });
    };

    $scope.reevaluateEqualize = function() {
      equalize('[data-equalize]');
    };
    const equalizeCaller = () => {
      equalize('[data-equalize]');
    };
    // setTimeout(equalizeCaller,3000);
    $interval(equalizeCaller, 100);
  });
