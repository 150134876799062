import angular from 'angular';

export default class PrettyImgCtrl {
  constructor($element) {
    'ngInject';

    this.$element = $element;
    this.onLoadSrcFailed = this.onLoadSrcFailed.bind(this);
  }

  $onInit() {
    this.imgElement = angular.element(this.$element.children()[0]);
    this.imgElement.on('error', this.onLoadSrcFailed);
  }

  $onChanges(changes) {
    if (changes.src) {
      this.isFailure = !this.src;
    }
  }

  $onDestroy() {
    this.imgElement.off('error', this.onLoadSrcFailed);
  }

  onLoadSrcFailed() {
    const isOriginalSrc = this.imgElement.attr('src') === this.src;
    this.isFailure = !isOriginalSrc || !this.fallbackSrc;
    if (isOriginalSrc && this.fallbackSrc) {
      this.imgElement.attr('src', this.fallbackSrc);
    }
  }
}
