import swal from 'sweetalert';
import { forEach } from 'lodash';

export default class ProviderUploadDocumentsCtrl {
  constructor(
    uiService,
    FileUploader,
    configService,
    $timeout,
    toaster,
    $window
  ) {
    'ngInject';

    this.uiService = uiService;
    this.FileUploader = FileUploader;
    this.configService = configService;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$window = $window;
    this.showAllCategory = false;
    this.isFavourites = false;
    this.categories = [];
    this.documentDetails = [];
    this.category = '';
  }

  toggleQueue() {
    this.showQueue = !this.showQueue;
  }

  removeUnreadyFiles(files) {
    forEach(files, fileItem => {
      // will only remove cancelled and failed files
      if (fileItem.isCancel || fileItem.isError) {
        this.uploader.removeFromQueue(fileItem);
      }
    });
  }

  $onInit() {
    this.showQueue = true;
    this.default = {
      extensions: ['pdf', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'docx', 'doc'],
      autoUpload: true,
      url: `${this.configService.resource}/contacts/DocumentUpload`,
    };

    this.inputStyle = {
      height: this.height || '100%',
      width: this.width || '100%',
    };

    this.uploader = new this.FileUploader({
      autoUpload: this.autoUpload || this.default.autoUpload,
      url: this.url || this.default.url,
      headers: { Authorization: this.configService.token },
    });

    this.uploader.uploadedItems = [];

    this.uploader.onBeforeUploadItem = item => {
      const params = [];
      if (!this.noUrlParams) {
        const { familyId, uploadType, documentId, type } = this;
        const args = { familyId, uploadType, documentId, type };
        Object.keys(args).forEach(key => {
          params.push(`${key}=${args[key] || 0}`);
        });
        item.url += `?${params.join('&')}`;
      }
    };

    this.uploader.filters.push({
      name: 'extensions',
      fn: item => {
        const jwt = this.$window.localStorage.getItem('myCRM_jwt');
        const didTokenChange =
          this.uploader.headers &&
          jwt &&
          this.uploader.headers.Authorization !== jwt;
        if (didTokenChange) {
          this.uploader.headers.Authorization = jwt;
        }
        const maxFileSize = 10485760;
        if (item.size > maxFileSize) {
          this.toaster.pop(
            'warning',
            'File is too large',
            "The file could not be added because it's too large. The size of the file cannot exceed 10MB."
          );
          return false;
        }
        const ext = this.extensions
          ? this.extensions.join('|')
          : this.default.extensions.join('|');
        const regEx = new RegExp(ext, 'gi');

        if (!item.name.match(regEx)) {
          if (this.invalidFileInfo) {
            this.toaster.pop(
              `error`,
              this.invalidFileInfo.title,
              this.invalidFileInfo.description
            );
          } else {
            const validFiles = this.extensions
              ? this.extensions.join(' / ')
              : this.default.extensions.join(' / ');
            this.toaster.pop(
              `error`,
              `Unable to upload ${item.name}`,
              `Please try uploading ${validFiles} files.`
            );
          }
        } else {
          return true;
        }
      },
    });

    this.uploader.onAfterAddingAll = () => {
      this.removeUnreadyFiles(this.uploader.getNotUploadedItems());
    };

    this.uploader.onCompleteItem = (fileItem, response, status) => {
      if (status === 200) {
        this.uploader.uploadedItems.push({
          ...response[0],
          isFavourite: false,
          rubikCategoryName: this.category,
        });
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.onCompleteAll) {
        this.onCompleteAll({ files: this.uploader.uploadedItems });
      }

      this.$timeout(() => {
        this.uploader.clearQueue();
        this.showAllCategory = true;
      }, 1000);
    };
    this.getAllCategoriesDocs();
  }
  toggleFavourites() {
    this.isFavourites = !this.isFavourites;
  }

  favourites(docs, idx) {
    this.toggleFavourites();
    this.uploader.uploadedItems[idx].isFavourite = this.isFavourites;
  }

  getAllCategoriesDocs() {
    this.modalData.service
      .getProviderDocCategory(this.modalData.providerId)
      .then(response => {
        if (response && response.length) {
          this.categories = response;
          this.category = this.categories[0].categoryId;
        }
      });
  }

  removeItems(documentId) {
    const filteredAry = this.uploader.uploadedItems.filter(e => {
      return e.DocumentId !== documentId;
    });
    this.uploader.uploadedItems = filteredAry;
  }

  saveDocuments() {
    const formParams = {
      providerId: this.modalData.providerId,
      providerDocument: this.filterDocumentsKeys(),
    };
    this.modalData.service.setProviderDocuments(formParams).then(response => {
      if (response.succeeded) {
        this.saveSuccess();
      }
    });
  }
  filterDocumentsKeys() {
    this.uploader.uploadedItems.forEach(object => {
      this.documentDetails.push({
        title: object.Name,
        fileName: object.Name,
        documentId: object.DocumentId,
        rubikCategoryName: object.rubikCategoryName,
        isFavourite: object.isFavourite,
        iSMergedProviderToolsSection: true,
      });
    });
    return this.documentDetails;
  }
  saveSuccess() {
    swal(
      {
        title: 'Success',
        text: `Files Successfully Save!`,
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        closeOnConfirm: true,
      },
      confirm => {
        if (confirm) {
          this.modalInstance.close();
        }
      }
    );
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}
