import template from './customDropdown.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    id: '@',
    options: '<',
    onChange: '&',
  },
};
