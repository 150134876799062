import { isFunction, flatMapDeep } from 'lodash';

class CustomCrmMultiSelectCtrl {
  constructor($window) {
    'ngInject';

    this.$window = $window;
  }

  $onInit() {
    this.isOpen = false;

    if (typeof this.isSelectAllDefault === 'undefined') {
      this.isSelectAllDefault = true;
    }

    if (typeof this.maxLabels === 'undefined') {
      this.maxLabels = 1;
    }

    if (this.groupProperty && !this.groupLabel) {
      this.$window.mydebug.log(
        'Developer warning: groupLabel prop is missing on grouped version.'
      );
    }
  }

  convertInputIntoGroup(inputModel) {
    const groupByInputModel = {};

    inputModel.forEach(obj => {
      if (!groupByInputModel[obj[this.groupProperty]]) {
        groupByInputModel[obj[this.groupProperty]] = [];
      }

      groupByInputModel[obj[this.groupProperty]].push({ ...obj });
    });

    this.groupByInputModel = groupByInputModel;

    this.fillOutput();
  }

  $onChanges(changes) {
    const { inputModel } = changes;
    if (!inputModel) return;

    const { currentValue } = inputModel;
    if (!currentValue) return;

    if (this.groupProperty) {
      this.convertInputIntoGroup(currentValue);
    } else {
      this.fillOutput();
    }
  }

  fillOutput() {
    this.outputModel = [];
    if (this.groupProperty) {
      Object.keys(this.groupByInputModel).forEach(key => {
        const ticked = this.groupByInputModel[key].filter(
          obj => obj[this.tickProperty]
        );
        this.outputModel.push(...ticked);
      });
    } else {
      this.outputModel = this.inputModel.filter(obj => obj[this.tickProperty]);
    }
  }

  getListFlat() {
    let list;
    if (this.groupProperty) {
      list = flatMapDeep(this.groupByInputModel);
    } else {
      list = [...this.inputModel];
    }
    return list;
  }

  toggleItem(data, sourceIsTick) {
    this.fillOutput();

    if (isFunction(this.onChange)) {
      const list = this.getListFlat();
      this.onChange({ data, list, sourceIsTick });
    }
  }

  removeObject(primaryPropertyValue, groupPropertyValue, $event) {
    $event.stopPropagation();
    if (this.disabled) return;

    if (this.groupProperty) {
      const output =
        (this.groupByInputModel[groupPropertyValue] &&
          this.groupByInputModel[groupPropertyValue].find(
            obj => obj[this.primaryProperty] === primaryPropertyValue
          )) ||
        {};
      output[this.tickProperty] = false;
    } else {
      const output =
        this.outputModel.find(
          obj => obj[this.primaryProperty] === primaryPropertyValue
        ) || {};
      output[this.tickProperty] = false;
    }

    this.fillOutput();
  }

  optionToolToggle(isSelectAll = true, isRefresh = false) {
    if (this.groupProperty) {
      Object.keys(this.groupByInputModel).forEach(key => {
        this.groupByInputModel[key] = this.groupByInputModel[key].map(obj => {
          obj[this.tickProperty] = isRefresh
            ? this.isSelectAllDefault
            : isSelectAll;
          return obj;
        });
      });
    } else {
      this.inputModel =
        this.inputModel &&
        this.inputModel.map(obj => {
          obj[this.tickProperty] = isRefresh
            ? this.isSelectAllDefault
            : isSelectAll;
          return obj;
        });
    }

    this.fillOutput();

    if (isSelectAll && !isRefresh) {
      const list = this.getListFlat();
      this.onSelectAll({ isSelectAll: true, list });
    } else if (!isSelectAll && !isRefresh) {
      const list = this.getListFlat();
      this.onSelectNone({ isSelectAll: false, list });
    } else if (isRefresh) {
      const list = this.getListFlat();
      this.onRefresh({ list });
    }
  }

  openToggle(toggleValue) {
    if (this.disabled) return;

    this.isOpen =
      typeof toggleValue !== 'undefined' ? toggleValue : !this.isOpen;
  }

  getButtonTitle(obj) {
    let label = '';
    this.buttonLabels.forEach((lbl, idx) => {
      label += `${idx !== 0 ? ` - ` : ``}${obj[lbl.label]}${
        lbl.isPercentage ? `%` : ``
      }`;
    });
    return label;
  }
}

export default CustomCrmMultiSelectCtrl;
