import template from './probabilityDropdown.html';

export default {
  template,
  bindings: {
    card: '<',
    onChangeProbability: '&',
    probabilityList: '<',
  },
};
