import angular from 'angular';

angular
  .module('app')
  .directive('systemUpdates', function systemUpdates(
    $timeout,
    overviewDashboardService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/systemUpdates.html',

      link(scope) {
        scope.interval = 'allDates';

        scope.slickConfig = { method: {} };
        // let totalData=28;
        scope.systemUpdateTableData = [];
        function systemUpdateTableGet() {
          overviewDashboardService.SystemUpdateTableGet().then(
            response => {
              scope.systemUpdateTableData = [];
              const totalData = response.data.length;
              const round = Math.floor(totalData / 5) * 5 - 1;
              const modStart = Math.floor(totalData / 5) * 5;
              const mod = Math.floor(totalData % 5);

              for (let i = 0; i < round; i += 5) {
                const testData = [];
                testData.push(response.data[i]);

                testData.push(response.data[i + 1]);
                testData.push(response.data[i + 2]);
                testData.push(response.data[i + 3]);
                testData.push(response.data[i + 4]);
                scope.systemUpdateTableData.push(testData);
              }

              if (parseInt(mod, 10) !== 0) {
                const lastItem = [];
                for (let i = modStart; i < totalData; i++) {
                  lastItem.push(response.data[i]);
                }
                scope.systemUpdateTableData.push(lastItem);
              }

              scope.dataLoaded = true;

              if (scope.systemUpdateTableData.length > 0) {
                $timeout(() => {
                  try {
                    scope.slickConfig.method.slickGoTo(0);
                  } catch (error) {
                    // Continue regardless of error
                  }
                }, 1000);
              }
            },
            () => {}
          );
        }
        function init() {
          systemUpdateTableGet();
        }
        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'systemUpdatesPopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') scope.customizeMode = true;
          else scope.customizeMode = false;
        };

        init();
      },
    };
  });
