import angular from 'angular';

angular
  .module('app')
  .controller('OffsetPdfPreviewModalCtrl', function OffsetPdfPreviewModalCtrl(
    $scope,
    $window,
    options,
    labels,
    colors,
    graphData,
    loanAmount,
    years,
    interestRate,
    frequency,
    interestSaved,
    timeSaved,
    customThemeService,
    series,
    userService,
    loanCalculatorSharedData
  ) {
    $scope.data = graphData;
    $scope.options = options;
    $scope.labels = labels;
    $scope.colors = colors;
    $scope.series = series;
    $scope.loanAmount = loanAmount;
    $scope.years = years;
    $scope.interestRate = interestRate;
    $scope.frequency = frequency;
    $scope.interestSaved = interestSaved;
    $scope.timeSaved = timeSaved;
    console.log(loanCalculatorSharedData, 'loanCalculatorSharedData');
    $scope.brandingLogo = loanCalculatorSharedData.brandingLogo;
    $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
    $scope.getUserInfo();
    $scope.date = new Date();
  });
