export function getPhoneNumberByType(phoneArray, type) {
  if (!phoneArray || !phoneArray.length) return;
  const phone = phoneArray.filter(obj => obj.Type === type);
  if (!phone || !phone.length) return;
  return phone[0].Number;
}

export function getEmailByType(emailArray, type) {
  if (!emailArray || !emailArray.length) return;
  const email = emailArray.filter(obj => obj.Type === type);
  if (!email || !email.length) return;
  return email[0].EmailAddress;
}

export function getFullName(firstname, lastname) {
  if (!firstname && !lastname) return '';
  return `${firstname || ''} ${lastname || ''}`.trim();
}

export function hasFirstnameOrLastname(contact) {
  return !!(contact && (contact.FirstName || contact.LastName));
}
