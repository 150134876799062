/**
 * Config for the router
 */

// Generates a resolve object previously configured in constant.JS_REQUIRES (config.constant.js)
function loadSequence() {
  // Lazy loading is disabled due to webpack integration. It will b  e replaced with code splitting with ES6 import
  return null;

  // const _args = arguments;
  // return {
  //   deps: ['$ocLazyLoad', '$q',
  //     function ($ocLL, $q) {
  //       let promise = $q.when(1);
  //       for (let i = 0, len = _args.length; i < len; i++) {
  //         promise = promiseThen(_args[i]);
  //       }
  //       return promise;
  //
  //       function promiseThen(_arg) {
  //         if (typeof _arg === 'function')
  //           return promise.then(_arg);
  //         return promise.then(() => {
  //           const nowLoad = requiredData(_arg);
  //           if (!nowLoad)
  //             return $.error(`Route resolve: Bad resource name [${_arg}]`);
  //           return $ocLL.load(nowLoad);
  //         });
  //       }
  //
  //       function requiredData(name) {
  //         if (jsRequires.modules)
  //           for (const m in jsRequires.modules)
  //             if (jsRequires.modules[m].name && jsRequires.modules[m].name === name)
  //               return jsRequires.modules[m];
  //         return jsRequires.scripts && jsRequires.scripts[name];
  //       }
  //     }],
  // };
}

function configRouter(
  $stateProvider,
  $urlRouterProvider,
  $controllerProvider,
  $compileProvider,
  $filterProvider,
  $provide,
  $ocLazyLoadProvider,
  jsRequires
) {
  // pretty URL
  // $locationProvider.html5Mode(true);

  // LAZY MODULES

  $ocLazyLoadProvider.config({
    debug: false,
    events: true,
    modules: jsRequires.modules,
  });

  // APPLICATION ROUTES
  // -----------------------------------
  // For any unmatched url, redirect to /app/dashboard
  // $urlRouterProvider.otherwise("/app/dashboard");
  $urlRouterProvider.otherwise($injector => {
    const $state = $injector.get('$state');
    return $state.go('app.dashboard');
  });
  // Set up the states
  $stateProvider
    .state('app', {
      //eslint-disable-line
      url: '/app',
      views: {
        '': {
          templateUrl: 'src/components/layout/layout.html',
        },
        'notificationSidebar@app': {
          templateUrl: `assets/views/notificationSidebar/notificationSidebar.html`,
        },
        'notificationSidebarXs@app': {
          templateUrl: `assets/views/notificationSidebar/notificationSidebarXs.html`,
        },
      },
      resolve: loadSequence(
        'chartjs',
        'chart.js',
        'CustomThemeCtrl',
        'NewLoanApplicationCtrl',
        'AddPersonModalCtrl',
        'NewInsuranceApplicationCtrl',
        'InsurancePersonAddCtrl',
        'ui.select',
        'NotificationSidebarCtrl',
        'NotificationSidebarXsCtrl',
        'ActivitySidebarCtrl',
        'TaskSidebarCtrl',
        'AppointmentSidebarCtrl',
        'AnnouncementModalCtrl'
      ),
      abstract: true,
    })
    .state('app.dashboard', {
      url: '/dashboard',
      templateUrl: `assets/views/overviewDashboard.html`,
      resolve: loadSequence(
        'datetimepicker',
        'OverviewDashboardCtrl',
        'CustomerCommentModalCtrl',
        'CustomerCommentModalSingleCtrl',
        'CustomerCommentViewAllModalCtrl'
      ),
      title: 'Dashboard',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsSingle', {
      url: '/contacts/:familyId/:activeTab/:loanId/:inprogress',
      templateUrl: `assets/views/contacts_single.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'ContactDetailCtrl',
        'InsuranceExistingModalCtrl',
        'NotesDetailsCtrl',
        'SidebarDetailCtrl',
        'LoanDetailCtrl',
        'WorkbenchModalCtrl',
        'LoanDetailsModalCtrl',
        'LoanInformationCtrl',
        'LoanInformationAddExistingCtrl',
        'SecurityStructureCtrl',
        'LoanStructureCtrl',
        'LoanStructureAddEditCtrl',
        'ModifyRefixCtrl',
        'financialsCtrl',
        'FinancialAssetsCtrl',
        'FinancialLiabilitiesCtrl',
        'FinancialIncomeCtrl',
        'FinancialExpenseCtrl',
        'SummaryDetailCtrl',
        'ClientDetailCtrl',
        'AddClientModalCtrl',
        'ClientTypeModalCtrl',
        'CategoryModalCtrl',
        'RatingModalCtrl',
        'ReferralModalCtrl',
        'PersonCompanyModalCtrl',
        'DocumentModalCtrl',
        'DocumentPreviewCtrl',
        'ViewEmailCtrl',
        'CreateNoteModalCtrl',
        'InsuranceDetailCtrl',
        'InsExistingCtrl',
        'InsInProgressCtrl',
        'InsurancePreviousCtrl',
        'InsuranceModalCtrl',
        'DocumentsDetailCtrl',
        'EmailsDetailCtrl',
        'AttachFileMgmtCtrl',
        'angucomplete',
        'LendingDetailCtrl',
        'ContactEmploymentCtrl',
        'IdentificationCtrl',
        'AddEmploymentCtrl',
        'EditEmploymentCtrl',
        'AddEmploymentRolesCtrl',
        'loanAppSecurity',
        'datetimepicker'
      ),
      params: {
        activeTab: { squash: true },
        loanId: { squash: true },
        inprogress: { squash: true },
      },
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsLoans', {
      url: '/contacts/:familyId/:activeTab/:lendingTab',
      templateUrl: `assets/views/contacts_single.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'ContactDetailCtrl',
        'NotesDetailsCtrl',
        'SidebarDetailCtrl',
        'LoanDetailCtrl',
        'WorkbenchModalCtrl',
        'LoanDetailsModalCtrl',
        'LoanInformationCtrl',
        'LoanInformationAddExistingCtrl',
        'SecurityStructureCtrl',
        'LoanStructureCtrl',
        'LoanStructureAddEditCtrl',
        'ModifyRefixCtrl',
        'FinancialAssetsCtrl',
        'FinancialLiabilitiesCtrl',
        'FinancialIncomeCtrl',
        'FinancialExpenseCtrl',
        'SummaryDetailCtrl',
        'ClientDetailCtrl',
        'AddClientModalCtrl',
        'InsuranceDetailCtrl',
        'InsuranceModalCtrl',
        'DocumentsDetailCtrl',
        'EmailsDetailCtrl',
        'AttachFileMgmtCtrl',
        'angucomplete',
        'LendingDetailCtrl',
        'ContactEmploymentCtrl',
        'IdentificationCtrl',
        'AddEmploymentCtrl',
        'EditEmploymentCtrl',
        'AddEmploymentRolesCtrl',
        'loanAppSecurity',
        'datetimepicker'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsInsurances', {
      url: '/contacts/:familyId/:activeTab',
      templateUrl: `assets/views/contacts_single.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'ContactDetailCtrl',
        'NotesDetailsCtrl',
        'SidebarDetailCtrl',
        'LoanDetailCtrl',
        'WorkbenchModalCtrl',
        'LoanDetailsModalCtrl',
        'LoanInformationCtrl',
        'LoanInformationAddExistingCtrl',
        'SecurityStructureCtrl',
        'LoanStructureCtrl',
        'LoanStructureAddEditCtrl',
        'ModifyRefixCtrl',
        'FinancialAssetsCtrl',
        'FinancialLiabilitiesCtrl',
        'FinancialIncomeCtrl',
        'FinancialExpenseCtrl',
        'SummaryDetailCtrl',
        'SummarySearchNearbyCtrl',
        'ClientDetailCtrl',
        'AddClientModalCtrl',
        'InsuranceDetailCtrl',
        'InsuranceModalCtrl',
        'DocumentsDetailCtrl',
        'EmailsDetailCtrl',
        'AttachFileMgmtCtrl',
        'angucomplete',
        'LendingDetailCtrl',
        'ContactEmploymentCtrl',
        'IdentificationCtrl',
        'AddEmploymentCtrl',
        'EditEmploymentCtrl',
        'AddEmploymentRolesCtrl',
        'loanAppSecurity',
        'datetimepicker'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.LoanProfilerSingle', {
      url: '/contact/loan-profiler/:familyId/:lendingScenarioId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/details.v4.html`,
      resolve: loadSequence(
        'ngTable',
        'lendingScenarioDetailCtrl',
        'lendingScenarioRelPartyCtrl',
        'lendingScenarioInsuranceCtrl'
      ),
      title: 'Loan Profiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })

    .state('app.LendingScenarioSingle', {
      url: '/contact/lending-scenario/:familyId/:lendingScenarioId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/details.v4.html`,
      resolve: loadSequence(
        'ngTable',
        'lendingScenarioDetailCtrl',
        'lendingScenarioRelPartyCtrl',
        'lendingScenarioInsuranceCtrl'
      ),
      title: 'Lending Scenario',
      ncyBreadcrumb: {
        label: 'Lending Scenario',
      },
    })
    .state('app.LoanProfilerLoanRequirements', {
      url:
        '/contact/loan-profiler/:familyId/:lendingScenarioId/loan-requirements/:loanRequirementId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/loanReq/loanReq.html`,
      resolve: loadSequence(
        'ngTable',
        'lendingScenarioDetailCtrl',
        'lendingScenarioReqCtrl'
      ),
      title: 'Loan Profiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.LendingScenarioLoanRequirements', {
      url:
        '/contact/lending-scenario/:familyId/:lendingScenarioId/loan-requirements/:loanRequirementId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/loanReq/loanReq.html`,
      resolve: loadSequence(
        'ngTable',
        'lendingScenarioDetailCtrl',
        'lendingScenarioReqCtrl'
      ),
      title: 'Lending Scenario',
      ncyBreadcrumb: {
        label: 'Lending Scenario',
      },
    })

    .state('app.contacts', {
      url: '/contacts',
      templateUrl: `assets/views/contacts.html`,
      // resolve: loadSequence('d3', 'ui.knob', 'countTo', 'dashboardCtrl'),
      resolve: loadSequence(
        'anguFixedHeaderTable',
        'ngTable',
        'ui.mask',
        'ContactsCtrl'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsAdviserOrg', {
      url: '/contact/adviser_org/:adviserOrgId/:adviserComplianceId',
      templateUrl: `assets/views/corporate/adviser_org.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'AdviserOrgCtrl',
        'NotesDetailsCtrl',
        'DocumentsDetailCtrl',
        'SidebarDetailCtrl',
        'InsuranceDetailCtrl',
        'angucomplete',
        'datetimepicker',
        'AgreementNewCtrl',
        'jQuery-Mask-Plugin',
        'ReferrerAsMemberNewCtrl'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })

    /**
     * Account Page for Business
     */
    .state('app.BusinessAccount', {
      url: '/contact/business/:familyId/:activeTab/:loanId/:inprogress',
      templateUrl: `assets/views/contacts/business/index.html`,
      resolve: loadSequence(
        'SidebarDetailCtrl',
        'InsuranceDetailCtrl',
        'financialsCtrl',
        'BusinessAccountSingleCtrl',
        'LoanDetailCtrl',
        'WorkbenchModalCtrl',
        'LoanDetailsModalCtrl',
        'ClientDetailCtrl',
        'AddClientModalCtrl',
        'ClientTypeModalCtrl',
        'CategoryModalCtrl',
        'RatingModalCtrl',
        'ReferralModalCtrl',
        'PersonCompanyModalCtrl',
        'DocumentModalCtrl',
        'DocumentPreviewCtrl',
        'ViewEmailCtrl',
        'CreateNoteModalCtrl',
        'LoanInformationCtrl',
        'LoanInformationAddExistingCtrl',
        'LoanStructureCtrl',
        'FinancialAssetsCtrl',
        'FinancialLiabilitiesCtrl',
        'FinancialIncomeCtrl',
        'FinancialExpenseCtrl',
        'LendingDetailCtrl',
        'NotesDetailsCtrl',
        'InsuranceModalCtrl',
        'InsExistingCtrl',
        'DocumentsDetailCtrl',
        'EmailsDetailCtrl',
        'datetimepicker',
        'angucomplete',
        'ui.select',
        'selectize',
        'ngTable'
      ),
      params: {
        activeTab: { squash: true },
        loanId: { squash: true },
        inprogress: { squash: true },
      },
      title: 'Contacts - Business Account',
      ncyBreadcrumb: {
        label: 'Contact - Business Account',
      },
    })

    .state('app.contactsAdviser', {
      url: '/contact/adviser/:clientId/:familyId',
      templateUrl: `assets/views/corporate/adviser.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'ContactsCtrl',
        'ContactDetailCtrl',
        'NotesDetailsCtrl',
        'AdviserCtrl',
        'DocumentsDetailCtrl',
        'SidebarDetailCtrl',
        'InsuranceDetailCtrl',
        'angucomplete',
        'datetimepicker',
        'AgreementNewCtrl',
        'jQuery-Mask-Plugin',
        'AccountAccreditationCtrl',
        'EmailManagementCtrl',
        'emailTemplateCtrl',
        'sharingAndPrivacyCtrl',
        'ReferrerAsMemberNewCtrl',
        'EmailsDetailCtrl'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsAssistant', {
      url: '/contact/assistant/:clientId/:familyId',
      templateUrl: `assets/views/corporate/assistant.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'SidebarDetailCtrl',
        'AssistantCtrl',
        'angucomplete',
        'datetimepicker'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsReferrerOrg', {
      url: '/contact/referrer-org/:referrerComplianceId/:referrerOrgId',
      templateUrl: `assets/views/corporate/referrer_org.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'SidebarDetailCtrl',
        'ReferrerOrgCtrl',
        'ReferralAgreementModalCtrl',
        'angucomplete',
        'datetimepicker',
        'jQuery-Mask-Plugin',
        'ReferrerAsMemberNewCtrl',
        'AgreementNewCtrl'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsReferrer', {
      url: '/contact/referrer/:clientId/:familyId',
      templateUrl: `assets/views/contacts/corportate_referrer.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor',
        'SidebarDetailCtrl',
        'ReferrerDetailCtrl',
        'angucomplete',
        'datetimepicker',
        'jQuery-Mask-Plugin',
        'ReferrerAsMemberNewCtrl',
        'AgreementNewCtrl'
      ),
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.genralSearchInMobile', {
      url: '/search/:searchText',
      templateUrl: `assets/views/dashboard/genral_mobile_search.html`,
      title: 'Search',
      resolve: loadSequence('GeneralSearchCtrl'),
      ncyBreadcrumb: {
        label: 'Search',
      },
    })
    .state('app.loanTools', {
      url: '/loantools/:activeTab',
      templateUrl: `assets/views/loanTools/loan_tools.html`,
      resolve: loadSequence(
        'LoanToolsCtrl',
        'AddKeyModalCtrl',
        'addDocumentsModalCtrl'
      ),
      title: 'Loan Tools',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.loanRepaymentCalculator', {
      url: '/repayment',
      templateUrl: `assets/views/loanCalculator/loan_repayment_calculator.html`,
      resolve: loadSequence(
        'LoanRepaymentCalculatorCtrl',
        'dom-to-image',
        'jspdf'
      ),
      title: 'Repayment',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.incomeCalculator', {
      url: '/incomeCalculator',
      template: '<income-calculator></income-calculator>',
      title: 'Income Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickQuote', {
      url: '/quickQuote',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      resolve: loadSequence(
        'NewQouteCtrl',
        'InsurerListCtrl',
        'QuoteSettingsCtrl',
        'QuoteReportPDFCtrl',
        'BrochuresModalCtrl',
        'touchspin-plugin'
      ),
      title: 'Quick Quote',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickQuoteDetails', {
      url: '/quickQuote/:familyId/:insQuoteId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      resolve: loadSequence(
        'NewQouteCtrl',
        'InsurerListCtrl',
        'QuoteSettingsCtrl',
        'QuoteReportPDFCtrl',
        'BrochuresModalCtrl',
        'touchspin-plugin'
      ),
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactQuickQuote', {
      url: '/contactQuote/:familyId?isFromInsuranceTab',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      resolve: loadSequence(
        'NewQouteCtrl',
        'InsurerListCtrl',
        'QuoteSettingsCtrl',
        'QuoteReportPDFCtrl',
        'BrochuresModalCtrl',
        'touchspin-plugin'
      ),
      title: 'Family Quick Quote',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      params: {
        isFromInsuranceTab: {
          value: null,
          inherit: false,
          squash: true,
        },
      },
    })
    .state('app.profilerQuickQuote', {
      url: '/profilerQuickQuote/profileId=:profilerId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      resolve: loadSequence(
        'NewQouteCtrl',
        'InsurerListCtrl',
        'QuoteSettingsCtrl',
        'QuoteReportPDFCtrl',
        'BrochuresModalCtrl',
        'touchspin-plugin'
      ),
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.profilerQuickQuoteFamily', {
      url: '/profilerQuickQuote/:profilerId/:familyId/:insQuoteId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      resolve: loadSequence(
        'NewQouteCtrl',
        'InsurerListCtrl',
        'QuoteSettingsCtrl',
        'QuoteReportPDFCtrl',
        'BrochuresModalCtrl',
        'touchspin-plugin'
      ),
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insuranceProfiler', {
      url: '/insuranceProfiler',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      resolve: loadSequence('InsuranceProfilerCtrl', 'touchspin-plugin'),
      title: 'Insurance Profiler',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactProfiler', {
      url: '/contactProfiler/:familyId',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      resolve: loadSequence('InsuranceProfilerCtrl', 'touchspin-plugin'),
      title: 'Insurance Profiler',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insuranceProfilerDetails', {
      url: '/insuranceProfiler/:familyId/:insProfilerId',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      resolve: loadSequence('InsuranceProfilerCtrl', 'touchspin-plugin'),
      title: 'Insurance Profiler Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insApp', {
      url: '/insuranceApplication/:insAppId/:familyId?',
      templateUrl: `assets/views/insuranceTools/insApp.html`,
      resolve: loadSequence(
        'InsuranceAppCtrl',
        'InsAppApplicationCtrl',
        'InsAppCustObjectivesCtrl',
        'InsAppProdSelCtrl',
        'InsAppFinancialCtrl',
        'loanAppFinancialCtrl',
        'loanAppFinancialAssetsCtrl',
        'loanAppFinancialLiabilitiesCtrl',
        'loanAppFinancialIncomeCtrl',
        'loanAppFinancialExpenseCtrl',
        'InsAppFinancialAssetsCtrl',
        'InsAppFinancialLiabilitiesCtrl',
        'InsAppFinancialIncomeCtrl',
        'InsAppFinancialExpenseCtrl',
        'InsurerAppCtrl',
        'InsurerAppStatementCtrl',
        'insAppApplicationCtrl',
        'insAppCustObjectivesCtrl',
        'insAppProdSelCtrl',
        'insAppFinancialCtrl',
        'LoanAppFinancialCtrl',
        'LoanAppFinancialAssetsCtrl',
        'LoanAppFinancialLiabilitiesCtrl',
        'LoanAppFinancialIncomeCtrl',
        'LoanAppFinancialExpenseCtrl',
        'insAppFinancialAssetsCtrl',
        'insAppFinancialLiabilitiesCtrl',
        'insAppFinancialIncomeCtrl',
        'insAppFinancialExpenseCtrl',
        'insurerAppCtrl',
        'insurerAppStatementCtrl',
        'touchspin-plugin'
      ),
      title: 'Insurance Application',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.importantDocuments', {
      url: '/importantDocuments',
      templateUrl: `assets/views/loanTools/importantDocs.html`,
      resolve: loadSequence(
        'ImportantDocumentCtrl',
        'ImportantDocumentModalCtrl'
      ),
      title: 'Important Documents',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.myDocuments', {
      url: '/my-documents',
      templateUrl: `assets/views/resource/myDocuments.html`,
      resolve: loadSequence('MyDocumentsCtrl', 'MyDocumentsModalCtrl'),
      title: 'My Documents',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.templates', {
      url: '/templates',
      templateUrl: `assets/views/resource/templates.html`,
      resolve: loadSequence('TemplatesCtrl'),
      title: 'Templates',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.education', {
      url: '/education',
      templateUrl: `assets/views/resource/education.html`,
      resolve: loadSequence('EducationCtrl', 'EducationModalCtrl'),
      title: 'Education',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.complaints', {
      url: '/complaints',
      templateUrl: `assets/views/resource/complaints.html`,
      resolve: loadSequence('ComplaintsCtrl', 'ComplaintsModalCtrl'),
      title: 'Complaints',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.onlineTraining', {
      url: '/online-training',
      templateUrl: `assets/views/resource/onlineTraining.html`,
      resolve: loadSequence('OnlineTrainingCtrl'),
      title: 'Online Training',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.additionalRepayment', {
      url: '/additionalRepayment',
      templateUrl: `assets/views/loanCalculator/additional_repayment_calculator.html`,
      resolve: loadSequence(
        'AdditionalRepaymentCalculatorCtrl',
        'dom-to-image',
        'jspdf'
      ),
      title: 'Additional Repayment',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.compareLoans', {
      url: '/compareLoans',
      templateUrl: `assets/views/loanCalculator/compare_loan_calculator.html`,
      resolve: loadSequence(
        'CompareLoanCalculatorCtrl',
        'dom-to-image',
        'jspdf'
      ),
      // resolve: loadSequence('touchspin-plugin','ngTable','compareLoanCalculatorCtrl'),
      title: 'Compare Loan Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickLMI', {
      url: '/quickLMI/:brokerEventId',
      templateUrl: `assets/views/loanCalculator/quick_lmi_calculator.html`,
      resolve: loadSequence('QuickLMICalculatorCtrl'),
      title: 'Quick LMI Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.hemCalculator', {
      url: '/hemCalculator',
      templateUrl: 'assets/views/hemCalculator/hem_calculator.html',
      title: 'HEM Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.savingGoal', {
      url: '/savingGoal',
      templateUrl: `assets/views/loanCalculator/saving_goal_calculator.html`,
      resolve: loadSequence(
        'SavingGoalCalculatorCtrl',
        'dom-to-image',
        'jspdf'
      ),
      title: 'Saving Goal Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.offset', {
      url: '/offset',
      templateUrl: `assets/views/loanCalculator/offset_calculator.html`,
      resolve: loadSequence('OffsetCalculatorCtrl', 'dom-to-image', 'jspdf'),
      title: 'Offset Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.leads', {
      url: '/leads',
      params: {
        createdContact: '',
      },

      templateUrl: `assets/views/pipeline/pipeline.html`,
      resolve: loadSequence(
        'ngTable',
        'PipelineCtrl',
        'PipelineTimelineCtrl',
        'PipelineListViewCtrl',
        'LinkToLoanCtrl'
      ),
      title: 'Leads',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.application', {
      url: '/application/:familyId',
      params: {
        createdContact: '',
      },
      templateUrl: `assets/views/pipeline/applications/applications.html`,
      resolve: loadSequence(
        'ngTable',
        'PipelineTimelineCtrl',
        'PipelineListViewCtrl',
        'PipelineAppCtrl',
        'LinkToLoanCtrl'
      ),
      title: 'Applications',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.emailCampaign', {
      url: '/email-campaign',
      templateUrl: `assets/views/communicate/communicate.html`,
      resolve: loadSequence('CommunicateCtrl'),
      title: 'Communicate',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.newEmailCampaign', {
      url: '/new-email-campaign',
      templateUrl: `assets/views/communicate/sendCampaign.html`,
      resolve: loadSequence('ngTable', 'NewEmailCampaignCtrl'),
    })
    .state('app.report', {
      url: '/report?dateFilter&adviser',
      params: {
        dateFilter: null,
        adviser: [],
      },
      template: `<jasper-report></jasper-report>`,
      title: 'Report',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.commissionReport', {
      url: '/commissionReport',
      templateUrl: `assets/views/report/commisionReport.html`,
      resolve: loadSequence('CommissionReportCtrl'),
      title: 'Commision Report',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.resources', {
      url: '/resources',
      templateUrl: `assets/views/resources/resources.html`,
      resolve: loadSequence('resourcesCtrl'),
      title: 'Resources',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.referrals', {
      url: '/referrals',
      templateUrl: `assets/views/referrals/referrals.html`,
      resolve: loadSequence('ReferralsCtrl'),
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.sendReferrals', {
      url: '/sendReferrals',
      templateUrl: `assets/views/referrals/sendReferrals.html`,
      resolve: loadSequence(
        'SendReferralsCtrl',
        'SendReferralModalCtrl',
        'ngTable'
      ),
      title: 'Send Referrals',
      ncyBreadcrumb: {
        label: 'Send Referrals',
      },
    })
    .state('app.newReferral', {
      url: '/Referral/:referralType/:agreementId/:agreementTypeId/:requestId',
      templateUrl: `assets/views/referrals/new_referral.html`,
      resolve: loadSequence('NewReferralCtrl'),
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.referrerDetail', {
      url: '/referrer/:referrerId/:agreementId',
      templateUrl: `assets/views/referrals/referrer_detail.html`,
      resolve: loadSequence('ReferrerInfoCtrl'),
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.groupDetail', {
      url: '/group/:agreementId',
      templateUrl: `assets/views/referrals/group_detail.html`,
      resolve: loadSequence('GroupInfoCtrl'),
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.tasks', {
      url: '/tasks/:familyId',
      templateUrl: `assets/views/tasks/tasks.html`,
      resolve: loadSequence(
        'ngTable',
        'tasksCtrl',
        'addTasksCtrl',
        'AddTaskTemplateCtrl',
        'TaskVideoDemoCtrl',
        'ViewTaskModalCtrl',
        'MobileSelectModalCtrl',
        'MobileFamilyFilterModalCtrl',
        'datetimepicker'
      ),
      title: 'tasks',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.sendEmail', {
      url: '/sendEmail/:contactType/:familyId',
      templateUrl: `assets/views/contacts/send_email.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ckeditor-plugin',
        'ckeditor',
        'sendEmailCtrl',
        'EmailManagementCtrl',
        'AttachFileMgmtCtrl',
        'emailTemplateCtrl',
        'sharingAndPrivacyCtrl'
      ),
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send Email',
      },
    })
    .state('app.sendMail', {
      url: '/sendMail/:contactType/:familyId',
      templateUrl: `assets/views/contacts/send_mail.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ckeditor-plugin',
        'ckeditor'
      ),
      title: 'SendMail',
      ncyBreadcrumb: {
        label: 'Send Mail',
      },
    })
    .state('app.loanProfiler', {
      url:
        '/loanProfiler/:brokerEventId/:loanScenarioId/:tab?familyId&loanId&proposedLoanAmount&securityAmount',
      templateUrl: `assets/views/loanProfiler/loan_profiler.html`,
      resolve: loadSequence(
        'RWD-Table-Patterns',
        'touchspin-plugin',
        'ngTable',
        'serviceabilityCtrl',
        'ServiceabilityModalCtrl',
        'ForSetRateModalCtrl',
        'ForSelectedProductModalCtrl',
        'ForDisplayProductFinderTableColumnCtrl',
        'ForAddLoanStructureModalCtrl',
        'ForLenderDetailModalCtrl',
        'ForProductDetailModalCtrl',
        'ForAdvanceFilterModalCtrl',
        'ProductFinderCtrl',
        'LoanProfilerCtrl'
      ),
      title: 'loanProfiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.loanProfilerMobile', {
      url: '/loanProfilerLender/:brokerEventId',
      templateUrl: `assets/views/loanProfiler/loan_profiler_mobile.html`,
      resolve: loadSequence(
        'touchspin-plugin',
        'ngTable',
        'ServiceabilityModalCtrl',
        'LoanProfilerCtrl'
      ),
      title: 'loanProfilerLender',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.fundingCalculator', {
      url: '/fundingCalculator/:brokerEventId',
      templateUrl: `assets/views/fundingCalculator/funding_calculator_setup.html`,
      resolve: loadSequence(
        'ui.tab.scroll',
        'ngMap',
        'ui.select',
        'selectize',
        'touchspin-plugin',
        'FundingCalculatorCtrl'
      ),
      title: 'fundingCalculator',
      ncyBreadcrumb: {
        label: 'Funding Calculator',
      },
    })
    .state('app.googleAuthCodeCtrl', {
      url: '/googleauthcode',
      templateUrl: `assets/views/googleAuthCode/googleAuthCode.html`,
      resolve: loadSequence('GoogleAuthCodeCtrl'),
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })
    .state('app.googleauthcodeGmail', {
      url: '/googleauthcode/gmail',
      templateUrl: `assets/views/googleAuthCode/gmail.html`,
      resolve: loadSequence('GmailCtrl'),
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })
    .state('app.googleauthcodeGdrive', {
      url: '/googleauthcode/drive',
      templateUrl: `assets/views/googleAuthCode/drive.html`,
      resolve: loadSequence('GdriveCtrl'),
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })

    /**
     * TODO: apply necessary uri after API is being applied
     * @author: Francis
     */
    .state('app.loanApplicationDetailsV3', {
      url: '/loan-application/:familyId/:loanAppId',
      templateUrl: `assets/views/loanApplication/index.html`,
      resolve: loadSequence(
        'ckeditor-plugin',
        'ckeditor',
        'ngTable',
        'LoanAppCtrl',
        'LoanAppVideoDemoCtrl',
        'FactFindModalCtrl',
        'ApplyOnlineSentCtrl',
        'RecommendationReportCtrl',
        'RecommendationModalCtrl',
        'LoanAppLoanSubmissionCtrl',
        'LoanAppApplicationCtrl',
        'LoanAppEmploymentCtrl',
        'LoanAppAddEmploymentCtrl',
        'LoanAppEditEmploymentCtrl',
        'ApplicantModalCtrl',
        'AddCompanyRelatedModalCtrl',
        'LoanAppIdentificationModalCtrl',
        'LoanAppAddressCtrl',
        'loanAppCreditHistoryCtrl',
        'LoanAppLoanDetailsCtrl',
        'LoanAppFinancialCtrl',
        'LoanAppFinancialAssetsCtrl',
        'LoanAppFinancialLiabilitiesCtrl',
        'LoanAppFinancialIncomeCtrl',
        'LoanAppFinancialExpenseCtrl',
        'LoanAppSecurityCtrl',
        'LoanAppInsuranceCtrl',
        'LoanAppBrokerDeclarationsCtrl',
        'LoanAppDepositCtrl',
        'AddDepositModalCtrl',
        'LoanAppCustomerObjectivesCtrl',
        'LoanAppSupportDocsCtrl',
        'LoanAppLoanFundingDetailsCtrl',
        'LoanAppProductFinderCtrl',
        'LenderWorksheetCtrl'
      ),
      title: 'Loan Application Details - V3',
      ncyBreadcrumb: {
        label: 'Loan Application Details',
      },
    })

    .state('app.myAccount', {
      url: '/user/my-account',
      templateUrl: `assets/views/user/account.html`,
      resolve: loadSequence(
        'UserAccountCtrl',
        'MyAccountModalCtrl',
        'CompanyProfileCtrl',
        'MyBrandsCtrls',
        'PersonalInfoCtrl'
      ),
      title: 'My Account',
      ncyBreadcrumb: {
        label: 'My Account',
      },
    })
    .state('app.userPermissions', {
      url: '/user/permissions',
      templateUrl: `assets/views/user/permissions.html`,
      resolve: loadSequence('UserPermissionsCtrl'),
      title: 'User Permissions',
      ncyBreadcrumb: {
        label: 'User Permissions',
      },
    })
    .state('app.userLenders', {
      url: '/user/lenders',
      templateUrl: `assets/views/user/lenders.html`,
      resolve: loadSequence('UserLendersCtrl'),
      title: 'User Lenders',
      ncyBreadcrumb: {
        label: 'User Lenders',
      },
    })

    /**
     * Customize Theme
     */

    .state('app.customTheme', {
      url: '/theme',
      templateUrl: `assets/views/customTheme.html`,
      resolve: loadSequence('CustomThemeCtrl', 'ui.select'),
      title: 'Customize Theme',
      ncyBreadcrumb: {
        label: 'Customize Theme',
      },
    })

    .state('app.commission', {
      url: '/commission',
      templateUrl: 'assets/views/commission/commission.html',
      resolve: loadSequence('CommissionCtrl'),
    })

    .state('app.commission.transaction', {
      url: '/transaction/:keyword',
      templateUrl: 'assets/views/commission/commissionTrans.html',
      resolve: loadSequence('CommissionTransCtrl'),
    })

    .state('app.expectedCommission', {
      url: '/expected-commission',
      templateUrl: 'assets/views/commission/expectedCommission.html',
      resolve: loadSequence('ExpectedCommissionCtrl'),
    })

    .state('app.unmatchedCommission', {
      url: '/unmatched-commission',
      templateUrl: 'assets/views/commission/unmatchedCommission.html',
      resolve: loadSequence('UnmatchedCommissionCtrl'),
    })

    /**
     * @description: separate uri for creating contacts
     * @author: francis
     */
    .state('app.createContact', {
      url: '/contact/create',
      params: {
        fromTool: '',
      },
      templateUrl: 'assets/views/contacts/create-contact.html',
      resolve: loadSequence(
        'createAccountCtrl',
        'angucomplete',
        'select2',
        'jQuery-Mask-Plugin',
        'datetimepicker',
        'ReferrerAsMemberNewCtrl',
        'AgreementNewCtrl'
      ),
    })

    .state('app.createContactCorporate', {
      url: '/contact/create/corporate',
      params: {
        fromTool: '',
      },
      templateUrl: 'assets/views/contacts/contacts_new.html',
      resolve: loadSequence(
        'ContactsNewCtrl',
        'angucomplete',
        'select2',
        'jQuery-Mask-Plugin',
        'datetimepicker',
        'ReferrerAsMemberNewCtrl',
        'AgreementNewCtrl',
        'ReferralModalCtrl'
      ), // 'jQuery-Mask-Plugin','datetimepicker'
    })

    .state('app.contactOnboarding', {
      url: '/contact/onboarding/:familyId/:clientId',
      templateUrl: 'assets/views/onboarding/onboarding.html',
      resolve: loadSequence(
        'OnboardingCtrl',
        'EntityDetailsCtrl',
        'AsicAppoinmentCtrl',
        'PrincipalDetailsCtrl',
        'RequiredDocumentsCtrl',
        'AdditionalStaffCtrl',
        'IndividualDetailsCtrl',
        'OnboardingQuestionaireCtrl',
        'CompleteOnboardingCtrl',
        'BankDetailsCtrl',
        'jQuery-Mask-Plugin',
        'datetimepicker'
      ),
    })

    .state('app.accreditation', {
      url: '/contact/accreditation/:familyId/:clientId',
      templateUrl: 'assets/views/corporate/adviser/accreditation.html',
      resolve: loadSequence(
        'jQuery-Mask-Plugin',
        'datetimepicker',
        'AccountAccreditationCtrl'
      ),
    })

    .state('app.corporateSendEmail', {
      url: '/corporate/sendEmail/:familyId/:clientId',
      templateUrl: `assets/views/corporate/send_email.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ckeditor-plugin',
        'ckeditor',
        'CorporateSendEmailCtrl',
        'AttachFileMgmtCtrl',
        'EmailManagementCtrl',
        'emailTemplateCtrl',
        'sharingAndPrivacyCtrl',
        'dndLists'
      ),
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send Email',
      },
    })
    .state('app.corporateSendSms', {
      url: '/corporate/sendSms/:contactType/:familyId/:isFromContact',
      templateUrl: `assets/views/corporate/send_sms.html`,
      resolve: loadSequence(
        'ui.select',
        'selectize',
        'ckeditor-plugin',
        'ckeditor',
        'CorporateSendSmsCtrl',
        'AttachFileMgmtCtrl',
        'dndLists'
      ),
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send SMS',
      },
    })
    .state('app.targetManagement', {
      url: '/corporate/targetmanagement/',
      templateUrl: `assets/views/corporate/targetManagement/target_management.html`,
      resolve: loadSequence(
        'CorporateTargetManagementCtrl',
        'CorporateTargetManagementNewCtrl',
        'ngTable'
      ),
    })
    .state('app.brokerSupportRequest', {
      url: '/helpsupport/brokersupportrequest/',
      templateUrl: `assets/views/helpSupport/brokerSupportRequest.html`,
      resolve: loadSequence(
        'DemoVideoModalCtrl',
        'BrokerSupportRequestCtrl',
        'ngTable'
      ),
    })
    .state('app.newRequest', {
      url: '/helpsupport/new/request',
      templateUrl: `assets/views/helpSupport/new/request.html`,
      resolve: loadSequence('BrokerSupportNewRequestCtrl'),
    })
    .state('app.brokerSupportDetails', {
      url: '/helpsupport/view/:ticketId/request/:create',
      templateUrl: `assets/views/helpSupport/view/brokerSupportdetails.html`,
      resolve: loadSequence(
        'BrokerSupportRequestCtrl',
        'DemoVideoModalCtrl',
        'BrokerViewBrokerSupporCtrl',
        'ngTable'
      ),
    })
    .state('app.satisfactionSurvey', {
      url: '/communicate/satisfactionsurvey',
      templateUrl: `assets/views/communicate/satisfactionSurvey.html`,
      resolve: loadSequence('SatisfactionSurveyCtrl', 'ngTable'),
    })
    .state('app.referral', {
      url: '/referral',
      templateUrl: `assets/views/referrals/referral.html`,
      resolve: loadSequence('ReferralCtrl', 'ngTable'),
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.customerCare', {
      url: '/customerCare/:activeTab',
      templateUrl: `assets/views/customerCare/customer_care.html`,
      resolve: loadSequence(
        'CustomerCareCtrl',
        'FixedRateCtrl',
        'PreApprovalCtrl',
        'birthdaysCtrl',
        'AnnualReviewCtrl',
        'PostSettlementCtrl',
        'EmailManagementCtrl',
        'emailTemplateCtrl',
        'AttachFileMgmtCtrl',
        'ngTable',
        'ckeditor-plugin',
        'ckeditor'
      ),
      title: 'Customer Care',
      ncyBreadcrumb: {
        label: 'Customer Care',
      },
    })
    .state('app.business', {
      url: '/business',
      templateUrl: `assets/views/profileManagement/Business/business.html`,
      resolve: loadSequence('BusinessCtrl'),
      title: 'Business',
      ncyBreadcrumb: {
        label: 'Business',
      },
    })
    .state('app.users', {
      url: '/users/:activeTab',
      templateUrl: `assets/views/profileManagement/Users/users.html`,
      resolve: loadSequence('UsersCtrl'),
      title: 'Users / People',
      ncyBreadcrumb: {
        label: 'Users / People',
      },
    })
    .state('app.creditCheck', {
      url: '/creditCheck',
      templateUrl: 'assets/views/creditChecks/credit_checks.html',
      title: 'Credit Check',
      ncyBreadcrumb: {
        label: 'Credit Check',
      },
    })
    .state('connectLogs', {
      url: '/connectLogs',
      templateUrl: 'assets/views/connectLogs.html',
      resolve: loadSequence('ngTable', 'ConnectLogsCtrl'),
      title: 'Connect Logs',
    })

    .state('app.pipeline', {
      url: '/pipeline/:pipelineType',
      template: `<pipeline is-small-device="isSmallDevice"></pipeline>`,
      title: 'Pipeline',
      params: {
        createdContact: '',
      },
    })

    .state('app.opportunity', {
      url:
        '/opportunity?opportunityId&loanId&sourceFamilyId&sourceFamilyName&isContactOpener&isBusiness&tabName',
      template: `<opportunity></opportunity>`,
      title: 'Opportunity',
    })

    .state('app.featureManagement', {
      url: '/corporate/feature-management',
      template: `<feature-management></feature-management>`,
      title: 'Feature Management',
    })
    .state('app.mobileWorkbench', {
      url:
        '/mobile-workbench?LoanID&LoanStatusID&PipelineCardsID&LoanScenarioID&familyId&enquirySource&clientNames',
      params: {
        LoanID: null,
        LoanStatusID: null,
        PipelineCardsID: null,
        LoanScenarioID: null,
        familyId: null,
        enquirySource: null,
        state: null,
        clientNames: '',
      },
      template: `<application-workbench></application-workbench>`,
      title: 'Mobile Workbench',
    })
    .state('app.meetTheCorporateTeam', {
      url: '/meet-the-corporate-team',
      template: `<corporate-team></corporate-team>`,
      title: 'Meet the Corporate Team',
    })
    .state('app.providerInformation', {
      url: '/resources/provider-information',
      template: '<provider-information-list></provider-information-list>',
      title: 'Provider Information',
    })
    .state('app.insuranceProviderInformation', {
      url: '/resources/providers/insurance/:providerId',
      template: '<provider-info type="insurance"></provider-info>',
      title: 'Insurance Provider Information',
    })
    .state('app.loanProviderInformation', {
      url: '/resources/providers/lender/:providerId',
      template: '<provider-info type="lender"></provider-info>',
      title: 'Lender Information',
    })
    .state('app.releaseNotes', {
      url: '/release-notes',
      template: `<release-notes ng-if="vm.configService.feature.releaseNotes"></release-notes>`,
      title: `What's New in MyCRM`,
    })
    .state('app.insuranceApplicationsPipeline', {
      url: '/insurance-application/pipeline',
      template:
        '<insurance-applications-pipeline></insurance-applications-pipeline>',
      title: 'Pipeline',
    });
}

configRouter.$inject = [
  '$stateProvider',
  '$urlRouterProvider',
  '$controllerProvider',
  '$compileProvider',
  '$filterProvider',
  '$provide',
  '$ocLazyLoadProvider',
  'JS_REQUIRES',
  '$locationProvider',
];

export default configRouter;
