import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .directive('pipelineStatsTable', function pipelineStatsTable(
    $timeout,
    overviewDashboardService,
    dashboardCategoryService,
    $rootScope
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/pipelineStatsTable.html',

      link($scope) {
        $scope.labelsNewApp = [];
        $scope.dataNewApp = [];
        $scope.labelsLodged = [];
        $scope.dataLodged = [];
        $scope.labelsPreApp = [];
        $scope.dataPreApp = [];
        $scope.labelsCondApp = [];
        $scope.dataCondApp = [];
        $scope.labelsUncondApp = [];
        $scope.dataUncondApp = [];

        // $scope.graphMode=true;
        function PipelineStatsGraphViewGet() {
          $scope.labelData = [];
          $scope.loanAmount = [];

          overviewDashboardService
            .PipelineStatsGraphViewGet($scope.myParam.Widget_UserID)
            .then(
              response => {
                $scope.pipelineStatsGraphData = response.data;
                $scope.newApp = [];
                $scope.lodged = [];
                $scope.pendingSettlement = [];
                $scope.condApproved = [];
                $scope.uncondApproved = [];

                _.each($scope.pipelineStatsGraphData, item => {
                  if (item.StatusName === 'New Application') {
                    $scope.newApp.push(item);
                  }
                  if (item.StatusName === 'Lodged') {
                    $scope.lodged.push(item);
                  }
                  if (item.StatusName === 'Pending Settlement') {
                    $scope.pendingSettlement.push(item);
                  }
                  if (item.StatusName === 'Conditionally Approved') {
                    $scope.condApproved.push(item);
                  }
                  if (item.StatusName === 'Unconditionally Approved') {
                    $scope.uncondApproved.push(item);
                  }
                });

                // $scope.loadChartCommonOptions();
                $scope.newApplicationChart($scope.newApp);
                $scope.lodgedChart($scope.lodged);
                $scope.pendingSettlementChart($scope.pendingSettlement);
                $scope.condAppChart($scope.condApproved);
                $scope.uncondAppChart($scope.uncondApproved);
              },
              () => {}
            );
        }
        $scope.newApplicationChart = function(newAppArray) {
          $scope.newApp = [];
          $scope.loanAmountNewApp = [];
          $scope.monthNewApp = [];
          $scope.newApp = newAppArray;

          // $scope.labelsNewApp=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'AUG', 'SEP', 'OCT','NOV','DEC'];
          for (let j = 0; j < $scope.newApp.length; j++) {
            const month = $scope.newApp[j].Month;
            $scope.monthNewApp.push(month);

            const amount = $scope.newApp[j].Amount;
            $scope.loanAmountNewApp.push(amount);
          }
          $scope.dataNewApp = [];
          $scope.dataNewApp.push($scope.loanAmountNewApp);
          $scope.labelsNewApp = $scope.monthNewApp;
        };

        $scope.lodgedChart = function(lodgedArray) {
          $scope.lodged = [];
          $scope.loanAmountLodged = [];
          $scope.monthLodged = [];
          $scope.lodged = lodgedArray;

          // $scope.labelsNewApp=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'AUG', 'SEP', 'OCT','NOV','DEC'];
          for (let j = 0; j < $scope.lodged.length; j++) {
            const month = $scope.lodged[j].Month;
            $scope.monthLodged.push(month);

            const amount = $scope.lodged[j].Amount;
            $scope.loanAmountLodged.push(amount);
          }
          $scope.dataLodged = [];
          $scope.dataLodged.push($scope.loanAmountLodged);
          $scope.labelsLodged = $scope.monthLodged;
          //   $scope.loadChartCommonOptions();
        };
        $scope.pendingSettlementChart = pendingSettlementArray => {
          $scope.pendingSettlement = [];
          $scope.loanAmountPreApp = [];
          $scope.monthPreApp = [];
          $scope.pendingSettlement = pendingSettlementArray;

          _.each($scope.pendingSettlement, item => {
            const month = item.Month;
            $scope.monthPreApp.push(month);

            const amount = item.Amount;
            $scope.loanAmountPreApp.push(amount);
          });

          $scope.dataPreApp = [];
          $scope.dataPreApp.push($scope.loanAmountPreApp);
          $scope.labelsPreApp = $scope.monthPreApp;
        };
        $scope.preAppChart = function(preAppArray) {
          $scope.preApproved = [];
          $scope.loanAmountPreApp = [];
          $scope.monthPreApp = [];
          $scope.preApproved = preAppArray;

          // $scope.labelsNewApp=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'AUG', 'SEP', 'OCT','NOV','DEC'];
          for (let j = 0; j < $scope.preApproved.length; j++) {
            const month = $scope.preApproved[j].Month;
            $scope.monthPreApp.push(month);

            const amount = $scope.preApproved[j].Amount;
            $scope.loanAmountPreApp.push(amount);
          }
          $scope.dataPreApp = [];
          $scope.dataPreApp.push($scope.loanAmountPreApp);
          $scope.labelsPreApp = $scope.monthPreApp;
          //  $scope.loadChartCommonOptions();
        };
        $scope.condAppChart = function(condAppArray) {
          $scope.condApproved = [];
          $scope.loanAmountCondApp = [];
          $scope.monthCondApp = [];
          $scope.condApproved = condAppArray;

          // $scope.labelsNewApp=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'AUG', 'SEP', 'OCT','NOV','DEC'];
          for (let j = 0; j < $scope.condApproved.length; j++) {
            const month = $scope.condApproved[j].Month;
            $scope.monthCondApp.push(month);

            const amount = $scope.condApproved[j].Amount;
            $scope.loanAmountCondApp.push(amount);
          }
          $scope.dataCondApp = [];
          $scope.dataCondApp.push($scope.loanAmountCondApp);
          $scope.labelsCondApp = $scope.monthCondApp;
          //  $scope.loadChartCommonOptions();
        };
        $scope.uncondAppChart = function(uncondAppArray) {
          $scope.uncondApproved = [];
          $scope.loanAmountUncondApp = [];
          $scope.monthUncondApp = [];
          $scope.uncondApproved = uncondAppArray;

          // $scope.labelsNewApp=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'AUG', 'SEP', 'OCT','NOV','DEC'];
          for (let j = 0; j < $scope.uncondApproved.length; j++) {
            const month = $scope.uncondApproved[j].Month;
            $scope.monthUncondApp.push(month);

            const amount = $scope.uncondApproved[j].Amount;
            $scope.loanAmountUncondApp.push(amount);
          }
          $scope.dataUncondApp = [];
          $scope.dataUncondApp.push($scope.loanAmountUncondApp);
          $scope.labelsUncondApp = $scope.monthUncondApp;
          //   $scope.loadChartCommonOptions();
        };

        $scope.loadChartCommonOptions = function() {
          $scope.seriesApp = ['First dataset'];
          $scope.showChart = true;
          $scope.colorsSentApp = [
            {
              fillColor: 'rgba(220,220,220,0)',
              strokeColor: '#909ec8',
              pointColor: '#fff',
              pointStrokeColor: '909ec8',
              pointHighlightFill: '#fff',
              pointHighlightStroke: '#909ec8',
            },
          ];

          // Chart.js Options - complete list at http://www.chartjs.org/docs/
          function roundFigure(val) {
            // return '$'+val.toFixed(2);
            return $rootScope.crmCurrency + Math.round(val).toLocaleString();
          }
          $scope.optionsSentApp = {
            // Sets the chart to be responsive
            responsive: true,
            //  tooltipTemplate: "$<%if (label){%><%=label%>: <%}%><%= '$' value %>",
            tooltipTemplate(v) {
              return roundFigure(v.value);
            },
            scaleShowGridLines: true,
            scaleLabel(valuePayload) {
              return roundFigure(parseInt(valuePayload.value, 10));
            },
            // String - Colour of the grid lines
            scaleGridLineColor: 'rgba(0,0,0,.05)',

            // Number - Width of the grid lines
            scaleGridLineWidth: 1,

            // Boolean - Whether the line is curved between points
            bezierCurve: true,

            // Number - Tension of the bezier curve between points
            bezierCurveTension: 0.4,

            // Boolean - Whether to show a dot for each point
            pointDot: true,

            // Number - Radius of each point dot in pixels
            pointDotRadius: 4,

            // Number - Pixel width of point dot stroke
            pointDotStrokeWidth: 1,

            // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
            pointHitDetectionRadius: 20,

            // Boolean - Whether to show a stroke for datasets
            datasetStroke: true,

            // Number - Pixel width of dataset stroke
            datasetStrokeWidth: 2,

            // Boolean - Whether to fill the dataset with a colour
            datasetFill: true,
          };
        };
        $scope.interval = 'allDates';

        function pipelineStatsTableGet() {
          overviewDashboardService
            .WidgetPipelineStatsTableViewGet($scope.myParam.Widget_UserID)
            .then(
              response => {
                $scope.pipelineStatsTableData = [];
                $scope.showSlick = false;
                const totalData = response.data.length;
                const round = Math.floor(totalData / 4) * 4 - 1;
                const modStart = Math.floor(totalData / 4) * 4;
                const mod = Math.floor(totalData % 4);
                for (let i = 0; i < round; i += 4) {
                  const testData = [];
                  testData.push(response.data[i]);
                  testData.push(response.data[i + 1]);
                  testData.push(response.data[i + 2]);
                  testData.push(response.data[i + 3]);

                  $scope.pipelineStatsTableData.push(testData);
                }

                if (parseInt(mod, 10) !== 0) {
                  const lastItem = [];
                  for (let i = modStart; i < totalData; i++) {
                    lastItem.push(response.data[i]);
                  }
                  $scope.pipelineStatsTableData.push(lastItem);
                }

                $scope.dataLoaded = true;
                $scope.showSlick = true;

                if ($scope.pipelineStatsTableData.length > 0) {
                  $timeout(() => {
                    try {
                      $scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1000);
                }
              },
              () => {}
            );
        }

        function init() {
          // CommissionRecievedChartGet();
          PipelineStatsGraphViewGet();
          pipelineStatsTableGet();
        }
        $scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'pipelineStatsTablePopover.html',
          title: 'Title',
        };

        $scope.doAction = function(msg) {
          $scope.categoryObj = {};

          if (msg === 'Graph') {
            $scope.categoryObj.categoryName = 'Graphic Widget';
            $scope.categoryObj.userWidgetID = $scope.myParam.Widget_UserID;
          } else {
            $scope.showSlick = false;
            $scope.categoryObj.categoryName = 'Big Widget';
            $scope.categoryObj.userWidgetID = $scope.myParam.Widget_UserID;
          }

          dashboardCategoryService.CategorySet($scope.categoryObj, () => {
            init();
          });

          $scope.graphMode = msg === 'Graph';
        };

        // TABLE

        $scope.interval = 'allDates';
        $scope.slickConfig = { method: {} };

        // END TABLE

        $scope.loadChartCommonOptions();
        init();
      },
    };
  });
