import './style.scss';
import template from './surveyModal.html';
import controller from './surveyModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
  },
};
