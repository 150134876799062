import template from './searchExistingClientPanel.html';
import controller from './searchExistingClientPanelCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAdviser: '<',
    existingFamilies: '<',
    partyType: '<',
    isPersons: '<',
    searchLabel: '@',
    onOpenAddNewModal: '&',
    onSelectClient: '&',
  },
};
