import template from './mailTemplatesSelection.html';
import MailTemplatesSelectionCtrl from './mailTemplatesSelectionCtrl';

export default {
  template,
  controller: MailTemplatesSelectionCtrl,
  controllerAs: 'vm',
  bindings: {
    onInitialized: '&',
    onTemplateSelected: '&',
    selectedTemplate: '<',
    refreshSelection: '=',
    module: '<',
  },
};
