import angular from 'angular';
import moment from 'moment';

// filter to convert utc to user date
angular.module('app').filter('dateUTCFormat', function dateUTCFormat() {
  return function(date, filter) {
    return moment(date)
      .utc()
      .format(filter);
  };
});
