export const SUBSCRIPTIONTYPE = {
  ONLINE_FACT_FIND: 1,
  SMS: 2,
  NPS: 3,
  CAMPAIGN_BREEZE: 4,
  BROKERPEDIA: 5,
  CREDIT_CHECK: 6,
  PRICE_FINDER: 7,
  MARKETING_AUTOMATION: 8,
  E_SIGN: 9,
};
