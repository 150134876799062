import template from './insuranceBenefitWidget.html';
import controller from './insuranceBenefitWidgetCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    benefitId: '<',
    benefitName: '<',
    benefitIcon: '<',
    benefitIndex: '<',
    benefitCover: '<',
    isNewBenefit: '<',
    person: '<',
    previewFieldName: '<',
    isPreviewValueFromOptions: '<',
    fieldsConfig: '<',
    onEditModeToggle: '&',
    onUpdate: '&',
    onSave: '&',
    onDelete: '&',
  },
};
