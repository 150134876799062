import angular from 'angular';
import selectMergeContactType from './selectMergeContactType';
import duplicateContacts from './duplicateContacts';
import marryContacts from './marryContacts';

export default angular.module('mergeContact.scenes', [
  selectMergeContactType,
  duplicateContacts,
  marryContacts,
]).name;
