class ProductFinderService {
  constructor(loanToolsService, uiService, $q) {
    'ngInject';

    this.loanToolsService = loanToolsService;
    this.uiService = uiService;
    this.$q = $q;
  }

  returnAddedSpreadsheetToProductList(productList) {
    const defer = this.$q.defer();

    if (!this.uiService.isCountry('Australia')) {
      return defer.resolve(productList);
    }

    const productListCopy = [];

    this.loanToolsService.rubikServiceabilityCalcGet().then(response => {
      const spreadSheetList = response.data;

      productList &&
        productList.forEach(product => {
          product.spreadSheetsArray = [];

          Object.keys(spreadSheetList).forEach(key => {
            if (key === product.LenderID) {
              product.spreadSheetsArray.push(...spreadSheetList[key]);
            }
          });

          const productCopy = product.attributes
            ? { ...product, ...product.attributes }
            : product;
          productListCopy.push(productCopy);
        });

      return defer.resolve(productListCopy);
    });

    return defer.promise;
  }
}

export default ProductFinderService;
