const configData = {};

export function setConfigData(data) {
  Object.assign(configData, data);
}

export function getConfigData() {
  return { ...configData };
}

export function getHashConfig() {
  // webpack define global variable
  return process.env.COMMIT_HASH || ''; // eslint-disable-line
}
