import './style.scss';
import template from './addTrust.html';
import AddTrustCtrl from './addTrustCtrl';

export default {
  template,
  controller: AddTrustCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    partyType: '<',
    entity: '<',
  },
};
