import angular from 'angular';
import AssetFinanceProductFinder from './assetFinanceProductFinder';
import compareView from './compareView';
import compareTableColumn from './compareView/compareTableColumn';
import './style.scss';

export default angular
  .module('components.assetFinanceProductFinder', [])
  .component('assetFinanceProductFinder', AssetFinanceProductFinder)
  .component('compareTableColumn', compareTableColumn)
  .component('compareView', compareView).name;
