import template from './opportunityMain.html';

export default {
  template,
  bindings: {
    activeTab: '<',
    opportunityDetails: '<',
    onRefreshDetails: '&',
    opportunityId: '<',
    opportunityTitle: '<',
    selectedAdviserId: '<',
    onAdviserChange: '&',
    loanId: '<',
    familyId: '<',
    adviserId: '<',
  },
};
