import template from './insuranceQuoteModsWidget.html';
import controller from './insuranceQuoteModsWidgetCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    quoteData: '<',
    onQuoteUpdated: '&',
    onQuoteUpdating: '&',
  },
};
