export function clientWindowWatcher(newValue) {
  const screenSize = newValue.w;
  let isNotesPanelDisplayed;
  let isAdditionalPanelDisplayed;

  if (newValue.w < 992) {
    isNotesPanelDisplayed = false;
    isAdditionalPanelDisplayed = false;
  } else {
    isNotesPanelDisplayed = true;
    isAdditionalPanelDisplayed = true;
  }

  return { screenSize, isNotesPanelDisplayed, isAdditionalPanelDisplayed };
}
export function isMobileView(windowWidth) {
  const mobileWidthBreakpoint = 991;
  return parseInt(windowWidth, 10) <= mobileWidthBreakpoint;
}
