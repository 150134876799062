export function sortArray(data, sortCol, sortType) {
  const asc = (a, b) => {
    if (!a[sortCol] || !b[sortCol]) return;
    return a[sortCol].localeCompare(b[sortCol]);
  };
  const desc = (a, b) => {
    if (!a[sortCol] || !b[sortCol]) return;
    return b[sortCol].localeCompare(a[sortCol]);
  };

  data.sort(sortType === 'ASC' ? asc : desc);
  return data;
}

export function changeSorting(tableParams, $column) {
  if (!$column.sortType) {
    $column.sortType = 'asc';
  } else if ($column.sortType === 'asc') {
    $column.sortType = 'desc';
  } else {
    $column.sortType = 'asc';
  }
  tableParams.sorting($column.sortable(), $column.sortType);
  tableParams.reload();
}
