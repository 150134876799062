import angular from 'angular';
import lmPdfHeader from './lmPdfHeader';
import lmPdfFooter from './lmPdfFooter';
import lmPdfHeaderShort from './lmPdfHeaderShort';

export default angular
  .module('components.lmPdfHeader', [])
  .component('lmPdfHeader', lmPdfHeader)
  .component('lmPdfFooter', lmPdfFooter)
  .component('lmPdfHeaderShort', lmPdfHeaderShort).name;
