import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';

export default class FactFindSidebarCtrl {
  constructor(
    $state,
    $uibModal,
    currentUserService,
    onlineFactFindModal,
    usersSubscriptionService,
    loanScenarioService,
    contactService,
    configService
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.currentUserService = currentUserService;
    this.onlineFactFindModal = onlineFactFindModal;
    this.usersSubscriptionService = usersSubscriptionService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.configService = configService;
  }

  $onInit() {
    const {
      userSubscription,
      onlineFactFind,
      splitOnlineFactFind,
      reviewFactFind,
    } = this.configService.feature;
    this.onlineFactFindSubscription = (userSubscription && onlineFactFind) || 0;
    this.splitOnlineFactFind = splitOnlineFactFind || 0;
    this.reviewFactFind = reviewFactFind || 0;
    this.factFindSubData = {};
    this.disabledShowModal = true;

    this.isReviewFactFindShow = false;
    this.showSplitOnlineFF =
      !this.onlineFactFindSubscription && this.splitOnlineFactFind;
    this.getClientInfo();
    this.onlineFactFindSubscription && this.getAllocatedAdviserInfo();
  }

  openFactFindModal() {
    const checkValid =
      this.currentUserService.isAU &&
      (this.factFindSubData && !this.factFindSubData.isActive) &&
      this.onlineFactFindSubscription;
    if (checkValid) {
      this.openFactFindSubscription();
    } else {
      this.checkFactFindFeature();
    }
  }

  checkFactFindFeature() {
    if (this.splitOnlineFactFind) {
      this.openGenerateFactFindModal();
    } else {
      this.openOnlineFactFindForm();
    }
  }

  openGenerateFactFindModal(type = 'General') {
    const { loanAppId: loanId } = this;
    this.onlineFactFindModal
      .open({
        loanId,
        type,
        isShowReviewButton: this.isReviewFactFindShow,
      })
      .then(response => {
        if (!response) return;
        const { factFindSend, factFindType } = response;
        if (factFindSend) this.getClientInfo();
        else if (!factFindSend && factFindType)
          this.openGenerateFactFindModal(factFindType);
      });
  }

  openFactFindSubscription() {
    const props = {
      familyId: this.familyId,
      subscriptionData: this.factFindSubData,
      orderedById: this.currentUserService.familyId,
    };
    this.usersSubscriptionService.subscribeToService(props).then(response => {
      if (!response) return;
      this.getSubscription(response);
    });
  }

  openOnlineFactFindForm() {
    const { loanAppId: loanId } = this;
    this.$uibModal.open({
      templateUrl:
        'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
      controller: OpenOnlineFactFindForm,
      controllerAs: 'vm',
      resolve: {
        loanId: () => loanId,
      },
      size: 'lg',
      windowClass: 'online-fact-find-form',
    });
  }

  getSubscription(afterSubscribeState = false) {
    if (this.familyId && this.onlineFactFindSubscription) {
      this.contactService.subscriptionInfo(this.familyId).then(response => {
        if (response && response.length) {
          this.factFindSubData =
            response &&
            response.find(
              obj =>
                obj.subscriptionProductId === SUBSCRIPTIONTYPE.ONLINE_FACT_FIND
            );
          const isFactFindSubscriptionActive =
            this.currentUserService.isAU &&
            (this.factFindSubData && this.factFindSubData.isActive);
          this.showSplitOnlineFF =
            this.splitOnlineFactFind && isFactFindSubscriptionActive;
          if (!afterSubscribeState) return;
          this.checkFactFindFeature();
        }
      });
    }
  }

  getAllocatedAdviserInfo() {
    const { loanAppId } = this;
    this.loanScenarioService.scenarioDetailsGet(loanAppId).then(({ data }) => {
      if (data && data.AdviserId) {
        this.LoanApplicationDetail = data;
        this.familyId = parseInt(this.LoanApplicationDetail.AdviserId, 10);
        this.CountryID = this.LoanApplicationDetail.CountryID;
        this.getSubscription();
      }
    });
  }

  getClientInfo() {
    const { loanAppId } = this;
    this.onlineFactFindModal
      .getInvolvePartyToReview(loanAppId)
      .then(response => {
        this.isReviewFactFindShow =
          (response && response.clients && response.clients.length) || false;
      });
  }

  showReviewFactFind() {
    return (
      this.showSplitOnlineFF && this.reviewFactFind && this.isReviewFactFindShow
    );
  }
}
