import _ from 'lodash';
import swal from 'sweetalert';
import { REPORT_TYPE } from 'Common/constants/reportType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { JASPER_FILTERS } from 'Common/constants/jasperReport';
import { getMonthList } from 'Common/utilities/month';
import { REFERRAL_TYPE } from 'Common/constants/referralType';
import {
  referrerOrgDefault,
  referrerDefault,
  reportingState,
  pipelineStatus,
  lenderCategory,
  businessUnit,
  referralType,
  loanStatus,
  dateRange,
  lender,
} from 'Common/default/jasperReport';

class JasperReportCtrl {
  constructor(
    pipelineStatusService,
    jasperReportService,
    sendReferralService,
    currentUserService,
    communicateService,
    commissionService,
    corporateService,
    optionsService,
    jasperService,
    configService,
    $window,
    $filter,
    $scope,
    $q,
    $state
  ) {
    'ngInject';

    this.selectReferrerOrg = this.selectReferrerOrg.bind(this);
    this.selectReferrer = this.selectReferrer.bind(this);
    this.pipelineStatusService = pipelineStatusService;
    this.jasperReportService = jasperReportService;
    this.sendReferralService = sendReferralService;
    this.currentUserService = currentUserService;
    this.communicateService = communicateService;
    this.commissionService = commissionService;
    this.corporateService = corporateService;
    this.optionsService = optionsService;
    this.jasperService = jasperService;
    this.configService = configService;
    this.$window = $window;
    this.$filter = $filter;
    this.$scope = $scope;
    this.$q = $q;
    this.$state = $state;
  }
  getReportTypeSettings() {
    this.jasperReportService.getReportTypeSetting().then(types => {
      this.reportTypes = types;
      this.reportType =
        this.reportTypes && this.reportTypes.length
          ? this.reportTypes[0]
          : null;
    });
  }
  getReferrerOrganizations() {
    this.jasperService.getReferrerOrganizations().then(data => {
      if (!data || !data.length) return;
      const referrerOrg = _.uniqBy(data, org =>
        [org.referrerOrgId, org.referrerOrgName].join()
      );
      this.referrerOrgList = this.isCorporateUser
        ? referrerOrg
        : [referrerOrgDefault, ...referrerOrg];
      this.referrerOrgId = 0;
      this.referrerId = 0;
    });
  }
  getReferrers(referrerOrg) {
    this.referrerId = 0;
    if (!referrerOrg) return;
    const params = {
      referrerOrg,
    };
    this.jasperService.getReferrers(params).then(data => {
      if (!data || !data.length) return;
      const referrer = _.uniqBy(data, ref =>
        [ref.referrerId, ref.referrerName].join()
      );
      this.referrerList = this.isCorporateUser
        ? referrer
        : [referrerDefault, ...referrer];
    });
  }
  getUnconditionalApprovedDate() {
    this.date.start = new Date(this.date.year, this.date.month - 6, 1);
    this.date.end = new Date(this.date.year, this.date.month, 0);
  }
  getCurrentMonthDate() {
    this.date.start = new Date(this.date.year, this.date.month, 1);
    this.date.end = new Date(this.date.year, this.date.month + 1, 0);
  }
  getLocalLang() {
    this.adviserLocalLang = {
      ...this.jasperReportService.getLocalLang('Advisers'),
    };
    this.reportingStateLocalLang = {
      ...this.jasperReportService.getLocalLang('States'),
    };
    this.lenderLocalLang = {
      ...this.jasperReportService.getLocalLang('Lenders'),
    };
    this.businessUnitLocalLang = {
      ...this.jasperReportService.getLocalLang('Business Units'),
    };
    this.pipelineStatusLocalLang = {
      ...this.jasperReportService.getLocalLang('Pipeline Status'),
    };
    this.loanStatusLocalLang = {
      ...this.jasperReportService.getLocalLang('Loan Status'),
    };
    this.lenderCategoryLocalLang = {
      ...this.jasperReportService.getLocalLang('Categories'),
    };
    this.referralTypeLocalLang = {
      ...this.jasperReportService.getLocalLang('Referral Types'),
    };
  }
  getCommissionBroker() {
    if (this.adviserListForCommission && this.adviserListForCommission.length) {
      this.parseAdviserMultiSelect(this.adviserListForCommission);
      return;
    }
    this.commissionService.getAllBrokers().then(response => {
      const { data } = response;
      if (!data || !data.length) return;
      const hasOnlyOneAdviser = data.length === 2;
      const adviserList = hasOnlyOneAdviser ? data.slice(1) : data;
      this.adviserListForCommission =
        adviserList &&
        adviserList
          .map((adviser, index) => {
            const { BrokerID: value, BrokerFullName: name } = adviser;
            const ticked = index === 0;
            return { ticked, value, name };
          })
          .sort((left, right) => {
            return left.value < right.value ? -1 : 1;
          });
      this.parseAdviserMultiSelect(this.adviserListForCommission);
    });
  }
  getLenderCategory() {
    this.optionsService.getCategoryLendersList().then(data => {
      this.parseLenderCategory(data);
    });
  }
  parseLenderCategory(data) {
    if (!data || !data.length) return;
    this.lenderCategoryList = [lenderCategory, ...data];
    this.lenderCategoryRaw = [lenderCategory, ...data];
    this.lenderCategorySelected = [lenderCategory];
  }
  parseAdviserMultiSelect(data) {
    if (!data || !data.length) return;
    this.adviserList = [...data];
    this.adviserRaw = [...data];
    this.adviserSelected = [data[0]];
  }
  parseAdviseSingleSelect(data) {
    if (!data || !data.length) return;
    this.adviserListForSingleSelect = [...data];
  }
  parseReportingState(response) {
    const { data } = response;
    if (!data || !data.length) return;
    const reportingList =
      data &&
      data.map(row => {
        row.ticked = false;
        return row;
      });
    this.reportingStateRaw = [reportingState, ...reportingList];
    this.reportingStateList = [reportingState, ...reportingList];
    this.reportingStateSelected = [reportingState];
  }
  parseLender(response) {
    const { data } = response;
    if (!data || !data.length) return;
    const lenderList =
      data &&
      data.map(row => {
        row.ticked = false;
        return row;
      });
    this.lenderRaw = [lender, ...lenderList];
    this.lenderList = [lender, ...lenderList];
    this.lenderSelected = [lender];
  }
  parseBusinessUnit(response) {
    const { data } = response;
    if (!data || !data.length) return;
    const businessUnitList =
      data &&
      data.map(row => {
        row.ticked = false;
        return row;
      });
    this.businessUnitRaw = [businessUnit, ...businessUnitList];
    this.businessUnitList = [businessUnit, ...businessUnitList];
    this.businessUnitSelected = [businessUnit];
  }
  parsePipelineStatus(data) {
    this.pipelineStatusPartialList = this.pipelineStatusService.getPipelineStatus();
    this.pipelineStatusFullList = [pipelineStatus, ...data];
    this.assignPipelineStatus();
  }
  parseDateRangeOption(data) {
    if (!data || !data.length) return;
    this.dataRangeList = [dateRange, ...data];
  }
  parseDateOption() {
    this.monthList = getMonthList();
    this.monthSelected = this.date.month + 1;
  }
  parseAdviserForSingleSelect(response) {
    const { data } = response;
    this.advisers = data && data.length ? data.filter(x => x.FullName) : [];
    this.adviserId =
      this.advisers && this.advisers.length
        ? this.advisers[0].FamilyId
        : this.currentUserService.familyId || 0;
  }
  parseLoanStatus(data) {
    if (!data || !data.length) return;
    this.loanStatusRaw = [loanStatus, ...data];
    this.loanStatusList = [loanStatus, ...data];
    this.loanStatusSelected = [loanStatus];
  }
  parseReferralType(response) {
    const { data } = response;
    if (!data || !data.length) return;
    this.types = data;
    if (this.currentUserService.isAU) {
      const { RAY_WHITE, WEALTH_MARKET, OTHER, LOAN_MARKET } = REFERRAL_TYPE;
      const typesForAu = [RAY_WHITE, WEALTH_MARKET, OTHER, LOAN_MARKET];
      this.types = data.filter(type => {
        return typesForAu.includes(type.ReferralTypeID);
      });
    }
    this.referralTypeRaw = [referralType, ...this.types];
    this.referralTypeList = [referralType, ...this.types];
    this.referralTypeSelected = [referralType];
  }
  assignPipelineStatus() {
    const isFullList =
      this.reportType.filters &&
      this.reportType.filters.length &&
      this.reportType.filters.includes(JASPER_FILTERS.PIPELINE_STATUS_FULL);
    const data = isFullList
      ? this.pipelineStatusFullList
      : this.pipelineStatusPartialList;
    if (!data || !data.length) return;
    this.pipelineStatusRaw = [...data];
    this.pipelineStatusList = [...data];
    this.pipelineStatusSelected = [data[0]];
  }
  openDate(dateField) {
    this.date[dateField] = !this.date[dateField];
  }
  selectReferrerOrg(referrerOrg) {
    if (!referrerOrg || !referrerOrg.originalObject) {
      this.$scope.$broadcast('angucomplete-alt:clearInput', 'referrer');
      this.referrerOrgId = 0;
      return;
    }
    const { referrerOrgId } = referrerOrg.originalObject;
    this.referrerOrgId = referrerOrgId;
    this.getReferrers(referrerOrgId);
  }
  selectReferrer(referrer) {
    if (!referrer || !referrer.originalObject) {
      this.referrerId = 0;
      return;
    }
    const { referrerId } = referrer.originalObject;
    this.referrerId = referrerId;
  }
  selectReportType(reportType) {
    if (
      reportType &&
      reportType.reportTypeId === REPORT_TYPE.AVERAGE_UNCONDITIONAL_APPROVED
    ) {
      this.getUnconditionalApprovedDate();
    } else {
      this.getCurrentMonthDate();
    }
    if (
      reportType &&
      reportType.reportTypeId === REPORT_TYPE.COMMISSION_REPORT
    ) {
      this.nonCommissionAdvisers = [...this.adviserList];
      this.getCommissionBroker();
    } else if (this.nonCommissionAdvisers) {
      this.parseAdviserMultiSelect(this.nonCommissionAdvisers);
    }
    this.dateFilteredOn = 0;
    if (reportType.reportTypeId === REPORT_TYPE.LOAN_DETAILS_REPORT) {
      this.dateFilteredOnList = this.loanFilteredOn;
      this.dateFilteredOnLabelFor = 'loan';
    } else {
      this.dateFilteredOnList = this.opportunityFilteredOn;
      this.dateFilteredOnLabelFor = 'opportunity';
    }
    this.assignPipelineStatus();
    this.dateRangeOption = 0;
  }
  displayAdviserSingle() {
    return (
      this.currentUserService.accessType !== ACCESS_TYPE.ADVISER &&
      this.displayFilters(this.jasperFilters.ADVISER_SINGLE)
    );
  }
  displayCustomDate() {
    return (
      !this.dateRangeOption &&
      (this.displayFilters(this.jasperFilters.CUSTOM_DATE) ||
        this.displayFilters(this.jasperFilters.RELATIVE_DATE))
    );
  }
  displayReferrerDetails() {
    return (
      this.configService.feature.jasperReportReferrerDetails &&
      this.displayFilters(this.jasperFilters.REFERRER_DETAILS)
    );
  }
  displayLender() {
    return (
      this.displayFilters(this.jasperFilters.LENDER) ||
      this.displayFilters(this.jasperFilters.LENDER_ID)
    );
  }
  resetReportDefault() {
    this.report.exportToPdf = null;
    this.report.exportToExcel = null;
    this.report.isCancelled = false;
  }
  generateReport() {
    this.resetReportDefault();
    this.getJasperParameter();
    this.getJasperReportToken().then(data => {
      if (!data || data.status === 'inactive') return;
      this.report.isLoading = true;
      this.report = { ...this.report, ...data };
      this.report.jasperUrl = this.jasperReportService.getJasperReportUrl(
        this.reportType
      );
      this.jasperReportService.loadJasperReport(this.report);
    });
  }
  displayReportContent() {
    const isActive = this.report.status !== 'inactive';
    const isNotCancelled = !this.report.isCancelled;
    return isActive && isNotCancelled;
  }
  disableGenerateButton() {
    return this.report.isLoading || this.report.status === 'inactive';
  }
  setDashboardWidgetFilter() {
    if (!this.$state.params.adviser || !this.$state.params.dateFilter) return;
    this.adviserList.map(obj => {
      obj.ticked = false;
      const selectedAdviser = Array.isArray(this.$state.params.adviser)
        ? this.$state.params.adviser.find(
            item => obj.value === parseInt(item, 10)
          )
        : obj.value === parseInt(this.$state.params.adviser, 10);
      if (
        selectedAdviser ||
        (this.$state.params.adviser === '0' && obj.value === -1)
      ) {
        obj.ticked = true;
      }
      return obj;
    });

    switch (this.$state.params.dateFilter) {
      case 'This Week':
        this.weekStart = this.fullDate.getDate() - this.fullDate.getDay() + 1;
        this.weekEnd = this.weekStart + 6;
        this.date.start = new Date(
          this.date.year,
          this.date.month + 1,
          this.weekStart
        );
        this.date.end = new Date(
          this.date.year,
          this.date.month + 1,
          this.weekEnd
        );
        break;
      case 'This Calendar Year':
        this.date.start = new Date(this.date.year, 0, 1);
        this.date.end = new Date(this.fullDate);
        break;
      case 'This Financial Year':
        this.date.start = new Date(this.date.year, 6, 1);
        this.date.end = new Date(this.fullDate);
        break;
      case 'This Month':
        this.date.start = new Date(this.date.year, this.date.month, 1);
        this.date.end = new Date(this.date.year, this.date.month + 1, 0);
        break;
      default:
        break;
    }
  }
  $onInit() {
    this.fullDate = new Date();
    this.date = {
      endIsOpen: false,
      startIsOpen: false,
      year: this.fullDate.getFullYear(),
      month: this.fullDate.getMonth(),
    };
    this.report = {};
    this.getReferrerOrganizations();
    this.getLocalLang();
    this.getReportTypeSettings();
    this.parseDateOption();
    this.getCurrentMonthDate();
    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    this.onItemSelect = this.jasperReportService.onItemSelect;
    this.onItemReset = this.jasperReportService.onItemReset;
    this.displayFilters = this.jasperReportService.displayFilters;
    this.getJasperParameter = this.jasperReportService.getJasperParameter;
    this.getJasperReportToken = this.jasperReportService.getJasperReportToken;
    this.getJasperParameter = this.jasperReportService.getJasperParameter;
    this.getJasperReportParameterArray = this.jasperReportService.getJasperReportParameterArray;
    this.opportunityFilteredOn = ['Date Created', 'Date Updated'];
    this.loanFilteredOn = [
      'Loan Lodged Date',
      'Loan Approved Date',
      'Loan Settlement Date',
    ];
    this.dateFilteredOnList = this.opportunityFilteredOn;
    this.dateFilteredOnLabelFor = 'opportunity';
    this.dateFilteredOn = 0;
    this.dateRangeOption = 0;
    const sameOrg = this.isCorporateUser ? 0 : 1;
    const isLoanWriterOnly =
      this.currentUserService.isAssistant ||
      this.currentUserService.isAdminAssistant;
    this.referrerList = this.isCorporateUser ? [] : [referrerDefault];
    this.jasperFilters = JASPER_FILTERS;
    this.loansSplitByList = ['Loan Amount', 'Number of Loans'];
    this.loansSplitBySelected = 0;
    this.getLenderCategory();
    const promises = [
      this.jasperReportService.getAdviserForMultiSelect(isLoanWriterOnly),
      this.corporateService.reportingStatesGet(),
      this.communicateService.lenderListGet(0),
      this.corporateService.businessUnitGet(),
      this.jasperService.getPipelineStatus(),
      this.jasperService.getDateRanges(),
      this.corporateService.getAdvisersDropdown(
        this.currentUserService.familyId,
        sameOrg,
        false,
        isLoanWriterOnly
      ),
      this.jasperService.getLoanStatus(),
      this.sendReferralService.referralReferralTypeGet(),
    ];
    this.report.cancel = () => {
      this.report.isLoading = false;
      this.report.isCancelled = true;
    };
    this.$q
      .all(promises)
      .then(responses => {
        if (!responses || !responses.length) return;
        const [
          adviserMultiSelectResponse,
          reportingStateResponse,
          lenderResponse,
          businessUnitResponse,
          pipelineStatusResponse,
          dateRangeOptionResponse,
          adviserForSingleSelectResponse,
          loanStatusResponse,
          referralTypeResponse,
        ] = responses;
        if (adviserMultiSelectResponse)
          this.parseAdviserMultiSelect(adviserMultiSelectResponse);
        if (reportingStateResponse)
          this.parseReportingState(reportingStateResponse);
        if (lenderResponse) this.parseLender(lenderResponse);
        if (businessUnitResponse) this.parseBusinessUnit(businessUnitResponse);
        if (pipelineStatusResponse)
          this.parsePipelineStatus(pipelineStatusResponse);
        if (dateRangeOptionResponse)
          this.parseDateRangeOption(dateRangeOptionResponse);
        if (adviserForSingleSelectResponse)
          this.parseAdviserForSingleSelect(adviserForSingleSelectResponse);
        if (loanStatusResponse) this.parseLoanStatus(loanStatusResponse);
        if (referralTypeResponse) this.parseReferralType(referralTypeResponse);
        this.getJasperParameter();
        this.getJasperReportToken()
          .then(data => {
            this.report = {
              ...this.report,
              ...data,
            };
            const isInactive = !data || data.status === 'inactive';
            this.report.isLoading = !isInactive;
            if (isInactive) return;
            return this.jasperReportService.loadVisualizeJs(this.report);
          })
          .then(() => {
            this.report.jasperUrl = this.jasperReportService.getJasperReportUrl(
              this.reportType
            );
            this.jasperReportService.loadJasperReport(this.report);
          });
        if (this.$state.params.adviser && this.$state.params.dateFilter) {
          this.setDashboardWidgetFilter();
        }
      })
      .catch(() => {
        swal('Server Error', `Unable to load lookups for reports.`, 'error');
      });
  }
}

export default JasperReportCtrl;
