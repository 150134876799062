import {
  PERSON_PARTY_TYPES,
  ENTITY_PARTY_TYPES,
} from 'Common/constants/partyTypes';

export default class SearchExistingClientPanelCtrl {
  constructor(contactModelService, $timeout) {
    'ngInject';

    this.contactModelService = contactModelService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.searchClientName = '';
    this.showClientList = false;
    this.clientList = [];
    this.isSearching = false;

    this.searchType = this.isPersons
      ? PERSON_PARTY_TYPES.GUARANTOR
      : ENTITY_PARTY_TYPES.COMPANY;
  }

  searchClient() {
    this.clientList = [];
    this.$timeout.cancel(this.searchTimeout);
    if (this.searchClientName.length < 3) {
      this.showClientList = false;
      return;
    }
    const params = {
      startsWith: '*',
      searchCriteria: this.searchClientName,
      bypassFilter: true,
      contactTypes: this.isPersons ? 'Client' : 'Entity',
      adviserFamilyId: this.loanAdviser.familyId,
    };
    this.isSearching = false;
    this.searchTimeout = this.$timeout(() => {
      this.showClientList = true;
      this.isSearching = true;
      this.contactModelService
        .familyListGet(params)
        .then(response => {
          this.isSearching = false;
          if (!response || !response.data) {
            this.clientList = [];
            return;
          }
          const { FamilyList: familyList } = response.data;
          if (!familyList.length) return;
          const clientList = familyList.filter(obj => {
            if (this.existingFamilies.includes(obj.FamilyID)) return false;
            return obj;
          });
          this.clientList = clientList;
        })
        .catch(() => {
          this.showClientList = false;
        });
    }, 300);
  }

  selectClient(client) {
    this.searchClientName = '';
    this.onSelectClient({ $e: { client } });
  }
}
