import template from './numberSpinner.html';
import controller from './numberSpinnerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    minValue: '<',
    maxValue: '<',
    isRequired: '<',
    textToAppend: '@',
    componentId: '@',
  },
};
