import angular from 'angular';
import { values } from 'lodash';
import { ASSET_TYPE } from 'Common/constants/securityValues';
import toastr from 'toastr';
import { favouriteProductBuilderForUI } from 'Common/mappers/serviceability';

export default class AssetFinanceProductFinderCtrl {
  constructor(
    $timeout,
    $uibModal,
    loanProfilerService,
    optionsService,
    NgTableParams,
    currentUserService,
    productFinderService,
    lenderRatesService,
    bouncingTooltipService,
    iFinanceAppService,
    serviceabilityService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.loanProfilerService = loanProfilerService;
    this.optionsService = optionsService;
    this.NgTableParams = NgTableParams;
    this.currentUserService = currentUserService;
    this.productFinderService = productFinderService;
    this.lenderRatesService = lenderRatesService;
    this.bouncingTooltipService = bouncingTooltipService;
    this.iFinanceAppService = iFinanceAppService;
    this.serviceabilityService = serviceabilityService;
    this.ASSET_TYPE = ASSET_TYPE;
  }

  $onInit() {
    this.clientType = [];
    this.familyMembersData = {
      loanAmount: 0,
      commissionPercent: 0,
      residualPercent: 0,
      documentFeePercent: 0,
      propertyOwner: false,
    };
    this.loanProviderLendersListGetAll = [];
    this.loanStructureList = [];
    this.assetTypeList = [];
    this.financeTypeList = [];
    this.generalSettingsValid = true;
    this.crmCurrency = '$';
    this.calculatedAmount = {
      ResidualAmount: 0,
      CommissionAmount: 0,
      DocumentFeeAmount: 0,
    };
    this.disableSearchFilter = true;

    this.filterOptions = {
      saleTypeList: [],
      assetVariantList: [],
      lenderList: [],
    };
    this.filterDataList = {};

    this.fromLenderRateObject = {
      isFromLenderRates: !!Object.keys(
        this.lenderRatesService.getLenderRateInfoForProductFinder()
      ).length,
    };

    this.asset = {
      vehicleDetails: {},
    };

    this.getFilterData();
    this.resetFilter();
    this.getSelectedProductDetailsGetByLoanStructure();
  }

  resetFilter() {
    this.filterDataList = {
      saleType: 'All',
      assetVariant: 'All',
      lender: 'All',
      establishmentFee: false,
      accountKeeping: false,
    };
  }

  getFilterData() {
    this.getGeneralDropdown();
    this.getGeneralFilter();

    this.loanProfilerService
      .getServiceabilityFiltersParamters()
      .then(response => {
        if (!response || !response.data) return;
        const { data } = response;

        if (data.Lenders && data.Lenders.length)
          this.loanProviderListFormat(data.Lenders);
        this.getLoanStructure(true);
      });
  }

  getGeneralFilter() {
    this.serviceabilityService
      .getFilteringProductsGeneralSettings(this.brokerEventId)
      .then(res => {
        if (!res) return;
        this.familyMembersData = res;
        const maxTerm = 7;
        this.familyMembersData.loanTerm =
          parseFloat(res.loanTerm) > maxTerm ? '' : res.loanTerm;
        this.asset.vehicleDetails = {
          redBookCode: res.redBookCode,
          model: res.description,
          vehicleClassID: res.vehicleClassType,
          make: res.vehicleMake,
          family: res.vehicleFamily,
          month: res.vehicleMonth,
          year: res.vehicleYear,
        };

        this.copySettings(res.assetType, res.financeType);
      });
  }

  openVehicleModal() {
    const props = {
      brokerEventId: this.brokerEventId,
      asset: this.asset,
    };
    const modalInstance = this.$uibModal.open({
      template: `<asset-finance-vehicle-modal
                  modal-instance="vm.modalInstance"
                  asset="vm.props.asset"
                  broker-event-id="vm.props.brokerEventId">
                </asset-finance-vehicle-modal>`,
      backdrop: 'static',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      this.familyMembersData.redBookCode = '';
      if (
        !response ||
        !response.vehicleDetails ||
        !response.vehicleDetails.redBookCode
      )
        return;
      this.asset = response;

      const details = response.vehicleDetails;
      const vehicle = {
        redBookCode: details.redBookCode,
        description: details.model,
        vehicleClassType: details.vehicleClassID,
        vehicleMake: details.make,
        vehicleFamily: details.family,
        vehicleMonth: details.month,
        vehicleYear: details.year,
      };

      this.familyMembersData = { ...this.familyMembersData, ...vehicle };
    });
  }

  loanProviderListFormat(lenders) {
    lenders.forEach(productFinderItem => {
      const length = 12;
      const trimmedString =
        productFinderItem.Name.length > length
          ? `${productFinderItem.Name.substring(0, length - 3)}...`
          : productFinderItem.Name;
      if (productFinderItem.Value !== 'FavouriteLenders') {
        this.loanProviderLendersListGetAll.push({
          name: productFinderItem.Name,
          shortName: trimmedString,
          value: productFinderItem.Value,
          ticked: false,
        });
      }
    });
  }

  getGeneralDropdown() {
    this.optionsService.getAssetFinanceSecurityType().then(response => {
      if (!response || !response.length) return;
      this.assetTypeList = response;
    });

    this.optionsService.getAssetFinanceCategory().then(response => {
      if (!response || !response.length) return;
      this.financeTypeList = response;
    });

    this.optionsService.getAssetFinanceLoanTerms().then(response => {
      if (!response || !response.length) return;
      this.loanTermsList = response;
    });

    this.iFinanceAppService.getQuotePurposeType().then(res => {
      this.clientType = res;
    });

    this.iFinanceAppService.getSaleType().then(res => {
      this.filterOptions.saleTypeList = res;
    });
  }

  getLoanStructure(isOverlay) {
    this.loanProfilerService
      .getProductFinderLoanStructureList(this.brokerEventId)
      .then(response => {
        const { data } = response;
        if (!data || !data.length) {
          this.getLoanStructure(true);
          return;
        }
        this.loanStructureList = data;

        this.loanStructureList.forEach(loanStructure => {
          loanStructure.isLoanStructure = true;
          loanStructure.loanProviderLendersListGetAll = [];
          loanStructure.selectedProduct = [];

          this.getSelectedProductList(loanStructure);
          if (isOverlay) this.updateOverlaySearch();
        });
      });
  }

  groupFilter(data) {
    const filters = ['assetVariantList', 'lenderList'];

    filters.filter(filterType => {
      const result = ['All'];
      const field =
        filterType === 'assetVariantList' ? 'asset_variant' : 'lender';
      data.filter(item => {
        if (result.indexOf(item[field]) < 0) result.push(item[field]);
        return item;
      });
      this.filterOptions[filterType] = result;
      return filterType;
    });

    this.resetFilter();
  }

  filterResult(data) {
    if (this.disableSearchFilter) return data;

    const {
      saleType,
      assetVariant,
      lender,
      establishmentFee,
      accountKeeping,
    } = this.filterDataList;
    const newData = data.filter(item => {
      const estFee =
        (establishmentFee && parseInt(item.establishment_fee, 10) === 0) ||
        !establishmentFee;
      const accFee =
        (accountKeeping && parseInt(item.account_keeping_fee, 10) === 0) ||
        !accountKeeping;
      const isSupplier = saleType === 'All' || item.supplier === saleType;
      const isAssetVariant =
        assetVariant === 'All' || item.asset_variant === assetVariant;
      const isLender = lender === 'All' || item.lender === lender;
      const isMatch =
        estFee && accFee && isSupplier && isAssetVariant && isLender;
      return isMatch;
    });

    return newData;
  }

  markFavourite(data) {
    return data.map(item => {
      const match = this.loanStructureList[0].selectedProduct.filter(
        selected => selected.productID === parseInt(item.id, 10)
      );
      item.isAddedForComparison = !!match.length;
      return item;
    });
  }

  sortArray(data, sortCol, sortType) {
    const asc = (a, b) => {
      return a[sortCol].localeCompare(b[sortCol]);
    };
    const desc = (a, b) => {
      return b[sortCol].localeCompare(a[sortCol]);
    };

    data.sort(sortType === 'ASC' ? asc : desc);
    if (this.disableSearchFilter) this.groupFilter(data);

    const markedFavourite = this.markFavourite(data);
    const newData = this.filterResult(markedFavourite);
    this.disableSearchFilter = false;
    return newData;
  }

  loadProductFinderList(loanStructure, parameters = {}) {
    this.countPerPage = 10;
    loanStructure.isSearchByLender = false;
    loanStructure.LoanStructureID = this.fromLenderRateObject.isFromLenderRates
      ? 0
      : loanStructure.LoanStructureID;

    const getProductFinder = (params, sortColumn, sortType, $defer) => {
      this.iFinanceAppService.getQuote(parameters).then(response => {
        if (!response || !response.data || !response.data.data) {
          loanStructure.isProductLoaderOverlayDisplay = false;

          $defer.resolve([]);
          return;
        }

        const { data } = response.data;

        params.total(data.length);

        if (this.currentUserService.isAU) {
          this.productFinderService
            .returnAddedSpreadsheetToProductList(data)
            .then(withSpreadsheet => {
              if (!withSpreadsheet) return;

              const sortedData = this.sortArray(
                withSpreadsheet,
                sortColumn,
                sortType
              );

              $defer.resolve(sortedData);
              loanStructure.isProductLoaderOverlayDisplay = false;
            });
        } else {
          $defer.resolve(data);
        }
      });
    };

    const tableParams = {
      page: 1,
      count: this.countPerPage,
      sorting: {
        lender: 'asc',
      },
      defaultSort: 'asc',
    };

    const tableSecondParams = {
      total: 0,
      getData($defer, params) {
        loanStructure.isProductLoaderOverlayDisplay = true;
        if (!loanStructure.isSearchByLender) {
          const sortColumn = params.orderBy()[0].substring(1);
          const sortType = values(params.sorting())[0].toUpperCase();

          getProductFinder(params, sortColumn, sortType, $defer);
        } else {
          this.loanProfilerService
            .lenderListWithProductCountGet(
              this.brokerEventId,
              loanStructure.LoanStructureID,
              params.page(),
              params.count(),
              null,
              null,
              this.fromLenderRateObject.isFromLenderRates
            )
            .then(response => {
              params.total(
                (response && response.data && response.data.Count) || 0
              );

              $defer.resolve(response.data.LenderProducts);
              loanStructure.isProductLoaderOverlayDisplay = false;
            });
        }
      },
    };

    loanStructure.productFinderTableParams = new this.NgTableParams(
      tableParams,
      tableSecondParams
    );
  }

  getSelectedProductList(loanStructure) {
    this.loanProfilerService
      .getFavouriteProduct(this.brokerEventId, loanStructure.LoanStructureID)
      .then(response => {
        if (!response.data.length) return;
        const products = response.data.map(favouriteProductBuilderForUI);

        loanStructure.selectedProduct = products.filter(
          item => item.assetFinanceProduct
        );
        this.showSelctedProduct = this.screenSize > 1440 ? 3 : 2;
      });
  }

  updateOverlaySearch() {
    this.loanStructureList.forEach(loanStructure => {
      loanStructure.isProductSearchOverlayDisplay = true;
      this.isProductSearchOverlayDisplay =
        loanStructure.isProductSearchOverlayDisplay;
    });
  }

  hoverProductName(bool) {
    this.isHoverProductName = bool;
  }

  deleteVehicle() {
    this.familyMembersData.redBookCode = '';
    this.asset = {
      vehicleDetails: {},
    };
  }

  copySettings(assetType, financeType) {
    this.familyMembersDataCopy = { ...this.familyMembersData };
    this.familyMembersDataCopy.assetTypeData = this.assetTypeList.filter(
      item => item.value === assetType
    );
    this.familyMembersDataCopy.financeTypeData = this.financeTypeList.filter(
      item => item.value === financeType
    );
    this.calculateAmount();
  }

  updateFilterData(loanStructure, isLoadTable) {
    this.generalSettingsValid = true;
    const {
      clientType,
      financeType,
      loanTerm,
      assetType,
      purchasePrice,
      commissionPercent,
      residualPercent,
      documentFeePercent,
      propertyOwner,
      loanAmount,
      redBookCode,
      description,
      vehicleClassType,
      vehicleMake,
      vehicleFamily,
      vehicleMonth,
      vehicleYear,
    } = this.familyMembersData;

    this.copySettings(assetType, financeType);

    if (
      !clientType ||
      !financeType ||
      !loanTerm ||
      !assetType ||
      !purchasePrice
    ) {
      this.generalSettingsValid = false;
      toastr.error('Please fill all required fields.', 'Oops!');
      return;
    } else if (assetType === this.ASSET_TYPE.MOTOR_VEHICLE && !redBookCode) {
      toastr.error('Please select a vehicle.', 'Oops!');
      return;
    }

    loanStructure.isProductSearchOverlayDisplay = false;
    this.isProductSearchOverlayDisplay =
      loanStructure.isProductSearchOverlayDisplay;

    if (isLoadTable) loanStructure.isProductLoaderOverlayDisplay = true;

    const generalSettingsCopy = {
      ClientType: clientType,
      AssetType: assetType,
      FinanceType: financeType,
      loanTerm,
      PurchasePrice: purchasePrice,
      CommissionPercent: commissionPercent,
      ResidualPercent: residualPercent,
      DocumentFeePercent: documentFeePercent,
      PropertyOwner: propertyOwner,
      loanAmount,
      ...this.calculatedAmount,
      Lender: ['All'],
      IsAssetFinance: true,
      RedBookCode: redBookCode,
      Description: description,
      VehicleClassType: vehicleClassType,
      VehicleMake: vehicleMake,
      VehicleFamily: vehicleFamily,
      VehicleMonth: vehicleMonth,
      VehicleYear: vehicleYear,
    };
    const postData = {
      GeneralSetting: generalSettingsCopy,
    };

    this.loanProfilerService
      .updateServiceabilityFilteringProductsSettings(
        this.brokerEventId,
        postData
      )
      .then(() => {
        if (isLoadTable) {
          this.loadIfinanceQuote(loanStructure);
          this.bouncingTooltipService.setTooltipVisible(true);
        }
        this.onSearch();
      })
      .catch(() => {
        toastr.error('Yikes! Something is wrong', 'Error');
      });
  }

  loadIfinanceQuote(loanStructure) {
    const {
      clientType: purposeType,
      financeType: quoteType,
      loanTerm: term,
      assetType,
      purchasePrice,
      commissionPercent: commissionPercentage,
      residualPercent: residualPercentage,
      documentFeePercent: brokerPercentage,
      propertyOwner,
      redBookCode,
    } = this.familyMembersData;

    const params = {
      purposeType,
      assetType,
      residentialStatusType: propertyOwner ? 1 : 2,
      quoteType,
      purchasePrice,
      term: Math.round(term * 12),
      brokerPercentage,
      commissionPercentage,
      residualPercentage,
      redBookCode:
        assetType === this.ASSET_TYPE.MOTOR_VEHICLE ? redBookCode : '',
    };
    this.loadProductFinderList(loanStructure, params);
  }

  changeSorting(loanStructure, $column) {
    this.nice = '';
    if (!$column.sortType) $column.sortType = 'asc';
    else if ($column.sortType === 'asc') $column.sortType = 'desc';
    else {
      $column.sortType = 'asc';
    }
    loanStructure.productFinderTableParams.sorting(
      $column.sortable(),
      $column.sortType
    );
    loanStructure.productFinderTableParams.reload();
  }

  updateFamilyMembers(searchType) {
    if (searchType === 'assetType') {
      if (
        this.familyMembersData.assetType === this.ASSET_TYPE.MOTOR_VEHICLE &&
        !this.familyMembersData.redBookCode
      )
        this.openVehicleModal();
    }
    this.disableSearchFilter = true;
    this.calculateAmount();
  }

  calculateAmount() {
    const loanAmount = this.familyMembersData.loanAmount;
    this.resetAmount();
    if (loanAmount) {
      this.calculatedAmount.ResidualAmount =
        loanAmount * (this.familyMembersData.residualPercent / 100);
      this.calculatedAmount.CommissionAmount =
        loanAmount * (this.familyMembersData.commissionPercent / 100);
      this.calculatedAmount.DocumentFeeAmount =
        loanAmount * (this.familyMembersData.documentFeePercent / 100);
    }
    this.familyMembersDataCopy.amounts = { ...this.calculatedAmount };
  }

  resetAmount() {
    this.calculatedAmount.ResidualAmount = 0;
    this.calculatedAmount.CommissionAmount = 0;
    this.calculatedAmount.DocumentFeeAmount = 0;
  }

  deleteLoanStructureProduct(loanStructure, product) {
    const { LoanStructureID: loanStructureID } = loanStructure;
    const params = {
      brokerEventID: this.brokerEventId,
      ProductID: product.productID,
      LenderID: product.lenderID,
      loanStructureID,
      IsFavourite: false,
    };
    this.updateFavoriteProduct(loanStructure, params, product);
  }

  selectLoanStructureProductAndStopEvent(loanStructure, product, event) {
    const maxFavorite = 3;
    if (
      !product.isAddedForComparison &&
      loanStructure.selectedProduct.length >= maxFavorite
    ) {
      toastr.info('You can select up to 3 products only.', 'Oops!');
      return;
    }

    product.isAddedForComparison = !product.isAddedForComparison;
    const { LoanStructureID: loanStructureID } = loanStructure;
    const params = {
      brokerEventID: this.brokerEventId,
      ProductID: product.id,
      LenderID: 0,
      loanStructureID,
      IsFavourite: product.isAddedForComparison,
      ...product,
    };
    this.updateFavoriteProduct(loanStructure, params, product);
    event.stopPropagation();
  }

  changeSelectionInLoanStructure(loanStructure, product) {
    this.favoritSelectionUpdated = true;
    loanStructure.productFinderTableParams.data.map(item => {
      if (parseInt(item.id, 10) === parseInt(product.productID, 10))
        item.isAddedForComparison = false;
      return item;
    });
  }

  getSelectedProductDetailsGetByLoanStructure() {
    this.selectedProductCount = 0;
    this.subTotal = 0;
    this.loanProfilerService
      .getSelectedProductDetailsGetByLoanStructure(this.brokerEventId)
      .then(response => {
        if (!response || !response.data) return;
        response.data.forEach(item => {
          this.selectedProductCount += item.SelectedProduct.length;

          const tollTipState = item.SelectedProduct.find(tooltipItem => {
            return !tooltipItem.IsToolTipShown;
          });
          item.SelectedProduct.map(itemValue => {
            itemValue.IsToolTipShownPopOver = false;
            return itemValue;
          });
          if (!tollTipState) {
            let isAllow = true;
            const recommendState = item.SelectedProduct.find(tooltipItem => {
              return tooltipItem.IsRecommended;
            });
            if (!recommendState) {
              item.SelectedProduct.map(itemValue => {
                if (isAllow) {
                  if (itemValue.IsToolTipShown) {
                    itemValue.IsToolTipShownPopOver = true;
                    isAllow = false;
                  }
                }
                return itemValue;
              });
            }
          }
        });
        this.loanStructureWiseSelectedProducts = response.data;
        this.loanStructureWiseSelectedProducts.forEach(item => {
          item.selectedProductDataList = [];
          this.subtotal = 0;
          this.loanProfilerService
            .getFavouriteProduct(this.brokerEventId, item.LoanStructureID)
            .then(productResponse => {
              item.selectedProductDataList = productResponse.data;
              if (item.selectedProductDataList.length > 0) {
                this.subTotal += 1;
              }
            });
          item.SelectedProduct = item.SelectedProduct.filter(
            prod => prod.AssetFinanceProduct
          );
        });
        this.loanStructureWiseSelectedProductsMobile = [...response.data];
        if (this.recommendInMobile) {
          const itemMobile = this.itemMobileFn();
          if (itemMobile) {
            const itemMobileData = itemMobile.SelectedProduct.filter(item => {
              return (
                parseInt(this.recommendInMobile.ProductID, 10) ===
                parseInt(item.ProductID, 10)
              );
            });
            if (this.recommendInMobile) this.recommendInMobile = itemMobileData;
          }
        }
        this.loanStructureWiseSelectedProducts.forEach(item => {
          if (item.SelectedProduct.length < 3) item.SelectedProduct.push({});
        });
      });
  }

  updateFavoriteProduct(loanStructure, params, product) {
    const { LoanStructureID: loanStructureID } = loanStructure;
    this.loanProfilerService.setFavouriteProduct(params).then(response => {
      if (parseInt(response.data, 10) === 1) {
        this.getSelectedProductList(loanStructure);
        angular
          .element(
            `.nav-tabs-asset-${loanStructureID} li.more-product-${loanStructureID} ul li.contact-parent-tab`
          )
          .remove();
        angular
          .element(`.nav-tabs-asset-${loanStructureID} > li.contact-parent-tab`)
          .remove();
        this.$timeout(() => {
          this.calcWidth(loanStructure);
        }, 100);

        if (
          !params.IsFavourite &&
          loanStructure.productFinderTableParams &&
          loanStructure.productFinderTableParams.data
        )
          this.changeSelectionInLoanStructure(loanStructure, product);
        this.getSelectedProductDetailsGetByLoanStructure();
      } else toastr.error('Yikes! Something is wrong', 'Error');
    });
  }

  calcWidth(loanSrtucture) {
    this.navwidth = 0;
    loanSrtucture.narrowNav = false;
    const mainId = loanSrtucture.LoanStructureID;
    loanSrtucture.hiddenTabCount = angular.element(
      `li.more-product-${mainId} li`
    ).length;
    const morewidth = 210;
    let availablespace = 0;
    angular
      .element(`.nav-tabs-asset-${mainId} > li.contact-parent-tab`)
      .each((i, item) => {
        this.navwidth += angular.element(item).outerWidth(true) + 10;
      });
    if (loanSrtucture.hiddenTabCount > 0) {
      availablespace =
        angular.element(`ul.nav-tabs-asset-${mainId}`).outerWidth(true) -
        morewidth;
    } else {
      availablespace = angular
        .element(`ul.nav-tabs-asset-${mainId}`)
        .outerWidth(true);
    }
    if (availablespace < 0) {
      availablespace = 0;
    }
    if (this.navwidth > availablespace) {
      const lastItem = angular
        .element(`.nav-tabs-asset-${mainId} > li.contact-parent-tab`)
        .last();
      lastItem.attr('data-width', lastItem.outerWidth(true));
      lastItem.prependTo(
        angular.element(
          `.nav-tabs-asset-${mainId} li.more-product-${mainId} ul`
        )
      );
      this.$timeout(() => this.calcWidth(loanSrtucture), 100);
    } else {
      loanSrtucture.viewMoreSelectedProduct = angular
        .element(`.nav-tabs-asset-${mainId} li.more-product-${mainId} ul`)
        .find('li.contact-parent-tab').length;
      const hiddenMoreElement = angular
        .element(`.nav-tabs-asset-${mainId} li.more-product-${mainId} li`)
        .find('li.contact-parent-tab').length;
      if (parseInt(hiddenMoreElement, 10) !== 0) {
        const firstMoreElement = angular
          .element(`.nav-tabs-asset-${mainId} li.more-product-${mainId} li`)
          .first();
        if (
          this.navwidth + firstMoreElement.outerWidth(true) <
          availablespace
        ) {
          firstMoreElement.insertBefore(
            angular.element(`.nav-tabs-asset-${mainId} .more-product-${mainId}`)
          );
        }
      }
    }

    loanSrtucture.narrowNav = !!loanSrtucture.hiddenTabCount;
  }

  hoverSelectedProduct(selectedProduct) {
    this.showSelctedProduct = this.screenSize > 1440 ? 3 : 2;
    selectedProduct.isHoverLabel = !selectedProduct.isHoverLabel;
  }

  hoverLender(product) {
    this.showSelctedProduct = this.screenSize > 1440 ? 3 : 2;
    product.isHoverLender = !product.isHoverLender;
  }

  goToProductComparison() {
    this.onModeComparison();
  }

  onSetProductRecommendation() {
    this.getSelectedProductDetailsGetByLoanStructure();
  }
}
