app.factory('popupBlockerService', [
  '$uibModal',
  '$timeout',
  '$http',
  function($uibModal, $timeout, $http) {
    // check add block
    var factory = {};
    factory.isPopUpDisabled = null;
    factory.run = function(callback, errorcallback) {
      $http.get('/config.json').then(() => {
        var popUp = window.open(
          null,
          '',
          'width=100,height=100,left=100000,top=100000'
        );

        if (popUp === null || typeof popUp === 'undefined') {
          errorcallback();
          factory.isPopUpDisabled = true;
          factory.showpopup();
        } else {
          popUp.close();
          factory.isPopUpDisabled = false;
          callback();
        }
      });
    };
    factory.showpopup = function() {
      $timeout(() => {
        $uibModal.open({
          templateUrl: '/assets/views/modals/popupblocker.html',
          controller($scope, $uibModalInstance) {
            $scope.close = function() {
              $uibModalInstance.close();
            };
          },
          windowClass: 'popupblocker-wrap',
          size: 'lg',
        });
      }, 3000);
    };
    return factory;
  },
]);
