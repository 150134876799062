import template from './prettyImg.html';
import controller from './prettyImgCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    src: '@',
    alt: '@',
    fallbackSrc: '@',
  },
};
