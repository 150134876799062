export const INSURANCE_APPLICATION_STATUS = {
  FROM_STATUS_ID: 1,
  TO_STATUS_ID: 2,
};

export const WORKBENCH_DATES = {
  FORMAT: 'dd MMM yyyy',
  ALL_FORMAT: ['d!/M!/yyyy', 'd!M!yyyy'],
  DATE_OPTIONS: {
    formatYear: 'yy',
    showWeeks: false,
  },
};
