import template from './applicationCard.html';

export default {
  template,
  bindings: {
    isSmallDevice: '<',
    isLockLoanappFeatureEnabled: '<',
    card: '<',
    isCorporateUser: '<',
    labelsSettings: '<',
    onLabelToggle: '&',
    onRefreshLabelsSettings: '&',
    isShrink: '<',
    isCompact: '<',
    onMenuClick: '&',
    onViewBorrower: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onCardClick: '&',
    onToggleIsShrink: '&',
    onDeletePipelineItem: '&',
    isShowTimeline: '<',
    timelineStatusList: '<',
    isSingle: '<',
    onChangeCardStatus: '&',
    onOpenWorkbench: '&',
    conversionOptions: '<',
    onConvertCard: '&',
  },
};
