import template from './viewModeToggle.html';
import controller from './viewModeToggleCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedMode: '<',
    onModeSelect: '&',
  },
};
