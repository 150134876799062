import angular from 'angular';

class CorporateTeamModalPlaceholderCtrl {
  constructor($uibModalInstance, email) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.email = email;
  }
}

angular
  .module('app')
  .controller(
    'CorporateTeamModalPlaceholderCtrl',
    CorporateTeamModalPlaceholderCtrl
  );
