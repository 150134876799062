import template from './mergeFieldOption.html';
import controller from './mergeFieldOptionCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    placeholder: '@',
    options: '<',
    formatOptionDisplay: '&',
    onSelect: '&',
  },
};
