import angular from 'angular';
import { KEY_CODE } from 'Common/constants/keyCode';

angular.module('app').directive('onPressEnter', function onPressEnter($parse) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const handleKeyDownEvent = event => {
        if (event.which === KEY_CODE.WIN_ENT) {
          const object = $parse(attrs.onPressEnter);
          object(scope);
          event.preventDefault();
        }
      };
      element.on('keypress', handleKeyDownEvent);
      scope.$on('$destroy', () => {
        element.off('keydown', handleKeyDownEvent);
      });
    },
  };
});
