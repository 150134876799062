import template from './emailRecipientsField.html';
import controller from './emailRecipientsFieldCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    placeholder: '@',
    selectedRecipients: '<',
    isInvalid: '<',
    validationMessage: '<',
    onSelectRecipient: '&',
    onRemoveRecipient: '&',
  },
};
