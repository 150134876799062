import { EVENT_VALUES } from 'Common/constants/eventValues';

class EventService {
  getEventsValue() {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();
    this.eventValue = [
      {
        title: EVENT_VALUES.TITLE,
        startsAt: new Date(y, m, 5, 19, 0),
        endsAt: new Date(y, m, 5, 22, 30),
        color: {
          primary: EVENT_VALUES.COLOR_PRIMARY,
          secondary: EVENT_VALUES.COLOR_SECONDARY,
        },
        actions: [
          {
            label: EVENT_VALUES.LABEL,
            cssClass: EVENT_VALUES.CSS_CLASS,
            onClick() {},
          },
        ],
        cssClass: '',
        allDay: true,
      },
    ];
    return this.eventValue;
  }
}

export default EventService;
