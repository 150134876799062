import { notesReferrerInfoForUI } from 'Common/mappers/rayWhite';

class RayWhiteService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'RayWhite';
  }

  getMembers(id) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/members/${id}`)
      .then(response => response.data && notesReferrerInfoForUI(response.data));
  }
}

export default RayWhiteService;
