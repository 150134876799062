import template from './insuranceSendReportForm.html';
import controller from './insuranceSendReportFormCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    familyId: '<',
    reportData: '<',
    reportDocument: '<',
    reportSettings: '<',
    onReportSent: '&',
  },
};
