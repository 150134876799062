import angular from 'angular';

angular
  .module('app')
  .controller('AppointmentSidebarCtrl', function AppointmentSidebarCtrl(
    $scope,
    $http,
    $timeout,
    $uibModal,
    loanToolsService,
    dashboardService,
    $filter,
    $element,
    $window,
    toaster,
    SweetAlert,
    configService,
    notificationSidebarService,
    $rootScope,
    moment
  ) {
    $scope.calendarSelectedDate = new Date();
    let authUrl = '';
    const getCalendar = (type, date) => {
      if (type === 'next') {
        $scope.calendarDay = date;
      } else if (type === 'previous') {
        $scope.calendarDay = date;
      } else {
        $scope.calendarDay = '';
      }
      $scope.calendarId = 'primary';
      notificationSidebarService
        .GetCalendar($scope.calendarId, $scope.calendarDay)
        .then(
          response => {
            const data = response.data;
            $scope.googleCalendarData = [];

            if (data && data.AuthSuccess) {
              $scope.calendarAuthorized = true;
              for (let i = 0; i < data.Calendars.length; i++) {
                for (let j = 0; j < data.Calendars[i].Events.length; j++) {
                  const calendarDataSingle = {};
                  calendarDataSingle.startTime = new Date(
                    data.Calendars[i].Events[j].StartTime
                  );
                  calendarDataSingle.endTime = new Date(
                    data.Calendars[i].Events[j].EndTime
                  );
                  calendarDataSingle.summary =
                    data.Calendars[i].Events[j].Summary;
                  calendarDataSingle.calendarId = data.Calendars[i].CalendarId;
                  calendarDataSingle.eventId =
                    data.Calendars[i].Events[j].EventId;
                  $scope.googleCalendarData.push(calendarDataSingle);
                }
              }
            } else if (data.UserAuthUrl != null && data.UserAuthUrl !== '') {
              authUrl = data.UserAuthUrl;
              $scope.calendarAuthorized = false;
            }
          },
          () => {}
        );
    };
    const init = () => {
      $scope.calendarDetails = false;
      $scope.calendarAuthorized = true;
      getCalendar();
    };
    $scope.currentDate = moment().format('DD MMM YYYY');
    $scope.currentDate = new Date($scope.currentDate);
    $scope.prevDate = moment()
      .subtract(1, 'days')
      .format('DD MMM YYYY');
    $scope.prevDate = new Date($scope.prevDate);
    $scope.nextDate = moment()
      .add(1, 'days')
      .format('DD MMM YYYY');
    $scope.nextDate = new Date($scope.nextDate);

    $scope.decreaseDate = function() {
      $scope.currentDate = new Date(moment($scope.prevDate));
      const momentPrev = moment($scope.prevDate);
      momentPrev.subtract(1, 'days').format('DD MMM YYYY');
      $scope.prevDate = new Date(momentPrev);
      const momentNext = moment($scope.currentDate);
      momentNext.add(1, 'days').format('DD MMM YYYY');
      $scope.nextDate = new Date(momentNext);
      $scope.prevdate = moment($scope.currentDate).format('YYYY-MM-DD');
      getCalendar('previous', $scope.prevdate);
    };
    $scope.increaseDate = function() {
      $scope.currentDate = new Date(moment($scope.nextDate));
      const momentPrev = moment($scope.currentDate);
      momentPrev.subtract(1, 'days').format('DD MMM YYYY');
      $scope.prevDate = new Date(momentPrev);
      const momentNext = moment($scope.nextDate);
      momentNext.add(1, 'days').format('DD MMM YYYY');
      $scope.nextDate = new Date(momentNext);
      $scope.nxtdate = moment($scope.currentDate).format('YYYY-MM-DD');
      getCalendar('next', $scope.nxtdate);
    };
    $scope.selectDate = function(currentDate) {
      if (currentDate !== '') {
        $scope.selectedDate = currentDate;
        $rootScope.$broadcast('calendarDate', {
          calendarDatePkt: $scope.selectedDate,
        });
      }
    };
    const calendarDateSelected = $rootScope.$on(
      'appointmentCalendarDateSelected',
      (event, args) => {
        const passedDate = new Date(args.calendarDate);
        getCalendar('next', moment(passedDate).format('YYYY-MM-DD'));
        $scope.currentDate = moment(passedDate).format('DD MMM YYYY');
        $scope.currentDate = new Date($scope.currentDate);
        $scope.slickDate3 = new Date($scope.currentDate);
        $scope.prevDate = moment(passedDate)
          .subtract(1, 'days')
          .format('DD MMM YYYY');
        $scope.prevDate = new Date($scope.prevDate);
        $scope.slickDate2 = new Date($scope.prevDate);
        $scope.prevDate2 = moment(passedDate)
          .subtract(2, 'days')
          .format('DD MMM YYYY');
        $scope.slickDate1 = new Date($scope.prevDate2);
        $scope.nextDate = moment(passedDate)
          .add(1, 'days')
          .format('DD MMM YYYY');
        $scope.nextDate = new Date($scope.nextDate);
        $scope.slickDate4 = new Date($scope.nextDate);
        $scope.nextDate2 = moment(passedDate)
          .add(2, 'days')
          .format('DD MMM YYYY');
        $scope.slickDate5 = new Date($scope.nextDate2);
        $scope.inViewRight = $scope.slickDate4;
        $scope.inViewLeft = $scope.slickDate2;
        $scope.middleSlickDate = 3;
        $scope.calendarDetails = false;
      }
    );
    $scope.$on('$destroy', calendarDateSelected);
    $scope.callCalendarAuth = function() {
      $window.open(authUrl);
    };
    $scope.deleteEvent = function(calendarObj) {
      $scope.calendarId = calendarObj.calendarId;
      $scope.eventId = calendarObj.eventId;
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            notificationSidebarService
              .CalendarEvent($scope.calendarId, $scope.eventId)
              .then(() => {
                $timeout(() => {
                  getCalendar();
                  SweetAlert.swal(
                    'Success',
                    'This event has been successfully deleted.',
                    'success'
                  );
                }, 100);
              });
          }
        }
      );
    };
    $window.calendarauth = function(code, functiontypeAfterAuth) {
      notificationSidebarService.CalendarAuth(code).then(
        () => {
          if (functiontypeAfterAuth === 'getcalendar') {
            getCalendar();
          }
        },
        () => {}
      );
    };
    init();
  });
