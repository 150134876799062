import { COUNTRY_TYPE } from 'Common/constants/countryType';

const userInfoData = {};

export function setUserInfoData(data) {
  Object.assign(userInfoData, data);
}

export function getUserInfoData() {
  return { ...userInfoData };
}

export function getCountryName(countryId) {
  switch (countryId) {
    case COUNTRY_TYPE.AUSTRALIA:
      return 'Australia';
    case COUNTRY_TYPE.NEW_ZEALAND:
      return 'New Zealand';
    case COUNTRY_TYPE.INDONESIA:
      return 'Indonesia';
    default:
      return '';
  }
}

export function getCountryCode(countryId) {
  switch (countryId) {
    case COUNTRY_TYPE.AUSTRALIA:
      return 'AU';
    case COUNTRY_TYPE.NEW_ZEALAND:
      return 'NZ';
    case COUNTRY_TYPE.INDONESIA:
      return 'ID';
    default:
      return '';
  }
}
