class CorporateTeamModalCtrl {
  constructor(corporateTeamService, contactService, toaster) {
    'ngInject';

    this.corporateTeamService = corporateTeamService;
    this.contactService = contactService;
    this.toaster = toaster;
  }
  cancel() {
    this.email.Recipients = [];
    this.email.Content = '';
    this.modalInstance.dismiss();
  }
  sendEnquiry(form) {
    if (form.$invalid) return;
    this.sendingEnquiry = true;
    this.contactService
      .sendEmail(this.email)
      .then(response => {
        const { data } = response;
        this.sendingEnquiry = false;
        if (!data) return;
        const promptInstance = this.corporateTeamService.promptSuccessMessage();
        this.email.Recipients = [];
        this.email.Content = '';
        promptInstance.result.then(() => {
          this.modalInstance.close();
        });
      })
      .catch(() => {
        this.sendingEnquiry = false;
      });
  }
}

export default CorporateTeamModalCtrl;
