import angular from 'angular';
import uiService from '../../../assets/js/services/uiService';
import fileUpload from './fileUpload';
import fileUploadMini from './fileUploadMini/fileUploadMini';
import fileUploadSimple from './fileUploadSimple/fileUploadSimple';

export default angular
  .module('components.fileUpload', [uiService, 'angularFileUpload'])
  .component('fileUpload', fileUpload)
  .component('fileUploadMini', fileUploadMini)
  .component('fileUploadSimple', fileUploadSimple).name;
