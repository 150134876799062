import template from './googleMapShapes.html';
import GoogleMapShapesCtrl from './googleMapShapesCtrl';

export default {
  template,
  bindings: {
    mapId: '@',
    shapeType: '@',
    showDistanceCtrl: '@',
    distanceCtrlTitle: '@',
    minDistance: '@',
    maxDistance: '@',
    leadDetails: '<',
    addressModel: '<',
    mainFamilyId: '<',
    isEditable: '<',
  },
  controller: GoogleMapShapesCtrl,
  controllerAs: 'vm',
};
