import template from './workbenchRequirementsForm.html';
import WorkbenchRequirementsFormCtrl from './workbenchRequirementsFormCtrl';
import './style.scss';

export default {
  template,
  controller: WorkbenchRequirementsFormCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    requirement: '<',
    loanId: '<',
    clients: '<',
  },
};
