import angular from 'angular';
import _ from 'lodash';
import { displayWarning } from 'Common/utilities/alert';
import { OLD_BENEFIT_TYPE } from 'Common/constants/insuranceOptions';
import {
  otherPolicyOwnership,
  defaultInsuranceDetails,
} from 'Common/default/insurance';

const mapPipelineStatusesForModal = statuses => {
  return (
    statuses &&
    statuses.map(status => ({ StatusID: status.id, StatusName: status.name }))
  );
};

const mapGroupedBenefitTypes = types => {
  return (
    types &&
    types.map(type => {
      return {
        BenefitTypeName: type.name,
        BenefitTypeID: type.oldId,
        InsuranceType: type.groupName,
        QPRBenefitID: type.id,
      };
    })
  );
};

const policyOwnership = (owner, selectedInsuredPerson, quoteId) => {
  return {
    quoteId,
    clientId: owner.PersonId,
    isDependent: owner.Role !== 'Adult',
    isOwnership: owner.Role === 'Adult',
    fullName: owner.FullName,
    isInsured: owner.PersonId === selectedInsuredPerson,
  };
};

const loadingExclusion = familyMember => {
  return {
    clientId: parseInt(familyMember.PersonId, 10),
    isLoadingExclusion: false,
    loading: 0,
    details: '',
    date: '',
    fullName: familyMember.FullName,
    isStandardRates: true,
    isChild: familyMember.Role !== 'Adult',
    selectedOptions: 1,
  };
};

const clientBenefitsHealthCover = client => {
  return {
    clientId: client.PersonId,
    fullName: client.FullName,
    role: client.Role,
    isBaseCover: false,
    loading: 0,
    excess: '',
    isSpecialistsTest: false,
    isGpPrescriptions: false,
    isDentalOptical: false,
  };
};

const getPaymentFrequencyIdByName = (name, list) => {
  if (!name || !list) return;
  const frequency = list.find(item => item.Name === name);
  return frequency && frequency.id;
};

angular
  .module('app')
  .controller('InsuranceExistingModalCtrl', function InsuranceExistingModalCtrl(
    $scope,
    $uibModalInstance,
    toaster,
    $state,
    contactInsuranceService,
    insurancePipelineService,
    items
  ) {
    /*
    Table of Contents
    1. MODELS
    2. Functions
    3. METHODS
    4. Shared Data
    */

    // 1. MODELS
    $scope.formData = {
      isSaving: false,
      isEditing: false,
      hasExcess: false,
    };

    $scope.OLD_BENEFIT_TYPE = OLD_BENEFIT_TYPE;

    const tempInsuranceDetailsInput = defaultInsuranceDetails(
      $state.params.familyId
    );

    const tempFrequencyList = [
      { Name: 'Select Frequency', id: '' },
      { Name: 'Fortnightly', id: 26 },
      { Name: 'Weekly', id: 52 },
      { Name: 'Monthly', id: 12 },
      { Name: 'Yearly', id: 1 },
    ];

    const tempLoadingList = [
      { Name: 'Select an option', id: 0 },
      { Name: '0%', id: 1 },
      { Name: '50%', id: 2 },
      { Name: '75%', id: 3 },
      { Name: '100%', id: 4 },
      { Name: '125%', id: 5 },
      { Name: '150%', id: 6 },
      { Name: '175%', id: 7 },
      { Name: '200%', id: 8 },
      { Name: '250%', id: 9 },
      { Name: '300%', id: 10 },
      { Name: '400%', id: 11 },
      { Name: '500%', id: 12 },
    ];

    const tempFPBenefitPeriodList = [
      // { benefitPeriodID: 1, Description: 'Fixed Terms', Value: 1 },
      // { benefitPeriodID: 2, Description: 'To Age', Value: 2 },
      { benefitPeriodID: '', Description: 'Select Period', Value: '' },
      { benefitPeriodID: 1, Description: '1 Year', Value: 1 },
      { benefitPeriodID: 2, Description: '2 Years', Value: 2 },
      { benefitPeriodID: 3, Description: '5 Years', Value: 3 },
      { benefitPeriodID: 4, Description: 'To Age 65', Value: 4 },
      { benefitPeriodID: 5, Description: 'To Age 70', Value: 5 },
    ];

    const benefitPeriodListGet = () => {
      contactInsuranceService
        .insuranceQuoteIPBenefitPeriodGet()
        .then(response => {
          if (response.data) {
            $scope.benefitPeriodList = [
              { Key: 'Select benefit period', Value: '' },
              ...response.data,
            ];
          }
        });
    };

    const weekWaitPeriodListGet = () => {
      contactInsuranceService
        .insuranceQuoteWeekWaitPeriodGet()
        .then(response => {
          if (response.data) {
            $scope.weekWaitPeriodList = [
              { Key: 'Select wait period', Value: '' },
              ...response.data,
            ];
          }
        });
    };

    const insuranceQuoteExcessGet = () => {
      contactInsuranceService.insuranceQuoteExcessGet().then(response => {
        if (response.data) {
          $scope.excessList = [
            { Key: 'Select Excess', Value: '' },
            ...response.data,
          ];
        }
      });
    };

    const appInfoGet = () => {
      const { quoteId, familyId, providerId, paymentFrequency } = items.data;
      if (!quoteId) return;

      $scope.quoteId = quoteId;
      $scope.familyId = familyId;
      $scope.providerId = providerId;
      $scope.insuranceDetailsInput.providerId = providerId;
      $scope.insuranceDetailsInput.paymentFrequency = getPaymentFrequencyIdByName(
        paymentFrequency,
        $scope.frequencyList
      );
    };

    angular.extend($scope, {
      benefitTypeList: [],
      insuranceModalTab: 'overview',
      insuranceDetailsInput: tempInsuranceDetailsInput,
      excessList: [],
      clientId: null,
      familyAdultList: [],
      familyList: [],
      selectedInsuredPerson: '',
      insurersList: [],
      productList: [],
      statusList: [],
      coverTypeList: [],
      frequencyList: tempFrequencyList,
      loadingList: tempLoadingList,
      insuranceCalcPeriodList: [],
      fpBenefitPeriodList: tempFPBenefitPeriodList,
      benefitPeriodList: [],
      weekWaitPeriodList: [],
    });

    // 2. Functions
    function insuranceDetailsSet() {
      $scope.insuranceDetailsInput = $scope.formatInput(
        $scope.insuranceDetailsInput
      );
      contactInsuranceService
        .insuranceDetailsSet($scope.insuranceDetailsInput)
        .then($scope.onInsuranceSaved);
    }

    function insuranceProductGet() {
      const { providerId, benefitTypeId } = $scope.insuranceDetailsInput;
      if (!providerId || !benefitTypeId) return;

      contactInsuranceService
        .insuranceProductGet(providerId, benefitTypeId)
        .then(({ data = [] }) => {
          $scope.productList = [
            { ProductName: 'Select product', ProductID: '' },
            ...data,
          ];
        });
    }

    function insuranceCalcPeriodGet() {
      contactInsuranceService.insuranceCalculationPeriodGet().then(response => {
        response.data.unshift({ Key: 'Select calculation period', Value: '' });
        $scope.insuranceCalcPeriodList = response.data;
      });
    }

    function insuranceQuoteProviderGet() {
      const { providerId, benefitTypeId } = $scope.insuranceDetailsInput;
      contactInsuranceService
        .insuranceExistingProviderGet(providerId, benefitTypeId)
        .then(response => {
          response.data.unshift({
            ProviderName: 'Select Insurer',
            ProviderId: '',
          });
          $scope.insurersList = $scope.providerId
            ? response.data.filter(
                provider => provider.ProviderId === $scope.providerId
              )
            : response.data;
        });
    }

    function insuranceBenefitsGet() {
      const { providerId } = $scope.insuranceDetailsInput;
      const promise = $scope.quoteId
        ? insurancePipelineService.getInsuranceBenefits(providerId)
        : contactInsuranceService.insuranceBenefitTypesGet();

      promise.then(response => {
        const types = $scope.quoteId
          ? mapGroupedBenefitTypes(response)
          : response.data;
        $scope.benefitTypeList = [
          {
            BenefitTypeName: 'Select benefit',
            BenefitTypeID: '',
            InsuranceType: '',
            QPRBenefitID: null,
          },
          ...types,
        ];
        const selectedBenefitType = $scope.benefitTypeList.find(
          benefit =>
            benefit.QPRBenefitID &&
            benefit.QPRBenefitID === items.data.benefitTypeId
        );
        $scope.insuranceDetailsInput.benefitTypeId = selectedBenefitType
          ? selectedBenefitType.BenefitTypeID
          : '';
        insuranceQuoteProviderGet();
        insuranceProductGet();
      });
    }

    function insuranceStatusGet() {
      const promise = $scope.quoteId
        ? insurancePipelineService
            .getInsurancePipelineStatus()
            .then(data => mapPipelineStatusesForModal(data))
        : contactInsuranceService.insuranceStatusGet();

      promise.then(response => {
        $scope.statusList = [
          { StatusName: 'Select Status', StatusID: '' },
          ...(response.data ? response.data : response),
        ];
      });
    }

    function iPCoverTypeGet() {
      contactInsuranceService.insuranceQuoteIPCoverTypeGet().then(response => {
        response.data.unshift({ Key: 'Select cover type', Value: '' });
        $scope.coverTypeList = response.data;
      });
    }

    const editDataSet = () => {
      if (items.data.benefitId > 0) {
        $scope.formData.isEditing = true;
        const insDetails = items.data;
        if (insDetails.clientId) {
          $scope.selectedInsuredPerson = insDetails.clientId.toString();
        }
        $scope.insuranceDetailsInput = insDetails;
        appInfoGet();
        $scope.benefitTypeChange(insDetails.benefitTypeId);
        $scope.insuranceDetailsInput.tempCommencementDate =
          $scope.insuranceDetailsInput.commencementDate;
      }
    };

    const initOwnershipForExistingInsurance = () => {
      $scope.insuranceDetailsInput.policyOwnership = $scope.familyAdultList
        .reduce((accum, adult) => {
          return accum.concat(
            policyOwnership(adult, $scope.selectedInsuredPerson, $scope.quoteId)
          );
        }, [])
        .concat(otherPolicyOwnership());
    };

    const initOwnershipForApplication = () => {
      insurancePipelineService
        .getInsurancePolicyOwnership($scope.quoteId)
        .then(response => {
          $scope.insuranceDetailsInput.policyOwnership = response.map(
            ownership => {
              return { ...ownership, isOwnership: !ownership.isDependent };
            }
          );
        });
    };

    const addPolicyOwnership = () => {
      if (!$scope.formData.isEditing) {
        if ($scope.quoteId) {
          initOwnershipForApplication();
        } else {
          initOwnershipForExistingInsurance();
        }
      } else {
        const { policyOwnership: poList } = $scope.insuranceDetailsInput;
        $scope.insuranceDetailsInput.policyOwnership = poList.map(po => {
          if (po.clientId) {
            const adult = $scope.familyAdultList.find(member => {
              return parseInt(member.PersonId, 10) === po.clientId;
            });

            return {
              ...po,
              isDependent: false,
              fullName: (adult && adult.FullName) || po.fullName,
              isInsured:
                adult && adult.PersonId === $scope.selectedInsuredPerson,
            };
          }
          return {
            ...po,
            isDependent: true,
            fullName: 'Other',
            isInsured: !!po.other,
          };
        });
      }
    };

    const addLoadingExclusion = () => {
      if (!$scope.formData.isEditing) {
        if (
          $scope.insuranceDetailsInput.benefitTypeId ===
          OLD_BENEFIT_TYPE.HEALTH_COVER
        ) {
          $scope.insuranceDetailsInput.loadingExclusions = $scope.familyList.reduce(
            (accum, member) => accum.concat(loadingExclusion(member)),
            []
          );
        } else {
          const selectedInsuredPerson = $scope.selectedInsuredPerson
            ? $scope.familyAdultList.find(
                member => member.PersonId === $scope.selectedInsuredPerson
              )
            : $scope.familyAdultList[0] || {};

          $scope.insuranceDetailsInput.loadingExclusions = [
            loadingExclusion(selectedInsuredPerson),
          ];
        }
      } else {
        const loadingExcl =
          $scope.insuranceDetailsInput.loadingExclusions || [];
        if (
          $scope.insuranceDetailsInput.benefitTypeId ===
          OLD_BENEFIT_TYPE.HEALTH_COVER
        ) {
          $scope.insuranceDetailsInput.loadingExclusions = $scope.familyList.reduce(
            (accum, member) => {
              const exclusion = loadingExcl.find(
                le => le.clientId === parseInt(member.PersonId, 10)
              );

              if (exclusion) {
                return accum.concat({
                  ...exclusion,
                  fullName: member.FullName,
                  selectedOptions: exclusion.loading > 0 ? 0 : 1,
                  isLoadingExclusion: exclusion.loading > 0,
                });
              }

              return accum.concat(loadingExclusion(member));
            },
            []
          );
        } else if (loadingExcl[0]) {
          const loadingExclusions = loadingExcl[0];
          if ($scope.selectedInsuredPerson) {
            loadingExclusions.clientId = parseInt(
              $scope.selectedInsuredPerson,
              10
            );
          }

          const insuredMember = $scope.familyList.find(member => {
            return parseInt(member.PersonId, 10) === loadingExclusions.clientId;
          });
          if (insuredMember) {
            loadingExclusions.fullName = insuredMember.FullName;
            loadingExclusions.selectedOptions =
              loadingExclusions.loading > 0 ? 0 : 1;
            loadingExclusions.isLoadingExclusion =
              loadingExclusions.selectedOptions !== 1;
          }
        } else {
          $scope.insuranceDetailsInput.loadingExclusions = [
            loadingExclusion($scope.familyAdultList[0]),
          ];
        }
      }
    };

    const addClientBenefitsHealthCover = () => {
      const { benefits } = $scope.insuranceDetailsInput;
      if (!$scope.formData.isEditing) {
        benefits.healthCover = $scope.familyList.reduce((accum, member) => {
          return accum.concat(clientBenefitsHealthCover(member));
        }, []);
      } else {
        benefits.healthCover = !$scope.quoteId
          ? $scope.getExistingHealthCover()
          : $scope.getApplicationHealthCover();
      }
    };

    $scope.getExistingHealthCover = () => {
      const healthCover =
        $scope.insuranceDetailsInput.benefits.healthCover || [];
      return $scope.familyList.reduce((accum, member) => {
        const memberHealthCover = healthCover.find(
          cover => cover.clientId === parseInt(member.PersonId, 10)
        );
        return memberHealthCover
          ? accum.concat({
              ...memberHealthCover,
              fullName: member.FullName,
              role: member.Role,
            })
          : clientBenefitsHealthCover(member);
      }, []);
    };

    $scope.getApplicationHealthCover = () => {
      const healthCover =
        $scope.insuranceDetailsInput.benefits.healthCover || [];
      return (
        healthCover &&
        healthCover.map(cover => {
          const role = cover.isChild ? 'Child' : 'Adult';
          return { ...cover, role };
        })
      );
    };

    function clientInformGet() {
      const familyId = $scope.familyId || $scope.$state.params.familyId;
      contactInsuranceService
        .clientInformGet(familyId, $scope.clientId)
        .then(response => {
          $scope.familyList = response.data;

          if ($scope.familyList.length >= 1) {
            $scope.familyAdultList = _.filter($scope.familyList, o => {
              if (o.Role === 'Adult') {
                return o;
              }
            });

            if ($scope.familyAdultList.length >= 1) {
              $scope.selectedInsuredPerson = $scope.familyAdultList[0].PersonId;
            }

            insuranceBenefitsGet();
            insuranceStatusGet();
            insuranceCalcPeriodGet();
            iPCoverTypeGet();
            editDataSet();

            addPolicyOwnership();
            addLoadingExclusion();
            addClientBenefitsHealthCover();
          }
        });
    }

    const setQPRBenefitID = value => {
      const selectedBenefitType = $scope.benefitTypeList.find(
        benefit => benefit.BenefitTypeID === value
      );
      if (!selectedBenefitType) return;
      $scope.insuranceDetailsInput.qprBenefitId =
        selectedBenefitType.QPRBenefitID;
    };

    // 2. METHODS
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.init = () => {
      appInfoGet();
      clientInformGet();
      benefitPeriodListGet();
      weekWaitPeriodListGet();
      insuranceQuoteExcessGet();
    };

    $scope.baseCoverChange = indx => {
      const { healthCover } = $scope.insuranceDetailsInput.benefits;
      const clientHealthCover = healthCover[indx];
      if (clientHealthCover.Excess === '') {
        clientHealthCover.isBaseCover = false;
      } else {
        const baseCover = clientHealthCover.isBaseCover;
        if (!baseCover) {
          clientHealthCover.isSpecialistsTest = baseCover;
          clientHealthCover.isGpPrescriptions = baseCover;
          clientHealthCover.isDentalOptical = baseCover;
        }
      }
    };

    $scope.hcExcessChange = indx => {
      const { healthCover } = $scope.insuranceDetailsInput.benefits;
      const clientHealthCover = healthCover[indx];
      clientHealthCover.isBaseCover = !!clientHealthCover.excess;
      if (!clientHealthCover.isBaseCover) {
        $scope.baseCoverChange(indx);
        $scope.formData.hasExcess = false;
      }
      $scope.excessChecker();
    };

    $scope.excessChecker = () => {
      const { benefits } = $scope.insuranceDetailsInput;
      $scope.formData.hasExcess = !!benefits.healthCover.find(
        cover => cover.excess > 0
      );
    };

    $scope.benefitTypeChange = value => {
      insuranceQuoteProviderGet();
      insuranceProductGet();
      addLoadingExclusion();
      setQPRBenefitID(value);
      if (value === OLD_BENEFIT_TYPE.MORTGAGE_INTERRUPTION) {
        $scope.insuranceDetailsInput.isPremiumAutoCalc = false;
        $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = false;
      }
    };

    $scope.providerChange = () => {
      insuranceProductGet();
    };

    $scope.insuredPersonChange = personId => {
      $scope.selectedInsuredPerson = personId;
      addLoadingExclusion();
    };

    $scope.standardRatesChange = exclusion => {
      if ($scope.insuranceDetailsInput.benefitTypeId) {
        exclusion.isLoadingExclusion = exclusion.selectedOptions !== '1';
        exclusion.isStandardRates = exclusion.selectedOptions === '1';
      } else {
        if (exclusion.selectedOptions) {
          exclusion.selectedOptions = '1';
        }
        displayWarning(
          'Please select Benefit Type first before adding Loading or Exclusion.',
          'Required Field'
        );
      }
    };

    $scope.formatInput = input => {
      const benefitType = $scope.benefitTypeList.find(benefit => {
        return benefit.BenefitTypeID === input.benefitTypeId;
      });
      const { policyOwnership: policyOwners } = $scope.insuranceDetailsInput;
      const policyOwner = policyOwners.find(ownership => {
        return (
          ownership.clientId === parseInt($scope.selectedInsuredPerson, 10)
        );
      });
      const healthCover = $scope.formatHealthCoverInput(input);
      return {
        ...input,
        quoteId: $scope.quoteId,
        clientId: $scope.quoteId
          ? policyOwner.quoteClientId
          : $scope.selectedInsuredPerson,
        commencementDate: input.tempCommencementDate,
        benefitTypeId: $scope.quoteId
          ? benefitType.QPRBenefitID
          : benefitType.BenefitTypeID,
        benefits: { ...input.benefits, healthCover },
        providerResultId: items.data.providerResultId,
        loadingExclusions: $scope.getLoadingExclusionsToSave(input),
      };
    };

    $scope.formatHealthCoverInput = input => {
      const { healthCover } = input.benefits;
      if (!healthCover) return null;

      const { policyOwnership: policyOwners } = input;
      return healthCover.map(cover => {
        const policyOwner = policyOwners.find(
          owner => owner.clientId === parseInt(cover.clientId, 10)
        );
        return policyOwner
          ? { ...cover, clientId: policyOwner.quoteClientId }
          : cover;
      });
    };

    $scope.getLoadingExclusionsToSave = input => {
      return $scope.quoteId
        ? input.loadingExclusions.map(exclusion => {
            const policyOwner = input.policyOwnership.find(
              owner => owner.clientId === exclusion.clientId
            );
            return { ...exclusion, clientId: policyOwner.quoteClientId };
          })
        : input.loadingExclusions;
    };

    $scope.onInsuranceSaved = response => {
      if (!response.data) return;
      toaster.pop('success', 'Success', 'Insurance successfully saved');
      $scope.formData.isSaving = false;
      $uibModalInstance.close('close');
    };

    $scope.saveNewExistingInsurance = () => {
      $scope.formData.isSaving = true;
      if ($scope.quoteId) {
        $scope.saveAppBenefitCover();
      } else {
        insuranceDetailsSet();
      }
    };

    $scope.saveAppBenefitCover = () => {
      insurancePipelineService
        .saveBenefitCover($scope.formatInput($scope.insuranceDetailsInput))
        .then($scope.onInsuranceSaved);
    };

    $scope.otherOwnershipChange = value => {
      if (value.fullName === 'Other' && !value.isOwnership) {
        value.other = '';
      }
    };

    $scope.waivePolicyChange = waivePolicyValue => {
      $scope.insuranceDetailsInput.policyFee = 0;
      $scope.insuranceDetailsInput.isPolicyFeeAutoCalc = waivePolicyValue
        ? false
        : $scope.insuranceDetailsInput.isPolicyFeeAutoCalc;
      $scope.disablePolicyFee = !!waivePolicyValue;
      $scope.policyFeeInput = !waivePolicyValue;
    };

    $scope.autoPolicyFeeChange = autoPolicyFeeValue => {
      if (autoPolicyFeeValue) {
        $scope.insuranceDetailsInput.waivePolicy = false;
        $scope.disablePolicyFee = false;
        $scope.policyFeeInput = true;
      } else {
        $scope.insuranceDetailsInput.policyFee = 0;
      }
    };

    $scope.init();

    // 4. Shared Data
  });
