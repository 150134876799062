import template from './addAssetSupplierModal.html';
import controller from './addAssetSupplierModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanAppId: '<',
  },
};
