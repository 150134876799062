import CONTACT_REQUESTER from 'Common/constants/addNewContactRequesters';
import PARTY_TYPE from 'Common/constants/partyTypes';

class GuarantorsCtrl {
  $onInit() {
    this.REQUESTER_TYPE = CONTACT_REQUESTER.REQUESTER_TYPE;
    this.SHOW_EXISTING_PANEL = CONTACT_REQUESTER.SHOW_EXISTING_PANEL;
    this.PARTY_TYPE = PARTY_TYPE;
  }
}

export default GuarantorsCtrl;
