import angular from 'angular';

angular
  .module('app')
  .controller(
    'OpenSMSSubscriptionPendingModalCtrl',
    function OpenSMSSubscriptionPendingModalCtrl($scope, $uibModalInstance) {
      // modal scope
      angular.extend($scope, {
        cancel() {
          $uibModalInstance.dismiss('cancel');
        },
      });
    }
  );
