export class OpportunityNotes {
  constructor(noteObj = {}) {
    const defaultNote = {
      isPrivate: false,
      isNotification: false,
      notificationSMS: false,
      notificationEmail: false,
      loanId: 0,
      adviserId: '',
      isSticky: false,
      isReferrer: false,
      familyId: '',
      communicationId: 0,
      activityDate: new Date(),
      activityTitle: '',
      activityDetails: '',
      activityOwner: '',
      activityType: '',
    };

    this.note = { ...defaultNote, ...noteObj };
  }
}
