import angular from 'angular';

angular
  .module('app')
  .controller('AddEditLmiModalCtrl', function AddEditLmiModalCtrl(
    $scope,
    $timeout,
    toaster,
    $stateParams,
    $uibModalInstance,
    optionsService,
    broadcastDataService,
    fundingCalculatorService,
    fundingCalculatorLMIDeatils,
    fundingCalculatorFundsSummary,
    fundingCalculatorProposedLending,
    getFundingCalculatorLMIDeatils,
    saveFundingRequierd,
    getFundingCalculatorProposedLending,
    saveProposedLending,
    getFundingCalculatorFunds,
    fundingCalculatorFunds,
    fundingClcMainObj,
    setLMI,
    fundingCalculatorSharedData
  ) {
    let $loamAmount = 0;
    $scope.fundingClcMainObj = fundingClcMainObj;
    $scope.fundingCalculatorLMIDeatils = fundingCalculatorLMIDeatils;
    $scope.fundingCalculatorFundsSummary = fundingCalculatorFundsSummary;
    $scope.fundingCalculatorProposedLending = fundingCalculatorProposedLending;
    $scope.getFundingCalculatorLMIDeatils = getFundingCalculatorLMIDeatils;
    $scope.saveFundingRequierd = saveFundingRequierd;
    $scope.getFundingCalculatorProposedLending = getFundingCalculatorProposedLending;
    $scope.saveProposedLending = saveProposedLending;
    $scope.getFundingCalculatorFunds = getFundingCalculatorFunds;
    $scope.fundingCalculatorFunds = fundingCalculatorFunds;
    $scope.brokerEventId = $stateParams.brokerEventId;
    $scope.setLMI = setLMI;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // Get Funding Calculator LMI Lender List
    $scope.LMILenserList = [];
    $scope.getLenderListForLMI = function() {
      fundingCalculatorService.getLenderList(true).then(response => {
        $scope.LMILenserList = response.data;
      });
    };
    $scope.getLenderListForLMI();

    // Get Funding Calculator LMI Insurer List
    $scope.LMIInsurerList = [];
    $scope.getInsurerList = fundingCalculatorSharedData.getInsurerList;
    if (parseInt($scope.fundingCalculatorLMIDeatils.LenderID, 10)) {
      $scope.getInsurerList($scope.fundingCalculatorLMIDeatils.LenderID);
    }

    if (parseInt($scope.fundingCalculatorLMIDeatils.FundsLMIID, 10) === 0) {
      if ($scope.fundingCalculatorFundsSummary.IsCalculatedProposedLending) {
        if ($scope.fundingCalculatorProposedLending.length > 0) {
          $scope.fundingCalculatorLMIDeatils.NewLoanTerm =
            $scope.fundingCalculatorProposedLending[0].LoanTermInYears;
          angular.forEach($scope.fundingCalculatorProposedLending, item => {
            $loamAmount += item.LoanAmount;
          });
        }
      } else {
        $loamAmount =
          $scope.fundingClcMainObj.tempTotalProposedLendingUserDefined;
      }
      $scope.fundingCalculatorLMIDeatils.NewLoanAmount = $loamAmount;
    }

    $scope.isSecurityVisible = false;
    $scope.newSecurityList = [];
    $scope.securityList = $scope.fundingCalculatorLMIDeatils.security;
    // Add Security Div
    $scope.addSecurity = function() {
      $scope.isSecurityVisible = true;
      const $newSecurity = {
        ID: Math.random(),
        StateOfSercurity: '',
        OccupancyType: '',
        Value: '',
        IsEditMode: false,
      };
      $scope.newSecurityList.push($newSecurity);
    };

    // Add Or Update Security
    $scope.saveSecurity = function(security) {
      let $index = $scope.newSecurityList.indexOf(security);
      if ($index > -1) $scope.newSecurityList.splice($index, 1);

      const postData = {
        ID: security.ID,
        StateOfSercurity: security.StateOfSercurity,
        OccupancyType: security.OccupancyType,
        Value: security.Value,
      };
      if (!security.IsEditMode) {
        postData.ID = 0;
        $scope.securityList.push(postData);
      } else {
        $index = $scope.securityList.indexOf(security);
        if ($index > -1) $scope.securityList.splice($index, 1);
        $scope.securityList.push(postData);
      }
      security.IsEditMode = false;
    };

    // Delete Security
    $scope.deleteSecurity = function(security) {
      let $index;
      let $index1;
      if (Math.floor(security.ID) === 0) {
        $index = $scope.newSecurityList.indexOf(security);
        if ($index > -1) $scope.newSecurityList.splice($index, 1);
        $index1 = $scope.securityList.indexOf(security);
        if ($index1 > -1) {
          $scope.securityList.splice($index1, 1);
        }
      } else {
        $index = $scope.securityList.indexOf(security);
        if ($index > -1) {
          $scope.securityList.splice($index, 1);
        }
      }
    };
  });
