// Styles
require('bootstrap/dist/css/bootstrap.min.css');
require('font-awesome/css/font-awesome.min.css');
require('themify-icons/css/themify-icons.css');
require('flag-icon-css/css/flag-icon.min.css');
require('animate.css/animate.min.css');

// Scripts
window.$ = window.jQuery = require('jquery'); // eslint-disable-line
window.swal = require('sweetalert'); // eslint-disable-line
require('sweetalert/lib/sweet-alert.css');
window.moment = require('moment'); // eslint-disable-line
window.html2canvas = require('html2canvas'); // eslint-disable-line
window.domtoimage = require('dom-to-image'); // eslint-disable-line
window.JsPDF = require('jspdf'); // eslint-disable-line

// TODO: remove this from window scope
window.hubsStatus = 'pending'; // eslint-disable-line

// SignalR
const $ = window.$; // eslint-disable-line

require('angular');
require('d3');
require('chart.js');
require('nestable');
require('bootstrap-touchspin');
require('bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css');
require('jquery-appear/build/jquery.appear.min.js');
// require('spectrum/spectrum.js');
// require('spectrum/spectrum.css');
require('selectize/dist/css/selectize.default.css');
require('RWD-Table-Patterns/dist/js/rwd-table.min.js');
require('RWD-Table-Patterns/dist/css/rwd-table.min.css');
// require('jquery.maskedinput-master/dist/jquery.maskedinput.min.js');
require('select2');
require('select2/select2.css');
require('jquery-mask-plugin/dist/jquery.mask.min.js');
require('moment-timezone');
require('perfect-scrollbar/jquery')(window.$); // eslint-disable-line
require('perfect-scrollbar/dist/css/perfect-scrollbar.css');
require('bootstrap-daterangepicker');
require('bootstrap-daterangepicker/daterangepicker-bs3.css');
require('spin.js');
require('ladda');
require('ladda/dist/ladda-themeless.min.css');
require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('lodash');
require('ng-bs-daterangepicker/dist/ng-bs-daterangepicker.min.js');
require('file-saver');
require('jquery-match-height');
require('svg-injector');
require('signalr/jquery.signalR.min.js');
require('waypoints/lib/jquery.waypoints.min.js');
require('waypoints/lib/shortcuts/sticky.min.js');
require('magnifier/magnifier.css');
window.MagnifierEvent = require('magnifier/demo/Event.js').Event; // eslint-disable-line
window.Magnifier = require('magnifier/Magnifier.js').Magnifier; // eslint-disable-line
// Previously, lazy loaded modules
require('angular-appear/build/angular-appear.min.js');
require('angular-filter-count-to/dist/angular-filter-count-to.min.js');
require('eonasdan-bootstrap-datetimepicker');
require('eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css');
require('spectrum-colorpicker/spectrum.js');
require('spectrum-colorpicker/spectrum.css');
require('angular-spectrum-colorpicker/dist/angular-spectrum-colorpicker.min.js');
// require('angular-bootstrap-datetimepicker-directive/angular-bootstrap-datetimepicker-directive.min.js');

// Temporary solution to bundle multiple javascript files into one. This will be replaced by ES6 import.
require('./assets/js/app.js');

// Going to get rid of this require after all components are properly imported
function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

requireAll(require.context('./assets/js/models', false, /.*\.js/));
requireAll(require.context('./assets/js/directives', true, /.*\.js/));
requireAll(require.context('./assets/js/services', true, /.*\.js/));
requireAll(require.context('./assets/js/filters', true, /.*\.js/));
requireAll(require.context('./assets/js/controllers', true, /.*\.js/));
