import template from './requestFactFind.html';
import controller from './requestFactFindCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
  },
};
