import template from './providerInfoToolsDownloads.html';
import controller from './providerInfoToolsDownloadsCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    providerId: '<',
  },
};
