import { COMPANY_TYPES } from 'Common/constants/partyTypes';

class partyTypeCtrl {
  $onInit() {
    this.trustTypes = [
      { value: COMPANY_TYPES.COMPANY, label: 'Company' },
      { value: COMPANY_TYPES.TRUST, label: 'Trust' },
    ];

    this.chosenTrust = this.trustTypes[this.partyType - 1];
    this.onTypeChange();
  }
}

export default partyTypeCtrl;
