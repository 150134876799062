import { map, find } from 'lodash';
import moment from 'moment';
import * as constants from 'Common/constants/workbench';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { typelessEqual } from 'Common/utilities/math';
import { PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';

class workbenchDetailsCtrl {
  constructor(
    optionsService,
    contactService,
    toaster,
    loanAppSharedData,
    loanScenarioService,
    modalRenderService,
    pipelineSharedData,
    pipelineService
  ) {
    'ngInject';

    this.optionsService = optionsService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.loanAppSharedData = loanAppSharedData;
    this.loanScenarioService = loanScenarioService;
    this.modalRenderService = modalRenderService;
    this.pipelineSharedData = pipelineSharedData;
    this.constants = constants;
    this.pipelineService = pipelineService;
  }

  $onInit() {
    this.reasonListView = [];
    this.statusList = [];
    this.involvedParties = [];
    this.selectedParty = {
      FamilyId: '',
      FamilyFullName: '',
    };
    this.nextWorkDate = {
      Date: null,
    };
    this.getLoanStatusList();
    this.importantDateGet();
    if (this.loanStatusId) {
      this.origLoanStatusId = this.loanStatusId.toString();
    }
    this.getReasonList();
  }

  /**
   * Description: Loan status list
   * */
  getLoanStatusList() {
    this.optionsService.getLoanStatus().then(response => {
      this.statusList = [];
      this.statusList = map(response.data, status => {
        return {
          LoanStatusGroupName: status.GroupName,
          LoanStatusName: status.Name,
          LoanStatusID: String(status.Value),
        };
      });
    });
  }

  /*
   * Description: Updates the loan's status
   * */
  moveToSettled() {
    if (this.selectedParty.FamilyId && this.loanId) {
      this.modalRenderService
        .openMovedToSettledModal(
          'modalLinkLoanToPipeline',
          this.selectedParty.FamilyId,
          this.loanId
        )
        .then(() => {
          this.toaster.pop('success', 'Loan Status successfully updated!');
        });
    }
  }
  updateLoanStatus() {
    if (typelessEqual(this.loanStatusId, LOAN_APP_STATUS_TYPES.NOT_PROCEEDED)) {
      this.modalRenderService
        .renderPipelineStatusMoveConfirmationModal({
          showNurtureCampaignsQuestion: this.pipelineSharedData.showNurtureCampaignsQuestion(
            this.enquirySource
          ),
          reasonList: this.reasonListView,
        })
        .result.then(result => {
          if (result.isOk) {
            this.moveLoanStatus(
              result.shouldSendNurtureCampaigns,
              result.selectedReasonId
            );
            this.origLoanStatusId = this.loanStatusId;
          } else {
            this.loanStatusId = this.origLoanStatusId;
          }
        });
    } else if (typelessEqual(this.loanStatusId, LOAN_STATUS.EXISTING.SETTLED)) {
      this.moveLoanStatus();
      this.moveToSettled();
    } else {
      this.moveLoanStatus();
    }
  }
  getReasonList() {
    this.pipelineService
      .getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
      .then(({ reasonList }) => {
        this.reasonListView = reasonList || null;
      });
  }

  moveLoanStatus(shouldSendNurtureCampaigns, selectedReasonId) {
    this.contactService
      .LoanStatusSet(
        this.loanId,
        this.loanStatusId,
        shouldSendNurtureCampaigns,
        selectedReasonId
      )
      .then(() => {
        this.toaster.pop('success', 'Loan Status successfully updated!');
        if (this.loanPipelineCardsId) {
          this.loanAppSharedData.lendingTabPipelineStatusData = {
            PipelineStatusID: this.loanStatusId,
          };
        }
      });
  }

  /*
   * Description: Get important dates of this loan app
   * */
  importantDateGet() {
    this.loanScenarioService
      .importantDateGet(this.loanScenarioId)
      .then(response => {
        // we only have business for Next Work Date
        this.nextWorkDate =
          find(response.data, obj => {
            obj.Date = new Date(obj.Date);
            return obj.TypeOfDateID === this.constants.NEXT_WORK_DATE;
          }) || {};
        this.nextWorkDate.isOpen = false;
        this.nextWorkDate.openDate = () => {
          this.nextWorkDate.isOpen = !this.nextWorkDate.isOpen;
        };
      });
  }

  importantDateSet() {
    const data = {
      loanId: this.loanId,
      nextWorkDate: moment(this.nextWorkDate.Date).format('YYYY-MM-DD'),
      description: 'Updated Next Work Date',
    };
    this.loanScenarioService.workbenchNextWorkDate(data).then(() => {
      this.onNwdUpdate();
    });
  }
}

export default workbenchDetailsCtrl;
