import _ from 'lodash';
import swal from 'sweetalert';
import { WORKBENCH_REQUIREMENT_TEMPLATE_TYPE } from 'Common/constants/workbenchRequirementTemplateType.js';

class WorkbenchRequirementsFormCtrl {
  constructor(loanScenarioService, userService) {
    'ngInject';

    this.loanScenarioService = loanScenarioService;
    this.userService = userService;
  }

  $onInit() {
    this.action = this.requirement ? 'Edit' : 'Add';
    if (this.requirement) {
      this.originalRequirement = _.clone(this.requirement);
    } else {
      this.requirement = { ApplicantID: 0 };
    }
    this.requirement.LoanID = this.loanId;
    this.fromDefaultRequirements =
      this.requirement.RequirementTemplateID &&
      this.requirement.RequirementTemplateID > 0;

    this.getDefaultRequirements();
    this.initClientsList();
  }

  getDefaultRequirements() {
    this.loadingDefaultRequirements = true;
    this.userService
      .UserRequirementTemplateGet({
        requirementTypeId: WORKBENCH_REQUIREMENT_TEMPLATE_TYPE.LOAN,
      })
      .then(response => {
        this.defaultRequirements = _.orderBy(
          response.data,
          ['OrderBy'],
          ['asc']
        );
        this.loadingDefaultRequirements = false;
      });
  }

  initClientsList() {
    if (!this.clients) return;
    this.clients = _.map(this.clients, client => {
      return {
        PersonId: parseInt(client.PersonId, 10),
        FullName: client.FullName,
        IsClient: client.IsClient,
      };
    });
    this.clients.unshift({ PersonId: 0, FullName: 'All Clients' });
  }

  onDefaultRequirementSelected() {
    const requirement = this.defaultRequirements.find(
      defRequirement =>
        defRequirement.RequirementTemplateID ===
        this.requirement.RequirementTemplateID
    );
    if (!requirement) return;

    this.requirement.RequirementName = requirement.RequirementTemplate;
    this.requirement.RequirementTemplateID = requirement.RequirementTemplateID;
  }

  onDefaultRequirementsToggled() {
    if (this.originalRequirement && this.isRequirementChanged()) {
      this.revertNameChanges();
    } else {
      this.clearNameChanges();
    }
  }

  revertNameChanges() {
    this.requirement.RequirementTemplateID = this.originalRequirement.RequirementTemplateID;
    const defaultRequirement = this.getDefaultRequirementById(
      this.requirement.RequirementTemplateID
    );
    this.requirement.RequirementName = defaultRequirement
      ? defaultRequirement.RequirementTemplate
      : this.originalRequirement.RequirementName;
  }

  clearNameChanges() {
    this.requirement.RequirementTemplateID = 0;
    this.requirement.RequirementName = null;
  }

  cancel() {
    if (!this.modalInstance) return;

    this.modalInstance.dismiss();
  }

  getDefaultRequirementById(id) {
    if (!this.defaultRequirements) return;

    const requirement = this.defaultRequirements.find(
      item => item.RequirementTemplateID === id
    );
    return requirement;
  }

  saveRequirement() {
    if (!this.isNewRequirement() && !this.isRequirementChanged()) return;
    if (!this.isRequirementValid()) {
      swal('Oooops', 'Requirement Name is required', 'error');
      return;
    }

    this.savingRequirement = true;
    this.loanScenarioService
      .workbenchRequirementSet(this.getPostData())
      .then(response => {
        this.savingRequirement = false;
        if (!this.requirement.RequirementID) {
          this.requirement.RequirementID = response.data;
        }
        this.modalInstance.close({
          success: true,
          requirement: this.requirement,
        });
      });
  }

  isRequirementValid() {
    const isNameValid =
      this.requirement.RequirementName &&
      this.requirement.RequirementName.trim();
    return this.requirement && this.requirement.LoanID && isNameValid;
  }

  isNewRequirement() {
    return !this.originalRequirement;
  }

  isRequirementChanged() {
    const isNameChanged =
      this.requirement.RequirementName !==
      this.originalRequirement.RequirementName;
    const isClientChanged =
      this.requirement.ApplicantID !== this.originalRequirement.ApplicantID;
    const isTemplateIDChanged =
      this.requirement.RequirementTemplateID !==
      this.originalRequirement.RequirementTemplateID;
    return isNameChanged || isClientChanged || isTemplateIDChanged;
  }

  getPostData() {
    const {
      LoanID: loanId,
      RequirementName: description,
      RequirementID: requirementId,
      RequirementTemplateID: requirementTemplateId,
      ApplicantID,
    } = this.requirement;
    const clientDetails = this.clients.find(
      client => client.PersonId === ApplicantID
    );
    let clientId;
    let entityId;
    if (clientDetails && clientDetails.IsClient) {
      clientId = ApplicantID;
    } else {
      entityId = ApplicantID;
    }
    return {
      loanId,
      description,
      requirementId,
      requirementTemplateId,
      clientId,
      entityId,
    };
  }

  isShowEmptyDefaultsWarning() {
    return (
      this.fromDefaultRequirements &&
      !this.loadingDefaultRequirements &&
      (!this.defaultRequirements || !this.defaultRequirements.length)
    );
  }
}

export default WorkbenchRequirementsFormCtrl;
