import angular from 'angular';
import _ from 'lodash';
import { INSURANCE_DATA } from 'Common/constants/insuranceOptions';
import { displayInfo } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('InsuranceDetailCtrl', function InsuranceDetailCtrl(
    $scope,
    $timeout,
    $uibModal,
    $state,
    $localStorage,
    $stateParams,
    $window,
    contactService,
    imageDataURI,
    insuranceQuoteService,
    aliService,
    toaster,
    insuranceModalApplicationService,
    clientInfoService
  ) {
    $scope.aliQuotes = [];
    $scope.primaryClientId = 0;
    $scope.aliQuoteEnabled = false;
    $scope.isAllocatedAdviserALIQuoteEnabled = true;
    $scope.familyId = $state.params.familyId;
    contactService.getUserInfo().then(response => {
      $scope.getPrimaryClient();
      const { data } = response;
      if (!data && !data.ALIQuoteEnabled) return;
      $scope.aliQuoteEnabled = data.ALIQuoteEnabled;
      $scope.getAliQuotes();
    });

    $scope.getAliQuotes = () => {
      aliService.getAliQuotes($stateParams.familyId).then(response => {
        $scope.aliQuotes = response;
      });
    };

    $scope.getPrimaryClient = () => {
      contactService.clientInformGet($stateParams.familyId).then(response => {
        const clients = response.data;
        $scope.client = {
          information: clients,
        };
        clientInfoService.getClientHeader($scope.client);
        $scope.clientHeaderName = $scope.client.headerName;

        const primaryClient = clients.filter(item => item.isPrimary);
        $scope.primaryClientId = primaryClient.length
          ? primaryClient[0].PersonId
          : 0;
        $scope.isAllocatedAdviserALIQuoteEnabled = primaryClient.length
          ? primaryClient[0].IsAllocatedAdviserALIQuoteEnabled
          : false;
        $scope.getAliQuotes();
      });
    };

    $scope.viewInAli = aliQuote => {
      if (!aliQuote.isLoading) {
        aliQuote.isLoading = true;
        aliService
          .getAliExternalLink(aliQuote.aliQuoteId, $stateParams.familyId)
          .then(response => {
            if (!response.succeeded) return displayInfo(response);

            aliQuote.isLoading = false;
            $window.open(response.data, '_blank');
          });
      }
    };

    $scope.openLoanAmountAliModal = () => {
      const modalInstance = $uibModal.open({
        templateUrl:
          'assets/views/contacts/client/insurances/loanAmountForAliModal.html',
        size: 'lg',
        controller: 'LoanAmountForAliCtrl',
        controllerAs: 'vm',
        resolve: {
          familyId: () => $stateParams.familyId,
          clientId: () => $scope.primaryClientId,
        },
      });
      modalInstance.result.then(() => {
        $scope.getAliQuotes();
      });
    };
    $scope.$watch('insuranceSubTab', newVal => {
      switch (newVal) {
        case 'inprogress':
          $scope.insuranceSubTabTemplate =
            '/assets/views/contacts/client/insurances/inProgress.html';
          break;
        case 'previous':
          $scope.insuranceSubTabTemplate =
            '/assets/views/contacts/client/insurances/previous.html';
          break;
        default:
          $scope.insuranceSubTab = 'existing';
          $scope.insuranceSubTabTemplate =
            '/assets/views/contacts/client/insurances/existing.html';
          break;
      }
    });

    /* INSURANCE LIST */

    // MODELS
    angular.extend($scope, {
      insuranceSubTab: '',
    });

    // SHARED DATA

    // FUNCTIONALITIES
    angular.extend($scope, {
      openInsuranceApplication() {
        insuranceModalApplicationService.onCreate({
          typeNew: true,
          familyId: $stateParams.familyId,
          quoteId: 0,
          fullName: $scope.FamilyFullName,
        });
      },
    });

    // strip unnecessary data
    function dataStripper(obj) {
      const id = angular.copy(obj.BenefitId);
      const tempBenefit = {
        BenefitId: id,
        Benefit: {},
        displayName: '',
      };

      switch (id) {
        case 1:
          tempBenefit.displayName = 'Health Cover';
          tempBenefit.Benefit = obj.HealthCover;
          break;
        case 2:
          tempBenefit.displayName = 'Life Cover';
          tempBenefit.Benefit = obj.LifeCover;
          break;
        case 3:
          tempBenefit.displayName = 'Family Protection';
          tempBenefit.Benefit = obj.FamilyProtection;
          break;
        case 4:
          tempBenefit.displayName = 'Trauma Cover';
          tempBenefit.Benefit = obj.TraumaCover;
          break;
        case 5:
          tempBenefit.displayName = 'Total and Permanent Disability';
          tempBenefit.Benefit = obj.TotalAndPermanentDisability;
          break;
        case 6:
          tempBenefit.displayName = 'Income Protection';
          tempBenefit.Benefit = obj.IncomeProtection;
          break;
        case 7:
          tempBenefit.displayName = 'Mortgage Repayment Cover';
          tempBenefit.Benefit = obj.MortgageRepaymentCover;
          break;
        case 8:
          tempBenefit.displayName = 'Redundancy Cover';
          tempBenefit.Benefit = obj.RedundancyCover;
          break;
        case 9:
          tempBenefit.displayName = 'Waiver Premium';
          tempBenefit.Benefit = obj.WaiverOfPremium;
          break;
        default:
          tempBenefit.displayName = 'Unknown Premium';
          tempBenefit.Benefit = {};
          break;
      }

      return tempBenefit;
    }

    // Saved Quotes
    angular.extend($scope, {
      savedQuotesList: [],
      savedProfilerList: [],
      initQuotes(moduleTypeVal) {
        insuranceQuoteService
          .insuranceQuoteGet(
            moduleTypeVal,
            0,
            parseInt($state.params.familyId, 10)
          )
          .then(response => {
            if (
              response.data &&
              response.data[0] &&
              response.data[0].Module === INSURANCE_DATA.PROFILER
            ) {
              const savedProfilerList = [];
              let savedProfiler = {};

              _.forEach(response.data, data => {
                savedProfiler = {
                  QuoteID: data.QuoteID,
                  DateCreated: data.DateCreated,
                  FamilyID: data.FamilyID,
                  NoOfClients: data.NoOfClients,
                  NoOfDependents: data.NoOfDependents,
                  BenefitList: [],
                };

                let tempBenefit = {};

                _.forEach(data.PeopleEntity, value => {
                  _.forEach(value.BenefitList, benefit => {
                    const result = _.filter(savedProfiler.BenefitList, o => {
                      return o.BenefitId === benefit.BenefitId;
                    });
                    if (result.length === 0) {
                      tempBenefit = dataStripper(benefit);
                      savedProfiler.BenefitList.push(tempBenefit);
                    }
                  });
                });
                savedProfilerList.push(savedProfiler);
                savedProfiler = {};
              });
              $scope.savedProfilerList = savedProfilerList;
            } else {
              switch (moduleTypeVal) {
                case INSURANCE_DATA.QUOTE:
                  $scope.savedQuotesList = [];
                  break;
                case INSURANCE_DATA.PROFILER:
                  $scope.savedProfilerList = [];
                  break;
                default:
                  break;
              }
            }
          });
      },
    });

    $scope.initQuotes(INSURANCE_DATA.QUOTE);

    // saved quotes methods
    angular.extend($scope, {
      addNewQuote() {
        $state.go('app.contactQuickQuote', {
          familyId: $stateParams.familyId,
          isFromInsuranceTab: true,
        });
      },
      openQuoteDetail(quoteObj) {
        $state.go('app.quickQuoteDetails', {
          insQuoteId: quoteObj.QuoteID,
          familyId: quoteObj.FamilyID,
        });
      },
      removeSavedQuote(quote) {
        insuranceQuoteService
          .insuranceQuoteDelete(quote.QuoteID)
          .then(response => {
            if (response.data === 1) {
              toaster.pop(
                'success',
                'Removed',
                'Insurance Quote successfully removed'
              );
              $scope.initQuotes(INSURANCE_DATA.QUOTE);
            } else {
              toaster.pop('error', 'Error', 'Insurance Quote was not removed');
            }
          });
      },
    });

    // Reload insurance outside scope

    // Clean up listeners
    $scope.$on('$destroy', () => {
      $scope.$destroy();
    });

    if ($state.params.benefitId) {
      const contactSingle = () => {
        $state.go('app.contactsSingle', {
          familyId: $state.params.familyId,
        });
      };
      $scope.ok = contactSingle();
      $scope.cancel = contactSingle();
      $scope.dismiss = contactSingle();

      $scope.newInsurance();
    }

    $scope.addNewProfiler = () => {
      $state.go('app.contactProfiler', { familyId: $stateParams.familyId });
    };

    $scope.updateInfo = function() {
      // TODO: Create service
      $localStorage.ActiveBenefitID = $scope.filter.benefit;
      $scope.newInsurance();
    };

    //-------------------------------------------------------------
    $scope.addExistingIsuranceModal = function() {
      $uibModal.open({
        templateUrl:
          '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
        controller: 'InsuranceExistingModalCtrl',
        scope: $scope,
        windowClass: 'insurance-existing-modal',
      });
    };
  });
