import template from './entityDetails.html';
import entityDetailsCtrl from './entityDetailsCtrl';

export default {
  template,
  controller: entityDetailsCtrl,
  controllerAs: 'vm',
  bindings: {
    partyType: '<',
    entityData: '=',
    formReference: '<',
  },
};
