import template from './sendReportsToEsign.html';
import controller from './sendReportsToEsignCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
    isSendCqp: '<',
    isSendCpa: '<',
  },
};
