export const SALE_TYPE_LIST = [
  { value: 1, name: 'All' },
  { value: 2, name: 'Private Sale' },
  { value: 3, name: 'Dealer' },
  { value: 4, name: 'Sale & Leaseback' },
];

export const CLIENT_TYPE = {
  COMMERCIAL: 1,
  PERSONAL: 2,
};

export const RESIDENTIAL_STATUS_TYPE = {
  PROPERTY_OWNER: 1,
  NOT_PROPERTY_OWNER: 2,
};
