import template from './emailCcField.html';
import controller from './emailCcFieldCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    label: '@',
    placeholder: '@',
    selectedCc: '<',
    isInvalid: '<',
    validationMessage: '<',
    onSelectCc: '&',
    onRemoveCc: '&',
  },
};
