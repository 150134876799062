class checkBoxFilterCtrl {
  constructor() {
    'ngInject';

    this.filterInfo = {};
  }

  $onInit() {
    this.initFilterInfoValue();
    if (this.onChangeInfo) {
      this.onChangeInfo({ filterInfo: this.filterInfo });
    }
  }

  initFilterInfoValue() {
    this.type.forEach(object => {
      this.filterInfo[object.type] = true;
    });
  }
}

export default checkBoxFilterCtrl;
