app.factory('selectOptionsService', [
  '$q',
  'selectOptionsModel',
  function($q, selectOptionsModel) {
    var factory = this;

    var selectOptions = new selectOptionsModel();

    factory.getLoanTerm = function() {
      var defer = $q.defer();

      selectOptions.getLoanTerm(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getLoanRepaymentFrequency = function() {
      var defer = $q.defer();

      selectOptions.getLoanRepaymentFrequency(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPaymentFrequency = function() {
      var defer = $q.defer();

      selectOptions.getPaymentFrequency(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getCalculationPeriod = function() {
      var defer = $q.defer();

      selectOptions.getCalculationPeriod(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getIPCoverType = function() {
      var defer = $q.defer();

      selectOptions.getIPCoverType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getWaitPeriod = function() {
      var defer = $q.defer();

      selectOptions.getWaitPeriod(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getBenefitPeriods = function() {
      var defer = $q.defer();

      selectOptions.getBenefitPeriods(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    // Security Structure
    factory.getSecurityType = function() {
      var defer = $q.defer();

      selectOptions.getSecurityType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyOwnership = function() {
      var defer = $q.defer();

      selectOptions.getPropertyOwnership(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyType = function() {
      var defer = $q.defer();

      selectOptions.getPropertyType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyDevelopmentStatus = function() {
      var defer = $q.defer();

      selectOptions.getPropertyDevelopmentStatus(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyIntention = function() {
      var defer = $q.defer();

      selectOptions.getPropertyIntention(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyZoning = function() {
      var defer = $q.defer();

      selectOptions.getPropertyZoning(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyTenure = function() {
      var defer = $q.defer();

      selectOptions.getPropertyTenure(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getPropertyValuationType = function() {
      var defer = $q.defer();

      selectOptions.getPropertyValuationType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    // Serviceability
    factory.getIncomeTypeForRegion = function(brokerEventId) {
      var defer = $q.defer();

      selectOptions.getIncomeTypeForRegion(
        brokerEventId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getLiabiltyTypeForRegion = function(brokerEventId) {
      var defer = $q.defer();

      selectOptions.getLiabiltyTypeForRegion(
        brokerEventId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    factory.getExpenseType = function() {
      var defer = $q.defer();

      selectOptions.getExpenseType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );

      return defer.promise;
    };

    return factory;
  },
]);
