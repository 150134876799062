import swal from 'sweetalert';

export default function success(title, successCallback) {
  swal(
    {
      imageUrl: 'assets/images/new_icons/success.svg',
      title,
      showCancelButton: false,
      confirmButtonColor: '#5b5084',
      confirmButtonText: 'Back to Workbench',
      closeOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: 'workbench-send-email-success',
    },
    confirm => {
      if (confirm) {
        successCallback();
      }
    }
  );
}
