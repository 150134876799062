import './style.scss';
import template from './employmentDetails.html';
import EmploymentDetailsCtrl from './employmentDetailsCtrl';

export default {
  template,
  bindings: {
    formReference: '<',
    employment: '=',
  },
  controller: EmploymentDetailsCtrl,
  controllerAs: 'vm',
};
