import { ENTITY_TYPE } from 'Common/constants/entityType';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';

class OnboardingCompletePreviewCtrl {
  constructor(onboardingDataService, dataService) {
    'ngInject';

    this.isCountryNewZealand = false;
    this.entityTypeIsCompany = false;
    this.entityTypeIsSoleTrader = false;
    this.entityTypeIsPartnership = false;
    this.isOwnBrand = false;
    this.onboardingDataService = onboardingDataService;
    this.dataService = dataService;
    this.currentIndex = 0;
    this.isFranchisee = false;
    this.isContractor = false;
    this.isEmployee = false;
    this.isPartner = false;
    this.isDirector = false;
    this.level_access = [];
  }

  $onInit() {
    this.isCountryNewZealand = this.onboardingDataService.isUserCurrentCountry(
      COUNTRY_TYPE.NEW_ZEALAND
    );
    this.entityTypeIsCompany =
      this.onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.COMPANY_TRUST
      );
    this.entityTypeIsSoleTrader =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.SOLE_TRADER_TRUST
      );
    this.entityTypeIsPartnership =
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP
      ) ||
      this.onboardingDataService.isUserCurrentEntityType(
        ENTITY_TYPE.PARTNERSHIP_TRUST
      );
    this.level_access = this.dataService.getLevelAccessList();
    this.onboardingDataService
      .getOnboardingResponse(
        this.onboardingDataService.getFamilyId(),
        this.onboardingDataService.getClientId()
      )
      .then(response => {
        if (response) {
          if (response.RoleId) {
            this.isFranchisee = this.onboardingDataService.isOnboardingUserRole(
              response.RoleId,
              ONBOARDING_ROLE_ID.FRANCHISEE
            );
          }
        }
      });
  }

  setQuestionniare(index) {
    this.currentIndex = index;
  }
}

export default OnboardingCompletePreviewCtrl;
