export const ANNOUNCEMENTS = {
  MAX_LVR: 1,
  QUICK_LMI: 3,
};

export const ANNOUNCEMENT_LOCAL_STORAGE = {
  DONT_CHECK: 0,
  CHECK_FIRST_LOGIN: 1,
  CHECK_HIJACK: 2,
};
