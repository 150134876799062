import angular from 'angular';

angular
  .module('app')
  .controller('PersonalInfoCtrl', function PersonalInfoCtrl(
    $scope,
    $timeout,
    $uibModal,
    userService,
    $stateParams,
    configService,
    contactService,
    accountService
  ) {
    /**
     * Get Personal Info
     * */
    $scope.PersonalInfoGet = function() {
      userService.PersonalInfoGet().then(response => {
        $scope.PersonalInfo = response.data;

        $scope.socialMedia();

        $scope.PersonalInfoCopy = {};

        angular.copy($scope.PersonalInfo, $scope.PersonalInfoCopy);

        $scope.personalInfoUpdated = false;

        $scope.getAvatar($scope.PersonalInfo.Avatar.DocumentId);
      });
    };

    $scope.getAvatar = function(id) {
      if (parseInt(id, 10) < 1) return false;
      contactService
        .documentThumbnailGet({
          documentID: id - 1,
          startPage: 1,
          pageNumber: 1,
          thumbWidth: 300,
        })
        .then(respond => {
          const thumbObj = respond.data[0];
          if (thumbObj.ContentType && thumbObj.ThumbnailContent) {
            $scope.myAvatar = `data:${thumbObj.ContentType};base64,${
              thumbObj.ThumbnailContent
            }`;
          } else {
            $scope.myAvatar = false;
          }
        });
    };

    /**
     * Set Personal Info
     * */
    $scope.PersonalInfoSet = function() {
      const data = {
        Avatar: {
          uploadUser: {},
        },
        UserName: $scope.PersonalInfo.UserName,
        Region: $scope.PersonalInfo.Region,
        Phone: $scope.PersonalInfo.Phone,
        Skype: $scope.PersonalInfo.Skype,
        Facebook: $scope.PersonalInfo.Facebook,
        LinkedIn: $scope.PersonalInfo.LinkedIn,
        Twitter: $scope.PersonalInfo.Twitter,
        Google: $scope.PersonalInfo.Google,
        Address: {
          formatted_address: $scope.PersonalInfo.Address.formatted_address,
        },
      };

      if ($scope.AvatarID) {
        data.Avatar.DocumentId = $scope.AvatarID;
        data.Avatar.Name = $scope.AvatarID;
        data.Avatar.DateCreated = new Date();
      }
      userService.PersonalInfoSet(data).then(() => {
        $scope.personalInfoUpdated = false;
        angular.copy($scope.PersonalInfo, $scope.PersonalInfoCopy);
      });
    };

    $scope.personalInfoUpdated = false;

    $scope.PersonalInfoWatchers = function() {
      $scope.$watch('Avatar.DocumentId', (newVal, oldVal) => {
        if (oldVal) {
          $scope.personalInfoUpdated = true;
        }
      });

      $scope.$watch('PersonalInfo.UserName', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.UserName) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Region', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Region) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Phone', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Phone) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Skype', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Skype) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Facebook', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Facebook) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.LinkedIn', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.LinkedIn) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Twitter', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Twitter) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch('PersonalInfo.Google', (newVal, oldVal) => {
        if (oldVal) {
          if (newVal === $scope.PersonalInfoCopy.Google) {
            $scope.personalInfoUpdated = false;
          } else {
            $scope.personalInfoUpdated = true;
          }
        }
      });

      $scope.$watch(
        'PersonalInfo.Address.formatted_address',
        (newVal, oldVal) => {
          if (oldVal) {
            if (newVal === $scope.PersonalInfoCopy.Address.formatted_address) {
              $scope.personalInfoUpdated = false;
            } else {
              $scope.personalInfoUpdated = true;
            }
          }
        }
      );
    };

    $scope.PersonalInfoWatchers();

    /**
     * Toggle Social Media Links
     * */
    $scope.addLinkedin = function() {
      $scope.showlinkedin = true;
    };
    $scope.addTwitter = function() {
      $scope.showtwitter = true;
    };
    $scope.addGPlus = function() {
      $scope.showgplus = true;
    };

    $scope.socialMedia = function() {
      if (!$scope.PersonalInfo.LinkedIn) {
        $scope.showlinkedin = false;
      } else {
        $scope.showlinkedin = true;
      }

      if (!$scope.PersonalInfo.Twitter) {
        $scope.showtwitter = false;
      } else {
        $scope.showtwitter = true;
      }

      if (!$scope.PersonalInfo.Google) {
        $scope.showgplus = false;
      } else {
        $scope.showgplus = true;
      }
    };

    /**
     * Uploading of photo and logo
     * */

    $scope.personalInfoInterface = {};
    $scope.uploadCount = 0;
    $scope.success = false;
    $scope.error = false;
    $scope.familyId = $stateParams.familyId;
    $scope.hideDocumentProgress = false;

    $scope.personalInfoDocumentProgressVisibility = function(visibility) {
      $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
    };

    $scope.openAvatarModal = function(obj) {
      $scope.documentDataObject = obj;

      $uibModal.open({
        templateUrl: '/assets/views/user/partials/add_image_modal.html',
        controller: 'MyAccountModalCtrl',
        scope: $scope,
      });
    };

    $scope.$on('$dropletReady', () => {
      if ($scope.personalInfoInterface) {
        $scope.personalInfoInterface = accountService.accountDropletReadyOptions(
          $scope.personalInfoInterface
        );
      }
    });

    $scope.$on('$dropletFileAdded', () => {
      if (!$scope.personalInfoInterface.isUploading()) {
        $scope.personalInfoDocumentProgressVisibility(1);
        $scope.personalInfoInterface.uploadFiles();
      }
    });

    $scope.$on('$dropletSuccess', () => {
      if ($scope.personalInfoInterface.isReady) {
        $scope.personalInfoInterface.uploadFiles();
      }
    });

    // Listen for when the files have been successfully uploaded.
    $scope.$on('$dropletSuccess', (event, response, files) => {
      const documentObj = response[0];
      $scope.uploadCount = files.length;
      $scope.success = true;
      if ($scope.infoType === 'personal') {
        $scope.openAvatarModal({
          Title: documentObj.Name,
          DocumentID: documentObj.DocumentId,
          FamilyID: $scope.familyId,
        });
      }
      $scope.AvatarID = documentObj.DocumentId;

      // Hides the upload progressbar
      $scope.personalInfoDocumentProgressVisibility(0);

      $timeout(() => {
        $scope.success = false;
      }, 5000);
    });

    // Listen for when the files have failed to upload.
    $scope.$on('$dropletError', () => {
      $scope.error = true;
    });

    /**
     * Initialize data
     * */
    $scope.PersonalInfoGet();
  });
