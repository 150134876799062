import { aliQuoteBuilderForUI } from 'Common/mappers/aliQuote';

class AliService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'ali-group';
  }

  authAliGroup() {
    return this.httpClient.get(`${this.apiBaseUrl}/auth`);
  }
  getAliQuotes(familyId) {
    return this.httpClient
      .get(this.apiBaseUrl, { familyId })
      .then(
        response =>
          (response.data && response.data.map(aliQuoteBuilderForUI)) || []
      );
  }
  getAliExternalLink(aliQuoteId, familyId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/external-link/${aliQuoteId}`,
      { familyId }
    );
  }
  storeAliQuote(data) {
    return this.httpClient.post(this.apiBaseUrl, data);
  }
}

export default AliService;
