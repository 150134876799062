import { SCREEN_SIZE } from 'Common/constants/screenSize';

const MIN_SEARCH_TEXT_LENGTH = 3;

export default class ProviderInformationListCtrl {
  constructor($window, $timeout, providerInformationService) {
    'ngInject';

    this.$window = $window;
    this.$timeout = $timeout;
    this.providerInformationService = providerInformationService;
  }

  $onInit() {
    this.loadProviders();
    this.onLoadingProvidersFailed = this.loadProvidersFailedHandler.bind(this);
  }

  $onDestroy() {
    if (this.onFilterToggledTimeout) {
      this.$timeout.cancel(this.onFilterToggledTimeout);
    }
  }

  loadProviders() {
    this.isLoadingProviders = true;
    this.providerInformationService
      .getProvidersInformation()
      .then(response => {
        this.unfilteredProviders = [...response];
        this.loadCategoryFilters();
      })
      .catch(this.onLoadingProvidersFailed);
  }

  loadCategoryFilters() {
    this.providerInformationService
      .getProviderCategories()
      .then(response => {
        this.categoryFilters = response;
        this.loadUserCategoryFilters();
      })
      .catch(this.onLoadingProvidersFailed);
  }

  loadUserCategoryFilters() {
    this.providerInformationService
      .getUserProviderCategories()
      .then(({ data }) => {
        const categoryIds =
          data && data.length
            ? data
            : this.categoryFilters.map(filter => filter.id);
        this.setSelectedCategoryFilters(categoryIds);
      })
      .catch(() => {
        const categoryIds = this.categoryFilters.map(filter => filter.id);
        this.setSelectedCategoryFilters(categoryIds);
        this.onLoadingProvidersFailed();
      });
  }

  loadProvidersFailedHandler() {
    this.isLoadingProviders = false;
  }

  isCategoryEnabled(categoryId) {
    const category = this.categoryFilters.find(
      filter => filter.id === categoryId
    );
    return category && category.enabled;
  }

  setSelectedCategoryFilters(categoryFilters) {
    if (!categoryFilters || !categoryFilters.length) return;
    this.selectedCategoryFilters = categoryFilters.filter(filter =>
      this.isCategoryEnabled(filter)
    );
    this.providers = this.getFilteredProviders();
    this.isLoadingProviders = false;
  }

  isMobile() {
    return this.$window.innerWidth <= SCREEN_SIZE.SM - 1;
  }

  onFilterToggled(filter, isSelected) {
    this.$timeout.cancel(this.onFilterToggledTimeout);
    this.isLoadingProviders = true;
    if (isSelected) {
      this.selectedCategoryFilters = [...this.selectedCategoryFilters, filter];
    } else {
      this.removeFilter(filter);
    }
    this.onFilterToggledTimeout = this.$timeout(() => {
      this.providers = this.getFilteredProviders();
      this.isLoadingProviders = false;
      this.saveSelectedFilters();
    }, 1000);
  }

  removeFilter(filter) {
    const indexOfFilter = this.selectedCategoryFilters.indexOf(filter);
    this.selectedCategoryFilters = [
      ...this.selectedCategoryFilters.slice(0, indexOfFilter),
      ...this.selectedCategoryFilters.slice(indexOfFilter + 1),
    ];
  }

  getFilteredProviders() {
    return this.unfilteredProviders.filter(provider => {
      const category = provider.categories[0];
      const categorySelected = this.selectedCategoryFilters.includes(
        category.id
      );
      return categorySelected && this.hasSearchTextInName(provider);
    });
  }

  hasSearchTextInName(provider) {
    if (!this.searchFilter) return true;
    const providerName = provider.providerName.toLowerCase();
    return providerName.includes(this.searchFilter.toLowerCase());
  }

  saveSelectedFilters() {
    const categoryIds = this.selectedCategoryFilters.join(',');
    this.providerInformationService.setUserProviderCategories(categoryIds);
  }

  toggleFilter() {
    this.isFilterShown = !this.isFilterShown;
  }

  filterByProviderName() {
    if (this.searchFilter && this.searchFilter.length < MIN_SEARCH_TEXT_LENGTH)
      return;
    this.providers = this.getFilteredProviders();
  }
}
