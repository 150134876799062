import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('lenderSplit', function lenderSplit(
    $timeout,
    overviewDashboardService,
    corporateService,
    queryService,
    $uibModal,
    corporateWidgetDefaultService,
    overviewDashboardDetailService,
    uiService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/lenderSplit.html',
      link(scope) {
        scope.tableMode = false;
        scope.chartMode = true;
        scope.customizeMode = false;
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };

        scope.labels = [];
        scope.data = [];
        scope.colors = [];
        scope.timePeriodArrays = [];
        scope.timePeriodArraysSelected = {};
        scope.lenderSplitdata = [];
        const countPerPage = 3;

        // Corporate Functionality @DatzMe101
        scope.widgetModel = {};
        scope.widgetModel.reportingStateList = [];
        scope.widgetModel.reportingStateId = 0;
        scope.widgetModel.isCorporateUser = false;
        scope.widgetModel.corporateSetting = corporateWidgetDefaultService.getDefaultTimePeriod();

        scope.showBy = [
          { id: 1, name: '# of Transactions', value: 'number' },
          { id: 2, name: 'Value of Transactions', value: 'amount' },
        ];

        //  Config
        scope.color_codes = [
          '#00bcd5',
          '#7f9fae',
          '#01bfbf',
          '#8cc052',
          '#b880c9',
          '#604faf',
        ];

        // Chart.js Options
        scope.options = overviewDashboardDetailService.getOptions();

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'lenderSplit.html',
          title: 'Title',
        };

        scope.getCountryCode = () => {
          return uiService.getCountryCode();
        };

        const currentDate = new Date();
        let getMonth = 100;
        let getFullYear;

        function lenderSplitFn(month, fullYear) {
          scope.showSlick = false;
          corporateService
            .loansSummaryLenderSplitGet(1, 0, month, fullYear)
            .then(response => {
              scope.data = [];
              scope.colors = [];
              scope.labels = [];
              const dataParent = _.orderBy(
                response.data,
                ['LenderAmountSplitPercent', 'LenderName'],
                ['desc', 'asc']
              );
              if (dataParent.length > 0) {
                const regex = /(\w+\s)/;
                let matches;
                angular.forEach(dataParent, (splitVal, splitKey) => {
                  if (splitVal.LenderName) {
                    matches = splitVal.LenderName.match(regex);
                    if (matches) {
                      splitVal.logoName = matches[1].trim();
                    }
                  }

                  splitVal.colorKey = splitKey;
                  scope.data.push(splitVal.LenderAmountSplitPercent.toFixed(2));
                  scope.labels.push(splitVal.LenderName);
                  scope.colors.push(scope.color_codes[splitKey % 7]);
                });
              } else {
                scope.data.push(0);
                scope.labels.push('');
                scope.colors.push('');
              }

              if (!scope.showSlick) {
                scope.lenderSplitdata = queryService.queryPerPage(
                  countPerPage,
                  dataParent
                );
                if (scope.lenderSplitdata.length > 0) {
                  scope.showSlick = true;
                }
              }
            });
        }

        const populateDropdownShowForStatus = value => {
          const status =
            scope.statusList &&
            scope.statusList.find(x => x.LoanStatusID === parseInt(value, 10));
          if (!status) return;
          scope.widgetModel.status = status;
        };

        function populateDropdownShowBy(value) {
          for (let i = 0; i < scope.showBy.length; i++) {
            if (scope.showBy[i].value === value) {
              scope.widgetModel.showBy = scope.showBy[i];
            }
          }
        }

        function populateDropdownShowFor(value) {
          const val = parseInt(value, 10);
          if (scope.reportingStateList && scope.reportingStateList.length > 0) {
            scope.reportingStateList.forEach(item => {
              if (item.ReportingStateId === val) {
                scope.widgetModel.reportingState = item;
              }
            });
          }
        }

        function widgetOptionGet() {
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(response => {
              scope.lenderOptions = response.data;

              angular.forEach(response.data, value => {
                if (parseInt(value.WidgetOptionId, 10) === 15) {
                  scope.timePeriodArraysSelected = scope.timePeriodArrays.filter(
                    item =>
                      parseInt(item.OptionDisplayName, 10) ===
                      parseInt(value.DisplayValue, 10)
                  )[0];
                } else if (parseInt(value.WidgetOptionId, 10) === 12) {
                  populateDropdownShowForStatus(value.SelectedUerValues);
                } else if (parseInt(value.WidgetOptionId, 10) === 13) {
                  populateDropdownShowBy(value.SelectedUerValues);
                } else if (parseInt(value.WidgetOptionId, 10) === 14) {
                  populateDropdownShowFor(value.SelectedUerValues);
                }
              });
            });
        }

        function init() {
          scope.chartMode = true;
          corporateService
            .getWidgetOptionTimePeriodList6()
            .then(
              response => {
                scope.timePeriodArrays = response.data;
              },
              () => {}
            )
            .then(() => {
              widgetOptionGet();

              scope.$watch('timePeriodArraysSelected', newValue => {
                if (newValue !== undefined) {
                  if (newValue.OptionDisplayName === 'Last Year') {
                    getMonth = 0;
                    getFullYear = currentDate.getFullYear() - 1;
                  } else {
                    getMonth = 0;
                    getFullYear = currentDate.getFullYear();
                  }

                  lenderSplitFn(getMonth, getFullYear);
                }
              });

              if (!scope.timePeriodArraysSelected) {
                lenderSplitFn(-100, 0);
              }
            });
        }

        function reportingStatesGet() {
          corporateService.reportingStatesGet().then(response => {
            const data = {
              ReportingStateId: 0,
              ReportingStateName: 'All',
            };
            response.data.unshift(data);
            scope.reportingStateList = response.data;
            scope.widgetModel.reportingState = response.data[0];
          });
        }

        reportingStatesGet();

        function getStatus() {
          corporateService.getRelatedLoanStatus().then(response => {
            scope.statusList = response.data;
            scope.widgetModel.status = response.data[0];
            $timeout(() => {
              widgetOptionGet();
            }, 100);
          });
        }

        getStatus();

        function saveOption(widgetSetObj) {
          overviewDashboardService
            .WidgetOptionSet(widgetSetObj)
            .then(widgetOptionGet)
            .catch(displayError);
        }

        scope.saveDropdownValue = function(widgetOptionId) {
          const filterData = scope.lenderOptions.filter(
            item =>
              parseInt(item.WidgetOptionId, 10) === parseInt(widgetOptionId, 10)
          );

          if (filterData.length > 0) {
            let value = '';
            filterData[0].WidgetId = scope.myParam.WidgetId;
            filterData[0].Widget_UserID = scope.myParam.Widget_UserID;

            switch (widgetOptionId) {
              case 12:
                value = scope.widgetModel.status.LoanStatusID;
                break;
              case 13:
                value = scope.widgetModel.showBy.value;
                break;
              case 14:
                value = scope.widgetModel.reportingState.ReportingStateId;
                break;
              default:
              // default case
            }
            filterData[0].SelectedUerValues = value;
            saveOption(filterData[0]);
          }
        };

        scope.doAction = function(msg) {
          if (msg === 'CustomiseMode') {
            scope.customizeMode = true;
            scope.chartMode = false;
            scope.tableMode = false;
          } else if (msg === 'TableMode') {
            scope.customizeMode = false;
            scope.chartMode = false;
            scope.tableMode = true;
          } else if (msg === 'GraphMode') {
            scope.customizeMode = false;
            scope.chartMode = true;
            scope.tableMode = false;
          }
        };

        function saveForTimePeriod(data) {
          overviewDashboardService.WidgetOptionSet(data).catch(displayError);
        }

        scope.updateTimePeriod = function(timeperiod) {
          const data = {};
          data.Widget_UserID = scope.myParam.Widget_UserID;
          data.Widget_OptionValue = timeperiod.Option_ValueID;
          data.WidgetOptionId = timeperiod.OptionID;
          data.DisplayValue = timeperiod.OptionDisplayName;
          data.SelectedUser = `${timeperiod.OptionDB_value}`;
          saveForTimePeriod(data);
        };

        // =============  Jasper Report ====================
        const countryQ = 1;
        const startDate = 'YEAR-10';
        const endDate = 'DAY-1';

        scope.report = {
          resource:
            '/Loan_Market/DEV/Corporate/Reports/Stand_Alone/WIP_Loans/WIP_Lender_Split',
          params: {
            Country_Q: [countryQ],
            Relative_Start_Date: [startDate],
            Relative_End_Date: [endDate],
          },
        };

        scope.viewReport = function(resourcePath) {
          scope.report.resource = resourcePath;
          $uibModal.open({
            templateUrl:
              'assets/views/overviewDashboard/modals/visualizeReportView.html',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            scope,
            controller($uibModalInstance) {
              scope.closeModal = function() {
                $uibModalInstance.dismiss('cancel');
              };
            },
          });
        };

        //= ============== End of Jasper Report ===================================

        // Initalization
        init();
      },
    };
  });
