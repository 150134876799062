import template from './navigationMobile.html';
import NavigationMobileCtrl from './navigationMobileCtrl';

export default {
  template,
  bindings: {
    uiService: '<',
    configService: '<',
    showPriceFinder: '&',
    priceFinderClick: '&',
    waitPriceFinderResponse: '<',
  },
  controller: NavigationMobileCtrl,
};
