import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import swal from 'sweetalert';
import toastr from 'toastr';

export default class ContactAddressesCtrl {
  constructor(
    $state,
    $interval,
    $window,
    $uibModal,
    currentUserService,
    contactService,
    colorService,
    utilitiesService,
    mapService
  ) {
    'ngInject';

    this.$state = $state;
    this.$interval = $interval;
    this.$window = $window;
    this.$uibModal = $uibModal;
    this.currentUserService = currentUserService;
    this.contactService = contactService;
    this.colorService = colorService;
    this.utilitiesService = utilitiesService;
    this.mapService = mapService;
  }

  $onInit() {
    this.propertyList = [];
    this.is_map_loaded = false;
    this.familyId = this.$state.params.familyId;

    if (this.familyId) {
      this.getAddresses();
    }
  }

  getAddresses() {
    this.contactService.contactAddressGet(this.familyId).then(({ data }) => {
      const propertyList = data || [];
      propertyList.map(property => {
        property.ContactLivingIn &&
          property.ContactLivingIn.map(contact => {
            contact.background = this.colorService.getRandomColor();

            const filterInitialMethod = contact.LastName
              ? 'filterInitial'
              : 'filterInitialOneString';
            const filterParams = contact.LastName
              ? [contact.FirstName, contact.LastName]
              : [contact.FirstName];
            contact.initials = this.utilitiesService[filterInitialMethod](
              ...filterParams
            );
            contact.displayName = `${contact.FirstName} ${contact.LastName ||
              ''}`;

            return contact;
          });

        property.isCurrent = +property.TypeId === +ADDRESS_TYPE.CURRENT_ADDRESS;
        property.isOther = +property.TypeId === +ADDRESS_TYPE.OTHER;
        property.isPostSettlement =
          +property.TypeId === +ADDRESS_TYPE.POST_SETTLEMENT_ADDRESS;
        property.isPostal = +property.TypeId === +ADDRESS_TYPE.POSTAL_ADDRESS;
        property.isPrevious =
          +property.TypeId === +ADDRESS_TYPE.PREVIOUS_ADDRESS;

        property.isShowDates = property.isCurrent || property.isPrevious;

        return property;
      });

      this.propertyList = propertyList;

      const googleInterval = this.$interval(() => {
        if (!this.$window.google) return false;

        this.propertyList.map(property => {
          if (property.latitude && property.longitude) {
            property.hasMap = true;
            const args = [
              property.latitude,
              property.longitude,
              property.formatted_address,
              undefined,
              false,
              `map-${property.AddressValuationExtendedDetailsId}`,
              '30',
            ];
            this.mapService.mapDesign(...args);
          } else {
            property.hasMap = false;
          }
          return property;
        });
        this.$interval.cancel(googleInterval);
      }, 500);
    });
  }

  openAddressModal(modalType, propertySet) {
    const props = { modalType, familyId: +this.familyId, propertySet };
    this.$uibModal
      .open({
        template: `<address-history-modal
        modal-instance="vm.modalInstance"
        modal-type="vm.props.modalType"
        family-id="vm.props.familyId"
        property-set="vm.props.propertySet"
      ></address-history-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: { props: () => props },
        keyboard: false,
        backdrop: 'static',
      })
      .result.then(() => {
        this.getAddresses();
      });
  }

  newAddressesModal() {
    this.openAddressModal('new');
  }

  editAddressesModal(property) {
    this.contactService
      .contactAddressDetailsGet(
        property.AddressValuationExtendedDetailsId,
        this.familyId
      )
      .then(({ data }) => {
        const propertySet = data || {};
        propertySet.FullAddress = propertySet.Address;

        this.openAddressModal('edit', propertySet);
      });
  }

  deleteAddress(addressValuationExtendedDetailsId) {
    swal(
      {
        title: 'Are you sure?',
        text: 'This record will be removed from your addresses list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      confirm => {
        if (confirm) {
          this.contactService
            .contactAddressDelete(
              addressValuationExtendedDetailsId,
              this.familyId
            )
            .then(() => {
              this.getAddresses();
              toastr.success(
                'Property has been been successfully deleted.',
                'Deleted'
              );
            });
        }
      }
    );
  }
}
