import template from './dobDayMonthYear.html';

export default {
  template,
  controller: 'DobDMYCtrl as vm',
  bindings: {
    personData: '<',
    onUpdated: '&',
  },
};
