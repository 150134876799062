import angular from 'angular';

angular
  .module('app')
  .controller(
    'CustomerCommentViewAllModalCtrl',
    function CustomerCommentViewAllModalCtrl(
      $scope,
      $uibModalInstance,
      viewAlldata
    ) {
      $scope.customerViewAlldata = viewAlldata;
      $scope.colors = ['#b38989', '#8073b8', '#a17fb5'];
      $scope.closeModal = () => {
        $uibModalInstance.dismiss('cancel');
      };
    }
  );
