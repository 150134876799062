export function aliQuoteBuilderForUI(quote) {
  return {
    aliQuoteId: quote.ALIQuoteID,
    familyId: quote.FamilyID,
    clientId: quote.ClientID,
    clientFullName: quote.ClientFullName,
    loanAmount: quote.LoanAmount,
    isSuccessful: quote.IsSuccessful,
    errorReason: quote.ErrorReason,
    createdDate: quote.CreatedDate,
  };
}
