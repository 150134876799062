import angular from 'angular';
import opportunity from './opportunity';
import opportunityMain from './opportunityMain';
import opportunityDetails from './opportunityMain/opportunityDetails';
import involvedPartyRows from './opportunityMain/opportunityDetails/involvedPartyRows';
import opportunityCalculations from './opportunityMain/opportunityCalculations';
import opportunitySidebar from './opportunitySidebar';
import relatedTask from './opportunitySidebar/relatedTask';
import opportunitySummary from './opportunitySidebar/opportunitySummary';
import manageParties from './opportunityMain/manageParties';
import managePartiesBorrower from './opportunityMain/manageParties/borrowers';
import managePartiesGuarantor from './opportunityMain/manageParties/guarantors';
import opportunityNotes from './opportunityMain/opportunityNotes';
import opportunityDocuments from './opportunityMain/opportunityDocuments';
import opportunityNotesModal from './opportunityMain/opportunityNotes/opportunityNotesModal';
import './style.scss';

export default angular
  .module('components.opportunity', [])
  .component('opportunity', opportunity)
  .component('opportunityMain', opportunityMain)
  .component('opportunityDetails', opportunityDetails)
  .component('involvedPartyRows', involvedPartyRows)
  .component('opportunityCalculations', opportunityCalculations)
  .component('opportunitySidebar', opportunitySidebar)
  .component('relatedTask', relatedTask)
  .component('opportunitySummary', opportunitySummary)
  .component('manageParties', manageParties)
  .component('managePartiesBorrower', managePartiesBorrower)
  .component('managePartiesGuarantor', managePartiesGuarantor)
  .component('opportunityNotes', opportunityNotes)
  .component('opportunityDocuments', opportunityDocuments)
  .component('opportunityNotesModal', opportunityNotesModal).name;
