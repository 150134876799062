class OnboardingContentCtrl {
  constructor() {
    'ngInject';

    this.activeTab = 0;
    this.tabs = [];
  }

  $onChanges(changes) {
    if (changes && changes.activeTab) {
      this.activeTab = parseInt(changes.activeTab.currentValue, 0);
    }
    if (changes && changes.tabs) {
      this.tabs = changes.tabs.currentValue;
    }
  }
}

export default OnboardingContentCtrl;
