import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

export default class StatusDropdownLeadsCtrl {
  $onInit() {
    this.PIPELINE_STATUS = PIPELINE_STATUS;
    this.changeDisabled =
      this.pipelineStatus === 'Settled' ||
      this.pipelineStatus === 'Not Proceeded';
    this.disabledStatus = ['Settled', 'Not Proceeded'];
  }
}
