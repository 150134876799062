import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('SendEmailCtrl', function SendEmailCtrl(
    $scope,
    $state,
    $timeout,
    corporateService,
    contactService,
    $stateParams,
    uiService
  ) {
    $scope.familyId = $stateParams.familyId;

    /* model */
    $scope.recipients = {};
    $scope.ccs = {};
    $scope.emailContactType = $stateParams.contactType;
    $scope.originalRecipients = [];
    $scope.originalRecipientsCount = 0;

    function routeContact() {
      if ($scope.emailContactType === 'adviser') {
        $state.go('app.contactsAdviser', {
          familyId: $scope.familyId,
          clientId: 0,
        });
      } else if ($scope.emailContactType === 'client') {
        $state.go('app.contactsSingle', { familyId: $scope.familyId });
      }
    }
    $scope.routeContact = routeContact;

    function getAdviserInfo() {
      corporateService
        .corporateContactGet($scope.paramClientId, $scope.familyId)
        .then(response => {
          $scope.adviserInfo = response.data;
          $scope.recipients.email = [];
          $scope.recipients.name = [];
          $scope.originalRecipients.name = [];
          $scope.originalRecipients.email = [];
          $scope.ccs.name = [];
          $scope.ccs.email = [];
          const data = response.data;

          if (_.size(data) > 0) {
            const email = data.Email ? data.Email : '';
            const firstName = data.FirstName ? data.FirstName : '';
            const lastName = data.LastName ? data.LastName : '';

            $scope.clientHeaderName = `${firstName} ${lastName}`;
            $scope.recipients.email.push(email);
            $scope.originalRecipients.email.push(email);

            $scope.recipients.name.push({
              name: `${firstName} ${lastName} [ ${email} ] `,
              email,
            });
            $scope.originalRecipients.name.push({
              name: `${firstName} ${lastName} [ ${email} ] `,
              email,
            });
          }
        });
    }

    function getClientInfo() {
      contactService.clientInformGet($scope.familyId).then(response => {
        // Client Header Name
        let clientHeaderName = '';
        let email = '';

        $scope.clients = response.data;
        $scope.recipients.email = [];
        $scope.recipients.name = [];
        $scope.originalRecipients.name = [];
        $scope.originalRecipients.email = [];
        $scope.ccs.name = [];
        $scope.ccs.email = [];
        $scope.recipientsObjectArray = [];
        angular.forEach($scope.clients, client => {
          email = '';
          const { Email: ClientEmail } = client;
          if (ClientEmail.length > 0) {
            if (ClientEmail[0].EmailAddress) {
              email = ClientEmail[0].EmailAddress;
              $scope.recipients.email.push(email);
              if (client.Role.toLowerCase() === 'adult')
                $scope.originalRecipients.email.push(email);
            }
          }

          const { Role, FirstName, LastName } = client;

          if (Role.toLowerCase() === 'adult') {
            // will only display header name for adults
            clientHeaderName = uiService.generateClientHeaderName(
              clientHeaderName,
              client,
              response.data
            );
            $scope.recipientsObjectArray.push({
              name: `${FirstName} ${LastName} [ ${email} ] `,
              email: `${email}`,
            });
            $scope.recipients.name.push({
              name: `${FirstName} ${LastName} [ ${email} ] `,
              email,
            });
            $scope.originalRecipients.name.push({
              name: `${FirstName} ${LastName} [ ${email} ] `,
              email,
            });
            $scope.originalRecipientsCount++;
          }
        });
        $scope.clientHeaderName = clientHeaderName;
      });
    }

    $timeout(() => {
      // Determine if Adviser or Client
      // Client ID is 0 if client
      if ($scope.paramClientId > 0) {
        getAdviserInfo();
      } else {
        getClientInfo();
      }
    }, 1000);
  });
