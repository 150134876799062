import angular from 'angular';
import { toastError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('MyAccountModalCtrl', function MyAccountModalCtrl(
    $scope,
    $uibModalInstance,
    contactService,
    $timeout,
    toaster
  ) {
    $scope.documentThumbnail = {};
    contactService
      .documentThumbnailGet({
        documentID: $scope.documentDataObject.DocumentID,
        startPage: 1,
        pageNumber: 1,
        thumbWidth: 300,
      })
      .then(respond => {
        const thumbObj = respond.data[0];
        if (thumbObj.ContentType && thumbObj.ThumbnailContent) {
          $scope.documentThumbnail = `data:${thumbObj.ContentType};base64,${
            thumbObj.ThumbnailContent
          }`;
        } else {
          $scope.documentThumbnail = false;
        }
      });
    $scope.documentObject = {
      Title: $scope.documentDataObject.Title,
      Tags: '',
      Description: '',
      DoucmentID: $scope.documentDataObject.DocumentID,
      FamilyID: $scope.documentDataObject.FamilyID,
    };
    $scope.uploadDocument = function() {
      contactService
        .documentPropertySet([$scope.documentObject])
        .then(respond => {
          if (respond.statusText === 'OK') {
            toaster.pop(
              'success',
              'Success',
              'Image has been been successfully uploaded.'
            );
          } else {
            toastError(respond);
          }
          $timeout(() => {
            $scope.cancel();
          }, 2000);
        });
    };
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
