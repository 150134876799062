export function brokerBuilderForUI(data) {
  return {
    brokerId: parseInt(data.BrokerId, 10),
    fullName: data.FullName,
    lastName: data.LastName,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    preferredName: data.PreferredName,
    BrokerFullName: data.FullName,
    BrokerID: parseInt(data.BrokerId, 10),
  };
}
