import template from './commissionSummaryWidget.html';
import controller from './commissionSummaryWidgetCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    summary: '<',
    title: '@',
    bgClass: '@',
    valueTextClass: '@',
  },
};
