import angular from 'angular';

angular
  .module('app')
  .directive('pipelineOverview', function pipelineOverview() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/pipelineOverview.html',

      link(scope) {
        scope.interval = 'week';
        function init() {}

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'commissionReceivedChartPopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          if (msg === 'Customise') scope.customizeMode = true;
          else scope.customizeMode = false;
        };

        init();
      },
    };
  });
