import angular from 'angular';
import mergeContact from './mergeContact';
import clientInsurance from './clientInsurance';
import insuranceAppWorkbench from './insuranceAppWorkbench';
import incomeCalculator from './incomeCalculator';
import insuranceSummaryOfCover from './insuranceSummaryOfCover';

export default angular.module('app.scenes', [
  mergeContact,
  clientInsurance,
  insuranceAppWorkbench,
  incomeCalculator,
  insuranceSummaryOfCover,
]).name;
