import template from './viewComfortDropdown.html';
import controller from './viewComfortDropdownCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    selectedView: '<',
    onUpdate: '&',
  },
};
