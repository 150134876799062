import './style.scss';
import template from './template.html';
import ConnectIncomeEmploymentCtrl from './connectIncomeEmploymentCtrl';

export default {
  template,
  bindings: {
    familyId: '<',
    loanAppId: '<',
    income: '<',
    onCancel: '&',
    callback: '&',
  },
  controller: ConnectIncomeEmploymentCtrl,
  controllerAs: 'vm',
};
