export const JASPER_FILTERS = {
  DATE_RANGE_OPTION: '1',
  RELATIVE_DATE: '2',
  MONTH: '3',
  ADVISER_SINGLE: '4',
  ADVISER_MULTIPLE: '5',
  REPORTING_STATE: '6',
  LENDER: '7',
  BUSINESS_UNIT: '8',
  PIPELINE_STATUS_FULL: '9',
  PIPELINE_STATUS_PARTIAL: '10',
  REFERRER_DETAILS: '11',
  INCLUDED_MARKETING: '12',
  COUNTRY_ID: '13',
  CUSTOM_DATE: '14',
  DATE_FILTER_ON: '15',
  LOAN_STATUS: '16',
  LENDER_ID: '17',
  LENDER_CATEGORY: '18',
  LENDER_SPLIT_BY: '19',
  SHOW_LOANS_DATA: '20',
  REFERRAL_TYPE: '21',
};
