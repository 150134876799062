import angular from 'angular';

angular.module('app').factory('CustomThemeModel', [
  'httpRequest',
  function(httpRequest) {
    var apiBaseUrl = 'User/';

    function CustomThemeModel() {}

    CustomThemeModel.prototype = {
      generalThemeLogoSet(data, callback) {
        httpRequest.post(`${apiBaseUrl}GeneralThemeLogoSet`, data, response => {
          return callback(response);
        });
      },

      generalThemeGet(callback) {
        httpRequest.get(`${apiBaseUrl}GeneralThemeGet`, response => {
          return callback(response);
        });
      },

      generalThemeSet(data, callback) {
        httpRequest.post(`${apiBaseUrl}GeneralThemeSet`, data, response => {
          return callback(response);
        });
      },

      widgetThemeGet(callback) {
        httpRequest.get(`${apiBaseUrl}WidgetThemeGet`, response => {
          return callback(response);
        });
      },

      widgetThemeSet(data, callback) {
        httpRequest.post(`${apiBaseUrl}WidgetThemeSet`, data, response => {
          return callback(response);
        });
      },

      buttonLinkThemeGet(callback) {
        httpRequest.get(`${apiBaseUrl}ButtonLinkThemeGet`, response => {
          return callback(response);
        });
      },

      buttonLinkThemeSet(data, callback) {
        httpRequest.post(`${apiBaseUrl}ButtonLinkThemeSet`, data, response => {
          return callback(response);
        });
      },

      formThemeGet(callback) {
        httpRequest.get(`${apiBaseUrl}FormThemeGet`, response => {
          return callback(response);
        });
      },

      formThemeSet(data, callback) {
        httpRequest.post(`${apiBaseUrl}FormThemeSet`, data, response => {
          return callback(response);
        });
      },

      customThemeReset(data, callback) {
        httpRequest.post(
          `${apiBaseUrl}CustomizedThemeReset`,
          data,
          response => {
            return callback(response);
          }
        );
      },

      customLogoSet(docId, callback) {
        httpRequest.post(`AccountSettings/Logo/${docId}`, {}, response => {
          return callback(response);
        });
      },
    };

    return CustomThemeModel;
  },
]);
