import angular from 'angular';
import JsPDF from 'jspdf';

export function downloadResource(url, toFileName) {
  if (!url) return;

  const anchorTag = angular.element('<a></a>')[0];
  anchorTag.download = toFileName;
  anchorTag.href = url;
  anchorTag.click();
}

export function downloadImgAsPDF(imgUrl, fileName, configuration = {}) {
  const { orientation, width, height } = configuration;
  const pageDimensions = width && height ? [width, height] : 'a4';
  const doc = new JsPDF(orientation || 'p', 'px', pageDimensions);
  doc.addImage(imgUrl, 'JPEG', 0, 0, width, height);
  doc.save(fileName);
}
