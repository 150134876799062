import {
  jasperReportBuilderForUI,
  dateRangeBuilderForUI,
  referrerOrgBuilderForUI,
  referrerBuilderForUI,
  pipelineStatusBuilderForUI,
  loanStatusBuilderForUI,
} from 'Common/mappers/jasperReport';

class JasperService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Jasper';
  }
  reportTypeSettings() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReportTypeSettings`)
      .then(
        response => response.data && response.data.map(jasperReportBuilderForUI)
      );
  }

  getDateRanges() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/date-ranges`)
      .then(
        response => response.data && response.data.map(dateRangeBuilderForUI)
      );
  }

  getReferrerOrganizations(params = {}) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReferrerOrganizations`, params)
      .then(
        response => response.data && response.data.map(referrerOrgBuilderForUI)
      );
  }

  getReferrers(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/Referrers`, params)
      .then(
        response => response.data && response.data.map(referrerBuilderForUI)
      );
  }

  getPipelineStatus() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/pipeline-statuses`)
      .then(
        response =>
          response.data && response.data.map(pipelineStatusBuilderForUI)
      );
  }
  getLoanStatus() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/loan-statuses`)
      .then(
        response => response.data && response.data.map(loanStatusBuilderForUI)
      );
  }
}

export default JasperService;
