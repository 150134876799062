import template from './printSummaryModal.html';
import PrintSummaryModalCtrl from './printSummaryModalCtrl';
import './style.scss';

export default {
  template,
  controller: PrintSummaryModalCtrl,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    contactDetails: '<',
  },
};
