class LenderSelectionCtrl {
  constructor(uiService) {
    'ngInject';

    this.uiService = uiService;
    this.filteredLenderList = [];
  }

  $onInit() {
    this.otherLenderName = this.selectedLender.ProviderName;
  }

  getCountryCode() {
    return this.uiService.getCountryCode();
  }

  buildCustomLender(lenderName) {
    this.validateOtherLender();
    return this.otherLender
      ? { ProviderId: this.otherLender.ProviderId, ProviderName: lenderName }
      : null;
  }

  setSelectedLender(lender) {
    const isValidLender = lender && lender.ProviderId;

    if (!isValidLender) return;

    this.selectedLender = lender;
    this.isDropdownOpen = false;

    if (this.isOtherLender(lender)) {
      if (this.onOtherLenderSelected) {
        this.onOtherLenderSelected({ lender });
      }
    } else if (this.onLenderSelected) {
      this.onLenderSelected({ lender });
    }
  }

  isSelectedLenderValid() {
    return (
      this.selectedLender &&
      this.selectedLender.ProviderName &&
      this.selectedLender.ProviderId
    );
  }

  isOtherLender(lender) {
    if (!lender) return false;

    this.validateOtherLender();
    if (!this.otherLender) return false;

    return lender.ProviderId === this.otherLender.ProviderId;
  }

  validateOtherLender() {
    if (!this.otherLender) {
      this.otherLender = this.lenderList
        ? this.lenderList.find(lender => lender.ProviderName === 'Other')
        : null;
    }
  }

  searchLender(filter) {
    if (filter && filter.trim().length && this.lenderList) {
      const validatedFilter = filter.trim().toLowerCase();
      this.filteredLenderList = this.lenderList.filter(lender => {
        const indexOfFilter = lender.ProviderName.toLowerCase().indexOf(
          validatedFilter
        );
        const isMatchExceptOther =
          indexOfFilter !== -1 && lender.ProviderName !== 'Other';
        return isMatchExceptOther;
      });
    } else {
      this.filteredLenderList = [];
    }
  }

  toggleOtherLenderField() {
    this.isOtherLenderSelected = !this.isOtherLenderSelected;
  }

  hideDropdown() {
    this.isDropdownOpen = false;
    this.lenderFilter = null;
    this.filteredLenderList = [];
  }
}

export default LenderSelectionCtrl;
