import moment from 'moment';
import { PICKER_RANGE } from 'Common/constants/pickerRange';

export default class CalendarRangePickerCtrl {
  constructor($filter) {
    'ngInject';

    this.$filter = $filter;
  }

  $onInit() {
    this.filterRange = PICKER_RANGE;

    this.selectedDates = [];

    this.dateOptions = {
      showWeeks: false,
      maxMode: 'day',
      customClass: data => {
        const currentDate = this.selectedDates.indexOf(
          data.date.setHours(0, 0, 0, 0)
        );
        if (currentDate === 1 || currentDate === 0) {
          this.activeRangeIndex = PICKER_RANGE.CUSTOM;
          if (
            (this.selectedDates.length > 1 && currentDate === 0) ||
            this.selectedDates.length > 1
          ) {
            this.selectRange(null, PICKER_RANGE.CUSTOM, true);
          }
          return 'full';
        } else if (currentDate > -1) {
          return 'partially';
        }
        return '';
      },
    };

    this.rangeActive = true;
    this.activeRangeIndex = null;
    this.selectedRange = {
      startDate: '',
      endDate: '',
    };

    this.ranges = this.customRanges || [
      {
        text: 'Today',
        range: [moment(), moment()],
      },
      {
        text: 'Last 3 days',
        range: [moment().subtract(3, 'days'), moment()],
      },
      {
        text: 'Last 7 days',
        range: [moment().subtract(7, 'days'), moment()],
      },
      {
        text: 'Last 30 days',
        range: [moment().subtract(30, 'days'), moment()],
      },
      {
        text: 'This month',
        range: [moment().startOf('month'), moment().endOf('month')],
      },
      {
        text: 'Custom',
        range: [moment(), moment()],
      },
    ];
  }

  selectRange(range, index, triggerCustom = false) {
    this.activeRangeIndex = index;
    this.onRangeSelected({ activeRangeIndex: this.activeRangeIndex });
    if (index === this.filterRange.CUSTOM && this.selectedDates.length) {
      this.selectedRange.startDate = this.$filter('orderBy')(
        this.selectedDates
      )[0];
      this.selectedRange.endDate = this.$filter('orderBy')(
        this.selectedDates,
        '-'
      )[0];
    } else {
      if (!range || !range.range || range.range.length < 2) return;
      this.selectedRange.startDate = range.range[0];
      this.selectedRange.endDate = range.range[1];
    }

    if (index !== this.filterRange.CUSTOM || triggerCustom) {
      this.pipelineSharedData.leadFilterData.DateCreatedStart = `${moment(
        this.selectedRange.startDate
      ).format('YYYY-MM-DDT00:00:00.SSS')}Z`;
      this.pipelineSharedData.leadFilterData.DateCreatedEnd = `${moment(
        this.selectedRange.endDate
      ).format('YYYY-MM-DDT00:00:00.SSS')}Z`;
      this.onUpdate({ filterData: this.pipelineSharedData.leadFilterData });
    }
  }

  clearDateFilter() {
    this.activeRangeIndex = null;
    this.pipelineSharedData.leadFilterData.DateCreatedStart = ``;
    this.pipelineSharedData.leadFilterData.DateCreatedEnd = ``;
    this.onRangeSelected({ activeRangeIndex: this.activeRangeIndex });
    this.onUpdate({ filterData: this.pipelineSharedData.leadFilterData });
  }
}
