import angular from 'angular';
import _ from 'lodash';
import { BIRTHDAYS_ID } from 'Common/constants/customerCareModules';
import { toastError } from 'Common/utilities/alert';
import moment from 'moment';

angular
  .module('app')
  .controller('BirthdaysSMSModalCtrl', function BirthdaysSMSModalCtrl(
    $scope,
    $uibModalInstance,
    $state,
    $controller,
    toaster,
    optionsService,
    contactService,
    customerCareService,
    birthdayBatchList,
    birthdayObj,
    source,
    customerCareTemplateService,
    loadBirthdaysTable,
    isRestricted
  ) {
    const vm = this;
    angular.extend(
      vm,
      $controller('CustomerCareSendMessageModalBaseCtrl', { $scope })
    );

    $scope.recipients = [];
    $scope.smsList = [];
    $scope.IsComplete = true;
    $scope.currentModule = BIRTHDAYS_ID;
    $scope.isRestricted = isRestricted;
    const getCountryList = () => {
      optionsService.countryListGet().then(
        response => {
          $scope.countryList = response.data;
          $scope.countryObj = $scope.countryList.filter(item => {
            if (birthdayObj.Country === item.CountryCode) {
              return item;
            }
            return false;
          });
          if ($scope.countryObj.length) {
            $scope.country = $scope.countryObj[0];
          } else {
            $scope.country = $scope.countryList.length
              ? $scope.countryList[1]
              : {};
          }
          $scope.selectedCountryCode = $scope.country.CountryCode || 'AU';
          $scope.makeObject($scope.selectedCountryCode);
        },
        () => {}
      );
    };

    $scope.makeObject = selectedCountryCode => {
      if (source === 'single') {
        if (birthdayObj.Mobile !== '') {
          if (typeof selectedCountryCode !== 'undefined') {
            $scope.countryObj = $scope.countryList.filter(item => {
              if (selectedCountryCode === item.CountryCode) {
                return item;
              }
              return false;
            });

            if (typeof $scope.countryObj !== 'undefined') {
              $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
            }
          }
          const birthDate = moment(birthdayObj.DateOfBirth).format('D MMM');
          const notiHeader = `Birthdays (${birthDate}) Completed via SMS`;
          $scope.smsList.sms = [
            {
              FamilyFullname: birthdayObj.FamilyFullName,
              LoanID: birthdayObj.LoanID,
              FamilyID: birthdayObj.FamilyID,
              MobileNumber: $scope.callingCode + birthdayObj.Mobile,
              NotificationHeader: notiHeader,
            },
          ];
          $scope.recipients.email = [
            {
              EmailAddress: birthdayObj.EmailAddress,
              FamilyFullname: birthdayObj.FamilyFullName,
              MobileNumber: birthdayObj.Mobile,
            },
          ];
        }
      }
      $scope.birthdayBatchList = birthdayBatchList;

      if (source === 'selected') {
        $scope.all = true;
        if (typeof selectedCountryCode !== 'undefined') {
          $scope.countryObj = $scope.countryList.filter(item => {
            if (selectedCountryCode === item.CountryCode) {
              return item;
            }
            return false;
          });

          if (typeof $scope.countryObj !== 'undefined') {
            $scope.callingCode = `+${$scope.countryObj[0].CallingCode}`;
          }
        }
        if ($scope.birthdayBatchList.length > 0) {
          $scope.selectSMSList = [];
          $scope.emailRecipients = [];

          for (let i = 0; i < $scope.birthdayBatchList.length; i++) {
            $scope.smsAdd = {};
            $scope.emailAdd = {};
            const smsDisabled =
              $scope.isRestricted && !$scope.birthdayBatchList[i].SMSEnabled;
            if (!smsDisabled && $scope.birthdayBatchList[i].Mobile) {
              $scope.smsAdd.FamilyFullname =
                $scope.birthdayBatchList[i].FamilyFullName;
              $scope.smsAdd.LoanID = $scope.birthdayBatchList[i].LoanID;

              $scope.smsAdd.FamilyID = $scope.birthdayBatchList[i].FamilyID;
              $scope.smsAdd.MobileNumber =
                $scope.callingCode + $scope.birthdayBatchList[i].Mobile;

              $scope.selectSMSList.push($scope.smsAdd);

              $scope.emailAdd.EmailAddress =
                $scope.birthdayBatchList[i].EmailAddress;
              $scope.emailAdd.FamilyFullname =
                $scope.birthdayBatchList[i].FamilyFullName;
              $scope.emailAdd.MobileNumber =
                $scope.callingCode + $scope.birthdayBatchList[i].Mobile;
              $scope.emailRecipients.push($scope.emailAdd);
            }
          }
          if ($scope.selectSMSList.length > 0) {
            $scope.smsList.sms = $scope.selectSMSList;
            $scope.noOfPeople = $scope.selectSMSList.length;
          }
          if ($scope.emailRecipients.length > 0) {
            $scope.recipients.email = $scope.emailRecipients;
          }
        }
      }
    };
    $scope.showAllPeopleSendingMessage = () => {
      if (!$scope.isRestricted) return true;
      return $scope.noOfPeople === $scope.birthdayBatchList.length;
    };
    $scope.countryChanged = selectedCountryCode => {
      $scope.makeObject(selectedCountryCode);
    };

    $scope.selectEmailList = [];
    $scope.selectedTemplate = [
      {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
      },
    ];

    $scope.refreshResults = select => {
      const search = select.search;

      $scope.getRecipientAutoSuggestion(search);
    };

    $scope.sendSMS = form => {
      let firstError = null;
      if (form.$invalid) {
        _.each(form, field => {
          if (field && field[0] !== '$') {
            if (firstError === null && !field.$valid) {
              firstError = field.$name;
            }

            if (field.$pristine) {
              field.$dirty = true;
            }
          }
        });
        angular.element(`.ng-invalid[name=${firstError}]`).focus();
        return;
      }

      $scope.recipientsSMSArray = [];
      $scope.smsToSend = {};
      $scope.smsToSend.IsComplete = $scope.IsComplete;

      $scope.smsToSend.Message = $scope.sms.Message;
      $scope.smsToSend.CustomerCareType = 'BIRTH_DAY';
      angular.forEach($scope.smsList.sms, item => {
        $scope.recipientsSMSArray.push(item);
      });
      $scope.smsToSend.SmsModelList = $scope.recipientsSMSArray;

      customerCareService
        .CustomerCareSMSSend($scope.smsToSend)
        .then(birthdayRes => {
          if (birthdayRes.data && birthdayRes.data.length) {
            const birthdaySMSValid = customerCareTemplateService.customerCareResponseMsg(
              birthdayRes.data,
              'SMS'
            );
            if (birthdaySMSValid) {
              loadBirthdaysTable();
              $scope.smsToSend.Message = '';
              form.$setPristine(true);
            }
            $scope.cancel();
          }
        })
        .catch(toastError);
    };
    const init = () => {
      getCountryList();

      $scope.phone = birthdayObj.MobileNo;
      $scope.content =
        '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';
      $scope.familyId = birthdayObj.FamilyID;
    };

    init();
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
