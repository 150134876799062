class loanProfilerDetailService {
  liabilityIsLimit(liabilityTypeID) {
    this.liabilityTypeId = parseInt(liabilityTypeID, 10);
    return (
      this.liabilityTypeId === 2 ||
      this.liabilityTypeId === 7 ||
      this.liabilityTypeId === 20
    );
  }
  liabilityIsNextProp(liabilityTypeID) {
    this.liabilityTypeId = parseInt(liabilityTypeID, 10);
    return (
      this.liabilityTypeId === 10 ||
      this.liabilityTypeId === 11 ||
      this.liabilityTypeId === 15 ||
      this.liabilityTypeId === 18 ||
      this.liabilityTypeId === 19 ||
      this.liabilityTypeId === 21
    );
  }

  $onInit() {
    this.isTooltipShown = false;
  }
  hideTooltip() {
    this.isTooltipShown = false;
  }

  showTooltip() {
    this.isTooltipShown = true;
  }
}

export default loanProfilerDetailService;
