/**
 * A set of directives for left and right sidebar.
 */
import angular from 'angular';
import $ from 'jquery';
/* eslint-disable angular/directive-restrict */
angular
  .module('app')
  .directive('sidebar', function sidebar($document, $rootScope, $timeout) {
    return {
      replace: false,
      restrict: 'C',
      link(scope, elem, attrs) {
        let shouldCloseOnOuterClicks = true;

        if (
          attrs.closeOnOuterClicks === 'false' ||
          attrs.closeOnOuterClicks === '0'
        ) {
          shouldCloseOnOuterClicks = false;
        }

        const isAncestorOrSelf = function(element, target) {
          let parent = element;

          while (parent.length > 0) {
            if (parent[0] === target[0]) {
              parent = null;
              return true;
            }
            parent = parent.parent();
          }

          parent = null;
          return false;
        };

        const closeOnOuterClicks = function(e) {
          if (!isAncestorOrSelf(angular.element(e.target), elem)) {
            $rootScope.toggle(attrs.id, 'off');
            e.preventDefault();
            return false;
          }
        };

        let clearCb1 = angular.noop();

        if (shouldCloseOnOuterClicks) {
          clearCb1 = $rootScope.$on('clip-two.toggled', (e, id, active) => {
            if (id === attrs.id) {
              if (active) {
                $timeout(() => {
                  $document.on('click tap', closeOnOuterClicks);
                }, 300);
              } else {
                $document.off('click tap', closeOnOuterClicks);
              }
            }
          });
        }

        scope.$on('$destroy', () => {
          clearCb1();
          $document.off('click tap', closeOnOuterClicks);
        });
      },
    };
  })
  .directive('searchForm', function searchFormDirective($document) {
    return {
      restrict: 'AC',
      link(scope, elem) {
        const wrap = $('.app-aside');
        const searchForm = elem.children('form');

        const closeForm = function(e) {
          if (
            !searchForm.is(e.target) &&
            searchForm.has(e.target).length === 0
          ) {
            $('.s-remove').trigger('click');
            $document.off('mousedown touchstart', closeForm);
          }
        };

        $('.s-open').on('click', e => {
          searchForm.prependTo(wrap);
          e.preventDefault();
          $document.on('mousedown touchstart', closeForm);
        });
        $('.s-remove').on('click', e => {
          searchForm.appendTo(elem);
          e.preventDefault();
        });
      },
    };
  })
  .directive('appAside', function appAsideDirective(
    $window,
    $rootScope,
    $timeout,
    $document,
    APP_MEDIAQUERY
  ) {
    const $html = $('html');
    const $win = $($window);
    let _this;

    function isTouch() {
      return $html.hasClass('touch');
    }

    function isMobile() {
      return $win.width() < APP_MEDIAQUERY.desktop;
    }

    function isSidebarClosed() {
      return $('.app-sidebar-closed').length;
    }

    function isSidebarFixed() {
      return $('.app-sidebar-fixed').length;
    }

    function isBoxedPage() {
      return $('.app-boxed-page').length;
    }

    return {
      restrict: 'AC',

      link(scope, elem) {
        const eventObject = isTouch() ? 'click' : 'mouseenter';
        const wrap = $('.app-aside');
        let ul = '';
        let menuTitle;
        let space = 0;

        function wrapLeave() {
          wrap.trigger('mouseleave');
        }

        elem.on('click', 'li > a', function(e) {
          _this = $(this);
          if (
            isSidebarClosed() &&
            !isMobile() &&
            !_this.closest('ul').hasClass('sub-menu')
          )
            return;

          _this
            .closest('ul')
            .find('.open')
            .not('.active')
            .children('ul')
            .not(_this.next())
            .slideUp(200)
            .parent('.open')
            .removeClass('open');
          if (_this.next().is('ul') && _this.parent().toggleClass('open')) {
            _this.next().slideToggle(200, () => {
              $win.trigger('resize');
            });
            e.stopPropagation();
            e.preventDefault();
          } else {
            $rootScope.toggle('sidebar', 'off');
          }
        });
        elem.on(eventObject, 'nav a', function() {
          if (!isSidebarClosed() || isMobile()) return;
          _this = $(this);

          if (
            !_this.parent().hasClass('hover') &&
            !_this.closest('ul').hasClass('sub-menu')
          ) {
            wrapLeave();
            _this.parent().addClass('hover');
            menuTitle = _this.find('.item-inner').clone();
            if (_this.parent().hasClass('active')) {
              menuTitle.addClass('active');
            }

            if ($('#app').hasClass('lyt-3')) {
              space =
                $('.crm-sidebar:first')
                  .find('.sidebar-container')
                  .position().top -
                $('header').outerHeight() +
                _this.position().top;
            }

            const offset =
              $('.crm-sidebar:first')
                .find('.sidebar-container > div')
                .position().top +
              $('.nav-user-wrapper').outerHeight() +
              $('header').outerHeight();
            let itemTop =
              isSidebarFixed() && !isBoxedPage()
                ? _this.parent().position().top + offset + space
                : _this.parent().offset().top - $('header').outerHeight();
            itemTop -= 3;
            menuTitle
              .css({
                position:
                  isSidebarFixed() && !isBoxedPage() ? 'fixed' : 'absolute',
                height: _this.parent().outerHeight(),
                top: itemTop,
                borderBottomRightRadius: '10px',
                lineHeight: `${_this.parent().outerHeight()}px`,
                padding: 0,
              })
              .appendTo(wrap);
            if (_this.next().is('ul')) {
              ul = _this.next().clone(true);
              menuTitle.css({
                borderBottomRightRadius: 0,
              });
              ul.appendTo(wrap).css({
                top: itemTop + _this.parent().outerHeight(),
                position:
                  isSidebarFixed() && !isBoxedPage() ? 'fixed' : 'absolute',
              });
              if (
                _this.parent().position().top +
                  _this.outerHeight() +
                  offset +
                  ul.height() >
                  $win.height() &&
                isSidebarFixed() &&
                !isBoxedPage()
              ) {
                ul.css('bottom', 0);
              } else {
                ul.css('bottom', 'auto');
              }

              wrap.find('.sidebar-container').scroll(() => {
                if (isSidebarFixed() && !isBoxedPage()) wrapLeave();
              });

              $timeout(() => {
                if (!wrap.is(':empty')) {
                  $document.on('click tap', wrapLeave);
                }
              }, 300);
            } else {
              ul = '';
            }
          }
        });

        wrap.on('mouseleave', () => {
          $document.off('click tap', wrapLeave);
          $('.hover', wrap).removeClass('hover');
          $('> .item-inner', wrap).remove();
          $('> ul', wrap).remove();
        });

        $rootScope.$on('$locationChangeSuccess', () => {
          const newPath = $window.location.hash;
          angular.forEach(elem.find('.main-navigation-menu a'), domLink => {
            const link = angular.element(domLink);
            let menu;
            if (
              domLink.hash === newPath &&
              (!isSidebarClosed() || isMobile())
            ) {
              if (link.closest('ul').hasClass('sub-menu')) {
                menu = link.closest('ul');
                menu
                  .slideDown(200)
                  .parent()
                  .siblings()
                  .children('.sub-menu')
                  .slideUp(200, function() {
                    $(this)
                      .parent()
                      .removeClass('open');
                  });
              } else {
                $('.sub-menu').slideUp(200, function() {
                  $(this)
                    .parent()
                    .removeClass('open');
                });
              }
            }
            menu = null;
          });
        });
      },
    };
  })
  .directive('sidebarToggler', function sidebarTogglerDirective(
    $window,
    $timeout
  ) {
    return {
      restrict: 'C',

      link(scope, elem) {
        elem.on('click', () => {
          $('.main-content').on(
            'webkitTransitionEnd mozTransitionEnd oTransitionEnd otransitionend transitionend',
            () => {
              $timeout(() => {
                const evt = $window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, $window, 0);
                $window.dispatchEvent(evt);
              }, 500);
              $('.main-content').off(
                'webkitTransitionEnd mozTransitionEnd oTransitionEnd otransitionend transitionend'
              );
            }
          );
        });
      },
    };
  })
  .directive('ctSticky', function ctStickyDirective($window, $timeout) {
    return {
      restrict: 'A',
      scope: {
        ctStickyDisabled: '&',
      },
      link($scope, $element, $attributes) {
        $timeout(() => {
          const actualPadding = 90;
          const maxPadding = 60;
          let isSticky;
          const setPadding = function() {
            if ($window.scrollY < maxPadding) {
              $element.css({
                paddingTop: actualPadding - $window.scrollY,
              });
            } else {
              $element.css({
                paddingTop: 30,
              });
            }
          };
          if ($attributes.ctStickyDisabled) {
            $scope.$watch($scope.ctStickyDisabled, (newVal, oldVal) => {
              if (newVal && !oldVal) {
                isSticky = false;
                $element.attr('style', (i, style) => {
                  return style.replace(/padding[^;]+;?/g, '');
                });
              } else if (!newVal) {
                isSticky = true;
                setPadding();
              }
            });
          }
          angular.element($window).on('scroll', () => {
            if (isSticky) {
              setPadding();
            }
          });
        });
      },
    };
  });
