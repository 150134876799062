import template from './companyOwners.html';
import controller from './companyOwnersCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    entityData: '<',
  },
};
