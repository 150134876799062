import angular from 'angular';

angular
  .module('app')
  .controller('InsurerAppStatementCtrl', function InsurerAppStatementCtrl(
    $rootScope,
    $scope,
    insuranceAppService,
    toaster
  ) {
    $scope.insurerAppStatementQuestionList = {};
    const getQuestions = () => {
      // 5 is insurance app ID, 5 is section id which detemine on what tab the question belongs
      insuranceAppService.applicationQuestionsGet(5, 5).then(response => {
        $scope.insurerAppStatementQuestionList = response.data;
      });
    };
    getQuestions();

    const applicationAnswerSet = (
      applicationId,
      questionId,
      answerString,
      answerBit,
      answerInt
    ) => {
      insuranceAppService
        .applicationAnswerSet(
          applicationId,
          questionId,
          answerString,
          answerBit,
          answerInt
        )
        .then(
          () => {
            toaster.pop(
              'success',
              'Notes updated',
              'Applicants section notes updated.'
            );
          },
          () => {
            toaster.pop(
              'warning',
              'Unable to Add',
              'Parties were not added, please try again'
            );
          }
        );
    };

    // answer arrangement "applicationId, questionId, answerString, answerBit, answerInt"
    $scope.saveApplicationAnswerSet = function(
      applicationId,
      questionId,
      answerString,
      answerBit,
      answerInt
    ) {
      applicationAnswerSet(
        applicationId,
        questionId,
        answerString,
        answerBit,
        answerInt
      );
    };
  });
