class BrokerSupportService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'brokersupport';
  }

  getBrokerSupportRequestList(
    count,
    page,
    sortBy,
    sortOrder,
    status,
    period,
    crmFamilyId = 0,
    showAll = 1
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/BrokerSupportRequestListGet`,
      { count, page, sortBy, sortOrder, status, period, crmFamilyId, showAll }
    );
  }

  submissionTypesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/SubmissionTypesGet`);
  }

  loanPurposeValuesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/LoanPurposeValuesGet`);
  }

  getZendeskTicketComments(ticketId) {
    return this.httpClient.get(`${this.apiBaseUrl}/ZendeskTicketCommentsGet`, {
      ticketId,
    });
  }

  whereToSendLoanOfferDocsGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/WhereToSendLoanOfferDocsGet`
    );
  }

  noOfLoanSplitsGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/NoOfLoanSplitsGet`);
  }

  brokerSupportServiceModelGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/BrokerSupportServiceModelGet`
    );
  }

  brokerSupportRequestSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/BrokerSupportRequestSet`,
      data
    );
  }

  brokerSupportRequestGet(ticketId) {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerSupportRequestGet`, {
      ticketId,
    });
  }

  getSurveyList(ticketId) {
    return this.httpClient.get(`${this.apiBaseUrl}/BrokerSupportRequestGet`, {
      ticketId,
    });
  }

  setZendeskTicketComment(ticketId, comment) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ZendeskTicketCommentSet`,
      {},
      { ticketId, comment }
    );
  }

  getReferrersWithActiveAgreement(adviserFamilyID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ReferrersWithActiveAgreementGet`,
      { adviserFamilyID }
    );
  }

  getBrokerSupportServiceModel() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/BrokerSupportServiceModelGet`
    );
  }

  getZendeskOrgUsers() {
    return this.httpClient.get(`${this.apiBaseUrl}/ZendeskOrgUsersGet`);
  }

  advisersWithTicketGet(status, period, crmFamilyId, showAll) {
    return this.httpClient.get(`${this.apiBaseUrl}/AdvisersWithTicketGet`, {
      status,
      period,
      crmFamilyId,
      showAll,
    });
  }

  timePeriods() {
    return this.httpClient.get(`${this.apiBaseUrl}/TimePeriods`);
  }
}

export default BrokerSupportService;
