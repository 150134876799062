import angular from 'angular';
import _ from 'lodash';
import { REFERRAL_TYPE } from 'Common/constants/referralType';

angular
  .module('app')
  .controller('SendReferralsCtrl', function SendReferralsCtrl(
    $scope,
    $http,
    $uibModal,
    sendReferralService,
    utilitiesService,
    dashboardService,
    toaster,
    contactService,
    $timeout,
    corporateService,
    wmComplianceService,
    userService,
    NgTableParams,
    $filter,
    $state,
    $localStorage
  ) {
    // MODELS

    angular.extend($scope, {
      userInfo: {},
      agencyList: [],
      familyAdvList: [],
      adviceTypeList: [],
      filteredList: [],
      sentToList: [],
      sentReferralsList: [],
      _filterShow: false,
      _isClickedSendBtn: false,
      queryFilter: {
        referralTypeId: '0',
        sendTo: '0',
      },
      statusList: [],
      pagination: { _page: 0 },
      countPage: {},
      searchingContacts: false,
    });

    const filterResults = (method, filterObj) => {
      $scope.queryFilter.pageSize = 10000000;

      switch (method) {
        case 'status':
          $scope.queryFilter.pageNumber = 1;
          $scope.queryFilter.sendTo = '0';
          $scope.countPage.status = filterObj.status;
          break;

        case 'type':
          $scope.queryFilter.pageNumber = 1;
          $scope.queryFilter.sendTo = '0';
          $scope.queryFilter.referralTypeId = filterObj.referralTypeId;
          break;

        case 'adviser':
          $scope.queryFilter.pageNumber = 1;
          $scope.queryFilter.sendTo = filterObj.sendTo;
          $scope.queryFilter.adviserName = filterObj.adviserName;
          break;

        default:
          $scope.queryFilter.status = '';
          $scope.queryFilter.sendTo = '0';
          $scope.queryFilter.referralTypeId = '0';
          $scope.queryFilter.adviserName = '';
          $scope.queryFilter.pageNumber = 1;
          break;
      }

      const sentReferralModelFormatter = data => {
        const formattedData = [];
        for (let i = 0; i < data.length; i++) {
          const toPush = {};
          toPush.Contact = data[i].Contact;
          toPush.DateReferred = data[i].DateReferred;
          toPush.ReferralID = data[i].ReferralID;
          toPush.FamilyID = data[i].FamilyID;
          toPush.ClientID = data[i].ClientID;
          toPush.ReferralType = data[i].ReferralType;
          toPush.StatusName = data[i].StatusName;
          toPush.AdviserName = data[i].SendToFullName || data[i].AdviserName;
          toPush.WithNote = data[i].WithNote;
          toPush.ContactIntials = utilitiesService.filterInitialOneString(
            toPush.Contact
          );

          switch (toPush.ReferralType) {
            case 'Ray White':
              toPush.ReferralTypeID = 1;
              toPush.logo = 'raywhite';
              break;
            case 'UK Pension':
              toPush.ReferralTypeID = 2;
              toPush.logo = 'ukpension';
              break;
            case 'F & G':
              toPush.ReferralTypeID = 3;
              toPush.logo = 'tower';
              break;
            case 'OFX':
              toPush.ReferralTypeID = 4;
              toPush.logo = 'ofx';
              break;
            case 'Wealth Market':
              toPush.ReferralTypeID = 5;
              toPush.logo = 'wealthmarket';
              break;
            case 'Other':
              toPush.ReferralTypeID = 6;
              toPush.logo = 'others';
              break;
            case 'Loan Market':
              toPush.ReferralTypeID = 7;
              toPush.logo = 'loanmarket';
              break;
            case 'XE':
              toPush.ReferralTypeID = 8;
              toPush.logo = 'xe';
              break;
            case 'Asset Finance':
              toPush.ReferralTypeID = REFERRAL_TYPE.ASSETS_FINANCE;
              toPush.logo = 'assetfinance';
              break;
            default:
              break;
          }
          formattedData.push(toPush);
        }

        return formattedData;
      };

      sendReferralService
        .referralReferralListGet($scope.queryFilter)
        .then(response => {
          const data = response.data;

          if (_.size(data) !== 0) {
            $scope.sentReferralsList = sentReferralModelFormatter(
              response.data
            );

            $scope.filteredList = $scope.sentReferralsList;

            $scope.tableParams = new NgTableParams(
              {
                page: 1,
                count: 10, // count per page
                sorting: {
                  Default: 'desc', // initial sorting
                },
              },
              {
                counts: [],
                total: data.length, // length of data
                getData($defer, params) {
                  let filterData = angular.copy($scope.filteredList);

                  filterData = params.sorting()
                    ? $filter('orderBy')(filterData, params.orderBy())
                    : filterData;
                  filterData = filterData.slice(
                    (params.page() - 1) * params.count(),
                    params.page() * params.count()
                  );
                  $defer.resolve(filterData);
                },
              }
            );
          } else {
            toaster.pop('info', 'No record found');
          }
        });

      sendReferralService
        .referralSendToListGet($scope.queryFilter.referralTypeId)
        .then(response => {
          if (response.data.length > 0) {
            $scope.sentToList = response.data;
          } else {
            $scope.sentToList = [];
          }
        });
    };

    const initSendReferrals = () => {
      // get list of referral types
      sendReferralService.referralReferralTypeGet().then(response => {
        const agencies = angular.copy(response.data);

        if (agencies && agencies.length > 0) {
          userService.GetUserInfo().then(user => {
            $scope.userInfo = user.data;

            // filter for AU users
            if (parseInt($scope.userInfo.CountryId, 10) === 2) {
              $scope.agencyList = _.filter(agencies, function agency(data) {
                return (
                  parseInt(data.ReferralTypeID, 10) !== 2 &&
                  parseInt(data.ReferralTypeID, 10) !== 3 &&
                  parseInt(data.ReferralTypeID, 10) !== 4
                );
              });
            } else $scope.agencyList = agencies;
          });
        }
      });

      // get list of status names
      sendReferralService.getStatusList().then(response => {
        $scope.statusList = response.data;
      });

      // initial filtering
      filterResults('', {});
    };

    // run initializer
    initSendReferrals();

    $scope.setFilters = (method, filterObj) => {
      filterResults(method, filterObj);
    };

    $scope.contactInfo = id => {
      if (!_.isUndefined(id) && id !== 0) {
        $state.go('app.contactsSingle', { familyId: id, activeTab: 'summary' });
      } else
        toaster.pop('info', 'Notice', 'You have selected a non MyCRM client');
    };

    $scope.viewNotes = data => {
      if (!data.WithNote) {
        toaster.pop('info', 'Notice', 'No Notes to view.');
      } else if (data.FamilyID) {
        $state.go('app.contactsLoans', {
          familyId: data.FamilyID,
          activeTab: 'notes',
        });
      } else
        toaster.pop('info', 'Notice', 'You have selected a non MyCRM client');
    };

    $scope.referralDetails = referral => {
      $uibModal.open({
        templateUrl: '/assets/views/referrals/modal/referral.details.html',
        backdrop: false,
        keyboard: false,
        controller: 'ReferralDetailsModalCtrl',
        resolve: {
          referral: () => {
            return referral;
          },
        },
      });
    };

    $scope.setReferral = refType => {
      $scope.newReferral(refType, $scope.agencyList);
    };

    $scope.newReferral = (refType, agencyList) => {
      $scope.refType = refType;
      $scope.agencyList = agencyList;
      const modalInstance = $uibModal.open({
        templateUrl: '/assets/views/referrals/modal/referral.main.html',
        backdrop: false,
        keyboard: false,
        windowClass: 'newReferral-modal',
        controller: 'SendReferralModalCtrl',
        scope: $scope,
      });

      modalInstance.result.then(() => {
        $scope._isClickedSendBtn = false;
        $timeout(() => {
          filterResults('', $scope.queryFilter);
        }, 2000);
      });
    };

    if ($localStorage.xeAutoSend && $localStorage.xeAutoSend.newReferral) {
      $timeout(() => {
        $scope.xeAutoSend = $localStorage.xeAutoSend;
        $scope.setReferral($scope.xeAutoSend.referralType);

        $localStorage.xeAutoSend = {};
      }, 2000);
    }
  });
