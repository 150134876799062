import angular from 'angular';
import {
  mapCustomConfig,
  circleDefaultOptions,
  getGoogleMapInstance,
} from 'Common/utilities/gMap';

class GoogleMapShapesCtrl {
  constructor(googleMapService, $interval, corporateService, $q) {
    'ngInject';

    this.googleMapService = googleMapService;
    this.$interval = $interval;
    this.$q = $q;
    this.corporateService = corporateService;
  }

  addCircleRadius(lat, lng) {
    const googleMapWindow = getGoogleMapInstance();
    if (!googleMapWindow) return;
    const circleFitListener = googleMapWindow.event.addListener(
      this.map,
      'bounds_changed',
      () => {
        this.map.fitBounds(this.circle.getBounds());
      }
    );
    googleMapWindow.event.addListener(this.map, 'tilesloaded', () => {
      googleMapWindow.event.removeListener(circleFitListener);
    });
    const circleDefaultOpt = circleDefaultOptions();
    const options = {
      map: this.map,
      center: new googleMapWindow.LatLng(lat, lng),
      radius: this.distanceValue * 1000,
    };
    const circleOptions = { ...circleDefaultOpt, ...options };
    this.circle = new googleMapWindow.Circle(circleOptions);
  }

  circleRadiusReInitialize() {
    this.circle.setRadius(this.distanceValue * 1000);
    this.map.fitBounds(this.circle.getBounds());
  }

  getSelectedValue(value) {
    this.distanceValue = parseInt(value, 10);
    this.circleRadiusReInitialize();
    this.leadDetailsObj.Distance = parseInt(value, 10);
    this.corporateService.leadsProfileSet(
      this.mainFamilyId,
      0,
      this.leadDetailsObj
    );
  }

  geocodeAddress() {
    const defer = this.$q.defer();
    const address = this.addressModelValue;
    const addressNotGeocoded = address.formatted_address;
    const googleMapWindow = getGoogleMapInstance();
    this.geocoder = new googleMapWindow.Geocoder();
    this.geocoder.geocode(
      { address: addressNotGeocoded },
      (results, status) => {
        if (status !== 'OK' || !results) return defer.resolve(address);
        const geoCodedAddress = results.find(value => value);
        if (!geoCodedAddress) return defer.resolve(address);
        const { geometry } = geoCodedAddress;
        if (!geometry) return defer.resolve(address);
        const { location } = geometry;

        const addressGeocoded = {
          ...address,
          formatted_address: geoCodedAddress.formatted_address,
          latitude: location.lat(),
          longitude: location.lng(),
        };
        return defer.resolve(addressGeocoded);
      }
    );

    return defer.promise;
  }

  mapSetUp(mapId) {
    const mapConfig = mapCustomConfig();
    const customConfig = {
      map: {
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
      },
    };
    const map = { ...mapConfig.map, ...customConfig.map };
    const overrideMapCustomConfig = { ...mapConfig, map, hasInfoWindow: false };
    const mapRequiredConfig = {
      mapId,
      latitude: this.addressModelValue.latitude,
      longitude: this.addressModelValue.longitude,
      address: this.addressModelValue.formatted_address,
    };
    this.map = this.googleMapService.mapCorporate(
      mapRequiredConfig,
      overrideMapCustomConfig
    );
    if (!this.map) return;
    this.addCircleRadius(
      this.addressModelValue.latitude,
      this.addressModelValue.longitude
    );
  }

  loadMap(mapId) {
    const { geoCoded, isCustomAddress } = this.addressModelValue;
    if (!geoCoded && isCustomAddress) {
      this.geocodeAddress().then(response => {
        this.addressModelValue = response;
        this.mapSetUp(mapId);
      });
    }
    this.mapSetUp(mapId);
  }

  checkMapEle() {
    const gmapEle = angular.element(`.gmap-shapes-component #${this.mapId}`);
    if (!gmapEle.length) return;
    this.loadMap(this.mapId);
    if (this.mapEleInterval) this.$interval.cancel(this.mapEleInterval);
  }

  $onChanges(changes) {
    if (!changes) return;
    if (changes.addressModel && changes.addressModel.currentValue) {
      this.addressModelValue = changes.addressModel.currentValue;
    }
    if (changes.leadDetails && changes.leadDetails.currentValue) {
      this.leadDetailsObj = changes.leadDetails.currentValue;
      this.distanceValue = parseInt(
        changes.leadDetails.currentValue.Distance,
        10
      );
    }
    this.checkMapEle();
  }

  $onInit() {
    this.options = {
      from: this.minDistance,
      to: this.maxDistance,
      step: 1,
      className: 'clip-slider',
      css: {
        background: {
          'background-color': 'silver',
        },
        range: {
          'background-color': '#579BCB',
        },
      },
    };
    this.mapEleInterval = this.$interval(this.checkMapEle.bind(this), 1000);
  }

  $onDestroy() {
    this.$interval.cancel(this.mapEleInterval);
  }
}

export default GoogleMapShapesCtrl;
