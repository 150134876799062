import template from './emailManagementModal.html';
import EmailManagementModalCtrl from './emailManagementModalCtrl';

export default {
  template,
  controller: EmailManagementModalCtrl,
  bindings: {
    modalInstance: '<',
  },
};
