import { getOpportunityStatus } from 'Common/utilities/loanOpportunity';
import { pipelineStatusBuilderForUI } from 'Common/mappers/pipeline';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';
import { opportunityConvert } from 'Common/utilities/opportunityConversion';
import moment from 'moment';
import toastr from 'toastr';

export default class StatusDropdownCtrl {
  constructor(
    $state,
    pipelineService,
    pipelineSharedData,
    modalRenderService,
    loanOpportunityService
  ) {
    'ngInject';

    this.$state = $state;
    this.pipelineService = pipelineService;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.loanOpportunityService = loanOpportunityService;
  }
  $onInit() {
    this.PIPELINE_STATUS = PIPELINE_STATUS;
    getOpportunityStatus(this.pipelineService).then(statusList => {
      this.statusList = statusList.map(pipelineStatusBuilderForUI);
    });

    this.loanOpportunityService.getReasonSubstatus().then(res => {
      if (!res.data) return;
      const { data } = res;
      this.statusReasonList = data;
    });
  }

  setStatus(status) {
    if (!status.isConversionStatus) {
      this.updatedStatusDate = moment(new Date()).format('YYYY MM DD hh:mm:ss');
      this.opportunityStatus = status.pipelineStatus;
      this.loanOpportunityService
        .setLoanOpportunityStatus(this.loanId, status.pipelineStatusId)
        .then(() => {
          toastr.success('Card successfully moved!', 'Success');
        });
    } else {
      opportunityConvert(
        this.pipelineSharedData,
        this.modalRenderService,
        this.loanOpportunityService,
        status.pipelineStatusId,
        this.opportunityId,
        this.statusReasonList
      ).then(res => {
        if (res && res.isOk) {
          toastr.success('Card successfully converted!', 'Success');
          this.onRemoveRow({ rowId: this.rowId });
        }
      });
    }
  }
}
