import './style.scss';
import template from './customInputPercentage.html';
import CustomInputPercentageCtrl from './customInputPercentageCtrl';

export default {
  template,
  bindings: {
    inputModel: '=',
    inputClass: '@',
    inputStyle: '@',
    maxAmount: '@',
    onChange: '&',
  },
  controller: CustomInputPercentageCtrl,
  controllerAs: 'vm',
};
