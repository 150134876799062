class DocumentService {
  constructor(contactService, $q) {
    'ngInject';

    this.contactService = contactService;
    this.$q = $q;
  }

  getDocumentThumbnail(documentObj) {
    const deferred = this.$q.defer();
    if (documentObj && documentObj.DocumentId) {
      this.contactService
        .documentThumbnailGet({
          documentID: documentObj.DocumentId,
          startPage: 1,
          pageNumber: 1,
          thumbWidth: 300,
        })
        .then(respond => {
          const thumbObj = respond && respond.data && respond.data[0];
          const isValidThumbnail =
            thumbObj && thumbObj.ContentType && thumbObj.ThumbnailContent;
          const thumbnail = isValidThumbnail
            ? `data:${thumbObj.ContentType};base64,${thumbObj.ThumbnailContent}`
            : false;
          deferred.resolve({ thumbnail });
        });
    } else {
      deferred.reject({ error: 'Invalid document' });
    }
    return deferred.promise;
  }
}

export default DocumentService;
