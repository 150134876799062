import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { setBooleanToInt } from 'Common/utilities/typeConversion';

angular
  .module('app')
  .controller('AsicAppoinmentCtrl', function AsicAppoinmentCtrl(
    $scope,
    corporateService,
    onboardingService,
    dataService,
    onboardingDataService,
    toaster
  ) {
    $scope.isCountryNewZealand = false;
    $scope.entityTypeIsCompany = false;
    $scope.keepACL = false;
    $scope.asicDetails = {
      KeepACL: 0,
    };

    const getAsicDetails = () => {
      onboardingService
        .onboardingAdviserOrganisationASICDetailsGet(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(asicDataResponse => {
          if (!asicDataResponse || !asicDataResponse.data) return;

          asicDataResponse.data.HasASICAppoinment =
            asicDataResponse.data.ASICId ||
            (!$scope.isCountryNewZealand && asicDataResponse.data.ASICType);
          /* set asic details after all conditions set */
          asicDataResponse.data.KeepACL = $scope.keepACL;
          $scope.asicDetails = asicDataResponse.data;
        })
        .then(() => {
          const dataAsicTmp = {
            asicAppointment: $scope.asicDetails,
          };
          onboardingDataService.setAllOnboardingDataForPreview(dataAsicTmp);
        });
    };

    $scope.updateKeepACL = function(keepACL) {
      const keepACLToPass = setBooleanToInt(keepACL);
      corporateService
        .recruitmentPreferencesSet(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId(),
          keepACLToPass
        )
        .then(getAsicDetails());
    };

    $scope.updateAsicDetails = function(data) {
      onboardingDataService
        .getOnboardingResponse(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(response => {
          if (!response || !response.RoleId) return;
          $scope.appointmentEntity = setBooleanToInt(
            onboardingDataService.isOnboardingUserRole(
              response.RoleId,
              ONBOARDING_ROLE_ID.CONTRACTOR
            )
          );
        })
        .then(() => {
          onboardingService
            .getOnboardingAdviserOrganisationDetails(
              onboardingDataService.getFamilyId(),
              onboardingDataService.getClientId()
            )
            .then(adviserOrgDataResp => {
              if (adviserOrgDataResp && adviserOrgDataResp.data) {
                $scope.onboardingAdviserOrgDetails = adviserOrgDataResp.data;
              }
            })
            .then(() => {
              data.HasASICAppoinment =
                data.ASICId || (!$scope.isCountryNewZealand && data.ASICType);
              onboardingService
                .asicAppointmentDetailsSet(
                  $scope.onboardingAdviserOrgDetails.AdviserOrganisationId,
                  0,
                  data.ASICType,
                  data.ASICId,
                  $scope.appointmentEntity
                )
                .then(response => {
                  if (!response.data) {
                    toaster.pop(
                      'error',
                      'Error',
                      'Saving ASIC Appointment Details'
                    );
                  }
                  getAsicDetails();
                });
            });
        });
    };

    const initialize = () => {
      $scope.asicTypeChexbox = onboardingDataService.getAsicType();

      corporateService
        .recruitmentPreferencesGet(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(recruitmentPrefResp => {
          if (recruitmentPrefResp && recruitmentPrefResp.data) {
            if (recruitmentPrefResp.data.length) {
              $scope.keepACL = recruitmentPrefResp.data[0].KeepACL;
              $scope.asicDetails = {
                keepACL: recruitmentPrefResp.data[0].KeepACL,
              };
            }
          }
        });
      /* get ASIC details */
      getAsicDetails();
      /* get Current Country check if NewZealand */
      $scope.isCountryNewZealand = onboardingDataService.isUserCurrentCountry(
        COUNTRY_TYPE.NEW_ZEALAND
      );
      /* get Current Entity check if Company */
      $scope.entityTypeIsCompany =
        onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.COMPANY_TRUST
        );
    };

    initialize();
  });
