import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular
  .module('app')
  .controller('FinancialExpenseCtrl', function FinancialExpenseCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    SweetAlert,
    optionsService,
    financialsSharedService,
    uiService,
    incomeSharedService
  ) {
    $scope.uiService = uiService;
    $scope.financialsSharedService = financialsSharedService;
    $scope.loanAppId = $stateParams.loanAppId;
    $scope.familyId = $stateParams.familyId;

    $scope.ExpenseTotalAmount = 0;
    $scope.totalExpensePerMonth = 0;

    $scope.Settings = {
      collapseList: true,
      displayIncomeForm: false,
    };

    $scope.$watch('selectedContacts', newValue => {
      if (newValue === 'financials') {
        $scope._expenseInit(true);
      }
    });

    //* ************************************/
    //  owners multi Select                /
    //* ************************************/
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: '&nbsp;', // default-label is deprecated and replaced with this.
    };
    $scope.ownersList = [];
    $scope.ownersListSelected = [];
    angular.extend($scope, {
      displayExpenseFormWrapper: { displayExpenseForm: false }, // scope was changing with bool property
      collapseListWrapper: { collapseList: false }, // scope was changing with bool property
    });

    $scope.ExpenseClientGet = (expenseIdParam, familyId, borrowers) => {
      let expenseId = expenseIdParam;
      if (_.isUndefined(expenseId)) {
        expenseId = 0;
      }

      $scope.ownersListSelected = [];

      contactService.borrowersDetailsGet(familyId, 0).then(respond => {
        $scope.ownersListSelected = borrowers || [];
        $scope.ownersList = $scope.processBorrowersData(
          respond,
          borrowers,
          $scope.ownersListSelected
        );
      });
    };

    $scope._expenseInit = isFull => {
      const expenseObjFinancial = {
        FamilyId: parseInt($scope.familyId, 10) /* .toString() */,
        initials: '',
      };
      if (_.isUndefined($scope.selectedFamily)) {
        $scope.selectedFamily = expenseObjFinancial;
      }

      $scope.ExpenseSet = {};
      $scope.expenseTypes = [];
      // $scope.expenseTypes = [
      //     {"Value":"Sample Type", "Group":"Expense", "Desc":"Sample Expense Type"},
      // ];
      $scope.frequencyTypes = [];
      optionsService.FinancialFrequency().then(response => {
        _.map(response.data, o => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.frequencyTypes = response.data;
      });

      if (isFull) {
        $scope.expenseTypes_all = [];
        loanScenarioService.ExpenseTypeGet().then(response => {
          if (response && response.data) {
            // Filter result - removing (Loans-Credit Cards and Mortgage Repayments)
            const filteredTypesExpense = [];
            for (let i = 0; i < response.data.length; i++) {
              switch (response.data[i].Name) {
                case 'Credit Cards':
                  // code for credit
                  break;
                case 'Mortgage Repayments':
                  break;
                default:
                  filteredTypesExpense.push(response.data[i]);
                  break;
              }
            }
            $scope.expenseTypes_all = financialsSharedService.financialExpeneseFilter(
              filteredTypesExpense
            );
            angular.extend($scope.ExpenseSet, {
              Type: response.data[0].Value,
              Frequency: 3,
            });
          }
        });
      }

      $scope.onTypeSelect = data => {
        $scope.expenseTypes = financialsSharedService.filterExpenseTypes(
          $scope.expenseTypes,
          data
        );
        $scope.ExpenseSet.SelectedType = {};
        $scope.ExpenseSet.SelectedType = { ...data };
        $scope.ExpenseSet.TypeId = data.Value;
      };

      $scope.onTypeSelectAll = () => {
        $scope.ExpenseSet.SelectedType = {};
      };

      // Temporary / should call get api
      $scope.listExpense = [];

      contactService.expenseInfoGet($scope.familyId).then(response => {
        const expense = response.data;
        $scope.ExpenseTotalAmount = 0;
        $scope.totalExpensePerMonth = expense.TotalAmountPerMonth;

        _.forEach(expense.FinancialInfo, financialInfo => {
          if (!angular.isUndefined(financialInfo.FrequencyName))
            financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(
              0
            );
          _.map(financialInfo.Borrowers, borrower => {
            borrower.OwnershipInitials = utilitiesService.filterInitial(
              borrower.FirstName,
              borrower.LastName ? borrower.LastName : ''
            );
            return borrower;
          });
        });

        $scope.ExpenseTotalAmount = expense.TotalAmountPerMonth * 12;
        $rootScope.ExpenseTotal = $scope.ExpenseTotalAmount;

        $scope.listExpense = response.data;

        if (_.size($scope.listExpense.FinancialInfo) > 0) {
          $scope.Settings.collapseList = false;
        }
      });

      $scope.ExpenseClientGet(undefined, $scope.familyId);
    };

    // initialize again this section because there are changes on application section
    $rootScope.$on('REFRESH_APPLICANT_DEPENDENT_SECTIONS', () => {
      $scope._expenseInit(true);
    });

    $rootScope.$on('UPDATE_EXPENSE', () => {
      $scope._expenseInit(true);
    });

    // watching changes of ExpenseList
    $scope.$watchGroup(
      ['listExpense', 'expenseTypes_all'],
      nv => {
        if (_.size(nv[1]) > 0) {
          $scope.expenseTypes = angular.copy(nv[1]);
        }
      },
      true
    );

    $scope.ExpenseInfoDelete = expenseId => {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your expense list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            contactService
              .deleteExpense(expenseId, $scope.familyId)
              .then(() => {
                $timeout(() => {
                  $scope._expenseInit(false);
                  SweetAlert.swal({
                    title: 'Success',
                    text: 'Expense has been been successfully deleted.',
                    type: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                  });
                }, 100);
              });
          }
        }
      );
    };

    $scope.getNewExpenseModel = () => {
      return {
        Repayments: 0,
        Id: 0,
        LoanScenarioId: $scope.loanAppId,
        FamilyId: $scope.familyId,
        FinancialId: 0,
        TypeId: 0,
        Description: '',
        Amount: null,
        CurrencyCode: 'USD',
        Borrowers: [],
        FrequencyID: 4,
        Provider: '',
      };
    };

    $scope.resetExpenseModel = () => {
      $scope.ExpenseSet = $scope.getNewExpenseModel();
    };

    // Save expense inline
    $scope.addExpense = () => {
      // $scope.listExpense.push( $scope.ExpenseSet );

      // action taken : change the familyIds of the model
      angular.extend($scope.ExpenseSet, {
        FamilyId: $scope.familyId.toString(),
        TypeId: parseInt($scope.ExpenseSet.TypeId, 10),
      });

      const joinFamilyObj = _.find($scope.ownersList, obj => {
        return obj.BorrowerID === 0;
      });

      if (joinFamilyObj) {
        if (joinFamilyObj.ticked) {
          $scope.ownersListSelected = _.map($scope.ownersList, obj => {
            if (obj.BorrowerID !== 0) {
              obj.ticked = true;
              return obj;
            }
          });
        }
      } else {
        $scope.ownersListSelected = _.filter($scope.ownersList, obj => {
          return obj.ticked === true;
        });
      }

      $scope.ExpenseSet.Borrowers = [];
      $scope.ownersList.forEach(data => {
        if (data.ticked && data.BorrowerID !== 0) {
          $scope.ExpenseSet.Borrowers.push({
            Percentage: 0,
            BorrowerID: data.BorrowerID,
            IsEntity: data.IsEntity,
          });
        }
      });

      contactService.expenseDetailsSet($scope.ExpenseSet).then(() => {
        $scope._expenseInit(false);
        $scope.resetExpenseModel();
      });
    };

    // Add Expense Modal
    $scope.newExpenseModal = type => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/contacts/client/financial/modal/expense.html',
        controller: 'NewExpenseModalCtrl',
        size: 'sm',
        scope: $scope,
        resolve: {
          modalType() {
            return type;
          },
        },
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then(
        () => {
          $scope.resetExpenseModel();
        },
        () => {
          $scope.resetExpenseModel();
        }
      );
    };

    $scope.ExpenseDetailsGet = (familyId, expense) => {
      $scope.resetExpenseModel();

      angular.extend($scope.ExpenseSet, expense);

      $scope.ExpenseSet.FrequencyID = parseInt(
        $scope.ExpenseSet.FrequencyID,
        10
      );
      $scope.ExpenseSet.TypeId = $scope.ExpenseSet.TypeId.toString();

      $scope.ExpenseSet.SelectedType = incomeSharedService.incomeServices.selectedType(
        $scope.expenseTypes,
        $scope.ExpenseSet.TypeId
      );

      _.map($scope.expenseTypes, expenses => {
        expenses.ticked = expenses.Value === $scope.ExpenseSet.TypeId;
        return expenses;
      });

      // Get clients of particular expense
      $scope.ExpenseClientGet(
        $scope.ExpenseSet.Id,
        familyId,
        angular.copy($scope.ExpenseSet.Borrowers)
      );

      $scope.newExpenseModal('edit');

      //

      // });
    };

    $scope.selectExpense = (familyId, expense) => {
      $scope.ExpenseDetailsGet(familyId, expense);
    };

    // Watcher
    $scope.$watch('listExpense', () => {
      let length = 0;
      if (_.size($scope.listExpense) > 0) {
        _.forEach($scope.listExpense, o => {
          length += _.size(o.FinancialInfo);
        });
        $rootScope.$broadcast('updateExpense', {
          length,
          TotalAmount: $scope.ExpenseTotalAmount,
        });
      } else {
        $rootScope.$broadcast('updateExpense', { length: 0, TotalAmount: 0 });
      }
    });

    // Watch for changes in selected family
    $scope.$watch('selectedFamily.FamilyId', nv => {
      $scope.ExpenseClientGet(undefined, nv);
    });

    $scope.onItemSelect = data => {
      let isJointFamily = true;
      for (let i = 0; i < $scope.ownersList.length; i++) {
        if (
          (typeof $scope.ownersList[i].ticked === 'undefined' ||
            !$scope.ownersList[i].ticked) &&
          $scope.ownersList[i].BorrowerID >= 1
        ) {
          isJointFamily = false;
        }
      }

      // will be true if user clicks on joint
      if (parseInt(data.BorrowerID, 10) === 0) {
        isJointFamily = true;
      }
      if (isJointFamily) {
        const findJointExpenseChecker = incomeSharedService.incomeServices.findJoint(
          $scope.ownersList
        );
        // need to check if single owner
        if (
          findJointExpenseChecker &&
          Object.keys(findJointExpenseChecker).length
        ) {
          $scope.ownersList = _.map($scope.ownersList, obj => {
            obj.ticked = parseInt(obj.BorrowerID, 10) === 0;
            return obj;
          });
        }
      } else {
        $scope.ownersList = _.map($scope.ownersList, obj => {
          if (parseInt(obj.BorrowerID, 10) === 0) {
            obj.ticked = false;
          }
          return obj;
        });
      }
    };

    $scope.ownersListSelectedArray = [];
    $scope.onSelectAll = () => {
      const findJointExpense = incomeSharedService.incomeServices.findJoint(
        $scope.ownersList
      );
      // need to check if single owner
      if (findJointExpense && Object.keys(findJointExpense).length) {
        $scope.ownersList = _.map($scope.ownersList, obj => {
          obj.ticked = obj.BorrowerID === 0;
          return obj;
        });
      } else {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].BorrowerID > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      }
    };

    $scope.$watch('ownersListSelected', () => {
      $('.hide-in-mobile .multiSelect')
        .nextAll('div.buttonLabel')
        .remove();
      if ($scope.ownersListSelected.length >= 3) {
        $('.multiSelect .buttonLabel:last-child').html(
          `${$scope.ownersListSelected.length - 2} more ...` +
            `<span class="caret"></span>`
        );
      }
      $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each(
        e => {
          $(e.currentTarget).html(
            `${$(
              e.currentTarget
            ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`
          );
        }
      );
    });

    $scope.reloadExpenseData = () => {
      $scope._expenseInit(false);
      $scope.resetExpenseModel();
    };

    $scope.openGridModal = () => {
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/loanApplication/clientAssess/modal/expense.grid.html',
        controller: 'LoanappOpenGridModalCtrl',
        size: 'sm',
        resolve: {
          expenseTypes: () => {
            const expenseTypes = [];
            _.forEach($scope.expenseTypes_all, o => {
              expenseTypes.push({
                GroupId: o.GroupId,
                TypeName: o.Name,
                GroupName: o.GroupName,
                TypeId: o.Value,
              });
            });
            return expenseTypes;
          },

          frequencyTypes: () => {
            return $scope.frequencyTypes;
          },

          loanAppId: () => {
            return $scope.loanAppId;
          },

          familyId: () => {
            return $scope.familyId;
          },

          ownersList: () => {
            const ownersList = [];
            _.forEach($scope.ownersList, o => {
              if (o.BorrowerID) {
                ownersList.push({
                  BorrowerID: o.BorrowerID,
                  LastName: o.LastName,
                  FirstName: o.FirstName,
                  PreferedName: o.PreferedName,
                  initials: utilitiesService.filterInitial(
                    o.FirstName ? o.FirstName : '',
                    o.LastName ? o.LastName : ''
                  ),
                  background: financialsSharedService.getBGcolor(o.BorrowerID),
                  IsEntity: o.IsEntity,
                });
              }
            });
            return ownersList;
          },
        },
        backdrop: 'static',
        keyboard: false,
        windowClass: 'expense-grid',
      });

      modalInstance.result.then(
        () => {
          $scope.reloadExpenseData();
        },
        () => {
          $scope.reloadExpenseData();
        }
      );
    };
  });
