import template from './onlineFactFindModal.html';
import controller from './onlineFactFindModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanId: '<',
    isReviewShow: '<',
  },
};
