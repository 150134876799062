import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .controller('ReferralModalCtrl', function ReferralModalCtrl(
    $scope,
    $uibModalInstance,
    $rootScope,
    $state,
    contactService,
    toaster,
    contactFamilyInfoGet,
    contactInfoSetValue,
    selectedReferralCategory,
    referralCategoryList,
    contactSharedData,
    referralDetailService
  ) {
    $scope.refChildList = [];
    $scope.isNewCategory = false;
    $scope.newCategoryItem = false;
    $scope.contactFamilyInfoGet = contactFamilyInfoGet;
    $scope.contactInfoSetValue = contactInfoSetValue;
    $scope.selectedReferralCategory = selectedReferralCategory;
    $scope.referralCategoryList = referralCategoryList;

    $scope.showHideEdit = function(item) {
      item.IsEditMode = !item.IsEditMode;
    };

    $scope.updateReferralCategoryItem = function(
      currentlyEditedReferralCategoryItem
    ) {
      if (
        currentlyEditedReferralCategoryItem.ItemName === '' ||
        !currentlyEditedReferralCategoryItem.ItemName
      ) {
        toaster.pop(
          'error',
          'Empty Name',
          'Referral Item Name cannot be empty.'
        );
        return;
      }
      currentlyEditedReferralCategoryItem.IsEditMode = false;

      contactService
        .updateRefCategoryItem(
          currentlyEditedReferralCategoryItem.ReferralItemID,
          currentlyEditedReferralCategoryItem.ItemName
        )
        .then(() => {
          toaster.pop(
            'success',
            'Updated',
            'Referral category item has been updated successfully'
          );
        })
        .catch(displayError);
    };

    // For New Source Group
    angular.extend($scope, {
      addSourceGroup() {
        $scope.isNewCategory = true;
        $scope.newCategory = {
          CategoryName: '',
        };
      },
      getReferralCategoryList() {
        referralDetailService.getReferralCategoryList().then(response => {
          $scope.referralCategoryList = _.uniqBy(
            response,
            'ReferralCategoryID'
          );
        });
      },
      saveNewSourceGroup() {
        if (!$scope.newCategory.CategoryName) {
          swal('Empty Name', 'Referral Source Name cannot be empty.', 'error');
        } else {
          $scope.isNewCategory = false;
          contactService
            .addReferralCategory($scope.newCategory.CategoryName)
            .then(() => {
              $scope.getReferralCategoryList();
              toaster.pop(
                'success',
                'Added',
                'Referral category has been added successfully'
              );
            })
            .catch(displayError);
        }
      },
      removeNewSourceGroup() {
        $scope.isNewCategory = false;
      },
      reorderCategory(referralCategory, orderBy) {
        contactService
          .updateRefCategoryOrder(referralCategory, orderBy)
          .then(() => {
            $scope.getReferralCategoryList();
          })
          .catch(displayError);
      },
      reorderUpCategory(referralCategory) {
        if (referralCategory.OrderBy > 1) {
          _.map($scope.referralCategoryList, o => {
            if (o.OrderBy === referralCategory.OrderBy - 1) {
              o.OrderBy = angular.copy(referralCategory.OrderBy);
            }
            return o;
          });
          referralCategory.OrderBy--;

          let referralCategoryID = '';
          let OrderBy = '';

          _.forEach($scope.referralCategoryList, (obj, indexVal) => {
            if (indexVal === $scope.referralCategoryList.length - 1) {
              referralCategoryID += obj.ReferralCategoryID;
              OrderBy += obj.OrderBy;
            } else {
              referralCategoryID += `${obj.ReferralCategoryID},`;
              OrderBy += `${obj.OrderBy},`;
            }
          });
          contactService.EnquiryCategoryReorder(
            $state.params.familyId,
            referralCategoryID,
            OrderBy
          );
        }
      },
      reorderDownCategory(referralCategory) {
        if (referralCategory.OrderBy < _.size($scope.referralCategoryList)) {
          _.map($scope.referralCategoryList, o => {
            if (
              parseInt(o.OrderBy, 10) ===
              parseInt(referralCategory.OrderBy, 10) + 1
            ) {
              o.OrderBy = angular.copy(referralCategory.OrderBy);
            }
            return o;
          });
          referralCategory.OrderBy++;

          let referralCategoryID = '';
          let OrderBy = '';

          _.forEach($scope.referralCategoryList, (o, i) => {
            if (i === _.size($scope.referralCategoryList) - 1) {
              referralCategoryID += o.ReferralCategoryID;
              OrderBy += o.OrderBy;
            } else {
              referralCategoryID += `${o.ReferralCategoryID},`;
              OrderBy += `${o.OrderBy},`;
            }
          });
          contactService.EnquiryCategoryReorder(
            $state.params.familyId,
            referralCategoryID,
            OrderBy
          );
        }
      },
      updateReferralCategory(currentlyEditedReferralCategory) {
        if (currentlyEditedReferralCategory.CategoryName === '') {
          toaster.pop(
            'error',
            'Empty Name',
            'Referral Category Name cannot be empty.',
            'error'
          );
          return;
        }
        currentlyEditedReferralCategory.IsEditMode = false;

        contactService
          .updateRefCategory(
            currentlyEditedReferralCategory.ReferralCategoryID,
            currentlyEditedReferralCategory.CategoryName
          )
          .then(() => {
            toaster.pop(
              'success',
              'Updated',
              'Referral category has been updated successfully'
            );
          })
          .catch(displayError);
      },
      deleteRefCategory(referralCategoryID) {
        swal(
          {
            title: 'Are you sure?',
            text:
              'This record will be removed from your referral category list',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: true,
          },
          confirm => {
            if (confirm) {
              contactService
                .deleteReferralCategory(referralCategoryID)
                .then(() => {
                  _.remove($scope.referralCategoryList, o => {
                    return (
                      parseInt(o.ReferralCategoryID, 10) ===
                      parseInt(referralCategoryID, 10)
                    );
                  });
                  toaster.pop(
                    'success',
                    'Deleted',
                    'Referral category has been deleted successfully'
                  );
                })
                .catch(displayError);
            }
          }
        );
      },
    });

    $scope.hideEditButton = function(referralCategory) {
      referralCategory.IsEditMode = true;
    };

    // For New Category Items
    angular.extend($scope, {
      addNewCategoryItem() {
        $scope.newCategoryItem = {
          ReferralCategoryID:
            $scope.selectedReferralCategory.ReferralCategoryID,
          ReferralItemID: 0,
          isExclusiveForCorpUser: false,
        };
      },
      removeNewCategoryItem() {
        $scope.newCategoryItem = false;
      },
      saveNewReferralCategoryItem() {
        if (
          $scope.newCategoryItem.ItemName === '' ||
          !$scope.newCategoryItem.ItemName
        ) {
          toaster.pop(
            'error',
            'Empty Name',
            'Referral Item Name cannot be empty.'
          );
          return;
        }

        contactService
          .addReferralItem(
            $scope.selectedReferralCategory.ReferralCategoryID,
            $scope.newCategoryItem.ItemName,
            $scope.newCategoryItem.ItemPhone
          )
          .then(() => {
            $scope.selectedReferralCategory.Children.push(
              $scope.newCategoryItem
            );
            $scope.newCategoryItem = false;
            toaster.pop(
              'success',
              'Added',
              'Referral category item has been added successfully'
            );
          })
          .catch(displayError);
      },
    });

    $scope.deleteReferralItem = function(referralItemID) {
      swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your referral item list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            contactService
              .deleteReferralItem(referralItemID)
              .then(() => {
                _.remove($scope.selectedReferralCategory.Children, o => {
                  return (
                    parseInt(o.ReferralItemID, 10) ===
                    parseInt(referralItemID, 10)
                  );
                });
                toaster.pop(
                  'success',
                  'Deleted',
                  'Referral category item has been deleted successfully'
                );
              })
              .catch(displayError);
          }
        }
      );
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.done = function() {
      if (typeof $scope.contactInfoSetValue.ReferralCategory === 'undefined') {
        $scope.contactInfoSetValue.ReferralCategory = '';
      }
      contactService
        .contactFamilyInfoSet(angular.toJson($scope.contactInfoSetValue))
        .then(() => {
          $scope.contactFamilyInfoGet();
          toaster.pop(
            'success',
            'Updated',
            'Referral Source has been successfully updated'
          );
        });
      $uibModalInstance.dismiss('cancel');
    };

    $scope.toggleChildrenDiv = referralCategory => {
      contactSharedData
        .toggleChildrenDiv(referralCategory, $scope.referralCategoryList)
        .then(response => {
          $scope.selectedReferralCategory = response.selectedReferralCategory;
          $scope.contactInfoSetValue.ReferralCategory =
            response.ReferralCategory;
        });
    };

    function init() {
      $scope.getReferralCategoryList();
    }

    init();
  });
