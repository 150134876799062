export default class TextAreaHeightAutoCtrl {
  constructor($timeout, $window) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
  }

  $onInit() {
    this.hiddenDiv = this.$window.document.getElementById('hidden-div');
    if (typeof this.isBordered === 'undefined') this.isBordered = true;
  }

  $onChanges(changes) {
    const { ngModel } = changes;
    if (ngModel && typeof ngModel.currentValue !== 'undefined')
      this.updateHiddenDiv();
  }

  updateHiddenDiv() {
    this.content = this.ngModel && this.ngModel.replace(/\n/g, '<br>');
    this.onNgChange({ textAreaModel: this.ngModel });
  }

  get divHeight() {
    return this.hiddenDiv.clientHeight;
  }
}
