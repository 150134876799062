import angular from 'angular';

angular
  .module('app')
  .controller('EditEmailContentModalCtrl', function EditEmailContentModalCtrl(
    $scope,
    $uibModalInstance,
    LenderSubmissionObj,
    emailType
  ) {
    $scope.$uibModalInstance = $uibModalInstance;
    $scope.LenderSubmissionObj = LenderSubmissionObj;
    $scope.emailType = emailType;
  });
