import template from './insurerComparisonGraph.html';
import controller from './insurerComparisonGraphCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    graphData: '<',
    quoteData: '<',
    graphColors: '<',
  },
};
