import template from './percentageInput.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    id: '@',
    model: '<',
    inputChange: '&',
  },
};
