import template from './commissionEnquiry.html';
import controller from './commissionEnquiryCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanId: '<',
    familyId: '<',
    commission: '<',
    onSubmitted: '&',
  },
};
