import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('decimalOnly', function decimalOnly($window) {
  return {
    require: '?ngModel',
    link(scope, element) {
      element.on('keypress', function(evt) {
        const event = evt || $window.event;
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode === 8 || charCode === 37) {
          return true;
        } else if ($(this).val().length === 0 && charCode === 46) {
          return false;
        } else if (
          charCode === 46 &&
          $(this)
            .val()
            .indexOf('.') !== -1
        ) {
          return false;
        } else if (
          charCode > 31 &&
          charCode !== 46 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        } else if (
          $(this)
            .val()
            .indexOf('.') > 0
        ) {
          const charAfterdot =
            $(this).val().length +
            1 -
            $(this)
              .val()
              .indexOf('.');
          if (charAfterdot > 3) {
            return false;
          }
        }
        return true;
      });
    },
  };
});
