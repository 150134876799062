import template from './officeAddress.html';
import OfficeAddressCtrl from './officeAddressCtrl';

export default {
  template,
  bindings: {
    addressModel: '=',
  },
  controller: OfficeAddressCtrl,
  controllerAs: 'vm',
};
