export const PIPELINE_TYPES_STR = {
  LEADS: 'leads',
  APPLICATION: 'application',
  OPPORTUNITY: 'opportunity',
};

export const PIPELINE_VIEW_TYPES = {
  KANBAN: 1,
  TABLE: 2,
};

export const KANBAN_OPTIONS = [
  { label: 'Comfortable', value: false },
  { label: 'Compact', value: true },
];

export const PIPELINE_TYPES_ID = {
  LEADS: 1,
  APPLICATION: 2,
  OPPORTUNITY: 3,
};

export const PIPELINE_SETTINGS_CATEGORY = {
  LEADS: 'New Lead',
  APPLICATION: 'Application in Progress',
  OPPORTUNITY: 'Opportunity',
};

export const PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS = {
  REFRESH_KANBAN: 'refresh-kanban',
  RECONVERT_CARDS: 'reconvert-cards',
  REFRESH_TOTAL_AMOUNTS: 'refresh-total-amounts',
  REFRESH_TABLE: 'refresh-table',
  ON_EXCLUDE_COLUMN: 'on-exclude-column',
  ON_FIRST_LOAD: 'on-first-load',
  REFRESH_LABELS: 'on-refresh-labels',
  REMOVE_CARD: 'on-remove-card',
  MOVE_CARD: 'on-move-card',
};

export const PIPELINE_CATEGORIES = {
  LEADS: 1,
  APPLICATION: 2,
  OPPORTUNITY: 3,
};
