import template from './checkBoxFilter.html';
import checkBoxFilterCtrl from './checkBoxFilterCtrl';

export default {
  template,
  bindings: {
    type: '<',
    onChangeInfo: '&',
  },
  controller: checkBoxFilterCtrl,
  controllerAs: 'vm',
};
