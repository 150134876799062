import template from './barGraphStack.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    stackData: '<',
    isLoadingRatings: '=',
  },
};
