import template from './allocatedAdviserTooltip.html';
import controller from './allocatedAdviserTooltipCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    adviserId: '<',
    adviser: '<',
    assistantId: '<',
    assistantName: '<',
    onAdviserChange: '&',
    onAssistantChange: '&',
  },
};
