import template from './insurerBrochuresList.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    brochures: '<',
  },
};
