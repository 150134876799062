import angular from 'angular';
import usersLeadManagement from './usersLeadManagement';
import leadStatus from './leadStatus';
import leadWorkingDays from './leadWorkingDays';
import './style.scss';

export default angular
  .module('components.usersLeadManagement', [])
  .component('usersLeadManagement', usersLeadManagement)
  .component('leadStatus', leadStatus)
  .component('leadWorkingDays', leadWorkingDays).name;
