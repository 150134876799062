import template from './loansSummary.html';
import controller from './loansSummaryCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    headingTitle: '@',
    viewDetailsTargetContactsTab: '@',
    loansSummaryType: '@',
    loansList: '<',
    initialMaxItemCount: '<',
    countryCode: '<',
    currency: '<',
    onViewDetailsClick: '&',
    onAddLoanClick: '&',
  },
};
