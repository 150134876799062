import angular from 'angular';
import $ from 'jquery';

angular.module('app').directive('colorPicker', function colorPicker() {
  return {
    require: '?ngModel',
    link(scope, elem, attr, ngModel) {
      function getPicker(vv) {
        let picker = elem.next('span.color-picker');
        if (picker.length === 0) {
          picker = $('<span class="color-picker"></span>');
        }
        picker.css('background-color', vv);
        return picker;
      }

      function checkVal() {
        const vv = ngModel.$viewValue;
        if (!vv || Number.isNaN(vv)) return;
        getPicker(vv).insertAfter(elem);
      }
      scope.$watch(() => {
        return ngModel.$viewValue;
      }, checkVal);
      checkVal();
    },
  };
});
