import template from './categoryFilters.html';
import controller from './categoryFiltersCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    filters: '<',
    filterValueProperty: '@',
    filterNameProperty: '@',
    filterColorProperty: '@',
    selectedFilterValues: '<',
    onFilterToggled: '&',
  },
};
