import template from './subscriptionSuccess.html';
import controller from './subscriptionSuccessCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    closeModal: '&',
  },
};
