import angular from 'angular';

angular
  .module('app')
  .controller('TaskVideoDemoCtrl', function TaskVideoDemoCtrl(
    $scope,
    $uibModalInstance,
    DEMO_VIDEO
  ) {
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.videoId = DEMO_VIDEO.TASKS.Url;
  });
