import template from './mycrmSlider.html';
import MycrmSliderCtrl from './mycrmSliderCtrl';

export default {
  template,
  bindings: {
    leadProfileDetails: '<',
    sliderTitle: '@',
    sliderDimension: '@',
    minDimension: '@',
    maxDimension: '@',
    mainFamilyId: '<',
    isEditable: '<',
  },
  controller: MycrmSliderCtrl,
  controllerAs: 'vm',
};
