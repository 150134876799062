// TODO: move this to dashboardService
class NotificationSidebarService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'dashboard';
  }

  RecentActivityFeedList(count) {
    return this.httpClient.get(`${this.apiBaseUrl}/RecentActivityFeedList`, {
      count,
    });
  }

  getNotificationList(count) {
    return this.httpClient.get(`${this.apiBaseUrl}/NotificationList`, {
      count,
    });
  }

  countUnReadNotification() {
    return this.httpClient.get(`${this.apiBaseUrl}/UnReadNotificationCount`);
  }

  getRecentActivityFeedSetting() {
    return this.httpClient.get(`${this.apiBaseUrl}/RecentActivityFeedSetting`);
  }

  setRecentActivityFeedSetting(id, data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/RecentActivityFeedSetting/${id}`,
      data
    );
  }

  setRecentActivityFeedRead(communicationID) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/RecentActivityFeedReadSet`,
      { communicationID }
    );
  }

  UserDefaultSelectionGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/UserDefaultSelectionGet`);
  }

  UserDefaultSelectionSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/UserDefaultSelectionSet`,
      data
    );
  }

  GetCalendar(calendarId, calendarDay) {
    // TODO: move this to googleService
    return this.httpClient.get(`google/Calendar`, { calendarId, calendarDay });
  }

  CalendarEvent(calendarId, eventId) {
    // TODO: move this to googleService
    return this.httpClient.delete(`google/CalendarEvent`, {
      calendarId,
      eventId,
    });
  }

  CalendarAuth(code) {
    // TODO: move this to googleService
    return this.httpClient.get(`google/CalendarAuth`, { code });
  }
}

export default NotificationSidebarService;
