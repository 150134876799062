import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .directive('newLoanApplicationEvents', function newLoanApplicationEvents() {
    return {
      link(scope, elem) {
        const par = elem.closest('.interactive-form');
        const subtitle = angular.element('.modal-subtitle');

        elem.on('click', () => {
          par.toggleClass('active');
          subtitle.toggle();
        });

        scope.$on('$destroy', () => {
          elem.off('click', _.noop);
        });
      },
    };
  });
