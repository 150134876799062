import { toastError } from 'Common/utilities/alert';
import { personToSetContactModel } from 'Common/utilities/insurance';

class QuoteToContactCtrl {
  constructor(
    $state,
    $timeout,
    $window,
    $uibModalInstance,
    $uibModal,
    insuranceProfilerService,
    insuranceQuoteService,
    contactService,
    toaster,
    formData,
    listenFrom,
    insuranceModalApplicationService
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.insuranceQuoteService = insuranceQuoteService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.formData = formData;

    this.searchContactText = '';
    this.selectedFamily = {};
    this.getFamilyList = [];
    this.searchContactText = '';
    this.selectedFamily = {};
    this.showContactList = false;
    this.prefix = this.formData.prefix;
    this.listenFrom = listenFrom;
    this.insuranceCreateApp = insuranceModalApplicationService;
    this.allocatedAdviserId = '';
  }
  // 3. Angular Functions
  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  close(response = 'close') {
    this.$uibModalInstance.close(response);
  }

  insuranceAssignToClient(familyId, insuranceId) {
    if (this.prefix !== 'quote') {
      this.insuranceProfilerService
        .profilerAssignToClient(familyId, insuranceId)
        .then(response => {
          if (response.status === 200) {
            this.toaster.pop(
              'success',
              'Success',
              'Profiler successfully added to contact.'
            );
          } else {
            this.toaster.pop(
              'error',
              'Error',
              'Profiler failed to add in contact'
            );
          }
          this.close({ familyId });
        });
    } else {
      this.insuranceQuoteSet(familyId, insuranceId);
    }
  }

  insuranceQuoteSet(familyId, insuranceID) {
    this.insuranceQuoteService
      .insuranceQuoteSet(
        insuranceID,
        familyId,
        this.formData.NumberOfClients,
        this.formData.NumberOfChildren
      )
      .then(response => {
        if (response.data) {
          const insuranceId = response.data;
          this.formData[`${this.prefix}Id`] = insuranceId;
          this.formData.savingNew = true;
          this.toaster.pop(
            'success',
            'Success',
            'Quote was successfully saved to contact.'
          );
          this.$state.go('app.quickQuoteDetails', {
            insQuoteId: insuranceId,
            familyId,
          });
          this.close({ familyId });
          if (this.listenFrom.types === 'app') {
            this.createNewLoanAppFromExisting(insuranceId);
          }
        } else {
          this.toaster.pop('error', 'Error', 'Error saving quote!');
        }
      });
  }

  createNewLoanAppFromExisting(paramQuoteId) {
    this.insuranceCreateApp.onCreate({
      typeNew: false,
      familyId: this.formData.FamilyID,
      quoteId: paramQuoteId,
      fullName: this.formData.FamilyFullName,
    });
  }

  getAdviserData(adviserData) {
    const familyMember = this.formData.familyInfo.map(member => {
      member.allocatedAdviserId = parseInt(adviserData, 10);
      return member;
    });
    this.formData.familyInfo = familyMember;
    this.allocatedAdviserId = parseInt(adviserData, 10);
  }

  saveToContacts() {
    if (!this.allocatedAdviserId && !this.formData.FamilyID) {
      toastError('Allocated Adviser is Required');
      return;
    }
    if (!this.formData.FamilyID) {
      const familyMembers = this.formData.familyInfo.map(member =>
        personToSetContactModel(member)
      );
      this.contactService.addContact(familyMembers).then(response => {
        if (response.data) {
          this.formData.FamilyID = response.data;
          this.formData.savingNew = !this.formData.FamilyID;
          this.insuranceAssignToClient(
            this.formData.FamilyID,
            this.formData[`${this.prefix}Id`]
          );
        }
      });
    } else {
      this.insuranceAssignToClient(
        this.formData.FamilyID,
        this.formData[`${this.prefix}Id`]
      );
    }
  }
}

export default QuoteToContactCtrl;
