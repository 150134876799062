import { CONTENT_TYPE } from 'Common/constants/contentType';

export const getDocumentTypeIcon = docType => {
  const contentType = docType && docType.toLowerCase();
  switch (contentType) {
    case CONTENT_TYPE.PDF:
      return 'assets/images/icon/pdf.png';
    case CONTENT_TYPE.WORD:
      return 'assets/images/icon/word.png';
    case CONTENT_TYPE.POWERPOINT:
      return '/assets/images/icon/ppt.png';
    case CONTENT_TYPE.SPREAD_SHEET:
    case CONTENT_TYPE.EXCEL:
      return 'assets/images/icon/xls.png';
    default:
      return '/assets/images/icon/generic.png';
  }
};
