/**
 * Prevent default action on empty links.
 */
import angular from 'angular';

angular.module('app').directive('a', function a() {
  return {
    restrict: 'E',
    link(scope, elem, attrs) {
      if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
        elem.on('click', e => {
          e.preventDefault();
        });
      }
    },
  };
});
