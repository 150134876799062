import template from './providerQuickForms.html';
import controller from './providerQuickFormsCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    insurerId: '<',
  },
};
