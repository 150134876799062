import template from './tableViewLeads.html';
import controller from './tableViewLeadsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    tableData: '<',
    tableHeaders: '<',
    freezeColumns: '<',
    filterSettings: '<',
    totalRecords: '<',
    isSmallDevice: '<',
    onChangeCardStatus: '&',
    isCorporateUser: '<',
    labelsSettings: '<',
    onLabelToggle: '&',
    onRefreshTable: '&',
    onRefreshLabelsSettings: '&',
    isShrink: '<',
    onMenuClick: '&',
    onViewBorrower: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onViewSubmission: '&',
    onCardClick: '&',
    onToggleIsShrink: '&',
    onDeletePipelineItem: '&',
    probabilityList: '<',
    onChangeProbability: '&',
    onChangeSubStatus: '&',
    onLeadAddNote: '&',
    conversionOptions: '<',
    onConvertCard: '&',
    onRefreshStats: '&',
    onColumnSort: '&',
    onPageChange: '&',
    pageSize: '<',
    pageNumber: '<',
  },
};
