import template from './pipelineLabelSelector.html';
import controller from './pipelineLabelSelectorCtrl.js';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    labels: '<',
    selectedLabelIds: '<',
    onItemClick: '&',
    onAddLabelToCard: '&',
    onDeleteLabelFromCard: '&',
    onDeleteLabel: '&',
  },
};
