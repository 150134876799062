export default class InsurancePipelineCardMenuCtrl {
  constructor($state) {
    'ngInject';

    this.$state = $state;
  }

  $onChanges(changes) {
    if (changes.doForceToMainMenu && changes.doForceToMainMenu.currentValue) {
      this.toggleMenu('main');
    }
  }

  toggleMenu(type) {
    this.menuType = this.menuType === type ? 'main' : type;
  }

  viewClient() {
    this.onViewClient && this.onViewClient();
    this.$state.go('app.contactsSingle', { familyId: this.clientId });
  }

  viewWorkbench() {
    this.onViewWorkbench &&
      this.onViewWorkbench({ quoteId: this.quoteId, clientId: this.clientId });
  }
}
