import angular from 'angular';

angular.module('app').controller('CommissionTransCtrl', [
  '$scope',
  '$routeParams',
  function CommissionTransCtrl($scope, $routeParams) {
    const vm = this;
    vm.keyword = $routeParams.keyword;
  },
]);
