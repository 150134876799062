class AuthService {
  constructor($http, httpClient, configService) {
    'ngInject';

    this.$http = $http;
    this.httpClient = httpClient;
    this.configService = configService;
  }

  checkJwt(token, jwttype) {
    if (!token || !jwttype) return false;
    return this.$http({
      url: `${this.configService.resource}/CheckJWT`,
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      config: {
        params: { jwttype },
      },
    });
  }

  logout() {
    return this.httpClient.post('Logout', {}, {}, false);
  }
}

export default AuthService;
