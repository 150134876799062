import template from './workBenchDetails.html';
import controller from './workBenchDetailsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    quoteId: '<',
    workBenchDetails: '<',
    onNewWorkDateChange: '&',
    onStatusChange: '&',
  },
};
