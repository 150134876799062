import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .directive('financeOwnership', function financeOwnership() {
    return {
      templateUrl(elem, attr) {
        switch (attr.mode) {
          case 'basic':
            return '/assets/views/contacts/client/financials/financeOwnership.html';
          case 'advanced':
            return '/assets/views/contacts/client/financials/financeOwnership-adv.html';
          case 'static':
            return '/assets/views/contacts/client/financials/financeOwnership-static.html';
          default:
            break;
        }
      },

      link($scope, attrs) {
        if (attrs.mode === 'static') {
          if (attrs.owners) {
            attrs.owners = JSON.parse(attrs.owners);

            _.map(
              ($scope.owners = _.filter(
                attrs.owners,

                obj => {
                  return obj.IsInclude === true;
                }
              ))
            );
          }
        }

        $scope.selectBorrower = function(index) {
          $scope.borrowers[index].IsInclude = !$scope.borrowers[index]
            .IsInclude;
        };
      },
    };
  });
