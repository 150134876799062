// Helpers for displaying custom tooltips specifically for angular-chart-js (http://jtblin.github.io/angular-chart.js/)
import angular from 'angular';

export function createTooltip(canvas, tooltipId) {
  const newTooltip = angular.element(
    `<div id="${tooltipId}" class="custom-chart-tooltip"></div>`
  );
  angular.element(canvas.parent()).append(newTooltip[0]);
  return newTooltip;
}

export function getChartTooltip(canvas, tooltipId = 'chartjs-tooltip') {
  const tooltipElementSelector = `#${tooltipId}`;
  const appendedTooltip = angular.element(tooltipElementSelector);
  const doCreateTooltip = (!appendedTooltip || !appendedTooltip[0]) && canvas;
  return doCreateTooltip ? createTooltip(canvas, tooltipId) : appendedTooltip;
}

export function getTooltipYLocation(tooltip) {
  if (!tooltip) return 0;
  const { y, yAlign, caretHeight, caretPadding } = tooltip;
  return yAlign === 'above'
    ? y - caretHeight - caretPadding
    : y + caretHeight + caretPadding;
}

export function hideTooltip(tooltipElement) {
  tooltipElement &&
    tooltipElement.css({
      display: 'none',
    });
}

export function setCaretPosition(tooltipElement, tooltip) {
  tooltipElement.removeClass('above below no-transform');
  const caretPositionClass = tooltip.yAlign || 'no-transform';
  tooltipElement.addClass(caretPositionClass);
}

export function getTooltipChartCanvas(tooltip = {}) {
  const { chart } = tooltip;
  const canvas = chart && chart.canvas;
  return canvas && angular.element(chart.canvas);
}

export function getTooltipStyles(tooltip, canvas) {
  if (!tooltip || !canvas) return;

  const top = getTooltipYLocation(tooltip);
  const canvasTop = canvas.prop('offsetTop');
  const canvasLeft = canvas.prop('offsetLeft');
  const {
    x,
    width,
    fontFamily,
    fontSize,
    fontStyle,
    yPadding,
    xPadding,
  } = tooltip;
  return {
    display: 'block',
    width: width ? `${width}px` : 'auto',
    left: `${canvasLeft + x}px`,
    top: `${canvasTop + top}px`,
    padding: `${yPadding} px ${xPadding}px`,
    fontFamily,
    fontSize,
    fontStyle,
  };
}

export function showCustomTooltip(tooltip, tooltipId) {
  const canvas = getTooltipChartCanvas(tooltip);
  const tooltipElement = getChartTooltip(canvas, tooltipId);

  if (!tooltip) {
    hideTooltip(tooltipElement);
    return;
  }

  canvas.css('cursor', 'pointer');
  tooltipElement.html(tooltip.text);
  setCaretPosition(tooltipElement, tooltip);
  tooltipElement.css(getTooltipStyles(tooltip, canvas));
}

export function getMonetaryTooltipTemplate() {
  return '<%if (label){%><%=label%>: <%}%><%= parseFloat(value, 10).toLocaleString("en") %>';
}
