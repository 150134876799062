import {
  PIPELINE_TYPES_STR,
  PIPELINE_VIEW_TYPES,
  KANBAN_OPTIONS,
  PIPELINE_SETTINGS_CATEGORY,
  PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS,
} from 'Common/constants/pipelineType';
import { displayError } from 'Common/utilities/alert';
import {
  PipelineLeadSettingsService,
  PipelineOpportunitySettingsService,
  PipelineApplicationSettingsService,
} from 'Common/services/pipelineSettingsService';
import {
  pipelineSettingsBuilderForUI,
  pipelineSettingsBuilderForAPI,
} from 'Common/mappers/pipeline';
import { goToOpportunity } from 'Common/utilities/loanOpportunity';
import { objectLength } from 'Common/utilities/objectValidation';
import { COLOR } from 'Common/constants/colors';
import { collectionCamelizeBuilderForUI } from 'Common/mappers/genericMapper';
import { applicationCardMap } from 'Common/utilities/kanbanHelper';
import { pipelineApplicationTableMap } from 'Common/utilities/pipelineTable';
import { PROBABILITIES } from 'Common/constants/probabilities';
import { PIPELINE_FILTER_TYPES } from 'Common/constants/pipelineFilterTypes';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import swal from 'sweetalert';
import toastr from 'toastr';
import moment from 'moment';

const pipelineStats = {
  displayPrevious: false,
  barCap: 3,
  trackWidth: 3,
  barWidth: 3,
  size: 36,
  readOnly: true,
  trackColor: 'transparent',
  unit: '%',
};

const loanInformationModalConvertionStatus = [
  LOAN_STATUS.EXISTING.SETTLED,
  LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH,
];

export default class PipelineCtrl {
  constructor(
    opportunityNewModalService,
    loanApplicationServices,
    cardRedirectionsService,
    loanOpportunityService,
    nextGenLoanAppService,
    enquirySourceService,
    pipelineSharedData,
    modalRenderService,
    currentUserService,
    pipelineService,
    contactService,
    optionsService,
    generalService,
    $localStorage,
    $stateParams,
    stateService,
    $uibModal,
    uiService,
    $window,
    $state,
    $filter,
    $sce,
    $q
  ) {
    'ngInject';

    this.opportunityNewModalService = opportunityNewModalService;
    this.loanApplicationServices = loanApplicationServices;
    this.cardRedirectionsService = cardRedirectionsService;
    this.loanOpportunityService = loanOpportunityService;
    this.nextGenLoanAppService = nextGenLoanAppService;
    this.enquirySourceService = enquirySourceService;
    this.pipelineSharedData = pipelineSharedData;
    this.modalRenderService = modalRenderService;
    this.currentUserService = currentUserService;
    this.contactService = contactService;
    this.optionsService = optionsService;
    this.generalService = generalService;
    this.$localStorage = $localStorage;
    this.$stateParams = $stateParams;
    this.stateService = stateService;
    this.$uibModal = $uibModal;
    this.uiService = uiService;
    this.$window = $window;
    this.$state = $state;
    this.pipelineService = pipelineService;
    this.$filter = $filter;
    this.$sce = $sce;
    this.$q = $q;
    this.checkRoute();

    this.getPipelineStats = this.getPipelineStats.bind(this);
    this.childComponentNotificationRegistration = this.childComponentNotificationRegistration.bind(
      this
    );
  }

  $onInit() {
    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.PIPELINE_VIEW_TYPES = PIPELINE_VIEW_TYPES;
    this.KANBAN_OPTIONS = KANBAN_OPTIONS;

    const { pipelineType } = this.$stateParams;
    this.pipelineType = pipelineType;

    this.userInfo = {};
    this.kanbanOption = KANBAN_OPTIONS[0];
    this.visitedViews = { table: false, kanban: false };
    this.isCorporateUser = this.currentUserService.isCorporate;
    this.getPipelineFilterSettings();
    this.getPipelineStats();
    this.getPipelineLabelsSettings();
    this.getAdviserList();
    this.getReferrerList();

    this.childComponentEventHandler = {
      tableEventHandler: null,
      kanbanEventHandler: null,
    };

    if (
      this.$stateParams.createdContact !== '' &&
      !this.$localStorage.pipelineCardSatusName
    ) {
      this.openAddCard(this.$stateParams.createdContact);
    }

    this.probabilityList = [{ NAME: 'All' }, ...PROBABILITIES];

    this.mobileFilterTypes =
      (this.pipelineType &&
        this.pipelineType !== PIPELINE_TYPES_STR.OPPORTUNITY &&
        PIPELINE_FILTER_TYPES[this.pipelineType.toUpperCase()].map(filter => {
          const {
            FILTER_KEY: key,
            FILTER_TITLE: title,
            DEFAULT_VALUE: defaultValue,
            IS_SELECTED: isSelected,
          } = filter;
          const newFilter = { key, title, defaultValue, isSelected };
          return newFilter;
        })) ||
      [];
  }

  $onChanges(changes) {
    const { isSmallDevice } = changes;
    if (isSmallDevice && this.settings) {
      this.setMobileViewSettings();
    }
  }

  checkRoute() {
    const { pipelineType } = this.$stateParams;
    const pipelineTypes = Object.keys(PIPELINE_TYPES_STR).map(key => {
      return String(PIPELINE_TYPES_STR[key]);
    });
    const isPipelineTypeValid = pipelineTypes.indexOf(pipelineType) !== -1;
    if (!isPipelineTypeValid) {
      this.$state.go('app.dashboard');
      return;
    }
    this.pipelineType = pipelineType;

    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.APPLICATION:
        this.addCardText = 'New Loan Application';
        break;
      default:
        this.addCardText = 'Add Card';
        break;
    }
  }

  childComponentNotificationRegistration(handler, handlerType) {
    this.childComponentEventHandler[handlerType] = handler;
  }

  getStatuses() {
    if (this.statusListRadio) {
      return this.$q.resolve({ data: this.statusListRadio });
    }

    return this.pipelineService
      .SettingsStatusGet(true, true)
      .then(
        res =>
          res &&
          res.data.filter(
            status =>
              status.CategoryName ===
                PIPELINE_SETTINGS_CATEGORY[this.pipelineType.toUpperCase()] ||
              !status.PipelineCategoryID
          )
      )
      .then(statusList => {
        const statusGroup = [];
        const otherStatusGroup = [];
        statusList.forEach(status => {
          if (!status.PipelineCategoryID) {
            otherStatusGroup.push(status);
          } else {
            statusGroup.push(status);
          }
        });

        this.statusListRadio = [[...statusGroup], [...otherStatusGroup]];
        this.statusGroup = [...statusGroup];
        const substatusDropdownList = statusGroup.reduce((accum, current) => {
          const { PipelineStatus, PipelineStatusID, SubStatus } = current;
          const mappedSubStatus = SubStatus.map(o => {
            o.PipelineStatus = PipelineStatus;
            o.PipelineStatusID = PipelineStatusID;
            return o;
          });
          const subStatusList = [...mappedSubStatus];

          return accum.concat(subStatusList);
        }, []);
        const allSubStatusOption = {
          pipelineSubStatusID: 0,
          SubStatusName: 'All',
          PipelineStatus: 'All',
        };
        this.substatusDropdownList = [
          allSubStatusOption,
          ...substatusDropdownList,
        ];
        this.otherStatusGroup = [...otherStatusGroup];

        const listOfstatusIds = this.settings.statusIds
          ? this.settings.statusIds.split(',').map(id => parseInt(id, 10))
          : [];
        const listOfConversionStatusIds = this.settings.conversionStatusIDs
          ? this.settings.conversionStatusIDs
              .split(',')
              .map(id => parseInt(id, 10))
          : [];

        this.settings.pipelineStatusIDs = [];
        statusGroup &&
          statusGroup.forEach(obj => {
            if (
              listOfstatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
              this.settings.isFetchAllPipelineStatusIDs
            ) {
              this.settings.pipelineStatusIDs.push(obj);
            }
          });

        otherStatusGroup &&
          otherStatusGroup.forEach(obj => {
            if (
              listOfConversionStatusIds.indexOf(obj.PipelineStatusID) !== -1 ||
              this.settings.isFetchAllPipelineStatusIDs
            ) {
              this.settings.pipelineStatusIDs.push(obj);
            }
          });

        if (this.settings.isFetchAllPipelineStatusIDs) {
          this.settings.statusIds = statusGroup
            .map(obj => obj.PipelineStatusID)
            .join(',');
          this.settings.conversionStatusIDs = otherStatusGroup
            .map(obj => obj.PipelineStatusID)
            .join(',');
        }
      });
  }

  openAddCard(familyId = 0) {
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        this.$uibModal
          .open({
            templateUrl:
              '/assets/views/pipeline/leads/partials/add_new_card.html',
            resolve: {
              leadsStatusList: () => this.statusGroup || [],
              familyId: () => familyId,
              subtool: () => 'leads',
            },
            controller: 'OpenAddCardModalCtrl',
            size: 'modalWidthAuto',
          })
          .result.then(() => {
            const eventHandler = this.settings.isTableView
              ? 'tableEventHandler'
              : 'kanbanEventHandler';
            const handlerAction = this.settings.isTableView
              ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
              : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN;
            if (!this.childComponentEventHandler[eventHandler]) return;
            this.childComponentEventHandler[eventHandler](handlerAction, {});
          });
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.opportunityNewModalService.openNewOpportunityModal();
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.uiService.openAddNewLoanApplicationModal(true);
        break;
      default:
        break;
    }
  }

  switchViewType(viewType, isUpdateSettings = false) {
    this.settings.isTableView = viewType === PIPELINE_VIEW_TYPES.TABLE;
    if (isUpdateSettings) {
      if (this.settings.isFetchAllPipelineStatusIDs) {
        this.getStatuses().then(() => {
          this.settings.isFetchAllPipelineStatusIDs = 0;
          this.switchViewType(viewType, isUpdateSettings);
        });
        return;
      }

      this.pipelineService
        .setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings))
        .then(() => {
          this.isDesktopTableView = this.settings.isTableView;
          const isRefreshTable =
            this.settings.isTableView && this.visitedViews.table;
          const action = isRefreshTable
            ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
            : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
          this.changeSettings({ noFilterUpdate: true }, action);
        });
    } else {
      const isRefreshTable =
        this.settings.isTableView && this.visitedViews.table;
      const action = isRefreshTable
        ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
        : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
      this.changeSettings({ noFilterUpdate: true }, action);
    }
  }

  closeWorkbenchLoanInformationModal(
    resultingLoanStatusId,
    pipelineStatusId,
    loanId,
    cardDetails
  ) {
    const isConverted = loanInformationModalConvertionStatus.includes(
      parseInt(resultingLoanStatusId, 10)
    );
    const { loanScenarioID: loanScenarioId } = cardDetails;

    if (isConverted) {
      this.getPipelineStats();
      const eventHandler = this.settings.isTableView
        ? 'tableEventHandler'
        : 'kanbanEventHandler';
      const handlerAction =
        PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;

      if (!this.childComponentEventHandler[eventHandler]) return;
      this.childComponentEventHandler[eventHandler](handlerAction, {
        pipelineStatusId,
        loanId,
        loanScenarioId,
      });
      return;
    }

    this.pipelineService
      .getPipelineLoan(loanId, { isTable: this.settings.isTableView })
      .then(({ data }) => {
        const mappedCardData = this.settings.isTableView
          ? pipelineApplicationTableMap(data, this.$filter)
          : applicationCardMap(data);
        const isStatusChanged =
          cardDetails.pipelineStatusID !== mappedCardData.pipelineStatusID;
        Object.keys(mappedCardData).forEach(prop => {
          cardDetails[prop] = mappedCardData[prop];
        });

        cardDetails.pipelineStatusID = data.pipelineStatusID;

        if (!this.settings.isTableView) {
          const eventHandler = 'kanbanEventHandler';
          const handlerAction = isStatusChanged
            ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.MOVE_CARD
            : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TOTAL_AMOUNTS;

          if (!this.childComponentEventHandler[eventHandler]) return;
          this.childComponentEventHandler[eventHandler](handlerAction, {
            card: cardDetails,
            primaryKey: 'loanID',
            sourcePipelineStatusId: pipelineStatusId,
          });
        }
      });
  }

  openWorkbenchModal(cardDetails, event = null) {
    event && event.stopPropagation();
    if (!this.isSmallDevice) {
      const {
        loanID: loanId,
        pipelineStatusID: pipelineStatusId,
      } = cardDetails;

      this.$uibModal
        .open({
          templateUrl: 'assets/views/loanApplication/components/workbench.html',
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            cLoanId: () => loanId,
            CLoanStatusId: () => String(cardDetails.loanStatusID),
            CPipelineCardsID: () => cardDetails.pipelineCardsID,
            cLoanScenarioId: () => cardDetails.loanScenarioID,
            cFamilyId: () => cardDetails.familyInfo[0].familyId,
            cEnquirySource: () => cardDetails.enquirySource,
          },
          controller: 'WorkbenchModalCtrl',
          controllerAs: 'vm',
          windowTopClass: 'workbench-modal',
        })
        .result.then(
          () => {},
          ({ loanStatusId: resultingLoanStatusId }) =>
            this.closeWorkbenchLoanInformationModal(
              resultingLoanStatusId,
              pipelineStatusId,
              loanId,
              cardDetails
            )
        );
    } else {
      const params = {
        LoanID: cardDetails.loanID,
        LoanStatusID: String(cardDetails.loanStatusID),
        PipelineCardsID: cardDetails.pipelineCardsID,
        LoanScenarioID: cardDetails.loanScenarioID,
        familyId: cardDetails.familyInfo[0].familyId,
        enquirySource: cardDetails.enquirySource,
        clientNames: cardDetails.clients.trim(),
      };
      this.stateService.savePrevState(
        this.$state.$current.self,
        this.$state.$current.locals.globals.$stateParams
      );
      this.$state.go('app.mobileWorkbench', { ...params });
    }
  }

  getFamiliesPerLoan(
    cardDetails,
    key = 'loanID',
    openMenuModal = false,
    modalProps
  ) {
    const { familyInfo } = cardDetails;
    const loanId = cardDetails[key];
    const hasFamilyInfoAndOpenModal =
      familyInfo && objectLength(familyInfo) && openMenuModal && modalProps;

    if (hasFamilyInfoAndOpenModal)
      this.openThreeDotModal(cardDetails, modalProps);
    if (familyInfo && objectLength(familyInfo)) return;
    return this.contactService
      .getFamiliesPerLoan(loanId)
      .then(({ succeeded, data }) => {
        if (!succeeded || !data) return;

        const { familyInfo: families } = data;
        const clients = families.filter(
          family => family.isClient && !family.isGuarantor
        );
        const entities = families.filter(family => !family.isClient);
        const guarantors =
          this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY
            ? families.filter(family => family.isClient && family.isGuarantor)
            : [];

        cardDetails.familyInfo = [...clients, ...entities, ...guarantors];
        if (openMenuModal && modalProps)
          this.openThreeDotModal(cardDetails, modalProps);
      });
  }

  clickPipelineItem(event, cardDetails, isMenuClicked) {
    event && event.stopPropagation();
    if (this.isSmallDevice && !isMenuClicked) return;
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.OPPORTUNITY: {
        const { loanScenarioId: opportunityId, loanId } = cardDetails;
        goToOpportunity(this.$state, {
          opportunityId,
          loanId,
          sourceFamilyId: 0,
          sourceFamilyName: '',
          isContactOpener: false,
          tabName: 'details',
        });
        break;
      }
      case PIPELINE_TYPES_STR.APPLICATION: {
        if (!cardDetails.loanID) return;

        if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
          this.getFamiliesPerLoan(cardDetails).then(() => {
            this.openWorkbenchModal(cardDetails);
          });
        } else {
          this.openWorkbenchModal(cardDetails);
        }
        break;
      }
      default:
        break;
    }
  }

  deletePipelineItem(
    event,
    loanId,
    pipelineCardsId,
    successCallback,
    successCallbackParams
  ) {
    event && event.stopPropagation();
    swal(
      {
        title: 'Are you sure?',
        text: 'This record will be removed from your pipeline list',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: false,
      },
      confirm => {
        if (confirm) {
          const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
          if (isLeads && !pipelineCardsId) return;

          const service = isLeads ? 'pipelineService' : 'contactService';
          const method = isLeads ? 'PipelineCardsDelete' : 'loanDelete';
          const args = isLeads ? { cardsID: pipelineCardsId } : loanId;

          this[service][method](args)
            .then(() => {
              successCallback && successCallback(successCallbackParams);
            })
            .catch(err => {
              displayError(err);
            })
            .finally(() => {
              swal({
                title: 'Success',
                text: `${
                  isLeads ? 'Lead' : 'Loan'
                } has been been successfully deleted.`,
                type: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            });
        }
      }
    );
  }

  createNewSettings() {
    this.visitedViews.kanban = true;

    const { familyId: advisorId } = this.currentUserService;
    const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
    const isOpportunity = this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;

    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        this.settings = new PipelineLeadSettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          { pipelineService: this.pipelineService }
        );
        this.getEnquirySourceList();
        this.changeSettings(
          { noFilterUpdate: true },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
        );
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.settings = new PipelineOpportunitySettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          { pipelineService: this.pipelineService }
        );
        this.changeSettings(
          { noFilterUpdate: true },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
        );
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.settings = new PipelineApplicationSettingsService(
          {
            advisorId,
            isLeads,
            isOpportunity,
            isFetchAllPipelineStatusIDs: 1,
          },
          { pipelineService: this.pipelineService }
        );
        this.changeSettings(
          { noFilterUpdate: true },
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
        );
        break;
      default:
        break;
    }
    this.isDesktopTableView = this.settings.isTableView;
    this.setMobileViewSettings();
  }

  getPipelineFilterSettings(callback, callbackParams = {}) {
    // Send a function on callback
    const isLeads = this.pipelineType === PIPELINE_TYPES_STR.LEADS;
    const isOpportunity = this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY;
    this.pipelineService
      .getPipelineCardFilters(isLeads, isOpportunity)
      .then(res => {
        const { data } = res;
        if (!data || !data.length || !data[0] || !objectLength(data[0])) {
          this.createNewSettings();
          return;
        }

        const { isTableView } = data[0];
        this.visitedViews[isTableView ? 'table' : 'kanban'] = true;

        switch (this.pipelineType) {
          case PIPELINE_TYPES_STR.LEADS:
            this.settings = new PipelineLeadSettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              { pipelineService: this.pipelineService }
            );
            this.getEnquirySourceList();
            this.changeSettings(
              { noFilterUpdate: true },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
            );
            break;
          case PIPELINE_TYPES_STR.OPPORTUNITY:
            this.settings = new PipelineOpportunitySettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              { pipelineService: this.pipelineService }
            );
            this.changeSettings(
              { noFilterUpdate: true },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
            );
            break;
          case PIPELINE_TYPES_STR.APPLICATION:
            this.settings = new PipelineApplicationSettingsService(
              pipelineSettingsBuilderForUI({
                ...data[0],
                IsLeads: isLeads,
                IsOpportunity: isOpportunity,
              }),
              { pipelineService: this.pipelineService }
            );
            this.changeSettings(
              { noFilterUpdate: true },
              PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD
            );
            break;
          default:
            break;
        }
      })
      .then(() => {
        this.lastSortColumn = this.settings.sortColumn;
        this.isDesktopTableView = this.settings.isTableView;
        if (typeof callback === 'function') {
          callback(callbackParams);
        }
      })
      .finally(() => {
        this.setMobileViewSettings();
      });
  }

  setMobileViewSettings() {
    if (this.isSmallDevice || !this.isDesktopTableView) {
      this.switchViewType(PIPELINE_VIEW_TYPES.KANBAN);

      if (
        this.isMobileInstructionsOpened ||
        (this.videos && this.videos.length)
      )
        return;

      if (this.isSmallDevice) {
        this.generalService.getTickableItems().then(tickableItems => {
          const pipelineTickableItem =
            (tickableItems &&
              tickableItems.find(
                item => item.id === TICKABLE_ITEMS.PIPELINE_MOBILE_VIEW
              )) ||
            {};
          this.videos =
            (pipelineTickableItem.attachments &&
              pipelineTickableItem.attachments.map(attachment => {
                attachment.source = this.$sce.trustAsResourceUrl(
                  attachment.source
                );
                return attachment;
              })) ||
            [];

          if (pipelineTickableItem.isTicked) return;
          this.mobileInstructionsModal();
        });
      }
    } else {
      this.switchViewType(PIPELINE_VIEW_TYPES.TABLE);
    }
  }

  mobileInstructionsModal() {
    if (this.isMobileInstructionsOpened) return;

    const props = { videos: this.videos || [] };
    this.$uibModal
      .open({
        template: `<pipeline-mobile-demo-modal
        modal-instance="vm.modalInstance"
        videos="vm.props.videos"
      ></pipeline-mobile-demo-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => props,
        },
        size: 'sm',
        windowClass: 'pipeline-mobile-demo-modal',
        keyboard: false,
        backdrop: 'static',
      })
      .then.result(
        () => {
          this.isMobileInstructionsOpened = false;
        },
        () => {
          this.isMobileInstructionsOpened = false;
        }
      );

    this.isMobileInstructionsOpened = true;
  }

  changeStatusSettings(values) {
    const statusGroupList = [];
    const otherStatusGroupList = [];

    values &&
      values.forEach(value => {
        const isStatusGroupFound = this.statusGroup.find(
          status =>
            status.PipelineStatusID === value.PipelineStatusID &&
            status.PipelineCategoryID === value.PipelineCategoryID
        );
        if (isStatusGroupFound) statusGroupList.push(value.PipelineStatusID);

        const isOtherStatusGroupFound = this.otherStatusGroup.find(
          status =>
            status.PipelineStatusID === value.PipelineStatusID &&
            status.PipelineCategoryID === value.PipelineCategoryID
        );
        if (isOtherStatusGroupFound)
          otherStatusGroupList.push(value.PipelineStatusID);
      });

    this.changeSettings(
      {
        statusIds: statusGroupList.join(','),
        conversionStatusIDs: otherStatusGroupList.join(','),
      },
      null,
      true
    );
  }

  changeExcludeColumnsSettings(tableViewExcludedColumns) {
    const action =
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_EXCLUDE_COLUMN;
    this.changeSettings({ tableViewExcludedColumns }, action, true);
  }

  changeSettings(changes, action, triggerChildNotification = true) {
    Object.keys(changes).forEach(prop => {
      this.settings[prop] = changes[prop];
    });

    this.countMobileActiveFilters();

    (this.settings.isTableView ||
      this.pipelineType === PIPELINE_TYPES_STR.LEADS) &&
      this.getStatuses();

    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';

    const handlerAction =
      action ||
      (this.settings.isTableView
        ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
        : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN);

    if (!this.childComponentEventHandler[eventHandler]) return;

    if (this.settings.noFilterUpdate) {
      this.settings.noFilterUpdate = false;
      triggerChildNotification &&
        this.childComponentEventHandler[eventHandler](
          handlerAction,
          this.settings
        );
    } else {
      this.pipelineService
        .setPipelineCardFilters(pipelineSettingsBuilderForAPI(this.settings))
        .then(() => {
          triggerChildNotification &&
            this.childComponentEventHandler[eventHandler](
              handlerAction,
              this.settings
            );
        });
    }
  }

  refreshData() {
    this.changeSettings({ noFilterUpdate: true }, null, true);
    this.toggleShowLoader(true);
  }

  toggleShowLoader(bool) {
    this.showLoader = bool;
  }

  openModalForDemoVideo() {
    const controller =
      this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
        ? 'PipelineAppDemoVideoModalCtrl'
        : 'ContactOpenModalForDemoVideoCtrl';
    const size = 'lg';
    this.$uibModal.open({
      templateUrl: '/assets/views/partials/demo_video_modal.html',
      controller,
      size,
      windowClass: 'demo-video-modal-window-class',
    });
  }

  getPipelineStats() {
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS: {
        const fromDate = moment()
          .subtract(12, 'months')
          .format('DD MMM YYYY');
        const toDate = moment().format('DD MMM YYYY');
        this.pipelineService
          .getPipelineCardsSummary(fromDate, toDate, 0)
          .then(({ data }) => {
            const { Successful, UnSuccessfulPercent } = data;
            this.pipelineStats = {
              convertedToSettled: {
                ...pipelineStats,
                barColor: COLOR.BRIGHT_TURQUOISE_DARKER,
                textColor: COLOR.DARKER_BLUE,
                displayValue: Math.round(Successful),
                value: Successful,
              },
              convertedToNotProceeding: {
                ...pipelineStats,
                barColor: COLOR.BLUE_VIKING,
                textColor: COLOR.BLUE_VIKING,
                displayValue: Math.round(UnSuccessfulPercent),
                value: UnSuccessfulPercent,
              },
            };
          });
        break;
      }
      case PIPELINE_TYPES_STR.APPLICATION:
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.pipelineService
          .getPipelineStats(this.pipelineType)
          .then(({ data }) => {
            const { successful, unSuccessfulPercent } = data;
            this.pipelineStats = {
              convertedToSettled: {
                ...pipelineStats,
                barColor: COLOR.BRIGHT_TURQUOISE_DARKER,
                textColor: COLOR.DARKER_BLUE,
                displayValue: Math.round(successful),
                value: successful,
              },
              convertedToNotProceeding: {
                ...pipelineStats,
                barColor: COLOR.BLUE_VIKING,
                textColor: COLOR.BLUE_VIKING,
                displayValue: Math.round(unSuccessfulPercent),
                value: unSuccessfulPercent,
              },
            };
          });
        break;
      default:
        break;
    }
  }

  getPipelineLabelsSettings(refreshParams = null) {
    this.pipelineService.PipelineSettingsLabel().then(({ data }) => {
      this.labelsSettings = collectionCamelizeBuilderForUI(data);
      this.threeDotModalNotificator('refresh-labels');
      refreshParams &&
        refreshParams.updatedLabel &&
        refreshParams.updatedLabel.labelId &&
        this.refreshCardLabels(refreshParams);
    });
  }

  refreshCardLabels(refreshParams) {
    const { updatedLabel, action } = refreshParams;
    if (!updatedLabel || !action || action === 'add') return;
    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';
    const handlerAction =
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_LABELS;

    if (!this.childComponentEventHandler[eventHandler]) return;
    this.childComponentEventHandler[eventHandler](handlerAction, refreshParams);
  }

  refreshView() {
    const eventHandler = this.settings.isTableView
      ? 'tableEventHandler'
      : 'kanbanEventHandler';
    const handlerAction = this.settings.isTableView
      ? PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TABLE
      : PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN;
    if (!this.childComponentEventHandler[eventHandler]) return;
    this.childComponentEventHandler[eventHandler](handlerAction);
  }

  getAdviserList() {
    this.pipelineSharedData.getAdvisersListData().then(advisersList => {
      this.advisersList = advisersList;
    });
  }

  getEnquirySourceList() {
    this.optionsService.getEnquirySourceList().then(({ data }) => {
      const isValidResponse = data && data.length;
      if (!isValidResponse) {
        this.enquirySourceList = [];
        return;
      }
      const enquirySourceList = this.enquirySourceService.getEnquirySourceListData(
        data
      );
      this.enquirySourceList = [
        {
          ReferralItemId: 0,
          ReferralItemName: 'All',
          ReferralCategoryId: 0,
          ReferralCategoryName: '',
        },
        ...enquirySourceList,
      ];

      const findEnquiryObject = this.enquirySourceList.find(
        item =>
          item.ReferralItemId === this.settings.referralItemID &&
          item.ReferralCategoryId === this.settings.referralCategoryID
      );
      if (findEnquiryObject)
        this.settings.enquiryObject = { ...findEnquiryObject };
      this.countMobileActiveFilters();
    });
  }

  getReferrerList() {
    this.optionsService.getReferencedReferralList().then(({ data }) => {
      const referrerList =
        data.map(o => {
          o.ReferralMemberId = o.ReferralMemberId.toString();
          o.ReferralOrganizationName +=
            (o.ReferralOrganizat0ionName !== '' ? ' - ' : '') +
            o.ReferralMemberName;
          return o;
        }) || [];
      this.referrerList = [
        {
          ReferralMemberId: 'All',
          ReferralOrganizationName: 'All',
        },
        ...referrerList,
      ];
    });
  }

  showFilters() {
    this.isShowFilters = !this.isShowFilters;
  }

  redirectLoanApp(cardDetails) {
    const { loanScenarioID: loanAppId } = cardDetails;
    const familyId = cardDetails.familyInfo[0].familyId;
    this.$state.go('app.loanApplicationDetailsV3', { familyId, loanAppId });
  }

  openLoanInformation(cardDetails) {
    const { loanID: loanId, pipelineStatusID: pipelineStatusId } = cardDetails;
    const familyId = cardDetails.familyInfo[0].familyId;

    const params = {
      loanInformationAction: 'edit',
      familyId: parseInt(familyId, 10),
      loanId,
    };

    // @TODO refresh the card or row detail with the updated one
    this.$uibModal
      .open({
        templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
        controller: 'LoanInformationCtrl',
        size: 'wide',
        resolve: {
          params: () => params,
          loanType: () => 'npw',
        },
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(({ loanStatusId: resultingLoanStatusId }) =>
        this.closeWorkbenchLoanInformationModal(
          resultingLoanStatusId,
          pipelineStatusId,
          loanId,
          cardDetails
        )
      );
  }

  viewLoanInformation(event, cardDetails) {
    event && event.stopPropagation();
    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.openLoanInformation(cardDetails);
      });
    } else {
      this.openLoanInformation(cardDetails);
    }
  }

  viewLoanApp(event, cardDetails) {
    event && event.stopPropagation();

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.redirectLoanApp(cardDetails);
      });
    } else {
      this.redirectLoanApp(cardDetails);
    }
  }

  viewBorrower(event, family) {
    event && event.stopPropagation();

    const { familyId, isClient } = family;
    if (isClient) {
      this.$state.go('app.contactsSingle', { familyId, activeTab: 'lending' });
    } else {
      this.$state.go('app.BusinessAccount', { familyId, activeTab: 'loans' });
    }
  }

  viewSubmission(event, cardDetails) {
    event && event.stopPropagation();

    const { loanScenarioID: LoanScenarioID, loanID: LoanID } = cardDetails;

    this.nextGenLoanAppService
      .nextGenAOLView({ LoanScenarioID, LoanID })
      .then(({ data }) => {
        const nextGenObj = data;
        nextGenObj.https = this.$sce.trustAsResourceUrl(nextGenObj.https);
        const guid = nextGenObj.GUID || '';
        const startPage = nextGenObj.StartPage || '';
        const applicationViewURL = `${
          nextGenObj.https
        }?GUID=${guid}&StartPage=${startPage}`;
        this.$window.open(applicationViewURL);
      });
  }

  duplicateLoan(cardDetails) {
    const familyId = cardDetails.familyInfo[0].familyId;
    const { loanScenarioID } = cardDetails;
    this.loanApplicationServices.cloneLoan(loanScenarioID, familyId).then(
      ({ data: loanAppId }) => {
        cardDetails.loansSectionLoadingStatus = false;
        this.$state.go('app.loanApplicationDetailsV3', { familyId, loanAppId });
      },
      () => {
        cardDetails.loansSectionLoadingStatus = false;
      }
    );
  }

  onDuplicateLoan(event, cardDetails) {
    event && event.stopPropagation();

    if (!cardDetails.familyInfo || !objectLength(cardDetails.familyInfo)) {
      this.getFamiliesPerLoan(cardDetails).then(() => {
        this.duplicateLoan(cardDetails);
      });
    } else {
      this.duplicateLoan(cardDetails);
    }
  }

  changeProbability({ probability, card }) {
    card.probability = probability;

    const args = {
      cardID: card.pipelineCardsID || card.pipelineCardsId,
      probability,
    };
    this.pipelineService.SetPipelineCardsProbability(args).then(({ data }) => {
      if (data) {
        toastr.success('Probability Successfully Saved!', 'Success');
      }
    });
  }

  changeSubStatus({ subStatus, card }) {
    card.pipelineStatusID = subStatus.StatusID;
    card.subStatusID = subStatus.pipelineSubStatusID;
    card.subStatusName = subStatus.SubStatusName;
    const args = {
      CardsID: card.pipelineCardsID,
      SubStatusID: subStatus.pipelineSubStatusID,
    };
    this.pipelineService.CardsSubStatusSet(args).then(({ data }) => {
      if (data) {
        toastr.success('Substatus Successfully Saved!', 'Success');
      }
    });
  }

  openThreeDotModal(card, props) {
    if (!card || !props || this.isThreeDotModalOpen) return;

    props.card = card;
    props.labelsSettings = this.labelsSettings;
    props.childComponentNotificationRegistration = this.childComponentNotificationRegistration;

    this.isThreeDotModalOpen = true;
    this.$uibModal
      .open({
        template: `
        <three-dot-modal
          modal-instance="vm.modalInstance"
          card="vm.props.card"
          menu-type="vm.props.menuType"
          props="vm.props"
          parent-to-child-notification-registration="vm.props.childComponentNotificationRegistration(handler, 'threeDotModalEventHandler')"
        >
        </three-dot-modal>
      `,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        size: 'sm',
        windowClass: 'threedot-modal-window',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          props: () => props,
        },
      })
      .result.then(
        () => {
          this.isThreeDotModalOpen = false;
        },
        () => {
          this.isThreeDotModalOpen = false;
        }
      );
  }

  threeDotModalNotificator(action) {
    if (action === 'refresh-labels') {
      if (
        this.childComponentEventHandler &&
        this.childComponentEventHandler.threeDotModalEventHandler
      ) {
        this.childComponentEventHandler.threeDotModalEventHandler(
          'refresh-labels-settings',
          this.labelsSettings
        );
      }
    }
  }

  openMobileFilter() {
    const props = {
      pipelineType: this.pipelineType,
      pipelineTypes: PIPELINE_TYPES_STR,
      settings: this.settings,
      substatusList: this.substatusDropdownList,
      advisersList: this.advisersList,
      labelsList: this.labelsSettings,
      probabilityList: this.probabilityList,
      enquirySourceList: this.enquirySourceList,
      referrerList: this.referrerList,
      isSmallDevice: this.isSmallDevice,
      changeSettings: this.changeSettings,
      isCorporateUser: this.isCorporateUser,
    };

    this.$uibModal
      .open({
        template: `<pipeline-filters-mobile-modal
        modal-instance="vm.modalInstance"
        pipeline-type="vm.props.pipelineType"
        pipeline-types="vm.props.pipelineTypes"
        is-corporate-user="vm.props.isCorporateUser"
        settings="vm.props.settings"
        is-small-device="vm.props.isSmallDevice"
        substatus-list="vm.props.substatusList"
        advisers-list="vm.props.advisersList"
        labels-list="vm.props.labelsList"
        probability-list="vm.props.probabilityList"
        enquiry-source-list="vm.props.enquirySourceList"
        referrer-list="vm.props.referrerList"
        on-change-settings="vm.props.changeSettings($e, null, triggerChildNotification)"
      ></pipeline-filters-mobile-modal`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        style: 'sm',
        windowClass: 'pipeline-filters-mobile-modal',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          props: () => props,
        },
      })
      .result.then(response => {
        if (!response || !response.changes) return;

        const { changes } = response;

        if (this.pipelineType === PIPELINE_TYPES_STR.LEADS) {
          const findEnquiryObject = this.enquirySourceList.find(
            item =>
              item.ReferralItemId === changes.referralItemID &&
              item.ReferralCategoryId === changes.referralCategoryID
          );
          if (findEnquiryObject)
            this.settings.enquiryObject = { ...findEnquiryObject };

          this.settings.dateCreatedStart = changes.dateCreatedStart;
          this.settings.dateCreatedEnd = changes.dateCreatedEnd;
          this.settings.displayFilterDate = this.settings.formatCreatedDateFilter();
        }

        this.changeSettings(
          changes,
          PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN
        );
      });
  }

  countMobileActiveFilters() {
    if (this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY) return;

    const activeFilters =
      this.mobileFilterTypes.filter(filter => {
        const { key } = filter;
        const value = this.settings[key];
        const testNumber = typeof value === 'number' && value > 0;
        const testString =
          typeof value === 'string' &&
          value.length &&
          value.toLowerCase() !== 'all' &&
          value.toLowerCase() !== 'none';
        const testEnquiry =
          key === 'enquiryObject' &&
          this.settings.enquiryObject &&
          this.settings.enquiryObject.ReferralCategoryId !== 0 &&
          this.settings.enquiryObject.ReferralItemId !== 0;
        return testNumber || testString || testEnquiry;
      }) || [];

    this.activeFiltersLength = activeFilters.length;
  }
}
