import template from './readonlyFieldTemplate.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    fieldClass: '@',
    isFieldShow: '<',
    fieldValue: '<',
    textClass: '@',
  },
};
