export default class MergeFieldOptionCtrl {
  $onInit() {
    if (!this.formatOptionDisplay)
      console.error('Missing format-option-display for merge-field-option');
  }

  onOptionSelected(option) {
    this.selectedValue = option;
    this.selectedValueDisplay = this.formatOptionDisplay({ option });
    if (this.onSelect) this.onSelect({ option });
  }
}
