export const ADVISER_TAB = {
  ADVISER_DETAILS: 0,
  BUSINESS_DETAILS: 1,
  MARKETING: 2,
  SUPPORT_SERVICES: 3,
  SUBSCRIPTIONS: 4,
  AGREEMENTS: 5,
  ACCREDITATION: 6,
  COMMUNICATIONS: 7,
  NOTES: 8,
  DOCUMENTS: 9,
};

export const ADVISER_ORG_TAB = {
  BUSINESS_DETAILS: 0,
  CONTACT: 1,
  AGREEMENTS: 2,
  MARKETING: 3,
  MEMBERS: 4,
  NOTES: 5,
  DOCUMENTS: 6,
};
