export function accessControlMatrixesForUI(field) {
  return {
    corporateTeam: field.CorporateTeam,
    pageId: field.PageId,
    pageName: field.PageName,
    tabId: field.TabId,
    tabName: field.TabName,
    subTabId: field.SubTabId,
    subTabName: field.SubTabName,
    panelId: field.PanelId,
    panelName: field.PanelName,
    fieldId: field.FieldId,
    fieldName: field.FieldName,
    allowedActionId: field.AllowedActionId,
    allowedAction: field.AllowedAction,
  };
}
