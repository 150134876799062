import template from './insuranceCardItemView.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    type: '@',
    itemId: '<',
    itemDateCreated: '<',
    benefitList: '<',
    onClick: '&',
    onDelete: '&',
  },
};
