import template from './onboardingTracker.html';
import OnboardingTrackerCtrl from './onboardingTrackerCtrl';

export default {
  template,
  bindings: {
    activeTab: '<',
    tabs: '<',
    titleTracker: '@',
    panelColor: '@',
    options: '<',
  },
  controller: OnboardingTrackerCtrl,
  controllerAs: 'vm',
};
