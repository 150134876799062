export function jasperReportBuilderForUI(report) {
  return {
    reportTypeSettingsId: report.ReportTypeSettingsId,
    reportTypeId: report.ReportTypeId,
    reportTypeDesc: report.ReportTypeDesc,
    isEnabled: report.IsEnabled,
    canCorpAccess: report.CanCorpAccess,
    canAdviserAccess: report.CanAdviserAccess,
    otherSettings: report.OtherSettings,
    corpUserUrl: report.CorpUserURL,
    adviserUserUrl: report.AdviserUserURL,
    principalAdviserUrl: report.PrincipalAdviserURL,
    assistantAdviserUrl: report.AssistantAdviserURL,
    accessTypes: report.AccessTypes ? report.AccessTypes.split(',') : [],
    filters: report.Filters ? report.Filters.split(',') : [],
  };
}

export function dateRangeBuilderForUI(dateRange) {
  return {
    dateRangeId: dateRange.DateRangeId,
    dateRangeDesc: dateRange.DateRangeDesc,
  };
}

export function referrerOrgBuilderForUI(referrerOrg) {
  return {
    referrerOrgId: referrerOrg.ReferrerOrgId,
    referrerOrgName: referrerOrg.ReferrerOrgName,
    agreementTypeID: referrerOrg.AgreementTypeID,
    agreementType: referrerOrg.AgreementType,
    hasRWName: referrerOrg.hasRWName,
  };
}

export function referrerBuilderForUI(referrer) {
  return {
    referrerId: referrer.ReferrerId,
    referrerName: referrer.ReferrerName,
    agreementTypeID: referrer.AgreementTypeID,
    agreementType: referrer.AgreementType,
    hasRWName: referrer.hasRWName,
  };
}

export function pipelineStatusBuilderForUI(status) {
  return {
    statusId: status.PipelineStatusID,
    statusName: status.PipelineStatusName,
    ticked: false,
  };
}

export function loanStatusBuilderForUI(status) {
  return {
    loanStatusId: status.LoanStatusID,
    loanDisplayName: status.LoanDisplayName,
    ticked: false,
  };
}
