import template from './lmiSecurities.html';
import lmiSecuritiesCtrl from './lmiSecuritiesCtrl';
import './style.scss';

export default {
  template,
  bindings: {
    mortgageObj: '=',
    brokerEventId: '<',
    addSecurityEnabled: '=',
  },
  controller: lmiSecuritiesCtrl,
  controllerAs: 'vm',
};
