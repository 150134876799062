import template from './pipelineApplicationFilter.html';

export default {
  template,
  bindings: {
    pipelineType: '<',
    pipelineTypes: '<',
    isCorporateUser: '<',
    settings: '<',
    statusListRadio: '<',
    advisersList: '<',
    filterColumns: '<',
    onHideShowColumn: '&',
    labelsList: '<',
    onChangeSettings: '&',
    onChangeStatusSettings: '&',
  },
};
