class MycrmSliderCtrl {
  constructor(corporateService) {
    'ngInject';

    this.corporateService = corporateService;
  }

  $onChanges(changes) {
    if (!changes) return;
    this.leadProfileObj =
      changes.leadProfileDetails && changes.leadProfileDetails.currentValue
        ? changes.leadProfileDetails.currentValue
        : this.leadProfileDetails;
    this.leadAllocatedPerMonth =
      changes.leadProfileDetails && changes.leadProfileDetails.currentValue
        ? Number(changes.leadProfileDetails.currentValue.AllocationCapPerMonth)
        : 0;
  }

  $onInit() {
    this.steps = Array(Number(this.maxDimension)).fill('&middot;');
    this.lastIndicator = this.steps.length - 1;
    this.options = {
      from: this.minDimension,
      to: this.maxDimension,
      step: 1,
      dimension: '',
      className: 'clip-slider',
      css: {
        background: {
          'background-color': '#cdc4d2',
        },
        range: {
          'background-color': '#f99c5d',
        },
      },
    };
  }

  setAllocationPerMonth(value) {
    this.leadProfileObj.AllocationCapPerMonth = Number(value);
    this.leadAllocatedPerMonth = Number(value);
    this.corporateService.leadsProfileSet(
      this.mainFamilyId,
      0,
      this.leadProfileObj
    );
  }
}
export default MycrmSliderCtrl;
