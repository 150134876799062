import angular from 'angular';
import _ from 'lodash';
import { displayError } from 'Common/utilities/alert';

angular
  .module('app')
  .directive('upcomingBirthdays', function upcomingBirthdays(
    $timeout,
    overviewDashboardService,
    overviewDashboardSharedService,
    userService,
    corporateService,
    $state,
    queryService,
    eventService,
    corporateWidgetDefaultService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: { myParam: '=' },
      templateUrl:
        'assets/views/overviewDashboard/directiveTemplates/upcomingBirthdays.html',

      link(scope) {
        scope.widgetModel = {};
        scope.widgetModel.reportingStateList = [];
        scope.widgetModel.reportingStateId = 0;
        scope.widgetModel.isCorporateUser = false;
        scope.widgetModel.corporateSetting = corporateWidgetDefaultService.getDefaultTimePeriod();

        function isCorporateUser() {
          userService.GetUserInfo().then(response => {
            if (response && response.data) {
              if (parseInt(response.data.AccessType, 10) === 5) {
                scope.widgetModel.isCorporateUser = true;
              }
            }
          });
        }
        isCorporateUser();

        function getReportingState() {
          scope.widgetModel.reportingStateList = [];
          corporateService.reportingStatesGet().then(response => {
            response.data.unshift({
              ReportingStateId: 0,
              ReportingStateName: 'All Reporting State',
            });
            scope.widgetModel.reportingStateList = response.data;
          });
        }
        getReportingState();

        function UpcomingBirthdayTableGet() {
          scope.dataLoaded = false;
          scope.showSlick = false;
          overviewDashboardService
            .UpcomingBirthdayTableGet(
              scope.myParam.Widget_UserID,
              scope.widgetModel.reportingStateId
            )
            .then(
              response => {
                scope.dataLoaded = false;
                scope.showSlick = false;
                scope.upcomingBirthdayTableData = [];
                scope.upcomingBirthdayTableData = queryService.queryPerPage(
                  5,
                  response.data
                );

                if (scope.upcomingBirthdayTableData.length > 0) {
                  $timeout(() => {
                    scope.dataLoaded = true;
                    scope.showSlick = true;
                  }, 1000);

                  $timeout(() => {
                    try {
                      scope.slickConfig.method.slickGoTo(0);
                    } catch (error) {
                      // Continue regardless of error
                    }
                  }, 1500);
                }
              },
              () => {}
            );
        }

        function assignState(value) {
          if (value) {
            const temp = _.filter(
              scope.widgetModel.reportingStateList,
              data => {
                return (
                  parseInt(data.ReportingStateId, 10) === parseInt(value, 10)
                );
              }
            );
            if (temp.length > 0) {
              scope.widgetModel.reportingStateId = parseInt(value, 10);
            }
            UpcomingBirthdayTableGet();
          } else {
            UpcomingBirthdayTableGet();
          }
        }

        function widgetOptionGet() {
          overviewDashboardService
            .WidgetOptionGet(scope.myParam.Widget_UserID)
            .then(
              response => {
                scope.WidgetOptionGetData = response.data;
                scope.WidgetOptionGetAdviser = {};
                scope.Advisers = [];
                let Flag;

                for (let i = 0; i < scope.WidgetOptionGetData.length; i++) {
                  if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 3
                  ) {
                    Flag = 1;
                    scope.switchsetting = 'Yes';
                    scope.Advisers =
                      scope.WidgetOptionGetData[i].SelectedUerValues;
                    const AdvisersArray = scope.Advisers.split(',');

                    scope.upcomingBirthdaysMultiAdviser = scope.upcomingBirthdaysMultiAdviser.map(
                      obj => {
                        if (AdvisersArray.indexOf(obj.FamilyId) > -1) {
                          obj.ticked = true;
                          return obj;
                        }
                        return obj;
                      }
                    );
                  } else if (
                    parseInt(
                      scope.WidgetOptionGetData[i].WidgetOptionId,
                      10
                    ) === 4
                  ) {
                    scope.widgetModel.corporateSetting =
                      scope.WidgetOptionGetData[i];
                    if (scope.widgetModel.isCorporateUser) {
                      assignState(
                        scope.WidgetOptionGetData[i].SelectedUerValues
                      );
                    }

                    const widgetOptionValue =
                      scope.WidgetOptionGetData[i].Widget_OptionValue;
                    scope.filteredUpcomingBirthdayssDropDownList = scope.upcomingBirthdayDropDownList.filter(
                      item =>
                        parseInt(item.OptionValueId, 10) ===
                        parseInt(widgetOptionValue, 10)
                    );

                    scope.upcomingBirthdayDropDowns =
                      scope.filteredUpcomingBirthdayssDropDownList[0];
                  } else if (
                    scope.WidgetOptionGetData[i].OptionName !== 'Adviser' &&
                    scope.switchsetting !== 'Yes' &&
                    Flag !== 1
                  ) {
                    scope.switchsetting = 'No';
                  }
                }

                if (!scope.widgetModel.isCorporateUser) {
                  UpcomingBirthdayTableGet();
                }
              },
              () => {}
            );
        }

        function saveForTimePeriod(widgetSetObj) {
          scope.OptionToSet = widgetSetObj;
          overviewDashboardService
            .WidgetOptionSet(scope.OptionToSet)
            .then(() => {
              widgetOptionGet();
            })
            .catch(displayError);
        }

        scope.changeReportingState = function(id) {
          scope.widgetModel.corporateSetting.SelectedUerValues = id;
          scope.widgetModel.corporateSetting.Widget_UserID =
            scope.myParam.Widget_UserID;
          saveForTimePeriod(scope.widgetModel.corporateSetting);
        };

        scope.tableMode = true;
        scope.interval = 'allDates';
        scope.slickConfig = { method: {} };
        scope.showSlick = false;
        scope.upcomingBirthdayTableData = [];
        scope.upcomingBirthdayDropDownList = [];
        scope.upcomingBirthdayDropDowns = {};
        scope.WidgetOptionAdviserList = [];
        scope.widgetOptionAdviser = {};
        scope.upcomingBirthdaysMultiAdviser = [];
        scope.upcomingBirthdaysMultiAdviserObj = {};
        scope.WidgetOptionGetData = [];
        scope.filteredUpcomingBirthdayssDropDownList = [];
        scope.outp = [];
        scope.switchsetting = {};

        scope.makeSelectNone = function() {
          for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
            scope.WidgetOptionAdviserList[i].ticked = false;
            scope.upcomingBirthdaysMultiAdviser.push(
              scope.WidgetOptionAdviserList[i]
            );
          }
          scope.setIsFiltered.Widget_OptionValue = '';
          scope.setIsFiltered.WidgetOptionId = 3;
          scope.setIsFiltered.Widget_UserID = scope.myParam.Widget_UserID;
          scope.setIsFiltered.SelectedUser = [];
          saveForTimePeriod(scope.setIsFiltered);
        };
        function WidgetOptionTimePeriodListv2Get() {
          overviewDashboardService.WidgetOptionTimePeriodListv2Get().then(
            response => {
              scope.upcomingBirthdayDropDownList = response.data;

              widgetOptionGet();
            },
            () => {}
          );
        }
        function WidgetOptionAdviserListGet() {
          overviewDashboardService.WidgetOptionAdviserListGet().then(
            response => {
              scope.WidgetOptionAdviserList = response.data;
              for (let i = 0; i < scope.WidgetOptionAdviserList.length; i++) {
                scope.WidgetOptionAdviserList[i].ticked = false;
                scope.upcomingBirthdaysMultiAdviser.push(
                  scope.WidgetOptionAdviserList[i]
                );
              }
              widgetOptionGet();
            },
            () => {}
          );
        }

        scope.updateMultiselectFilterData = function(clickedItem) {
          let dataToSend = [];
          scope.MultiOptionSet = {};
          scope.MultiOptionSetForAll = {};
          const itemData = _.find(scope.upcomingBirthdaysMultiAdviser, [
            'ticked',
            true,
          ]);
          if (_.isUndefined(itemData)) {
            _.map(scope.upcomingBirthdaysMultiAdviser, item => {
              if (item.FamilyName === clickedItem.FamilyName) {
                item.ticked = true;
              }
            });
          }
          if (clickedItem.FamilyName !== 'All') {
            if (_.size(scope.upcomingBirthdaysMultiAdviser) !== 0) {
              scope.upcomingBirthdaysMultiAdviser[0].ticked = false;
            }

            dataToSend = scope.upcomingBirthdaysMultiAdviser
              .filter(item => item.FamilyName !== 'All' && item.ticked === true)
              .map(obj => {
                return { PersonId: obj.FamilyId };
              });

            scope.MultiOptionSet.SelectedUser = dataToSend;
            scope.MultiOptionSet.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSet.Widget_UserID = scope.myParam.Widget_UserID;
            scope.MultiOptionSet.Widget_OptionValue =
              scope.WidgetOptionAdviserList[1].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSet);
          } else {
            const all = scope.outp.filter(item => item.FamilyName === 'All');
            if (all.length > 0 && scope.outp.length > 1) {
              scope.upcomingBirthdaysMultiAdviser = scope.upcomingBirthdaysMultiAdviser.map(
                obj => {
                  if (obj.FamilyName === 'All') return obj;

                  obj.ticked = false;
                  return obj;
                }
              );
            }
            dataToSend.push({
              PersonId: scope.upcomingBirthdaysMultiAdviser[0].FamilyId,
            });

            scope.MultiOptionSetForAll.SelectedUser = dataToSend;
            scope.MultiOptionSetForAll.WidgetOptionId =
              scope.WidgetOptionAdviserList[0].OptionId;
            scope.MultiOptionSetForAll.Widget_UserID =
              scope.myParam.Widget_UserID;
            scope.MultiOptionSetForAll.Widget_OptionValue =
              scope.WidgetOptionAdviserList[0].OptionValueId;

            saveForTimePeriod(scope.MultiOptionSetForAll);
          }
        };

        scope.updateTimePeriod = function(upcomingBirthdayDropDowns) {
          scope.upcomingBirthdayDropDownsObj = upcomingBirthdayDropDowns;
          scope.upcomingBirthdayDropDownsObjToSet = {};

          scope.upcomingBirthdayDropDownsObjToSet.Widget_UserID =
            scope.myParam.Widget_UserID;
          scope.upcomingBirthdayDropDownsObjToSet.Widget_OptionValue =
            scope.upcomingBirthdayDropDownsObj.OptionValueId;
          scope.upcomingBirthdayDropDownsObjToSet.WidgetOptionId =
            scope.upcomingBirthdayDropDownsObj.OptionId;
          scope.upcomingBirthdayDropDownsObjToSet.SelectedUser =
            scope.widgetModel.reportingStateId;
          saveForTimePeriod(scope.upcomingBirthdayDropDownsObjToSet);
        };

        scope.isFiltered = function(filteredOption) {
          scope.val = filteredOption;
          scope.setIsFiltered = {};

          if (scope.val === 'No') {
            scope.makeSelectNone();
          } else {
            scope.switchsetting = 'Yes';
          }
        };

        function init() {
          WidgetOptionTimePeriodListv2Get();
          WidgetOptionAdviserListGet();
        }

        scope.dynamicPopover = {
          content: ' ',
          templateUrl: 'upcomingBirthdaysPopover.html',
          title: 'Title',
        };

        scope.doAction = function(msg) {
          overviewDashboardSharedService.doActionShared(msg, scope);
        };

        scope.events = eventService.getEventsValue();

        scope.calendarView = 'month';
        scope.calendarDate = new Date();

        scope.startOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        scope.endOptions = {
          showWeeks: false,
          startingDay: 1,
          minDate: scope.minDate,
          maxDate: scope.maxDate,
        };

        init();

        scope.goToContact = function(familyId) {
          if (scope.widgetModel.isCorporateUser) {
            corporateService.corporateContactGet(0, familyId).then(response => {
              $state.go('app.contactsAdviser', {
                clientId: response.data.ClientID,
                familyId,
              });
            });
          } else {
            $state.go('app.contactsSingle', { familyId });
          }
        };
      },
    };
  });
