class LoadAllocationModalService {
  constructor($uibModal) {
    'ngInject';

    this.$uibModal = $uibModal;
  }
  launchBrokerAllocationModal(showAllocationButton, postalCode) {
    const size = 'wide';
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/contacts/partials/brokerAllocationToolModal.html',
      controller: 'BrokerAllocationToolModalCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      size,
      windowClass: '',
      resolve: {
        postalCode: () => postalCode,
        showAllocationButton: () => showAllocationButton,
      },
    });
    return modalInstance;
  }
}

export default LoadAllocationModalService;
