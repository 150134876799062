import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { isFunction } from 'lodash';

class AddressSelectorCtrl {
  constructor(timeoutService, generalService, $timeout) {
    'ngInject';

    this.timeoutService = timeoutService;
    this.generalService = generalService;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.address = {};
  }

  $onDestroy() {
    this.timeoutService.cancelTimeouts(WATCHER_CONTEXT.ADDRESS_SELECTOR_CTRL);
  }

  hasSuggestedAddresses() {
    return this.suggestedAddresses && this.suggestedAddresses.length;
  }

  getSuggestedAddresses() {
    const { formatted_address: formattedAddress } = this.address;
    const isSearcheableAddress = formattedAddress && formattedAddress.length;
    if (!isSearcheableAddress || formattedAddress === this.previousSearchKey)
      return;

    if (this.searchTimeout) {
      this.$timeout.cancel(this.searchTimeout);
    }

    this.searchTimeout = this.$timeout(() => {
      this.previousSearchKey = formattedAddress;
      this.generalService.placeSearch(formattedAddress).then(
        response => {
          this.suggestedAddresses =
            response &&
            response.data.filter(
              address => address.geoCoded || !this.excludeNonGeoCoded
            );
        },
        () => {
          this.suggestedAddresses = null;
        }
      );
    }, 300);

    this.timeoutService.addTimeout(
      this.searchTimeout,
      WATCHER_CONTEXT.ADDRESS_SELECTOR_CTRL
    );
  }

  closeSuggestions() {
    this.suggestedAddresses = null;
  }

  selectAddress(address) {
    if (this.isAddressSelected(address)) return;

    this.selectedAddress = address;
    this.address = address;
    if (isFunction(this.onAddressSelected)) {
      this.onAddressSelected({ address });
      this.closeSuggestions();
    }
  }

  isAddressSelected(address) {
    const selectedAddressFormatted =
      this.selectedAddress && this.selectedAddress.formatted_address;
    const formattedAddress = address && address.formatted_address;
    return selectedAddressFormatted === formattedAddress;
  }
}

export default AddressSelectorCtrl;
