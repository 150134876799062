import { getToken, removeToken, setToken } from 'Common/utilities/token';
import { MYCRM_GLOBAL } from 'Common/constants/mycrmGlobal';
import { constructConfigRequest } from 'Common/utilities/request';
import swal from 'sweetalert';

app.factory('sessionService', function(
  $timeout,
  $interval,
  $window,
  configService,
  $http,
  Idle,
  Keepalive,
  $q
) {
  const { COMMON_TASK_SCHEDULE } = MYCRM_GLOBAL;
  const idleWatcher = $interval(
    () => !Idle.running() && Idle.watch(),
    COMMON_TASK_SCHEDULE
  );
  const tokenValidatorWatcher = $interval(
    () => !getToken() && vm.logoutUser(),
    COMMON_TASK_SCHEDULE
  );
  let vm = {};
  let sessionUpdate;
  let runningSession;
  let isRunningSession;

  vm.sessionTime = null;
  vm.autoLogout = null;
  vm.resetSessionTime = function(isSessionAlreadyUpdated) {
    vm.sessionUpdateTimer(false, isSessionAlreadyUpdated);
  };

  vm.sessionExpired = function() {
    swal({
      type: 'error',
      title: 'Session Expired',
      text: 'You will be redirected automatically to the login page.',
      showConfirmButton: false,
      timer: 2000,
    });
    $timeout(() => {
      window.location.assign(configService.login);
    }, 2000);
  };

  vm.logoutUser = function(silent) {
    removeToken();
    $interval.cancel(idleWatcher);
    $interval.cancel(tokenValidatorWatcher);
    Idle.unwatch();
    Keepalive.stop();
    $timeout(() => {
      if (configService.login) {
        $http
          .post(
            `${configService.resource}/Logout`,
            null,
            constructConfigRequest()
          )
          .then(
            () => {
              window.location.assign(configService.login);
            },
            () => {
              vm.sessionExpired();
            }
          );
      } else if (!silent) {
        vm.sessionExpired();
      }
    }, 1000);
  };

  vm.keepAlive = function() {
    $timeout.cancel(sessionUpdate);
    if (!getToken()) return vm.logoutUser();
    if (!isRunningSession) {
      isRunningSession = true;
      runningSession = $timeout(() => {
        isRunningSession = false;
        vm.updateSession();
        $timeout.cancel(runningSession);
      }, vm.sessionTime);
    }
  };

  vm.checkJwt = function(token, jwttype) {
    let defer = $q.defer();
    if (!token || !jwttype) return false;
    const requestConfig = {
      url: `${configService.resource}/CheckJWT?jwttype=${jwttype}`,
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    $http(constructConfigRequest(requestConfig)).then(
      response => {
        return defer.resolve(response);
      },
      err => {
        return defer.reject(err);
      }
    );
    return defer.promise;
  };

  vm.updateSession = function() {
    if (!getToken()) return;
    const requestConfig = {
      method: 'POST',
      url: `${configService.resource}/UpdateSession`,
      headers: {
        Authorization: getToken(),
      },
    };
    $http(constructConfigRequest(requestConfig)).then(
      response => {
        if (response.data) {
          setToken(`Bearer ${response.data}`);
          configService.token = `Bearer ${response.data}`;
          $http.defaults.headers.common.Authorization = configService.token;
        }
      },
      () => {
        vm.sessionExpired();
      }
    );
  };

  // will run every 30 minutes to check for the session of the user
  vm.sessionUpdateTimer = function(isSessionUpdate, isSessionAlreadyUpdated) {
    vm.keepAlive();
    if (isSessionUpdate) {
      if (
        typeof isSessionAlreadyUpdated === 'undefined' ||
        !isSessionAlreadyUpdated
      ) {
        vm.updateSession();
      }
      $timeout.cancel(runningSession);
      isRunningSession = false;
    }
    sessionUpdate = $timeout(() => {
      vm.keepAlive();
    }, vm.sessionTime);
  };
  return vm;
});
