import template from './opportunityDocuments.html';
import controller from './opportunityDocumentsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    opportunityId: '<',
  },
};
