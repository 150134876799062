import template from './applicationMenu.html';

export default {
  template,
  bindings: {
    card: '<',
    isCorporateUser: '<',
    labelsSettings: '<',
    onRefreshLabelsSettings: '&',
    onCardClick: '&',
    onViewBorrower: '&',
    onViewLoanApp: '&',
    onDuplicateLoan: '&',
    onViewSubmission: '&',
    onViewLoanInformation: '&',
    onDeletePipelineItem: '&',
    onOpenWorkbench: '&',
    isLockLoanappFeatureEnabled: '<',
    hasWorkbenchOpen: '<',
    isShowTimeline: '<',
    conversionOptions: '<',
    onConvertCard: '&',
  },
};
