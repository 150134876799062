import template from './imagePreviewThumb.html';
import imagePreviewThumbCtrl from './imagePreviewThumbCtrl';

export default {
  template,
  bindings: {
    item: '=',
  },
  controller: imagePreviewThumbCtrl,
  controllerAs: 'vm',
};
