export const REPORT_TYPE = {
  WIP_BY_LOAN: 1,
  AVG_LOAN_SIZE: 2,
  WIP_BY_LOAN_REFERRER: 3,
  WIP_BY_LOAN_FRANCHISE: 4,
  LEAD_STATUS_REPORT: 5,
  COMMISSION_REPORT: 6,
  LOAN_DETAILS_REPORT: 7,
  ESTIMATED_SETTLEMENT_DATES: 8,
  AVERAGE_UNCONDITIONAL_APPROVED: 9,
  CUSTOMER_BIRTHDAYS_LIST: 10,
  CURRENT_OPPORTUNITIES: 11,
};
