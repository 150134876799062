import template from './pipelineLabelColorPicker.html';
import PipelineLabelColorPickerCtrl from './pipelineLabelColorPickerCtrl';

export default {
  template,
  controller: PipelineLabelColorPickerCtrl,
  controllerAs: 'vm',
  bindings: {
    selectedColor: '<',
    onColorSelected: '&',
  },
};
