import template from './documentTypeIcon.html';
import documentTypeIconCtrl from './documentTypeIconCtrl';

export default {
  template,
  bindings: {
    contentType: '<',
    documentId: '<',
  },
  controller: documentTypeIconCtrl,
  controllerAs: 'vm',
};
