export default class LenderLogoCtrl {
  $onChanges(changes) {
    const { lenderId, countryCode } = changes;
    if (lenderId && lenderId.currentValue) {
      this.lenderId = changes.lenderId.currentValue;
    }
    if (countryCode && countryCode.currentValue) {
      this.countryCode = changes.countryCode.currentValue;
    }

    this.imageSrc = this.createImageSrc();
  }

  createImageSrc() {
    const { countryCode, lenderId, shownOnError } = this;
    const imgDir = `assets/images`;
    const defaultImgSrc = `${imgDir}/lenderLogos/default.png`;
    const lenderLogoDir = this.isLarge ? 'lenderLogoLg' : 'lenderLogos';
    const screenType = this.isRetina ? '@2x' : '';
    const lenderLogo = [imgDir, lenderLogoDir, countryCode, lenderId].join('/');
    const isLenderLogo =
      (lenderId || lenderId > 0 || shownOnError) && countryCode;
    const lenderLogoSrc = `${lenderLogo}${screenType}.png`;
    return isLenderLogo ? lenderLogoSrc : defaultImgSrc;
  }
}
