import template from './onboardingCompletePreview.html';
import OnboardingCompletePreview from './onboardingCompletePreviewCtrl';

export default {
  template,
  bindings: {
    data: '<',
    onboardingTabs: '<',
    setTab: '&',
    setPreviousTab: '&',
    sendApplication: '&',
    downloadDocument: '&',
  },
  controller: OnboardingCompletePreview,
  controllerAs: 'vm',
};
