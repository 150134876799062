import angular from 'angular';

class ComplianceReviewRatingModalCtrl {
  constructor($uibModalInstance, comment) {
    this.$uibModalInstance = $uibModalInstance;
    this.comment = comment;
  }
}
angular
  .module('app')
  .controller(
    'ComplianceReviewRatingModalCtrl',
    ComplianceReviewRatingModalCtrl
  );
