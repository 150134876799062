import angular from 'angular';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';
import swal from 'sweetalert';

angular
  .module('app')
  .controller('CreateNoteModalCtrl', function CreateNoteModalCtrl(
    $scope,
    $uibModalInstance,
    $stateParams,
    toaster,
    contactService,
    loanOpportunityService,
    familyId,
    note
  ) {
    function setNoteModel() {
      return {
        isSticky: false,
        familyId: 0,
        activityTitle: '',
        activityDetails: '',
        communicationID: 0,
        activityOwner: '',
        activityDate: new Date(),
        adviserName: '',
        adviserId: '',
        isReferrer: false,
        isPrivate: true,
        isNotification: false,
        notificationSMS: false,
        notificationEmail: false,
        activityType: '',
        notes: '',
      };
    }

    $scope.setNote = setNoteModel();
    if ($stateParams.adviserOrgId) {
      $scope.setNote.familyId = 0;
      $scope.setNote.brokerBrandingID = $stateParams.adviserOrgId;
    } else {
      $scope.setNote.familyId = familyId;
    }
    $scope.options = {
      language: 'en',
      removePlugins: 'toolbar',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 200,
      resize_enabled: false,
    };
    $scope.setNote.isSticky = note.isSticky;
    $scope.setNote.isOpportunity = note.isOpportunity;
    $scope.setNote.activityTitle = note.activityTitle;
    $scope.setNote.activityDetails = note.activityDetails;
    $scope.setNote.communicationID = note.communicationID;
    $scope.setNote.activityOwner = note.writtenBy;
    $scope.setNote.activityDate = note.eventTime;
    $scope.setNote.adviserName = note.adviserName;
    $scope.setNote.adviserId = note.adviserID;
    $scope.setNote.isReferrer = note.isReferrer;
    $scope.setNote.isPrivate = note.isPrivate;
    $scope.setNote.isNotification = note.isNotification;
    $scope.setNote.notificationSMS = note.notificationSMS;
    $scope.setNote.notificationEmail = note.notificationEmail;
    $scope.setNote.activityType = note.activityType;
    $scope.setNote.notes = note.notes;

    $scope.saveCreateNote = () => {
      if (!$scope.setNote.activityTitle) {
        toaster.pop('warning', 'Invalid Note', 'Please create a valid note');
      } else {
        let service;
        let method;
        let params;

        $scope.setNote.emailObj = {
          body: $scope.setNote.notes,
        };

        if (!$scope.setNote.isOpportunity) {
          service = contactService;
          method = 'noteSet';
          params = {
            note: $scope.setNote,
          };
        } else {
          service = loanOpportunityService;
          method = 'updateLoanOpportunityNote';
          params = {
            note: notesBuilderForUI($scope.setNote),
            communicationId: $scope.setNote.communicationID,
          };
        }
        service[method](params.note, params.communicationId || null).then(
          () => {
            swal({
              title: 'Success',
              text: `Note has been successfully ${
                $scope.setNote.communicationID ? 'updated' : 'created'
              }.`,
              type: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
            if ($scope.$parent && $scope.$parent.getNotesList) {
              $scope.$parent.getNotesList();
            }
            $scope.cancel();
          }
        );
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
