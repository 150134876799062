import template from './pipelineLabels.html';
import controller from './pipelineLabelsCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    labelsSettings: '<',
    settingLabelKey: '@',
    cardLabels: '<',
    cardLabelKey: '@',
    card: '<',
    onLabelToggle: '&',
    onRefreshLabelsSettings: '&',
    pipelineType: '@',
  },
};
