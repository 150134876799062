import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { displayError } from 'Common/utilities/alert';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { isCancelled } from 'Common/utilities/subscription';

angular
  .module('app')
  .controller('LoanAppSupportDocsCtrl', function LoanAppSupportDocsCtrl(
    $scope,
    $timeout,
    $rootScope,
    $window,
    toaster,
    contactService,
    configService,
    $state,
    $stateParams,
    $uibModal,
    loanApplicationServices,
    $filter,
    loanAppSharedData,
    loanScenarioService,
    optionsService,
    downloadDataService,
    broadcastDataService,
    documentService,
    uiService,
    generalService
  ) {
    let clickCounter = 0;

    $scope.loanAppId = $stateParams.loanAppId;
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.selectedDocuments = [];

    $scope.documentsList = [];
    $scope.searchFilesText = '';
    $scope.documentsListLength = 0;
    /* listeners */
    $scope.$on('isLodged', (event, data) => {
      $scope.isLodged = data;
    });
    const packageListener = $rootScope.$on(
      'eSignPackageSent',
      (event, data) => {
        $scope.packageSent = data ? $scope.packageSent + 1 : 0;
      }
    );
    $scope.$on('$destroy', packageListener);
    /* end of listeners */
    $scope.showEsign = false;
    $scope.packageSent = 0;
    $scope.getSubscription = () => {
      const showEsign =
        (configService.feature && configService.feature.esign) || 0;
      const assignedAdviserId =
        loanAppSharedData &&
        loanAppSharedData.LoanApplicationDetails &&
        loanAppSharedData.LoanApplicationDetails.AdviserFamilyId;
      if (!assignedAdviserId || !showEsign) return;
      contactService.subscriptionInfo(assignedAdviserId).then(response => {
        if (!response || !response.length) return;
        const eSignSubscription =
          response &&
          response.find(
            obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN
          );
        const { isActive } = eSignSubscription;
        $scope.showEsign = isActive || isCancelled(eSignSubscription);
      });
    };
    loanApplicationServices.loanSummaryGet($scope.loanAppId).then(() => {
      $scope.processSupportDocsSection = () => {
        if (clickCounter !== 0) return;
        clickCounter++;
        $scope.getSubscription();
        $scope.fnIsStartedSupportingDocs = () => {
          $scope.isStartedSupportingDocs =
            $scope.documentsList && $scope.documentsList.length > 0;
        };

        $scope.settings = {
          uploadSucceeded: true,
        };

        /**
         * Validates deposits section
         */
        $scope.validateSupportingDocs = () => {
          $scope.validateToGreenTick();
          const sectionObject = loanAppSharedData.getSectionObjectById(
            loanAppSharedData.completionListObject,
            SECTION_ID.UPLOAD_VIEW
          );
          sectionObject.IsCompleted = $scope.isValidSupportingDocs;
          $scope.countValids(sectionObject.IsCompleted, 'documents');
          loanAppSharedData.completionStatusSet(
            SECTION_ID.UPLOAD_VIEW,
            sectionObject.IsCompleted,
            $scope.isValidSupportingDocs
          );
        };

        $scope.documentProgressVisibility = visibility => {
          $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
        };

        /* droplet method for upload/view */
        angular.extend($scope, {
          uploadViewSuccess(files) {
            angular.forEach(files, o => {
              $scope.processDoc({
                Title: o.Name,
                DocumentID: o.DocumentId,
                FamilyID: $scope.familyId,
              });
            });
          },
        });

        /**
         *  Validates every update on object
         */
        $scope.$watch(
          'selectedDocuments',
          () => {
            $scope.validateSupportingDocs();
            $scope.fnIsStartedSupportingDocs();
          },
          true
        );

        $scope.$watch(
          () => uiService.isCountry('New Zealand'),
          nv => {
            if (nv) {
              $scope.extensions = ['pdf', 'jpg', 'jpeg', 'png'];
              $scope.invalidFileInfo = {
                title: 'Invalid',
                description:
                  'This file format will not be visible to the lender, please try uploading using PDF, JPEG or PNG format',
              };
            } else {
              $scope.extensions = [
                'pdf',
                'docx',
                'doc',
                'odt',
                'xls',
                'xlsx',
                'ods',
                'odp',
                'ppt',
                'pptx',
                'jpg',
                'jpeg',
                'png',
                'rtf',
                'odt',
                'txt',
                'tif',
                'bmp',
                'gif',
                'html',
              ];
              $scope.invalidFileInfo = {
                title: 'Invalid',
                description: 'Invalid Bad file NOT added',
              };
            }
          }
        );

        $scope.getRelatedDocs = () => {
          loanScenarioService
            .availableDocuments($scope.loanAppId)
            .then(response => {
              $scope.relatedDocs = response.data;
              $scope.checkDuplicates();
            });
        };

        $scope.updateSeletedDocs = () => {
          $scope.selectedDocuments = _.filter($scope.relatedDocs, o => {
            return o.IsAdded === true;
          });
        };

        const getDocumentType = country => {
          if (country !== 'New Zealand') {
            optionsService.DocumentTypeGet().then(response => {
              $scope.DocumentsTypeList = response.data;
              $scope.validateSupportingDocs();
              $scope.fnIsStartedSupportingDocs();
            });
          } else {
            // should watch changes on selected lender
            $scope.$watch(
              'loanAppSharedData.lenderId',
              nv => {
                optionsService.NZDocumentTypeGet(nv || 0).then(response => {
                  $scope.DocumentsTypeList = response.data;
                  $scope.validateSupportingDocs();
                  $scope.fnIsStartedSupportingDocs();
                  let isTypeExisting = null;
                  _.forEach($scope.documentsList, val => {
                    isTypeExisting = _.find($scope.DocumentsTypeList, o => {
                      return o.Value === val.PageType;
                    });
                    if (val.PageType && isTypeExisting) {
                      $scope.settings.uploadSucceeded = true;
                    }
                  });
                });
              },
              true
            );
          }
        };

        $scope.$watch('crmCountry', nv => {
          if (nv) {
            getDocumentType(nv);
          }
        });

        $scope.$watch(
          'relatedDocs',
          list => {
            let selectedItems = 0;
            $scope.allSelectedDocuments = _.flatten(
              _.map(list, items => items.Documents)
            );
            angular.forEach($scope.allSelectedDocuments, item => {
              selectedItems += item.IsAdded && !item.included ? 1 : 0;
            });
            $scope.selectedItems = selectedItems;
            $scope.updateSeletedDocs();
          },
          true
        );

        $scope.validateToGreenTick = () => {
          $scope.settings.uploadSucceeded = true;
          const validFileStatus = [];
          _.forEach($scope.documentsList, object => {
            validFileStatus.push(object.Type);
          });
          const hasNoLabelFile = _.some(validFileStatus, _.isEmpty);
          $scope.isValidSupportingDocs = !hasNoLabelFile;
        };

        $scope.showPages = item => {
          item.showPages = !item.showPages;
          $scope.loadPages(item);
        };

        $scope.loadPages = item => {
          if (
            (!item.pagesLoaded || item.pagesLoaded === false) &&
            item.DocumentId
          ) {
            item.isGettingDocuments = true;
            loanApplicationServices
              .getDocumentDetails($scope.loanAppId, item.DocumentId, 500, false)
              .then(response => {
                item.pages = response.data;
                item.pagesLoaded = true;
                item.isGettingDocuments = false;
                broadcastDataService.InlinePreloader = {
                  preLoaderText: '',
                  preLoaderSize: '',
                  showPreLoader: false,
                };
              });
          }
        };

        $scope.checkDuplicates = () => {
          const relatedDocs = _.flatten(
            _.map($scope.relatedDocs, items => items.Documents)
          );
          const documentIDs = $scope.documentsList.map(item => item.DocumentId);
          _.forEach(relatedDocs, item => {
            item.included = _.includes(documentIDs, item.DocumentId);
            item.IsAdded = _.includes(documentIDs, item.DocumentId);
          });
        };

        $scope.selectDoc = item => {
          if (!item.included) item.IsAdded = !item.IsAdded;
          $scope.updateSeletedDocs();
        };

        $scope.showAddDocsModal = () => {
          $scope.getRelatedDocs();
          $uibModal.open({
            templateUrl:
              '/assets/views/loanApplication/supportDocs/modal/selectDoc.html',
            controller($uibModalInstance) {
              $scope.cancel = () => {
                $uibModalInstance.dismiss('cancel');
              };
              $scope.finishSelection = () => {
                $scope.cancel();
                toaster.pop('success', 'Success', 'Document Updated');

                angular.forEach($scope.allSelectedDocuments, val => {
                  if (val.IsAdded === true && !val.included) {
                    $scope.processDoc({
                      Title: val.Name,
                      DocumentID: val.DocumentId,
                    });
                  }
                });
              };
            },
            size: 'md',
            scope: $scope,
          });
        };

        $scope.setDocument = (
          documentObject,
          dontRefresh,
          successText,
          noModal,
          callback
        ) => {
          // copy by value
          const obj = angular.copy(documentObject);
          obj.LoanScenarioId = $scope.loanAppId;

          // remove unnecessary attributes
          _.omit(obj, [
            'pages',
            'showPages',
            'pagesLoaded',
            'addNotes',
            'StatusName',
          ]);

          loanApplicationServices
            .setDocument(obj)
            .then(() => {
              if (!noModal) {
                toaster.pop('success', 'Sucess', successText);
              }
              if (!dontRefresh) {
                $scope.loadDocumentList();
              }
              callback();
            })
            .catch(displayError);
        };

        $scope.processDoc = obj => {
          const documentDataObject = obj;

          $scope.documentThumbnail = {};
          loanApplicationServices
            .getDocumentPageCount(documentDataObject.DocumentID)
            .then(respond => {
              $scope.setDocument(
                {
                  LoanScenarioId: $scope.loanAppId,
                  DocumentPage: 1,
                  IsAdded: true,
                  Notes: '',
                  UploadDate: new Date(),
                  UploadedByFamilyID: $scope.familyId,
                  DocumentId: documentDataObject.DocumentID,
                  Name: documentDataObject.Title,
                  Size: 0,
                  NumberPages: respond.data,
                  DateUploaded: new Date(),
                  Createddate: new Date(),
                  CreatedMonth: new Date(),
                },
                false,
                'Document has been been successfully uploaded.',
                false,
                _.noop
              );
              $scope.validateSupportingDocs();
            });
        };

        // Update Notes
        $scope.updateNotes = item => {
          $scope.setDocument(
            item,
            true,
            'Notes successfully saved.',
            false,
            _.noop
          );
        };

        $scope.updateDocType = item => {
          if (
            item.DocumentId &&
            item.LoanScenarioDocument_PageID &&
            item.PageType &&
            item.PageName &&
            item.PageNumber
          ) {
            loanApplicationServices.setDocumentPage({
              LoanScenarioId: $scope.loanAppId,
              DocumentId: item.DocumentId,
              LoanScenarioDocument_PageID: item.LoanScenarioDocument_PageID,
              PageType: item.PageType,
              PageName: item.PageName,
              PageNumber: item.PageNumber,
              ThumbnailURI: item.ThumbnailURI,
            });

            const isTypeExisting = _.find($scope.DocumentsTypeList, o => {
              return o.Value === item.PageType;
            });

            if (item.PageType !== '' && isTypeExisting) {
              $scope.settings.uploadSucceeded = true;
            } else {
              $scope.settings.uploadSucceeded = false;
            }
            $scope.validateSupportingDocs();
          } else {
            swal(
              'Incomplete Document Information',
              'Necessary document information is missing.',
              'error'
            );
          }
        };

        $scope.showGenerateBanner = item => {
          return (
            item.StatusName === 'Included' &&
            item.showPages &&
            item.pages.length > 0 &&
            item.pages.length !== $scope.countPageThumbnail(item)
          );
        };

        $scope.countPageThumbnail = item => {
          const hasThumbs = _.filter(item.pages, obj => obj.ThumbNail);
          return hasThumbs.length;
        };

        $scope.generateThumbsAllPages = item => {
          if (
            item.pages.length === $scope.countPageThumbnail(item) ||
            item.isGettingThumbnail
          ) {
            return;
          }

          item.isGettingThumbnail = true;
          let counter = 0;
          _.forEach(item.pages, page => {
            if (!page.ThumbNail) {
              const data = {
                loanScenarioId: $scope.loanAppId,
                documentId: page.DocumentId,
                pageNumber: page.PageNumber,
              };
              page.isGettingThumbnail = true;
              generalService.documentThumbnail(data).then(
                response => {
                  page.ThumbNail = response.data;
                  page.isGettingThumbnail = false;
                  counter++;

                  if (counter === item.pages.length) {
                    item.isGettingThumbnail = false;
                  }
                },
                () => {
                  page.isGettingThumbnail = true;
                }
              );
            }
          });
        };

        $scope.updateAllDocType = item => {
          $scope.setDocument(item, true, '', true, () => {
            if (item.showPages) {
              loanApplicationServices
                .getDocumentDetails($scope.loanAppId, item.DocumentId, 500)
                .then(response => {
                  item.pages = response.data;
                });
            }
            $scope.validateSupportingDocs();
          });
        };

        // Douments List
        //-------------------------------------------------------------
        $scope.loadDocumentList = () => {
          $scope.isGettingDocuments = true;

          loanApplicationServices
            .getDocumentList($scope.loanAppId)
            .then(result => {
              result.data = _.map(result.data, res => {
                const identicalDoc = _.find($scope.documentsList, doc => {
                  return doc.DocumentId === res.DocumentId;
                });
                if (identicalDoc) {
                  res.showPages = identicalDoc.showPages;
                  res.pages = identicalDoc.pages;
                }
                return res;
              });
              // put StatusName attribute
              $scope.documentsList = loanAppSharedData.documents.fillStatusName(
                result.data
              );

              // check if there is notes, open automatically if there is
              _.each($scope.documentsList, doc => {
                if (_.size(doc.Notes) > 0) {
                  doc.addNotes = true;
                }
              });

              $scope.isGettingDocuments = false;

              $scope.fnIsStartedSupportingDocs();

              /* save removed lenderWorksheet into loanAppSharedData */
              loanAppSharedData.documentListLenderWorksheet = _.remove(
                $scope.documentsList,
                object => {
                  return object.Description === 'Worksheet';
                }
              );
              // check documentList if all has type
              $scope.validateSupportingDocs();
            });
        };
        $scope.loadDocumentList();

        $scope.downloadDocument = documentID => {
          downloadDataService.documentDownload(documentID);
        };

        $scope.removeDocument = (documentId, loanScenarioDocumentId) => {
          swal(
            {
              title: 'Please confirm action',
              text:
                'Are you sure you want to delete this document? This action cannot be undone',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#FA8E91',
              confirmButtonText: 'Delete Document',
              closeOnConfirm: false,
            },
            isConfirm => {
              if (isConfirm) {
                loanApplicationServices
                  .deleteDocument(
                    $scope.loanAppId,
                    documentId,
                    loanScenarioDocumentId
                  )
                  .then(() => {
                    $scope.loadDocumentList();
                    $scope.validateSupportingDocs();
                    swal('Deleted', 'File has been deleted.', 'success');
                  })
                  .catch(displayError);
              }
            }
          );
        };

        $scope.searchDocument = $event => {
          $scope.searchFilesText = $event.target.innerText;
          $scope.tableParams.reload();
        };

        // Forthcoming
        angular.extend($scope, {
          saveForthcomingDocument() {
            const docData = {
              LoanScenarioId: $scope.loanAppId,
              UploadDate: new Date(),
              UploadedByFamilyID: $scope.familyId,
              DocumentId: 0,
              Name: 'Forthcoming Document #',
              Size: 0,
              NumberPages: 0,
              DateUploaded: new Date(),
              Createddate: new Date(),
              CreatedMonth: new Date(),
              PageType: [],
              Notes: '',
              StatusId: '2',
              StatusName: loanAppSharedData.documents.getStatusName('2'),
            };

            $scope.setDocument(docData, false, '', true, _.noop);
          },
          getForthcomingNo($LoanScenarioDocumentID) {
            const forthcomingList = [];

            _.each(
              _.filter($scope.documentsList, doc => {
                return doc.StatusName === 'Forthcoming';
              }),
              doc => {
                forthcomingList.push({
                  LoanScenarioDocumentID: doc.LoanScenarioDocumentID,
                  StatusId: doc.StatusId,
                });
              }
            );

            return _.findIndex(forthcomingList, forth => {
              return forth.LoanScenarioDocumentID === $LoanScenarioDocumentID;
            });
          },
        });
      };

      const sectionObject = loanAppSharedData.getSectionObjectById(
        loanAppSharedData.completionListObject,
        SECTION_ID.UPLOAD_VIEW
      );
      $scope.countValids(sectionObject.IsCompleted, 'documents');

      if (typeof sectionObject.IsCompleted === 'undefined') {
        $scope.processSupportDocsSection();
      } else {
        const { DocumentList } = loanAppSharedData.loanAppSummary;
        $scope.isValidSupportingDocs = sectionObject.IsCompleted;
        $scope.isStartedSupportingDocs = DocumentList && DocumentList.length;

        if ($scope.isStartedSupportingDocs) {
          $scope.documentsList = DocumentList;
        }
      }
    });
  });
