import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { notesBuilderForUI } from 'Common/mappers/loanOpportunity';
import { SMS_AND_EMAIL_PATTERN } from 'Common/constants/pattern';
import { AGREEMENT_TYPE } from 'Common/constants/agreementType';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

angular
  .module('app')
  .controller('NotesDetailsCtrl', function NotesDetailsCtrl(
    $scope,
    $http,
    $timeout,
    $uibModal,
    $state,
    $stateParams,
    contactService,
    NgTableParams,
    $filter,
    SweetAlert,
    utilitiesService,
    corporateService,
    toaster,
    userService,
    loanOpportunityService,
    currentUserService,
    rayWhiteService
  ) {
    $scope.isAssistantView = $state.includes('app.contactsAssistant');
    $scope.notesWidget = false;
    $scope.notesWidgetMenu = false;
    $scope.notesWidgetPanel = false;
    $scope.filterReferrerNotes = 'all';
    $scope.searchNotes = '';
    $scope.brokerBrandingId = $stateParams.adviserOrgId || 0;
    $scope.isClient = true;
    const getClientInfo = familyId => {
      if (!familyId) return;
      contactService.clientInformGet(familyId).then(response => {
        const { data } = response;
        if (!data || !data.length) return;
        $scope.clientName = data[0].FullName;
      });
    };
    const getUserInfo = () => {
      if (!currentUserService) return;
      $scope.userInfo = currentUserService;
      if ($scope.brokerBrandingId || $state.params.clientId !== undefined) {
        $scope.isClient = false;
      }
      if ($scope.isClient) {
        const { familyId } = $state.params;
        getClientInfo(familyId);
      }
    };

    $scope.actionButtonVisible = ownerId => {
      if ($scope.isClient) return true;
      if (!$scope.userInfo && !$scope.userInfo.familyId) return true;
      return parseInt($scope.userInfo.familyId, 10) === parseInt(ownerId, 10);
    };

    $scope.showWidget = function(notesWidgetPanel) {
      $scope.notesWidgetPanel = !notesWidgetPanel;
      if ($scope.notesWidgetPanel === true) {
        $('#contacts-sidebar').addClass('overlap');
      } else {
        $('#contacts-sidebar').removeClass('overlap');
      }
    };
    //
    $scope.filterNotes = function() {
      const temp = [];
      if ($scope.filterReferrerNotes === 'referrer') {
        $scope.noteListData = {}; // resets the value of noteslistdata
        angular.forEach($scope.notesContainer, (value, index) => {
          if (value.IsReferrer) {
            temp[index] = value;
          }
        });
        $scope.noteListData = temp; // stores the value based on filter
      } else {
        $scope.noteListData = $scope.notesContainer; // store all the original noteslistdata
      }
    };

    angular.extend($scope, {
      pagination: {
        bigTotalItems: 0,
        bigCurrentPage: 1,
        maxSize: 5,
        itemsPerPage: 5,
      },
    });

    const parseNotesDetails = notes => {
      if (!notes) return;
      const notesDetails = notesBuilderForUI(notes);
      const { activityDetails, isSharedWithReferrer } = notesDetails;
      if (!isSharedWithReferrer) return activityDetails;
      const regexPattern = new RegExp(SMS_AND_EMAIL_PATTERN);
      const patternMatches =
        activityDetails && activityDetails.match(regexPattern);
      if (!patternMatches || !patternMatches.length) return notes;
      const notesMatch = patternMatches.map(match => match);
      const notesData = {
        activityDetails: notesMatch[1] || '',
        smsBody: notesMatch[2] || '',
        emailBody: notesMatch[3] || '',
      };
      return notesData;
    };
    // Notes [corporate] - moved from contactDetailsCtrl
    $scope.getNotesList = function() {
      // Set family ID default to 1 if Adviser Org Contact is opened
      const tempFamilyId =
        typeof $scope.familyId !== 'undefined' && $scope.familyId > 0
          ? $scope.familyId
          : 1;
      contactService
        .notesListCount(tempFamilyId, $scope.brokerBrandingId)
        .then(response => {
          $scope.pagination.bigTotalItems = response.data;

          contactService
            .notesList(
              tempFamilyId,
              $scope.pagination.bigTotalItems,
              $scope.brokerBrandingId
            )
            .then(listResponse => {
              if (!listResponse.data) return;
              $scope.noteListData = listResponse.data.map(note => {
                const {
                  activityDetails,
                  smsBody,
                  emailBody,
                } = parseNotesDetails(note);
                return {
                  ...note,
                  initial: utilitiesService.filterInitialOneString(
                    note.ActivityOwner
                  ),
                  ActivityDetails: activityDetails || note.ActivityDetails,
                  smsBody,
                  emailBody,
                };
              });
              $scope.$watch($scope.noteListData, () => {
                $scope.notesContainer = $scope.noteListData;
              });
            });
        });
    };
    $scope.getNotesList();

    $scope.editNote = function(action, note) {
      let familyId = $scope.familyId;
      let noteObj = {};
      const isOpportunity =
        note.IsOpportunity ||
        (typeof note.IsOpportunity !== 'undefined' && !note.IsOpportunity);

      if ($stateParams.adviserOrgId) {
        familyId = 0;
      }
      contactService
        .noteGet(
          familyId,
          note.CommunicationID,
          $scope.brokerBrandingId,
          isOpportunity
        )
        .then(response => {
          const { data } = response;
          if (data && !data.length) return;

          noteObj = data[0];
          noteObj.IsOpportunity = isOpportunity;
          if (noteObj) {
            $scope.openCreateNoteModal(action, noteObj);
          } else toaster.pop('error', 'Error retrieving note info.');
        });
    };

    $scope.deleteNote = function(note) {
      if (!angular.isUndefined(note.CommunicationID)) {
        SweetAlert.swal(
          {
            title: 'Are you sure?',
            text: 'This record will be removed from your notes',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Yes, remove it!',
            closeOnConfirm: false,
          },
          confirm => {
            if (confirm) {
              let service;
              let method;
              if (typeof note.IsOpportunity === 'undefined') {
                service = contactService;
                method = 'noteDelete';
              } else {
                service = loanOpportunityService;
                method = 'deleteLoanOpportunityNote';
              }
              service[method](note.CommunicationID).then(() => {
                _.remove($scope.noteListData, o => {
                  return _.isEqual(o, note);
                });

                SweetAlert.swal({
                  title: 'Success',
                  text: 'Note has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              });
            }
          }
        );
      } else {
        _.remove($scope.noteListData, o => {
          return _.isEqual(o, note);
        });
      }
    };

    const getReferrerDetails = (referrerId, agreementTypeId) => {
      if (!referrerId) return;
      if (agreementTypeId === AGREEMENT_TYPE.RAY_WHITE) {
        return rayWhiteService.getMembers(referrerId);
      }
      return corporateService
        .corporateContactGet(0, referrerId)
        .then(response => {
          if (!response.data) return false;
          return response.data;
        });
    };

    const getClientDetails = familyID => {
      return contactService.contactFamilyInfoGet(familyID).then(response => {
        if (!response.data) return false;
        const referrerId = response.data.ReferrerID || 0;
        const agreemetyTypeId = response.data.ReferrerAgreementTypeId;
        $scope.adviserFullName = response.data.AdviserFullName || '';
        return getReferrerDetails(referrerId, agreemetyTypeId);
      });
    };

    const openNoteModal = props => {
      const modalInstance = $uibModal.open({
        template: `<opportunity-notes-modal
          modal-instance="vm.modalInstance"
          note-obj="vm.props.noteObj"
          is-add="vm.props.isAdd"
          referral-id="vm.props.referralId"
          is-sms-enabled="vm.props.isSmsEnabled"
          is-not-opportunity-module="true">
        </opportunity-notes-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        windowClass: 'opportunity-notes-modal',
        resolve: {
          props: () => props,
        },
      });
      modalInstance.result.then(() => {
        $scope.getNotesList();
      });
    };

    const emailDefaultObject = (referrerInfo, props) => {
      const emailSubj = `New Note Created for ${referrerInfo.FirstName} ${
        referrerInfo.LastName
      }`;
      return {
        subject: emailSubj,
        body: props.emailBody || '',
        replyTo: {
          EmailAddress: $scope.userInfo.email,
        },
        sentDate: new Date(),
        recipients: [
          {
            EmailAddress: referrerInfo.Email,
          },
        ],
      };
    };

    const emailData = referrerInfo => {
      const emailSubj = `New Note Created for ${referrerInfo.FirstName} ${
        referrerInfo.LastName
      }`;
      return {
        recipients: [
          {
            EmailAddress: referrerInfo.Email,
          },
        ],
        subject: emailSubj,
        referrerEmail: referrerInfo.Email,
      };
    };

    const setUpEmailAndSmsObj = (notesData, referrerInfo, props) => {
      const smsObj = {
        mobile: referrerInfo.Mobile,
        body: props.smsBody || '',
      };
      const emailObjDefault = emailDefaultObject(referrerInfo, props);
      const emailObj = {
        ...emailObjDefault,
        ...emailData(referrerInfo),
      };
      const isSmsEnabled = $scope.userInfo.sMSEnabled || false;
      const referralId = referrerInfo.FamilyId || 0;
      const referrerShareEmail = !!props.emailBody;
      const referrerShareSms = !!props.smsBody;
      const noteObj = {
        ...notesData,
        emailObj,
        smsObj,
        referrerShareEmail,
        referrerShareSms,
        clientName: $scope.clientName || '',
        brokerName: $scope.adviserFullName || '',
      };
      const propsTmp = {
        isSmsEnabled,
        referralId,
        noteObj,
      };
      const propsData = {
        ...props,
        ...propsTmp,
      };
      return propsData;
    };

    $scope.openCreateNoteModal = function(action, noteObjData = {}) {
      const familyId = $state.params.familyId || 0;
      const brokerBrandingId = $state.params.adviserOrgId || 0;
      const notes = {
        ...noteObjData,
        FamilyID: familyId,
        BrokerBrandingID: brokerBrandingId,
      };
      const notesData = notesBuilderForUI(notes);
      const props = {
        isAdd: action === 'add',
        noteObj: notesData,
        referralId: 0,
        isSmsEnabled: 0,
        emailBody: noteObjData.emailBody || '',
        smsBody: noteObjData.smsBody || '',
      };
      if ($scope.isClient && familyId > 0) {
        getClientDetails(familyId).then(referrerInfo => {
          if (referrerInfo) {
            const propsData = setUpEmailAndSmsObj(
              notesData,
              referrerInfo,
              props
            );
            if ($scope.userInfo.accessType === ACCESS_TYPE.CORPORATE) {
              openNoteModal(propsData);
            } else {
              contactService
                .getContactAssignedAdviser($scope.familyId)
                .then(assignedAdviser => {
                  if (!assignedAdviser.data) return;
                  corporateService
                    .familyBrokerSupportGet(assignedAdviser.data)
                    .then(adviser => {
                      if (!adviser.data) return;
                      const prop = {
                        ...propsData,
                        isSmsEnabled: adviser.data.SMSEnabled,
                      };
                      openNoteModal(prop);
                    });
                });
            }
          } else {
            openNoteModal(props);
          }
        });
      } else {
        openNoteModal(props);
      }
    };
    getUserInfo();

    $scope.$on('REFRESH_NOTES_LIST', () => {
      $scope.getNotesList();
    });
  });
