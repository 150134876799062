import {
  FIXED_RATE_EXPIRES_ID,
  APPROVAL_EXPIRIES_ID,
  BIRTHDAYS_ID,
  ANNUAL_REVIEWS_ID,
  POST_SETTLEMENT_FOLLOW_UP_ID,
} from 'Common/constants/customerCareModules';

class CustomerCareModule {
  constructor(moduleID) {
    let moduleName;
    switch (moduleID) {
      case FIXED_RATE_EXPIRES_ID:
        moduleName = 'Fixed Rate Expires';
        break;
      case APPROVAL_EXPIRIES_ID:
        moduleName = 'Approval Expiries';
        break;
      case BIRTHDAYS_ID:
        moduleName = 'Birthdays';
        break;
      case ANNUAL_REVIEWS_ID:
        moduleName = 'Annual Reviews';
        break;
      case POST_SETTLEMENT_FOLLOW_UP_ID:
        moduleName = 'Post Settlement Follow Up';
        break;
      default:
    }

    this.ModuleID = moduleID;
    this.ModuleName = moduleName;
  }
}

export default CustomerCareModule;
