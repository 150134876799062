import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ANNUAL_REVIEWS_ID } from 'Common/constants/customerCareModules';

angular
  .module('app')
  .controller('AnnualReviewCtrl', function annualReviewCtrlFun(
    $scope,
    $uibModal,
    SweetAlert,
    NgTableParams,
    $window,
    $location,
    $state,
    $stateParams,
    toaster,
    cfpLoadingBar,
    $timeout,
    $rootScope,
    $filter,
    $localStorage,
    customerCareService
  ) {
    // Annual Reviews
    //--------------------------------------------------------------------------
    $scope.mainObj = {};
    $scope.mainObj.adviserId = 0;
    $scope.mainObj.dateRange = 'this';
    $scope.mainObj.ShowCompleted = false;

    // table
    $scope.loadAnnualReviewTable = () => {
      $scope.annualReviewTableParams = new NgTableParams(
        {
          page: 1,
          count: 10,
          sorting: {
            reviewDate: 'asc',
          },
        },
        {
          counts: [],
          total: 0,
          getData($defer, params) {
            const sortColumn = params.orderBy()[0].substring(1);
            const sortType = _.values(params.sorting())[0].toUpperCase();
            customerCareService
              .ClientsAnnualReviewListGet(
                $scope.mainObj.adviserId,
                $scope.mainObj.formDate,
                $scope.mainObj.toDate,
                params.page(),
                params.count(),
                sortColumn,
                sortType,
                $scope.mainObj.ShowCompleted
              )
              .then(
                response => {
                  if (
                    typeof response.data !== 'undefined' &&
                    typeof response.data.ClientsAnnualReviewList !== 'undefined'
                  ) {
                    $scope.annualReviewListTable =
                      response.data.ClientsAnnualReviewList;
                    _.map($scope.annualReviewListTable, item => {
                      const $date = `${item.ReviewMonth}-1-${item.ReviewYear}`;
                      item.reviewDate = moment($date).format('MMMM YYYY');

                      const today = moment().format('MMMM YYYY');

                      if (today === item.reviewDate) item.IsDueThisMonth = true;
                      else item.IsDueThisMonth = false;
                    });
                    if (typeof response.data.TotalCount !== 'undefined')
                      params.total(response.data.TotalCount);
                    else params.total(0);
                    $defer.resolve($scope.annualReviewListTable);
                  }
                },
                () => {}
              );
          },
        }
      );
    };

    // Date Range Filter
    $scope.dateRangeFilter = () => {
      if ($scope.mainObj.dateRange === 'this') {
        $scope.mainObj.formDate = moment()
          .startOf('month')
          .format('D MMMM YYYY');
        $scope.mainObj.toDate = moment()
          .endOf('month')
          .format('D MMMM YYYY');
      } else if ($scope.mainObj.dateRange === 'next') {
        const $date = moment().add(1, 'months');
        $scope.mainObj.formDate = moment($date)
          .startOf('month')
          .format('D MMMM YYYY');
        $scope.mainObj.toDate = moment($date)
          .endOf('month')
          .format('D MMMM YYYY');
      }
      $scope.loadAnnualReviewTable();
    };
    $scope.dateRangeFilter();

    // Set Annual Review as Complete
    $scope.setAnnualReviewCompilation = annualReview => {
      const notificationHeader = `Annual Reviews Completed`;
      const $postData = [
        {
          CustomerCareType: 'CLIENT_ANNUAL_REVIEW',
          FamilyID: annualReview.FamilyID,
          LoanID: 0,
          IsComplete: annualReview.isComplete,
          NotificationHeader: notificationHeader,
        },
      ];
      customerCareService.setCompeletion($postData).then(
        () => {
          $scope.annualReviewTableParams.reload();
        },
        () => {}
      );
    };

    // Open modal For Get Annual Review Detail in Mobile
    $scope.openModalForGetAnnualReviewDetail = (annualReview, size) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/annual_review_modal.html',
        controller: 'GetAnnualReviewDetailModalCtrl',
        size,
        resolve: {
          annualReview: () => annualReview,
        },
      });
    };

    $scope.openSendEmailModal = (size, annualReviewObj, source) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_email_modal.html',
        resolve: {
          annualReviewListTable() {
            return $scope.annualReviewListTable;
          },
          loadAnnualReviewTable() {
            return $scope.loadAnnualReviewTable;
          },
          annualReviewObj: () => annualReviewObj,
          source: () => source,
        },
        controller: 'AnnualReviewSendEmailModalCtrl',
        size,
      });
    };
    $scope.openSendMailModal = (size, annualReviewObj, source) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_mail_modal.html',
        resolve: {
          listTableData() {
            return $scope.annualReviewListTable;
          },
          sendMailObj: () => annualReviewObj,
          source: () => source,
          currentModuleId() {
            return ANNUAL_REVIEWS_ID;
          },
        },
        controller: 'OpenSendMailModalCtrl',
        size,
      });
    };
    $scope.openSMSModal = (size, annualReviewObj, source) => {
      $uibModal.open({
        templateUrl:
          '/assets/views/customerCare/partials/modal/send_sms_modal.html',
        // CLIENT_ANNUAL_REVIEW
        resolve: {
          annualReviewListTable() {
            return $scope.annualReviewListTable;
          },
          loadAnnualReviewTable() {
            return $scope.loadAnnualReviewTable;
          },
          annualReviewObj: () => annualReviewObj,
          source: () => source,
        },
        controller: 'AnnualReviewSMSModalCtrl',
        size,
      });
    };
  });
