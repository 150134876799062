import template from './addressHistoryModal.html';
import controller from './addressHistoryModalCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    modalType: '<',
    familyId: '<',
    propertySet: '<',
    loanAppId: '<',
  },
};
