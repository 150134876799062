export const PACKAGE_STATUS = {
  PACKAGE_ACTIVATE: 1,
  PACKAGE_COMPLETE: 2,
  PACKAGE_CREATE: 3,
  PACKAGE_DEACTIVATE: 4,
  PACKAGE_DECLINE: 5,
  PACKAGE_EXPIRE: 6,
  PACKAGE_ATTACHMENT: 7,
  PACKAGE_TRASHED: 8,
  SIGNER_COMPLETE: 9,
  SIGNER_LOCKED: 10,
  DOCUMENT_SIGNED: 11,
  KBA_FAILURE: 12,
  EMAIL_BOUNCE: 13,
};

export const DOCUMENT = {
  UNSIGNED: 1,
  SIGNED: 2,
};

export const E_SIGN_ACOUNT_TYPE = {
  INVITED: 1,
  ACTIVE: 2,
  MEMBER_OF_ANOTHER: 3,
  LOCKED: 4,
};
