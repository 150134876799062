import { announcementBuilderForUI } from 'Common/mappers/announcement';

class AnnouncementService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'Announcement';
  }

  announcementGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}`)
      .then(response => response.data.map(announcementBuilderForUI));
  }
}

export default AnnouncementService;
