import $ from 'jquery';
import angular from 'angular';
import _ from 'lodash';

angular.module('app').controller('CustomThemeCtrl', [
  '$scope',
  '$timeout',
  '$interval',
  '$uibModal',
  '$stateParams',
  '$compile',
  '$q',
  'SweetAlert',
  'configService',
  'contactService',
  'customThemeService',
  'uiService',
  'toaster',
  function CustomThemeCtrl(
    $scope,
    $timeout,
    $interval,
    $uibModal,
    $stateParams,
    $compile,
    $q,
    SweetAlert,
    configService,
    contactService,
    customThemeService,
    uiService,
    toaster
  ) {
    $scope.theme = {};
    $scope.themeCopy = {};
    $scope.defaults = {
      general: {
        CRM_LogoDocId: '0',
        SideBarBGColour: '#1B1439',
        SideBarFontColour: '#FFFFFF',
        ThemeFontColour: '#674C7F',
        TextColour: '#FFFFFF',
        BGColour: '#EEEAF2',
      },
      widget: {
        TableHeaderColour: '#836694',
        TableStripeColour: '#F5F2F8',
        WidgetBorderColour: '#E5E1E9',
        WidgetHeaderColour: '#A28CAF',
        TextColour: '#674C7E',
        BGColour: '',
      },
      buttonLink: {
        LinkColour: '#00BFBF',
        SelectionBGColour: '#1B1439',
        SelectionColour: '#FFFFFF',
        TextColour: '#FFFFFF',
        BGColour: '#43357F',
      },
      form: {
        FormLinkBGColour: '',
        FormSelectionBGColour: '#1B1439',
        FormSelectionColour: '#FFFFFF',
        TextColour: '#6F5F7B',
        BGColour: '#F4ECF8',
      },
    };
    $scope.activeTab = 'general';
    $scope.dropdownSelect = [`${uiService.returnCountry()}`];
    $scope.dropdownSelectModel = $scope.dropdownSelect[0];
    $scope.clients = [
      {
        Name: 'Katie Silva',
        Date: '19 Sep 2016',
      },
      {
        Name: 'Clifford Underwood',
        Date: '07 Sep 2016',
      },
      {
        Name: 'Isaiah Chavez',
        Date: '02 Sep 2016',
      },
    ];
    $scope.colorPickerOptions = {
      showInput: false,
      showInitial: false,
      allowEmpty: false,
      preferredFormat: 'hex',
      containerClassName: 'custom-spectrum-color-picker',
    };
    $scope.uiService = uiService;
    $scope.setActiveTab = function(tab) {
      $timeout(() => {
        $scope.activeTab = tab;
        if (
          $scope.theme.general &&
          typeof $scope.theme.general.CRM_LogoDocId !== 'undefined'
        ) {
          $scope.theme.general.CRM_LogoDocId = String(
            $scope.theme.general.CRM_LogoDocId
          );
          if (
            tab === 'general' &&
            !_.isUndefined($scope.theme.general.CRM_LogoDocId) &&
            $scope.theme.general.CRM_LogoDocId.trim().length > 0
          ) {
            $scope.getLogoThumbnail($scope.theme.general.CRM_LogoDocId);
            $scope.activeSide();
          }
        }
      });
    };
    $scope.restoreDefaults = function() {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This action will reset your custom theme to default',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, reset!',
          closeOnConfirm: true,
        },
        confirm => {
          if (confirm) {
            customThemeService.customThemeReset().then(() => {
              $timeout(() => {
                $scope.theme = _.clone($scope.defaults);
                customThemeService.updateThemePreview($scope.theme);
              }, 0);
              customThemeService.initGlobalCSS();
              customThemeService.setBrandingLogo(null);
              customThemeService.setBrandingLogoPreview(null);
              toaster.pop(
                'success',
                'Done',
                'Custom theme has been reverted to default.'
              );
            });
          }
        }
      );
    };
    $scope.getContent = function(section) {
      return `assets/views/customTheme/${section}/${$scope.activeTab}.html`;
    };
    /* CRM Branding Upload */
    $scope.crmBrand = {
      interface: {},
    };
    $scope.dropletReady = function() {
      $scope.crmBrand.interface.allowedExtensions([
        'png',
        'jpg',
        'jpeg',
        'gif',
        'svg',
      ]);
      $scope.crmBrand.interface.setRequestUrl(
        `${configService.resource}/contacts/DocumentUpload`
      );
      $scope.crmBrand.interface.setRequestHeaders({
        Authorization: configService.token,
      });
      $scope.crmBrand.interface.defineHTTPSuccess([/2.{2}/]);
      $scope.crmBrand.interface.useArray(false);
    };
    $scope.dropletFileAdded = function() {
      if (!$scope.crmBrand.interface.isUploading()) {
        $scope.crmBrand.interface.uploadFiles();
      }
    };
    $scope.dropletSuccess = function(response) {
      if (!_.isEmpty(response)) {
        toaster.pop(
          'success',
          'Done',
          'Logo has been successfully uploaded. Hit apply button to save changes permanently!'
        );
        const docId = response[0].DocumentId;
        $scope.theme.general.CRM_LogoDocId = docId || '';
        $scope.getLogoThumbnail(docId);
      }
    };
    $scope.dropletError = function() {
      toaster.pop('error', 'Something went wrong', 'Please try again');
    };

    function setLogo(docId) {
      if (docId) {
        customThemeService.generalThemeLogoSet(docId).then(() => {
          $scope.getLogoThumbnail(docId);
        });
      }
    }

    $scope.activeSide = () => {
      // for sidebar preview only
      let sel;
      const sTimer = $interval(() => {
        sel = $('.sidebar-preview .crm-sidebar');
        if (sel) {
          sel
            .find('div nav > ul > li.sidebar-item-menu:first')
            .addClass('active');
          $interval.cancel(sTimer);
        }
      });
    };

    $scope.getLogoThumbnail = docId => {
      if (Number(docId) === 0) return;

      contactService.documentImageDataURIGet(docId).then(respond => {
        if (!respond || !respond.data) return;

        customThemeService.setBrandingLogoPreview(respond.data);
      });
    };

    function init() {
      // General theme info
      customThemeService.generalThemeGet().then(response => {
        if (response.data) {
          $scope.theme.general = response.data || { CRM_LogoDocId: '' };
          if (
            response.data.CRM_LogoDocId &&
            String(response.data.CRM_LogoDocId).trim().length > 0
          ) {
            $scope.getLogoThumbnail(response.data.CRM_LogoDocId);
          }
          $scope.themeCopy.general = _.clone($scope.theme.general);
        }
      });
      // Widget theme info
      customThemeService.widgetThemeGet().then(response => {
        if (response.data) {
          $scope.theme.widget = response.data;
          $scope.themeCopy.widget = _.clone($scope.theme.widget);
        }
      });
      // ButtonLink theme info
      customThemeService.buttonLinkThemeGet().then(response => {
        if (response.data) {
          $scope.theme.buttonLink = response.data;
          $scope.themeCopy.buttonLink = _.clone($scope.theme.buttonLink);
        }
      });
      // Form theme info
      customThemeService.formThemeGet().then(response => {
        if (response.data) {
          $scope.theme.form = response.data;
          $scope.themeCopy.form = _.clone($scope.theme.form);
        }
      });
      $scope.activeSide();
    }
    $timeout(() => {
      customThemeService.updateThemePreview($scope.theme);
      let tTimer;
      $scope.$watch(
        'theme',
        () => {
          if (
            $scope.theme.general &&
            $scope.theme.form &&
            $scope.theme.buttonLink &&
            $scope.theme.widget
          ) {
            if (tTimer) $timeout.cancel(tTimer);
            tTimer = $timeout(() => {
              customThemeService.updateThemePreview($scope.theme);
            }, 300);
          }
        },
        true
      );
      init();
    }, 500);
    $scope.saveTheme = function() {
      const promises = [];
      if (
        $scope.theme.general.CRM_LogoDocId !==
        $scope.themeCopy.general.CRM_LogoDocId
      ) {
        const customLogoPromise = customThemeService.customLogoSet(
          $scope.theme.general.CRM_LogoDocId
        );
        promises.push(customLogoPromise);
      }
      _.forOwn($scope.theme, (value, key) => {
        if (!_.isEqual(value, $scope.themeCopy[key])) {
          const currentPromise = customThemeService[`${key}ThemeSet`](
            $scope.theme[key]
          );
          promises.push(currentPromise);
        }
      });

      if (!_.isEmpty(promises)) {
        $q.all(promises).then(() => {
          $scope.themeCopy = _.clone($scope.theme);

          toaster.pop(
            'success',
            'Done',
            'Custom theme has been updated successfully!'
          );
          customThemeService.initGlobalCSS();
          setLogo($scope.theme.general.CRM_LogoDocId);
          if (Number($scope.theme.general.CRM_LogoDocId) > 0) {
            contactService
              .documentImageDataURIGet($scope.theme.general.CRM_LogoDocId, 300)
              .then(response => {
                customThemeService.setBrandingLogo(response.data);
              });
          } else {
            customThemeService.setBrandingLogo(null);
          }
        });
      } else {
        toaster.pop(
          'success',
          'Done',
          'Custom theme has been updated successfully!'
        );
      }
    };
  },
]);
