export default class EmailCcFieldCtrl {
  $onChanges() {
    this.updateCcList();
  }

  updateCcList() {
    this.cc = this.selectedCc ? [...this.selectedCc] : [];
  }

  onRemove(cc) {
    this.onRemoveCc && this.onRemoveCc({ cc });
    this.updateCcList();
  }
}
