import template from './insurancePipelineColumnSorter.html';
import controller from './insurancePipelineColumnSorterCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    column: '<',
    sorting: '<',
    onUpdate: '&',
  },
};
