import template from './familyContainer.html';
import controller from './familyContainerCtrl';
import './styles.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAdviser: '<',
    isPersons: '<',
    searchClientList: '<',
    panelNumber: '<',
    isAddingDisable: '<',
    partyType: '<',
    existingFamilies: '<',
    requesterType: '<',
    listKey: '@',
    sectionLabel: '@',
    onOpenAddFamilyModal: '&',
    onOpenEditFamilyModal: '&',
    clientList: '<',
    onRemoveClient: '&',
    onSearchClient: '&',
    onSelectClient: '&',
  },
};
