import angular from 'angular';

angular
  .module('app')
  .controller('ForSetRateModalCtrl', function ForSetRateModalCtrl(
    $scope,
    $uibModalInstance,
    setFavouriteProductRatesInMobile,
    $state,
    rateType,
    loanStructure,
    productId,
    oldRate,
    newRate,
    $timeout
  ) {
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.rateType = rateType;
    $scope.selectedProduct = {};
    $scope.selectedProduct.rateType = `${$scope.rateType}Rate`;
    $scope.selectedProduct.LoanStructureID = loanStructure.LoanStructureID;
    $scope.selectedProduct.ProductID = productId;
    $scope.selectedProduct.OldRate = oldRate;
    $scope.selectedProduct.NewRate = newRate;

    $scope.setFavouriteProductRatesInMobile = function(
      loanStructureId,
      productFavouriteId,
      rateFavouriteType,
      newFavouriteRate
    ) {
      setFavouriteProductRatesInMobile(
        loanStructureId,
        productFavouriteId,
        rateFavouriteType,
        newFavouriteRate
      );
      $timeout(() => {
        $scope.cancel();
      }, 1000);
    };
  });
