import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller('OnlineTrainingCtrl', function OnlineTrainingCtrl(
    loanToolsService
  ) {
    const vm = this;
    vm.onlineTrainingList = [];
    const addNameToDocumentUrlList = onlineTrainingDocumentUrlList => {
      const documentUrlWithName = [];

      _.forEach(onlineTrainingDocumentUrlList, object => {
        const startTrimAt = object.lastIndexOf('/') + 1;
        const endTrimAt = object.length - 1;
        const documentName = object
          .substr(startTrimAt, endTrimAt)
          .split('+')
          .join(' ');

        documentUrlWithName.push({
          DocumentName: documentName,
          DocumentUrl: object,
        });
      });

      return documentUrlWithName;
    };

    const populateOnlineTrainingList = () => {
      vm.onlineTrainingList = null;

      loanToolsService.resourceTrainingGet().then(response => {
        vm.onlineTrainingList = response.data;

        _.forEach(vm.onlineTrainingList, object => {
          object.DocumentUrlList = addNameToDocumentUrlList(
            object.DocumentUrlList
          );
        });
      });
    };

    const init = () => {
      populateOnlineTrainingList();
    };
    /* calling init */
    init();
  });
