import './style.scss';
import template from './customCrmMultiSelect.html';
import CustomCrmMultiSelectCtrl from './customCrmMultiSelectCtrl';

export default {
  template,
  bindings: {
    disableEdit: '<',
    inputModel: '<',
    outputModel: '=',
    buttonLabels: '<',
    itemLabels: '<',
    tickProperty: '@',
    maxLabels: '<',
    onChange: '&',
    onSelectAll: '&',
    onSelectNone: '&',
    onRefresh: '&',
    primaryProperty: '@',
    groupProperty: '<',
    groupLabel: '@',
    name: '@',
    required: '<',
    isSelectAllDefault: '<',
    disabled: '<',
    placeholder: '@',
    isError: '<',
  },
  controller: CustomCrmMultiSelectCtrl,
  controllerAs: 'vm',
};
