export const MERGE_FIELDS = {
  LENDER_NAME: 'Lender Name',
  LOAN_REF_NUMBER: 'Loan Reference Number',
  TOTAL_LOAN_AMOUNT: 'Total Loan Amount',
  PRE_APPROVAL_EXPIRY: 'Pre-Approval Expiry',
  FULL_ADDR_BLOCK_HOME: 'Full Address block - Home',
  FULL_ADDR_BLOCK_MAILING: 'Full Address block - Mailing',
  PREFERRED_NAME: 'Preferred Name',
  ADVISER_NAME: 'Adviser Name',
  BUSINESS_NAME: 'Business Name',
  LOAN_STRUCTURE: {
    INTEREST_RATE: 'Loan Structure Interest Rate',
    REPAYMENT_AMOUNT: 'Loan Structure Repayment Amount',
    LOAN_AMOUNT: 'Loan Structure Loan Amount',
  },
};
