class LmiGoalTypeCtrl {
  toggleGoalTypeHover(bool) {
    this.isHoverInfo = bool;
  }

  $onInit() {
    this.isHoverInfo = false;
  }
}

export default LmiGoalTypeCtrl;
