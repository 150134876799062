import angular from 'angular';

class CKEditorConfigService {
  constructor() {}

  getCommonEmailEditorConfig() {
    return {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] },
        { items: ['JustifyLeft'] },
        { items: ['BulletedList', '-'] },
        { items: ['Link'] },
        { items: ['Image'] },
        { items: ['Table'] },
        { items: ['Paste', '-'] },
        { items: ['Smiley'] },
        { items: ['-'] },
        '/',
        { items: ['Format'] },
        { items: ['Font'] },
        { items: ['FontSize', '-'] },
        { items: ['Bold'] },
        { items: ['Italic'] },
        { items: ['Underline'] },
        { items: ['Subscript'] },
        { items: ['Superscript'] },
      ],
    };
  }

  getCustomerCareEmailEditorConfig() {
    return {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] },
        { items: ['JustifyLeft'] },
        { items: ['BulletedList', '-'] },
        { items: ['Link'] },
        { items: ['Image'] },
        { items: ['Table'] },
        { items: ['-'] },
        '/',
        { items: ['Format'] },
        { items: ['Font'] },
        { items: ['FontSize', '-'] },
        { items: ['Bold'] },
        { items: ['Italic'] },
        { items: ['Underline'] },
      ],
    };
  }
}

angular
  .module('app')
  .factory('ckEditorConfigService', () => new CKEditorConfigService());
