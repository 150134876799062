import angular from 'angular';
import swal from 'sweetalert';
import { ENTITY_TYPE } from 'Common/constants/entityType';
import { ONBOARDING_ROLE_ID } from 'Common/constants/onboardingRoles';
import { BRANDING_CATEGORY_TYPE } from 'Common/constants/brandingCategories';
import { CONTACT_TYPE } from 'Common/constants/contactType';
import { setBooleanToInt } from 'Common/utilities/typeConversion';

import { setCurrrentDate, setDateFormat } from 'Common/utilities/date';

angular
  .module('app')
  .controller('IndividualDetailsCtrl', function IndividualDetailsCtrl(
    $scope,
    $filter,
    $stateParams,
    $timeout,
    $window,
    SweetAlert,
    corporateService,
    onboardingService,
    contactService,
    queryService,
    mapService,
    generalService,
    onboardingDataService,
    broadcastDataService,
    commonFnService,
    dashboardService,
    modelDataService,
    addressModalService,
    toaster,
    dataService
  ) {
    $scope.familyId = $stateParams.familyId;
    $scope.clientId = $stateParams.clientId;
    $scope.adviserOrgId = 0;
    $scope.isShareHolderShowForm = false;
    $scope.isPreferredEmailInValid = false;
    $scope.emailLoanMarket = '@loanmarket.com.au';
    $scope.currentRecruitmentContact = {};
    $scope.recruitmentContactSet = {
      isShareHolderValue: 0,
      isCompanyNonDirectorValue: 0,
    };
    $scope.reportingCountryId = 0;
    $scope.recruitmentContactSetShareHolder = {};
    $scope.addressOfBusinessCardArr = [];
    $scope.countBusinessPartner = 0;
    $scope.countDirector = 0;
    $scope.countShareholder = 0;
    $scope.addressId = '';
    $scope.nameTitles = ['Mr.', 'Ms.', 'Mrs.'];
    $scope.altInputFormats = [];

    $scope.entityType = '';
    $scope.entityTypeIsCompany = false;
    $scope.entityTypeIsPartnership = false;
    $scope.entityTypeIsSoleTrader = false;
    $scope.isFranchisee = false;
    $scope.isContractor = false;
    $scope.isEmployee = false;
    $scope.isPartner = false;
    $scope.isDirector = false;
    $scope.isOwnBrand = false;
    $scope.dateInitValue = '0000-00-00';

    $scope.recruitmentContactSet = {
      homeAddress: {},
      businessAddress: {},
      mailingAddress: {},
    };
    $scope.phoneMask = '';
    $scope.mobileMask = '';
    $scope.isEmailExistsObj = {};
    $scope.isMapLoaded = false;
    const validateEmail = email => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };
    const _checkifEmailMatches = email => {
      const regexPattern = /([^@]+)(@.+)?/g;
      const match = regexPattern.exec(email);
      if (match) {
        if (match[2]) {
          return match[1];
        }
        return email + $scope.emailLoanMarket;
      }
      return email;
    };
    const _checkEmailValidity = data => {
      queryService.queryGet(
        $scope,
        onboardingService,
        'getCheckIfEmailExists',
        [_checkifEmailMatches(data.EmailTmp), data.FamilyId],
        () => {
          if ($scope.getCheckIfEmailExists > 0) {
            data.isEmailExists = false;
          }
          data.isEmailExists = true;
        }
      );
      data.isEmailValid = validateEmail(_checkifEmailMatches(data.EmailTmp));
    };

    $scope.homeAutoAddresss = function(recruitmentContact) {
      generalService
        .placeSearch(recruitmentContact.homeAddress)
        .then(respond => {
          $scope.homeAutoAddressDetails = respond.data;
        });
    };
    $scope.selectHomeAddress = function(homeAddress, recruitmentContact) {
      recruitmentContact.homeAddress = homeAddress;
      corporateService
        .familyAddressSet($scope.familyId, 0, homeAddress, 0, 0, 0)
        .then(() => {});
      $scope.closeAutoHomeAddress();
    };
    $scope.closeAutoHomeAddress = function() {
      $scope.homeAutoAddressDetails = [];
    };
    $scope.showAddForm = function(contacttype) {
      if (contacttype === CONTACT_TYPE.BUSSINESS_PARTNER) {
        $scope.isAdditionalBusinessPartnerValue = contacttype;
      }
    };
    $scope.$watch('homeAddress', newValue => {
      if (newValue) {
        queryService.querySet(corporateService, 'familyAddressSet', [
          newValue.FamilyId,
          newValue,
          true,
          true,
        ]);
      }
    });
    $scope.setFamilyAddress = function(data) {
      $scope.currentRecruitmentContact = data;
    };

    $scope.setLoanMarketEmail = function(data) {
      if (data) {
        if (data.FirstName && data.LastName) {
          data.EmailTmp = `${data.FirstName}.${data.LastName}`;
          data.EmailTmp = data.EmailTmp.toLowerCase();
          data.Email = _checkifEmailMatches(data.EmailTmp);
          _checkEmailValidity(data);
        }
        if (!data.isEmailExists && !data.isEmailValid) {
          data.Email = _checkifEmailMatches(data.EmailTmp);
        }
      }
    };

    const populatePreferredEmail = email => {
      const preferredEmailSplit = email.split('@');
      if (preferredEmailSplit.length) {
        return preferredEmailSplit[0];
      }
      return email;
    };

    $scope.onChangeSuggestEmail = function(givenName, surname, data, isCreate) {
      let emailContainer = '';
      if (!$scope.isOwnBrand) {
        if (givenName || surname) {
          if (data) {
            data.EmailTmp = onboardingDataService.suggestPreferredEmail(
              givenName,
              surname
            );
            emailContainer = data.EmailTmp;
          } else {
            data.EmailTmp = onboardingDataService.suggestPreferredEmail(
              givenName,
              surname
            );
            emailContainer = data.EmailTmp;
          }
        }

        if (isCreate) {
          $scope.checkCreateIfEmailExist(emailContainer);
        }
      }
    };

    const saveIndividualAddress = (
      addressObj,
      familyId,
      clientId,
      isMailing,
      isBusiness
    ) => {
      dashboardService.addressDetailsSet(addressObj).then(response => {
        if (response && response.data) {
          corporateService.familyAddressSet(
            familyId,
            clientId,
            response.data,
            isMailing,
            isBusiness,
            false
          );
        }
      });
    };

    $scope.setPostalAdddressToBussAdd = (isSamePostal, data) => {
      if (!isSamePostal || !data || !data.mailingAddress) return;
      if (!data.businessAddress) {
        data.businessAddress = {};
      }
      data.businessAddress.formatted_address =
        data.mailingAddress.formatted_address || '';
      saveIndividualAddress(
        data.businessAddress,
        data.FamilyId,
        0,
        false,
        true
      );
    };

    $scope.selectHomeAddress = function(
      addressObj,
      familyId,
      isMailing,
      isBusiness
    ) {
      saveIndividualAddress(addressObj, familyId, 0, isMailing, isBusiness);
    };

    $scope.addressValueOnBlur = function(
      address,
      familyId,
      isMailing,
      isBusiness
    ) {
      const addressObjTmp = modelDataService.addressModelData();
      if (address) {
        addressObjTmp.formatted_address = address;
        saveIndividualAddress(
          addressObjTmp,
          familyId,
          0,
          isMailing,
          isBusiness
        );
      }
    };

    $scope.updateDetails = function(data, formName, inputName) {
      if (formName && inputName) {
        if (
          !onboardingDataService.checkFormAndInputIsValid(formName, inputName)
        ) {
          return false;
        }
      }
      if (!data.DOB) data.DOB = $scope.dateInitValue;
      let familyId = 0;
      $scope.timeoutIndividualAssistance = $timeout(() => {
        familyId = data.FamilyId || 0;
        let email = '';
        data.DOB = data.DOBTmp
          ? commonFnService.formatDateString(data.DOBTmp)
          : '1900-01-01';
        if (!$scope.isOwnBrand) {
          if (data.EmailTmp) {
            email = `${data.EmailTmp}@loanmarket.com.au`;
          }
        }
        onboardingDataService.checkEmail(email, familyId).then(isExist => {
          data.isPreferredEmailInValid = isExist;
          if (!data.isPreferredEmailInValid) {
            // set invalid field
            angular.element(`#email${familyId}`).removeClass('is-required');
            angular.element(`#email${familyId}`).removeClass('invalid');
            // set email to be saved
            data.Email = `${data.EmailTmp}@loanmarket.com.au`;
            if (data.ReportingCountrySelected) {
              data.ReportingCountryId = data.ReportingCountrySelected.CountryId;
            }
            data.HaveTwoYrsIndustryExp = data.HaveTwoYrsIndustryExpValue;
            data.isShareHolder = data.isShareHolderValue;
            data.isWriting = setBooleanToInt(data.isWritingValue);
            data.isBusinessPartner = 0;
            data.isCompanyDirector = 0;
            /* set data */
            onboardingService.setRecruitmentContact(0, data);
            generalService.sendPasswordResetLinkEmailSet(
              data.FamilyId,
              data.ClientID
            );

            switch (data.ContactType) {
              case data.ContactType === CONTACT_TYPE.BUSSINESS_PARTNER:
                data.isBusinessPartner = 1;
                data.RoleId = ONBOARDING_ROLE_ID.PARTNER;
                break;
              case data.ContactType === CONTACT_TYPE.DIRECTOR:
                data.isCompanyDirector = 1;
                data.RoleId = ONBOARDING_ROLE_ID.DIRECTOR;
                break;
              case data.ContactType === CONTACT_TYPE.SHARE_HOLDER:
                data.isShareHolder = 1;
                data.RoleId = ONBOARDING_ROLE_ID.DIRECTOR;
                break;
              default:
                break;
            }
            if (
              data.ContactType !== CONTACT_TYPE.ADVISER_STR &&
              data.ContactType !== CONTACT_TYPE.PRINCIPAL_ADVISER
            ) {
              onboardingService.familyPartnershipSet(
                data.FamilyId,
                data.FamilyId,
                data.ClientID,
                data.isWriting,
                data.isBusinessPartner,
                data.isCompanyDirector,
                data.isShareHolder,
                1,
                data.FamilyPartnershipID
              );
            }
          } else {
            angular.element(`#email${familyId}`).addClass('invalid');
          }
        });
      }, 1000);
    };

    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeoutIndividualAssistance);
    });

    $scope.suggestEmail = function(data) {
      if (data.FirstName && data.LastName) {
        data.EmailTmp = `${data.FirstName.toLowerCase()}.${data.LastName.toLowerCase()}`;
      }
    };

    $scope.hideFormDetails = function(data) {
      if (data.ContactType === CONTACT_TYPE.BUSSINESS_PARTNER) {
        data.isAdditionalBusinessPartnerValue = 'No';
      } else if (data.ContactType === CONTACT_TYPE.DIRECTOR) {
        data.isCompanyAddDirectorValue = 'No';
      }
    };
    $scope.getAddressValue = function(address) {
      $scope.addressId = address;
    };

    $scope.checkCreateIfEmailExist = email => {
      let emailTmp = '';
      $scope.emailCheckerTimeout = $timeout(() => {
        if (!$scope.isOwnBrand) {
          emailTmp = `${email}@loanmarket.com.au`;
        }
        onboardingDataService.checkEmail(emailTmp, 0).then(isExist => {
          $scope.isPreferredEmailInValid = isExist;
          if (!$scope.isPreferredEmailInValid) {
            // element check
            // set invalid field
            angular.element('#email0').removeClass('is-required');
            angular.element('#email0').removeClass('invalid');
          } else {
            // element check
            // set invalid field
            angular.element('#email0').addClass('invalid');
          }
        });
      }, 1000);
      $timeout.cancel($scope.emailCheckerTimeout);
    };

    const saveRecruitmentDetails = (data, contactType, dataParent) => {
      // set email to be saved
      data.ClientID = 0;
      data.FamilyId = 0;
      data.isWriting = setBooleanToInt(data.isWritingValue);
      data.isShareHolder = data.isShareHolderValue;
      data.HaveTwoYrsIndustryExp = data.HaveTwoYrsIndustryExpValue;
      data.ContactType = contactType || '';
      data.isBusinessPartner = contactType === CONTACT_TYPE.BUSSINESS_PARTNER;
      data.isCompanyDirector = contactType === CONTACT_TYPE.DIRECTOR;
      data.isShareHolder = contactType === CONTACT_TYPE.SHARE_HOLDER;
      onboardingService
        .setRecruitmentContact(onboardingDataService.getFamilyId(), data)
        .then(responseRecruitment => {
          data.FamilyId = $scope.familyId;
          data.ClientID = responseRecruitment.data;
          if (data.mailingAddress) {
            if (data.mailingAddress.formatted_address) {
              saveIndividualAddress(
                data.mailingAddress,
                0,
                data.ClientID,
                true,
                false
              );
              if (data.isSameAsPostal) {
                data.businessAddress.formatted_address =
                  data.mailingAddress.formatted_address;
                saveIndividualAddress(
                  data.businessAddress,
                  0,
                  data.ClientID,
                  false,
                  true
                );
              }
            }
          }
          if (data.homeAddress.formatted_address) {
            saveIndividualAddress(
              data.homeAddress,
              0,
              data.ClientID,
              false,
              false
            );
          }

          onboardingService
            .familyPartnershipSet(
              data.FamilyId,
              0,
              data.ClientID,
              data.isWriting,
              data.isBusinessPartner,
              data.isCompanyDirector,
              data.isShareHolder,
              1,
              0
            )
            .then(() => {
              swal('Successfully', `Added ${contactType}`, 'success');
              $scope.recruitmentContactSet = {};
              if (contactType === CONTACT_TYPE.BUSSINESS_PARTNER) {
                dataParent.isAdditionalBusinessPartnerValue = false;
              } else if (contactType === CONTACT_TYPE.DIRECTOR) {
                dataParent.isCompanyAddDirectorValue = false;
              } else if (contactType === CONTACT_TYPE.SHARE_HOLDER) {
                $scope.recruitmentContactSetShareHolder = {};
                dataParent.isShareHolderAdd = false;
                $scope.isShareHolderShowForm = false;
              }
              $scope.getRecruitmentContact.push(data);
            });
        });
    };

    $scope.createDetails = function(data, dataParent, contactType, formName) {
      if (formName.$valid) {
        let email = '';
        $scope.timeOutCreateDetails = $timeout(() => {
          if (!email) {
            saveRecruitmentDetails(data, contactType, dataParent);
          } else {
            email = data.EmailTmp;
          }
          onboardingDataService.checkEmail(email, 0).then(isExist => {
            $scope.isPreferredEmailInValid = isExist;
            if (!$scope.isPreferredEmailInValid) {
              // set invalid field
              // email invalid
              angular.element('#email0').removeClass('is-required');
              angular.element('#email0').removeClass('invalid');
              data.SecondaryEmail = email;
              saveRecruitmentDetails(data, contactType, dataParent);
            } else {
              angular.element('#email0').addClass('invalid');
            }
          });
        }, 1000);
      } else {
        onboardingDataService.getTabsRequiredField(
          `.tab-pane.active form[name=${formName.$name}]`,
          3
        );
        swal('Error', 'Please fill in all the field(s)!', 'error');
      }
    };

    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeOutCreateDetails);
    });

    $scope.selectedAnswers = function(question) {
      $scope.setQuestioniare(question);
      if (question.answer) {
        return 1;
      }
      return 0;
    };

    $scope.checkIsShareHolder = isShareHolder => {
      $scope.isShareHolderShowForm = isShareHolder;
    };

    $scope.formatDate = date => {
      return new Date(date);
    };

    const initializeOnSetAddressField = () => {
      // set adding of address for Create
      const addressObj = {
        homeAddress: modelDataService.addressModelData(),
        businessAddress: modelDataService.addressModelData(),
        mailingAddress: modelDataService.addressModelData(),
      };
      addressObj.homeAddress.isMailing = false;
      addressObj.homeAddress.isBusiness = false;
      addressObj.businessAddress.isMailing = false;
      addressObj.businessAddress.isBusiness = true;
      addressObj.mailingAddress.isMailing = true;
      addressObj.mailingAddress.isBusiness = false;
      $scope.recruitmentContactSet = { ...addressObj };
      $scope.recruitmentContactSetShareHolder = { ...addressObj };
      $scope.recruitmentContactSet.isSameAsPostal = false;
    };

    const getFamilyAddress = value => {
      corporateService.familyAddressGet(value.FamilyId).then(address => {
        if (address && address.data) {
          const addressTmp = commonFnService.setAddressTypes(address.data);
          if (addressTmp) {
            value.homeAddress = { ...addressTmp.home };
            value.businessAddress = { ...addressTmp.business };
            value.mailingAddress = { ...addressTmp.mailing };
          }
          value.homeAddress =
            typeof value.homeAddress === 'undefined'
              ? modelDataService.addressModelData()
              : value.homeAddress;
          value.businessAddress =
            typeof value.businessAddress === 'undefined'
              ? modelDataService.addressModelData()
              : value.businessAddress;
          value.mailingAddress =
            typeof value.mailingAddress === 'undefined'
              ? modelDataService.addressModelData()
              : value.mailingAddress;
        }
        const checkAddressesValue =
          value.businessAddress.formatted_address &&
          value.mailingAddress.formatted_address;
        if (checkAddressesValue) {
          value.isSameAsPostal =
            value.businessAddress.formatted_address ===
            value.mailingAddress.formatted_address;
        }
        $scope.familyAddressGet = address.data;
      });
    };

    $scope.launchAddressModal = (value, isShareHolder) => {
      const size = 'md';
      value.FamilyId = value.FamilyId || 0;
      const contactOnboardingData = isShareHolder
        ? $scope.recruitmentContactSetShareHolder
        : $scope.recruitmentContactSet;
      const modalInstance = addressModalService.launchAddressModal(
        size,
        value.FamilyId,
        false,
        contactOnboardingData
      );
      modalInstance.result.then(response => {
        $scope.timeOutAdviserInstance = $timeout(() => {
          $scope.recruitmentContactSet.isSameAsPostal =
            response.mailingAddress.isSameAsPrimary;
          if (value.FamilyId > 0) {
            getFamilyAddress(value);
            toaster.pop('success', 'Successfully Updated', 'Address');
          } else {
            if (isShareHolder) {
              $scope.recruitmentContactSetShareHolder.homeAddress.formatted_address =
                response.homeAddress.formatted_address;
              $scope.recruitmentContactSetShareHolder.homeAddress.isBusiness = false;
              $scope.recruitmentContactSetShareHolder.homeAddress.isMailing = false;
            }

            $scope.recruitmentContactSet.homeAddress.formatted_address =
              response.homeAddress.formatted_address;
            $scope.recruitmentContactSet.homeAddress.isBusiness = false;
            $scope.recruitmentContactSet.homeAddress.isMailing = false;

            $scope.recruitmentContactSet.mailingAddress.formatted_address =
              response.mailingAddress.formatted_address;
            $scope.recruitmentContactSet.mailingAddress.isBusiness = false;
            $scope.recruitmentContactSet.mailingAddress.isMailing = true;

            $scope.recruitmentContactSet.businessAddress.formatted_address =
              response.businessAddress.formatted_address;
            $scope.recruitmentContactSet.businessAddress.isBusiness = true;
            $scope.recruitmentContactSet.businessAddress.isMailing = false;
          }
        }, 500);
      });
    };

    $scope.$on('$destory', () => {
      $timeout.cancel($scope.timeOutAdviserInstance);
    });

    const initialize = () => {
      initializeOnSetAddressField();
      /* set phonemask */
      $scope.phoneMask = broadcastDataService.getPhoneFormat(
        onboardingDataService.getCountryId()
      );
      /* set mobileMask */
      $scope.mobileMask = broadcastDataService.getMobileFormat(
        onboardingDataService.getCountryId()
      );

      $scope.isOwnBrand = onboardingDataService.isOwnBrand(
        BRANDING_CATEGORY_TYPE.OWN_BRAND
      );
      $scope.dateFormat = setDateFormat();
      $scope.currentDate = setCurrrentDate();
      $scope.altInputFormats = dataService.getDateValidityFormats();

      $scope.addressOfBusinessCardArr = onboardingDataService.getAddressForBusinessCards();
      $scope.reportingCountryId = onboardingDataService.getCountryId();
      $scope.entityType = onboardingDataService.getUserCurrentEntityType();
      $scope.entityTypeIsCompany =
        onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.COMPANY_TRUST
        );
      $scope.entityTypeIsPartnership =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP_TRUST
        );
      $scope.entityTypeIsSoleTrader =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER_TRUST
        );

      onboardingDataService
        .getOnboardingResponse(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(response => {
          if (!response || !response.RoleId) return;
          $scope.isFranchisee = onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.FRANCHISEE
          );
          $scope.isContractor = onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.CONTRACTOR
          );
          $scope.isEmployee = onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.EMPLOYEE
          );
          $scope.isPartner = onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.PARTNER
          );
          $scope.isDirector = onboardingDataService.isOnboardingUserRole(
            response.RoleId,
            ONBOARDING_ROLE_ID.DIRECTOR
          );
        });

      onboardingService
        .getOnboardingAdviserOrganisationDetails(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(response => {
          if (response && response.data) {
            $scope.adviserOrgId = response.data.AdviserOrganisationId;
          }
        });

      onboardingService
        .getRecruitmentContact(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(response => {
          if (response && response.data) {
            let count = 0;
            let countDirector = 0;
            let countShareholder = 0;
            let familyIds = [];
            const dataIndividualDetails = response.data;
            dataIndividualDetails.forEach(value => {
              value.DOBTmp = value.DOB ? $scope.formatDate(value.DOB) : '';
              familyIds = [...familyIds, value.FamilyId];
              value.LanguagesSpoken =
                value.LanguagesSpoken === '0' || !value.LanguagesSpoken
                  ? ''
                  : value.LanguagesSpoken;
              value.HaveTwoYrsIndustryExpValue = setBooleanToInt(
                value.HaveTwoYrsIndustryExp
              );
              value.isBusinessPartnerValue = setBooleanToInt(
                value.isBusinessPartner
              );
              value.isWritingValue = value.isWriting ? 'true' : 'false';
              value.isShareHolderValue = setBooleanToInt(value.isShareHolder);
              value.isCompanyDirectorValue = setBooleanToInt(
                value.isCompanyDirector
              );
              if (value.ReportingCountryId) {
                contactService
                  .getOperatingCountry()
                  .then(reportingCountryResp => {
                    if (reportingCountryResp && reportingCountryResp.data) {
                      angular.forEach(reportingCountryResp.data, address => {
                        if (value.ReportingCountryId === address.CountryId) {
                          value.ReportingCountrySelected = address;
                        }
                      });
                    }
                  });
              } else {
                value.ReportingCountrySelected = '';
              }
              if (value.ContactType === CONTACT_TYPE.BUSSINESS_PARTNER) {
                count++;
              } else if (value.ContactType === CONTACT_TYPE.DIRECTOR) {
                countDirector++;
              } else if (value.ContactType === CONTACT_TYPE.SHARE_HOLDER) {
                countShareholder++;
              }

              $scope.countDirector = countDirector;
              $scope.countBusinessPartner = count;
              $scope.countShareholder = countShareholder;

              if (!value.SecondaryEmail) value.SecondaryEmail = value.Email;
              const emailTmp = value.Email
                ? value.Email
                : `${value.FirstName}.${value.LastName}@loanmarket.com.au`;
              value.EmailTmp = emailTmp ? populatePreferredEmail(emailTmp) : '';
              if (value.FamilyID && value.EmailTmp) {
                onboardingDataService
                  .checkEmail(value.EmailTmp, value.FamilyID)
                  .then(isExist => {
                    if (!isExist) {
                      value.isPreferredEmailInValid = true;
                    } else {
                      value.isPreferredEmailInValid = false;
                    }
                  });
              }
            });
            onboardingDataService.setFamilyIds(familyIds);
            $scope.getRecruitmentContact = response.data;
          }
        })
        .then(() => {
          $scope.getRecruitmentContact.forEach(value => {
            getFamilyAddress(value);
          });
          const dataTmp = {
            individualDetails: $scope.getRecruitmentContact,
          };
          onboardingDataService.setAllOnboardingDataForPreview(dataTmp);
        });
    };
    initialize();
  });
