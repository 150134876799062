export default class ViewComfortDropdownCtrl {
  $onInit() {
    this.options = [
      { key: 'Compact', value: 1 },
      { key: 'Comfortable', value: 0 },
    ];
  }

  $onChanges(changes) {
    this.view = changes.selectedView
      ? changes.selectedView.currentValue
      : this.view;
  }
}
