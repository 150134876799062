import angular from 'angular';
import _ from 'lodash';

class UnmatchedCommissionDetailsCtrl {
  constructor(
    $uibModalInstance,
    $timeout,
    contactService,
    utilitiesService,
    commissionDetails,
    typeList,
    dateSettings,
    commissionTypesList,
    brokerId
  ) {
    'ngInject';

    this.$uibModalInstance = $uibModalInstance;
    this.$timeout = $timeout;
    this.contactService = contactService;
    this.utilitiesService = utilitiesService;
    this.commissionDetails = commissionDetails;
    this.typeList = typeList;
    this.dateSettings = dateSettings;
    this.commissionTypesList = commissionTypesList;
    this.brokerId = brokerId;

    this.views = [
      {
        ID: 'commission_details',
        Title: 'Commission Details',
        IsMain: true,
      },
      {
        ID: 'match_commission',
        Title: 'Select a Loan Structure to Match',
      },
    ];
  }

  $onInit() {
    this.setCurrentView('commission_details');
  }

  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  getInitials(name) {
    return this.utilitiesService.filterInitialOneString(name);
  }

  searchClient(delay) {
    if (this.searchClientFilter && this.searchClientFilter.length >= 3) {
      if (delay) {
        this.$timeout(() => {
          this.getSearchResults(true);
        }, 1500);
      } else {
        this.getSearchResults();
      }
    } else if (!this.searchClientFilter || !this.searchClientFilter.length) {
      this.clearSearchResults();
    }
  }

  getSearchResults(fromAutoSearch) {
    if (
      !fromAutoSearch ||
      !this.isSearchingClient ||
      this.searchClientFilter !== this.previousFilter
    ) {
      this.isSearchingClient = true;
      this.previousFilter = this.searchClientFilter;
      this.contactService
        .searchFamily(this.searchClientFilter, this.brokerId)
        .then(response => {
          this.isSearchingClient = false;
          if (response.data) {
            this.searchClientResults = response.data.FamilyList;
            this.showSearchResults = true;
          }
        });
    }
  }

  clearSearchResults() {
    this.showSearchResults = false;
    if (this.searchClientResults && this.searchClientResults.length) {
      this.searchClientResults.length = 0;
    }
    this.searchClientFilter = '';
    this.selectedUnconfirmedMatch = 0;
  }

  setSelectedClientAsMatchFor(commission, selectedClient) {
    if (selectedClient) {
      this.clearSearchResults();
      this.selectedClientId = selectedClient.FamilyID;
      this.setCurrentView('match_commission');
    }
  }

  onCommissionMatched() {
    this.goBack();
  }

  goBack() {
    this.setCurrentView('commission_details');
  }

  setCurrentView(id) {
    this.currentView = _.find(this.views, view => view.ID === id);
  }
}

angular
  .module('app')
  .controller('UnmatchedCommissionDetailsCtrl', UnmatchedCommissionDetailsCtrl);
