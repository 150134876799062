import angular from 'angular';

angular
  .module('app')
  .directive('disableButton', function disableButton($window) {
    return {
      restrict: 'A',
      scope: {
        toDisableBtn: '=',
      },
      link(scope) {
        scope.toDisableBtn = true;

        scope.$watch(() => {
          const buttonChecker = $window.document.querySelectorAll(
            '.selected-benefits.opened'
          );
          if (buttonChecker.length > 0) {
            scope.toDisableBtn = false;
          } else {
            scope.toDisableBtn = true;
          }
        });
      },
    };
  });
