import swal from 'sweetalert';
import { uniqBy } from 'lodash';
import { showActionConfirmation, displayError } from 'Common/utilities/alert';
import {
  DEFAULT_EXISTING_INSURANCE,
  DEFAULT_INSURANCE_FILTER,
} from 'Common/default/insurance';

const EXISTING_BENEFIT_TYPE = {
  LIFE_COVER: 1,
  HEALTH_COVER: 3,
  INCOME_PROTECTION: 10,
  MORTAGE_REPAYMENT: 7,
  WAIVER_OF_PREMIUM: 51,
  REDUNDANCY: 11,
  TPD: 9,
  TRAUMA_COVER: 2,
  FAMILY_PROTECTION: 20,
};

export function getBenefitLogo(benefitId) {
  switch (benefitId) {
    case EXISTING_BENEFIT_TYPE.LIFE_COVER:
      return '/assets/images/insuranceTab/life-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.HEALTH_COVER:
      return '/assets/images/insuranceTab/health-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.INCOME_PROTECTION:
      return '/assets/images/insuranceTab/income-protection-small.svg';
    case EXISTING_BENEFIT_TYPE.MORTAGE_REPAYMENT:
      return '/assets/images/insuranceTab/mortgage-repayment-small.svg';
    case EXISTING_BENEFIT_TYPE.WAIVER_OF_PREMIUM:
      return '/assets/images/insuranceTab/premium-waiver-small.svg';
    case EXISTING_BENEFIT_TYPE.REDUNDANCY:
      return '/assets/images/insuranceTab/redundancy-cover-small.svg';
    case EXISTING_BENEFIT_TYPE.TPD:
      return '/assets/images/insuranceTab/total-permanent-small.svg';
    case EXISTING_BENEFIT_TYPE.TRAUMA_COVER:
      return '/assets/images/insuranceTab/trauma-small.svg';
    case EXISTING_BENEFIT_TYPE.FAMILY_PROTECTION:
      return '/assets/images/insuranceTab/family-protection-small.svg';
    default:
      return null;
  }
}

export default class ExistingInsuranceCtrl {
  constructor(
    $state,
    $uibModal,
    contactInsuranceService,
    insurancePipelineService,
    insuranceModalApplicationService,
    insuranceSharedData
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.contactInsuranceService = contactInsuranceService;
    this.insurancePipelineService = insurancePipelineService;
    this.insuranceModalApplicationService = insuranceModalApplicationService;
    this.insuranceSharedData = insuranceSharedData;
  }

  $onInit() {
    this.tabName = this.type;
    this.insuranceProvider = [];
    this.insuranceFamilyPoliciesList = [];
    this.existing = { ...DEFAULT_EXISTING_INSURANCE };
    this.existingInsuranceList = [];
    this.existingIsuranceModalData = [];
    this.insuranceProviderList = [];
    this.insurancePolicyList = [];
    this.filter = { ...DEFAULT_INSURANCE_FILTER };
    this.familyId = parseInt(this.$state.params.familyId, 10) || 0;

    this.loadProviders();
    this.loadInsurances();
    this.loadFiltersList();
  }

  loadProviders() {
    this.contactInsuranceService
      .insuranceQuoteProviderGet(0, 0)
      .then(response => {
        if (!response.data || !response.data.length) return;
        this.insuranceProvider = [
          { ProviderName: 'Show All Insurers', ProviderId: 0 },
          ...response.data,
        ];
      });
  }

  loadFamilyPolicies() {
    this.contactInsuranceService
      .insuranceFamilyPoliciesGet(this.familyId, this.type)
      .then(({ data }) => {
        this.insuranceFamilyPoliciesList = [
          { PolicyNumber: 'Show All Policies', PolicyID: '' },
          ...(data || []),
        ];
        this.insurancePolicyList = [...this.insuranceFamilyPoliciesList];
      });
  }

  loadInsurances(providerId = 0, policyNumber = '') {
    this.isLoading = true;
    this.contactInsuranceService
      .insuranceDetailsListGet(
        this.familyId,
        this.type,
        providerId,
        policyNumber
      )
      .then(response => {
        this.existingInsuranceList = response.data
          ? response.data.map(insurance => {
              return {
                ...insurance,
                logo: getBenefitLogo(insurance.BenefitTypeID),
              };
            })
          : [];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  loadFiltersList() {
    this.contactInsuranceService
      .insuranceFamilyProviderGet(this.familyId, this.type)
      .then(({ data }) => {
        this.insuranceProviderList = [
          { ProviderName: 'Show All Insurers', ProviderID: 0 },
          ...uniqBy(data, 'ProviderID'),
        ];
      });
    this.loadFamilyPolicies();
  }

  delete(benefit) {
    showActionConfirmation(
      'Are you sure?',
      'This insurance will be removed from your list',
      confirmed => {
        if (!confirmed) return;
        const deletePromise = benefit.PolicyID
          ? this.contactInsuranceService.insuranceDetailsDelete(
              this.familyId,
              benefit.PolicyID
            )
          : this.insurancePipelineService.deleteInsuranceAppBenefitCover(
              benefit.BenefitCoverID
            );

        deletePromise
          .then(response => {
            const isSuccessful = response.data === 1 || !benefit.PolicyID;
            if (isSuccessful) {
              this.loadFiltersList();
              this.loadInsurances();
              this.insuranceSharedData.summaryInsuranceListGet(this.familyId);
              this.onDeleted && this.onDeleted({ familyId: this.familyId });
              swal({
                type: 'success',
                title: 'Insurance successfully deleted.',
              });
            } else {
              displayError({
                data: {
                  Message: 'Insurance was not deleted successfully.',
                },
              });
            }
          })
          .catch(displayError);
      }
    );
  }

  changeFilters() {
    const { filter } = this;
    const policyNumber =
      filter.policyNumber === 'Show All Policies'
        ? ''
        : filter.policyNumber.toString();
    this.loadInsurances(filter.providerId, policyNumber);
  }

  resetFilters() {
    this.filter = DEFAULT_INSURANCE_FILTER;
    this.changeFilters();
  }

  viewDataRefresh() {
    this.loadFiltersList();
    this.loadInsurances();
    this.onUpdated && this.onUpdated({ familyId: this.familyId });
    this.insuranceSharedData.summaryInsuranceListGet(this.familyId);
  }

  existingBenefitOptionChange() {
    this.loadInsurances();
  }

  addExistingIsuranceModal() {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
      controller: 'InsuranceExistingModalCtrl',
      windowClass: 'insurance-existing-modal',
      backdrop: false,
      keyboard: false,
      resolve: {
        items() {
          return {
            data: [],
          };
        },
      },
    });

    modalInstance.result.then(() => {
      this.viewDataRefresh();
    });
  }

  addNew() {
    if (this.type === 'In Progress') {
      this.onNewApplication();
    } else {
      this.addExistingIsuranceModal();
    }
  }

  edit(value) {
    if (value.QuoteID) {
      this.editInsuranceAppBenefit(value);
    } else {
      this.editInsuranceBenefit(value);
    }
  }

  editInsuranceAppBenefit(benefit) {
    this.insurancePipelineService
      .getBenefitCover(benefit.BenefitCoverID)
      .then(response => {
        this.insuranceModalApplicationService
          .openAddBenefitModal({
            ...response,
            familyId: this.familyId,
            paymentFrequency: benefit.FrequencyName,
          })
          .result.then(() => this.viewDataRefresh());
      });
  }

  editInsuranceBenefit(benefit) {
    this.contactInsuranceService
      .insuranceDetailsGet(this.familyId, benefit.BenefitID)
      .then(response => {
        const modalInstance = this.$uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/insurance-modal/insurance_existing.html',
          controller: 'InsuranceExistingModalCtrl',
          windowClass: 'insurance-existing-modal',
          backdrop: false,
          keyboard: false,
          resolve: {
            items() {
              return {
                data: response,
                obj: benefit,
              };
            },
          },
        });

        modalInstance.result.then(() => this.viewDataRefresh());
      });
  }
}
