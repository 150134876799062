import angular from 'angular';

angular
  .module('app')
  .directive('dropdownValidation', function dropdownValidation() {
    return {
      require: '?ngModel',
      scope: {
        options: '<dropdownValidation',
      },
      link(scope, elem, attrs, ngModel) {
        if (!attrs.dropdownValidationOptionKey) {
          console.error(
            'dropdown-validation-option-key is missing from dropdown-validation'
          );
          return;
        }
        ngModel.$validators.dropdownValidation = modelValue => {
          if (!scope.options) return false;
          return !!scope.options.find(option => {
            return option[attrs.dropdownValidationOptionKey] === modelValue;
          });
        };
      },
    };
  });
