import template from './pipelineReasonNotProceedingModal.html';
import controller from './pipelineReasonNotProceedingModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  bindings: {
    modalInstance: '<',
    selectedReasonId: '<',
    reasonsList: '<',
  },
};
