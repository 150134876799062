export default class CategoryFiltersCtrl {
  $onChanges(changes) {
    const { filters, selectedFilterValues } = changes;
    const filtersChanged =
      filters && filters.currentValue !== filters.previousValue;
    const selectedFiltersChanged =
      selectedFilterValues &&
      selectedFilterValues.currentValue !== selectedFilterValues.previousValue;
    if (
      (filtersChanged || selectedFiltersChanged) &&
      !this.filtersInitialized
    ) {
      this.mappedFilters = this.getMappedFilters();
      this.filtersInitialized =
        selectedFiltersChanged &&
        this.mappedFilters &&
        this.mappedFilters.length;
    }
  }

  getMappedFilters() {
    return (
      this.filters &&
      this.filters.map(filter => {
        const enabled = filter.enabled;
        const value = filter[this.filterValueProperty];
        const isSelected =
          this.selectedFilterValues &&
          this.selectedFilterValues.indexOf(value) !== -1;
        return {
          value,
          name: filter[this.filterNameProperty],
          color: filter[this.filterColorProperty],
          enabled,
          isSelected,
        };
      })
    );
  }
}
