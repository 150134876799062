import angular from 'angular';

angular.module('app').directive('selectTabDir', function selectTabDir($window) {
  return {
    restrict: 'A',
    scope: {
      selectedTab: '@',
    },
    link(scope, element) {
      const changeListener = () => {
        const el = $window.document.querySelectorAll(
          `#${scope.selectedTab} .nav-link`
        );
        angular.element(el).triggerHandler('click');
      };

      element.on('change', changeListener);
      scope.$on('destroy', () => {
        element.off('change', changeListener);
      });
    },
  };
});
