import { forEach } from 'lodash';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';

const FACT_FIND_TYPE = {
  GENERAL: 'General',
  INVITE: 'Invite',
  REQUEST: 'Request',
  REVIEW: 'Review',
};

export default class OnlineFactFindModal {
  constructor(
    $q,
    $uibModal,
    contactService,
    loanScenarioService,
    contactModelService
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.contactService = contactService;
    this.loanScenarioService = loanScenarioService;
    this.contactModelService = contactModelService;
  }

  open(settings) {
    const defer = this.$q.defer();
    const { loanId, type, isShowReviewButton } = settings;
    this.factFindModal = null;

    if (type === FACT_FIND_TYPE.INVITE) {
      this.factFindModal = this.getFullFactFind(loanId);
    } else {
      this.props = {
        loanId,
        isShowReviewButton,
      };

      this.factFindModal = this.$uibModal.open({
        template: this.getTemplate(type),
        size: 'lg',
        windowClass: 'online-fact-find-form',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props: () => this.props,
        },
      });
    }
    this.factFindModal.result.then(response => {
      if (response) {
        defer.resolve(response);
      } else {
        defer.resolve(0);
      }
    });
    return defer.promise;
  }

  getTemplate(type) {
    this.template = '';
    switch (type) {
      case FACT_FIND_TYPE.GENERAL:
        this.template = `<online-fact-find-modal
                      modal-instance='vm.modalInstance'
                      loan-id='vm.props.loanId'
                      is-review-show='vm.props.isShowReviewButton'>
                </online-fact-find-modal>`;
        break;
      case FACT_FIND_TYPE.REQUEST:
        this.template = `<request-fact-find
                      modal-instance='vm.modalInstance'
                      loan-id='vm.props.loanId'>
                  </request-fact-find>`;
        break;
      case FACT_FIND_TYPE.REVIEW:
        this.template = `<review-fact-find
                      modal-instance='vm.modalInstance'
                      loan-id='vm.props.loanId'>
                  </review-fact-find>`;
        break;
      default:
    }

    return this.template;
  }

  getFullFactFind(loanId) {
    return this.$uibModal.open({
      size: 'lg',
      windowClass: 'online-fact-find-form',
      templateUrl:
        'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
      controller: OpenOnlineFactFindForm,
      controllerAs: 'vm',
      resolve: {
        loanId: () => loanId,
      },
    });
  }

  getInvolveParty(party) {
    this.involvedParty = [];
    forEach(party, (val, key) => {
      val.forEach(data => {
        this.involvedParty.push({
          familyId: key,
          data,
        });
      });
    });

    return this.involvedParty;
  }

  getInvolvePartyToReview(loanAppId) {
    return this.loanScenarioService
      .scenarioApplicantListGet(loanAppId)
      .then(({ data }) => {
        const parties = this.getInvolveParty(data.InvolvedPartyPerson);
        const newParties = parties.reduce((accum, obj) => {
          const result = accum ? `,${obj.data.PersonId}` : obj.data.PersonId;
          return `${accum}${result}`;
        }, '');
        return this.contactModelService.checkFactFind(newParties, loanAppId);
      });
  }
}
