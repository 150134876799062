import angular from 'angular';
import { COUNTRY_TYPE } from 'Common/constants/countryType';
import { ENTITY_TYPE } from 'Common/constants/entityType';

angular
  .module('app')
  .controller('OnboardingQuestionaireCtrl', function OnboardingQuestionaireCtrl(
    $scope,
    SweetAlert,
    corporateService,
    onboardingService,
    $stateParams,
    contactService,
    queryService,
    toaster,
    onboardingDataService,
    $q
  ) {
    $scope.activeParentIndex = 0;
    $scope.currQuestionSetIdx = 0;
    $scope.isUserCountryNewZealand = false;
    $scope.showHiddenTabs = false;
    let questionTmp = [];
    $scope.questionniareFilter = [];
    $scope.questionniareLists = [];
    $scope.countContactType = 0;
    $scope.isCompletedQuestioniare = false;
    // const scrolling = () => {
    //   let fixed = false;
    //   const $ele = angular.element('#questionniare .main-nav');
    //   const eleOffset = $ele.offset().top;
    //   const eleW = $ele.width();
    //   angular.element(window).on('scroll', () => {
    //     if (angular.element(this.currentTarget).scrollTop() >= eleOffset) {
    //       if (!fixed) {
    //         $ele.css('position', 'fixed');
    //         $ele.css('top', '0px');
    //         $ele.width(eleW);
    //         fixed = true;
    //       }
    //     } else if (fixed) {
    //       $ele.css('position', 'absolute');
    //       $ele.css('top', '0px');
    //       fixed = false;
    //     }
    //   });
    // };

    $scope.selectedAnswers = function(question) {
      $scope.setQuestioniare(question);
    };

    $scope.setQuestioniare = function(question) {
      if (questionTmp.length > 0) {
        let isFound = false;
        angular.forEach(questionTmp, value => {
          if (value.question === question.QuestionNo) {
            value.question = question.QuestionNo;
            value.answer = Number(question.answer);
            value.additionalAnswer = question.AdditionalAnswer;
            isFound = true;
          } else {
            isFound = false;
          }
        });
        if (!isFound) {
          questionTmp.push({
            question: question.QuestionNo,
            answer: Number(question.answer),
            additionalAnswer: question.AdditionalAnswer,
          });
        }
      } else {
        questionTmp.push({
          question: question.QuestionNo,
          answer: Number(question.answer),
        });
      }
      $scope.updateQuestioniare(questionTmp, $scope.activeParentIndex);
    };

    const getQuestion = familyId => {
      const deferQuestion = $q.defer();
      onboardingService.questionaireListGet(familyId).then(
        questionniare => {
          if (questionniare && questionniare.data) {
            return deferQuestion.resolve(questionniare.data);
          }
        },
        err => {
          return deferQuestion.reject(err);
        }
      );

      return deferQuestion.promise;
    };

    const getAnswer = familyId => {
      const defer = $q.defer();

      onboardingService.questionaireAnswersGet(familyId).then(
        answers => {
          if (answers && answers.data) {
            return defer.resolve(answers.data);
          }
        },
        err => {
          return defer.reject(err);
        }
      );

      return defer.promise;
    };

    const setAnswersInit = (answers, question, familyId) => {
      if (!question.answer) {
        question.answer = 0;
      }
      if (!question.AdditionalAnswer) {
        question.AdditionalAnswer = '';
      }
      angular.forEach(answers, answer => {
        if (question.QuestionNo === answer.QuestionNo) {
          question.answer = answer.YesOrNoAnswer;
          question.FamilyId = familyId;
          question.AdditionalAnswer = answer.AdditionalAnswer;
          questionTmp = [
            {
              question: answer.QuestionNo,
              answer: answer.YesOrNoAnswer,
              additionalAnswer: answer.AdditionalAnswer,
            },
          ];
        }
      });
    };

    const getAllQuetionniarePerFamilyId = () => {
      const tmpListData = {
        questionniare: [],
      };
      if ($scope.questionniareFilter && $scope.questionniareFilter.length) {
        angular.forEach($scope.questionniareFilter, family => {
          let questionsValueTmp = [];
          getQuestion(family.FamilyId)
            .then(resp => {
              questionsValueTmp = resp;
            })
            .then(() => {
              tmpListData.questionniare.push({
                name: family,
                questions: questionsValueTmp,
              });
            });
        });
        onboardingDataService.setAllOnboardingDataForPreview(tmpListData);
      }
    };

    const getQuestionniareDetails = () => {
      if ($scope.activeParentIndex) {
        getQuestion($scope.activeParentIndex)
          .then(questionniare => {
            $scope.questionniareLists = questionniare;
          })
          .then(() => {
            if ($scope.questionniareLists.length) {
              getAnswer($scope.activeParentIndex).then(answers => {
                angular.forEach($scope.questionniareLists, question => {
                  setAnswersInit(answers, question, $scope.activeParentIndex);
                });
              });
            } else {
              questionTmp = [
                {
                  question: '',
                  answer: 0,
                  additionalAnswer: '',
                },
              ];
            }
          });
      }
    };

    $scope.updateQuestioniare = function(data, familyId) {
      const questionArr = [];
      const answersArr = [];
      const additionalAnsArr = [];

      angular.forEach(data, value => {
        questionArr.push(value.question);
        answersArr.push(value.answer);
        additionalAnsArr.push(value.additionalAnswer);
      });

      const question = questionArr.join();
      const answer = answersArr.join();
      const additionalAnswer = additionalAnsArr.join();

      onboardingService
        .questionaireAnswersSet(familyId, question, answer, additionalAnswer)
        .then(response => {
          if (response) {
            if ($scope.questionniareLists.length === data.length) {
              $scope.isCompletedQuestioniare = true;
              toaster.pop('success', 'Successfully', 'Answered Questionnaire');
            } else {
              $scope.isCompletedQuestioniare = false;
            }
          }
        })
        .then(() => {
          getAllQuetionniarePerFamilyId();
        });
    };

    $scope.showQuestionList = function(index) {
      $scope.activeParentIndex = index;
      getQuestionniareDetails();
    };

    $scope.isShowing = function(index) {
      return $scope.activeParentIndex === index;
    };

    const initialize = () => {
      $scope.entityTypeIsCompany =
        onboardingDataService.isUserCurrentEntityType(ENTITY_TYPE.COMPANY) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.COMPANY_TRUST
        );
      $scope.entityTypeIsPartnership =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.PARTNERSHIP_TRUST
        );
      $scope.entityTypeIsSoleTrader =
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER
        ) ||
        onboardingDataService.isUserCurrentEntityType(
          ENTITY_TYPE.SOLE_TRADER_TRUST
        );

      $scope.activeParentIndex = 0;
      /* get Current Country check if NewZealand */
      $scope.isUserCountryNewZealand = onboardingDataService.isUserCurrentCountry(
        COUNTRY_TYPE.NEW_ZEALAND
      );
      // scrolling();
      onboardingService
        .getRecruitmentContact(
          onboardingDataService.getFamilyId(),
          onboardingDataService.getClientId()
        )
        .then(recruitmentContactResp => {
          if (recruitmentContactResp && recruitmentContactResp.data) {
            const tmp = [];
            angular.forEach(recruitmentContactResp.data, value => {
              if (
                value.ContactType === 'Adviser' ||
                value.ContactType === 'Principal Adviser'
              ) {
                $scope.activeParentIndex = value.FamilyId;
                tmp.push(value);
              }

              if ($scope.entityTypeIsCompany) {
                if (value.ContactType === 'Director') {
                  tmp.push(value);
                }
              } else if ($scope.entityTypeIsPartnership) {
                if (value.ContactType === 'Partnership') {
                  tmp.push(value);
                }
              }

              if (value.ContactType) {
                $scope.countContactType++;
              }
              $scope.questionniareFilter = tmp;
            });
          }
        })
        .then(() => {
          /* get Quetionniare details */
          getQuestionniareDetails();
          getAllQuetionniarePerFamilyId();
        });
    };

    initialize();
  });
