import './style.scss';
import template from './template.html';

export default {
  template,
  bindings: {
    featuresList: '<',
    selectedFeature: '<',
    onToggleFeature: '&',
    onClose: '&',
  },
};
