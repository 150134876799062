import angular from 'angular';
import { isEmploymentIncome } from 'Common/utilities/income';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';

angular
  .module('app')
  .controller('NewIncomeModalCtrl', function NewIncomeModalCtrl(
    $scope,
    $uibModalInstance,
    modalType,
    toaster
  ) {
    $scope.Title = modalType === 'new' ? 'Add' : 'Edit';
    $scope.isEmploymentIncome = isEmploymentIncome;
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.changeIncomeModal = () => {
      if (isEmploymentIncome($scope.financials.IncomeSet.TypeId)) {
        $uibModalInstance.close({
          isOpenIncomeModal: true,
          obj: $scope.financials.IncomeSet,
        });
      } else if (
        parseInt($scope.financials.IncomeSet.TypeId, 10) ===
        DEFAULT_INCOME_TYPES.RENTAL
      ) {
        $scope.financials.IncomeSet.TypeId = $scope.prevValueHolder;
        toaster.pop(
          'error',
          'Error',
          'Please add rental income to the Existing Real Estate Asset'
        );
      }
    };
    $scope.saveBreakdown = function() {
      $scope.addIncome();
      $scope.cancel();
    };
  });
