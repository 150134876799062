import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller('OpenTaskDetailModalCtrl', function OpenTaskDetailModalCtrl(
    $scope,
    $uibModalInstance,
    tasksListData,
    $filter,
    FamilyId,
    $timeout,
    contactService,
    dashboardService,
    Id,
    taskService,
    toaster,
    $window,
    contactModelService,
    currentUserService
  ) {
    $scope.selectedTask = {};
    $scope.selectedTask.FamilyID = FamilyId;

    $scope.isCorporateUser =
      currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    $scope.isNzBased =
      COUNTRY_TYPE.NEW_ZEALAND === currentUserService.countryId;
    const isLoanWriter = $scope.isCorporateUser && $scope.isNzBased ? 0 : null;
    contactModelService.taskAdviserListGet(isLoanWriter).then(respond => {
      $scope.taskAdviserList = respond.data;
    });
    $scope.tasksListData = tasksListData;
    let defaultDate;
    const taskID = Id;
    for (let i = 0; i < $scope.tasksListData.length; i++) {
      if (
        parseInt($scope.tasksListData[i].ActivityID, 10) ===
        parseInt(taskID, 10)
      ) {
        $scope.selectedTask.taskTitle = $scope.tasksListData[i].ActivityTitle;

        if ($scope.tasksListData[i].ActivityDetails !== '') {
          $scope.showNotes = true;
          $scope.selectedTask.Notes = $scope.tasksListData[i].ActivityDetails;
        }

        $scope.selectedTask.TaskType = $scope.tasksListData[i].ActivityType;
        $scope.selectedTask.TaskCategory =
          $scope.tasksListData[i].ActivityCategory;
        $scope.selectedTask.TaskID = $scope.tasksListData[i].ActivityID;
        const date = $scope.tasksListData[i].ActivityDate;
        defaultDate = $scope.tasksListData[i].ActivityDate;
        $scope.selectedTask.DueDate = moment(date).format('D MMM YYYY');
      }
    }
    const oldSelectedTaskObject = angular.copy($scope.selectedTask);
    dashboardService.getBrokerBasicInfo().then(response => {
      $scope.brokerId = response.data.BrokerId;
      $scope.selectedTask.BrokerID = parseInt($scope.brokerId, 10);
      oldSelectedTaskObject.BrokerID = parseInt($scope.brokerId, 10);
    });
    $scope.addNotes = function() {
      $scope.showNotes = true;
    };
    $scope.taskSet = function(taskObj) {
      $scope.taskObj = {};
      $scope.taskObj.FamilyID = taskObj.FamilyID;
      $scope.taskObj.TaskID = taskObj.TaskID;
      $scope.taskObj.Title = taskObj.taskTitle;
      $scope.taskObj.assignedClientID = taskObj.BrokerID;
      $scope.taskObj.CreatedByUser = taskObj.BrokerID;
      $scope.taskObj.DueDate = taskObj.DueDate;
      $scope.taskObj.TaskType = taskObj.TaskType;
      $scope.taskObj.TaskCategory = taskObj.TaskCategory;
      $scope.taskObj.Notes = taskObj.Notes;
      taskService.taskSet($scope.taskObj).then(
        () => {
          toaster.pop('success', 'Updated', 'Task successfully updated');
          $scope.loadTasks();
          $timeout(() => {
            $uibModalInstance.dismiss('cancel');
          }, 100);
        },
        () => {
          toaster.pop('error', 'Error', 'Yikes! Something is wrong');
        }
      );
    };

    $scope.closeModal = function() {
      const result = _.isEqual($scope.selectedTask, oldSelectedTaskObject);
      if (result !== true) {
        swal(
          {
            title: 'Please confirm action',
            text:
              'Are you sure you want to close this task without saving changes?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F68F8F',
            confirmButtonText: 'Close Task',
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          isConfirm => {
            if (isConfirm) {
              $uibModalInstance.dismiss('cancel');
            }
          }
        );
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    $timeout(() => {
      angular.element('#due-date').datetimepicker({
        inline: true,
        sideBySide: true,
        debug: false,
        defaultDate,
      });
      $scope.duedateview = 'date';

      angular.element($window.document).on('dp.change', '#due-date', e => {
        $scope.selectedTask.DueDate = moment(e.date._d).format('D MMM YYYY');
      });
    });

    $scope.$watch('duedateview', newVal => {
      if (newVal === 'date') {
        angular.element('.datepicker').show();
        angular.element('.timepicker').hide();
        angular
          .element('.modal-actions')
          .clone()
          .appendTo('.datepicker');
      } else {
        angular.element('.timepicker').show();
        angular.element('.datepicker').hide();
        angular
          .element('.modal-actions')
          .clone()
          .appendTo('.timepicker');
      }
    });
  });
