class PepperService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'pepper';
  }

  optOut(familyId, optOut) {
    return this.httpClient.post(`${this.apiBaseUrl}/${familyId}/opt-out`, {
      optOutValue: optOut,
    });
  }
}

export default PepperService;
