import { PIPELINE_COLORS } from 'Common/constants/colors';
import { range, isFunction } from 'lodash';

class PipelineLabelColorPickerCtrl {
  constructor() {
    this.initColors();
    this.itemsPerRow = 5;
    const rowRange = this.colors.length / this.itemsPerRow;
    const rowIndices = range(rowRange);
    if (rowIndices && rowIndices.length) {
      this.labelColorRows = rowIndices.reduce((accum, rowIndex) => {
        const labelColors = this.getLabelColorsForRow(rowIndex);
        accum.push({
          id: rowIndex + 1,
          labelColors,
        });
        return accum;
      }, []);
    }
  }

  $onChanges(changesObj) {
    const shouldInitSelectedColorId =
      changesObj.selectedColor && !this.fromColorClick;
    if (shouldInitSelectedColorId) {
      this.selectedColorId = this.colors.indexOf(this.selectedColor) + 1;
    }
    this.fromColorClick = false;
  }

  initColors() {
    this.colors = Object.keys(PIPELINE_COLORS).reduce((accum, key) => {
      key !== 'WHITE' && accum.push(PIPELINE_COLORS[key]);
      return accum;
    }, []);
    this.colors.push(PIPELINE_COLORS.BRIGHT_TURQUOISE);
  }

  getLabelColorsForRow(rowIndex) {
    const isRowIndexValid = typeof rowIndex === 'number' && rowIndex > -1;
    if (!isRowIndexValid) return;

    const indexStart = rowIndex * this.itemsPerRow;
    const indexEnd = indexStart + this.itemsPerRow;
    const rowColors = this.colors.slice(indexStart, indexEnd);
    if (rowColors && rowColors.length) {
      const labelColors = rowColors.reduce((accum, color, index) => {
        const id = this.itemsPerRow * rowIndex + (index + 1);
        const labelColor = { id, color };
        accum.push(labelColor);
        return accum;
      }, []);
      return labelColors;
    }
  }

  isLabelColorSelected(labelColor) {
    return this.selectedColorId === labelColor.id;
  }

  onColorClicked(labelColor) {
    if (!labelColor) return;
    const isSelectedColor = this.isLabelColorSelected(labelColor);
    const selectedColor = !isSelectedColor ? labelColor.color : '';
    this.selectedColor = selectedColor;

    const selectedColorId = !isSelectedColor ? labelColor.id : 0;
    this.selectedColorId = selectedColorId;

    this.fromColorClick = true;
    if (isFunction(this.onColorSelected)) {
      this.onColorSelected({ selectedColor });
    }
  }
}

export default PipelineLabelColorPickerCtrl;
